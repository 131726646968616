import { asArray } from '../asArray'

type Obj = Record<string, any>
type ArrayKeysType<T> = MaybeReadonlyArray<keyof T | string>
type KeysType<T> = keyof T | string

type PickSignatures = {
  <T extends Obj, Keys extends ArrayKeysType<T>>(obj: T, keys: Keys): Partial<
    Record<(typeof keys)[number] & keyof T, any>
  >
  <T extends Obj, Keys extends KeysType<T>>(obj: T, keys?: Keys): Partial<
    Record<typeof keys & keyof T, any>
  >
}

export const pick: PickSignatures = <T extends Obj, Keys extends ArrayKeysType<T> | KeysType<T>>(
  obj: T,
  keys?: Keys
) =>
  Object.keys(obj)
    .filter(i => asArray(keys).includes(i))
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: obj[key]
      }),
      {}
    )
