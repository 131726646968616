// @ts-strict

import { getSubTeamMembershipsUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export type PostSubTeamMembershipArgs = {
  role: string
  teamMembershipId: string
  userId: string
}

export const postSubTeamMembership = ({
  userId,
  role,
  teamMembershipId
}: PostSubTeamMembershipArgs) =>
  postRequest(getSubTeamMembershipsUrl(), {
    team_membership_id: teamMembershipId,
    user_id: userId,
    role
  })
