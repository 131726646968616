// @ts-strict
import { Block } from 'components'
import { isCCTradeInLead, Lead } from 'models'
import { dateDiffInDays, DEFAULT_STRING } from 'utils'
import styles from './OrdersTable.module.scss'

type TProps = {
  lead: Lead
}

export const OrdersTableRowLeadDaysOwned = ({ lead }: TProps) => {
  const emptyCell = <>{DEFAULT_STRING}</>

  if (isCCTradeInLead(lead)) {
    const tradeInLead = lead.tradeInProviderLead?.tradeInLead
    const purchaseEscrowAt = tradeInLead?.departingPropertyCloseOfPurchaseEscrowAt || ''
    const saleEscrowAt = tradeInLead?.departingPropertyCloseOfSaleEscrowAt || new Date()
    if (!purchaseEscrowAt) {
      return emptyCell
    }

    const daysOwned = dateDiffInDays(saleEscrowAt, purchaseEscrowAt)

    if (daysOwned <= 0) {
      return emptyCell
    }

    const className = daysOwned >= 45 ? (daysOwned >= 60 ? 'danger' : 'warning') : ''

    return <Block title={daysOwned} className={styles[className]} />
  }

  return emptyCell
}
