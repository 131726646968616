import { getSendLeadToDialerUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export type PostSendLeadToDialerPayload = {
  leadId: string
}

export const postSendLeadToDialer = (payload: PostSendLeadToDialerPayload): Promise<any> => {
  return postRequest(getSendLeadToDialerUrl(), snakeCaseKeys(payload))
}
