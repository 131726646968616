import { createContext, ReactNode, useContext } from 'react'
import { useInvestorPayPerLeadSettingsController } from 'controllers'

const InvestorPayPerLeadSettingsContext =
  createContext<ReturnType<typeof useInvestorPayPerLeadSettingsController>>(null)

export const useInvestorPayPerLeadSettingsContext = () => {
  const context = useContext(InvestorPayPerLeadSettingsContext)
  if (!context) {
    throw new Error(
      'useInvestorPayPerLeadSettingsContext must be called within an InvestorPayPerLeadSettingsProvider'
    )
  }
  return context
}

type Props = {
  children: ReactNode
  investorId?: string
}

export const InvestorPayPerLeadSettingsProvider = ({ children, investorId }: Props) => {
  return (
    <InvestorPayPerLeadSettingsContext.Provider
      value={useInvestorPayPerLeadSettingsController(investorId)}
    >
      {children}
    </InvestorPayPerLeadSettingsContext.Provider>
  )
}
