import { addSeconds, format } from 'date-fns'
import { DEFAULT_STRING } from '../constants'

export const formatDuration = (duration?: string | number, defaultValue: any = DEFAULT_STRING) => {
  if (!duration || !parseInt(String(duration), 10)) {
    return defaultValue
  }

  return format(addSeconds(new Date(0), parseInt(String(duration), 10)), 'm:ss')
}
