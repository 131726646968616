import { css, styled, Theming } from '@foundation/themes/styles'
import { AutocompleteProps } from './types'

type TriggerWrapperProps = {
  color: 'neutral4' | 'primary10'
  isDisabled: boolean
  size: AutocompleteProps['size']
}

const triggerSizeStyle = {
  xs: ({ theme }: { theme: Theming }) => css`
    & > svg {
      top: ${theme.space.$2};
      right: ${theme.space.$3};
    }

    & > span[data-testid='spinnerWrapper'] {
      top: ${theme.space.$2};
      right: ${theme.space.$7};
    }

    button[data-id='clearButton'] {
      top: 0.5rem;
      right: ${theme.space.$7};
    }
  `,
  lg: ({ theme }: { theme: Theming }) => css`
    & > svg {
      top: ${theme.space.$4};
      right: ${theme.space.$3};
    }

    & > span[data-testid='spinnerWrapper'] {
      top: ${theme.space.$4};
      right: ${theme.space.$7};
    }

    button[data-id='clearButton'] {
      top: ${theme.space.$4};
      right: ${theme.space.$7};
    }
  `
}

const disabledField = (theme: Theming) => css`
  input {
    border-color: ${theme.colors.neutral4};

    &::placeholder {
      color: ${theme.colors.neutral4};
    }
  }
`

export const TriggerWrapper = styled.div<TriggerWrapperProps>`
  ${({ color, isDisabled, theme, size }) => css`
    color: ${theme.colors[color]};
    position: relative;

    & > svg {
      position: absolute;
    }

    & > span[data-testid='spinnerWrapper'] {
      position: absolute;
    }

    ${size && triggerSizeStyle[size]}
    ${isDisabled && disabledField(theme)}
  `}
`

export const ClearButton = styled.button`
  ${({ theme }) => css`
    position: absolute;
    display: flex;
    align-items: center;
    padding: 0.2rem;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: ${theme.radii.rounded};
    cursor: pointer;
    &:hover {
      background-color: ${theme.colors.neutral2};
    }
  `}
`
