import { LEAD_SUMMARY_CACHE_KEY } from 'controllers'
import { useQuery } from 'hooks'
import { fetchLeadSummary } from 'services'

const STALE_TIME = 300000 // 5 min

export const useLeadSummaryController = (leadId: string) => {
  const {
    data: lead,
    isLoading,
    isError
  } = useQuery([LEAD_SUMMARY_CACHE_KEY, leadId], () => fetchLeadSummary(leadId), {
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME
  })

  return {
    lead,
    isLoading,
    isError
  }
}
