import { Outlet } from 'react-router-dom'
import { Console, ConsoleAside, ConsoleDetails, Dev, Sticky, TabNav, Tabs } from 'components'
import { useDocumentTitle } from 'hooks'
import { SettingsAside } from './SettingsAside'
import styles from './SettingsPage.module.scss'

export const SettingsPageContent = ({ children }) => {
  return (
    <Console>
      <ConsoleAside className={styles.aside}>
        <Sticky>
          <SettingsAside />
        </Sticky>
      </ConsoleAside>
      <ConsoleDetails className={styles.details}>
        <Tabs className={styles.tabs} sticky>
          <TabNav to="/settings">Details</TabNav>
          <TabNav to="/settings/preferences">Preferences</TabNav>
          <Dev>
            <TabNav to="/settings/inspect">Inspect</TabNav>
          </Dev>
        </Tabs>
        {children}
      </ConsoleDetails>
    </Console>
  )
}

export const SettingsPage = () => {
  useDocumentTitle('Settings')

  return (
    <SettingsPageContent>
      <Outlet />
    </SettingsPageContent>
  )
}
