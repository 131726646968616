import { AgentTeam, User } from 'models'
import { AgentTeamMembershipResponse } from 'schema'

export class AgentTeamMembership {
  id: string
  agentTeam?: AgentTeam
  role?: 'Agent' | string
  user?: User

  constructor(agentTeamMembershipAttributes: AgentTeamMembership) {
    Object.assign(this, agentTeamMembershipAttributes)
  }

  static create(
    agentTeamMembershipResponse: Potential<AgentTeamMembershipResponse>
  ): Potential<AgentTeamMembership> {
    if (!agentTeamMembershipResponse) {
      return
    }
    const user = User.create(agentTeamMembershipResponse?.user)
    const agentTeam = AgentTeam.create(agentTeamMembershipResponse?.agentTeam)
    return new AgentTeamMembership({ ...agentTeamMembershipResponse, user, agentTeam })
  }
}
