import { FormField, Section, Sections } from 'components'
import { LoanOfficerSection } from 'content/LoanOfficer/LoanOfficerSection/LoanOfficerSection'
import { dataOrDash, formatPhone } from 'utils'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>
type LoanOfficer = Props['lead']['bbysProviderLead']['bbysLead']['loanOfficer']

export const LoanOfficerInformationSection = ({ lead, updateBBYSLead, updateLead }: Props) => {
  const bbysLead = lead.bbysProviderLead.bbysLead
  const loanOfficer = bbysLead?.loanOfficer || ({} as LoanOfficer)

  const { phoneNumber, phoneNumbers } = loanOfficer
  const phone = phoneNumber?.number || phoneNumbers?.[0]?.number

  return (
    <Sections title="Loan Officer">
      <Section>
        <LoanOfficerSection
          title="Name"
          loanOfficer={loanOfficer}
          onBlur={val =>
            updateLead({
              loan_officer_id: val
            })
          }
        />
      </Section>
      <Section>
        <FormField title="Email Address" inline>
          {dataOrDash(loanOfficer.email)}
        </FormField>

        <FormField title="Phone Number" inline>
          {dataOrDash(formatPhone(phone))}
        </FormField>
        <FormField title="Company Name" inline>
          {dataOrDash(loanOfficer.companyName)}
        </FormField>
        <FormField title="Partner Slug" inline>
          {dataOrDash(bbysLead.sourcePartner?.slug)}
        </FormField>
      </Section>
    </Sections>
  )
}
