// @ts-strict
import { MabpoxApiNormalizedFeature, MapboxApiFeature } from '../types'

const getContext = (arr: MapboxApiFeature['context'], type: string) =>
  arr.find(ctx => ctx.id.indexOf(type) > -1)

export const normalizeMapboxApiResponse = (
  result: MapboxApiFeature
): MabpoxApiNormalizedFeature => {
  const { context, geometry, place_name, address, text, place_type, id } = result
  const isAddress = place_type && place_type[0] === 'address'
  const isCity = place_type && place_type[0] === 'place'
  const isZip = place_type && place_type[0] === 'postcode'
  const stateContext = getContext(context, 'region.')
  const county = getContext(context, 'district.')?.text
  const postal_code = isZip ? text : getContext(context, 'postcode.')?.text
  const lat = geometry && geometry.coordinates && geometry.coordinates[1]
  const lng = lat && geometry.coordinates[0]
  const full_address = place_name && place_name.replace(', United States', '')
  const types = place_type
  let city: string | undefined
  let description: string | undefined
  let street_address: string | undefined
  let slugArr: (string | undefined)[] = []

  const state: string | undefined =
    stateContext &&
    [
      stateContext.short_code && stateContext.short_code.replace('US-', ''),
      stateContext.text
    ].filter(x => x)[0]

  if (isCity) {
    city = text
    description = `${city}, ${state}`
    slugArr = [city, state]
  } else if (isAddress) {
    city = getContext(context, 'place.')?.text
    street_address = address && text && `${address} ${text}`
    description = full_address
    slugArr = [city, state]
  } else if (isZip) {
    description = postal_code
    slugArr = [postal_code]
  }

  const slug: string | undefined = slugArr
    ?.map((x: string | undefined) => x?.toLowerCase().split(' ').join('-'))
    .join('-')

  return {
    id,
    slug,
    city,
    state,
    types,
    postal_code,
    county,
    street_address,
    full_address,
    description,
    lat,
    lng
  }
}
