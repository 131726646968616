import {
  AddressUnitAutoComplete,
  DateInput,
  DollarsInput,
  FormField,
  Section,
  Sections,
  Select
} from 'components'

export const SellerLeadFormFields = ({
  formTitle,
  fieldSettings,
  fieldValues,
  leadPropertyAddress,
  leadPropertyTypes,
  leadTimelineValues,
  set
}) => {
  return (
    <>
      <Sections title={formTitle}>
        <Section>
          <FormField title="Property Address" required={fieldSettings.propertyAddress.isRequired}>
            <AddressUnitAutoComplete
              data-testid="propertyAddress"
              onSelect={val => set('propertyAddress', val)}
              value={leadPropertyAddress}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="Property Type" required={fieldSettings.propertyType.isRequired}>
            <Select
              addBlank
              data-testid="propertyTypeSelect"
              value={fieldValues.propertyType}
              options={leadPropertyTypes}
              onChange={val => set('propertyType', val)}
            />
          </FormField>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <FormField title="Price" required={fieldSettings.price.isRequired}>
            <DollarsInput
              data-testid="priceInput"
              value={fieldValues.price}
              onChange={e => set('price', e)}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="Timeline" required={fieldSettings.timeline.isRequired}>
            <Select
              addBlank
              data-testid="timelineSelect"
              value={fieldValues.timeline}
              options={leadTimelineValues}
              onChange={val => set('timeline', val)}
            />
          </FormField>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <FormField title="Original Created At">
            <DateInput
              value={fieldValues.originalCreatedAt}
              onChange={val => set('originalCreatedAt', val)}
            />
          </FormField>
        </Section>
      </Sections>
    </>
  )
}
