import { AttachLeadOrderVariables } from 'contexts/types'
import { getOrdersUrl } from 'services/getUrl'
import { deleteRequest } from 'utils'

export const deleteAttachLeadOrder = ({
  orderId,
  leadId
}: AttachLeadOrderVariables): Promise<any> => {
  return deleteRequest(getOrdersUrl({ id: orderId, action: `leads/${leadId}/order-leads` }))
}
