import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Form.module.scss'

type Props = {
  children: ReactNode
  className?: string
  confirmation?: string
  error?: string | false
  onMouseEnter?: () => void
}

export const FormActions = ({
  children,
  confirmation,
  error,
  className,
  onMouseEnter,
  ...props
}: Props) => {
  return (
    <div
      className={cx(styles.actions, className)}
      onMouseEnter={onMouseEnter}
      {...props}
      data-testid="form-actions"
    >
      {error && <span className={styles.error}>{error}</span>}
      {!error && confirmation && <span className={styles.confirmation}>{confirmation}</span>}
      {children}
    </div>
  )
}
