import { CapitalPartner } from 'models'
import { getConstantsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchCapitalPartners = async () => {
  const response = await getRequest(
    getConstantsUrl({
      searchParams: {
        types: 'capital_partners'
      }
    })
  )
  const capitalPartners = await deserializeAsync(response.data, {
    transform: CapitalPartner.create
  })
  return capitalPartners
}
