import pkceChallenge from 'pkce-challenge'
import { CODE_CHALLENGE_KEY, CODE_VERIFIER_KEY, LAST_UNAUTHORIZED_URL_KEY } from '@constants'
import { useLocalStorage, useLocation } from 'hooks'

const PKCE_CHALLENGE_CHARS = 128

export const useGeneratePKCEToAuthenticate = () => {
  const { code_verifier: generatedCodeVerifier, code_challenge: generatedCodeChallenge } =
    pkceChallenge(PKCE_CHALLENGE_CHARS)

  const location = useLocation()
  const routesToExclude = ['auth/redirect', 'sign-out', 'sign-in']
  const userLastLocation = routesToExclude.some(route => location.pathname?.includes(route))
    ? '/'
    : `${location.pathname}${location?.search}`

  const [codeVerifier, , clearCodeVerifier] = useLocalStorage<string | undefined>(
    CODE_VERIFIER_KEY,
    generatedCodeVerifier
  )
  const [codeChallenge, , clearCodeChallenge] = useLocalStorage<string | undefined>(
    CODE_CHALLENGE_KEY,
    generatedCodeChallenge
  )
  const [lastUnauthorizedURL, , clearLastUnauthorizedURL] = useLocalStorage<string | undefined>(
    LAST_UNAUTHORIZED_URL_KEY,
    userLastLocation
  )

  const clearPKCEChallenge = () => {
    clearCodeVerifier()
    clearCodeChallenge()
    clearLastUnauthorizedURL()
  }

  return {
    codeVerifier,
    codeChallenge,
    lastUnauthorizedURL,
    clearPKCEChallenge
  }
}
