// @ts-strict

import { useState } from 'react'
import { CCCashOfferLeadDisposition } from 'content/Lead'
import { CCCashOfferLead } from 'models'

type CashOfferLeadTabActionsProps = {
  lead: CCCashOfferLead
}

export const CashOfferLeadTabActions = ({ lead }: CashOfferLeadTabActionsProps) => {
  const [showDispositionForm, setShowDispositionForm] = useState(false)

  const toggleLeadDispositionForm = () => {
    setShowDispositionForm(!showDispositionForm)
  }

  return (
    <>
      <CCCashOfferLeadDisposition lead={lead} onCancel={toggleLeadDispositionForm} />
    </>
  )
}
