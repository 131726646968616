import { useFeatureFlags } from 'hooks'
import { TMarketplaceProgramSlug, useMarketplacePrograms } from 'lookups'
import { Agent } from 'models'
import { cx } from 'utils'
import { AgentMarketplaceProgramBadge } from '../AgentMarketplaceProgram'
import styles from './AgentPrograms.module.scss'

type Props = {
  agent: Agent
}

export const AgentMarketplacePrograms = ({ agent }: Props) => {
  const { marketplacePrograms } = useMarketplacePrograms()
  const { salesAppAgentMetrics } = useFeatureFlags(['sales-app-agent-metrics'])

  if (!agent?.marketplaceProgramAgents || !agent?.marketplaceProgramAgents.length) {
    return null
  }

  const marketplaceProgramAgents = agent.marketplaceProgramAgents.filter(
    program => program.enrollStatus !== 'unenrolled'
  )

  const getMarketplaceProgramAgent = (slug: TMarketplaceProgramSlug) =>
    marketplaceProgramAgents.find(item => slug && item.marketplaceProgram.slug === slug)

  const sortedMarketplaceProgramAgents = marketplacePrograms
    .map(mp => getMarketplaceProgramAgent(mp.slug))
    .filter(Boolean)

  return (
    <>
      {sortedMarketplaceProgramAgents.map(mppa => (
        <div key={mppa.marketplaceProgram.slug} className={cx('agentProgram', styles.agentProgram)}>
          <AgentMarketplaceProgramBadge marketplaceProgramAgent={mppa} />
          {!salesAppAgentMetrics?.enabled && (
            <div className={styles.programCounter}>
              {agent.getMarketplaceProgramCount(mppa.marketplaceProgram.slug) || '-'}
            </div>
          )}
        </div>
      ))}
    </>
  )
}
