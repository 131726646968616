import ReactDatetimePicker from 'react-datetime-picker'
import { cx } from 'utils'
import styles from './Calendar.module.scss'

type Props = {
  className?: string
  onChange: (val: Date) => void
  showNeighboringMonth?: boolean
  value: Date
}

export const CalendarDatetime = ({ value, className, ...props }: Props) => {
  return (
    <ReactDatetimePicker
      clearIcon={null}
      calendarIcon={null}
      disableClock={true}
      value={value}
      className={cx(className, styles.datetime)}
      {...props}
    />
  )
}
