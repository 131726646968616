// @ts-strict
import { Marker, Point } from 'classes'
import { asArray, isNotNull } from 'utils'
type TProps = {
  center: Point
  markers: Potential<Marker>[] | Potential<Marker>
}

export const useMapBox = ({ markers, center }: TProps) => {
  return { markers: asArray(markers).filter(isNotNull), center }
}
