import { useEffect } from 'react'
import { Inspect } from 'components/Inspect'
import { useEmployeeContext } from 'contexts'
import { useDocumentTitle } from 'hooks'

export const EmployeeInspectTab = () => {
  const { employeeQuery } = useEmployeeContext()
  const { data: employee } = employeeQuery
  const { setTitle } = useDocumentTitle()

  useEffect(() => setTitle(`${employee.name} - Employee Inspect`), [employee, setTitle])
  return <Inspect>{employee}</Inspect>
}
