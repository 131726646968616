import { TradeInLead } from 'models'
import { TradeInLeadAttributes } from 'schema'
import { stringifyValue } from 'utils'
import { CCTradeInLeadDetailsProps } from '../..'

export const getTradeInLeadInputProps = (
  tradeInLead?: TradeInLead,
  updateTradeInLead?: CCTradeInLeadDetailsProps['updateTradeInLead']
) => {
  const handleUpdateTradeInLead = (key: keyof TradeInLeadAttributes, val: string) => {
    if (!tradeInLead || !updateTradeInLead) {
      return
    }
    updateTradeInLead.mutate({
      id: tradeInLead?.id,
      payload: {
        [key]: val
      }
    })
  }

  const getProps = (key: keyof TradeInLeadAttributes) => {
    const value = tradeInLead ? stringifyValue(tradeInLead[key]) : ''

    return {
      value,
      onBlur: val => {
        handleUpdateTradeInLead(key, val)
      }
    }
  }

  return {
    getProps,
    handleUpdateTradeInLead
  }
}
