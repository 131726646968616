import { AgentInterface } from '../Agent'
import { BuyerLeadInterface } from '../BuyerLead'
import { CashOfferLeadInterface } from '../CashOfferLead'
import { EscrowLeadInterface } from '../EscrowLead'
import { HLSimpleSaleLeadInterface } from '../HLSimpleSaleLead'
import { MortgageLeadInterface } from '../MortgageLead'
import { SellerLeadInterface } from '../SellerLead'
import { TradeInLeadInterface } from '../TradeInLead'
import * as utils from './utils'

export * from './utils/getResourceUrl'
export * from './utils/getNameFromUserResource'
export * from './utils/getRecipientsString'
export * from './utils/getUserTypeString'

export type UserResourceType = 'User' | 'Agent' | 'Order'

export type ActivityFeedMetaInterface = {
  actualSender?: string
  actualSenderDetails?: UserResource
  column?: string
  communicationId?: string
  communicationProviderName?: string
  communicationTemplateName?: string
  communicationThreadId?: string
  currentValue?: string
  description?: string
  direction?: string
  eventType?: string
  name?: string
  notes?: string
  occurredAt?: string
  phoneNumber?: string
  recipients?: string[]
  recipientsDetails?: UserResource[]
  sender?: string
  senderDetails?: UserResource
  status?: string
}

export type UserResourcePhone = 'unknown' | (string & {})

export interface UserResource {
  call?: {
    phone?: UserResourcePhone
  }
  email?: {
    address?: string
    name?: string
  }
  linkedResourceId?: number
  linkedResourceType?: UserResourceType
  sms?: {
    phone?: UserResourcePhone
  }
  status?: string
  userId?: number
  userName?: string
}

export type ActivityFeedInterface = {
  agent?: AgentInterface
  callLog: {
    recordingUrl: string
  }
  description?: string
  id: string
  lead?:
    | BuyerLeadInterface
    | CashOfferLeadInterface
    | EscrowLeadInterface
    | HLSimpleSaleLeadInterface
    | MortgageLeadInterface
    | SellerLeadInterface
    | TradeInLeadInterface
  meta: ActivityFeedMetaInterface
  name: string
  occurredAt: string
  title: string
}

export const ActivityFeedModel = {
  ...utils
}
