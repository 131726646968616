import { ReactNode } from 'react'
import { createCtx } from 'contexts'
import { CCTradeInLead } from 'models'
import { useCCTradeInLeadDisposition } from '..'

type CCTradeInLeadDispositionProviderProps = {
  children: ReactNode
  lead: CCTradeInLead
}

export const [useCCTradeInLeadDispositionContext, CCTradeInLeadDispositionContextProvider] =
  createCtx<ReturnType<typeof useCCTradeInLeadDisposition>>()

export const CCTradeInLeadDispositionProvider = ({
  lead,
  children
}: CCTradeInLeadDispositionProviderProps) => {
  return (
    <CCTradeInLeadDispositionContextProvider value={useCCTradeInLeadDisposition(lead)}>
      {children}
    </CCTradeInLeadDispositionContextProvider>
  )
}
