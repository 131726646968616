// @ts-strict

import {
  FormField,
  InlineEditPhoneInput,
  InlineEditSelect,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { yesOrNoOptions } from 'lookups'
import { MortgageLead } from 'models'
import { stringifyValue } from 'utils'

type Props = {
  lead: MortgageLead
  requiredFields?: Record<string, boolean>
  updateCallReview: (key: string, value: string) => void
}

export const AgentMatchingLeadExistingLenderScreenSection = ({
  lead,
  updateCallReview,
  requiredFields = {}
}: Props) => {
  return (
    <Sections title="Existing Lender Info">
      <Section>
        <FormField title="Lender Name" required={requiredFields.existingLenderName} inline>
          <InlineEditTextInput
            onBlur={(value: string) => updateCallReview('existingLenderName', value)}
            value={stringifyValue(lead.callReview?.existingLenderName)}
          />
        </FormField>
        <FormField
          title="Loan Officer Name"
          required={requiredFields.existingLoanOfficerName}
          inline
        >
          <InlineEditTextInput
            onBlur={(value: string) => updateCallReview('existingLoanOfficerName', value)}
            value={stringifyValue(lead.callReview?.existingLoanOfficerName)}
          />
        </FormField>
        <FormField
          title="Loan Officer Phone"
          required={requiredFields.existingLoanOfficerPhone}
          inline
        >
          <InlineEditPhoneInput
            onBlur={(value: string) => updateCallReview('existingLoanOfficerPhone', value)}
            value={stringifyValue(lead.callReview?.existingLoanOfficerPhone)}
          />
        </FormField>
        <FormField
          title="Loan Officer Email"
          required={requiredFields.ageRestrictedCommunity}
          inline
        >
          <InlineEditTextInput
            onBlur={(value: string) => updateCallReview('existingLoanOfficerEmail', value)}
            value={stringifyValue(lead.callReview?.existingLoanOfficerEmail)}
          />
        </FormField>
        <FormField
          title="Client is Pre-Approved?"
          required={requiredFields.existingLenderPreApproved}
          inline
        >
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('existingLenderPreApproved', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.existingLenderPreApproved)}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
