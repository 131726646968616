// @ts-strict

import { Lead, Order } from 'models'
import { TFieldValues } from '../useAttachLeadCreateLeadTab'

type TArgs = {
  fieldValues: TFieldValues
  lead: Lead
  order: Order
}

export const getLeadUserTypePayload = ({ lead, order, fieldValues }: TArgs) => {
  const commonPayload = {
    source: 'web',
    sourcePageType: 'sales_app',
    sourceForm: 'attach_leads_to_order',
    duplicate: true,
    duplicateFromLeadId: lead.id,
    orderId: order.id,
    userType: fieldValues.leadUserType,
    referringOwnerId: fieldValues.referringAgent?.id,
    referringOwnerType: fieldValues.referringAgent ? 'Agent' : undefined,
    price: fieldValues.price,
    propertyType: fieldValues.propertyType,
    timeline: fieldValues.timeline
  }

  switch (fieldValues.leadUserType) {
    case 'buyer': {
      const [cityName, stateName] = (fieldValues.cityState || '')
        .split(',')
        .map(field => field.trim())
      return {
        ...commonPayload,
        agentAeId: fieldValues.asmAse?.id,
        cityName: cityName,
        stateName: stateName,
        originalCreatedAt: fieldValues.originalCreatedAt
      }
    }

    case 'seller': {
      return {
        ...commonPayload,
        agentAeId: fieldValues.asmAse?.id,
        fullAddress: fieldValues.propertyAddress,
        originalCreatedAt: fieldValues.originalCreatedAt
      }
    }

    case 'cc_trade_in': {
      return {
        ...commonPayload,
        tradeInLeadAttributes: {
          incomingPropertyFullAddress: fieldValues.propertyAddress,
          departingPropertySellerAgentId: fieldValues.sellerAgent?.id,
          simpleSale: fieldValues.hlSimpleSale
        }
      }
    }

    case 'cc_cash_offer': {
      return {
        ...commonPayload,
        cashOfferLeadAttributes: {
          incomingPropertyFullAddress: fieldValues.propertyAddress,
          incomingPropertyBuyerAgentId: fieldValues.buyerAgent?.id
        }
      }
    }

    case 'cc_hl_simple_sale': {
      return {
        ...commonPayload,
        hlSimpleSaleLeadAttributes: {
          departingPropertyFullAddress: fieldValues.propertyAddress,
          departingPropertySellerAgentId: fieldValues.sellerAgent?.id
        }
      }
    }

    case 'mortgage': {
      return commonPayload
    }

    default: {
      return {}
    }
  }
}
