import { useState } from 'react'
import { ActionBar, Button, Form, FormField, Select, TextArea } from 'components'
import { byValue } from 'utils'

type Props = {
  declinedReasons?: TLookupType[]
  onCancel: () => void
  onSubmit: ({ notes, declinedReason }: { declinedReason: TLookupType; notes: string }) => void
}

export const ServicesOpportunitySelectorNoteForm = ({
  onSubmit,
  onCancel,
  declinedReasons
}: Props) => {
  const [notes, setNotes] = useState('')
  const [declinedReason, setDeclinedReason] = useState(null)
  const cantSubmit = !declinedReason || !notes

  const handleDeclinedReason = (val: string) =>
    setDeclinedReason(declinedReasons.find(byValue(val)))

  const handleOnSubmit = () => onSubmit({ notes, declinedReason })

  return (
    <Form onSubmit={handleOnSubmit}>
      {declinedReasons ? (
        <FormField title="Decline reason" required>
          <Select
            value={declinedReason?.value}
            options={declinedReasons}
            onChange={handleDeclinedReason}
            addBlank
          />
        </FormField>
      ) : null}
      <FormField title="Note" required>
        <TextArea value={notes} onChange={val => setNotes(val)} />
      </FormField>
      <ActionBar>
        <Button as="cancel" onClick={onCancel}>
          Cancel
        </Button>
        <Button as="submit" disabled={cantSubmit}>
          Submit
        </Button>
      </ActionBar>
    </Form>
  )
}
