import { useCallback, useEffect, useState } from 'react'

export const useScreenSize = () => {
  const [width, setWidth] = useState<number>()

  const onResize = useCallback(() => {
    setWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    setWidth(window.innerWidth)
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  return {
    isMobile: width <= 960,
    isDesktop: width > 960
  }
}
