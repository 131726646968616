// @ts-strict
import { useNavigate } from 'react-router-dom'
import { ORDERS_CACHE_KEY, useMutation, UseMutationResult, useQueryClient } from 'hooks'
import { EscrowLead } from 'models'
import {
  postEscrowOrder,
  postEscrowOrderInstantOpen,
  TEscrowOrderInstantOpenPostPayload,
  TEscrowOrderPostPayload
} from 'services'

type UseCreateEscrowOrderControllerProps = {
  clearCacheKey?: string
  onSuccess?: () => void
}

export const useCreateEscrowOrderController = ({
  onSuccess,
  clearCacheKey
}: UseCreateEscrowOrderControllerProps) => {
  const queryCache = useQueryClient()
  const clearCaches = () => {
    if (clearCacheKey) {
      queryCache.invalidateQueries([clearCacheKey])
    }
    queryCache.invalidateQueries([ORDERS_CACHE_KEY])
  }
  const navigate = useNavigate()

  const createEscrowOrder: UseMutationResult<
    Potential<EscrowLead>,
    unknown,
    TEscrowOrderPostPayload,
    unknown
  > = useMutation(data => postEscrowOrder(data), {
    onSuccess: () => {
      clearCaches()
      if (onSuccess) {
        onSuccess()
      }
    }
  })

  const createInstantOrder: UseMutationResult<
    Potential<EscrowLead>,
    unknown,
    TEscrowOrderInstantOpenPostPayload,
    unknown
  > = useMutation(data => postEscrowOrderInstantOpen(data), {
    onSuccess: () => {
      clearCaches()
      if (onSuccess) {
        onSuccess()
      }
    }
  })

  const handleOrderCreated = async (lead: EscrowLead) => {
    navigate(`/escrows/${lead.id}`)
  }

  return { createEscrowOrder, createInstantOrder, handleOrderCreated }
}
