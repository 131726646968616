// @ts-strict

import { useState } from 'react'
import { Button } from '@foundation/components'
import { CCTradeInLeadDisposition } from 'content/Lead'
import { CCTradeInLead } from 'models'

type TradeInLeadTabActionsProps = {
  lead: CCTradeInLead
}

export const TradeInLeadTabActions = ({ lead }: TradeInLeadTabActionsProps) => {
  const [showDispositionForm, setShowDispositionForm] = useState(false)

  return (
    <>
      <Button size="xs" onClick={() => setShowDispositionForm(true)}>
        Disposition
      </Button>
      {showDispositionForm && (
        <CCTradeInLeadDisposition lead={lead} onCancel={() => setShowDispositionForm(false)} />
      )}
    </>
  )
}
