// @ts-strict
import { Box, Flex } from '@foundation/components'
import { Contents } from 'components'
import { Divider } from 'components/Divider'
import { Agent } from 'models'
import AgentBillingStatus from './AgentBillingStatus'
import { LocationSettingsCurrentList } from './LocationSettings'

type Props = {
  agent: Agent
}

export const AgentBilling = ({ agent }: Props) => {
  return (
    <Contents title="Billing Type">
      <Box
        borderStyle="solid"
        borderColor="neutral5"
        borderWidth="thin"
        borderRadius="xl"
        padding="$8"
        width="485px"
      >
        <Box backgroundColor="neutral2" padding="$8" borderRadius="xl">
          <Flex flexDirection="column" gap="$7">
            <AgentBillingStatus agent={agent} />
          </Flex>
        </Box>
        <Box paddingTop="$6" paddingBottom="$6">
          <Divider />
        </Box>
        <LocationSettingsCurrentList agent={agent} locationSettings={agent.pplProviderSettings!} />
      </Box>
    </Contents>
  )
}
