import { toSnakeCase } from '../toSnakeCase'

type Params = {
  [key: string]:
    | number
    | string
    | boolean
    | Array<number | string | Params>
    | Date
    | Params
    | string[]
}

export const snakeCaseKeys = (params: Params = {}): Params => {
  // we don't want to process non-object params when recursively running this function:
  if (typeof params !== 'object' || !params) {
    return params
  }

  return Object.entries(params).reduce((obj, [k, v]) => {
    let value = v

    if (!!obj && typeof value === 'object' && value instanceof Object) {
      // If it's an array, check that array items can be snakecased as well
      if (Array.isArray(value)) {
        value = value.map(item => {
          // Run snakeCaseKeys on an array's Params items, but not on other types
          if (typeof item === 'object' && item instanceof Object && !Array.isArray(item)) {
            return snakeCaseKeys(item)
          }
          return item
        })
      } else if (value?.constructor === Object) {
        value = snakeCaseKeys(v as Params)
      }
    }

    return {
      ...obj,
      [toSnakeCase(k)]: value
    }
  }, {})
}
