import { TeamMembership } from 'models'
import { byAttr } from 'utils'
import { escrowLeadFieldsSettings, TEscrowLeadFieldAttribute } from './escrowLeadFieldsSettings'
import { EscrowLeadField } from './'

export const lookupEscrowLeadFields = escrowLeadFieldsSettings.map(setting => {
  return new EscrowLeadField(setting)
})

export const getEscrowLeadField = (attr: TEscrowLeadFieldAttribute) => {
  return lookupEscrowLeadFields.find(byAttr('attr', attr))
}

export const getEscrowLeadFieldName = (attr: TEscrowLeadFieldAttribute) => {
  return getEscrowLeadField(attr)?.name
}

export const getSettlementAgencyTeamMemberNamesByRole = (
  role: string,
  settlementAgencyTeam?: TeamMembership[]
): string[] => {
  if (!settlementAgencyTeam) {
    return ['---']
  }

  const members = settlementAgencyTeam
    .filter(member => member.role === role)
    .map(member => member.user?.name)
    .filter(Boolean)

  if (!members.length) {
    return ['---']
  }

  return members
}
