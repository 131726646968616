import {
  ApplyFiltersButton,
  CheckBoxArray,
  Column,
  Columns,
  Filters,
  ListBox,
  MenuSection,
  Section,
  SectionFooter,
  Select
} from 'components'
import { useNewFilters } from 'hooks'
import {
  activityWindowOptions,
  blankOptions,
  leadStagesOptions,
  stateOptions,
  trueFalseOptions,
  userTypeOptions,
  useServicesOpportunities
} from 'lookups'
import styles from './AgentLeadsListFilters.module.scss'

type Params = {
  daysAgo: string
  escrowAttached: string
  leadStages: string[]
  oppStatus: string[]
  state: string[]
  userType: string[]
}

export const AgentLeadsListFilters = ({ totalUrgentCount }: { totalUrgentCount?: number }) => {
  const { newParams, set } = useNewFilters<Params>()
  const { activeServicesOpportunitiesStatusOptions } = useServicesOpportunities()

  return (
    <Filters>
      <Section className={styles.section}>
        <Columns>
          <Column>
            <MenuSection title="Lead Type">
              <CheckBoxArray
                options={userTypeOptions}
                value={newParams.userType}
                onChange={val => set('userType', val)}
              />
            </MenuSection>
            <MenuSection title="Opp Status">
              <CheckBoxArray
                options={activeServicesOpportunitiesStatusOptions}
                value={newParams.oppStatus}
                onChange={val => set('oppStatus', val)}
              />
            </MenuSection>
            <MenuSection title="State">
              <CheckBoxArray
                options={stateOptions(totalUrgentCount)}
                value={newParams.state}
                onChange={val => set('state', val)}
              />
            </MenuSection>
            <MenuSection title="Escrow Attachment">
              <Select
                value={newParams.escrowAttached}
                options={blankOptions.concat(trueFalseOptions)}
                onChange={val => set('escrowAttached', val)}
              />
            </MenuSection>
          </Column>
          <Column title="Stages">
            <CheckBoxArray
              options={leadStagesOptions}
              value={newParams.leadStages}
              onChange={val => set('leadStages', val)}
            />
          </Column>
          <Column title="Activity Window">
            <ListBox
              options={activityWindowOptions}
              value={newParams.daysAgo}
              onChange={val => set('daysAgo', val)}
            />
          </Column>
        </Columns>
        <SectionFooter>
          <ApplyFiltersButton newParams={newParams} />
        </SectionFooter>
      </Section>
    </Filters>
  )
}
