// @ts-strict
import { useState } from 'react'
import { Button, IconButton, Popover } from '@foundation/components'
import { IconPathKeys } from '@foundation/icons'
import { ActionBar } from 'components'
import { AgentLeadDispositionButtonForm } from 'content/AgentLead'
import { useAgentLeadController } from 'controllers'
import { useUserSettings } from 'hooks'
import { AgentProviderLead } from 'models'
import { cx, toSnakeCase } from 'utils'
import styles from './AgentProviderLeadDispositionHeader.module.scss'

type TProps = {
  agentProviderLead: AgentProviderLead
}

export const AgentProviderLeadDispositionHeader = ({ agentProviderLead }: TProps) => {
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const { resendIntroEmail } = useAgentLeadController(agentProviderLead?.agentLead?.id!)
  const { isEnabled } = useUserSettings()
  const stageLabel = agentProviderLead.stageAndFurthestStage
  const stage = toSnakeCase(agentProviderLead.stage)
  const resendButtonIcon: IconPathKeys = resendIntroEmail.isSuccess ? 'Check' : 'EmailOutlined'

  return (
    <div className={cx(styles.wrapper, styles[stage])}>
      <div>{stageLabel}</div>
      <ActionBar className={styles.actionBar}>
        <AgentLeadDispositionButtonForm agentProviderLead={agentProviderLead} />
        {isEnabled('houseCanary') && (
          <Popover.Root
            isOpen={isOpenPopover}
            onOpenChange={setIsOpenPopover}
            placement="bottom-end"
          >
            <Popover.Trigger>
              <IconButton
                title="Menu"
                icon={isOpenPopover ? 'UpArrow' : 'DownArrow'}
                color="neutralLight"
              />
            </Popover.Trigger>
            <Popover.Content>
              <Button
                variant="link"
                color="neutralLight"
                size="xs"
                onClick={() => resendIntroEmail.mutate()}
                isDisabled={resendIntroEmail.isSuccess}
                isLoading={resendIntroEmail.isLoading}
                iconRight={resendButtonIcon}
              >
                Resend Intro Email
              </Button>
            </Popover.Content>
          </Popover.Root>
        )}
      </ActionBar>
    </div>
  )
}
