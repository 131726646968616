import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'
import { UseFetchData } from 'controllers/types'
import { ActivityFeedInterface } from 'models/v2/ActivityFeed'
import { ActivityFeedSchema } from 'schema'
import { fetchActivitiesFeed, TFetchItems } from 'services'
import { ActivitiesFeedParams } from 'services/ActivitityFeed/getSearchParams'
import { getNextPageParam } from 'utils'
import { communicationKeys } from '../../../commons/hooks'

type QueryOptions = UseInfiniteQueryOptions<
  TFetchItems<ActivityFeedInterface>,
  unknown,
  TFetchItems<ActivityFeedInterface>,
  TFetchItems<ActivityFeedInterface>,
  ReturnType<typeof communicationKeys.thisClientList>
>

const FETCH_COMMUNICATIONS_SHAPE: ActivityFeedSchema = {
  activityFeedItems: [
    'occurredAt',
    'title',
    'name',
    'description',
    { callLog: ['recordingUrl'] },
    'meta'
  ]
}

const defaultSearchSettings: ActivitiesFeedParams = {
  perPage: 20
}

export const useFetchInfiniteThisClientComms = (
  orderId: string,
  { searchParams, options }: UseFetchData<ActivitiesFeedParams, QueryOptions> = {}
) => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  return useInfiniteQuery(
    communicationKeys.thisClientList(orderId, searchSettings),
    ctx =>
      fetchActivitiesFeed(FETCH_COMMUNICATIONS_SHAPE, {
        ...searchSettings,
        orderId,
        page: ctx.pageParam
      }),
    {
      ...options,
      getNextPageParam: getNextPageParam,
      refetchOnWindowFocus: false,
      staleTime: 300000 // 5min
    }
  )
}
