/**
 * Each step was designed for at least one specific use case to compose colors when creating components.
 * Please, check the Foundation documentation to see how to compose the colors when creating new components.
 */

export const amber = {
  $5: '#dda605'
}

export const black = '#000000'

export const blue = {
  $1: '#E9F6FF',
  $2: '#D0ECFF',
  $3: '#B8E1FF',
  $4: '#70C4FF',
  $5: '#46B6FF',
  $6: '#2CA4F2',
  $7: '#1192E5',
  $8: '#0C6EBA',
  $9: '#1A5287',
  $10: '#273653'
}

export const cyan = {
  $5: '#3192ba'
}

export const gray = {
  $1: '#F9F9FB',
  $2: '#F5F6F9',
  $3: '#EEF0F6',
  $4: '#E6E9EF',
  $5: '#DBDFE6',
  $6: '#C5C8CD',
  $7: '#A7ABB3',
  $8: '#8E929C',
  $9: '#55585E',
  $10: '#363B44'
}

export const green = {
  $1: '#E2F9F1',
  $2: '#D4F2EA',
  $3: '#BCEBDB',
  $4: '#7DD9B9',
  $5: '#38BF8F',
  $6: '#31A67D',
  $7: '#239068',
  $8: '#1B6F4F',
  $9: '#165A3F',
  $10: '#124430'
}

export const lime = {
  $5: '#45bf38'
}

export const purple = {
  $5: '#8e42a4'
}

export const red = {
  $1: '#FEEBEA',
  $2: '#FDD7D5',
  $3: '#FBA29D',
  $4: '#F96D65',
  $5: '#F7382D',
  $6: '#E22D27',
  $7: '#CD2221',
  $8: '#B8161A',
  $9: '#A30B14',
  $10: '#8E000E'
}

export const yellow = {
  $1: '#FDF9E2',
  $2: '#FFF7CC',
  $3: '#FFF3B8',
  $4: '#FFE694',
  $5: '#FFDA6B',
  $6: '#FFCE47',
  $7: '#DB9A00',
  $8: '#996C00',
  $9: '#6B4700',
  $10: '#422E00'
}

export const white = '#FFFFFF'

export const ironGray = {
  $9: '#666666'
}
