import { CC_TRADE_IN_LEADS_CACHE_KEY, useQuery } from 'hooks'
import { fetchCCTradeInLeads, TCCTradeInLeadsSearchParams } from 'services'

const defaultSearchSettings: TCCTradeInLeadsSearchParams = {
  sortBy: 'Created',
  sortDir: 'desc',
  perPage: 25
}

export const useCCTradeInLeadsController = (searchParams: TCCTradeInLeadsSearchParams) => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  const ccTradeInLeadsQuery = useQuery([CC_TRADE_IN_LEADS_CACHE_KEY, searchSettings], () =>
    fetchCCTradeInLeads(searchSettings)
  )

  return { ccTradeInLeadsQuery }
}
