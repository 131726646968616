// @ts-strict

import {
  LEADS_CACHE_KEY,
  ORDER_CACHE_KEY,
  ORDERS_CACHE_KEY,
  useMutation,
  useQueryClient
} from 'hooks'
import { alertError } from 'services'
import { putCashOfferLead } from 'services/CashOfferLead/putCashOfferLead'

export const useCashOfferLeadController = (cashOfferLeadId: string) => {
  const queryCache = useQueryClient()

  const clearCaches = () => {
    queryCache.invalidateQueries(LEADS_CACHE_KEY)
    queryCache.invalidateQueries(ORDER_CACHE_KEY)
    queryCache.invalidateQueries(ORDERS_CACHE_KEY)
  }

  const updateCashOfferLead = useMutation(
    (payload: Record<string, string>) => putCashOfferLead(cashOfferLeadId, payload),
    { onSuccess: clearCaches, onError: () => alertError() }
  )

  return { updateCashOfferLead }
}
