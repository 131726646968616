import { TTaskStatusIcon, TTaskStatusValue } from '.'

export class TaskStatus {
  name!: string
  value!: TTaskStatusValue
  icon?: TTaskStatusIcon

  constructor(taskStatusAttributes: TaskStatus) {
    Object.assign(this, taskStatusAttributes)
  }
}
