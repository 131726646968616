import { css, styled } from '@foundation/themes/styles'
import type { Theming } from '@foundation/themes/styles'
import type { HeadingProps as Props } from './Heading'

type HeadingProps = Omit<Props, 'children'>
type StylesProps = { theme: Theming } & HeadingProps

const colorStyle = css<HeadingProps>`
  color: ${({ color, theme }) => theme.colors[color!]};
`

const sizeStyle = {
  lg: ({ theme }: StylesProps) => css`
    font-size: ${theme.fontSizes.lg};
    line-height: ${theme.lineHeights.lg};
  `,
  xl: ({ theme }: StylesProps) => css`
    font-size: ${theme.fontSizes.xl};
    line-height: ${theme.lineHeights.xl};
  `,
  xl2: ({ theme }: StylesProps) => css`
    font-size: ${theme.fontSizes.xl2};
    line-height: ${theme.lineHeights.xl2};
  `
}

const ellipsisStyle = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Heading = styled.h1<HeadingProps>`
  ${({ theme, hasEllipsis, size }) => css`
    font-weight: ${theme.fontWeights.bold};

    ${colorStyle};
    ${size && sizeStyle[size]};
    ${hasEllipsis && ellipsisStyle};
  `}
`
