import React, { ReactNode, useContext } from 'react'
import { Button, IButtonProps } from 'components/Button'
import { cx } from 'utils'
import styles from './Menu.module.scss'
import { MenuContext } from './MenuContext'

type Props = {
  children: ReactNode
  className?: string
  onClick?: () => void
}

export const MenuButton = ({
  className,
  onClick,
  children,
  ...props
}: Props & Omit<IButtonProps, 'onClick'>) => {
  const [, setMenuOpen] = useContext(MenuContext)

  const closeMenu = () => {
    if (onClick) {
      onClick()
    }
    setMenuOpen(false)
  }

  return (
    <div className={cx(styles.item, className)}>
      <Button as="menuButton" onClick={closeMenu} {...props}>
        {children}
      </Button>
    </div>
  )
}
