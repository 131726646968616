import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { List, Section } from 'components'
import { Employee } from 'models'
import { d, initializeString } from 'utils'
import styles from './EmployeeSettlementAgencyTeams.module.scss'

type Props = {
  employee: Employee
}

export const EmployeeSettlementAgencyTeams = ({ employee }: Props) => {
  const settlementAgencyteamMemberships = employee.teamMemberships?.filter(
    memberShip => memberShip.team.type === 'SettlementAgencyTeam'
  )

  if (!settlementAgencyteamMemberships.length) {
    return null
  }

  return (
    <Section title="Settlement Agency Teams">
      <List className={styles.teamsList}>
        {settlementAgencyteamMemberships.map(membership => (
          <Fragment key={membership.team.id}>
            <Link to={`/teams/${membership.team.id}`} className={styles.teamListLink}>
              {membership.team.name}
            </Link>
            <span>({initializeString(d(membership.role))})</span>
          </Fragment>
        ))}
      </List>
    </Section>
  )
}
