import { ActionBar } from 'components'
import { LenderDispositionButtonForm } from 'content/Lender'
import { Lender } from 'models'
import { cx, d, toSnakeCase } from 'utils'
import styles from './LenderDispositionHeader.module.scss'

type LenderDispositionHeaderProps = {
  lender: Lender
}

export const LenderDispositionHeader = ({ lender }: LenderDispositionHeaderProps) => {
  const stage = lender.providerLead?.stage || ''

  return (
    <div className={cx(styles.wrapper, styles[toSnakeCase(stage)])}>
      <div>{d(stage)}</div>
      <ActionBar className={styles.actionBar}>
        <LenderDispositionButtonForm lender={lender} />
      </ActionBar>
    </div>
  )
}
