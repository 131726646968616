// @ts-strict
import { Marker, Point } from 'classes'
import { Agent, CashOfferLeadStageUpdate, CashOfferProviderLead, MasterProperty } from 'models'
import { CashOfferLeadResponse } from 'schema'
import { formatPrice, normalizeDateString } from 'utils'

type TCashOfferLeadConstructor = Omit<CashOfferLead, 'toMarker' | 'toPoint'>

export class CashOfferLead {
  id!: string
  agentNotes?: string
  cashOfferFeeAmount?: string
  cashOfferFeeRate?: string
  cashOfferFeeMethod?: 'rate' | 'amount'
  cashOfferLeadStageUpdates?: CashOfferLeadStageUpdate[]
  contingentStatus?: string
  countryCode?: string
  depositRate?: string
  expressClose?: boolean
  incomingMasterProperty?: MasterProperty
  incomingPropertyBuyerAgent?: Agent
  incomingPropertyCloseOfPurchaseEscrowAt?: string
  incomingPropertyCloseOfSellEscrowAt?: string
  incomingPropertyFullAddress?: string // this is incomingMasterProperty.displayAddress
  incomingPropertyFundsWiredToHlAt?: string
  incomingPropertyFundsWiredToSellerAt?: string
  incomingPropertyInspectionContingencyWaived?: boolean
  incomingPropertyListPrice?: number
  incomingPropertyMaxOfferPrice?: string
  incomingPropertyOfferAcceptedAt?: string
  incomingPropertyOfferCancelledAt?: string
  incomingPropertyOfferCancelledReason?: string
  incomingPropertyOfferRejectedAt?: string
  incomingPropertyOfferRejectedReason?: string
  incomingPropertyOpenPurchaseEscrowAt?: string
  incomingPropertyOpenSellEscrowAt?: string
  incomingPropertySalePrice?: number
  incomingPropertyValuation?: number
  introSentAt?: string
  introduced?: boolean
  ipAdditionalDeposit?: string
  ipAffordabilityUpdatedAt?: string
  ipAvmValuation?: string
  ipAvmValuationAt?: string
  ipBorrowingBase?: string
  ipBuyersAgentCommission?: string
  ipBuyersAgentCommissionRate?: string
  ipCapitalDaysOutstanding?: string
  ipCashOfferDeposit?: string
  ipCashOfferFee?: string
  ipContingencyRemovalAt?: string
  ipEarnestMoney?: string
  ipFundingAmount?: string
  ipHlEquity?: string
  ipHomeownerAssociation?: boolean
  ipInsuranceCoverage?: string
  ipMaxOfferPriceAtHlValuation?: string
  ipMaxOfferPriceWithoutBuffer?: number
  ipMinAppraisal?: string
  ipNetSettlement?: string
  ipOfferTermsDaysTillCloseOfEscrow?: number
  ipOfferTermsDaysTillInspectionContingencyEnds?: number
  ipOfferTermsOptionTerminationFee?: string
  ipOfferTermsInspectionObjectionDeadlineDate?: string
  ipOfferTermsInspectionTerminationDeadlineDate?: string
  ipOfferTermsInspectionResolutionDeadlineDate?: string
  ipOfferTermsDeadlineDate?: string
  ipPropertyType?: string
  ipSellerCredits?: string
  ipSolarPanels?: string
  ipTargetCloseOfPurchaseEscrowAt?: string
  ipTargetCloseOfSellEscrowAt?: string
  isDeal?: boolean
  modelName? = 'CashOfferLead'
  number?: string
  phoneType?: string
  providerLead?: CashOfferProviderLead
  qualificationTalkedToClient?: boolean
  reasonForFail?: string
  updatedAt?: string
  propertyConditionApproved?: boolean

  constructor(cashOfferLeadAttributes: TCashOfferLeadConstructor) {
    Object.assign(this, cashOfferLeadAttributes)
  }

  toMarker(color?: string) {
    return this.providerLead?.lead?.latitude && this.providerLead.lead.longitude
      ? new Marker({
          name: `${formatPrice(this.providerLead.lead.price, 'short')} ${
            this.providerLead?.lead.fullAddress
          }`,
          description: this.providerLead.lead.name,
          type: 'lead',
          point: this.toPoint(),
          color
        })
      : undefined
  }

  toPoint() {
    return this.providerLead?.lead?.latitude && this.providerLead.lead.longitude
      ? new Point({
          latitude: this.providerLead?.lead.latitude,
          longitude: this.providerLead?.lead.longitude
        })
      : undefined
  }

  static create(cashOfferLeadResponse: Potential<CashOfferLeadResponse>): Potential<CashOfferLead> {
    if (!cashOfferLeadResponse) {
      return
    }
    const cashOfferLeadStageUpdates = (cashOfferLeadResponse?.cashOfferLeadStageUpdates?.map(
      CashOfferLeadStageUpdate.create
    ) || []) as CashOfferLeadStageUpdate[]
    const contingentStatus = cashOfferLeadResponse.contingent ? 'Contingent' : 'Non-Contingent'
    const incomingMasterProperty = MasterProperty.create(
      cashOfferLeadResponse?.incomingMasterProperty
    )
    const incomingPropertyBuyerAgent = Agent.create(
      cashOfferLeadResponse.incomingPropertyBuyerAgent
    )
    const incomingPropertyFullAddress = cashOfferLeadResponse.incomingMasterProperty?.displayAddress
    const providerLead = CashOfferProviderLead.create(cashOfferLeadResponse?.providerLead)

    const incomingPropertyCloseOfPurchaseEscrowAt = normalizeDateString(
      cashOfferLeadResponse.incomingPropertyCloseOfPurchaseEscrowAt
    )

    const incomingPropertyCloseOfSellEscrowAt = normalizeDateString(
      cashOfferLeadResponse.incomingPropertyCloseOfSellEscrowAt
    )

    const ipMaxOfferPriceWithoutBuffer = Number(cashOfferLeadResponse.ipMaxOfferPriceWithoutBuffer)

    return new CashOfferLead({
      ...cashOfferLeadResponse,
      cashOfferLeadStageUpdates,
      contingentStatus,
      incomingMasterProperty,
      incomingPropertyBuyerAgent,
      incomingPropertyFullAddress,
      incomingPropertyCloseOfPurchaseEscrowAt,
      incomingPropertyCloseOfSellEscrowAt,
      providerLead,
      ipMaxOfferPriceWithoutBuffer
    })
  }
}
