import { TaskCategory } from 'models'
import { getConstantsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchTaskCategories = async (): Promise<TaskCategory[]> => {
  const response = await getRequest(
    getConstantsUrl({
      searchParams: {
        types: 'task_categories'
      }
    })
  )

  return await deserializeAsync(response.data, { transform: TaskCategory.create })
}
