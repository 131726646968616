import { Agent } from 'models'
import { AgentMarketplacePrograms } from './AgentMarketplacePrograms'
import style from './AgentPrograms.module.scss'

type Props = {
  agent: Potential<Agent>
}

export const AgentPrograms = ({ agent }: Props) => {
  if (!agent) {
    return null
  }

  return (
    <div className={style.agentPrograms}>
      <AgentMarketplacePrograms agent={agent} />
    </div>
  )
}
