import { InvestorInterface } from '../Investor'
import { StateInterface } from '../State'
import * as utils from './utils'

export type AgentInterface = {
  firstName: string
  id: string
  investor?: InvestorInterface
  lastName: string
  officeDisplayName?: string
  stage: string
  state: StateInterface
  userType: string
}

export const AgentModel = {
  ...utils
}
