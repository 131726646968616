// @ts-strict
import { ReactNode } from 'react'
import { Icon } from 'components/Icon'
import { cx } from 'utils'
import styles from './Table.module.scss'

type SortTextProps = {
  children?: ReactNode
  dir?: 'asc' | 'desc'
  showIcon?: boolean
}

export const SortText = ({ dir = 'asc', children, showIcon }: SortTextProps) => {
  const SortIcon = dir === 'asc' ? Icon.UpLongArrow : Icon.DownLongArrow
  return (
    <div className={styles.sortText}>
      <div className={styles.content}>{children}</div>
      <SortIcon className={cx(styles.icon, showIcon && styles.visible)} />
    </div>
  )
}
