import { useSearchParams } from 'react-router-dom'
import { useTokenContext } from 'contexts'
import { useEffectOnce, useMutation } from 'hooks'
import { postSignout } from 'services'

export const useLogoutUser = () => {
  const { clearToken } = useTokenContext()
  const [searchParams] = useSearchParams()

  const onSuccess = () => {
    clearToken()
  }

  const signOut = useMutation(postSignout, {
    onSuccess
  })

  useEffectOnce(() => {
    if (searchParams.get('statusCode') === '401') {
      onSuccess()
    }
  })

  return {
    signOut
  }
}
