// @ts-strict
import type { UseMutationResult } from 'react-query'
import { useToastContext } from '@foundation/components'
import { useButtonForm } from 'components'
import type { Agent } from 'models'

type PutAgentPayload = any

type SelectedLocationsSettingButtonFormPayload = PutAgentPayload

export const useSelectedLocationsButtonForm = (
  mutation: UseMutationResult<any, unknown, PutAgentPayload, unknown>,
  agent: Agent
) => {
  const transformPayload = (
    payload: SelectedLocationsSettingButtonFormPayload
  ): SelectedLocationsSettingButtonFormPayload => {
    return {
      selectedZipCodes: payload.selectedZipCodes,
      excludedZipCodes: payload.excludedZipCodes
    }
  }

  const toast = useToastContext()
  const onSubmitSuccess = () => {
    toast({
      title: 'Historical Locations Updated',
      status: 'success'
    })
  }

  const { fieldsProps, formProps, setters } =
    useButtonForm<SelectedLocationsSettingButtonFormPayload>({
      initialPayload: {
        selectedZipCodes: agent.referralPreferences?.selectedZipCodes?.map(c => c.code) ?? [],
        excludedZipCodes: agent.referralPreferences?.excludedZipCodes?.map(c => c.code) ?? []
      },
      callback: onSubmitSuccess,
      mutation,
      transformPayload
    })

  return { fieldsProps, formProps, setters }
}
