// @ts-strict
import { TMarketplaceProgramSlug } from 'lookups'
import { TCashCloseProduct } from '..'

type TProductToMarketplaceProgramDictionary = Partial<
  Record<TCashCloseProduct, TMarketplaceProgramSlug>
>

const productToMarketplaceProgramDictionary: TProductToMarketplaceProgramDictionary = {
  trade_in_hlss: 'hlss'
}

export const mapProductSlugsToMarketplaceProgramServerSlug = (
  productSlugs: TCashCloseProduct[]
): TMarketplaceProgramSlug[] => {
  return productSlugs.map(
    productSlug =>
      productToMarketplaceProgramDictionary[productSlug] || (productSlug as TMarketplaceProgramSlug)
  )
}
