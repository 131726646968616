import { UsState } from 'models'
import { getConstantsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchUsStates = async (): Promise<UsState[]> => {
  const response = await getRequest(
    getConstantsUrl({
      searchParams: {
        types: 'states'
      }
    })
  )
  return await deserializeAsync(response.data, { transform: s => new UsState(s) })
}
