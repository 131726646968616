// @ts-strict
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetchInvestorPayPerLeadPaymentInformation, putInvestorMonthlyCap } from 'services'

type UpdateProviderPayPerLeadMonthlyCapProps = {
  monthlyCap: number
}

export const useInvestorPayPerLeadPaymentInformationController = (investorId: string) => {
  const queryCache = useQueryClient()

  const investorPayPerLeadPaymentInformationQuery = useQuery(
    ['investor-ppl-payment-information', investorId],
    () => fetchInvestorPayPerLeadPaymentInformation(investorId),
    { refetchOnWindowFocus: false }
  )

  const updateInvestorPayPerLeadMonthlyCap = useMutation(
    ({ monthlyCap }: UpdateProviderPayPerLeadMonthlyCapProps) =>
      putInvestorMonthlyCap(investorId, monthlyCap),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('provider-ppl-payment-information')
      }
    }
  )

  return { investorPayPerLeadPaymentInformationQuery, updateInvestorPayPerLeadMonthlyCap }
}
