// @ts-strict
import { LoanOfficer } from 'models/LoanOfficer'
import { LenderLeadResponse } from 'schema'

export class LenderLead {
  id?: string
  actualLoanAmount?: string
  actualPurchasePrice?: string
  appraisalExpectedDate?: string
  appraisalReceivedDate?: string
  archived?: boolean
  closeOfEscrowOn?: string
  coeDateChangeReason?: string
  comformingLoanLimit?: string
  conditionalApprovalDate?: string
  downpaymentAmount?: number
  dtiAtMax?: string
  dtiRatios?: string
  eaveLoanApplicationId?: number
  interestRate?: string
  lmTaskStage?: string
  loanAmount?: number
  loanDecision?: string
  loanMilestone?: string
  loanStatus?: string
  ltv?: string
  ltvAtMaxPurchasePriceAndLoanAmount?: string
  maxDownPayment?: string
  maxLoanAmount?: string
  maxPurchasePrice?: string
  pinnedNote?: string
  product?: string
  program?: string
  propertyCounty?: string
  targetCoeDate?: string
  term?: string
  loanApplicationLink?: string
  updatedAt?: string
  loanManagerName?: string
  loanOfficer?: LoanOfficer

  lender?: {
    slug?: string
  }

  constructor(lenderLeadAttributes: LenderLead) {
    Object.assign(this, lenderLeadAttributes)
  }

  static create(lenderLeadResponse: Potential<LenderLeadResponse>): Potential<LenderLead> {
    if (!lenderLeadResponse) {
      return
    }

    return new LenderLead(lenderLeadResponse)
  }
}
