import { forwardRef } from 'react'
import { Icon } from '../Icon'
import { Spinner } from '../Spinner'
import * as S from './styles'
import type { CommonProps } from './types'

export type IconButtonProps = CommonProps

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      variant = 'solid',
      color = 'primary',
      size = 'sm',
      icon,
      title,
      isDisabled,
      isLoading,
      onClick,
      isRounded
    },
    ref
  ) => {
    const shouldDisable = isDisabled || isLoading
    const renderIcon = isLoading ? (
      <Spinner data-testid="spinner" size={size} />
    ) : (
      <Icon data-testid="icon" path={icon} size={size} />
    )

    return (
      <S.Button
        ref={ref}
        color={color}
        title={title}
        size={size}
        disabled={shouldDisable}
        isDisabled={shouldDisable}
        onClick={onClick}
        variant={variant}
        isRounded={isRounded}
      >
        {renderIcon}
      </S.Button>
    )
  }
)

IconButton.displayName = 'IconButton'
