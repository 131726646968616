// @ts-strict
import { MouseEvent, useEffect, useState } from 'react'
import { UseQueryResult } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useKey } from 'react-use'
import { PhotoUpload } from 'models'
import { TFetchItems } from 'services/types'

type TProps = {
  photoUploadId: string
  photoUploadsQuery: UseQueryResult<TFetchItems<PhotoUpload>>
}

export const usePhotoUploadsCarousel = ({ photoUploadsQuery, photoUploadId }: TProps) => {
  const navigate = useNavigate()

  const { data, isLoading } = photoUploadsQuery

  const processedPhotoUploads = data?.items?.filter(photoUpload => !photoUpload.isProcessing) || []

  const currentPhotoIndex = processedPhotoUploads.findIndex(photo => photo.id === photoUploadId)

  const currentPhoto = processedPhotoUploads[currentPhotoIndex]

  const [isZoomedIn, setIsZoomedIn] = useState(false)

  const toggleZoomedIn = (e?: MouseEvent<HTMLImageElement>) => {
    setIsZoomedIn(!isZoomedIn)

    // state isn't updated yet; if we're not currently zoomed in (but will be),
    // scroll parent to the center so the zoom starts in the middle of the image
    if (!isZoomedIn) {
      if (!e) {
        return
      }

      const parent = (e.target as HTMLImageElement).parentElement!

      requestAnimationFrame(() => {
        const { clientWidth, clientHeight } = e.target as HTMLImageElement

        // center of the image
        parent.scrollTo(
          clientWidth / 2 - parent.clientWidth / 2,
          clientHeight / 2 - parent.clientHeight / 2
        )
      })
    }
  }

  useEffect(() => {
    setIsZoomedIn(false)
  }, [currentPhoto])

  useEffect(() => {
    if (!isLoading && !currentPhoto) {
      navigate('..')
    }
  }, [currentPhoto, navigate, isLoading])

  const getUploadedPhotoId = (direction: number) => {
    const index =
      (currentPhotoIndex + direction + processedPhotoUploads.length) % processedPhotoUploads.length
    return processedPhotoUploads[index]?.id
  }

  const handleCancel = () => navigate('..')

  const previousPhotoId = getUploadedPhotoId(-1)
  const nextPhotoId = getUploadedPhotoId(1)
  const handleNavigatePrevious = () => navigate(`../${previousPhotoId}`)
  const handleNavigateNext = () => navigate(`../${nextPhotoId}`)

  useKey(event => event.key === 'ArrowLeft', handleNavigatePrevious, { event: 'keydown' })
  useKey(event => event.key === 'ArrowRight', handleNavigateNext, { event: 'keydown' })

  return {
    isZoomedIn,
    toggleZoomedIn,
    currentPhoto,
    handleCancel,
    previousPhotoId,
    nextPhotoId
  }
}
