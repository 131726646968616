import { SearchConsole, SearchStatus, SearchSummary } from 'components'
import { useParamsContext } from 'contexts'
import { AgentLeadsListFilters } from './AgentLeadsListFilters'
import { AgentLeadsListSearch } from './AgentLeadsListSearch'

export const AgentLeadsListHeader = ({ totalCount, totalUrgentCount }) => {
  const { params, resetParams } = useParamsContext()
  const canClear = !!Object.keys(params).length
  const onClear = () => resetParams()

  return (
    <>
      <SearchSummary
        status={<SearchStatus count={totalCount} type="Referral" params={params} />}
        onClear={canClear ? onClear : null}
      />
      <SearchConsole
        search={<AgentLeadsListSearch />}
        actions={<AgentLeadsListFilters totalUrgentCount={totalUrgentCount} />}
      />
    </>
  )
}
