// @ts-strict

import { useButtonForm } from 'components'
import { useTeamContext } from 'contexts'
import { useTeamRoles } from 'lookups'
import { TransactionTeam } from 'models'
import { validatePresence } from 'utils'

type SubTeamAddEmployeePayload = {
  role: Potential<string>
  teamMembershipId: Potential<string>
  userId: Potential<string>
  userName: Potential<string>
}

export const useSubTeamAddEmployeeButtonForm = (team: TransactionTeam) => {
  const { addSubTeamMember } = useTeamContext()
  const { teamRoles } = useTeamRoles(team.type)
  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm<SubTeamAddEmployeePayload>({
    initialPayload: {
      userId: undefined,
      userName: undefined,
      role: undefined,
      teamMembershipId: undefined
    },
    mutation: addSubTeamMember,
    formValidations: {
      userId: validatePresence,
      role: validatePresence,
      teamMembershipId: validatePresence
    },
    transformPayload: ({ userId, role, teamMembershipId }) => ({ userId, role, teamMembershipId })
  })

  const isSettlementAgencyTeam = team.type === 'SettlementAgencyTeam'

  const teamRolesOptions = teamRoles.map(option => ({
    disabled: isSettlementAgencyTeam ? false : team.roleExists(option.value),
    ...option
  }))

  return { formProps, teamRolesOptions, onChangeAttribute, payload }
}
