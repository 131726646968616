import { leadStageAttributes, TLeadStageSetting, TLeadStageSlug } from '../lookupLeadStages'

export type TCashOfferProviderLeadStagesSlug = Extract<
  TLeadStageSlug,
  | 'property_pending'
  | 'property_submitted'
  | 'property_approved'
  | 'property_rejected'
  | 'property_cancelled'
  | 'agreement_signed'
  | 'offer_made'
  | 'offer_accepted'
  | 'offer_rejected'
  | 'offer_cancelled'
  | 'in_escrow_purchase'
  | 'closed_purchase'
  | 'in_escrow_sell'
  | 'closed_sell'
  | 'closed_paid'
>

export type TCashOfferProviderLeadStagesSetting =
  TLeadStageSetting<TCashOfferProviderLeadStagesSlug>

export const cashOfferProviderLeadStagesSettings: Array<TCashOfferProviderLeadStagesSetting> = [
  leadStageAttributes.propertyPending,
  leadStageAttributes.propertySubmitted,
  leadStageAttributes.propertyApproved,
  leadStageAttributes.propertyRejected,
  leadStageAttributes.propertyCancelled,
  leadStageAttributes.agreementSigned,
  leadStageAttributes.offerMade,
  leadStageAttributes.offerAccepted,
  leadStageAttributes.offerRejected,
  leadStageAttributes.offerCancelled,
  leadStageAttributes.inEscrowPurchase,
  leadStageAttributes.closedPurchase,
  leadStageAttributes.inEscrowSell,
  leadStageAttributes.closedSell,
  leadStageAttributes.closedPaid
]
