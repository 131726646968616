import { useLookupsController } from 'controllers/Lookups'
import { getOptions } from '../utils'
import { ChangeReason } from '.'

export const useChangeReasons = () => {
  const [ChangeReasonsData] = useLookupsController('coe_date_change_reason')
  const [expressCloseChangeReasonsData] = useLookupsController('express_close_change_reason')

  const coeDateChangeReasons = ChangeReasonsData.map(attrs => new ChangeReason(attrs))
  const expressCloseChangeReasons = expressCloseChangeReasonsData.map(
    attrs => new ChangeReason(attrs)
  )

  const { options: coeDateChangeReasonOptions } = getOptions(coeDateChangeReasons as ChangeReason[])
  const { options: expressCloseChangeReasonsOptions } = getOptions(
    expressCloseChangeReasons as ChangeReason[]
  )

  return {
    coeDateChangeReasons,
    coeDateChangeReasonOptions,
    expressCloseChangeReasons,
    expressCloseChangeReasonsOptions
  }
}
