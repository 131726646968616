import { Section, Sections } from 'components'
import { FieldFormField } from '../../FieldFormField'
import { useFields } from '../../useHLSSLeadDisposition'

export const HLSSInEscrowSell = () => {
  useFields({
    required: [
      'departingPropertyOfferCount',
      'departingPropertyActualSalePrice',
      'departingPropertyOpenedSaleEscrowDate',
      'dpTargetSellCoeDate'
    ],
    fields: ['departingPropertyOfferAcceptedDate']
  })
  return (
    <Sections title="DR Sale Transaction">
      <Section>
        <FieldFormField field="departingPropertyOfferAcceptedDate" />
        <FieldFormField field="departingPropertyActualSalePrice" />
        <FieldFormField field="dpTargetSellCoeDate" />
      </Section>
      <Section>
        <FieldFormField field="departingPropertyOfferCount" />
        <FieldFormField field="departingPropertyOpenedSaleEscrowDate" />
      </Section>
    </Sections>
  )
}
