import { Deserializer } from '@homelight/js-jsonapi-serializer'
import { convertAllNullToUndefined } from '../convertAllNullToUndefined'

type Props = {
  [key: string]: any
  transform?: (val: any) => void
}

export const deserializeAsync = async <T = any>(
  data: any,
  { transform = val => val, ...relationships }: Props = {}
): Promise<T> => {
  const deserializer = new Deserializer({
    keyForAttribute: 'camelCase',
    transform: (val: any) => transform(convertAllNullToUndefined(val)),
    ...relationships
  })
  delete data.links
  return await deserializer.deserialize(data)
}
