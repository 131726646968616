import { getAgentTeamMembershipsUrl } from 'services/getUrl'
import { putRequest } from 'utils'

type Args = {
  active?: boolean
  isFallbackAgent?: boolean
  leadRoutingWarmTransferOptIn?: boolean
  prefersBuyer?: boolean
  prefersSeller?: boolean
}

export const putAgentTeamMemberReferralPreferences = (
  id: string,
  { active, isFallbackAgent, leadRoutingWarmTransferOptIn, prefersBuyer, prefersSeller }: Args
): Promise<any> => {
  return putRequest(getAgentTeamMembershipsUrl({ id, action: 'preferences' }), {
    active,
    is_fallback_agent: isFallbackAgent,
    lead_routing_warm_transfer_opt_in: leadRoutingWarmTransferOptIn,
    prefers_buyer: prefersBuyer,
    prefers_seller: prefersSeller
  })
}
