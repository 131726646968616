import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './PageFooter.module.scss'

type TProps = {
  children?: ReactNode
  className?: string
}

export const PageFooter = ({ className, children }: TProps) => {
  return <div className={cx(styles.footer, className)}>{children}</div>
}
