// @ts-strict

import { ReactNode } from 'react'
import { useLeadGroupablesController } from 'controllers'
import { LeadGroupableType } from 'models'
import { createCtx } from '../utils'

type LeadGroupablesContext = ReturnType<typeof useLeadGroupablesController> & {
  leadGroupableType: LeadGroupableType
}

export const [useLeadGroupablesContext, LeadGroupablesContextProvider] =
  createCtx<LeadGroupablesContext>()

type Props = {
  children: ReactNode
  leadGroupableType: LeadGroupableType
}

export const LeadGroupablesProvider = ({ children, leadGroupableType }: Props) => {
  const value = { ...useLeadGroupablesController(leadGroupableType), leadGroupableType }
  return <LeadGroupablesContextProvider value={value}>{children}</LeadGroupablesContextProvider>
}
