import { FormField, RadioButtons, Section, TextInput } from 'components'
import { useCurrentUserContext } from 'contexts'
import styles from '../EditEscrowOrderButtonForm.module.scss'
import { TFormProps } from '../types'

export const Global = ({ lead, payload, onChangeAttribute }: TFormProps) => {
  const { currentUser } = useCurrentUserContext()

  const stageOptions = [
    {
      name: 'Pending Failure',
      value: 'pending_failed',
      disabled: lead.stage === 'pending_failed'
    },
    currentUser?.roles?.superUser
      ? {
          name: 'Failed',
          value: 'failed',
          disabled: lead.stage === 'failed'
        }
      : null
  ].filter(Boolean)

  return (
    <>
      <Section title="Update lead stage">
        <FormField>
          <RadioButtons
            className={styles.radioButtons}
            options={stageOptions}
            value={payload.newStage}
            onChange={val => onChangeAttribute('newStage', val)}
          />
        </FormField>
      </Section>
      {currentUser?.roles?.superUser && (
        <Section title="Qualia">
          <FormField
            title="Order identifier"
            note="To update through the API, enter the order identifier:"
          >
            <TextInput
              value={payload.orderIdentifier}
              onChange={val => onChangeAttribute('orderIdentifier', val)}
            />
          </FormField>
        </Section>
      )}
    </>
  )
}
