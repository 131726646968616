import React, { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './SectionHeader.module.scss'

type Props = {
  borderless?: boolean
  children: ReactNode
  className?: string
}

export const SectionHeader = ({ className, children, borderless }: Props) => {
  const borderlessClassName = borderless && styles.borderless

  return (
    <header
      data-testid="sectionHeader"
      className={cx('sectionHeader', styles.header, borderlessClassName, className)}
    >
      {children}
    </header>
  )
}
