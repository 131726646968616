// @ts-strict
import { Link } from 'react-router-dom'
import { Icon } from '@foundation/components'
import type { TableCellProps } from 'components'
import { Block, List, TableCell as Td } from 'components'
import { AgentLinkWithMenuCard } from 'content/Agent/AgentDetailsMenuCard'
import { AgentProviderLeadServicesOpportunitiesSelector } from 'content/AgentProviderLead'
import { LeadMenuCard } from 'content/Lead'
import { LeadBadge } from 'content/Lead/LeadBadge'
import { TaskMenuCard } from 'content/Task'
import {
  BUYER_LEADS_CACHE_KEY,
  CC_CASH_OFFER_LEADS_CACHE_KEY,
  CC_TRADE_IN_LEADS_CACHE_KEY,
  SELLER_LEADS_CACHE_KEY,
  useQueryClient
} from 'hooks'
import { getLeadStageNameFromSlug } from 'lookups'
import { Agent, AgentMatchingLead, Lead } from 'models'
import {
  byAttr,
  cx,
  dataOrDash,
  dateDiffInDays,
  DEFAULT_STRING,
  formatDate,
  formatMoney,
  formatTime,
  structureAddress,
  titleize,
  toCamelCase
} from 'utils'
import styles from './LeadsTableCells.module.scss'
import { getEscrowAttachedToOrder } from './utils'

type TProps<T = Lead> = TableCellProps & {
  column?: string
  lead: T
  testId?: string
}

const leadTypeCacheKeys = {
  cc_trade_in: CC_TRADE_IN_LEADS_CACHE_KEY,
  cc_cash_offer: CC_CASH_OFFER_LEADS_CACHE_KEY,
  seller: SELLER_LEADS_CACHE_KEY,
  buyer: BUYER_LEADS_CACHE_KEY
}
type TLeadTypeCacheKeys = keyof typeof leadTypeCacheKeys

export const LeadNamePartnerCell = ({ lead, testId }: TProps) => {
  const leadName = !!lead?.name?.trim() ? titleize(lead.name) : lead.email || DEFAULT_STRING

  return (
    <Td fixed data-testid={testId}>
      <Block
        title={<Link to={lead.getPathname()}>{leadName}</Link>}
        subtitle={titleize(lead.partnerName)}
        image={<LeadBadge lead={lead} />}
      />
    </Td>
  )
}

export const LeadAddressCell = ({ lead, testId }: TProps) => {
  const { street, cityState } = structureAddress(lead.fullAddress)

  return (
    <Td data-testid={testId}>
      <List>
        {street}
        {dataOrDash(cityState)}
      </List>
    </Td>
  )
}

export const LeadOtherLeadsCell = ({ lead, testId }: TProps) => (
  <Td data-testid={testId}>
    {dataOrDash(
      lead.otherLeads?.map(otherLead => <LeadMenuCard key={otherLead.id} lead={otherLead} />)
    )}
  </Td>
)

export const LeadStageFurthestCell = ({ lead, testId }: TProps) => {
  const normalizeStage = getLeadStageNameFromSlug(lead.stage!)
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={normalizeStage} subtitle={titleize(lead.furthestStage)} />
    </Td>
  )
}

export const LeadCreatedUpdatedCell = ({ lead, testId }: TProps) => (
  <Td data-testid={testId}>
    <Block title={formatDate(lead.createdAt)} subtitle={formatDate(lead.lastStageUpdate)} />
  </Td>
)

export const LeadLastStageUpdateCell = ({ lead, testId }: TProps) => (
  <Td data-testid={testId}>
    <Block title={formatDate(lead.lastStageUpdate)} />
  </Td>
)

export const LeadSourceCell = ({ lead, testId }: TProps) => (
  <Td data-testid={testId}>
    <Block allowTitleOverflow title={dataOrDash(titleize(lead.order?.source))} />
  </Td>
)

export const LeadPropertyTypeCell = ({ lead, testId }: TProps) => (
  <Td data-testid={testId}>
    <Block allowTitleOverflow title={dataOrDash(titleize(lead.propertyType))} />
  </Td>
)

export const LeadDaysSinceLastStageUpdateCell = ({ lead, testId }: TProps) => {
  const { lastStageUpdate = '' } = lead
  const today = new Date()
  const diffInDays = dateDiffInDays(today, lastStageUpdate)
  const daysSinceLastStageUpdate = diffInDays > 0 ? diffInDays : ''

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(daysSinceLastStageUpdate)} />
    </Td>
  )
}

export const LeadPriceCell = ({ lead, testId }: TProps) => (
  <Td data-testid={testId}>
    <Block allowTitleOverflow title={dataOrDash(formatMoney(lead.price))} />
  </Td>
)

export const LeadIntroSentAtCell = ({ lead, testId }: TProps) => (
  <Td data-testid={testId}>
    <Block title={formatDate(lead.introSentAt, 'short')} subtitle={formatTime(lead.introSentAt)} />
  </Td>
)
export const LeadAgentsOppsCell = ({ lead, testId }: TProps) => {
  const queryCache = useQueryClient()
  const agentProviderLeads = (lead as AgentMatchingLead).agentProviderLeads
    ?.filter(({ agentLead }) => agentLead?.introduced && agentLead.agent?.firstName)
    .sort(
      (a, b) =>
        Number(b.stage === lead.stage) -
        Number(a.stage === lead.stage) -
        (Number(b.stage === 'failed') - Number(a.stage === 'failed'))
    )

  return (
    <Td data-testid={testId}>
      {!!agentProviderLeads?.length &&
        agentProviderLeads?.map(apl => {
          const agent = apl.agentLead!.agent!
          const isFailed = apl.stage === 'failed'
          const key = `${agent.id}_${apl.id}`
          const path = `/agents/${agent.id}`

          return (
            <Block
              key={key}
              title={
                <Link to={path} className={cx(isFailed && styles.failed)} title={agent.fullName}>
                  {agent.fullName}
                </Link>
              }
              actions={
                <AgentProviderLeadServicesOpportunitiesSelector
                  agentProviderLead={apl}
                  onChange={() => {
                    queryCache.invalidateQueries(leadTypeCacheKeys[lead.userType!])
                  }}
                />
              }
            />
          )
        })}
    </Td>
  )
}

const AgentCell = ({ agent, testId, lead, column, ...rest }: TProps & { agent?: Agent }) => {
  return (
    <Td data-testid={testId} {...rest}>
      <Block
        title={dataOrDash(agent && <AgentLinkWithMenuCard agent={agent} />)}
        allowTitleOverflow
        subtitle={agent?.agentTeamName}
      />
    </Td>
  )
}
export const LeadAgentCell = (props: TProps) => {
  const { lead, column = '' } = props
  const agent: Potential<Agent> = lead[column]
  return <AgentCell agent={agent} {...props} />
}
export const LeadProvidableAgentCell = (props: TProps) => {
  const { lead, column = '' } = props
  const agent: Potential<Agent> = lead[lead.providableName ?? '']?.[column]
  return <AgentCell agent={agent} {...props} />
}
export const LeadNeedsListingReviewCell = ({
  lead,
  testId,
  column,
  ...rest
}: TProps<AgentMatchingLead>) => (
  <Td data-testid={testId} {...rest}>
    {dataOrDash(lead?.needsListingReview && <Icon path="Canary" size="lg" />)}
  </Td>
)

export const LeadTaskCell = ({ lead, testId, column, ...rest }: TProps) => {
  const matchingTask = lead.tasks?.find(task => toCamelCase(task.templateSlug) === column)

  const queryCache = useQueryClient()

  const defaultLeadType: TLeadTypeCacheKeys = 'cc_trade_in'

  if (matchingTask) {
    matchingTask!.attachable = lead
  }

  return (
    <Td data-testid={testId} {...rest}>
      <TaskMenuCard
        task={matchingTask}
        onSuccessCallback={() => {
          queryCache.invalidateQueries(leadTypeCacheKeys[lead.userType || defaultLeadType])
        }}
      />
    </Td>
  )
}

export const LeadPurchaseEscrowStatusCell = ({ lead, testId }: TProps) => {
  const escrowBuyer = getEscrowAttachedToOrder(lead, 'buyer')
  const escrowOfficeLead = escrowBuyer?.escrowOfficeProviderLeads![0].escrowOfficeLead

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(titleize(escrowOfficeLead?.orderStatus))} />
    </Td>
  )
}

export const LeadPurchaseEscrowOrderOpenedDateCell = ({ lead, testId }: TProps) => {
  const escrowBuyer = getEscrowAttachedToOrder(lead, 'buyer')
  const escrowOfficeLead = escrowBuyer?.escrowOfficeProviderLeads![0].escrowOfficeLead

  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(escrowOfficeLead?.orderOpenAt))} />
    </Td>
  )
}

export const LeadPurchaseEscrowExpectedCloseDateCell = ({ lead, testId }: TProps) => {
  const escrowBuyer = getEscrowAttachedToOrder(lead, 'buyer')
  const escrowOfficeLead = escrowBuyer?.escrowOfficeProviderLeads![0].escrowOfficeLead

  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(escrowOfficeLead?.orderClosingOn))} />
    </Td>
  )
}

export const LeadPurchaseEscrowNetEquityCell = ({ lead, testId }: TProps) => {
  const escrowBuyer = getEscrowAttachedToOrder(lead, 'buyer')
  const escrowOfficeLead = escrowBuyer?.escrowOfficeProviderLeads![0].escrowOfficeLead

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(formatMoney(escrowOfficeLead?.netEquity))} />
    </Td>
  )
}

export const LeadPurchaseEscrowOfficerCell = ({ lead, testId }: TProps) => {
  const escrowBuyer = getEscrowAttachedToOrder(lead, 'buyer')
  const escrowOfficeLead = escrowBuyer?.escrowOfficeProviderLeads![0].escrowOfficeLead
  const escrowOrderSettlementTeamMember = escrowOfficeLead?.escrowOrderSettlementTeamMembers?.find(
    item => toCamelCase(item?.role) === toCamelCase('Escrow Officer')
  )
  const settlementTeamMember = escrowOrderSettlementTeamMember?.settlementTeamMember

  return (
    <Td data-testid={testId}>
      <Block
        title={dataOrDash(settlementTeamMember?.getFullName())}
        subtitle={settlementTeamMember?.email}
      />
    </Td>
  )
}

export const LeadPurchaseEscrowAssistantCell = ({ lead, testId }: TProps) => {
  const escrowBuyer = getEscrowAttachedToOrder(lead, 'buyer')
  const escrowOfficeLead = escrowBuyer?.escrowOfficeProviderLeads![0].escrowOfficeLead

  const escrowOrderSettlementTeamMember = escrowOfficeLead?.escrowOrderSettlementTeamMembers?.find(
    item => toCamelCase(item?.role) === toCamelCase('Assistant')
  )
  const settlementTeamMember = escrowOrderSettlementTeamMember?.settlementTeamMember

  return (
    <Td data-testid={testId}>
      <Block
        title={dataOrDash(settlementTeamMember?.getFullName())}
        subtitle={settlementTeamMember?.email}
      />
    </Td>
  )
}

export const LeadPurchaseEscrowTitleOfficerCell = ({ lead, testId }: TProps) => {
  const escrowBuyer = getEscrowAttachedToOrder(lead, 'buyer')
  const escrowOfficeLead = escrowBuyer?.escrowOfficeProviderLeads![0].escrowOfficeLead
  const escrowOrderSettlementTeamMember = escrowOfficeLead?.escrowOrderSettlementTeamMembers?.find(
    item => toCamelCase(item?.role) === toCamelCase('Title Officer')
  )
  const settlementTeamMember = escrowOrderSettlementTeamMember?.settlementTeamMember

  return (
    <Td data-testid={testId}>
      <Block
        title={dataOrDash(settlementTeamMember?.getFullName())}
        subtitle={settlementTeamMember?.email}
      />
    </Td>
  )
}

export const LeadSaleEscrowStatusCell = ({ lead, testId }: TProps) => {
  const escrowSeller = getEscrowAttachedToOrder(lead, 'seller')
  const escrowOfficeLead = escrowSeller?.escrowOfficeProviderLeads![0].escrowOfficeLead

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(titleize(escrowOfficeLead?.orderStatus))} />
    </Td>
  )
}

export const LeadSaleEscrowOrderOpenedDateCell = ({ lead, testId }: TProps) => {
  const escrowSeller = getEscrowAttachedToOrder(lead, 'seller')
  const escrowOfficeLead = escrowSeller?.escrowOfficeProviderLeads![0].escrowOfficeLead

  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(escrowOfficeLead?.orderOpenAt))} />
    </Td>
  )
}

export const LeadSaleEscrowExpectedCloseDateCell = ({ lead, testId }: TProps) => {
  const escrowSeller = getEscrowAttachedToOrder(lead, 'seller')
  const escrowOfficeLead = escrowSeller?.escrowOfficeProviderLeads![0].escrowOfficeLead

  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(escrowOfficeLead?.orderClosingOn))} />
    </Td>
  )
}

export const LeadSaleEscrowOfficerCell = ({ lead, testId }: TProps) => {
  const escrowSeller = getEscrowAttachedToOrder(lead, 'seller')
  const escrowOfficeLead = escrowSeller?.escrowOfficeProviderLeads![0].escrowOfficeLead
  const escrowOrderSettlementTeamMember = escrowOfficeLead?.escrowOrderSettlementTeamMembers?.find(
    item => toCamelCase(item?.role) === toCamelCase('Escrow Officer')
  )
  const settlementTeamMember = escrowOrderSettlementTeamMember?.settlementTeamMember

  return (
    <Td data-testid={testId}>
      <Block
        title={dataOrDash(settlementTeamMember?.getFullName())}
        subtitle={settlementTeamMember?.email}
      />
    </Td>
  )
}

export const LeadSaleEscrowAssistantCell = ({ lead, testId }: TProps) => {
  const escrowSeller = getEscrowAttachedToOrder(lead, 'seller')
  const escrowOfficeLead = escrowSeller?.escrowOfficeProviderLeads![0].escrowOfficeLead

  const escrowOrderSettlementTeamMember = escrowOfficeLead?.escrowOrderSettlementTeamMembers?.find(
    item => toCamelCase(item?.role) === toCamelCase('Assistant')
  )
  const settlementTeamMember = escrowOrderSettlementTeamMember?.settlementTeamMember

  return (
    <Td data-testid={testId}>
      <Block
        title={dataOrDash(settlementTeamMember?.getFullName())}
        subtitle={settlementTeamMember?.email}
      />
    </Td>
  )
}

export const LeadSaleEscrowTitleOfficerCell = ({ lead, testId }: TProps) => {
  const escrowSeller = getEscrowAttachedToOrder(lead, 'seller')
  const escrowOfficeLead = escrowSeller?.escrowOfficeProviderLeads![0].escrowOfficeLead
  const escrowOrderSettlementTeamMember = escrowOfficeLead?.escrowOrderSettlementTeamMembers?.find(
    item => toCamelCase(item?.role) === toCamelCase('Title Officer')
  )
  const settlementTeamMember = escrowOrderSettlementTeamMember?.settlementTeamMember

  return (
    <Td data-testid={testId}>
      <Block
        title={dataOrDash(settlementTeamMember?.getFullName())}
        subtitle={settlementTeamMember?.email}
      />
    </Td>
  )
}

export const LeadClientAdvisorCell = ({ lead, testId }: TProps) => {
  const leadUser = lead.leadUsers?.find(byAttr('role', 'client_advisor'))
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(leadUser?.user?.name)} />
    </Td>
  )
}

export const LeadClientAdvisorAssistantCell = ({ lead, testId }: TProps) => {
  const leadUser = lead.leadUsers?.find(byAttr('role', 'client_advisor_assistant'))
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(leadUser?.user?.name)} />
    </Td>
  )
}
