import { useRef } from 'react'
import ReactCalendar, { CalendarProps } from 'react-calendar'
import { cx } from 'utils'
import styles from './Calendar.module.scss'

export type TCalendarProps = {
  className?: string
  onChange: (val: Date) => void
  value: Date
  yearOnly?: boolean
} & Omit<CalendarProps, 'onChange' | 'value' | 'className'>

export const Calendar = ({ value, onChange, className, yearOnly, ...props }: TCalendarProps) => {
  const ref = useRef<HTMLInputElement>()

  const onClickYear = val => {
    if (!yearOnly) {
      return
    }

    onChange(new Date(val))

    ref.current.blur()
  }

  return (
    <ReactCalendar
      onClickYear={onClickYear}
      onChange={onChange}
      inputRef={ref}
      value={value}
      className={cx(className, styles.date)}
      {...props}
    />
  )
}
