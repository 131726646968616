import { sortByAgentLeadStageAttr } from 'lookups'
import { AgentProviderLead } from 'models'
import { ProviderLeadResponse } from 'schema'

const sortByAgentLeadRank = (a: AgentProviderLead, b: AgentProviderLead) =>
  a?.agentLead?.rank - b?.agentLead?.rank

export const getAgentProviderLeads = (agentProviderLeads?: Potential<ProviderLeadResponse[]>) => {
  return (agentProviderLeads || [])
    .map(AgentProviderLead.create)
    .filter(Boolean)
    .map(apl => new AgentProviderLead(apl))
    .sort((a, b) => sortByAgentLeadStageAttr(a, b) || sortByAgentLeadRank(a, b))
}
