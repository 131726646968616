import { Section, Sections } from 'components'
import { FieldFormField } from '../../FieldFormField'
import { useFields } from '../../useHLSSLeadDisposition'

export const HLSSApplicationComplete = () => {
  useFields({
    fields: ['dpAgentAfterRepairValue'],
    required: [
      'dpPropertyType',
      'departingPropertyHomeownerAssociation',
      'departingPropertySolarPanels',
      'departingPropertyAgentValuationReceivedDate',
      'agentValuation'
    ]
  })
  return (
    <>
      <Sections title="DR Property Information">
        <Section>
          <FieldFormField field="dpPropertyType" />
        </Section>
        <Section>
          <FieldFormField field="departingPropertyHomeownerAssociation" />
        </Section>
        <Section>
          <FieldFormField field="departingPropertySolarPanels" />
        </Section>
      </Sections>
      <Sections title="DR Property Valuation">
        <Section>
          <FieldFormField field="departingPropertyAgentValuationReceivedDate" />
        </Section>
        <Section>
          <FieldFormField field="agentValuation" />
        </Section>
        <Section>
          <FieldFormField field="dpAgentAfterRepairValue" />
        </Section>
      </Sections>
    </>
  )
}
