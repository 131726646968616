import { ReactNode } from 'react'
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import { Box, Heading, IconButton, OVERLAY_ELEMENT_ID } from '@foundation/components'
import { HeadingColors } from '../Heading/types'
import { useDialogContext } from './Dialog'
import * as S from './styles'

type DialogHeaderProps = {
  /**
   * The title to display
   */
  children: string
  /**
   * The color of the heading text
   */
  color?: HeadingColors
}

export const DialogHeader = ({ children, color }: DialogHeaderProps) => {
  const { setOpen } = useDialogContext()

  return (
    <>
      <S.Toolbar>
        <IconButton
          title="Close dialog"
          icon="Close"
          size="sm"
          variant="ghost"
          onClick={() => setOpen(false)}
        />
      </S.Toolbar>
      <Box paddingLeft="$7" paddingRight="$7">
        <Heading color={color}>{children}</Heading>
      </Box>
    </>
  )
}

type DialogBodyProps = {
  /**
   * The content to display
   */
  children: ReactNode
}

export const DialogBody = ({ children }: DialogBodyProps) => {
  return <S.Body>{children}</S.Body>
}

type DialogFooterProps = {
  /**
   * The content to display
   */
  children: ReactNode
}

export const DialogFooter = ({ children }: DialogFooterProps) => {
  return <S.Footer>{children}</S.Footer>
}

type DialogContentProps = {
  /**
   * The content to display inside the dialog area
   */
  children: ReactNode
}

export const DialogContent = ({ children }: DialogContentProps) => {
  const { context, floatingRef, getFloatingProps, isOpen } = useDialogContext()

  return (
    <FloatingPortal id="dialog-portal">
      {isOpen && (
        <S.Overlay id={OVERLAY_ELEMENT_ID} lockScroll data-testid="dialogOverlay">
          <FloatingFocusManager context={context}>
            <S.Content ref={floatingRef} {...getFloatingProps()}>
              <S.InnerContent>{children}</S.InnerContent>
            </S.Content>
          </FloatingFocusManager>
        </S.Overlay>
      )}
    </FloatingPortal>
  )
}
