import { orderColumns } from './ordersColumnsSettings'

export const homeLoansOrdersColumnsSettings = [
  orderColumns.clientName,
  orderColumns.property,
  orderColumns.lead,
  orderColumns.stage,
  orderColumns.createdAt,
  orderColumns.updatedAt
] as const

export type THomeLoansOrdersColumnValue = (typeof homeLoansOrdersColumnsSettings)[number]['value']
