import { getAgentsUrl } from 'services/getUrl'
import { patchRequest, postRequest, snakeCaseKeys } from 'utils'

export const patchAgentPayPerLeadSettings = async (agentId: string, payload: any) => {
  const url = getAgentsUrl({ id: agentId, action: 'ppl-settings' })
  await patchRequest(url, snakeCaseKeys({ ...payload }))
}

export const postAgentPayPerLeadSettings = async (agentId: string, payload: any) => {
  const url = getAgentsUrl({ id: agentId, action: 'ppl-settings' })
  await postRequest(url, snakeCaseKeys({ ...payload }))
}
