import { Lead } from 'models'
import { getHapiRequestParams, LeadSchema } from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchLeadTeam = async (id: string): Promise<Lead> => {
  const leadResponseShape: LeadSchema = {
    lead: [{ leadUsers: ['role', 'createdAt', { user: ['name'] }] }]
  }

  const searchParams = getHapiRequestParams(leadResponseShape)
  const url = getLeadsUrl({ id, searchParams })
  const response = await getRequest(url)
  const lead = await deserializeAsync(response.data, {
    transform: Lead.create
  })
  return lead
}
