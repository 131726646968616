import { useCallback, useEffect, useState } from 'react'
import { intervalToDuration } from 'date-fns'
import { pluralize } from 'utils'

type Props = {
  live?: boolean
  since: Date
}

export const Duration = ({ since, live }: Props) => {
  const [duration, setDuration] = useState<string>()

  const getDurationString = duration => {
    if (duration.days) {
      return `${pluralize(duration.days, 'day')} ${duration.hours}:${String(
        duration.minutes
      ).padStart(2, '0')}:${String(duration.seconds).padStart(2, '0')}`
    }
    if (duration.hours) {
      return `${duration.hours}:${String(duration.minutes).padStart(2, '0')}:${String(
        duration.seconds
      ).padStart(2, '0')}`
    }
    return `${duration.minutes}:${String(duration.seconds).padStart(2, '0')}`
  }

  const sinceMilliseconds = since?.getTime()

  const updateDuration = useCallback(() => {
    if (sinceMilliseconds) {
      const nowDate = new Date()
      const seconds = nowDate.getTime() - sinceMilliseconds
      const duration = intervalToDuration({ start: 0, end: seconds })
      setDuration(getDurationString(duration))
    }
  }, [sinceMilliseconds])

  useEffect(() => {
    updateDuration()
    if (live) {
      const intervalId = setInterval(updateDuration, 1000)
      return () => clearInterval(intervalId)
    }
  }, [updateDuration, setDuration, live])

  if (!since) {
    return null
  }

  return <span>{duration}</span>
}
