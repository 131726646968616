// @ts-strict
import { ReactNode } from 'react'
import { useInvestorController } from 'controllers'
import { createCtx } from '../utils'

export const [useInvestorContext, InvestorContextProvider] =
  createCtx<ReturnType<typeof useInvestorController>>()

export const InvestorProvider = ({
  children,
  investorId
}: {
  children: ReactNode
  investorId: string
}) => {
  return (
    <InvestorContextProvider value={useInvestorController(investorId)}>
      {children}
    </InvestorContextProvider>
  )
}
