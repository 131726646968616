import { ReactNode } from 'react'
import { createCtx } from 'contexts'
import { ToastStack } from './ToastStack'
import { useToast } from './useToast'

export const [useToastContext, ToastContextProvider] = createCtx<
  ReturnType<typeof useToast>['toast']
>({
  errorMessage:
    'useToastContext: `context` is undefined. Seems you forgot to wrap all toast components within `<ToastProvider />`'
})

export type ToastProviderProps = {
  children: ReactNode
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const { toast, toasts } = useToast()
  return (
    <>
      <ToastContextProvider value={toast}>{children}</ToastContextProvider>
      <ToastStack items={toasts} />
    </>
  )
}
