import { PhoneNumber } from 'models'
import { getLeads2Url } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export type PostPhoneNumberPayload = {
  number: string
  phoneType?: PhoneNumber['phoneType']
}

export const postPhoneNumber = (leadId: string, payload: PostPhoneNumberPayload): Promise<any> => {
  return postRequest(
    getLeads2Url({
      id: leadId,
      action: 'phone-numbers'
    }),
    snakeCaseKeys(payload)
  )
}
