// @ts-strict

import { ReactElement, useEffect, useState } from 'react'
import { Tooltip } from '@foundation/components'
import {
  DisplayReadOnlyValue,
  FormField,
  Icon,
  If,
  InlineEditMoneyInput,
  InlineEditSelect,
  Section,
  Sections
} from 'components'
import { useLeadContext } from 'contexts'
import { useEligibilityController } from 'controllers'
import useIsBbysEligible from 'hooks/useIsBbysEligible'
import { agentMatchingCallReviewCreditScoreOptions, fhaVaOptions, yesOrNoOptions } from 'lookups'
import { isSellerLead, MortgageLead, SellerLead } from 'models'
import { dataOrDash, formatPrice, stringifyValue, titleize } from 'utils'
import EligibilityHeader from './EligibilityHeader'

type Props = {
  lead: SellerLead | MortgageLead
  requiredFields?: Record<string, boolean>
  updateCallReview: (key: string, value: string | null) => void
  updateField: (
    value: Record<string, string | number | Record<string, string | Record<string, string>>>
  ) => void
}

export const AgentMatchingLeadBBYSScreenSection = ({
  lead,
  updateCallReview,
  updateField,
  requiredFields = {}
}: Props) => {
  const { isDrStateEligible } = useIsBbysEligible({ lead })

  const remainingMortgageBalance = lead.callReview?.remainingMortgageBalance
  const homeValue = Number(isSellerLead(lead) ? lead.price : lead.callReview?.homeValue)
  const disabled = !isDrStateEligible || !homeValue
  const fullAddress = isSellerLead(lead)
    ? lead.fullAddress
    : lead.callReview?.othersideInfo?.fullAddress
  const [apiCalled, setApiCalled] = useState(false)
  const [tooltipText, setTooltipText] = useState<ReactElement>()
  const {
    data,
    refetch: fetchEligibility,
    isLoading,
    isError
  } = useEligibilityController({
    leadId: lead.id,
    agentValuation: homeValue,
    clientMortgageBalance: remainingMortgageBalance,
    enabled: false
  })
  const { refetch: refreshLead } = useLeadContext()
  const eligibilityCheck = lead.eligibilityDetails

  useEffect(() => {
    if (data || isError) {
      refreshLead()
    }
  }, [data, isError, refreshLead])

  useEffect(() => {
    if (eligibilityCheck !== undefined) {
      setApiCalled(true)
    }
  }, [eligibilityCheck])

  useEffect(() => {
    if (eligibilityCheck?.humanReadableErrors()?.length !== 0) {
      setTooltipText(
        <ul>
          {eligibilityCheck?.humanReadableErrors()?.map((error: string, index: number) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )
    }
  }, [eligibilityCheck])

  const fetchEligibilityCheck = () => {
    fetchEligibility()
    setApiCalled(true)
  }

  const homeValueUpdate = (value: string) => {
    if (isSellerLead(lead)) {
      updateField({ price: value })
    } else {
      updateCallReview('homeValue', value)
    }
  }

  return (
    <Sections
      title="Buy Before You Sell Screen"
      actions={
        <EligibilityHeader
          buttonText={apiCalled ? 'Recalculate EU' : 'Check Eligibility'}
          buttonType={apiCalled ? 'tertiary' : 'action'}
          lead={lead}
          onClick={() => fetchEligibilityCheck()}
          disabled={disabled}
          isLoading={isLoading}
        />
      }
      titleStyle={{ flex: 'none', marginRight: '10px' }}
    >
      <Section>
        <FormField title="DR Address" inline>
          <DisplayReadOnlyValue value={dataOrDash(fullAddress)} />
        </FormField>
        <FormField title="DR State Eligibility" inline>
          <DisplayReadOnlyValue
            value={dataOrDash(isDrStateEligible ? 'Eligible' : 'Not Eligible')}
          />
        </FormField>
        <FormField title="DR Home Value" inline>
          <InlineEditMoneyInput
            onBlur={homeValueUpdate}
            value={dataOrDash(homeValue)}
            isError={!homeValue}
          />
        </FormField>
        <FormField title="Remaining Mortgage Balance?" inline>
          <InlineEditMoneyInput
            onBlur={(value: string) => {
              updateCallReview('remainingMortgageBalance', value)
            }}
            value={dataOrDash(lead.callReview?.remainingMortgageBalance)}
          />
        </FormField>
        <FormField title="Equity Unlock - Min" inline>
          <DisplayReadOnlyValue
            value={dataOrDash(lead.callReview?.minEquityUnlock)}
            format={formatPrice}
          />
        </FormField>
        <FormField title="Equity Unlock - Max" inline>
          <DisplayReadOnlyValue
            value={dataOrDash(lead.callReview?.maxEquityUnlock)}
            format={formatPrice}
          />
        </FormField>
        <FormField title="DR Property Eligibility" inline>
          <DisplayReadOnlyValue value={titleize(eligibilityCheck?.eligibilityStatus)} />
          <If test={eligibilityCheck?.eligibilityStatus && tooltipText}>
            <Tooltip content={tooltipText} placement="right">
              <span>
                <Icon.QuestionCircle width={18} height={18} />
              </span>
            </Tooltip>
          </If>
        </FormField>
      </Section>
      <Section>
        <FormField title="Primary Residence" required={requiredFields.primaryResidence} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('primaryResidence', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.primaryResidence)}
            isError={apiCalled && lead.callReview?.primaryResidence === undefined}
          />
        </FormField>
        <FormField title="Credit Score > 620" required={requiredFields.creditScore} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('creditScore', value)}
            options={agentMatchingCallReviewCreditScoreOptions}
            value={stringifyValue(lead.callReview?.creditScore)}
            isError={apiCalled && lead.callReview?.creditScore === undefined}
          />
        </FormField>
        <FormField
          title="DR Age-Restricted Community"
          required={requiredFields.ageRestrictedCommunity}
          inline
        >
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('ageRestrictedCommunity', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.ageRestrictedCommunity)}
            isError={apiCalled && lead.callReview?.ageRestrictedCommunity === undefined}
          />
        </FormField>
        <FormField title="IR FHA/VA Loan" required={requiredFields.fhaVaLoan} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('fhaVaLoan', value || null)}
            options={fhaVaOptions}
            value={stringifyValue(lead.callReview?.fhaVaLoan)}
            isError={apiCalled && lead.callReview?.fhaVaLoan === undefined}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
