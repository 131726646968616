export const isValidHttpUrl = (string: string) => {
  let url: Nullable<URL> = null

  try {
    url = new URL(string)
  } catch {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}
