import {
  AddressUnitAutoComplete,
  DateInput,
  DateTimeInput,
  DollarsAndCentsInput,
  FloatInput,
  FormField,
  IntegerInput,
  MoneyInput,
  PercentInputV2,
  SelectBoolean,
  SelectWithBlank,
  TextInput
} from 'components'
import { TCCHLSimpleSaleLeadFieldAttribute, useCCHLSimpleSaleLeadFields } from 'lookups'
import { stringifyValue } from 'utils'
import { useHLSSLeadDispositionContext } from '../HLSSLeadDispositionContext'

type Props = {
  field: TCCHLSimpleSaleLeadFieldAttribute
  readonly?: boolean
  required?: boolean
  title?: string
}

export const FieldFormField = ({ field, ...rest }: Props) => {
  const { dispositionPayload, setDispositionPayloadItem, requiredFields } =
    useHLSSLeadDispositionContext()

  const { getCCHLSimpleSaleLeadField } = useCCHLSimpleSaleLeadFields()
  const formField = getCCHLSimpleSaleLeadField(field)

  const inputComponents = {
    address: AddressUnitAutoComplete,
    boolean: SelectBoolean,
    date: DateInput,
    datetime: DateTimeInput,
    dollarsAndCents: DollarsAndCentsInput,
    float: FloatInput,
    integer: IntegerInput,
    money: MoneyInput,
    percent: PercentInputV2,
    select: SelectWithBlank,
    text: TextInput
  }

  const InputComponent = inputComponents[formField.inputType]

  const fieldLookupProps = {
    ...(formField?.allowNegativeNumber && { allowNegative: formField.allowNegativeNumber })
  }

  return (
    <FormField title={formField.name} required={requiredFields.includes(field)}>
      {InputComponent ? (
        <InputComponent
          id={field}
          name={field}
          onChange={val => setDispositionPayloadItem(field, val)}
          value={stringifyValue(dispositionPayload[field])}
          options={formField.options}
          {...fieldLookupProps}
          {...rest}
        />
      ) : null}
    </FormField>
  )
}
