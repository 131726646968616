import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Columns.module.scss'

type Props = {
  children: ReactNode
  className?: string
  hasFixedHeight?: boolean
}
export const Columns = ({ className, children, hasFixedHeight }: Props) => (
  <div className={cx(styles.columns, className, hasFixedHeight && styles.fixedHeight)}>
    {children}
  </div>
)
