// @ts-strict
import { LenderProviderLead } from 'models'
import { formatOrDash, titleize } from 'utils'
import { getLoanApplicationsByStatus } from './utils'

type TProps = {
  lenderProviderLeads: LenderProviderLead[]
}

export const LoanApplicationOptions = ({ lenderProviderLeads }: TProps) => {
  const loanApplicationsByStatus = getLoanApplicationsByStatus(lenderProviderLeads)
  let counter = 0

  return (
    <>
      {Object.keys(loanApplicationsByStatus).map(key => {
        const providerLeadGroup = loanApplicationsByStatus[key]
        return (
          <optgroup label={titleize(key)} key={key}>
            {providerLeadGroup.map((providerLead: LenderProviderLead) => {
              const lenderLead = providerLead.lenderLead
              return (
                <option key={providerLead.id} value={counter++}>
                  #{lenderLead?.eaveLoanApplicationId} (
                  {formatOrDash(lenderLead?.loanMilestone, titleize)})
                </option>
              )
            })}
          </optgroup>
        )
      })}
    </>
  )
}
