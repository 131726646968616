// @ts-strict
import { SectionSubTitle, SectionTitle } from 'components'
import { LeadsTable } from 'content/Leads'
import { ListProvider } from 'contexts'
import { useLeadsController } from 'controllers'
import { similarLeadsListColumns } from 'lookups'
import { isEmptyValue } from 'utils'

export const subtitleMessages = {
  emptyPayload: 'Add client data in the form to search for similar leads',
  avoidDuplicates: 'Avoid creating duplicated orders creating the lead in the existing leads',
  empty: ''
}

const renderSubtitleMessage = (formEmpty: boolean, isLoading: boolean, hasLeads: boolean) => {
  if (formEmpty || isLoading) {
    return subtitleMessages.emptyPayload
  } else if (hasLeads) {
    return subtitleMessages.avoidDuplicates
  }

  return subtitleMessages.empty
}

type TProps = {
  similarEmails?: Potential<string>[]
  similarNames?: Potential<string>[]
  similarPhoneNumbers?: Potential<string>[]
}

export const SimilarLeads = ({
  similarEmails = [],
  similarNames = [],
  similarPhoneNumbers = []
}: TProps) => {
  const { leads, isLoading } = useLeadsController({
    perPage: 6,
    similarEmails,
    similarNames,
    similarPhoneNumbers,
    sortBy: 'Score'
  })

  const formEmpty = isEmptyValue([...similarEmails, ...similarNames, ...similarPhoneNumbers])

  const similarLeadsSubtitle = renderSubtitleMessage(formEmpty, isLoading, leads?.length)

  return (
    <ListProvider columns={similarLeadsListColumns}>
      <SectionTitle>Similar Leads</SectionTitle>
      <SectionSubTitle>{similarLeadsSubtitle}</SectionSubTitle>
      <LeadsTable leads={leads} isLoading={isLoading} />
    </ListProvider>
  )
}
