// @ts-strict
import React, { ReactNode } from 'react'
import { cx, showIf } from 'utils'
import { SectionHeader } from '../SectionHeader'
import { SectionTitle } from '../SectionTitle'
import styles from './SubSection.module.scss'

type Props = {
  actions?: ReactNode
  children?: ReactNode
  className?: string
  title?: string
}

export const SubSection = ({ className, children, title, actions }: Props) => {
  let sectionHeader
  if (title || actions) {
    sectionHeader = (
      <SectionHeader className={styles.subSectionHeader}>
        {showIf(title, <SectionTitle>{title}</SectionTitle>)}
        {actions}
      </SectionHeader>
    )
  }

  return (
    <div className={cx(styles.subSection, className)} data-testid="subsection">
      {sectionHeader}
      {children ? <div className={styles.contents}>{children}</div> : null}
    </div>
  )
}
