// @ts-strict
// @ts-strict
import { useEffect, useState } from 'react'
import { TButtonFormFormProps, useButtonForm } from 'components'
import { UseMutationResult } from 'hooks'
import { TMarketplaceProgramSlug } from 'lookups'
import { TOrderPostPayload } from 'services'
import {
  formatDate,
  validateEmailFormat,
  validateMatch,
  validatePhoneFormat,
  validatePresence
} from 'utils'
import { TOrderPayload } from './types'
import {
  getMarketplaceProgramParams,
  mapProductSlugsToMarketplaceProgramServerSlug,
  TMarketplaceProgramParams
} from './utils'
import { validatePropertyUuid } from './utils/validatePropertyUuid'

const initialPayload: TOrderPayload = {
  source: 'agent',
  leadType: undefined,
  clientName: '',
  referringOwnerType: 'Agent',
  sourceForm: 'new_cash_close_order',
  sourcePageType: 'cash_close_app'
}

const isReferringAgentSource = (source: string) => {
  return ['agent', 'disclosures', 'accept'].includes(source)
}

const isLoanOfficerSource = (source: string) => {
  return ['loan_officer'].includes(source)
}

const getReferringOwnerType = (source: string) => {
  if (isReferringAgentSource(source)) {
    return 'Agent'
  }
  if (isLoanOfficerSource(source)) {
    return 'Loan Officer'
  }

  return undefined
}

type TCreateOrderFormParams = {
  defaultPayload?: Partial<TOrderPayload>
  mutation: UseMutationResult<string, unknown, TOrderPostPayload, unknown>
  onSubmit: (orderId: string) => Promise<void>
}

export type TUseOrderFormParams = {
  formProps: TButtonFormFormProps
  handleSelectSource: (val: string) => void
  hideBbysForm: boolean
  isBbys: boolean
  isTradeInHLSS: boolean
  marketplaceProgramsServerSlugs: TMarketplaceProgramSlug[]
  marketplaceProgramsServerSlugsParams: TMarketplaceProgramParams
  onChangeAttribute: <K extends keyof TOrderPayload>(key: K, value: TOrderPayload[K]) => void
  payload: TOrderPayload
  showReferringAgent: boolean
  showReferringLoanOfficer: boolean
}

export const useCreateOrderForm = ({
  mutation,
  onSubmit,
  defaultPayload = {}
}: TCreateOrderFormParams): TUseOrderFormParams => {
  const [validations, setValidations] = useState({})

  const transformPayload = (payload: TOrderPayload): TOrderPostPayload => {
    const { leadType } = payload

    if (!leadType) {
      throw new Error('No lead type provided.')
    }

    return {
      ...payload,
      newPropertyCloseOfEscrowDate: payload.newPropertyCloseOfEscrowDate
        ? formatDate(payload.newPropertyCloseOfEscrowDate, 'MM/dd/yyyy')
        : undefined,
      types: [leadType === 'trade_in_hlss' ? 'hl_simple_sale' : leadType],
      secondaryLoans: [{ balance: Number(payload.mortgageBalance || '0'), type: 'mortgage' }]
    }
  }

  const {
    formProps,
    fieldsProps: { payload, onChangeAttribute }
  } = useButtonForm<TOrderPayload, string>({
    initialPayload: { ...initialPayload, ...defaultPayload },
    mutation,
    callback: onSubmit,
    formValidations: validations,
    transformPayload
  })

  useEffect(() => {
    const { leadType } = payload

    if (leadType === 'bbys') {
      setValidations({
        lenderCompanyName: {
          label: 'Lender Company Name',
          validations: validatePresence
        },
        loanOfficerName: {
          label: 'Loan Officer Name',
          validations: validatePresence
        },
        loanOfficerPhone: {
          label: 'Loan Officer Phone',
          validations: [validatePresence, validatePhoneFormat]
        },
        loanOfficerEmail: {
          label: 'Loan Officer Email',
          validations: [validatePresence, validateEmailFormat]
        },
        agentEmail: {
          label: 'Agent Email',
          validations: validateEmailFormat
        },
        agentPhone: {
          label: 'Agent Phone',
          validations: validatePhoneFormat
        },
        departingPropertyFullAddress: {
          label: 'Property Address',
          validations: [validatePresence]
        },
        propertyUuid: {
          label: 'Property UUID',
          validations: [validatePropertyUuid, validatePresence],
          requirements: {
            departingPropertyFullAddress: {
              label: 'Full address',
              validations: [validatePresence]
            }
          }
        },
        primaryClientName: {
          label: 'Client Name',
          validations: validatePresence
        },
        primaryClientEmail: {
          label: 'Client Email',
          validations: [validateEmailFormat, validatePresence]
        },
        primaryClientPhone: {
          label: 'Client Phone',
          validations: validatePhoneFormat
        }
      })
    } else {
      setValidations({
        clientName: validatePresence,
        referringOwnerId: {
          label: 'Referring Agent',
          validations: [validatePresence],
          requirements: {
            source: {
              label: 'Source',
              validations: [validateMatch(['agent', 'disclosures'])]
            }
          }
        },
        leadType: validatePresence,
        source: validatePresence,
        phoneNumber: {
          label: 'Client Phone Number',
          validations: [validatePhoneFormat]
        },
        phoneAlt: {
          label: 'Spouse / Partner Phone Number',
          validations: [validatePhoneFormat]
        },
        email: {
          label: 'Client Email',
          validations: [validatePresence, validateEmailFormat]
        },
        emailAlt: {
          label: 'Spouse / Partner Email',
          validations: [validateEmailFormat]
        }
      })
    }
  }, [payload])

  const { source, leadType } = payload
  const isTradeInHLSS =
    leadType === 'trade_in' || leadType === 'trade_in_hlss' || leadType === 'hl_simple_sale'
  const isBbys = leadType === 'bbys'

  const showReferringAgent = isReferringAgentSource(source)
  const showReferringLoanOfficer = isLoanOfficerSource(source)
  const hideBbysForm = (showReferringAgent || showReferringLoanOfficer) && !payload.referringOwnerId

  const handleSelectSource = (val: string) => {
    onChangeAttribute('source', val)
    onChangeAttribute('referringOwnerType', getReferringOwnerType(val))
  }

  const marketplaceProgramsServerSlugs = leadType
    ? mapProductSlugsToMarketplaceProgramServerSlug([leadType])
    : []

  const marketplaceProgramsServerSlugsParams = getMarketplaceProgramParams(
    marketplaceProgramsServerSlugs
  )

  return {
    handleSelectSource,
    onChangeAttribute,
    formProps,
    payload,
    isTradeInHLSS,
    isBbys,
    showReferringAgent,
    showReferringLoanOfficer,
    hideBbysForm,
    marketplaceProgramsServerSlugs,
    marketplaceProgramsServerSlugsParams
  }
}
