import { useFeatureFlags } from 'hooks'
import { ActivityFeedInterface } from 'models/v2/ActivityFeed'
import { CommsPhoneCallEventRow, CommsTextMessageEventRow } from '../CommsEventRows'
import { CommsEmailEventRow } from '../CommsEventRows/CommsEmailEventRow/CommsEmailEventRow'

const mapEventComponent = (eventType: string, showEmailEvents: boolean = false) => {
  if (eventType === 'phone_call_initiated') {
    return CommsPhoneCallEventRow
  }
  if (eventType === 'email_occurred' && showEmailEvents) {
    return CommsEmailEventRow
  }

  if (eventType === 'sms_occurred') {
    return CommsTextMessageEventRow
  }

  return null
}

type Props = {
  activityFeed: ActivityFeedInterface
  rowIndex?: number
}

export const CommsListRow = ({ activityFeed, rowIndex, ...rest }: Props) => {
  const { salesOpsCommsEmails } = useFeatureFlags(['sales-ops-comms-emails'])
  const Component = mapEventComponent(activityFeed.name, salesOpsCommsEmails.enabled)

  return Component ? <Component rowIndex={rowIndex} activityFeed={activityFeed} {...rest} /> : null
}
