import { Section, Sections } from 'components'
import { FieldFormField } from '../../FieldFormField'
import { useFields } from '../../useHLSSLeadDisposition'

export const HLSSClosedPurchase = () => {
  useFields({
    required: ['departingPropertyCloseOfPurchaseEscrowDate']
  })
  return (
    <>
      <Sections title="DR Purchase Transaction">
        <Section>
          <FieldFormField field="departingPropertyCloseOfPurchaseEscrowDate" />
        </Section>
      </Sections>
    </>
  )
}
