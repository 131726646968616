import { lookupsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { transformLookup } from './transformLookups'

export const fetchLookups = async () => {
  const response = await getRequest(lookupsUrl)
  return await deserializeAsync(response.data, {
    transform: transformLookup
  })
}
