import { ReactNode } from 'react'
import { cx } from 'utils'
import { PageTitle } from '../PageTitle'
import styles from './PageHeader.module.scss'

type TProps = {
  children?: ReactNode
  className?: string
  subTitle?: string
  title?: string
}

export const PageHeader = ({ className, title, subTitle, children }: TProps) => {
  if (title || children) {
    const titleTag = title ? <PageTitle>{title}</PageTitle> : null
    const subTitleTag = subTitle ? <div className={styles.subTitle}>{subTitle}</div> : null
    return (
      <header className={cx(styles.header, 'pageHeader', className)}>
        {titleTag}
        {subTitleTag}
        {children}
      </header>
    )
  }
  return null
}
