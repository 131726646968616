type TOppCount = Array<{ attributes: { count: number; status_id: string } }>

export const transformOpportunityCounts = ({ data }: { data: TOppCount }) => {
  return data.map(d => {
    return {
      id: d.attributes.status_id,
      count: d.attributes.count
    }
  })
}
