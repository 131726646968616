// @ts-strict
import { useButtonForm } from 'components'
import { useAgentController } from 'controllers'
import { TMarketplaceProgramSlug } from 'lookups'
import { Agent } from 'models'
import { MarketplaceProgramAgentEnrollStatus } from 'models/MarketplaceProgramAgent/utils'
import { TFormValidation, validateLength, validateMatch, validatePresence } from 'utils'

export type TAgentProgramsFormButtonPayload = {
  enrollmentStatus: Potential<MarketplaceProgramAgentEnrollStatus>
  note?: string
  program: Potential<TMarketplaceProgramSlug>
}

export const optionsNote: MarketplaceProgramAgentEnrollStatus[] = ['unenrolled', 'declined']
export const minNoteLength = 10
export const useAgentProgramsFormButton = (agent: Agent) => {
  const { updateAgentServiceEnrollment } = useAgentController(agent.id)

  const formValidations: TFormValidation = {
    program: { label: 'Program', validations: [validatePresence] },
    enrollmentStatus: { label: 'Enrollment Status', validations: [validatePresence] },
    note: {
      label: 'Note',
      validations: [validatePresence, validateLength({ min: minNoteLength })],
      requirements: {
        enrollmentStatus: {
          label: 'Enrollment Status',
          validations: [validateMatch(optionsNote)]
        }
      }
    }
  }

  const initialPayload: TAgentProgramsFormButtonPayload = {
    enrollmentStatus: undefined,
    note: undefined,
    program: undefined
  }

  const transformPayload = ({
    program,
    enrollmentStatus,
    note
  }: TAgentProgramsFormButtonPayload) => ({
    notes: note || undefined,
    enrollmentStatus,
    program
  })

  const { formProps, fieldsProps } = useButtonForm<TAgentProgramsFormButtonPayload>({
    transformPayload,
    initialPayload,
    formValidations,
    mutation: updateAgentServiceEnrollment
  })

  return {
    formProps,
    fieldsProps
  }
}
