import { byAttr } from 'utils'
import { agentTeamFieldsSettings, TAgentTeamFieldAttribute } from './agentTeamFieldsSettings'
import { AgentTeamField } from './'

export const agentTeamFields = agentTeamFieldsSettings.map(setting => {
  return new AgentTeamField(setting)
})

export const getAgentTeamField = (attr: TAgentTeamFieldAttribute) => {
  return agentTeamFields.find(byAttr('attr', attr))
}

export const getAgentTeamFieldName = (attr: TAgentTeamFieldAttribute) => {
  return getAgentTeamField(attr)?.name
}
