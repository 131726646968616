import {
  AddressUnitAutoComplete,
  AgentsByProgramAutoComplete,
  FormField,
  Section,
  Sections,
  TButtonFormFieldsProps
} from 'components'
import { TLeadNewIncomingResidencePayload } from '.'

type TProps = {
  fieldsProps: TButtonFormFieldsProps<TLeadNewIncomingResidencePayload>
}

export const LeadNewIncomingResidenceFields = ({ fieldsProps }: TProps) => {
  const { onChangeAttribute } = fieldsProps

  return (
    <>
      <Sections>
        <Section>
          <FormField title="Full Address" htmlFor="address-unit-auto" required>
            <AddressUnitAutoComplete
              id="address-unit-auto"
              data-testid="fullAddress"
              onSelect={value => onChangeAttribute('incomingPropertyFullAddress', value)}
              placeholderAddress="Enter street, city, state..."
              placeholderUnit="Unit"
            />
          </FormField>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <FormField title="Buyer Agent" htmlFor="agents-by-program-auto">
            <AgentsByProgramAutoComplete
              id="agents-by-program-auto"
              name="incomingPropertyBuyerAgentId"
              marketplaceProgramServerSlugs={{ cashOffer: 'enrolled' }}
              onSelect={agent => onChangeAttribute('incomingPropertyBuyerAgentId', agent?.id)}
              onClear={() => onChangeAttribute('incomingPropertyBuyerAgentId', undefined)}
            />
          </FormField>
        </Section>
      </Sections>
    </>
  )
}
