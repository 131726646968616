// @ts-strict

import { ReactNode } from 'react'
import { SearchConsole, SearchInputParams, SearchStatus, SearchSummary } from 'components'
import { useParamsContext } from 'contexts'
import { omitViewParams } from 'hooks/useParams/utils'

type TProps = {
  counterText: string
  filter?: ReactNode
  isLoading: boolean
  searchInputPlaceholder?: string
  totalCount: Potential<number>
}

export const ListSearchHeader = ({
  filter,
  totalCount,
  isLoading,
  counterText,
  searchInputPlaceholder = 'Search by client, agent, or property address'
}: TProps) => {
  const { params, resetParams } = useParamsContext()

  const onClear = () => resetParams()
  const canClear = !isLoading && !!Object.keys(omitViewParams(params)).length

  return (
    <>
      <SearchSummary
        status={<SearchStatus params={params} count={totalCount} type={counterText} />}
        onClear={canClear ? onClear : undefined}
      />
      <SearchConsole
        search={<SearchInputParams placeholder={searchInputPlaceholder} />}
        actions={filter}
      />
    </>
  )
}
