// @ts-strict
import { ReactNode } from 'react'
import { TableCell as Td } from 'components'
import styles from './Table.module.scss'

type TProps = {
  children: ReactNode
}

export const TableActions = ({ children }: TProps) => {
  return <Td className={styles.actionsList}>{children}</Td>
}
