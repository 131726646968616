import {
  ActionBar,
  ButtonV2Props,
  Icon,
  IconProps,
  PrimaryButton,
  TActionBarProps,
  TIconObject
} from 'components'
import styles from './Console.module.scss'

export const AsideActionBar = (props: TActionBarProps) => (
  <ActionBar className={styles.actionBar} {...props} />
)
export const AsideActionButton = (props: ButtonV2Props) => (
  <PrimaryButton className={styles.actionButton} {...props} />
)
export const AsideActionIcon = ({ icon, ...rest }: { icon: keyof TIconObject } & IconProps) => {
  const Component = Icon[icon]
  return <Component className={styles.actionIcon} width={12} height={12} {...rest} />
}
