type ObjArg = Record<string, any>
type SortBy = (a: ObjArg, b: ObjArg, attr: string) => number
type SortByCreatedAt = (a: ObjArg, b: ObjArg) => number

const sortByCreatedAt: SortByCreatedAt = (a, b) =>
  a.createdAt || b.createdAt ? (new Date(a.createdAt) as any) - (new Date(b.createdAt) as any) : 0

const sortByDate: SortBy = (a, b, attr) => {
  return (new Date(a[attr]) as any) - (new Date(b[attr]) as any) || sortByCreatedAt(a, b)
}

const sortByString: SortBy = (a, b, attr) =>
  String(a[attr]).toLowerCase().localeCompare(String(b[attr]).toLowerCase()) ||
  sortByCreatedAt(a, b)

const sortByNumber: SortBy = (a, b, attr) => a[attr] - b[attr]

type Sorts = Record<string, (a: ObjArg, b: ObjArg) => number>

export const sorts: Sorts = {
  fullName: (a, b) => sortByString(a, b, 'lastName') || sortByString(a, b, 'firstName'),
  role: (a, b) => sortByString(a, b, 'role'),
  name: (a, b) => sortByString(a, b, 'name'),
  type: (a, b) => sortByString(a, b, 'userType'),
  state: (a, b) => sortByString(a, b, 'state'),
  price: (a, b) => sortByNumber(a, b, 'price'),
  phone: (a, b) => sortByString(a, b, 'phone'),
  email: (a, b) => sortByString(a, b, 'email'),
  source: (a, b) => sortByString(a, b, 'source'),
  updated: (a, b) => sortByDate(a, b, 'updatedAt'),
  intro: (a, b) => sortByDate(a, b, 'introedAt'),
  activity: (a, b) => sortByDate(a, b, 'lastActivityAt'),
  created: (a, b) => sortByCreatedAt(a, b)
}

export const sortByColumn = (columnName: string) => {
  if (sorts[columnName]) {
    return sorts[columnName]
  }
  return (a: ObjArg, b: ObjArg) => sortByString(a, b, columnName)
}
