import { TModelFieldSetting } from '../ModelField'

export type THLSimpleSaleProviderLeadFieldAttribute = 'reasonForFail'

export type THLSimpleSaleProviderLeadFieldSetting = {
  attr: THLSimpleSaleProviderLeadFieldAttribute
} & TModelFieldSetting

export const hlSimpleSaleProviderLeadFieldsSettings: THLSimpleSaleProviderLeadFieldSetting[] = [
  {
    attr: 'reasonForFail',
    name: 'Failed Reason'
  }
]
