// @ts-strict
import { ButtonForm, FormField, Select, useButtonForm } from 'components'
import { useAgentsContext } from 'contexts'
import type { TransactionTeam } from 'models'
import { validatePresence } from 'utils'

type Props = {
  team: TransactionTeam
}

export const ReassignPreferredEscrowOfficerOfAgents = ({ team }: Props) => {
  const { reassignAgentsPreferredEscrowOfficer } = useAgentsContext()
  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm({
    initialPayload: {
      oldPreferredEscrowOfficerUserId: '',
      preferredEscrowOfficerUserId: ''
    },
    mutation: reassignAgentsPreferredEscrowOfficer,
    formValidations: {
      oldPreferredEscrowOfficerUserId: [validatePresence],
      preferredEscrowOfficerUserId: [validatePresence]
    }
  })

  const escrowOfficersTeamMembers = team.teamMemberships
    ? team.teamMemberships
        ?.filter(member => member.role === 'escrow_officer')
        .map(member => {
          return { name: member.user.name, id: member.user.id }
        })
    : []

  return (
    <ButtonForm
      title="Reassign Agents Preferred Escrow Officer"
      buttonText="Reassign"
      {...formProps}
    >
      <FormField title="Change from" htmlFor="old-preferred-escrow-officer" required>
        <Select
          id="old-preferred-escrow-officer"
          onChange={oldPreferredEscrowOfficerUserId =>
            onChangeAttribute('oldPreferredEscrowOfficerUserId', oldPreferredEscrowOfficerUserId)
          }
          name="oldPreferredEscrowOfficer"
          options={escrowOfficersTeamMembers.map(({ name, id }) => ({
            name,
            value: id
          }))}
          value={payload.oldPreferredEscrowOfficerUserId}
          data-testid="old-preferred-escrow-officer"
          addBlank
        />
      </FormField>
      <FormField title="Change to" htmlFor="preferred-escrow-officer" required>
        <Select
          id="preferred-escrow-officer"
          onChange={preferredEscrowOfficerUserId =>
            onChangeAttribute('preferredEscrowOfficerUserId', preferredEscrowOfficerUserId)
          }
          name="preferredEscrowOfficer"
          options={escrowOfficersTeamMembers
            .filter(member => member.id !== payload.oldPreferredEscrowOfficerUserId)
            .map(({ name, id }) => ({
              name,
              value: id
            }))}
          value={payload.preferredEscrowOfficerUserId}
          data-testid="preferred-escrow-officer"
          addBlank
        />
      </FormField>
    </ButtonForm>
  )
}
