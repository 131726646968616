import { Section } from 'components'
import { useCashOfferDispositionContext } from '../CashOfferDispositionContext'
import { FieldFormField } from '../FieldFormField'
import { useFields } from '../useFields'

export const CashOfferClosedSell = () => {
  const { changes, cashOfferLead } = useCashOfferDispositionContext()
  const requireChangeReason =
    cashOfferLead.incomingPropertyCloseOfSellEscrowAt && changes.incomingPropertyCloseOfSellEscrowAt

  useFields({
    fields: requireChangeReason ? ['changeReasons.incomingPropertyCloseOfSellEscrowAt.note'] : [],
    required: [
      'incomingPropertyCloseOfSellEscrowAt',
      requireChangeReason ? 'changeReasons.incomingPropertyCloseOfSellEscrowAt.reason' : undefined
    ]
  })
  return (
    <Section>
      <FieldFormField field="incomingPropertyCloseOfSellEscrowAt" />
      {requireChangeReason && (
        <>
          <FieldFormField field="changeReasons.incomingPropertyCloseOfSellEscrowAt.reason" />
          <FieldFormField field="changeReasons.incomingPropertyCloseOfSellEscrowAt.note" />
        </>
      )}
    </Section>
  )
}
