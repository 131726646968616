import { TableHeaderColumn as TH, TableHeaderRow as TR } from 'components'
import styles from './AgentLeadsTable.module.scss'

export const AgentLeadsTableHeader = () => {
  return (
    <TR>
      <TH name="Name" />
      <TH name="Address" />
      <TH sort={[{ name: 'Price', slug: 'Price' }]} />
      <TH name="Opps" />
      <TH name="Stage / Lead Stage" className={styles.stage} />
      <TH name="Update" />
      <TH sort={[{ name: 'Intro', slug: 'Intro' }]} />
    </TR>
  )
}
