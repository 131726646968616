// @ts-strict
import { EmptyTableCell, TableRow as Tr } from 'components'
import {
  CCTradeInLeadCapitalMarketsFacilityCell,
  CCTradeInLeadDaysOnMarketCell,
  CCTradeInLeadDaysOwnedCell,
  CCTradeInLeadDaysSinceFundingCell,
  CCTradeInLeadDaystoListingCell,
  CCTradeInLeadDaysUntilFundingCell,
  CCTradeInLeadDRPurchaseCoeDateCell,
  CCTradeInLeadDRPurchaseOpenEscrowDateCell,
  CCTradeInLeadDRSaleActualCOEDateCell,
  CCTradeInLeadDRSaleOpenEscrowDateCell,
  CCTradeInLeadFundingAmountCell,
  CCTradeInLeadFundingDateCell,
  CCTradeInLeadFundingFacilityCell,
  CCTradeInLeadHoaCell,
  CCTradeInLeadIRPurchaseCOEDateCell,
  CCTradeInLeadListedDateCell,
  CCTradeInLeadPropertyAddress,
  CCTradeInLeadSolarPanelsCell,
  CCTradeInLeadTradeInPlus
} from 'content/CCTradeInLeads/CCTradeInLeadsTable/CCTradeInLeadsTableCells'
import {
  CashCloseLeadConsumerClientManagerCell,
  CashCloseLeadContractAdministratorCell,
  CashCloseLeadListingSpecialistCell,
  CashCloseLeadSalesSpecialistCell,
  CashCloseLeadStrategicRelationshipManagerCell,
  CashCloseLeadTransactionSpecialistCell,
  CashCloseLeadTxTeamCell,
  CashCloseLoanOfficerTeamLeadCell
} from 'content/CashCloseLeads/CashCloseLeadsTableCells'
import {
  LeadAddressCell,
  LeadAgentCell,
  LeadClientAdvisorAssistantCell,
  LeadClientAdvisorCell,
  LeadCreatedUpdatedCell,
  LeadDaysSinceLastStageUpdateCell,
  LeadLastStageUpdateCell,
  LeadNamePartnerCell,
  LeadOtherLeadsCell,
  LeadPropertyTypeCell,
  LeadProvidableAgentCell,
  LeadPurchaseEscrowAssistantCell,
  LeadPurchaseEscrowExpectedCloseDateCell,
  LeadPurchaseEscrowNetEquityCell,
  LeadPurchaseEscrowOfficerCell,
  LeadPurchaseEscrowOrderOpenedDateCell,
  LeadPurchaseEscrowStatusCell,
  LeadPurchaseEscrowTitleOfficerCell,
  LeadSaleEscrowAssistantCell,
  LeadSaleEscrowExpectedCloseDateCell,
  LeadSaleEscrowOfficerCell,
  LeadSaleEscrowOrderOpenedDateCell,
  LeadSaleEscrowStatusCell,
  LeadSaleEscrowTitleOfficerCell,
  LeadSourceCell,
  LeadStageFurthestCell,
  LeadTaskCell
} from 'content/Leads'
import { useListContext } from 'contexts'
import { CCTradeInLead } from 'models'
import { capitalize, cx } from 'utils'

const columnMapping = {
  namePartner: LeadNamePartnerCell,
  address: LeadAddressCell,
  createdUpdated: LeadCreatedUpdatedCell,
  lastStageUpdate: LeadLastStageUpdateCell,
  otherLeads: LeadOtherLeadsCell,
  propertyType: LeadPropertyTypeCell,
  source: LeadSourceCell,
  stageFurthest: LeadStageFurthestCell,
  txTeam: CashCloseLeadTxTeamCell,
  departingPropertySellerAgent: LeadProvidableAgentCell,
  incomingPropertyBuyerAgent: LeadProvidableAgentCell,
  referringAgent: LeadAgentCell,
  propertyAddress: CCTradeInLeadPropertyAddress,
  hoa: CCTradeInLeadHoaCell,
  solarPanels: CCTradeInLeadSolarPanelsCell,
  fundingAmount: CCTradeInLeadFundingAmountCell,
  drPurchaseCoeDate: CCTradeInLeadDRPurchaseCoeDateCell,
  irPurchaseCoeDate: CCTradeInLeadIRPurchaseCOEDateCell,
  drSaleActualCoeDate: CCTradeInLeadDRSaleActualCOEDateCell,
  drSaleOpenEscrowDate: CCTradeInLeadDRSaleOpenEscrowDateCell,
  drPurchaseOpenEscrowDate: CCTradeInLeadDRPurchaseOpenEscrowDateCell,
  fundingDate: CCTradeInLeadFundingDateCell,
  listedDate: CCTradeInLeadListedDateCell,
  fundingFacility: CCTradeInLeadFundingFacilityCell,
  daysOwned: CCTradeInLeadDaysOwnedCell,
  daysToListing: CCTradeInLeadDaystoListingCell,
  daysOnMarket: CCTradeInLeadDaysOnMarketCell,
  daysUntilFunding: CCTradeInLeadDaysUntilFundingCell,
  daysSinceFunding: CCTradeInLeadDaysSinceFundingCell,
  daysSinceLastStageUpdate: LeadDaysSinceLastStageUpdateCell,
  strategicRelationshipManager: CashCloseLeadStrategicRelationshipManagerCell,
  consumerClientManager: CashCloseLeadConsumerClientManagerCell,
  salesSpecialist: CashCloseLeadSalesSpecialistCell,
  contractAdministrator: CashCloseLeadContractAdministratorCell,
  transactionSpecialist: CashCloseLeadTransactionSpecialistCell,
  listingSpecialist: CashCloseLeadListingSpecialistCell,
  clientAdvisor: LeadClientAdvisorCell,
  clientAdvisorAssistant: LeadClientAdvisorAssistantCell,
  capitalMarketsFacility: CCTradeInLeadCapitalMarketsFacilityCell,
  tradeInContactClient: LeadTaskCell,
  ccTradeInPropertyQuestionnaire: LeadTaskCell,
  tradeInAssignFundingFacility: LeadTaskCell,
  tradeInAuditFilePurchase: LeadTaskCell,
  tradeInAuditFileSell: LeadTaskCell,
  tradeInClientSignAgreement: LeadTaskCell,
  tradeInConfirmCompleteFundingPackage: LeadTaskCell,
  tradeInConfirmDrSellDisclosuresPackageSigned: LeadTaskCell,
  tradeInConfirmFundingAmount: LeadTaskCell,
  tradeInConfirmIrInContract: LeadTaskCell,
  tradeInConfirmListingAgreementReceived: LeadTaskCell,
  tradeInConfirmMortgageClearToClose: LeadTaskCell,
  tradeInConfirmPostClosingPackageCompleted: LeadTaskCell,
  tradeInConfirmPurchaseEscrowOpened: LeadTaskCell,
  tradeInConfirmSellEscrowOpened: LeadTaskCell,
  tradeInDealApproval: LeadTaskCell,
  tradeInEmailAgentScheduleListingMeeting: LeadTaskCell,
  tradeInExecuteClosingDocs: LeadTaskCell,
  tradeInExecuteSellClosingDocs: LeadTaskCell,
  tradeInFundProperty: LeadTaskCell,
  tradeInHoaReceive: LeadTaskCell,
  tradeInHoaRequest: LeadTaskCell,
  tradeInInspection: LeadTaskCell,
  tradeInPreliminaryTitleReport: LeadTaskCell,
  tradeInPrepDealApproval: LeadTaskCell,
  tradeInReceiveDrPurchaseDisclosuresPackage: LeadTaskCell,
  tradeInReceiveInspectionReports: LeadTaskCell,
  tradeInReceiveSignedDrPurchaseContract: LeadTaskCell,
  tradeInReceiveSignedDrSellContract: LeadTaskCell,
  tradeInRequestPropertyInsurance: LeadTaskCell,
  tradeInReviewQuestionnaireResponses: LeadTaskCell,
  tradeInScheduleFinalNumberReview: LeadTaskCell,
  tradeInScheduleInspection: LeadTaskCell,
  tradeInSendClosingInstructions: LeadTaskCell,
  tradeInSendDrPurchaseDisclosuresPackage: LeadTaskCell,
  tradeInSendEmailsTi7Ti8: LeadTaskCell,
  tradeInSendEmailTi1NextSteps: LeadTaskCell,
  tradeInSendEmailTi2OpenEscrow: LeadTaskCell,
  tradeInSendEmailTi3TimelineEscrowIntro: LeadTaskCell,
  tradeInSendEmailTi4WhatToExpect: LeadTaskCell,
  tradeInSendEmailTi5: LeadTaskCell,
  tradeInSendFinalCustomerReceipt: LeadTaskCell,
  tradeInSendFinalNumbersEmail: LeadTaskCell,
  tradeInSendTia: LeadTaskCell,
  tradeInSignClosingInstructions: LeadTaskCell,
  tradeInSignDrPurchaseContract: LeadTaskCell,
  tradeInSignListingAgreement: LeadTaskCell,
  tradeInSubmitAgreement: LeadTaskCell,
  tradeInSubmitPropertyPhotos: LeadTaskCell,
  tradeInRequestInspection: LeadTaskCell,
  tradeInSubmitRpa: LeadTaskCell,
  tradeInTerminatePropertyInsurance: LeadTaskCell,
  tradeInUpdateLeadStageToClosedPurchase: LeadTaskCell,
  tradeInUpdateLeadStageToInEscrowPurchase: LeadTaskCell,
  tradeInValuation: LeadTaskCell,
  tradeInVerifyClientsFinalProceeds: LeadTaskCell,
  tradeInPlus: CCTradeInLeadTradeInPlus,
  loanOfficerTeamLead: CashCloseLoanOfficerTeamLeadCell,
  purchaseEscrowStatus: LeadPurchaseEscrowStatusCell,
  purchaseEscrowOrderOpenedDate: LeadPurchaseEscrowOrderOpenedDateCell,
  purchaseEscrowExpectedCloseDate: LeadPurchaseEscrowExpectedCloseDateCell,
  purchaseEscrowNetEquity: LeadPurchaseEscrowNetEquityCell,
  purchaseEscrowOfficer: LeadPurchaseEscrowOfficerCell,
  purchaseEscrowAssistant: LeadPurchaseEscrowAssistantCell,
  purchaseEscrowTitleOfficer: LeadPurchaseEscrowTitleOfficerCell,
  saleEscrowStatus: LeadSaleEscrowStatusCell,
  saleEscrowOrderOpenedDate: LeadSaleEscrowOrderOpenedDateCell,
  saleEscrowExpectedCloseDate: LeadSaleEscrowExpectedCloseDateCell,
  saleEscrowOfficer: LeadSaleEscrowOfficerCell,
  saleEscrowAssistant: LeadSaleEscrowAssistantCell,
  saleEscrowTitleOfficer: LeadSaleEscrowTitleOfficerCell,
  tradeInReviewPropertyPhotos: LeadTaskCell
}

type TProps = {
  lead: CCTradeInLead
}

export const CCTradeInLeadTableRow = ({ lead }: TProps) => {
  const { highlightRows, visibleColumns, getColumnAlignment } = useListContext()
  const rowClassName = highlightRows?.includes(lead.id) && 'highlight'

  return (
    <Tr className={cx(rowClassName)} data-testid={`lead-id-${lead.id}`}>
      {visibleColumns.map(visibleColumn => {
        const Cell = columnMapping[visibleColumn]
        const testId = `lead${capitalize(visibleColumn)}Cell`
        const align = getColumnAlignment(visibleColumn)

        return Cell ? (
          <Cell
            lead={lead}
            column={visibleColumn}
            key={visibleColumn}
            testId={testId}
            align={align}
          />
        ) : (
          <EmptyTableCell key={visibleColumn} align={align} />
        )
      })}
    </Tr>
  )
}
