// @ts-strict
import { forwardRef, InputHTMLAttributes, useRef, useState } from 'react'
import { cx } from 'utils'
import formStyles from '../Form.module.scss'
import { TextInput } from '../TextInput'
import styles from './TypeaheadSelect.module.scss'

export type TTypeaheadSelectProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value' | 'onSelect'
> & {
  onChange?: (value: string) => void
  onSelect?: (value: string) => void
  options: {
    label: string
    value: string
  }[]
  value?: string
}

export const TypeaheadSelect = forwardRef<HTMLDivElement, TTypeaheadSelectProps>(
  ({ value = '', onChange = () => {}, onSelect = () => {}, className, options, ...rest }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [currentValue, setCurrentValue] = useState(value)
    const [focused, setFocused] = useState(false)

    const onInputChange = (value: string) => {
      if (onChange) {
        onChange(value)
      }

      setCurrentValue(value)
    }

    const displayOptions = options.filter(
      option =>
        !currentValue || option.value.toLowerCase().includes(currentValue.toString().toLowerCase())
    )

    const select = (value: string) => (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()

      setCurrentValue(value)
      onSelect(value)
    }

    return (
      <div ref={ref} className={cx(styles.wrapper, className)} {...rest}>
        <TextInput
          ref={inputRef}
          value={currentValue}
          className={cx(formStyles.input)}
          onChange={onInputChange}
          onFocus={() => setFocused(true)}
        />
        {focused && (
          <div className={cx(styles.dropdownWrapper)} onClick={e => e.preventDefault()}>
            {displayOptions.map(option => (
              <div
                key={option.value}
                className={cx(styles.dropdownItem)}
                onClick={select(option.value)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
)
