import * as S from './styles'
import { FlexProps } from './types'

export const Flex = ({ children, ...props }: FlexProps) => {
  return (
    <S.Flex data-testid="flex" {...props}>
      {children}
    </S.Flex>
  )
}
