import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-top: 40px;
`

export const Hoverable = styled.div`
  &:hover {
    text-decoration: underline;
  }
`
export const EquityUnlockRange = styled.p`
  white-space: nowrap;
`
