// @ts-strict
import { AgentAeArea, isMetroplex, Metroplex, UsState } from 'models'
import { unique } from 'utils'

type TGroupByAssinedArea = {
  metroplexes: Metroplex[]
  usStates: UsState[]
}

export const getAssignedAreasFromAgentAeAreas = (agentAeAreas: AgentAeArea[]) => {
  const { metroplexes, usStates } = agentAeAreas.reduce<TGroupByAssinedArea>(
    (acc, area) => {
      const key = isMetroplex(area.assignedArea) ? 'metroplexes' : 'usStates'
      return { ...acc, [key]: [...acc[key], area.assignedArea] }
    },
    { usStates: [], metroplexes: [] }
  )

  const metroplexStates = metroplexes.map((metroplex: Metroplex) => metroplex.state).filter(Boolean)

  return {
    metroplexes,
    usStates: unique([...usStates, ...metroplexStates])
  }
}
