// @ts-strict
import { Block } from 'components'
import { isCCCashOfferLead, isCCTradeInLead, Lead } from 'models'
import { DEFAULT_STRING, formatDate } from 'utils'

type TProps = {
  lead: Lead
}

export const OrdersTableRowLeadFundingDate = ({ lead }: TProps) => {
  if (isCCTradeInLead(lead)) {
    const tradeInLead = lead.tradeInProviderLead?.tradeInLead
    return <Block title={formatDate(tradeInLead?.dpActualPurchaseFundedDate, 'short')} />
  }
  if (isCCCashOfferLead(lead)) {
    const cashOfferLead = lead.activeCashOfferLead
    return (
      <Block title={formatDate(cashOfferLead?.incomingPropertyFundsWiredToSellerAt, 'short')} />
    )
  }

  return <>{DEFAULT_STRING}</>
}
