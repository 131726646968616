// @ts-strict
import { Activity } from 'models'
import { BBYSLeadStageUpdateResponse } from 'schema'
import { d } from 'utils'

type TBBYSLeadStageUpdateConstructor = Omit<BBYSLeadStageUpdate, 'toActivity'>

export class BBYSLeadStageUpdate {
  id!: string
  notes?: string
  oldStage?: string
  newStage?: string
  createdAt?: string
  occurredAt?: string
  updatedBy?: string
  ae?: {
    name: string
  }

  constructor(bbysLeadStageUpdateAttributes: TBBYSLeadStageUpdateConstructor) {
    Object.assign(this, bbysLeadStageUpdateAttributes)
  }

  static create(
    bbysLeadStageUpdateResponse: Potential<BBYSLeadStageUpdateResponse>
  ): Potential<BBYSLeadStageUpdate> {
    if (!bbysLeadStageUpdateResponse) {
      return
    }
    return new BBYSLeadStageUpdate(bbysLeadStageUpdateResponse)
  }

  toActivity() {
    return new Activity({
      id: this.createdAt || '',
      title: `Stage Updated by ${d(this.updatedBy)} ${
        this.updatedBy === 'ae' && this.ae?.name ? this.ae.name : ''
      }`,
      body: this.notes,
      transitions: [d(this.oldStage), d(this.newStage)],
      occurredAt: this.createdAt
    })
  }
}
