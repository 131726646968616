// @ts-strict
import type { TableCellProps } from 'components'
import { useListContext } from 'contexts'
import type { TColumnAttributes } from 'lookups'
import { capitalize, getPropertyByString, isNotNull } from 'utils'

export type UseTableCellProps<V = any, AV = any> = TableCellProps & {
  attachedColumnValue?: AV
  slug?: string
  value?: V
}

export const useTable = () => {
  const { findColumn } = useListContext()

  const getSort = (setting?: TColumnAttributes) => {
    if (setting?.sort) {
      return {
        slug: setting.value.split('.')[0],
        name: setting.name
      }
    }
    return undefined
  }

  const getHeaderCellProps = (columnKey: string) => {
    const setting = findColumn(columnKey)!
    const attachedColumn = setting.attachedColumn
    const name = attachedColumn ? `${setting.name} / ${attachedColumn.name}` : setting.name
    const sort = [getSort(setting), getSort(attachedColumn)].filter(isNotNull)
    const firstValueKey = setting.value.split('.')[0]
    return {
      slug: setting.value,
      align: setting.align,
      size: setting.columnSize,
      title: setting.title,
      fixed: setting.fixed,
      name,
      sort,
      'data-testid': `tableHeaderCell${capitalize(firstValueKey)}`
    }
  }

  const getBodyCellProps = <T extends object>(item: T, columnKey: string) => {
    const setting = findColumn(columnKey)!
    const attachedColumn = setting.attachedColumn
    const firstValueKey = setting.value.split('.')[0]
    return {
      slug: setting.value,
      align: setting.align,
      size: setting.columnSize,
      value: getPropertyByString(item, setting.value),
      attachedColumnValue: getPropertyByString(item, attachedColumn?.value),
      'data-testid': `tableCell${capitalize(firstValueKey)}`
    }
  }

  return {
    getHeaderCellProps,
    getBodyCellProps
  }
}
