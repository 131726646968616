import { ReactNode } from 'react'
import { TableBody, TableLoading, TableNoResults } from '../../index'

type TTableResultsProps = {
  children?: ReactNode[]
  colSpan?: number
  isLoading?: boolean
  type?: string
}

export const TableResults = ({
  isLoading = false,
  children,
  type,
  colSpan
}: TTableResultsProps) => {
  if (isLoading || !children) {
    return <TableLoading colSpan={colSpan} type={type} />
  }

  if (!isLoading && children.length === 0) {
    return <TableNoResults colSpan={colSpan} type={type} />
  }

  return <TableBody>{children}</TableBody>
}
