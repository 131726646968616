// @ts-strict
import { FormField, FormFieldSet, Section, Sections, SubSection } from 'components'
import { getMortgageLeadFieldName } from 'lookups'
import { LenderProviderLead } from 'models'
import {
  dataOrDash,
  formatBoolean,
  formatDate,
  formatMoney,
  formatOrDash,
  formatPercentFromDecimal,
  formatPrice,
  titleize
} from 'utils'
import style from '../LenderProviderLead.module.scss'
import { LenderProviderLeadLoanLink } from './LenderProviderLeadLoanLink'

export type TLoanProductsProps = {
  lenderProviderLead: LenderProviderLead
  price?: number
}

export const LenderProviderLeadLoanProducts = ({
  lenderProviderLead,
  price
}: TLoanProductsProps) => {
  const lenderLead = lenderProviderLead?.lenderLead
  const eaveLoanApplicationId = dataOrDash(lenderLead?.eaveLoanApplicationId)
  const loanMilestone = formatOrDash(lenderLead?.loanMilestone, titleize)
  const formatDecimals = (v: string) => formatPercentFromDecimal(v, 3)

  const lenderIsBesi = lenderProviderLead?.lenderLead?.lender?.slug === 'besi'

  return (
    <>
      <SubSection
        title={`${getMortgageLeadFieldName(
          'eaveLoanApplicationId'
        )} ${eaveLoanApplicationId} (${loanMilestone})`}
      >
        <Sections>
          <Section>
            <FormFieldSet>
              <FormField title={getMortgageLeadFieldName('program')} inline>
                {dataOrDash(lenderLead?.program)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('product')} inline>
                {formatOrDash(lenderLead?.product, titleize)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('term')} inline>
                {dataOrDash(lenderLead?.term)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('interestRate')} inline>
                {formatOrDash(lenderLead?.interestRate, formatDecimals)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('propertyCounty')} inline>
                {dataOrDash(lenderLead?.propertyCounty)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('ltv')} inline>
                {formatOrDash(lenderLead?.ltv, formatPercentFromDecimal)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('dtiRatios')} inline>
                {formatOrDash(lenderLead?.dtiRatios, formatPercentFromDecimal)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('comformingLoanLimit')} inline>
                {formatOrDash(lenderLead?.comformingLoanLimit, formatPrice)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('loanAmount')} inline>
                {formatOrDash(lenderLead?.loanAmount, formatPrice)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('calculatedValue')} inline>
                {formatOrDash(price, formatPrice)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('downpaymentAmount')} inline>
                {formatOrDash(lenderLead?.downpaymentAmount, formatPrice)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('actualLoanAmount')} inline>
                {formatOrDash(lenderLead?.actualLoanAmount, formatPrice)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('actualPurchasePrice')} inline>
                {formatOrDash(lenderLead?.actualPurchasePrice, formatPrice)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('loanManagerName')} inline>
                {dataOrDash(lenderLead?.loanManagerName)}
              </FormField>
            </FormFieldSet>
          </Section>
          <Section>
            <LenderProviderLeadLoanLink
              lenderIsBesi={lenderIsBesi}
              updatedAt={lenderLead?.updatedAt}
              loanApplicationLink={lenderLead?.loanApplicationLink}
            />
            <FormField title={getMortgageLeadFieldName('eaveLoanApplicationId')} inline>
              {eaveLoanApplicationId}
            </FormField>
            <FormField title={getMortgageLeadFieldName('lmTaskStage')} inline>
              {formatOrDash(lenderLead?.lmTaskStage, titleize)}
            </FormField>
            <FormField title={getMortgageLeadFieldName('loanDecision')} inline>
              {formatOrDash(lenderLead?.loanDecision, titleize)}
            </FormField>
            <FormField title={getMortgageLeadFieldName('loanMilestone')} inline>
              {loanMilestone}
            </FormField>
            <FormField title={getMortgageLeadFieldName('loanStatus')} inline>
              {formatOrDash(lenderLead?.loanStatus, titleize)}
            </FormField>
            <FormField title={getMortgageLeadFieldName('appraisalExpectedDate')} inline>
              {formatOrDash(lenderLead?.appraisalExpectedDate, formatDate)}
            </FormField>
            <FormField title={getMortgageLeadFieldName('appraisalReceivedDate')} inline>
              {formatOrDash(lenderLead?.appraisalReceivedDate, formatDate)}
            </FormField>
            <FormField title={getMortgageLeadFieldName('targetCoeDate')} inline>
              {formatOrDash(lenderLead?.targetCoeDate, formatDate)}
            </FormField>
            <FormField title={getMortgageLeadFieldName('closeOfEscrowOn')} inline>
              {formatOrDash(lenderLead?.closeOfEscrowOn, formatDate)}
            </FormField>
            <FormField title={getMortgageLeadFieldName('coeDateChangeReason')} inline>
              {dataOrDash(lenderLead?.coeDateChangeReason)}
            </FormField>
            <FormField title={getMortgageLeadFieldName('pinnedNote')} inline>
              {dataOrDash(lenderLead?.pinnedNote)}
            </FormField>
            <FormField title={getMortgageLeadFieldName('archived')} inline>
              {formatOrDash(lenderLead?.archived, formatBoolean)}
            </FormField>
          </Section>
        </Sections>
      </SubSection>
      <SubSection title="Max Loan Product" className={style.maxLoanProduct}>
        <Sections>
          <Section>
            <FormFieldSet>
              <FormField title={getMortgageLeadFieldName('maxPurchasePrice')} inline>
                {formatOrDash(lenderLead?.maxPurchasePrice, formatMoney)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('maxLoanAmount')} inline>
                {formatOrDash(lenderLead?.maxLoanAmount, formatPrice)}
              </FormField>
              <FormField title={getMortgageLeadFieldName('maxDownPayment')} inline>
                {formatOrDash(lenderLead?.maxDownPayment, formatPrice)}
              </FormField>
            </FormFieldSet>
          </Section>
          <Section>
            <FormField
              title={getMortgageLeadFieldName('ltvAtMaxPurchasePriceAndLoanAmount')}
              inline
            >
              {formatOrDash(
                lenderLead?.ltvAtMaxPurchasePriceAndLoanAmount,
                formatPercentFromDecimal
              )}
            </FormField>
            <FormField title={getMortgageLeadFieldName('dtiAtMax')} inline>
              {formatOrDash(lenderLead?.dtiAtMax, formatPercentFromDecimal)}
            </FormField>
          </Section>
        </Sections>
      </SubSection>
    </>
  )
}
