import { TModelFieldSetting } from '../ModelField'

export type TLeadFieldAttribute = 'leadUsersNamesString'

export type TLeadFieldSetting = {
  attr: TLeadFieldAttribute
} & TModelFieldSetting

export const leadFieldsSettings: TLeadFieldSetting[] = [
  { attr: 'leadUsersNamesString', name: 'Team Members' }
]
