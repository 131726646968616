import { getTasksUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export type TPutCompleteTaskVariables = {
  id: string
  payload?: {
    completionNote?: string
  }
}

export const putCompleteTask = async ({ id, payload }: TPutCompleteTaskVariables) => {
  const url = getTasksUrl({ id, action: 'complete' })
  await putRequest(url, snakeCaseKeys(payload))
}
