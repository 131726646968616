import { useState } from 'react'
import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { Button, Flex, Popover, useToastContext } from '../../../../@foundation/components'
import { FormField, TextInput } from '../../../../components'
import { postRequest } from '../../../../utils'
import { BASE_URL, GET_TRANSACTION_OPERATORS_LIST } from '../../pages'
import { PopoverContentContainer } from './styles'

export function CreateOperatorGroupPopover() {
  const queryCache = useQueryClient()
  const toast = useToastContext()

  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState('')

  const createGroupRequest = async () => {
    return postRequest(`${BASE_URL}/lead-data-service/transaction-operator-groups`, {
      name
    })
  }

  const { mutate, isLoading } = useMutation(createGroupRequest, {
    onSuccess: async () => {
      setName('')
      setIsOpen(false)
      await queryCache.invalidateQueries([GET_TRANSACTION_OPERATORS_LIST])
    },
    onError: (error: AxiosError) => {
      console.error(error.response)
      toast({
        title: `Oops! Something went wrong. ${error.response.data.errors}`,
        status: 'error'
      })
    }
  })

  return (
    <Popover.Root placement="bottom-end" isOpen={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger>
        <Button>New group</Button>
      </Popover.Trigger>
      <Popover.Content>
        <PopoverContentContainer>
          <Flex flexDirection="column" gap="$6">
            <FormField title="Group Name">
              <TextInput disabled={isLoading} onChange={setName} data-testid="name" value={name} />
            </FormField>
            <Button onClick={() => mutate()} isLoading={isLoading} hasFullWidth>
              Create new group
            </Button>
          </Flex>
        </PopoverContentContainer>
      </Popover.Content>
    </Popover.Root>
  )
}

export default CreateOperatorGroupPopover
