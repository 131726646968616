// @ts-strict
import { useEffect, useState } from 'react'
import { useButtonForm } from 'components'
import { UseMutationResult } from 'hooks'
import { EscrowLead } from 'models'
import { TEscrowOrderPostPayload } from 'services'
import { validateEmailFormat, validatePresence } from 'utils'
import { parseSubmitAddress } from '../helpers'
import { TEscrowOrderPayload } from '../types'

const initialPayload: TEscrowOrderPayload = {
  address: '',
  buyer: false,
  email: '',
  firstName: '',
  lastName: '',
  name: '',
  orderType: 'escrow',
  secondaryAddress: '',
  transactionCoordinatorEmail: '',
  transactionCoordinatorName: '',
  referringAgentId: '',
  referringAgentName: '',
  serviceOffice: '',
  contractReceivedDate: ''
}

type TCreateOrderFormParams = {
  mutation: UseMutationResult<Potential<EscrowLead>, unknown, TEscrowOrderPostPayload, unknown>
  onSubmit: (lead: Potential<EscrowLead>) => Promise<void>
}

export const useCreateEscrowOrderForm = ({ mutation, onSubmit }: TCreateOrderFormParams) => {
  const [validations, setValidations] = useState({})

  const transformPayload = (payload: TEscrowOrderPayload): TEscrowOrderPostPayload => {
    const address = parseSubmitAddress(payload)

    const params = {
      parent_lead_id: payload.parentLeadId,
      data: {
        type: 'lead',
        attributes: {
          lead_attributes: {
            address,
            email: payload.email,
            first_name: payload.firstName,
            last_name: payload.lastName,
            name: payload.name,
            sales_notes: payload.notes,
            phone: payload.phone,
            referring_agent_id: payload.referringAgentId || null,
            source_form: 'create_escrow_order',
            source_page_type: 'sales_app',
            source: 'web'
          },
          seller_coordination: payload.orderType === 'seller_coordination',
          buyer: payload.buyer,
          refinance: payload.orderType === 'refinance',
          files: payload.files || [],
          service_office_id: payload.serviceOffice,
          started_as_pre_escrow: payload.orderType === 'pre_escrow',
          transaction_coordinator_email: payload.transactionCoordinatorEmail,
          transaction_coordinator_name: payload.transactionCoordinatorName
        }
      }
    } as TEscrowOrderPostPayload

    if (payload.agentFullName) {
      params.data.attributes = {
        ...params.data.attributes,
        agent_full_name: payload.agentFullName || undefined,
        agent_phone: payload.agentPhone || undefined,
        agent_email: payload.agentEmail || undefined,
        agent_state: payload.agentState || undefined,
        agent_brokerage: payload.agentBrokerage || undefined,
        agent_team_lead_name: payload.agentTeamLeadName || undefined,
        agent_license_number: payload.agentLicenseNumber || undefined,
        notes_to_ae: payload.notesToAe || undefined
      }
    }

    return params as TEscrowOrderPostPayload
  }

  const {
    formProps,
    fieldsProps: { payload, onChangeAttribute }
  } = useButtonForm<TEscrowOrderPayload, Potential<EscrowLead>>({
    initialPayload,
    mutation,
    callback: onSubmit,
    formValidations: validations,
    transformPayload
  })

  useEffect(() => {
    let newValidations = {}

    if (payload.orderType === 'pre_escrow') {
      newValidations = {
        address: validatePresence,
        firstName: validatePresence,
        lastName: validatePresence,
        name: validatePresence,
        referringAgentId: validatePresence,
        orderType: validatePresence,
        transactionCoordinatorEmail: validateEmailFormat,
        transactionCoordinatorName: validatePresence
      }
    } else {
      newValidations = {
        address: validatePresence,
        firstName: validatePresence,
        lastName: validatePresence,
        name: validatePresence,
        referringAgentId: validatePresence,
        orderType: validatePresence,
        contractReceivedDate: validatePresence,
        transactionCoordinatorEmail: [validateEmailFormat],
        transactionCoordinatorName: validatePresence
      }
    }

    if (payload.agentFullName) {
      newValidations = {
        ...newValidations,
        referringAgentId: null,
        agentFullName: validatePresence,
        agentPhone: validatePresence,
        agentEmail: [validateEmailFormat, validatePresence],
        agentState: validatePresence,
        agentLicenseNumber: validatePresence,
        agentBrokerage: validatePresence
      }
    }

    setValidations(newValidations)
  }, [payload])

  return {
    onChangeAttribute,
    formProps,
    payload
  }
}
