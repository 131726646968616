type Params = {
  [key: string]: any
}

export const normalizeObject = (params: Params = {}): Params => {
  // we don't want to process non-object params when recursively running this function:
  if (typeof params !== 'object' || !params) {
    return params
  }

  return Object.entries(params).reduce((obj, [k, v]) => {
    let value = v

    if (!!obj && typeof value === 'object' && value instanceof Object) {
      // If it's an array, check that array items
      if (Array.isArray(value)) {
        value = value
          .map(item => {
            // Run normalizeObject on an array's items, but not on other types
            if (typeof item === 'object' && item instanceof Object && !Array.isArray(item)) {
              return normalizeObject(item)
            }
            return item
          })
          .sort()
      } else if (value?.constructor === Object) {
        value = normalizeObject(v as Params)
      }
    }

    return {
      ...obj,
      [k]: value
    }
  }, {})
}
