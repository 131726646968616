import { User } from 'models'
import { getUsersUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

// This is deprecated. Please, use the `User/fetchUsers.ts` service
export const fetchUsersLegacy = async (searchParams: {}): Promise<User[]> => {
  const response = await getRequest(
    getUsersUrl({
      searchParams: {
        include: 'roles',
        'fields[user]': 'created_at,email,name,roles',
        'fields[role]': 'name',
        ...searchParams
      }
    })
  )
  return await deserializeAsync(response.data, { transform: User.create })
}
