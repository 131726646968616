import { ReactNode, useEffect, useRef, useState } from 'react'
import { useWindowScroll } from 'react-use'
import { cx } from 'utils'
import styles from './Sticky.module.scss'

export const Sticky = ({ children, className }: { children: ReactNode; className?: string }) => {
  const { y } = useWindowScroll()
  const [style, setStyle] = useState(styles.normal)
  const elementRef = useRef<HTMLDivElement>()
  const placeholderRef = useRef<HTMLSpanElement>()

  useEffect(() => {
    const scrolledAbove =
      placeholderRef.current &&
      placeholderRef.current.getBoundingClientRect().top <= 0 &&
      elementRef.current &&
      elementRef.current.getBoundingClientRect().top <= 0
    if (scrolledAbove) {
      setStyle(styles.fixed)
    } else {
      setStyle(styles.normal)
    }
  }, [y])

  return (
    <>
      <span className={styles.placeholder} ref={placeholderRef} />
      <div ref={elementRef} className={cx(style, className)}>
        {children}
      </div>
    </>
  )
}
