// @ts-strict
import { useLookupsController } from 'controllers/Lookups'
import {
  CLIENT_ADVISOR_TEAM_ROLE_CACHE_KEY,
  SETTLEMENT_AGENCY_TEAM_ROLE_CACHE_KEY,
  TRANSACTION_TEAM_ROLE_CACHE_KEY
} from 'hooks'
import { TeamType } from 'models'

/* Gets the Team Roles based on team type. */
export const useTeamRoles = (teamType: TeamType) => {
  const CACHE_KEY_BY_TEAM_TYPE = {
    ClientAdvisoryTeam: CLIENT_ADVISOR_TEAM_ROLE_CACHE_KEY,
    SettlementAgencyTeam: SETTLEMENT_AGENCY_TEAM_ROLE_CACHE_KEY,
    TransactionTeam: TRANSACTION_TEAM_ROLE_CACHE_KEY
  }

  const [unsortedTeamRoles, findTeamRole] = useLookupsController(CACHE_KEY_BY_TEAM_TYPE[teamType])

  const teamRoles = unsortedTeamRoles?.sort((a, b) => {
    if (a.description && b.description) {
      return a.description > b.description ? 1 : b.description > a.description ? -1 : 0
    }

    return 0
  })

  return {
    teamRoles,
    findTeamRole
  }
}
