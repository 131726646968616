// @ts-strict

import { TEAMS_CACHE_KEY, useMutation, useQuery, useQueryClient } from 'hooks'
import { TeamType } from 'models'
import { fetchTeams, postTeam } from 'services'

export const useTeamsController = (type: TeamType = 'TransactionTeam') => {
  const queryCache = useQueryClient()

  const searchSettings = { type }
  const cacheKeys = [TEAMS_CACHE_KEY, searchSettings]

  const teamsQuery = useQuery(cacheKeys, () => fetchTeams(searchSettings))

  const defaultCallback = {
    onSuccess: () => queryCache.invalidateQueries(cacheKeys)
  }

  const createTeam = useMutation((payload: {}) => postTeam(payload), defaultCallback)

  return { teamsQuery, createTeam }
}
