// @ts-strict
import { TableHeaderColumn as TH, TableHeaderRow as Tr } from 'components'
import styles from './AgentAeAreasTable.module.scss'

export const AgentAeAreasTableHeader = () => (
  <Tr>
    <TH className={styles.area} sort={[{ name: 'Area', slug: 'AreaName' }]} />
    <TH className={styles.state} name="State" />
    <TH className={styles.asm} name="ASM" />
    <TH className={styles.ase} name="ASE" />
    <TH className={styles.agents} name="# Agents" />
    <TH className={styles.programs} name="Programs" />
  </Tr>
)
