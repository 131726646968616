// @ts-strict

import { MutableRefObject } from 'react'
import { css, styled } from '@foundation/themes/styles'
import { Coaster as CoasterWrapper } from 'components'

type CoasterProps = {
  ref?: MutableRefObject<HTMLDivElement | undefined>
}

export const WarmTransferWrapper = styled.div`
  margin-left: ${({ theme }) => theme.space.$2};
  position: relative;
`

export const Coaster = styled(CoasterWrapper)<CoasterProps>`
  max-width: 40rem;
  right: 0;

  p {
    white-space: pre;
  }
`

export const ButtonWrapper = styled.div`
  text-align: center;
`

export const MenuTitle = styled.div`
  ${({ theme }) => css`
    border-bottom: ${theme.borderWidths.thin} solid ${theme.colors.neutral3};
    margin-bottom: ${theme.space.$3};
    padding-bottom: ${theme.space.$2};
  `}

  width: 100%;
  p {
    margin: 0;
  }
`
