// @ts-strict
import { useEffect, useState } from 'react'
import {
  ActionButton,
  AddressUnitAutoComplete,
  AgentsByProgramAutoComplete,
  DateInput,
  EmailInput,
  FormField,
  FormFieldSet,
  MoneyInput,
  PhoneInput,
  RadioTabs,
  Section,
  Sections,
  Select,
  TextInput
} from 'components'
import { LoanOfficersAutoComplete } from 'components/AutoComplete/LoanOfficersAutoComplete'
import { ConditionalSection } from 'components/ConditionalSection'
import { CreateLoanOfficerButtonForm } from 'content/LoanOfficer'
import { usePropertiesController } from 'controllers/Property'
import { yesOrNoOptions } from 'lookups'
import { usePartners } from 'lookups/lookupPartners'
import { formatPhone } from 'utils'
import styles from '../CreateOrderForm.module.scss'
import { TFormItemsProps } from '../types'

export const BbysFormItems = ({
  payload,
  onChangeAttribute,
  marketplaceProgramsServerSlugsParams,
  formProps,
  hideBbysForm
}: TFormItemsProps) => {
  const [lookupAddress, setLookupAddress] = useState('')
  const [loanOfficerDisplay, setLoanOfficerDisplay] = useState('')
  const { data: propertyLookupData } = usePropertiesController(lookupAddress, {
    onError: () => {
      onChangeAttribute('propertyUuid', 'not_found')
    }
  })

  const { partners } = usePartners()

  useEffect(() => {
    if (propertyLookupData?.uuid) {
      onChangeAttribute('propertyUuid', propertyLookupData.uuid)
    }
  }, [onChangeAttribute, propertyLookupData])

  if (hideBbysForm) {
    return null
  }

  const AgentFormFields = (
    <>
      <Sections>
        <Section>
          <FormField
            title="Agent Full Name"
            error={formProps?.errors?.agentName}
            htmlFor="agent_name"
          >
            {payload.referringOwnerType === 'Loan Officer' ? (
              <AgentsByProgramAutoComplete
                allowDefaultValue
                onBlur={(_e, value) => onChangeAttribute('agentName', value)}
                name="agent_name"
                marketplaceProgramServerSlugs={marketplaceProgramsServerSlugsParams || {}}
                onSelect={agent => {
                  if (!agent) {
                    return
                  }

                  onChangeAttribute('agentName', agent?.name)
                  onChangeAttribute('agentEmail', agent?.email)

                  if (agent.phoneNumbers && agent.phoneNumbers?.length) {
                    onChangeAttribute('agentPhone', formatPhone(agent?.phoneNumbers[0]?.number))
                  }
                }}
                onClear={() => {
                  onChangeAttribute('agentName', undefined)
                }}
              />
            ) : (
              <TextInput
                id="agent_name"
                onChange={value => {
                  onChangeAttribute('agentName', value)
                }}
                value={payload?.agentName}
              />
            )}
          </FormField>
        </Section>
        <Section>
          <FormField
            title="Agent Phone Number"
            error={formProps?.errors?.agentPhone}
            htmlFor="agent_phone"
          >
            <PhoneInput
              id="agent_phone"
              onChange={value => {
                onChangeAttribute('agentPhone', value)
              }}
              value={payload?.agentPhone}
            />
          </FormField>
        </Section>
      </Sections>

      <Sections>
        <Section>
          <FormField
            title="Agent Email"
            htmlFor="agent_email"
            error={formProps?.errors?.agentEmail}
          >
            <EmailInput
              id="agent_email"
              onChange={value => {
                onChangeAttribute('agentEmail', value)
              }}
              value={payload?.agentEmail}
            />
          </FormField>
        </Section>
      </Sections>
    </>
  )

  return (
    <Sections>
      <Section>
        {/* Client section */}
        <FormFieldSet title="Client Details">
          <Sections>
            <Section>
              <FormField
                title="Primary Client Full Name"
                error={formProps?.errors?.primaryClientName}
                htmlFor="primary_client_name"
                required
              >
                <TextInput
                  id="primary_client_name"
                  onChange={value => {
                    onChangeAttribute('primaryClientName', value)
                  }}
                  value={payload?.primaryClientName}
                />
              </FormField>
            </Section>
          </Sections>
          <Sections>
            <Section>
              <FormField
                title="Primary Client Email"
                error={formProps?.errors?.primaryClientEmail}
                htmlFor="primary_client_email"
                required
              >
                <EmailInput
                  id="primary_client_email"
                  onChange={value => {
                    onChangeAttribute('primaryClientEmail', value)
                  }}
                  value={payload?.primaryClientEmail}
                />
              </FormField>
            </Section>
            <Section>
              <FormField
                title="Primary Client Phone"
                error={formProps?.errors?.primaryClientPhone}
                htmlFor="primary_client_phone"
              >
                <PhoneInput
                  id="primary_client_phone"
                  onChange={value => {
                    onChangeAttribute('primaryClientPhone', value)
                  }}
                  value={payload?.primaryClientPhone}
                />
              </FormField>
            </Section>
          </Sections>
          <ConditionalSection
            showButtonText="Add client (e/g. spouse/partner}"
            hideButtonText="Remove client"
            headerClassName={styles.addClientHeader}
            footerClassName={styles.addClientFooter}
          >
            <Sections>
              <Section>
                <FormField
                  title="Additional Client Full Name"
                  error={formProps?.errors?.additionalClientName}
                  htmlFor="additional_client_name"
                >
                  <TextInput
                    id="additional_client_name"
                    onChange={value => {
                      onChangeAttribute('additionalClientName', value)
                    }}
                    value={payload?.additionalClientName}
                  />
                </FormField>
              </Section>
            </Sections>
            <Sections>
              <Section>
                <FormField
                  title="Additional Client Email"
                  error={formProps?.errors?.additionalClientEmail}
                  htmlFor="additional_client_email"
                >
                  <EmailInput
                    id="additional_client_email"
                    onChange={value => {
                      onChangeAttribute('additionalClientEmail', value)
                    }}
                    value={payload?.additionalClientEmail}
                  />
                </FormField>
              </Section>
              <Section>
                <FormField
                  title="Additional Client Phone"
                  error={formProps?.errors?.additionalClientPhone}
                  htmlFor="additional_client_phone"
                >
                  <PhoneInput
                    id="additional_client_phone"
                    onChange={value => {
                      onChangeAttribute('additionalClientPhone', value)
                    }}
                    value={payload?.additionalClientPhone}
                  />
                </FormField>
              </Section>
            </Sections>
          </ConditionalSection>
          <Sections>
            <Section>
              <FormField
                title="Is the client under contract for a new property?"
                error={formProps?.errors?.underContract}
              >
                <></>
              </FormField>
            </Section>
            <Section>
              <RadioTabs
                value={payload?.underContract}
                tabClassName={styles.radioTab}
                onChange={value => {
                  if (value === 'false') {
                    onChangeAttribute('newPropertyCloseOfEscrowDate', undefined)
                  }

                  onChangeAttribute('underContract', value)
                }}
                options={yesOrNoOptions.map(opt => ({
                  label: opt.name as string,
                  value: opt.value as string
                }))}
              />
            </Section>
          </Sections>
          {payload?.underContract === 'true' && (
            <Sections>
              <Section>
                <FormField
                  title="What is the close of escrow date?"
                  htmlFor="new_property_close_of_escrow_date"
                  error={formProps?.errors?.underContract}
                >
                  <></>
                </FormField>
              </Section>
              <Section>
                <DateInput
                  id="new_property_close_of_escrow_date"
                  value={payload.newPropertyCloseOfEscrowDate}
                  onChange={val => onChangeAttribute('newPropertyCloseOfEscrowDate', val)}
                />
              </Section>
            </Sections>
          )}
        </FormFieldSet>

        {/* Departing residence section */}
        <FormFieldSet title="Departing Residence">
          <Sections>
            <Section>
              <FormField
                title="Departing Residence Address"
                error={
                  formProps?.errors?.departingPropertyFullAddress || formProps?.errors?.propertyUuid
                }
                htmlFor="property-autocomplete"
                required
              >
                <AddressUnitAutoComplete
                  data-testid="property-autocomplete"
                  onSelect={val => {
                    onChangeAttribute('propertyUuid', '')
                    setLookupAddress(val as string)
                    onChangeAttribute('departingPropertyFullAddress', val)
                  }}
                  onClear={() => {
                    onChangeAttribute('propertyUuid', '')
                    onChangeAttribute('departingPropertyFullAddress', '')
                  }}
                  placeholder="Enter property address"
                />
              </FormField>
              <FormField
                title="Estimated Home Value"
                error={formProps?.errors?.estimatedHomeValue}
                htmlFor="estimated_home_value"
              >
                <MoneyInput
                  id="estimated_home_value"
                  onChange={value => {
                    onChangeAttribute('estimatedHomeValue', value)
                  }}
                  value={payload?.estimatedHomeValue}
                />
              </FormField>
            </Section>
          </Sections>
          <Sections>
            <Section>
              <FormField
                title="Target Equity Unlock Amount"
                error={formProps?.errors?.targetEquityUnlockAmount}
                htmlFor="target_equity_unlock_amount"
              >
                <MoneyInput
                  id="target_equity_unlock_amount"
                  onChange={value => {
                    onChangeAttribute('targetEquityUnlockAmount', value)
                  }}
                  value={payload?.targetEquityUnlockAmount}
                />
              </FormField>
            </Section>
          </Sections>
          <Sections>
            <Section>
              <FormField
                title="Mortgage Balance"
                error={formProps?.errors?.mortgageBalance}
                htmlFor="mortgage_balance"
              >
                <MoneyInput
                  id="mortgage_balance"
                  onChange={value => {
                    onChangeAttribute('mortgageBalance', value)
                  }}
                  value={payload?.mortgageBalance}
                />
              </FormField>
            </Section>
          </Sections>
        </FormFieldSet>

        {/* Loan officer section */}
        <FormFieldSet title="Loan Officer">
          <Sections>
            <Section>
              <FormField
                title="Loan Officer Full Name"
                htmlFor="loan_officer_name"
                error={formProps?.errors?.loanOfficerName}
                hint={
                  payload.referringOwnerType === 'Agent' ? (
                    <span>
                      Can't find the loan officer?{' '}
                      <CreateLoanOfficerButtonForm
                        onSuccess={(loanOfficer, params) => {
                          setLoanOfficerDisplay(loanOfficer.name)
                          onChangeAttribute('referringOwnerId', loanOfficer.userId)
                          onChangeAttribute('lenderCompanyName', loanOfficer.companyName)
                          onChangeAttribute('loanOfficerName', loanOfficer.name)
                          onChangeAttribute('loanOfficerEmail', loanOfficer.email)
                          onChangeAttribute(
                            'loanOfficerPhone',
                            formatPhone(params?.loanOfficerPhone)
                          )
                        }}
                        title="Create a new loan officer"
                        buttonComponent={ActionButton}
                      />
                    </span>
                  ) : null
                }
                required
              >
                {payload.referringOwnerType === 'Agent' ? (
                  <LoanOfficersAutoComplete
                    allowDefaultValue
                    onBlur={(_e, value) => onChangeAttribute('loanOfficerName', value)}
                    onSelect={loanOfficer => {
                      if (!loanOfficer) {
                        return
                      }

                      setLoanOfficerDisplay(loanOfficer.name)
                      onChangeAttribute('lenderCompanyName', loanOfficer.companyName)
                      onChangeAttribute('loanOfficerName', loanOfficer.name)
                      onChangeAttribute('loanOfficerEmail', loanOfficer.email)
                      onChangeAttribute(
                        'loanOfficerPhone',
                        formatPhone(loanOfficer.phoneNumber?.number)
                      )
                    }}
                    onClear={() => {
                      setLoanOfficerDisplay('')
                      onChangeAttribute('loanOfficerName', undefined)
                    }}
                    value={loanOfficerDisplay}
                  />
                ) : (
                  <TextInput
                    id="loan_officer_name"
                    onChange={value => {
                      onChangeAttribute('loanOfficerName', value)
                    }}
                    value={payload?.loanOfficerName}
                  />
                )}
              </FormField>
            </Section>
          </Sections>

          <Sections>
            <Section>
              <FormField
                title="Loan Officer Email Address"
                error={formProps?.errors?.loanOfficerEmail}
                htmlFor="loan_officer_email"
                required
              >
                <EmailInput
                  id="loan_officer_email"
                  onChange={value => {
                    onChangeAttribute('loanOfficerEmail', value)
                  }}
                  value={payload?.loanOfficerEmail}
                />
              </FormField>
            </Section>
            <Section>
              <FormField
                title="Loan Officer Phone Number"
                error={formProps?.errors?.loanOfficerPhone}
                htmlFor="loan_officer_phone"
                required
              >
                <PhoneInput
                  id="loan_officer_phone"
                  onChange={value => {
                    onChangeAttribute('loanOfficerPhone', value)
                  }}
                  value={payload?.loanOfficerPhone}
                />
              </FormField>
            </Section>
          </Sections>
          <Sections>
            <Section>
              <FormField
                title="Lender"
                error={formProps?.errors?.lenderCompanyName}
                required
                htmlFor="lender_company_name"
              >
                <TextInput
                  id="lender_company_name"
                  required
                  onChange={value => {
                    onChangeAttribute('lenderCompanyName', value)
                  }}
                  value={payload?.lenderCompanyName}
                />
              </FormField>
            </Section>
            <Section>
              <FormField
                title="Partner"
                error={formProps?.errors?.partnerSlug}
                htmlFor="partner_slug"
              >
                <Select
                  id="partner_slug"
                  onChange={val => onChangeAttribute('partnerSlug', val)}
                  value={payload.partnerSlug}
                  options={partners.map(partner => ({
                    value: partner?.slug,
                    label: partner?.name || partner?.slug
                  }))}
                  addBlank
                />
              </FormField>
            </Section>
          </Sections>
        </FormFieldSet>

        {/* Agent section */}
        <FormFieldSet
          title={payload.referringOwnerType === 'Loan Officer' ? '' : 'Departing Residence Agent'}
        >
          {payload.referringOwnerType === 'Loan Officer' ? (
            <ConditionalSection
              title="Departing Residence Agent (Optional)"
              showButtonText="Add agent"
              hideButtonText="Remove agent"
              defaultShow={!!payload.agentName}
              headerClassName={styles.addClientHeader}
              footerClassName={styles.addClientFooter}
            >
              {AgentFormFields}
            </ConditionalSection>
          ) : (
            AgentFormFields
          )}
        </FormFieldSet>
      </Section>
    </Sections>
  )
}
