// @ts-strict
import { Link } from 'react-router-dom'
import { Employee } from 'models'
import { DEFAULT_STRING } from 'utils/constants'

type Props = {
  attribute?: string
  employee: Employee
  link?: string
}

export const EmployeeLink = ({ employee, attribute = 'name', link }: Props) => {
  const dashes = <>{DEFAULT_STRING}</>

  if (!employee) {
    return dashes
  }

  const text = employee[attribute]?.trim()
  const path = link || employee.link.pathname

  return text ? <Link to={path}>{text}</Link> : dashes
}
