import { forwardRef } from 'react'
import * as S from './styles'
import { BoxProps } from './types'

export const Box = forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => {
  return (
    <S.Box data-testid="box" {...props} ref={ref}>
      {children}
    </S.Box>
  )
})
