import { Agent } from 'models'
import { AgentSchema, getHapiRequestParams } from 'schema'
import { getAgentsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const agentPayPerLeadSettingsShape: AgentSchema = {
  agent: [
    'id',
    {
      pplProviderSettings: ['active', 'areaType', 'location', 'price', 'userType']
    },
    {
      pplBillingInfos: ['monthlyCap', 'buyerMonthlyCap', 'billingCycleStartDate']
    },
    {
      pplProviderAgreements: ['acceptedAt']
    },
    {
      lastSignedAgreement: [
        'signedAt',
        {
          agreement: ['commissionSplit', 'version']
        }
      ]
    }
  ]
}

export const fetchAgentPayPerLeadSettings = async (agentId: string) => {
  const url = getAgentsUrl({
    id: agentId,
    searchParams: getHapiRequestParams(agentPayPerLeadSettingsShape)
  })

  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: Agent.create })
}
