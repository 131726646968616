import { useEffect } from 'react'
import { Contents } from 'components'
import { AgentMatchingLeadDetails } from 'content/AgentMatchingLead'
import {
  CCBBYSLeadDetails,
  CCCashOfferLeadDetails,
  CCTradeInLeadDetails,
  EscrowLeadDetails,
  HLSSLeadDetails,
  LeadDetails,
  MortgageLeadDetails
} from 'content/Lead'
import { MapProvider, useLeadContext } from 'contexts'
import { useDocumentTitle } from 'hooks'
import { TLeadUserTypeServerSlug } from 'lookups'
import {
  isAgentMatchingLead,
  isCCBBYSLead,
  isCCCashOfferLead,
  isCCHLSimpleSaleLead,
  isCCTradeInLead,
  isEscrowLead,
  isMortgageLead
} from 'models'
import { d } from 'utils'

const LeadDetailsContents = () => {
  const {
    partners,
    lead,
    runPropertyQualification,
    runMortgageAffordability,
    updateLead,
    updateOrder,
    updateTradeInLead,
    updateBBYSLead,
    updateLenderLead,
    createCashOffer
  } = useLeadContext()

  if (isAgentMatchingLead(lead)) {
    return <AgentMatchingLeadDetails />
  }
  if (isCCBBYSLead(lead)) {
    return (
      <CCBBYSLeadDetails
        lead={lead}
        runPropertyQualification={runPropertyQualification}
        updateLead={updateLead.mutateAsync}
        updateOrder={updateOrder}
        updateBBYSLead={updateBBYSLead}
        updateLenderLead={updateLenderLead}
        partners={partners}
      />
    )
  }
  if (isCCTradeInLead(lead)) {
    return (
      <CCTradeInLeadDetails
        lead={lead}
        runPropertyQualification={runPropertyQualification}
        updateLead={updateLead.mutateAsync}
        updateOrder={updateOrder}
        updateTradeInLead={updateTradeInLead}
        updateLenderLead={updateLenderLead}
      />
    )
  }
  if (isCCCashOfferLead(lead)) {
    return (
      <CCCashOfferLeadDetails
        lead={lead}
        updateLead={updateLead.mutateAsync}
        updateOrder={updateOrder}
        createCashOffer={createCashOffer}
        runMortgageAffordability={runMortgageAffordability}
      />
    )
  }
  if (isMortgageLead(lead)) {
    return <MortgageLeadDetails lead={lead} updateLead={updateLead.mutateAsync} />
  }
  if (isEscrowLead(lead)) {
    return <EscrowLeadDetails lead={lead} />
  }
  if (isCCHLSimpleSaleLead(lead)) {
    return (
      <HLSSLeadDetails lead={lead} updateLead={updateLead.mutateAsync} updateOrder={updateOrder} />
    )
  }
  return <LeadDetails lead={lead} />
}

export const LeadDetailsTab = ({ userType }: { userType?: TLeadUserTypeServerSlug }) => {
  const { lead } = useLeadContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${lead.name} - ${d(lead.userType)} Lead`), [lead, setTitle])

  if (userType !== lead?.userType) {
    return null
  }

  return (
    <Contents>
      <MapProvider center={lead.toPoint?.()} markers={[lead.toMarker?.('yellow')]}>
        <LeadDetailsContents />
      </MapProvider>
    </Contents>
  )
}
