import { ReactNode } from 'react'
import { createCtx } from 'contexts'
import { useCommsFilters } from './useCommsFilters'

export const [useCommsFiltersContext, CommsFiltersContextProvider] = createCtx<
  ReturnType<typeof useCommsFilters>
>({
  errorMessage:
    'CommsFiltersProvider: `context` is undefined. Seems you forgot to wrap all comms filters components within `<CommsFiltersProvider />`'
})

type Props = {
  children: ReactNode
}

export const CommsFiltersProvider = ({ children }: Props) => {
  return (
    <CommsFiltersContextProvider value={useCommsFilters()}>{children}</CommsFiltersContextProvider>
  )
}
