import { ModelField } from '../ModelField'
import { THLSimpleSaleLeadFieldAttribute } from './hlSimpleSaleLeadFieldsSettings'

export class HLSimpleSaleLeadField extends ModelField {
  attr!: THLSimpleSaleLeadFieldAttribute

  constructor(attributes: HLSimpleSaleLeadField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
