// @ts-strict
import { validateEmailFormat, validatePhoneFormat, validatePresence } from 'utils/validations'
import { TFormValidation } from 'utils/validations'

export const agentFormValidations: TFormValidation = {
  firstName: { label: 'First Name', validations: [validatePresence] },
  lastName: { label: 'Last Name', validations: [validatePresence] },
  phoneMobile: { label: 'Cell Number', validations: [validatePhoneFormat, validatePresence] },
  phoneOffice: { label: 'Office Number', validations: [validatePhoneFormat] },
  email1: { label: 'Email', validations: [validateEmailFormat, validatePresence] },
  email2: { label: 'Secondary Email', validations: [validateEmailFormat] },
  licenseNumber: { label: 'License Number', validations: [validatePresence] },
  stateId: { label: 'License State', validations: [validatePresence] },
  brokerage: { label: 'Brokerage', validations: [validatePresence] },
  assignedAreaId: { label: 'Assigned Area', validations: [validatePresence] }
}
