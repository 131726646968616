import { useQuery } from 'hooks'
import { fetchInspectionDeficiencies } from 'services'
import type { FetchInspectionDeficienciesProps } from 'services'
import { INSPECTION_DEFICIENCIES_CACHE_KEY } from '../cache'

export const useInspectionDeficienciesController = (settings: FetchInspectionDeficienciesProps) => {
  const inspectionDeficienciesQuery = useQuery([INSPECTION_DEFICIENCIES_CACHE_KEY, settings], () =>
    fetchInspectionDeficiencies(settings)
  )

  return { inspectionDeficienciesQuery }
}
