// @ts-strict
import { CLIENTS_CACHE_KEY, useQuery } from 'hooks'
import { Client } from 'models/Client'
import { fetchClient } from 'services'

export const useClientController = (clientId?: string) => {
  const clientQuery = useQuery<Client>(
    [CLIENTS_CACHE_KEY, clientId],
    () => fetchClient(clientId!),
    {
      enabled: !!clientId,
      refetchOnWindowFocus: false
    }
  )

  return {
    clientQuery
  }
}
