import { Contents, Page, PaginationParams } from 'components'
import { OrdersTable } from 'content/Orders'
import { OrdersListHeader } from 'content/Orders/OrdersList/OrdersListHeader'
import { ListProvider, OrdersProvider, useOrdersContext } from 'contexts'
import { useDocumentTitle } from 'hooks'
import { closingServiceOrdersListColumns, useOrdersListSearchFilters } from 'lookups'

const PageContents = () => {
  const { orders, pageCount, isLoading } = useOrdersContext()

  return (
    <Page>
      <Contents title="Closing Services Orders">
        <OrdersListHeader />
        <OrdersTable orders={orders} isLoading={isLoading} />
        {pageCount ? <PaginationParams totalPages={pageCount} /> : null}
      </Contents>
    </Page>
  )
}

export const ClosingServicesOrdersPage = () => {
  useDocumentTitle('Closing Services Orders')
  const filters = useOrdersListSearchFilters()

  return (
    <OrdersProvider rootPath="/closing-services" scope="closing_services">
      <ListProvider columns={closingServiceOrdersListColumns} filters={filters}>
        <PageContents />
      </ListProvider>
    </OrdersProvider>
  )
}
