import { Link } from 'react-router-dom'
import { Dev, List, Section } from 'components'
import { useSavedSearchesContext } from 'contexts'

export const DevelopmentSection = () => {
  const { getLastSearchUrl } = useSavedSearchesContext()
  return (
    <Dev>
      <Section title="Development">
        <List>
          <Link to="/dev/icons">Icons</Link>
          <Link to="/settings">Settings</Link>
        </List>
        <Section>
          <List withMargin title="Orders Lists">
            <Link to={getLastSearchUrl('/orders')}>All Orders</Link>
            <Link to={getLastSearchUrl('/closing-services')}>Closing Services</Link>
            <Link to={getLastSearchUrl('/home-loans')}>Home Loans</Link>
            <Link to={getLastSearchUrl('/simple-sale')}>Simple Sale</Link>
          </List>
        </Section>
      </Section>
    </Dev>
  )
}
