// @ts-strict

import { AgentAutoCompleteResponse, AutoCompleteProps } from 'components'
import {
  AgentsAutoCompleteV2,
  TAgentAutoCompleteResponse
} from 'components/AutoComplete/AgentsAutoCompleteV2'
import { useMap } from 'hooks'
import { MarketplaceProgramAgentEnrollStatus } from 'models/MarketplaceProgramAgent/utils'

type TProps = {
  id?: string
  marketplaceProgramServerSlugs: Partial<Record<string, MarketplaceProgramAgentEnrollStatus>>
  name: string
  onClear?: () => void
  onSelect: (agent: TAgentAutoCompleteResponse) => void
} & AutoCompleteProps

export const AgentsByProgramAutoComplete = ({
  marketplaceProgramServerSlugs,
  name,
  onClear,
  onSelect,
  id,
  ...props
}: TProps) => {
  const [agents, { set: setAgent }] = useMap()

  const handleSelectAgent = (field: string, agent: AgentAutoCompleteResponse) => {
    onSelect(agent)
    setAgent(field, agent?.title)
  }

  const getAgentProps = (field: string) => ({
    onSelect: (agent: TAgentAutoCompleteResponse) => handleSelectAgent(field, agent),
    onClear,
    value: agents[field],
    searchParams: marketplaceProgramServerSlugs,
    ...props
  })

  return <AgentsAutoCompleteV2 id={id} {...getAgentProps(name)} />
}
