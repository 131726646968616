// @ts-strict

import { useMemo } from 'react'
import { useLocation } from 'react-use'
import { Contents, ListSearchHeader, Page, PaginationParams, ViewsMenu } from 'components'
import { CCTradeInListFilters } from 'content/CCTradeInLead'
import { CCTradeInLeadsTable } from 'content/CCTradeInLeads'
import { CreateOrderButtonForm } from 'content/Order'
import { ListProvider } from 'contexts'
import {
  CC_TRADE_IN_LEADS_CACHE_KEY,
  useCCTradeInLeadsController,
  useCreateOrderController
} from 'controllers'
import { useDocumentTitle, useFeatureFlags } from 'hooks'
import { ccTradeInLeadsListColumns, useCCTradeInLeadSearchFilter } from 'lookups'
import { queryStringToObject } from 'utils'

const PAGE_TITLE = 'Buy Before You Sell Leads (legacy TI)'

const actions = (
  <>
    <CCTradeInListFilters />
    <ViewsMenu />
  </>
)

const useCCTradeInLeadsPage = () => {
  useDocumentTitle(PAGE_TITLE)
  const { search } = useLocation()
  const searchParams = queryStringToObject(search)

  const { ccTradeInLeadsQuery } = useCCTradeInLeadsController(searchParams)

  return {
    ccTradeInLeadsQuery
  }
}

export const CCTradeInLeadsPage = () => {
  const { ccTradeInLeadsQuery } = useCCTradeInLeadsPage()
  const { data, isLoading } = ccTradeInLeadsQuery
  const { items } = data || {}
  const ccTradeInLeadFilters = useCCTradeInLeadSearchFilter()
  const { createOrder, handleOrderCreated } = useCreateOrderController({
    clearCacheKey: CC_TRADE_IN_LEADS_CACHE_KEY
  })
  const { salesAppImprovement } = useFeatureFlags(['sales-app-improvement'])

  const action = <CreateOrderButtonForm mutation={createOrder} onSubmit={handleOrderCreated} />

  const columns = useMemo(() => {
    if (salesAppImprovement.enabled) {
      return ccTradeInLeadsListColumns
    }
    return {
      ...ccTradeInLeadsListColumns,
      columns: ccTradeInLeadsListColumns.columns.filter(c => c.value !== 'daysSinceFunding')
    }
  }, [salesAppImprovement])

  return (
    <ListProvider columns={columns} filters={ccTradeInLeadFilters}>
      <Page>
        <Contents title={PAGE_TITLE} actions={action}>
          <ListSearchHeader
            counterText="Lead"
            totalCount={ccTradeInLeadsQuery.data?.totalCount}
            isLoading={ccTradeInLeadsQuery.isLoading}
            filter={actions}
          />
          <CCTradeInLeadsTable items={items} isLoading={isLoading} />
          <PaginationParams totalPages={ccTradeInLeadsQuery.data?.pageCount} />
        </Contents>
      </Page>
    </ListProvider>
  )
}
