// @ts-strict

import { Option } from 'classes'
import { TButtonFormFieldsProps } from 'components'
import { useUpdateEffect } from 'hooks'
import { enrollmentOptions, TMarketplaceProgramSlug, useMarketplacePrograms } from 'lookups'
import { Agent, AgentTeam } from 'models'
import { MarketplaceProgramAgentEnrollStatus } from 'models/MarketplaceProgramAgent/utils'
import { isEmptyValue, pluralize, titleize } from 'utils'
import {
  minNoteLength,
  optionsNote,
  TAgentProgramsFormButtonPayload
} from '../useAgentProgramsFormButton'
import { getAgentEligibilityCounts, getAgentProgramEligibility } from '../utils'

export type TUseAgentProgramsFormFieldsProps = Pick<
  TButtonFormFieldsProps<TAgentProgramsFormButtonPayload>,
  'onChangeAttribute' | 'payload'
> & {
  agent: Agent
  agentTeam: Potential<AgentTeam>
}
export const useAgentProgramsFormFields = ({
  agent,
  agentTeam,
  onChangeAttribute,
  payload
}: TUseAgentProgramsFormFieldsProps) => {
  const setProgram = (val: string) => onChangeAttribute('program', val as TMarketplaceProgramSlug)
  const setEnrollmentStatus = (val: string) =>
    onChangeAttribute('enrollmentStatus', val as MarketplaceProgramAgentEnrollStatus)
  const setNote = (val: string) => onChangeAttribute('note', val)
  const { marketplacePrograms } = useMarketplacePrograms()

  const isProgramSelected = !isEmptyValue(payload.program)
  const agentCurrentEnrollmentStatus = agent.getMarketplaceProgramEnrollStatus(payload.program)

  const filteredEnrollmentOptions = enrollmentOptions
    .filter(item => item.value !== '')
    .filter(item => item.value !== agentCurrentEnrollmentStatus)

  const initialEnrollStatusValue = isProgramSelected ? agentCurrentEnrollmentStatus : ''

  const programsOptions = marketplacePrograms
    .filter(marketplaceProgram => getAgentProgramEligibility(agent, marketplaceProgram).isEligible)
    .map(program => new Option({ name: program.name, value: program.slug }))

  const enrollmentStatusOptions =
    agent.getMarketplaceProgramEnrollStatus(payload.program) === 'declined'
      ? filteredEnrollmentOptions.filter(
          enrollmentOption => enrollmentOption.value !== 'unenrolled'
        )
      : filteredEnrollmentOptions

  const showNoteField =
    optionsNote.includes(payload.enrollmentStatus!) &&
    payload.enrollmentStatus !== initialEnrollStatusValue

  useUpdateEffect(() => {
    onChangeAttribute('enrollmentStatus', undefined)
    onChangeAttribute('note', '')
  }, [payload.program, onChangeAttribute])

  useUpdateEffect(() => {
    if (payload.enrollmentStatus === 'enrolled') {
      onChangeAttribute('note', '')
    }
  }, [payload.enrollmentStatus, onChangeAttribute])

  const agents = agentTeam?.agentTeamMemberships
    ?.map(agentTeamMembership => agentTeamMembership.user?.agent)
    ?.filter(item => item) as Agent[]
  const eligibilityCounts = getAgentEligibilityCounts(agents)

  const hintTitle = initialEnrollStatusValue
    ? `Current status: ${titleize(initialEnrollStatusValue)}`
    : ''

  const hintSubtitle =
    payload.enrollmentStatus === 'enrolled' && agentTeam
      ? `Enrolling this agent will invite ${pluralize(
          eligibilityCounts[payload.program!],
          'team member'
        )} to this program`
      : ''
  const hintNote =
    payload?.note && payload.note.length < minNoteLength
      ? `minimum ${minNoteLength} characters`
      : ''

  return {
    enrollmentStatusOptions,
    hintTitle,
    hintSubtitle,
    hintNote,
    isProgramSelected,
    programsOptions,
    showNoteField,
    setProgram,
    setEnrollmentStatus,
    setNote
  }
}
