import { TModelFieldSetting } from '../ModelField'

export type TCapitalPartnerFieldAttribute = 'facility' | 'fundingFacility'

export type TCapitalPartnerFieldSetting = {
  attr: TCapitalPartnerFieldAttribute
} & TModelFieldSetting

export const capitalPartnerFieldsSettings: TCapitalPartnerFieldSetting[] = [
  { attr: 'facility', name: 'Capital Markets Facility' },
  { attr: 'fundingFacility', name: 'Funding Facility' }
]
