import { useState } from 'react'
import {
  ActionBar,
  CheckBoxArray,
  Column,
  Columns,
  EmployeesAutoComplete,
  ListBox,
  MenuSection,
  MoneyInput,
  Section,
  Select
} from 'components'
import { useListContext } from 'contexts'
import { useNewFilters } from 'hooks'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import { ListFilters } from 'templates'
import styles from './OrdersListFilters.module.scss'

type Params = {
  aeId: string
  daysAgo: string
  escrowAttached: string
  leadStages: string[]
  listingStatuses: string[]
  maxPrice: string
  minPrice: string
  needsListingReview: string
  oppStatus: string[]
  queue: boolean
  userType: string[]
}

export const OrdersListFilters = () => {
  const { tracking } = useCustomEvents()
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const { newParams, set } = useNewFilters<Params>()
  const { findFilterBySlug } = useListContext()
  const userTypeFilter = findFilterBySlug('userType')
  const oppStatusFilter = findFilterBySlug('oppStatus')
  const leadStagesFilter = findFilterBySlug('leadStages')
  const daysAgoFilter = findFilterBySlug('daysAgo')
  const needsListingReviewFilter = findFilterBySlug('needsListingReview')
  const escrowAttachedFilter = findFilterBySlug('escrowAttached')
  const minPriceFilter = findFilterBySlug('minPrice')
  const maxPriceFilter = findFilterBySlug('maxPrice')
  const listingStatusesFilter = findFilterBySlug('listingStatuses')
  const aeIdFilter = findFilterBySlug('aeId')

  return (
    <ListFilters.Root isOpen={isOpenPopover} onOpenChange={setIsOpenPopover}>
      <ListFilters.Trigger>
        <ListFilters.TriggerButton iconRight="FilterAltOutlined">Filters</ListFilters.TriggerButton>
      </ListFilters.Trigger>
      <ListFilters.Content>
        <Section className={styles.section}>
          <Columns className={styles.resetOverflow}>
            <Column>
              <MenuSection title={userTypeFilter?.name}>
                <CheckBoxArray
                  options={userTypeFilter.options}
                  value={newParams.userType}
                  onChange={val => set('userType', val)}
                />
              </MenuSection>
              <MenuSection title={oppStatusFilter?.name}>
                <CheckBoxArray
                  options={oppStatusFilter.options}
                  value={newParams.oppStatus}
                  onChange={val => set('oppStatus', val)}
                />
              </MenuSection>
            </Column>
            <Column title={leadStagesFilter?.name}>
              <CheckBoxArray
                options={leadStagesFilter.options}
                value={newParams.leadStages}
                onChange={val => set('leadStages', val)}
              />
            </Column>

            <Column>
              <MenuSection title={daysAgoFilter?.name}>
                <ListBox
                  options={daysAgoFilter.options}
                  value={newParams.daysAgo || ''}
                  onChange={val => set('daysAgo', val)}
                />
              </MenuSection>
            </Column>

            {listingStatusesFilter && (
              <Column>
                <MenuSection title={listingStatusesFilter?.name}>
                  <CheckBoxArray
                    options={listingStatusesFilter.options}
                    value={newParams.listingStatuses}
                    onChange={val => set('listingStatuses', val)}
                  />
                </MenuSection>
              </Column>
            )}

            <Column className={styles.resetOverflow}>
              {aeIdFilter && (
                <MenuSection title={aeIdFilter?.name}>
                  <EmployeesAutoComplete
                    value={aeIdFilter.getDisplayValue(newParams.aeId)}
                    onSelect={val => set('aeId', val?.id)}
                    onClear={() => set('aeId', '')}
                  />
                </MenuSection>
              )}
              {needsListingReviewFilter && (
                <MenuSection title={needsListingReviewFilter?.name}>
                  <Select
                    addBlank
                    value={newParams.needsListingReview}
                    options={needsListingReviewFilter.options}
                    onChange={val => set('needsListingReview', val)}
                  />
                </MenuSection>
              )}

              <MenuSection title={escrowAttachedFilter?.name}>
                <Select
                  addBlank
                  value={newParams.escrowAttached}
                  options={escrowAttachedFilter.options}
                  onChange={val => set('escrowAttached', val)}
                />
              </MenuSection>
              <MenuSection title="Price Range">
                <ActionBar>
                  <MoneyInput
                    value={newParams.minPrice}
                    onChange={val => set('minPrice', val)}
                    placeholder={minPriceFilter?.name}
                  />
                  <MoneyInput
                    value={newParams.maxPrice}
                    onChange={val => set('maxPrice', val)}
                    placeholder={maxPriceFilter?.name}
                  />
                </ActionBar>
              </MenuSection>
            </Column>
          </Columns>
          <ListFilters.Footer>
            <ListFilters.ApplyButton
              newParams={newParams}
              onApplyToggle={setIsOpenPopover}
              onClick={() =>
                tracking({ name: 'filters', params: { action: 'apply', target: 'order' } })
              }
            />
          </ListFilters.Footer>
        </Section>
      </ListFilters.Content>
    </ListFilters.Root>
  )
}
