import { Option } from 'classes'
import { Button } from 'components'

type Props = {
  offOption: Option
  onChange: (val?: string | number) => void
  onOption: Option
  value: string
}

export const ToggleSelect = ({ offOption, onOption, value, onChange }: Props) => {
  const newOption = value === onOption.value ? offOption : onOption

  return (
    <Button
      as="link"
      className={value === onOption.value ? 'selected' : null}
      onClick={() => onChange(newOption.value)}
    >
      {newOption.name}
    </Button>
  )
}
