import { formatMoney, formatPercent } from 'utils'

interface IBbysEventMeta {
  agentFee: string
  agentValuation: string
  bbysFee: string
  closingCost: string
  estimatedLoanPayoff: string
  failure: boolean
  fallback: boolean
  highEndRange: string
  inspectionCost: string
  lowEndRange: string
  preliminary: boolean
  propertyUuid: string
  propertyValueMean: string
  source: string
  sourceForm: string
  targetUnlockAmount: string
  validEquity: boolean
}

const formatters = {
  bbys_equity_unlock_calculation: (data: IBbysEventMeta) => ({
    agentValuation: formatMoney(data.agentValuation),
    equityUnlockRange: `${formatMoney(data.lowEndRange)} - ${formatMoney(data.highEndRange)}`,
    targetUnlockAmount: formatMoney(data.targetUnlockAmount, '-'),
    estimatedLoanPayoff: formatMoney(data.estimatedLoanPayoff, '-'),
    agentFee: formatPercent(data.agentFee),
    sourceForm: data.sourceForm,
    source: data.source,
    propertyUuid: data.propertyUuid
  })
}

export default formatters
