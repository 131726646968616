// @ts-strict
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  MapboxAutoComplete,
  MapboxAutoCompleteProps,
  MapboxAutocompleteResponse,
  TextInput
} from 'components'
import { useUpdateEffect } from 'hooks'
import { cx, structureAddress } from 'utils'
import styles from './AddressUnitAutoComplete.module.scss'

type Props = Omit<MapboxAutoCompleteProps, 'onChange' | 'onSelect'> & {
  'data-testid'?: string
  onChange?: (address?: string) => void
  onSelect?: (address?: string) => void
  placeholderAddress?: string
  placeholderUnit?: string
}

export const AddressUnitAutoComplete = ({
  id,
  autoFocus,
  className,
  'data-testid': dataTestId = 'addressUnitAutoComplete',
  onBlur,
  onChange,
  onSelect,
  placeholderAddress,
  placeholderUnit,
  value
}: Props) => {
  const [unitNumber, setUnitNumber] = useState('')
  const [address, setAddress] = useState<string | undefined>(value)
  const [isFocussed, setIsFocussed] = useState(true)
  const onSelectRef = useRef(onSelect || onChange)
  const onBlurRef = useRef(onBlur)
  let blurring: NodeJS.Timeout

  const fullAddress = (() => {
    const parts = structureAddress(address)
    if (parts.street && parts.street.length) {
      return `${parts.street}${unitNumber && unitNumber.length ? ` #${unitNumber}` : ''}, ${
        parts.cityState
      }`
    }
    return parts.cityState
  })()

  const triggerOnSelect = useCallback((fullAddress: string | undefined) => {
    if (onSelectRef.current) {
      onSelectRef.current(fullAddress)
    }
  }, [])

  const handleBlur = () => {
    blurring = setTimeout(() => setIsFocussed(false), 50)
  }

  const handleClear = () => {
    setAddress('')
    setUnitNumber('')
  }

  const handleFocus = () => {
    clearTimeout(blurring)
    setIsFocussed(true)
  }

  const handleSelect = (item: MapboxAutocompleteResponse[number]) => {
    setAddress(item ? item.title : '')
  }

  useEffect(() => {
    setAddress(value)
  }, [value])

  useEffect(() => {
    if (!isFocussed) {
      triggerOnSelect(fullAddress)

      if (onBlurRef.current) {
        onBlurRef.current()
      }
    }
  }, [fullAddress, isFocussed, triggerOnSelect])

  useUpdateEffect(() => {
    onBlurRef.current = onBlur
    onSelectRef.current = onSelect || onChange
  })

  return (
    <div
      className={cx(styles.addressAndUnit, className)}
      onBlur={handleBlur}
      data-testid={dataTestId}
    >
      <MapboxAutoComplete
        id={id}
        autoFocus={autoFocus}
        onClear={handleClear}
        onFocus={handleFocus}
        onSelect={handleSelect}
        placeholder={placeholderAddress}
        value={address}
        data-testid={`${dataTestId}-select`}
      />
      <div className={styles.unit}>
        <label>Unit</label>
        <TextInput
          onChange={setUnitNumber}
          onFocus={handleFocus}
          placeholder={placeholderUnit}
          value={unitNumber}
          data-testid={`${dataTestId}-unit`}
        />
      </div>
    </div>
  )
}
