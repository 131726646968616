import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './PageContents.module.scss'

type TProps = {
  children?: ReactNode
  className?: string
}

export const PageContents = ({ className, children }: TProps) => {
  return (
    <div data-testid="page-contents" className={cx('page-contents', styles.contents, className)}>
      {children}
    </div>
  )
}
