import { getReassignPreferredEscrowOfficerUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export type PostReassignPreferredEscrowOfficerPayload = {
  oldPreferredEscrowOfficerUserId: string
  preferredEscrowOfficerUserId: string
}

export const PostAgentReassignPreferredEscrowOfficer = (
  payload: PostReassignPreferredEscrowOfficerPayload
) => postRequest(getReassignPreferredEscrowOfficerUrl(), snakeCaseKeys(payload))
