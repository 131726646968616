import { forwardRef } from 'react'
import { paths } from '@foundation/icons'
import type { IconPathKeys } from '@foundation/icons'
import type { Theme } from '@foundation/themes'
import * as S from './styles'

type ColorsKeys = keyof Theme['colors']
type Colors = Extract<ColorsKeys, 'danger8' | 'neutral8' | 'primary5' | 'primary10' | 'white'>

export type IconProps = {
  /**
   * The color of the icon. Default is "currentColor"
   */
  color?: Colors
  /**
   * An id to use in test environments
   */
  'data-testid'?: string
  /**
   * The icon name from the ones we have inside the icons folder to render the svg
   */
  path: IconPathKeys
  /**
   * The size of the icon
   */
  size?: 'xs' | 'sm' | 'md' | 'lg'
  /**
   * The title of the icon
   */
  title?: string
}

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  (
    { 'data-testid': dataTestId = 'iconWrapper', color, path, size = 'sm', title }: IconProps,
    ref
  ) => {
    if (!path) {
      return null
    }

    return (
      <S.IconWrapper
        ref={ref}
        $size={size}
        $color={color}
        data-testid={dataTestId}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        role="img"
        aria-hidden="true"
      >
        <path data-testid="iconPath" d={paths[path]} />
        {title && <title>{title}</title>}
      </S.IconWrapper>
    )
  }
)

Icon.displayName = 'Icon'
