// @ts-strict

import { MarketplaceProgramAgent } from 'models'
import { MarketplaceProgramBadge } from '../../MarketplaceProgram/MarketplaceProgramBadge'

type Props = {
  marketplaceProgramAgent: MarketplaceProgramAgent
}

export const AgentMarketplaceProgramBadge = ({ marketplaceProgramAgent }: Props) => {
  return (
    <MarketplaceProgramBadge
      program={marketplaceProgramAgent.marketplaceProgram}
      status={marketplaceProgramAgent.enrollStatus}
    />
  )
}
