import { getPhotosUrl } from 'services/getUrl'
import { postRequest } from 'utils'
import { snakeCaseKeys } from 'utils'
import { TUploadToS3 } from '../utils'

type TPayload = {
  attachableId: string
  attachableType: string
  category: string
  files: TUploadToS3[]
}

export const postPhotoUploads = async (payload: TPayload) => {
  const url = getPhotosUrl()
  return postRequest(url, snakeCaseKeys(payload))
}
