import { MapBox } from 'components'
import { Lead } from 'models'
import styles from './LeadsMap.module.scss'

export const LeadsMap = ({ leads, isLoading }: { isLoading: boolean; leads: Lead[] }) => {
  if (isLoading) {
    return null
  }
  const markers = leads.map(l => l.toMarker())

  return <MapBox markers={markers} className={styles.leadsMap} />
}
