import { ModelField } from '../ModelField'
import { TCashOfferLeadFieldAttribute } from './cashOfferLeadFieldsSettings'

export class CashOfferLeadField extends ModelField {
  attr!: TCashOfferLeadFieldAttribute

  constructor(attributes: CashOfferLeadField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
