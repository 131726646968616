// @ts-strict

import { css, styled } from '@foundation/themes/styles'

export const TabActions = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
  `}
`

export const ButtonNavigation = styled.div`
  ${({ theme }) => css`
    display: flex;

    > :last-child {
      margin-left: ${theme.space.$2};
    }
  `}
`
