import { Children, cloneElement, ReactNode } from 'react'
import { useDialogContext } from './Dialog'

type Props = {
  /**
   * The button to display inside the Dialog Trigger. It accepts only one child.
   */
  children: ReactNode
}

export const DialogTrigger = ({ children }: Props) => {
  const { triggerRef, getReferenceProps } = useDialogContext()

  // enforce a single child
  const child: any = Children.only(children)

  return cloneElement(child, getReferenceProps({ ref: triggerRef }))
}
