import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Contents, Page } from 'components'
import { useQueuedLeadsController } from 'controllers'
import { useDocumentTitle } from 'hooks'
import { useQueryClient } from 'hooks'
import { Lead } from 'models'
import { fetchHandmatchLead } from 'services/HandmatchLead'

export const HandmatchPage = () => {
  const [lead, setLead] = useState<Lead>()
  const fetching = useRef(false)
  const navigate = useNavigate()
  useDocumentTitle('Handmatch')
  const { addLeadToQueue } = useQueuedLeadsController()
  const queryCache = useQueryClient()

  useEffect(() => {
    const fetchLead = async () => {
      fetching.current = true
      const nextLead = await fetchHandmatchLead()
      setLead(nextLead)
      addLeadToQueue(nextLead)
      queryCache.invalidateQueries('current-user-queues')
    }
    if (!lead && !fetching.current) {
      fetchLead()
    }
  }, [lead, queryCache, addLeadToQueue])

  useEffect(() => {
    if (lead && queryCache) {
      navigate(lead.getPathname())
    }
  }, [navigate, lead, queryCache])

  return (
    <Page>
      <Contents title="Handmatch Lead">Looking for lead...</Contents>
    </Page>
  )
}
