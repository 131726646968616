import { AgentMatchingLead } from 'models'
import { getHandmatchLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchHandmatchLead = async (): Promise<Potential<AgentMatchingLead>> => {
  const response = (
    await getRequest(
      getHandmatchLeadsUrl({
        searchParams: { 'fields[lead]': ['name', 'user_type'] },
        action: 'next-lead'
      })
    )
  ).data

  if (!response?.data) {
    return undefined
  }
  return await deserializeAsync(response, {
    transform: lead => new AgentMatchingLead(lead)
  })
}
