// @ts-strict

import { Block, TableCell as Td } from 'components'
import { CCCashOfferLead } from 'models'
import { dataOrDash, dateDiffInDays, formatBoolean, formatDate, formatMoney, titleize } from 'utils'

type TProps = {
  column?: string
  lead: CCCashOfferLead
  testId: string
}

export const CCCashOfferLeadHoaCell = ({ lead, testId }: TProps) => {
  const { ipHomeownerAssociation } = lead.activeCashOfferLead || {}
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(formatBoolean(ipHomeownerAssociation))} />
    </Td>
  )
}

export const CCCashOfferLeadSolarPanelsCell = ({ lead, testId }: TProps) => {
  const { ipSolarPanels } = lead.activeCashOfferLead || {}
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(titleize(ipSolarPanels))} />
    </Td>
  )
}

export const CCCashOfferLeadFundingAmountCell = ({ lead, testId }: TProps) => {
  const { ipFundingAmount } = lead.activeCashOfferLead || {}
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(formatMoney(ipFundingAmount))} />
    </Td>
  )
}

export const CCCashOfferLeadIRPurchaseCOEDateCell = ({ lead, testId }: TProps) => {
  const { incomingPropertyCloseOfPurchaseEscrowAt } = lead.activeCashOfferLead || {}

  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(incomingPropertyCloseOfPurchaseEscrowAt))} />
    </Td>
  )
}

export const CCCashOfferLeadIRSaleActualCOEDateCell = ({ lead, testId }: TProps) => {
  const { incomingPropertyCloseOfSellEscrowAt } = lead.activeCashOfferLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(incomingPropertyCloseOfSellEscrowAt))} />
    </Td>
  )
}

export const CCCashOfferLeadIRPurchaseOpenEscrowDate = ({ lead, testId }: TProps) => {
  const { incomingPropertyOpenPurchaseEscrowAt } = lead.activeCashOfferLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(incomingPropertyOpenPurchaseEscrowAt))} />
    </Td>
  )
}

export const CCCashOfferLeadIRSellOpenEscrowDate = ({ lead, testId }: TProps) => {
  const { incomingPropertyOpenSellEscrowAt } = lead.activeCashOfferLead || {}

  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(incomingPropertyOpenSellEscrowAt))} />
    </Td>
  )
}

export const CCCashOfferLeadFundingDateCell = ({ lead, testId }: TProps) => {
  const { incomingPropertyFundsWiredToSellerAt } = lead.activeCashOfferLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(incomingPropertyFundsWiredToSellerAt))} />
    </Td>
  )
}

export const CCCashOfferLeadFundingFacilityCell = ({ lead, testId }: TProps) => {
  const { capitalPartner } = lead.activeCashOfferProviderLead?.providerLeadCapitalPartner || {}
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(titleize(capitalPartner?.fundingFacility))} />
    </Td>
  )
}

export const CCCashOfferLeadCapitalMarketsFacilityCell = ({ lead, testId }: TProps) => {
  const { capitalPartner } = lead.activeCashOfferProviderLead?.providerLeadCapitalPartner || {}

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(titleize(capitalPartner?.name))} />
    </Td>
  )
}

export const CCCashOfferLeadDaysUntilFundingCell = ({ lead, testId }: TProps) => {
  const { incomingPropertyFundsWiredToSellerAt } = lead.activeCashOfferLead || {}
  const fundingDate = incomingPropertyFundsWiredToSellerAt || ''

  const today = formatDate(new Date(), 'yyyy-MM-dd')
  const diffInDays = dateDiffInDays(fundingDate, today)
  const result = diffInDays >= 0 ? String(diffInDays) : null

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(result)} />
    </Td>
  )
}
