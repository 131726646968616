import { format, parseISO } from 'date-fns'
import { DEFAULT_STRING } from '../constants'

const formats = {
  short: 'M/d/yy',
  long: 'MMM d, yyyy'
}

export const formatDate = (date?: string | Date, fmt = 'long', defaultValue = DEFAULT_STRING) => {
  if (!date) {
    return defaultValue
  }

  const dateFormat = formats[fmt] || fmt

  try {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date
    return format(parsedDate, dateFormat)
  } catch {
    return 'Invalid date'
  }
}
