import { ImgHTMLAttributes } from 'react'
import { cx } from 'utils'
import styles from './RoundImage.module.scss'

type Props = ImgHTMLAttributes<HTMLImageElement>

export const RoundImage = ({ className, src, alt, ...props }: Props) => {
  return (
    <img
      src={src}
      alt={alt || 'Profile'}
      data-testid="roundImage"
      className={cx(styles.image, className)}
      {...props}
    />
  )
}
