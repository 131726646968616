// @ts-strict
import { InvestorPayPerLeadSettingResponse } from 'schema'
import { Model } from '../Model'

export class InvestorPayPerLeadSetting extends Model {
  active?: boolean
  id?: string | number
  location?: string
  price?: number
  qualified?: boolean
  userType?: string

  constructor(investorPayPerLeadSettingAttributes: InvestorPayPerLeadSettingResponse) {
    super()
    Object.assign(this, investorPayPerLeadSettingAttributes)
  }

  static create(
    investorPayPerLeadSettingResponse: Potential<InvestorPayPerLeadSettingResponse>
  ): Potential<InvestorPayPerLeadSettingResponse> {
    if (!investorPayPerLeadSettingResponse) {
      return
    }

    return new InvestorPayPerLeadSetting(investorPayPerLeadSettingResponse)
  }
}
