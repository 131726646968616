import { SearchFilter, useSearchFilters } from 'lookups'
import { bySlug } from '../../utils'

export const useTaskSearchFilters = () => {
  const { findFilterBySlug } = useSearchFilters()

  const tasksPageFilters = [
    new SearchFilter({
      slug: 'leadType',
      name: 'Lead Type'
    }),
    new SearchFilter({
      slug: 'attachableStatus',
      name: 'Lead Stage'
    })
  ]

  const findTasksPageFilterBySlug = (slug: string) => {
    return tasksPageFilters.find(bySlug(slug)) || findFilterBySlug(slug)
  }

  return {
    findFilterBySlug: findTasksPageFilterBySlug
  }
}
