import { Section } from 'components'
import { useCCTradeInLeadDispositionContext, useFields } from '..'
import { FieldFormField } from '../FieldFormField'

export const CCTradeInClosedSell = () => {
  const { changes, tradeInLead } = useCCTradeInLeadDispositionContext()
  const requireChangeReason =
    tradeInLead.departingPropertyCloseOfSaleEscrowAt && changes.departingPropertyCloseOfSaleEscrowAt

  useFields({
    fields: requireChangeReason ? ['changeReasons.departingPropertyCloseOfSaleEscrowAt.note'] : [],
    required: [
      'departingPropertyFundsWiredAmount',
      'departingPropertyCloseOfSaleEscrowAt',
      requireChangeReason ? 'changeReasons.departingPropertyCloseOfSaleEscrowAt.reason' : undefined
    ]
  })
  return (
    <Section title="DR Sale Transaction">
      <FieldFormField field="departingPropertyFundsWiredAmount" />

      <FieldFormField field="departingPropertyCloseOfSaleEscrowAt" />
      {requireChangeReason && (
        <>
          <FieldFormField field="changeReasons.departingPropertyCloseOfSaleEscrowAt.reason" />
          <FieldFormField field="changeReasons.departingPropertyCloseOfSaleEscrowAt.note" />
        </>
      )}
    </Section>
  )
}
