import { Children, cloneElement, ReactNode } from 'react'
import { usePopoverContext } from './Popover'

type Props = {
  /**
   * The button to display inside the Popover Trigger. It accepts only one child.
   */
  children: ReactNode
}

export const PopoverTrigger = ({ children }: Props) => {
  const { popoverTriggerProps } = usePopoverContext()

  // enforce a single child
  const child: any = Children.only(children)

  return cloneElement(child, popoverTriggerProps)
}

PopoverTrigger.displayName = 'PopoverTrigger'
