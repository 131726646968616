// @ts-strict
import { MutationButton, TableActions, TableCell as Td, TableRow as Tr } from 'components'
import { DeleteIconButton } from 'components/Button/IconButton'
import { useEmployeeRoleController } from 'controllers'
import { Employee, Role } from 'models'
import { d } from 'utils'

type Props = {
  employee: Employee
  role: Role
}

export const RoleTableRow = ({ employee, role }: Props) => {
  const { destroyEmployeeRole } = useEmployeeRoleController(employee.id, role.id)

  return (
    <Tr>
      <Td>{d(role.name)}</Td>
      <TableActions>
        <MutationButton mutation={destroyEmployeeRole} button={DeleteIconButton} />
      </TableActions>
    </Tr>
  )
}
