import { ModelField } from '../ModelField'
import { TCapitalPartnerFieldAttribute } from './capitalPartnerFieldsSettings'

export class CapitalPartnerField extends ModelField {
  attr!: TCapitalPartnerFieldAttribute

  constructor(attributes: CapitalPartnerField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
