import { getBBYSLeadsUrl } from 'services/getUrl'
import { postRequest, putRequest } from 'utils'

export type TPostGeneratedDocumentPayload = Record<string, string>

export const generateDocument = async (
  bbysLeadId: string,
  params: TPostGeneratedDocumentPayload
) => {
  return postRequest(
    `${getBBYSLeadsUrl()}/${bbysLeadId}/generated-documents?slug=${params['slug']}`,
    params
  )
}

export const signGeneratedDocument = async (bbysLeadId: string, id: string) => {
  return putRequest(`${getBBYSLeadsUrl()}/${bbysLeadId}/generated-documents/${id}/sign`)
}
