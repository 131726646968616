import { useEffect } from 'react'
import { useCashOfferDispositionContext } from 'content/Lead'
import { TCashOfferLeadFieldAttribute } from 'lookups'

export const useFields = (fields: {
  fields?: TCashOfferLeadFieldAttribute | TCashOfferLeadFieldAttribute[]
  required?: TCashOfferLeadFieldAttribute | TCashOfferLeadFieldAttribute[]
}) => {
  const fieldString = JSON.stringify(fields)

  const { registerFields } = useCashOfferDispositionContext()

  useEffect(() => {
    registerFields(JSON.parse(fieldString))
  }, [fieldString, registerFields])
}
