import { useState } from 'react'
import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import {
  Button,
  Dialog,
  Flex,
  Heading,
  Text,
  useToastContext
} from '../../../../@foundation/components'
import { deleteRequest } from '../../../../utils'
import { BASE_URL, GET_TRANSACTION_OPERATORS_LIST } from '../../pages'
import { TransactionOperatorGroup as TransactionOperatorGroupType } from '../../pages/types'
import { AddOperatorPopover } from '../add-operator-popover/add-operator-popover'
import { Operator } from '../operator/operator'
import { Container } from './styles'

type TransactionGroupProps = {
  group: TransactionOperatorGroupType
}

export const OperatorGroup = ({ group }: TransactionGroupProps) => {
  const queryCache = useQueryClient()
  const toast = useToastContext()

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const deleteGroup = async () => {
    return deleteRequest(`${BASE_URL}/lead-data-service/transaction-operator-groups/${group.id}`)
  }

  const { mutate, isLoading } = useMutation(deleteGroup, {
    onSuccess: async () => {
      await queryCache.invalidateQueries([GET_TRANSACTION_OPERATORS_LIST])
      setIsDeleteDialogOpen(false)
    },
    onError: (error: AxiosError) => {
      console.error(error.response)
      toast({
        title: `Oops! Something went wrong. ${error.response.data.errors}`,
        status: 'error'
      })
    }
  })

  return (
    <Container>
      <Flex flexDirection="column" gap="$4">
        <Flex justifyContent="space-between" gap="$6">
          <Heading size="lg">{group?.name}</Heading>
          <Flex gap="$3">
            <AddOperatorPopover groupId={group.id} />
            <Dialog.Root isOpen={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
              <Dialog.Trigger>
                <Button color="danger" variant="outline">
                  Delete
                </Button>
              </Dialog.Trigger>
              <Dialog.Content>
                <Dialog.Header>Delete group</Dialog.Header>
                <Dialog.Body>
                  This will remove {group.name} from your transaction operator groups.
                </Dialog.Body>
                <Dialog.Footer>
                  <Flex justifyContent="end">
                    <Button color="danger" onClick={() => mutate()} isLoading={isLoading}>
                      Delete group
                    </Button>
                  </Flex>
                </Dialog.Footer>
              </Dialog.Content>
            </Dialog.Root>
          </Flex>
        </Flex>

        {group.users?.[0] && (
          <>
            <Text size="base" weight="semibold">
              Users
            </Text>
            <div>
              <Flex gap="$6" flexWrap="wrap">
                {group.users.map(operator => (
                  <Operator operator={operator} type="user" groupId={group.id} key={operator.id} />
                ))}
              </Flex>
            </div>
          </>
        )}

        {group.teams?.[0] && (
          <>
            <Text size="base" weight="semibold">
              Teams
            </Text>
            <div>
              <Flex gap="$6" flexWrap="wrap">
                {group.teams.map(operator => (
                  <Operator operator={operator} type="team" groupId={group.id} key={operator.id} />
                ))}
              </Flex>
            </div>
          </>
        )}
      </Flex>
    </Container>
  )
}
