// @ts-strict
import { PartnerResponse } from 'schema'

export class Partner {
  id!: string
  slug!: string
  name?: string
  createdAt?: string

  constructor(partnerAttributes: Partner) {
    Object.assign(this, partnerAttributes)
  }
  static create(partner: Potential<PartnerResponse>): Potential<Partner> {
    if (!partner) {
      return
    }
    return new Partner(partner)
  }
}
