export type TMortgageLeadStagesSlug = 'new' | 'connected' | 'pitched' | 'nurture' | 'failed'

export type TMortgageLeadStageSetting = {
  name: string
  nextStageSlugs?: TMortgageLeadStagesSlug[]
  other?: boolean
  removePreviousStage?: boolean
  slug: TMortgageLeadStagesSlug
  validPreviousStage?: boolean
}

export const mortgageLeadStagesSettings: Array<TMortgageLeadStageSetting> = [
  {
    name: 'New',
    slug: 'new',
    validPreviousStage: true,
    nextStageSlugs: ['connected']
  },
  {
    name: 'Connected',
    slug: 'connected',
    validPreviousStage: true,
    nextStageSlugs: ['pitched']
  },
  {
    name: 'Pitched',
    slug: 'pitched',
    validPreviousStage: true
  },
  {
    name: 'Nurture',
    slug: 'nurture',
    other: true
  },
  {
    name: 'Failed',
    slug: 'failed',
    other: true
  }
]
