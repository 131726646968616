import { Console, ConsoleAside, ConsoleDetails } from '../Console'
import { LoadingText } from '../index'
import styles from './Console.module.scss'

export const ConsoleLoading = ({ type }: { type: string }) => {
  return (
    <Console>
      <ConsoleAside className={styles.padded}>
        <LoadingText text={`${type} Summary`} />
      </ConsoleAside>

      <ConsoleDetails className={styles.padded}>
        <LoadingText text={`${type} Details`} />
      </ConsoleDetails>
    </Console>
  )
}
