import { CheckBoxField } from 'components'
import styles from './AgentFilters.module.scss'

type Props = {
  onChange: (boolean) => void
  value: boolean
}

export const AgentsAlgoFilter = ({ value, onChange }: Props) => {
  const handleOnChange = e => {
    onChange(e.currentTarget.checked)
  }
  return (
    <CheckBoxField
      title="Use Lead"
      onChange={handleOnChange}
      checked={value}
      className={styles.filter}
      inline
    />
  )
}
