// @ts-strict
import { ReactNode } from 'react'
import { ActionBar, FormField, Section } from 'components'
import { User } from 'models'
import { dataOrDash } from 'utils'
import { UserEditButtonForm } from '../UserButtonForm'
import { UserImpersonateButton } from '../UserImpersonateButton'
import { Separator } from './styles'

type Props = {
  children?: ReactNode
  user?: User
}

export const UserInfo = ({ user, children }: Props) => {
  const hasUserAccount = user && user.id

  if (!hasUserAccount) {
    return (
      <Section title="User Info">
        <div>No user account found.</div>
        {children}
      </Section>
    )
  }

  const actions = hasUserAccount && (
    <ActionBar>
      <UserEditButtonForm user={user} />
      <Separator />
      <UserImpersonateButton userId={user.id} />
    </ActionBar>
  )

  return (
    <Section title="User Info" actions={actions}>
      <FormField title="First Name" inline>
        {dataOrDash(user?.firstName)}
      </FormField>
      <FormField title="Last Name" inline>
        {dataOrDash(user?.lastName)}
      </FormField>
      <FormField title="Email" inline>
        {dataOrDash(user?.email)}
      </FormField>
      {children}
    </Section>
  )
}
