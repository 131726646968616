// import { Agent } from 'models'
import { getAgentsUrl } from 'services/getUrl'
import { postRequest, putRequest, snakeCaseKeys } from 'utils'

export const voidAgentAgreement = async (agentId: string, payload: any) => {
  const url = getAgentsUrl({ id: agentId, action: 'agent-agreements/void' })
  await putRequest(url, snakeCaseKeys({ ...payload }))
}

export const issueAgentAgreement = async (agentId: string, payload: any) => {
  const url = getAgentsUrl({ id: agentId, action: 'agent-agreements/issue' })
  await postRequest(url, snakeCaseKeys({ ...payload }))
}
