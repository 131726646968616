// @ts-strict
import {
  FormField,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  InlineEditDollarsInput,
  Section,
  Sections
} from 'components'
import { useHLSimpleSaleLeadController } from 'controllers'
import { useCCHLSimpleSaleLeadFields } from 'lookups'
import { HLSSLeadDetailsProps } from '../HLSSLeadDetails'
import { getFieldValues } from '../utils'

type TProps = Pick<HLSSLeadDetailsProps, 'lead'>

export const DRPropertyValuationSection = ({ lead }: TProps) => {
  const hlSimpleSaleLead = lead.hlSimpleSaleLead!
  const { updateHLSimpleSaleLead } = useHLSimpleSaleLeadController(hlSimpleSaleLead!.id)
  const { getFieldValue } = getFieldValues(hlSimpleSaleLead)
  const { getCCHLSimpleSaleLeadFieldName } = useCCHLSimpleSaleLeadFields()

  return (
    <Sections title="DR Property Valuation">
      <Section>
        <FormField title={getCCHLSimpleSaleLeadFieldName('agentValuation')} inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ agentValuation: val })}
            value={getFieldValue('agentValuation')}
          />
        </FormField>
        <FormField
          title={getCCHLSimpleSaleLeadFieldName('departingPropertyAgentValuationReceivedDate')}
          inline
        >
          <InlineEditDateInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyAgentValuationReceivedDate: val })
            }
            value={getFieldValue('departingPropertyAgentValuationReceivedDate')}
          />
        </FormField>

        <FormField title={getCCHLSimpleSaleLeadFieldName('dpAnalystValuation')} inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpAnalystValuation: val })}
            value={getFieldValue('dpAnalystValuation')}
          />
        </FormField>
        <FormField title={getCCHLSimpleSaleLeadFieldName('dpAnalystValuationDate')} inline>
          <InlineEditDateInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpAnalystValuationDate: val })}
            value={getFieldValue('dpAnalystValuationDate')}
          />
        </FormField>
        <FormField title={getCCHLSimpleSaleLeadFieldName('departingPropertyValuation')} inline>
          <InlineEditDollarsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ departingPropertyValuation: val })}
            value={getFieldValue('departingPropertyValuation')}
          />
        </FormField>
        <FormField title={getCCHLSimpleSaleLeadFieldName('dpHlAfterRepairValue')} inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpHlAfterRepairValue: val })}
            value={getFieldValue('dpHlAfterRepairValue')}
          />
        </FormField>
      </Section>
      <Section>
        <FormField
          title={getCCHLSimpleSaleLeadFieldName('departingPropertyPreliminaryValuation')}
          inline
        >
          <InlineEditDollarsInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyPreliminaryValuation: val })
            }
            value={getFieldValue('departingPropertyPreliminaryValuation')}
          />
        </FormField>
        <FormField title={getCCHLSimpleSaleLeadFieldName('avmDate')} inline>
          <InlineEditDateInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ avmDate: val })}
            value={getFieldValue('avmDate')}
          />
        </FormField>
        <FormField title={getCCHLSimpleSaleLeadFieldName('dpAgentAfterRepairValue')} inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpAgentAfterRepairValue: val })}
            value={getFieldValue('dpAgentAfterRepairValue')}
          />
        </FormField>
        <FormField title={getCCHLSimpleSaleLeadFieldName('dpAgentAfterRepairValueDate')} inline>
          <InlineEditDateInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpAgentAfterRepairValueDate: val })}
            value={getFieldValue('dpAgentAfterRepairValueDate')}
          />
        </FormField>
        <FormField title={getCCHLSimpleSaleLeadFieldName('dpAnalystAfterRepairValue')} inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpAnalystAfterRepairValue: val })}
            value={getFieldValue('dpAnalystAfterRepairValue')}
          />
        </FormField>
        <FormField title={getCCHLSimpleSaleLeadFieldName('dpAnalystAfterRepairValueDate')} inline>
          <InlineEditDateInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpAnalystAfterRepairValueDate: val })}
            value={getFieldValue('dpAnalystAfterRepairValueDate')}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
