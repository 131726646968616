// @ts-strict

import {
  AGENT_PARTNERSHIPS_CACHE_KEY,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from 'hooks'
import { AgentPartnership } from 'models'
import { AgentPartnershipPostPayload, fetchAgentPartnerships, postAgentPartnership } from 'services'

type Props = {
  searchParams: Record<string, string>
}

export const useAgentPartnershipsController = ({ searchParams }: Props) => {
  const queryCache = useQueryClient()
  const { data, isLoading, isError } = useQuery([AGENT_PARTNERSHIPS_CACHE_KEY, searchParams], () =>
    fetchAgentPartnerships(searchParams)
  )

  const clearCaches = () => {
    queryCache.invalidateQueries(AGENT_PARTNERSHIPS_CACHE_KEY, searchParams)
  }

  const createAgentPartnership: UseMutationResult<
    unknown,
    unknown,
    AgentPartnershipPostPayload,
    unknown
  > = useMutation(data => postAgentPartnership(data), {
    onSuccess: (data: { agentPartnership: AgentPartnership }) => {
      clearCaches()
    }
  })

  return {
    agentPartnerships: data?.agentPartnerships,
    createAgentPartnership,
    isLoading,
    isError
  }
}
