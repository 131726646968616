import { useState } from 'react'
import { toggleArrayItem } from './toggleArrayItem'

export const useSelected = () => {
  const [selected, setSelected] = useState<string[]>([])

  const toggleSelected = (id: string) => {
    const newArray = toggleArrayItem(selected, id)
    setSelected(newArray)
  }

  const clearSelected = () => setSelected([])
  const selectAll = (items: { id: string }[] = []) => {
    const ids = items?.map(({ id }) => id) || []
    setSelected(ids)
  }
  const isSelected = (id: string) => selected.some(str => str === id)
  const areSelected = (items: { id: string }[] = []) => {
    const ids = items?.map(({ id }) => id) || []
    return ids.every(id => selected.includes(id))
  }
  const hasSelection = !!selected.length

  return {
    selected,
    toggleSelected,
    clearSelected,
    selectAll,
    isSelected,
    areSelected,
    hasSelection
  }
}
