import { Icon, Menu, MenuButton, Section } from 'components'
import { useAgentTeamContext } from 'contexts/AgentTeam'
import type { AgentTeamMembership } from 'models'
import styles from './TeamMemberActionMenu.module.scss'

type TeamMemberActionMenuProps = {
  onFallbackAgentToggle: () => void
  onImpersonate: () => void
  onLeadRoutingToggle: () => void
  onRemoveTeamMember: () => void
  onWarmTransferToggle: () => void
  teamMember: AgentTeamMembership
}

export const TeamMemberActionMenu = ({
  onFallbackAgentToggle,
  onImpersonate,
  onLeadRoutingToggle,
  onRemoveTeamMember,
  onWarmTransferToggle,
  teamMember
}: TeamMemberActionMenuProps) => {
  const { agentTeamQuery } = useAgentTeamContext()

  const agentTeam = agentTeamQuery.data

  const toggleCopy = (state: boolean) => (state ? 'Remove from' : 'Add to')

  const leadRoutingState = teamMember?.user?.agent?.referralPreferences?.active
  const warmTransferState =
    teamMember?.user?.agent?.referralPreferences?.leadRoutingWarmTransferOptIn

  return (
    <Menu icon={Icon.Ellipse} className={styles.button}>
      <Section data-testid="teamMemberMenuSection">
        <MenuButton onClick={onRemoveTeamMember}>Delete team member</MenuButton>
        <MenuButton onClick={onImpersonate}>Impersonate</MenuButton>
        {agentTeam?.leadRoutingEnabled && (
          <>
            <MenuButton onClick={onLeadRoutingToggle}>
              {toggleCopy(leadRoutingState)} Lead Routing
            </MenuButton>
            {leadRoutingState && (
              <>
                <MenuButton onClick={onFallbackAgentToggle}>Set fallback agent</MenuButton>
                <MenuButton onClick={onWarmTransferToggle}>
                  {toggleCopy(warmTransferState)} Warm Transfer
                </MenuButton>
              </>
            )}
          </>
        )}
      </Section>
    </Menu>
  )
}
