import { SearchStatus, SearchSummary } from 'components'
import { useParamsContext, useTasksContext } from 'contexts'
import { objectMatch, presence } from 'utils'

export const TaskListStatus = () => {
  const { params, resetParams, initialParams, initializeParams } = useParamsContext()
  const { totalCount, isLoading } = useTasksContext()

  if (presence(initialParams)) {
    const canReset = !isLoading && !objectMatch(initialParams, params)

    return (
      <SearchSummary
        status={
          <SearchStatus params={params} count={totalCount} type="Tasks" unfilteredText="ALL" />
        }
        onReset={canReset ? initializeParams : null}
      />
    )
  }

  const canClear = !isLoading && presence(params)
  return (
    <SearchSummary
      status={<SearchStatus params={params} count={totalCount} type="Tasks" />}
      onClear={canClear ? resetParams : null}
    />
  )
}
