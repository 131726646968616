import React from 'react'
import { Block, Button, Shadow } from 'components'
import { Contact as ContactModel } from 'models'
import { cx, formatDate, formatTime } from 'utils'
import styles from './ContactListItem.module.scss'

type Props = {
  contact: ContactModel
  onClick?: (number) => void
  selected?: boolean
}

export const ContactListItem = ({ contact, onClick, selected }: Props) => {
  if (!contact) {
    return null
  }

  const handleClick = () => {
    onClick(contact.agent.id)
  }

  const lastMessagedAt = contact.messages[0] && contact.messages[0].createdAt
  const lastMessageBody = contact.messages[0] && contact.messages[0].body

  const outbound =
    contact.messages[0] && contact.messages[0].outbound ? (
      <span className={styles.outbound}>HL: </span>
    ) : null

  const lastMessage = (
    <>
      {outbound}
      {lastMessageBody}
    </>
  )

  const contactName = (
    <>
      {contact.agent.fullName}
      {contact.unreadCount ? ` (${contact.unreadCount})` : null}
    </>
  )

  const button = (
    <Button
      onClick={!selected ? handleClick : null}
      className={cx(
        styles.contact,
        selected ? styles.selected : null,
        contact.unreadCount ? styles.unread : null
      )}
    >
      <Block
        title={contactName}
        subtitle={lastMessage}
        date={
          <Block
            title={formatDate(lastMessagedAt, 'short')}
            subtitle={formatTime(lastMessagedAt, 'short')}
          />
        }
      />
    </Button>
  )

  return selected ? <Shadow>{button}</Shadow> : button
}
