import { useState } from 'react'
import { Point } from 'classes'
import { Button, FilterBar, FormField, List, MapInput, ToggleSelect } from 'components'
import { Lead } from 'models'
import { cx } from 'utils'
import styles from './AgentFilters.module.scss'
import { AgentsAlgoFilter } from './AgentsAlgoFilter'
import { AgentsClosingServicesFilter } from './AgentsClosingServicesFilter'
import { AgentsGenderFilter } from './AgentsGenderFilter'
import { AgentsHomeLoansFilter } from './AgentsHomeLoansFilter'
import { AgentsInContractFilter } from './AgentsInContractFilter'
import { AgentsWorksWithFilter } from './AgentsWorksWithFilter'

type Props = {
  filters: Record<string, string>
  lead?: Lead
  onChange: (key: string, val: string | number) => void
}

export const AgentsFilters = ({ filters, onChange, lead }: Props) => {
  const [center, setCenter] = useState(undefined)

  const handleSetCenter = e => {
    onChange('longitude', e.lngLat.lng)
    onChange('latitude', e.lngLat.lat)
    onChange('algoLeadId', undefined)
    setCenter(new Point({ latitude: e.lngLat.lat, longitude: e.lngLat.lng }))
  }

  const handleNoMap = () => {
    onChange('algoLeadId', undefined)
    onChange('longitude', undefined)
    onChange('latitude', undefined)
    setCenter(undefined)
  }

  const handleSetAlgoState = val => {
    if (val) {
      onChange('algoLeadId', lead.id.toString())
      onChange('longitude', undefined)
      onChange('latitude', undefined)
      setCenter(undefined)
    } else {
      onChange('algoLeadId', undefined)
      onChange('longitude', lead.longitude.toString())
      onChange('latitude', lead.latitude.toString())
      setCenter(new Point({ latitude: lead.latitude, longitude: lead.longitude }))
    }
  }

  return (
    <FilterBar>
      <AgentsGenderFilter value={filters.gender} onChange={val => onChange('gender', val)} />
      <AgentsWorksWithFilter
        value={filters.worksWith}
        onChange={val => onChange('worksWith', val)}
      />
      <AgentsInContractFilter
        value={filters.inContract}
        onChange={val => onChange('inContract', val)}
      />
      <FormField title="Enrolled" inline className={styles.filter}>
        <List className={styles.listSelect}>
          <AgentsHomeLoansFilter
            value={filters.homeLoans}
            onChange={val => onChange('homeLoans', val)}
          />
          <AgentsClosingServicesFilter
            value={filters.closingServices}
            onChange={val => onChange('closingServices', val)}
          />
          <ToggleSelect
            offOption={{ name: 'TI', value: undefined }}
            onOption={{ name: 'TI', value: 'enrolled' }}
            value={filters.tradeIn}
            onChange={val => onChange('tradeIn', val)}
          />
          <ToggleSelect
            offOption={{ name: 'CO', value: undefined }}
            onOption={{ name: 'CO', value: 'enrolled' }}
            value={filters.cashOffer}
            onChange={val => onChange('cashOffer', val)}
          />
          <ToggleSelect
            offOption={{ name: 'HLSS', value: undefined }}
            onOption={{ name: 'HLSS', value: 'enrolled' }}
            value={filters.hlss}
            onChange={val => onChange('hlss', val)}
          />
        </List>
      </FormField>
      <FormField title="Location" inline className={styles.filter}>
        {lead ? (
          <AgentsAlgoFilter value={!!filters.algoLeadId} onChange={handleSetAlgoState} />
        ) : null}
        <MapInput center={center} onClick={handleSetCenter} />
        <Button
          as="link"
          className={cx(styles.noMap, !filters.algoLeadId && !center ? 'selected' : null)}
          onClick={handleNoMap}
        >
          No Map
        </Button>
      </FormField>
    </FilterBar>
  )
}
