import {
  excludeObjectKeys,
  includeObjectKeys,
  objectToQueryString,
  removeAttributesWithNullValues
} from 'utils'

type TQueryArg = Record<string, any>

export const getViewParams = (settings: TQueryArg) => {
  const keys = ['viewColumns']
  return includeObjectKeys(settings, keys)
}

export const omitViewParams = (settings: TQueryArg) => {
  const omitKeys = ['viewColumns']
  return excludeObjectKeys(settings, omitKeys)
}

export const getViewParamsQueryString = (settings: TQueryArg) => {
  return objectToQueryString(removeAttributesWithNullValues(getViewParams(settings)))
}
