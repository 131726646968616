// @ts-strict
import {
  AgentPayPerLeadReferralResponse,
  PplBillingInfoResponse,
  PplProviderSettingResponse,
  ProviderLeadResponse
} from 'schema'
import { Model } from '../Model'

export class AgentPayPerLeadReferral extends Model {
  active?: boolean
  id?: string | number
  location?: string
  status?: string
  price?: number
  providerLead?: ProviderLeadResponse
  pplBillingInfo?: PplBillingInfoResponse
  pplProviderSetting?: PplProviderSettingResponse
  qualified?: boolean
  userType?: string
  notes?: string

  constructor(agentPayPerLeadReferralAttributes: AgentPayPerLeadReferralResponse) {
    super()
    Object.assign(this, agentPayPerLeadReferralAttributes)
  }

  static create(
    agentPayPerLeadReferralResponse: Potential<AgentPayPerLeadReferralResponse>
  ): Potential<AgentPayPerLeadReferralResponse> {
    if (!agentPayPerLeadReferralResponse) {
      return
    }

    return new AgentPayPerLeadReferral(agentPayPerLeadReferralResponse)
  }
}
