// @ts-strict
import { ReactNode } from 'react'
import { Box, Button, Dialog, Text } from '@foundation/components'
import { Flex } from '@foundation/components/Flex/styles'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  title: string
  value: ReactNode
}

export const LeadSensitiveInfoModal = ({ isOpen, setOpen, value, title }: Props) => {
  return (
    <Dialog.Root isOpen={isOpen} onOpenChange={open => setOpen(open)}>
      <Dialog.Content>
        <Dialog.Header color="danger9">Sensitive Data</Dialog.Header>
        <Dialog.Body>
          <Box marginBottom="$8">
            <Text color="danger9">Do not share this value with Clients or Agents.</Text>
          </Box>

          <Box marginBottom="$2">
            <Text size="xs" color="neutral8">
              {title}
            </Text>
          </Box>

          <Box marginBottom="$2">{value}</Box>
        </Dialog.Body>
        <Dialog.Footer>
          <Flex justifyContent="flex-end">
            <Button onClick={() => setOpen(false)}>Done</Button>
          </Flex>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}
