import { useCallback, useEffect } from 'react'

export const useBodyClass = className => {
  const addBodyClass = useCallback(() => {
    document.body.classList.add(className)
  }, [className])

  const removeBodyClass = useCallback(() => {
    document.body.classList.remove(className)
  }, [className])

  useEffect(() => {
    addBodyClass()
    return removeBodyClass
  }, [addBodyClass, removeBodyClass])

  return {
    addBodyClass,
    removeBodyClass
  }
}
