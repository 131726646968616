import { Agent } from 'models'
import { getAgentsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams, TAgentsSearchParams } from './getSearchParams'

export const fetchAgentsLegacy = async (
  settings: TAgentsSearchParams
): Promise<{ agents: Agent[]; totalCount: number }> => {
  const url = getAgentsUrl({
    searchParams: getSearchParams(settings)
  })
  const response = await getRequest(url)

  const agents = await deserializeAsync(response.data, {
    transform: Agent.create
  })
  return {
    agents,
    totalCount: response.data.meta.total_agents as number
  }
}
