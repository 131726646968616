import { Link } from 'react-router-dom'
import { Block, Contents, Page } from 'components'
import { useDocumentTitle } from 'hooks'

export const DevPage = () => {
  useDocumentTitle('Development')

  return (
    <Page>
      <Contents title="Development">
        <Block title={<Link to="/dev/icons">Icons</Link>} />
        <Block title={<Link to="/settings">Settings</Link>} />
      </Contents>
    </Page>
  )
}
