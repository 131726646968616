import { Link } from 'react-router-dom'
import { Block, Section } from 'components'
import { useCurrentUserContext, useSavedSearchesContext } from 'contexts'
import { useFeatureFlags } from 'hooks'

export const AdministrationSection = () => {
  const { canSeePartnerships, isSalesAppAdmin } = useCurrentUserContext()
  const { getLastSearchUrl } = useSavedSearchesContext()
  const {
    salesAppPartnerships,
    salesOpsCentralizedLeadDistribution,
    salesAppImpersonatingAgentTeamCoord,
    salesAppEoEaMapping
  } = useFeatureFlags([
    'sales-app-partnerships',
    'sales-ops-centralized-lead-distribution',
    'sales-app-impersonating-agent-team-coord',
    'sales-app-eo-ea-mapping'
  ])

  const shouldShowPartnerships = canSeePartnerships && salesAppPartnerships?.enabled

  const transactionTeamsRoute = salesOpsCentralizedLeadDistribution.enabled
    ? 'teams/transaction-teams'
    : 'teams'

  if (!isSalesAppAdmin && !shouldShowPartnerships) {
    return null
  }

  return (
    <Section title="Administration">
      {isSalesAppAdmin && (
        <>
          <Block title={<Link to={transactionTeamsRoute}>Transaction Teams</Link>} />

          {salesAppEoEaMapping.enabled && (
            <Block
              title={<Link to="/teams/settlement-agency-teams">Settlement Agency Teams</Link>}
            />
          )}

          {salesOpsCentralizedLeadDistribution.enabled && (
            <Block title={<Link to="/teams/client-advisor-teams">Client Advisory Teams</Link>} />
          )}

          <Block
            title={
              <Link to={getLastSearchUrl('/admin/agent-ae-areas', '?sortBy=AreaName&sortDir=asc')}>
                Agent Ae Areas
              </Link>
            }
          />
        </>
      )}

      {shouldShowPartnerships && <Block title={<Link to="/partnerships">Partnership</Link>} />}

      <Block title={<Link to="/admin/loan-officers">Loan Officers</Link>} />

      <Block title={<Link to="/admin/investors">Investors</Link>} />

      <Block title={<Link to="/lead-creation-admin">Lead Creation Admin</Link>} />

      {isSalesAppAdmin && (
        <>
          <Block
            title={
              salesAppImpersonatingAgentTeamCoord.enabled ? (
                <Link to={getLastSearchUrl('/admin/users', '?userStatus=active')}>Users</Link>
              ) : (
                <Link to={getLastSearchUrl('/admin/employees', '?userStatus=active')}>
                  Employees
                </Link>
              )
            }
          />

          <Block title={<Link to="/lrm">LRM Round Robin</Link>} />

          <Block title={<Link to="/ca">CA Round Robin</Link>} />

          <Block title={<Link to="/transaction-operators">Transaction Operators</Link>} />

          <Block title={<Link to="/processors">Processor Round Robin</Link>} />
        </>
      )}
    </Section>
  )
}
