// @ts-strict
import { Agent, LenderProviderLead, TradeInLead, TradeInProviderLead, UserInvitation } from 'models'
import { LeadResponse } from 'schema'
import { Lead, TLeadChildConstructor } from '../Lead'
import { getLead, getLeadUsers } from '../utils'

type TCCTradeInLeadConstructor = TLeadChildConstructor<CCTradeInLead>
export class CCTradeInLead extends Lead {
  agent?: Agent
  agentTeamsName?: string
  calculatedValue?: string
  siblingLeads?: Lead[]
  isHLSS?: boolean
  modelName? = 'CCTradeInLead'
  providableName? = 'tradeInLead'
  providerName? = 'tradeInProviderLead'
  referringAgent?: Agent
  revenueRecognitionDate?: string
  tradeInLead?: TradeInLead
  tradeInProviderLead?: TradeInProviderLead
  lenderProviderLeads?: LenderProviderLead[]
  userInvitations?: UserInvitation[]
  constructor(cCTradeInLeadAttributes: TCCTradeInLeadConstructor) {
    super(cCTradeInLeadAttributes)
    Object.assign(this, cCTradeInLeadAttributes)
  }

  static create(cCTradeInLeadResponse: Potential<LeadResponse>): Potential<CCTradeInLead> {
    if (!cCTradeInLeadResponse) {
      return
    }
    const tradeInProviderLead = TradeInProviderLead.create(
      cCTradeInLeadResponse.tradeInProviderLead
    )
    const tradeInLead = tradeInProviderLead?.tradeInLead
    const referringAgent = Agent.create(cCTradeInLeadResponse.referringOwner)
    const agent = tradeInProviderLead?.tradeInLead?.departingPropertySellerAgent
    const leadUsers = getLeadUsers(cCTradeInLeadResponse)
    const agentTeamsName = agent?.agentTeamName

    const calculatedValue = cCTradeInLeadResponse.calculatedValue
    const revenueRecognitionDate = cCTradeInLeadResponse.revenueRecognitionDate
    const isHLSS = !!tradeInLead?.simpleSale
    const leadAttributes = Lead.mapResponse(cCTradeInLeadResponse)
    const siblingLeads = (cCTradeInLeadResponse.childLeads?.map(getLead) as Lead[]) || []

    return new CCTradeInLead({
      ...leadAttributes,
      agent,
      agentTeamsName,
      calculatedValue,
      siblingLeads,
      isHLSS,
      leadUsers,
      referringAgent,
      revenueRecognitionDate,
      tradeInLead,
      tradeInProviderLead
    })
  }
}
