// @ts-strict
import { CashOfferLead, User } from 'models'
import { ExternalLinkResponse } from 'schema'
import { Model } from '../Model'

export class ExternalLink extends Model {
  createdAt?: string
  createdBy?: User
  id!: string
  name?: string
  subAttachable?: CashOfferLead
  url?: string
  constructor(attributes: Omit<ExternalLink, keyof Model>) {
    super()
    Object.assign(this, attributes)
  }

  static create(externalLink: Potential<ExternalLinkResponse>): Potential<ExternalLink> {
    if (!externalLink) {
      return
    }
    const createdBy = User.create(externalLink?.createdBy)
    const subAttachable = CashOfferLead.create(externalLink?.subAttachable)

    return new ExternalLink({ ...externalLink, createdBy, subAttachable })
  }
}
