import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  children: React.ReactNode
  domElement?: string
}

function useForceUpdate() {
  const [, dispatch] = useState<{}>(Object.create(null))
  return useCallback(() => {
    dispatch(Object.create(null))
  }, [])
}

export const Portal = ({ children, domElement = 'div' }: Props) => {
  const mountNode = useRef<HTMLDivElement | null>(null)
  const portalNode = useRef<HTMLElement | null>(null)
  const forceUpdate = useForceUpdate()

  useLayoutEffect(() => {
    // This ref may be null when a hot-loader replaces components on the page
    if (!mountNode.current) {
      return
    }
    // It's possible that the content of the portal has, itself, been portaled.
    // In that case, it's important to append to the correct document element.
    const ownerDocument = mountNode.current!.ownerDocument
    portalNode.current = ownerDocument?.createElement(domElement)!
    ownerDocument!.body.appendChild(portalNode.current)
    forceUpdate()
    return () => {
      if (portalNode.current && portalNode.current.ownerDocument) {
        portalNode.current.ownerDocument.body.removeChild(portalNode.current)
      }
    }
  }, [domElement, forceUpdate])

  return portalNode.current ? createPortal(children, portalNode.current) : <span ref={mountNode} />
}
