import { AgentTeam } from 'models'
import { AgentSchema, getHapiRequestParams } from 'schema'
import { getAgentsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { agentListShape } from '../Agents/agentsShapes'

const agentShape: AgentSchema = {
  agent: [
    'referralStats',
    {
      agentTeam: [
        'name',
        'leadRoutingEnabled',
        'leadRoutingSeats',
        'warmTransferSeats',
        {
          agentTeamMemberships: [
            { user: ['firstName', 'lastName', 'email', { agent: agentListShape }] }
          ]
        }
      ]
    }
  ]
}

export const fetchAgentTeam = async (agentId: string) => {
  const url = getAgentsUrl({
    id: agentId,
    searchParams: getHapiRequestParams(agentShape)
  })

  const response = await getRequest(url)
  const agent = await deserializeAsync(response.data)
  return AgentTeam.create(agent?.agentTeam, agent)
}
