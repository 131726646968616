// @ts-strict
import { LeadChannelResponse } from 'schema'
import { Model } from '../Model'

export class LeadChannel extends Model {
  id?: string
  name?: string
  opsMethod?: string

  constructor(leadChannelAttributes: LeadChannel) {
    super()
    Object.assign(this, leadChannelAttributes)
  }

  static create(
    leadChannelResponse: Potential<LeadChannelResponse>
  ): Potential<LeadChannelResponse> {
    if (!leadChannelResponse) {
      return
    }

    return new LeadChannel(leadChannelResponse)
  }
}
