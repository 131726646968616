import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from '../Section.module.scss'
import { SectionHeader } from '../SectionHeader'
import { SectionTitle } from '../SectionTitle'

type Props = {
  actions?: ReactNode
  children: ReactNode
  className?: string
  title?: string
  titleStyle?: React.CSSProperties
}

export const Sections = ({ className, title, actions, children, titleStyle, ...props }: Props) => {
  let sectionHeader
  if (title || actions) {
    sectionHeader = (
      <SectionHeader>
        <SectionTitle style={titleStyle}>{title}</SectionTitle>
        {actions}
      </SectionHeader>
    )
  }
  return (
    <div className={styles.sectionsWrapper} {...props} data-testid="section-wrapper">
      {sectionHeader}
      <div className={cx(styles.sections, className)}>{children}</div>
    </div>
  )
}
