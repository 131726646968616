// @ts-strict
import { Table, TableBody } from 'components/Table'
import { TeamMembership } from 'models'
import { TransactionTeamMembersListHeader } from './TransactionTeamMembersListHeader'
import { TransactionTeamMembersListRow } from './TransactionTeamMembersListRow'

type TProps = {
  members?: TeamMembership[]
}

export const TransactionTeamMembersList = ({ members = [] }: TProps) => {
  return (
    <Table>
      <TransactionTeamMembersListHeader />
      <TableBody>
        {members.map(member => {
          return <TransactionTeamMembersListRow member={member} key={member.id} />
        })}
      </TableBody>
    </Table>
  )
}
