import { AgentPartnership } from 'models'
import { AgentPartnershipSchema, getHapiRequestParams } from 'schema'
import { getAgentPartnershipsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams, SearchParamsSettings } from '.'

const agentPartnershipShape: AgentPartnershipSchema = {
  partnerProgram: [
    'active',
    'createdAt',
    'description',
    'destinationUrl',
    'id',
    'incomingUrl',
    'name',
    'agentsCount',
    {
      'transactionTeam:team': ['name']
    },
    'updatedAt'
  ]
}
export const fetchAgentPartnerships = async (settings: SearchParamsSettings) => {
  const url = getAgentPartnershipsUrl({
    searchParams: {
      ...getHapiRequestParams(agentPartnershipShape),
      ...getSearchParams(settings),
      'page[size]': 1000
    }
  })

  const response = await getRequest(url)

  const agentPartnerships: AgentPartnership[] = await deserializeAsync(response.data, {
    transform: AgentPartnership.create
  })
  return {
    agentPartnerships,
    totalCount: response.data.meta.total_agent_leads as number
  }
}
