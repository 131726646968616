interface IRecord<T> {
  [k: string]: T
}

type TNestedRecord = Record<string, string | IRecord<TNestedRecord>>

export const expandPayload = (obj: Record<string, string>): TNestedRecord => {
  return Object.keys(obj).reduce((acc, key) => {
    if (key.includes('.')) {
      const [first, ...restArray] = key.split('.')
      const rest = restArray.join('.')
      if (acc[first]) {
        // if this key already exists, it must be an object, merge in next key.
        return {
          ...acc,
          ...{ [first]: expandPayload({ ...acc[first], [rest]: obj[key] }) }
        }
      }
      if (rest) {
        return { ...acc, [first]: expandPayload({ [rest]: obj[key] }) }
      }
      return { ...acc, [first]: obj[key] }
    }
    return { ...acc, [key]: obj[key] }
  }, {})
}
