import { TModelFieldSetting } from '../ModelField'

export type TCcTradeInLeadFieldAttribute =
  | 'actualCashCloseFee'
  | 'actualLoanAmount'
  | 'agentValuation'
  | 'avmDate'
  | 'calculatedValue'
  | 'changeReasons.departingPropertyCloseOfPurchaseEscrowAt.note'
  | 'changeReasons.departingPropertyCloseOfPurchaseEscrowAt.reason'
  | 'changeReasons.departingPropertyCloseOfSaleEscrowAt.note'
  | 'changeReasons.departingPropertyCloseOfSaleEscrowAt.reason'
  | 'departingPropertyActualDom'
  | 'departingPropertyActualSalePrice'
  | 'departingPropertyAgentValuationReceivedAt'
  | 'departingPropertyCloseOfPurchaseEscrowAt'
  | 'departingPropertyCloseOfSaleEscrowAt'
  | 'departingPropertyDaysOfOwnership'
  | 'departingPropertyExpectedListingPrice'
  | 'departingPropertyExternalListingDate'
  | 'departingPropertyExternalListingStatus'
  | 'departingPropertyExternalListingStatusDate'
  | 'departingPropertyExternalLoanPayoff'
  | 'departingPropertyFlags'
  | 'departingPropertyFundsWiredAmount'
  | 'departingPropertyFundsWiredToClientAt'
  | 'departingPropertyGuaranteedPrice'
  | 'departingPropertyHomeownerAssociation'
  | 'departingPropertyInspectedAt'
  | 'departingPropertyListedAt'
  | 'departingPropertyListingPrice'
  | 'departingPropertyLoanPayoff'
  | 'departingPropertyMarketDom'
  | 'departingPropertyMarketIndex'
  | 'departingPropertyMarketInventoryCount'
  | 'departingPropertyOfferAcceptedAt'
  | 'departingPropertyOfferCount'
  | 'departingPropertyOpenedPurchasedEscrowAt'
  | 'departingPropertyOpenedSaleEscrowAt'
  | 'departingPropertyPercentEquity'
  | 'departingPropertyPreliminaryTitleReviewed'
  | 'departingPropertyPreliminaryValuation'
  | 'departingPropertyPriceDelta'
  | 'departingPropertyQualificationReason'
  | 'departingPropertyQualified'
  | 'departingPropertySolarLoanBalance'
  | 'departingPropertySolarPanels'
  | 'departingPropertyValuation'
  | 'displayAddress'
  | 'dpActualClosingCosts'
  | 'dpActualInspection'
  | 'dpActualHoaDocumentFees'
  | 'dpActualNewHomePurchaseEquityHl'
  | 'dpActualLoanPayoff'
  | 'dpActualSolarLoanBalance'
  | 'dpActualOtherExpenses'
  | 'dpActualPurchaseFundedDate'
  | 'dpActualTotalListingPrepWork'
  | 'dpAgentAfterRepairValue'
  | 'dpAgentAfterRepairValueDate'
  | 'dpAnalystValuation'
  | 'dpAnalystValuationDate'
  | 'dpAnalystAfterRepairValue'
  | 'dpAnalystAfterRepairValueDate'
  | 'dpBuyersAgentCommissionRate'
  | 'dpBorrowingBase'
  | 'dpCapitalDaysOutstanding'
  | 'dpDaysSellerInPossession'
  | 'dpEstimatedDaysOfWork'
  | 'dpEstimatedListToPending'
  | 'dpEstimatedSaleEscrow'
  | 'dpFundingAmount'
  | 'dpHlEquity'
  | 'dpLocalTransferTaxRate'
  | 'dpPropertyType'
  | 'dpSellersAgentCommissionRate'
  | 'dpSellerCredits'
  | 'dpSellerVacateDate'
  | 'dpTargetNewHomePurchaseEquity'
  | 'dpTargetPurchaseCoeDate'
  | 'dpTargetSellCoeDate'
  | 'dpTargetTotalCosts'
  | 'dpTargetTradeInFee'
  | 'dpTargetTradeInFeeRate'
  | 'dpTotalEstimatedDaysOfOwnership'
  | 'hlcsAttached'
  | 'incomingPropertyCloseOfEscrowAt'
  | 'incomingPropertyFullAddress'
  | 'incomingPropertyInspectionContingencyWaived'
  | 'incomingPropertyMaxOfferPrice'
  | 'incomingPropertyOfferAcceptedAt'
  | 'incomingPropertyOfferCancelledAt'
  | 'incomingPropertyOfferCancelledReason'
  | 'incomingPropertyOfferRejectedAt'
  | 'incomingPropertyOfferRejectedReason'
  | 'incomingPropertySalePrice'
  | 'incomingPropertyValuation'
  | 'pastDue'
  | 'revenueRecognitionDate'
  | 'reasonForFail'
  | 'simpleSale'
  | 'tradeInPlus'
  | 'twoCheckHlSimpleSale'

export type TCcTradeInLeadFieldSetting = {
  attr: TCcTradeInLeadFieldAttribute
} & TModelFieldSetting

export const ccTradeInLeadFieldsSettings: TCcTradeInLeadFieldSetting[] = [
  {
    attr: 'actualCashCloseFee',
    name: 'Actual Trade-In Fee ($)',
    inputType: 'dollarsAndCents'
  },
  {
    attr: 'actualLoanAmount',
    name: 'Actual Loan Payoff',
    inputType: 'money'
  },
  {
    attr: 'agentValuation',
    name: 'Agent As-Is Valuation',
    inputType: 'money'
  },
  {
    attr: 'avmDate',
    name: 'AVM Valuation Date'
  },
  {
    attr: 'calculatedValue',
    name: 'Actual Trade-In Fee',
    inputType: 'dollarsAndCents'
  },
  {
    attr: 'changeReasons.departingPropertyCloseOfPurchaseEscrowAt.reason',
    name: 'Reason for change to Actual COE Purchase Date',
    inputType: 'select',
    getOptions: 'coeDateChangeReasons'
  },
  {
    attr: 'changeReasons.departingPropertyCloseOfPurchaseEscrowAt.note',
    name: 'Note about change to Actual COE Purchase Date',
    inputType: 'text'
  },
  {
    attr: 'changeReasons.departingPropertyCloseOfSaleEscrowAt.reason',
    name: 'Reason for change to Actual COE Sale Date',
    inputType: 'select',
    getOptions: 'coeDateChangeReasons'
  },
  {
    attr: 'changeReasons.departingPropertyCloseOfSaleEscrowAt.note',
    name: 'Note about change to Actual COE Sale Date',
    inputType: 'text'
  },
  {
    attr: 'departingPropertyActualDom',
    name: 'Actual Days on Market',
    inputType: 'integer'
  },
  {
    attr: 'departingPropertyActualSalePrice',
    name: 'Sale Price',
    inputType: 'money'
  },
  {
    attr: 'departingPropertyAgentValuationReceivedAt',
    name: 'Agent As-Is Val Date',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyCloseOfPurchaseEscrowAt',
    name: 'Actual Purchase COE Date',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyCloseOfSaleEscrowAt',
    name: 'Actual Sale COE Date',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyDaysOfOwnership',
    name: 'Actual Days of Ownership',
    inputType: 'integer'
  },
  {
    attr: 'departingPropertyExpectedListingPrice',
    name: 'Expected Listing Price',
    inputType: 'money'
  },
  {
    attr: 'departingPropertyExternalListingDate',
    name: 'Listed On',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyExternalListingStatus',
    name: 'Currently Listed'
  },
  {
    attr: 'departingPropertyExternalListingStatusDate',
    name: 'Listing Status Updated',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyExternalLoanPayoff',
    name: 'Target Loan Payoff',
    inputType: 'money'
  },
  {
    attr: 'departingPropertyFlags',
    name: 'Prop Flagged Reason'
  },
  {
    attr: 'departingPropertyFundsWiredAmount',
    name: 'Client Upside Proceeds',
    inputType: 'money'
  },
  {
    attr: 'departingPropertyFundsWiredToClientAt',
    name: 'Client Upside Proceeds Wired Date',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyGuaranteedPrice',
    name: 'Risk Adjusted Valuation',
    inputType: 'money'
  },
  {
    attr: 'departingPropertyHomeownerAssociation',
    name: 'HOA',
    inputType: 'boolean'
  },
  {
    attr: 'departingPropertyInspectedAt',
    name: 'Property Inspected At',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyListedAt',
    name: 'Listed Date',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyListingPrice',
    name: 'List Price',
    inputType: 'money'
  },
  {
    attr: 'departingPropertyLoanPayoff',
    name: 'Actual Loan Payoff - TRUS',
    inputType: 'money'
  },
  {
    attr: 'departingPropertyMarketDom',
    name: 'Market Days On Market'
  },
  {
    attr: 'departingPropertyMarketIndex',
    name: 'Market Index'
  },
  {
    attr: 'departingPropertyMarketInventoryCount',
    name: 'Market Inventory'
  },
  {
    attr: 'departingPropertyOfferAcceptedAt',
    name: 'Offer Accepted',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyOfferCount',
    name: 'Offers Made',
    inputType: 'integer'
  },
  {
    attr: 'departingPropertyOpenedPurchasedEscrowAt',
    name: 'Open Purchase Escrow Date',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyOpenedSaleEscrowAt',
    name: 'Open Sale Escrow Date',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyPercentEquity',
    name: '% of Equity in Home'
  },
  {
    attr: 'departingPropertyPreliminaryTitleReviewed',
    name: 'Prelim Title',
    inputType: 'boolean'
  },
  {
    attr: 'departingPropertyPreliminaryValuation',
    name: 'AVM Valuation'
  },
  {
    attr: 'departingPropertyPriceDelta',
    name: 'Sale - List Price',
    inputType: 'money'
  },
  {
    attr: 'departingPropertyQualificationReason',
    name: 'DNQ Reason',
    inputType: 'select'
  },
  {
    attr: 'departingPropertyQualified',
    name: 'Prop Qualified',
    inputType: 'select'
  },
  {
    attr: 'departingPropertySolarLoanBalance',
    name: 'Solar Loan Balance'
  },
  {
    attr: 'departingPropertySolarPanels',
    name: 'Solar Panels',
    inputType: 'select',
    options: [
      { name: 'No Solar', value: 'no_solar' },
      { name: 'Leased', value: 'leased' },
      { name: 'Owned - Loan', value: 'owned_loan' },
      { name: 'Fully Owned', value: 'fully_owned' }
    ]
  },
  {
    attr: 'departingPropertyValuation',
    name: 'HL Valuation',
    inputType: 'money'
  },
  {
    attr: 'displayAddress',
    name: 'Address'
  },
  {
    attr: 'dpActualNewHomePurchaseEquityHl',
    name: 'Actual Equity for New Home Purchase',
    inputType: 'dollarsAndCents'
  },
  {
    attr: 'dpActualPurchaseFundedDate',
    name: 'Funding Date',
    inputType: 'date'
  },
  {
    attr: 'dpBuyersAgentCommissionRate',
    name: 'Target Agent Buy Commission Rate',
    inputType: 'percent'
  },
  {
    attr: 'dpCapitalDaysOutstanding',
    name: 'Days of Investor Capital Outstanding',
    inputType: 'integer'
  },
  {
    attr: 'dpDaysSellerInPossession',
    name: 'Days Seller in Possession',
    inputType: 'integer'
  },
  {
    attr: 'dpFundingAmount',
    name: 'Funding Amt',
    inputType: 'dollarsAndCents'
  },
  {
    attr: 'dpLocalTransferTaxRate',
    name: 'Target City Transfer Tax %',
    inputType: 'percent'
  },
  {
    attr: 'dpPropertyType',
    name: 'Property Type',
    inputType: 'select'
  },
  {
    attr: 'simpleSale',
    name: 'HL Simple Sale',
    inputType: 'select'
  },
  {
    attr: 'tradeInPlus',
    name: 'Trade-in+',
    inputType: 'boolean'
  },
  {
    attr: 'dpSellersAgentCommissionRate',
    name: 'Target Agent Sell Commission Rate',
    inputType: 'percent'
  },
  {
    attr: 'dpTargetNewHomePurchaseEquity',
    name: 'Target',
    inputType: 'money'
  },
  {
    attr: 'dpTargetTradeInFeeRate',
    name: 'Target Trade-In Fee %',
    inputType: 'percent'
  },
  {
    attr: 'dpTargetPurchaseCoeDate',
    name: 'Target Purchase COE Date',
    inputType: 'date'
  },
  {
    attr: 'dpTargetSellCoeDate',
    name: 'Target Sale COE Date',
    inputType: 'date'
  },
  {
    attr: 'dpTargetTotalCosts',
    name: 'Total Estimated Costs',
    inputType: 'money'
  },
  {
    attr: 'dpTargetTradeInFee',
    name: 'Actual Program Fee',
    inputType: 'dollarsAndCents'
  },
  {
    attr: 'dpTargetTradeInFeeRate',
    name: 'Target Trade-In Fee %',
    inputType: 'percent'
  },
  {
    attr: 'hlcsAttached',
    name: 'HLCS Attached',
    inputType: 'select',
    options: [
      { value: 'true', name: 'Yes' },
      { value: 'false', name: 'No' }
    ]
  },
  {
    attr: 'incomingPropertyCloseOfEscrowAt',
    name: 'Actual IR COE Date',
    inputType: 'date'
  },
  {
    attr: 'incomingPropertyFullAddress',
    name: 'IR Full Address',
    inputType: 'address'
  },
  {
    attr: 'incomingPropertyInspectionContingencyWaived',
    name: 'Insp. Cont. Waived'
  },
  {
    attr: 'incomingPropertyMaxOfferPrice',
    name: 'Max Cash Offer Purchase Price'
  },
  {
    attr: 'incomingPropertyOfferAcceptedAt',
    name: 'Offer Accepted Date',
    inputType: 'date'
  },
  {
    attr: 'incomingPropertyOfferCancelledAt',
    name: 'Cancel Date',
    inputType: 'date'
  },
  {
    attr: 'incomingPropertyOfferCancelledReason',
    name: 'Cancel Reason'
  },
  {
    attr: 'incomingPropertyOfferRejectedAt',
    name: 'Rejected Date',
    inputType: 'date'
  },
  {
    attr: 'incomingPropertyOfferRejectedReason',
    name: 'Rejected Reason'
  },
  {
    attr: 'incomingPropertySalePrice',
    name: 'Cash Offer Purchase Price'
  },
  {
    attr: 'incomingPropertyValuation',
    name: 'HL Expected Appraisal'
  },
  {
    attr: 'pastDue',
    name: 'Past Due'
  },
  {
    attr: 'reasonForFail',
    name: 'Failed Reason',
    inputType: 'select',
    getOptions: 'tradeInFailedReasons'
  },
  {
    attr: 'revenueRecognitionDate',
    name: 'Waterfall Funds Received Date',
    inputType: 'date'
  },
  {
    attr: 'twoCheckHlSimpleSale',
    name: '2 Check HLSS',
    inputType: 'select'
  },
  {
    attr: 'dpEstimatedDaysOfWork',
    name: 'Estimated Days of Work',
    inputType: 'integer'
  },
  {
    attr: 'dpEstimatedListToPending',
    name: 'Estimated List-to-Pending',
    inputType: 'integer'
  },
  {
    attr: 'dpEstimatedSaleEscrow',
    name: 'Estimated Sale Escrow',
    inputType: 'integer'
  },
  {
    attr: 'dpTotalEstimatedDaysOfOwnership',
    name: 'Total Estimated Days of Ownership',
    inputType: 'integer'
  },
  {
    attr: 'dpSellerVacateDate',
    name: 'Seller Vacate Date',
    inputType: 'date'
  },
  {
    attr: 'dpBorrowingBase',
    name: ' Funding Amt - Borrowing Base',
    inputType: 'money'
  },
  {
    attr: 'dpHlEquity',
    name: ' Funding Amt - HL Equity',
    inputType: 'money'
  },
  {
    attr: 'dpSellerCredits',
    name: 'Seller Credits',
    inputType: 'money'
  },
  {
    attr: 'dpActualClosingCosts',
    name: 'Actual Closing Costs',
    inputType: 'money'
  },
  {
    attr: 'dpActualInspection',
    name: 'Actual Inspection',
    inputType: 'money'
  },
  {
    attr: 'dpActualHoaDocumentFees',
    name: 'Actual HOA Document Fees',
    inputType: 'money'
  },
  {
    attr: 'dpActualTotalListingPrepWork',
    name: 'Listing Prep Work',
    inputType: 'money'
  },
  {
    attr: 'dpActualLoanPayoff',
    name: 'Actual Loan Payoff - HomeLight',
    inputType: 'money'
  },
  {
    attr: 'dpActualSolarLoanBalance',
    name: 'Actual Solar Loan Balance',
    inputType: 'money'
  },
  {
    attr: 'dpActualOtherExpenses',
    name: 'Actual Other Expenses',
    inputType: 'money'
  },
  {
    attr: 'dpAgentAfterRepairValue',
    name: 'Agent ARV',
    inputType: 'money'
  },
  {
    attr: 'dpAgentAfterRepairValueDate',
    name: 'Agent ARV Date',
    inputType: 'date'
  },
  {
    attr: 'dpAnalystValuation',
    name: 'Analyst As-Is Valuation',
    inputType: 'money'
  },
  {
    attr: 'dpAnalystValuationDate',
    name: 'Analyst As-Is Val Date',
    inputType: 'date'
  },
  {
    attr: 'dpAnalystAfterRepairValue',
    name: 'Analyst ARV',
    inputType: 'money'
  },
  {
    attr: 'dpAnalystAfterRepairValueDate',
    name: 'Analyst ARV Date',
    inputType: 'date'
  }
]
