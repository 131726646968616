import { TCertification } from '../types'

export const getMoveSafeFilter = (moveSafe: Potential<TCertification>) => {
  const filters = {}
  if (moveSafe === 'certified') {
    filters['filter[capabilities.certified_move_safe]'] = true
  } else if (moveSafe === 'not certified') {
    filters['filter[capabilities.certified_move_safe]'] = false
  }

  return filters
}
