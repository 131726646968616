// @ts-strict
import { ButtonForm, useButtonForm } from 'components'
import { useUserIntegrationsController } from 'hooks'
import { TUserIntegrationPayload } from 'services'
import { TFormValidation, validatePresence } from 'utils'
import { UserIntegrationFields } from '.'

type TProps = {
  employeeId: string
}

export const UserIntegrationCreateButtonForm = ({ employeeId }: TProps) => {
  const { createUserIntegration } = useUserIntegrationsController(employeeId)

  const initialPayload = {
    value: ''
  }

  const formValidations: TFormValidation = {
    name: validatePresence,
    value: validatePresence
  }

  const { formProps, fieldsProps } = useButtonForm<TUserIntegrationPayload>({
    initialPayload,
    mutation: createUserIntegration,
    formValidations
  })

  return (
    <ButtonForm buttonText="Add" title="Add User Integration" {...formProps}>
      <UserIntegrationFields fieldsProps={fieldsProps} />
    </ButtonForm>
  )
}
