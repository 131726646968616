// @ts-strict
import { ReactNode } from 'react'
import { Icon, IconProps } from 'components'
import { cx } from 'utils'
import { ButtonV2, ButtonV2Props } from '../ButtonV2'
import styles from './IconButton.module.scss'

export type IconButtonProps = { icon: ReactNode; inverted?: boolean } & ButtonV2Props

export const IconButton = ({
  children,
  isLoading,
  inverted,
  className,
  icon,
  ...props
}: IconButtonProps) => {
  return (
    <ButtonV2
      data-testid="icon-button"
      className={cx(styles.default, inverted && styles.inverted, className)}
      isLoading={isLoading}
      {...props}
    >
      {!isLoading && icon}
    </ButtonV2>
  )
}

const getIconButtonComponent =
  (Icon: (props: IconProps) => JSX.Element, dataTestId: string) =>
  (props: Omit<IconButtonProps, 'icon'>) =>
    <IconButton icon={<Icon />} data-testid={dataTestId} {...props} />

export const AddIconButton = getIconButtonComponent(Icon.Plus, 'add-button')
export const ConfirmIconButton = getIconButtonComponent(Icon.Check, 'confirm-button')
export const DeleteIconButton = getIconButtonComponent(Icon.Close, 'delete-button')
export const EditIconButton = getIconButtonComponent(Icon.Pencil, 'edit-button')
export const ViewIconButton = getIconButtonComponent(Icon.Eye, 'view-button')
export const RepeatIconButton = getIconButtonComponent(Icon.Repeat, 'repeat-button')
