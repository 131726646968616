import { Ellipsis, FormField } from 'components'
import { Section } from 'components'
import { Investor } from 'models'
import { formatPhone } from 'utils'
import { cx } from 'utils'

type Props = {
  className?: string
  investor: Investor
}

export const InvestorContact = ({ investor, className }: Props) => {
  return (
    <Section title="Contact" className={cx('investor-contact', className)}>
      <div className={className}>
        <FormField title="Name" inline>
          <Ellipsis>{investor.name}</Ellipsis>
        </FormField>
        <FormField title="Email" inline>
          <Ellipsis>{investor.email}</Ellipsis>
        </FormField>
        <FormField title="Cell Phone" inline>
          <Ellipsis>{formatPhone(investor.phoneMobile)}</Ellipsis>
        </FormField>
        <FormField title="Office Phone" inline>
          <Ellipsis>{formatPhone(investor.phoneOffice)}</Ellipsis>
        </FormField>
        <FormField title="PPL Investor" inline>
          <Ellipsis>{investor.pplInvestor}</Ellipsis>
        </FormField>
      </div>
    </Section>
  )
}
