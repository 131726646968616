// @ts-strict

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

type TReorderableProvider = {
  children: React.ReactChild
}

export const ReorderableProvider = ({ children }: TReorderableProvider) => {
  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>
}
