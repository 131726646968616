import { SearchParams } from '@types'
import { AgentAttributesAndAssociations, getHapiRequestParams } from 'schema'
import { DEFAULT_PLATFORM_METRICS_TIME_RANGE } from 'schema/AgentPlatformMetricsSchema'
import { removeAttributesWithNullValues, toCamelCase } from 'utils'
import { agentListShape, agentMetricsShape } from './agentsShapes'
import {
  getAgentProgramFilter,
  getCalendlyFilter,
  getMoveSafeFilter,
  getWarmTransferFilter,
  TEnrollmentStatus,
  TProgramKey,
  TProgramsCertifications
} from './getFilters'

const sortParam = {
  Name: 'first_name.keyword',
  Metro: 'assigned_area.metroplex_name.keyword',
  State: 'state_code',
  Total: 'referral_stats.total_count',
  Active: 'referral_stats.active_count',
  Buyers: 'referral_stats.active_buyer_count',
  Sellers: 'referral_stats.active_seller_count',
  Listings: 'referral_stats.in_listing_count',
  Introduced: 'referral_stats.in_introduced_count',
  Meetings: 'referral_stats.in_meeting_scheduled_count',
  ALVM: 'referral_stats.in_agent_left_vm_count',
  Connected: 'referral_stats.in_connected_count',
  Closed: 'referral_stats.closed_count',
  Red: 'referral_stats.red_count',
  Yellow: 'referral_stats.yellow_count',
  Green: 'referral_stats.green_count',
  Urgent: 'referral_stats.urgent_count',
  'Last Update': 'last_activity_at',
  'Last Touch': 'last_touched_at',
  'Last Login': 'last_signed_in_at',
  Opps: 'services_opportunities_counts.total_count',
  'Closing Services': 'services_opportunities_counts.escrow_count',
  'Home Loans': 'services_opportunities_counts.mortgage_count',
  Lost: 'services_opportunities_counts.lost_count',

  name: 'first_name.keyword',
  total: 'referral_stats.total_count',
  introducedReferrals: 'platform_metrics.introduced_referrals',
  closedReferrals: 'platform_metrics.closed_referrals',
  homesLeads: 'platform_metrics.homes_leads',
  homesApprovals: 'platform_metrics.homes_approvals',
  homesCloses: 'platform_metrics.homes_closes',
  lastUpdate: 'last_activity_at',
  lastTouch: 'last_touched_at',
  lastLogin: 'last_signed_in_at',
  agentLeftVmReferrals: 'platform_metrics.agent_left_vm_referrals',
  connectedReferrals: 'platform_metrics.connected_referrals',
  meetingScheduledReferrals: 'platform_metrics.meeting_scheduled_referrals',
  metInPersonReferrals: 'platform_metrics.met_in_person_referrals',
  listingReferrals: 'platform_metrics.listing_referrals',
  failedReferrals: 'platform_metrics.failed_referrals',
  escrowOpens: 'platform_metrics.escrow_opens',
  escrowCloses: 'platform_metrics.escrow_closes',
  agentToHlTradeInLeads: 'platform_metrics.agent_to_hl_trade_in_leads',
  agentToHlCashOfferLeads: 'platform_metrics.agent_to_hl_cash_offer_leads',
  agentToHomelightSimpleSaleLeads: 'platform_metrics.agent_to_homelight_simple_sale_leads',
  agentToHlTradeInApprovalsCompleted: 'platform_metrics.agent_to_hl_trade_in_approvals_completed',
  agentToHlCashOfferApprovalsCompleted:
    'platform_metrics.agent_to_hl_cash_offer_approvals_completed',
  agentToHomelightSimpleSaleApprovalsCompleted:
    'platform_metrics.agent_to_homelight_simple_sale_approvals_completed',
  agentToHlCashOfferCloses: 'platform_metrics.agent_to_hl_cash_offer_closes',
  agentToHomelightSimpleSaleCloses: 'platform_metrics.agent_to_homelight_simple_sale_closes',
  agentToHlTradeInCloses: 'platform_metrics.agent_to_hl_trade_in_closes',
  homesEnrollmentDate: 'homes_enrollment_date',
  referralsEnrollmentDate: 'referrals_enrollment_date',
  homesActivationDate: 'homes_activation_date',
  lastHomesLeadDate: 'last_homes_lead_date',
  lastReferralLeadDate: 'last_referral_lead_date'
}

type SearchParamsSettings = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'sortBy' | 'searchTerm' | 'sortDir'
>

type MetricsTimeRangeValues =
  | 'current_month'
  | 'last_12_months'
  | 'last_3_months'
  | 'last_6_months'
  | 'last_month'

export type TAgentsSearchParams = Partial<{
  agentPartnership: string
  agentShape: AgentAttributesAndAssociations
  algoLeadId: string
  assignedAreaId: string
  assigneeId: string
  daysAgo: string
  eliteStatus: string[]
  enrollmentProgram: Potential<TProgramKey>
  enrollmentStatus: TEnrollmentStatus
  gender: 'male' | 'female'
  inContract: boolean
  latitude: number
  leadStages: string[]
  longitude: number
  metricsTimeRange?: MetricsTimeRangeValues | ''
  page: string
  perPage: number
  pitchOppProduct: 'referrals' | 'title_escrow'
  pitchOppStatus: string[]
  preferredEscrowOfficerUserId: string
  radius: number
  searchTerm: string
  sortDir: SortDirection
  teamId: string
  teamType: string
  tierStatus: string
  worksWith: 'buyers' | 'sellers'
}> &
  TProgramsCertifications &
  SearchParamsSettings

export const getSearchParams = (
  {
    agentShape = agentListShape,
    agentPartnership,
    assigneeId,
    algoLeadId,
    assignedAreaId,
    calendly,
    daysAgo,
    eliteStatus,
    gender,
    inContract,
    latitude,
    leadStages,
    longitude,
    moveSafe,
    pitchOppStatus,
    page,
    perPage,
    radius,
    searchTerm,
    sortBy,
    sortDir,
    teamId,
    teamType,
    warmTransfer,
    worksWith,
    pitchOppProduct,
    enrollmentProgram,
    enrollmentStatus,
    metricsTimeRange = '',
    tierStatus,
    preferredEscrowOfficerUserId
  }: TAgentsSearchParams,
  getMetrics?: boolean
) => {
  const getSort = () => {
    const dir = sortDir === 'desc' ? '-' : ''

    if (sortBy && sortParam[sortBy]?.includes('platform_metrics')) {
      const timeRange = metricsTimeRange || DEFAULT_PLATFORM_METRICS_TIME_RANGE

      return `${dir}${sortParam[sortBy]}.${timeRange}`
    }

    if (sortBy) {
      return `${dir}${sortParam[sortBy]}`
    }
    return `-${sortParam.total}`
  }

  const agentMetrics = getMetrics ? agentMetricsShape : []

  const settlementAgencyTeamId = teamType === 'SettlementAgencyTeam' ? teamId : null
  const transactionTeameamId = teamType !== 'SettlementAgencyTeam' ? teamId : null

  return removeAttributesWithNullValues({
    ...getHapiRequestParams({ agents: [...agentShape, ...agentMetrics]! }),
    'page[number]': page,
    'page[size]': perPage,
    'filter[settlement_agency_team.id]': settlementAgencyTeamId,
    'filter[transaction_team.id]': transactionTeameamId,
    'filter[_assigned_to_user_id]': assigneeId,
    'filter[_preferred_escrow_officer_user_id]': preferredEscrowOfficerUserId,
    'filter[in_contract]': inContract,
    'filter[_search]': searchTerm,
    'filter[_transaction_search[latitude]]': latitude,
    'filter[_transaction_search[longitude]]': longitude,
    'filter[_transaction_search[radius]]': radius,
    'filter[services_opportunities.status_id]': pitchOppStatus,
    'filter[services_opportunities.product_type]': pitchOppProduct,
    'filter[elite_status]': eliteStatus,
    'filter[_lead_stages]': leadStages,
    'filter[_days_ago]': daysAgo,
    'filter[gender]': gender,
    'filter[assigned_area.id]': assignedAreaId,
    'filter[referral_preferences.works_with_buyers]': worksWith === 'buyers',
    'filter[referral_preferences.works_with_sellers]': worksWith === 'sellers',
    'filter[_algo_lead_id]': algoLeadId,
    'filter[partner_program_id]': agentPartnership,
    'filter[_platform_tier_status][slug]': tierStatus,
    'filter[_platform_tier_status][time_range]': tierStatus && metricsTimeRange,
    ...getAgentProgramFilter(toCamelCase(enrollmentProgram), enrollmentStatus),
    ...getCalendlyFilter(calendly),
    ...getWarmTransferFilter(warmTransfer),
    ...getMoveSafeFilter(moveSafe),
    sort: getSort()
  })
}
