import { Lead } from 'models'
import { getLead } from 'models/Lead/utils'
import { OrderResponse } from 'schema'

export const getLeads: (order: OrderResponse) => Array<Lead> = order => {
  const priority = {
    cc_trade_in: 1,
    cc_cash_offer: 2,
    escrow: 3,
    mortgage: 4,
    seller: 5,
    buyer: 6
  }

  return order.leads
    ? order.leads
        .sort((a, b) => {
          return priority[a.userType] - priority[b.userType]
        })
        .map(lead => getLead(lead))
    : []
}
