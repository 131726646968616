// @ts-strict
import { css, styled } from '@foundation/themes/styles'

export const Content = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.$6};
    padding-top: 0;
  `}
`

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.space.$5} 0;
    // remove this when global style is available around the app
    h2 {
      margin: 0;
    }
  `}
`
