import { ReactNode } from 'react'
import { Button } from 'components/Button'
import { cx } from 'utils'
import styles from './Pagination.module.scss'

type TProps = {
  children: ReactNode
  className?: string
  disabled?: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const PaginationLink = ({ className, children, onClick, disabled = false }: TProps) => {
  const classNames = cx(styles.page, className)

  return (
    <Button type="button" className={classNames} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  )
}
