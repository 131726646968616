import { ReactNode } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import {
  Console,
  ConsoleAside,
  ConsoleDetails,
  ConsoleLoading,
  Sticky,
  TabNav,
  Tabs
} from 'components'
import { InvestorAside } from 'content/Investor/InvestorAside'
import {
  InvestorProvider,
  QueryStringParamsProvider,
  useCurrentUserContext,
  useInvestorContext
} from 'contexts'
import { Investor } from 'models'
import styles from './InvestorPage.module.scss'

const InvestorPageContent = ({
  investor,
  children
}: {
  children: ReactNode
  investor: Investor
}) => {
  const { isPplAdmin } = useCurrentUserContext()

  return (
    <Console>
      <ConsoleAside>
        <Sticky>
          <InvestorAside investor={investor} className={styles.aside} />
        </Sticky>
      </ConsoleAside>
      <ConsoleDetails className={styles.details}>
        <Tabs data-testid="investorTabs" className={styles.tabs} sticky>
          {isPplAdmin && <TabNav to={`/investors/${investor.id}/billing`}>Billing</TabNav>}
        </Tabs>
        {children}
      </ConsoleDetails>
    </Console>
  )
}

const InvestorPageContext = ({ children }) => {
  const { investor, isLoading, isError } = useInvestorContext()

  if (isLoading) {
    return <ConsoleLoading type="Investor" />
  }
  if (isError) {
    console.log('Error fetching investor')
  }
  if (!investor) {
    return null
  }
  return <InvestorPageContent investor={investor}>{children}</InvestorPageContent>
}

export const InvestorPage = () => {
  const { investorId } = useParams()
  return (
    <InvestorProvider investorId={investorId}>
      <QueryStringParamsProvider initialParams={{ completionStatus: ['ready'] }}>
        <InvestorPageContext>
          <Outlet />
        </InvestorPageContext>
      </QueryStringParamsProvider>
    </InvestorProvider>
  )
}
