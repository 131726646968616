import { ModelField } from '../ModelField'
import { TEscrowLeadFieldAttribute } from './escrowLeadFieldsSettings'

export class EscrowLeadField extends ModelField {
  attr!: TEscrowLeadFieldAttribute

  constructor(attributes: EscrowLeadField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
