import { FloatInput, FloatUnder100Input, InventoryInput } from 'components/Form'
import { InlineEditTextInput, InlineEditTextInputProps } from '../InlineEditTextInput'

export const InlineEditFloatInput = (props: InlineEditTextInputProps) => {
  return <InlineEditTextInput {...props} render={FloatInput} />
}

export const InlineEditFloatUnder100Input = (props: InlineEditTextInputProps) => {
  return <InlineEditTextInput {...props} render={FloatUnder100Input} />
}

export const InlineEditInventoryInput = (props: InlineEditTextInputProps) => {
  return <InlineEditTextInput {...props} render={InventoryInput} />
}
