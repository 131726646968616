// @ts-strict
import { lookupCashOfferProviderLeadStages } from 'lookups'
import { Agent, CashOfferLead, CashOfferProviderLead, getLead } from 'models'
import { LeadResponse } from 'schema'
import { bySlug, presence } from 'utils'
import { Lead, TLeadChildConstructor } from '../Lead'
import { sortCashOfferProviderLeads } from './utils/sortCashOfferProviderLeads'

type TCCCashOfferLeadConstructor = TLeadChildConstructor<
  Omit<CCCashOfferLead, 'getCashOfferProviderLeadsOptions'>
>

export class CCCashOfferLead extends Lead {
  active?: boolean
  activeCashOfferLead?: CashOfferLead
  activeCashOfferProviderLead?: CashOfferProviderLead
  agent?: Agent
  agentTeamsName?: string
  calculatedValue?: string
  cashOfferProviderLeads?: CashOfferProviderLead[]
  siblingLeads?: Lead[]
  expressClose?: boolean
  hasActiveCashOfferLead?: boolean
  hasIncomingResidence?: boolean
  modelName? = 'CCCashOfferLead'
  providableName? = 'activeCashOfferLead'
  providerName? = 'activeCashOfferProviderLead'
  referringAgent?: Agent
  revenueRecognitionDate?: string
  valuation?: number
  protectedOfferStatus?: string
  updatedAt?: string
  lenderSlug?: string

  constructor(ccCashOfferLeadAttributes: TCCCashOfferLeadConstructor) {
    super(ccCashOfferLeadAttributes)
    Object.assign(this, ccCashOfferLeadAttributes)
  }

  static create(ccCashOfferLeadResponse: Potential<LeadResponse>): Potential<CCCashOfferLead> {
    if (!ccCashOfferLeadResponse) {
      return
    }
    const leadAttributes = Lead.mapResponse(ccCashOfferLeadResponse)
    const unsortedCashOfferProviderLeads = (ccCashOfferLeadResponse?.cashOfferProviderLeads?.map(
      CashOfferProviderLead.create
    ) || []) as CashOfferProviderLead[]
    const cashOfferProviderLeads = sortCashOfferProviderLeads(unsortedCashOfferProviderLeads)

    const referringAgent = Agent.create(ccCashOfferLeadResponse.referringOwner)

    const activeCashOfferLead = cashOfferProviderLeads?.length
      ? cashOfferProviderLeads[cashOfferProviderLeads.length - 1].cashOfferLead
      : undefined

    const activeCashOfferProviderLead = cashOfferProviderLeads?.length
      ? cashOfferProviderLeads[cashOfferProviderLeads.length - 1]
      : undefined

    const agent = activeCashOfferLead?.incomingPropertyBuyerAgent
    const agentTeamsName = agent?.agentTeamName

    const valuation = activeCashOfferLead?.incomingPropertyValuation
    const revenueRecognitionDate = ccCashOfferLeadResponse.revenueRecognitionDate
    const calculatedValue = ccCashOfferLeadResponse.calculatedValue
    const hasActiveCashOfferLead = !!lookupCashOfferProviderLeadStages
      .filter(item => !!item.active)
      .find(bySlug(ccCashOfferLeadResponse.stage))

    const lenderSlug = leadAttributes.order?.lenderSlug

    const hasIncomingResidence = !!presence(cashOfferProviderLeads)
    const siblingLeads = (ccCashOfferLeadResponse.childLeads?.map(getLead) as Lead[]) || []

    return new CCCashOfferLead({
      ...leadAttributes,
      activeCashOfferLead,
      activeCashOfferProviderLead,
      agentTeamsName,
      calculatedValue,
      cashOfferProviderLeads,
      siblingLeads,
      hasActiveCashOfferLead,
      hasIncomingResidence,
      referringAgent,
      revenueRecognitionDate,
      valuation,
      agent,
      lenderSlug
    })
  }

  getCashOfferProviderLeadsOptions() {
    return this.cashOfferProviderLeads?.map(copl => copl.toOption()).reverse()
  }
}
