// @ts-strict

import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { PlatformMetricsTimeRanges } from 'schema/AgentPlatformMetricsSchema'
import { queryStringToObject } from 'utils'
import { createCtx } from '../utils'

const metricsTimeRangeMapping = {
  current_month: 'currentMonth',
  last_12_months: 'last12Months',
  last_3_months: 'last3Months',
  last_6_months: 'last6Months',
  last_month: 'lastMonth'
} as const

const useAgentMetrics = () => {
  const { search } = useLocation()
  const searchParams = queryStringToObject(search) as {
    metricsTimeRange?: keyof PlatformMetricsTimeRanges
  }
  const mappedRange: Potential<
    (typeof metricsTimeRangeMapping)[keyof typeof metricsTimeRangeMapping]
  > = searchParams?.metricsTimeRange && metricsTimeRangeMapping?.[searchParams.metricsTimeRange]

  return { agentMetricsTimeRange: mappedRange || 'currentMonth' }
}

export const [useAgentMetricsContext, AgentMetricsContextProvider] =
  createCtx<ReturnType<typeof useAgentMetrics>>()

type Props = {
  children: ReactNode
}

export const AgentMetricsProvider = ({ children }: Props) => {
  return (
    <AgentMetricsContextProvider value={useAgentMetrics()}>{children}</AgentMetricsContextProvider>
  )
}
