// @ts-strict
import { useParams } from 'react-router-dom'
import { PhotoUploadsCarousel } from 'content/PhotoUploads'
import { useParamsContext } from 'contexts'
import { usePhotoUploadsController } from 'controllers'

export const LeadPhotoUploadsCarousel = () => {
  const { leadId, photoUploadId } = useParams()
  const { params } = useParamsContext()
  const { photoUploadsQuery } = usePhotoUploadsController({
    ...params,
    leadId,
    page: 1,
    perPage: 100
  })

  return (
    <PhotoUploadsCarousel photoUploadsQuery={photoUploadsQuery} photoUploadId={photoUploadId!} />
  )
}
