// @ts-strict

import { useMutation, useQuery, useQueryClient } from 'hooks'
import { TMarketplaceProgramSlug } from 'lookups'
import { MarketplaceProgramAgentEnrollStatus } from 'models/MarketplaceProgramAgent/utils'
import {
  fetchAgent,
  patchAgentServiceEnrollment,
  postAgentUser,
  PostAgentUserPayload,
  putAgent,
  putAgentVisibility
} from 'services'

export const useAgentController = (agentId: string, enabled = false) => {
  const queryCache = useQueryClient()

  const defaultCallback = {
    onSuccess: () => {
      queryCache.invalidateQueries(['agents', agentId])
      queryCache.invalidateQueries(['leads'])
    }
  }

  const {
    data: agent,
    isLoading,
    isError
  } = useQuery(['agents', agentId], () => fetchAgent(agentId), { enabled })

  const updateAgentServiceEnrollment = useMutation(
    (payload: {
      enrollmentStatus: MarketplaceProgramAgentEnrollStatus
      notes?: string
      program: TMarketplaceProgramSlug
    }) =>
      patchAgentServiceEnrollment({
        id: agentId,
        payload
      }),
    defaultCallback
  )

  const updateAgent = useMutation((payload: any) => putAgent(agentId, payload), defaultCallback)

  const updateAgentVisibility = useMutation(
    (payload: any) => putAgentVisibility(agentId, payload),
    defaultCallback
  )

  const createAgentUser = useMutation(
    (payload: PostAgentUserPayload) => postAgentUser(agentId, payload),
    defaultCallback
  )

  return {
    agent,
    updateAgent,
    updateAgentVisibility,
    createAgentUser,
    updateAgentServiceEnrollment,
    isLoading,
    isError
  }
}
