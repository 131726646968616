import { ReactNode } from 'react'
import { Text } from '@foundation/components'
import { ActivityFeedInterface } from 'models/v2/ActivityFeed'
import { dataOrDash, formatDate } from 'utils'
import { ActivityCollapseRow } from '../../ActivityCollapseRow'
import { ActivityEventDetail } from './ActivityEventDetail'
import * as S from './styles'

type Props = {
  'data-testid'?: string
  date: string
  description: string
  formatter?: (data: any) => any
  title: ReactNode
} & Pick<ActivityFeedInterface, 'meta'>

export const ActivityEventRow = ({
  'data-testid': dataTestId,
  date,
  description,
  meta,
  title,
  formatter = data => data
}: Props) => {
  return (
    <ActivityCollapseRow
      data-testid={dataTestId}
      content={
        <>
          <S.ContentLeft>
            <S.NameWrapper>{title}</S.NameWrapper>
            <S.DescriptionWrapper>
              <Text as="span" size="sm" hasEllipsis title={description}>
                {dataOrDash(description)}
              </Text>
            </S.DescriptionWrapper>
          </S.ContentLeft>
          {formatDate(date, 'short')}
        </>
      }
    >
      <S.Collapse>
        <ActivityEventDetail meta={formatter(meta)} />
      </S.Collapse>
    </ActivityCollapseRow>
  )
}
