import { createContext, ReactNode, useContext } from 'react'
import { ListingsContextType } from 'contexts/types'
import { useLocation } from 'hooks'
import { queryStringToObject } from 'utils'
import { useListings } from './useListings'

const ListingsContext = createContext<ListingsContextType | null>(null)
export const useListingsContext = () => useContext(ListingsContext)

type Props = {
  agentId?: string
  children: ReactNode
  rootPath: string
}

export const ListingsProvider = ({ children, rootPath, agentId }: Props) => {
  const { pathname, search } = useLocation()

  const searchParams = { ...queryStringToObject(search), agentId }

  return (
    <ListingsContext.Provider value={useListings({ searchParams }, rootPath === pathname)}>
      {children}
    </ListingsContext.Provider>
  )
}
