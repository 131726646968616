import { useMutation, useQueryClient } from 'react-query'
import { useButtonForm } from 'components'
import { postLoanOfficer } from 'services/LoanOfficers/postLoanOfficer'
import { deserializeAsync, validateEmailFormat, validatePhoneFormat, validatePresence } from 'utils'
import { TLoanOfficerFormPayload, TLoanOfficerResponse } from '../utils'

type UseCreateLoanOfficerOptions = {
  onSuccess?: (response: TLoanOfficerResponse, params?: any) => void
}

export const useCreateLoanOfficer = (options?: UseCreateLoanOfficerOptions) => {
  const queryCache = useQueryClient()

  const createLoanOfficer = useMutation((data: {}) => postLoanOfficer(data), {
    onSuccess: async (response, params) => {
      queryCache.invalidateQueries('loan-officers')

      if (options?.onSuccess) {
        const deserializedResponse = await deserializeAsync<TLoanOfficerResponse>(response.data)
        options.onSuccess(deserializedResponse, params)
      }
    }
  })

  const buttonForm = useButtonForm<TLoanOfficerFormPayload, string>({
    initialPayload: {
      loanOfficerName: '',
      loanOfficerEmail: '',
      loanOfficerPhone: '',
      companyName: '',
      loanOfficerNmlsId: null,
      loanOfficerTransactionTeamId: null
    },
    mutation: createLoanOfficer,
    formValidations: {
      loanOfficerName: validatePresence,
      loanOfficerEmail: validateEmailFormat,
      loanOfficerPhone: validatePhoneFormat
    }
  })

  return { ...buttonForm, isLoading: createLoanOfficer.isLoading }
}
