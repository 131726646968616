// @ts-strict
import { ReactElement } from 'react'
import { Card, TabbedContent } from 'components'
import { LenderDetails, LenderDispositionHeader, LenderSummary } from 'content/Lender'
import { Lender } from 'models'
import { cx, toSnakeCase } from 'utils'
import styles from './LenderCard.module.scss'

type LenderCardProps = {
  lender: Lender
}

export const LenderCard = ({ lender }: LenderCardProps) => {
  const stage = toSnakeCase(lender.providerLead?.stage)

  const cardContent: Record<string, ReactElement> = {
    Details: <LenderDetails lender={lender} />
  }

  return (
    <Card
      data-testid="lenderCard"
      summary={
        <>
          <LenderDispositionHeader lender={lender} />
          <LenderSummary lender={lender} />
        </>
      }
      details={<TabbedContent content={cardContent} />}
      className={cx(styles.card, styles[stage])}
    />
  )
}
