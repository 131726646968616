import { conformToMask } from 'react-text-mask'
import { DEFAULT_STRING } from '../constants'

// prettier-ignore
export const phoneMask = [ '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ]

type PhoneNumber = number | string | null | undefined

export const formatPhone = (number: PhoneNumber, defaultValue: any = DEFAULT_STRING) => {
  if (!number) {
    return defaultValue
  }

  const phoneNumber = `${number}`.replace(/\D/g, '')
  const conformation = conformToMask(phoneNumber, phoneMask, { guide: false })
  return conformation.conformedValue
}
