// @ts-strict
import { LeadGroupableDefinitionResponse } from 'schema'
import { Model } from '../Model'

export type LeadGroupableDefinitionType = 'persona_definitions'

export class LeadGroupableDefinition extends Model {
  definition?: Array<{
    [key in 'action' | 'column' | 'values']: string | string[]
  }>

  constructor(leadGroupableDefinitionAttributes: LeadGroupableDefinitionResponse) {
    super()
    Object.assign(this, leadGroupableDefinitionAttributes)
  }

  static create(
    leadGroupableDefinitionResponse: Potential<LeadGroupableDefinitionResponse>
  ): Potential<LeadGroupableDefinitionResponse> {
    if (!leadGroupableDefinitionResponse) {
      return
    }

    return new LeadGroupableDefinition(leadGroupableDefinitionResponse)
  }
}
