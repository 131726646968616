import { CcCashOfferLeadField, useFailedReasons } from 'lookups'
import { byAttr } from 'utils'
import {
  ccCashOfferLeadFieldsSettings,
  TCcCashOfferLeadFieldAttribute
} from './ccCashOfferLeadFieldsSettings'

export const useCcCashOfferLeadFields = () => {
  const { ccCashOfferFailedReasons } = useFailedReasons()

  const optionLoaders = {
    ccCashOfferFailedReasons
  }

  const ccCashOfferLeadFields = ccCashOfferLeadFieldsSettings.map(setting => {
    let options = setting.options
    if (setting.getOptions) {
      options = optionLoaders[setting.getOptions]
    }
    return CcCashOfferLeadField.createFromSetting({ ...setting, options })
  })

  const getCcCashOfferLeadField = (attr: TCcCashOfferLeadFieldAttribute) => {
    return ccCashOfferLeadFields.find(byAttr('attr', attr))
  }

  const getCcCashOfferLeadFieldName = (attr: TCcCashOfferLeadFieldAttribute) => {
    return getCcCashOfferLeadField(attr)?.name
  }

  return {
    ccCashOfferLeadFields,
    getCcCashOfferLeadField,
    getCcCashOfferLeadFieldName
  }
}
