import { getLeads2Url } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export type PutPhoneNumberPayload = {
  id: string
  number: string
}

export const putPhoneNumber = (leadId: string, payload: PutPhoneNumberPayload): Promise<any> => {
  return putRequest(
    getLeads2Url({
      id: leadId,
      action: `phone-numbers/${payload.id}`,
      searchParams: { number: payload.number }
    }),
    snakeCaseKeys(payload)
  )
}
