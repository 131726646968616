// @ts-strict
import { Block, Flipup } from 'components'
import { Task } from 'models'
import { formatDateTime } from 'utils'
import styles from './TaskStatus.module.scss'
import { TaskStatusIcon } from '.'

type TProps = {
  task: Task
}

export const TaskStatus = ({ task }: TProps) => {
  const { completedAt, dismissedAt, completionNote } = task
  const completedBy = task.getCompletedByUser()
  const completedByUserName = completedBy && `by ${completedBy?.name}`
  const completedAtDate = completedAt && `- ${formatDateTime(completedAt)}`
  const dismissedAtDate = dismissedAt && `- ${formatDateTime(dismissedAt)}`
  const taskStatusDates = [completedAtDate, dismissedAtDate].filter(Boolean)
  const taskStatusTitle = [task.status.name, taskStatusDates, completedByUserName]
    .filter(Boolean)
    .join(' ')

  return (
    <Flipup title={<TaskStatusIcon task={task} />}>
      <div className={styles.details}>
        <Block title={taskStatusTitle} subtitle={completionNote} />
      </div>
    </Flipup>
  )
}
