// @ts-strict
import { PropertyValuation } from '@types'
import { Marker, Point } from 'classes'
import { Agent, HLSimpleSaleProviderLead, MasterProperty } from 'models'
import { HLSimpleSaleLeadResponse } from 'schema'
import { formatPrice } from 'utils'

type THLSimpleSaleLeadConstructor = Omit<HLSimpleSaleLead, 'toMarker' | 'toPoint'>

export class HLSimpleSaleLead {
  id!: string
  agentValuation?: string
  avmDate?: string
  createdAt?: string
  departingMasterProperty?: MasterProperty
  departingPropertyActualDom?: number
  departingPropertyActualSalePrice?: number
  departingPropertyAgentValuationReceivedDate?: string
  departingPropertyCloseOfPurchaseEscrowDate?: string
  departingPropertyCloseOfSaleEscrowDate?: string
  departingPropertyDaysOfOwnership?: number
  departingPropertyExpectedListingPrice?: number
  departingPropertyExternalListingDate?: string
  departingPropertyExternalListingStatus?: string
  departingPropertyExternalListingStatusDate?: string
  departingPropertyFlags?: string[]
  departingPropertyFundsWiredToClientAt?: string
  departingPropertyFundsWiredToHlAt?: string
  departingPropertyGuaranteedPrice?: number
  departingPropertyHomeownerAssociation?: boolean
  departingPropertyInspectedAt?: string
  departingPropertyInspectionScheduledAt?: string
  departingPropertyListedDate?: string
  departingPropertyListingPrice?: number
  departingPropertyLoanPayoff?: number
  departingPropertyMarketDom?: number
  departingPropertyMarketIndex?: string
  departingPropertyMarketInventoryCount?: string
  departingPropertyOfferAcceptedDate?: string
  departingPropertyOfferCount?: number
  departingPropertyOpenedPurchasedEscrowDate?: string
  departingPropertyOpenedSaleEscrowDate?: string
  departingPropertyPreliminaryTitleReviewed?: boolean
  departingPropertyPreliminaryValuation?: number
  departingPropertyPriceDelta?: number
  departingPropertyQualificationReason?: string
  departingPropertyQualified?: boolean
  departingPropertyQualifiedDate?: string
  departingPropertySellerAgentId?: string
  departingPropertySolarLoanBalance?: number
  departingPropertySolarPanels?: string
  departingPropertyUuid?: string
  departingPropertyValuation?: number
  dpActualDaysToListing?: number
  dpActualGainOnSale?: number
  dpActualPurchaseFundedDate?: string
  dpActualRehabExpensesPercentage?: number
  dpActualRehabExpensesPrice?: number
  dpBuyerAgentFee?: number
  dpCapitalDaysOutstanding?: number
  dpDaysSellerInPossession?: number
  dpDealValuationPercentage?: number
  dpEstimatedDaysOfOwnership?: number
  dpDealValuationPrice?: number
  dpDealValuationPriceRounding?: number
  dpEstimatedDaysOfWork?: number
  dpEstimatedDaysOfWorkTransaction?: number
  dpEstimatedListToPending?: number
  dpEstimatedListToPendingTransaction?: number
  dpEstimatedSaleEscrow?: number
  dpFundingAmount?: string
  dpFundingAmountBorrowingBase?: string
  dpFundingAmountHlEquity?: string
  dpHlAfterRepairValue?: string
  dpHlCommitteeAdjustment?: string
  dpInspectionAdjustment?: string
  dpPropertyType?: string
  dpSellerAgentFee?: number
  dpSellerCredit?: number
  dpSellerVacateDate?: string
  dpTargetPurchaseCoeDate?: string
  dpTargetRehabBudgetMethod?: string
  dpTargetRehabBudgetPercentage?: number
  dpTargetRehabBudgetPrice?: number
  dpTargetSalePrice?: number
  dpTargetSellCoeDate?: string
  dpTermSheetOffer?: number
  dpTotalEstimatedCostsOfBuying?: number
  dpTotalEstimatedCostsOfFinancing?: number
  dpTotalEstimatedCostsOfSelling?: number
  dpTotalEstimatedDaysOfOwnership?: number
  dpAgentAfterRepairValue?: string
  dpAgentAfterRepairValueDate?: string
  dpAnalystValuation?: string
  dpAnalystValuationDate?: string
  dpAnalystAfterRepairValue?: string
  dpAnalystAfterRepairValueDate?: string
  updatedAt?: string
  departingPropertySellerAgent?: Agent
  incomingPropertyBuyerAgent?: Agent
  propertyValuation?: PropertyValuation
  providerLead?: HLSimpleSaleProviderLead
  twoCheck?: boolean

  constructor(hlSimpleSaleLeadAttributes: THLSimpleSaleLeadConstructor) {
    Object.assign(this, hlSimpleSaleLeadAttributes)
  }

  toMarker(color?: string) {
    return this.providerLead?.lead?.latitude && this.providerLead.lead.longitude
      ? new Marker({
          name: `${formatPrice(this.providerLead.lead.price, 'short')} ${
            this.providerLead.lead.fullAddress
          }`,
          description: this.providerLead.lead.name,
          type: 'lead',
          point: this.toPoint(),
          color
        })
      : undefined
  }

  toPoint() {
    return this.providerLead?.lead?.latitude && this.providerLead.lead.longitude
      ? new Point({
          latitude: this.providerLead.lead.latitude,
          longitude: this.providerLead.lead.longitude
        })
      : undefined
  }

  static create(
    hlSimpleSaleLeadResponse: Potential<HLSimpleSaleLeadResponse>
  ): Potential<HLSimpleSaleLead> {
    if (!hlSimpleSaleLeadResponse) {
      return
    }

    const departingMasterProperty = MasterProperty.create(
      hlSimpleSaleLeadResponse.departingMasterProperty
    )
    const departingPropertySellerAgent = Agent.create(
      hlSimpleSaleLeadResponse.departingPropertySellerAgent
    )

    const incomingPropertyBuyerAgent = Agent.create(
      hlSimpleSaleLeadResponse.incomingPropertyBuyerAgent
    )

    const providerLead = HLSimpleSaleProviderLead.create(
      hlSimpleSaleLeadResponse?.simpleSaleProviderLead
    )

    return new HLSimpleSaleLead({
      ...hlSimpleSaleLeadResponse,
      departingMasterProperty,
      departingPropertySellerAgent,
      incomingPropertyBuyerAgent,
      providerLead
    })
  }
}
