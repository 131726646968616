import { Agent, Message } from 'models'

export class Contact {
  agent?: Agent
  messages?: Message[] = []
  lastMessagedAt?: string
  lastMessageBody?: string
  outbound?: boolean
  totalCount?: number
  unreadCount?: number
  lastReadAt?: string

  constructor(contactAttributes: Contact) {
    Object.assign(this, contactAttributes)
  }

  static create(agentResponse: Potential<Agent>): Potential<Contact> {
    if (!agentResponse) {
      return
    }
    const agent = agentResponse
    return new Contact({ agent })
  }
}
