// @ts-strict
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetchAgentPayPerLeadPaymentInformation, putAgentMonthlyCap } from 'services'

type UpdateAgentPayPerLeadMonthlyCapProps = {
  buyerMonthlyCap: number
  monthlyCap: number
}

export const useAgentPayPerLeadPaymentInformationController = (agentId: string) => {
  const queryCache = useQueryClient()

  const agentPayPerLeadPaymentInformationQuery = useQuery(
    ['agent-ppl-payment-information', agentId],
    () => fetchAgentPayPerLeadPaymentInformation(agentId),
    { refetchOnWindowFocus: false }
  )

  const updateAgentPayPerLeadMonthlyCap = useMutation(
    ({ monthlyCap, buyerMonthlyCap }: UpdateAgentPayPerLeadMonthlyCapProps) =>
      putAgentMonthlyCap(agentId, monthlyCap, buyerMonthlyCap),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('agent-ppl-payment-information')
      }
    }
  )

  return { agentPayPerLeadPaymentInformationQuery, updateAgentPayPerLeadMonthlyCap }
}
