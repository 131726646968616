// @ts-strict

import { ReactNode } from 'react'
import { createCtx } from '../utils'
import { useLeadTeam } from './useLeadTeam'

export const [useLeadTeamContext, LeadTeamContextProvider] =
  createCtx<ReturnType<typeof useLeadTeam>>()

type Props = {
  children: ReactNode
  leadId?: string
}

export const LeadTeamProvider = ({ children, leadId }: Props) => {
  return <LeadTeamContextProvider value={useLeadTeam(leadId)}>{children}</LeadTeamContextProvider>
}
