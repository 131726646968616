import { ReactNode } from 'react'
import styles from './Inspect.module.scss'

export const Inspect = ({ children }: { children: ReactNode }) => {
  return (
    <pre className={styles.inspect}>
      <code>{JSON.stringify(children, null, 2)}</code>
    </pre>
  )
}
