import { SearchParams } from '@types'
import { removeAttributesWithNullValues } from 'utils'
import { getSort } from '../../utils'

const sortParam = {
  Created: 'created_at',
  Name: 'name.keyword',
  Score: '_score',
  Updated: 'order.last_lead_stage_update',
  Stage: 'stage'
}

export type TMortgageLeadsSearchParams = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'searchTerm' | 'sortDir' | 'sortBy'
> & {
  assigneeId?: string
  source?: string[]
  stage?: string[]
  stateName?: string
}

const getFilters = ({
  searchTerm,
  assigneeId,
  stage,
  source,
  stateName
}: TMortgageLeadsSearchParams) => {
  const filters = {}

  if (searchTerm) {
    filters['filter[_search]'] = searchTerm
  }

  if (assigneeId) {
    filters['filter[lead_users.user_id]'] = assigneeId
  }

  if (stage) {
    filters['filter[stage]'] = stage
  }

  if (source) {
    filters['filter[order.source]'] = source
  }

  if (stateName) {
    filters['filter[state_name]'] = stateName
  }

  return filters
}

export const getMortgageLeadsSearchParams = ({
  page,
  perPage,
  sortBy,
  sortDir,
  ...filters
}: TMortgageLeadsSearchParams) => {
  return removeAttributesWithNullValues({
    'page[number]': page ? page : undefined,
    'page[size]': perPage ? perPage : undefined,
    'filter[user_type]': 'mortgage',
    sort: getSort(sortParam, sortDir, sortBy),
    ...getFilters(filters)
  })
}
