// @ts-strict

import { Button } from '@foundation/components'
import { DownloadButton, FormActions, Popup } from 'components'
import { useEnvelopes } from 'contexts/Envelopes/useEnvelopes'
import { CCTradeInLead, FileUpload } from 'models'
import styles from './CCTradeInLeadPublishReceipt.module.scss'

type Props = {
  file?: FileUpload
  lead: CCTradeInLead
  onCancel: () => void
  onSuccess?: () => void
}

export const CCTradeInLeadPublishReceipt = ({ onCancel, file, lead, onSuccess }: Props) => {
  const { addEnvelope } = useEnvelopes(lead, {}, false)
  const errorMessage =
    addEnvelope.isError &&
    'An error happened when publishing the Trade-In Receipt. Please, review all inputs and try again.'

  const tradeInReceiptExistsText = file && (
    <span data-testid="tradeInReceiptExistsText">
      A <DownloadButton url={file?.fileAttachment?.downloadUrl}>Trade-In Receipt</DownloadButton>{' '}
      already exists for this Lead.
    </span>
  )

  const importantMessage = (
    <span>
      IMPORTANT: {tradeInReceiptExistsText} Publishing a NEW Trade-In Receipt will incur additional
      costs.
    </span>
  )

  const onHandlePublish = () => {
    addEnvelope.mutate(
      {
        templateSlug: 'trade_in_receipt',
        autoSend: true
      },
      {
        onSuccess: () => {
          onSuccess?.()
        }
      }
    )
  }

  return (
    <Popup title="Publish Trade-In Receipt" onCancel={onCancel}>
      <div className={styles.popupContent}>
        <span className={styles.warningText}>{importantMessage}</span>
        <p className={styles.infoText}>The file may take up to 1 or 2 minutes to be generated.</p>
        <FormActions error={errorMessage}>
          <Button
            variant="ghost"
            color="neutralLight"
            onClick={onCancel}
            isDisabled={addEnvelope.isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={onHandlePublish}
            isLoading={addEnvelope.isLoading}
            isDisabled={addEnvelope.isError}
          >
            Publish
          </Button>
        </FormActions>
      </div>
    </Popup>
  )
}
