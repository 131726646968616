import { getLeadDispositionUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export type TPutDispositionAgentLead = {
  disposition: string
  note?: string
}

export const putDispositionAgentLead = (
  leadId: string,
  agentLeadId: string,
  payload: TPutDispositionAgentLead
) => {
  return putRequest(
    getLeadDispositionUrl({
      id: leadId,
      action: `agent-leads/${agentLeadId}`
    }),
    snakeCaseKeys(payload)
  )
}
