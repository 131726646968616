// @ts-strict
import { useState } from 'react'

export const usePopup = () => {
  const [showPopup, setShowPopup] = useState(false)

  const toggleShowPopup = () => {
    setShowPopup(!showPopup)
  }

  const handleClose = () => {
    setShowPopup(false)
  }

  return { handleClose, showPopup, toggleShowPopup }
}
