// @ts-strict

import { useMutation, useQueryClient } from 'hooks'
import { LeadGroupableDefinitionType } from 'models'
import {
  destroyLeadGroupableDefinition,
  postLeadGroupableDefinition,
  putLeadGroupableDefinition
} from 'services'

export const useLeadGroupableDefinitionController = (
  leadGroupableType: string,
  leadGroupableId: string,
  leadGroupableDefinitionType: LeadGroupableDefinitionType,
  leadGroupableDefinitionId: string
) => {
  const queryCache = useQueryClient()

  const defaultCallback = () => {
    queryCache.invalidateQueries([
      `lead-groupable-${leadGroupableDefinitionType}-${leadGroupableDefinitionId}`
    ])
    queryCache.invalidateQueries([`lead-groupable-${leadGroupableType}-${leadGroupableId}`])
  }

  const updateLeadGroupableDefinition = useMutation(
    (payload: any) =>
      putLeadGroupableDefinition(leadGroupableDefinitionType, leadGroupableDefinitionId, payload),
    {
      onSuccess: () => defaultCallback()
    }
  )

  const duplicateLeadGroupableDefinition = useMutation(
    () => postLeadGroupableDefinition(leadGroupableDefinitionType, leadGroupableDefinitionId),
    {
      onSuccess: () => defaultCallback()
    }
  )

  const deleteLeadGroupableDefinition = useMutation(
    () => destroyLeadGroupableDefinition(leadGroupableDefinitionType, leadGroupableDefinitionId),
    {
      onSuccess: () => defaultCallback()
    }
  )

  return {
    updateLeadGroupableDefinition,
    duplicateLeadGroupableDefinition,
    deleteLeadGroupableDefinition
  }
}
