function isObject(obj: Record<string, any>) {
  return obj !== null && typeof obj === 'object'
}

type ShowIf = (test: any, returnVal: any) => typeof returnVal

export const showIf: ShowIf = (test, returnVal) => {
  if (Array.isArray(test)) {
    if (test.length) {
      return returnVal
    }
  } else if (isObject(test)) {
    if (Object.entries(test).length) {
      return returnVal
    }
  } else if (test) {
    return returnVal
  }
  return null
}
