import { byAttr } from 'utils'
import { leadFieldsSettings, TLeadFieldAttribute } from './leadFieldsSettings'
import { LeadField } from './'

export const leadFields = leadFieldsSettings.map(setting => {
  return new LeadField(setting)
})

export const getLeadField = (attr: TLeadFieldAttribute) => {
  return leadFields.find(byAttr('attr', attr))
}

export const getLeadFieldName = (attr: TLeadFieldAttribute) => {
  return getLeadField(attr)?.name
}
