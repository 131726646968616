// @ts-strict

import { useEffect } from 'react'
import { Button, Text } from '@foundation/components'
import { ButtonForm, DateInput, FormField, Section, Select } from 'components'
import { TMortgageLeadStagesSlug, useMortgageLeadStages } from 'lookups'
import { MortgageLead } from 'models'
import * as S from './styles'
import { useMortgageLeadDispositionButtonForm } from './useMortgageLeadDispositionButtonForm'
import { useMortgageLeadStageOptions } from './useMortgageLeadStageOptions'
import { validatePreviousStageUpdate } from './utils'

type MortgageLeadDispositionButtonFormProps = {
  lead: MortgageLead
}

export const MortgageLeadDispositionButtonForm = ({
  lead
}: MortgageLeadDispositionButtonFormProps) => {
  const {
    formProps,
    onChangeAttribute,
    payload,
    removeAttribute,
    setFieldValidations,
    FieldsComponent
  } = useMortgageLeadDispositionButtonForm(lead)
  const stageOptions = useMortgageLeadStageOptions(lead)
  const { getMortgageLeadStage } = useMortgageLeadStages()
  const currentStage = getMortgageLeadStage(lead.stage as TMortgageLeadStagesSlug)

  useEffect(() => {
    setFieldValidations({ disposition: validatePreviousStageUpdate({ lead }) })

    return () => setFieldValidations({})
  }, [lead, setFieldValidations])

  const renderDispositionButton = () => {
    return (
      <Button onClick={formProps.toggleShowForm} size="xs">
        Disposition
      </Button>
    )
  }

  return (
    <>
      {renderDispositionButton()}
      <ButtonForm hideTriggerButton title="Disposition" {...formProps} showError>
        <Section>
          {currentStage && (
            <S.CurrentStage>
              <Text size="sm" as="span">
                Current Stage:
              </Text>{' '}
              {currentStage?.name && (
                <Text size="sm" weight="bold" as="span">
                  {currentStage.name}
                </Text>
              )}
            </S.CurrentStage>
          )}
          <FormField title="Update Stage" required>
            <Select
              value={payload.disposition}
              onChange={val => onChangeAttribute('disposition', val)}
              data-testid="stage-drop-down"
              options={stageOptions}
            />
          </FormField>
        </Section>

        {FieldsComponent && (
          <FieldsComponent
            lead={lead}
            payload={payload}
            onChangeAttribute={onChangeAttribute}
            removeAttribute={removeAttribute}
            setValidations={setFieldValidations}
          />
        )}

        <Section>
          <FormField title="Occurred On" required>
            <DateInput
              value={payload.occurredAt}
              data-testid="occurred-at-date-input"
              onChange={val => onChangeAttribute('occurredAt', val)}
            />
          </FormField>
        </Section>
      </ButtonForm>
    </>
  )
}
