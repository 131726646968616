import { FormField, Icon, Section, TextInput } from 'components'
import styles from '../EditEscrowOrderButtonForm.module.scss'
import { TFormProps } from '../types'

export const AwaitingAgent = ({ payload, onChangeAttribute }: TFormProps) => {
  const standardFormFieldProps = key => ({
    value: payload[key],
    onChange: val => onChangeAttribute(key, val)
  })

  return (
    <>
      <p className={styles.warning}>
        <Icon.Message />
        This lead is Pending Submission. You must provide a Referring Agent Id to continue.
      </p>

      <Section>
        <FormField title="Referring Agent ID">
          <TextInput {...standardFormFieldProps('referringOwnerId')} />
        </FormField>
      </Section>
    </>
  )
}
