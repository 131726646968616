// @ts-strict
import { SearchStatus } from 'components/SearchStatus/index'
import { SearchSummary } from 'components/SearchSummary/index'
import { useAgentAeAreasContext } from 'contexts/AgentAeAreas'
import { useParamsContext } from 'contexts/Params'
import { SearchParamsSettings } from 'services/AgentAeAreas/getSearchParams'

export const AgentAeAreasListStatus = () => {
  const {
    params: filtersAndSorts,
    resetParams
  }: { params: SearchParamsSettings; resetParams: () => void } = useParamsContext()
  const { agentAeAreasQuery } = useAgentAeAreasContext()
  const { agentAeAreasCount } = agentAeAreasQuery

  const canClear = Object.keys(filtersAndSorts).length

  return (
    <SearchSummary
      status={
        <SearchStatus params={filtersAndSorts} count={agentAeAreasCount} type="Agent Ae Area" />
      }
      onClear={canClear ? resetParams : undefined}
    />
  )
}
