// @ts-strict

import { QueryFunction, useQuery } from 'hooks'

export const useAutoComplete = (
  fetcher: QueryFunction,
  search?: string,
  isEnabled: boolean = true,
  hash?: string
) => {
  const { data: results, isLoading } = useQuery(['autoComplete', search, hash], fetcher, {
    enabled: Boolean(search) && isEnabled,
    cacheTime: 0
  })
  return { isLoading, results: results as any[] }
}
