import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useFlexUi = () => {
  const [flexUiModeEnabled, setFlexUiModeEnabled] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const isFlexUi = searchParams.has('flexUi')

  useEffect(() => {
    if (isFlexUi && !flexUiModeEnabled) {
      setFlexUiModeEnabled(true)
    }
  }, [isFlexUi, flexUiModeEnabled])

  useEffect(() => {
    if (!isFlexUi && flexUiModeEnabled) {
      const paramsString = searchParams.toString()
      const newParams = new URLSearchParams(paramsString)

      newParams.append('flexUi', '')

      setSearchParams(newParams)
    }
  }, [flexUiModeEnabled, isFlexUi, searchParams, setSearchParams])

  return { isFlexUi, flexUiModeEnabled }
}
