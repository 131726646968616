import { forwardRef, HTMLAttributes, ReactNode } from 'react'
import styles from './HoverBackground.module.scss'

type Props = { children: ReactNode } & HTMLAttributes<HTMLSpanElement>

export const HoverBackground = forwardRef<HTMLSpanElement, Props>(({ children, onClick }, ref) => (
  <span role="button" ref={ref} onClick={onClick} className={styles.bghover}>
    {children}
  </span>
))
