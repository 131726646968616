// @ts-strict
import { Option } from 'classes'
import {
  getCashOfferProviderLeadStage,
  IOptionable,
  TCashOfferProviderLeadStagesSlug
} from 'lookups'
import { CashOfferLead, CCCashOfferLead, ProviderLeadCapitalPartner } from 'models'
import { CashOfferLeadResponse, ProviderLeadResponse } from 'schema'
import { d, formatDateTime } from 'utils'

type TCashOfferProviderLeadConstructor = Omit<CashOfferProviderLead, 'toOption'>
export class CashOfferProviderLead implements IOptionable {
  id!: string
  cashOfferLead?: CashOfferLead
  createdAt?: string
  followUpDate?: string
  isActive?: boolean
  lead?: CCCashOfferLead
  modelName?: 'CashOfferProviderLead'
  providerLeadCapitalPartner?: ProviderLeadCapitalPartner
  reasonForFail?: string
  representedProduct?: string
  stage?: string
  updatedAt?: string

  constructor(cashOfferProviderAttributes: TCashOfferProviderLeadConstructor) {
    Object.assign(this, cashOfferProviderAttributes)
  }

  toOption() {
    const locationMsg =
      this.cashOfferLead?.incomingMasterProperty?.primaryLine || 'Unknown Location'
    const stageMsg = d(this.stage) || formatDateTime(this.createdAt)
    return new Option({
      name: `${locationMsg} (${stageMsg})`,
      value: this.cashOfferLead?.id
    })
  }

  static create(
    cashOfferProviderLeadResponse: Potential<ProviderLeadResponse>
  ): Potential<CashOfferProviderLead> {
    if (!cashOfferProviderLeadResponse) {
      return
    }

    const cashOfferLead = CashOfferLead.create(
      cashOfferProviderLeadResponse?.providable as CashOfferLeadResponse
    )
    // if (cashOfferLead) {
    //   cashOfferLead['reasonForFail'] = cashOfferProviderLeadResponse.reasonForFail
    // } // Caching on the child

    const isActive = getCashOfferProviderLeadStage(
      cashOfferProviderLeadResponse.stage as TCashOfferProviderLeadStagesSlug
    )?.active
    const lead = CCCashOfferLead.create(cashOfferProviderLeadResponse?.lead)
    const providerLeadCapitalPartner = ProviderLeadCapitalPartner.create(
      cashOfferProviderLeadResponse.providerLeadCapitalPartner
    )

    const updatedAt = cashOfferLead?.updatedAt

    return new CashOfferProviderLead({
      ...cashOfferProviderLeadResponse,
      cashOfferLead,
      isActive,
      lead,
      providerLeadCapitalPartner,
      updatedAt
    })
  }
}
