import { ReactNode } from 'react'
import { FloatingPortal } from '@floating-ui/react'
import { DRAWER_ELEMENT_ID } from '../floatingIds'
import { DrawerContextProvider } from './Drawer'
import * as S from './styles'
import { useDrawer } from './useDrawer'

type RoowDrawerProps = {
  /**
   * The content to display inside the Drawer
   */
  children: ReactNode
}

export const RootDrawer = ({ children }: RoowDrawerProps) => {
  const drawerHook = useDrawer()
  const content = <S.Wrapper id={DRAWER_ELEMENT_ID}>{children}</S.Wrapper>

  return (
    <FloatingPortal id="drawer-portal">
      <DrawerContextProvider value={drawerHook}>
        {drawerHook.isOpen ? (
          <S.Overlay lockScroll data-testid="overlay">
            {content}
          </S.Overlay>
        ) : (
          content
        )}
      </DrawerContextProvider>
    </FloatingPortal>
  )
}

RootDrawer.displayName = 'RootDrawer'
