import pluralizeModule from 'pluralize'
import { formatNumber } from '../formatNumber'

type CountArg = number | string | undefined

export const pluralizeWord = (stringText: string, count?: CountArg) => {
  return pluralizeModule(stringText, count)
}

export const pluralize = (count: CountArg, stringText: string) => {
  if (count === undefined) {
    return pluralizeWord(stringText)
  }
  if (String(Number(count)) !== String(count)) {
    console.warn('The count parameter must be a number:', count)
    return String(count)
  }
  return `${formatNumber(count)} ${pluralizeModule(stringText, count)}`
}
