import { UseMutationResult } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { OrderType } from 'contexts/Orders'
import { useMutation, useQuery, useQueryClient } from 'hooks'
import { OrderSchema } from 'schema'
import {
  fetchAgentMatchingOrders,
  fetchCashCloseOrders,
  fetchOrder,
  postSalesOrder,
  TOrderPostPayload
} from 'services'
import { TOrdersSearchArgs } from 'services'
import { asArray, getPageCount } from 'utils'

const defaultSearchSettings = {
  sortBy: 'Updated',
  sortDir: 'desc',
  perPage: 25
}

const orderLeadIDShape: OrderSchema = {
  order: [{ leads: ['userType'] }]
}

export const useOrdersController = (
  searchParams: TOrdersSearchArgs,
  scope?: OrderType | OrderType[],
  enabled: boolean = true
) => {
  const queryCache = useQueryClient()
  const clearCache = () => queryCache.invalidateQueries('orders')
  const navigate = useNavigate()

  const searchSettings = { ...defaultSearchSettings, ...searchParams }

  const fetchOrders = () => {
    if (asArray(scope).includes('cash_close')) {
      return fetchCashCloseOrders({ ...searchSettings, scope })
    }
    return fetchAgentMatchingOrders({ ...searchSettings, scope })
  }

  const { data, isLoading } = useQuery(['orders', scope, searchSettings], fetchOrders, {
    enabled,
    refetchOnWindowFocus: false
  })

  const orders = data?.orders
  const totalCount = data?.ordersCount
  const pageCount = getPageCount(totalCount, searchSettings.perPage)

  const defaultCallback = { onSuccess: clearCache }

  const createSalesOrder: UseMutationResult<string, unknown, TOrderPostPayload, unknown> =
    useMutation(data => postSalesOrder(data), defaultCallback)

  const handleOrderCreated = async (id: string) => {
    const order = await fetchOrder(id, orderLeadIDShape)

    const pathname = order?.leads?.[0]?.getPathname() || ''

    navigate(pathname)
  }

  return {
    orders,
    totalCount,
    pageCount,
    createSalesOrder,
    isLoading,
    handleOrderCreated
  }
}
