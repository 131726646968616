// @ts-strict
import { useEffect } from 'react'
import { ActionBar, Contents, PaginationParams } from 'components'
import { FilesTable } from 'content/Files'
import { LeadFileUploadsButtonForm } from 'content/Lead'
import { ListProvider, ParamsProvider, useLeadContext, useParamsContext } from 'contexts'
import { useFileUploadsController } from 'controllers'
import { useDocumentTitle } from 'hooks'
import { fileUploadsCashOfferListColumns, fileUploadsListColumns } from 'lookups'
import { isCCCashOfferLead } from 'models'
import { LeadDocumentsTabs } from '../LeadDocumentsTabs'

const LeadFileUploadsContent = () => {
  const { lead } = useLeadContext()
  const { params } = useParamsContext()
  const { fileUploads, filePagesCount } = useFileUploadsController({
    ...params,
    leadId: lead?.id,
    fileType: 'document'
  })
  const actions = (
    <ActionBar>
      <LeadFileUploadsButtonForm lead={lead} />
    </ActionBar>
  )

  return (
    <>
      <LeadDocumentsTabs />
      <Contents title="Files" actions={actions}>
        <FilesTable fileUploads={fileUploads} />
        <PaginationParams totalPages={filePagesCount} />
      </Contents>
    </>
  )
}
export const LeadFileUploadsTab = () => {
  const { setTitle } = useDocumentTitle()
  const { lead } = useLeadContext()

  useEffect(() => setTitle(`${lead?.name} - Documents - Files`), [lead, setTitle])

  return (
    <ParamsProvider>
      <ListProvider
        columns={isCCCashOfferLead(lead) ? fileUploadsCashOfferListColumns : fileUploadsListColumns}
      >
        <LeadFileUploadsContent />
      </ListProvider>
    </ParamsProvider>
  )
}
