// @ts-strict
import { Block } from 'components'
import { isCCCashOfferLead, isCCTradeInLead, Lead } from 'models'
import { DEFAULT_STRING, formatDate } from 'utils'

type TProps = {
  lead: Lead
}

export const OrdersTableRowLeadCoe = ({ lead }: TProps) => {
  if (isCCTradeInLead(lead)) {
    const tradeInLead = lead.tradeInProviderLead?.tradeInLead
    return (
      <Block
        title={formatDate(tradeInLead?.departingPropertyCloseOfPurchaseEscrowAt, 'short')}
        subtitle={formatDate(tradeInLead?.departingPropertyCloseOfSaleEscrowAt, 'short')}
      />
    )
  }
  if (isCCCashOfferLead(lead)) {
    const cashOfferLead = lead.activeCashOfferLead

    return (
      <Block
        title={formatDate(cashOfferLead?.incomingPropertyCloseOfPurchaseEscrowAt, 'short')}
        subtitle={formatDate(cashOfferLead?.incomingPropertyCloseOfSellEscrowAt, 'short')}
      />
    )
  }

  return <>{DEFAULT_STRING}</>
}
