/* eslint-disable import/order */
/* eslint-disable typescript-sort-keys/interface */

import { cx } from 'utils'

import styles from './StageBadge.module.css'

type StageBadgeProps = {
  stage?: string
  size?: 'sm' | 'md'
}

export const StageBadge = ({ size = 'md', stage }: StageBadgeProps) => {
  return <p className={cx(styles.stageBadge, styles[size])}>{stage}</p>
}
