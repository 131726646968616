import { EMPLOYEES_CACHE_KEY } from 'controllers/cache'
import { useMutation, useQueryClient } from 'hooks'
import { alertError, postUserIntegrations, TUserIntegrationPayload } from 'services'

export const useUserIntegrationsController = (employeeId: string) => {
  const queryCache = useQueryClient()

  const defaultCallback = {
    onSuccess: () => queryCache.invalidateQueries([EMPLOYEES_CACHE_KEY, employeeId]),
    onError: () => alertError()
  }

  const createUserIntegration = useMutation(
    (payload: TUserIntegrationPayload) => postUserIntegrations(employeeId, payload),
    defaultCallback
  )

  return {
    createUserIntegration
  }
}
