import { removeAttributesWithNullValues } from '../removeAttributesWithNullValues'

type QueryArg = Record<string, any>

export function objectToQueryString(
  query?: QueryArg,
  delimiter: string = '&',
  valueSeparator: string = '=',
  prefix: string = '?'
) {
  if (!query) {
    return ''
  }

  const queryComponents = Object.keys(query)
    .sort()
    .map(key => {
      if (query[key] instanceof Array) {
        if (query[key].length) {
          return `${key}${valueSeparator}${encodeURIComponent(`[${query[key].join(',')}]`)}`
        }
        return null
      }
      if (query[key] != null) {
        return `${key}${valueSeparator}${encodeURIComponent(query[key])}`
      }
      return null
    })
    .filter(value => {
      return value && value.length
    })
    .join(delimiter)

  if (queryComponents.length) {
    return `${prefix}${queryComponents}`
  }
  return ''
}

export function objectToParam(query: QueryArg) {
  return objectToQueryString(query, '&', '=', '')
}

export const getSearchParam = (settings: QueryArg) => {
  const { page, perPage, searchTerm, sortBy, sortDir, ...filters } = settings
  const queryStringSettings = {
    page: page > 1 ? page : undefined,
    searchTerm,
    sortBy,
    sortDir: sortBy ? sortDir : undefined,
    ...filters
  }
  return objectToQueryString(removeAttributesWithNullValues(queryStringSettings))
}
