import { useState } from 'react'
import {
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  UseFloatingProps,
  useHover,
  useInteractions
} from '@floating-ui/react'
import theme, { Theme } from '@foundation/themes'
import { DROPDOWN_ELEMENT_ID, OVERLAY_ELEMENT_ID } from '..'

export type UsePopoverProps = {
  /**
   * The number of milliseconds to wait before showing the popover
   */
  enterDelay?: keyof Theme['motions']['duration']
  /**
   * To open up the popover when hovering the trigger element
   */
  isHoverEnabled?: boolean
  /**
   * The controlled open state of the popover. Must be used in conjunction with onOpenChange.
   */
  isOpen?: boolean
  /**
   * Callback that is called when closing the popover
   */
  onClose?: () => void
  /**
   * Event handler called when the open state of the popover changes.
   */
  onOpenChange?: (isOpen: boolean) => void
  /**
   * To align the popover in different positions
   */
  placement?: UseFloatingProps<Element>['placement']
}

export const usePopover = ({
  placement,
  isHoverEnabled = false,
  onClose,
  enterDelay = 'short',
  onOpenChange,
  isOpen: isOpenProp
}: UsePopoverProps = {}) => {
  const [isOpenState, setIsOpenState] = useState(false)
  const isControlledState = isOpenProp !== undefined
  const isOpen = isControlledState ? isOpenProp : isOpenState

  const { x, y, reference, floating, strategy, context } = useFloating({
    open: isOpen,
    onOpenChange: open => {
      onOpenChange?.(open)

      if (!isControlledState) {
        setIsOpenState(open)
      }

      if (!open) {
        onClose?.()
      }
    },

    middleware: [
      // distance between the trigger element and the popover. Access the docs for more info
      offset(5),
      // changes the placement of the floating element to the opposite one by default in order to keep it in view. Access the docs for more info
      flip(),
      // moves the floating element along the specified axes in order to keep it in view. Access the docs for more info
      shift({ padding: 8 })
    ],
    placement,
    whileElementsMounted: autoUpdate
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      enabled: isHoverEnabled,
      handleClose: safePolygon(),
      delay: {
        open: theme.motions.duration[enterDelay],
        close: 0
      }
    }),
    useClick(context),
    useDismiss(context, {
      outsidePress: (event: any) => {
        // do not close the popover when opening the following elements with those ids
        return (
          !event.target?.closest(`#${OVERLAY_ELEMENT_ID}`) &&
          !event.target?.closest(`#${DROPDOWN_ELEMENT_ID}`)
        )
      },
      // whether to dismiss the floating element upon scrolling an overflow ancestor
      ancestorScroll: true
    })
  ])

  const popoverTriggerProps = { ...getReferenceProps({ ref: reference }) }

  const popoverProps = {
    ref: floating,
    context,
    isOpen,
    style: {
      position: strategy,
      top: y ?? 0,
      left: x ?? 0
    },
    ...getFloatingProps()
  }

  return {
    popoverTriggerProps,
    popoverProps
  }
}
