import { useState } from 'react'
import { FilterBar, ListSelect } from 'components'
import { LeadsMap, LeadsTable } from 'content/Leads'
import { Lead } from 'models'

type LeadsViewProps = {
  isLoading: boolean
  leads: Lead[]
  pageCount?: number
}

export const LeadsView = ({ leads, isLoading }: LeadsViewProps) => {
  const [format, setFormat] = useState<'Table' | 'Map'>('Table')

  const formats = {
    Table: <LeadsTable leads={leads} isLoading={isLoading} />,
    Map: <LeadsMap leads={leads} isLoading={isLoading} />
  }

  return (
    <>
      {leads && leads.length ? (
        <FilterBar>
          <ListSelect
            options={[{ name: 'Table' }, { name: 'Map' }]}
            value={format}
            onChange={setFormat}
          />
        </FilterBar>
      ) : null}
      {formats[format]}
    </>
  )
}
