import { removeAttributesWithNullValues } from 'utils'
import { getAgentProgramsFilter, TProgramsCertifications } from '../getFilters'

export type TAgentsAutoCompleteParams = Partial<{
  include?: string
  pageSize?: number
  searchTerm: string
}> &
  TProgramsCertifications

export const getSearchParams = ({
  cashOffer,
  hlss,
  searchTerm,
  include,
  pageSize
}: TAgentsAutoCompleteParams) => {
  return removeAttributesWithNullValues({
    'filter[_search]': searchTerm,
    'page[size]': pageSize || 15,
    ...getAgentProgramsFilter({
      cashOffer,
      hlss
    }),
    include
  })
}
