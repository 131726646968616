// @ts-strict

import { useEffect, useRef, useState } from 'react'
import {
  DisplayReadOnlyValue,
  FormField,
  InlineEditMoneyInput,
  InlineEditSelect,
  Section,
  Sections,
  Tag
} from 'components'
import { agentMatchingCallReviewCreditScoreOptions, yesOrNoOptions } from 'lookups'
import { isSellerLead, MortgageLead, SellerLead } from 'models'
import { dataOrDash, formatPrice, stringifyValue } from 'utils'

type Props = {
  lead: SellerLead | MortgageLead
  requiredFields?: Record<string, boolean>
  updateCallReview: (key: string, value: string) => void
  updateField: (value: Record<string, string | number | Record<string, string>>) => void
}

const eligibilityBadge = (lead: SellerLead | MortgageLead, equityUnlockMax?: number | null) => {
  const errors = []
  const bbysEligibleArea = isSellerLead(lead)
    ? lead.bbysEligibleArea
    : lead.callReview?.othersideStateBbysEligible

  if (!bbysEligibleArea) {
    errors.push('Not in eligible area')
  }
  if (lead.callReview?.primaryResidence !== undefined && !lead.callReview?.primaryResidence) {
    errors.push('Only primary residences are eligible')
  }
  if (
    lead.callReview?.creditScore !== undefined &&
    !['over_740', '680_739', '620_679', 'dont_know'].includes(lead.callReview?.creditScore)
  ) {
    errors.push('Credit Score should be greater than 620')
  }
  if (lead.callReview?.ageRestrictedCommunity) {
    errors.push("Can't be Age-Restricted Community")
  }
  if (lead.callReview?.fhaVaLoan) {
    errors.push("Can't have IR FHA/VA Loan")
  }
  if (equityUnlockMax && equityUnlockMax < 0) {
    errors.push('Equity Unlock - Max should be positive')
  }

  if (errors.length === 0) {
    return
  }

  const errorList = (
    <ul>
      {errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  )

  const tag = (
    <Tag as="danger" size="xsmall" tooltipText={errorList}>
      Not Eligible Per Criteria
    </Tag>
  )

  return tag
}

export const LegacyAgentMatchingLeadBBYSScreenSection = ({
  lead,
  updateCallReview,
  updateField,
  requiredFields = {}
}: Props) => {
  const [minEquityUnlock, setMinEquityUnlock] = useState<number | null>()
  const [maxEquityUnlock, setMaxEquityUnlock] = useState<number | null>()
  const isMounted = useRef(false)
  const remainingMortgageBalance = Number(lead.callReview?.remainingMortgageBalance)
  const homeValue = Number(isSellerLead(lead) ? lead.price : lead.callReview?.homeValue)
  const drAddress = isSellerLead(lead)
    ? lead.fullAddress
    : lead.callReview?.othersideInfo?.fullAddress

  const homeValueUpdate = (value: string) => {
    if (isSellerLead(lead)) {
      updateField({ price: value })
    } else {
      updateCallReview('homeValue', value)
    }
  }

  useEffect(() => {
    if (isMounted.current) {
      updateCallReview(
        'minEquityUnlock',
        minEquityUnlock ? Math.round(minEquityUnlock).toString() : ''
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minEquityUnlock])

  useEffect(() => {
    if (isMounted.current) {
      updateCallReview(
        'maxEquityUnlock',
        maxEquityUnlock ? Math.round(maxEquityUnlock).toString() : ''
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxEquityUnlock])

  useEffect(() => {
    setMinEquityUnlock(
      remainingMortgageBalance !== undefined ? homeValue * 0.7 - remainingMortgageBalance : null
    )
    setMaxEquityUnlock(
      remainingMortgageBalance !== undefined ? homeValue * 0.8 - remainingMortgageBalance : null
    )
  }, [remainingMortgageBalance, homeValue])

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    }
  })

  return (
    <Sections
      title="Buy Before You Sell Screen"
      titleStyle={{ flex: 'none', marginRight: '10px' }}
      actions={eligibilityBadge(lead, maxEquityUnlock)}
    >
      <Section>
        <FormField title="DR Address" inline>
          <DisplayReadOnlyValue value={dataOrDash(drAddress)} />
        </FormField>
        <FormField title="DR Home Value" inline>
          <InlineEditMoneyInput onBlur={homeValueUpdate} value={dataOrDash(homeValue)} />
        </FormField>
        <FormField title="Remaining Mortgage Balance?" inline>
          <InlineEditMoneyInput
            onBlur={(value: string) => {
              updateCallReview('remainingMortgageBalance', value)
            }}
            value={dataOrDash(lead.callReview?.remainingMortgageBalance)}
          />
        </FormField>
        <FormField title="Equity Unlock - Min" inline>
          <DisplayReadOnlyValue value={dataOrDash(minEquityUnlock)} format={formatPrice} />
        </FormField>
        <FormField title="Equity Unlock - Max" inline>
          <DisplayReadOnlyValue value={dataOrDash(maxEquityUnlock)} format={formatPrice} />
        </FormField>
      </Section>
      <Section>
        <FormField title="Primary Residence" required={requiredFields.primaryResidence} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('primaryResidence', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.primaryResidence)}
          />
        </FormField>
        <FormField title="Credit Score > 620" required={requiredFields.creditScore} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('creditScore', value)}
            options={agentMatchingCallReviewCreditScoreOptions}
            value={stringifyValue(lead.callReview?.creditScore)}
          />
        </FormField>
        <FormField
          title="DR Age-Restricted Community"
          required={requiredFields.ageRestrictedCommunity}
          inline
        >
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('ageRestrictedCommunity', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.ageRestrictedCommunity)}
          />
        </FormField>
        <FormField title="IR FHA/VA Loan" required={requiredFields.fhaVaLoan} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('fhaVaLoan', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.fhaVaLoan)}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
