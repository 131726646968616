import { Text } from '@foundation/components'
import { ButtonForm, useButtonForm } from 'components'
import { ButtonV2 } from 'components'
import { useAgentTeamController } from 'controllers'
import type { AgentTeam } from 'models'
import styles from './ToggleLeadRoutingButtonForm.module.scss'

type Props = {
  agent: any
  team: AgentTeam
}

const leadRoutingParams = {
  enabled: {
    submitButtonText: 'Disable',
    title: 'Disable Lead Routing',
    subtext: 'This will disable and clear the existing lead routing settings for this Agent Team'
  },
  disabled: {
    submitButtonText: 'Enable',
    title: 'Enable Lead Routing',
    subtext: 'This will enable Lead Routing for this Agent Team and reactivate the Fallback Agent'
  }
}

export const ToggleLeadRoutingButtonForm = ({ agent, team }: Props) => {
  const { toggleLeadRouting } = useAgentTeamController(agent.id, agent.agentTeamId)
  const leadRoutingState = team.leadRoutingEnabled ? 'enabled' : 'disabled'
  const { submitButtonText, title, subtext } = leadRoutingParams[leadRoutingState]

  const { formProps } = useButtonForm({
    mutation: toggleLeadRouting,
    submitButtonText,
    initialPayload: {
      agentTeamId: team.id
    }
  })

  return (
    <ButtonForm
      title={title}
      buttonComponent={buttonProps => <ButtonV2 className={styles.button} {...buttonProps} />}
      {...formProps}
    >
      <Text>{subtext}</Text>
    </ButtonForm>
  )
}
