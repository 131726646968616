import { useQuery, UseQueryOptions } from 'react-query'
import { Lead } from 'models'
import { LeadSchema } from 'schema'
import { fetchLeadV2 } from 'services'
import { communicationKeys } from '../../../commons/hooks'

type QueryOptions = UseQueryOptions<
  Lead,
  unknown,
  Lead,
  ReturnType<typeof communicationKeys.thisClientLead>
>

const FETCH_THIS_CLIENT_LEAD_SHAPE: LeadSchema = {
  lead: [{ order: ['id'] }]
}

export const useFetchThisClientLead = (id: string, options: QueryOptions = {}) => {
  return useQuery(
    communicationKeys.thisClientLead(id),
    () => fetchLeadV2(id, FETCH_THIS_CLIENT_LEAD_SHAPE),
    {
      ...options,
      refetchOnWindowFocus: false,
      staleTime: 300000 // 5min
    }
  )
}
