import { toOptions } from 'lookups/utils'
import { bySlug } from 'utils'
import { buildLeadStages } from '../lookupLeadStages'
import { MortgageLeadStage } from './MortgageLeadStage'
import { mortgageLeadStagesSettings, TMortgageLeadStagesSlug } from './mortgageLeadStagesSettings'

export const useMortgageLeadStages = () => {
  const lookupMortgageLeadStages = buildLeadStages(mortgageLeadStagesSettings, MortgageLeadStage)

  const getMortgageLeadStage = (slug: TMortgageLeadStagesSlug) => {
    return lookupMortgageLeadStages.find(bySlug(slug))
  }

  const mortgageLeadStagesOptions = toOptions(lookupMortgageLeadStages)

  return {
    getMortgageLeadStage,
    mortgageLeadStagesOptions
  }
}
