import { useEffect } from 'react'
import { Inspect } from 'components/Inspect'
import { useLeadContext } from 'contexts/Lead'
import { useDocumentTitle } from 'hooks'
import styles from './LeadPage.module.scss'

export const LeadInspectTab = () => {
  const { lead } = useLeadContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${lead.name} - Lead Inspead`), [lead, setTitle])
  return (
    <div className={styles.inspect}>
      <Inspect>{lead}</Inspect>
    </div>
  )
}
