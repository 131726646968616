import { Envelope } from 'models'
import { getEnvelopesUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { EnvelopesSearchParams, getSearchParams } from './getSearchParams'

export const fetchEnvelopes = async (leadId: string, settings: EnvelopesSearchParams) => {
  const url = getEnvelopesUrl({
    id: leadId,
    action: 'envelopes',
    searchParams: getSearchParams(settings)
  })
  const response = await getRequest(url)
  const envelopes = await deserializeAsync(response.data, {
    transform: Envelope.create
  })
  return {
    envelopes,
    count: response.data.meta.total_count as number,
    pages: response.data.meta.total_pages as number
  }
}
