import { getLeadDispositionUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export type TPutDispositionProviderLead = {
  delayIntroEmail?: boolean
  disposition: string
  includeAgentProfile?: boolean
  includeAgentWebsite?: boolean
  note?: string
  sendIntroFirst?: boolean
  skipAgentEmail?: boolean
}

export const putDispositionProviderLead = (
  leadId: string,
  providerLeadId: string,
  payload: TPutDispositionProviderLead
) => {
  return putRequest(
    getLeadDispositionUrl({
      id: leadId,
      action: `provider-leads/${providerLeadId}`
    }),
    snakeCaseKeys(payload)
  )
}
