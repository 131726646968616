import { toOptions } from 'lookups/utils'
import { bySlug } from 'utils'
import { buildLeadStages } from '../lookupLeadStages'
import { AgentMatchingLeadStage } from './AgentMatchingLeadStage'
import {
  agentLeadStageSettings,
  TAgentMatchingLeadStageSlug
} from './agentMatchingLeadStageSettings'

const agentMatchingLeadStages = buildLeadStages(agentLeadStageSettings, AgentMatchingLeadStage)

export const getAgentMatchingLeadStage = (slug: TAgentMatchingLeadStageSlug) =>
  agentMatchingLeadStages.find(bySlug(slug))

export const getAgentMatchingLeadStageName = (slug: TAgentMatchingLeadStageSlug) =>
  getAgentMatchingLeadStage(slug)?.name

const agentMatchingLeadStageOptions = toOptions(agentMatchingLeadStages)

export const getAgentMatchingLeadStageOptions = (slugs: TAgentMatchingLeadStageSlug[] = []) =>
  agentMatchingLeadStageOptions.filter(option => slugs.some(slug => slug === option.value))

export const sellerLeadStageOptions = agentMatchingLeadStageOptions
export const buyerLeadStageOptions = getAgentMatchingLeadStageOptions([
  'intro_pending',
  'introduced',
  'agent_left_vm',
  'client_left_vm',
  'connected',
  'meeting_scheduled',
  'met_in_person',
  'in_escrow',
  'closed',
  'closed_booked',
  'closed_paid',
  'failed'
])
