// @ts-strict

import { useCallback, useMemo, useState } from 'react'
import { useWebSocketController } from 'controllers'
import { snakeCaseKeys } from 'utils'
import {
  ActiveViewer,
  EntityChannels,
  EntityTypes,
  Message,
  MessageIdentifier,
  NewViewerMessage,
  Props,
  SendMessage,
  ViewersReturn
} from './types'

const VIEWERS_CHANNELS: Record<EntityTypes, EntityChannels> = {
  lead: 'LeadViewersChannel',
  agent: 'AgentViewersChannel'
}

export const useViewersRealTimeController = ({
  entityId,
  entityType = 'lead'
}: Props): ViewersReturn => {
  const [activeViewers, setActiveViewers] = useState<ActiveViewer[]>([])
  const entityKeyId = `${entityType}Id`
  const entityChannel = VIEWERS_CHANNELS[entityType]

  const identifier = useMemo(
    () =>
      snakeCaseKeys({
        channel: entityChannel,
        [entityKeyId]: entityId
      }) as MessageIdentifier,
    [entityChannel, entityId, entityKeyId]
  )

  const subscribeToChannel = useCallback(
    ({ callback }: SendMessage) => {
      const message: Message = {
        identifier,
        command: 'subscribe'
      }

      callback(message)
    },
    [identifier]
  )

  const addNewActiveViewers = useCallback((viewer: ActiveViewer) => {
    setActiveViewers(prevViewers => {
      const isViewerRegistered =
        !!prevViewers.length &&
        prevViewers.some(activeViewer => activeViewer?.userId === viewer?.userId)

      if (isViewerRegistered) {
        return prevViewers
      }

      let initials = viewer.initials
      if (!initials) {
        initials = `${viewer.firstName?.charAt(0)}${viewer.lastName?.charAt(0)}`
      }

      const newViewer = {
        ...viewer,
        initials
      }

      return [...prevViewers, newViewer]
    })
  }, [])

  const removeViewer = useCallback((viewerId: number) => {
    setActiveViewers(prevViewers => {
      const removeCurrentViewer = prevViewers.filter(viewer => viewerId !== viewer.userId)
      return removeCurrentViewer
    })
  }, [])

  const { createWebSocketTicket, sendMessage } = useWebSocketController<Message>({
    onMessage: ({ message, type }) => {
      if (type === 'welcome') {
        subscribeToChannel({ callback: sendMessage })
      }

      if (type === 'confirm_subscription') {
        const newViewerMessage: NewViewerMessage = {
          command: 'message',
          identifier,
          data: {
            action: 'user_viewing_lead_details_page'
          }
        }

        sendMessage(newViewerMessage)
      }

      const newMessage = message?.data as ActiveViewer
      if (newMessage?.userId) {
        if (newMessage?.action === 'user_viewing_lead_details_page') {
          addNewActiveViewers(newMessage)
        }

        if (newMessage?.action === 'user_left_lead_details_page') {
          removeViewer(newMessage?.userId)
        }
      }
    }
  })

  const startConnection = useCallback(() => {
    createWebSocketTicket.mutate()
  }, [createWebSocketTicket])

  return {
    startConnection,
    activeViewers,
    addNewActiveViewers
  }
}
