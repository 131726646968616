import { Link } from 'react-router-dom'
import { TableActions, TableCell, TableRow } from 'components'
import { DeleteIconButton } from 'components/Button/IconButton'
import { useTeamContext } from 'contexts'
import { SETTLEMENT_AGENCY_TEAM_ROLE_CACHE_KEY, useLookupsController } from 'hooks'
import { TeamMembership } from 'models'

type TProps = {
  teamMember: TeamMembership
}

export const SettlementAgencySubTeamMembersList = ({ teamMember }: TProps) => {
  const { removeSubTeamMember } = useTeamContext()

  const [, findTeamRole] = useLookupsController(SETTLEMENT_AGENCY_TEAM_ROLE_CACHE_KEY)

  const handleRemoveSubTeamMember = (id: string) => {
    removeSubTeamMember.mutate(id)
  }

  return (
    <>
      {!!teamMember.subTeamMemberships &&
        teamMember.subTeamMemberships.map(member => {
          const role = findTeamRole(member.role)
          return (
            <TableRow key={member.id} data-member-id={member.id} className="subTeamMember">
              <TableCell>Sub Team Member</TableCell>
              <TableCell>
                <Link to={`/admin/employees/${member.user.id}`}>{member.user.name}</Link>
              </TableCell>
              <TableCell>
                <div key={member.id}>
                  {role?.description} {role?.name ? `(${role?.name})` : <em>deprecated role</em>}
                </div>
              </TableCell>
              <TableCell>
                <Link to={`/admin/employees/${teamMember.user.id}`}>
                  {teamMember.user.name} ({findTeamRole(teamMember.role)?.name})
                </Link>
              </TableCell>
              <TableActions>
                <DeleteIconButton onClick={() => handleRemoveSubTeamMember(member.id)} />
              </TableActions>
            </TableRow>
          )
        })}
    </>
  )
}
