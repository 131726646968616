import { Icon, Spinner } from 'components'
import { cx } from 'utils'
import { ButtonV2, ButtonV2Props } from '../ButtonV2'
import style from './InlineEditButton.module.scss'

type InlineEditButtonProps = {
  isError?: boolean
} & ButtonV2Props

export const InlineEditButton = ({
  children,
  isLoading,
  isError,
  className,
  ...props
}: InlineEditButtonProps) => {
  const dangerStyle = isError && style.buttonDanger

  return (
    <ButtonV2 className={cx(style.button, dangerStyle, className)} disabled={isLoading} {...props}>
      {children}
      {isLoading ? <Spinner className={style.spinner} /> : <Icon.Pencil className={style.edit} />}
    </ButtonV2>
  )
}
