import { useState } from 'react'
import { assignmentStatusOptions } from '@constants/tasks'
import {
  CheckBoxArray,
  Column,
  Columns,
  EmployeesAutoComplete,
  MenuSection,
  Section,
  Select
} from 'components'
import { useListContext, useParamsContext } from 'contexts'
import { useEmployeeUsers, useFeatureFlags, useNewFilters } from 'hooks'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import {
  taskAttachableStatus,
  taskCreationTypes,
  taskUserTypeOptions,
  useTaskCategories
} from 'lookups'
import { CompletionStatus } from 'services/Tasks/getSearchParams'
import { ListFilters } from 'templates'
import { FiltersCompletionStatus } from './FiltersCompletionStatus'
import styles from './TaskListFilters.module.scss'

type Params = {
  assigned: string
  assigneeId: string | null
  assignerId: string | null
  attachableStatus: string | null
  categories: string[] | null
  completionStatus: CompletionStatus
  creationType: string | null
  incomingResidence: string
  requiredForLeadStage: string
  templateSlug: string
  unassigned: string
  userType: string[]
}

export const TaskListFilters = () => {
  const { tracking } = useCustomEvents()
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const { newParams, set } = useNewFilters<Params>()
  const { getUserName } = useEmployeeUsers()
  const {
    params: { assigneeId, assignerId }
  } = useParamsContext()
  const { taskCategoryOptions } = useTaskCategories()
  const { findFilterBySlug } = useListContext()
  const { salesOpsAllLeadTasks } = useFeatureFlags(['sales-ops-all-lead-tasks'])

  const currentAssigneeId = newParams.assigneeId ?? assigneeId
  const currentAssignerId = newParams.assignerId ?? assignerId
  const assigneeName = getUserName(currentAssigneeId)
  const assignerName = getUserName(currentAssignerId)
  const requiredForStageFilter = findFilterBySlug('requiredForStage')
  const incomingResidenceFilter = findFilterBySlug('incomingResidence')
  const leadTypeFilter = findFilterBySlug('leadType')
  const attachableStatusFilter = findFilterBySlug('attachableStatus')
  const showFilterColumn = incomingResidenceFilter || requiredForStageFilter || leadTypeFilter

  const setEmployeeId = (key: keyof Params, employee: any) => {
    set(key, employee?.id.toString() || null)
  }

  return (
    <ListFilters.Root isOpen={isOpenPopover} onOpenChange={setIsOpenPopover}>
      <ListFilters.Trigger>
        <ListFilters.TriggerButton iconRight="FilterAltOutlined">Filters</ListFilters.TriggerButton>
      </ListFilters.Trigger>
      <ListFilters.Content>
        <Section className={styles.section}>
          <Columns className={styles.columnsList}>
            <Column className={styles.columnList}>
              <MenuSection title="Assignee">
                <EmployeesAutoComplete
                  value={assigneeName}
                  onSelect={employee => setEmployeeId('assigneeId', employee)}
                  onClear={() => {}}
                  data-testid="assigneeAutoComplete"
                />
              </MenuSection>
              <MenuSection title="Assigner">
                <EmployeesAutoComplete
                  value={assignerName}
                  onSelect={employee => setEmployeeId('assignerId', employee)}
                  onClear={() => {}}
                />
              </MenuSection>
              <MenuSection title="Assignment Status">
                <Select
                  value={newParams.assigned}
                  options={assignmentStatusOptions}
                  onChange={val => set('assigned', val)}
                />
              </MenuSection>
            </Column>
            <Column>
              <FiltersCompletionStatus
                value={newParams.completionStatus}
                onChange={(val: CompletionStatus) => set('completionStatus', val)}
              />
            </Column>
            <Column>
              <MenuSection title="Creation Type">
                <Select
                  value={newParams.creationType}
                  onChange={val => set('creationType', val)}
                  options={taskCreationTypes}
                />
              </MenuSection>
              {!!attachableStatusFilter && salesOpsAllLeadTasks.enabled && (
                <MenuSection title={attachableStatusFilter.name}>
                  <Select
                    value={newParams.attachableStatus}
                    options={taskAttachableStatus}
                    onChange={val => set('attachableStatus', val)}
                  />
                </MenuSection>
              )}
            </Column>

            {!!showFilterColumn && (
              <Column>
                {!!incomingResidenceFilter && (
                  <MenuSection title={incomingResidenceFilter.name}>
                    <Select
                      addBlank
                      value={newParams.incomingResidence}
                      onChange={val => set('incomingResidence', val)}
                      options={incomingResidenceFilter.options}
                    />
                  </MenuSection>
                )}
                {!!requiredForStageFilter && (
                  <MenuSection title={requiredForStageFilter.name}>
                    <Select
                      addBlank
                      value={newParams.requiredForLeadStage}
                      onChange={val => set('requiredForLeadStage', val)}
                      options={requiredForStageFilter.options}
                    />
                  </MenuSection>
                )}

                {!!leadTypeFilter && (
                  <MenuSection title={leadTypeFilter.name}>
                    <CheckBoxArray
                      options={taskUserTypeOptions}
                      value={newParams.userType}
                      onChange={val => set('userType', val)}
                    />
                  </MenuSection>
                )}
              </Column>
            )}
            <Column>
              <MenuSection title="Categories">
                <CheckBoxArray
                  options={taskCategoryOptions}
                  value={newParams.categories}
                  onChange={val => set('categories', val)}
                />
              </MenuSection>
            </Column>
          </Columns>
          <ListFilters.Footer>
            <ListFilters.ApplyButton
              newParams={newParams}
              onApplyToggle={setIsOpenPopover}
              onClick={() =>
                tracking({ name: 'filters', params: { action: 'apply', target: 'task' } })
              }
            />
          </ListFilters.Footer>
        </Section>
      </ListFilters.Content>
    </ListFilters.Root>
  )
}
