// @ts-strict
import { capitalize, cx, DEFAULT_STRING } from 'utils'
import style from './Table.module.scss'

export type TableCellSizes = 'xxsm' | 'xsm' | 'sm' | 'md' | 'lg' | 'mlg' | 'xlg'
export type TableCellAlignments = 'right' | 'center'
export type useTableCellClassesProps = {
  align?: TableCellAlignments
  className?: string
  fixed?: boolean
  size?: TableCellSizes
}

const useTableCellClasses = ({ className, align, fixed, size }: useTableCellClassesProps) =>
  cx(
    className,
    align && style[align],
    fixed && style.fixed,
    size && style[`size${capitalize(size)}`]
  )

export type TableCellProps = useTableCellClassesProps & React.TdHTMLAttributes<any>

export const TableCell = ({
  className,
  children,
  align,
  fixed,
  size,
  ...props
}: TableCellProps) => {
  const classNames = useTableCellClasses({ className, align, fixed, size })

  return (
    <td {...props} className={cx(style.cell, classNames)}>
      {children}
    </td>
  )
}

export const TableHeaderCell = ({
  className,
  children,
  align,
  fixed,
  size,
  ...props
}: TableCellProps) => {
  const classNames = useTableCellClasses({ className, align, fixed, size })

  return (
    <th {...props} className={cx(style.headerCell, classNames)}>
      {children}
    </th>
  )
}

export const EmptyTableCell = (props: TableCellProps) => {
  return <TableCell {...props}>{DEFAULT_STRING}</TableCell>
}
