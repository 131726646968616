import { getLeadsSearchFilterSettings } from '../utils'

export const ccCashOfferLeadSearchFilterSlugs = [
  'assigneeId',
  'capitalPartnerId',
  'source',
  'expressClose',
  'protectedOfferStatus',
  'stage',
  'stateName'
] as const

export const ccCashOfferLeadSearchFilterSettings = getLeadsSearchFilterSettings(
  ccCashOfferLeadSearchFilterSlugs
)
