// @ts-strict
import { ReactNode, useContext } from 'react'
import { TMenuTitleProps } from 'components'
import { useHoverHandler } from 'hooks'
import { cx } from 'utils'
import styles from './Menu.module.scss'
import { MenuContext, MenuProvider } from './MenuContext'
import { MenuItems } from './MenuItems'
import { MenuTitle } from './MenuTitle'

type MenuProps = {
  children: ReactNode
  className?: string
  enterDelay?: number
  leaveDelay?: number
  popupAlignment?: 'left' | 'right'
  shouldUseHover?: boolean
  title?: ReactNode
} & Pick<TMenuTitleProps, 'icon'>

type TWrapperProps = {
  children: ReactNode
  className?: string
  enterDelay?: number
  leaveDelay?: number
  shouldUseHover?: boolean
}

const MenuWrapper = ({
  children,
  className,
  shouldUseHover,
  leaveDelay,
  enterDelay,
  ...props
}: TWrapperProps) => {
  const [, setMenuOpen] = useContext(MenuContext)
  const { onMouseEnter, onMouseLeave } = useHoverHandler({
    onEnter: () => (shouldUseHover ? setMenuOpen(true) : undefined),
    onLeave: () => (shouldUseHover ? setMenuOpen(false) : undefined),
    enterDelay,
    leaveDelay
  })

  return (
    <div
      {...props}
      className={cx(styles.root, className)}
      data-testid="menu"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  )
}

export const Menu = ({
  title,
  children,
  icon,
  popupAlignment = 'right',
  className,
  leaveDelay,
  enterDelay,
  ...props
}: MenuProps) => {
  return (
    <MenuProvider>
      <MenuWrapper
        {...props}
        className={cx(styles.root, className)}
        leaveDelay={leaveDelay}
        enterDelay={enterDelay}
        data-testid="menu"
      >
        <MenuTitle icon={icon}>{title}</MenuTitle>
        <MenuItems justify={popupAlignment}>{children}</MenuItems>
      </MenuWrapper>
    </MenuProvider>
  )
}
