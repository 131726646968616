import { css, styled } from '@foundation/themes/styles'
import type { AvatarStyleProps, AvatarWithImageStyleProps } from './types'

/**
 * VARIANTS
 */

const solidColorModifier = {
  primary: ({ theme }: AvatarStyleProps) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary5};
  `,
  neutral: ({ theme }: AvatarStyleProps) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.neutral9};
  `
}

const outlineColorModifier = {
  primary: ({ theme }: AvatarStyleProps) => css`
    border: ${theme.borderWidths.thin} solid ${theme.colors.primary10};
    color: ${theme.colors.primary10};
    background-color: ${theme.colors.white};
  `,
  neutral: ({ theme }: AvatarStyleProps) => css`
    border: ${theme.borderWidths.thin} solid ${theme.colors.neutral10};
    color: ${theme.colors.neutral10};
    background-color: ${theme.colors.white};
  `
}

const softColorModifier = {
  primary: ({ theme }: AvatarStyleProps) => css`
    color: ${theme.colors.primary9};
    background-color: ${theme.colors.primary2};
  `,
  neutral: ({ theme }: AvatarStyleProps) => css`
    color: ${theme.colors.neutral9};
    background-color: ${theme.colors.neutral2};
  `
}

const variantStyle = {
  solid: ({ color }: Pick<AvatarStyleProps, 'color'>) => solidColorModifier[color!],
  outline: ({ color }: Pick<AvatarStyleProps, 'color'>) => outlineColorModifier[color!],
  soft: ({ color }: Pick<AvatarStyleProps, 'color'>) => softColorModifier[color!]
}

/**
 * SIZES
 */

const sizeStyle = {
  xs: ({ theme }: AvatarStyleProps) => css`
    font-size: ${theme.fontSizes.xs};
    height: ${theme.sizes.$13};
    width: ${theme.sizes.$13};
  `,
  sm: ({ theme }: AvatarStyleProps) => css`
    font-size: ${theme.fontSizes.sm};
    height: ${theme.sizes.$16};
    width: ${theme.sizes.$16};
  `,
  md: ({ theme }: AvatarStyleProps) => css`
    font-size: ${theme.fontSizes.base};
    height: ${theme.sizes.$19};
    width: ${theme.sizes.$19};
  `,
  lg: ({ theme }: AvatarStyleProps) => css`
    font-size: ${theme.fontSizes.lg};
    height: ${theme.sizes.$23};
    width: ${theme.sizes.$23};
  `
}

/**
 * IMAGE
 */

export const Image = styled.img<AvatarWithImageStyleProps>`
  ${({ theme }: AvatarWithImageStyleProps) => css`
    border-radius: ${theme.radii.rounded};
    height: auto;
    max-width: 100%;
  `}
`

export const Avatar = styled.div<AvatarStyleProps>`
  ${({ theme, size, variant, shadow, color }) => css`
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: ${theme.radii.rounded};
    font-weight: ${theme.fontWeights.bold};
    box-shadow: ${shadow ? theme.shadows[shadow] : 'none'};

    ${!!size && sizeStyle[size]}
    ${!!variant && color && variantStyle[variant]({ color })}
  `}
`
