// @ts-strict

import { Link } from 'react-router-dom'
import { ListStringValues } from 'components'
import { titleize } from 'utils'
import styles from './LeadUncompletedTasks.module.scss'

type TLeadUncompletedTasks = {
  items?: string[]
  selectedStage: string
}

export const LeadUncompletedTasks = ({ selectedStage, items = [] }: TLeadUncompletedTasks) => {
  return (
    <div className={styles.leadUncompletedTasks}>
      <ListStringValues
        title="The following tasks must be completed for this stage disposition..."
        items={items}
      />
      <Link to={`tasks?requiredForLeadStage=${selectedStage}&completionStatus=[active]`}>
        View uncompleted "{titleize(selectedStage)}" Tasks...
      </Link>
    </div>
  )
}
