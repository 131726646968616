// @ts-strict

import { useTransactionTeams } from 'lookups'
import { TeamMembership } from 'models'

type TransactionTeamMembersCSVProps = {
  members: Potential<TeamMembership[]>
}

export const TransactionTeamMembersCSV = ({ members }: TransactionTeamMembersCSVProps) => {
  const { findLeadTeamRole } = useTransactionTeams()
  if (!members || !members.length) {
    return null
  }

  return (
    <>
      {members
        .map(member => {
          const role = findLeadTeamRole(member.role)
          return `${member.user.name}${role?.name ? ` (${role.name})` : ''}`
        })
        .join(', ')}
    </>
  )
}
