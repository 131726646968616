import { useChangeReasons } from 'lookups'
import { byAttr } from 'utils'
import { CashOfferLeadField } from './CashOfferLeadField'
import {
  cashOfferLeadFieldsSettings,
  TCashOfferLeadFieldAttribute
} from './cashOfferLeadFieldsSettings'

export const useCashOfferLeadFields = () => {
  const { coeDateChangeReasons, expressCloseChangeReasons } = useChangeReasons()

  const optionLoaders = {
    coeDateChangeReasons,
    expressCloseChangeReasons
  }

  const cashOfferLeadFields = cashOfferLeadFieldsSettings.map(setting => {
    let options = setting.options
    if (setting.getOptions) {
      options = optionLoaders[setting.getOptions]
    }
    return new CashOfferLeadField({ ...setting, options })
  })

  const getCashOfferLeadField = (attr: TCashOfferLeadFieldAttribute) => {
    return cashOfferLeadFields.find(byAttr('attr', attr))
  }

  const getCashOfferLeadFieldName = (attr: TCashOfferLeadFieldAttribute) => {
    return getCashOfferLeadField(attr)?.name
  }

  return {
    cashOfferLeadFields,
    getCashOfferLeadField,
    getCashOfferLeadFieldName
  }
}
