import { TransactionTeam } from 'models'
import { getHapiRequestParams, TransactionTeamSchema } from 'schema'
import { getTeamsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const transactionTeamShape: TransactionTeamSchema = {
  'transactionTeam:team': [
    'description',
    'enabled',
    'name',
    'type',
    {
      teamMemberships: [
        'role',
        'createdAt',
        {
          user: ['firstName', 'lastName', 'email']
        },
        {
          subTeamMemberships: [
            'role',
            'createdAt',
            {
              user: ['firstName', 'lastName', 'email']
            }
          ]
        }
      ]
    }
  ]
}

export const fetchTeam = async (id: string) => {
  const searchParams = getHapiRequestParams(transactionTeamShape)
  const url = getTeamsUrl({ id, searchParams })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: TransactionTeam.create })
}
