import { useEffectOnce } from 'hooks'
import { useAuthCodeFromURL } from './useAuthCodeFromURL'
import { useGeneratePKCEToAuthenticate } from './useGeneratePKCEToAuthenticate'
import { usePostExchangeTokens } from './usePostExchangeTokens'

export const useOAuthHandshakeToGetToken = () => {
  const { authCode } = useAuthCodeFromURL()
  const { exchangeTokens } = usePostExchangeTokens()
  const { codeVerifier } = useGeneratePKCEToAuthenticate()

  useEffectOnce(() => {
    if (authCode && codeVerifier) {
      exchangeTokens.mutate({
        code: authCode,
        codeVerifier
      })
    }
  })
}
