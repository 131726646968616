import {
  AddressUnitAutoComplete,
  AgentsAutoComplete,
  FormField,
  Section,
  Sections
} from 'components'

export const CCCashOfferLeadFormFields = ({
  formTitle,
  fieldSettings,
  fieldValues,
  leadPropertyTypes,
  leadTimelineValues,
  set,
  remove
}) => {
  return (
    <Sections title={formTitle}>
      <Section>
        <FormField title="Property Address" required={fieldSettings.propertyAddress.isRequired}>
          <AddressUnitAutoComplete
            data-testid="propertyAddress"
            onSelect={val => set('propertyAddress', val)}
          />
        </FormField>
      </Section>

      <Section>
        <FormField title="Buyer Agent" required={fieldSettings.buyerAgent.isRequired}>
          <AgentsAutoComplete
            data-testid="buyerAgentSelect"
            value={fieldValues.buyerAgent?.title}
            searchParams={{ cashOffer: 'enrolled' }}
            onSelect={item => set('buyerAgent', item)}
            onClear={() => remove('buyerAgent')}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
