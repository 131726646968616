// @ts-strict
import { Spinner } from '@foundation/components'
import * as S from './styles'

type Props = {
  isLoading?: boolean
}

export const LoadingInfiniteScrollingFooter = ({ isLoading }: Props) => {
  if (!isLoading) {
    return null
  }

  return (
    <S.Wrapper>
      <Spinner size="md" />
    </S.Wrapper>
  )
}
