// @ts-strict
import {
  FormField,
  InlineEditUnder1000Input,
  InlineEditUnder100Input,
  Section,
  Sections
} from 'components'
import { useFeatureFlags } from 'hooks'
import { useCcTradeInLeadFields } from 'lookups'
import { dataOrDash } from 'utils'
import { CCTradeInLeadDetailsProps } from '../CCTradeInLeadDetails'
import { getTradeInLeadInputProps } from './utils'

type Props = Pick<CCTradeInLeadDetailsProps, 'lead' | 'updateTradeInLead'>

export const DRPropertyAssessmentSection = ({ lead, updateTradeInLead }: Props) => {
  const tradeInLead = lead.tradeInProviderLead!.tradeInLead

  const { salesAppImprovement } = useFeatureFlags(['sales-app-improvement'])

  const { getProps } = getTradeInLeadInputProps(tradeInLead, updateTradeInLead)
  const { getCcTradeInLeadFieldName } = useCcTradeInLeadFields()

  return (
    <>
      <Sections title="DR Property Assessment">
        <Section>
          <FormField title={getCcTradeInLeadFieldName('dpEstimatedDaysOfWork')} inline>
            <InlineEditUnder100Input {...getProps('dpEstimatedDaysOfWork')} />
          </FormField>
          <FormField title={getCcTradeInLeadFieldName('dpEstimatedListToPending')} inline>
            {salesAppImprovement ? (
              <InlineEditUnder1000Input {...getProps('dpEstimatedListToPending')} />
            ) : (
              <InlineEditUnder100Input {...getProps('dpEstimatedListToPending')} />
            )}
          </FormField>
        </Section>
        <Section>
          <FormField title={getCcTradeInLeadFieldName('dpEstimatedSaleEscrow')} inline>
            <InlineEditUnder100Input {...getProps('dpEstimatedSaleEscrow')} />
          </FormField>
          <FormField title={getCcTradeInLeadFieldName('dpTotalEstimatedDaysOfOwnership')} inline>
            {dataOrDash(tradeInLead?.dpTotalEstimatedDaysOfOwnership)}
          </FormField>
        </Section>
      </Sections>
    </>
  )
}
