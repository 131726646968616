import { EligibilityCheck } from 'models'
import { getEligibilityCheckUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchEligibilityCheck = async ({
  agentValuation,
  clientMortgageBalance,
  leadId,
  state
}: {
  agentValuation?: string | number
  clientMortgageBalance?: string | number
  leadId: string
  state?: string
}) => {
  const url = getEligibilityCheckUrl({
    searchParams: {
      lead_id: leadId,
      state,
      agent_valuation: agentValuation,
      client_mortgage_balance: clientMortgageBalance
    }
  })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: EligibilityCheck.create })
}
