import { getLead, Lead } from 'models'
import { getHapiRequestParams, LeadSchema } from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchLeadV2 = async (id: string, shape: LeadSchema): Promise<Lead> => {
  const searchParams = getHapiRequestParams(shape)
  const url = getLeadsUrl({
    id,
    searchParams
  })

  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: getLead })
}
