// @ts-strict

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AgentAutoCompleteResponse, TEmployeesAutoCompleteOption } from 'components'
import { useDuplicatableLeadController } from 'controllers'
import { useMap } from 'hooks'
import {
  TLeadUserTypeServerSlug,
  useLeadPropertyTypes,
  useLeadUserTypes,
  useTimelineValues
} from 'lookups'
import { getLead, isBuyerLead, Lead, Order } from 'models'
import { d, isObjectEmpty, presence, SOMETHING_WENT_WRONG, toCamelCase } from 'utils'
import { fieldsLookup } from './constants'
import { getLeadUserTypePayload, getLeadUserTypeValidationMessage } from './utils'

export type TFieldValues = {
  asmAse?: TEmployeesAutoCompleteOption
  buyerAgent?: AgentAutoCompleteResponse
  cityState?: string
  hlSimpleSale?: string
  leadUserType: TLeadUserTypeServerSlug
  originalCreatedAt?: string
  price?: string
  propertyAddress?: string
  propertyType?: string
  referringAgent?: AgentAutoCompleteResponse
  sellerAgent?: AgentAutoCompleteResponse
  timeline?: string
}

type TFieldSettings =
  | (typeof fieldsLookup)['seller']
  | (typeof fieldsLookup)['buyer']
  | (typeof fieldsLookup)['ccTradeIn']
  | (typeof fieldsLookup)['ccCashOffer']
  | (typeof fieldsLookup)['ccHlSimpleSale']

type TProps = {
  order: Order
  selectedLead: Lead
}

export const useAttachLeadCreateLeadTab = ({ selectedLead, order }: TProps) => {
  const { leadPropertyTypes } = useLeadPropertyTypes()
  const { leadTimelineValues } = useTimelineValues()
  const leadPropertyAddress = isBuyerLead(selectedLead)
    ? selectedLead.callReview?.othersideInfo?.fullAddress
    : null
  const navigate = useNavigate()
  const { getLeadUserTypesByOrder } = useLeadUserTypes()
  const [fieldValues, { set, setAll, remove }] = useMap<TFieldValues>()
  const { addDuplicatableLead } = useDuplicatableLeadController(selectedLead)

  const leadUserTypeOptions = getLeadUserTypesByOrder(selectedLead, order)
  const leadUserTypeValidationMessage = getLeadUserTypeValidationMessage(selectedLead, order)
  const fieldSettings: TFieldSettings = fieldValues.leadUserType
    ? fieldsLookup[toCamelCase(fieldValues.leadUserType)]
    : {}
  const formTitle = fieldValues.leadUserType && `${d(fieldValues.leadUserType)} Lead Details`
  const isLoading = addDuplicatableLead.isLoading
  const errorMessage = addDuplicatableLead.isError && SOMETHING_WENT_WRONG

  const fieldIsValid = (key: string) =>
    !fieldSettings[key]?.isRequired || presence(fieldValues[key])

  const isValid = !isObjectEmpty(fieldSettings) && Object.keys(fieldSettings).every(fieldIsValid)

  const handleSubmit = () => {
    addDuplicatableLead.mutate(getLeadUserTypePayload({ lead: selectedLead, order, fieldValues }), {
      onSuccess: ({ data: { data } }) => {
        const newLead = getLead({ id: data.id, userType: fieldValues.leadUserType })
        const path = newLead?.getPathname() || ''
        navigate(path)
      }
    })
  }

  useEffect(() => {
    setAll({
      // reset the form but keep leadUserType
      leadUserType: fieldValues.leadUserType
    })
  }, [fieldValues.leadUserType, setAll])

  return {
    fieldSettings,
    fieldValues,
    handleSubmit,
    isValid,
    leadPropertyTypes,
    leadPropertyAddress,
    leadTimelineValues,
    leadUserTypeOptions,
    leadUserTypeValidationMessage,
    remove,
    set,
    formTitle,
    isLoading,
    errorMessage
  }
}
