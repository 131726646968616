// @ts-strict

import { useButtonForm } from 'components'
import { useTeamContext } from 'contexts'
import { useTeamRoles } from 'lookups'
import { TransactionTeam } from 'models'
import { validatePresence } from 'utils'

type TeamAddEmployeePayload = {
  role: Potential<string>
  userId: Potential<string>
  userName: Potential<string>
}

export const useTeamAddEmployeeButtonForm = (team: TransactionTeam) => {
  const { addTeamMember } = useTeamContext()
  const { teamRoles } = useTeamRoles(team.type)
  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm<TeamAddEmployeePayload>({
    initialPayload: { userId: undefined, userName: undefined, role: undefined },
    mutation: addTeamMember,
    formValidations: {
      userId: validatePresence,
      role: validatePresence
    },
    transformPayload: ({ userId, role }) => ({ userId, role })
  })

  const isSettlementAgencyTeam = team.type === 'SettlementAgencyTeam'

  const teamRolesOptions = teamRoles.map(option => ({
    disabled: isSettlementAgencyTeam ? false : team.roleExists(option.value),
    ...option
  }))

  return { formProps, teamRolesOptions, onChangeAttribute, payload }
}
