import { TMarketplaceProgramSlug } from 'lookups'
import { MarketplaceProgramAgentEnrollStatus } from 'models/MarketplaceProgramAgent/utils'
import { getAgentsUrl } from 'services/getUrl'
import { patchRequest } from 'utils'

type Params = {
  id: string
  payload: {
    enrollmentStatus: MarketplaceProgramAgentEnrollStatus
    program: TMarketplaceProgramSlug
  }
}

export const patchAgentServiceEnrollment = ({
  id,
  payload: { program, enrollmentStatus, ...payload }
}: Params): Promise<any> => {
  const status = enrollmentStatus === 'unenrolled' ? 'disenrolled' : enrollmentStatus

  return patchRequest(getAgentsUrl({ id, action: `marketplace-program-agents/${program}` }), {
    ...payload,
    status
  })
}
