import type { Theming } from '@foundation/themes/styles'
import { css, styled } from '@foundation/themes/styles'
import {
  ghostColorDisabledModifier,
  ghostColorModifier,
  outlineColorDisabledModifier,
  outlineColorModifier,
  solidColorDisabledModifier,
  solidColorModifier
} from './modifiers'
import { IconButtonProps } from '.'

export type IconButtonStyle = Omit<IconButtonProps, 'children' | 'icon'>
type StylesProps = { theme: Theming } & IconButtonStyle

const variantStyle = {
  ghost: ({ color, isDisabled, theme }: StylesProps) => {
    const colorModifier = isDisabled
      ? ghostColorDisabledModifier(theme)
      : ghostColorModifier[color!](theme)

    return css`
      border: 1px solid transparent;
      background-color: transparent;
      ${colorModifier};
    `
  },
  outline: ({ color, isDisabled, theme }: StylesProps) => {
    const colorModifier = isDisabled
      ? outlineColorDisabledModifier(theme)
      : outlineColorModifier[color!](theme)

    return css`
      background-color: transparent;
      ${colorModifier};
    `
  },
  solid: ({ color, isDisabled, theme }: StylesProps) => {
    const colorModifier = isDisabled
      ? solidColorDisabledModifier(theme)
      : solidColorModifier[color!](theme)

    return css`
      border: 1px solid transparent;
      ${colorModifier}
    `
  }
}

const sizeStyle = {
  xs: ({ theme }: StylesProps) => css`
    font-size: ${theme.fontSizes.xs};
    padding: ${theme.space.$1} ${theme.space.$1};
  `,
  sm: ({ theme }: StylesProps) => css`
    font-size: ${theme.fontSizes.sm};
    padding: ${theme.space.$2} ${theme.space.$2};
  `,
  md: ({ theme }: StylesProps) => css`
    font-size: ${theme.fontSizes.base};
    padding: ${theme.space.$3} ${theme.space.$3};
  `,
  lg: ({ theme }: StylesProps) => css`
    font-size: ${theme.fontSizes.base};
    padding: ${theme.space.$4} ${theme.space.$4};
  `
}

const isDisabledStyle = css`
  &:disabled {
    cursor: not-allowed;
  }
`

const isRoundedStyle = css`
  border-radius: ${({ theme }) => theme.radii.rounded};
`

export const Button = styled.button<IconButtonStyle>`
  ${({ variant, size, isDisabled, isRounded, theme }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.radii.md};
    cursor: pointer;

    svg {
      path {
        fill: currentColor;
      }
      circle {
        stroke: currentColor;
      }
    }

    ${!!variant && variantStyle[variant]};
    ${!!size && sizeStyle[size]};
    ${!!isDisabled && isDisabledStyle};
    ${!!isRounded && isRoundedStyle};
  `}
`
