import * as React from 'react'

export const useClickAway = (ref, cb, nullify) => {
  const listen = React.useCallback(
    event => {
      if (nullify) {
        return
      }

      if (typeof document === 'undefined') {
        return
      }

      /**
       * Quick hack.
       * Need to think of an effective way to capture events from the dialer-root node.
       * React's synthetic events make this a bit of an issue.
       */
      const dialerRoot = document.getElementById('dialer-root')
      if (event.path && event.path.indexOf(dialerRoot) !== -1) {
        return
      }

      const { target } = event

      if (ref.current) {
        /**
         * We're finding the root parent of useClickAway,
         * so that click events don't close menus behind a Modal.
         * e.g.: you have a Menu that opens a Modal. When clicking elements within the Modal,
         * we don't want the Menu to close. Similarly, if we have something else
         * that uses useClickAway in that Modal (such as a Calendar), we want to retain the click-away
         * functionality within that Modal. We previously just had document.documentElement, which
         * didn't'work the way we wanted it to, and it's now just a fallback.
         */
        const doc =
          ref.current.closest('#root') || ref.current.closest('#modal') || document.documentElement

        if (target && doc.contains(target) && !ref.current.contains(target)) {
          cb(event)
        }
      }
    },
    [ref, cb, nullify]
  )

  React.useEffect(() => {
    document.addEventListener('mousedown', listen, false)

    return () => {
      document.removeEventListener('mousedown', listen, false)
    }
  }, [listen])
}
