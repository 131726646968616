import { Popover } from '@foundation/components'
import { ListFilters as ListFiltersComponent } from './ListFilters'
import { ListFiltersApplyButton } from './ListFiltersApplyButton'
import { ListFiltersFooter } from './ListFiltersFooter'
import { ListFiltersTriggerButton } from './ListFiltersTriggerButton'

export const ListFilters = {
  Root: ListFiltersComponent,
  Trigger: Popover.Trigger,
  Content: Popover.Content,
  ApplyButton: ListFiltersApplyButton,
  Footer: ListFiltersFooter,
  TriggerButton: ListFiltersTriggerButton
}
