import { AxiosResponse } from 'axios'
import { AgentResponse } from 'schema'
import { getAgentsUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export type PostAgentPhoneNumber = {
  id?: string
  number: string
  phoneType: 'cell' | 'office'
}
export type PostAgentPayload = {
  assignedAreaId?: string
  assignedAreaType?: 'Metroplex' | 'State'
  brokerage?: string
  email1?: string
  email2?: string
  firstName?: string
  lastName?: string
  licenseNumber?: string
  phoneNumbers?: PostAgentPhoneNumber[]
  stateId?: string
  website?: string
}
export type PostAgentResponse = AxiosResponse<{ data: AgentResponse }>

export const postAgent = async (payload: PostAgentPayload) =>
  await postRequest(getAgentsUrl(), snakeCaseKeys(payload))
