export const AGENT_AE_AREA_CACHE_KEY = 'agent-ae-areas'
export const AGENT_PARTNERSHIPS_CACHE_KEY = 'agent-partnerships'
export const ORDER_CACHE_KEY = 'order'
export const ORDERS_CACHE_KEY = 'orders'
export const TASKS_CACHE_KEY = 'tasks'
export const TASKS_CACHE_KEY_COUNT = 'tasksCount'
export const TASK_CATEGORIES_CACHE_KEY = 'taskCategories'
export const EMPLOYEES_CACHE_KEY = 'employees'
export const CLIENTS_CACHE_KEY = 'clients'
export const CURRENT_USER_CACHE_KEY = 'current-user'
export const CURRENT_USER_LEADS_COUNT_CACHE_KEY = 'currentUserLeadsCount'
export const SIMILAR_LEADS_COUNT_CACHE_KEY = 'similarLeadsCount'
export const EXTERNAL_LINKS_CACHE_KEY = 'external-links'
export const AGENTS_CACHE_KEY = 'agents'
export const USERS_CACHE_KEY = 'users'
export const INSPECTIONS_CACHE_KEY = 'inspections'
export const INSPECTION_DEFICIENCIES_CACHE_KEY = 'inspection-deficiencies'
export const LEADS_CACHE_KEY = 'leads'
export const CONSTANTS_CACHE_KEY = 'constants'
export const FILES_CACHE_KEY = 'files'
export const US_STATES_CACHE_KEY = 'usStates'
export const METROPLEXES_CACHE_KEY = 'metroplexes'
export const SERV_OPORTUNITY_STATUS_CACHE_KEY = 'servicesOpportunityStatuses'
export const TEAMS_CACHE_KEY = 'teams'
export const CLIENT_ADVISOR_TEAM_ROLE_CACHE_KEY = 'client_advisory_team_role'
export const TRANSACTION_TEAMS_CACHE_KEY = 'transactionTeams'
export const TRANSACTION_TEAM_ROLE_CACHE_KEY = 'transaction_team_role'
export const SETTLEMENT_AGENCY_TEAMS_CACHE_KEY = 'settlementAgencyTeams'
export const SETTLEMENT_AGENCY_TEAM_ROLE_CACHE_KEY = 'settlement_agency_team_role'
export const LEAD_USER_ROLE_CACHE_KEY = 'lead_user_role'
export const NYLAS_CACHE_KEY = 'nylas'
export const MORTGAGE_LEAD_CACHE_KEY = 'mortgage'
export const PHOTOS_CACHE_KEY = 'photos'
export const ALL_LEADS_CACHE_KEY = 'allLeads'
export const CC_TRADE_IN_LEADS_CACHE_KEY = 'ccTradeInLeads'
export const CC_CASH_OFFER_LEADS_CACHE_KEY = 'ccCashOfferLeads'
export const ESCROW_LEADS_CACHE_KEY = 'escrowLeads'
export const SERVICE_OFFICES_CACHE_KEY = 'serviceOffices'
export const BUYER_LEAD_CACHE_KEY = 'buyerLead'
export const BUYER_LEADS_CACHE_KEY = 'buyerLeads'
export const SELLER_LEAD_CACHE_KEY = 'sellerLead'
export const SELLER_LEADS_CACHE_KEY = 'sellerLeads'
export const MORTGAGE_LEADS_CACHE_KEY = 'mortgageLeads'
export const HLSS_LEADS_CACHE_KEY = 'ccHLSimpleSaleLeads'
export const BUYERS_AND_SELLERS_CACHE_KEY = 'buyerSellerLeads'
export const LEAD_SUMMARY_CACHE_KEY = 'leadSummary'
export const LEAD_WARM_TRANSFER_KEY = 'leadWarmTransfer'
export const ACTIVITY_FEED_KEY = 'activityFeed'
export const INVESTOR_LEADS_CACHE_KEY = 'investorLeads'
export const AGENT_LEADS_CACHE_KEY = 'agentLeads'
export const CHECKOUT_SESSIONS_CACHE_KEY = 'checkoutSessions'
export const GENERATED_DOCUMENTS_CACHE_KEY = 'generatedDocuments'
export const PARTNERS_CACHE_KEY = 'partners'
export const HOME_VALUE_CACHE_KEY = 'homeValue'
export const PURE_CLOUD_TOKEN_ACTIVE = 'pureCloudTokenActive'
