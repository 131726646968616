import { AgentAeArea } from 'models'
import { AgentAeAreasSchema, getHapiRequestParams, Meta } from 'schema'
import { getAgentAeAreas } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams, SearchParamsSettings } from '../getSearchParams'

const agentAeAreaShape: AgentAeAreasSchema = {
  agentAeAreas: [
    {
      'assignedArea:metroplex': [
        'name',
        'slug',
        { state: ['name', 'code', { marketplacePrograms: ['name', 'slug'] }] }
      ]
    },
    { 'assignedArea:state': ['name', 'code'] },
    { 'servicesUser:user': ['name'] },
    { user: ['name'] }
  ]
}

export const fetchAgentAeAreas = async (searchParams: SearchParamsSettings) => {
  const meta: Meta = ['total', 'agentsCount']
  const url = getAgentAeAreas({
    searchParams: {
      ...getHapiRequestParams(agentAeAreaShape, { meta }),
      ...getSearchParams(searchParams)
    }
  })

  const response = await getRequest(url)

  return {
    agentAeAreas: await deserializeAsync(response.data, {
      transform: AgentAeArea.create
    }),
    agentAeAreasCount: response.data.meta.total,
    assignedAreaAgentsCount: response.data.meta.agents_count
  }
}
