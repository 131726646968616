// @ts-strict
import { AgentAgreementResponse, AgreementResponse } from 'schema'
import { Model } from '../Model'

export class AgentAgreement extends Model {
  agreement?: AgreementResponse
  displayFilename?: string
  id?: string
  s3Path?: string
  signedAt?: string
  status?: string
  createdAt?: string
  voidedAt?: string
  note?: string

  constructor(agentAgreementAttributes: AgentAgreementResponse) {
    super()
    Object.assign(this, agentAgreementAttributes)
  }

  static create(
    agentAgreementResponse: Potential<AgentAgreementResponse>
  ): Potential<AgentAgreementResponse> {
    if (!agentAgreementResponse) {
      return
    }

    return new AgentAgreement(agentAgreementResponse)
  }
}
