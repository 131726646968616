/* eslint-disable sort-imports */
/* eslint-disable import/order */
import { useMemo, useState } from 'react'

import {
  Button,
  CheckBoxArray,
  Column,
  Columns,
  EmployeesAutoComplete,
  MenuSection,
  Section,
  SectionFooter,
  Select
} from 'components'

import { FiltersObj, useTableContext } from '@foundation/components/Table'

import {
  bbysStageOptions,
  bbysPartnerOptions,
  stateNameOptions,
  blankOptions,
  bbysDatesAndMilestonesOptions
} from 'lookups'
import { useEmployeeUsers } from 'hooks'

import styles from './styles.module.scss'
import { Option } from 'classes'

export const FiltersControl = () => {
  const { filterValues, onFilter } = useTableContext()
  const { getUserName } = useEmployeeUsers()

  const [filters, setFilters] = useState(filterValues)

  const employeeValue = filters['lead_users.user_id']?.[0]
  const employeeName = getUserName(employeeValue)

  const filtersObj = useMemo(() => {
    return Object.entries(filters).reduce((obj, [key, values]) => {
      if (values.length > 0) {
        return {
          ...obj,
          [key]: values.map(v => {
            switch (key as keyof typeof filters) {
              case 'stage':
                return bbysStageOptions.find(o => o.value === v)
              case 'partner_slug':
                return bbysPartnerOptions.find(o => o.value === v)
              case 'state_name':
                return stateNameOptions.find(o => o.value === v)
              case 'dates_and_milestones':
                return bbysDatesAndMilestonesOptions.find(o => o.value === v)
              default: // lead_users.user_id
                return new Option({ name: employeeName, value: employeeValue })
            }
          }) as Option<string>[]
        }
      }
      return obj
    }, {} as FiltersObj)
  }, [filters, employeeName, employeeValue])

  const onChange = (key: keyof typeof filters, val: string[]) => {
    setFilters(prev => ({ ...prev, [key]: val }))
  }

  return (
    <Section className={styles.section}>
      <Columns>
        <Column>
          <MenuSection title="Current Stage">
            <CheckBoxArray
              options={bbysStageOptions}
              value={filters.stage || []}
              onChange={val => onChange('stage', val)}
            />
          </MenuSection>
        </Column>

        <Column>
          <MenuSection title="Partner">
            <CheckBoxArray
              options={bbysPartnerOptions}
              value={filters.partner_slug || []}
              onChange={val => onChange('partner_slug', val)}
            />
          </MenuSection>
        </Column>

        <Column>
          <MenuSection title="Dates & Milestones">
            <CheckBoxArray
              options={bbysDatesAndMilestonesOptions}
              value={filters.dates_and_milestones || []}
              onChange={val => onChange('dates_and_milestones', val)}
            />
          </MenuSection>
        </Column>

        <Column className="resetOverflow">
          <MenuSection title="HomeLight member">
            <EmployeesAutoComplete
              value={employeeName || ''}
              onSelect={val => onChange('lead_users.user_id', [val?.id])}
              onClear={() => onChange('lead_users.user_id', [])}
            />
          </MenuSection>
          <MenuSection title="State">
            <Select
              value={filters.state_name?.[0] || ''}
              options={blankOptions.concat(stateNameOptions)}
              onChange={val => onChange('state_name', [val])}
            />
          </MenuSection>
        </Column>
      </Columns>

      <SectionFooter>
        <Button as="primary" onClick={() => onFilter(filtersObj)} disabled={false}>
          Apply
        </Button>
      </SectionFooter>
    </Section>
  )
}
