import { useState } from 'react'
import { ContactListItem } from 'content/Contact'
import { Contact } from 'models'
import { escapeRegex, idMatch } from 'utils'
import styles from './ContactsList.module.scss'

type Props = {
  contacts: Contact[]
  currentContactId: string
  onClick: (number) => void
}

export const ContactsList = ({ contacts, onClick, currentContactId }: Props) => {
  const [search, setSearch] = useState('')

  const withWord = RegExp(`\\b${escapeRegex(search)}`, 'gi')
  const visibleContacts = contacts.filter(c => withWord.test(c.agent.fullName))

  const handleSetSearch = e => setSearch(e.currentTarget.value)

  return (
    <>
      <input
        placeholder="Search"
        value={search}
        onChange={handleSetSearch}
        className={styles.search}
      />
      <div className={styles.list}>
        {visibleContacts.map(contact => (
          <ContactListItem
            contact={contact}
            onClick={onClick}
            key={contact.agent.id}
            selected={idMatch(contact.agent.id, currentContactId)}
          />
        ))}
      </div>
    </>
  )
}
