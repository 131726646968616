// @ts-strict
import { useEffect, useState } from 'react'
import { updateTitle } from 'utils'

const blank = '&#6158;'

export const useDocumentTitle = (title: string = document.title) => {
  const [blink, setBlink] = useState(false)

  useEffect(() => {
    updateTitle(title)
  }, [title])

  useEffect(() => {
    let blinkInterval: NodeJS.Timer | undefined
    if (blink) {
      blinkInterval = setInterval(() => {
        updateTitle(blank)
        setTimeout(() => updateTitle(title), 550)
      }, 1100)
    }
    return () => {
      clearInterval(blinkInterval)
    }
  }, [blink, title])

  return {
    setBlink,
    setTitle: updateTitle
  }
}
