// @ts-strict
import { Employee, Metroplex, UsState } from 'models'
import { AgentAeAreaResponse } from 'schema'
import { MetroplexResponse, StateResponse } from 'schema'
import { Model } from '../Model'

export class AgentAeArea extends Model {
  id!: string
  assignedArea?: Metroplex | UsState
  modelName = 'AgentAeArea'
  servicesUser?: Employee
  user!: Employee

  constructor(agentAeAreaAttributes: Omit<AgentAeArea, keyof Model>) {
    super()
    Object.assign(this, agentAeAreaAttributes)
  }

  static create(agentAeAreaResponse: Potential<AgentAeAreaResponse>): Potential<AgentAeArea> {
    if (!agentAeAreaResponse) {
      return
    }

    const responseAssignedArea = agentAeAreaResponse.assignedArea
    const assignedArea =
      'slug' in agentAeAreaResponse.assignedArea
        ? Metroplex.create(responseAssignedArea as MetroplexResponse)
        : UsState.create(responseAssignedArea as StateResponse)!
    const servicesUser = Employee.create(agentAeAreaResponse?.servicesUser)
    const user = Employee.create(agentAeAreaResponse.user)!

    return new AgentAeArea({
      ...agentAeAreaResponse,
      assignedArea,
      servicesUser,
      user
    })
  }
}
