import type { AutocompleteItem } from '@foundation/components'

const channelTextsOption = {
  label: 'Texts',
  value: 'sms_occurred',
  section: { label: 'Text Messages', value: 'sms_occurred' }
}
const channelVoicecallsOption = {
  label: 'Voice Calls',
  value: 'phone_call_initiated',
  section: { label: 'Voice Calls', value: 'phone_call_initiated' }
}
const channelVoicemailsOption = {
  label: 'Voicemails',
  value: 'voicemail_receive',
  section: { label: 'Voice Calls', value: 'phone_call_initiated' }
}
const channelRejectedCallsOption = {
  label: 'Rejected Calls',
  value: 'rejected_phone_call',
  section: { label: 'Voice Calls', value: 'phone_call_initiated' }
}
const channelMissedCallOption = {
  label: 'Missed Calls',
  value: 'missed_phone_call',
  section: { label: 'Voice Calls', value: 'phone_call_initiated' }
}
const channelEmailsOption = {
  label: 'Emails',
  value: 'email_occurred',
  section: { label: 'Emails', value: 'email_occurred' }
}

export const channelsOptions = [
  channelTextsOption,
  channelEmailsOption,
  channelVoicecallsOption,
  channelVoicemailsOption,
  channelRejectedCallsOption,
  channelMissedCallOption
]

export type CommsFiltersDateRange = {
  endingDate?: string
  startingDate?: string
}

/**
 * GLOBAL TAB
 */

export type GlobalFilters = Partial<{
  agent?: AutocompleteItem
  channels: string[]
  client: AutocompleteItem
  dateRange: CommsFiltersDateRange
  user: AutocompleteItem
}>

export const emailChannelValue = channelEmailsOption.value
export const globalChannelsDefaultValues = [channelTextsOption.value, channelVoicecallsOption.value]

/**
 * CLIENT TAB
 */

export type ClientFilters = Partial<{
  agent?: AutocompleteItem
  channels: string[]
  dateRange: CommsFiltersDateRange
  user: AutocompleteItem
}>

export const clientChannelsDefaultValues = [channelTextsOption.value, channelVoicecallsOption.value]

/**
 * AGENT TAB
 */

export type AgentFilters = Partial<{
  channels: string[]
  client: AutocompleteItem
  dateRange: CommsFiltersDateRange
  user: AutocompleteItem
}>

export const agentChannelsDefaultValues = [channelTextsOption.value, channelVoicecallsOption.value]
