import { useMemo } from 'react'
import { ParamsContextType } from 'contexts/types'
import { useMap } from 'hooks'
import { presence, sortDirection } from 'utils'
import { getViewParams, omitViewParams } from './utils'

type TParams = {
  [key: string]: string | string[]
  sortBy?: string
  sortDir?: string
  viewColumns?: string[]
}

const emptyArray = []

export const useParams = (
  defaultParams: TParams = {},
  initialParams: TParams = {}
): ParamsContextType => {
  const [params, { set: updateParam, setAll: updateParams, remove: resetParam }] =
    useMap<TParams>(defaultParams)
  const searchParams = useMemo(() => omitViewParams(params), [params])
  const viewParams = useMemo(() => getViewParams(params), [params])

  const initializeParams = () => {
    updateParams(initialParams)
  }

  const updateSearchTerm = searchTerm =>
    updateParams({
      ...Object.fromEntries(Object.entries(params).filter(([key]) => key !== 'page')),
      searchTerm
    })

  const updatePage = (pageNumber: number) => {
    if (pageNumber === 1) {
      return resetParam('page')
    }

    return updateParam('page', String(pageNumber))
  }

  const updateSort = (sortBy: string, defaultDir: SortDirection = 'asc') => {
    const sortDir = sortDirection(params.sortBy, params.sortDir, sortBy, defaultDir)

    updateParams({ ...params, sortBy, sortDir })
  }

  const updateFilters = (newParams: {} | Record<string, string | string[]> = {}) => {
    updateParams({
      ...params,
      ...newParams,
      page: undefined
    })
  }

  const updateViews = (newParams: {} | Record<string, string | string[]> = {}) => {
    updateParams({
      ...params,
      ...newParams
    })
  }

  const removeValueFromParam = (param: string, value: string) => {
    const paramValue = params[param]

    if (typeof paramValue === 'string' && value === paramValue) {
      resetParam(param)
    }
    if (Array.isArray(paramValue)) {
      const filteredValues = paramValue?.filter(paramValue => paramValue !== value)
      presence(filteredValues) ? updateParam(param, filteredValues) : resetParam(param)
    }
  }

  const resetParams = () => {
    const currentViewParams = getViewParams(params)
    updateParams(currentViewParams)
  }

  const viewColumns: string[] = presence(params?.viewColumns) || emptyArray

  const setViewColumns = (val: string[] | undefined) => {
    val ? updateParam('viewColumns', val) : resetParam('viewColumns')
  }

  const isSelectedColumn = (val: string) => {
    return viewColumns.includes(val)
  }

  return {
    defaultParams,
    searchParams,
    initialParams,
    initializeParams,
    isSelectedColumn,
    params,
    removeValueFromParam,
    resetParam,
    resetParams,
    setViewColumns,
    updateFilters,
    updatePage,
    updateParam,
    updateParams,
    updateSearchTerm,
    updateSort,
    updateViews,
    viewColumns,
    viewParams
  }
}
