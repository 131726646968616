// @ts-strict
import { titleize } from 'utils'
import { useDisplayValueFunctions } from './useDisplayValueFunctions'
import { SearchFilter, searchFilterSettings } from '.'

export type TLookupSearchFilters = ReturnType<typeof useSearchFilters>

export const useSearchFilters = () => {
  const functions = useDisplayValueFunctions()

  const buildDefaultString = (searchFilter: SearchFilter) => (value: string) =>
    searchFilter.options?.find(el => el.value === value)?.name || titleize(value)

  const searchFilters = searchFilterSettings.map(filterSetting => {
    const filter = new SearchFilter(filterSetting as any) //until we remove as const from the settings
    filter.getDisplayValue = buildDefaultString(filter)
    if (filter.getDisplayValueFnName && functions[filter.getDisplayValueFnName]) {
      filter.getDisplayValue = functions[filter.getDisplayValueFnName]
    }
    return filter
  })

  const findFilterBySlug = (slug: string) => searchFilters.find(filter => filter.slug === slug)

  return {
    findFilterBySlug
  }
}
