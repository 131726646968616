import { ReactNode } from 'react'
import { createCtx } from 'contexts'
import { CCHLSimpleSaleLead } from 'models'
import { useHLSSLeadDisposition } from '../useHLSSLeadDisposition'

type HLSSLeadDispositionProviderProps = {
  children: ReactNode
  lead: CCHLSimpleSaleLead
}

export const [useHLSSLeadDispositionContext, HLSSLeadDispositionContextProvider] =
  createCtx<ReturnType<typeof useHLSSLeadDisposition>>()

export const HLSSLeadDispositionProvider = ({
  lead,
  children
}: HLSSLeadDispositionProviderProps) => {
  return (
    <HLSSLeadDispositionContextProvider value={useHLSSLeadDisposition(lead)}>
      {children}
    </HLSSLeadDispositionContextProvider>
  )
}
