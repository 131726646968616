import { ComponentPropsWithoutRef, useEffect, useRef, useState } from 'react'
import { TextInput } from 'components/Form'
import { Icon } from 'components/Icon'
import { cx } from 'utils'
import styles from './SearchInput.module.scss'

type Props = {
  className?: string
  now?: boolean
  onChange?: (value: string) => void
  value: string
}

export const SearchInput = ({
  value,
  onChange,
  className,
  now,
  ...props
}: Props & Omit<ComponentPropsWithoutRef<'input'>, 'onChange'>) => {
  const searchHandle = useRef<ReturnType<typeof setTimeout>>(null)
  const [term, setTerm] = useState(value)

  const debounceChange = t => {
    clearTimeout(searchHandle.current)
    searchHandle.current = setTimeout(() => onChange(t), now ? 0 : 400)
  }

  const handleOnChange = val => {
    setTerm(val)
    debounceChange(val)
  }

  useEffect(() => {
    setTerm(value)
  }, [value])

  return (
    <div className={styles.searchWrapper}>
      <Icon.Search />
      <TextInput
        value={term}
        onChange={handleOnChange}
        className={cx(styles.search, className)}
        {...props}
      />
    </div>
  )
}
