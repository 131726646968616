import { ReactNode } from 'react'
import { If } from 'components'
import { cx } from 'utils'
import styles from './FilterBar.module.scss'

type Props = {
  actions?: ReactNode
  children?: ReactNode
  className?: string
}

export const FilterBar = ({ children, actions, className }: Props) => {
  return (
    <div className={cx(className, styles.filterBar)}>
      <div className={styles.left}> {children}</div>
      <If test={actions}>
        <div className={styles.right}>{actions}</div>
      </If>
    </div>
  )
}
