// @ts-strict
import { TableHeaderColumn as TH, TableHeaderRow as TR } from 'components'
import { useListContext } from 'contexts'
import { cx } from 'utils'
import styles from './OrdersTable.module.scss'

export const OrdersTableHeader = () => {
  const { showColumn } = useListContext()
  return (
    <TR>
      {showColumn('businessUnit') && <TH className={styles.businessUnit} name="Business Unit" />}
      {showColumn('clientName') && <TH className={styles.client} name="Client / Partner" />}
      {showColumn('orderStageSource') && <TH className={styles.orderStage} name="Order" />}
      {showColumn('property') && <TH className={styles.property} name="Property" />}
      {showColumn('lead') && <TH className={styles.lead} name="Lead" />}
      {showColumn('price') && (
        <TH className={styles.price} sort={[{ name: 'Price', slug: 'Price' }]} />
      )}
      {showColumn('stage') && <TH className={styles.leadStage} name="Stage / Furthest" />}
      {showColumn('listingStatus') && <TH className={styles.listingStatus} name="Listing Status" />}
      {showColumn('agentsOpps') && <TH className={styles.agentsOpps} name="Agents / Opps" />}
      {showColumn('agent') && <TH className={styles.agent} name="Agent" />}
      {showColumn('transactionTeam') && <TH className={styles.transactionTeam} name="Tx Team" />}
      {showColumn('gppVal') && <TH className={styles.gppVal} name="GPP / Val" />}
      {showColumn('coe') && (
        <TH
          sort={[
            { name: 'COE Purchase', slug: 'COE Purchase', flip: true },
            { name: 'COE Sale', slug: 'COE Sale', flip: true }
          ]}
          className={styles.date}
        />
      )}
      {showColumn('daysOwned') && <TH className={styles.daysOwned} name="Days Owned" />}
      {showColumn('fundingDate') && (
        <TH
          sort={[{ name: 'Funding Date', slug: 'FundingDate', flip: true }]}
          className={styles.date}
        />
      )}
      {showColumn('createdAt') && (
        <TH sort={[{ name: 'Created', slug: 'Created', flip: true }]} className={styles.date} />
      )}
      {showColumn('updatedAt') && (
        <TH
          sort={[{ name: 'Updated', slug: 'Updated', flip: true }]}
          className={cx(styles.date, styles.date)}
        />
      )}
      {showColumn('introSentAt') && (
        <TH
          sort={[{ name: 'Intro Sent At', slug: 'IntroSentAt', flip: true }]}
          className={cx(styles.date, styles.date)}
        />
      )}
    </TR>
  )
}
