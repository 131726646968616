import { PaginationParams } from 'components'
import { Contents } from 'components/Contents/index'
import { Page } from 'components/Page/index'
import { OrdersTable } from 'content/Orders'
import { OrdersListHeader } from 'content/Orders/OrdersList/OrdersListHeader'
import { ListProvider } from 'contexts'
import { OrdersProvider, useOrdersContext } from 'contexts/Orders/index'
import { useDocumentTitle } from 'hooks'
import { homeLoansOrdersListColumns, useOrdersListSearchFilters } from 'lookups'

const PageContents = () => {
  const { orders, pageCount, isLoading } = useOrdersContext()

  return (
    <Page>
      <Contents title="Home Loans Orders">
        <OrdersListHeader />
        <OrdersTable orders={orders} isLoading={isLoading} />
        {pageCount ? <PaginationParams totalPages={pageCount} /> : null}
      </Contents>
    </Page>
  )
}

export const HomeLoansOrdersPage = () => {
  useDocumentTitle('Home Loans Orders')
  const filters = useOrdersListSearchFilters()

  return (
    <OrdersProvider rootPath="/home-loans" scope="home_loans">
      <ListProvider columns={homeLoansOrdersListColumns} filters={filters}>
        <PageContents />
      </ListProvider>
    </OrdersProvider>
  )
}
