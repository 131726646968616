import { UseMutationResult } from 'react-query'
import { TCashOfferLeadFieldAttribute } from 'lookups'
import { CashOfferLead } from 'models'
import { getPercentageValue, getPercentageValueFormat, isStringEqual, stringifyValue } from 'utils'

export const getCashOfferLeadInputProps = (
  cashOfferLead: CashOfferLead,
  updateCashOfferLead: UseMutationResult<unknown, unknown, Record<string, string>>,
  getCashOfferLeadField
) => {
  const handleUpdateCashOfferLead = (key: string, val: string) => {
    if (!isStringEqual(cashOfferLead[key], val)) {
      updateCashOfferLead.mutate({
        [key]: val
      })
    }
  }

  const getProps = (key: string) => {
    const field = getCashOfferLeadField(key as TCashOfferLeadFieldAttribute)

    return {
      value: stringifyValue(cashOfferLead[key]),
      options: field?.options || undefined,
      onBlur: val => {
        handleUpdateCashOfferLead(key, val)
      }
    }
  }

  const getPercentProps = (key: string) => {
    return {
      onBlur: (val: string) => handleUpdateCashOfferLead(key, getPercentageValue(val)),
      value: getPercentageValueFormat(cashOfferLead[key]) as unknown as string
    }
  }

  return {
    getProps,
    getPercentProps
  }
}
