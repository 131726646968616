export const getFilter = <KS extends Record<string, string>, KV extends Record<string, any>>(
  keySlugs: KS,
  keyValue: Partial<Record<keyof KS, KV[keyof KV]>>
) => {
  const result = {}
  Object.entries(keySlugs).forEach(([key, slug]) => {
    const value = keyValue[key]
    if (value !== undefined && value !== null && value !== '') {
      result[slug] = value
    }
  })

  return result
}
