import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Console.module.scss'

type ConsoleAsideProps = {
  children: ReactNode
  className?: string
}

export const ConsoleAside = ({ className, children }: ConsoleAsideProps) => (
  <div className={cx('consoleAside', styles.aside, className)}>{children}</div>
)
