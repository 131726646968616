import { Button } from '@foundation/components'
import { useParamsContext } from 'contexts'
import { normalizeObject, objectMatch, removeAttributesWithNullValues } from 'utils'

type Props = {
  newParams: Record<string, any>
  onApplyToggle: (isOpen: boolean) => void
  onClick?: () => void
}

export const ListFiltersApplyButton = ({ onApplyToggle, newParams, onClick }: Props) => {
  const { params, updateFilters } = useParamsContext()
  const newParamsWithoutEmptyValues = removeAttributesWithNullValues(newParams)
  const isParamsEqual = objectMatch(
    normalizeObject(params),
    normalizeObject(newParamsWithoutEmptyValues)
  )

  const handleApplyFilter = () => {
    updateFilters(newParams)
    onApplyToggle(false)
    onClick?.()
  }

  return (
    <Button onClick={handleApplyFilter} isDisabled={isParamsEqual}>
      Apply
    </Button>
  )
}
