// @ts-strict
import { Section } from 'components'
import { Employee } from 'models'
import { csv } from 'utils'
import { EmployeeRolesPopupButton } from '.'

type Props = {
  employee: Employee
}

export const EmployeeRoles = ({ employee }: Props) => {
  if (!employee.roles?.length) {
    return null
  }

  return (
    <Section title="Roles" actions={<EmployeeRolesPopupButton employee={employee} />}>
      {csv(employee.roles, 'name')}
    </Section>
  )
}
