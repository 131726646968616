import { LoanOfficer } from 'models'
import { getHapiRequestParams, LoanOfficerResponse, LoanOfficerSchema } from 'schema'
import { getLoanOfficersUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const getLoanOfficer = async (loanOfficerId: string) => {
  const loanOfficersFields: Array<keyof LoanOfficerResponse> = [
    'companyName',
    'email',
    'name',
    'userId'
  ]

  const loanOfficerShape: LoanOfficerSchema = {
    loanOfficer: [
      ...loanOfficersFields,
      { user: ['name'] },
      { phoneNumber: ['number', 'phoneType'] },
      { partner: ['slug'] }
    ]
  }
  const searchParams = getHapiRequestParams(loanOfficerShape)
  const url = getLoanOfficersUrl({ id: loanOfficerId, searchParams: searchParams })

  const response = await getRequest(url)

  return await deserializeAsync(response.data, {
    transform: LoanOfficer.create
  })
}
