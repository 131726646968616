import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Spinner, useToastContext } from '@foundation/components'
import { Table, TColumn, useTable } from '@foundation/components/Table'
import { CheckBoxField } from 'components'
import { deserializeAsync, makeRequest } from 'utils'
import { Container } from './styles'

type LRM = {
  created_at: string
  email: string
  id: string
  lrmRoundRobinAvailability: false
  name: string
  picture_url: string
}

type UpdateLRMPayload = {
  id: string
  isAvailable: boolean
}

const GET_LRM_LIST = 'GET_LRM_LIST'
const BASE_URL = process.env.REACT_APP_API_ROOT

export const All = () => {
  const [mutationId, setMutationId] = useState<string | null>(null)

  const client = useQueryClient()
  const table = useTable()
  const toast = useToastContext()

  const { data, isLoading: isQueryLoading } = useQuery<LRM[]>(
    [GET_LRM_LIST],
    async () => {
      const URL =
        BASE_URL + '/user-data-service/users/lrms?fields[user]=lrm_round_robin_availability'

      const { data } = await makeRequest(URL)
      const deserialized = await deserializeAsync(data)

      return deserialized
    },
    {
      onError: error => {
        console.error(error)
        toast({
          title: 'Ops! Something went wrong.',
          status: 'error'
        })
      }
    }
  )

  const { mutate, isLoading: isMutationLoading } = useMutation(
    ({ id, isAvailable }: UpdateLRMPayload) => {
      const URL = BASE_URL + '/user-data-service/users/update_lrm_availability'

      return makeRequest(URL, {
        method: 'POST',
        data: {
          user_id: id,
          availability: isAvailable ? 'true' : 'false'
        }
      })
    },
    {
      onMutate: ({ id, isAvailable }) => {
        const previousLrms = client.getQueryData(GET_LRM_LIST)
        client.setQueryData(GET_LRM_LIST, (old: LRM[]) => {
          return old.map(lrm =>
            lrm.id === id ? { ...lrm, lrmRoundRobinAvailability: isAvailable } : lrm
          )
        })
        return { previousLrms }
      },
      onSuccess: () => {
        client.invalidateQueries(GET_LRM_LIST)
        setMutationId(null)
      },
      onError: (error, _payload, context) => {
        client.setQueryData(GET_LRM_LIST, context.previousLrms)
        console.error(error)

        toast({
          title: 'Ops! Something went wrong.',
          status: 'error'
        })
      }
    }
  )

  const columns: TColumn<LRM>[] = [
    {
      label: 'Name',
      render: row => row.name
    },
    {
      label: 'Email',
      render: row => row.email
    },
    {
      label: 'Available?',
      render: row =>
        isMutationLoading && mutationId === row.id ? (
          <Spinner size="xs" />
        ) : (
          <CheckBoxField
            checked={row.lrmRoundRobinAvailability}
            onChange={e => mutate({ id: row.id, isAvailable: e.target.checked })}
          />
        )
    }
  ]

  return (
    <Container>
      <Table.Root {...table}>
        <Table.View>
          <Table.Heading title="LRM" />
          <Table.Data rows={data || []} columns={columns} isLoading={isQueryLoading} />
        </Table.View>
      </Table.Root>
    </Container>
  )
}
