export const ACTIVE = 'active'
export const BUYER = 'buyer'
export const CASHOFFER = 'cc_cash_offer'
export const CERTIFIED = 'certified'
export const DECLINED = 'declined'
export const DEFAULT_STRING = '---'
export const ELITE = 'elite'
export const ENROLLED = 'enrolled'
export const ESCROW = 'escrow'
export const MORTGAGE = 'mortgage'
export const NOT_CERTIFIED = 'not certified'
export const ONE_MINUTE = 60_000
export const PENDING = 'pending'
export const TWENTY_SECONDS = 20_000
export const PREVIOUSLY_ENROLLED = 'previously enrolled'
export const SELECTED = 'selected'
export const SELLER = 'seller'
export const TIER = 'tier'
export const TRADEIN = 'cc_trade_in'
export const UNENROLLED = 'unenrolled'
export const SOMETHING_WENT_WRONG = 'Something went wrong. Try again'
