// @ts-strict
import type { InspectionOrderResponse } from 'schema'
import { formatDate } from 'utils'
import { Model } from '../Model'
import type { ModelAttrKeys } from '../Model'

type Attributes = Partial<Omit<InspectionOrder, ModelAttrKeys>>
type Response = Potential<Partial<InspectionOrderResponse>>

export class InspectionOrder extends Model {
  modelName = 'InspectionOrder'
  id = ''
  createdAt = ''
  externalOrderId = ''
  propertyUuid = ''
  updatedAt = ''

  constructor(attributes?: Attributes) {
    super()
    Object.assign(this, attributes)
  }

  static create(response: Response): InspectionOrder {
    return new InspectionOrder({
      id: response?.id ?? '',
      createdAt: response?.createdAt ? formatDate(response?.createdAt) : '',
      externalOrderId: response?.externalOrderId ?? '',
      propertyUuid: response?.propertyUuid ?? '',
      updatedAt: response?.updatedAt ? formatDate(response?.updatedAt) : ''
    })
  }
}
