import { getLeadsSearchFilterSettings } from '../utils'

const commonSlugs = [
  'assigneeId',
  'capitalPartnerId',
  'source',
  'stage',
  'stateName',
  'tradeInPlus'
] as const

export const ccTradeInLeadSearchFilterSettings = getLeadsSearchFilterSettings(commonSlugs)
