import { ReactNode } from 'react'
import { createCtx } from 'contexts'
import { useDialog, UseDialog } from './useDialog'

export const [useDialogContext, DialogContextProvider] = createCtx<ReturnType<typeof useDialog>>({
  errorMessage:
    'useDialogContext: `context` is undefined. Seems you forgot to wrap all dialog components within `<Dialog.Root />`'
})

export type DialogProps = {
  /**
   * The content to display inside the Dialog Root
   */
  children: ReactNode
} & UseDialog

export const Dialog = ({
  children,
  initialOpen,
  isOpen,
  onOpenChange,
  shouldCloseOnOverlayClick
}: DialogProps) => {
  return (
    <DialogContextProvider
      value={useDialog({ initialOpen, isOpen, onOpenChange, shouldCloseOnOverlayClick })}
    >
      {children}
    </DialogContextProvider>
  )
}

Dialog.displayName = 'Dialog'
