// @ts-strict
import { useQuery } from 'hooks'
import { countTasks } from 'services'
import { SearchParamsSettings } from 'services/Tasks/getSearchParams'
import { TASKS_CACHE_KEY_COUNT } from '../cache'

type Props = {
  userId: Potential<string>
}

const defaultParams: SearchParamsSettings = {
  completionStatus: ['active']
}

export const useAssigneeTaskCountsController = ({ userId }: Props) => {
  const defaultOptions = {
    refetchOnWindowFocus: false,
    enabled: !!userId
  }

  const activeTasks = useQuery(
    [TASKS_CACHE_KEY_COUNT, userId, 'count-active'],
    () => countTasks({ assigneeId: userId, perPage: 1, ...defaultParams }),
    { ...defaultOptions, refetchInterval: 180_000 }
  )

  const pastDueTasks = useQuery(
    [TASKS_CACHE_KEY_COUNT, userId, 'count-past'],
    () =>
      countTasks({
        assigneeId: userId,
        pastDue: 'true',
        perPage: 1,
        ...defaultParams
      }),
    defaultOptions
  )

  const readyTasks = useQuery(
    [TASKS_CACHE_KEY_COUNT, userId, 'count-ready'],
    () =>
      countTasks({
        assigneeId: userId,
        completionStatus: ['ready'],
        perPage: 1
      }),
    { refetchInterval: 180_000 }
  )

  const activeTasksCount = activeTasks.data?.tasksCount
  const pastDueTasksCount = pastDueTasks.data?.tasksCount
  const readyTasksCount = readyTasks.data?.tasksCount

  return {
    activeTasksCount,
    pastDueTasksCount,
    readyTasksCount
  }
}
