import { cx } from 'utils'
import { ButtonV2, ButtonV2Props } from '../ButtonV2'
import styles from './SecondaryButton.module.scss'

export const SecondaryButton = ({ children, className, ...props }: ButtonV2Props) => {
  return (
    <ButtonV2
      data-testid="secondary-button"
      type="button"
      {...props}
      className={cx(className, styles.secondaryButton)}
    >
      {children}
    </ButtonV2>
  )
}
