// @ts-strict

import { getTeamMembershipsUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export type PostTeamMembershipArgs = {
  role: string
  userId: string
}

export const postTeamMembership = (teamId: string, { userId, role }: PostTeamMembershipArgs) =>
  postRequest(getTeamMembershipsUrl(), { team_id: teamId, user_id: userId, role })
