// @ts-strict
import { pluralize } from 'utils'
import styles from './SearchStatus.module.scss'

type TProps = {
  count?: number
  type: string
}

export const SearchStatusPrompt = ({ count, type }: TProps) => {
  return (
    <span className={styles.prompt} data-testid="search-counter" data-search-count={count}>
      {pluralize(count, type.toUpperCase())}
    </span>
  )
}
