import { HTMLAttributes, ReactNode } from 'react'
import { Icon, IconProps, Text } from '@foundation/components'
import { IconPathKeys } from '@foundation/icons'
import { formatDate, formatTime } from 'utils'
import * as S from './styles'

export type CommsEventRowProps = {
  'aria-expanded'?: boolean
  children: ReactNode
  'data-testid'?: string
  date: string
  iconColor?: IconProps['color']
  iconPath: IconPathKeys
  rowIndex?: number
} & Pick<HTMLAttributes<HTMLDivElement>, 'onClick'>

export const CommsEventRow = ({
  children,
  date,
  iconColor = 'neutral8',
  iconPath,
  onClick,
  rowIndex,
  'aria-expanded': ariaExpanded,
  'data-testid': dataTestId
}: CommsEventRowProps) => {
  return (
    <S.Wrapper
      onClick={onClick}
      data-testid={dataTestId}
      aria-expanded={ariaExpanded}
      alternateBackground={rowIndex !== undefined && rowIndex % 2 === 0}
    >
      <S.DetailsWrapper>
        <S.Details>{children}</S.Details>
      </S.DetailsWrapper>
      <S.Separator />
      <S.Info>
        <Icon path={iconPath} size="md" color={iconColor} />
        <S.DateWrapper>
          <Text size="xs" as="span" color="neutral9">
            {formatDate(date)}
          </Text>
          <Text size="xs" as="span" color="neutral9">
            {formatTime(date)}
          </Text>
        </S.DateWrapper>
      </S.Info>
    </S.Wrapper>
  )
}
