import { useFeatureFlags, useQuery } from 'hooks'
import { EmployeeSearchParamsSettings, fetchEmployees } from 'services'
import { getPageCount } from 'utils'
import { EMPLOYEES_CACHE_KEY } from '../index'

export const useEmployeesController = (searchParams: EmployeeSearchParamsSettings = {}) => {
  const { salesAppImpersonatingAgentTeamCoord } = useFeatureFlags([
    'sales-app-impersonating-agent-team-coord'
  ])

  const params = {
    perPage: 25,
    ...searchParams,
    userCategory: salesAppImpersonatingAgentTeamCoord.enabled
      ? searchParams.userCategory
      : 'employees'
  }

  const employeeQuery = useQuery([EMPLOYEES_CACHE_KEY, params], () => fetchEmployees(params))
  const totalCount = employeeQuery.data?.employeesCount
  const pageCount = getPageCount(totalCount, params.perPage)

  return {
    employeeQuery,
    pageCount
  }
}
