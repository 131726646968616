import { validatePresence } from 'utils'

export type TTeamFormPayload = {
  description: string | undefined
  enabled: boolean | undefined
  name: string | undefined
  type?: string
}

export const formValidations = {
  name: validatePresence
}
