import { Markdown } from 'components'
import { formatDate } from 'utils'
import styles from './ReleaseNotesPage.module.scss'

export const ReleaseNote = ({ note }) => {
  return (
    <div className={styles.note}>
      <div className={styles.header}>
        <div className={styles.title}>{note.title}</div>
        <div className={styles.date}>{formatDate(note.date)}</div>
      </div>
      <Markdown className={styles.description}>{note.description}</Markdown>
    </div>
  )
}
