import { HtmlHTMLAttributes, ReactNode } from 'react'
import { cx, showIf } from 'utils'
import styles from './Section.module.scss'
import { SectionFooter } from './SectionFooter'
import { SectionHeader } from './SectionHeader'
import { SectionTitle } from './SectionTitle'

export type SectionProps = {
  actions?: ReactNode
  borderless?: boolean
  children?: ReactNode
  className?: string
  footer?: ReactNode
  footerClassName?: string
  headerClassName?: string
  title?: string
} & HtmlHTMLAttributes<HTMLDivElement>

export const Section = ({
  className,
  title,
  actions,
  footer,
  children,
  borderless,
  headerClassName,
  footerClassName,
  ...rest
}: SectionProps) => {
  let sectionHeader
  if (title || actions) {
    sectionHeader = (
      <SectionHeader {...{ borderless }} className={headerClassName}>
        {showIf(title, <SectionTitle>{title}</SectionTitle>)}
        {actions}
      </SectionHeader>
    )
  }

  let sectionFooter
  if (footer) {
    sectionFooter = <SectionFooter className={footerClassName}>{footer}</SectionFooter>
  }

  return (
    <div data-testid="section" {...rest} className={cx(styles.section, className)}>
      {sectionHeader}
      {children ? <div className={styles.contents}>{children}</div> : null}
      {sectionFooter}
    </div>
  )
}
