import {
  AgentAgreementAttributes,
  AgentAttributes,
  AgentAttributesAndAssociations,
  AgentMetricAttributes,
  AgreementAttributes,
  ReferralPreferencesAttributes
} from 'schema'
import { agentListShape } from 'services/Agents/agentsShapes'

const agentFields: Array<keyof AgentAttributes> = [
  'eliteStatus',
  'email1',
  'email2',
  'fax',
  'firstName',
  'gender',
  'hideProfile',
  'hideFromSearch',
  'inContract',
  'lastActivityAt',
  'lastName',
  'lastSignedInAt',
  'lastTouchedAt',
  'licenseIssueDate',
  'licenseNumber',
  'licenseStatus',
  'licenseType',
  'mlsIdentifier',
  'moveSafeCertified',
  'notes',
  'officeDisplayName',
  'performanceMetrics',
  'pictureThumbUrl',
  'pplEnabledAgent',
  'recentTransactions',
  'referralStats',
  'servicesOpportunitiesCounts',
  'slug',
  'tier',
  'tradeInPlusEnabled',
  'website',
  'signedAgreement',
  'teamLeader',
  'agentTeamId'
]

const agentAgreementFields: Array<keyof AgentAgreementAttributes> = [
  'id',
  'displayFilename',
  's3Path',
  'signedAt',
  'status'
]

const agentMetricFields: Array<keyof AgentMetricAttributes> = [
  'buyerAvgPrice',
  'buyerCash',
  'buyerFha',
  'buyerReos',
  'buyerSalesList',
  'buyerShortSales',
  'buyerSolds',
  'buyerVaLoans',
  'condos',
  'createdAt',
  'sellerAttempted',
  'sellerAvgPrice',
  'sellerDom',
  'sellerGuaranteedSolds',
  'sellerPercentSold',
  'sellerSalesList',
  'sellerShortSales',
  'sellerSolds',
  'sfhs',
  'solds',
  'tics',
  'townhomes'
]

const AgreementFields: Array<keyof AgreementAttributes> = [
  'commissionSplit',
  'effectiveAt',
  'id',
  'name',
  'proType',
  'replacedAt',
  'templateId',
  'version'
]

const referralPreferencesFields: Array<keyof ReferralPreferencesAttributes> = [
  'acceptsCalendly',
  'acceptsCommercialProperties',
  'acceptsCondominiums',
  'acceptsLandDeals',
  'acceptsManufacturedHomes',
  'acceptsPrivateListings',
  'acceptsSingleFamilyHomes',
  'acceptsTennancyInCommon',
  'acceptsTownhomes',
  'acceptsWarmTransfers',
  'buyerIntroMessage',
  'calendlyOptOut',
  'calendlyUrl',
  'closingsEmailAddress',
  'closingsName',
  'closingsPhoneNumber',
  'electronicSigning',
  'excludedZipCodes',
  'leadBoostActive',
  'listingTools',
  'minPrice',
  'onVacationUntil',
  'primaryCrm',
  'requestedZipCodes',
  'safetyMeasures',
  'selectedZipCodes',
  'sellerIntroMessage',
  'specialties',
  'virtualMeetings',
  'virtualStaging',
  'virtualTours',
  'worksWithBuyers',
  'worksWithSellers'
]

const commonAgentDetailsShape: AgentAttributesAndAssociations = [
  ...agentFields,
  { agentMetric: agentMetricFields },
  { agentTeamMemberships: ['role', { agentTeam: [{ agent: agentListShape }] }] },
  { 'assignedArea:state': ['name', 'code'] },
  { 'assignedArea:metroplex': ['name', 'slug'] },
  { referralPreferences: referralPreferencesFields },
  { serviceAreas: ['name'] },
  { languages: ['name'] },
  { phoneNumbers: ['number', 'phoneType'] },
  {
    marketplaceProgramAgents: [
      'pending',
      'enrolled',
      'declined',
      'declinedAt',
      'createdAt',
      { marketplaceProgram: ['name', 'slug'] }
    ]
  },
  { investor: ['name'] },
  { state: ['code', 'name'] },
  {
    servicesOpportunities: ['productType', { 'status:servicesOpportunityStatus': ['name', 'slug'] }]
  },
  {
    'settlementAgencyTeam:team': [
      'name',
      'description',
      { teamMemberships: ['role', { user: ['name'] }] }
    ]
  },
  {
    'preferredEscrowOfficer:user': ['name']
  },
  {
    'transactionTeam:team': [
      'name',
      'description',
      { teamMemberships: ['role', { user: ['name'] }] }
    ]
  },
  {
    'asm:user': ['name']
  },
  {
    'ase:user': ['name']
  },
  { user: ['name', 'firstName', 'lastName', 'email'] }
]

export const agentDetailsShape: AgentAttributesAndAssociations = [
  ...agentFields,
  ...commonAgentDetailsShape,
  {
    agentTeam: ['name', { agent: agentListShape }]
  },
  { lastPendingAgreement: [...agentAgreementFields, { agreement: AgreementFields }] },
  { lastSignedAgreement: [...agentAgreementFields, { agreement: AgreementFields }] },
  { eligibleAgreements: [...AgreementFields] },
  { partnerProgram: ['id', 'name'] }
]

export const agentDetailsForAgentMatchingLeads: AgentAttributesAndAssociations = [
  ...agentFields,
  ...commonAgentDetailsShape,
  {
    agentTeam: ['name', { agent: agentListShape }]
  }
]
