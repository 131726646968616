// @ts-strict
import { ComponentType } from 'react'
import { ButtonProps } from '@foundation/components'
import { ActionButton } from 'components'
import { useCurrentUserContext } from 'contexts'
import { useUserController } from 'controllers'

type Props = {
  as?: ComponentType<Pick<ButtonProps, 'onClick' | 'isLoading'>>
  userId: string
}

export const UserImpersonateButton = ({ userId, as: ButtonComponent }: Props) => {
  const { userImpersonate } = useUserController(userId)
  const { canImpersonateUser } = useCurrentUserContext()

  const handleImpersonate = () => {
    userImpersonate.mutateAsync()
  }

  if (!canImpersonateUser) {
    return null
  }

  const sharedProps = {
    onClick: handleImpersonate,
    isLoading: userImpersonate.isLoading
  }

  if (ButtonComponent) {
    return <ButtonComponent {...sharedProps} />
  }

  return <ActionButton {...sharedProps}>Impersonate</ActionButton>
}
