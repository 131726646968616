import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Contents } from 'components'
import { ActivityList } from 'content/Activities'
import { LeadCreateActivityNoteButtonForm } from 'content/Lead'
import { useLeadContext } from 'contexts'
import { useLeadActivitiesController } from 'controllers'
import { useDocumentTitle } from 'hooks'

export const LeadActivityTab = () => {
  const location = useLocation()
  const { leadId } = useParams()
  const { activitiesQuery } = useLeadActivitiesController({
    leadId,
    rootPath: location.pathname
  })
  const { lead } = useLeadContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => {
    setTitle(`${lead.name} - Lead Activity`)
  }, [lead, setTitle])

  return (
    <Contents title="Activity" actions={<LeadCreateActivityNoteButtonForm lead={lead} />}>
      <ActivityList
        activities={activitiesQuery.data?.activities}
        isLoading={activitiesQuery.isLoading}
      />
    </Contents>
  )
}
