import { ReactNode } from 'react'
import { createCtx } from 'contexts'
import { CCCashOfferLead } from 'models'
import { useCCCashOfferLeadDisposition } from '..'

type TCCCashOfferLeadDispositionProviderProps = {
  children: ReactNode
  lead: CCCashOfferLead
}

export const [useCCCashOfferLeadDispositionContext, CCCashOfferLeadDispositionContextProvider] =
  createCtx<ReturnType<typeof useCCCashOfferLeadDisposition>>()

export const CCCashOfferLeadDispositionProvider = ({
  lead,
  children
}: TCCCashOfferLeadDispositionProviderProps) => {
  return (
    <CCCashOfferLeadDispositionContextProvider value={useCCCashOfferLeadDisposition(lead)}>
      {children}
    </CCCashOfferLeadDispositionContextProvider>
  )
}
