// @ts-strict
import { Block, List, TableCell as Td } from 'components'
import type { TableCellProps } from 'components'
import styles from 'content/Leads/LeadsTable/LeadsTableCells/LeadsTableCells.module.scss'
import { CCTradeInLead } from 'models'
import {
  dataOrDash,
  dateDiffInDays,
  formatBoolean,
  formatDate,
  formatMoney,
  structureAddress,
  titleize
} from 'utils'

type TProps = TableCellProps & {
  column?: string
  lead: CCTradeInLead
  testId: string
}

export const CCTradeInLeadPropertyAddress = ({ lead, testId }: TProps) => {
  const { departingMasterProperty } = lead.tradeInLead || {}
  const { street, cityState } = structureAddress(departingMasterProperty?.displayAddress)

  return (
    <Td data-testid={testId}>
      <List>
        {street}
        {dataOrDash(cityState)}
      </List>
    </Td>
  )
}
export const CCTradeInLeadTradeInPlus = ({ lead, testId, column, ...rest }: TProps) => {
  const { tradeInPlus } = lead.tradeInLead || {}
  return (
    <Td data-testid={testId} {...rest}>
      {formatBoolean(tradeInPlus)}
    </Td>
  )
}
export const CCTradeInLeadHoaCell = ({ lead, testId }: TProps) => {
  const { departingPropertyHomeownerAssociation } = lead.tradeInLead || {}
  return (
    <Td data-testid={testId}>
      <Block
        allowTitleOverflow
        title={dataOrDash(formatBoolean(departingPropertyHomeownerAssociation))}
      />
    </Td>
  )
}

export const CCTradeInLeadSolarPanelsCell = ({ lead, testId }: TProps) => {
  const { departingPropertySolarPanels } = lead.tradeInLead || {}
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(titleize(departingPropertySolarPanels))} />
    </Td>
  )
}

export const CCTradeInLeadFundingAmountCell = ({ lead, testId }: TProps) => {
  const { dpFundingAmount } = lead.tradeInLead || {}

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(formatMoney(dpFundingAmount))} />
    </Td>
  )
}

export const CCTradeInLeadDRPurchaseCoeDateCell = ({ lead, testId }: TProps) => {
  const { departingPropertyCloseOfPurchaseEscrowAt } = lead.tradeInLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(departingPropertyCloseOfPurchaseEscrowAt))} />
    </Td>
  )
}

export const CCTradeInLeadIRPurchaseCOEDateCell = ({ lead, testId }: TProps) => {
  const { incomingPropertyCloseOfEscrowAt } = lead.tradeInLead || {}

  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(incomingPropertyCloseOfEscrowAt))} />
    </Td>
  )
}

export const CCTradeInLeadDRSaleActualCOEDateCell = ({ lead, testId }: TProps) => {
  const { departingPropertyCloseOfSaleEscrowAt } = lead.tradeInLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(departingPropertyCloseOfSaleEscrowAt))} />
    </Td>
  )
}

export const CCTradeInLeadDRSaleOpenEscrowDateCell = ({ lead, testId }: TProps) => {
  const { departingPropertyOpenedSaleEscrowAt } = lead.tradeInLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(departingPropertyOpenedSaleEscrowAt))} />
    </Td>
  )
}

export const CCTradeInLeadDRPurchaseOpenEscrowDateCell = ({ lead, testId }: TProps) => {
  const { departingPropertyOpenedPurchasedEscrowAt } = lead.tradeInLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(departingPropertyOpenedPurchasedEscrowAt))} />
    </Td>
  )
}

export const CCTradeInLeadFundingDateCell = ({ lead, testId }: TProps) => {
  const { dpActualPurchaseFundedDate } = lead?.tradeInLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(dpActualPurchaseFundedDate))} />
    </Td>
  )
}

export const CCTradeInLeadListedDateCell = ({ lead, testId }: TProps) => {
  const { departingPropertyListedAt } = lead.tradeInLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(departingPropertyListedAt))} />
    </Td>
  )
}

export const CCTradeInLeadFundingFacilityCell = ({ lead, testId }: TProps) => {
  const { capitalPartner } = lead.tradeInProviderLead?.providerLeadCapitalPartner || {}
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(titleize(capitalPartner?.fundingFacility))} />
    </Td>
  )
}

export const CCTradeInLeadDaysOwnedCell = ({ lead, testId }: TProps) => {
  const purchaseEscrowAt = lead.tradeInLead?.departingPropertyCloseOfPurchaseEscrowAt || ''
  const saleEscrowAt = lead.tradeInLead?.departingPropertyCloseOfSaleEscrowAt || new Date()
  const diffInDays = dateDiffInDays(saleEscrowAt, purchaseEscrowAt)
  const daysOwned = diffInDays > 0 ? diffInDays : ''
  const className = daysOwned >= 45 ? (daysOwned >= 60 ? 'danger' : 'warning') : ''

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(daysOwned)} className={styles[className]} />
    </Td>
  )
}

export const CCTradeInLeadDaystoListingCell = ({ lead, testId }: TProps) => {
  const purchaseEscrowAt = lead.tradeInLead?.departingPropertyCloseOfPurchaseEscrowAt || ''
  const listedAt = lead.tradeInLead?.departingPropertyListedAt || new Date()
  const diffInDays = dateDiffInDays(listedAt, purchaseEscrowAt)
  const daystoListing = diffInDays > 0 ? diffInDays : ''

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(daystoListing)} />
    </Td>
  )
}

export const CCTradeInLeadDaysOnMarketCell = ({ lead, testId }: TProps) => {
  const listedAt = lead.tradeInLead?.departingPropertyListedAt || ''
  const saleEscrowAt = lead.tradeInLead?.departingPropertyCloseOfSaleEscrowAt || new Date()
  const diffInDays = dateDiffInDays(saleEscrowAt, listedAt)
  const daysOnMarket = diffInDays > 0 ? diffInDays : ''

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(daysOnMarket)} />
    </Td>
  )
}

export const CCTradeInLeadDaysUntilFundingCell = ({ lead, testId }: TProps) => {
  const fundingDate = lead.tradeInLead?.dpActualPurchaseFundedDate || ''

  const today = formatDate(new Date(), 'yyyy-MM-dd')
  const diffInDays = dateDiffInDays(fundingDate, today)
  const result = diffInDays >= 0 ? String(diffInDays) : null

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(result)} />
    </Td>
  )
}

export const CCTradeInLeadDaysSinceFundingCell = ({ lead, testId }: TProps) => {
  const fundingDate = lead.tradeInLead?.dpActualPurchaseFundedDate || ''

  const diffInDays = dateDiffInDays(new Date(), fundingDate)
  const result = diffInDays > 0 ? diffInDays : ''

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(result)} />
    </Td>
  )
}

export const CCTradeInLeadCapitalMarketsFacilityCell = ({ lead, testId }: TProps) => {
  const { providerLeadCapitalPartner } = lead.tradeInProviderLead || {}
  return (
    <Td data-testid={testId}>
      <Block
        allowTitleOverflow
        title={dataOrDash(providerLeadCapitalPartner?.capitalPartner?.name)}
      />
    </Td>
  )
}
