import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './SectionTitle.module.scss'

type Props = {
  children: ReactNode
  className?: string
  style?: React.CSSProperties
}

export const SectionTitle = ({ className, children, style }: Props) => {
  return (
    <h3
      data-testid="sectionTitle"
      style={style}
      className={cx(styles.title, className, 'section-title')}
    >
      {children}
    </h3>
  )
}
