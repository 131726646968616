// @ts-strict

import { Button } from '@foundation/components'
import {
  AgentsAutoComplete,
  Form,
  FormActions,
  FormField,
  Section,
  Sections,
  Select
} from 'components'
import { TLeadUserTypeServerSlug } from 'lookups'
import { isCCCashOfferLead, Lead, Order } from 'models'
import { BuyerLeadFormFields } from './AttachLeadUserTypeFormFields/BuyerLeadFormFields'
import { CCCashOfferLeadFormFields } from './AttachLeadUserTypeFormFields/CCCashOfferLeadFormFields'
import { CCTradeInLeadFormFields } from './AttachLeadUserTypeFormFields/CCTradeInLeadFormFields'
import { SellerLeadFormFields } from './AttachLeadUserTypeFormFields/SellerLeadFormFields'
import { useAttachLeadCreateLeadTab } from './useAttachLeadCreateLeadTab'

type TAttachLeadCreateLeadTabProps = {
  order: Order
  selectedLead: Lead
}

const formFieldsComponents = {
  cc_cash_offer: CCCashOfferLeadFormFields,
  cc_hl_simple_sale: CCTradeInLeadFormFields,
  cc_trade_in: CCTradeInLeadFormFields,
  seller: SellerLeadFormFields,
  buyer: BuyerLeadFormFields
}

const programLeadMap = {
  cc_trade_in: 'tradeIn',
  cc_cash_offer: 'cashOffer',
  cc_hl_simple_sale: 'hlss'
}

export const AttachLeadCreateLeadTab = ({ selectedLead, order }: TAttachLeadCreateLeadTabProps) => {
  const {
    errorMessage,
    fieldSettings,
    fieldValues,
    formTitle,
    handleSubmit,
    isLoading,
    isValid,
    leadPropertyAddress,
    leadPropertyTypes,
    leadTimelineValues,
    leadUserTypeOptions,
    leadUserTypeValidationMessage,
    remove,
    set
  } = useAttachLeadCreateLeadTab({ selectedLead, order })

  const FormFieldsComponent = formFieldsComponents[fieldValues.leadUserType]
  const program = programLeadMap[fieldValues.leadUserType]
  const showReferringAgent =
    fieldValues.leadUserType === 'mortgage'
      ? !!fieldSettings.referringAgent && !isCCCashOfferLead(selectedLead)
      : !!fieldSettings.referringAgent

  return (
    <Form onSubmit={handleSubmit}>
      <Sections>
        <Section>
          <FormField title="Lead Type" required={true} hint={leadUserTypeValidationMessage}>
            <Select
              addBlank
              data-testid="leadUserTypeSelect"
              value={fieldValues.leadUserType}
              options={leadUserTypeOptions}
              onChange={e => set('leadUserType', e as TLeadUserTypeServerSlug)}
              disabled={!leadUserTypeOptions.length}
            />
          </FormField>
        </Section>
        {showReferringAgent && (
          <Section>
            <FormField title="Referring Agent" required={fieldSettings.referringAgent.isRequired}>
              <AgentsAutoComplete
                data-testid="referringAgentSelect"
                value={fieldValues.referringAgent?.title}
                searchParams={{ [program]: 'enrolled' }}
                onSelect={item => set('referringAgent', item)}
                onClear={() => remove('referringAgent')}
              />
            </FormField>
          </Section>
        )}
      </Sections>
      {FormFieldsComponent ? (
        <FormFieldsComponent
          {...{
            formTitle,
            fieldSettings,
            fieldValues,
            leadPropertyAddress,
            leadPropertyTypes,
            leadTimelineValues,
            remove,
            set
          }}
        />
      ) : null}
      <FormActions error={errorMessage}>
        <Button isDisabled={!isValid} isLoading={isLoading}>
          Submit
        </Button>
      </FormActions>
    </Form>
  )
}
