import { Listing } from 'models'
import { getListingsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams } from './getSearchParams'

export const fetchListings = async (settings: {}) => {
  const url = getListingsUrl({
    searchParams: getSearchParams(settings)
  })
  const { data } = await getRequest(url)
  const listings = await deserializeAsync(data, { transform: Listing.create })
  return {
    listings,
    totalCount: data.meta.total_listings
  }
}
