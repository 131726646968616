// @ts-strict
import type { InspectionDeficiencyResponse, InspectionResponse } from 'schema'
import { formatDate, titleize } from 'utils'

type Attributes = Partial<InspectionDeficiency>
type Response = Potential<Partial<InspectionDeficiencyResponse>>

export class InspectionDeficiency {
  modelName = 'InspectionDeficiency'
  id = ''
  category: SlugOption<InspectionDeficiencyResponse['category']> = { name: '', value: '' }
  createdAt = ''
  detail = ''
  escalated: InspectionDeficiencyResponse['escalated'] = null
  externalCatalogId = ''
  externalId = ''
  severity: SlugOption<InspectionDeficiencyResponse['severity']> = { name: '', value: '' }
  title = ''
  updatedAt = ''
  reportInspectionTypes: SlugOption<InspectionResponse['inspectionType']>[] = []
  files: SlugOption[] = []
  constructor(attributes?: Attributes) {
    Object.assign(this, attributes)
  }

  static create(response: Response): InspectionDeficiency {
    const category = response?.category
      ? { name: titleize(response.category), value: response.category }
      : undefined
    const reportInspectionTypes = response?.inspectionReport?.inspectionTypes.map(el => ({
      name: titleize(el),
      value: el
    }))
    return new InspectionDeficiency({
      id: response?.id,
      category,
      detail: response?.detail,
      externalCatalogId: response?.externalCatalogId,
      externalId: response?.externalId,
      escalated: response?.escalated,
      severity: response?.severity
        ? { name: titleize(response.severity), value: response.severity }
        : undefined,
      title: response?.title,
      createdAt: response?.createdAt ? formatDate(response?.createdAt) : undefined,
      updatedAt: response?.updatedAt ? formatDate(response?.updatedAt) : undefined,
      reportInspectionTypes,
      files: response?.inspectionDeficiencyFiles?.map(el => ({
        value: el.file?.id,
        name: `${reportInspectionTypes?.[0].name} - ${category?.name}`
      }))
    })
  }
}
