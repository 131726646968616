import { LeadWarmTransfer } from 'models'
import { getHapiRequestParams, LeadWarmTransferSchema } from 'schema'
import { getLeadWarmTransferUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const LEAD_WARM_TRANSFER_RESPONSE_SHAPE: LeadWarmTransferSchema = {
  leadWarmTransfer: [
    'acceptedAt',
    'canceledAt',
    'completedAt',
    'declinedAt',
    'declinedReason',
    'pin',
    'smsSentAt',
    'status',
    'transferredAt',
    'updatedAt',
    'createdAt',
    'leadRoutingEnabled',
    {
      agent: ['firstName', 'lastName', 'pictureThumbUrl', 'officeDisplayName', { state: ['code'] }]
    },
    {
      'workedByAgent:agent': ['firstName', 'lastName', 'pictureThumbUrl', 'officeDisplayName']
    }
  ]
}

export const fetchLeadWarmTransfer = async (id: string): Promise<LeadWarmTransfer[]> => {
  const searchParams = getHapiRequestParams(LEAD_WARM_TRANSFER_RESPONSE_SHAPE)
  const url = getLeadWarmTransferUrl({ id, searchParams, action: 'lead-warm-transfers' })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: LeadWarmTransfer.create })
}
