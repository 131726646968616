import { DEFAULT_STRING } from '../constants'

export const formatNumber = (
  num: Potential<string | number>,
  style: string = 'long',
  defaultValue: any = DEFAULT_STRING
) => {
  if (num === undefined || String(Number(num)) !== String(num)) {
    return defaultValue
  }
  if (style === 'short') {
    return Number(num).toLocaleString('en-US', {
      minimumFractionDigits: 0
    })
  }
  return Number(num).toLocaleString('en-US', {
    minimumFractionDigits: 0
  })
}
