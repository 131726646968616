import merge from 'deepmerge'
import { getLead, Lead } from 'models'
import {
  CCTradeInLeadSchema,
  getHapiRequestParams,
  LeadAttributesWithoutId,
  OrderAttributes,
  TradeInLeadAttributes
} from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchCCTradeInLead = async (id: string): Promise<Lead> => {
  const leadFields: Array<LeadAttributesWithoutId> = [
    'calculatedValue',
    'callReview',
    'createdAt',
    'email',
    'emailAlt',
    'fullAddress',
    'furthestStage',
    'humanTimeline',
    'isDeal',
    'lastCallDate',
    'lastStageUpdate',
    'latitude',
    'longitude',
    'name',
    'notesToAgents',
    'partnerName',
    'phoneAlt',
    'price',
    'propertyType',
    'qualificationTalkedToClient',
    'revenueRecognitionDate',
    'salesNotes',
    'source',
    'sourcePageType',
    'stage',
    'userType',
    'dialerQueueStatus'
  ]

  const orderFields: Array<keyof OrderAttributes> = [
    'createdAt',
    'ownedBusinessUnit',
    'source',
    'stage',
    'updatedAt'
  ]

  const tradeInLeadFields: Array<keyof TradeInLeadAttributes> = [
    'actualCashCloseFee',
    'actualLoanAmount',
    'agentValuation',
    'avmDate',
    'createdAt',
    'departingMasterProperty',
    'departingPropertyActualDom',
    'departingPropertyActualSalePrice',
    'departingPropertyAgentValuationReceivedAt',
    'departingPropertyAmountOverGuaranteedPrice',
    'departingPropertyBuyersAgentCommission',
    'departingPropertyCashCloseFee',
    'departingPropertyClientValuationReceivedAt',
    'departingPropertyCloseOfPurchaseEscrowAt',
    'departingPropertyCloseOfSaleEscrowAt',
    'departingPropertyCondition',
    'departingPropertyDaysOfOwnership',
    'departingPropertyExpectedListingPrice',
    'departingPropertyExpectedRevenue',
    'departingPropertyExternalListingDate',
    'departingPropertyExternalListingStatus',
    'departingPropertyExternalListingStatusDate',
    'departingPropertyExternalLoanPayoff',
    'departingPropertyFlags',
    'departingPropertyFundsWiredAmount',
    'departingPropertyFundsWiredToClientAt',
    'departingPropertyFundsWiredToHlAt',
    'departingPropertyGuaranteedPrice',
    'departingPropertyHlOwnedAt',
    'departingPropertyHomeownerAssociation',
    'departingPropertyInspectedAt',
    'inspectionCompletedAt',
    'approvalType',
    'departingPropertyInspectionScheduledAt',
    'departingPropertyListedAt',
    'departingPropertyListingPrepFee',
    'departingPropertyListingPrice',
    'departingPropertyLoanPayoff',
    'departingPropertyLocalTransferTax',
    'departingPropertyMarketDom',
    'departingPropertyMarketIndex',
    'departingPropertyMarketInventoryCount',
    'departingPropertyMeetsPropertyGuidelines',
    'departingPropertyOfferAcceptedAt',
    'departingPropertyOfferCount',
    'departingPropertyOpenedPurchasedEscrowAt',
    'departingPropertyOpenedSaleEscrowAt',
    'departingPropertyOwnershipExpenses',
    'departingPropertyPercentEquity',
    'departingPropertyPreliminaryTitleReviewed',
    'departingPropertyPreliminaryValuation',
    'departingPropertyPriceDelta',
    'departingPropertyQualificationReason',
    'departingPropertyQualified',
    'departingPropertyQualifiedAt',
    'departingPropertyRepairCosts',
    'departingPropertySellersAgentCommission',
    'departingPropertySolarLoanBalance',
    'departingPropertySolarPanels',
    'departingPropertyTransactionCosts',
    'departingPropertyUuid',
    'departingPropertyValuation',
    'dpActualAgentFees',
    'dpActualFeeFirstThirtyDays',
    'dpActualFeeProratedAfterThirtyDays',
    'dpActualPropertyTaxes',
    'dpActualHoaDocumentFees',
    'dpActualPropertyInsurance',
    'dpActualHoaDocumentFees',
    'dpActualSolarLoanBalance',
    'dpActualOtherExpenses',
    'dpActualHoaTransferFees',
    'dpActualHoaDues',
    'dpActualUtilities',
    'dpActualCityTransferTaxes',
    'dpActualHomeWarrantyPolicy',
    'dpActualAdditionalRepairs',
    'dpActualCreditsGivenToBuyer',
    'dpActualCreditsGivenToSeller',
    'dpActualServicesBilledFromEscrow',
    'dpActualStagingCosts',
    'dpActualCleaningCosts',
    'dpActualPaintingCosts',
    'dpActualFlooringCosts',
    'dpActualRepairsCosts',
    'dpActualOtherListingCosts',
    'dpActualInspection',
    'dpActualNewHomePurchaseEquityHl',
    'dpActualPurchaseFundedDate',
    'dpActualTotalListingPrepWork',
    'dpActualLoanPayoff',
    'dpActualTotalPropertyOwnership',
    'dpActualTotalRepairCostsAndValuationAdjustments',
    'dpActualTotalTransactionCosts',
    'dpActualClosingCosts',
    'dpActualSipCosts',
    'dpBorrowingBase',
    'dpBuyersAgentCommissionRate',
    'dpCapitalDaysOutstanding',
    'dpDaysSellerInPossession',
    'dpEstimatedDaysOfWork',
    'dpEstimatedListToPending',
    'dpEstimatedSaleEscrow',
    'dpFundingAmount',
    'dpGpPercentageOfValuation',
    'dpHlEquity',
    'dpLocalTransferTaxRate',
    'dpPropertyType',
    'dpSellerCredits',
    'dpSellerVacateDate',
    'dpSellersAgentCommissionRate',
    'dpTargetAnnualPropertyTaxRate',
    'dpTargetClientClosingCosts',
    'dpTargetNewHomePurchaseEquity',
    'dpTargetPurchaseCoeDate',
    'dpTargetSellCoeDate',
    'dpTargetTotalCosts',
    'dpTotalActualCosts',
    'dpTotalEquity',
    'dpTotalEstimatedDaysOfOwnership',
    'dpTotalProceedsFromFinalSale',
    'dpTargetTradeInFee',
    'dpTargetTradeInFeeRate',
    'dpTotalAgentCommission',
    'dpUpfrontProceedsToClient',
    'dpAgentAfterRepairValue',
    'dpAgentAfterRepairValueDate',
    'dpAnalystValuation',
    'dpAnalystValuationDate',
    'dpAnalystAfterRepairValue',
    'dpAnalystAfterRepairValueDate',
    'hlcsAttached',
    'incomingMasterProperty',
    'incomingPropertyCloseOfEscrowAt',
    'incomingPropertyEstimatedMoveInDate',
    'incomingPropertyFoundAt',
    'incomingPropertyInspectedAt',
    'incomingPropertyLoanApprovedAt',
    'incomingPropertyLoanClosedAt',
    'incomingPropertyOfferAcceptedAt',
    'incomingPropertyOfferMadeAt',
    'incomingPropertyRpaSignedAt',
    'incomingPropertySalePrice',
    'incomingPropertyUuid',
    'incomingPropertyValuation',
    'netProceedsRequired',
    'purchasingPower',
    'simpleSale',
    'tradeInPlus',
    'twoCheckHlSimpleSale',
    'updatedAt'
  ]

  const leadResponseShape: CCTradeInLeadSchema = {
    lead: [
      {
        client: [
          {
            user: ['firstName', 'lastName', 'email']
          },
          'softCreditCheckReportLink',
          'softCreditCheckIntelligence'
        ]
      },
      { leadUsers: ['role', { user: ['name'] }] },
      {
        order: [
          ...orderFields,
          {
            leads: [
              {
                'lenderProviderLeads:providerLead': [
                  {
                    'providable:lenderLead': [
                      'eaveLoanApplicationId',
                      'closeOfEscrowOn',
                      {
                        loanOfficer: ['companyName', { phoneNumbers: ['number', 'phoneType'] }]
                      },
                      {
                        lender: ['slug']
                      }
                    ]
                  }
                ]
              },
              {
                'escrowOfficeProviderLeads:providerLead': [
                  { 'providable:escrowOfficeLead': ['netEquity', 'orderClosingOn'] }
                ]
              }
            ]
          }
        ]
      },
      { phoneNumbers: ['number', 'phoneType'] },
      {
        'referringOwner:agent': [
          'email1',
          'email2',
          'firstName',
          'lastName',
          'licenseNumber',
          'officeDisplayName',
          { agentTeam: ['name'] },
          { agentTeamMemberships: [{ agentTeam: ['name'] }] },
          { phoneNumbers: ['number', 'phoneType'] }
        ]
      },
      {
        userInvitations: ['email', 'name', 'slug']
      }
    ]
  }

  // need to split the request up further so that the fetch request URL doesn't get too large
  const secondaryLeadResponseShape: CCTradeInLeadSchema = {
    lead: [
      {
        'tradeInProviderLead:providerLead': [
          'stage',
          'reasonForFail',
          { providerLeadCapitalPartner: [{ capitalPartner: ['name', 'fundingFacility'] }] },
          {
            'providable:tradeInLead': [
              ...tradeInLeadFields,
              { 'propertyValuation:corbetsPropertyValuation': ['spreadsheetUrl'] },
              {
                'departingPropertySellerAgent:agent': [
                  'email1',
                  'email2',
                  'firstName',
                  'lastName',
                  'licenseNumber',
                  { agentTeam: ['name'] },
                  { agentTeamMemberships: [{ agentTeam: ['name'] }] },
                  { phoneNumbers: ['number', 'phoneType'] },
                  { 'transactionTeam:team': ['id'] }
                ]
              },
              {
                'incomingPropertyBuyerAgent:agent': [
                  'email1',
                  'email2',
                  'firstName',
                  'lastName',
                  'licenseNumber',
                  { agentTeam: ['name'] },
                  { agentTeamMemberships: [{ agentTeam: ['name'] }] },
                  { phoneNumbers: ['number', 'phoneType'] }
                ]
              }
            ]
          },
          { providerLeadExpenses: ['slug', 'category', 'description', 'value'] }
        ]
      }
    ]
  }

  const searchParams = getHapiRequestParams(leadResponseShape)
  const url = getLeadsUrl({ id, searchParams })
  const primaryResponse = await getRequest(url)

  // need to split the request up further so that the fetch request URL doesn't get too large
  const secondarySearchParams = getHapiRequestParams(secondaryLeadResponseShape)
  const secondaryUrl = getLeadsUrl({ id, searchParams: secondarySearchParams })
  const secondaryResponse = await getRequest(secondaryUrl)

  const responseData = merge(primaryResponse.data, secondaryResponse.data)

  const leadParams: CCTradeInLeadSchema = { lead: [...leadFields] }
  const leadSearchParams = getHapiRequestParams(leadParams)
  const leadUrl = getLeadsUrl({ id, searchParams: leadSearchParams })
  const leadResponse = await getRequest(leadUrl)

  return await deserializeAsync(merge(leadResponse.data, responseData as any), {
    transform: getLead
  })
}
