// @ts-strict
import { ReactNode } from 'react'
import { createCtx } from 'contexts/utils'
import { useLeadGroupableDefinitionController } from 'controllers'
import { LeadGroupableDefinitionType } from 'models'

export const [useLeadGroupableDefinitionContext, LeadGroupableDefinitionContextProvider] =
  createCtx<ReturnType<typeof useLeadGroupableDefinitionController>>()

type Props = {
  children: ReactNode
  leadGroupableDefinitionId: string
  leadGroupableDefinitionType: LeadGroupableDefinitionType
  leadGroupableId: string
  leadGroupableType: string
}

export const LeadGroupableDefinitionProvider = ({
  children,
  leadGroupableType,
  leadGroupableId,
  leadGroupableDefinitionId,
  leadGroupableDefinitionType
}: Props) => {
  return (
    <LeadGroupableDefinitionContextProvider
      value={useLeadGroupableDefinitionController(
        leadGroupableType,
        leadGroupableId,
        leadGroupableDefinitionType,
        leadGroupableDefinitionId
      )}
    >
      {children}
    </LeadGroupableDefinitionContextProvider>
  )
}
