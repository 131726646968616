import { useEmployeeUsers, useLookupsController } from 'controllers'
import { TSearchFilterSetting, useAgentPartnerships, useAssignedAreas } from 'lookups'
import { agentsColumnsSettings } from 'lookups/lookupColumns/listColumns/settings'
import { titleize } from 'utils'
import { buildSearchFilters, findFilterBySlug } from '../utils'

const settings: TSearchFilterSetting[] = [
  {
    slug: 'tierStatus',
    name: 'Tier Status',
    optionsKey: 'tierStatus',
    getDisplayValueFnName: 'getTierStatusNameFromId'
  },
  {
    slug: 'assigneeId',
    name: 'Assigned to',
    getDisplayValueFnName: 'getUserName'
  },
  {
    slug: 'preferredEscrowOfficerUserId',
    name: 'Preferred Escrow Officer',
    getDisplayValueFnName: 'getUserName'
  },
  { slug: 'assignedAreaId', name: 'Assigned Area', getDisplayValueFnName: 'getAssignedAreaName' },
  { slug: 'eliteStatus', name: 'Elite Status', getDisplayValueFnName: 'getEliteStatusName' },
  {
    slug: 'inContract',
    name: 'In Contract'
  },
  { slug: 'pitchOppProduct', name: 'Pitch Status Program' },
  {
    slug: 'enrollmentProgram',
    name: 'Enrollment Program',
    getDisplayValueFnName: 'titleize'
  },
  {
    slug: 'enrollmentStatus',
    name: 'Enrollment Status',
    getDisplayValueFnName: 'titleize'
  },
  {
    slug: 'agentPartnership',
    name: 'Partnership',
    getDisplayValueFnName: 'getPartnershipName'
  },
  {
    slug: 'metricsTimeRange',
    name: 'Metrics Time Range',
    getDisplayValueFnName: 'titleize'
  },
  ...agentsColumnsSettings
    .filter(el => Object.hasOwn(el, 'sort'))
    .map(el => ({ name: el.name, slug: el.value }))
]

export const useAgentsSearchFilter = () => {
  const [agentPlatformTiers] = useLookupsController('agent_platform_tiers')
  const { getUserName } = useEmployeeUsers()
  const { getAssignedAreaName } = useAssignedAreas()
  const { getPartnershipName } = useAgentPartnerships()

  const getTierStatusNameFromId = (id: string) => {
    return agentPlatformTiers.find(item => item.slug === id)?.name
  }

  const getEliteStatusName = (value: string) => {
    if (value === 'eligible') {
      return 'Prospect'
    }
    return titleize(value)
  }

  const optionLoaders = {
    tierStatus: agentPlatformTiers
  }

  const displayValueFnLoaders = {
    titleize,
    getTierStatusNameFromId,
    getUserName,
    getAssignedAreaName,
    getEliteStatusName,
    getPartnershipName
  }

  const agentsFilters = buildSearchFilters(settings, {
    optionLoaders,
    displayValueFnLoaders
  })

  return { agentsFilters, findFilterBySlug: findFilterBySlug(agentsFilters) }
}
