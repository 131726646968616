import { createContext, ReactNode, useContext } from 'react'
import { TasksContextType } from 'contexts/types'
import { TaskAttachable, useTasksController } from 'controllers'

type TasksProviderProps = {
  allLeadTasksEnabled?: boolean
  attachable?: TaskAttachable
  children: ReactNode
  params?: Record<string, any>
}

export const TasksContext = createContext<TasksContextType | null>(null)
export const useTasksContext = () => {
  const context = useContext(TasksContext)
  if (!context) {
    throw new Error('useTasksContext must be called within an TasksProvider.')
  }
  return context
}

export const TasksProvider = ({
  children,
  params,
  attachable,
  allLeadTasksEnabled
}: TasksProviderProps) => {
  return (
    <TasksContext.Provider
      value={useTasksController({
        enabled: !!params,
        params,
        attachable,
        allLeadTasksEnabled
      })}
    >
      {children}
    </TasksContext.Provider>
  )
}
