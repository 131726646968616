import { useEffect, useState } from 'react'
import { cx, formatDuration } from 'utils'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { RangeInput } from '../RangeInput'
import styles from './AudioPlayer.module.scss'

type TProps = {
  className?: string
  disabled?: boolean
  src: string
}

/**
 * This component is deprecated. Please, use the AudioPlayer from Foundation
 */
export const AudioPlayer = ({ src, disabled = false, className }: TProps) => {
  const [progress, setProgress] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [loading, setLoading] = useState(false)
  const [audio, setAudio] = useState<Partial<HTMLAudioElement>>({})

  // define audio event handlers / listeners
  const handleLoadingDone = () => {
    setLoading(false)
  }

  const handleProgressUpdate = e => {
    setProgress((e.currentTarget.currentTime / e.currentTarget.duration) * 100 || 0)
  }

  const handleAudioToggle = e => {
    setPlaying(!e.currentTarget.paused)
  }

  useEffect(() => {
    let audioObj: HTMLAudioElement | null = new Audio(src)
    setLoading(true)
    audioObj.addEventListener('canplaythrough', handleLoadingDone)
    audioObj.addEventListener('timeupdate', handleProgressUpdate)
    audioObj.addEventListener('play', handleAudioToggle)
    audioObj.addEventListener('pause', handleAudioToggle)
    setAudio(audioObj)

    return () => {
      audioObj?.removeEventListener('canplaythrough', handleLoadingDone)
      audioObj?.removeEventListener('timeupdate', handleProgressUpdate)
      audioObj?.removeEventListener('play', handleAudioToggle)
      audioObj?.removeEventListener('pause', handleAudioToggle)

      // stop audio from playing on unmount
      audioObj?.pause()
      audioObj = null
    }
  }, [src])

  const togglePlay = () => {
    if (playing) {
      audio.pause()
    } else {
      audio.play()
    }

    setPlaying(!playing)
  }

  const seek = e => {
    if (parseInt(e.currentTarget.value, 10)) {
      audio.currentTime = audio.duration * (parseInt(e.currentTarget.value, 10) / 100)
    } else {
      audio.currentTime = 0
    }
  }

  const ButtonIcon = Icon[playing ? 'Pause' : 'Play']

  return (
    <div
      className={cx(styles.audioPlayer, className, disabled || loading ? styles.disabled : null)}
    >
      <Button onClick={togglePlay} className={styles.playButton}>
        <ButtonIcon className={styles.toggle} />
        <Icon.CircleNotch className={cx(styles.spinner, playing ? styles.on : null)} />
      </Button>
      <RangeInput value={progress} onChange={seek} className={styles.progressBar} />
      {audio.duration !== Infinity ? (
        <div className={styles.progressDisplay}>
          {formatDuration((audio && audio.currentTime) || 0, '0:00')}
          {` / `}
          {formatDuration((audio && audio.duration) || 0, '0:00')}
        </div>
      ) : null}
    </div>
  )
}
