// @ts-strict
import { Link } from 'react-router-dom'
import { Block, TableCellProps, TableCell as Td } from 'components'
import { AgentPrograms, AgentServicesOpportunitiesSelector } from 'content/Agent'
import { AgentAvatar } from 'content/Agent/AgentAvatar'
import { AgentLinkWithMenuCard } from 'content/Agent/AgentDetailsMenuCard'
import { useAgentMetricsContext } from 'contexts'
import { useFeatureFlags, useQueryClient } from 'hooks'
import { Agent } from 'models'
import { PlatformMetricsKeys } from 'schema/AgentPlatformMetricsSchema'
import { dataOrDash, formatDate, formatTime } from 'utils'
import styles from './AgentsTable.module.scss'

type Props<T> = TableCellProps & {
  column?: string
  testId?: string
  value: T
}

const AgentMetricDisplayValue = ({
  agent,
  metricKey,
  linkTo
}: {
  agent: Agent
  linkTo?: string
  metricKey: PlatformMetricsKeys
}) => {
  const { agentMetricsTimeRange } = useAgentMetricsContext()

  const displayValue = agent?.platformMetrics?.[metricKey]?.[agentMetricsTimeRange]
    ? dataOrDash(agent?.platformMetrics?.[metricKey]?.[agentMetricsTimeRange]?.toString())
    : dataOrDash(undefined)

  const metricsUpdatedString = `Metrics were last updated on ${formatDate(
    agent?.platformMetrics?.lastUpdatedAt,
    'short'
  )}.`

  const shouldHaveLink = linkTo && !!agent?.platformMetrics?.[metricKey]?.[agentMetricsTimeRange]

  const blockTitle = shouldHaveLink ? (
    <Link title={metricsUpdatedString} to={`${linkTo}`}>
      {displayValue}
    </Link>
  ) : (
    <span title={metricsUpdatedString}>{displayValue}</span>
  )

  return <Block title={blockTitle} />
}

export function AgentsListAgentCell<T extends Agent>({ value, testId }: Props<T>) {
  const { salesAppAgentDetailsComponent } = useFeatureFlags(['sales-app-agent-details-component'])
  const subtitle = [value.officeDisplayName, value.state?.code].filter(Boolean).join(', ')

  return (
    <Td className={styles.agentCell} data-testid={testId} fixed>
      {salesAppAgentDetailsComponent?.enabled ? (
        <Block
          allowTitleOverflow
          title={<AgentLinkWithMenuCard agent={value} />}
          subtitle={subtitle}
          image={<AgentAvatar agent={value} />}
        />
      ) : (
        <Block
          className={styles.agentName}
          title={<Link to={`/agents/${value.id}`}>{value.name}</Link>}
          subtitle={subtitle}
          image={<AgentAvatar agent={value} />}
        />
      )}
    </Td>
  )
}

export function AgentProgramsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentPrograms agent={value} />
    </Td>
  )
}

export function AgentTeamNameCell<T extends Agent>({ value, testId }: Props<T>) {
  return <Td data-testid={testId}>{value.agentTeamName}</Td>
}

export function AgentPreferredEscrowOfficerCell<T extends Agent>({ value, testId }: Props<T>) {
  const preferredEscrowOfficer = value.preferredEscrowOfficer
  return (
    <Td data-testid={testId}>
      {!!preferredEscrowOfficer && (
        <Link to={`/admin/employees/${preferredEscrowOfficer.id}`}>
          {preferredEscrowOfficer.name}
        </Link>
      )}
    </Td>
  )
}

export function AgentPitchStatusCell<T extends Agent>({ value, testId }: Props<T>) {
  const queryCache = useQueryClient()
  const clearCache = () => queryCache.invalidateQueries(['agents'])

  return (
    <Td data-testid={testId}>
      <AgentServicesOpportunitiesSelector agent={value} onChange={clearCache} />
    </Td>
  )
}

export function AgentLastActivityAtCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <Block
        title={formatDate(value.lastActivityAt, 'short')}
        subtitle={formatTime(value.lastActivityAt)}
      />
    </Td>
  )
}

export function AgentLastTouchedAtCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <Block
        title={formatDate(value.lastTouchedAt, 'short')}
        subtitle={formatTime(value.lastTouchedAt)}
      />
    </Td>
  )
}

export function AgentLastSignedInAtCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <Block
        title={formatDate(value.lastSignedInAt, 'short')}
        subtitle={formatTime(value.lastSignedInAt)}
      />
    </Td>
  )
}

export function AgentIntroducedReferralsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="introducedReferrals"
        linkTo={`/agents/${value.id}/referrals?leadStages=[introduced]`}
      />
    </Td>
  )
}
export function AgentClosedReferralsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="closedReferrals"
        linkTo={`/agents/${value.id}/referrals?leadStages=[closed%2Cclosed_paid%2Cclosed_booked]`}
      />
    </Td>
  )
}
export function AgentLeftVmReferralsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="agentLeftVmReferrals"
        linkTo={`/agents/${value.id}/referrals?leadStages=[agent_left_vm]`}
      />
    </Td>
  )
}
export function AgentConnectedReferralsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="connectedReferrals"
        linkTo={`/agents/${value.id}/referrals?leadStages=[connected]`}
      />
    </Td>
  )
}
export function AgentMeetingScheduledReferralsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="meetingScheduledReferrals"
        linkTo={`/agents/${value.id}/referrals?leadStages=[meeting_scheduled]`}
      />
    </Td>
  )
}
export function AgentMetInPersonReferralsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="metInPersonReferrals"
        linkTo={`/agents/${value.id}/referrals?leadStages=[met_in_person]`}
      />
    </Td>
  )
}
export function AgentListingReferralsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="listingReferrals"
        linkTo={`/agents/${value.id}/referrals?leadStages=[listing]`}
      />
    </Td>
  )
}
export function AgentFailedReferralsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="failedReferrals"
        linkTo={`/agents/${value.id}/referrals?leadStages=[failed]`}
      />
    </Td>
  )
}
export function AgentEscrowOpensCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue agent={value} metricKey="escrowOpens" />
    </Td>
  )
}
export function AgentEscrowClosesCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue agent={value} metricKey="escrowCloses" />
    </Td>
  )
}
export function AgentHomesLeadsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue agent={value} metricKey="homesLeads" />
    </Td>
  )
}
export function AgentHomesApprovalsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue agent={value} metricKey="homesApprovals" />
    </Td>
  )
}
export function AgentHomesClosesCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue agent={value} metricKey="homesCloses" />
    </Td>
  )
}
export function AgentToHlTradeInLeadsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="agentToHlTradeInLeads"
        linkTo={`/trade-ins?searchTerm=${value.fullName}`}
      />
    </Td>
  )
}
export function AgentToHlCashOfferLeadsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="agentToHlCashOfferLeads"
        linkTo={`/cash-offers?searchTerm=${value.fullName}`}
      />
    </Td>
  )
}
export function AgentToHomelightSimpleSaleLeadsCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="agentToHomelightSimpleSaleLeads"
        linkTo={`/hlss?searchTerm=${value.fullName}`}
      />
    </Td>
  )
}
export function AgentToHlTradeInApprovalsCompletedCell<T extends Agent>({
  value,
  testId
}: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="agentToHlTradeInApprovalsCompleted"
        linkTo={`/trade-ins?searchTerm=${value.fullName}`}
      />
    </Td>
  )
}
export function AgentToHlCashOfferApprovalsCompletedCell<T extends Agent>({
  value,
  testId
}: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="agentToHlCashOfferApprovalsCompleted"
        linkTo={`/cash-offers?searchTerm=${value.fullName}`}
      />
    </Td>
  )
}
export function AgentToHomelightSimpleSaleApprovalsCompletedCell<T extends Agent>({
  value,
  testId
}: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        linkTo={`/hlss?searchTerm=${value.fullName}`}
        agent={value}
        metricKey="agentToHomelightSimpleSaleApprovalsCompleted"
      />
    </Td>
  )
}
export function AgentToHlCashOfferClosesCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="agentToHlCashOfferCloses"
        linkTo={`/cash-offers?searchTerm=${value.fullName}`}
      />
    </Td>
  )
}
export function AgentToHomelightSimpleSaleClosesCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="agentToHomelightSimpleSaleCloses"
        linkTo={`/hlss?searchTerm=${value.fullName}`}
      />
    </Td>
  )
}
export function AgentToHlTradeInClosesCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <AgentMetricDisplayValue
        agent={value}
        metricKey="agentToHlTradeInCloses"
        linkTo={`/trade-ins?searchTerm=${value.fullName}`}
      />
    </Td>
  )
}

export function AgentHomesEnrollmentDateCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <Block
        title={formatDate(value.homesEnrollmentDate, 'short')}
        subtitle={formatTime(value.homesEnrollmentDate)}
      />
    </Td>
  )
}
export function AgentEnrollmentDateCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <Block
        title={formatDate(value.referralsEnrollmentDate, 'short')}
        subtitle={formatTime(value.referralsEnrollmentDate)}
      />
    </Td>
  )
}
export function AgentHomesActivationDateCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <Block
        title={formatDate(value.homesActivationDate, 'short')}
        subtitle={formatTime(value.homesActivationDate)}
      />
    </Td>
  )
}
export function AgentLastHomesLeadDateCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <Block
        title={formatDate(value.lastHomesLeadDate, 'short')}
        subtitle={formatTime(value.lastHomesLeadDate)}
      />
    </Td>
  )
}
export function AgentLastReferralDateCell<T extends Agent>({ value, testId }: Props<T>) {
  return (
    <Td data-testid={testId}>
      <Block
        title={formatDate(value.lastReferralLeadDate, 'short')}
        subtitle={formatTime(value.lastReferralLeadDate)}
      />
    </Td>
  )
}
