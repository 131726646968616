// @ts-strict
import { useState } from 'react'
import { Button } from '@foundation/components'
import { Popup } from 'components'
import { CreateEscrowOptions } from './CreateEscrowOptions'
import { CreateEscrowOrderForm } from './CreateEscrowOrder'
import styles from './CreateEscrowOrder.module.scss'
import { CreateInstantOrderForm } from './CreateInstantOrder'
import { TOrderOption } from './types'

export const CreateEscrowOrderButtonForm = () => {
  const [orderType, setOrderType] = useState<string>('')
  const [showForm, setShowForm] = useState<boolean>(false)
  const toggleForm = () => {
    if (showForm) {
      setOrderType('')
    }
    setShowForm(prev => !prev)
  }

  const orderTypes = [
    {
      value: 'instant_open',
      icon: 'Cellphone',
      title: 'Instant Open'
    },
    {
      value: 'escrow_order',
      icon: 'Envelope',
      title: 'Manual Open'
    }
  ] as TOrderOption[]

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={toggleForm}>
        Create Escrow Order
      </Button>
      {showForm ? (
        <Popup title="Submit to Qualia" onCancel={toggleForm} className={styles.popup}>
          <div className={styles.buttonFormContents}>
            {!orderType && (
              <CreateEscrowOptions
                orderTypes={orderTypes}
                onSelect={(type: string) => setOrderType(type)}
              />
            )}
            {orderType === 'instant_open' && <CreateInstantOrderForm handleClose={toggleForm} />}
            {orderType === 'escrow_order' && <CreateEscrowOrderForm handleClose={toggleForm} />}
          </div>
        </Popup>
      ) : null}
    </>
  )
}
