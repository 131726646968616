import { SearchParams } from '@types'
import { removeAttributesWithNullValues } from 'utils'
import { getSort } from '../../utils'

const sortParam = {
  Created: 'created_at',
  Name: 'name.keyword',
  Score: '_score',
  Updated: 'order.last_lead_stage_update',
  Stage: 'stage',
  'DR Purchase Actual COE Date': 'trade_in.departing_property.close_of_purchase_escrow_at',
  'IR Purchase Actual COE Date': 'trade_in.incoming_property.close_of_escrow_at',
  'DR Sale Actual COE Date': 'trade_in.departing_property.close_of_sale_escrow_at',
  'DR Sale Open Escrow Date': 'trade_in.departing_property.opened_sale_escrow_at',
  'DR Purchase Open Escrow Date': 'trade_in.departing_property.opened_purchased_escrow_at',
  'Trade-In+': 'trade_in.trade_in_plus',
  'Funding Date': 'providable.purchase_funded_at',
  'Listed Date': 'trade_in.departing_property.listed_at',
  'Days Since Funding': 'providable.purchase_funded_at'
}

export type TCCTradeInLeadsSearchParams = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'searchTerm' | 'sortDir' | 'sortBy'
> & {
  assigneeId?: string
  capitalPartnerId?: string
  source?: string[]
  stage?: string[]
  stateName?: string
  tradeInPlus?: string
}

const getFilters = ({
  searchTerm,
  assigneeId,
  stage,
  source,
  capitalPartnerId,
  tradeInPlus,
  stateName
}: TCCTradeInLeadsSearchParams) => {
  const filters = {}

  if (searchTerm) {
    filters['filter[_search]'] = searchTerm
  }

  if (assigneeId) {
    filters['filter[lead_users.user_id]'] = assigneeId
  }
  if (stage) {
    filters['filter[stage]'] = stage
  }

  if (source) {
    filters['filter[order.source]'] = source
  }

  if (capitalPartnerId) {
    filters['filter[trade_in.capital_partner_id]'] = capitalPartnerId
  }

  if (tradeInPlus) {
    filters['filter[trade_in.trade_in_plus]'] = tradeInPlus
  }

  if (stateName) {
    filters['filter[state_name]'] = stateName
  }

  return filters
}

export const getCCTradeInLeadsSearchParams = ({
  page,
  perPage,
  sortBy,
  sortDir,
  ...filters
}: TCCTradeInLeadsSearchParams) => {
  return removeAttributesWithNullValues({
    'page[number]': page ? page : undefined,
    'page[size]': perPage ? perPage : undefined,
    'filter[user_type]': 'cc_trade_in',
    sort: getSort(sortParam, sortDir, sortBy),
    ...getFilters(filters)
  })
}
