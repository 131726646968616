import { Transaction } from 'models'
import { getTransactionsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams } from './getSearchParams'

export const fetchTransactions = async (settings: {}) => {
  const url = getTransactionsUrl({
    searchParams: getSearchParams(settings)
  })
  const { data } = await getRequest(url)
  const transactions = await deserializeAsync(data, {
    transform: Transaction.create
  })
  return {
    transactions,
    totalCount: data.meta.total_transactions
  }
}
