import axios, { AxiosRequestConfig } from 'axios'

export const putFileToS3 = async (file: File, signedUrl: string) => {
  const isImageFileType = file.type.includes('image')
  const headers: AxiosRequestConfig = {
    headers: { 'Content-Type': file.type }
  }

  return axios.put(signedUrl, file, !isImageFileType ? headers : {})
}
