// @ts-strict
import { useEffect, useState } from 'react'
import { useButtonForm } from 'components'
import { UseMutationResult } from 'hooks'
import { EscrowLead } from 'models'
import { TEscrowOrderInstantOpenPostPayload } from 'services'
import { TValidation, validateEmailFormat, validatePresence } from 'utils'
import { isCA, parseSubmitAddress } from '../helpers'
import { TInstantOrderPayload } from '../types'

const initialPayload: TInstantOrderPayload = {
  address: '',
  buyer: false,
  cooperatingAgentEmail: '',
  files: [],
  firstName: '',
  lastName: '',
  name: '',
  orderType: 'escrow',
  referringAgentId: '',
  referringAgentName: '',
  secondaryAddress: '',
  sourceOfFunds: ''
}

type TCreateOrderFormParams = {
  mutation: UseMutationResult<
    Potential<EscrowLead>,
    unknown,
    TEscrowOrderInstantOpenPostPayload,
    unknown
  >
  onSubmit: (lead: EscrowLead) => Promise<void>
}

const transformPayload = (payload: TInstantOrderPayload) => {
  const name = payload.name || 'Name Pending'
  const address = parseSubmitAddress(payload)

  return {
    parent_lead_id: payload.parentLeadId,
    data: {
      type: 'lead',
      attributes: {
        lead_attributes: {
          address,
          name,
          first_name: name.split(' ')[0],
          last_name: name.split(' ')[name.split(' ').length - 1],
          email: payload.email ?? 'unknown@homelight.com',
          phone: payload.phone ?? null,
          source: 'web',
          source_page_type: 'sales_app',
          source_form: 'instant_open',
          referring_agent_id: payload.referringAgentId
        },
        buyer: payload.buyer,
        files: payload.files,
        cooperating_agent_email: payload.cooperatingAgentEmail,
        source_of_funds: payload.sourceOfFunds,
        order_type: payload.transactionType,
        started_as_pre_escrow: payload.orderType === 'pre_escrow'
      }
    }
  }
}

type TValidations = {
  address: TValidation | TValidation[]
  cooperatingAgentEmail: TValidation | TValidation[]
  files?: TValidation | TValidation[]
  orderType?: TValidation | TValidation[]
  referringAgentId: TValidation | TValidation[]
  sourceOfFunds?: TValidation | TValidation[]
}

export const useCreateInstantOrderForm = ({ mutation, onSubmit }: TCreateOrderFormParams) => {
  const [validations, setValidations] = useState({})

  const {
    formProps,
    fieldsProps: { payload, onChangeAttribute }
  } = useButtonForm<TInstantOrderPayload, EscrowLead>({
    initialPayload,
    mutation,
    callback: onSubmit,
    transformPayload,
    formValidations: validations
  })

  useEffect(() => {
    const newValidations = {
      address: validatePresence,
      cooperatingAgentEmail: validateEmailFormat,
      referringAgentId: validatePresence
    } as TValidations

    if (payload.orderType !== 'pre_escrow') {
      newValidations.files = validatePresence
      newValidations.sourceOfFunds = validatePresence
      newValidations.cooperatingAgentEmail = [validateEmailFormat, validatePresence]
    }

    if (payload.address && isCA(payload.address)) {
      newValidations.orderType = validatePresence
    }

    setValidations(newValidations)
  }, [payload])

  return {
    onChangeAttribute,
    formProps,
    payload
  }
}
