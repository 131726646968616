// @ts-strict
import { ExternalLink, FormField, Section, Sections } from 'components'
import { Lender } from 'models'
import { titleize } from '../../../utils'
import styles from './LenderDetails.module.scss'

type LenderDetailsProps = {
  lender: Lender
}

export const LenderDetails = ({ lender }: LenderDetailsProps) => {
  return (
    <Sections className={styles.section}>
      <Section title="About Lender">
        <FormField title="Name" inline>
          {lender.name}
        </FormField>
        <FormField title="Slug" inline>
          {lender.slug}
        </FormField>
        <FormField title="Email" inline>
          {lender.email}
        </FormField>
        <FormField title="States" inline>
          {lender.states?.toString()}
        </FormField>
        {Object.keys(lender.urls || {}).map(urlKey => (
          <FormField key={urlKey} title={titleize(urlKey, /([A-Z][a-z]+)/)} inline>
            <ExternalLink href={lender.urls?.[urlKey]}>Link</ExternalLink>
          </FormField>
        ))}
      </Section>
    </Sections>
  )
}
