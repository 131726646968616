// @ts-strict
import { Button } from '@foundation/components'
import { ButtonForm } from 'components'
import { LeadUsersNewLeadUserFormFields } from './LeadUsersNewLeadUserFormFields'
import { useLeadUsersNewLeadUserButtonForm } from './useLeadUsersNewLeadUserButtonForm'

export type TNewLeadUserPayload = {
  role: string
  userId: string
}

export const LeadUsersNewLeadUserButtonForm = () => {
  const { formProps, fieldsProps, transactionTeamRoleOptions, transactionTeamsMembersOptions } =
    useLeadUsersNewLeadUserButtonForm()

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={formProps.toggleShowForm}>
        Add Team Member
      </Button>
      <ButtonForm hideTriggerButton title="Add Team Member" {...formProps}>
        <LeadUsersNewLeadUserFormFields
          {...fieldsProps}
          transactionTeamsMembersOptions={transactionTeamsMembersOptions}
          transactionTeamRoleOptions={transactionTeamRoleOptions}
        />
      </ButtonForm>
    </>
  )
}
