import { Message } from 'models'
import { getHapiRequestParams, TextMessageSchema } from 'schema'
import { getTextInboxUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const messageResponseShape: TextMessageSchema = {
  textMessage: [
    'body',
    'outbound',
    'createdAt',
    'twilioSid',
    'provider',
    { agent: ['firstName', 'lastName'] }
  ]
}

export const fetchMessages = async () => {
  const searchParams = getHapiRequestParams(messageResponseShape)
  const url = getTextInboxUrl({ searchParams })
  const response = await getRequest(url)
  const deserializedMessages = await deserializeAsync<Message[]>(response.data, {
    transform: Message.create
  })

  return deserializedMessages
    .filter(message => !!message.provider)
    .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
}
