// @ts-strict
import { useMutation, useQueryClient } from 'hooks'
import { deleteEmployeeRole } from 'services'
import { EMPLOYEES_CACHE_KEY } from '../index'

export const useEmployeeRoleController = (userId: string, roleId: string) => {
  const queryCache = useQueryClient()

  const defaultCallback = {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryCache.invalidateQueries(EMPLOYEES_CACHE_KEY)
    }
  }

  const destroyEmployeeRole = useMutation(() => deleteEmployeeRole(userId, roleId), defaultCallback)

  return {
    destroyEmployeeRole
  }
}
