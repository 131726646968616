import { PAGE_NOT_FOUND_TITLE } from 'pages/NotFoundPage'
import { titleize } from 'utils'

export const LEAD_PAGES_DICTIONARY = {
  'trade-ins': 'Trade-In Leads',
  hlss: 'HLSS Leads',
  'cash-offers': 'Cash Offer Leads',
  mortgages: 'Mortgage Leads',
  'buyers-sellers': 'Buyer and Sellers Leads',
  buyers: 'Buyer Leads',
  sellers: 'Seller Leads'
}

export const formatPageUrlToTitle = (pathname: string) => {
  if (pathname === '/') {
    return 'Home'
  }

  if (document.title === PAGE_NOT_FOUND_TITLE) {
    return PAGE_NOT_FOUND_TITLE
  }

  const [, ...urlPaths] = pathname.split('/')

  const formattedPaths: string[] = urlPaths.map((url, index) => {
    if (Object.keys(LEAD_PAGES_DICTIONARY).includes(url)) {
      return LEAD_PAGES_DICTIONARY[url]
    }

    const isADetailsPage = Number(url) && index === 1

    if (isADetailsPage) {
      return 'Details'
    }

    return titleize(url)
  })

  return formattedPaths.length > 1 ? formattedPaths.join(' - ') : formattedPaths[0]
}
