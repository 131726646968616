// @ts-strict
import { Block, TableCell as Td, TableRow as Tr } from 'components'
import { EscrowOrderSettlementTeamMember } from 'models'
import { dataOrDash } from 'utils'
import style from './EscrowOrderSettlementTeamMemberRow.module.scss'

type TProps = {
  teamMember: EscrowOrderSettlementTeamMember
}

export const EscrowOrderSettlementTeamMemberRow = ({
  teamMember: { settlementTeamMember, role }
}: TProps) => (
  <Tr>
    <Td className={style.roleColumn}>{dataOrDash(role)}</Td>

    <Td className={style.nameColumn}>
      <Block
        title={dataOrDash(`${settlementTeamMember?.firstName} ${settlementTeamMember?.lastName}`)}
      />
    </Td>

    <Td className={style.emailColumn}>
      <a href={`mailto:${settlementTeamMember?.email}`}>
        {dataOrDash(settlementTeamMember?.email)}
      </a>
    </Td>

    <Td className={style.phoneColumn}>
      <Block title={dataOrDash(settlementTeamMember?.phone)} />
    </Td>
  </Tr>
)
