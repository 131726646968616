import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './FormFieldSet.module.scss'

export type Props = {
  children?: ReactNode
  className?: string
  title?: string
}

export const FormFieldSet = ({ title, children, className }: Props) => {
  return (
    <fieldset className={cx(styles.fieldset, className)}>
      {title ? <h4 className={styles.fieldsetTitle}>{title}</h4> : null}
      {children}
    </fieldset>
  )
}
