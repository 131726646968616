// @ts-strict

import { memo, MutableRefObject, useMemo, useRef } from 'react'
import { Button } from '@foundation/components'
import { List } from 'components'
import { WARM_TRANSFER_STEP } from 'controllers/WarmTransfer/types'
import { useClickAway } from 'hooks'
import { AgentWarmTransfer } from './AgentWarmTransfer'
import { AgentsAvailablePopUp } from './AgentsAvailablePopUp'
import { AgentsListPopUp } from './AgentsListPopUp'
import * as S from './styles'
import { ButtonStylesVariants, ButtonVariants, Props } from './types'
import { useLeadWarmTransfer } from './useLeadWarmTransfer'

const defaultButton: ButtonStylesVariants = {
  color: 'primary',
  text: 'Warm Transfer',
  variant: 'outline'
}

const buttonVariants: ButtonVariants = {
  [WARM_TRANSFER_STEP.unsubscribed]: {
    ...defaultButton,
    isDisabled: false,
    isLoading: false
  },
  [WARM_TRANSFER_STEP.started]: {
    ...defaultButton,
    isDisabled: true,
    isLoading: true
  },
  [WARM_TRANSFER_STEP.pending]: {
    color: 'warning',
    text: 'Warm Transfer (Pending)',
    variant: 'solid',
    isDisabled: false,
    isLoading: false
  },
  [WARM_TRANSFER_STEP.active]: {
    color: 'success',
    text: 'Warm Transfer (Active)',
    variant: 'solid',
    isDisabled: false,
    isLoading: false
  },
  [WARM_TRANSFER_STEP.canceled]: {
    color: 'warning',
    text: 'Warm Transfer (Canceled)',
    variant: 'solid',
    isDisabled: true,
    isLoading: false
  },
  [WARM_TRANSFER_STEP.completed]: {
    color: 'success',
    text: 'Warm Transfer (Completed)',
    variant: 'solid',
    isDisabled: true,
    isLoading: false
  }
}

export const LeadWarmTransfer = memo(
  ({ amountOfEligibleAgents, leadId, hasStarted = false }: Props) => {
    const {
      cancel,
      canShowCancelButton,
      canShowAgents,
      canShowAmountOfAgentsAvailable,
      isFinishedState,
      openAgentsAvailable,
      closeAgentsList,
      closeAgentsAvailable,
      handleClick,
      connectAgentWithWarmTransfer,
      step,
      agentsAvailable,
      totalElapsedTime
    } = useLeadWarmTransfer({ leadId, hasStarted })

    const listRef: MutableRefObject<HTMLDivElement | undefined> = useRef()
    const agentsAvailableRef: MutableRefObject<HTMLDivElement | undefined> = useRef()

    useClickAway(listRef, closeAgentsList)
    useClickAway(agentsAvailableRef, closeAgentsAvailable)

    const memoizedButton = useMemo(() => {
      if (!buttonVariants[step]) {
        return
      }

      return (
        <Button
          isLoading={buttonVariants[step].isLoading}
          isDisabled={buttonVariants[step].isDisabled}
          onClick={handleClick}
          size="xs"
          color={buttonVariants[step].color}
          variant={buttonVariants[step].variant}
          onMouseEnter={openAgentsAvailable}
        >
          {buttonVariants[step].text}
        </Button>
      )
    }, [handleClick, openAgentsAvailable, step])

    return (
      <S.WarmTransferWrapper>
        {amountOfEligibleAgents && canShowAmountOfAgentsAvailable ? (
          <div ref={agentsAvailableRef as MutableRefObject<HTMLDivElement>}>
            <AgentsAvailablePopUp amountOfAgents={amountOfEligibleAgents} />
          </div>
        ) : null}
        {memoizedButton}
        <div ref={listRef as MutableRefObject<HTMLDivElement>}>
          <AgentsListPopUp
            canShowAmountOfAgents={canShowAgents && !isFinishedState}
            amountOfAgents={agentsAvailable.length}
            totalElapsedTime={totalElapsedTime}
          >
            <List>
              {agentsAvailable.map(agent => (
                <AgentWarmTransfer
                  amountOfAgents={agentsAvailable.length}
                  connectAgent={connectAgentWithWarmTransfer}
                  key={agent.agentId}
                  agent={agent}
                />
              ))}
            </List>
            {canShowCancelButton ? (
              <S.ButtonWrapper>
                <Button color="neutralLight" variant="ghost" onClick={cancel} size="xs">
                  Cancel Warm Transfer
                </Button>
              </S.ButtonWrapper>
            ) : null}
          </AgentsListPopUp>
        </div>
      </S.WarmTransferWrapper>
    )
  }
)

LeadWarmTransfer.displayName = 'LeadWarmTransfer'
