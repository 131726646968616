// @ts-strict
import { Block, List, TableCell as Td } from 'components'
import styles from 'content/Leads/LeadsTable/LeadsTableCells/LeadsTableCells.module.scss'
import { CCHLSimpleSaleLead } from 'models'
import {
  dataOrDash,
  dateDiffInDays,
  formatBoolean,
  formatDate,
  formatMoney,
  structureAddress,
  titleize
} from 'utils'

type TProps = {
  column?: string
  lead: CCHLSimpleSaleLead
  testId: string
}

export const CCHLSimpleSaleLeadPropertyAddress = ({ lead, testId }: TProps) => {
  const { departingMasterProperty } = lead.hlSimpleSaleLead || {}
  const { street, cityState } = structureAddress(departingMasterProperty?.displayAddress)

  return (
    <Td data-testid={testId}>
      <List>
        {street}
        {dataOrDash(cityState)}
      </List>
    </Td>
  )
}

export const CCHLSimpleSaleLeadHoaCell = ({ lead, testId }: TProps) => {
  const { departingPropertyHomeownerAssociation } = lead.hlSimpleSaleLead || {}
  return (
    <Td data-testid={testId}>
      <Block
        allowTitleOverflow
        title={dataOrDash(formatBoolean(departingPropertyHomeownerAssociation))}
      />
    </Td>
  )
}

export const CCHLSimpleSaleLeadSolarPanelsCell = ({ lead, testId }: TProps) => {
  const { departingPropertySolarPanels } = lead.hlSimpleSaleLead || {}
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(titleize(departingPropertySolarPanels))} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadFundingAmountCell = ({ lead, testId }: TProps) => {
  const { dpFundingAmount } = lead.hlSimpleSaleLead || {}

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(formatMoney(dpFundingAmount))} />
    </Td>
  )
}

export const CCHLSimpleSaleDRSaleOpenEscrowDateCell = ({ lead, testId }: TProps) => {
  const { departingPropertyOpenedSaleEscrowDate } = lead.hlSimpleSaleLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(departingPropertyOpenedSaleEscrowDate))} />
    </Td>
  )
}

export const CCSimpleSaleLeadDRPurchaseOpenEscrowDateCell = ({ lead, testId }: TProps) => {
  const { departingPropertyOpenedPurchasedEscrowDate } = lead.hlSimpleSaleLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(departingPropertyOpenedPurchasedEscrowDate))} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadDRPurchaseCoeDateCell = ({ lead, testId }: TProps) => {
  const { departingPropertyCloseOfPurchaseEscrowDate } = lead.hlSimpleSaleLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(departingPropertyCloseOfPurchaseEscrowDate))} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadDRSaleActualCOEDateCell = ({ lead, testId }: TProps) => {
  const { departingPropertyCloseOfSaleEscrowDate } = lead.hlSimpleSaleLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(departingPropertyCloseOfSaleEscrowDate))} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadOpenPurchaseEscrowDateCell = ({ lead, testId }: TProps) => {
  const { departingPropertyOpenedPurchasedEscrowDate } = lead.hlSimpleSaleLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(departingPropertyOpenedPurchasedEscrowDate))} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadOpenSaleEscrowDateCell = ({ lead, testId }: TProps) => {
  const { departingPropertyOpenedSaleEscrowDate } = lead?.hlSimpleSaleLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(departingPropertyOpenedSaleEscrowDate))} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadFundingDateCell = ({ lead, testId }: TProps) => {
  const { dpActualPurchaseFundedDate } = lead?.hlSimpleSaleLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(dpActualPurchaseFundedDate))} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadListedDateCell = ({ lead, testId }: TProps) => {
  const { departingPropertyListedDate } = lead.hlSimpleSaleLead || {}
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(formatDate(departingPropertyListedDate))} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadFundingFacilityCell = ({ lead, testId }: TProps) => {
  const capitalPartner =
    lead.hlSimpleSaleLead?.providerLead?.providerLeadCapitalPartner?.capitalPartner
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(titleize(capitalPartner?.fundingFacility))} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadDaysOwnedCell = ({ lead, testId }: TProps) => {
  const purchaseEscrowDate = lead.hlSimpleSaleLead?.departingPropertyCloseOfPurchaseEscrowDate || ''
  const saleEscrowDate = lead.hlSimpleSaleLead?.departingPropertyCloseOfSaleEscrowDate || new Date()
  const diffInDays = dateDiffInDays(saleEscrowDate, purchaseEscrowDate)
  const daysOwned = diffInDays > 0 ? diffInDays : ''
  const className = daysOwned >= 45 ? (daysOwned >= 60 ? 'danger' : 'warning') : ''

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(daysOwned)} className={styles[className]} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadDaystoListingCell = ({ lead, testId }: TProps) => {
  const purchaseEscrowDate = lead.hlSimpleSaleLead?.departingPropertyCloseOfPurchaseEscrowDate || ''
  const listedDate = lead.hlSimpleSaleLead?.departingPropertyListedDate || new Date()
  const diffInDays = dateDiffInDays(listedDate, purchaseEscrowDate)
  const daystoListing = diffInDays > 0 ? diffInDays : ''

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(daystoListing)} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadDaysOnMarketCell = ({ lead, testId }: TProps) => {
  const listedDate = lead.hlSimpleSaleLead?.departingPropertyListedDate || ''
  const saleEscrowDate = lead.hlSimpleSaleLead?.departingPropertyCloseOfSaleEscrowDate || new Date()
  const diffInDays = dateDiffInDays(saleEscrowDate, listedDate)
  const daysOnMarket = diffInDays > 0 ? diffInDays : ''

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(daysOnMarket)} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadDaysUntilFundingCell = ({ lead, testId }: TProps) => {
  const fundingDate = lead.hlSimpleSaleLead?.dpActualPurchaseFundedDate || ''

  const today = formatDate(new Date(), 'yyyy-MM-dd')
  const diffInDays = dateDiffInDays(fundingDate, today)
  const result = diffInDays >= 0 ? String(diffInDays) : null

  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(result)} />
    </Td>
  )
}

export const CCHLSimpleSaleLeadCapitalMarketsFacilityCell = ({ lead, testId }: TProps) => {
  const { providerLeadCapitalPartner } = lead.hlSimpleSaleProviderLead || {}
  return (
    <Td data-testid={testId}>
      <Block
        allowTitleOverflow
        title={dataOrDash(providerLeadCapitalPartner?.capitalPartner?.name)}
      />
    </Td>
  )
}
