// @ts-strict
import { FormField, InlineEditSelect, InlineEditTextInput, Section, Sections } from 'components'
import {
  agentMatchingCallReviewGenderPreference,
  agentMatchingCallReviewLanguageRequirement,
  agentMatchingCallReviewPreviousAgent,
  agentMatchingCallReviewRelationship,
  yesOrNoForJsonOptions
} from 'lookups'
import { BuyerLead, SellerLead } from 'models'
import { dataOrDash } from 'utils'
import * as S from '../styles'
import { BrokeragesToExcludeButtonForm } from './BrokeragesToExcludeButtonForm'

type Props = {
  // is temporary
  hideBrokeragesToExclude?: boolean
  lead: BuyerLead | SellerLead
  requiredFields?: Record<string, boolean>
  updateCallReview: (key: string, value: string) => void
}

export const AgentMatchingLeadAgentScreenSection = ({
  lead,
  requiredFields = {},
  hideBrokeragesToExclude = false,
  updateCallReview
}: Props) => {
  return (
    <Sections title="Agent Screen">
      <Section className="agent-screen">
        <FormField title="Relationship to Client" inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('relationship', value)}
            options={agentMatchingCallReviewRelationship}
            value={dataOrDash(lead?.callReview?.relationship)}
          />
        </FormField>
        <FormField title="Gender Preference" inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('genderPreference', value)}
            options={agentMatchingCallReviewGenderPreference}
            value={dataOrDash(lead?.callReview?.genderPreference)}
          />
        </FormField>
        <FormField title="Language Requirement" inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('languageRequirement', value)}
            options={agentMatchingCallReviewLanguageRequirement}
            value={dataOrDash(lead?.callReview?.languageRequirement)}
          />
        </FormField>
        <FormField title="Worked w/ Other Agent" required={requiredFields.previousAgent} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('previousAgent', value)}
            options={agentMatchingCallReviewPreviousAgent}
            value={dataOrDash(lead?.callReview?.previousAgent)}
          />
        </FormField>
        <FormField title="Previous Agent Name" required={requiredFields.previousAgentName} inline>
          <InlineEditTextInput
            onBlur={(val: string) => updateCallReview('previousAgentName', val)}
            value={dataOrDash(lead?.callReview?.previousAgentName)}
          />
        </FormField>
        <FormField
          title="Previous Agent Brokerage"
          required={requiredFields.previousAgentBrokerage}
          inline
        >
          <InlineEditTextInput
            onBlur={(val: string) => updateCallReview('previousAgentBrokerage', val)}
            value={dataOrDash(lead?.callReview?.previousAgentBrokerage)}
          />
        </FormField>
        {!hideBrokeragesToExclude && (
          <S.FormField title="Brokerages to Exclude" inline>
            <BrokeragesToExcludeButtonForm brokerages={lead?.callReview?.brokeragesToExclude} />
          </S.FormField>
        )}
      </Section>
      <Section className="agent-screen">
        <FormField title="Meeting availability" inline>
          <InlineEditTextInput
            onBlur={(val: string) => updateCallReview('availability', val)}
            value={dataOrDash(lead?.callReview?.availability)}
          />
        </FormField>
        <FormField title="Handmatch?" inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('highTouch', value)}
            options={yesOrNoForJsonOptions}
            value={dataOrDash(lead?.callReview?.highTouch)}
          />
        </FormField>
        <FormField title="Same Agents as Orig Lead?" inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('sameAgents', value)}
            options={yesOrNoForJsonOptions}
            value={dataOrDash(lead?.callReview?.sameAgents)}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
