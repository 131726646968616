// @ts-strict
import { useEffect, useRef } from 'react'
import mapboxgl, { Map, MapMouseEvent } from 'mapbox-gl'
import { Marker, Point } from 'classes'
import { useMapBoxContext } from 'contexts'
import { environment } from 'environment'
import 'mapbox-gl/dist/mapbox-gl.css'
import { asArray, cx, dataOrDash, isNotNull } from 'utils'
import styles from './MapBox.module.scss'
import { getBounds, getCenter, getIcon } from './utils'

const MAP_STYLE = 'mapbox://styles/homelight/cjqsm3msvakki2sp7j1ugl9gy'
mapboxgl.accessToken = environment.mapboxToken || ''

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl['workerClass'] = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const addMarkersToMap = (markers: Marker[], map: Map, interactive: boolean, center: Point) => {
  const points: Point[] = []
  markers
    .sort((a, b) => b.point.latitude - a.point.latitude)
    .forEach(marker => {
      if (!marker.point.longitude || !marker.point.latitude) {
        return
      }
      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
        `<b>${dataOrDash(marker.name)}</b><div>${dataOrDash(marker.description)}</div>`
      )
      const icon = getIcon(marker.color)
      points.push(marker.point)
      new mapboxgl.Marker(icon)
        .setLngLat([marker.point.longitude, marker.point.latitude])
        .setPopup(interactive ? popup : undefined)
        .addTo(map)
    })
  setBounds(points, map, center)
}

const setBounds = (points: Point[], map: Map, center: Point) => {
  const bounds = points ? getBounds(points) : getBounds([center])

  points &&
    points.length > 2 &&
    map.fitBounds(
      [
        [bounds.sw.longitude, bounds.sw.latitude],
        [bounds.ne.longitude, bounds.ne.latitude]
      ],
      {
        padding: 40
      }
    )
}

type TProps = {
  center?: Point
  className?: string
  interactive?: boolean
  markers?: Potential<Marker>[] | Potential<Marker>
  onClick?: (e: MapMouseEvent) => void
}

export const MapBox = ({
  center,
  className,
  interactive = true,
  markers = [],
  onClick = () => {}
}: TProps) => {
  const { center: defaultCenter, markers: contextMarkers = [] } = useMapBoxContext()
  const mapWrapper = useRef<null | HTMLDivElement>(null)
  const map = useRef<null | Map>(null)
  const newCenter = center || defaultCenter || getCenter([])

  useEffect(() => {
    if (map.current || !mapWrapper.current) {
      // initialize map only once
      return
    }
    map.current = new mapboxgl.Map({
      container: mapWrapper.current,
      style: MAP_STYLE,
      center: [newCenter.longitude, newCenter.latitude],
      zoom: 11,
      attributionControl: false,
      interactive
    })

    const allMarkers = asArray(markers).filter(isNotNull).concat(contextMarkers)
    const controls = new mapboxgl.NavigationControl()

    map.current.addControl(controls, 'top-left')
    map.current.on('click', onClick)
    addMarkersToMap(allMarkers, map.current, interactive, newCenter)
  }, [contextMarkers, interactive, markers, newCenter, onClick])

  return <div ref={mapWrapper} className={cx(styles.mapBox, className)} />
}
