import { forwardRef, ReactNode } from 'react'
import { Button, ButtonProps, Dialog, Flex } from '@foundation/components'

/**
 * FilterDialogFooter
 */

type FilterDialogFooterProps = {
  children: ReactNode
}

export const FilterDialogFooter = ({ children }: FilterDialogFooterProps) => {
  return (
    <Dialog.Footer>
      <Flex justifyContent="space-between">{children}</Flex>
    </Dialog.Footer>
  )
}

FilterDialogFooter.displayName = 'FilterDialogFooter'

/**
 * FilterDialogTriggerButton
 */

type FilterDialogTriggerButtonProps = {
  counter: number
} & Pick<ButtonProps, 'onClick' | 'size' | 'variant'>

export const FilterDialogTriggerButton = forwardRef<
  HTMLButtonElement,
  FilterDialogTriggerButtonProps
>(({ counter, onClick, size, variant }: FilterDialogTriggerButtonProps, ref) => {
  const counterText = counter === 1 ? 'Filter' : 'Filters'
  const triggerText = [counter, counterText].filter(Boolean).join(' ')

  return (
    <Button
      ref={ref}
      color="neutralLight"
      onClick={onClick}
      size={size}
      variant={variant}
      isRounded
    >
      {triggerText}
    </Button>
  )
})

FilterDialogTriggerButton.displayName = 'FilterDialogTriggerButton'

/**
 * FilterDialogApplyButton
 */

export const FilterDialogApplyButton = ({
  onClick,
  isDisabled
}: Pick<ButtonProps, 'onClick' | 'isDisabled'>) => {
  return (
    <Button onClick={onClick} isDisabled={isDisabled}>
      Apply Filters
    </Button>
  )
}

FilterDialogApplyButton.displayName = 'FilterDialogApplyButton'

/**
 * FilterDialogResetButton
 */

export const FilterDialogResetButton = ({
  onClick,
  isDisabled
}: Pick<ButtonProps, 'onClick' | 'isDisabled'>) => {
  return (
    <Button variant="ghost" color="neutralLight" onClick={onClick} isDisabled={isDisabled}>
      Clear
    </Button>
  )
}

FilterDialogResetButton.displayName = 'FilterDialogResetButton'
