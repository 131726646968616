// @ts-strict
import { Box } from '@foundation/components'
import { Contents, Divider, Section } from 'components'
import { Agent, AgentPayPerLeadReferral } from 'models'
import { ReferralsTableReadOnly } from './PayPerLeadReferrals'

type Props = {
  agent: Agent
  currentReferrals: AgentPayPerLeadReferral[]
  pastReferrals: AgentPayPerLeadReferral[]
}

export const AgentReferrals = ({ agent, currentReferrals, pastReferrals }: Props) => {
  return (
    <Contents title="Pay-Per Lead Referrals">
      <Box
        borderStyle="solid"
        borderColor="neutral5"
        borderWidth="thin"
        borderRadius="xl"
        padding="$8"
        width="650px"
      >
        <Section>
          <b style={{ paddingRight: '8px' }}>Current Billing Cycle</b>
          <ReferralsTableReadOnly agent={agent} referrals={currentReferrals} />
        </Section>
        <Box paddingTop="$6" paddingBottom="$6">
          <Divider />
        </Box>
        <Section>
          <b style={{ paddingRight: '8px' }}>Past Billing Cycles</b>
          <ReferralsTableReadOnly agent={agent} referrals={pastReferrals} />
        </Section>
      </Box>
    </Contents>
  )
}
