import { useCallback } from 'react'
import { useLocalStorage, useQueryClient } from 'hooks'
import { Lead } from 'models'
import { fetchHandmatchLead } from 'services/HandmatchLead/fetchHandmatchLead'
import { uniqBy } from 'utils'

type QueuedItem = {
  date: number
  id: string
}

type Queue = QueuedItem[]

export const useQueuedLeadsController = () => {
  const [queue, setQueue] = useLocalStorage<Queue>('orderQueue', [])
  const queryCache = useQueryClient()

  const addLeadToQueue = useCallback(
    (lead: Lead) => {
      if (lead) {
        setQueue(uniqBy([...queue, { id: lead.id, date: new Date().getTime() }], 'id'))
      }
    },
    [setQueue, queue]
  )

  const getNextHandmatchLead = useCallback(async () => {
    const nextLead = await fetchHandmatchLead()
    if (nextLead) {
      addLeadToQueue(nextLead)
    }
    queryCache.invalidateQueries('current-user-queues')
  }, [queryCache, addLeadToQueue])

  const removeQueuedLead = useCallback(
    (id: string) => {
      setQueue(queue.filter(queuedItem => queuedItem.id !== id))
      queryCache.invalidateQueries('current-user-queues')
    },
    [queryCache, setQueue, queue]
  )

  const queueIds = queue.map(q => q.id)

  const isActive = (id: string): boolean => queueIds.includes(id)

  const getSinceDate = (id: string): Potential<Date> => {
    if (isActive(id)) {
      const queueItem = queue.find(i => i.id === id)
      return queueItem ? new Date(queueItem.date) : undefined
    }
    return undefined
  }

  return {
    addLeadToQueue,
    getNextHandmatchLead,
    getSinceDate,
    isActive,
    queue,
    queueIds,
    removeQueuedLead
  }
}
