import { FormField, InlineEditPhoneInput, InlineEditTextInput, Section, Sections } from 'components'
import { useUserController } from 'hooks'
import { dataOrDash, formatPhone } from 'utils'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import { AddLoanOfficerContact } from './AddLoanOfficerContact'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>

type ContactProps = {
  contact: Props['lead']['loanOfficerAdditionalContacts'][number]
}

const Contact = ({ contact }: ContactProps) => {
  const { userUpdate } = useUserController(contact?.user.id)
  const contactPhone = contact?.user?.phoneNumbers[0]?.number
    ? formatPhone(contact?.user?.phoneNumbers[0]?.number)
    : ''

  const updateUser = (payload: any) => {
    userUpdate.mutateAsync(payload)
  }

  const getSplitName = (value: string) => {
    const splitName = value.split(' ', 2)
    return { first_name: splitName[0], last_name: splitName[1] }
  }

  return (
    <Sections>
      <Section>
        <FormField title="Name" inline>
          <InlineEditTextInput
            onBlur={(value: string) => updateUser(getSplitName(value))}
            value={dataOrDash(contact?.user?.name)}
          />
        </FormField>
      </Section>
      <Section>
        <FormField title="Email Address" inline>
          <InlineEditTextInput
            onBlur={(value: string) => updateUser({ email: value })}
            value={dataOrDash(contact?.user?.email)}
          />
        </FormField>

        <FormField title="Phone Number" inline>
          <InlineEditPhoneInput
            onBlur={(value: string) => updateUser({ phone_mobile: value })}
            value={dataOrDash(contactPhone)}
          />
        </FormField>
      </Section>
    </Sections>
  )
}

export const LoanOfficerContactsSection = ({ lead }: Props) => {
  return (
    <Sections title="Loan Officer Contacts">
      <Section>
        {lead.loanOfficerAdditionalContacts.map(contact => (
          <Contact key={contact.id} contact={contact} />
        ))}

        <AddLoanOfficerContact bbysLead={lead?.bbysProviderLead?.bbysLead} />
      </Section>
    </Sections>
  )
}
