import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Column.module.scss'

type Props = {
  children: ReactNode
  className?: string
  title?: string
}
export const Column = ({ children, title, className }: Props) => (
  <div className={cx(styles.column, className)}>
    {title ? <div className={styles.title}>{title}</div> : null}
    {children}
  </div>
)
