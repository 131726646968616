import { formatNumber } from '../formatNumber'

export const dash = (num?: number) => {
  if (num === undefined) {
    return
  }
  if (Number(num) === 0) {
    return '-'
  }
  return formatNumber(num)
}
