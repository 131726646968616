// @ts-strict
import { useMutation, useQuery, useQueryClient } from 'hooks'
import { AgentPayPerLeadSetting } from 'models'
import {
  fetchAgentPayPerLeadSettings,
  patchAgentPayPerLeadSettings,
  postAgentPayPerLeadSettings,
  postSendSetupCheckoutSessionMagicLink,
  postSetupCheckoutSession
} from 'services'

type UpdateAgentPayPerLeadSettingsPayload = {
  settings: AgentPayPerLeadSetting[]
}

type CreateAgentPayPerLeadSettingsPayload = {
  buyerMonthlyCap: number
  monthlyCap: number
  settings: AgentPayPerLeadSetting[]
}

type CreateSetupCheckoutSessionPayload = {
  addNewPaymentMethod?: boolean
  redirectUrl?: string
  subscriberId: string
  subscriberType: string
}

type CreateSetupCheckoutSessionMagicLinkPayload = {
  priceId?: string
  redirectUrl?: string
  subscriberId: string
  subscriberType: string
}

export const useAgentPayPerLeadSettingsController = (agentId: string) => {
  const agentPayPerLeadSettingsQuery = useQuery(
    ['agent-billing-settings', agentId],
    () => fetchAgentPayPerLeadSettings(agentId),
    { refetchOnWindowFocus: false }
  )

  const queryCache = useQueryClient()

  const updateAgentPayPerLeadSettings = useMutation(
    (payload: UpdateAgentPayPerLeadSettingsPayload) =>
      patchAgentPayPerLeadSettings(agentId, payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('agent-billing-settings')
      }
    }
  )

  const createAgentPayPerLeadSettings = useMutation(
    (payload: CreateAgentPayPerLeadSettingsPayload) =>
      postAgentPayPerLeadSettings(agentId, payload),
    {
      onSuccess: () => {}
    }
  )

  const createSetupCheckoutSession = useMutation((payload: CreateSetupCheckoutSessionPayload) =>
    postSetupCheckoutSession(payload)
  )

  const createSetupCheckoutSessionMagicLink = useMutation(
    (payload: CreateSetupCheckoutSessionMagicLinkPayload) =>
      postSendSetupCheckoutSessionMagicLink(payload)
  )

  const clearCaches = () => {
    queryCache.invalidateQueries('agent-billing-settings')
  }

  return {
    agentPayPerLeadSettingsQuery,
    updateAgentPayPerLeadSettings,
    createAgentPayPerLeadSettings,
    createSetupCheckoutSession,
    createSetupCheckoutSessionMagicLink,
    clearCaches
  }
}
