// @ts-strict

import {
  ButtonForm,
  ButtonV2,
  CheckBoxField,
  FormField,
  Section,
  Select,
  TextArea
} from 'components'
import { useCurrentUserContext, useLeadContext } from 'contexts'
import { agentLeadDispositionNotIntroducedOptions, agentLeadDispositionOptions } from 'lookups'
import { AgentProviderLead } from 'models'
import styles from './AgentLeadDispositionButtonForm.module.scss'
import {
  TAgentLeadDispositionButtonFormPayload,
  useAgentLeadDispositionButtonForm
} from './useAgentLeadDispositionButtonForm'

type TProps = {
  agentProviderLead: AgentProviderLead
}

export const AgentLeadDispositionButtonForm = ({ agentProviderLead }: TProps) => {
  const { lead } = useLeadContext()
  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useAgentLeadDispositionButtonForm(agentProviderLead, lead)

  const isIntroduced = agentProviderLead.introduced
  const stageOptions = isIntroduced
    ? agentLeadDispositionOptions
    : agentLeadDispositionNotIntroducedOptions
  const showIntroduced = !isIntroduced && payload.disposition === 'introduce'

  const toggleCheckbox = (key: keyof TAgentLeadDispositionButtonFormPayload) => {
    onChangeAttribute(key, !payload[key])
  }

  const { isHandMatchingAdmin } = useCurrentUserContext()

  if (!isHandMatchingAdmin) {
    return null
  }

  return (
    <ButtonForm
      title="Agent Lead Disposition"
      buttonText="Disposition"
      buttonComponent={buttonProps => (
        <ButtonV2 className={styles.dispositionButton} {...buttonProps} />
      )}
      {...formProps}
    >
      <Section>
        <FormField title="Agent" inline className={styles.info}>
          {agentProviderLead?.agentLead?.agent?.fullName}
        </FormField>
        <FormField title="Current Stage" inline className={styles.info}>
          {agentProviderLead.stageAndFurthestStage}
        </FormField>
      </Section>
      <Section>
        <FormField title="Update Stage" required>
          <Select
            addBlank
            options={stageOptions}
            value={String(payload.disposition)}
            onChange={val => onChangeAttribute('disposition', val)}
          />
        </FormField>
        {showIntroduced && (
          <Section
            title="Introduction Options"
            className={styles.introductionOptionsSection}
            data-testid="introducedFields"
          >
            <CheckBoxField
              title="Include Agent Profile"
              checked={payload.includeAgentProfile}
              onChange={() => {
                toggleCheckbox('includeAgentProfile')
              }}
            />
            <CheckBoxField
              title="Include Agent Website"
              checked={payload.includeAgentWebsite}
              onChange={() => {
                toggleCheckbox('includeAgentWebsite')
              }}
            />
            <CheckBoxField
              title="Send Intro First"
              checked={payload.sendIntroFirst}
              onChange={() => {
                toggleCheckbox('sendIntroFirst')
              }}
            />
            <CheckBoxField
              title="Delay Intro Email"
              checked={payload.delayIntroEmail}
              onChange={() => {
                toggleCheckbox('delayIntroEmail')
              }}
            />
            <CheckBoxField
              title="Skip Agent Email"
              checked={payload.skipAgentEmail}
              onChange={() => {
                toggleCheckbox('skipAgentEmail')
              }}
            />
          </Section>
        )}
        {isIntroduced && (
          <FormField title="Note" htmlFor="note">
            <TextArea
              id="note"
              value={payload.note}
              onChange={val => onChangeAttribute('note', val)}
            />
          </FormField>
        )}
      </Section>
    </ButtonForm>
  )
}
