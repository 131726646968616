// @ts-strict

import { Table, TTableProps } from 'components'
import { cx } from 'utils'
import style from './SimpleTable.module.scss'

export const SimpleTable = ({
  children,
  title,
  wrapperClassName,
  className,
  ...rest
}: TTableProps) => (
  <Table
    wrapperClassName={cx(style.wrapper, wrapperClassName)}
    title={title}
    className={cx(style.table, className)}
    hasFixedHeader={false}
    {...rest}
  >
    {children}
  </Table>
)
