import { CCCashOfferLead } from 'models'
import { CCCashOfferLeadDispositionButtonForm, CCCashOfferLeadDispositionProvider } from '.'

type TProps = {
  lead: CCCashOfferLead
  onCancel: () => void
}

export const CCCashOfferLeadDisposition = ({ lead, onCancel }: TProps) => {
  return (
    <CCCashOfferLeadDispositionProvider lead={lead}>
      <CCCashOfferLeadDispositionButtonForm lead={lead} onCancel={onCancel} />
    </CCCashOfferLeadDispositionProvider>
  )
}
