// @ts-strict
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { UseMutationResult } from 'react-query'
import { useDeepCompareEffect, useMap } from 'hooks'
import {
  getFormErrorMessages,
  getFormIsValid,
  TFormErrorMessages,
  TFormValidation
} from 'utils/validations'
import { isNotNull, objectMatch } from '../../utils'

export type TBasicPayload = Record<string, any>

export type TUseButtonFormProps<T extends TBasicPayload = TBasicPayload, TCallbackData = ''> = {
  callback?: (data: TCallbackData) => void
  formValidations?: TFormValidation
  initialPayload: T
  mutation: UseMutationResult<any, any, any>
  onOpenChange?: (isOpen: boolean) => void
  submitButtonText?: string
  transformPayload?: (payload: T) => Record<string, any>
}

export type TButtonFormFormProps = {
  error: string
  errorMessage: string
  errors: TFormErrorMessages
  handleClose: () => void
  handleHover: () => void
  isFormValid: boolean
  isLoading: boolean
  onSubmit: () => void
  resetForm: () => void
  showError: boolean
  showForm: boolean
  submitButtonText?: string
  toggleShowForm: () => void
}

export type TButtonFormFieldsProps<T extends TBasicPayload = TBasicPayload> = {
  onChangeAttribute: <K extends keyof T>(key: K, value: T[K]) => void
  payload: T
  removeAttribute: <K extends keyof T>(key: K) => void
}

export type TButtonFormSettersProps<T extends TBasicPayload = TBasicPayload> = {
  setPayload: (newMap: T) => void
  setValidations: Dispatch<SetStateAction<TFormValidation>>
}

export const useButtonForm = <T extends TBasicPayload = TBasicPayload, TCallbackData = string>({
  submitButtonText = 'Submit',
  initialPayload,
  mutation,
  transformPayload = payload => payload,
  formValidations = {},
  callback,
  onOpenChange
}: TUseButtonFormProps<T, TCallbackData>): {
  fieldsProps: TButtonFormFieldsProps<T>
  formProps: TButtonFormFormProps
  setters: TButtonFormSettersProps<T>
} => {
  const [payload, { setAll, set, reset, remove }] = useMap<T>(initialPayload)
  const [showError, setShowError] = useState<boolean>(false)
  const [validations, setValidations] = useState<TFormValidation>(formValidations)

  // some endpoints (e.g. /user-data-service/user-integrations) return errors
  // as a string instead of as an array. that was crashing the application
  const responseErrors = mutation?.error?.response?.data?.errors
  const errorMessages = Array.isArray(responseErrors) ? responseErrors?.join('; ') : responseErrors
  const errorMessage = mutation.isError ? errorMessages || mutation.error?.message : null

  const errors = getFormErrorMessages(payload, validations)
  const error = Object.values(errors).flat().filter(isNotNull)[0]

  useDeepCompareEffect(() => {
    setAll(initialPayload)
    setValidations(formValidations)
  }, [initialPayload])

  useEffect(() => {
    if (JSON.stringify(formValidations) !== JSON.stringify(validations)) {
      setValidations(formValidations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValidations])

  const [showForm, setShowForm] = useState<boolean>(false)
  const toggleShowForm = () => {
    const showFormValue = !showForm
    setShowForm(showFormValue)
    onOpenChange?.(showFormValue)
  }

  const onSubmit = useCallback(() => {
    if (!!callback) {
      mutation.mutate(
        transformPayload(payload),
        callback && {
          onSuccess: callback
        }
      )
    } else {
      mutation.mutate(transformPayload(payload))
    }
  }, [mutation, payload, transformPayload, callback])

  const handleClose = useCallback(() => {
    const showFormValue = false
    mutation.reset()
    setAll(initialPayload)
    setShowForm(showFormValue)
    onOpenChange?.(showFormValue)
  }, [mutation, setAll, initialPayload, onOpenChange])

  const handleHover = () => {
    if (error && !objectMatch(initialPayload, payload)) {
      setShowError(true)
    }
  }

  useEffect(() => {
    setShowError(false)
  }, [payload])

  useEffect(() => {
    if (mutation.isSuccess) {
      handleClose()
    }
  }, [mutation, handleClose])

  return {
    fieldsProps: {
      payload,
      onChangeAttribute: set,
      removeAttribute: remove
    },
    formProps: {
      submitButtonText,
      resetForm: reset,
      toggleShowForm,
      handleHover,
      onSubmit,
      errorMessage,
      showForm,
      showError,
      handleClose,
      isLoading: mutation.isLoading,
      isFormValid: getFormIsValid(payload, validations),
      errors,
      error
    },
    setters: {
      setPayload: setAll,
      setValidations
    }
  }
}
