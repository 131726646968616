import { SearchParams } from '@types'
import { presence, removeAttributesWithNullValues } from 'utils'

const sortParam = {
  name: 'name',
  createdAt: '-created_at'
}

export type TUploadedPhotoSearchParams = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'sortBy' | 'sortDir'
> & {
  category?: string
  fileId?: string
  fileType?: string
  leadId?: string
}

const getFilters = ({ category, fileType, leadId, fileId }: TUploadedPhotoSearchParams) => {
  const filters = {}

  if (category) {
    filters['filter[category]'] = category
  }
  if (fileType) {
    filters['filter[file_type]'] = fileType
  }
  if (leadId) {
    filters['filter[attachable_id]'] = leadId
    filters['filter[attachable_type]'] = 'Lead'
  }
  if (fileId) {
    filters['filter[file_id]'] = fileId
  }

  return filters
}

export const getPhotoUploadsSearchParams = ({
  page,
  perPage,
  sortBy,
  sortDir,
  ...filters
}: TUploadedPhotoSearchParams) => {
  const sort = sortBy ? `${sortDir === 'desc' ? '-' : ''}${sortParam[sortBy]}` : sortParam.createdAt

  return removeAttributesWithNullValues({
    sort,
    'page[number]': presence(page),
    'page[size]': presence(perPage),
    ...getFilters(filters)
  })
}
