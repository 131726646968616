import React from 'react'
import { Table, TableResults } from 'components'
import { Agent } from 'models'
import styles from './Agents.module.scss'
import { AgentsTableHeader } from './AgentsTableHeader'
import { AgentsTableRow } from './AgentsTableRow'

type Props = {
  agents: Agent[]
  isLoading: boolean
  rowActions?: React.ReactNode
}

export const AgentsTableLegacy = ({ agents, isLoading, rowActions }: Props) => {
  return (
    <Table className={styles.table}>
      <AgentsTableHeader />
      <TableResults type="Agents" colSpan={15} isLoading={isLoading}>
        {agents?.map(agent => (
          <AgentsTableRow agent={agent} key={agent.id} rowActions={rowActions} />
        ))}
      </TableResults>
    </Table>
  )
}
