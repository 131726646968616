// @ts-strict
import { List } from 'components'
import { MarketplaceProgramBadge } from 'content/MarketplaceProgram'
import { useMarketplacePrograms } from 'lookups'
import { MarketplaceProgram } from 'models'
import { DEFAULT_STRING } from 'utils'
import styles from './MarketplaceProgramsBadges.module.scss'

type Props = {
  marketplacePrograms?: MarketplaceProgram[]
}

export const MarketplaceProgramsBadges = ({ marketplacePrograms }: Props) => {
  const { marketplacePrograms: allMarketplacePrograms } = useMarketplacePrograms()
  if (!marketplacePrograms?.length) {
    return <>{DEFAULT_STRING}</>
  }
  const badgeSlugs = marketplacePrograms?.map(program => program.slug).filter(Boolean)

  const sortedMarketplacePrograms = allMarketplacePrograms.filter(program =>
    badgeSlugs.includes(program.slug)
  )

  return (
    <List className={styles.marketplaceProgramList}>
      {sortedMarketplacePrograms
        ? sortedMarketplacePrograms.map(program => (
            <MarketplaceProgramBadge program={program} key={program.slug} />
          ))
        : null}
    </List>
  )
}
