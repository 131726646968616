// @ts-strict

import { Badge } from 'components'
import { useLeadUserTypes } from 'lookups'
import type { Lead as LegacyLead } from 'models'
import { LeadModel } from 'models/v2/Lead'
import { LeadInterface } from 'models/v2/Lead'
import { cx } from 'utils'
import styles from './LeadBadge.module.scss'

type TProps = {
  lead: LeadInterface | LegacyLead
}

export const LeadBadge = ({ lead }: TProps) => {
  const { getLeadUserType } = useLeadUserTypes()
  const leadUserType = getLeadUserType(lead?.userType)
  const label = leadUserType?.name || 'Unknown'
  const className = leadUserType?.value || ''

  return (
    <Badge
      title={label}
      className={cx(styles.default, styles[className])}
      strikeThru={LeadModel.isFailedStage(lead?.stage)}
    >
      {leadUserType?.abbreviation || '?'}
    </Badge>
  )
}
