import { FileUpload } from 'models'
import { User } from 'models/User'
import { EnvelopeResponse } from 'schema'
import { CCCashOfferLead, CCTradeInLead, getCCLead } from '../Lead'
import { CashOfferLead } from '../providables'
import { Recipient } from './Recipient'

export class Envelope {
  attachable: CCCashOfferLead | CCTradeInLead
  completedAt: string
  createdAt: string
  createdBy: User
  displayStatus: string
  files: FileUpload[]
  envelopeDetailsUrl: string
  name: string
  recipients: Recipient[]
  sentAt: string
  subAttachable: CashOfferLead
  status: 'completed' | 'sent'
  updatedAt: string
  voidedAt: string
  templateSlug: string
  constructor(envelopeAttributes: Envelope) {
    Object.assign(this, envelopeAttributes)
  }
  static create(envelopeResponse: Potential<EnvelopeResponse>): Potential<Envelope> {
    if (!envelopeResponse) {
      return
    }
    const attachable = getCCLead(envelopeResponse?.attachable)
    const createdBy = User.create(envelopeResponse.createdBy)
    const files = envelopeResponse.files?.map(FileUpload.create)
    const recipients = envelopeResponse.recipients?.map(Recipient.create)
    const subAttachable = CashOfferLead.create(envelopeResponse?.subAttachable)
    return new Envelope({
      ...envelopeResponse,
      attachable,
      createdBy,
      files,
      recipients,
      subAttachable
    })
  }
}
