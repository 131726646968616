// @ts-strict
import { LeadResponse } from 'schema'
import { AgentMatchingLead } from '../AgentMatchingLead'

export class SellerLead extends AgentMatchingLead {
  modelName? = 'SellerLead'

  static create(sellerLeadResponse: Potential<LeadResponse>): Potential<SellerLead> {
    if (!sellerLeadResponse) {
      return
    }

    return new SellerLead(AgentMatchingLead.mapAgentMatchingLeadResponse(sellerLeadResponse))
  }
}
