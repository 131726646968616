// @ts-strict
import { User } from 'models'
import { LeadUserResponse } from 'schema'
import { Model } from '../Model'
import { getPrettyName } from './utils'

export class LeadUser extends Model {
  id!: string
  createdAt?: string
  readonly prettyName!: string
  role!: string
  user?: User

  constructor(attributes: Omit<LeadUser, 'prettyName' | keyof Model>) {
    super()
    Object.assign(this, attributes)
    this.prettyName = getPrettyName(this)
  }

  static create(leadUserResponse: Potential<LeadUserResponse>): Potential<LeadUser> {
    if (!leadUserResponse) {
      return
    }
    const user = User.create(leadUserResponse.user)!
    return new LeadUser({ ...leadUserResponse, user })
  }
}
