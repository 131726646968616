// @ts-strict
import { TableHeaderCell as Th, TableHeaderRow as Tr } from 'components/Table'
import style from './AgentTeamMembershipsTable.module.scss'

export const AgentTeamMembershipsTableHeader = () => (
  <Tr>
    <Th className={style.name}>Name</Th>
    <Th className={style.email}>E-mail</Th>
    <Th className={style.role}>Role</Th>
    <Th className={style.leadRouting}>Lead Routing Member</Th>
    <Th className={style.fallbackAgent}>Fallback Agent</Th>
    <Th className={style.leadType}>Lead Type</Th>
    <Th className={style.warmTransfer}>Warm Transfer Member</Th>
    <Th className={style.enrollments}>Enrollments</Th>
    <Th className={style.actions}></Th>
  </Tr>
)
