import { TLeadStageSetting } from '../lookupLeadStages'

export type TEscrowLeadStagesSlug =
  | 'called_left_msg'
  | 'requested'
  | 'unqualified'
  | 'bad_number'
  | 'introduced'
  | 'in_pre_escrow'
  | 'not_transacting'
  | 'intro_pending'
  | 'connected'
  | 'closed_paid'
  | 'agent_left_vm'
  | 'meeting_scheduled'
  | 'pending_failed'
  | 'not_interested'
  | 'new'
  | 'pending_order'
  | 'awaiting_agent'
  | 'failed'
  | 'closed'
  | 'duplicate'
  | 'in_escrow'
  | 'processing'
  | 'claimed'

type TEscrowLeadStageSetting = TLeadStageSetting<TEscrowLeadStagesSlug>

export const escrowLeadStagesSettings: TEscrowLeadStageSetting[] = [
  {
    name: 'Left Message',
    slug: 'called_left_msg'
  },
  {
    name: 'Requested',
    slug: 'requested'
  },
  {
    name: 'Unqualified',
    slug: 'unqualified'
  },
  {
    name: 'Bad Number',
    slug: 'bad_number'
  },
  {
    name: 'Introduced',
    slug: 'introduced'
  },
  {
    name: 'In Pre-Escrow',
    slug: 'in_pre_escrow'
  },
  {
    name: 'Not Transacting',
    slug: 'not_transacting'
  },
  {
    name: 'Intro Pending',
    slug: 'intro_pending'
  },
  {
    name: 'Connected',
    slug: 'connected'
  },
  {
    name: 'Closed Paid',
    slug: 'closed_paid'
  },
  {
    name: 'Agent Left VM',
    slug: 'agent_left_vm'
  },
  {
    name: 'Meeting Scheduled',
    slug: 'meeting_scheduled'
  },
  {
    name: 'Pending Failed',
    slug: 'pending_failed'
  },
  {
    name: 'Not Interested',
    slug: 'not_interested'
  },
  {
    name: 'New',
    slug: 'new'
  },
  {
    name: 'Pending Order',
    slug: 'pending_order'
  },
  {
    name: 'Awaiting Agent',
    slug: 'awaiting_agent'
  },
  {
    name: 'Failed',
    slug: 'failed'
  },
  {
    name: 'Closed',
    slug: 'closed'
  },
  {
    name: 'Duplicate',
    slug: 'duplicate'
  },
  {
    name: 'In Escrow',
    slug: 'in_escrow'
  },
  {
    name: 'Processing',
    slug: 'processing'
  },
  {
    name: 'Claimed',
    slug: 'claimed'
  }
]
