import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './SectionFooter.module.scss'

type TProps = {
  children: ReactNode
  className?: string
}

export const SectionFooter = ({ className, children }: TProps) => {
  return (
    <footer data-testid="sectionFooter" className={cx(styles.footer, className)}>
      {children}
    </footer>
  )
}
