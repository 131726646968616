// @ts-strict

import { MapboxApiResponse, MapboxAutocompleteResponse } from '../types'
import { normalizeMapboxApiResponse } from './normalizeMapboxApiResponse'

export const structureResponseForAutoComplete = ({
  features
}: {
  features: MapboxApiResponse['features']
}): MapboxAutocompleteResponse => {
  if (!features || !features.length) {
    return []
  }

  return features.map(feature => {
    const normalizedFeature = normalizeMapboxApiResponse(feature)
    return {
      title: normalizedFeature.description,
      id: feature.id,
      place: normalizedFeature
    }
  })
}
