import {
  FormField,
  InlineEditPhoneInput,
  InlineEditSelect,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { useUserController } from 'hooks'
import { lennarDivisionOptions } from 'lookups'
import { dataOrDash, formatPhone } from 'utils'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import { getBBYSLeadInputProps } from './utils'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>

export const LenderSalesConsultantSection = ({ lead, updateBBYSLead, updateLead }: Props) => {
  const { newHomeConsultant, bbysLead } = lead

  const newHomeConsultantPhone = newHomeConsultant?.user?.phoneNumbers[0]?.number
    ? formatPhone(newHomeConsultant?.user?.phoneNumbers[0]?.number)
    : ''

  const { userUpdate } = useUserController(newHomeConsultant?.user?.id)
  const { handleUpdateBBYSLead } = getBBYSLeadInputProps(bbysLead, updateBBYSLead)

  const updateUser = (payload: any) => {
    userUpdate.mutateAsync(payload)
  }

  if (lead.bbysLead?.sourcePartner?.slug === 'lennar') {
    return (
      <Sections title="New Home Consultant">
        <Section>
          <FormField title="Name" inline>
            {dataOrDash(newHomeConsultant?.user?.name)}
          </FormField>
          <FormField title="Division" inline>
            <InlineEditSelect
              options={lennarDivisionOptions}
              value={bbysLead?.division}
              onChange={val => handleUpdateBBYSLead('division', val)}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="Email Address" inline>
            <InlineEditTextInput
              onBlur={(value: string) => updateUser({ email: value })}
              value={dataOrDash(newHomeConsultant?.user?.email)}
            />
          </FormField>

          <FormField title="Phone Number" inline>
            <InlineEditPhoneInput
              onBlur={(value: string) => updateUser({ phone_mobile: value })}
              value={dataOrDash(newHomeConsultantPhone)}
            />
          </FormField>
        </Section>
      </Sections>
    )
  }
  return <></>
}
