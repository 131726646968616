import {
  Block,
  Contents,
  ExternalLink,
  FormField,
  FormFieldSet,
  List,
  MapBox,
  Section,
  Sections
} from 'components'
import { isMortgageLead, Lead } from 'models'
import { d, formatDate, formatPrice, titleize, toSearchParam } from 'utils'

type LeadDetailsProps = {
  lead: Lead
}

export const LeadDetails = ({ lead }: LeadDetailsProps) => {
  return (
    <Contents>
      <Sections title="Lead Details">
        <Section>
          <FormFieldSet>
            <FormField title="Created At" inline>
              {formatDate(lead.createdAt)}
            </FormField>
            <FormField title="Last Call Date" inline>
              {formatDate(lead.lastCallDate)}
            </FormField>
            <FormField title="Price" inline>
              {formatPrice(lead.price)}
            </FormField>
            <FormField title="Timeline" inline>
              {lead.humanTimeline}
            </FormField>
            <FormField title="Agent Comm Pref" inline>
              {lead.callReview?.agentCommunicationPreference}
            </FormField>
            <FormField title="Best Contact" inline>
              {lead.callReview?.bestContact}
            </FormField>
            <FormField title="Need To Meet Next Day" inline>
              {lead.callReview?.needToMeetInNextDay}
            </FormField>
          </FormFieldSet>
        </Section>
        <Section>
          <FormField title="Stage" inline>
            {titleize(lead.stage)}
          </FormField>
          <FormField title="Source" inline>
            {`${titleize(lead.source)} (${titleize(lead.sourcePageType)})`}
          </FormField>
          <FormField title="Relationship" inline>
            {lead.callReview?.relationship}
          </FormField>
          <FormField title="Auto-Intro Count" inline>
            {lead.callReview?.autoIntroCount}
          </FormField>
          <FormField title="High Touch" inline>
            {lead.callReview?.highTouch}
          </FormField>
          <FormField title="Brokerages To Exclude" inline>
            {lead.callReview?.brokeragesToExclude.join(', ')}
          </FormField>
        </Section>
      </Sections>
      <Sections title="Property Details">
        <Section>
          <FormField title="Full Address" inline>
            <Block title={lead.fullAddress} />
          </FormField>
          <FormField data-testid="Property Type" title="Property Type" inline>
            {d(lead.propertyType)}
          </FormField>
          <FormField data-testid="Foreclosure" title="Foreclosure" inline>
            {lead.callReview?.foreclosure}
          </FormField>
          {isMortgageLead(lead) ? (
            <FormField title="Home Use Type" inline>
              {lead.callReview?.homeUseType}
            </FormField>
          ) : null}
          <FormField title="External Links" inline>
            <List>
              <ExternalLink
                href={`https://www.google.com/search?q=${toSearchParam(lead.fullAddress)}`}
              >
                Google Address
              </ExternalLink>
              <ExternalLink
                href={`http://www.zillow.com/homes/${toSearchParam(lead.fullAddress)}_rb`}
              >
                Zillow Address
              </ExternalLink>
            </List>
          </FormField>
        </Section>
        <Section>
          {lead.latitude && lead.longitude ? (
            <MapBox center={lead.toPoint()} markers={[lead.toMarker()]} />
          ) : (
            <FormField title="Map" inline>
              Latitude and Longitude are missing
            </FormField>
          )}
        </Section>
      </Sections>
    </Contents>
  )
}
