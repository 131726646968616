import { marketplaceProgramSlugs as slugs } from 'lookups'
import { MarketplaceProgram } from 'models'
import { getMarketplaceProgramsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchMarketplacePrograms = async () => {
  const response = await getRequest(
    getMarketplaceProgramsUrl({
      searchParams: {
        include: 'states',
        'fields[state]': 'code,name'
      }
    })
  )
  const marketplacePrograms: MarketplaceProgram[] = await deserializeAsync(response.data, {
    transform: MarketplaceProgram.create
  })
  marketplacePrograms.sort((a, b) => {
    if (!a.slug || !b.slug) {
      return 0
    }
    return slugs.indexOf(a.slug) - slugs.indexOf(b.slug)
  })
  return marketplacePrograms
}
