// @ts-strict
import { AddIconButton, DeleteIconButton } from 'components/Button/IconButton'
import { useLeadController } from 'controllers'
import { Lead, Order } from 'models'
import { alertError } from 'services'
import { useAttachLead } from '../AttachLeadList/hooks/useAttachLead'
import styles from './AttachLeadPopup.module.scss'

type TProps = {
  lead: Lead
  order: Order
  selectedLeadId: string
}

export const AttachLeadAction = ({ order, lead, selectedLeadId }: TProps) => {
  const { attachLeadToOrder, detachLeadFromOrder } = useLeadController(
    lead.id,
    lead.userType!,
    false
  )
  const {
    isDetachMode,
    setIsDetachMode,
    shouldShowAttachButton,
    shouldDisableAttachButton,
    defaultAttachButtonTitle,
    disabledAttachButtonTitle
  } = useAttachLead({ lead, order, selectedLeadId })
  const isLoadingAttachButton =
    lead.id === attachLeadToOrder.variables?.leadId && attachLeadToOrder.isLoading
  const isLoadingDetachButton =
    lead.id === detachLeadFromOrder.variables?.leadId && detachLeadFromOrder.isLoading

  const handleAttachLead = () => {
    const service = isDetachMode ? detachLeadFromOrder : attachLeadToOrder
    service.mutate(
      { orderId: order.id, leadId: lead.id },
      {
        onSuccess: () => {
          setIsDetachMode(state => !state)
        },
        onError: () => alertError()
      }
    )
  }

  if (selectedLeadId === lead.id) {
    return <div className={styles.currentBadge}>CURRENT</div>
  }

  return (
    <>
      {shouldShowAttachButton &&
        (isDetachMode ? (
          <DeleteIconButton
            title={disabledAttachButtonTitle || defaultAttachButtonTitle}
            className={styles.attachLeadButton}
            onClick={handleAttachLead}
            isLoading={isLoadingDetachButton}
            disabled={shouldDisableAttachButton}
          />
        ) : (
          <AddIconButton
            title={disabledAttachButtonTitle || defaultAttachButtonTitle}
            className={styles.attachLeadButton}
            onClick={handleAttachLead}
            isLoading={isLoadingAttachButton}
            disabled={shouldDisableAttachButton}
          />
        ))}
    </>
  )
}
