import { Section } from 'components'
import { FieldFormField } from '../FieldFormField'
import { useFields } from '../useFields'

export const CashOfferInEscrowSell = () => {
  useFields({
    required: ['ipTargetCloseOfSellEscrowAt', 'incomingPropertyOpenSellEscrowAt']
  })

  return (
    <Section>
      <FieldFormField field="ipTargetCloseOfSellEscrowAt" />
      <FieldFormField field="incomingPropertyOpenSellEscrowAt" />
    </Section>
  )
}
