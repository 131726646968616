export const titleize = (str: Potential<string>, separator?: string | RegExp) => {
  if (!str) {
    return ''
  }
  const sep = separator && separator.constructor !== Number ? separator : /[\s_-]+/g
  return str
    .split(sep)
    .filter(Boolean)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}
