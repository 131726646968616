import { ReactNode } from 'react'
import { Icon } from '../Icon'
import styles from './ExternalLink.module.scss'

type Props = {
  children: ReactNode
  href?: string
}

export const ExternalLink = ({ href, children, ...props }: Props) => {
  if (!href) {
    return <>{children}</>
  }
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.externalLink}
      {...props}
    >
      {children}
      <Icon.ExternalLink />
    </a>
  )
}
