import { ReactNode } from 'react'
import style from './ButtonFormContents.module.scss'

type TProps = {
  children: ReactNode
}

export const ButtonFormContents = ({ children }: TProps) => (
  <div className={style.buttonFormContents}>{children}</div>
)
