import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Contents.module.scss'

type TProps = {
  actions?: ReactNode
  children?: ReactNode
  className?: string
  subtitle?: string
  title?: string
}

export const Contents = ({ className, title, subtitle, actions, children }: TProps) => {
  if (!(title || children)) {
    return null
  }

  const titleTag = title ? <div className={cx(styles.title, 'contents-title')}>{title}</div> : null
  const subtitleTag = subtitle ? (
    <div className={cx(styles.subtitle, 'contents-subtitle')}>{subtitle}</div>
  ) : null
  return (
    <div className={cx(styles.contents, className)}>
      {title || subtitle || actions ? (
        <div className={styles.header}>
          {title || subtitle ? (
            <div className={styles.titles}>
              {titleTag}
              {subtitleTag}
            </div>
          ) : null}
          {actions ? <div className={styles.actions}>{actions}</div> : null}
        </div>
      ) : null}
      {children}
    </div>
  )
}
