import { useQueryClient } from 'react-query'
import { IconButton } from '@foundation/components'
import { TableActions, TableCell as Td, TableRow as Tr } from 'components'
import { UserImpersonateButton } from 'content/User/UserImpersonateButton'
import { useLeadTeamContext } from 'contexts/LeadTeam'
import { LEADS_CACHE_KEY } from 'hooks'
import { LeadUser } from 'models'
import { alertError } from 'services'
import { ImpersonateIconButton } from 'templates/ImpersonateIconButton'
import { formatDate, titleize } from 'utils'

type Props = {
  leadUser: LeadUser
}

export const LeadUsersListRow = ({ leadUser }: Props) => {
  const queryCache = useQueryClient()
  const { removeTeamMember } = useLeadTeamContext()
  const requestTeamMemberId = removeTeamMember.variables
  const isLoadingRemoveButton = leadUser.id === requestTeamMemberId && removeTeamMember.isLoading

  const clearCaches = () => {
    queryCache.invalidateQueries(LEADS_CACHE_KEY)
  }

  const defaultCallback = () => {
    clearCaches()
  }

  const handleRemoveTeamMember = () => {
    removeTeamMember.mutate(leadUser.id, {
      onSuccess: () => defaultCallback(),
      onError: () => alertError()
    })
  }

  return (
    <Tr data-testid={`leaderUser${leadUser.id}`}>
      <Td>{leadUser.user?.name}</Td>
      <Td>{titleize(leadUser.role)}</Td>
      <Td>{formatDate(leadUser.createdAt)}</Td>
      <TableActions>
        <IconButton
          icon="Close"
          variant="outline"
          size="xs"
          onClick={handleRemoveTeamMember}
          isLoading={isLoadingRemoveButton}
          title="remove lead user"
        />
        {!!leadUser.user && (
          <UserImpersonateButton userId={leadUser.user?.id} as={ImpersonateIconButton} />
        )}
      </TableActions>
    </Tr>
  )
}
