import { Field, Section, Sections } from 'components'
import { useAgentLeadController } from 'hooks'
import { AgentLead, AgentProviderLead } from 'models'
import { dataOrDash, formatDateTime } from 'utils'

type Props = {
  agentProviderLead: AgentProviderLead
}

const ALGO_VERSION_DESCRIPTION = {
  '0.1': 'Fallback Query',
  '5.1': 'Standard',
  '5.2': 'TAM'
}

export const AgentProviderLeadDetails = ({ agentProviderLead }: Props) => {
  const { agentLeadDetailsQuery } = useAgentLeadController(agentProviderLead.agentLead.id)

  if (agentLeadDetailsQuery.isError) {
    return <div>Something went wrong</div>
  }

  if (agentLeadDetailsQuery.isIdle) {
    agentLeadDetailsQuery.refetch()
  }

  if (agentLeadDetailsQuery.isLoading || agentLeadDetailsQuery.isIdle) {
    return <div>Loading referral details...</div>
  }

  return (
    <>
      <IntroDetailsSection agentLeadData={agentLeadDetailsQuery.data as AgentLead} />
    </>
  )
}

const IntroDetailsSection = ({ agentLeadData }: { agentLeadData: AgentLead }) => {
  return (
    <Section title="Intro Information">
      <Sections>
        <Section>
          <Field title="Algo version">
            {dataOrDash(agentLeadData.algoVersion)} -{' '}
            {dataOrDash(ALGO_VERSION_DESCRIPTION[agentLeadData.algoVersion])}
          </Field>
        </Section>
        <Section>
          <Field title="Intro Sent At">
            {dataOrDash(formatDateTime(agentLeadData.introSentAt))}
          </Field>
        </Section>
        <Section>
          <Field title="Auto-intro Sent At">
            {dataOrDash(formatDateTime(agentLeadData.autoIntroSentAt))}
          </Field>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <Field title="Intro Type">{dataOrDash(agentLeadData.introTracking?.introType)}</Field>
        </Section>
        <Section>
          <Field title="Intro Detail">{dataOrDash(agentLeadData.introTracking?.introDetail)}</Field>
        </Section>
        <Section>
          <Field title="Qualification Type">
            {dataOrDash(agentLeadData.introTracking?.qualificationType)}
          </Field>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <Field title="Qualification Method">
            {dataOrDash(agentLeadData.introTracking?.qualificationMethod)}
          </Field>
        </Section>
        <Section>
          <Field title="Claim Method">{dataOrDash(agentLeadData.introTracking?.claimMethod)}</Field>
        </Section>
        <Section />
      </Sections>
    </Section>
  )
}
