// @ts-strict
import {
  ActionBar,
  ActionButton,
  Contents,
  Grid,
  PaginationParams,
  SearchStatusPrompt
} from 'components'
import { LeadUploadPhotosButtonForm } from 'content/Lead'
import { PhotoUploadDeleteButtonForm, PhotoUploadGridItem } from 'content/PhotoUpload'
import { useParamsContext } from 'contexts'
import { usePhotoUploadsController } from 'controllers'
import { Lead } from 'models'
import style from './LeadPhotoUploadsGallery.module.scss'
import { useSelected } from './utils'
type TProps = {
  lead: Lead
}

export const LeadPhotoUploadsGallery = ({ lead }: TProps) => {
  const { params } = useParamsContext()
  const { photoUploadsQuery } = usePhotoUploadsController({
    ...params,
    leadId: lead.id
  })
  const {
    selected,
    isSelected,
    toggleSelected,
    hasSelection,
    selectAll,
    clearSelected,
    areSelected
  } = useSelected()
  const { items: photos, pageCount, totalCount } = photoUploadsQuery?.data || {}

  const actions = (
    <ActionBar>
      {hasSelection && (
        <PhotoUploadDeleteButtonForm multiple ids={selected} callback={clearSelected} />
      )}
      {!hasSelection && <LeadUploadPhotosButtonForm lead={lead} />}
    </ActionBar>
  )

  const secondaryActions = (
    <div className={style.secondaryActionsWrapper}>
      <SearchStatusPrompt count={totalCount} type="Photo" />

      <div className={style.secondaryActions}>
        <div className={style.item}>{selected.length} SELECTED</div>
        <ActionButton
          className={style.item}
          onClick={() => {
            selectAll(photos)
          }}
          disabled={areSelected(photos)}
        >
          Select All
        </ActionButton>
        <ActionButton className={style.item} onClick={clearSelected} disabled={!hasSelection}>
          Clear
        </ActionButton>
      </div>
    </div>
  )
  return (
    <Contents title="Photos" actions={actions}>
      {secondaryActions}
      <Grid>
        {photos?.map(photo => (
          <PhotoUploadGridItem
            key={photo.id}
            photo={photo}
            isSelected={isSelected(photo.id)}
            onSelect={() => {
              toggleSelected(photo.id)
            }}
          />
        ))}
      </Grid>
      {pageCount && <PaginationParams totalPages={pageCount} />}
    </Contents>
  )
}
