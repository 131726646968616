// @ts-strict

import { useMutation, useQuery, useQueryClient } from 'hooks'
import { LeadGroupableType } from 'models'
import { LeadGroupableAttributes } from 'schema'
import { fetchLeadGroupables, postLeadGroupable } from 'services'

export const useLeadGroupablesController = (leadGroupableType: LeadGroupableType) => {
  const queryCache = useQueryClient()
  const cacheKey = `lead-groupable-${leadGroupableType}`
  const defaultCallback = () => {
    queryCache.invalidateQueries([cacheKey])
  }

  const {
    data: leadGroupables,
    isLoading,
    isError
  } = useQuery([cacheKey], () => fetchLeadGroupables(leadGroupableType))

  const createLeadGroupable = useMutation(
    (payload: Partial<LeadGroupableAttributes>) => postLeadGroupable(leadGroupableType, payload),
    {
      onSuccess: () => defaultCallback()
    }
  )

  return {
    leadGroupables,
    createLeadGroupable,
    isLoading,
    isError
  }
}
