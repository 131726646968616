// @ts-strict
import { Block, TableCell as Td } from 'components'
import { dataOrDash } from 'utils'
import type { UseTableCellProps } from './useTable'

export type TableCellSimpleProps = UseTableCellProps<string, string>

export const TableCellSimple = ({
  slug,
  value,
  attachedColumnValue,
  ...tdProps
}: UseTableCellProps) => (
  <Td {...tdProps}>
    <Block title={dataOrDash(value)} subtitle={attachedColumnValue} />
  </Td>
)
