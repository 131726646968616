// @ts-strict
import { TransactionTeam, User } from 'models'
import { SubTeamMembership } from 'models/SubTeamMembership'
import { TeamMembershipResponse } from 'schema'
import { Model } from '../Model'

export class TeamMembership extends Model {
  createdAt?: string
  id!: string
  role!: string
  team?: TransactionTeam
  user!: User
  subTeamMemberships?: Potential<SubTeamMembership[]>

  constructor(attributes?: Omit<TeamMembership, keyof Model>) {
    super()
    Object.assign(this, attributes)
  }

  static create(
    teamMembershipAttributes: Potential<TeamMembershipResponse>
  ): Potential<TeamMembership> {
    if (!teamMembershipAttributes) {
      return
    }
    const user = User.create(teamMembershipAttributes.user)!
    const team = TransactionTeam.create(teamMembershipAttributes.team)
    const subTeamMemberships = teamMembershipAttributes.subTeamMemberships
      ? (teamMembershipAttributes.subTeamMemberships
          .map(stm => SubTeamMembership.create(stm))
          .filter(Boolean) as SubTeamMembership[])
      : undefined
    return new TeamMembership({ ...teamMembershipAttributes, user, team, subTeamMemberships })
  }
}
