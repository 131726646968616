import { useEffect } from 'react'
import { Box, Spinner } from '@foundation/components'
import { Contents } from 'components/Contents'
import { InvestorBilling, PaymentInformation } from 'content/Investor/InvestorBilling'
import { InvestorPayPerLeadSettingsProvider, useInvestorContext } from 'contexts'
import { useInvestorPayPerLeadSettingsController } from 'controllers'
import { useDocumentTitle } from 'hooks'
import { Investor } from 'models'

export const InvestorBillingTab = () => {
  const { investor } = useInvestorContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${investor.name} - Investor`), [investor, setTitle])

  const { investorPayPerLeadSettingsQuery } = useInvestorPayPerLeadSettingsController(investor.id)
  const { data: providerPplData }: { data: Investor } = investorPayPerLeadSettingsQuery

  if (investorPayPerLeadSettingsQuery.isLoading) {
    return (
      <Box margin="$3">
        <Spinner size="xs" label="Loading Pay-Per-Lead Information..." />
      </Box>
    )
  }
  return (
    <InvestorPayPerLeadSettingsProvider investorId={investor?.id}>
      <Contents>
        <InvestorBilling investor={providerPplData} />
        <PaymentInformation investor={providerPplData} />
      </Contents>
    </InvestorPayPerLeadSettingsProvider>
  )
}
