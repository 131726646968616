import { FloatingOverlay } from '@floating-ui/react'
import { css, styled } from '@foundation/themes/styles'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    height: 100%;
    z-index: ${theme.zIndices.overlay - 1};
  `}
`

export const Sidebar = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 5rem;
    background-color: ${theme.colors.neutral1};
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.2), 0 2px 4px -2px rgb(0 0 0 / 0.2);
    @media (max-width: 414px) {
      display: none;
    }
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 55.6rem;
    background-color: ${theme.colors.white};
  `}
`

export const Body = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 ${theme.space.$6};
  `}
`

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.space.$5} ${theme.space.$6};
  `}
`

export const MenuButton = styled.button<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: none;
    padding: ${theme.space.$5} ${theme.space.$4};
    background-color: transparent;
    width: 100%;
    cursor: pointer;
    transition: 0.3s;

    ${!isActive &&
    css`
      &:hover {
        background-color: ${theme.colors.neutral3};
      }
    `}

    ${isActive && `background-color: ${theme.colors.neutral5};`}
  `}
`

export const Overlay = styled(FloatingOverlay)`
  ${({ theme }) => css`
    z-index: ${theme.zIndices.overlay};
    background: rgba(0, 0, 0, 0.3);
  `}
`
