// @ts-strict
import { Activity } from 'models'
import { TradeInLeadStageUpdateResponse } from 'schema'
import { d } from 'utils'

type TTradeInLeadStageUpdateConstructor = Omit<TradeInLeadStageUpdate, 'toActivity'>

export class TradeInLeadStageUpdate {
  id!: string
  notes?: string
  oldStage?: string
  newStage?: string
  createdAt?: string
  updatedBy?: string
  ae?: {
    name: string
  }

  constructor(tradeInLeadStageUpdateAttributes: TTradeInLeadStageUpdateConstructor) {
    Object.assign(this, tradeInLeadStageUpdateAttributes)
  }

  static create(
    tradeInLeadStageUpdateResponse: Potential<TradeInLeadStageUpdateResponse>
  ): Potential<TradeInLeadStageUpdate> {
    if (!tradeInLeadStageUpdateResponse) {
      return
    }
    return new TradeInLeadStageUpdate(tradeInLeadStageUpdateResponse)
  }

  toActivity() {
    return new Activity({
      id: this.createdAt || '',
      title: `Stage Updated by ${d(this.updatedBy)} ${
        this.updatedBy === 'ae' && this.ae?.name ? this.ae.name : ''
      }`,
      body: this.notes,
      transitions: [d(this.oldStage), d(this.newStage)],
      occurredAt: this.createdAt
    })
  }
}
