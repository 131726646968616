import { EmployeeRoles, EmployeeSummary, EmployeeTeams } from 'content/Employee'
import { Employee } from 'models'
import { EmployeeSettlementAgencyTeams } from '../EmployeeSettlemntAgencyTeams'

type Props = {
  className?: string
  employee: Employee
}

export const EmployeeAside = ({ employee, className }: Props) => {
  return (
    <div className={className}>
      <EmployeeSummary employee={employee} />
      <EmployeeRoles employee={employee} />
      <EmployeeTeams employee={employee} />
      <EmployeeSettlementAgencyTeams employee={employee} />
    </div>
  )
}
