// @ts-strict
import { Button } from '@foundation/components'
import { ButtonForm, useButtonForm } from 'components'
import { useAgentsContext } from 'contexts'
import { AgentFormFields } from './AgentFormFields'
import { agentFormValidations, TAgentFormPayload, transformPayload } from './utils'

const initialPayload: TAgentFormPayload = {
  firstName: '',
  lastName: '',
  phoneMobile: '',
  phoneOffice: '',
  email1: '',
  email2: '',
  licenseNumber: '',
  stateId: '',
  brokerage: '',
  website: '',
  assignedAreaId: ''
}

export const AgentCreateButtonForm = () => {
  const { createAgent } = useAgentsContext()

  const { fieldsProps, formProps } = useButtonForm<TAgentFormPayload>({
    initialPayload,
    mutation: createAgent,
    transformPayload: transformPayload,
    formValidations: agentFormValidations
  })

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={formProps.toggleShowForm}>
        Create Agent
      </Button>
      <ButtonForm hideTriggerButton title="Create Agent" {...formProps}>
        <AgentFormFields {...fieldsProps} />
      </ButtonForm>
    </>
  )
}
