// @ts-strict
import { Link } from 'classes'
import { PhoneNumber, Role, TeamMembership, UserIntegration } from 'models'
import { EmployeeResponse } from 'schema'
import { sorts } from 'utils'
import { User } from '..'
import { Model } from '../../Model'

type TEmployeeConstructor = Omit<
  Employee,
  'link' | 'pathname' | 'toOption' | 'isEmployee' | keyof Model
>

export class Employee extends User {
  archived?: string
  confirmationSentAt?: string
  confirmedAt?: string
  createdAt?: string
  currentSignInAt?: string
  currentSignInIp?: string
  lastSignInAt?: string
  lastSignInIp?: string
  pictureUrl?: string
  pureCloudUserId?: string
  region?: string
  signInCount?: string
  updatedAt?: string
  phoneNumbers?: PhoneNumber[] = []
  roles?: Role[] = []
  teamMemberships?: TeamMembership[]
  userIntegrations?: UserIntegration[]

  constructor(employeeAttributes: TEmployeeConstructor) {
    super(employeeAttributes)
    Object.assign(this, employeeAttributes)
    this.roles = employeeAttributes.roles?.sort(sorts.name)
  }

  static create(employeeResponse: Potential<EmployeeResponse>): Potential<Employee> {
    if (!employeeResponse) {
      return
    }

    const phoneNumbers = (employeeResponse.phoneNumbers?.map(PhoneNumber.create) ||
      []) as PhoneNumber[]
    const roles = (employeeResponse.roles?.map(Role.create) || []) as Role[]
    const teamMemberships = (employeeResponse.teamMemberships
      ?.map(TeamMembership.create)
      .filter(Boolean) || []) as TeamMembership[]
    const userIntegrations = (employeeResponse.userIntegrations?.map(UserIntegration.create) ||
      []) as UserIntegration[]
    const name =
      employeeResponse.name ||
      [employeeResponse.firstName, employeeResponse.lastName].filter(Boolean).join(' ')

    return new Employee({
      ...employeeResponse,
      name,
      phoneNumbers,
      roles,
      teamMemberships,
      userIntegrations
    })
  }

  get link() {
    return new Link({
      name: this.name,
      pathname: this.pathname
    })
  }

  get pathname() {
    return `/admin/employees/${this.id}`
  }

  // In the future we'll need to create a new User model that contains all the Employee attributes.
  // There are pages fetching users that may be an Employee.
  get isEmployee() {
    return this.roles?.some(role => role.isEmployee!!)
  }
}
