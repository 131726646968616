import { titleize } from '../titleize'

// maybe try https://www.npmjs.com/package/parse-address someday

export const structureAddress = (address: string | undefined) => {
  if (!address || !address.length) {
    return {}
  }
  const parts = address.split(',').map(c => c.trim())
  const words = address.split(' ')
  if (words.length <= 4 && parts.length <= 3) {
    let cityParts = parts.slice(0, parts.length - 1)
    if (!/[a-z]/.test(address)) {
      // All uppercase, clean it up
      cityParts = cityParts.map(c => titleize(c.toLowerCase()))
    }
    const city = cityParts.join(', ')
    const state = parts[parts.length - 1]
    return {
      city,
      state,
      cityState: [city, state].filter(c => c.length).join(', ')
    }
  }

  let cityParts = parts.slice(1, parts.length - 1)
  if (!/[a-z]/.test(address)) {
    // All uppercase, clean it up
    cityParts = cityParts.map(c => titleize(c.toLowerCase()))
  }
  const city = cityParts.join(', ')
  const state = parts[parts.length - 1]
  return {
    street: titleize(parts[0].toLowerCase()),
    city,
    state,
    cityState: [city, state].join(', ')
  }
}
