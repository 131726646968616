import { Section, Sections } from 'components'
import { useFields } from '..'
import { FieldFormField } from '../FieldFormField'

export const CCTradeInApplicationComplete = () => {
  useFields({
    required: [
      'departingPropertyHomeownerAssociation',
      'departingPropertySolarPanels',
      'departingPropertyAgentValuationReceivedAt',
      'departingPropertyExpectedListingPrice',
      'agentValuation'
    ]
  })
  return (
    <>
      <Sections title="DR Property Information">
        <Section>
          <FieldFormField field="departingPropertyHomeownerAssociation" />
        </Section>
        <Section>
          <FieldFormField field="departingPropertySolarPanels" />
        </Section>
      </Sections>
      <Sections title="DR Property Valuation">
        <Section>
          <FieldFormField field="departingPropertyAgentValuationReceivedAt" />
        </Section>
        <Section>
          <FieldFormField field="agentValuation" />
        </Section>
      </Sections>
      <Sections title="DR Sale Transaction">
        <FieldFormField field="departingPropertyExpectedListingPrice" />
      </Sections>
    </>
  )
}
