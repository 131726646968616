import { ReactNode } from 'react'
import { createCtx } from 'contexts'
import { CashOfferProviderLead, CCCashOfferLead } from 'models'
import { useCashOfferProviderLeadDisposition } from '..'

type TCashOfferDispositionProviderProps = {
  children: ReactNode
  lead: CCCashOfferLead
  providerLead: CashOfferProviderLead
}

export const [useCashOfferDispositionContext, CashOfferDispositionContextProvider] =
  createCtx<ReturnType<typeof useCashOfferProviderLeadDisposition>>()

export const CashOfferDispositionProvider = ({
  lead,
  providerLead,
  children
}: TCashOfferDispositionProviderProps) => {
  return (
    <CashOfferDispositionContextProvider
      value={useCashOfferProviderLeadDisposition(lead, providerLead)}
    >
      {children}
    </CashOfferDispositionContextProvider>
  )
}
