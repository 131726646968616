// @ts-strict
import {
  ActionButton,
  ConfirmationPopup,
  Contents,
  FormField,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  InlineEditDollarsInput,
  Section,
  Sections
} from 'components'
import { useCashOfferLeadController } from 'controllers'
import { useCashOfferLeadFields } from 'lookups'
import { CashOfferLead } from 'models'
import { formatDate, formatMoney } from 'utils'
import { IncomingResidenceProps } from './IR'
import { useRunMortgageAffordability } from './hooks'
import { getCashOfferLeadInputProps } from './utils'

type TProps = { cashOfferLead: CashOfferLead } & Pick<
  IncomingResidenceProps,
  'lead' | 'runMortgageAffordability'
>

export const IRPropertyValuationSection = ({
  cashOfferLead,
  lead,
  runMortgageAffordability
}: TProps) => {
  const { getCashOfferLeadField } = useCashOfferLeadFields()
  const { updateCashOfferLead } = useCashOfferLeadController(cashOfferLead.id)
  const { getProps } = getCashOfferLeadInputProps(
    cashOfferLead,
    updateCashOfferLead,
    getCashOfferLeadField
  )

  const { affordabilityUpdatedAt, buttonTitle, loanApplicationId, popupMessage } =
    useRunMortgageAffordability({ lead, cashOfferLead })

  const action = (
    <ConfirmationPopup
      buttonText={
        affordabilityUpdatedAt
          ? `Run Mortgage Affordability (last run ${formatDate(affordabilityUpdatedAt)})`
          : 'Run Mortgage Affordability'
      }
      buttonComponent={props => <ActionButton {...props} title={buttonTitle} />}
      disabled={!loanApplicationId}
      title="Run Mortgage Affordability"
      message={popupMessage}
      mutation={runMortgageAffordability}
      initialPayload={{ id: cashOfferLead.id! }}
    />
  )

  const lenderIsBesi = lead?.lenderSlug === 'besi'

  return (
    <Contents>
      <Sections title="Affordability" actions={lenderIsBesi ? null : action}>
        <Section>
          <FormField title="HL Expected Appraisal" inline>
            <InlineEditDollarsInput {...getProps('incomingPropertyValuation')} />
          </FormField>
          <FormField title="Min Appraisal for Cash Offer Purchase Price" inline>
            {lenderIsBesi ? (
              <InlineEditDollarsInput title="ipMinAppraisal" {...getProps('ipMinAppraisal')} />
            ) : (
              <span data-testid="ipMinAppraisal">{formatMoney(cashOfferLead.ipMinAppraisal)}</span>
            )}
          </FormField>
          <FormField title="Max Cash Offer Purchase Price at HL Valuation" inline>
            <InlineEditDollarsAndCentsInput {...getProps('ipMaxOfferPriceAtHlValuation')} />
          </FormField>
        </Section>
        <Section>
          <FormField title="AVM Valuation" inline>
            <InlineEditDollarsAndCentsInput {...getProps('ipAvmValuation')} />
          </FormField>
          <FormField title="Date of AVM Valuation" inline>
            <InlineEditDateInput {...getProps('ipAvmValuationAt')} />
          </FormField>
        </Section>
      </Sections>
    </Contents>
  )
}
