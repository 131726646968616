// @ts-strict
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { EmptyTableCell, TableRow as Tr } from 'components'
import { LeadStageFurthestCell } from 'content/Leads'
import { useListContext } from 'contexts'
import { EscrowLead } from 'models'
import { capitalize, cx } from 'utils'
import {
  EditCell,
  EscrowLeadAddressCell,
  EscrowLeadsSourceCell,
  EscrowOfficerCell,
  EscrowOrderNumberCell,
  PurchaseContractCell,
  SettlementAgencyCell,
  SubmissionTimeCell
} from './EscrowLeadsTableCells'

const columnMapping = {
  address: EscrowLeadAddressCell,
  purchaseContract: PurchaseContractCell,
  source: EscrowLeadsSourceCell,
  submissionTime: SubmissionTimeCell,
  escrowOrderNumber: EscrowOrderNumberCell,
  settlementAgency: SettlementAgencyCell,
  escrowOfficer: EscrowOfficerCell,
  stageFurthest: LeadStageFurthestCell,
  edit: EditCell
}

type TProps = {
  lead: EscrowLead
}

const StyledTr = styled(Tr)`
  cursor: pointer;
`

export const EscrowLeadsTableRow = ({ lead }: TProps) => {
  const { highlightRows, visibleColumns, getColumnAlignment } = useListContext()
  const rowClassName = highlightRows?.includes(lead.id) && 'highlight'
  const navigate = useNavigate()

  return (
    <StyledTr
      className={cx(rowClassName)}
      data-testid={`lead-id-${lead.id}`}
      onClick={() => navigate(`/escrows/${lead.id}`)}
    >
      {visibleColumns.map(visibleColumn => {
        const Cell = columnMapping[visibleColumn]
        const testId = `lead${capitalize(visibleColumn)}Cell`
        const align = getColumnAlignment(visibleColumn)

        return Cell ? (
          <Cell
            lead={lead}
            column={visibleColumn}
            key={visibleColumn}
            testId={testId}
            align={align}
          />
        ) : (
          <EmptyTableCell key={visibleColumn} align={align} />
        )
      })}
    </StyledTr>
  )
}
