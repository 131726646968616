// @ts-strict
import { cashOfferProviderLeadStagesSettings } from 'lookups'
import { CashOfferProviderLead } from 'models'
import { sorts } from 'utils'

// sorts by stage and isActive as ordered in cashOfferProviderLeadStagesSettings, or by updated date if stage is equal
export const sortCashOfferProviderLeads = (
  cashOfferProviderLeads: CashOfferProviderLead[]
): CashOfferProviderLead[] => {
  return cashOfferProviderLeads.sort((a: CashOfferProviderLead, b: CashOfferProviderLead) => {
    const stageIndexA = cashOfferProviderLeadStagesSettings.findIndex(
      stage => stage.slug === a.stage
    )
    const stageIndexB = cashOfferProviderLeadStagesSettings.findIndex(
      stage => stage.slug === b.stage
    )

    // if only one of the stages is an active stage:
    if (a.isActive !== b.isActive) {
      return a.isActive ? 1 : -1
    }

    // order by stage index in the settings list:
    if (!(stageIndexA === stageIndexB)) {
      return stageIndexA - stageIndexB
    }

    // if stages are the same, order by updatedAt:
    if (a?.updatedAt && b?.updatedAt) {
      return sorts.updated(a, b)
    }

    return sorts.created(a, b)
  })
}
