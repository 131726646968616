// @ts-strict

import { createContext, useContext } from 'react'

type Args = {
  errorMessage?: string
}

export const createCtx = <A extends {} | null>({
  errorMessage = 'useCtx must be inside a Provider with a value'
}: Args = {}) => {
  const ctx = createContext<A | undefined>(undefined)
  const useCtx = () => {
    const c = useContext(ctx)
    if (c === undefined) {
      throw new Error(errorMessage)
    }
    return c
  }
  return [useCtx, ctx.Provider] as const
}
