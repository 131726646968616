import { css, styled } from '@foundation/themes/styles'
import type { IconProps as Props } from './Icon'

type IconProps = {
  $color?: Props['color']
  $size?: Props['size']
}

const sizeStyle = {
  xs: css`
    ${({ theme }) => `
      height: ${theme.sizes.$7};
      width: ${theme.sizes.$7};
    `}
  `,
  sm: css`
    ${({ theme }) => `
      height: ${theme.sizes.$8};
      width: ${theme.sizes.$8};
    `}
  `,
  md: css`
    ${({ theme }) => `
      height: ${theme.sizes.$9};
      width: ${theme.sizes.$9};
    `}
  `,
  lg: css`
    ${({ theme }) => `
      height: ${theme.sizes.$10};
      width: ${theme.sizes.$10};
    `}
  `
}
export const IconWrapper = styled.svg<IconProps>`
  ${({ $size, $color, theme }) => css`
    display: inline-flex;
    align-items: center;
    ${!!$size && sizeStyle[$size]};
    fill: ${$color ? theme.colors[$color] : 'currentColor'};
  `}
`
