import { useQuery, useQueryClient } from 'hooks'
import { fetchPureCloudTokenActive } from 'services'
import { getRedirect } from 'services/PureCloud'
import { PURE_CLOUD_TOKEN_ACTIVE } from '../index'

export const usePureCloudController = () => {
  const queryCache = useQueryClient()
  const { data } = useQuery('pureCloudRedirectUrl', getRedirect)
  const { data: pureCloudTokenActiveQueryData } = useQuery(
    PURE_CLOUD_TOKEN_ACTIVE,
    fetchPureCloudTokenActive
  )

  const invalidatePureCloudTokenActive = () => {
    queryCache.invalidateQueries([PURE_CLOUD_TOKEN_ACTIVE])
  }

  return {
    redirectUrl: data,
    pureCloudTokenActive: !!pureCloudTokenActiveQueryData?.result,
    invalidatePureCloudTokenActive
  }
}
