import { trueFalseOptions } from 'lookups/lookupOptions'
import { TModelFieldSetting } from '../ModelField'

export type TAgentMatchingLeadFieldAttribute = 'needsListingReview' | 'pendingActions'

export type TAgentMatchingLeadFieldSetting = {
  attr: TAgentMatchingLeadFieldAttribute
} & TModelFieldSetting

export const agentMatchingLeadFieldsSettings: TAgentMatchingLeadFieldSetting[] = [
  {
    attr: 'needsListingReview',
    name: 'Needs Listing Review',
    options: trueFalseOptions
  },
  {
    attr: 'pendingActions',
    name: 'Pending Actions'
  }
]
