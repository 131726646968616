// @ts-strict
import { Carousel } from 'components'
import { usePhotoUploadsController } from 'controllers'

type Props = {
  fileId: string
  isOpen?: boolean
  onClose: () => void
  onNextSlide: (index: number) => void
  onPrevSlide: (index: number) => void
  title?: string
}

export const FileCarousel = ({
  fileId,
  onNextSlide,
  onPrevSlide,
  isOpen,
  onClose,
  title
}: Props) => {
  const { photoUploadsQuery } = usePhotoUploadsController(
    {
      fileId,
      category: undefined,
      fileType: 'image'
    },
    { enabled: isOpen }
  )
  const images = photoUploadsQuery?.data?.items ?? []
  const imageName = images[0]?.name

  if (!isOpen) {
    return null
  }

  return (
    <Carousel
      title={`${title}${imageName ? ` -  ${imageName}` : ''}`}
      images={images}
      current={0}
      onNextSlide={onNextSlide}
      onPrevSlide={onPrevSlide}
      onClose={onClose}
      isLoading={photoUploadsQuery.isLoading}
    />
  )
}
