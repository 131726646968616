// @ts-strict
import { BBYSLead, CCBBYSLead, ProviderLeadCapitalPartner, ServicesOpportunity } from 'models'
import { BBYSLeadResponse, ProviderLeadResponse } from 'schema'

type ProviderLeadExpenses = {
  category: string
  description: string
  id: string
  slug: string
  value: string
}

export class BBYSProviderLead {
  id!: string
  createdAt?: string
  followUpDate?: string
  lead?: CCBBYSLead
  providerLeadCapitalPartner?: ProviderLeadCapitalPartner
  reasonForFail?: string
  representedProduct?: string
  servicesOpportunities?: ServicesOpportunity[]
  stage?: string
  bbysLead?: BBYSLead
  providerLeadExpenses?: ProviderLeadExpenses[]
  constructor(bbysProviderLeadAttributes: BBYSProviderLead) {
    Object.assign(this, bbysProviderLeadAttributes)
  }

  static create(
    bbysProviderLeadResponse: Potential<ProviderLeadResponse>
  ): Potential<BBYSProviderLead> {
    if (!bbysProviderLeadResponse) {
      return
    }
    const lead = CCBBYSLead.create(bbysProviderLeadResponse?.lead)
    const providerLeadCapitalPartner = ProviderLeadCapitalPartner.create(
      bbysProviderLeadResponse.providerLeadCapitalPartner
    )
    const bbysLead = BBYSLead.create(bbysProviderLeadResponse?.providable as BBYSLeadResponse)
    return new BBYSProviderLead({
      ...bbysProviderLeadResponse,
      lead,
      providerLeadCapitalPartner,
      bbysLead
    })
  }
}
