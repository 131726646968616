// @ts-strict
import { Button, Popover } from '@foundation/components'
import theme from '@foundation/themes'
import { FormField } from 'components'
import { InlineEditLoanOfficer } from 'components/InlineEdit/InlineEditLoanOfficer'
import { LoanOfficer } from 'models'
import { LoanOfficerDetailsMenuCard } from '../LoanOfficerDetailsMenuCard/LoanOfficerDetailsMenuCard'

type BaseProps = {
  loanOfficer: Potential<LoanOfficer>
  title?: string
}
type TOptionalProps =
  | {
      onBlur: (val: string) => void
    }
  | {
      onBlur?: never
    }

export const LoanOfficerSection = ({ loanOfficer, title, onBlur }: BaseProps & TOptionalProps) => {
  const viewMore = loanOfficer?.id && (
    <Popover.Root placement="bottom-start">
      <Popover.Trigger>
        <span>
          <Button size="xs" variant="link">
            View More
          </Button>
        </span>
      </Popover.Trigger>
      <Popover.Content zIndex={theme.zIndices.overlay - 1}>
        <LoanOfficerDetailsMenuCard loanOfficerId={loanOfficer.id} />
      </Popover.Content>
    </Popover.Root>
  )

  return (
    <FormField title={title} valueSubtitle={viewMore} inline>
      <InlineEditLoanOfficer value={loanOfficer?.name} onBlur={onBlur} />
    </FormField>
  )
}
