import { getInvestorSubsUrl } from 'services/getUrl'
import { patchRequest, postRequest, snakeCaseKeys } from 'utils'

export const patchInvestorPayPerLeadSettings = async (providerId: string, payload: any) => {
  const url = getInvestorSubsUrl({ id: providerId, action: 'ppl-settings' })
  await patchRequest(url, snakeCaseKeys({ ...payload }))
}

export const postInvestorPayPerLeadSettings = async (providerId: string, payload: any) => {
  const url = getInvestorSubsUrl({ id: providerId, action: 'ppl-settings' })
  await postRequest(url, snakeCaseKeys({ ...payload }))
}
