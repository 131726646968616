import { ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Coaster.module.scss'

type TProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode
  className?: string
}

export const Coaster = forwardRef(
  ({ children, className, ...props }: TProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div {...props} className={cx(styles.coaster, className)} ref={ref}>
        {children}
      </div>
    )
  }
)
