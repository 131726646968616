import { DEFAULT_STRING } from '../constants'

export const formatPercentage = (pct: number, defaultValue: any = DEFAULT_STRING) => {
  if (String(Number(pct)) !== String(pct)) {
    return defaultValue
  }
  return Number(pct).toLocaleString('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
  })
}
