// @ts-strict
import { TableCell as Td, TableRow as Tr } from 'components'
import { EmployeeLink } from 'content/Employee'
import { MarketplaceProgramsBadges } from 'content/MarketplacePrograms'
import { AgentAeArea } from 'models'
import { dataOrDash } from 'utils'
import { useAgentAeAreasTableRow } from '.'

type Props = {
  agentAeArea: AgentAeArea
}

export const AgentAeAreasTableRow = ({ agentAeArea }: Props) => {
  const { agentsCount, areaName, marketplacePrograms, servicesUser, state, user } =
    useAgentAeAreasTableRow({ agentAeArea })

  return (
    <Tr>
      <Td>{dataOrDash(areaName)}</Td>
      <Td>{dataOrDash(state)}</Td>
      <Td>
        <EmployeeLink employee={user} />
      </Td>
      <Td>
        <EmployeeLink employee={servicesUser!} />
      </Td>
      <Td>{agentsCount}</Td>
      <Td>
        <MarketplaceProgramsBadges marketplacePrograms={marketplacePrograms} />
      </Td>
    </Tr>
  )
}
