import { getLeadsSearchFilterSettings } from '../utils'

const commonSlugs = [
  'source',
  'completionStatus',
  'escrowOfficer',
  'stageFurthest',
  'stateName'
] as const

export const escrowLeadSearchFilterSettings = getLeadsSearchFilterSettings(commonSlugs)
