import { Button } from '@foundation/components'
import {
  ConfirmationPopup,
  FormField,
  InlineEditDateInput,
  InlineEditDollarsInput,
  InlineEditFloatUnder100Input,
  InlineEditInventoryInput,
  InlineEditSelect,
  InlineEditUnder100Input,
  List,
  Section,
  Sections
} from 'components'
import {
  propertyDnqReasonsOptions,
  propertyQualifiedOptions,
  trueFalseOptions,
  useCcTradeInLeadFields
} from 'lookups'
import { d, dataOrDash, formatDate, formatOrDash, formatPercent } from 'utils'
import { CCTradeInLeadDetailsProps } from '../CCTradeInLeadDetails'
import { getTradeInLeadInputProps } from './utils'

type Props = Pick<
  CCTradeInLeadDetailsProps,
  'lead' | 'updateTradeInLead' | 'runPropertyQualification'
>

export const DRPropertyQualificationSection = ({
  lead,
  updateTradeInLead,
  runPropertyQualification
}: Props) => {
  const tradeInLead = lead.tradeInProviderLead.tradeInLead

  const address = tradeInLead?.departingMasterProperty?.displayAddress
  const qualifiedAt = tradeInLead?.departingPropertyQualifiedAt
  const flaggedReasons = tradeInLead?.departingPropertyFlags

  const { getProps } = getTradeInLeadInputProps(tradeInLead, updateTradeInLead)
  const { getCcTradeInLeadFieldName } = useCcTradeInLeadFields()

  const propertyQualificationStatus = () => {
    if (!address) {
      return 'DR Address is missing'
    }

    if (qualifiedAt) {
      return 'Qualification has already been run'
    }

    if (flaggedReasons?.length > 0) {
      return 'This property has been flagged'
    }

    return ''
  }

  const runQualificationText = qualifiedAt
    ? `Qualification ran on ${formatDate(qualifiedAt)}`
    : 'Run Qualification'

  const action = (
    <ConfirmationPopup
      buttonComponent={props => (
        <Button
          variant="outline"
          size="xs"
          color="neutralLight"
          onClick={props.onClick}
          isDisabled={props.disabled}
          title={propertyQualificationStatus()}
        >
          {runQualificationText}
        </Button>
      )}
      disabled={propertyQualificationStatus() !== ''}
      title="Run Property Qualification"
      message={`Click 'Submit' to qualify the property at: ${tradeInLead?.departingMasterProperty?.displayAddress}`}
      mutation={runPropertyQualification}
      initialPayload={{ id: tradeInLead.id }}
    />
  )

  return (
    <>
      <Sections title="DR Property Qualification" actions={action}>
        <Section>
          <FormField title={getCcTradeInLeadFieldName('departingPropertyQualified')} inline>
            <InlineEditSelect
              {...getProps('departingPropertyQualified')}
              options={propertyQualifiedOptions}
              addBlank
            />
          </FormField>
          <FormField
            title={getCcTradeInLeadFieldName('departingPropertyQualificationReason')}
            inline
          >
            <InlineEditSelect
              {...getProps('departingPropertyQualificationReason')}
              options={propertyDnqReasonsOptions}
              addBlank
            />
          </FormField>
          <FormField title={getCcTradeInLeadFieldName('departingPropertyFlags')} inline>
            <List>{flaggedReasons?.map(r => d(r))}</List>
          </FormField>
          <FormField
            title={getCcTradeInLeadFieldName('departingPropertyExternalLoanPayoff')}
            inline
          >
            {dataOrDash(tradeInLead?.departingPropertyExternalLoanPayoff)}
          </FormField>

          <FormField title={getCcTradeInLeadFieldName('departingPropertyPercentEquity')} inline>
            {formatOrDash(tradeInLead.departingPropertyPercentEquity, formatPercent)}
          </FormField>
          <FormField title={getCcTradeInLeadFieldName('departingPropertySolarLoanBalance')} inline>
            <InlineEditDollarsInput {...getProps('departingPropertySolarLoanBalance')} />
          </FormField>
          <FormField
            title={getCcTradeInLeadFieldName('departingPropertyPreliminaryTitleReviewed')}
            inline
          >
            <InlineEditSelect
              {...getProps('departingPropertyPreliminaryTitleReviewed')}
              options={trueFalseOptions}
              addBlank
            />
          </FormField>
        </Section>
        <Section>
          <FormField
            title={getCcTradeInLeadFieldName('departingPropertyExternalListingStatus')}
            inline
          >
            {dataOrDash(tradeInLead?.departingPropertyExternalListingStatus)}
          </FormField>
          <FormField
            title={getCcTradeInLeadFieldName('departingPropertyExternalListingDate')}
            inline
          >
            <InlineEditDateInput {...getProps('departingPropertyExternalListingDate')} />
          </FormField>
          <FormField
            title={getCcTradeInLeadFieldName('departingPropertyExternalListingStatusDate')}
            inline
          >
            <InlineEditDateInput {...getProps('departingPropertyExternalListingStatusDate')} />
          </FormField>
          <FormField title={getCcTradeInLeadFieldName('departingPropertyInspectedAt')} inline>
            <InlineEditDateInput {...getProps('departingPropertyInspectedAt')} />
          </FormField>
          <FormField title={getCcTradeInLeadFieldName('departingPropertyMarketDom')} inline>
            <InlineEditUnder100Input {...getProps('departingPropertyMarketDom')} />
          </FormField>
          <FormField title={getCcTradeInLeadFieldName('departingPropertyMarketIndex')} inline>
            <InlineEditFloatUnder100Input {...getProps('departingPropertyMarketIndex')} />
          </FormField>
          <FormField
            title={getCcTradeInLeadFieldName('departingPropertyMarketInventoryCount')}
            inline
          >
            <InlineEditInventoryInput {...getProps('departingPropertyMarketInventoryCount')} />
          </FormField>
        </Section>
      </Sections>
    </>
  )
}
