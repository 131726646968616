// @ts-strict
import { EmptyTableCell, TableRow as Tr } from 'components'
import { useListContext } from 'contexts'
import { AgentPartnership } from 'models'
import { capitalize } from 'utils'
import {
  AgentPartnershipActionsCell,
  AgentPartnershipCreatedUpdatedAtCell,
  AgentPartnershipDestinationUrlCell,
  AgentPartnershipEnabledCell,
  AgentPartnershipIncomingUrlCell,
  AgentPartnershipNameCell,
  AgentPartnershipNumberOfAgentsCell,
  AgentPartnershipTransactionTeamCell
} from './AgentPartnershipsTableCells'

type Props = {
  agentPartnership: AgentPartnership
}

const columnMapping = {
  name: AgentPartnershipNameCell,
  transactionTeam: AgentPartnershipTransactionTeamCell,
  incomingUrl: AgentPartnershipIncomingUrlCell,
  destinationUrl: AgentPartnershipDestinationUrlCell,
  enabled: AgentPartnershipEnabledCell,
  numberOfAgents: AgentPartnershipNumberOfAgentsCell,
  createdUpdatedAt: AgentPartnershipCreatedUpdatedAtCell,
  actions: AgentPartnershipActionsCell
}

export const AgentPartnershipsTableRow = ({ agentPartnership }: Props) => {
  const { visibleColumns, getColumnAlignment } = useListContext()

  return (
    <Tr>
      {visibleColumns.map(visibleColumn => {
        const Cell = columnMapping[visibleColumn]
        const testId = `agentPartnership${capitalize(visibleColumn)}Cell`
        const align = getColumnAlignment(visibleColumn)

        return Cell ? (
          <Cell
            agentPartnership={agentPartnership}
            column={visibleColumn}
            key={visibleColumn}
            testId={testId}
            align={align}
          />
        ) : (
          <EmptyTableCell key={visibleColumn} align={align} />
        )
      })}
    </Tr>
  )
}
