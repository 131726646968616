import { useEmployeeUsers } from 'controllers'
import {
  ccCashOfferLeadStagesOptions,
  protectedOfferStatusOptions,
  sourcesOptions,
  stateNameOptions,
  trueFalseOptions
} from 'lookups'
import { useCapitalPartners } from 'lookups/lookupCapitalPartners'
import { titleize } from 'utils'
import { buildSearchFilters, findFilterBySlug } from '../utils'
import { ccCashOfferLeadSearchFilterSettings } from './ccCashOfferLeadSearchFilterSettings'

export const useCCCashOfferLeadSearchFilter = () => {
  const { capitalPartnersOptions } = useCapitalPartners()
  const { getUserName } = useEmployeeUsers()

  const optionLoaders = {
    stage: ccCashOfferLeadStagesOptions,
    capitalPartnerId: capitalPartnersOptions,
    source: sourcesOptions,
    trueFalse: trueFalseOptions,
    protectedOfferStatus: protectedOfferStatusOptions,
    stateName: stateNameOptions
  }
  const displayValueFnLoaders = {
    titleize,
    getUserName
  }

  const ccCashOfferLeadFilters = buildSearchFilters(ccCashOfferLeadSearchFilterSettings, {
    optionLoaders,
    displayValueFnLoaders
  })

  const findCCCashOfferLeadFilterBySlug = findFilterBySlug(ccCashOfferLeadFilters)

  return { ccCashOfferLeadFilters, findFilterBySlug: findCCCashOfferLeadFilterBySlug }
}
