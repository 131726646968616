// @ts-strict

import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './PageSubTitle.module.scss'

type TProps = {
  children: ReactNode
}

export const PageSubTitle = ({ children }: TProps) => {
  return <div className={cx(styles.subTitle, 'pageSubtitle')}>{children}</div>
}
