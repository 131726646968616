// @ts-strict
import { TableHeaderActions, TableHeaderCell as TH, TableHeaderRow as TR } from 'components'
import { useListContext } from 'contexts'
import styles from './LeadsTable.module.scss'

export const LeadsTableHeader = () => {
  const { showColumn, getColumnName, getColumnSize, actions } = useListContext()

  return (
    <TR>
      {showColumn('namePartner') && (
        <TH fixed size={getColumnSize('namePartner')} className={styles.namePartner}>
          {getColumnName('namePartner')}
        </TH>
      )}

      {showColumn('contactInfo') && (
        <TH className={styles.contactInfo} size={getColumnSize('contactInfo')}>
          {getColumnName('contactInfo')}
        </TH>
      )}

      {showColumn('address') && (
        <TH className={styles.address} size={getColumnSize('address')}>
          {getColumnName('address')}
        </TH>
      )}

      {showColumn('otherLeads') && (
        <TH className={styles.otherLeads} size={getColumnSize('otherLeads')}>
          {getColumnName('otherLeads')}
        </TH>
      )}

      {showColumn('price') && (
        <TH className={styles.price} size={getColumnSize('price')}>
          {getColumnName('price')}
        </TH>
      )}

      {showColumn('stageFurthest') && (
        <TH className={styles.stageFurthest} size={getColumnSize('stageFurthest')}>
          {getColumnName('stageFurthest')}
        </TH>
      )}

      {showColumn('createdUpdated') && (
        <TH className={styles.createdUpdated} size={getColumnSize('createdUpdated')}>
          {getColumnName('createdUpdated')}
        </TH>
      )}

      {!!actions && <TableHeaderActions />}
    </TR>
  )
}
