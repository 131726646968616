// @ts-strict
import { Avatar } from '@foundation/components'
import { useViewersRealTimeController } from 'controllers'
import type { ActiveViewer } from 'controllers'
import { useEffectOnce } from 'hooks'
import { LeadViewers as Viewers } from 'models'
import * as S from './styles'

type Props = {
  leadId: string | number
  leadViewers?: Viewers[]
}

export const LeadViewers = ({ leadId, leadViewers }: Props) => {
  const { startConnection, activeViewers, addNewActiveViewers } = useViewersRealTimeController({
    entityId: leadId
  })

  useEffectOnce(() => {
    if (leadViewers?.length) {
      leadViewers.forEach(viewer => {
        const { id, ...rest } = viewer
        const newViewer: ActiveViewer = {
          ...rest,
          userId: Number(id),
          action: 'user_viewing_lead_details_page'
        }

        addNewActiveViewers(newViewer)
      })
    }

    startConnection()

    return
  })

  if (!activeViewers.length) {
    return null
  }

  return (
    <S.Wrapper data-testid="leadViewers">
      {activeViewers.map(viewer => {
        if (viewer.initials) {
          return (
            <Avatar.Text
              key={viewer.userId}
              title={`${viewer.firstName ?? ''} ${viewer.lastName ?? ''}`}
              variant="outline"
              size="sm"
            >
              {viewer.initials}
            </Avatar.Text>
          )
        }

        return null
      })}
    </S.Wrapper>
  )
}
