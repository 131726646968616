import { useState } from 'react'
import { Point } from 'classes'
import { MapBox, MenuSimple } from 'components'
import styles from './MapInput.module.scss'

export const MapInput = ({ center, onClick }: { center: Point; onClick: (val) => void }) => {
  const [open, setOpen] = useState<boolean>(false)
  const toggleOpen = () => setOpen(!open)

  const handleOnClick = e => {
    toggleOpen()
    onClick(e)
  }

  return (
    <MenuSimple
      title={
        center
          ? `Pick from Map: ${center.longitude.toFixed(2)}, ${center.latitude.toFixed(2)}`
          : 'Pick from Map'
      }
      open={open}
      onClick={toggleOpen}
      className={styles.mapMenuWrapper}
    >
      <MapBox center={center} onClick={handleOnClick} className={styles.mapMenu} />
    </MenuSimple>
  )
}
