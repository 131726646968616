import { formatDate, formatDateTime } from '../'
import { DEFAULT_STRING } from '../constants'

export const formatDateOrDateTime = (
  date: string | Date,
  { includeTime = false, defaultValue = DEFAULT_STRING } = {}
) => {
  if (!date) {
    return defaultValue
  }

  if (includeTime) {
    return formatDateTime(date)
  }

  return formatDate(date)
}
