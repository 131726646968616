// @ts-strict
import { Option } from 'classes'
import { CheckBoxField, FormField, Select, TButtonFormFieldsProps } from 'components'
import { AgentMatchingLeadDispositionPayload } from 'content/Lead'
import { useQueuedLeadsController } from 'hooks'
import { Lead } from 'models'
import { titleize } from 'utils'

type TProps = {
  fieldsProps: TButtonFormFieldsProps<AgentMatchingLeadDispositionPayload>
  lead: Lead
}

export const AgentMatchingLeadDispositionFormFields = ({ lead, fieldsProps }: TProps) => {
  const { isActive } = useQueuedLeadsController()
  const { payload, onChangeAttribute } = fieldsProps

  const dispositionOptions: Option[] = [
    isActive(lead.id) &&
      new Option({ name: 'Handmatching Complete', value: 'handmatching_complete' }),
    !lead.isDeal && new Option({ name: 'Not Transacting', value: 'not_transacting' }),
    new Option({ name: 'Send to On-boarding', value: 'send_to_onboarding' })
  ].filter((item): item is Option => !!item)

  const currentStageHint = lead.stage ? `Current stage: ${titleize(lead.stage)}` : null

  return (
    <>
      <FormField title="Stage" required hint={currentStageHint}>
        <Select
          options={dispositionOptions}
          data-testid="dispositionOptions"
          value={payload.key}
          onChange={val => onChangeAttribute('key', val)}
          addBlank
        />
      </FormField>
      <CheckBoxField
        title="Notify Client"
        checked={payload.notifyClient}
        onChange={e => onChangeAttribute('notifyClient', e.currentTarget.checked)}
      />
    </>
  )
}
