import { prettySort } from 'utils'

type TOptions = {
  sort?: boolean
}

export const addToArray = (array: any[], item: any, options: TOptions = {}) => {
  const { sort } = options
  const newArray = [...new Set([...array, item])]
  return sort ? prettySort(newArray) : newArray
}
