import styled from 'styled-components'
import { Icon } from 'components'
import styles from './CreateEscrowOrder.module.scss'
import { TOrderOption } from './types'

type TCreateEscrowOptionsProps = {
  onSelect: (orderType: string) => void
  orderTypes: TOrderOption[]
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 30rem;
  width: 100%;

  > div + div {
    margin-top: 2rem;
  }
`

const StyledDiv = styled.div`
  ${({ theme }) => `
    align-items: center;
    border-radius: 5px;
    border: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 3rem;
    transition: all .2s ease-out;
    width: 100%;

    path {
      fill: ${theme.colors.primary5};
    }

    &:hover {
      background-color: ${theme.colors.neutral3};
    }
    
    > p {
      font-size: 18px;
      margin-top: 1rem;
    }
  `}
`

const OrderTypeOption = ({ value, title, icon, onSelect }: TOrderOption) => {
  const IconComponent = Icon[icon]

  return (
    <StyledDiv onClick={() => onSelect(value)}>
      <IconComponent
        style={{
          fill: 'primary5',
          width: '32px',
          height: '32px'
        }}
      />
      <p> {title} </p>
    </StyledDiv>
  )
}

export const CreateEscrowOptions = ({ onSelect, orderTypes }: TCreateEscrowOptionsProps) => {
  return (
    <div className={styles.optionsWrapper}>
      <Wrapper>
        {orderTypes.map(orderType => (
          <OrderTypeOption key={orderType.value} onSelect={onSelect} {...orderType} />
        ))}
      </Wrapper>
    </div>
  )
}
