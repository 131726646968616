import { useState } from 'react'
import { Icon } from 'components/Icon'
import styles from './Form.module.scss'
import { TextInput, TextInputProps } from './TextInput'

type Props = {
  enableViewText?: boolean
} & TextInputProps

export const PasswordInput = ({ enableViewText, ...props }: Props) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShow = () => setShowPassword(!showPassword)

  const IconComponent = Icon[showPassword ? 'Eye' : 'EyeSlash']

  return enableViewText ? (
    <span className={styles.passwordInputWrapper}>
      <TextInput {...props} type={showPassword ? 'text' : 'password'} />
      <IconComponent onClick={toggleShow} data-testid="eyeIcon" />
    </span>
  ) : (
    <TextInput {...props} type="password" />
  )
}
