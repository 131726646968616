import { Option } from 'classes'
import { IOptionable } from '../utils'

export type TAgentLeadStagesAttributes = {
  active?: boolean
  activePosition?: number
  name: string
  value: string | number
}

export class AgentLeadStage implements IOptionable {
  active?: boolean
  activePosition?: number
  name!: string
  value!: string

  constructor(agentLeadStagesAttributes: TAgentLeadStagesAttributes) {
    Object.assign(this, agentLeadStagesAttributes)
  }

  toOption() {
    return new Option({ name: this.name, value: this.value })
  }
}
