// @ts-strict
import { Lead, LenderLead, LoanOfficer } from 'models'
import { LenderLeadResponse, ProviderLeadResponse } from 'schema'

export class LenderProviderLead {
  id!: string
  stage?: string
  lenderLead?: LenderLead
  providable?: LenderLead
  loanOfficer?: LoanOfficer
  lead?: Lead

  constructor(lenderProviderLeadAttributes: LenderProviderLead) {
    Object.assign(this, lenderProviderLeadAttributes)
  }

  static create(
    lenderProviderLeadResponse: Potential<ProviderLeadResponse>
  ): Potential<LenderProviderLead> {
    if (!lenderProviderLeadResponse) {
      return
    }
    const lenderLead = LenderLead.create(
      lenderProviderLeadResponse?.providable as LenderLeadResponse
    )
    const lead = Lead.create(lenderProviderLeadResponse?.lead)
    return new LenderProviderLead({
      ...lenderProviderLeadResponse,
      lenderLead,
      providable: lenderLead,
      lead
    })
  }
}
