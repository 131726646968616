// @ts-strict
import { useLeadController } from 'controllers'
import { TLeadUserTypeServerSlug } from 'lookups'
import { createCtx } from '../utils'

export const [useLeadContext, LeadContextProvider] =
  createCtx<ReturnType<typeof useLeadController>>()

type TProps = {
  children: React.ReactNode
  leadId: string
  userType?: TLeadUserTypeServerSlug
}

export const LeadProvider = ({ leadId, userType, children }: TProps) => {
  return (
    <LeadContextProvider value={useLeadController(leadId, userType)}>
      {children}
    </LeadContextProvider>
  )
}
