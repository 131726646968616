// @ts-strict
import { Icon } from 'components'
import { ButtonV2 } from 'components/Button/ButtonV2'
import { useParamsContext } from 'contexts'
import { SearchFilter } from 'lookups/lookupSearchFilters'
import { cx } from 'utils'
import styles from './SearchStatusFilterValue.module.scss'

type TSearchStatusFilterValueProps = {
  filter: SearchFilter
  paramValue: string
}

export const SearchStatusFilterValue = ({ filter, paramValue }: TSearchStatusFilterValueProps) => {
  const { removeValueFromParam } = useParamsContext()

  return (
    <ButtonV2
      className={cx(styles.searchFilterTag)}
      onClick={() => removeValueFromParam(filter.slug, paramValue)}
    >
      <span>{filter.getDisplayValue?.(paramValue)}</span>
      <Icon.Close className={styles.closeIcon} />
    </ButtonV2>
  )
}
