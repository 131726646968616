// @ts-strict
import { Dialer, Link, Marker, Point } from 'classes'
import { TMarketplaceProgramSlug } from 'lookups'
import {
  AgentAgreement,
  AgentTeam,
  AgentTeamMembership,
  CurrentUser,
  MarketplaceProgramAgent,
  Metroplex,
  PhoneNumber,
  ServicesOpportunity,
  TransactionTeam,
  User,
  UsState
} from 'models'
import { AgentPartnership } from 'models/AgentPartnership/AgentPartnership'
import { getPhoneNumbers } from 'models/Lead/utils'
import {
  AgentAgreementResponse,
  AgentMetricResponse,
  AgentResponse,
  AgreementResponse,
  InvestorResponse,
  LanguageResponse,
  MetroplexResponse,
  PplBillingInfoResponse,
  PplProviderAgreementResponse,
  PplProviderSettingResponse,
  ReferralPreferencesAttributes,
  ServiceAreaResponse,
  StateResponse
} from 'schema'
import { AgentPlatformMetricsSchemaResponse } from 'schema/AgentPlatformMetricsSchema'
import { byAttr } from 'utils'
import { MarketplaceProgramAgentEnrollStatus } from '../MarketplaceProgramAgent/utils'
import { Model } from '../Model'
import { AgentBadge, getAgentBadge, getAgentServicesOpportunities } from './utils'

type TAgentConstructor = Omit<
  Agent,
  | 'hasATransactionTeam'
  | 'isOnAgentTeam'
  | 'hasUserAccount'
  | 'getMarketplaceProgramEnrollStatus'
  | 'getMarketplaceProgramCount'
  | 'getDialer'
  | 'getLink'
  | 'getPathname'
  | 'getLatestPhoneByType'
  | 'getPhoneNumbers'
  | 'getPhoneOffice'
  | 'getPhoneMobile'
  | keyof Model
>

export class Agent extends Model {
  agentMetric?: AgentMetricResponse
  agentPartnership?: AgentPartnership
  agentTeam?: AgentTeam
  agentTeamId?: string
  agentTeamName?: string
  agentTeamMemberships?: AgentTeamMembership[]
  assignedArea?: UsState | Metroplex
  badge?: AgentBadge
  modelName = 'Agent'
  eliteStatus?: 'active' | 'eligible'
  eligibleAgreements?: AgreementResponse[]
  email1?: string
  email2?: string
  fax?: string
  firstName?: string
  fullName?: string
  gender?: string
  hideFromSearch?: boolean
  hideProfile?: boolean
  id!: string
  inContract?: boolean
  isTeamLead?: boolean
  investor?: InvestorResponse
  languages?: LanguageResponse[]
  lastActivityAt?: string
  lastName?: string
  lastPendingAgreement?: AgentAgreementResponse
  lastSignedAgreement?: AgentAgreementResponse
  lastSignedInAt?: string
  lastTouchedAt?: string
  licenseIssueDate?: string
  licenseNumber?: string
  licenseStatus?: 'active' | 'inactive'
  licenseType?: string
  marketplaceProgramAgents?: MarketplaceProgramAgent[]
  mlsIdentifier?: string
  moveSafeCertified?: boolean
  name?: string
  notes?: string
  officeDisplayName?: string
  performanceMetrics?: {
    closeChance?: number
    dealProgression?: number
    responseTime?: number
  }
  phoneNumbers?: PhoneNumber[]
  pictureThumbUrl?: string
  pplEnabledAgent?: boolean
  recentTransactions?: Marker[] = []
  referralPreferences?: ReferralPreferencesAttributes
  referralStats?: {
    activeBuyerCount: number
    activeCount: number
    activeSellerCount: number
    closedCount: number
    greenCount: number
    homelightCount: number
    inAgentLeftVmCount: number
    inConnectedCount: number
    inIntroducedCount: number
    inListingCount: number
    inMeetingScheduledCount: number
    inMetInPersonCount: number
    inMsMipCount: number
    mortgageCount: number
    nonHomelightCount: number
    redCount: number
    titleEscrowCount: number
    totalCount: number
    urgentCount: number
    yellowCount: number
  }
  slug?: string
  serviceAreas?: ServiceAreaResponse[]
  servicesOpportunitiesCounts?: {
    escrowCount?: number
    lostCount?: number
    mortgageCount?: number
    totalCount: number
  }
  servicesOpportunities?: ServicesOpportunity[]
  state?: UsState
  teamLeader?: boolean
  tier?: 1 | 2 | 3
  tradeInPlusEnabled?: boolean
  transactionTeam?: TransactionTeam
  settlementAgencyTeam?: TransactionTeam
  type?: 'agent' = 'agent'
  website?: string
  asm?: User
  ase?: User
  user?: User
  platformMetrics?: AgentPlatformMetricsSchemaResponse
  homesEnrollmentDate?: string
  referralsEnrollmentDate?: string
  homesActivationDate?: string
  lastHomesLeadDate?: string
  lastReferralLeadDate?: string
  signedAgreement?: boolean
  preferredEscrowOfficer?: User
  pplBillingInfos?: PplBillingInfoResponse[]
  pplProviderAgreements?: PplProviderAgreementResponse[]
  pplProviderSettings?: PplProviderSettingResponse[]

  constructor(agentAttributes: TAgentConstructor) {
    super()
    Object.assign(this, agentAttributes)

    this.badge = getAgentBadge(agentAttributes)
  }

  static create(agentResponse: Potential<AgentResponse>): Potential<Agent> {
    if (!agentResponse) {
      return
    }

    const agentTeamMemberships = agentResponse?.agentTeamMemberships?.map(
      AgentTeamMembership.create
    ) as AgentTeamMembership[]
    const agentTeam = AgentTeam.create(agentResponse.agentTeam)
    const lastPendingAgreement = AgentAgreement.create(agentResponse.lastPendingAgreement)
    const lastSignedAgreement = AgentAgreement.create(agentResponse.lastSignedAgreement)
    const agentTeamName = agentTeam
      ? agentTeam.name
      : agentTeamMemberships?.map(teamMemberships => teamMemberships?.agentTeam?.name)?.join(', ')
    const transactionTeam = TransactionTeam.create(agentResponse?.transactionTeam)
    const settlementAgencyTeam = TransactionTeam.create(agentResponse?.settlementAgencyTeam)
    const phoneNumbers = getPhoneNumbers(agentResponse).filter(
      ({ phoneType }) => phoneType === 'cell' || phoneType === 'office'
    )
    const recentTransactions = agentResponse?.recentTransactions?.map(
      t =>
        new Marker({
          point: new Point({
            longitude: t.lon,
            latitude: t.lat
          })
        })
    )

    const isTeamLead = agentTeamMemberships?.some(mbsh => mbsh.role === 'team_lead')

    const fullName = `${agentResponse.firstName} ${agentResponse.lastName}`
    const name = agentResponse.investor ? agentResponse.investor.name : fullName

    const marketplaceProgramAgents = agentResponse?.marketplaceProgramAgents?.map(
      MarketplaceProgramAgent.create
    ) as MarketplaceProgramAgent[]
    const servicesOpportunities = getAgentServicesOpportunities(
      agentResponse?.servicesOpportunities
    )
    const asm = User.create(agentResponse.asm)
    const ase = User.create(agentResponse.ase)
    const user = User.create(agentResponse.user)
    const assignedArea = agentResponse.assignedArea?.hasOwnProperty('code')
      ? UsState.create(agentResponse.assignedArea as StateResponse)
      : agentResponse.assignedArea?.hasOwnProperty('slug')
      ? Metroplex.create(agentResponse.assignedArea as MetroplexResponse)
      : undefined
    const state = UsState.create(agentResponse.state)
    const preferredEscrowOfficer = User.create(agentResponse.preferredEscrowOfficer)

    const agentPartnership = AgentPartnership.create(agentResponse?.partnerProgram)

    const hideFromSearch = !!agentResponse.hideFromSearch
    const hideProfile = !!agentResponse.hideProfile

    return new Agent({
      ...agentResponse,
      agentTeam,
      agentTeamName,
      agentTeamMemberships,
      agentPartnership,
      ase,
      asm,
      assignedArea,
      fullName,
      lastPendingAgreement,
      lastSignedAgreement,
      marketplaceProgramAgents,
      name,
      phoneNumbers,
      recentTransactions,
      servicesOpportunities,
      state,
      transactionTeam,
      settlementAgencyTeam,
      user,
      isTeamLead,
      preferredEscrowOfficer,
      hideFromSearch,
      hideProfile
    })
  }

  // getters

  get hasATransactionTeam() {
    return !!this.transactionTeam
  }
  get isOnAgentTeam() {
    return !!this.agentTeamMemberships?.length
  }
  get hasUserAccount() {
    return !!this.user
  }

  // instance methods

  getMarketplaceProgramEnrollStatus(
    slug?: TMarketplaceProgramSlug
  ): MarketplaceProgramAgentEnrollStatus {
    const marketplaceProgramAgent = this.marketplaceProgramAgents?.find(
      mpa => mpa.marketplaceProgram?.slug === slug
    )
    return marketplaceProgramAgent?.enrollStatus || 'unenrolled'
  }

  getMarketplaceProgramCount(slug: TMarketplaceProgramSlug): number | undefined {
    switch (slug) {
      case 'title_escrow': {
        return this.referralStats?.titleEscrowCount
      }
      case 'home_loans': {
        return this.referralStats?.mortgageCount
      }
    }
    return undefined
  }

  getDialer = (currentUser: CurrentUser, phoneNumber: string, phoneType: PhoneType) =>
    new Dialer({
      currentUser,
      name: this.fullName!,
      phoneNumber,
      phoneType,
      type: 'Agent',
      id: this.id
    })

  getLink = () => {
    return new Link({ name: this.name!, pathname: this.getPathname() })
  }

  getPathname = () => {
    return `/agents/${this.id}`
  }

  getPhoneOffice = () => {
    return this.phoneNumbers?.find(byAttr('phoneType', 'office'))
  }

  getPhoneMobile = () => {
    return this.phoneNumbers?.find(byAttr('phoneType', 'cell'))
  }
}
