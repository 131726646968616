import { FloatingPortal } from '@floating-ui/react'
import * as RadixToast from '@radix-ui/react-toast'
import { IconPathKeys } from '@foundation/icons'
import { Icon } from '../Icon'
import { IconButton } from '../IconButton'
import * as S from './styles'
import type { Toast } from './useToast'

export type ToastProps = {
  items: Toast[]
}

const statusIcon: Record<Toast['status'], IconPathKeys> = {
  success: 'Check',
  warning: 'ErrorOutlineOutlined',
  error: 'Close',
  info: 'InfoOutlined'
}

export const ToastStack = ({ items }: ToastProps) => {
  return (
    <FloatingPortal>
      <RadixToast.Provider swipeDirection="right">
        {items
          .map(({ id, description, title, onOpenChange, duration, status }) => {
            const iconPath = statusIcon[status]
            return (
              <S.Root
                key={id}
                onOpenChange={onOpenChange}
                duration={duration === Infinity ? Infinity : undefined}
              >
                <S.MainContent>
                  <S.StatusWrapper>
                    <S.Status $status={status} data-testid="iconStatus">
                      <Icon
                        data-testid={`${iconPath}Icon`}
                        path={iconPath}
                        color="white"
                        size="xs"
                      />
                    </S.Status>
                  </S.StatusWrapper>
                  <S.Content>
                    <S.Title>{title}</S.Title>
                    <S.Description asChild>
                      <span>{description}</span>
                    </S.Description>
                  </S.Content>
                </S.MainContent>
                <S.Action>
                  <RadixToast.Action asChild altText="Close notification">
                    <IconButton
                      isRounded
                      variant="ghost"
                      color="neutralLight"
                      title="Close notification"
                      icon="Close"
                      size="xs"
                    />
                  </RadixToast.Action>
                </S.Action>
              </S.Root>
            )
          })
          .reverse()
          .slice(0, 3)}

        <S.Viewport />
      </RadixToast.Provider>
    </FloatingPortal>
  )
}
