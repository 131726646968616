import { orderColumns } from './ordersColumnsSettings'

export const agentMatchingOrdersColumnsSettings = [
  {
    name: orderColumns.clientName.name,
    value: orderColumns.clientName.value,
    isDefault: true
  },
  {
    name: orderColumns.property.name,
    value: orderColumns.property.value,
    isDefault: true
  },
  {
    name: orderColumns.lead.name,
    value: orderColumns.lead.value,
    isDefault: true
  },
  {
    name: orderColumns.stage.name,
    value: orderColumns.stage.value,
    isOptional: true,
    isDefault: true
  },
  {
    name: orderColumns.listingStatus.name,
    value: orderColumns.listingStatus.value,
    isOptional: true,
    isDefault: true
  },
  {
    name: orderColumns.createdAt.name,
    value: orderColumns.createdAt.value,
    isOptional: true,
    isDefault: true
  },
  {
    name: orderColumns.updatedAt.name,
    value: orderColumns.updatedAt.value,
    isOptional: true,
    isDefault: true
  },
  {
    name: orderColumns.price.name,
    value: orderColumns.price.value,
    isOptional: true,
    isDefault: true
  },
  {
    name: orderColumns.agentsOpps.name,
    value: orderColumns.agentsOpps.value,
    isOptional: true,
    isDefault: true
  },
  {
    name: orderColumns.introSentAt.name,
    value: orderColumns.introSentAt.value,
    isOptional: true,
    isDefault: true
  }
] as const

export type TAgentMatchingOrdersColumnValue =
  (typeof agentMatchingOrdersColumnsSettings)[number]['value']

export type TAgentMatchingOrdersColumn = typeof agentMatchingOrdersColumnsSettings
