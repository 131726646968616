import { getProviderLeadsUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export const postProviderLeadCapitalPartner = (
  providerLeadId: string,
  capitalPartnerId: string
): Promise<any> => {
  return postRequest(
    getProviderLeadsUrl({ id: providerLeadId, action: 'provider-lead-capital-partners' }),
    snakeCaseKeys({ capitalPartnerId })
  )
}
