import { EditExternalLinkVariables } from 'contexts/types'
import { getExternalLinksUrl } from 'services/getUrl'
import { putRequest } from 'utils'

export const putExternalLink = (
  externalLinkId: string,
  { name, url, subAttachableId, subAttachableType }: EditExternalLinkVariables
) => {
  return putRequest(getExternalLinksUrl({ id: externalLinkId }), {
    name,
    url,
    sub_attachable_id: subAttachableId,
    sub_attachable_type: subAttachableType
  })
}
