import { useEffectOnce } from 'react-use'
import { Virtuoso } from 'react-virtuoso'
import { Box } from '@foundation/components'
import { useFeatureFlags } from 'hooks'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import { EmptyPlaceholderInfiniteScrolling, LoadingInfiniteScrollingFooter } from 'templates'
import { CommsListRow, useCommsFiltersContext } from '../../commons/ui'
import { useFetchInfiniteGlobalComms } from '../hooks'
import { ListGlobalCommsFilters } from './ListGlobalCommsFilters'

export const ListGlobalComms = () => {
  const { tracking } = useCustomEvents()
  const { salesAppCommsUxImprovements } = useFeatureFlags(['sales-app-comms-ux-improvements'])
  const { filters } = useCommsFiltersContext()
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useFetchInfiniteGlobalComms({
      searchParams: {
        name: filters.global.channels,
        lessEqualOccurredAt: filters.global.dateRange?.endingDate,
        greaterEqualOccurredAt: filters.global.dateRange?.startingDate,
        agentIdContactInfo: filters.global.agent?.value,
        userIdContactInfo: filters.global.user?.value,
        orderIdContactInfo: filters.global.client?.value
      }
    })

  useEffectOnce(() => {
    tracking({
      name: 'drawer_view',
      params: {
        trigger: 'click',
        location: 'global',
        target: 'communication'
      }
    })
  })

  const isLoading = isFetching || isFetchingNextPage

  return (
    <>
      {salesAppCommsUxImprovements.enabled && (
        <Box marginTop="$4" marginBottom="$4">
          <ListGlobalCommsFilters />
        </Box>
      )}
      <Virtuoso
        context={{ isLoading }}
        style={{ height: '100%' }}
        data={data?.pages.flatMap(d => d.items) || []}
        endReached={() => {
          if (!isLoading && hasNextPage) {
            fetchNextPage()
          }
        }}
        itemContent={(index, item) => {
          return (
            <CommsListRow
              rowIndex={index}
              data-testid={`eventComponent${index}`}
              activityFeed={item}
            />
          )
        }}
        components={{
          Footer: ({ context }) => (
            <LoadingInfiniteScrollingFooter isLoading={context?.isLoading} />
          ),
          EmptyPlaceholder: ({ context }) => (
            <EmptyPlaceholderInfiniteScrolling isLoading={context?.isLoading} />
          )
        }}
      />
    </>
  )
}
