// @ts-strict

import { FormField, ListSelect } from 'components'
import { trueFalseOptions, TrueFalseType } from 'lookups'
import styles from './AgentFilters.module.scss'

type Props = {
  onChange: (val: TrueFalseType) => void
  value: string
}

export const AgentsInContractFilter = ({ value, onChange }: Props) => {
  return (
    <FormField title="In Contract" className={styles.filter} inline>
      <ListSelect options={trueFalseOptions} value={value} onChange={onChange} />
    </FormField>
  )
}
