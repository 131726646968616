import { css, styled, Theming } from '@foundation/themes/styles'
import { SwitchProps } from './types'

const trackSizes = {
  sm: ({ theme }: SwitchProps & { theme: Theming }) => css`
    width: ${theme.sizes.$14};
    height: ${theme.sizes.$8};
    line-height: ${theme.sizes.$8};
  `,
  md: ({ theme }: SwitchProps & { theme: Theming }) => css`
    width: ${theme.sizes.$20};
    height: ${theme.sizes.$12};
    line-height: ${theme.sizes.$12};
  `,
  lg: ({ theme }: SwitchProps & { theme: Theming }) => css`
    width: ${theme.sizes.$28};
    height: ${theme.sizes.$16};
    line-height: ${theme.sizes.$16};
  `
}

export const Track = styled.button<SwitchProps>`
  ${({ theme, isOn, disabled, size }) => css`
    ${trackSizes[size!]}

    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    background: ${isOn ? theme.colors.primary5 : theme.colors.neutral3};
    border: none;

    vertical-align: middle;

    border-radius: ${theme.radii.rounded};
    border: ${theme.borderWidths.thin} solid ${theme.colors.neutral6};
    border-color: ${isOn ? theme.colors.primary5 : theme.colors.neutral6};
    box-sizing: border-box;

    position: relative;

    transition: background ${theme.motions.duration.short}ms ease-in-out;

    ${disabled && `opacity: 0.5;`}
  `}
`

const thumbSizes = {
  sm: ({ theme, $isOn }: { $isOn: SwitchProps['isOn']; theme: Theming }) => css`
    width: ${theme.sizes.$8};
    height: ${theme.sizes.$8};
    transform: translateY(-50%) ${$isOn ? css`translateX(${theme.sizes.$6});` : `translateX(0)`};
  `,
  md: ({ theme, $isOn }: { $isOn: SwitchProps['isOn']; theme: Theming }) => css`
    width: ${theme.sizes.$12};
    height: ${theme.sizes.$12};
    transform: translateY(-50%) ${$isOn ? css`translateX(${theme.sizes.$8});` : `translateX(0)`};
  `,
  lg: ({ theme, $isOn }: { $isOn: SwitchProps['isOn']; theme: Theming }) => css`
    width: ${theme.sizes.$16};
    height: ${theme.sizes.$16};
    transform: translateY(-50%) ${$isOn ? css`translateX(${theme.sizes.$12});` : `translateX(0)`};
  `
} as const

type ThumbProps = {
  $isOn: SwitchProps['isOn']
  $size: SwitchProps['size']
} & { theme: Theming }

export const Thumb = styled.span<ThumbProps>`
  ${({ theme, $isOn, $size }) => css`
    ${thumbSizes[$size!]};
    background: ${theme.colors.white};
    border-radius: ${theme.radii.rounded};
    border: ${theme.borderWidths.thin} solid ${theme.colors.neutral6};
    border-color: ${$isOn ? theme.colors.primary5 : theme.colors.neutral6};
    position: absolute;
    left: -1px;
    top: 50%;
    transition: transform ${theme.motions.duration.short}ms ease-in-out;
  `}
`

const labelSizes = {
  sm: ({ theme }: { theme: Theming }) => css`
    line-height: ${theme.sizes.$8};
    font-size: ${theme.fontSizes.sm};
    margin-right: ${theme.space.$4};
  `,
  md: ({ theme }: { theme: Theming }) => css`
    line-height: ${theme.sizes.$12};
    font-size: ${theme.fontSizes.base};
    margin-right: ${theme.space.$5};
  `,
  lg: ({ theme }: { theme: Theming }) => css`
    line-height: ${theme.sizes.$16};
    font-size: ${theme.fontSizes.lg};
    margin-right: ${theme.space.$8};
  `
}

type LabelProp = {
  $size: SwitchProps['size']
}

export const Label = styled.span<LabelProp>`
  ${({ $size }) => css`
    ${labelSizes[$size!]}

    vertical-align: middle;
  `}
`
