// @ts-strict
import { EscrowOrderSettlementTeamMember, ServiceOffice } from 'models'
import {
  EscrowOfficeLeadResponse,
  EscrowOrderResponse,
  EscrowOrderSettlementTeamMembersResponse
} from 'schema'
import { sorts } from 'utils'

export class EscrowOfficeLead {
  id!: string
  loanAmount?: number
  netEquity?: string
  orderClosingOn?: string
  orderIdentifier?: string
  orderNumber?: string
  orderOpenAt?: string
  orderStatus?: string
  transactionCoordinatorEmail?: string
  transactionCoordinatorName?: string
  serviceOffice?: ServiceOffice
  startedAsPreEscrow?: boolean
  escrowOrder?: EscrowOrderResponse
  escrowOrderSettlementTeamMembers?: Potential<EscrowOrderSettlementTeamMember>[]

  constructor(escrowOfficeLeadAttributes: EscrowOfficeLead) {
    Object.assign(this, escrowOfficeLeadAttributes)
  }

  private static sortTeamMembers(
    a: EscrowOrderSettlementTeamMembersResponse,
    b: EscrowOrderSettlementTeamMembersResponse
  ) {
    return sorts.role(a, b)
  }

  static create(
    escrowOfficeLeadResponse: Potential<EscrowOfficeLeadResponse>
  ): Potential<EscrowOfficeLead> {
    if (!escrowOfficeLeadResponse) {
      return
    }
    const serviceOffice = ServiceOffice.create(escrowOfficeLeadResponse?.serviceOffice)

    const escrowOrderSettlementTeamMembers =
      escrowOfficeLeadResponse?.escrowOrder?.escrowOrderSettlementTeamMembers
        ?.sort(this.sortTeamMembers)
        ?.map(EscrowOrderSettlementTeamMember.create)

    delete escrowOfficeLeadResponse.serviceOffice

    return new EscrowOfficeLead({
      ...escrowOfficeLeadResponse,
      serviceOffice,
      escrowOrderSettlementTeamMembers
    })
  }
}
