// @ts-strict
import {
  ChangeReasonButtonForm,
  Contents,
  FormField,
  InlineEditBoolean,
  InlineEditDateInput,
  InlineEditDollarsInput,
  InlineEditIntegerInput,
  InlineEditSelect,
  Section,
  Sections,
  SelectBoolean,
  ViewMore
} from 'components'
import { useCashOfferLeadController } from 'controllers'
import {
  protectedOfferStatusOptions,
  trueFalseOptions,
  useCcCashOfferLeadFields,
  useChangeReasons
} from 'lookups'
import { CashOfferLead } from 'models'
import { dataOrDash, titleize } from 'utils'
import { getCashOfferLeadInputProps } from './utils'

type Props = { cashOfferLead: CashOfferLead }

export const IROfferDetailsSection = ({ cashOfferLead }: Props) => {
  const { getCcCashOfferLeadField, getCcCashOfferLeadFieldName } = useCcCashOfferLeadFields()
  const { updateCashOfferLead } = useCashOfferLeadController(cashOfferLead.id)
  const { expressCloseChangeReasonsOptions } = useChangeReasons()
  const { getProps } = getCashOfferLeadInputProps(
    cashOfferLead,
    updateCashOfferLead,
    getCcCashOfferLeadField
  )

  const hasExpressClose = cashOfferLead.expressClose !== undefined

  return (
    <Contents>
      <Sections title={`Offer Details (${cashOfferLead.contingentStatus})`}>
        <Section>
          <FormField
            title={getCcCashOfferLeadFieldName('ipOfferTermsDaysTillCloseOfEscrow')}
            inline
          >
            <InlineEditIntegerInput {...getProps('ipOfferTermsDaysTillCloseOfEscrow')} />
          </FormField>
          <FormField
            title={getCcCashOfferLeadFieldName('ipOfferTermsDaysTillInspectionContingencyEnds')}
            inline
          >
            <InlineEditIntegerInput
              {...getProps('ipOfferTermsDaysTillInspectionContingencyEnds')}
            />
          </FormField>
          <FormField title={getCcCashOfferLeadFieldName('ipOfferTermsDeadlineDate')} inline>
            <InlineEditDateInput {...getProps('ipOfferTermsDeadlineDate')} />
          </FormField>
          <FormField title={getCcCashOfferLeadFieldName('ipContingencyRemovalAt')} inline>
            <InlineEditDateInput {...getProps('ipContingencyRemovalAt')} />
          </FormField>
          <FormField
            title={getCcCashOfferLeadFieldName('incomingPropertyInspectionContingencyWaived')}
            inline
          >
            <InlineEditSelect
              {...getProps('incomingPropertyInspectionContingencyWaived')}
              options={trueFalseOptions}
              addBlank
            />
          </FormField>
          <FormField title={getCcCashOfferLeadFieldName('propertyConditionApproved')} inline>
            <InlineEditSelect
              {...getProps('propertyConditionApproved')}
              options={trueFalseOptions}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title={getCcCashOfferLeadFieldName('expressClose')} inline>
            {hasExpressClose ? (
              <ChangeReasonButtonForm
                title={getCcCashOfferLeadFieldName('expressClose')}
                fieldName={getCcCashOfferLeadFieldName('expressClose')!}
                mutation={updateCashOfferLead}
                reasons={expressCloseChangeReasonsOptions}
                value={cashOfferLead.expressClose}
                inputComponent={SelectBoolean}
                formatter={titleize}
              />
            ) : (
              <InlineEditBoolean {...getProps('expressClose')} options={trueFalseOptions} />
            )}
          </FormField>
          <FormField title={getCcCashOfferLeadFieldName('protectedOfferStatus')} inline>
            <InlineEditSelect
              {...getProps('protectedOfferStatus')}
              options={protectedOfferStatusOptions}
            />
          </FormField>
          <FormField title={getCcCashOfferLeadFieldName('ipOfferTermsOptionTerminationFee')} inline>
            <InlineEditDollarsInput {...getProps('ipOfferTermsOptionTerminationFee')} />
          </FormField>
          <FormField
            title={getCcCashOfferLeadFieldName('ipOfferTermsInspectionObjectionDeadlineDate')}
            inline
          >
            <InlineEditDateInput {...getProps('ipOfferTermsInspectionObjectionDeadlineDate')} />
          </FormField>
          <FormField
            title={getCcCashOfferLeadFieldName('ipOfferTermsInspectionTerminationDeadlineDate')}
            inline
          >
            <InlineEditDateInput {...getProps('ipOfferTermsInspectionTerminationDeadlineDate')} />
          </FormField>
          <FormField
            title={getCcCashOfferLeadFieldName('ipOfferTermsInspectionResolutionDeadlineDate')}
            inline
          >
            <InlineEditDateInput {...getProps('ipOfferTermsInspectionResolutionDeadlineDate')} />
          </FormField>
          <FormField title={getCcCashOfferLeadFieldName('agentNotes')} inline>
            <ViewMore>{dataOrDash(getProps('agentNotes')?.value)}</ViewMore>
          </FormField>
        </Section>
      </Sections>
    </Contents>
  )
}
