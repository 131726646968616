import { Option } from 'classes'
import { ModelField } from '../ModelField'
import {
  TCCHLSimpleSaleLeadFieldAttribute,
  TCCHLSimpleSaleLeadFieldSetting
} from './ccHLSimpleSaleLeadFieldsSettings'

export class CCHLSimpleSaleLeadField extends ModelField {
  attr!: TCCHLSimpleSaleLeadFieldAttribute

  constructor(attributes: CCHLSimpleSaleLeadField) {
    super(attributes)
    Object.assign(this, attributes)
  }

  static createFromSetting(setting: TCCHLSimpleSaleLeadFieldSetting) {
    const options = setting.options?.map(opt => new Option(opt))
    return new CCHLSimpleSaleLeadField({ ...setting, options })
  }
}
