// @ts-strict
import { TabNav, Tabs } from 'components'
import { useLeadContext, useSavedSearchesContext } from 'contexts'
import { isCCBBYSLead, isCCHLSimpleSaleLead, isCCTradeInLead, isEscrowLead } from 'models'

export const LeadDocumentsTabs = () => {
  const { lead } = useLeadContext()
  const { getLastSearchUrl } = useSavedSearchesContext()
  const showDocusignTab = !isEscrowLead(lead) && !isCCHLSimpleSaleLead(lead)
  const showPhotosTab = isCCTradeInLead(lead) || isCCHLSimpleSaleLead(lead) || isCCBBYSLead(lead)
  const showTemplatesTab = isCCBBYSLead(lead)

  return (
    <Tabs>
      <TabNav to={getLastSearchUrl(lead?.getPathname() + '/documents')}>Files</TabNav>

      {showTemplatesTab && (
        <TabNav to={getLastSearchUrl(lead?.getPathname() + '/documents/templates')}>
          Generated Documents
        </TabNav>
      )}

      {showPhotosTab && (
        <TabNav to={getLastSearchUrl(lead?.getPathname() + '/documents/photos')}>Photos</TabNav>
      )}

      {showDocusignTab && (
        <TabNav to={getLastSearchUrl(lead?.getPathname() + '/documents/docusign')}>
          Docusign Envelopes
        </TabNav>
      )}

      <TabNav to={getLastSearchUrl(lead?.getPathname() + '/documents/external')}>
        External Resources
      </TabNav>
    </Tabs>
  )
}
