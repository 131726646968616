// @ts-strict
import { Button } from '@foundation/components'
import { ButtonForm, useButtonForm } from 'components'
import { UseMutationResult } from 'hooks'
import { CCCashOfferLead } from 'models'
import { validatePresence } from 'utils/validations'
import { LeadNewIncomingResidenceFields } from './LeadNewIncomingResidenceFields'

type TProps = {
  createCashOffer: UseMutationResult
  lead: CCCashOfferLead
}
export type TLeadNewIncomingResidencePayload = {
  incomingPropertyBuyerAgentId?: string
  incomingPropertyFullAddress: string
  leadId: string
}

const formValidations = {
  incomingPropertyFullAddress: { label: 'Full Address', validations: [validatePresence] }
}

export const LeadNewIncomingResidenceButtonForm = ({ lead, createCashOffer }: TProps) => {
  const initialPayload: TLeadNewIncomingResidencePayload = {
    leadId: lead.id,
    incomingPropertyFullAddress: '',
    incomingPropertyBuyerAgentId: undefined
  }

  const { formProps, fieldsProps } = useButtonForm({
    initialPayload,
    mutation: createCashOffer,
    formValidations
  })

  return (
    <>
      <Button onClick={formProps.toggleShowForm} variant="outline" color="neutralLight" size="xs">
        New IR
      </Button>
      <ButtonForm title="New Incoming Residence" hideTriggerButton {...formProps}>
        <LeadNewIncomingResidenceFields fieldsProps={fieldsProps} />
      </ButtonForm>
    </>
  )
}
