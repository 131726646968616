import { ReactNode, useRef } from 'react'
import { Coaster } from 'components'
import { useClickAway } from 'hooks'
import { cx } from 'utils'
import styles from './Menu.module.scss'

type Props = {
  button?: ReactNode
  children: ReactNode
  className?: string
  disabled?: boolean
  justify?: 'left' | 'right'
  onClick: () => void
  open?: boolean
  title: ReactNode
}

export const MenuSimple = ({
  title,
  children,
  className,
  justify = 'right',
  open = false,
  onClick,
  button,
  disabled = false,
  ...props
}: Props) => {
  const menuRef = useRef(null)

  useClickAway(menuRef, onClick, !open)

  return (
    <div className={cx(styles.root, className)} data-testid="menu" {...props} ref={menuRef}>
      {button || (
        <button
          className={cx(styles.title, 'menu-title')}
          type="button"
          onClick={onClick}
          data-testid="menuTitle"
          disabled={disabled}
        >
          {title}
        </button>
      )}
      {open ? (
        <Coaster data-testid="menuItems" className={justify} {...props}>
          {children}
        </Coaster>
      ) : null}
    </div>
  )
}
