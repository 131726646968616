// @ts-strict
import { FileVersionResponse } from 'schema'

export class FileVersion {
  id!: string
  fastlyUrl!: string
  metadata?: string
  mime?: string

  constructor(attributes: FileVersion) {
    Object.assign(this, attributes)
  }

  static create(response: Potential<FileVersionResponse>): Potential<FileVersion> {
    if (!response) {
      return
    }

    const fastlyUrl = `https://${response.fastlyUrl}`
    return new FileVersion({ ...response, fastlyUrl })
  }
}
