type TCacheItem<T extends unknown> = {
  date: number
  value: T
}

type TCache = {
  [key: string]: TCacheItem<any>
}

const storageKey = 'localStorageCache'

/**
 * LocalStorageCache
 *
 * Mechanism for caching results from Promises in Local Storage to reduce consecutive/duplicate queries to the server.
 *
 * @param cacheKey - to identify the cache value
 * @param ttl - number (of milliseconds) until cache is cold
 *
 */

export class LocalStorageCache<T extends unknown> {
  key: string
  ttl: number

  constructor(key: string, ttl: number) {
    this.key = key
    this.ttl = ttl
  }

  private store(value: T) {
    const now = new Date().getTime()
    const cachedItem: TCacheItem<T> = {
      date: now,
      value
    }
    global.localStorage.setItem(storageKey, JSON.stringify({ [this.key]: cachedItem }))
  }

  async refetch(callback: () => Promise<T>): Promise<T> {
    const now = new Date().getTime()
    const cacheString = global.localStorage.getItem(storageKey)
    const cache: TCache = cacheString && JSON.parse(cacheString)
    const cachedItem: TCacheItem<T> = cache && cache[this.key]
    let data: TCacheItem<T>['value']

    if (cachedItem && cachedItem.date + this.ttl > now) {
      data = cachedItem.value
    } else {
      data = await callback()
      this.store(data)
    }

    return data
  }

  clear() {
    const cacheString = global.localStorage.getItem(storageKey)
    const cache: TCache = cacheString ? JSON.parse(cacheString) : {}
    delete cache[this.key]
    global.localStorage.setItem(storageKey, JSON.stringify(cache))
  }
}
