import { LeadInterface } from '../..'

type Data = Pick<LeadInterface, 'id' | 'userType'>
type PathKey = LeadInterface['userType']

const paths: Record<PathKey, (id: string) => string> = {
  buyer: (id: string) => `/buyers/${id}`,
  seller: (id: string) => `/sellers/${id}`,
  escrow: (id: string) => `/escrows/${id}`,
  mortgage: (id: string) => `/mortgages/${id}`,
  cc_trade_in: (id: string) => `/trade-ins/${id}`,
  cc_cash_offer: (id: string) => `/cash-offers/${id}`,
  cc_hl_simple_sale: (id: string) => `/hlss/${id}`
}

export const getPathname = ({ id, userType }: Data) => {
  if (!id || !userType) {
    return ''
  }

  const leadPath = paths[userType]?.(id)

  if (!leadPath) {
    return `/leads/${id}`
  }

  return leadPath
}
