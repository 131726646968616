export const HOME_FEATURES_KEYS = [
  'preFabHome',
  'ageRestrictedCommunity',
  'gatedCommunity',
  'unpermittedAddition',
  'significantFoundationIssues',
  'floodZone',
  'pool',
  'solarPanels'
] as const

export type HomeFeaturesKeys = (typeof HOME_FEATURES_KEYS)[number]

export type Field = {
  key: HomeFeaturesKeys
  title: string
  value: boolean
}

export type HomeFeaturesPayload = {
  homeFeatures: string[]
}
