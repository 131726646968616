import { ReactNode, useState } from 'react'
import * as S from './styles'

type Props = {
  children: ReactNode
  content: ReactNode
  'data-testid'?: string
}

export const ActivityCollapseRow = ({ children, content, 'data-testid': dataTestId }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleCollapse = () => {
    setIsOpen(state => !state)
  }

  return (
    <S.Wrapper data-testid={dataTestId}>
      <S.Row onClick={handleCollapse}>{content}</S.Row>
      {isOpen && children}
    </S.Wrapper>
  )
}
