// @ts-strict
import { ReactNode } from 'react'
import { useKey } from 'react-use'
import { Modal } from 'components'
import { PopupHeader } from './PopupHeader'
import * as S from './styles'

export type PopupPropTypes = {
  children: ReactNode
  className?: string
  modalClassName?: string
  onCancel: () => void
  title?: string
}

export const Popup = ({ children, className, modalClassName, onCancel, title }: PopupPropTypes) => {
  useKey(event => event.key === 'Escape', onCancel, { event: 'keydown' })

  return (
    <Modal onCancel={onCancel} className={modalClassName}>
      <S.Popup className={className} data-testid="popup-contents">
        <PopupHeader title={title} onCancel={onCancel} />
        {children}
      </S.Popup>
    </Modal>
  )
}
