import { Section, Sections } from 'components'
import { useFeatureFlags } from 'hooks'
import { useFields } from '..'
import { FieldFormField } from '../FieldFormField'

export const CCTradeInClientInEscrow = () => {
  const { salesAppImprovement } = useFeatureFlags(['sales-app-improvement'])
  useFields({
    required: [salesAppImprovement.enabled ? 'hlcsAttached' : undefined]
  })

  return (
    <Sections>
      {salesAppImprovement.enabled && (
        <Section title="DR Sale Transaction">
          <FieldFormField field="hlcsAttached" />
        </Section>
      )}
    </Sections>
  )
}
