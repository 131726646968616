import { environment } from 'environment'

export const getTwilioUrl = (sid: string = '') => {
  switch (environment.env) {
    case 'production': {
      return `https://api.twilio.com/2010-04-01/Accounts/AC6a5690e31c3647968c7da9ac2589d342/Recordings/${sid}.wav`
    }
    case 'staging':
    case 'development': {
      return `https://api.twilio.com/2010-04-01/Accounts/ACde3b1cc8bc0fa70c6b369596f686aee3/Recordings/${sid}.wav`
    }
    default: {
      return ''
    }
  }
}
