import { isNumeric } from '../isNumeric'

function isObject(obj: any): obj is Record<string, any> {
  return obj !== null && typeof obj === 'object'
}

function isSymbol(obj: any): obj is Symbol {
  return obj !== null && typeof obj === 'symbol'
}

export const isEmptyValue = (value: any): boolean => {
  if (Array.isArray(value)) {
    return value.every(isEmptyValue)
  } else if (value instanceof Date) {
    return false
  } else if (value instanceof File) {
    return false
  } else if (isSymbol(value)) {
    return false
  } else if (isObject(value)) {
    return Object.values(value).every(isEmptyValue)
  } else if (isNumeric(value)) {
    return false
  } else if (value) {
    return false
  }

  return true
}
