import { orderColumns } from './ordersColumnsSettings'

export const simpleSaleOrdersColumnsSettings = [
  orderColumns.clientName,
  orderColumns.property,
  orderColumns.lead,
  orderColumns.stage,
  orderColumns.createdAt,
  orderColumns.updatedAt
] as const

export type TSimpleSaleOrdersColumnValue = (typeof simpleSaleOrdersColumnsSettings)[number]['value']
