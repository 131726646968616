// @ts-strict
import { Fragment } from 'react'
import { MenuSection } from 'components'
import { RadioButtonField } from 'components'
import radioButtonsStyle from 'components/Form/RadioButtons/RadioButtons.module.scss'
import { TTaskStatusSetting, TTaskStatusValue } from 'lookups'
import { cx } from 'utils'
import styles from './FiltersCompletionStatus.module.scss'

type TProps = {
  onChange: (value: Potential<TTaskStatusValue[]>) => any
  value: Potential<TTaskStatusValue[]>
}
type TOption = {
  name: string
  subOptions?: TTaskStatusSetting[]
  value: Potential<TTaskStatusValue>
}

const options: Array<TOption> = [
  {
    name: 'All',
    value: undefined
  },
  {
    name: 'Open',
    value: 'active',
    subOptions: [
      {
        name: 'All',
        value: 'active'
      },
      {
        name: 'Ready',
        value: 'ready'
      },
      {
        name: 'Scheduled',
        value: 'upcoming'
      },
      {
        name: 'Overdue',
        value: 'past_due'
      }
    ]
  },
  {
    name: 'Complete',
    value: 'completed'
  },
  {
    name: 'Dismissed',
    value: 'dismissed'
  }
]

export const FiltersCompletionStatus = ({ value, onChange }: TProps) => {
  const valueFromArray = value ? value[0] : undefined
  const handleChange = (v: Potential<TTaskStatusValue>) => {
    onChange(v ? [v] : undefined)
  }

  return (
    <MenuSection
      title="Completion Status"
      className={cx(radioButtonsStyle.radioButtons, styles.wrapper)}
    >
      {options.map(opt => {
        const isChildChecked = !!opt.subOptions?.some(sub => sub.value === valueFromArray)
        const isChecked = valueFromArray === opt.value
        const key = `${opt.name}-${opt.value}`
        return (
          <Fragment key={key}>
            <RadioButtonField
              title={opt.name}
              label={opt.name}
              name={key}
              className={radioButtonsStyle.radioButtonField}
              checked={isChecked || isChildChecked}
              onChange={() => handleChange(opt.value)}
            />
            {opt.subOptions && (
              <fieldset className={styles.fieldSet}>
                {opt.subOptions.map(sub => {
                  const subKey = `${sub.name}-${sub.value}`
                  return (
                    <RadioButtonField
                      key={subKey}
                      title={sub.name}
                      label={sub.name}
                      name={subKey}
                      className={radioButtonsStyle.radioButtonField}
                      checked={valueFromArray === sub.value}
                      onChange={() => handleChange(sub.value)}
                    />
                  )
                })}
              </fieldset>
            )}
          </Fragment>
        )
      })}
    </MenuSection>
  )
}
