import { Lead } from '../../../models'

export const calculateBuyerIntent = (lead?: Lead) => {
  if (!lead) {
    return
  }

  const preapproved = lead.callReview?.preapproved?.toLowerCase() || ''
  const buyerProgress = lead.callReview?.buyerProgress?.toLowerCase() || ''
  const timeline = lead.humanTimeline?.toLowerCase() || ''

  if (['lender contacted', 'yes'].includes(preapproved)) {
    return 'Action Ready'
  } else if (
    preapproved !== 'cash buyer' &&
    ['viewing properties', 'in contract', 'ready to offer'].includes(buyerProgress)
  ) {
    return 'Action Ready'
  } else if (preapproved !== 'cash buyer' && ['asap', '0-3 months'].includes(timeline)) {
    return 'Action Ready'
  } else if (
    (!preapproved || preapproved === 'no') &&
    (!buyerProgress || buyerProgress === 'researching options') &&
    timeline === '3-6 months'
  ) {
    return 'Exploratory'
  }
}
