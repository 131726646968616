// @ts-strict

import { NavLink, NavLinkProps } from 'react-router-dom'
import { cx } from 'utils'
import styles from './OrderLeadNavigation.module.scss'

export const OrderLeadNavigationLink = (props: NavLinkProps) => (
  <NavLink
    className={({ isActive }) => cx(isActive && styles.activeLink, styles.link)}
    {...props}
  />
)
