type TEnv = 'development' | 'production' | 'staging' | 'test'
type TSentryEnv = 'production' | 'staging'

type TEnvironment = {
  agentAdminURL: string
  agentPrefsURL: string
  agentProfileURL: string
  apiMonolith?: string
  apiRoot?: string
  authClientId: string
  doorkeeperAuthClientId: string
  env?: TEnv
  flagsmithApi: string
  flagsmithKey: string
  mapboxToken?: string
  oldSalesAdminURL: string
  oldSalesLeadURL: string
  planningAppURL: string
  sentryDSN?: string
  sentryEnvironment: TSentryEnv
  signInURL: string
  sourceVersion?: string
  stripeProsPriceId?: string
  webSocket?: string
  webSocketTicket?: string
}

const env: TEnv = process.env.REACT_APP_ENV as TEnv

export const environment: TEnvironment = {
  env,
  apiMonolith: process.env.REACT_APP_MONOLITH_API,
  apiRoot: process.env.REACT_APP_API_ROOT,

  agentAdminURL: `${process.env.REACT_APP_ROOT_URL}/sales/agents/`,
  agentPrefsURL: `${process.env.REACT_APP_ROOT_URL}/sales/app#/agent/`,
  agentProfileURL: `${process.env.REACT_APP_ROOT_URL}/agents/`,
  oldSalesLeadURL: `${process.env.REACT_APP_ROOT_URL}/sales/leads/`,
  oldSalesAdminURL: `${process.env.REACT_APP_ROOT_URL}/admin/`,
  planningAppURL: `${process.env.REACT_APP_PLANNING_APP_URL}`,

  flagsmithApi: 'https://edge.api.flagsmith.com/api/v1/',
  flagsmithKey: process.env.REACT_APP_FLAGSMITH_KEY,

  mapboxToken: process.env.REACT_APP_MAPBOX_TOKEN,

  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  sentryEnvironment: env === 'production' ? 'production' : 'staging',

  authClientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
  doorkeeperAuthClientId: process.env.REACT_APP_DOORKEEPER_CLIENT_ID,
  signInURL: process.env.REACT_APP_SIGN_IN_URL,

  webSocket: process.env.REACT_APP_WEBSOCKET,
  webSocketTicket: process.env.REACT_APP_WEBSOCKET_TICKET,

  sourceVersion: process.env.REACT_APP_SOURCE_VERSION,

  stripeProsPriceId: process.env.REACT_APP_STRIPE_PROS_PRICE_ID
}
