import {
  EmployeesAutoComplete,
  FormField,
  MapboxAutoComplete,
  Section,
  Select,
  TextInput
} from 'components'
import { useEmployeeUsers } from 'hooks'
import { TFormProps } from '../types'

export const Standard = ({ payload, onChangeAttribute }: TFormProps) => {
  const standardFormFieldProps = key => ({
    value: payload[key],
    onChange: val => onChangeAttribute(key, val)
  })
  const { getUserName } = useEmployeeUsers()

  return (
    <>
      <Section title="Lead details">
        <FormField title="Parent Lead ID">
          <TextInput {...standardFormFieldProps('parentLeadId')} />
        </FormField>
        <FormField title="Property address">
          <MapboxAutoComplete
            data-testid="property-autocomplete"
            value={payload.fullAddress}
            onSelect={val => onChangeAttribute('fullAddress', val.title)}
            onClear={() => onChangeAttribute('fullAddress', '')}
            placeholder="Enter property address or client name"
          />
        </FormField>
        <FormField title="Referring Agent ID">
          <TextInput {...standardFormFieldProps('referringOwnerId')} />
        </FormField>
        <FormField title="Leg of Transaction">
          <Select
            data-testid="leg-of-transaction-select"
            options={[
              {
                name: '1st Leg',
                value: 'buyer'
              },
              {
                name: '2nd Leg',
                value: 'seller'
              }
            ]}
            {...standardFormFieldProps('secondaryUserType')}
          />
        </FormField>
      </Section>
      <Section title="Lead users">
        <FormField title="ASM">
          <EmployeesAutoComplete
            value={getUserName(payload.asmId)}
            onSelect={val => onChangeAttribute('asmId', val?.id)}
            onClear={() => onChangeAttribute('asmId', '')}
            data-testid="asm-autocomplete"
          />
        </FormField>
        <FormField title="SRM">
          <EmployeesAutoComplete
            value={getUserName(payload.srmId)}
            onSelect={val => onChangeAttribute('srmId', val?.id)}
            onClear={() => onChangeAttribute('srmId', '')}
            data-testid="srm-autocomplete"
          />
        </FormField>
      </Section>
      <Section title="Transaction coordinator">
        <FormField title="Transaction coordinator name">
          <TextInput {...standardFormFieldProps('transactionCoordinatorName')} />
        </FormField>
        <FormField title="Transaction coordinator email">
          <TextInput {...standardFormFieldProps('transactionCoordinatorEmail')} />
        </FormField>
      </Section>
    </>
  )
}
