export const DEFAULT_PLATFORM_METRICS_TIME_RANGE = 'current_month'

export type PlatformMetricsKeys =
  | 'agentToHlTradeInCloses'
  | 'escrowOpens'
  | 'agentToHlCashOfferLeads'
  | 'metInPersonReferrals'
  | 'agentToHlTradeInLeads'
  | 'closedReferrals'
  | 'connectedReferrals'
  | 'agentToHomelightSimpleSaleCloses'
  | 'agentLeftVmReferrals'
  | 'agentToHlCashOfferApprovalsCompleted'
  | 'meetingScheduledReferrals'
  | 'agentToHomelightSimpleSaleApprovalsCompleted'
  | 'failedReferrals'
  | 'agentToHlTradeInApprovalsCompleted'
  | 'listingReferrals'
  | 'agentToHomelightSimpleSaleLeads'
  | 'agentToHlCashOfferCloses'
  | 'homesCloses'
  | 'homesLeads'
  | 'homesApprovals'
  | 'introducedReferrals'
  | 'escrowCloses'

export type PlatformMetricsTimeRanges = {
  currentMonth?: number
  last12Months?: number
  last3Months?: number
  last6Months?: number
  lastMonth?: number
}

export type AgentPlatformMetricsSchemaAttributes =
  | Record<PlatformMetricsKeys, PlatformMetricsTimeRanges> & { lastUpdatedAt: string }

export interface AgentPlatformMetricsSchemaResponse
  extends AgentPlatformMetricsSchemaAttributes,
    Record<PlatformMetricsKeys, PlatformMetricsTimeRanges> {}

export interface AgentPlatformMetricsSchema {
  platformMetrics: Array<keyof AgentPlatformMetricsSchemaResponse>
}
