// @ts-strict
import { Table, TableCellAlignments, TableResults } from 'components'
import styles from 'content/Leads/LeadsTable/LeadsTableCells/LeadsTableCells.module.scss'
import { ListProvider, TListFilters, useListContext } from 'contexts'
import { TGeneratedColumnsLookup } from 'lookups'
import { cx } from 'utils'
import { SmartHeaderCells } from './SmartTableHeader'
import { SmartTableRow } from './SmartTableRow'

export function SmartTable<T extends BaseTableItem>({
  filters,
  columns,
  columnMapping,
  'data-testid': dataTestId,
  isLoading,
  items
}: SmartTableProps<T>) {
  const { visibleColumns } = useListContext()
  const colSpan = visibleColumns.length

  return (
    <ListProvider filters={filters} columns={columns}>
      <Table className={cx(styles.table)} data-testid={dataTestId} alternatingColumns>
        <SmartHeaderCells />
        <TableResults type="items" colSpan={colSpan} isLoading={isLoading}>
          {items?.map((item: T) => (
            <SmartTableRow<T> key={item.id} value={item} columnMapping={columnMapping} />
          ))}
        </TableResults>
      </Table>
    </ListProvider>
  )
}

type ColumnMapping<T extends BaseTableItem> = Record<
  string,
  ({
    value,
    testId,
    column,
    key,
    align
  }: {
    align: TableCellAlignments | undefined
    column: string
    key: string
    testId: string
    value: T
  }) => JSX.Element
>

type SmartTableProps<T extends BaseTableItem> = {
  columnMapping: ColumnMapping<T>
  columns: TGeneratedColumnsLookup
  'data-testid'?: string
  filters: TListFilters
  isLoading: boolean
  items: Potential<Array<T>>
}

type BaseTableItem = {
  id: string
}
