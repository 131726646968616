import { Table, TableResults } from 'components'
import { EnvelopeTableRow } from './EnvelopeTableRow'
import styles from './EnvelopesTable.module.scss'
import { EnvelopesTableHeader } from './EnvelopesTableHeader'

export const EnvelopesTable = ({ envelopes }) => {
  return (
    <Table className={styles.table} data-testid="envelopes-table">
      <EnvelopesTableHeader />
      <TableResults type="Envelopes" colSpan={8} isLoading={false}>
        {envelopes?.map(envelope => (
          <EnvelopeTableRow envelope={envelope} key={envelope.id} />
        ))}
      </TableResults>
    </Table>
  )
}
