import { Activity } from 'models'
import { getSalesOrdersUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchOrderActivities = async (id: string) => {
  const url = getSalesOrdersUrl({ id, action: 'activity-feed' })
  const response = await getRequest(url)
  const activities = await deserializeAsync(response.data, {
    transform: Activity.create
  })
  return {
    activities
  }
}
