// @ts-strict
import { Button } from '@foundation/components'
import {
  ButtonForm,
  EmailInput,
  FormField,
  FormFieldSet,
  PhoneInput,
  Section,
  Sections,
  TextInput,
  useButtonForm
} from 'components'
import { useLeadController } from 'controllers'
import { Lead } from 'models'
import { validateEmailFormat, validatePhoneFormat, validatePresence } from 'utils'

type TProps = {
  lead: Lead
}

export const LeadEditContactInfoButtonForm = ({ lead }: TProps) => {
  const { updateLead } = useLeadController(lead.id, lead.userType!)

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm({
    mutation: updateLead,
    initialPayload: {
      email: lead.email,
      emailAlt: lead.emailAlt,
      name: lead.name,
      partnerName: lead.partnerName,
      phoneNumber: lead.phoneNumbers?.[0]?.number
    },
    formValidations: {
      email: [validatePresence, validateEmailFormat],
      emailAlt: [validateEmailFormat],
      name: [validatePresence],
      phoneNumber: [validatePhoneFormat]
    }
  })
  return (
    <>
      <Button variant="outline" color="neutralLight" size="xs" onClick={formProps.toggleShowForm}>
        Edit
      </Button>
      <ButtonForm title="Contact Info" hideTriggerButton {...formProps}>
        <Sections>
          <Section>
            <FormFieldSet>
              <FormField title="Name" htmlFor="name">
                <TextInput
                  id="name"
                  value={payload.name}
                  onChange={val => onChangeAttribute('name', val)}
                />
              </FormField>
              <FormField title="Email" htmlFor="email">
                <EmailInput
                  id="email"
                  value={payload.email}
                  onChange={val => onChangeAttribute('email', val)}
                />
              </FormField>
              <FormField title="Phone" htmlFor="phone">
                <PhoneInput
                  id="phone"
                  value={payload.phoneNumber}
                  onChange={val => onChangeAttribute('phoneNumber', val)}
                />
              </FormField>
            </FormFieldSet>
          </Section>
          <Section>
            <FormFieldSet>
              <FormField title="Partner Name" htmlFor="partner-name">
                <TextInput
                  id="partner-name"
                  value={payload.partnerName}
                  onChange={val => onChangeAttribute('partnerName', val)}
                />
              </FormField>
              <FormField title="Email Alt" htmlFor="email-alt">
                <EmailInput
                  id="email-alt"
                  value={payload.emailAlt}
                  onChange={val => onChangeAttribute('emailAlt', val)}
                />
              </FormField>
            </FormFieldSet>
          </Section>
        </Sections>
      </ButtonForm>
    </>
  )
}
