import { TaskAttachable } from 'hooks'
import { isCCCashOfferLead } from 'models'
import { presence } from 'utils'
import { TTaskFormPayload } from '.'

type TArgs = {
  attachable?: TaskAttachable
  documents?: TTaskFormPayload['metadata']['documents']
  payload: TTaskFormPayload
  sendNotification?: TTaskFormPayload['metadata']['send_notification']
}

export const transformPayload = ({ attachable, payload, documents, sendNotification }: TArgs) => {
  const shouldAddSubAttachableType =
    isCCCashOfferLead(attachable) && presence(payload.subAttachableId)

  const withMetadata =
    documents || sendNotification
      ? {
          metadata: {
            ...(payload.metadata || {}),
            documents,
            send_notification: sendNotification
          }
        }
      : {}

  return {
    ...payload,
    ...(shouldAddSubAttachableType && { subAttachableType: 'CashOfferLead' }),
    ...withMetadata
  }
}
