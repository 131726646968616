import { ReactNode, useEffect, useRef, useState } from 'react'
import { Coaster } from 'components/Coaster'
import { usePageResize, useWindowScroll } from 'hooks'
import { cx, presence } from 'utils'
import styles from './Flipup.module.scss'

type TProps = {
  children: ReactNode
  className?: string
  title?: ReactNode
}

export const Flipup = ({ title, children, className }: TProps) => {
  const flipUpRef = useRef(null)
  const { width } = usePageResize()
  const { x, y } = useWindowScroll()
  const [style, setStyle] = useState({})
  const [open, setOpen] = useState(false)

  const calculatePositioning = () => {
    const rect = flipUpRef.current.getBoundingClientRect()
    let top
    let left
    let translateTop = '0'
    let translateLeft = '0'

    if (rect.top < window.innerHeight / 2) {
      top = rect.bottom
    } else {
      top = rect.top
      translateTop = '-100%'
    }
    if (rect.left < window.innerWidth / 2) {
      left = rect.right
    } else {
      left = rect.left
      translateLeft = '-100%'
    }

    setStyle({ left, top, transform: `translate(${translateLeft}, ${translateTop})` })
  }

  const showFlipup = () => {
    if (!presence(children)) {
      return
    }

    calculatePositioning()
    setOpen(true)
  }

  const hideFlipup = () => {
    setOpen(false)
  }

  useEffect(() => {
    setOpen(false)
  }, [x, y, width])

  return (
    <div
      className={cx(className)}
      data-testid="flipup"
      ref={flipUpRef}
      onMouseEnter={showFlipup}
      onMouseLeave={hideFlipup}
    >
      {title}
      {open ? (
        <Coaster
          data-testid="flipup-content"
          style={style}
          className={styles.coaster}
          onMouseEnter={showFlipup}
          onMouseLeave={hideFlipup}
        >
          {children}
        </Coaster>
      ) : null}
    </div>
  )
}
