import { cx } from 'utils'
import { ButtonV2, ButtonV2Props } from '../ButtonV2'
import styles from './CreateButton.module.scss'

export const CreateButton = ({ children, className, ...props }: ButtonV2Props) => {
  return (
    <ButtonV2 className={cx(className, styles.createButton)} {...props}>
      {children}
    </ButtonV2>
  )
}
