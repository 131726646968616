// @ts-strict
import { ButtonForm, useButtonForm } from 'components'
import { DeleteIconButton } from 'components/Button/IconButton'
import { useUserIntegrationController } from 'hooks'

type TProps = {
  integrationId: string
  name: string
  value: string
}

export const UserIntegrationDeleteButtonForm = ({ name, value, integrationId }: TProps) => {
  const { removeUserIntegration } = useUserIntegrationController(integrationId)

  const { formProps } = useButtonForm({
    initialPayload: {},
    mutation: removeUserIntegration,
    formValidations: {}
  })

  return (
    <ButtonForm
      title="Delete User Integration"
      buttonText="Delete"
      buttonComponent={DeleteIconButton}
      {...formProps}
    >
      <span>
        Are you sure you want to delete this <strong>{name}</strong> user integration?
      </span>
      <p>
        Current value: <strong>{value}</strong>
      </p>
    </ButtonForm>
  )
}
