import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Badge.module.scss'

export type BadgeProps = {
  children: ReactNode
  className?: string
  strikeThru?: boolean
  stroke?: boolean
  title?: string
}

export const Badge = ({
  className,
  title,
  strikeThru = false,
  stroke = false,
  children,
  ...props
}: BadgeProps) => {
  const strokeWidth = stroke ? 0.5 : 0
  const radius = 12 - strokeWidth

  return (
    <svg viewBox="0 0 24 24" className={cx(styles.badge, className)} {...props}>
      {title ? <title>{title}</title> : null}
      <circle cx="12" cy="12" r={radius} strokeWidth={strokeWidth} />
      <text x="50%" y="50%" textAnchor="middle">
        {children}
      </text>
      {strikeThru ? (
        <line
          x1="24"
          y1="0"
          x2="0"
          y2="24"
          stroke="#C13131"
          strokeWidth="3"
          strokeLinecap="square"
        />
      ) : null}
    </svg>
  )
}
