// @ts-strict
import { LeadResponse } from 'schema'
import { AgentMatchingLead } from '../AgentMatchingLead'

export class BuyerLead extends AgentMatchingLead {
  modelName = 'BuyerLead'

  static create(agentMatchingLeadResponse: Potential<LeadResponse>): Potential<BuyerLead> {
    if (!agentMatchingLeadResponse) {
      return
    }

    return new BuyerLead(AgentMatchingLead.mapAgentMatchingLeadResponse(agentMatchingLeadResponse))
  }
}
