import { Badge } from 'components'
import { Agent } from 'models'
import { cx } from 'utils'
import styles from './AgentTierBadge.module.scss'

type Props = {
  agent: Agent
  className?: string
}

export const AgentTierBadge = ({ agent, className }: Props) => {
  if (!agent.badge) {
    return null
  }

  const slugStyle = styles[agent.badge.slug] || null

  return (
    <Badge
      title={agent.badge.name}
      className={cx(styles.agentTierBadge, slugStyle, className)}
      data-testid="agentTierBadge"
    >
      {agent.badge.abbr}
    </Badge>
  )
}
