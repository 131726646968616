import { useEffect } from 'react'
import { ActionBar, Contents, LoadingText } from 'components'
import { GeneratedDocumentsTable } from 'content/GeneratedDocuments'
import { LeadNewGeneratedDocumentButtonForm } from 'content/Lead'
import { ParamsProvider, useLeadContext } from 'contexts'
import { useGeneratedDocumentsController } from 'controllers'
import { useDocumentTitle } from 'hooks'
import { CCBBYSLead } from 'models'
import { LeadDocumentsTabs } from '../LeadDocumentsTabs'

const LeadTemplatesContents = ({ lead }: { lead?: CCBBYSLead }) => {
  const { generatedDocuments, generatedDocumentsLoading } = useGeneratedDocumentsController({
    bbysLeadId: lead?.bbysLead.id
  })

  if (generatedDocumentsLoading) {
    return <LoadingText />
  }

  const actions = (
    <ActionBar>
      <LeadNewGeneratedDocumentButtonForm lead={lead} />
    </ActionBar>
  )
  return (
    <>
      <LeadDocumentsTabs />
      <Contents title="Generated Documents" actions={actions}>
        <GeneratedDocumentsTable lead={lead} generatedDocuments={generatedDocuments} />
      </Contents>
    </>
  )
}
export const LeadTemplatesTab = () => {
  const { lead } = useLeadContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${lead.name} - Documents - Generated Documents`), [lead, setTitle])

  return (
    <ParamsProvider>
      <LeadTemplatesContents lead={lead} />
    </ParamsProvider>
  )
}
