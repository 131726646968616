import { LeadGroupableDefinitionType } from 'models'
import { getLeadGroupableDefinitionUrl } from 'services/getUrl'
import { deleteRequest } from 'utils'

export const destroyLeadGroupableDefinition = async (
  groupableDefinitionType: LeadGroupableDefinitionType,
  groupableDefinitionId: string
) => {
  return await deleteRequest(
    getLeadGroupableDefinitionUrl({
      action: `${groupableDefinitionType}/${groupableDefinitionId}`
    })
  )
}
