import { OrderAttributes } from 'schema'
import { getOrdersUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export const putOrder = (
  orderId: string,
  payload: Record<keyof OrderAttributes, string>
): Promise<any> => {
  return putRequest(getOrdersUrl({ id: orderId }), snakeCaseKeys(payload))
}
