import {
  AddressUnitAutoComplete,
  AgentsAutoComplete,
  FormField,
  Section,
  Sections
} from 'components'

export const CCTradeInLeadFormFields = ({ formTitle, fieldSettings, fieldValues, set, remove }) => {
  return (
    <Sections title={formTitle}>
      <Section>
        <FormField title="Property Address" required={fieldSettings.propertyAddress.isRequired}>
          <AddressUnitAutoComplete
            data-testid="propertyAddress"
            onSelect={val => set('propertyAddress', val)}
          />
        </FormField>
      </Section>

      <Section>
        <FormField title="Seller Agent" required={fieldSettings.sellerAgent.isRequired}>
          <AgentsAutoComplete
            data-testid="sellerAgentSelect"
            value={fieldValues.sellerAgent?.title}
            searchParams={{ tradeIn: 'enrolled' }}
            onSelect={item => set('sellerAgent', item)}
            onClear={() => remove('sellerAgent')}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
