// @ts-strict
import { Option } from 'classes'

export class OptionGroup {
  label!: string
  options!: Option[]

  constructor(optionGroupAttributes: OptionGroup) {
    Object.assign(this, optionGroupAttributes)
  }
}
