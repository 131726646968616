// @ts-strict
import {
  Agent,
  BBYSLead,
  BBYSProviderLead,
  LeadUser,
  LenderProviderLead,
  UserInvitation
} from 'models'
import { LeadResponse } from 'schema'
import { byAttr } from 'utils'
import { Lead, TLeadChildConstructor } from '../Lead'
import { getLead, getLeadUsers } from '../utils'

type TCCBBYSLeadConstructor = TLeadChildConstructor<CCBBYSLead>

export class CCBBYSLead extends Lead {
  agent?: Agent
  agentTeamsName?: string
  coListingAgent?: Agent
  calculatedValue?: string
  siblingLeads?: Lead[]
  isHLSS?: boolean
  modelName? = 'CCBBYSLead'
  providableName? = 'bbysLead'
  providerName? = 'bbysProviderLead'
  referringAgent?: Agent
  revenueRecognitionDate?: string
  bbysLead?: BBYSLead
  bbysProviderLead?: BBYSProviderLead
  lenderProviderLeads?: LenderProviderLead[]
  userInvitations?: UserInvitation[]
  newHomeConsultant?: LeadUser
  loanOfficerAssistant?: LeadUser
  loanOfficerAdditionalContacts?: LeadUser[]
  transactionCoordinator?: LeadUser
  constructor(cCBBYSLeadAttributes: TCCBBYSLeadConstructor) {
    super(cCBBYSLeadAttributes)
    Object.assign(this, cCBBYSLeadAttributes)
  }

  static create(cCBBYSLeadResponse: Potential<LeadResponse>): Potential<CCBBYSLead> {
    if (!cCBBYSLeadResponse) {
      return
    }
    const bbysProviderLead = BBYSProviderLead.create(cCBBYSLeadResponse.bbysProviderLead)
    const bbysLead = bbysProviderLead?.bbysLead
    const leadUsers = getLeadUsers(cCBBYSLeadResponse)
    const newHomeConsultant = leadUsers?.find(byAttr('role', 'external_sales_consultant'))
    const loanOfficerAssistant = leadUsers?.find(byAttr('role', 'loan_officer_assistant'))
    const loanOfficerAdditionalContacts = leadUsers?.filter(
      byAttr('role', 'loan_officer_additional_contact')
    )
    const transactionCoordinator = leadUsers?.find(byAttr('role', 'transaction_coordinator'))

    const calculatedValue = cCBBYSLeadResponse.calculatedValue
    const revenueRecognitionDate = cCBBYSLeadResponse.revenueRecognitionDate
    const leadAttributes = Lead.mapResponse(cCBBYSLeadResponse)
    const siblingLeads = (cCBBYSLeadResponse.childLeads?.map(getLead) as Lead[]) || []

    return new CCBBYSLead({
      ...leadAttributes,
      calculatedValue,
      siblingLeads,
      leadUsers,
      revenueRecognitionDate,
      bbysLead,
      bbysProviderLead,
      newHomeConsultant,
      loanOfficerAssistant,
      loanOfficerAdditionalContacts,
      transactionCoordinator
    })
  }
}
