import { AsideActionBar, AsideActionButton, AsideActionIcon } from 'components'
import { useCurrentUserContext } from 'contexts/CurrentUser'
import { useDialerContext } from 'contexts/Dialer'
import { LoanOfficer } from 'models'

type Props = { loanOfficer: LoanOfficer; onOpenChangeModal?: (isOpen: boolean) => void }

export const ContactActionBar = ({ loanOfficer, onOpenChangeModal }: Props) => {
  const { call, isOnCall } = useDialerContext()
  const { currentUser } = useCurrentUserContext()

  const phoneMobile = loanOfficer.getPhone()
  const isCellButtonDisabled = !phoneMobile || isOnCall

  const handleClickCallMobile = () => {
    if (!currentUser || !phoneMobile?.number) {
      return
    }

    call(loanOfficer.getDialer(currentUser, phoneMobile, 'mobile'))
  }

  const handleMailTo = () => {
    window.open(`mailto:${[loanOfficer.email].filter(Boolean).join('?cc=')}`)
  }

  return (
    <AsideActionBar>
      {currentUser?.phoneTwilio && (
        <>
          <AsideActionButton
            title="Make a call"
            onClick={handleClickCallMobile}
            disabled={isCellButtonDisabled}
          >
            <AsideActionIcon icon="Cellphone" />
            Cell
          </AsideActionButton>
        </>
      )}
      <AsideActionButton title="Send email" onClick={handleMailTo}>
        <AsideActionIcon icon="Envelope" />
        Email
      </AsideActionButton>
    </AsideActionBar>
  )
}
