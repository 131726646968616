// @ts-strict
import { useEffect, useRef } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import {
  Console,
  ConsoleAside,
  ConsoleDetails,
  ConsoleLoading,
  Sticky,
  TabNav,
  Tabs
} from 'components'
import { OrderLeadNavigation } from 'content/Order'
import { useOrderController } from 'controllers'
import { useSettings } from 'hooks'
import { formatOrDash, titleize } from 'utils'
import { LeadSummary } from '../LeadPage/LeadSummary'
import styles from './OrderPage.module.scss'

export const OrderPage = () => {
  const { orderId = '' } = useParams()
  const {
    orderQuery: { data: order, isLoading }
  } = useOrderController(orderId)
  const { addOrderToHistory } = useSettings()
  const addOrderToHistoryRef = useRef(addOrderToHistory)

  addOrderToHistoryRef.current = addOrderToHistory
  const selectedLead = order?.leads?.[0]!

  useEffect(() => {
    if (order) {
      addOrderToHistoryRef.current({
        name: `${order.clientName} (${formatOrDash(order.ownedBusinessUnit, titleize)})`,
        pathname: order.pathname
      })
    }
  }, [order])

  if (isLoading) {
    return <ConsoleLoading type="Lead" />
  }

  return (
    <Console>
      <ConsoleAside className={styles.aside}>
        <Sticky>
          <LeadSummary lead={selectedLead} />
          <OrderLeadNavigation order={order!} selectedLead={selectedLead} />
        </Sticky>
      </ConsoleAside>
      <ConsoleDetails className={styles.details}>
        <Tabs className={styles.tabs} sticky data-testid="order-page-tabs">
          <TabNav to={order?.pathname}>Summary</TabNav>
          <TabNav to={`${order?.pathname}/activity`}>Activity</TabNav>
        </Tabs>
        <Outlet />
      </ConsoleDetails>
    </Console>
  )
}
