import { attachableType, TaskAttachable, TASKS_CACHE_KEY, TASKS_CACHE_KEY_COUNT } from 'controllers'
import { useMutation, useQuery, useQueryClient } from 'hooks'
import { countTasks, fetchTasks, postTasks } from 'services'
import { getPageCount } from 'utils'

const defaultSearchSettings = { perPage: 10 }

type Props = {
  allLeadTasksEnabled?: boolean
  attachable?: TaskAttachable
  enabled: boolean
  params?: Record<string, any>
}

export const useTasksController = ({
  enabled,
  attachable,
  allLeadTasksEnabled,
  params = {}
}: Props) => {
  const queryCache = useQueryClient()

  const searchSettings = { ...defaultSearchSettings, ...params, attachable }

  const { data, isLoading } = useQuery(
    [TASKS_CACHE_KEY, searchSettings],
    controller => fetchTasks(searchSettings, allLeadTasksEnabled, controller.signal),
    {
      enabled,
      refetchOnWindowFocus: false
    }
  )

  const { page: _page, ...countKeys } = params
  const { data: countData, isLoading: countIsLoading } = useQuery(
    [TASKS_CACHE_KEY_COUNT, countKeys],
    controller => countTasks(searchSettings, allLeadTasksEnabled, controller.signal),
    {
      enabled,
      refetchOnWindowFocus: false
    }
  )

  const tasks = data?.tasks
  const totalCount = countData?.tasksCount
  const pageCount = getPageCount(totalCount, searchSettings.perPage)

  const defaultCallback = {
    onSuccess: () => queryCache.invalidateQueries(TASKS_CACHE_KEY)
  }

  const createTask = useMutation(
    (data: object) =>
      postTasks({
        ...data,
        attachableId: attachable?.id,
        attachableType: attachable ? attachableType(attachable) : undefined
      }),
    defaultCallback
  )

  return {
    tasks,
    pageCount,
    totalCount,
    isLoading: isLoading && countIsLoading,
    createTask,
    attachable
  }
}
