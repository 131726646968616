// @ts-strict
import { FormField, Section, TextArea } from 'components'
import { FieldFormField } from '../../FieldFormField'
import { HLSSLeadDispositionPayload, useFields } from '../../useHLSSLeadDisposition'

export const HLSSFailed = ({
  payload,
  setPayload
}: {
  payload: HLSSLeadDispositionPayload
  setPayload: <K extends keyof HLSSLeadDispositionPayload>(
    key: K,
    value: HLSSLeadDispositionPayload[K]
  ) => void
}) => {
  useFields({
    required: ['reasonForFail']
  })

  return (
    <Section>
      <FieldFormField field="reasonForFail" />

      {payload?.reasonForFail === 'other' && (
        <FormField required title="Additional Context">
          <TextArea
            required
            id="note"
            data-testid="note"
            value={payload.note}
            onChange={val => setPayload('note', val)}
          />
        </FormField>
      )}
    </Section>
  )
}
