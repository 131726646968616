import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '@foundation/components'
import {
  AutoComplete,
  Form,
  FormActions,
  FormField,
  PhoneInput,
  Popup,
  Section,
  Sections,
  Select,
  TButtonFormFormProps,
  TextInput
} from 'components'
import { searchLoanOfficers } from 'services/LoanOfficers/searchLoanOfficers'
import {
  getFormErrorMessages,
  TValidation,
  validateEmailFormat,
  validatePhoneFormat,
  validatePresence
} from 'utils'
import { formatPhone, getFormIsValid } from 'utils'
import { TLenderInformationFieldsPayload } from './LenderInformationSection'
import styles from './LenderInformationSection.module.scss'

type Props = {
  formProps: TButtonFormFormProps
  onChangeAttribute: <K extends keyof TLenderInformationFieldsPayload>(
    key: K,
    value: TLenderInformationFieldsPayload[K]
  ) => void
  payload: TLenderInformationFieldsPayload
}

type TValidations = {
  lenderCompanyName?: TValidation | TValidation[]
  lenderEmail?: TValidation | TValidation[]
  lenderName?: TValidation | TValidation[]
  lenderPhone?: TValidation | TValidation[]
  lenderType: TValidation | TValidation[]
}

const StyledFormField = styled(FormField)<{ disabled?: boolean }>`
  ${({ disabled }) => `
    margin-bottom: 16px;

    > label {
      ${disabled && `color: #8e929c !important;`}
    }

    input,
    select {
      height: 36px;
    }
  `}
`

type LoanOfficerAutoCompleteOption = {
  company_name: string
  email: string
  loan_officer_id: string
  name: string
  phone: string
}

export const LenderInformationPopup = ({ payload, formProps, onChangeAttribute }: Props) => {
  const [validations, setValidations] = useState({})

  const mortgageOptionDisplayMap = {
    homelight: 'HomeLight Home Loans',
    outside: 'Outside lender',
    none: 'No mortgage needed',
    unsure: 'Unsure'
  }

  useEffect(() => {
    const newValidations = {
      lenderType: validatePresence
    } as TValidations

    if (payload.lenderType === 'outside') {
      newValidations.lenderName = validatePresence
      newValidations.lenderEmail = [validatePresence, validateEmailFormat]
      newValidations.lenderPhone = [validatePresence, validatePhoneFormat]
      newValidations.lenderCompanyName = validatePresence
    }

    setValidations(newValidations)
  }, [payload])

  const isFormValid = getFormIsValid(payload, validations)
  const errors = getFormErrorMessages(payload, validations)

  const fetchOptions = async (searchTerm: string) => {
    const { loanOfficers } = await searchLoanOfficers(searchTerm)

    return (loanOfficers || []).map((lo: LoanOfficerAutoCompleteOption) => ({
      id: lo.loan_officer_id,
      title: lo.name,
      subTitle: lo.email,
      details: lo
    }))
  }

  const onAutoCompleteSelect = (loanOfficer: { details: LoanOfficerAutoCompleteOption }) => {
    onChangeAttribute('lenderCompanyName', loanOfficer.details.company_name)
    onChangeAttribute('lenderName', loanOfficer.details.name)
    onChangeAttribute('lenderPhone', formatPhone(loanOfficer.details.phone))
    onChangeAttribute('lenderEmail', loanOfficer.details.email)
    onChangeAttribute('loanOfficerId', loanOfficer.details.loan_officer_id)
  }

  return (
    <Popup title="Lender Info" onCancel={() => formProps.toggleShowForm()} className={styles.popup}>
      <div className={styles.buttonFormContents}>
        <Form className={styles.form} onSubmit={formProps.onSubmit}>
          <Sections>
            <Section>
              <StyledFormField title="Mortgage Options">
                <Select
                  data-testid="lenderTypeSelect"
                  options={Object.keys(mortgageOptionDisplayMap).map(key => ({
                    value: key,
                    name: mortgageOptionDisplayMap[key]
                  }))}
                  onChange={val => onChangeAttribute('lenderType', val)}
                  value={payload.lenderType}
                />
              </StyledFormField>
              {payload.lenderType === 'outside' && (
                <>
                  <StyledFormField
                    title="Lender"
                    disabled={payload.lenderType !== 'outside'}
                    error={errors.lenderCompanyName ? 'Company name is required' : null}
                  >
                    <TextInput
                      disabled={payload.lenderType !== 'outside'}
                      value={payload.lenderCompanyName}
                      onChange={(val: string) => onChangeAttribute('lenderCompanyName', val)}
                    />
                  </StyledFormField>
                  <StyledFormField
                    title="Loan Officer phone number"
                    disabled={payload.lenderType !== 'outside'}
                    error={errors.lenderPhone ? 'Phone number is invalid' : null}
                  >
                    <PhoneInput
                      disabled={payload.lenderType !== 'outside'}
                      value={payload.lenderPhone}
                      onChange={(val: string) => onChangeAttribute('lenderPhone', val)}
                    />
                  </StyledFormField>
                </>
              )}
            </Section>
            <Section>
              {payload.lenderType === 'outside' && (
                <>
                  <StyledFormField
                    title="Loan Officer name"
                    disabled={payload.lenderType !== 'outside'}
                    error={errors.lenderName ? 'Lender name is required' : null}
                  >
                    <AutoComplete
                      fetcher={fetchOptions}
                      onSelect={onAutoCompleteSelect}
                      onChange={(val: string) => onChangeAttribute('lenderName', val)}
                      value={payload.lenderName}
                    />
                  </StyledFormField>
                  <StyledFormField
                    title="Loan Officer email address"
                    error={errors.lenderEmail ? 'Email format is invalid' : null}
                    disabled={payload.lenderType !== 'outside'}
                  >
                    <TextInput
                      disabled={payload.lenderType !== 'outside'}
                      value={payload.lenderEmail}
                      onChange={(val: string) => onChangeAttribute('lenderEmail', val)}
                    />
                  </StyledFormField>
                </>
              )}
            </Section>
          </Sections>
          <FormActions
            error={(formProps.showError && formProps.error) || formProps.errorMessage}
            onMouseEnter={formProps.handleHover}
          >
            <Button
              onClick={() => formProps.toggleShowForm()}
              isDisabled={formProps.isLoading}
              variant="ghost"
              color="neutralLight"
              type="button"
            >
              Cancel
            </Button>
            <Button isDisabled={!isFormValid} isLoading={formProps.isLoading}>
              Submit
            </Button>
          </FormActions>
        </Form>
      </div>
    </Popup>
  )
}
