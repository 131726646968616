// @ts-strict
import { Button } from '@foundation/components'
import {
  ConfirmationPopup,
  FormField,
  InlineEditDateInput,
  InlineEditDollarsInput,
  InlineEditFloatUnder100Input,
  InlineEditInventoryInput,
  InlineEditSelect,
  InlineEditUnder100Input,
  List,
  Section,
  Sections
} from 'components'
import { useHLSimpleSaleLeadController } from 'controllers'
import { propertyDnqReasonsOptions, propertyQualifiedOptions, trueFalseOptions } from 'lookups'
import { d, dataOrDash, formatDate } from 'utils'
import { HLSSLeadDetailsProps } from '../HLSSLeadDetails'
import { getFieldValues } from '../utils'

type TProps = Pick<HLSSLeadDetailsProps, 'lead'>

export const DRPropertyQualificationSection = ({ lead }: TProps) => {
  const hlSimpleSaleLead = lead.hlSimpleSaleLead!
  const { runPropertyQualification } = useHLSimpleSaleLeadController(hlSimpleSaleLead!.id)
  const { updateHLSimpleSaleLead } = useHLSimpleSaleLeadController(hlSimpleSaleLead!.id)
  const { getFieldValue } = getFieldValues(hlSimpleSaleLead)

  const address = hlSimpleSaleLead?.departingMasterProperty?.displayAddress
  const qualifiedAt = hlSimpleSaleLead?.departingPropertyQualifiedDate
  const flaggedReasons = hlSimpleSaleLead?.departingPropertyFlags

  const propertyQualificationStatus = () => {
    if (!address) {
      return 'DR Address is missing'
    }

    if (qualifiedAt) {
      return 'Qualification has already been run'
    }

    if (!!flaggedReasons?.length) {
      return 'This property has been flagged'
    }

    return ''
  }

  const runQualificationText = qualifiedAt
    ? `Qualification ran on ${formatDate(qualifiedAt)}`
    : 'Run Qualification'

  const action = (
    <ConfirmationPopup
      buttonComponent={props => (
        <Button
          variant="outline"
          size="xs"
          color="neutralLight"
          onClick={props.onClick}
          isDisabled={props.disabled}
          title={propertyQualificationStatus()}
        >
          {runQualificationText}
        </Button>
      )}
      disabled={propertyQualificationStatus() !== ''}
      title="Run Property Qualification"
      message={`Click 'Submit' to qualify the property at: ${hlSimpleSaleLead?.departingMasterProperty?.displayAddress}`}
      mutation={runPropertyQualification}
      initialPayload={{ id: hlSimpleSaleLead.id }}
    />
  )

  return (
    <Sections title="DR Property Qualification" actions={action}>
      <Section>
        <FormField title="Prop Qualified" inline>
          <InlineEditSelect
            onBlur={val => updateHLSimpleSaleLead.mutate({ departingPropertyQualified: val })}
            value={getFieldValue('departingPropertyQualified')}
            options={propertyQualifiedOptions}
            addBlank
          />
        </FormField>
        <FormField title="Prop Qualified At" inline>
          {formatDate(hlSimpleSaleLead.departingPropertyQualifiedDate)}
        </FormField>
        <FormField title="Target Loan payoff" inline>
          <InlineEditDollarsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ departingPropertyLoanPayoff: val })}
            value={getFieldValue('departingPropertyLoanPayoff')}
          />
        </FormField>
        <FormField title="DNQ Reason" inline>
          <InlineEditSelect
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyQualificationReason: val })
            }
            value={getFieldValue('departingPropertyQualificationReason')}
            options={propertyDnqReasonsOptions}
            addBlank
          />
        </FormField>
        <FormField title="Prop Flagged Reason" inline>
          <List>{flaggedReasons?.map(r => d(r))}</List>
        </FormField>
        <FormField title="Solar Loan Balance" inline>
          <InlineEditDollarsInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertySolarLoanBalance: val })
            }
            value={getFieldValue('departingPropertySolarLoanBalance')}
          />
        </FormField>
        <FormField title="Prelim Title" inline>
          <InlineEditSelect
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyPreliminaryTitleReviewed: val })
            }
            value={getFieldValue('departingPropertyPreliminaryTitleReviewed')}
            options={trueFalseOptions}
            addBlank
          />
        </FormField>
        <FormField title="Currently Listed" inline>
          {dataOrDash(hlSimpleSaleLead?.departingPropertyExternalListingStatus)}
        </FormField>
      </Section>
      <Section>
        <FormField title="Listed On" inline>
          <InlineEditDateInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyExternalListingDate: val })
            }
            value={getFieldValue('departingPropertyExternalListingDate')}
          />
        </FormField>
        <FormField title="Listing Status Updated" inline>
          <InlineEditDateInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyExternalListingStatusDate: val })
            }
            value={getFieldValue('departingPropertyExternalListingStatusDate')}
          />
        </FormField>
        <FormField title="Property Inspected At" inline>
          <InlineEditDateInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ departingPropertyInspectedAt: val })}
            value={getFieldValue('departingPropertyInspectedAt')}
          />
        </FormField>
        <FormField title="Market Days On Market" inline>
          <InlineEditUnder100Input
            onBlur={val => updateHLSimpleSaleLead.mutate({ departingPropertyMarketDom: val })}
            value={getFieldValue('departingPropertyMarketDom')}
          />
        </FormField>
        <FormField title="Market Index" inline>
          <InlineEditFloatUnder100Input
            onBlur={val => updateHLSimpleSaleLead.mutate({ departingPropertyMarketIndex: val })}
            value={getFieldValue('departingPropertyMarketIndex')}
          />
        </FormField>
        <FormField title="Market Inventory" inline>
          <InlineEditInventoryInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyMarketInventoryCount: val })
            }
            value={getFieldValue('departingPropertyMarketInventoryCount')}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
