import { getLeadDispositionUrl } from 'services/getUrl'
import { expandPayload, putRequest, snakeCaseKeys } from 'utils'

export type TPutProviderLeadDisposition = {
  disposition: string
}

export const putProviderLeadDisposition = ({
  leadId,
  id,
  payload
}: {
  id: string
  leadId: string
  payload: {}
}) =>
  putRequest(
    getLeadDispositionUrl({
      id: leadId,
      action: `provider-leads/${id}`
    }),
    snakeCaseKeys(expandPayload(payload))
  )
