import { fetchPresignedLink, putFileToS3 } from 'services'
import type { FetchPresignedLinkProps } from 'services'

export type TUploadToS3 = {
  fileType?: FetchPresignedLinkProps['fileType']
  name: string
  storageKey: string
}

export const uploadToS3 = async (
  attachments: File[],
  fileType?: FetchPresignedLinkProps['fileType']
): Promise<TUploadToS3[]> => {
  return await Promise.all(
    attachments.map(async attachment => {
      const presignResponse = await fetchPresignedLink({ mime: attachment.type, fileType })
      const { url, key } = presignResponse?.data

      if (presignResponse.status !== 200 || !url || !key) {
        return Promise.reject()
      }

      const S3Response = await putFileToS3(attachment, url)

      if (S3Response.status !== 200) {
        return Promise.reject()
      }

      return {
        storageKey: key,
        name: attachment.name,
        fileType
      }
    })
  )
}
