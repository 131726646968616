import { Option } from 'classes'
import { useLookupsController } from 'controllers/Lookups'

export const useActivityFeedDisposition = () => {
  const [activityFeedDispositionData] = useLookupsController('activity_feed_note')

  const activityFeedDispositionOptions = activityFeedDispositionData.map(
    (lookup: TLookupType) => new Option({ name: lookup.name, value: lookup.slug })
  )

  return { activityFeedDispositionOptions }
}
