import { ESCROW_LEADS_CACHE_KEY, useQuery } from 'hooks'
import { EscrowLeadsSearchParams, fetchEscrowLeads } from 'services'

const defaultSearchSettings: EscrowLeadsSearchParams = {
  sortBy: 'Created',
  sortDir: 'desc',
  perPage: 25
}

export const useEscrowLeadsController = (searchParams: EscrowLeadsSearchParams) => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  const escrowLeadsQuery = useQuery([ESCROW_LEADS_CACHE_KEY, searchSettings], () =>
    fetchEscrowLeads(searchSettings)
  )

  return { escrowLeadsQuery }
}
