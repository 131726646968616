import { Text } from '@foundation/components'
import { Flex } from '@foundation/components/Flex/styles'
import { styled } from '@foundation/themes/styles'
import { Button, Form, FormActions, PrimaryButton, TextInput } from 'components'
import { Copy } from '../Icons'
import styles from './MonthlyCap.module.scss'
import { LinkInvestorDetailsFormProps } from './types'

const StyledForm = styled(Form)`
  padding: 2.4rem;
  width: 100%;
  min-width: 80rem;
  max-width: 80rem;

  &.confirmation {
    min-width: 40rem;
    max-width: 60rem;
  }

  @media (max-width: 414px) {
    min-width: 30rem;
  }
`

export const LinkInvestorDetailsForm = ({
  investor,
  onSubmit,
  url
}: LinkInvestorDetailsFormProps) => {
  return (
    <StyledForm onSubmit={() => onSubmit()}>
      <Flex flexDirection="row" paddingBottom="$8">
        <Flex flexDirection="column" width="50%">
          <Text size="base" weight="bold">
            Confirm contact information to send payment link
          </Text>
        </Flex>
      </Flex>

      <Flex flexDirection="row" gap="$8">
        <Flex flexDirection="column" width="50%">
          <Text size="sm">Copy and share the link below</Text>

          <Flex flexDirection="row" className={styles.input_link}>
            <TextInput value={url} disabled={true} />
            <Button as="link" icon={<Copy />} onClick={() => navigator.clipboard.writeText(url)} />
          </Flex>
        </Flex>

        <Flex flexDirection="column" width="50%">
          <Text size="sm">Email address</Text>
          <TextInput className={styles.input} value={investor.email} disabled={true} />
        </Flex>
      </Flex>

      <FormActions>
        <PrimaryButton type="submit">Send</PrimaryButton>
      </FormActions>
    </StyledForm>
  )
}
