import { Section, Sections } from 'components'
import { useFields } from '..'
import { FieldFormField } from '../FieldFormField'

export const CCTradeInListed = () => {
  useFields({
    required: [
      'departingPropertyListingPrice',
      'departingPropertyListedAt',
      'dpActualTotalListingPrepWork'
    ]
  })
  return (
    <Sections title="DR Sale Transaction">
      <Section>
        <FieldFormField field="departingPropertyListingPrice" />
        <FieldFormField field="departingPropertyListedAt" />
      </Section>
      <Section>
        <FieldFormField field="dpActualTotalListingPrepWork" readOnly />
      </Section>
    </Sections>
  )
}
