import { TransactionsView } from 'content/Transactions'
import { useTransactionsController } from 'controllers'
import { Agent } from 'models'

type AgentListingsProps = {
  agent: Agent
}

export const AgentTransactions = ({ agent }: AgentListingsProps) => {
  const { transactions, isLoading } = useTransactionsController({
    searchParams: { agentId: agent.id }
  })
  return <TransactionsView transactions={transactions} isLoading={isLoading} />
}
