// @ts-strict
import { Link } from 'react-router-dom'
import { Block, List, TableActions, TableCell as Td, TableRow as Tr } from 'components'
import { LeadBadge } from 'content/Lead'
import { useListContext } from 'contexts'
import { Lead } from 'models'
import { cx, d, formatDate, formatPhone, formatPrice, structureAddress } from 'utils'
import styles from './LeadsTable.module.scss'

export const LeadsTableRow = ({ lead }: { lead: Lead }) => {
  const { showColumn, actions: Actions, highlightRows } = useListContext()
  const { street, cityState } = structureAddress(lead.fullAddress)

  const rowClassName = highlightRows?.includes(lead.id) && 'highlight'

  return (
    <Tr className={cx(rowClassName)} data-testid={`lead-id-${lead.id}`}>
      <Td>
        <Block
          title={
            <a href={lead.getPathname()} target="_blank" rel="noreferrer">
              {d(lead.name)}
            </a>
          }
          subtitle={d(lead.partnerName)}
          image={<LeadBadge lead={lead} />}
        />
      </Td>
      {showColumn('contactInfo') && (
        <Td>
          <Block
            title={lead.email}
            subtitle={
              <List>
                {lead.emailAlt}
                {lead.phoneNumbers?.map(pn => pn?.formattedNumber)}
                {lead.phoneAlt ? formatPhone(lead.phoneAlt) : null}
              </List>
            }
          />
        </Td>
      )}
      {showColumn('address') && (
        <Td>
          <List>
            {street}
            {cityState}
          </List>
        </Td>
      )}
      {showColumn('price') && <Td>{formatPrice(lead.price, 'short')}</Td>}
      {showColumn('otherLeads') && (
        <Td className={styles.otherLeadsList}>
          {lead.otherLeads?.map(otherLead => (
            <Link to={otherLead.getPathname()} className={styles.otherLeadsLink} key={otherLead.id}>
              <LeadBadge lead={otherLead} />
            </Link>
          ))}
        </Td>
      )}
      {showColumn('stageFurthest') && (
        <Td>
          <Block title={d(lead.stage)} subtitle={d(lead.furthestStage)} />
        </Td>
      )}
      {showColumn('createdUpdated') && (
        <Td>
          <Block title={formatDate(lead.createdAt)} subtitle={formatDate(lead.lastStageUpdate)} />
        </Td>
      )}
      {!!Actions && (
        <TableActions>
          <Actions lead={lead} />
        </TableActions>
      )}
    </Tr>
  )
}
