import { Dialog } from '@foundation/components'
import {
  FilterDialogApplyButton,
  FilterDialogFooter,
  FilterDialogResetButton,
  FilterDialogTriggerButton
} from './FilterDialog'

export { useFilterDialog } from './useFilterDialog'

export const FilterDialog = {
  Root: Dialog.Root,
  Trigger: Dialog.Trigger,
  TriggerButton: FilterDialogTriggerButton,
  Header: Dialog.Header,
  Content: Dialog.Content,
  Body: Dialog.Body,
  Footer: FilterDialogFooter,
  ApplyButton: FilterDialogApplyButton,
  ResetButton: FilterDialogResetButton
}
