import { CCCashOfferLead } from 'models'
import { LeadAttributes } from 'schema'
import { isStringEqual, stringifyValue } from 'utils'
import { CCCashOfferLeadDetailsProps } from '../../CCCashOfferLeadDetails'

export const getLeadInputProps = (
  lead?: CCCashOfferLead,
  updateLead?: CCCashOfferLeadDetailsProps['updateLead']
) => {
  const handleUpdateLead = (key: keyof LeadAttributes, val: string) => {
    if (!lead || !updateLead) {
      return
    }
    if (!isStringEqual(lead[key], val)) {
      updateLead({
        [key]: val
      })
    }
  }

  const getProps = (key: keyof LeadAttributes) => {
    const value = lead ? stringifyValue(lead[key]) : ''

    return {
      value,
      onBlur: val => {
        handleUpdateLead(key, val)
      }
    }
  }

  return {
    getProps
  }
}
