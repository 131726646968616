//@ts-strict
import { Box, Text } from '@foundation/components'
import { Pill } from 'components'
import styles from './LocationPreferences.module.scss'

type ZipCodesSectionProps = {
  active?: boolean
  onZipCodeClick?: (zipCode: string) => void
  sectionName: string
  zipCodes: string[]
}

export const ZipCodesSection = ({
  active,
  onZipCodeClick,
  sectionName,
  zipCodes
}: ZipCodesSectionProps) => {
  const handleOnClick = (zipCode: string) => {
    if (onZipCodeClick) {
      onZipCodeClick(zipCode)
    }
  }

  return (
    <Box className={styles.zipCodeContainer}>
      {zipCodes.length ? (
        zipCodes.map((zipCode: string, i: number) => (
          <Pill
            key={i}
            active={active}
            className={styles.pillContent}
            onClick={() => handleOnClick(zipCode)}
          >
            {zipCode}
          </Pill>
        ))
      ) : (
        <Text size="sm">{`No ${sectionName} zip codes found.`}</Text>
      )}
    </Box>
  )
}
