import { Order } from 'models'
import { getHapiRequestParams, OrdersSchema } from 'schema'
import { getOrdersUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams } from './getSearchParams'

const orderResponseShape: OrdersSchema = {
  orders: [
    'source',
    'stage',
    'ownedBusinessUnit',
    'updatedAt',
    'createdAt',
    {
      leads: [
        'createdAt',
        'fullAddress',
        'furthestStage',
        'lastStageUpdate',
        'name',
        'partnerName',
        'price',
        'source',
        'stage',
        'userType',
        {
          'tradeInProviderLead:providerLead': [
            'stage',
            'introSentAt',
            {
              'providable:tradeInLead': [
                'dpActualPurchaseFundedDate',
                'departingPropertyActualDom',
                'departingPropertyActualSalePrice',
                'departingPropertyAgentValuationReceivedAt',
                'departingPropertyCloseOfPurchaseEscrowAt',
                'departingPropertyCloseOfSaleEscrowAt',
                'departingPropertyGuaranteedPrice',
                'departingPropertyValuation',
                'simpleSale',
                {
                  'departingPropertySellerAgent:agent': [
                    'email1',
                    'email2',
                    'firstName',
                    'lastName',
                    'licenseNumber',
                    {
                      agentTeam: ['name']
                    },
                    {
                      'transactionTeam:team': ['name']
                    },
                    {
                      agentTeamMemberships: [
                        {
                          agentTeam: ['name']
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          'cashOfferProviderLeads:providerLead': [
            'stage',
            'introSentAt',
            'createdAt',
            {
              'providable:cashOfferLead': [
                'incomingPropertyCloseOfPurchaseEscrowAt',
                'incomingPropertyCloseOfSellEscrowAt',
                'incomingPropertyFundsWiredToSellerAt',
                'incomingPropertyValuation',
                {
                  'incomingPropertyBuyerAgent:agent': [
                    'firstName',
                    'lastName',
                    {
                      agentTeam: ['name']
                    },
                    {
                      'transactionTeam:team': ['name']
                    },
                    {
                      agentTeamMemberships: [
                        {
                          agentTeam: ['name']
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          'escrowOfficeProviderLeads:providerLead': [
            {
              'providable:escrowOfficeLead': ['orderIdentifier', 'netEquity']
            }
          ]
        },
        {
          'lenderProviderLeads:providerLead': [
            {
              'providable:lenderLead': [
                'conditionalApprovalDate',
                'closeOfEscrowOn',
                'eaveLoanApplicationId'
              ]
            }
          ]
        },
        {
          'agentProviderLeads:providerLead': [
            'stage',
            'introSentAt',
            {
              servicesOpportunities: [
                'productType',
                { 'status:servicesOpportunityStatus': ['name', 'slug'] }
              ]
            },
            {
              'providable:agentLead': [
                'createdAt',
                'introduced',
                'introSentAt',
                'stage',
                { agent: ['firstName', 'lastName'] }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export const fetchCashCloseOrders = async (settings: {}) => {
  const url = getOrdersUrl({
    searchParams: {
      ...getSearchParams(settings),
      ...getHapiRequestParams(orderResponseShape)
    }
  })
  const response = await getRequest(url)
  const orders = await deserializeAsync(response.data, {
    transform: Order.create
  })
  return {
    orders,
    ordersCount: response.data.meta.total_orders
  }
}
