import styled from 'styled-components'

export const StageTagStyles = styled.div`
  padding: 2px 8px 2px 20px;
  display: inline;
  color: #0c6eba;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  position: relative;
  border: 1px solid #f2f9fe;
  border-radius: 200px;
  background-color: #f2f9fe;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    left: 6px;
    top: calc(50% - 4px);
    border-radius: 50%;
    background-color: #bbd3ea;
    transition: background-color 200ms ease-in-out;
  }

  &:hover {
    border-color: #8bd1ff;
    background-color: #e9f6ff;
  }

  &:hover::before {
    background-color: #8bd1ff;
  }
`
