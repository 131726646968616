// @ts-strict
import { TableHeaderColumn as TH, TableHeaderRow as Tr, useTable } from 'components'
import styles from 'content/Leads/LeadsTable/LeadsTableCells/LeadsTableCells.module.scss'
import { useListContext } from 'contexts'
import { cx } from 'utils'

export const SmartHeaderCells = () => {
  const { findColumn, visibleColumns } = useListContext()
  const { getHeaderCellProps } = useTable()

  return (
    <Tr>
      {visibleColumns.map(visibleColumn => {
        const column = findColumn(visibleColumn)

        if (!column) {
          return null
        }

        return (
          <TH
            className={cx(styles.default, styles[visibleColumn])}
            key={visibleColumn}
            {...getHeaderCellProps(visibleColumn)}
          />
        )
      })}
    </Tr>
  )
}
