import { AttachLeadOrderVariables } from 'contexts/types'
import { LEADS_CACHE_KEY, ORDER_CACHE_KEY, ORDERS_CACHE_KEY, useTasksController } from 'controllers'
import {
  AGENT_LEADS_CACHE_KEY,
  INVESTOR_LEADS_CACHE_KEY,
  useMutation,
  useQuery,
  useQueryClient
} from 'hooks'
import type { CcCashOfferLeadStage, CcTradeInLeadStage, TLeadUserTypeServerSlug } from 'lookups'
import { getCcCashOfferLeadStage, getCcTradeInLeadStage } from 'lookups/lookupLeadStages'
import { usePartners } from 'lookups/lookupPartners'
import {
  CashOfferProviderLead,
  HLSimpleSaleProviderLead,
  isCCCashOfferLead,
  isCCTradeInLead,
  TradeInProviderLead
} from 'models'
import {
  alertError,
  deleteAttachLeadOrder,
  deleteProviderLeadCapitalPartner,
  fetchBuyerLead,
  fetchCCBBYSLead,
  fetchCCCashOfferLead,
  fetchCCHLSimpleSaleLead,
  fetchCCTradeInLead,
  fetchEscrowLead,
  fetchLead,
  fetchMortgageLead,
  fetchSellerLead,
  postAttachLeadOrder,
  postCashOfferLead,
  postEconomicModel,
  postPropertyQualification,
  postPropertyValuation,
  postProviderLeadCapitalPartner,
  postResolvePendingActions,
  putAgentMatchingLeadDisposition,
  putBBYSLead,
  putLead,
  putLeadDisposition,
  putLenderLead,
  putMortgageAffordability,
  putOrder,
  putProviderLeadDisposition,
  putTradeInLead,
  TPostCashOfferLeadVariables
} from 'services'
import {
  fetchInvestorLeads,
  fetchLeadProviderLeads,
  PostResolvePendingActionsParams
} from 'services'
import { isNotNull, presence } from 'utils'

export const useLeadController = (
  leadId: string,
  leadType?: TLeadUserTypeServerSlug,
  enabled = true
) => {
  const fetchAnyLead = () => {
    switch (leadType) {
      case 'buyer':
        return fetchBuyerLead(leadId)
      case 'seller':
        return fetchSellerLead(leadId)
      case 'bbys':
        return fetchCCBBYSLead(leadId)
      case 'cc_trade_in':
        return fetchCCTradeInLead(leadId)
      case 'cc_cash_offer':
        return fetchCCCashOfferLead(leadId)
      case 'mortgage':
        return fetchMortgageLead(leadId)
      case 'escrow':
        return fetchEscrowLead(leadId)
      case 'cc_hl_simple_sale':
        return fetchCCHLSimpleSaleLead(leadId)

      default:
        return fetchLead(leadId)
    }
  }

  const {
    data: lead,
    isLoading,
    refetch
  } = useQuery([LEADS_CACHE_KEY, leadType, leadId], () => fetchAnyLead(), {
    refetchOnWindowFocus: false,
    enabled
  })

  const getLeadNextStages = (stage?: CcTradeInLeadStage | CcCashOfferLeadStage) => {
    const nextStageValues = stage?.nextStageOptions?.map(item => item.value).filter(isNotNull) || []
    const otherStageValues =
      stage?.otherStageOptions?.map(item => item.value).filter(isNotNull) || []

    return [...nextStageValues, ...otherStageValues]
  }

  const getTradeInNextStages = () => {
    const ccTradeInLeadStage = getCcTradeInLeadStage(lead?.stage as any)
    return getLeadNextStages(ccTradeInLeadStage)
  }

  const getCashOfferNextStages = () => {
    const ccCashOfferLeadStage = getCcCashOfferLeadStage(lead?.stage as any)
    return getLeadNextStages(ccCashOfferLeadStage)
  }

  const cashOfferNextStages = isCCCashOfferLead(lead) ? getCashOfferNextStages() : undefined
  const tradeInNextStages = isCCTradeInLead(lead) ? getTradeInNextStages() : undefined

  const { tasks: uncompletedLeadTasks = [] } = useTasksController({
    enabled: !!lead && !!presence(cashOfferNextStages || tradeInNextStages),
    attachable: lead,
    params: {
      // perPage: 9999,
      perPage: 25,
      completionStatus: ['active'],
      // the BE will return the uncompleted tasks related to the next stages
      requiredForLeadStage: cashOfferNextStages || tradeInNextStages
    }
  })

  const queryCache = useQueryClient()

  const clearCaches = () => {
    queryCache.invalidateQueries([LEADS_CACHE_KEY, leadType, leadId])
    queryCache.invalidateQueries([INVESTOR_LEADS_CACHE_KEY, leadId])
    queryCache.invalidateQueries([AGENT_LEADS_CACHE_KEY, leadId])
    queryCache.invalidateQueries(ORDER_CACHE_KEY)
    queryCache.invalidateQueries(ORDERS_CACHE_KEY)
  }

  const defaultCallback = {
    onSuccess: clearCaches
  }

  const updateLead = useMutation(
    (payload: Record<string, string | Record<string, string | Record<string, string>> | number>) =>
      putLead({ leadId, payload }),
    defaultCallback
  )

  const updateLeadNoCacheClear = useMutation(
    (payload: Record<string, string | Record<string, string | Record<string, string>> | number>) =>
      putLead({ leadId, payload })
  )

  const createProviderLeadCapitalPartner = useMutation(
    ({ providerLeadId, capitalPartnerId }: { capitalPartnerId: string; providerLeadId: string }) =>
      postProviderLeadCapitalPartner(providerLeadId, capitalPartnerId),
    defaultCallback
  )

  const removeProviderLeadCapitalPartner = useMutation(
    (id: string) => deleteProviderLeadCapitalPartner(id),
    defaultCallback
  )

  const updateTradeInLead = useMutation(
    ({ id, payload }: { id: string; payload: Record<string, string> }) =>
      putTradeInLead(id, payload),
    defaultCallback
  )

  const updateBBYSLead = useMutation(
    ({ id, payload }: { id: string; payload: Record<string, string> }) => putBBYSLead(id, payload),
    defaultCallback
  )

  const updateLenderLead = useMutation(
    ({ id, payload }: { id: string; payload: Record<string, string> }) =>
      putLenderLead({ leadId: id, payload }),
    defaultCallback
  )

  const updateAgentMatchingLeadDisposition = useMutation(
    (payload: { key: string; notifyClient: boolean }) =>
      putAgentMatchingLeadDisposition({ leadId, payload }),
    defaultCallback
  )

  const updateProviderLeadDisposition = useMutation(putProviderLeadDisposition, defaultCallback)
  const updateLeadDisposition = useMutation(putLeadDisposition, defaultCallback)

  const { mutateAsync: updateOrder } = useMutation(
    (payload: Record<string, string>) => putOrder(lead!.order!.id, payload),
    defaultCallback
  )

  const createCashOffer = useMutation(
    (payload: TPostCashOfferLeadVariables) => postCashOfferLead(payload),
    defaultCallback
  )

  const createEconomicModel = useMutation(
    ({ id }: { id: string }) => postEconomicModel(id),
    defaultCallback
  )

  const runPropertyQualification = useMutation(
    ({ id }: { id: string }) => postPropertyQualification(id),
    defaultCallback
  )

  const runPropertyValuation = useMutation(
    (id: string) => postPropertyValuation(id),
    defaultCallback
  )

  const runMortgageAffordability = useMutation(
    ({ id }: { id: string }) => putMortgageAffordability(id),
    defaultCallback
  )

  const attachLeadToOrder = useMutation(
    (payload: AttachLeadOrderVariables) => postAttachLeadOrder(payload),
    defaultCallback
  )

  const detachLeadFromOrder = useMutation(
    (payload: AttachLeadOrderVariables) => deleteAttachLeadOrder(payload),
    {
      ...defaultCallback,
      onError: () => {
        alertError()
      }
    }
  )

  const updateCapitalPartner = ({
    capitalPartnerId,
    providerLead
  }: {
    capitalPartnerId: string
    providerLead: CashOfferProviderLead | HLSimpleSaleProviderLead | TradeInProviderLead
  }) => {
    const { id, providerLeadCapitalPartner } = providerLead

    if (capitalPartnerId) {
      createProviderLeadCapitalPartner.mutate({
        providerLeadId: id,
        capitalPartnerId
      })
    } else if (providerLeadCapitalPartner?.id) {
      removeProviderLeadCapitalPartner.mutate(providerLeadCapitalPartner.id)
    }
  }

  const setFailedReason = (val: string) => {
    updateLeadDisposition.mutate({
      payload: {
        disposition: 'failed',
        reasonForFail: val
      },
      leadId: lead!.id
    })
  }

  const resolvePendingActions = useMutation(
    ({ ids, actionTaken, note }: PostResolvePendingActionsParams) =>
      postResolvePendingActions({ ids, actionTaken, note }),
    defaultCallback
  )

  const investorLeadsQuery = useQuery(
    [INVESTOR_LEADS_CACHE_KEY, leadId],
    () => fetchInvestorLeads(leadId),
    {
      enabled: !!leadId,
      refetchOnWindowFocus: false
    }
  )

  const agentProviderLeadsQuery = useQuery(
    [AGENT_LEADS_CACHE_KEY, leadId],
    () => fetchLeadProviderLeads(leadId, 'AgentLead'),
    {
      enabled: !!leadId,
      refetchOnWindowFocus: false
    }
  )

  const { partners } = usePartners()

  return {
    partners,
    leadId,
    lead,
    leadType,
    attachLeadToOrder,
    createCashOffer,
    createEconomicModel,
    detachLeadFromOrder,
    isLoading,
    runMortgageAffordability,
    runPropertyQualification,
    runPropertyValuation,
    uncompletedLeadTasks,
    updateAgentMatchingLeadDisposition,
    updateBBYSLead,
    updateCapitalPartner,
    updateLead,
    updateLeadNoCacheClear,
    updateLeadDisposition,
    updateOrder,
    updateProviderLeadDisposition,
    updateTradeInLead,
    updateLenderLead,
    setFailedReason,
    resolvePendingActions,
    investorLeadsQuery,
    agentProviderLeadsQuery,
    refetch
  }
}
