import { UseFetchData } from 'controllers/types'
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'hooks'
import { ActivityFeedInterface } from 'models/v2/ActivityFeed'
import { ActivityFeedSchema } from 'schema'
import { fetchActivitiesFeed, TFetchItems } from 'services'
import { ActivitiesFeedParams } from 'services/ActivitityFeed/getSearchParams'
import { getNextPageParam } from 'utils'
import { activityFeedKeys } from '../../commons/hooks'

type QueryOptions = UseInfiniteQueryOptions<
  TFetchItems<ActivityFeedInterface>,
  unknown,
  TFetchItems<ActivityFeedInterface>,
  TFetchItems<ActivityFeedInterface>,
  ReturnType<typeof activityFeedKeys.globalList>
>

const FETCH_ACTIVITIES_SHAPE: ActivityFeedSchema = {
  activityFeedItems: [
    { agent: ['firstName', 'lastName'] },
    { lead: ['name', 'userType', 'stage'] },
    'occurredAt',
    'meta',
    'title',
    'name'
  ]
}

const defaultSearchSettings: ActivitiesFeedParams = {
  perPage: 25,
  name: [
    'agent_enrollment_update',
    'agent_left_note',
    'closing_docs_sent',
    'employee_left_note',
    'lead_claimed',
    'lead_cloned',
    'lead_reviewed',
    'lead_sales_action',
    'lead_sent_to_partner',
    'lead_stage_update',
    'lead_sub_stage_update',
    'lead_updated',
    'meeting_canceled',
    'meeting_scheduled',
    'signed_agreement',
    'task_status_update',
    'warm_transfer_eligible',
    'inspectify_order_update',
    'closing_signing_update'
  ]
}

export const useInfiniteFetchGlobalActivities = ({
  searchParams,
  options
}: UseFetchData<ActivitiesFeedParams, QueryOptions> = {}) => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  return useInfiniteQuery(
    activityFeedKeys.globalList(searchSettings),
    ctx =>
      fetchActivitiesFeed(FETCH_ACTIVITIES_SHAPE, {
        ...searchSettings,
        page: ctx.pageParam
      }),
    {
      ...options,
      getNextPageParam: getNextPageParam,
      refetchOnWindowFocus: false,
      staleTime: 300000 // 5min
    }
  )
}
