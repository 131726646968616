// @ts-strict
import { Box } from '@foundation/components'
import { Icon, Menu, MenuButton, Section, Sections } from 'components'
import { useCurrentUserContext, useLeadGroupableDefinitionContext } from 'contexts'
import { LeadGroupableDefinition } from 'models'
import styles from './LeadGroupableDefinition.module.scss'
import { LeadGroupableDefinitionStatement } from './LeadGroupableDefinitionStatement'
import { useLeadGroupableDefinitionForm } from './useLeadGroupableDefinitionForm'

type LeadGroupableDefinitionCardProps = {
  leadGroupableDefinition: LeadGroupableDefinition
  registerUpdateDefinition: (updateDefinition: () => void) => void
  unregisterUpdateDefinition: () => void
}

export const LeadGroupableDefinitionCard = ({
  leadGroupableDefinition,
  registerUpdateDefinition,
  unregisterUpdateDefinition
}: LeadGroupableDefinitionCardProps) => {
  const { isLeadCreationAdmin } = useCurrentUserContext()

  const {
    updateLeadGroupableDefinition,
    duplicateLeadGroupableDefinition,
    deleteLeadGroupableDefinition
  } = useLeadGroupableDefinitionContext()

  const { setAttribute, addStatement, removeStatement, payload } = useLeadGroupableDefinitionForm(
    updateLeadGroupableDefinition,
    leadGroupableDefinition,
    registerUpdateDefinition,
    unregisterUpdateDefinition
  )

  return (
    <Box className={styles.cardWrapper}>
      <Box className={styles.cardHeader}>
        <Sections>
          <Section title="Column"></Section>
          <Section title="Action"></Section>
          <Section title="Values"></Section>
        </Sections>
        {isLeadCreationAdmin && (
          <Menu className={styles.menuButton} icon={Icon.Ellipse}>
            <MenuButton onClick={addStatement}>Add Statement</MenuButton>
            <MenuButton onClick={() => duplicateLeadGroupableDefinition.mutateAsync()}>
              Duplicate
            </MenuButton>
            <MenuButton
              onClick={() => {
                unregisterUpdateDefinition()
                deleteLeadGroupableDefinition.mutateAsync()
              }}
            >
              Delete
            </MenuButton>
          </Menu>
        )}
      </Box>
      {payload.definition?.map((definition: any, index: number) => (
        <LeadGroupableDefinitionStatement
          key={index}
          leadGroupableDefinition={definition}
          onChangeAttribute={setAttribute(index)}
          onRemoveStatement={removeStatement(index)}
        />
      ))}
    </Box>
  )
}
