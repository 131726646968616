import { Button } from '@foundation/components'
import { TLoanOfficerResponse } from 'content/LoanOfficer/utils'
import { fetchLoanOfficersAutoComplete } from 'services/LoanOfficers/fetchLoanOfficersAutoComplete/fetchLoanOfficersAutoComplete'
import { cx } from 'utils'
import { AutoComplete, AutoCompleteProps } from '../AutoComplete'
import styles from './AgentsAutoComplete.module.scss'

type Props = {
  autoFocus?: boolean
  className?: string
  onClear: () => void
  onCreateLoanOfficer?: () => void
  onSelect: (LoanOfficer: TLoanOfficerResponse) => void
  searchParams?: object
  value?: string
} & AutoCompleteProps

export const LoanOfficersAutoComplete = ({
  onSelect,
  onClear,
  onBlur,
  onCreateLoanOfficer,
  value,
  searchParams,
  autoFocus,
  className,
  ...props
}: Props) => {
  return (
    <AutoComplete
      fetcher={searchTerm => fetchLoanOfficersAutoComplete({ ...searchParams, searchTerm })}
      onSelect={onSelect as any}
      onClear={onClear}
      onBlur={onBlur}
      value={value}
      className={cx(className, styles.autoComplete)}
      placeholder="Enter loan officer name or email..."
      autoFocus={autoFocus}
      data-testid="loan-officers-autocomplete"
      renderListAction={
        onCreateLoanOfficer &&
        (() => (
          <Button variant="link" size="xs" onClick={onCreateLoanOfficer}>
            Create Loan Officer
          </Button>
        ))
      }
      {...props}
    />
  )
}
