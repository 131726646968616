import { MortgageLead } from 'models'
import { AgentAttributesAndAssociations, getHapiRequestParams, LeadSchema } from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getPageCount, getRequest } from 'utils'
import { TFetchItems } from '../../types'
import {
  getMortgageLeadsSearchParams,
  TMortgageLeadsSearchParams
} from './getMortgageLeadsSearchParams'

const agentFields: AgentAttributesAndAssociations = [
  'firstName',
  'lastName',
  { agentTeam: ['name'] },
  { agentTeamMemberships: [{ agentTeam: ['name'] }] }
]

const leadResponseShape: LeadSchema = {
  lead: [
    'partnerName',
    'createdAt',
    'email',
    'fullAddress',
    'lastStageUpdate',
    'name',
    'stage',
    'userType',
    'propertyType',
    {
      'referringOwner:agent': [
        ...agentFields,
        {
          'transactionTeam:team': ['name']
        }
      ]
    },
    {
      tasks: [
        'title',
        'completedAt',
        'readyAt',
        'requiredForLeadStage',
        'templateSlug',
        'dismissedAt',
        'type',
        {
          'assignee:user': ['name']
        }
      ]
    },
    {
      order: ['source', { leads: ['userType', 'price'] }]
    },
    { leadUsers: ['role', { user: ['name'] }] }
  ]
}

export const fetchMortgageLeads = async (
  settings: TMortgageLeadsSearchParams
): Promise<TFetchItems<MortgageLead>> => {
  const url = getLeadsUrl({
    searchParams: {
      ...getMortgageLeadsSearchParams(settings),
      ...getHapiRequestParams(leadResponseShape)
    }
  })
  const response = await getRequest(url)
  const leads = await deserializeAsync<MortgageLead[]>(response.data, {
    transform: MortgageLead.create
  })

  return {
    items: leads,
    pageCount: getPageCount(response.data.meta.total_leads, settings.perPage),
    totalCount: response.data.meta.total_leads || 0
  }
}
