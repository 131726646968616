// @ts-strict
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Contents, FormField, Section, Sections } from 'components'
import { AgentSection } from 'content/Agent/AgentSection'
import { CCTradeInLeadCallout } from 'content/CCTradeInLead'
import { useOrderController } from 'controllers'
import { useDocumentTitle } from 'hooks'
import type { Agent, Lead } from 'models'
import { dataOrDash, formatDate, formatOrDash, formatPhone, titleize } from 'utils'

type LeadWithReferringAgent = Lead & {
  referringAgent?: Agent
}

export const OrderPageDetailTab = () => {
  const { orderId = '' } = useParams()
  const { setTitle } = useDocumentTitle()
  const {
    orderQuery: { data: order }
  } = useOrderController(orderId)

  const lead = order?.leads?.[0]!
  const ccTradeInLead = order?.ccTradeInLead
  const withReferringAgent: Potential<LeadWithReferringAgent> = order?.leads?.find(
    (lead: LeadWithReferringAgent) => !!lead?.referringAgent
  )

  useEffect(() => {
    if (order) {
      setTitle(`${order.clientName} - ${formatOrDash(order.ownedBusinessUnit, titleize)}`)
    }
  }, [order, setTitle])

  return (
    <Contents title="Summary">
      <Sections title={order?.title}>
        <Section>
          <Section>
            <FormField title="Order Created" inline>
              {formatOrDash(order?.createdAt, formatDate)}
            </FormField>
            <FormField title="Order Source" inline>
              {formatOrDash(order?.source, titleize)}
            </FormField>
          </Section>
          {withReferringAgent?.referringAgent && (
            <AgentSection agent={withReferringAgent?.referringAgent} />
          )}
        </Section>
        <Section>{ccTradeInLead && <CCTradeInLeadCallout lead={ccTradeInLead} />}</Section>
      </Sections>
      <Sections title="Client Information">
        <Section>
          <FormField title="Client Name" inline>
            {dataOrDash(lead?.name)}
          </FormField>
          <FormField title="Client Cell Phone" inline>
            {dataOrDash(lead?.phoneMobile?.formattedNumber)}
          </FormField>
          <FormField title="Client Work Phone" inline>
            {dataOrDash(lead?.phoneOffice?.formattedNumber)}
          </FormField>
          <FormField title="Client Email" inline>
            {dataOrDash(lead?.email)}
          </FormField>
        </Section>
        <Section>
          <FormField title="Partner Name" inline>
            {dataOrDash(lead?.partnerName)}
          </FormField>
          <FormField title="Partner Phone" inline>
            {formatOrDash(lead?.phoneAlt, formatPhone)}
          </FormField>
          <FormField title="Partner Email" inline>
            {dataOrDash(lead?.emailAlt)}
          </FormField>
        </Section>
      </Sections>
    </Contents>
  )
}
