import { SearchParams } from '@types'
import { EnvelopesSchema, getHapiRequestParams } from 'schema'
import { presence, removeAttributesWithNullValues } from 'utils'

const sortParam = {
  SentAt: 'sent_at',
  CompletedAt: 'completed_at',
  VoidedAt: 'voided_at',
  UpdatedAt: 'updatedAt',
  CreatedAt: 'created_at'
}

type SearchParamsSettings = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'sortBy' | 'sortDir'
>

export type EnvelopesSearchParams = SearchParamsSettings

export const getSearchParams = ({ page, perPage, sortBy, sortDir }: EnvelopesSearchParams) => {
  const sort = sortBy
    ? `${sortDir === 'desc' ? '-' : ''}${sortParam[sortBy]}`
    : '-' + sortParam.CreatedAt

  const envelopesListShape: EnvelopesSchema = {
    envelopes: [
      'status',
      'displayStatus',
      'completedAt',
      'createdAt',
      'sentAt',
      'updatedAt',
      'voidedAt',
      'name',
      'templateSlug',
      {
        files: [
          'name',
          'createdAt',
          'updatedAt',
          { fileAttachment: ['createdAt', 'downloadUrl', 'updatedAt'] }
        ]
      },
      {
        recipients: ['name', 'email', 'roleName', 'createdAt', 'updatedAt']
      },
      {
        'createdBy:user': ['email', 'firstName', 'lastName', 'name']
      },
      { 'subAttachable:cashOfferLead': ['incomingMasterProperty'] }
    ]
  }

  return removeAttributesWithNullValues({
    ...getHapiRequestParams(envelopesListShape),
    sort,
    'page[number]': presence(page),
    'page[size]': presence(perPage)
  })
}
