import {
  Block,
  FormField,
  InlineEditEmailInput,
  InlineEditPhoneInput,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { useUserController } from 'hooks'
import { dataOrDash, formatPhone } from 'utils'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import { AddTransactionCoordinatorContact } from './AddTransactionCoordinatorContact'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>

export const TransactionCoordinatorSection = ({ lead, updateBBYSLead, updateLead }: Props) => {
  const { transactionCoordinator } = lead
  const { userUpdate } = useUserController(transactionCoordinator?.user.id)
  const transactionCoordinatorPhone = transactionCoordinator?.user?.phoneNumbers[0]?.number
    ? formatPhone(transactionCoordinator?.user?.phoneNumbers[0]?.number)
    : ''

  const updateUser = (payload: any) => {
    userUpdate.mutateAsync(payload)
  }

  const getSplitName = (value: string) => {
    const splitName = value.split(' ', 2)
    return { first_name: splitName[0], last_name: splitName[1] }
  }

  if (!transactionCoordinator) {
    return (
      <Sections title="Agent's Transaction Coordinator">
        <Section>
          <FormField title="Name" inline>
            <Block
              actions={
                <AddTransactionCoordinatorContact bbysLead={lead?.bbysProviderLead?.bbysLead} />
              }
            ></Block>
          </FormField>
        </Section>
        <Section>
          <></>
        </Section>
      </Sections>
    )
  }

  return (
    <Sections title="Agent's Transaction Coordinator">
      <Section>
        <FormField title="Name" inline>
          <InlineEditTextInput
            onBlur={(value: string) => updateUser(getSplitName(value))}
            value={dataOrDash(transactionCoordinator?.user?.name)}
          />
        </FormField>
      </Section>
      <Section>
        <FormField title="Email Address" inline>
          <InlineEditEmailInput
            onBlur={(value: string) => updateUser({ email: value })}
            value={dataOrDash(transactionCoordinator?.user?.email)}
          />
        </FormField>

        <FormField title="Phone Number" inline>
          <InlineEditPhoneInput
            onBlur={(value: string) => updateUser({ phone_mobile: value })}
            value={dataOrDash(transactionCoordinatorPhone)}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
