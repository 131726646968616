import { FormField, ListSelect } from 'components'
import styles from './AgentFilters.module.scss'

type Props = {
  onChange: (val) => void
  value: string
}

export const AgentsGenderFilter = ({ value, onChange }: Props) => {
  const options = [
    { name: 'M', value: 'male' },
    { name: 'F', value: 'female' }
  ]

  return (
    <FormField title="Gender" className={styles.filter} inline>
      <ListSelect options={options} value={value} onChange={onChange} />
    </FormField>
  )
}
