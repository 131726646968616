import { ButtonForm, EmailInput, FormField, PhoneInput, TextInput, useButtonForm } from 'components'
import { useBBYSLeadController } from 'hooks'
import { BBYSLead } from 'models'
import { validateEmailFormat, validatePresence } from 'utils'

type Props = {
  bbysLead?: BBYSLead
}

export const AddLoanOfficerAssistantContact = ({ bbysLead }: Props) => {
  const { updateBBYSLead } = useBBYSLeadController(bbysLead.id)
  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm({
    mutation: updateBBYSLead,
    initialPayload: {
      loanOfficerAssistantName: '',
      loanOfficerAssistantEmail: '',
      loanOfficerAssistantPhoneNumber: ''
    },
    formValidations: {
      loanOfficerAssistantName: [validatePresence],
      loanOfficerAssistantEmail: [validatePresence, validateEmailFormat]
    }
  })

  return (
    <ButtonForm title="Add Contact" {...formProps}>
      <FormField title="Name" required>
        <TextInput
          onChange={val => onChangeAttribute('loanOfficerAssistantName', val)}
          data-testid="name"
          value={payload.loanOfficerAssistantName}
        />
      </FormField>
      <FormField title="Email Address" required>
        <EmailInput
          onChange={val => onChangeAttribute('loanOfficerAssistantEmail', val)}
          data-testid="email"
          value={payload.loanOfficerAssistantEmail}
        />
      </FormField>
      <FormField title="Phone Number">
        <PhoneInput
          onChange={val => onChangeAttribute('loanOfficerAssistantPhoneNumber', val)}
          data-testid="phoneMobile"
          value={payload.loanOfficerAssistantPhoneNumber}
        />
      </FormField>
    </ButtonForm>
  )
}
