import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMap } from 'react-use'
import { useButtonForm } from 'components'
import { useLeadContext } from 'contexts'
import {
  getCcCashOfferLeadStage,
  TCashOfferProviderLeadStagesSlug,
  TCCCashOfferFailedReason,
  TCcCashOfferLeadStagesSlug
} from 'lookups'
import { CCCashOfferLead } from 'models'
import { asArray, presence, stringifyValue, validateMatch, validatePresence } from 'utils'

export type TCCCashOfferDispositionPayload = {
  disposition: TCcCashOfferLeadStagesSlug | TCashOfferProviderLeadStagesSlug
  note?: string
  occurredAt: string
  reasonForFail?: string
}

export const useCCCashOfferLeadDisposition = (lead: CCCashOfferLead) => {
  const [dispositionPayload, { set: setDispositionPayloadItem, setAll: setDispositionPayload }] =
    useMap<any>()
  const { updateLeadDisposition, uncompletedLeadTasks } = useLeadContext()
  const [requiredFields, setRequiredFields] = useState<string[]>([])
  const [payloadFields, setPayloadFields] = useState<string[]>([])

  useEffect(() => {
    if (lead && presence(payloadFields) && setDispositionPayload) {
      const newPayload = payloadFields.reduce((acc, field) => {
        return {
          ...acc,
          [field]: stringifyValue(lead[field])
        }
      }, {})
      setDispositionPayload(newPayload)
    }
  }, [lead, payloadFields, setDispositionPayload])

  const registerFields = useCallback(
    ({ fields, required }: { fields?: string | string[]; required?: string | string[] }) => {
      setPayloadFields([...asArray(fields).filter(Boolean), ...asArray(required).filter(Boolean)])
      setRequiredFields([...asArray(required).filter(Boolean)])
    },
    []
  )

  const unregisterFields = useCallback(() => {
    setPayloadFields([])
    setRequiredFields([])
  }, [])

  const transformPayload = (payload: TCCCashOfferDispositionPayload, leadId: string) => {
    const resultingPayload = {
      ...payload,
      reasonForFail: payload.disposition === 'failed' ? payload.reasonForFail : undefined
    }

    return {
      leadId,
      payload: resultingPayload
    }
  }

  const currentStage = getCcCashOfferLeadStage(
    lead.stage as TCashOfferProviderLeadStagesSlug | TCcCashOfferLeadStagesSlug
  )
  const furthestStage = getCcCashOfferLeadStage(
    lead.furthestStage as TCashOfferProviderLeadStagesSlug | TCcCashOfferLeadStagesSlug
  )
  const newStage = getCcCashOfferLeadStage('new')

  const initialPayload: TCCCashOfferDispositionPayload = useMemo(
    () => ({
      disposition: (currentStage?.nextStageSlugs?.[0] ||
        lead.stage ||
        '') as TCCCashOfferDispositionPayload['disposition'],
      occurredAt: new Date().toISOString(),
      reasonForFail: lead.reasonForFail
    }),
    [currentStage?.nextStageSlugs, lead]
  )

  const {
    fieldsProps: { payload, onChangeAttribute },
    formProps
  } = useButtonForm<TCCCashOfferDispositionPayload>({
    initialPayload,
    mutation: updateLeadDisposition,
    transformPayload: payload => transformPayload(payload, lead.id),
    formValidations: {
      disposition: validatePresence,
      occurredAt: validatePresence,
      reasonForFail: {
        validations: [validatePresence],
        requirements: { disposition: { validations: [validateMatch('failed')] } }
      }
      // ,
      // additionalContextForFailedReason: {
      //   validations: [validatePresence],
      //   requirements: { reasonForFail: { validations: [validateMatch('other')] } }
      // }
    }
  })

  const isValid = useMemo(() => {
    return (
      !!payload.occurredAt && requiredFields.every(field => presence(dispositionPayload[field]))
    )
  }, [dispositionPayload, payload, requiredFields])

  const onHandleSubmit = useCallback(
    (onSuccessCallback: () => void) => {
      updateLeadDisposition.mutate(
        { leadId: lead.id, payload: { ...dispositionPayload, ...payload } },
        { onSuccess: onSuccessCallback }
      )
    },
    [payload, dispositionPayload, updateLeadDisposition, lead]
  )

  const setFailedReason = (val: TCCCashOfferFailedReason) => {
    updateLeadDisposition.mutate({
      payload: {
        disposition: 'failed',
        reasonForFail: val
      },
      leadId: lead.id
    })
  }

  const selectedStage = getCcCashOfferLeadStage(payload.disposition)

  const uncompletedTasks: string[] = uncompletedLeadTasks
    .filter(task => task.requiredForLeadStage === selectedStage?.slug)
    .map(item => item.title)

  const hasUncompletedTasks = presence(uncompletedTasks)

  const isInStageCannotBeUpdatedMode =
    selectedStage?.slug &&
    !getCcCashOfferLeadStage(
      selectedStage.slug as TCashOfferProviderLeadStagesSlug | TCcCashOfferLeadStagesSlug
    )

  const showSubmitForm = !hasUncompletedTasks && !isInStageCannotBeUpdatedMode

  return {
    currentStage,
    dispositionPayload,
    furthestStage,
    hasUncompletedTasks,
    isInStageCannotBeUpdatedMode,
    isLoading: updateLeadDisposition.isLoading,
    isValid,
    newStage,
    onHandleSubmit,
    payloadFields,
    registerFields,
    requiredFields,
    selectedStage,
    setDispositionPayload,
    setDispositionPayloadItem,
    setFailedReason,
    setPayloadFields,
    uncompletedTasks,
    unregisterFields,
    showSubmitForm,
    payload,
    onChangeAttribute,
    formProps
  }
}
