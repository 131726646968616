// @ts-strict
import { Table, TableResults } from 'components'
import { useMarketplacePrograms } from 'lookups'
import { Agent } from 'models'
import { AgentProgramEligibilityRow } from './AgentProgramEligibilityRow'
import styles from './AgentProgramEligibilityTable.module.scss'
import { AgentProgramEligibilityTableHeader } from './AgentProgramEligibilityTableHeader'

type Props = {
  agent: Agent
}

export const AgentProgramEligibilityTable = ({ agent }: Props) => {
  const { marketplacePrograms, isLoading } = useMarketplacePrograms()

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <Table className={styles.table}>
      <AgentProgramEligibilityTableHeader />
      <TableResults colSpan={3}>
        {marketplacePrograms &&
          marketplacePrograms.map(mp => {
            return <AgentProgramEligibilityRow key={mp.slug} program={mp} agent={agent} />
          })}
      </TableResults>
    </Table>
  )
}
