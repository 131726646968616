import { CCCashOfferLead } from 'models'
import { bySlug, titleize } from 'utils'
import { ccCashOfferLeadStagesOptions } from '../lookupLeadStages'
import { SearchFilter } from './SearchFilter'
import { useSearchFilters } from './useSearchFilters'

export const useCCCashOfferLeadTaskSearchFilters = (lead?: CCCashOfferLead) => {
  const { findFilterBySlug } = useSearchFilters()
  const incomingResidenceOptions = lead ? lead.getCashOfferProviderLeadsOptions() : []

  const ccCashOfferLeadTaskSearchFilters = [
    new SearchFilter({
      slug: 'requiredForStage',
      name: 'Required for Stage',
      options: ccCashOfferLeadStagesOptions
    }),
    new SearchFilter({
      slug: 'incomingResidence',
      name: 'Incoming Residence',
      options: incomingResidenceOptions
    })
  ]

  const buildSearchFilter = (filter: SearchFilter) => {
    const buildDefaultString = (searchFilter: SearchFilter) => (value: string) => {
      return searchFilter.options?.find(el => el.value === value)?.name || titleize(value)
    }

    filter.getDisplayValue = buildDefaultString(filter)
    return filter
  }

  const findCCCashOfferLeadTaskSearchFilterBySlug = (slug: string) => {
    const filter = ccCashOfferLeadTaskSearchFilters.find(bySlug(slug))
    return filter ? buildSearchFilter(filter) : findFilterBySlug(slug)
  }

  return {
    findFilterBySlug: findCCCashOfferLeadTaskSearchFilterBySlug
  }
}
