import { Block } from 'components'
import { isCCCashOfferLead, isCCTradeInLead, Lead } from 'models'
import { formatPrice } from 'utils'

type Props = {
  lead: Lead
}

export const OrdersTableRowLeadGppVal = ({ lead }: Props) => {
  if (isCCTradeInLead(lead)) {
    return (
      <Block
        title={formatPrice(lead.tradeInProviderLead.tradeInLead?.departingPropertyGuaranteedPrice)}
        subtitle={formatPrice(lead.tradeInProviderLead.tradeInLead?.departingPropertyValuation)}
      />
    )
  }
  if (isCCCashOfferLead(lead)) {
    return <Block title={formatPrice(lead.valuation)} />
  }

  return null
}
