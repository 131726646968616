import { LeadViewers } from 'models'
import { PhoneNumber } from 'models/PhoneNumber'
import { LeadSummaryAttributes } from 'schema'
import { formatPhone } from 'utils'

export class LeadSummary {
  name!: string
  email!: string
  phoneNumbers?: PhoneNumber[]
  leadViewers: LeadViewers[]
  formattedPhoneNumber?: string

  constructor(leadSummaryAttributes: LeadSummaryAttributes) {
    Object.assign(this, leadSummaryAttributes)
  }

  static create(leadSummaryResponse: Potential<LeadSummaryAttributes>): Potential<LeadSummary> {
    if (!leadSummaryResponse) {
      return
    }

    const phoneNumber = leadSummaryResponse?.phoneNumbers[0]
    const formattedPhoneNumber: string = phoneNumber?.number ? formatPhone(phoneNumber.number) : ''
    const leadViewers = leadSummaryResponse.leadViewers.map(viewer => LeadViewers.create(viewer))
    return new LeadSummary({ ...leadSummaryResponse, formattedPhoneNumber, leadViewers })
  }
}
