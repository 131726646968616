// @ts-strict

import { Option, OptionGroup } from 'classes'
import { TMortgageLeadStagesSlug, useMortgageLeadStages } from 'lookups'
import { MortgageLead } from 'models'
import { presence } from 'utils'

export const useMortgageLeadStageOptions = (lead: MortgageLead) => {
  const { getMortgageLeadStage } = useMortgageLeadStages()

  const newStage = getMortgageLeadStage('new')
  const currentStage = getMortgageLeadStage(lead.stage as TMortgageLeadStagesSlug) || newStage
  const furthestStage = getMortgageLeadStage(lead.furthestStage as TMortgageLeadStagesSlug)

  let previousStageOptions: Option[] = []

  if (currentStage?.other) {
    if (furthestStage) {
      previousStageOptions = [
        ...(furthestStage.previousStageOptions || []),
        furthestStage.toOption()
      ]
    } else if (newStage) {
      previousStageOptions = [...(newStage.previousStageOptions || []), newStage.toOption()]
    }
  } else {
    previousStageOptions = currentStage?.previousStageOptions || []
  }

  const currentStageOptions = currentStage ? [currentStage.toOption()] : [newStage!.toOption()]
  const nextStageOptions = presence(currentStage?.nextStageOptions) || []
  const otherStageOptions = presence(currentStage?.otherStageOptions) || []

  return [
    presence(previousStageOptions) &&
      new OptionGroup({ label: 'Previous', options: previousStageOptions }),
    presence(currentStageOptions) &&
      new OptionGroup({ label: 'Current', options: currentStageOptions }),
    presence(nextStageOptions) && new OptionGroup({ label: 'Next', options: nextStageOptions }),
    presence(otherStageOptions) && new OptionGroup({ label: 'Other', options: otherStageOptions })
  ].filter((item): item is OptionGroup => !!item)
}
