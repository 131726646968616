import styled from 'styled-components'
import { css } from 'styled-components/macro'

export const Container = styled.div`
  ${({ theme }) => css`
    border: ${theme.borderWidths.thin} solid ${theme.colors.neutral4};
    padding: ${theme.space.$4};
    border-radius: ${theme.radii.base};
  `}
`
