// @ts-strict
import { cx } from 'utils'
import { ButtonV2, ButtonV2Props } from '../ButtonV2'
import styles from './ActionButton.module.scss'

export const ActionButton = ({ children, className, ...props }: ButtonV2Props) => {
  return (
    <ButtonV2 className={cx(styles.actionButton, className)} {...props}>
      {children}
    </ButtonV2>
  )
}
