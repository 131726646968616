import { LeadGroupableType } from 'models'
import { LeadGroupableAttributes } from 'schema'
import { getLeadGroupableUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export const putLeadGroupable = async (
  groupableType: LeadGroupableType,
  groupableId: string,
  payload: Partial<LeadGroupableAttributes>
) => {
  return await putRequest(
    getLeadGroupableUrl({ action: `${groupableType}/${groupableId}` }),
    snakeCaseKeys(payload)
  )
}
