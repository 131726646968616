import { useEffect } from 'react'
import { Inspect } from 'components/Inspect'
import { useAgentContext } from 'contexts/Agent/AgentProvider'
import { useDocumentTitle } from 'hooks'

export const AgentInspectTab = () => {
  const { agent } = useAgentContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${agent.fullName} - Agent Inspect`), [agent, setTitle])
  return <Inspect>{agent}</Inspect>
}
