import { TableHeaderColumn as TH, TableHeaderRow as Tr } from 'components/Table'
import styles from './Agents.module.scss'

export const AgentsTableHeader = () => {
  return (
    <Tr>
      <TH sort={[{ name: 'Name', slug: 'Name' }]} className={styles.name} fixed />
      <TH className={styles.programs} name="Programs" />
      <TH className={styles.pitchStatus} name="Pitch Status" />
      <TH sort={[{ name: 'Opps', slug: 'Opps', flip: true }]} className={styles.stat} />
      <TH
        sort={[
          { name: 'Total', slug: 'Total', flip: true },
          { name: 'Active', slug: 'Active', flip: true }
        ]}
        className={styles.referralCounts}
      />

      <TH sort={[{ name: 'Intro', slug: 'Introduced', flip: true }]} className={styles.stat} />
      <TH sort={[{ name: 'ALVM', slug: 'ALVM', flip: true }]} className={styles.stat} />
      <TH sort={[{ name: 'Conn', slug: 'Connected', flip: true }]} className={styles.stat} />
      <TH sort={[{ name: 'MS/MIP', slug: 'Meetings', flip: true }]} className={styles.stat} />
      <TH sort={[{ name: 'Listed', slug: 'Listings', flip: true }]} className={styles.stat} />
      <TH sort={[{ name: 'Closed', slug: 'Closed', flip: true }]} className={styles.stat} />
      <TH sort={[{ name: 'Urgent', slug: 'Urgent', flip: true }]} className={styles.stat} />
      <TH
        sort={[{ name: 'Last Update', slug: 'Last Update', flip: true }]}
        className={styles.date}
      />
      <TH sort={[{ name: 'Last Touch', slug: 'Last Touch', flip: true }]} className={styles.date} />
      <TH sort={[{ name: 'Last Login', slug: 'Last Login', flip: true }]} className={styles.date} />
    </Tr>
  )
}
