import { CreateExternalLinkVariables } from 'contexts/types'
import { getExternalLinksUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export const postExternalLink = (leadId: string, payload: CreateExternalLinkVariables) => {
  const { name, url, subAttachableId, subAttachableType } = payload
  return postRequest(getExternalLinksUrl(), {
    name,
    url,
    attachable_id: leadId,
    attachable_type: 'Lead',
    sub_attachable_id: subAttachableId,
    sub_attachable_type: subAttachableType
  })
}
