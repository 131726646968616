// @ts-strict
import { Button, ButtonProps } from '@foundation/components'
import { ButtonForm } from 'components'
import { UseMutationResult } from 'hooks'
import { TOrderPostPayload } from 'services'
import { isEmptyValue } from 'utils'
import {
  BbysFormItems,
  ClientDetailsFormItems,
  LeadTypeSelect,
  NotesSection,
  SourceItems,
  TradeInHLSSItems
} from './FormItems'
import { SimilarLeads } from './SimilarLeads'
import { TOrderPayload } from './types'
import { useCreateOrderForm } from './useCreateOrderForm'

type TProps = {
  defaultPayload?: Partial<TOrderPayload>
  isPrimary?: boolean
  mutation: UseMutationResult<string, unknown, TOrderPostPayload, unknown>
  onSubmit: (orderId: string) => Promise<void>
}

export const CreateOrderButtonForm = ({
  onSubmit,
  mutation,
  isPrimary,
  defaultPayload = {}
}: TProps) => {
  const controller = useCreateOrderForm({ mutation, onSubmit, defaultPayload })
  const { formProps, payload, isTradeInHLSS, isBbys, hideBbysForm } = controller

  const isDepartingPropertyValid =
    isTradeInHLSS || isBbys ? !isEmptyValue(payload.departingPropertyFullAddress) : true

  const similarLeadProps = isBbys
    ? {
        similarNames: [payload?.primaryClientName, payload?.additionalClientName],
        similarEmails: [payload?.primaryClientEmail, payload?.additionalClientEmail],
        similarPhoneNumbers: [payload?.primaryClientPhone, payload?.additionalClientPhone]
      }
    : {
        similarNames: [payload?.clientName, payload?.partnerName],
        similarEmails: [payload?.email, payload?.emailAlt],
        similarPhoneNumbers: [payload?.phoneNumber, payload?.phoneAlt]
      }

  const btnStyle = isPrimary
    ? {
        variant: 'solid' as ButtonProps['variant'],
        color: 'primary' as ButtonProps['color']
      }
    : {
        variant: 'outline' as ButtonProps['variant'],
        color: 'neutralLight' as ButtonProps['color']
      }

  return (
    <>
      <Button {...btnStyle} onClick={formProps.toggleShowForm}>
        Create Order
      </Button>
      <ButtonForm
        {...formProps}
        isFormValid={formProps.isFormValid && isDepartingPropertyValid}
        title="Create Order"
        hideTriggerButton
        aside={<SimilarLeads {...similarLeadProps} />}
      >
        <LeadTypeSelect {...controller} />
        <SourceItems {...controller} />

        {!isBbys && <ClientDetailsFormItems {...controller} />}
        {isBbys && <BbysFormItems {...controller} />}

        {(isBbys ? !hideBbysForm : true) && <NotesSection {...controller} />}

        {isTradeInHLSS && <TradeInHLSSItems {...controller} />}
      </ButtonForm>
    </>
  )
}
