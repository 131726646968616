// @ts-strict
import { MarketplaceProgram } from 'models'
import { MarketplaceProgramAgentResponse } from 'schema'
import { MarketplaceProgramAgentEnrollStatus } from './utils'

export class MarketplaceProgramAgent {
  id?: string
  createdAt?: string
  declined?: boolean
  declinedAt?: string
  enrolled?: boolean
  pending?: boolean
  marketplaceProgram?: MarketplaceProgram
  enrollStatus?: MarketplaceProgramAgentEnrollStatus

  constructor(attrs: MarketplaceProgramAgent) {
    Object.assign(this, attrs)
  }

  static create(
    marketplaceProgramAgentResponse: Potential<MarketplaceProgramAgentResponse>
  ): Potential<MarketplaceProgramAgent> {
    if (!marketplaceProgramAgentResponse) {
      return
    }
    const marketplaceProgram = MarketplaceProgram.create(
      marketplaceProgramAgentResponse.marketplaceProgram
    )
    const enrollStatus = marketplaceProgramAgentResponse.enrolled
      ? 'enrolled'
      : marketplaceProgramAgentResponse.pending
      ? 'pending'
      : marketplaceProgramAgentResponse.declined
      ? 'declined'
      : 'unenrolled'
    return new MarketplaceProgramAgent({
      ...marketplaceProgramAgentResponse,
      marketplaceProgram,
      enrollStatus
    })
  }
}
