import {
  FormField,
  InlineEditAddressUnit,
  InlineEditMoneyInput,
  InlineEditSelect,
  Section,
  Sections
} from 'components'
import { useLeadContext } from 'contexts'
import {
  agentMatchingCallReviewBuyingProcess,
  agentMatchingCallReviewPreapproved,
  agentMatchingLeadTimelineOptions,
  yesOrNoForJsonOptions,
  yesOrNoOptions
} from 'lookups'
import { dataOrDash, stringifyValue } from 'utils'

type TProps = {
  isBBYSLeadType?: boolean
  onChangeAttribute: (key: any, value: any) => void
}

export default function LenderDispositionClientScreenSection({
  onChangeAttribute,
  isBBYSLeadType = false
}: TProps) {
  const { lead, updateLeadNoCacheClear } = useLeadContext()

  const updateLeadField = (
    value: Record<string, string | number | Record<string, string | Record<string, string>>>
  ) => {
    updateLeadNoCacheClear.mutateAsync(value)
  }

  const updateCallReview = (key: string, value: string) => {
    onChangeAttribute(key, value)
    updateLeadField({ callReview: { [key]: value } })
  }

  const updateAddress = (value: string) => {
    onChangeAttribute('otherSideInfo_fullAddress', value)
    updateLeadField({ callReview: { othersideInfo: { fullAddress: value } } })
  }

  return (
    <Sections title="Client Screen">
      <Section className="client-screen">
        <FormField title="Buying & Selling?" required={true} inline>
          <InlineEditSelect
            onChange={(val: string) => {
              updateCallReview('interestedInOtherside', val)
            }}
            options={yesOrNoForJsonOptions}
            value={dataOrDash(lead?.callReview?.interestedInOtherside)}
            isError={!lead?.callReview?.interestedInOtherside}
          />
        </FormField>
        <FormField title="Seller Address" required={isBBYSLeadType} inline>
          <InlineEditAddressUnit
            value={dataOrDash(lead?.callReview?.othersideInfo?.fullAddress)}
            onBlur={(val: string) => {
              updateAddress(val)
            }}
            isError={isBBYSLeadType && !lead?.callReview?.othersideInfo?.fullAddress}
          />
        </FormField>
        <FormField title="Primary Residence" required={isBBYSLeadType} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('primaryResidence', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.primaryResidence)}
            isError={
              isBBYSLeadType &&
              (lead.callReview?.primaryResidence === undefined ||
                lead.callReview?.primaryResidence === null)
            }
          />
        </FormField>
        <FormField title="Where in the buying process?" required={true} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('buyerProgress', value)}
            options={agentMatchingCallReviewBuyingProcess}
            value={dataOrDash(lead?.callReview?.buyerProgress)}
            isError={!lead?.callReview?.buyerProgress}
          />
        </FormField>
        <FormField title="Where in the financing process?" required={true} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('preapproved', value)}
            options={agentMatchingCallReviewPreapproved}
            value={dataOrDash(lead?.callReview?.preapproved)}
            isError={!lead?.callReview?.preapproved}
          />
        </FormField>
        <FormField title="Timeline" required={true} inline>
          <InlineEditSelect
            onChange={(val: string) => {
              onChangeAttribute('humanTimeline', val)
              updateLeadField({ timeline: val })
            }}
            options={agentMatchingLeadTimelineOptions}
            value={
              agentMatchingLeadTimelineOptions?.find(opt => opt.name === lead?.humanTimeline)?.value
            }
            isError={!lead?.humanTimeline}
          />
        </FormField>
        <FormField title="Purchase Price" required={true} inline>
          <InlineEditMoneyInput
            onBlur={(value: string) => {
              onChangeAttribute('price', value)
              updateLeadField({ price: value })
            }}
            value={dataOrDash(lead.price)}
            isError={!lead.price}
          />
        </FormField>
        <FormField title="Need Loan to Buy?" required={true} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('interestedInLoan', value)}
            options={yesOrNoOptions}
            value={dataOrDash(lead?.callReview?.interestedInLoan)}
            isError={
              lead?.callReview?.interestedInLoan === undefined ||
              lead?.callReview?.interestedInLoan === null
            }
          />
        </FormField>
      </Section>
    </Sections>
  )
}
