import { getAgentsUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export const putAgentMonthlyCap = async (
  agentId: string,
  monthlyCap: number,
  buyerMonthlyCap: number
) => {
  const url = getAgentsUrl({ id: agentId, action: 'ppl-billing-info' })
  await putRequest(url, snakeCaseKeys({ monthlyCap, buyerMonthlyCap }))
}
