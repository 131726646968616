import { useMutation, useQuery } from 'hooks'
import {
  fetchAgentLeadDetails,
  postResendIntroEmailAgentLead,
  postSendClosingDocsAgentLead
} from 'services/AgentLead'

export const useAgentLeadController = (agentLeadId: string) => {
  const resendIntroEmail = useMutation(() => postResendIntroEmailAgentLead(agentLeadId))
  const sendClosingDocs = useMutation(() => postSendClosingDocsAgentLead(agentLeadId))

  const agentLeadDetailsQuery = useQuery(
    ['agentLeadDetails', agentLeadId],
    () => fetchAgentLeadDetails(agentLeadId),
    { enabled: false, refetchOnWindowFocus: false }
  )

  return {
    agentLeadDetailsQuery,
    resendIntroEmail,
    sendClosingDocs
  }
}
