import { TLeadStageSetting } from '../lookupLeadStages'

export type TAgentMatchingLeadStageSlug =
  | 'intro_pending'
  | 'introduced'
  | 'agent_left_vm'
  | 'client_left_vm'
  | 'connected'
  | 'meeting_scheduled'
  | 'met_in_person'
  | 'coming_soon'
  | 'listing'
  | 'in_escrow'
  | 'closed'
  | 'closed_booked'
  | 'closed_paid'
  | 'failed'

type TAgentMatchingLeadStageSetting = TLeadStageSetting<TAgentMatchingLeadStageSlug>
export const agentLeadStageSettings: TAgentMatchingLeadStageSetting[] = [
  {
    name: 'Intro Pending',
    slug: 'intro_pending'
  },
  {
    name: 'Introduced',
    slug: 'introduced'
  },
  {
    name: 'Agent Left VM',
    slug: 'agent_left_vm'
  },
  {
    name: 'Client Left VM',
    slug: 'client_left_vm'
  },
  {
    name: 'Connected',
    slug: 'connected'
  },
  {
    name: 'Meeting Scheduled',
    slug: 'meeting_scheduled'
  },
  {
    name: 'Met In Person',
    slug: 'met_in_person'
  },
  {
    name: 'Coming Soon',
    slug: 'coming_soon'
  },
  {
    name: 'Listing',
    slug: 'listing'
  },
  {
    name: 'In Escrow',
    slug: 'in_escrow'
  },
  {
    name: 'Closed',
    slug: 'closed'
  },
  {
    name: 'Closed Booked',
    slug: 'closed_booked'
  },
  {
    name: 'Closed Paid',
    slug: 'closed_paid'
  },
  {
    name: 'Failed',
    slug: 'failed'
  }
]
