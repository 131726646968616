import { useEffectOnce } from 'react-use'
import { ParamsProvider } from 'contexts'
import { InfiniteActivityList } from 'features/activities/commons'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import { useInfiniteFetchGlobalActivities } from '../hooks'

const Content = () => {
  const { tracking } = useCustomEvents()
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteFetchGlobalActivities()
  const isLoading = isFetching || isFetchingNextPage

  useEffectOnce(() => {
    tracking({
      name: 'drawer_view',
      params: { trigger: 'click', location: 'global', target: 'activity' }
    })
  })

  return (
    <InfiniteActivityList
      isLoading={isLoading}
      data={data}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
    />
  )
}

export const ListGlobalActivities = () => {
  return (
    <ParamsProvider key="global">
      <Content />
    </ParamsProvider>
  )
}
