import { FloatingOverlay } from '@floating-ui/react'
import { css, styled } from '@foundation/themes/styles'

export const Overlay = styled(FloatingOverlay)`
  ${({ theme }) => css`
    display: flex;
    align-items: start;
    justify-content: center;
    width: 100vw;
    z-index: ${theme.zIndices.overlay};
    background: rgba(0, 0, 0, 0.4);
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    max-height: 90vh;
    max-width: 94vw;
    margin-top: ${theme.space.$8};
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadows.lg};
    z-index: ${theme.zIndices.modal};
    min-width: 51.2rem;
    outline: none;
    border-radius: ${theme.radii.xl};
    /* to keep the border-radius when overflow-y scroll is being shown */
    mask-image: radial-gradient(${theme.colors.white}, ${theme.colors.black});
    /* prettier-ignore */
    @media(max-width: 414px) {
      min-width: 30rem;
    }
  `}
`

export const InnerContent = styled.div`
  max-height: 90vh;
  max-width: 94vw;
  overflow-y: auto;
`

export const Toolbar = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    padding: ${theme.space.$4} ${theme.space.$3};
    padding-bottom: ${theme.space.$2};
  `}
`

export const Body = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.$7};
    padding-top: ${theme.space.$6};
  `}
`

export const Footer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.$4} ${theme.space.$5};
    border-top: 1px solid ${theme.colors.neutral5};
  `}
`
