// @ts-strict
import { ReactNode } from 'react'
import { Contents, PaginationParams, SearchConsole } from 'components'
import { AgentMetricsProvider, useAgentsContext } from 'contexts'
import { useFeatureFlags } from 'hooks'
import { agentsListColumns, useAgentsSearchFilter } from 'lookups'
import { Agent } from 'models'
import { columnMapping } from '../AgentsTable/AgentsTableRow'
import { SmartTable } from '../SmartTable'
import { AgentsListStatus, AgentsTableLegacy } from '.'

export const AgentsDatatableList = ({
  filters,
  search,
  actions,
  className = '',
  rowActions
}: {
  actions?: ReactNode
  className?: string
  filters?: ReactNode
  rowActions?: ReactNode
  search?: ReactNode
}) => {
  const { pageCount, agents, isLoading } = useAgentsContext()
  const { salesAppAgentMetrics } = useFeatureFlags(['sales-app-agent-metrics'])
  const agentsSearchFilter = useAgentsSearchFilter()

  return (
    <AgentMetricsProvider>
      <Contents title="Agents" actions={actions}>
        <AgentsListStatus />
        <SearchConsole className={className} actions={filters} search={search} />
        {salesAppAgentMetrics.enabled ? (
          <SmartTable<Agent>
            items={agents}
            isLoading={isLoading}
            columnMapping={columnMapping}
            filters={agentsSearchFilter}
            columns={agentsListColumns}
          />
        ) : (
          <AgentsTableLegacy agents={agents} isLoading={isLoading} rowActions={rowActions} />
        )}
        <PaginationParams totalPages={pageCount} />
      </Contents>
    </AgentMetricsProvider>
  )
}
