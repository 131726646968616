// @ts-strict
import { TeamMembership, User } from 'models'
import { SubTeamMembershipResponse } from 'schema/SubTeamMembershipSchema'
import { Model } from '../Model'

export class SubTeamMembership extends Model {
  createdAt?: string
  id!: string
  role!: string
  teamMembership?: TeamMembership
  user!: User

  constructor(attributes?: Omit<SubTeamMembership, keyof Model>) {
    super()
    Object.assign(this, attributes)
  }

  static create(
    subTeamMembershipAttributes: Potential<SubTeamMembershipResponse>
  ): Potential<SubTeamMembership> {
    if (!subTeamMembershipAttributes) {
      return
    }
    const user = User.create(subTeamMembershipAttributes.user)!
    const teamMembership = TeamMembership.create(subTeamMembershipAttributes.teamMembership)
    return new SubTeamMembership({ ...subTeamMembershipAttributes, user, teamMembership })
  }
}
