import { useMutation, useQuery, useQueryClient } from 'hooks'
import {
  deleteAgentTeamMember,
  fetchAgentTeam,
  postAgentTeam,
  postAgentTeamMember,
  postAgentTeamPreferredEscrowOfficer,
  postToggleLeadRouting,
  putAgentTeam,
  putAgentTeamMemberReferralPreferences
} from 'services'
import { postToggleLeadBoostUi } from 'services/AgentTeam/postToggleLeadBoostUi'

export const useAgentTeamController = (agentId: string, agentTeamId?: string) => {
  const agentTeamQuery = useQuery(['agent_team', agentId], () => fetchAgentTeam(agentId), {
    refetchOnWindowFocus: false
  })

  const queryCache = useQueryClient()

  const defaultCallback = {
    onSuccess: () => {
      queryCache.invalidateQueries(['agent_team', agentId])
      queryCache.invalidateQueries(['agents', agentId])
    }
  }

  const editAgentTeam = useMutation(putAgentTeam, defaultCallback)

  const addTeamMember = useMutation(
    ({ userId }: { userId: string }) => postAgentTeamMember({ agentId, userId }),
    defaultCallback
  )

  const createAgentTeam = useMutation(
    (name: string) => postAgentTeam({ agentId, name }),
    defaultCallback
  )

  const removeTeamMember = useMutation(deleteAgentTeamMember, defaultCallback)
  const updateReferralPreferences = useMutation(
    ({
      id,
      active,
      isFallbackAgent,
      leadRoutingWarmTransferOptIn,
      prefersBuyer,
      prefersSeller
    }: {
      active?: boolean
      id: string
      isFallbackAgent?: boolean
      leadRoutingWarmTransferOptIn?: boolean
      prefersBuyer?: boolean
      prefersSeller?: boolean
    }) =>
      putAgentTeamMemberReferralPreferences(id, {
        active,
        isFallbackAgent,
        leadRoutingWarmTransferOptIn,
        prefersBuyer,
        prefersSeller
      }),
    defaultCallback
  )

  const changeAgentTeamPreferredEscrowOfficer = useMutation(
    ({
      agentId,
      preferredEscrowOfficerUserId
    }: {
      agentId: string
      preferredEscrowOfficerUserId: string
    }) => postAgentTeamPreferredEscrowOfficer({ agentId, preferredEscrowOfficerUserId }),
    defaultCallback
  )

  const toggleLeadBoostUi = useMutation(() => postToggleLeadBoostUi(agentId), {
    onSuccess: () => {
      setTimeout(() => {
        queryCache.invalidateQueries(['current-user'])
        queryCache.invalidateQueries(['agents', agentId])
      }, 2000)
    }
  })

  const toggleLeadRouting = useMutation(() => postToggleLeadRouting(agentTeamId), {
    onSuccess: () => {
      setTimeout(() => {
        queryCache.invalidateQueries(['current-user'])
        queryCache.invalidateQueries(['agent_team', agentId])
        queryCache.invalidateQueries(['agents', agentId])
      }, 2000)
    }
  })

  return {
    agentTeamQuery,
    createAgentTeam,
    editAgentTeam,
    addTeamMember,
    removeTeamMember,
    changeAgentTeamPreferredEscrowOfficer,
    toggleLeadBoostUi,
    toggleLeadRouting,
    updateReferralPreferences
  }
}
