import { FormEventHandler } from 'react'
import { useToastContext } from '@foundation/components'
import { Button } from 'components'
import { useFeatureFlags, useMutation } from 'hooks'
import { CCBBYSLead } from 'models'
import { getBBYSRequestIRContractUrl } from 'services/getUrl'
import { putRequest } from 'utils'
import styles from './RequestIRContract.module.scss'

type RequestIRContractProps = {
  lead: CCBBYSLead
}

export const RequestIRContract = ({ lead }: RequestIRContractProps) => {
  const { salesAppRequestIrContractBtn } = useFeatureFlags(['sales-app-request-ir-contract-btn'])

  const toast = useToastContext()

  const { mutate } = useMutation(
    () =>
      putRequest(getBBYSRequestIRContractUrl(), {
        id: lead.id
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Email / Task sent to Loan Officer!',
          status: 'success'
        })
      },
      onError: () => {
        toast({
          title: 'Ops! Something went wrong!',
          status: 'error'
        })
      }
    }
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    mutate()
  }

  return (
    <form onSubmit={onSubmit}>
      <Button
        type="submit"
        as="primary"
        className={styles.requestIRContractBtn}
        disabled={!salesAppRequestIrContractBtn.enabled}
      >
        Request IR Contract
      </Button>
    </form>
  )
}
