import { ReactNode, useEffect, useRef } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import {
  Bubble,
  Console,
  ConsoleAside,
  ConsoleDetails,
  ConsoleLoading,
  Dev,
  Sticky,
  TabNav,
  Tabs
} from 'components'
import { AgentAside } from 'content/Agent'
import {
  AgentProvider,
  QueryStringParamsProvider,
  useAgentContext,
  useCurrentUserContext,
  useSavedSearchesContext
} from 'contexts'
import { useAttachableTaskCountsController } from 'controllers'
import { useSettings } from 'hooks'
import { Agent } from 'models'
import styles from './AgentPage.module.scss'

const AgentPageContent = ({ agent, children }: { agent: Agent; children: ReactNode }) => {
  const { activeTasksCount, pastDueTasksCount } = useAttachableTaskCountsController({
    attachable: agent
  })
  const { getLastSearchUrl } = useSavedSearchesContext()
  const { isPplAdmin } = useCurrentUserContext()

  return (
    <Console>
      <ConsoleAside>
        <Sticky>
          <AgentAside agent={agent} className={styles.aside} />
        </Sticky>
      </ConsoleAside>
      <ConsoleDetails className={styles.details}>
        <Tabs data-testid="agentTabs" className={styles.tabs} sticky>
          <TabNav to={`/agents/${agent.id}`}>Details</TabNav>
          <TabNav to={getLastSearchUrl(`/agents/${agent.id}/referrals`)}>Referrals</TabNav>
          {isPplAdmin && <TabNav to={`/agents/${agent.id}/billing`}>Billing</TabNav>}
          <TabNav to={getLastSearchUrl(`/agents/${agent.id}/listings`)}>MLS Listings</TabNav>
          <TabNav to={`/agents/${agent.id}/transactions`}>Transactions</TabNav>
          <TabNav to={getLastSearchUrl(`/agents/${agent.id}/tasks`, '?completionStatus=[ready]')}>
            Tasks
            <Bubble count={activeTasksCount} className={styles.bubble} />
            <Bubble count={pastDueTasksCount} className={styles.bubble} danger />
          </TabNav>
          <TabNav to={`/agents/${agent.id}/messages`}>Messages</TabNav>
          <TabNav to={`/agents/${agent.id}/activity`}>Activity</TabNav>
          <TabNav to={`/agents/${agent.id}/team`}>Agent Team</TabNav>
          <TabNav to={`/agents/${agent.id}/agreements`}>Agreements</TabNav>
          <Dev>
            <TabNav to={`/agents/${agent.id}/inspect`}>Inspect</TabNav>
          </Dev>
        </Tabs>
        {children}
      </ConsoleDetails>
    </Console>
  )
}

const AgentPageContext = ({ children }) => {
  const { agent, isLoading, isError } = useAgentContext()
  const { addAgentToHistory } = useSettings()
  const addAgentToHistoryRef = useRef(addAgentToHistory)

  addAgentToHistoryRef.current = addAgentToHistory

  useEffect(() => {
    if (agent) {
      addAgentToHistoryRef.current(agent.getLink())
    }
  }, [agent])

  if (isLoading) {
    return <ConsoleLoading type="Agent" />
  }
  if (isError) {
    console.log('Error fetching agent')
  }
  if (!agent) {
    return null
  }
  return <AgentPageContent agent={agent}>{children}</AgentPageContent>
}

export const AgentPage = () => {
  const { agentId } = useParams()
  return (
    <AgentProvider agentId={agentId}>
      <QueryStringParamsProvider initialParams={{ completionStatus: ['ready'] }}>
        <AgentPageContext>
          <Outlet />
        </AgentPageContext>
      </QueryStringParamsProvider>
    </AgentProvider>
  )
}
