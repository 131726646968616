// @ts-strict
import { ButtonForm, useButtonForm } from 'components'
import { EditIconButton } from 'components/Button/IconButton'
import { useUserIntegrationController } from 'hooks'
import { UserIntegration } from 'models'
import { TUserIntegrationPayload } from 'services'
import { validatePresence } from 'utils'
import { UserIntegrationFields } from '.'

type TProps = {
  userIntegration: UserIntegration
}

export const UserIntegrationEditButtonForm = ({ userIntegration }: TProps) => {
  const { updateUserIntegration } = useUserIntegrationController(userIntegration.id!)

  const initialPayload = {
    name: userIntegration.name as TUserIntegrationPayload['name'],
    value: userIntegration.value!
  }

  const formValidations = {
    name: validatePresence,
    value: validatePresence
  }

  const { formProps, fieldsProps } = useButtonForm<TUserIntegrationPayload>({
    initialPayload,
    mutation: updateUserIntegration,
    formValidations
  })

  return (
    <ButtonForm title="Edit User Integration" buttonComponent={EditIconButton} {...formProps}>
      <UserIntegrationFields nameDisabled fieldsProps={fieldsProps} />
    </ButtonForm>
  )
}
