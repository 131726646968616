import { Block } from 'components'
import { Section } from 'components/Section'
import { Investor } from 'models'
import { InvestorAvatar } from '../InvestorAvatar'
import { InvestorContact } from '../InvestorContact'

type Props = {
  className?: string
  investor: Investor
}

export const InvestorAside = ({ investor, className }: Props) => {
  const subtitle = 'Investor'

  return (
    <Section>
      <Block
        title={investor.name}
        subtitle={subtitle}
        image={<InvestorAvatar investor={investor} />}
        className={className}
      />

      <div className={className}>
        <InvestorContact investor={investor} />
      </div>
    </Section>
  )
}
