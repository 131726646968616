import React from 'react'
import { SearchInput } from 'components/SearchInput'
import { useParamsContext } from 'contexts/Params'

export const TaskListSearch = () => {
  const { params, updateParams } = useParamsContext()
  const { searchTerm } = params

  const updateSearchTerm = term => {
    const newSearchTerm = term && term.length ? term : undefined
    updateParams({ searchTerm: newSearchTerm, page: 1 })
  }

  return (
    <SearchInput
      value={searchTerm}
      onChange={updateSearchTerm}
      placeholder="Search by Task Title"
    />
  )
}
