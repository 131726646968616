// @ts-strict

import { ButtonForm, CreateButton, useButtonForm } from 'components'
import { useTeamsContext } from 'contexts'
import { TeamFormFields } from './TeamFormFields'
import { formValidations, TTeamFormPayload } from './utils'

export const CreateTeamButtonForm = () => {
  const { createTeam, type } = useTeamsContext()
  const initialPayload = {
    description: '',
    enabled: false,
    type,
    name: ''
  }

  const { formProps, fieldsProps } = useButtonForm<TTeamFormPayload>({
    initialPayload,
    mutation: createTeam,
    formValidations
  })

  return (
    <ButtonForm
      buttonText="Create Team"
      title="Create Team"
      buttonComponent={CreateButton}
      {...formProps}
    >
      <TeamFormFields {...fieldsProps} />
    </ButtonForm>
  )
}
