export const removeOutliers = (numbers: number[]) => {
  if (numbers.length < 5) {
    return numbers
  }
  const values = [...numbers].sort((a, b) => a - b)

  const q1 = values[Math.floor(values.length / 4)]
  const q3 = values[Math.ceil(values.length * (3 / 4))]
  const iqr = q3 - q1

  const maxValue = q3 + iqr * 1.5
  const minValue = q1 - iqr * 1.5

  return values.filter(x => x <= maxValue && x >= minValue)
}
