// @ts-strict
import { useEffect } from 'react'
import type { UseMutationResult } from 'react-query'
import { useToastContext } from '@foundation/components'
import { useButtonForm } from 'components'
import type { Agent } from 'models'
import { validatePresence } from 'utils'

type PutAgentPayload = any

type AgentVacationSettingButtonFormPayload = PutAgentPayload

export const useAgentVacationSettingButtonForm = (
  mutation: UseMutationResult<any, unknown, PutAgentPayload, unknown>,
  agent: Agent
) => {
  const transformPayload = (
    payload: AgentVacationSettingButtonFormPayload
  ): AgentVacationSettingButtonFormPayload => {
    return {
      onVacationUntil: payload.onVacationActive ? payload.onVacationUntil : '',
      scheduledUnavailabilityUntil: payload.onVacationActive ? payload.onVacationUntil : '',
      scheduledUnavailabilityFrom: payload.onVacationActive ? new Date() : ''
    }
  }

  const toast = useToastContext()
  const onSubmitSuccess = () => {
    toast({
      title: 'Vacation Settings Updated',
      status: 'success'
    })
  }

  const formValidations = {
    onVacationUntil: validatePresence
  }

  const { fieldsProps, formProps, setters } = useButtonForm<AgentVacationSettingButtonFormPayload>({
    initialPayload: {
      onVacationActive: !!agent.referralPreferences?.onVacationUntil,
      onVacationUntil: agent.referralPreferences?.onVacationUntil || ''
    },
    callback: onSubmitSuccess,
    mutation,
    transformPayload,
    formValidations
  })

  // This conditionally controls if we need to validate the presence of the input date
  // If the checkbox is unchecked, we check agent is currently on vacation so the request can go through
  useEffect(() => {
    if (fieldsProps.payload.onVacationActive) {
      setters.setValidations({ onVacationUntil: validatePresence })
    } else {
      if (agent.referralPreferences?.onVacationUntil) {
        setters.setValidations({})
      } else {
        setters.setValidations({ onVacationActive: validatePresence })
      }
    }
    // eslint-disable-next-line
  }, [fieldsProps.payload.onVacationActive])

  return { fieldsProps, formProps, setters }
}
