// @ts-strict
import { Button } from '@foundation/components'
import { ButtonForm, DateInput, FormField, ListStringValues, Section, Select } from 'components'
import { LeadUncompletedTasks, UpdateStage } from 'content/Lead'
import { useCcCashOfferLeadFields } from 'lookups'
import { CCCashOfferLead } from 'models'
import { useCCCashOfferLeadDispositionContext } from '..'

type TProps = {
  lead: CCCashOfferLead
  onCancel: () => void
}

export const CCCashOfferLeadDispositionButtonForm = ({ lead }: TProps) => {
  const {
    currentStage,
    furthestStage,
    hasUncompletedTasks,
    isInStageCannotBeUpdatedMode,
    newStage,
    selectedStage,
    uncompletedTasks,
    showSubmitForm,
    payload,
    onChangeAttribute,
    formProps
  } = useCCCashOfferLeadDispositionContext()
  const { getCcCashOfferLeadField } = useCcCashOfferLeadFields()

  const { disposition } = payload
  const failedReasons = getCcCashOfferLeadField('reasonForFail')
  const showFailedReason = disposition === 'failed'

  const renderDispositionButton = () => {
    return (
      <Button onClick={formProps.toggleShowForm} size="xs">
        Disposition
      </Button>
    )
  }

  return (
    <>
      {renderDispositionButton()}
      <ButtonForm hideTriggerButton title="Disposition" {...formProps}>
        <UpdateStage
          payload={payload}
          setPayloadItem={onChangeAttribute}
          currentStage={currentStage}
          furthestStage={furthestStage}
          newStage={newStage}
          hideOther={lead.hasActiveCashOfferLead}
        />

        {showSubmitForm ? (
          <>
            {showFailedReason && (
              <Section>
                <FormField required title={failedReasons?.name}>
                  <Select
                    addBlank
                    id="reasonForFail"
                    data-testid="reasonForFail"
                    value={payload.reasonForFail}
                    onChange={val => onChangeAttribute('reasonForFail', val)}
                    options={failedReasons?.options}
                  />
                </FormField>

                {/* {payload.reasonForFail === 'other' && (
                  <FormField required title="Additional Context">
                    <TextArea
                      required
                      id="note"
                      data-testid="note"
                      value={payload.note}
                      onChange={val => onChangeAttribute('note', val)}
                    />
                  </FormField>
                )} */}
              </Section>
            )}

            <Section>
              <FormField title="Occurred On" required>
                <DateInput
                  value={payload.occurredAt}
                  onChange={val => onChangeAttribute('occurredAt', val)}
                />
              </FormField>
            </Section>
          </>
        ) : (
          <>
            {isInStageCannotBeUpdatedMode && (
              <ListStringValues
                title="Stage Cannot be Updated"
                items={['All Provider Leads are in a terminal stage']}
              />
            )}
            {hasUncompletedTasks && (
              <Section>
                <LeadUncompletedTasks
                  selectedStage={selectedStage?.slug!}
                  items={uncompletedTasks}
                />
              </Section>
            )}
          </>
        )}
      </ButtonForm>
    </>
  )
}
