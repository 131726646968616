import { environment } from 'environment'
import { getExchangeTokenUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export type ExchangeTokensPayload = {
  code: string
  codeVerifier: string
}

export const postExchangeTokens = async (payload: ExchangeTokensPayload) => {
  return postRequest(
    getExchangeTokenUrl(),
    snakeCaseKeys({
      ...payload,
      grantType: 'authorization_code',
      redirectUri: `${window.location.origin}/auth/redirect`,
      clientId: environment.authClientId
    })
  )
}
