import { Link } from 'react-router-dom'
import { TableActions, TableCell, TableRow } from 'components'
import { DeleteIconButton } from 'components/Button/IconButton'
import { Table, TableBody } from 'components/Table'
import { useTeamContext } from 'contexts'
import { SettlementAgencySubTeamMembersList } from 'features/settlementAgency/listSettlementAgencySubTeamMembers'
import { SETTLEMENT_AGENCY_TEAM_ROLE_CACHE_KEY, useLookupsController } from 'hooks'
import { TeamMembership } from 'models'
import { SettlementAgencyTeamMembersListHeader } from './SettlementAgencyTeamMembersListHeader'

type TProps = {
  members?: TeamMembership[]
}

export const SettlementAgencyTeamMembersList = ({ members = [] }: TProps) => {
  const { removeTeamMember } = useTeamContext()

  const [, findTeamRole] = useLookupsController(SETTLEMENT_AGENCY_TEAM_ROLE_CACHE_KEY)

  const handleRemoveTeamMember = (id: string) => {
    removeTeamMember.mutate(id)
  }

  return (
    <Table>
      <SettlementAgencyTeamMembersListHeader />
      <TableBody>
        {members.map(member => {
          const role = findTeamRole(member.role)
          return (
            <>
              <TableRow key={member.id} data-member-id={member.id} className="member">
                <TableCell>Team Member</TableCell>
                <TableCell>
                  <Link to={`/admin/employees/${member.user.id}`}>{member.user.name}</Link>
                </TableCell>
                <TableCell>
                  <div key={member.id}>
                    {role?.description} {role?.name ? `(${role?.name})` : <em>deprecated role</em>}
                  </div>
                </TableCell>
                <TableCell>---</TableCell>
                <TableActions>
                  <DeleteIconButton onClick={() => handleRemoveTeamMember(member.id)} />
                </TableActions>
              </TableRow>
              <SettlementAgencySubTeamMembersList
                teamMember={member}
              ></SettlementAgencySubTeamMembersList>
            </>
          )
        })}
      </TableBody>
    </Table>
  )
}
