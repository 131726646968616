import { EmployeesSchema, getHapiRequestParams } from 'schema'
import { getUsers2Url } from 'services/getUrl'
import { TFetchItems } from 'services/types'
import { deserializeAsync, getPageCount, getRequest } from 'utils'

export const fetchUsers = async <Response>(
  shape: EmployeesSchema,
  settings: Record<string, any>
): Promise<TFetchItems<Response>> => {
  const url = getUsers2Url({
    searchParams: {
      ...getHapiRequestParams(shape),
      ...settings
    }
  })

  const response = await getRequest(url)
  const deserializedResponse = await deserializeAsync<Response[]>(response.data)
  const totalCount = response.data.meta.total

  return {
    items: deserializedResponse,
    pageCount: getPageCount(totalCount, settings['page[size]']),
    totalCount
  }
}
