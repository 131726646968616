// @ts-strict
import { ButtonForm, useButtonForm } from 'components'
import { DeleteIconButton } from 'components/Button/IconButton'
import { useExternalLinkController } from 'controllers'
import { ExternalLink } from 'models'

type TProps = {
  externalLink: ExternalLink
}

export const LeadRemoveExternalLinkButtonForm = ({ externalLink }: TProps) => {
  const { removeExternalLink } = useExternalLinkController(externalLink.id)

  const { formProps } = useButtonForm({
    initialPayload: { externalLinkId: externalLink.id },
    mutation: removeExternalLink
  })

  return (
    <ButtonForm
      title="Remove External Resource"
      buttonText="Remove Resource"
      buttonComponent={DeleteIconButton}
      {...formProps}
    >
      Remove "{externalLink.name}" external resource?
    </ButtonForm>
  )
}
