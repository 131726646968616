import { CapitalPartner } from 'models'
import { ProviderLeadCapitalPartnerResponse } from 'schema'

export class ProviderLeadCapitalPartner {
  id!: string
  capitalPartner?: CapitalPartner

  constructor(providerLeadCapitalPartnerAttritubes: ProviderLeadCapitalPartner) {
    Object.assign(this, providerLeadCapitalPartnerAttritubes)
  }

  static create(
    providerLeadCapitalPartnerResponse: Potential<ProviderLeadCapitalPartnerResponse>
  ): Potential<ProviderLeadCapitalPartner> {
    if (!providerLeadCapitalPartnerResponse) {
      return
    }

    const capitalPartner = CapitalPartner.create(providerLeadCapitalPartnerResponse.capitalPartner)
    return new ProviderLeadCapitalPartner({
      ...providerLeadCapitalPartnerResponse,
      capitalPartner
    })
  }
}
