import { useEffect } from 'react'
import { Contents } from 'components'
import { AgentLeadsList } from 'content/AgentLeads'
import { useAgentContext } from 'contexts/Agent'
import { AgentLeadsProvider, useAgentLeadsContext } from 'contexts/AgentLeads'
import { useDocumentTitle } from 'hooks'

const AgentReferralsTabContents = () => {
  const { agentLeads, isLoading, pageCount, totalCount, totalUrgentCount } = useAgentLeadsContext()
  return (
    <Contents title="Referrals">
      <AgentLeadsList
        agentLeads={agentLeads}
        isLoading={isLoading}
        pageCount={pageCount}
        totalCount={totalCount}
        totalUrgentCount={totalUrgentCount}
      />
    </Contents>
  )
}

export const AgentReferralsTab = () => {
  const { agent } = useAgentContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${agent.fullName} - Agent Referrals`), [agent, setTitle])

  return (
    <AgentLeadsProvider rootPath={`/agents/${agent.id}/referrals`} agentId={agent.id}>
      <AgentReferralsTabContents />
    </AgentLeadsProvider>
  )
}
