import { useMutation, useQueryClient } from 'hooks'
import { dispositionLender } from 'services'

type lenderDispositionPayload = {
  disposition: string
  followUpDate?: string
  leadType?: string
  lenderId: string
  note?: string
  skipAutoIntro?: string
}

export const useDispositionLenderController = (leadId?: string) => {
  const queryCache = useQueryClient()

  const lenderDisposition = useMutation(
    (payload: lenderDispositionPayload) => dispositionLender(leadId, payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries()
      }
    }
  )

  return { lenderDisposition }
}
