import { asArray } from '../asArray'

export const byAttr =
  (attr: string, value: string | readonly string[]) => (item: Record<string, any>) => {
    return item[attr] && asArray(value).some(val => asArray(item[attr]).includes(val))
  }

type ItemByType = Partial<{
  [val: string]: any
  type: string
}>

export const byType = (types: string | string[]) => (item: ItemByType) => {
  if (!item.type) {
    return false
  }

  return asArray(types)
    .map(x => x?.toLowerCase())
    .includes(item.type.toLowerCase())
}

type Ids = Readonly<string> | Readonly<Array<string>>
type ItemById = Partial<{
  [val: string]: any
  id: string
}>

export const byId = (ids: Ids) => (item: ItemById) =>
  asArray(ids)
    .map(id => String(id))
    .includes(String(item.id))

type ItemByValue = Partial<{
  [val: string]: any
  value: any
}>

export const byValue = (values: any) => (item: ItemByValue) => {
  return asArray(values).includes(item.value)
}

type ItemBySlug = Partial<{
  [val: string]: any
  slug: string
}>

export const bySlug = (slugs: any) => (item: ItemBySlug) => {
  return asArray(slugs).includes(item.slug)
}
