import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Grid.module.scss'

type TProps = {
  children: ReactNode
  className?: string
}

export const Grid = ({ children, className }: TProps) => {
  return <div className={cx(styles.grid, className)}>{children}</div>
}
