import { createContext, useContext } from 'react'
import { ParamsContextType } from 'contexts/types'

export const ParamsContext = createContext<ParamsContextType | null>(null)

export const useParamsContext = () => {
  const context = useContext(ParamsContext)
  if (!context) {
    throw new Error(
      'useParamsContext must be called within a QueryStringParamsProvider or ParamsProvider'
    )
  }
  return context
}
