import { ReactElement } from 'react'
import { TabbedContent } from 'components'
import { AgentAgentLeads, AgentDetails, AgentListings, AgentTransactions } from 'content/Agent'
import { AgentProviderLeadActivity, AgentProviderLeadDetails } from 'content/AgentProviderLead'
import { ParamsProvider } from 'contexts/Params'
import { AgentProviderLead } from 'models'

type Props = {
  agentProviderLead: AgentProviderLead
}

export const AgentProviderLeadTabs = ({ agentProviderLead }: Props) => {
  const agent = agentProviderLead.agentLead.agent
  const contentsComponents: Record<string, ReactElement> = {
    'Agent Details': <AgentDetails agent={agent} stack />,
    'MLS Listings': (
      <ParamsProvider>
        <AgentListings agent={agent} />
      </ParamsProvider>
    ),
    Transactions: (
      <ParamsProvider>
        <AgentTransactions agent={agent} />
      </ParamsProvider>
    ),
    Referrals: (
      <ParamsProvider>
        <AgentAgentLeads agent={agent} />
      </ParamsProvider>
    ),
    'Stage Updates': <AgentProviderLeadActivity agentProviderLead={agentProviderLead} />,
    'Referral Details': <AgentProviderLeadDetails agentProviderLead={agentProviderLead} />
  }

  return <TabbedContent content={contentsComponents} />
}
