import { cx } from 'utils'
import styles from './Pagination.module.scss'
import { PaginationLink } from './PaginationLink'
import { pageArray } from './pageArray'

interface Props {
  className?: string
  currentPage: string
  onChange: (num) => void
  totalPages?: number
}

export const Pagination = ({ className, totalPages, onChange, currentPage = '1' }: Props) => {
  const currPage = parseInt(currentPage)
  const pages = pageArray(totalPages, currPage)

  if (!totalPages || totalPages <= 1) {
    return null
  }

  const handleOnChange = (e, val) => {
    e.stopPropagation()
    onChange(val)
  }

  return (
    <div className={cx(styles.paginationContainer, className)} data-testid="pagination">
      <PaginationLink disabled={currPage === 1} onClick={e => handleOnChange(e, 1)}>
        First
      </PaginationLink>
      <PaginationLink disabled={currPage === 1} onClick={e => handleOnChange(e, currPage - 1)}>
        Previous
      </PaginationLink>
      {pages.map(page => {
        return (
          <PaginationLink
            key={page}
            className={currPage === page ? styles.active : null}
            onClick={e => handleOnChange(e, page)}
          >
            {page}
          </PaginationLink>
        )
      })}
      <PaginationLink
        disabled={currPage === totalPages}
        onClick={e => handleOnChange(e, currPage + 1)}
      >
        Next
      </PaginationLink>
      <PaginationLink
        disabled={currPage === totalPages}
        onClick={e => handleOnChange(e, totalPages)}
      >
        Last
      </PaginationLink>
    </div>
  )
}
