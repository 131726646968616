import { Option } from 'classes'
import { useLookupsController } from 'controllers/Lookups'

export const useListingStatus = () => {
  const [listingStatusData] = useLookupsController('listing_status')

  const listingStatusOptions = listingStatusData.map(
    (lookup: TLookupType) => new Option({ name: lookup.name, value: lookup.name })
  )

  return { listingStatusOptions }
}
