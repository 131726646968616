// @ts-strict

import { Option } from 'classes'
import { Metroplex, UsState } from 'models'
import { filterByState } from 'models/Metroplex/util'
import { isNotNull } from 'utils'

type TAssignedStateAreas = {
  allAreas: Option<string>[]
  nonMetroplex: Option<string>[]
}

export const getAssignedStateOptions = (
  metroplexes: Metroplex[],
  usStates: UsState[]
): TAssignedStateAreas => {
  const { allAreas, nonMetroplex } = usStates.reduce(
    (acc: TAssignedStateAreas, state) => {
      const stateMetroplexIds = filterByState(metroplexes, state.code).map(mpx => mpx.id)

      if (stateMetroplexIds.length) {
        acc.allAreas.push({
          name: `${state.code} - All Areas`,
          value: [state.id, ...stateMetroplexIds].join(',')
        })
      }

      acc.nonMetroplex.push({
        name: `${state.code} - Non Metroplex`,
        value: state.id
      })

      return acc
    },
    { allAreas: [], nonMetroplex: [] }
  )

  return {
    allAreas: allAreas.filter(isNotNull),
    nonMetroplex: nonMetroplex.filter(isNotNull)
  }
}
