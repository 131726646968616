import { HTMLAttributes, ReactNode, useRef, useState } from 'react'
import { useClickAway } from 'hooks'
import styles from './Ellipsis.module.scss'

export type EllipsisProps = {
  children?: ReactNode
} & HTMLAttributes<HTMLDivElement>

export const Ellipsis = ({ children, ...props }: EllipsisProps) => {
  const [visibleValue, setVisibleValue] = useState(false)
  const valueRef = useRef()

  const hideValue = () => setVisibleValue(false)
  const showValue = () => setVisibleValue(true)
  useClickAway(valueRef, hideValue)

  return (
    <div
      className={!visibleValue ? styles.ellipsis : null}
      onClick={showValue}
      ref={valueRef}
      {...props}
    >
      {children}
    </div>
  )
}
