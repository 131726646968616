import { IconButton, IconButtonProps } from '@foundation/components'

type Props = Pick<IconButtonProps, 'onClick' | 'isLoading'> & {
  title?: string
}

export const ImpersonateIconButton = ({ isLoading, onClick, title }: Props) => {
  return (
    <IconButton
      title={title ?? 'Impersonate'}
      variant="outline"
      icon="VisibilityOutlined"
      size="xs"
      onClick={onClick}
      isLoading={isLoading}
    />
  )
}
