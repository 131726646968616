import React, { createContext, ReactNode, useContext } from 'react'
import { useAgentTeamController } from 'controllers'

const AgentTeamContext = createContext<ReturnType<typeof useAgentTeamController>>(null)

export const useAgentTeamContext = () => {
  const context = useContext(AgentTeamContext)
  if (!context) {
    throw new Error('useAgentTeamContext must be called within a AgentTeamProvider')
  }
  return context
}

type Props = {
  agentId?: string
  children: ReactNode
}

export const AgentTeamProvider = ({ children, agentId }: Props) => {
  return (
    <AgentTeamContext.Provider value={useAgentTeamController(agentId)}>
      {children}
    </AgentTeamContext.Provider>
  )
}
