import { CheckBoxField, FormField, TextArea, TextInput } from 'components'
import styles from '../../forms/Form.module.scss'
import { TTeamFormPayload } from './utils'

type TProps = {
  onChangeAttribute: <K extends keyof TTeamFormPayload>(key: K, value: TTeamFormPayload[K]) => void
  payload: TTeamFormPayload
}

export const TeamFormFields = ({ payload, onChangeAttribute }: TProps) => {
  const onClick = e => {
    const { name } = e.target
    onChangeAttribute(name, e.target.checked)
  }

  return (
    <>
      <FormField title="Name" htmlFor="name" required>
        <TextInput
          id="name"
          onChange={value => onChangeAttribute('name', value)}
          name="name"
          autoComplete="off"
          value={payload.name}
          data-testid="name-field"
        />
      </FormField>
      <FormField title="Description" htmlFor="description">
        <TextArea
          id="description"
          value={payload.description}
          name="description"
          onChange={value => onChangeAttribute('description', value)}
          data-testid="description-field"
          className={styles.description}
        />
      </FormField>
      <CheckBoxField
        checked={payload.enabled}
        title="Enabled"
        name="enabled"
        onChange={onClick}
        data-testid="enabled-field"
      />
    </>
  )
}
