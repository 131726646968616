import { ButtonForm, EmailInput, FormField, PhoneInput, TextInput, useButtonForm } from 'components'
import { useBBYSLeadController } from 'hooks'
import { BBYSLead } from 'models'
import { validateEmailFormat, validatePresence } from 'utils'

type Props = {
  bbysLead?: BBYSLead
}

export const AddTransactionCoordinatorContact = ({ bbysLead }: Props) => {
  const { updateBBYSLead } = useBBYSLeadController(bbysLead.id)
  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm({
    mutation: updateBBYSLead,
    initialPayload: {
      transactionCoordinatorName: '',
      transactionCoordinatorEmail: '',
      transactionCoordinatorPhoneNumber: ''
    },
    formValidations: {
      transactionCoordinatorName: [validatePresence],
      transactionCoordinatorEmail: [validatePresence, validateEmailFormat]
    }
  })

  return (
    <ButtonForm title="Add Contact" {...formProps}>
      <FormField title="Name" required>
        <TextInput
          onChange={val => onChangeAttribute('transactionCoordinatorName', val)}
          data-testid="name"
          value={payload.transactionCoordinatorName}
        />
      </FormField>
      <FormField title="Email Address" required>
        <EmailInput
          onChange={val => onChangeAttribute('transactionCoordinatorEmail', val)}
          data-testid="email"
          value={payload.transactionCoordinatorEmail}
        />
      </FormField>
      <FormField title="Phone Number">
        <PhoneInput
          onChange={val => onChangeAttribute('transactionCoordinatorPhoneNumber', val)}
          data-testid="phoneMobile"
          value={payload.transactionCoordinatorPhoneNumber}
        />
      </FormField>
    </ButtonForm>
  )
}
