// @ts-strict
import { EmptyTableCell, TableRow as Tr } from 'components'
import {
  LeadAddressCell,
  LeadAgentCell,
  LeadCreatedUpdatedCell,
  LeadNamePartnerCell,
  LeadOtherLeadsCell,
  LeadPriceCell,
  LeadStageFurthestCell
} from 'content/Leads'
import { useListContext } from 'contexts'
import { Lead } from 'models'
import { capitalize, cx } from 'utils'
import { AllLeadsSourceCell } from './AllLeadsTableCells'

const columnMapping = {
  namePartner: LeadNamePartnerCell,
  address: LeadAddressCell,
  otherLeads: LeadOtherLeadsCell,
  stageFurthest: LeadStageFurthestCell,
  createdUpdated: LeadCreatedUpdatedCell,
  source: AllLeadsSourceCell,
  referringAgent: LeadAgentCell,
  price: LeadPriceCell
}

type TProps = {
  lead: Lead
}

export const AllLeadsTableRow = ({ lead }: TProps) => {
  const { highlightRows, visibleColumns, getColumnAlignment } = useListContext()
  const rowClassName = highlightRows?.includes(lead.id) && 'highlight'

  return (
    <Tr className={cx(rowClassName)} data-testid={`lead-id-${lead.id}`}>
      {visibleColumns.map(visibleColumn => {
        const Cell = columnMapping[visibleColumn]
        const testId = `lead${capitalize(visibleColumn)}Cell`
        const align = getColumnAlignment(visibleColumn)

        return Cell ? (
          <Cell
            lead={lead}
            column={visibleColumn}
            key={visibleColumn}
            testId={testId}
            align={align}
          />
        ) : (
          <EmptyTableCell key={visibleColumn} align={align} />
        )
      })}
    </Tr>
  )
}
