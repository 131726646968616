// @ts-strict
import { List, Tooltip } from 'components'
import {
  Agent,
  CashOfferLead,
  isCCBBYSLead,
  isCCCashOfferLead,
  isCCHLSimpleSaleLead,
  isCCTradeInLead,
  Lead
} from 'models'
import { d } from 'utils'

type AttachedToSubtitleProps = {
  attachableLead: Agent | Lead
  subAttachableLead?: CashOfferLead
}

export const AttachedToSubtitle = ({
  attachableLead,
  subAttachableLead
}: AttachedToSubtitleProps) => {
  const subAttachableAddress = subAttachableLead?.incomingMasterProperty?.displayAddress

  const isTradeInAttachableLead =
    isCCTradeInLead(attachableLead) &&
    !isCCCashOfferLead(attachableLead) &&
    !isCCHLSimpleSaleLead(attachableLead)

  const isCCBBYSAttachableLead =
    isCCBBYSLead(attachableLead) &&
    !isCCTradeInLead(attachableLead) &&
    !isCCCashOfferLead(attachableLead) &&
    !isCCHLSimpleSaleLead(attachableLead)

  const isHLSSAttachableLead =
    isCCHLSimpleSaleLead(attachableLead) &&
    !isCCTradeInLead(attachableLead) &&
    !isCCCashOfferLead(attachableLead)

  const isCashOfferAttachableLead =
    isCCCashOfferLead(attachableLead) &&
    !subAttachableLead &&
    !isCCTradeInLead(attachableLead) &&
    !isCCHLSimpleSaleLead(attachableLead)

  const isCashOfferSubAttachableLead =
    isCCCashOfferLead(attachableLead) &&
    subAttachableLead &&
    !isCCTradeInLead(attachableLead) &&
    !isCCHLSimpleSaleLead(attachableLead)

  const isDefaultAttachableLead =
    !isTradeInAttachableLead &&
    !isCashOfferAttachableLead &&
    !isCashOfferSubAttachableLead &&
    !isHLSSAttachableLead &&
    !isCCBBYSAttachableLead

  const BbysAttachable = () => {
    return isCCBBYSAttachableLead ? (
      <List>
        <Tooltip ellipsis>{`${d(attachableLead.modelName)} (${d(attachableLead.stage)})`}</Tooltip>
        <Tooltip ellipsis>{attachableLead.bbysLead?.departingPropertyFullAddress}</Tooltip>
      </List>
    ) : null
  }

  const TradeInAttachable = () => {
    return isTradeInAttachableLead ? (
      <List>
        <Tooltip ellipsis>{`${d(attachableLead.modelName)} (${d(attachableLead.stage)})`}</Tooltip>
        <Tooltip ellipsis>
          {attachableLead.tradeInProviderLead?.tradeInLead?.departingMasterProperty?.displayAddress}
        </Tooltip>
      </List>
    ) : null
  }

  const HLSSAttachable = () => {
    return isHLSSAttachableLead ? (
      <List>
        <Tooltip ellipsis>{`${d(attachableLead.modelName)} (${d(attachableLead.stage)})`}</Tooltip>
        <Tooltip ellipsis>
          {
            attachableLead.hlSimpleSaleProviderLead?.providable?.departingMasterProperty
              ?.displayAddress
          }
        </Tooltip>
      </List>
    ) : null
  }

  const CashOfferAttachable = () => {
    return isCashOfferAttachableLead ? (
      <List>
        <Tooltip ellipsis>{`${d(attachableLead.modelName)} (${d(attachableLead.stage)})`}</Tooltip>
        <Tooltip ellipsis>
          {attachableLead.activeCashOfferLead?.incomingMasterProperty?.displayAddress}
        </Tooltip>
      </List>
    ) : null
  }

  const CashOfferSubAttachable = () => {
    return isCashOfferSubAttachableLead ? (
      <List>
        <Tooltip ellipsis>
          {`${d(subAttachableLead.modelName)} (${d(subAttachableLead?.providerLead?.stage)})`}
        </Tooltip>
        <Tooltip ellipsis>{subAttachableAddress}</Tooltip>
      </List>
    ) : null
  }

  return (
    <>
      {isDefaultAttachableLead ? (
        <Tooltip ellipsis>{d(attachableLead?.modelName)}</Tooltip>
      ) : (
        <>
          <BbysAttachable />
          <TradeInAttachable />
          <HLSSAttachable />
          <CashOfferAttachable />
          <CashOfferSubAttachable />
        </>
      )}
    </>
  )
}
