import { Order } from 'models'
import { stringifyValue } from 'utils'
import { CCTradeInLeadDetailsProps } from '../../CCTradeInLeadDetails'

export const getOrderInputProps = (
  order: Order,
  updateOrder: CCTradeInLeadDetailsProps['updateOrder']
) => {
  const handleUpdateOrder = (key: string, val: string) => {
    updateOrder({
      [key]: val
    })
  }

  const getProps = (key: string) => {
    const value = order ? stringifyValue(order[key]) : ''

    return {
      value,
      onBlur: val => {
        handleUpdateOrder(key, val)
      }
    }
  }

  return {
    getProps
  }
}
