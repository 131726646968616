import { ActivityFeedInterface } from 'models/v2/ActivityFeed'
import { titleize, toSnakeCase } from 'utils'
import * as S from './styles'

// Temporary function to make the metadata more readable. Eventually to be replaced for proper messages.
const getHumanReadableMeta = (meta: ActivityFeedInterface['meta']) => {
  const content = []

  for (const [key, value] of Object.entries(meta)) {
    if (typeof value !== 'object') {
      // If the type of value represents a URL, then create a link
      if (key.toLowerCase().includes('url')) {
        content.push(
          <li key={key}>
            <strong>{titleize(toSnakeCase(key))}: </strong>
            <a href={value}>{value}</a>
          </li>
        )

        continue
      }

      content.push(
        <li key={key}>
          <strong>{titleize(toSnakeCase(key))}:</strong> {value}
        </li>
      )
    } else {
      if (key === 'inspectionDetails') {
        for (const [, inspection_detail] of Object.entries(value)) {
          content.push(
            <li key={inspection_detail['inspectionType']}>
              <strong>{titleize(toSnakeCase(inspection_detail['inspectionType']))}:</strong>{' '}
              {inspection_detail['status']}
            </li>
          )
        }
      }
    }
  }

  return content
}

type Props = {
  meta: ActivityFeedInterface['meta']
}

export const ActivityEventDetail = ({ meta }: Props) => {
  return <S.List>{getHumanReadableMeta(meta)}</S.List>
}
