import { UserResource } from '..'

const resourcePrefixes = {
  Agent: '/agents',
  Order: '/orders',
  User: '/admin/employees'
}

export const getResourceUrl = (userResource?: UserResource) => {
  if (!userResource?.linkedResourceType) {
    return undefined
  }

  const resourceUrl = resourcePrefixes[userResource.linkedResourceType]
  const resourceId = userResource.linkedResourceId || userResource.userId

  return `${resourceUrl}/${resourceId}`
}
