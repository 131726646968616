import * as S from './styles'
import type { CommonProps } from './types'

export type SpinnerProps = CommonProps

export const Spinner = ({
  'data-testid': dataTestId = 'spinnerWrapper',
  label,
  labelPlacement = 'right',
  size = 'sm',
  color = 'primary5',
  title
}: SpinnerProps) => {
  return (
    <S.SpinnerWrapper data-testid={dataTestId} labelPlacement={labelPlacement} size={size}>
      <S.SpinnerAnimation data-testid="spinnerAnimation" color={color} size={size}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="22 22 44 44" role="img" aria-hidden="true">
          <circle cx="44" cy="44" r="16" fill="none" strokeWidth="4"></circle>
          {title && <title>{title}</title>}
        </svg>
      </S.SpinnerAnimation>
      {label && <S.Label>{label}</S.Label>}
    </S.SpinnerWrapper>
  )
}
