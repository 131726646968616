import { LeadUser } from 'models/LeadUser'
import { LeadResponse } from 'schema'

export const getLeadUsers = (lead: LeadResponse): LeadUser[] => {
  if (!lead.leadUsers) {
    return []
  }

  return lead.leadUsers
    .map(leadUser => (leadUser ? LeadUser.create(leadUser) : undefined))
    .filter(Boolean)
    .sort((a, b) => a?.user?.name.localeCompare(b?.user?.name))
}
