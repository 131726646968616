// @ts-strict
import { useButtonForm } from 'components'
import { TEmployeePayload, useEmployeeController } from 'controllers'
import { Employee } from 'models'
import { TFormValidation, validateEmailFormat, validatePhoneFormat, validatePresence } from 'utils'

export const useEmployeeEditButtonForm = (employee: Employee) => {
  const { updateEmployee } = useEmployeeController(employee.id)
  const initialPayload: TEmployeePayload = {
    email: employee.email,
    firstName: employee.firstName!,
    lastName: employee.lastName!,
    region: employee.region,
    phoneMobile: employee.phoneNumbers?.find(number => number.phoneType === 'cell')?.number,
    phoneOffice: employee.phoneNumbers?.find(number => number.phoneType === 'office')?.number
  }

  const defaultValidations: TFormValidation = {
    firstName: validatePresence,
    lastName: validatePresence,
    email: [validatePresence, validateEmailFormat],
    phoneMobile: validatePhoneFormat,
    phoneOffice: validatePhoneFormat
  }

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm({
    initialPayload,
    mutation: updateEmployee,
    formValidations: defaultValidations
  })

  return {
    formProps,
    onChangeAttribute,
    payload
  }
}
