import { AgentPartnership } from 'models'
import { getAgentPartnershipsUrl } from 'services/getUrl'
import { deserializeAsync, putRequest, snakeCaseKeys } from 'utils'
import { AgentPartnershipPostPayload } from './postAgentPartnership'

export interface AgentPartnershipPutPayload extends AgentPartnershipPostPayload {}

export const putAgentPartnership = async (
  partnershipId: string,
  payload: AgentPartnershipPutPayload
) => {
  const url = getAgentPartnershipsUrl({ id: partnershipId })

  const response = await putRequest(url, snakeCaseKeys(payload))

  const agentPartnership: AgentPartnership = await deserializeAsync(response.data, {
    transform: AgentPartnership.create
  })
  return {
    agentPartnership
  }
}
