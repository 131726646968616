import { Option } from 'classes'

const leadGroupableDefinitionColumns = [
  'agent_leads_count',
  'area_id',
  'area_type',
  'blind_introable',
  'calculated_value_v3',
  'concierge_triggered',
  'created_at',
  'duplicate',
  'eligible_for_hlco',
  'eligible_for_hlss',
  'eligible_for_hlti',
  'eligible_for_investor',
  'hour_pt_created_at',
  'interested_in_agent',
  'investor_leads_count',
  'last_id_digit',
  'last_two_id_digits',
  'lead_channel_id',
  'marketing_channel',
  'marketing_source',
  'order_owned_business_unit',
  'otherside_info_eligible_for_hlco',
  'otherside_info_eligible_for_hlss',
  'otherside_info_eligible_for_hlti',
  'otherside_info_eligible_for_investor',
  'outgoing_call_count',
  'phone_numbers.number',
  'phone_numbers.verified_phone_type',
  'price',
  'property_type',
  'prospect_id',
  'pt_day_of_week',
  'sales_events.action',
  'secondary_user_type',
  'source',
  'source_form',
  'source_page_type',
  'source_quiz_start',
  'stage',
  'states',
  'timeline',
  'user_type',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'viable_for_sales_search'
]

const leadGroupableDefinitionActions = [
  'after',
  'any_not_empty',
  'before',
  'between',
  'does_not_exist_values',
  'equal',
  'exists_values',
  'greater_than',
  'ilike',
  'ilike_any',
  'in',
  'is_blank',
  'is_not_true',
  'is_true',
  'jsonb_not_equal',
  'less_than',
  'not_blank',
  'not_ilike',
  'not_in'
]

const fieldsToOptions = fields => {
  return fields.map(
    field =>
      new Option({
        name: field,
        value: field
      })
  )
}

export const noInputActions = ['is_blank', 'not_blank', 'is_true', 'is_not_true']
export const columnOptions = fieldsToOptions(leadGroupableDefinitionColumns)
export const actionOptions = fieldsToOptions(leadGroupableDefinitionActions)
