export const similarLeadsSettings = [
  {
    name: '',
    value: 'type'
  },
  {
    name: 'Name',
    value: 'namePartner'
  },
  {
    name: 'Contact Info',
    value: 'contactInfo'
  },
  {
    name: 'Address',
    value: 'address'
  },
  {
    name: 'Stage',
    value: 'stage'
  },
  {
    name: 'Created / Updated',
    value: 'createdUpdated'
  }
] as const

export type TSimilarLeadsColumnValue = (typeof similarLeadsSettings)[number]['value']
