import { useEmployeeUsers } from 'controllers'
import {
  mortgageCreditCheckPassedOption,
  mortgageStagesOptions,
  sourcesOptions,
  stateNameOptions
} from 'lookups'
import { titleize } from 'utils'
import { buildSearchFilters, findFilterBySlug } from '../utils'
import { mortgageLeadSearchFilterSettings } from './mortgageLeadSearchFilterSettings'

export const useMortgageLeadSearchFilter = () => {
  const { getUserName } = useEmployeeUsers()

  const optionLoaders = {
    source: sourcesOptions,
    stage: mortgageStagesOptions,
    stateName: stateNameOptions
  }

  const mortgageTitleize = (value: string) => {
    if (value === mortgageCreditCheckPassedOption.value) {
      return mortgageCreditCheckPassedOption.name
    }
    return titleize(value)
  }

  const displayValueFnLoaders = {
    titleize: mortgageTitleize,
    getUserName
  }

  const mortgageLeadFilters = buildSearchFilters(mortgageLeadSearchFilterSettings, {
    optionLoaders,
    displayValueFnLoaders
  })

  const findMortgageLeadFilterBySlug = findFilterBySlug(mortgageLeadFilters)

  return { mortgageLeadFilters, findFilterBySlug: findMortgageLeadFilterBySlug }
}
