// @ts-strict

import { Button } from '@foundation/components'
import { ButtonForm, useButtonForm } from 'components'
import { useTasksContext } from 'contexts'
import { TaskFormFields } from '../TaskFormFields'
import { formValidations, transformPayload, TTaskFormPayload } from '../utils'

export const TaskCreateButtonForm = () => {
  const { attachable, createTask } = useTasksContext()

  const initialPayload: TTaskFormPayload = {
    assigneeId: '',
    assigneeName: '',
    dueAt: '',
    note: '',
    title: ''
  }

  const { formProps, fieldsProps } = useButtonForm({
    initialPayload,
    mutation: createTask,
    formValidations,
    transformPayload: payload => transformPayload({ attachable, payload })
  })

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={formProps.toggleShowForm}>
        Create Task
      </Button>
      <ButtonForm hideTriggerButton title="Create Task" {...formProps}>
        <TaskFormFields {...fieldsProps} attachable={attachable} />
      </ButtonForm>
    </>
  )
}
