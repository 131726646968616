import { Option } from 'classes'
import { useLookupsController } from 'controllers/Lookups'

export const useInspectionCategory = () => {
  const [data] = useLookupsController('inspection_categories')

  const inspectionCategoryOptions = data.map(
    (lookup: TLookupType) => new Option({ name: lookup.name, value: lookup.slug })
  )

  return { inspectionCategoryOptions }
}
