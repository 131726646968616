import { ReactNode } from 'react'
import { useCurrentUserContext } from 'contexts'

type Props = {
  children: ReactNode
}

export const Dev = ({ children }: Props): JSX.Element => {
  const { isDev } = useCurrentUserContext()

  if (!isDev) {
    return null
  }
  return children as JSX.Element
}
