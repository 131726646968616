import { TLeadUserTypeServerSlug } from 'lookups'
import { CCCashOfferLead, CCTradeInLead, EscrowLead, MortgageLead } from 'models'
import { OrderResponse } from 'schema'

export const getProduct: (order: OrderResponse) => TLeadUserTypeServerSlug = order => {
  if (order.leads?.find(l => l instanceof CCTradeInLead)) {
    return 'cc_trade_in'
  }

  if (order.leads?.find(l => l instanceof CCCashOfferLead)) {
    return 'cc_cash_offer'
  }

  if (order.leads?.find(l => l instanceof MortgageLead)) {
    return 'mortgage'
  }

  if (order.leads?.find(l => l instanceof EscrowLead)) {
    return 'escrow'
  }

  return null
}
