import { Order } from 'models'
import {
  getHapiRequestParams,
  LeadAttributesWithoutId,
  OrderAttributes,
  OrdersSchema
} from 'schema'
import { getOrdersUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams } from './getSearchParams'

const orderFields: Array<keyof OrderAttributes> = [
  'updatedAt',
  'createdAt',
  'ownedBusinessUnit',
  'source',
  'stage'
]

const leadFields: Array<LeadAttributesWithoutId> = [
  'createdAt',
  'fullAddress',
  'furthestStage',
  'lastStageUpdate',
  'name',
  'partnerName',
  'price',
  'source',
  'stage',
  'userType',
  'needsListingReview'
]

const orderResponseShape: OrdersSchema = {
  orders: [
    ...orderFields,
    {
      leads: [
        ...leadFields,
        {
          'agentProviderLeads:providerLead': [
            'stage',
            'introSentAt',
            {
              servicesOpportunities: [
                'productType',
                { 'status:servicesOpportunityStatus': ['name', 'slug'] }
              ]
            },
            {
              'providable:agentLead': [
                'createdAt',
                'introduced',
                'introSentAt',
                'stage',
                { agent: ['firstName', 'lastName'] }
              ]
            }
          ]
        },
        {
          houseCanaryStatusCache: ['status']
        },
        {
          'tradeInProviderLead:providerLead': [{ 'providable:tradeInLead': ['simpleSale'] }]
        }
      ]
    }
  ]
}

export const fetchAgentMatchingOrders = async (settings: {}) => {
  const url = getOrdersUrl({
    searchParams: {
      ...getSearchParams(settings),
      ...getHapiRequestParams(orderResponseShape)
    }
  })
  const response = await getRequest(url)
  const orders = await deserializeAsync(response.data, {
    transform: Order.create
  })
  return {
    orders,
    ordersCount: response.data.meta.total_orders
  }
}
