// @ts-strict
import { ActionButton, ButtonForm, Section, useButtonForm } from 'components'
import { useCurrentUserContext } from 'contexts'
import { useAgentTeamController } from 'controllers'
import type { Agent } from 'models'
import styles from './ToggleLeadBoostButtonForm.module.scss'

type Props = {
  agent: Agent
  isLeadBoostVisible: boolean
}

export const ToggleLeadBoostButtonForm = ({ agent, isLeadBoostVisible }: Props) => {
  const { toggleLeadBoostUi } = useAgentTeamController(agent.id, agent.agentTeamId)
  const { canEnableLeadBoost } = useCurrentUserContext()
  const { formProps } = useButtonForm({
    mutation: toggleLeadBoostUi,
    initialPayload: {}
  })
  const handleToggle = () => {
    toggleLeadBoostUi.mutateAsync()
  }

  if (!canEnableLeadBoost || agent.eliteStatus === 'active') {
    return null
  }

  if (isLeadBoostVisible) {
    return (
      <ButtonForm
        buttonText="Hide"
        title="Are you sure you want to hide Lead Boost?"
        {...formProps}
      >
        <Section className={styles.section}>
          <span>Hiding it will enable Lead Boost for this Agent and their team.</span>
        </Section>
      </ButtonForm>
    )
  }
  return (
    <ActionButton onClick={handleToggle} isLoading={toggleLeadBoostUi.isLoading}>
      Show
    </ActionButton>
  )
}
