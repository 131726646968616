import { useEffect } from 'react'
import { TCcTradeInLeadFieldAttribute } from 'lookups'
import { useCCTradeInLeadDispositionContext } from '../CCTradeInLeadDispositionContext'

export const useFields = (fields: {
  fields?: TCcTradeInLeadFieldAttribute | TCcTradeInLeadFieldAttribute[]
  required?: TCcTradeInLeadFieldAttribute | TCcTradeInLeadFieldAttribute[]
}) => {
  const fieldString = JSON.stringify(fields)
  const { registerFields, unregisterFields } = useCCTradeInLeadDispositionContext()

  useEffect(() => {
    registerFields(JSON.parse(fieldString))
    return unregisterFields
  }, [fieldString, registerFields, unregisterFields])
}
