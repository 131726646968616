// @ts-strict
import { useMemo } from 'react'
import { useLookupsController } from 'controllers/Lookups'
import { LEAD_USER_ROLE_CACHE_KEY, TRANSACTION_TEAMS_CACHE_KEY, useQuery } from 'hooks'
import { useTeamRoles } from 'lookups'
import { TransactionTeam, User } from 'models'
import { fetchTransactionTeams } from 'services'
import { sorts, uniqBy } from 'utils'
import { getOptions } from '../utils'

const getMembershipUsers = (transactionTeams: TransactionTeam[] = []) => {
  return transactionTeams
    .map(team => team.teamMemberships?.map(({ user }) => user))
    .flat()
    .filter(Boolean) as User[]
}

export const useTransactionTeams = () => {
  const { data: transactionTeams = [] } = useQuery(
    [TRANSACTION_TEAMS_CACHE_KEY],
    fetchTransactionTeams
  )
  const { teamRoles: transactionTeamRoles, findTeamRole: findTransactionTeamRole } =
    useTeamRoles('TransactionTeam')
  const [leadTeamRoles, findLeadTeamRole] = useLookupsController(LEAD_USER_ROLE_CACHE_KEY)

  const { options: transactionTeamsOptions } = getOptions(transactionTeams)
  const { options, getOptionName: getTransactionTeamMemberName } = getOptions(
    getMembershipUsers(transactionTeams)
  )

  const transactionTeamsMembersOptions = useMemo(
    () => uniqBy(options, 'value').sort(sorts.name),
    [options]
  )

  return {
    transactionTeams,
    transactionTeamsOptions,
    transactionTeamsMembersOptions,
    transactionTeamRoles,
    leadTeamRoles,
    findLeadTeamRole,
    findTransactionTeamRole,
    getTransactionTeamMemberName
  }
}
