export type TCCHLSimpleSaleLeadStageSlug =
  | 'new'
  | 'connected'
  | 'pitched'
  | 'application_started'
  | 'application_complete'
  | 'inspection_complete'
  | 'approved'
  | 'agreement_signed'
  | 'in_escrow_purchase'
  | 'closed_purchase'
  | 'listed'
  | 'in_escrow_sell'
  | 'closed_sell'
  | 'closed_paid'
  | 'nurture'
  | 'failed'

export type TCCHLSimpleSaleLeadStageSetting = {
  name: string
  nextStageSlugs?: TCCHLSimpleSaleLeadStageSlug[]
  other?: boolean
  slug: TCCHLSimpleSaleLeadStageSlug
  validPreviousStage?: boolean
}

export const ccHLSimpleSaleLeadStagesSettings: Array<TCCHLSimpleSaleLeadStageSetting> = [
  { name: 'New', slug: 'new', nextStageSlugs: ['connected'], validPreviousStage: true },
  { name: 'Connected', slug: 'connected', nextStageSlugs: ['pitched'], validPreviousStage: true },
  {
    name: 'Pitched',
    slug: 'pitched',
    nextStageSlugs: ['application_started'],
    validPreviousStage: true
  },
  {
    name: 'Application Started',
    slug: 'application_started',
    nextStageSlugs: ['application_complete'],
    validPreviousStage: true
  },
  {
    name: 'Application Complete',
    slug: 'application_complete',
    nextStageSlugs: ['inspection_complete'],
    validPreviousStage: true
  },
  {
    name: 'Inspection Complete',
    slug: 'inspection_complete',
    nextStageSlugs: ['approved'],
    validPreviousStage: true
  },
  {
    name: 'Approved',
    slug: 'approved',
    nextStageSlugs: ['agreement_signed'],
    validPreviousStage: true
  },
  {
    name: 'Agreement Signed',
    slug: 'agreement_signed',
    nextStageSlugs: ['in_escrow_purchase'],
    validPreviousStage: true
  },
  {
    name: 'In Escrow Purchase',
    slug: 'in_escrow_purchase',
    nextStageSlugs: ['closed_purchase'],
    validPreviousStage: true
  },
  {
    name: 'Closed Purchase',
    slug: 'closed_purchase',
    nextStageSlugs: ['listed'],
    validPreviousStage: true
  },
  { name: 'Listed', slug: 'listed', nextStageSlugs: ['in_escrow_sell'], validPreviousStage: true },
  {
    name: 'In Escrow Sell',
    slug: 'in_escrow_sell',
    nextStageSlugs: ['closed_sell'],
    validPreviousStage: true
  },
  {
    name: 'Closed Sell',
    slug: 'closed_sell',
    nextStageSlugs: ['closed_paid'],
    validPreviousStage: true
  },
  { name: 'Closed Paid', slug: 'closed_paid', validPreviousStage: true },
  { name: 'Nurture', slug: 'nurture', other: true },
  { name: 'Failed', slug: 'failed', other: true }
]
