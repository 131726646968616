// @ts-strict

import { TableHeaderActions, TableHeaderCell, TableHeaderRow } from 'components'

export const SettlementAgencyTeamMembersListHeader = () => {
  return (
    <TableHeaderRow>
      <TableHeaderCell>Type</TableHeaderCell>
      <TableHeaderCell>Name</TableHeaderCell>
      <TableHeaderCell>Role</TableHeaderCell>
      <TableHeaderCell>Assigned To</TableHeaderCell>
      <TableHeaderActions />
    </TableHeaderRow>
  )
}
