// @ts-strict

import { MortgageLeadDispositionButtonForm } from 'content/MortgageLead'
import { MortgageLead } from 'models'

type MortgageLeadTabActionsProps = {
  lead: MortgageLead
}

export const MortgageLeadTabActions = ({ lead }: MortgageLeadTabActionsProps) => {
  return (
    <>
      <MortgageLeadDispositionButtonForm lead={lead} />
    </>
  )
}
