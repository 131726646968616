// @ts-strict

import { MenuButton } from 'components'
import { useParamsContext } from 'contexts'
import { normalizeObject, objectMatch, removeAttributesWithNullValues } from 'utils'

type TProps = {
  newParams: Record<string, any>
}

export const ApplyFiltersButton = ({ newParams }: TProps) => {
  const { params, updateFilters } = useParamsContext()
  const newParamsWithoutEmptyValues = removeAttributesWithNullValues(newParams)
  const isParamsEqual = objectMatch(
    normalizeObject(params),
    normalizeObject(newParamsWithoutEmptyValues)
  )

  const handleApplyFilter = () => {
    updateFilters(newParams)
  }

  return (
    <MenuButton as="primary" onClick={handleApplyFilter} disabled={isParamsEqual}>
      Apply
    </MenuButton>
  )
}
