// @ts-strict
import { PhoneNumberResponse } from 'schema'
import { fetchAgentsAutoComplete } from 'services/Agents'
import { cx } from 'utils'
import { AutoComplete } from '../AutoComplete'
import styles from './AgentsAutoComplete.module.scss'

type TProps = {
  className?: string
  id?: string
  searchParams?: object
}

export type TAgentAutoCompleteResponse = {
  email?: string
  id: string
  name?: string
  phoneNumbers?: PhoneNumberResponse[]
  subTitle: string
  title: string
}

export const AgentsAutoCompleteV2 = ({ searchParams, className, ...props }: TProps) => {
  const fetcher = (searchTerm: string) => fetchAgentsAutoComplete({ ...searchParams, searchTerm })

  return (
    <AutoComplete
      fetcher={fetcher}
      className={cx(className, styles.autoComplete)}
      placeholder="Enter agent name or email..."
      data-testid="agents-autocomplete"
      {...props}
    />
  )
}
