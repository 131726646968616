import { useNavigate } from 'react-router-dom'
import { useToastContext } from '@foundation/components'
import { useTokenContext } from 'contexts'
import { useMutation } from 'hooks'
import { ExchangeTokensPayload, postExchangeTokens } from 'services/Auth'
import { camelCaseKeys } from 'utils'
import { useGeneratePKCEToAuthenticate } from './useGeneratePKCEToAuthenticate'

type PostExchangeTokensResponse = {
  accessToken: string
}

export const usePostExchangeTokens = () => {
  const navigate = useNavigate()
  const { setToken } = useTokenContext()
  const { clearPKCEChallenge, lastUnauthorizedURL } = useGeneratePKCEToAuthenticate()
  const toast = useToastContext()

  const exchangeTokens = useMutation(
    (payload: ExchangeTokensPayload) => postExchangeTokens(payload),
    {
      onSuccess: ({ data }) => {
        const { accessToken } = camelCaseKeys(data) as PostExchangeTokensResponse
        setToken(accessToken)

        clearPKCEChallenge()

        const urlToNavigate = lastUnauthorizedURL ?? '/'
        navigate(urlToNavigate)
      },
      onError: () => {
        clearPKCEChallenge()

        toast({
          title: 'Ops! Something went wrong. Try to login again!',
          status: 'error'
        })

        navigate('sign-out?statusCode=401')
      },
      onSettled: () => clearPKCEChallenge()
    }
  )

  return {
    exchangeTokens
  }
}
