import { Block } from 'components'
import { isCCCashOfferLead, isCCTradeInLead, Lead } from 'models'

type Props = {
  lead: Lead
}

export const OrdersTableRowLeadAgent = ({ lead }: Props) => {
  if (isCCTradeInLead(lead)) {
    return <Block title={lead.agent?.fullName} subtitle={lead.agentTeamsName} />
  }

  if (isCCCashOfferLead(lead)) {
    return <Block title={lead.agent?.fullName} subtitle={lead.agentTeamsName} />
  }
  return null
}
