import { HTMLProps, MutableRefObject } from 'react'
import { FloatingContext, ReferenceType } from '@floating-ui/react'
import { Icon, Text } from '..'
import * as SelectStyle from '../Select/styles'
import { selectOptionTextSize } from '../Select/styles'
import type { Item } from '../Select/types'
import * as S from './styles'
import { MultiSelectProps } from './types'

type MappedItem = Item & {
  index: number
}

type Props = {
  activeIndex: number | null
  context: FloatingContext<ReferenceType>
  getItemProps: (userProps?: HTMLProps<HTMLElement> | undefined) => Record<string, unknown>
  handleSelect: (index: number, value: string) => void
  isSelected: boolean
  item: MappedItem
  listRef: MutableRefObject<(HTMLElement | null)[]>
  size?: MultiSelectProps['size']
}

export const Option = ({
  activeIndex,
  context,
  item,
  handleSelect,
  listRef,
  getItemProps,
  isSelected,
  size = 'xs'
}: Props) => {
  const isOptionDisabled = !!item?.isDisabled
  return (
    <div
      ref={node => {
        listRef.current[item.index] = node
      }}
      role="option"
      tabIndex={item.index === activeIndex ? 0 : -1}
      aria-selected={isSelected}
      {...getItemProps({
        onClick() {
          if (item.isDisabled) {
            return
          }
          handleSelect(item.index, item.value)
        },

        onKeyDown(event) {
          if (item.isDisabled) {
            return
          }

          if (event.key === 'Enter') {
            handleSelect(item.index, item.value)
          }

          if (event.key === ' ' && !context.dataRef.current.typing) {
            event.preventDefault()
            handleSelect(item.index, item.value)
          }
        }
      })}
    >
      <SelectStyle.Option
        data-testid={`${item.value}Option`}
        disabled={isOptionDisabled}
        isFocused={item.index === activeIndex}
      >
        <S.OptionCheck data-testid={`${item.value}OptionCheck`} isSelected={isSelected}>
          {isSelected && <Icon data-testid="checkIcon" path="Check" color="white" />}
        </S.OptionCheck>
        <Text color={isOptionDisabled ? 'neutral8' : 'primary10'} size={selectOptionTextSize[size]}>
          {item.label}
        </Text>
      </SelectStyle.Option>
    </div>
  )
}
