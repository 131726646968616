// @ts-strict
import { Table } from 'components'
import { TableResults } from 'components/Table/TableResults'
import styles from 'content/Leads/LeadsTable/LeadsTableCells/LeadsTableCells.module.scss'
import { LeadsTableHeaderCells } from 'content/Leads/LeadsTable/LeadsTableHeaderCells'
import { useListContext } from 'contexts'
import { CCHLSimpleSaleLead } from 'models'
import { cx } from 'utils'
import { CCHLSimpleSaleLeadTableRow } from '.'

type TCCHLSimpleSaleLeadsTable = {
  className?: string
  isLoading: boolean
  items: Potential<CCHLSimpleSaleLead[]>
}

export const CCHLSimpleSaleLeadsTable = ({
  items,
  isLoading,
  className
}: TCCHLSimpleSaleLeadsTable) => {
  const { visibleColumns } = useListContext()
  const colSpan = visibleColumns.length

  return (
    <Table className={cx(styles.table, className)} alternatingColumns>
      <LeadsTableHeaderCells />
      <TableResults type="Leads" colSpan={colSpan} isLoading={isLoading}>
        {items?.map(lead => (
          <CCHLSimpleSaleLeadTableRow key={lead.id} lead={lead} />
        ))}
      </TableResults>
    </Table>
  )
}
