// @ts-strict
import { ButtonForm, CheckBoxField, InlineChangeButton, useButtonForm } from 'components'
import { useLeadContext } from 'contexts'
import { d, DEFAULT_STRING } from 'utils'
import * as S from '../styles'
import type { Field, HomeFeaturesKeys, HomeFeaturesPayload } from './types'

type Props = {
  features?: HomeFeaturesKeys[]
}

const fields: Field[] = [
  {
    title: 'Pre Fabricated home',
    key: 'preFabHome',
    value: false
  },
  {
    title: 'Age restricted community',
    key: 'ageRestrictedCommunity',
    value: false
  },
  {
    title: 'Gated community',
    key: 'gatedCommunity',
    value: false
  },
  {
    title: 'Unpermitted addition',
    key: 'unpermittedAddition',
    value: false
  },
  {
    title: 'Significant foundation issues',
    key: 'significantFoundationIssues',
    value: false
  },
  {
    title: 'Located in floodzone',
    key: 'floodZone',
    value: false
  },
  {
    title: 'Pool',
    key: 'pool',
    value: false
  },
  {
    title: 'Solar panels',
    key: 'solarPanels',
    value: false
  }
]

export const HomeFeaturesButtonForm = ({ features = [] }: Props) => {
  const { updateLead } = useLeadContext()
  const {
    fieldsProps: { onChangeAttribute, payload },
    formProps
  } = useButtonForm<HomeFeaturesPayload>({
    initialPayload: {
      homeFeatures: features
    },
    mutation: updateLead,
    formValidations: {
      homeFeatures: [
        ({ label, value }) => {
          const errors = []

          if (!value.length && features.length) {
            return []
          }

          if (
            value.length === features.length &&
            value.every((v: HomeFeaturesKeys) => features.includes(v))
          ) {
            // The button form api doesn't accepts true or false for errors and
            // don't allow the user to choose if we want to show or not
            // thats why I need to return this empty string here
            errors.push(` `)
          }

          return errors
        }
      ]
    },
    transformPayload: data => {
      const { homeFeatures } = data
      let callReviewHomefeatures: Record<HomeFeaturesKeys, boolean> | {} = {}
      const allFeatures = [...features, ...homeFeatures]

      allFeatures.forEach(feature => {
        const removeFeature =
          features.includes(feature as HomeFeaturesKeys) && !homeFeatures.includes(feature)
        callReviewHomefeatures = { ...callReviewHomefeatures, [feature]: !removeFeature }
      })

      return { callReview: callReviewHomefeatures }
    }
  })

  const featuresSelectedTitles = fields
    .filter(field => features.includes(field.key))
    .map(field => field.title)

  const formTitleWithFeatures =
    featuresSelectedTitles.length > 1
      ? featuresSelectedTitles.join(', ')
      : featuresSelectedTitles[0]
  const formTitle = features.length ? formTitleWithFeatures : DEFAULT_STRING

  const handleBrokerages = (feature: HomeFeaturesKeys) => {
    const homeFeatures = payload.homeFeatures
    if (homeFeatures.includes(feature)) {
      const optionsWithoutSelected = homeFeatures.filter(value => value !== feature)
      onChangeAttribute('homeFeatures', optionsWithoutSelected)
      return
    }

    onChangeAttribute('homeFeatures', [...homeFeatures, feature])
  }

  return (
    <ButtonForm
      buttonComponent={InlineChangeButton}
      title="Home Features"
      buttonText={formTitle}
      {...formProps}
    >
      {formProps.showForm && (
        <>
          <S.FormField title="Features" required>
            {Object.values(fields).map(field => {
              return (
                <CheckBoxField
                  key={field.key}
                  title={d(field.title)}
                  checked={payload.homeFeatures.includes(field.key)}
                  onChange={() => handleBrokerages(field.key)}
                />
              )
            })}
          </S.FormField>
        </>
      )}
    </ButtonForm>
  )
}
