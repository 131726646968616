import { GeneratedDocument } from 'models'
import { getBBYSLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

type TResponse = { count: number; generatedDocuments: GeneratedDocument[] }

export type TGeneratedDocumentsSearchParams = {
  bbysLeadId: string
}

export const fetchGeneratedDocuments = async (
  searchParams: TGeneratedDocumentsSearchParams
): Promise<TResponse> => {
  const response = await getRequest(
    getBBYSLeadsUrl({
      id: searchParams.bbysLeadId,
      action: 'generated-documents',
      searchParams: {
        include: 'user',
        sort: '-created_at'
      }
    })
  )

  const generatedDocuments = await deserializeAsync(response.data, {
    transform: GeneratedDocument.create
  })
  return {
    generatedDocuments,
    count: response.data.meta.total_count as number
  }
}
