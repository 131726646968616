// @ts-strict

import {
  Callout,
  SimpleTable,
  TableHeaderRow,
  TableCell as TD,
  TableHeaderCell as TH,
  TableRow as TR
} from 'components'
import { useFeatureFlags } from 'hooks'
import { CCTradeInLead } from 'models'
import { formatDate, formatMoney } from 'utils'

type TProps = { lead: CCTradeInLead }
const dateFormat = 'short'

export const CCTradeInLeadCallout = ({ lead }: TProps) => {
  const tradeInLead = lead?.tradeInProviderLead?.tradeInLead
  const { expressApproval } = useFeatureFlags(['express-approval'])
  const inspectionComplete = (
    <TR>
      <TD>Inspection's Complete</TD>
      <TD>{formatDate(tradeInLead?.inspectionCompletedAt, dateFormat)}</TD>
    </TR>
  )
  return (
    <Callout>
      <SimpleTable title="Net Equity For New Home Purchase" data-testid="net-equity-table">
        <TableHeaderRow>
          <TH>Type</TH>
          <TH>Target</TH>
          <TH>Actual</TH>
        </TableHeaderRow>
        <tbody>
          <TR>
            <TD>Net Equity</TD>
            <TD>{formatMoney(tradeInLead?.dpTargetNewHomePurchaseEquity)}</TD>
            <TD>{formatMoney(tradeInLead?.dpActualNewHomePurchaseEquityHl)}</TD>
          </TR>
        </tbody>
      </SimpleTable>

      <SimpleTable title="Close Of Escrow Dates" data-testid="escrow-dates-table">
        <TableHeaderRow>
          <TH>Type</TH>
          <TH>Actual</TH>
        </TableHeaderRow>
        <tbody>
          <TR>
            <TD>IR Purchase</TD>
            <TD>{formatDate(tradeInLead?.incomingPropertyCloseOfEscrowAt, dateFormat)}</TD>
          </TR>
          <TR>
            <TD>DR Purchase</TD>
            <TD>{formatDate(tradeInLead?.departingPropertyCloseOfPurchaseEscrowAt, dateFormat)}</TD>
          </TR>
          <TR>
            <TD>DR Sell</TD>
            <TD>{formatDate(tradeInLead?.departingPropertyCloseOfSaleEscrowAt, dateFormat)}</TD>
          </TR>
          {expressApproval && inspectionComplete}
        </tbody>
      </SimpleTable>
    </Callout>
  )
}
