// @ts-strict
import { isActiveAgentLead, sortByAgentLeadRank, sortByAgentLeadStageAttr } from 'lookups'
import { Agent, AgentLead, SellerLead, ServicesOpportunity } from 'models'
import { getAgentServicesOpportunities } from 'models/Agent/utils'
import { AgentLeadResponse, ProviderLeadResponse } from 'schema'
import { d } from 'utils'

export class AgentProviderLead {
  agentLead?: AgentLead
  createdAt?: string
  followUpDate?: string
  furthestStage?: string
  id!: string
  introduced?: boolean
  introSentAt?: string
  lead?: SellerLead
  reasonForFail?: string
  representedProduct?: string
  servicesOpportunities?: ServicesOpportunity[]
  stage?: string
  stageAndFurthestStage?: string

  constructor(agentProviderLeadAttributes: AgentProviderLead) {
    Object.assign(this, agentProviderLeadAttributes)
  }

  static fromAgent(agent: Agent) {
    return new AgentProviderLead({
      id: `apl-from-agent-${agent.id}`,
      agentLead: AgentLead.fromAgent(agent)
    })
  }

  static create(
    agentProviderLeadResponse: Potential<ProviderLeadResponse>
  ): Potential<AgentProviderLead> {
    if (!agentProviderLeadResponse) {
      return
    }

    const agentLead = AgentLead.create(agentProviderLeadResponse.providable as AgentLeadResponse)
    const introduced = Boolean(agentProviderLeadResponse.introSentAt)
    const lead = SellerLead.create(agentProviderLeadResponse?.lead)
    const servicesOpportunities = getAgentServicesOpportunities(
      agentProviderLeadResponse.servicesOpportunities
    )
    const stage = agentProviderLeadResponse.stage || ''
    const stageAndFurthestStage = ['failed', 'nurture'].includes(stage)
      ? `${d(stage)} (${d(agentProviderLeadResponse.furthestStage)})`
      : d(stage)

    return new AgentProviderLead({
      ...agentProviderLeadResponse,
      agentLead,
      introduced,
      lead,
      servicesOpportunities,
      stageAndFurthestStage
    })
  }

  static getActive(agentProviderLeads: AgentProviderLead[]) {
    const activeAgentProviderLeads =
      agentProviderLeads
        ?.filter(isActiveAgentLead)
        .sort((a, b) => sortByAgentLeadStageAttr(a, b) || sortByAgentLeadRank(a, b)) ?? []

    return activeAgentProviderLeads
  }

  static getSorted(agentProviderLeads: AgentProviderLead[]) {
    const sortedAgentProviderLeads =
      agentProviderLeads?.sort(
        (a, b) => sortByAgentLeadStageAttr(a, b) || sortByAgentLeadRank(a.agentLead!, b.agentLead!)
      ) ?? []

    return sortedAgentProviderLeads
  }
}
