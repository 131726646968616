import { getLeadsSearchFilterSettings } from '../utils'

export const buyerAndSellerLeadSearchFilterSlugs = [
  'assigneeId',
  'lastStageUpdate',
  'escrowAttached',
  'listingStatus',
  'maxPrice',
  'minPrice',
  'needsListingReview',
  'oppStatus',
  'stage',
  'userType',
  'pendingActions',
  'productEligibility',
  'stateName'
] as const

export const buyerAndSellerLeadSearchFilterSettings = getLeadsSearchFilterSettings(
  buyerAndSellerLeadSearchFilterSlugs
)
