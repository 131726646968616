// @ts-strict
import { Box } from '@foundation/components'
import { Icon, Section, Sections, Select, TextArea } from 'components'
import { useCurrentUserContext } from 'contexts'
import styles from './LeadGroupableDefinition.module.scss'
import { actionOptions, columnOptions, noInputActions } from './leadGroupableDefinitionHelper'

type LeadGruopableDefinitionProps = {
  leadGroupableDefinition: {
    action: string
    column: string
    values: string
  }
  onChangeAttribute: (key: string, value: any) => void
  onRemoveStatement: () => void
}

export const LeadGroupableDefinitionStatement = ({
  leadGroupableDefinition,
  onChangeAttribute,
  onRemoveStatement
}: LeadGruopableDefinitionProps) => {
  const { isLeadCreationAdmin } = useCurrentUserContext()

  return (
    <Box className={styles.definitionStatement}>
      <Sections>
        <Section>
          <Select
            disabled={!isLeadCreationAdmin}
            addBlank
            data-testid="column-select"
            value={leadGroupableDefinition.column}
            onChange={val => {
              onChangeAttribute('column', val)
            }}
            options={columnOptions}
          />
        </Section>
        <Section>
          <Select
            disabled={!isLeadCreationAdmin}
            addBlank
            data-testid="action-select"
            value={leadGroupableDefinition.action}
            onChange={val => {
              onChangeAttribute('action', val)
            }}
            options={actionOptions}
          />
        </Section>
        <Section>
          <TextArea
            disabled={
              !isLeadCreationAdmin || noInputActions.includes(leadGroupableDefinition.action)
            }
            onChange={val => {
              onChangeAttribute('values', val)
            }}
            name="values"
            autoComplete="off"
            value={leadGroupableDefinition.values}
            data-testid="values-text-field"
          />
        </Section>
      </Sections>
      {isLeadCreationAdmin && (
        <Box className={styles.deleteStatementIconContainer}>
          <Icon.Close className={styles.deleteStatementIcon} onClick={onRemoveStatement} />
        </Box>
      )}
    </Box>
  )
}
