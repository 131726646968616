// @ts-strict
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Block } from 'components'
import { FormField } from 'components/Form'
import { IslandSolid } from 'components/Graphic'
import { Section } from 'components/Section'
import { AgentEditButtonForm } from 'content/Agent/AgentForm'
import { useLocation } from 'hooks'
import { Agent, AgentLead } from 'models'
import { AgentAvatar } from '../AgentAvatar'
import { AgentActionBar } from './AgentActionBar'
import styles from './AgentSummary.module.scss'

type Props = {
  agent: Agent
  agentLead?: AgentLead
  children?: ReactNode
}

export const AgentSummary = ({ agent, agentLead }: Props) => {
  const { pathname } = useLocation()

  const subtitle = agent.investor
    ? 'Investor'
    : [agent.officeDisplayName, agent.state?.code].filter(Boolean).join(', ')
  const agentName = agent.investor ? agent.investor.name : agent.fullName
  const onVacation = agent?.referralPreferences?.onVacationUntil
  const agentNameDisplay = onVacation ? (
    <div>
      {agentName}
      <IslandSolid />
    </div>
  ) : (
    agentName
  )

  return (
    <Section>
      <Block
        title={
          pathname !== `/agents/${agent.id}` ? (
            <Link to={`/agents/${agent.id}`} title={agentName}>
              {agentNameDisplay}
            </Link>
          ) : (
            agentNameDisplay
          )
        }
        subtitle={subtitle}
        image={<AgentAvatar agent={agent} />}
        className={styles.title}
        actions={<AgentEditButtonForm agent={agent} />}
      />
      <AgentActionBar agent={agent} />
      {agentLead?.workedByAgent !== undefined ? (
        <FormField title="Worked by agent team member:">
          <Link to={agentLead.workedByAgent.getPathname()}>{agentLead.workedByAgent.name}</Link>
        </FormField>
      ) : null}
    </Section>
  )
}
