// @ts-strict

import { ReactNode } from 'react'
import { useSavedSearches } from 'hooks'
import { createCtx } from '../utils'

export const [useSavedSearchesContext, SavedSearchesContextProvider] =
  createCtx<ReturnType<typeof useSavedSearches>>()

type Props = {
  children: ReactNode
}

export const SavedSearchesProvider = ({ children }: Props) => {
  return (
    <SavedSearchesContextProvider value={useSavedSearches()}>
      {children}
    </SavedSearchesContextProvider>
  )
}
