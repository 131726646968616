import { useState } from 'react'
import { Contents, If, Section } from 'components'
import { AgentMatchingLeadBBYSScreenSection } from 'content/AgentMatchingLead/AgentMatchingLeadBBYSScreenSection'
import { LegacyAgentMatchingLeadBBYSScreenSection } from 'content/AgentMatchingLead/AgentMatchingLeadBBYSScreenSection'
import { AgentMatchingLeadClientScreenSection } from 'content/AgentMatchingLead/AgentMatchingLeadClientScreenSection'
import { AgentMatchingLeadExistingLenderScreenSection } from 'content/AgentMatchingLead/AgentMatchingLeadExistingLenderScreenSection'
import { AgentMatchingLeadMortgageScreenSection } from 'content/AgentMatchingLead/AgentMatchingLeadMortgageScreenSection'
import { AgentMatchingLeadPropertyDetailsSection } from 'content/AgentMatchingLead/AgentMatchingLeadPropertyDetailsSection'
import { LenderProviderLeadLoanApplicationsSection } from 'content/LenderProviderLead'
import { LenderStack } from 'content/Lenders'
import { LoanAmountCalculator } from 'content/MortgageLead/LoanAmountCalculator'
import { ParamsProvider } from 'contexts'
import { useFeatureFlags } from 'hooks'
import { LenderProviderLead, MortgageLead } from 'models'
import { presence } from 'utils'
import { MortgageLeadDetailsSection } from './Mortgage'

type MortgageLeadDetailsProps = {
  lead: MortgageLead
  updateLead: (payload: Record<string, string | number | Record<string, string>>) => void
}

export const MortgageLeadDetails = ({ lead, updateLead }: MortgageLeadDetailsProps) => {
  const {
    salesAppEligibility: { enabled: eligibilityEnabled }
  } = useFeatureFlags(['sales-app-eligibility'])
  const [selectedLoanApplication, setSelectedLoanApplication] = useState('0')
  const lenderProviderLeads = lead?.lenderProviderLeads
  const currentLoanApplication: LenderProviderLead | undefined =
    lenderProviderLeads && lenderProviderLeads[selectedLoanApplication]
  const showD2cFields = lead.source === 'd2c'

  const updateCallReview = (key: string, value: string) => {
    updateLead({ callReview: { [key]: value } })
  }

  return (
    <Contents>
      <MortgageLeadDetailsSection lead={lead} updateLead={updateLead} />
      <AgentMatchingLeadPropertyDetailsSection
        lead={lead}
        updateField={updateLead}
        updateCallReview={updateCallReview}
      />
      <AgentMatchingLeadClientScreenSection
        lead={lead}
        updateField={updateLead}
        updateCallReview={updateCallReview}
      />
      <If test={showD2cFields}>
        <AgentMatchingLeadMortgageScreenSection
          lead={lead}
          updateField={updateLead}
          updateCallReview={updateCallReview}
        />
      </If>

      <If test={showD2cFields}>
        <LoanAmountCalculator
          lead={lead}
          updateCallReview={updateCallReview}
          updateField={updateLead}
        />
      </If>

      <If test={eligibilityEnabled && showD2cFields}>
        <AgentMatchingLeadBBYSScreenSection
          lead={lead}
          updateCallReview={updateCallReview}
          updateField={updateLead}
        />
      </If>
      <If test={!eligibilityEnabled && showD2cFields}>
        <LegacyAgentMatchingLeadBBYSScreenSection
          lead={lead}
          updateCallReview={updateCallReview}
          updateField={updateLead}
        />
      </If>

      <If test={showD2cFields}>
        <AgentMatchingLeadExistingLenderScreenSection
          lead={lead}
          updateCallReview={updateCallReview}
        />
      </If>

      <If test={presence(lenderProviderLeads)}>
        <LenderProviderLeadLoanApplicationsSection
          price={lead.price}
          selectedLoanApplication={selectedLoanApplication}
          setSelectedLoanApplication={setSelectedLoanApplication}
          lenderProviderLeads={lenderProviderLeads}
          currentLoanApplication={currentLoanApplication}
        />
      </If>
      <If test={showD2cFields}>
        <ParamsProvider>
          <Section title="Lenders">
            <Section>
              <LenderStack lead={lead} />
            </Section>
          </Section>
        </ParamsProvider>
      </If>
    </Contents>
  )
}
