import { useState } from 'react'
import { Text } from '@foundation/components'
import { ButtonForm, ButtonV2, Section, Sections, TextInput } from 'components'
import { useCurrentUserContext } from 'contexts'
import { useReferralPreferencesController } from 'controllers/ReferralPreferences'
import type { Agent } from 'models'
import styles from './LocationPreferences.module.scss'
import { useRequestedLocationsButtonForm, ZipCodesSection } from '.'

type RequestedLocationsButtonFormProps = {
  agent: Agent
}

export const RequestedLocationsButtonForm = ({ agent }: RequestedLocationsButtonFormProps) => {
  const [inputValue, setInputValue] = useState('')
  const { isAgentAdmin } = useCurrentUserContext()
  const { updateAgentReferralPreferences } = useReferralPreferencesController(agent.id)
  const {
    fieldsProps: { onChangeAttribute, payload },
    formProps
  } = useRequestedLocationsButtonForm(updateAgentReferralPreferences, agent)

  if (!isAgentAdmin) {
    return null
  }

  const onInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onChangeAttribute('requestedZipCodes', [
        ...payload.requestedZipCodes,
        ...inputValue.split(',').map(code => code.trim())
      ])
      setInputValue('')
    }
  }

  const onCodeClick = (code: string) => {
    onChangeAttribute(
      'requestedZipCodes',
      payload.requestedZipCodes.filter((c: string) => c !== code)
    )
  }

  return (
    <ButtonForm
      title="Requested Locations"
      buttonText="Edit Locations"
      buttonComponent={buttonProps => (
        <ButtonV2 className={styles.editLocationsButton} {...buttonProps} />
      )}
      {...formProps}
    >
      <Section>
        <Text>You can remove zip codes by clicking them.</Text>
      </Section>
      <Section>
        <Section>
          <Sections className={styles.inputLabel}>
            <Text size="sm">Find zip code </Text>
            <div style={{ width: '4px' }} />
            <Text size="sm" color="neutral8">
              (Press enter or comma after each one)
            </Text>
          </Sections>
          <TextInput
            placeholder="Find zip codes"
            name="Find zip code"
            value={inputValue}
            onChange={setInputValue}
            onKeyPress={onInputKeyPress}
          />
        </Section>
        <Section>
          <ZipCodesSection
            active
            sectionName="requested"
            onZipCodeClick={onCodeClick}
            zipCodes={payload.requestedZipCodes}
          />
        </Section>
      </Section>
    </ButtonForm>
  )
}
