// @ts-strict
import { Link } from 'react-router-dom'
import { Box, Popover } from '@foundation/components'
import { Block, Ellipsis } from 'components'
import { useLeadUserTypes } from 'lookups'
import { Lead } from 'models'
import { formatMoney } from 'utils'
import { LeadBadge } from '..'

type TProps = {
  lead?: Lead
}

export const LeadMenuCard = ({ lead }: TProps) => {
  const { getLeadUserType } = useLeadUserTypes()

  if (!lead) {
    return <span>---</span>
  }

  const { fullAddress, stageAndFurthestStage } = lead || {}
  const leadUserType = getLeadUserType(lead?.userType)?.name || 'Unknown'
  const stageAndFurthestStageLabel = stageAndFurthestStage ? `(${stageAndFurthestStage})` : ''
  const leadLabel = `${leadUserType} ${stageAndFurthestStageLabel}`

  return (
    <Popover.Root placement="bottom-start" isHoverEnabled>
      <Box as="span" marginRight="$2">
        <Popover.Trigger>
          <Link to={lead.getPathname()}>
            <LeadBadge lead={lead} />
          </Link>
        </Popover.Trigger>
      </Box>
      <Popover.Content>
        <Block title={<Ellipsis title={leadLabel}>{leadLabel}</Ellipsis>} subtitle={fullAddress} />
        <Block subtitle={`Price: ${formatMoney(lead.price)}`} />
      </Popover.Content>
    </Popover.Root>
  )
}
