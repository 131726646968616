import { TValidation } from './validate'

export const validateChanged =
  (originalValue: string): TValidation =>
  ({ label, value }) => {
    const errors = []

    if (originalValue === value) {
      errors.push(`${label} has not been changed`)
    }

    return errors
  }
