// @ts-strict
import { css, styled } from '@foundation/themes/styles'

export const Title = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space.$3};
    margin-bottom: ${theme.space.$3};
  `}
`

export const Popup = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: min(90vw, 1440px);
    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.3);
    margin-top: 4rem;
    margin-bottom: 4rem;
    height: fit-content;
    background-color: ${theme.colors.white};
    font-size: ${theme.fontSizes.sm};
    z-index: ${theme.zIndices.modal};
    /* prettier-ignore */
    @media(max-width: 414px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
      max-width: 90vw;
    }
  `}
`
