import { byAttr } from 'utils'
import { MasterPropertyField } from './MasterPropertyField'
import {
  masterPropertyFieldsSettings,
  TMasterPropertyFieldAttribute
} from './masterPropertyFieldsSettings'

export const lookupMasterPropertyFields = masterPropertyFieldsSettings.map(setting => {
  return new MasterPropertyField(setting)
})

export const getMasterPropertyField = (attr: TMasterPropertyFieldAttribute) => {
  return lookupMasterPropertyFields.find(byAttr('attr', attr))
}

export const getMasterPropertyFieldName = (attr: TMasterPropertyFieldAttribute) => {
  return getMasterPropertyField(attr)?.name
}
