import {
  INSPECTION_DEFICIENCY_SORT_SETTINGS,
  SearchFilter,
  trueFalseOptions,
  useInspectionCategory,
  useInspectionSeverity,
  useInspectionType
} from 'lookups'
import { bySlug } from 'utils'
import { buildSearchFilters } from '../utils'
import { INSPECTION_DEFICIENCY_FILTER_SETTINGS } from './inspectionDeficiencyFilterSettings'

export const useInspectionDeficienciesSearchFilter = () => {
  const { inspectionCategoryOptions } = useInspectionCategory()
  const { inspectionTypeOptions } = useInspectionType()
  const { inspectionSeverityOptions } = useInspectionSeverity()

  const sortFilters = INSPECTION_DEFICIENCY_SORT_SETTINGS.map(
    setting =>
      new SearchFilter({
        slug: setting.value.split('.')[0],
        name: setting.name
      })
  )

  const filters = buildSearchFilters(INSPECTION_DEFICIENCY_FILTER_SETTINGS, {
    optionLoaders: {
      trueFalseOptions,
      inspectionCategoryOptions,
      inspectionTypeOptions,
      inspectionSeverityOptions
    }
  })

  const findFilterBySlug = (slug: string) =>
    sortFilters.find(bySlug(slug)) || filters.find(bySlug(slug))

  return { findFilterBySlug }
}
