// @ts-strict
import { useState } from 'react'
import {
  CheckBoxArray,
  Column,
  Columns,
  EmployeesAutoComplete,
  MenuSection,
  MoneyInput,
  Section,
  Select
} from 'components'
import { useListContext } from 'contexts'
import { useNewFilters } from 'hooks'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import { ListFilters } from 'templates'
import styles from './AllLeadsSearchFilters.module.scss'

export const AllLeadsSearchFilters = () => {
  const { tracking } = useCustomEvents()
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const { newParams, set } = useNewFilters()
  const { findFilterBySlug } = useListContext()

  const assigneeIdFilter = findFilterBySlug('assigneeId')
  const userTypeFilter = findFilterBySlug('userType')
  const minPriceFilter = findFilterBySlug('minPrice')
  const maxPriceFilter = findFilterBySlug('maxPrice')
  const stateFilter = findFilterBySlug('stateName')

  return (
    <ListFilters.Root isOpen={isOpenPopover} onOpenChange={setIsOpenPopover}>
      <ListFilters.Trigger>
        <ListFilters.TriggerButton iconRight="FilterAltOutlined">Filters</ListFilters.TriggerButton>
      </ListFilters.Trigger>
      <ListFilters.Content>
        <Section>
          <Columns className="resetOverflow">
            <Column className="resetOverflow">
              <MenuSection title={userTypeFilter?.name}>
                <CheckBoxArray
                  options={userTypeFilter!.options!}
                  value={newParams.userType}
                  onChange={val => set('userType', val)}
                />
              </MenuSection>
            </Column>
            <Column className="resetOverflow">
              <MenuSection title={assigneeIdFilter?.name}>
                <EmployeesAutoComplete
                  value={assigneeIdFilter?.getDisplayValue(newParams.assigneeId)}
                  onSelect={val => set(assigneeIdFilter?.slug!, val?.id)}
                  onClear={() => set(assigneeIdFilter?.slug!, '')}
                />
              </MenuSection>

              <MenuSection title="Price Range" className={styles.priceRangeFields}>
                <MoneyInput
                  value={newParams.minPrice}
                  onChange={val => set(minPriceFilter?.slug!, val)}
                  placeholder={minPriceFilter?.placeholder!}
                />
                <MoneyInput
                  value={newParams.maxPrice}
                  onChange={val => set(maxPriceFilter?.slug!, val)}
                  placeholder={maxPriceFilter?.placeholder!}
                />
              </MenuSection>

              <MenuSection title={stateFilter?.name}>
                <Select
                  addBlank
                  value={newParams.stateName}
                  options={stateFilter?.options}
                  onChange={val => set(stateFilter!.slug, val)}
                />
              </MenuSection>
            </Column>
          </Columns>
          <ListFilters.Footer>
            <ListFilters.ApplyButton
              newParams={newParams}
              onApplyToggle={setIsOpenPopover}
              onClick={() =>
                tracking({ name: 'filters', params: { action: 'apply', target: 'lead' } })
              }
            />
          </ListFilters.Footer>
        </Section>
      </ListFilters.Content>
    </ListFilters.Root>
  )
}
