// @ts-strict
import { FileVersion } from 'models'
import { PhotoUploadResponse } from 'schema'
import { formatDate } from 'utils'
import { Upload } from '.'

type TConstructor = Potential<Omit<PhotoUpload, 'url' | 'thumbUrl' | 'mime' | 'isProcessing'>>

export class PhotoUpload extends Upload {
  category?: string
  fileVersions?: Potential<FileVersion>[]
  isProcessing: boolean
  mime: Potential<string>
  url: string = ''
  thumbUrl: Potential<string>
  userLog: string = ''

  constructor(attributes: TConstructor) {
    super()
    Object.assign(this, attributes)

    const fileVersion = this.fileVersions?.[0]
    this.url = fileVersion?.fastlyUrl ?? ''
    this.thumbUrl = this.url ? `${this.url}?width=400` : undefined
    this.mime = fileVersion?.mime
    this.isProcessing = this.mime === 'image/heic' || this.mime === 'image/heif'
  }

  static create(response: Potential<PhotoUploadResponse>): Potential<PhotoUpload> {
    if (!response) {
      return
    }

    const uploadedItem = Upload.build(response)
    if (!uploadedItem) {
      return
    }

    const uploadedByText = response.user?.name ? `by ${response.user?.name ?? ''}` : ''
    const userLog = `Uploaded ${uploadedByText} on ${formatDate(response.createdAt, 'short')}`
    const fileVersions =
      response?.fileVersions?.map(fileVersion => FileVersion.create(fileVersion)) || []

    return new PhotoUpload({ ...uploadedItem, fileVersions, userLog })
  }
}
