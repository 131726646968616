// @ts-strict

import { useEffect, useState } from 'react'
import { CityStateAutoComplete } from 'components/AutoComplete'
import { dataOrDash } from 'utils'
import { DisplayValue } from '../DisplayValue'
import type { InlineProps } from '../DisplayValue'

type Props = {
  className?: string
  onBlur: (val: string) => void
  placeholder?: string
  value?: string
} & InlineProps

export const InlineEditCityState = ({
  format,
  isError,
  isLoading,
  placeholder,
  onBlur,
  value,
  className
}: Props) => {
  const [currentValue, setCurrentValue] = useState(value || '')
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    }
  }, [value])

  const handleOnSelect = (selectedValue: string) => {
    if (selectedValue !== value) {
      onBlur(selectedValue)
    }
    setEditMode(false)
    setCurrentValue(selectedValue)
  }

  const removeValue = () => {
    setCurrentValue('')
  }

  const stopEditMode = () => {
    setEditMode(false)
  }

  const setEditModeTrue = () => {
    setEditMode(true)
  }

  const formatter = (changedValue: string) => {
    return format ? format(changedValue) : changedValue
  }

  if (editMode) {
    return (
      <CityStateAutoComplete
        className={className}
        value={currentValue}
        onSelect={handleOnSelect}
        onClear={removeValue}
        onBlur={stopEditMode}
        placeholder={placeholder}
        data-testid="cityState-auto-complete"
      />
    )
  }

  return (
    <DisplayValue
      className={className}
      value={dataOrDash(currentValue)}
      setEditMode={setEditModeTrue}
      format={formatter}
      isLoading={isLoading}
      isError={isError}
    />
  )
}
