import { byAttr } from 'utils'
import {
  escrowOfficeLeadFieldsSettings,
  TEscrowOfficeLeadFieldAttribute
} from './escrowOfficeLeadFieldsSettings'
import { EscrowOfficeLeadField } from './'

export const escrowOfficeLeadFields = escrowOfficeLeadFieldsSettings.map(setting => {
  return new EscrowOfficeLeadField(setting)
})

export const getEscrowOfficeLeadField = (attr: TEscrowOfficeLeadFieldAttribute) => {
  return escrowOfficeLeadFields.find(byAttr('attr', attr))
}

export const getEscrowOfficeLeadFieldName = (attr: TEscrowOfficeLeadFieldAttribute) => {
  return getEscrowOfficeLeadField(attr)?.name
}
