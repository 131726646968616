import { getLead, Lead } from 'models'
import {
  AgentAttributesAndAssociations,
  EscrowOfficeLeadAttributesAndAssociations,
  EscrowOrderSchema,
  getHapiRequestParams,
  LeadSchema
} from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getPageCount, getRequest } from 'utils'
import { TFetchItems } from '../../types'
import { EscrowLeadsSearchParams, getEscrowLeadsSearchParams } from './getEscrowLeadsSearchParams'

const agentFields: AgentAttributesAndAssociations = [
  'firstName',
  'lastName',
  { agentTeam: ['name'] },
  { agentTeamMemberships: [{ agentTeam: ['name'] }] }
]

const escrowOrderShape: EscrowOrderSchema['escrowOrder'] = [
  'status',
  {
    escrowOrderSettlementTeamMembers: [
      'role',
      {
        settlementTeamMember: ['firstName', 'lastName', 'email', 'phone']
      }
    ]
  }
]

const escrowOfficeProviderLeadFields: EscrowOfficeLeadAttributesAndAssociations = [
  'loanAmount',
  'netEquity',
  'orderClosingOn',
  'orderIdentifier',
  'orderNumber',
  'orderOpenAt',
  'orderStatus',
  'transactionCoordinatorEmail',
  'transactionCoordinatorName',
  { serviceOffice: ['licenseNumber', 'name', 'branchName'] },
  {
    escrowOrder: escrowOrderShape
  }
]

const leadResponseShape: LeadSchema = {
  lead: [
    'partnerName',
    'createdAt',
    'fullAddress',
    'lastStageUpdate',
    'email',
    'name',
    'stage',
    'userType',
    'source',
    'sourcePageType',
    'masterProperty',
    { fileAttachments: ['category', 'downloadUrl', { file: ['name', 'category'] }] },
    { leadUsers: ['role', { user: ['name', 'email'] }] },
    {
      'escrowOfficeProviderLeads:providerLead': [
        {
          'providable:escrowOfficeLead': escrowOfficeProviderLeadFields
        }
      ]
    },
    { 'referringOwner:agent': agentFields },
    {
      order: ['source', { leads: ['userType', 'price'] }]
    },
    {
      'parentLead:lead': []
    }
  ]
}

export const fetchEscrowLeads = async (
  settings: EscrowLeadsSearchParams
): Promise<TFetchItems<Lead>> => {
  const url = getLeadsUrl({
    searchParams: {
      ...getEscrowLeadsSearchParams(settings),
      ...getHapiRequestParams(leadResponseShape)
    }
  })
  const response = await getRequest(url)
  const leads = await deserializeAsync<Lead[]>(response.data, {
    transform: getLead
  })

  return {
    items: leads,
    pageCount: getPageCount(response.data.meta.total_leads, settings.perPage),
    totalCount: response.data.meta.total_leads || 0
  }
}
