import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient, useUpdateEffect } from 'hooks'
import { LocalStorageCache } from 'lib/LocalStorageCache'
import { fetchMessages, fetchMessagesCount, postMessage } from 'services'
import { getContacts } from './getContacts'
import { useLastRead } from './useLastRead'
import { lastMessageCreatedAt } from './utils/lastMessageCreatedAt'

const twentySeconds = 20_000
const longAgo = new Date('2010-01-01T00:00:00.000+00:00').toISOString()
const emptyArray = []
const newMessagesCountCache = new LocalStorageCache<number>('messageCount', twentySeconds)

export const useInbox = user => {
  const queryCache = useQueryClient()
  const [sinceDate, setSinceDate] = useState(longAgo)

  const { lastRead, markRead, markAllRead } = useLastRead()

  const fetchCount = () =>
    newMessagesCountCache.refetch(() =>
      fetchMessagesCount({
        senderId: user.id,
        fromDate: sinceDate
      })
    )

  const newMessagesData = useQuery(['messagesCount', sinceDate], fetchCount, {
    refetchInterval: twentySeconds,
    refetchIntervalInBackground: true,
    enabled: !!user.phoneTwilio
  })
  const newMessagesCount = newMessagesData.data || 0

  const { data: messages = emptyArray, isLoading } = useQuery(
    'messagesList',
    () => fetchMessages(),
    {
      staleTime: Infinity
    }
  )

  const sendText = useMutation(postMessage, {
    onSuccess: () => {
      queryCache.invalidateQueries('messagesList')
    }
  })

  useUpdateEffect(() => {
    if (messages && messages.length) {
      const lastMessageDate = lastMessageCreatedAt(messages)
      const since = new Date(lastMessageDate || longAgo)
      since.setMilliseconds(since.getMilliseconds() + 1)
      setSinceDate(since.toISOString())
    }
  }, [messages])

  useEffect(() => {
    newMessagesCountCache.clear()
  }, [sinceDate])

  useEffect(() => {
    if (newMessagesCount) {
      queryCache.invalidateQueries('messagesList')
    }
  }, [queryCache, newMessagesCount])

  const contacts = useMemo(() => getContacts({ messages, lastRead }), [messages, lastRead])
  const unreadMessagesCount = useMemo(() => {
    return contacts ? contacts.reduce((totalUnread, con) => totalUnread + con.unreadCount, 0) : 0
  }, [contacts])
  const getContact = useCallback(
    agentId => contacts.find(con => con.agent.id === agentId),
    [contacts]
  )

  return {
    contacts,
    isLoading,
    unreadMessagesCount,
    sendText,
    markRead,
    markAllRead,
    getContact
  }
}
