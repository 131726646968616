import { Section, Sections } from 'components'
import { useFields } from '..'
import { FieldFormField } from '../FieldFormField'

export const CCTradeInInEscrowPurchase = () => {
  useFields({
    required: [
      'dpTargetPurchaseCoeDate',
      'departingPropertyOpenedPurchasedEscrowAt',
      'incomingPropertyCloseOfEscrowAt'
    ],
    fields: ['incomingPropertyFullAddress']
  })
  return (
    <>
      <Sections title="DR Purchase Transaction">
        <Section>
          <FieldFormField field="dpTargetPurchaseCoeDate" />
        </Section>
        <Section>
          <FieldFormField field="departingPropertyOpenedPurchasedEscrowAt" />
        </Section>
      </Sections>
      <Sections title="Incoming Residence">
        <Section>
          <FieldFormField field="incomingPropertyCloseOfEscrowAt" />
        </Section>
        <Section>
          <FieldFormField field="incomingPropertyFullAddress" />
        </Section>
      </Sections>
    </>
  )
}
