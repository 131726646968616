import { useLayoutEffect, useRef, useState } from 'react'
import {
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead
} from '@floating-ui/react'
import type { Item, UseSelectProps } from './types'

const compareSectionTitles = (a: string, b: string) => {
  const fa = a.toLowerCase()
  const fb = b.toLowerCase()

  if (fa < fb) {
    return -1
  }
  if (fa > fb) {
    return 1
  }
  return 0
}

export const groupBySection = (items: Item[]) => {
  return (
    items
      // we have to sort first because we need to keep the options indexes in order
      .sort((a, b) => compareSectionTitles(a.section!.label, b.section!.label))
      .reduce<Record<string, Item[]>>((acc, item, index) => {
        const sectionValue = item.section!.value
        acc[sectionValue] = acc[sectionValue] || []
        acc[sectionValue].push({ ...item, index })
        return acc
      }, {})
  )
}

export const useSelect = ({
  defaultOpen,
  onClose,
  onOpen,
  items,
  isDisabled,
  value
}: UseSelectProps) => {
  const selectedItemByDefault = items.indexOf(items.find(item => item.value === value)!)
  const selectedItemByDefaultIndex = selectedItemByDefault === -1 ? null : selectedItemByDefault

  const [selectedIndex, setSelectedIndex] = useState<number | null>(selectedItemByDefaultIndex)

  if (selectedIndex !== selectedItemByDefaultIndex) {
    setSelectedIndex(selectedItemByDefault)
  }

  const [isOpen, setIsOpen] = useState<boolean>(!!defaultOpen)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [isPointer, setIsPointer] = useState<boolean>(false)

  const options = items.map(item => item.value)

  if (!isOpen && isPointer) {
    setIsPointer(false)
  }

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: open => {
      if (isDisabled) {
        return
      }

      if (open && onOpen) {
        onOpen()
      }

      if (!open && onClose) {
        onClose()
      }

      setIsOpen(open)
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        padding: 10,
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`
          })
        }
      })
    ]
  })

  const listRef = useRef<Array<HTMLElement | null>>([])
  const listContentRef = useRef(options)

  const click = useClick(context, { event: 'mousedown' })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'listbox' })
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: true
  })
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : setSelectedIndex
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    click,
    dismiss,
    role,
    listNav,
    typeahead
  ])

  useLayoutEffect(() => {
    if (activeIndex == null || isPointer) {
      return
    }

    requestAnimationFrame(() => {
      listRef.current[activeIndex]?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest'
      })
    })
  }, [isPointer, activeIndex])

  const selectedItem = selectedIndex !== null ? items[selectedIndex] : undefined

  const displayType: 'section' | 'single' = items.every(item => item.section?.value)
    ? 'section'
    : 'single'
  const formattedItems =
    displayType === 'section'
      ? (Object.entries(groupBySection(items)) as [string, Item[]][])
      : (items as Item[])

  const getSection = (sectionValue: string) =>
    items.find(option => option.section?.value === sectionValue)!.section!

  return {
    context,
    positionX: x,
    positionY: y,
    reference,
    floating,
    strategy,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    setSelectedIndex,
    setIsOpen,
    selectedItemLabel: selectedItem?.label,
    setIsPointer,
    isOpen,
    options: formattedItems,
    listRef,
    selectedIndex,
    activeIndex,
    displayType,
    getSection
  }
}
