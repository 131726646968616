import {
  AgentContactStatus,
  AgentProgramsCertifications,
  AgentStats,
  AgentSummary,
  AgentUserInfo
} from 'content/Agent'
import { Agent, AgentLead } from 'models'

type Props = {
  agent: Agent
  agentLead?: AgentLead
  className?: string
}

export const AgentAside = ({ agent, agentLead, className }: Props) => {
  return (
    <div className={className}>
      <AgentSummary agent={agent} agentLead={agentLead} />
      <AgentProgramsCertifications agent={agent} />
      <AgentContactStatus agent={agent} />
      <AgentStats agent={agent} />
      <AgentUserInfo agent={agent} />
    </div>
  )
}
