import { TableHeaderRow as Tr } from 'components'
import * as S from './styles'

export const ListUsersTableHeader = () => (
  <Tr>
    <S.NameTH sort={[{ name: 'Name', slug: 'Name' }]} />
    <S.NameTH sort={[{ name: 'Email', slug: 'Email' }]} />
    <S.NameTH name="Roles" />
    <S.NameTH name="Teams" />
    <S.IdTH sort={[{ name: 'ID', slug: 'Id' }]} />
    <S.ActionsTH name="Actions" />
  </Tr>
)
