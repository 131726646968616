// @ts-strict
import { ButtonForm } from 'components'
import { AgentTeamProvider } from 'contexts/AgentTeam'
import { useAgentTeamContext } from 'contexts/AgentTeam'
import { Agent } from 'models'
import { AgentProgramEligibility } from '../AgentProgramEligibility'
import { AgentProgramsFormFields } from './AgentProgramsFormFields'
import { useAgentProgramsFormButton } from './useAgentProgramsFormButton'

type Props = {
  agent: Agent
}

export const AgentProgramsFormButtonContent = ({ agent }: Props) => {
  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useAgentProgramsFormButton(agent)

  const { agentTeamQuery } = useAgentTeamContext()

  return (
    <ButtonForm title="Edit Program Enrollments" {...formProps} buttonText="Edit">
      <AgentProgramEligibility agent={agent} />
      <AgentProgramsFormFields
        agent={agent}
        agentTeam={agentTeamQuery.data}
        onChangeAttribute={onChangeAttribute}
        payload={payload}
      />
    </ButtonForm>
  )
}

export const AgentProgramsFormButton = ({ agent }: Props) => {
  return (
    <AgentTeamProvider agentId={agent.id}>
      <AgentProgramsFormButtonContent agent={agent} />
    </AgentTeamProvider>
  )
}
