import { FormField, LoadingText, Section } from 'components'
import { useAgentController } from 'controllers'
import { dataOrDash } from 'utils'
import { AgentLink } from '../AgentLink'
import { AgentActionBar } from '../AgentSummary'
import styles from './AgentDetailsMenuCard.module.scss'

type AgentDetailsMenuCardProps = {
  agentId: string
  onOpenChangeModal?: (isOpen: boolean) => void
}

export const AgentDetailsMenuCard = ({ agentId, onOpenChangeModal }: AgentDetailsMenuCardProps) => {
  const { agent } = useAgentController(agentId, true)

  if (!agent) {
    return (
      <Section>
        <LoadingText text="Loading agent data..." />
      </Section>
    )
  }

  return (
    <Section className={styles.content}>
      <AgentActionBar agent={agent} onOpenChangeModal={onOpenChangeModal} />

      <FormField className={styles.formField} title="Agent Team" inline>
        {dataOrDash(agent?.agentTeamName)}
      </FormField>
      <FormField className={styles.formField} title="Email" inline>
        {dataOrDash(agent?.email1 || agent?.email2)}
      </FormField>
      <FormField className={styles.formField} title="Phone" inline>
        {dataOrDash(agent?.getPhoneMobile()?.formattedNumber)}
      </FormField>
      <FormField className={styles.formField} title="License" inline>
        {dataOrDash(agent?.licenseNumber)}
      </FormField>
      <FormField className={styles.formField} title="Brokerage" inline>
        {dataOrDash(agent?.officeDisplayName)}
      </FormField>

      <div className={styles.agentLinkWrapper}>
        <AgentLink agent={agent} linkText="Go to the Agent's page" />
      </div>
    </Section>
  )
}
