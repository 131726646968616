import { Point } from 'classes'
import { removeOutliers } from './removeOutliers'

export const getBounds = (
  points: Point[]
): { bottomRight: Point; ne: Point; sw: Point; topLeft: Point } => {
  const validPoints = points.filter(p => p.longitude && p.latitude)
  if (!validPoints || !validPoints.length) {
    return {
      topLeft: new Point({ latitude: 38.2882221, longitude: -122.8965667 }),
      bottomRight: new Point({ latitude: 37.2882221, longitude: -121.8965667 }),
      ne: new Point({ latitude: 38.2882221, longitude: -121.8965667 }),
      sw: new Point({ latitude: 37.2882221, longitude: -122.8965667 })
    }
  }

  const coreLongitudes = removeOutliers(validPoints.map(p => p.longitude))
  const coreLatitudes = removeOutliers(validPoints.map(p => p.latitude))

  return {
    topLeft: new Point({
      longitude: coreLongitudes[coreLongitudes.length - 1],
      latitude: coreLatitudes[0]
    }),
    bottomRight: new Point({
      longitude: coreLongitudes[0],
      latitude: coreLatitudes[coreLatitudes.length - 1]
    }),
    sw: new Point({
      longitude: coreLongitudes[0],
      latitude: coreLatitudes[0]
    }),
    ne: new Point({
      longitude: coreLongitudes[coreLongitudes.length - 1],
      latitude: coreLatitudes[coreLatitudes.length - 1]
    })
  }
}
