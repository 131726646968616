// @ts-strict
import { css, styled } from '@foundation/themes/styles'

export const NormalIntroWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space.$6};

    > div > div {
      display: block;

      .agent-screen,
      .client-screen {
        margin-left: 0;
      }
    }
  `}
`
