import { Popover as PopoverComponent } from './Popover'
import { PopoverContent } from './PopoverContent'
import { PopoverTrigger } from './PopoverTrigger'
export type { PopoverProps } from './Popover'

export const Popover = {
  Root: PopoverComponent,
  Trigger: PopoverTrigger,
  Content: PopoverContent
}
