// @ts-strict
import { useButtonForm } from 'components'
import { useDispositionProviderLeadController } from 'controllers'
import { InvestorLead, Lead } from 'models'
import { TFormValidation, validatePresence } from 'utils'

export type TInvestorLeadDispositionButtonFormPayload = {
  disposition?: string
  note?: string
}

export const useInvestorLeadDispositionButtonForm = (
  investorLead: InvestorLead,
  lead: Potential<Lead>
) => {
  const { updateDispositionProviderLead } = useDispositionProviderLeadController({
    id: investorLead.providerLead.id,
    leadId: lead?.id,
    leadType: lead?.userType || 'seller'
  })

  const mutation = updateDispositionProviderLead

  const formValidations: TFormValidation = {
    disposition: { label: 'Disposition', validations: [validatePresence] }
  }

  const initialPayload: TInvestorLeadDispositionButtonFormPayload = {
    disposition: investorLead.providerLead?.furthestStage || ''
  }

  const transformPayload = ({ disposition, note }: TInvestorLeadDispositionButtonFormPayload) => {
    return {
      disposition,
      note
    }
  }

  const { formProps, fieldsProps } = useButtonForm<TInvestorLeadDispositionButtonFormPayload>({
    transformPayload,
    initialPayload,
    formValidations,
    mutation
  })

  return {
    formProps,
    fieldsProps
  }
}
