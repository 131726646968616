import { useEmployeeUsers } from 'controllers'
import { stateNameOptions, taskUserTypeOptions } from 'lookups/lookupOptions'
import { titleize } from 'utils'
import { buildSearchFilters, findFilterBySlug } from '../utils'
import { allLeadsSearchFilterSettings } from './allLeadsSearchFilterSettings'

export const useAllLeadsSearchFilter = () => {
  const { getUserName } = useEmployeeUsers()

  const optionLoaders = { userType: taskUserTypeOptions, stateName: stateNameOptions }
  const displayValueFnLoaders = {
    titleize,
    getUserName
  }

  const allLeadsFilters = buildSearchFilters(allLeadsSearchFilterSettings, {
    optionLoaders,
    displayValueFnLoaders
  })

  const findAllLeadsFilterBySlug = findFilterBySlug(allLeadsFilters)

  return { allLeadsFilters, findFilterBySlug: findAllLeadsFilterBySlug }
}
