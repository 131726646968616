import { UseMutationResult } from 'react-query'
import { Text } from '@foundation/components'
import { ButtonForm, ButtonV2, TextArea, useButtonForm } from 'components'
import { AgentPayPerLeadReferral } from 'models'
import styles from './ReferralsActionMenu.module.scss'

type Props = {
  mutation: UseMutationResult<any, any, any, unknown>
  overrideSubmitButtonText?: string
  referral: AgentPayPerLeadReferral
}

const referralParams = {
  unpaid: {
    buttonText: 'Credit',
    submitButtonText: 'Credit',
    title: 'Credit this Referral',
    subtext: 'Please enter the reason why you are crediting this referral'
  },
  paid: {
    buttonText: 'Refund',
    submitButtonText: 'Refund',
    title: 'Refund this Referral',
    subtext: 'Please enter the reason why you are refunding this referral'
  }
}

export const ReferralButtonForm = ({ referral, mutation, overrideSubmitButtonText }: Props) => {
  const referralStatus = referral.status || 'unpaid'
  const { buttonText, title, subtext } = referralParams[referralStatus]
  const submitButtonText =
    overrideSubmitButtonText || referralParams[referralStatus].submitButtonText

  const {
    fieldsProps: { onChangeAttribute, payload },
    formProps
  } = useButtonForm({
    mutation,
    submitButtonText,
    initialPayload: {
      notes: referral.notes
    }
  })

  return (
    <ButtonForm
      title={title}
      buttonText={buttonText}
      buttonComponent={buttonProps => <ButtonV2 className={styles.item} {...buttonProps} />}
      {...formProps}
    >
      <Text>{subtext}</Text>
      <TextArea
        name="notes"
        value={payload.notes}
        data-testid="notes-field"
        onChange={val => onChangeAttribute('notes', val)}
      />
    </ButtonForm>
  )
}
