import { SearchParams } from '@types'
import { removeAttributesWithNullValues } from 'utils'
import { getSort } from '../../utils'

const sortParam = {
  Created: 'created_at',
  Name: 'name.keyword',
  Score: '_score',
  Updated: 'order.last_lead_stage_update',
  Stage: 'stage',
  'IR Purchase Actual COE Date': 'active_cash_offer.incoming_property.close_of_purchase_escrow_at',
  'IR Sale Actual COE Date': 'active_cash_offer.incoming_property.close_of_sell_escrow_at',
  'IR Purchase Open Escrow Date': 'active_cash_offer.incoming_property.open_purchase_escrow_at',
  'IR Sale Open Escrow Date': 'active_cash_offer.incoming_property.open_sell_escrow_at',
  'Funding Date': 'providable.purchase_funded_at'
}

export type TCashOfferLeadsSearchParams = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'searchTerm' | 'sortDir' | 'sortBy'
> & {
  assigneeId?: string
  capitalPartnerId?: string
  expressClose?: string
  protectedOfferStatus?: string
  source?: string[]
  stage?: string[]
  stateName?: string
}

const getFilters = ({
  searchTerm,
  assigneeId,
  source,
  stage,
  expressClose,
  capitalPartnerId,
  protectedOfferStatus,
  stateName
}: TCashOfferLeadsSearchParams) => {
  const filters = {}

  if (searchTerm) {
    filters['filter[_search]'] = searchTerm
  }

  if (assigneeId) {
    filters['filter[lead_users.user_id]'] = assigneeId
  }

  if (stage) {
    filters['filter[stage]'] = stage
  }

  if (source) {
    filters['filter[order.source]'] = source
  }

  if (expressClose) {
    filters['filter[active_cash_offer.express_close]'] = expressClose
  }

  if (protectedOfferStatus) {
    filters['filter[active_cash_offer.protected_offer_status]'] = protectedOfferStatus
  }

  if (capitalPartnerId) {
    filters['filter[active_cash_offer.capital_partner_id]'] = capitalPartnerId
  }

  if (stateName) {
    filters['filter[state_name]'] = stateName
  }

  return filters
}

export const getCCCashOfferLeadsSearchParams = ({
  page,
  perPage,
  sortBy,
  sortDir,
  ...filters
}: TCashOfferLeadsSearchParams) => {
  return removeAttributesWithNullValues({
    'filter[user_type]': 'cc_cash_offer',
    'page[number]': page ? page : undefined,
    'page[size]': perPage ? perPage : undefined,
    sort: getSort(sortParam, sortDir, sortBy),
    ...getFilters(filters)
  })
}
