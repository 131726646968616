import { Section } from 'components'
import { Sections } from '../../../../../../components/Section/Sections'
import { FieldFormField } from '../FieldFormField'
import { useFields } from '../useFields'

export const CashOfferPropertySubmitted = () => {
  useFields({
    fields: [
      'incomingPropertyFullAddress',
      'incomingPropertySalePrice',
      'incomingPropertyInspectionContingencyWaived',
      'ipOfferTermsDaysTillCloseOfEscrow',
      'ipOfferTermsDaysTillInspectionContingencyEnds',
      'ipOfferTermsOptionTerminationFee',
      'ipOfferTermsInspectionObjectionDeadlineDate',
      'ipOfferTermsInspectionTerminationDeadlineDate',
      'ipOfferTermsInspectionResolutionDeadlineDate',
      'ipOfferTermsDeadlineDate'
    ],
    required: ['incomingPropertyInspectionContingencyWaived']
  })
  return (
    <>
      <Section>
        <FieldFormField field="incomingPropertyFullAddress" />
        <FieldFormField field="incomingPropertySalePrice" />
        <FieldFormField field="incomingPropertyInspectionContingencyWaived" required />
      </Section>
      <Sections>
        <Section>
          <FieldFormField field="ipOfferTermsDaysTillCloseOfEscrow" />
        </Section>
        <Section>
          <FieldFormField field="ipOfferTermsDaysTillInspectionContingencyEnds" />
        </Section>
      </Sections>
      <Section>
        <FieldFormField field="ipOfferTermsOptionTerminationFee" />
      </Section>
      <Sections>
        <Section>
          <FieldFormField field="ipOfferTermsInspectionObjectionDeadlineDate" />
          <FieldFormField field="ipOfferTermsInspectionTerminationDeadlineDate" />
        </Section>
        <Section>
          <FieldFormField field="ipOfferTermsInspectionResolutionDeadlineDate" />
          <FieldFormField field="ipOfferTermsDeadlineDate" />
        </Section>
      </Sections>
    </>
  )
}
