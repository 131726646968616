import { Button, ButtonProps } from '@foundation/components'

type Props = {
  isToggledOn: boolean
} & Pick<ButtonProps, 'onClick'>

export const ViewDetailsButton = ({ onClick, isToggledOn }: Props) => {
  return (
    <Button variant="link" size="xs" onClick={onClick}>
      {isToggledOn ? 'Hide details' : 'View details'}
    </Button>
  )
}
