import { useEffect, useState } from 'react'
import { LoanOfficersAutoComplete } from 'components/AutoComplete/LoanOfficersAutoComplete'
import { ButtonForm } from 'components/ButtonForm'
import { useCreateLoanOfficer } from 'content/LoanOfficer'
import { LoanOfficerFormFields } from 'content/LoanOfficer/LoanOfficerFormFields'
import { TLoanOfficerResponse } from 'content/LoanOfficer/utils'
import { cx, dataOrDash } from 'utils'
import { DisplayValue, InlineProps } from '../DisplayValue'
import style from '../InlineEditDateInput/InlineEditDateInput.module.scss'

type Props = {
  className?: string
  onBlur?: (val: string) => void
  placeholder?: string
  searchParams?: Record<string, string>
  value?: string
} & InlineProps

export const InlineEditLoanOfficer = ({
  onBlur = (_: string) => {},
  value,
  isLoading,
  isError,
  searchParams,
  format,
  className
}: Props) => {
  const [currentValue, setCurrentValue] = useState(value || '')
  const [editMode, setEditMode] = useState(false)

  const {
    formProps,
    fieldsProps,
    isLoading: isCreateLoanOfficerLoading
  } = useCreateLoanOfficer({
    onSuccess: data => {
      handleOnSelect(data)
    }
  })

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleOnSelect = (selectedValue: TLoanOfficerResponse) => {
    if (selectedValue?.userId !== value) {
      onBlur(selectedValue?.userId ?? '')
    }
    setEditMode(false)
    setCurrentValue(selectedValue?.title)
  }

  const handleClear = () => {
    setCurrentValue('')
  }

  const setEditModeTrue = () => {
    setEditMode(true)
  }
  const setEditModeFalse = () => {
    setEditMode(false)
  }

  const formatter = (changedValue: string) => {
    return format ? format(changedValue) : changedValue
  }

  return (
    <>
      <ButtonForm {...formProps} title="Create Loan Officer" hideTriggerButton>
        <LoanOfficerFormFields {...fieldsProps} />
      </ButtonForm>

      {editMode ? (
        <LoanOfficersAutoComplete
          value={currentValue}
          onSelect={handleOnSelect}
          onClear={handleClear}
          onBlur={setEditModeFalse}
          onCreateLoanOfficer={formProps.toggleShowForm}
          searchParams={searchParams}
          className={cx(style.date, className)}
          autoFocus
        />
      ) : (
        <DisplayValue
          value={dataOrDash(currentValue)}
          setEditMode={setEditModeTrue}
          format={formatter}
          isLoading={isLoading || isCreateLoanOfficerLoading}
          isError={isError}
        />
      )}
    </>
  )
}
