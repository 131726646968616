export const getRecipientsString = (recipientNames: string[]) => {
  const hasMoreThanTwoRecipients = recipientNames.length > 2

  if (hasMoreThanTwoRecipients) {
    const [recipient1, recipent2, ...rest] = recipientNames
    const restLength = rest.length
    const othersString = restLength > 1 ? 'others' : 'other'

    return `${recipient1}, ${recipent2} and ${restLength} ${othersString}`
  }

  return `${recipientNames.join(' and ')}`
}
