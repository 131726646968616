import { css, styled } from '@foundation/themes/styles'

export const Content = styled.div<{ $zIndex?: number }>`
  ${({ theme, $zIndex }) => css`
    z-index: ${$zIndex || theme.zIndices.popover};
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadows.md};
    padding: ${theme.space.$4};
    border-radius: ${theme.radii.base};
    outline: none;
  `}
`
