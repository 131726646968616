import React from 'react'
import { LoadingText, TableBody, TableCell, TableRow } from '../index'

type TableLoadingProps = {
  colSpan?: number
  type?: string
}

export const TableLoading = ({ colSpan, type }: TableLoadingProps) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={colSpan}>
          <LoadingText text={`Loading ${type}`} />
        </TableCell>
      </TableRow>
    </TableBody>
  )
}
