// @ts-strict
import { FC, useCallback, useMemo } from 'react'
import { useButtonForm } from 'components'
import { useLeadContext } from 'contexts'
import { TMortgageLeadDispositionPayload, useMortgageLeadController } from 'controllers'
import { TMortgageLeadFailedReason } from 'lookups'
import { TMortgageLeadStagesSlug, useMortgageLeadStages } from 'lookups'
import { MortgageLead } from 'models'
import { TFormValidation, validatePresence } from 'utils'
import { MortgageLeadDispositionFailedFields } from '../MortgageLeadDispositionButtonFormFields'

export type TMortgageLeadDispositionFC<TPayload> = {
  lead: MortgageLead
  onChangeAttribute: <K extends keyof TPayload>(key: K, value: TPayload[K]) => void
  payload: TPayload
  removeAttribute: <K extends keyof TPayload>(key: K) => void
  setValidations: (newValidations: TFormValidation) => void
}

export type TMortgageLeadDispositionFields<TPayload> = Record<
  string,
  FC<TMortgageLeadDispositionFC<TPayload>>
>

const defaultValidations: TFormValidation = {
  occurredAt: validatePresence
}

const fields: TMortgageLeadDispositionFields<TMortgageLeadDispositionPayload> = {
  failed: MortgageLeadDispositionFailedFields
}

export const useMortgageLeadDispositionButtonForm = (lead: MortgageLead) => {
  const { updateMortgageLeadDisposition } = useMortgageLeadController(lead.id)
  const { updateLeadDisposition } = useLeadContext()
  const { getMortgageLeadStage } = useMortgageLeadStages()
  const currentStage = getMortgageLeadStage(lead.stage as TMortgageLeadStagesSlug)

  const initialPayload: TMortgageLeadDispositionPayload = useMemo(
    () => ({
      disposition: currentStage?.nextStageSlugs?.[0] || lead.stage || '',
      occurredAt: new Date().toISOString()
    }),
    [currentStage?.nextStageSlugs, lead]
  )

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload, removeAttribute },
    setters: { setValidations }
  } = useButtonForm<TMortgageLeadDispositionPayload>({
    initialPayload,
    mutation: updateMortgageLeadDisposition,
    formValidations: defaultValidations
  })

  const setFieldValidations = useCallback(
    (fieldValidations: TFormValidation) => {
      setValidations((validations: TFormValidation) => ({
        disposition: validations.disposition,
        occurredAt: validations.occurredAt,
        ...defaultValidations,
        ...fieldValidations
      }))
    },
    [setValidations]
  )

  const FieldsComponent = useMemo(() => {
    return fields[payload.disposition]
  }, [payload.disposition])

  const setFailedReason = (val: TMortgageLeadFailedReason) => {
    updateLeadDisposition.mutate({
      payload: {
        disposition: 'failed',
        reasonForFail: val
      },
      leadId: lead.id
    })
  }

  return {
    formProps,
    onChangeAttribute,
    payload,
    removeAttribute,
    setFieldValidations,
    FieldsComponent,
    setFailedReason
  }
}
