import { buyersAndSellersColumnsSettings } from './buyersAndSellersColumnsSettings'
import { ccCashOfferTaskColumnsSettings } from './ccCashOfferTaskColumns'
import { ccTradeInTaskColumnsSettings } from './ccTradeInTaskColumns'

export const allLeadsColumnsSettings = {
  type: {
    group: 'leadAttributes',
    name: 'Type',
    value: 'type',
    isReorderable: false,
    columnSize: 'md'
  },
  namePartner: {
    group: 'leadAttributes',
    name: 'Client / Partner',
    value: 'namePartner',
    isDefault: true,
    isReorderable: false,
    columnSize: 'lg'
  },
  contactInfo: {
    group: 'leadAttributes',
    name: 'Contact Info',
    value: 'contactInfo',
    isReorderable: true,
    columnSize: 'md'
  },
  address: {
    group: 'leadAttributes',
    name: 'Address',
    value: 'address',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg'
  },
  createdUpdated: {
    group: 'leadAttributes',
    name: 'Created / Updated',
    value: 'createdUpdated',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'md',
    sort: ['Created', 'Updated']
  },
  stageFurthest: {
    group: 'leadAttributes',
    name: 'Stage / Furthest',
    value: 'stageFurthest',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg',
    sort: ['Stage']
  },
  price: {
    group: 'leadAttributes',
    name: 'Price',
    value: 'price',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    sort: ['Price']
  },
  otherLeads: {
    group: 'leadAttributes',
    name: 'Other Leads',
    value: 'otherLeads',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'md'
  },
  source: {
    group: 'leadAttributes',
    name: 'Source',
    value: 'source',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'md'
  },
  propertyType: {
    group: 'leadAttributes',
    name: 'Property Type',
    value: 'propertyType',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg'
  },
  txTeam: {
    group: 'leadAttributes',
    name: 'Tx Team',
    value: 'txTeam',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'md'
  },
  departingPropertySellerAgent: {
    group: 'leadAttributes',
    name: 'DR Agent',
    value: 'departingPropertySellerAgent',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg'
  },
  incomingPropertyBuyerAgent: {
    group: 'leadAttributes',
    name: 'IR Agent',
    value: 'incomingPropertyBuyerAgent',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg'
  },
  referringAgent: {
    group: 'leadAttributes',
    name: 'Referring Agent',
    value: 'referringAgent',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg'
  },
  tradeInPlus: {
    group: 'leadAttributes',
    name: 'Trade-in+',
    value: 'tradeInPlus',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center',
    sort: ['Trade-In+']
  },
  hoa: {
    group: 'leadAttributes',
    name: 'HOA',
    value: 'hoa',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm'
  },
  solarPanels: {
    group: 'leadAttributes',
    name: 'Solar Panels',
    value: 'solarPanels',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm'
  },
  fundingAmount: {
    group: 'leadAttributes',
    name: 'Funding Amount',
    value: 'fundingAmount',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'md'
  },
  drPurchaseCoeDate: {
    group: 'leadAttributes',
    name: 'DR Purchase Actual COE Date',
    value: 'drPurchaseCoeDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    sort: ['DR Purchase Actual COE Date']
  },
  daysSinceFunding: {
    group: 'leadAttributes',
    name: 'Days Since Funding',
    value: 'daysSinceFunding',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    sort: ['Days Since Funding']
  },
  irPurchaseCoeDate: {
    group: 'leadAttributes',
    name: 'IR Purchase Actual COE Date',
    value: 'irPurchaseCoeDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    sort: ['IR Purchase Actual COE Date']
  },
  drSaleActualCoeDate: {
    group: 'leadAttributes',
    name: 'DR Sale Actual COE Date',
    value: 'drSaleActualCoeDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    sort: ['DR Sale Actual COE Date']
  },
  irSaleActualCoeDate: {
    group: 'leadAttributes',
    name: 'IR Sale Actual COE Date',
    value: 'irSaleActualCoeDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    sort: ['IR Sale Actual COE Date']
  },
  irPurchaseOpenEscrowDate: {
    group: 'leadAttributes',
    name: 'IR Purchase Open Escrow Date',
    value: 'irPurchaseOpenEscrowDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    sort: ['IR Purchase Open Escrow Date']
  },
  irSaleOpenEscrowDate: {
    group: 'leadAttributes',
    name: 'IR Sale Open Escrow Date',
    value: 'irSaleOpenEscrowDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    sort: ['IR Sale Open Escrow Date']
  },
  drSaleOpenEscrowDate: {
    group: 'leadAttributes',
    name: 'DR Sale Open Escrow Date',
    value: 'drSaleOpenEscrowDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    sort: ['DR Sale Open Escrow Date']
  },
  drPurchaseOpenEscrowDate: {
    group: 'leadAttributes',
    name: 'DR Purchase Open Escrow Date',
    value: 'drPurchaseOpenEscrowDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    sort: ['DR Purchase Open Escrow Date']
  },
  fundingDate: {
    group: 'leadAttributes',
    name: 'Funding Date',
    value: 'fundingDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    sort: ['Funding Date']
  },
  listedDate: {
    group: 'leadAttributes',
    name: 'Listed Date',
    value: 'listedDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    sort: ['Listed Date']
  },
  fundingFacility: {
    group: 'leadAttributes',
    name: 'Funding Facility',
    value: 'fundingFacility',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg'
  },
  daysSinceLastStageUpdate: {
    group: 'leadAttributes',
    name: 'Days Since Last Stage Update',
    value: 'daysSinceLastStageUpdate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm'
  },
  daysUntilFunding: {
    group: 'leadAttributes',
    name: 'Days Until Funding',
    value: 'daysUntilFunding',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm'
  },
  daysOwned: {
    group: 'leadAttributes',
    name: 'Days Owned',
    value: 'daysOwned',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm'
  },
  daysToListing: {
    group: 'leadAttributes',
    name: 'Days to Listing',
    value: 'daysToListing',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm'
  },
  daysOnMarket: {
    group: 'leadAttributes',
    name: 'Days on Market',
    value: 'daysOnMarket',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm'
  },
  listingStatus: {
    group: 'leadAttributes',
    name: 'Listing Status',
    value: 'listingStatus',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  introSentAt: {
    group: 'leadAttributes',
    name: 'Intro Sent At',
    value: 'introSentAt',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  capitalMarketsFacility: {
    group: 'leadAttributes',
    name: 'Capital Markets Facility',
    value: 'capitalMarketsFacility',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg'
  },
  strategicRelationshipManager: {
    group: 'teamRoles',
    name: 'Strategic Relationship Manager',
    value: 'strategicRelationshipManager',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  consumerClientManager: {
    group: 'teamRoles',
    name: 'Consumer Client Manager',
    value: 'consumerClientManager',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  salesSpecialist: {
    group: 'teamRoles',
    name: 'Sales Specialist',
    value: 'salesSpecialist',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  contractAdministrator: {
    group: 'teamRoles',
    name: 'Contract Administrator',
    value: 'contractAdministrator',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  transactionSpecialist: {
    group: 'teamRoles',
    name: 'Transaction Specialist',
    value: 'transactionSpecialist',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  listingSpecialist: {
    group: 'teamRoles',
    name: 'Listing Specialist',
    value: 'listingSpecialist',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  loanOfficerTeamLead: {
    group: 'teamRoles',
    name: 'Mortgage Advisor Team Lead',
    value: 'loanOfficerTeamLead',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  clientAdvisor: {
    group: 'teamRoles',
    name: 'Client Advisor',
    value: 'clientAdvisor',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  clientAdvisorAssistant: {
    group: 'teamRoles',
    name: 'Client Advisor Assistant',
    value: 'clientAdvisorAssistant',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  agentsOpps: {
    name: 'Agents / Opps',
    value: 'agentsOpps',
    isOptional: true,
    group: 'leadAttributes',
    isDefault: false,
    isReorderable: true,
    columnSize: 'mlg'
  },
  needsListingReview: {
    group: 'leadAttributes',
    name: 'Reviews',
    value: 'needsListingReview',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  purchaseEscrowStatus: {
    group: 'escrowAttributes',
    name: 'Purchase Escrow Status',
    value: 'purchaseEscrowStatus',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    align: 'center'
  },
  purchaseEscrowOrderOpenedDate: {
    group: 'escrowAttributes',
    name: 'Purchase Escrow Order Opened Date',
    value: 'purchaseEscrowOrderOpenedDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    align: 'center'
  },
  purchaseEscrowExpectedCloseDate: {
    group: 'escrowAttributes',
    name: 'Purchase Escrow Expected Close Date',
    value: 'purchaseEscrowExpectedCloseDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm',
    align: 'center'
  },
  purchaseEscrowNetEquity: {
    group: 'escrowAttributes',
    name: 'Purchase Escrow Net Equity',
    value: 'purchaseEscrowNetEquity',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg',
    align: 'center'
  },
  purchaseEscrowOfficer: {
    group: 'escrowAttributes',
    name: 'Purchase Escrow Officer',
    value: 'purchaseEscrowOfficer',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg',
    align: 'center'
  },
  purchaseEscrowAssistant: {
    group: 'escrowAttributes',
    name: 'Purchase Escrow Assistant',
    value: 'purchaseEscrowAssistant',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg',
    align: 'center'
  },
  purchaseEscrowTitleOfficer: {
    group: 'escrowAttributes',
    name: 'Purchase Escrow Title Officer',
    value: 'purchaseEscrowTitleOfficer',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg',
    align: 'center'
  },
  saleEscrowStatus: {
    group: 'escrowAttributes',
    name: 'Sale Escrow Status',
    value: 'saleEscrowStatus',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg',
    align: 'center'
  },
  saleEscrowOrderOpenedDate: {
    group: 'escrowAttributes',
    name: 'Sale Escrow Order Opened Date',
    value: 'saleEscrowOrderOpenedDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg',
    align: 'center'
  },
  saleEscrowExpectedCloseDate: {
    group: 'escrowAttributes',
    name: 'Sale Escrow Expected Close Date',
    value: 'saleEscrowExpectedCloseDate',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg',
    align: 'center'
  },
  saleEscrowOfficer: {
    group: 'escrowAttributes',
    name: 'Sale Escrow Officer',
    value: 'saleEscrowOfficer',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg',
    align: 'center'
  },
  saleEscrowAssistant: {
    group: 'escrowAttributes',
    name: 'Sale Escrow Assistant',
    value: 'saleEscrowAssistant',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg',
    align: 'center'
  },
  saleEscrowTitleOfficer: {
    group: 'escrowAttributes',
    name: 'Sale Escrow Title Officer',
    value: 'saleEscrowTitleOfficer',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg',
    align: 'center'
  },
  escrowOfficer: {
    group: 'escrowAttributes',
    name: 'Escrow Officer',
    value: 'escrowOfficer',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg'
  },
  settlementAgency: {
    group: 'escrowAttributes',
    name: 'Settlement Agency',
    value: 'settlementAgency',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg'
  },
  purchaseContract: {
    group: 'escrowAttributes',
    name: 'Contract',
    value: 'purchaseContract',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  submissionTime: {
    group: 'escrowAttributes',
    name: 'Submission Time',
    value: 'submissionTime',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'lg'
  },
  escrowOrderNumber: {
    group: 'escrowAttributes',
    name: 'Escrow Order #',
    value: 'escrowOrderNumber',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'sm'
  },
  edit: {
    group: 'escrowAttributes',
    name: '',
    value: 'edit',
    isDefault: true,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xxsm'
  }
} as const

const {
  type,
  namePartner,
  address,
  otherLeads,
  stageFurthest,
  createdUpdated,
  source,
  propertyType,
  contactInfo,
  price,
  txTeam,
  departingPropertySellerAgent,
  incomingPropertyBuyerAgent,
  referringAgent,
  hoa,
  solarPanels,
  fundingAmount,
  drPurchaseCoeDate,
  irPurchaseCoeDate,
  drSaleActualCoeDate,
  irSaleActualCoeDate,
  irPurchaseOpenEscrowDate,
  irSaleOpenEscrowDate,
  drSaleOpenEscrowDate,
  drPurchaseOpenEscrowDate,
  fundingDate,
  listedDate,
  fundingFacility,
  daysSinceLastStageUpdate,
  daysUntilFunding,
  daysSinceFunding,
  daysOwned,
  daysToListing,
  daysOnMarket,
  capitalMarketsFacility,
  strategicRelationshipManager,
  consumerClientManager,
  salesSpecialist,
  contractAdministrator,
  transactionSpecialist,
  listingSpecialist,
  loanOfficerTeamLead,
  clientAdvisor,
  clientAdvisorAssistant,
  listingStatus,
  introSentAt,
  agentsOpps,
  tradeInPlus,
  needsListingReview,
  purchaseEscrowStatus,
  purchaseEscrowOrderOpenedDate,
  purchaseEscrowExpectedCloseDate,
  purchaseEscrowNetEquity,
  purchaseEscrowOfficer,
  purchaseEscrowAssistant,
  purchaseEscrowTitleOfficer,
  saleEscrowStatus,
  saleEscrowOrderOpenedDate,
  saleEscrowExpectedCloseDate,
  saleEscrowOfficer,
  saleEscrowAssistant,
  saleEscrowTitleOfficer,
  escrowOfficer,
  settlementAgency,
  purchaseContract,
  submissionTime,
  escrowOrderNumber,
  edit
} = allLeadsColumnsSettings

/**
 * Leads
 */
export const leadsColumnsSettings = [
  type,
  namePartner,
  address,
  price,
  createdUpdated,
  stageFurthest
] as const

export type TLeadsColumn = typeof leadsColumnsSettings
export type TLeadsColumnValue = (typeof leadsColumnsSettings)[number]['value']

/**
 * Attach Leads
 */
export const attachLeadsColumnsSettings = [
  type,
  namePartner,
  contactInfo,
  address,
  createdUpdated,
  stageFurthest
] as const

export type TAttachLeadsColumn = typeof attachLeadsColumnsSettings
export type TAttachLeadsColumnValue = (typeof attachLeadsColumnsSettings)[number]['value']

/**
 * CCTradeIn Leads
 */
export const ccTradeInLeadsColumnsSettings = [
  namePartner,
  address,
  otherLeads,
  stageFurthest,
  createdUpdated,
  source,
  propertyType,
  txTeam,
  { ...departingPropertySellerAgent, isDefault: true },
  incomingPropertyBuyerAgent,
  referringAgent,
  hoa,
  solarPanels,
  fundingAmount,
  irPurchaseCoeDate,
  drPurchaseOpenEscrowDate,
  drPurchaseCoeDate,
  drSaleOpenEscrowDate,
  drSaleActualCoeDate,
  fundingDate,
  listedDate,
  fundingFacility,
  daysSinceLastStageUpdate,
  daysUntilFunding,
  daysSinceFunding,
  daysOwned,
  daysToListing,
  daysOnMarket,
  clientAdvisor,
  clientAdvisorAssistant,
  consumerClientManager,
  contractAdministrator,
  listingSpecialist,
  loanOfficerTeamLead,
  salesSpecialist,
  strategicRelationshipManager,
  transactionSpecialist,
  capitalMarketsFacility,
  tradeInPlus,
  purchaseEscrowStatus,
  purchaseEscrowOrderOpenedDate,
  purchaseEscrowExpectedCloseDate,
  purchaseEscrowNetEquity,
  purchaseEscrowOfficer,
  purchaseEscrowAssistant,
  purchaseEscrowTitleOfficer,
  saleEscrowStatus,
  saleEscrowOrderOpenedDate,
  saleEscrowExpectedCloseDate,
  saleEscrowOfficer,
  saleEscrowAssistant,
  saleEscrowTitleOfficer,
  ...ccTradeInTaskColumnsSettings
]

export type TCCTradeInLeadsColumn = typeof ccTradeInLeadsColumnsSettings
export type TCCTradeInLeadsColumnValue = (typeof ccTradeInLeadsColumnsSettings)[number]['value']

/**
 * CCHLSimpleSale Leads
 */
export const ccHLSimpleSaleLeadSettings = [
  namePartner,
  address,
  otherLeads,
  stageFurthest,
  createdUpdated,
  source,
  propertyType,
  txTeam,
  { ...departingPropertySellerAgent, isDefault: true },
  incomingPropertyBuyerAgent,
  referringAgent,
  hoa,
  solarPanels,
  drPurchaseOpenEscrowDate,
  drPurchaseCoeDate,
  drSaleOpenEscrowDate,
  drSaleActualCoeDate,
  fundingDate,
  listedDate,
  fundingAmount,
  fundingFacility,
  capitalMarketsFacility,
  daysSinceLastStageUpdate,
  daysUntilFunding,
  daysOwned,
  daysToListing,
  daysOnMarket,
  clientAdvisor,
  clientAdvisorAssistant,
  consumerClientManager,
  contractAdministrator,
  listingSpecialist,
  salesSpecialist,
  strategicRelationshipManager,
  transactionSpecialist,
  purchaseEscrowStatus,
  purchaseEscrowOrderOpenedDate,
  purchaseEscrowExpectedCloseDate,
  purchaseEscrowOfficer,
  purchaseEscrowAssistant,
  purchaseEscrowTitleOfficer,
  saleEscrowStatus,
  saleEscrowOrderOpenedDate,
  saleEscrowExpectedCloseDate,
  saleEscrowOfficer,
  saleEscrowAssistant,
  saleEscrowTitleOfficer
]

export type TCCHLSimpleSaleLeadLeadsColumn = typeof ccHLSimpleSaleLeadSettings
export type TCCHLSimpleSaleLeadLeadsColumnValue =
  (typeof ccHLSimpleSaleLeadSettings)[number]['value']

/**
 * CCCashOffer Leads
 */
export const ccCashOfferLeadSettings = [
  namePartner,
  address,
  otherLeads,
  stageFurthest,
  createdUpdated,
  source,
  propertyType,
  txTeam,
  { ...incomingPropertyBuyerAgent, isDefault: true },
  referringAgent,
  clientAdvisor,
  clientAdvisorAssistant,
  consumerClientManager,
  contractAdministrator,
  listingSpecialist,
  loanOfficerTeamLead,
  salesSpecialist,
  strategicRelationshipManager,
  transactionSpecialist,
  daysSinceLastStageUpdate,
  hoa,
  daysUntilFunding,
  solarPanels,
  fundingAmount,
  irPurchaseOpenEscrowDate,
  irPurchaseCoeDate,
  irSaleOpenEscrowDate,
  irSaleActualCoeDate,
  fundingDate,
  fundingFacility,
  capitalMarketsFacility,
  purchaseEscrowStatus,
  purchaseEscrowOrderOpenedDate,
  purchaseEscrowExpectedCloseDate,
  purchaseEscrowOfficer,
  purchaseEscrowAssistant,
  purchaseEscrowTitleOfficer,
  saleEscrowStatus,
  saleEscrowOrderOpenedDate,
  saleEscrowExpectedCloseDate,
  saleEscrowOfficer,
  saleEscrowAssistant,
  saleEscrowTitleOfficer,
  ...ccCashOfferTaskColumnsSettings
]
export type TCCCashOfferLeadLeadsColumn = typeof ccCashOfferLeadSettings
export type TCCCashOfferLeadLeadsColumnValue = (typeof ccCashOfferLeadSettings)[number]['value']

/**
 * Mortgage Leads
 */
export const mortgageLeadSettings = [
  namePartner,
  address,
  createdUpdated,
  otherLeads,
  propertyType,
  source,
  stageFurthest,
  txTeam,
  referringAgent,
  clientAdvisor,
  clientAdvisorAssistant,
  loanOfficerTeamLead
]
export type TMortgageLeadLeadsColumn = typeof mortgageLeadSettings
export type TMortgageLeadLeadsColumnValue = (typeof mortgageLeadSettings)[number]['value']

/**
 * Buyer and Seller Leads
 */
export const BUYER_AND_SELLER_LEAD_SETTINGS = [
  namePartner,
  address,
  createdUpdated,
  otherLeads,
  stageFurthest,
  price,
  listingStatus,
  introSentAt,
  agentsOpps,
  needsListingReview
]

export const BUYER_AND_SELLER_LEAD_WITH_PENDING_ACTIONS_SETTINGS = [
  namePartner,
  address,
  createdUpdated,
  otherLeads,
  stageFurthest,
  price,
  listingStatus,
  introSentAt,
  agentsOpps,
  needsListingReview,
  ...buyersAndSellersColumnsSettings
]

export type BuyerAndSellerLeadLeadsColumn = typeof BUYER_AND_SELLER_LEAD_SETTINGS
export type BuyerAndSellerLeadsColumnValue =
  (typeof BUYER_AND_SELLER_LEAD_SETTINGS)[number]['value']
export type BuyerAndSellerLeadLeadsColumnWithPendingActions =
  typeof BUYER_AND_SELLER_LEAD_WITH_PENDING_ACTIONS_SETTINGS
export type BuyerAndSellerLeadsColumnWithPendingActionsValue =
  (typeof BUYER_AND_SELLER_LEAD_WITH_PENDING_ACTIONS_SETTINGS)[number]['value']

/**
 * Escrow Leads
 */
export const ESCROW_LEAD_TYPES_COLUMNS_SETTINGS = [
  edit,
  address,
  purchaseContract,
  source,
  submissionTime,
  escrowOrderNumber,
  settlementAgency,
  escrowOfficer,
  stageFurthest
]

/**
 * All Leads
 */
export const ALL_LEAD_TYPES_COLUMNS_SETTINGS = [
  namePartner,
  address,
  otherLeads,
  stageFurthest,
  createdUpdated,
  source,
  referringAgent,
  price
]

export type TAllLeadsColumn = typeof ALL_LEAD_TYPES_COLUMNS_SETTINGS
export type TAllLeadsColumnValue = (typeof ALL_LEAD_TYPES_COLUMNS_SETTINGS)[number]['value']
