import { css } from '@foundation/themes/styles'
import type { Theming } from '@foundation/themes/styles'

/**
 * SOLID
 */

export const solidColorModifier = {
  danger: (theme: Theming) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.danger5};
    &:hover {
      background-color: ${theme.colors.danger6};
    }
  `,
  neutralLight: (theme: Theming) => css`
    color: ${theme.colors.primary10};
    background-color: ${theme.colors.neutral3};
    &:hover {
      background-color: ${theme.colors.neutral4};
    }
  `,
  primary: (theme: Theming) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary5};
    &:hover {
      background-color: ${theme.colors.primary6};
    }
  `,
  success: (theme: Theming) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.success5};
    &:hover {
      background-color: ${theme.colors.success6};
    }
  `
}

export const solidColorDisabledModifier = (theme: Theming) => css`
  &:disabled {
    color: ${theme.colors.neutral8};
    background-color: ${theme.colors.neutral1};
    &:hover {
      background-color: ${theme.colors.neutral1};
    }
  }
`

/**
 * OUTLINE
 */

export const outlineColorModifier = {
  danger: (theme: Theming) => css`
    color: ${theme.colors.danger9};
    border: 1px solid ${theme.colors.danger5};
    &:hover {
      background-color: ${theme.colors.danger2};
    }
  `,
  neutralLight: (theme: Theming) => css`
    color: ${theme.colors.primary10};
    border: 1px solid ${theme.colors.neutral5};
    &:hover {
      background-color: ${theme.colors.neutral2};
    }
  `,
  primary: (theme: Theming) => css`
    color: ${theme.colors.primary9};
    border: 1px solid ${theme.colors.primary5};
    &:hover {
      background-color: ${theme.colors.primary2};
    }
  `,
  success: (theme: Theming) => css`
    color: ${theme.colors.success9};
    border: 1px solid ${theme.colors.success5};
    &:hover {
      background-color: ${theme.colors.success2};
    }
  `
}

export const outlineColorDisabledModifier = (theme: Theming) => css`
  &:disabled {
    color: ${theme.colors.neutral8};
    border: 1px solid ${theme.colors.neutral5};
    &:hover {
      background-color: transparent;
    }
  }
`

/**
 * GHOST
 */

export const ghostColorModifier = {
  danger: (theme: Theming) => css`
    color: ${theme.colors.danger9};
    &:hover {
      background-color: ${theme.colors.danger2};
    }
  `,
  neutralLight: (theme: Theming) => css`
    color: ${theme.colors.primary10};
    &:hover {
      background-color: ${theme.colors.neutral2};
    }
  `,
  primary: (theme: Theming) => css`
    color: ${theme.colors.primary9};
    &:hover {
      background-color: ${theme.colors.primary2};
    }
  `,
  success: (theme: Theming) => css`
    color: ${theme.colors.success9};
    &:hover {
      background-color: ${theme.colors.success2};
    }
  `
}

export const ghostColorDisabledModifier = (theme: Theming) => css`
  &:disabled {
    color: ${theme.colors.neutral8};
  }
`
