// @ts-strict
import { CashOfferLead } from 'models'
import { FileAttachmentResponse } from 'schema'

export class FileAttachment {
  downloadUrl!: string
  id!: string
  createdAt?: string
  updatedAt?: string
  subAttachable?: CashOfferLead
  category?: string
  file?: {
    category: string
    name: string
  }

  constructor(attributes: FileAttachment) {
    Object.assign(this, attributes)
  }

  static create(response: Potential<FileAttachmentResponse>): Potential<FileAttachment> {
    if (!response) {
      return
    }
    const subAttachable = CashOfferLead.create(response?.subAttachable)

    return new FileAttachment({ ...response, subAttachable })
  }
}
