import { Contents } from 'components'
import { UseMutationResult } from 'hooks'
import { CCTradeInLead } from 'models'
import {
  DRAgentInformationSection,
  DRExpressApprovalSection,
  DRPropertyAssessmentSection,
  DRPropertyInformationSection,
  DRPropertyQualificationSection,
  DRPropertyValuationSection,
  DRPurchaseTransaction,
  DRSaleTransactionSection,
  IRPropertyDetailsSection,
  LenderInformationSection,
  TradeInLeadDetailsSection
} from './CCTradeIn'

export type CCTradeInLeadDetailsProps = {
  lead: CCTradeInLead
  runPropertyQualification: UseMutationResult<unknown, unknown, { id: string }>
  updateLead: (payload: Record<string, string>) => void
  updateLenderLead: UseMutationResult
  updateOrder: (payload: Record<string, string>) => void
  updateTradeInLead: UseMutationResult
}

export const CCTradeInLeadDetails = ({
  lead,
  runPropertyQualification,
  updateLead,
  updateOrder,
  updateTradeInLead,
  updateLenderLead
}: CCTradeInLeadDetailsProps) => {
  return (
    <Contents>
      <TradeInLeadDetailsSection
        lead={lead}
        updateLead={updateLead}
        updateTradeInLead={updateTradeInLead}
        updateOrder={updateOrder}
      />
      <DRExpressApprovalSection lead={lead} />

      <DRAgentInformationSection lead={lead} updateTradeInLead={updateTradeInLead} />
      <LenderInformationSection lead={lead} updateLenderLead={updateLenderLead} />
      <DRPropertyInformationSection lead={lead} updateTradeInLead={updateTradeInLead} />
      <DRPropertyQualificationSection
        lead={lead}
        updateTradeInLead={updateTradeInLead}
        runPropertyQualification={runPropertyQualification}
      />
      <DRPropertyValuationSection lead={lead} updateTradeInLead={updateTradeInLead} />
      <DRPropertyAssessmentSection lead={lead} updateTradeInLead={updateTradeInLead} />
      <DRPurchaseTransaction
        lead={lead}
        updateLead={updateLead}
        updateTradeInLead={updateTradeInLead}
      />
      <DRSaleTransactionSection
        lead={lead}
        updateTradeInLead={updateTradeInLead}
        updateLead={updateLead}
      />
      <IRPropertyDetailsSection lead={lead} updateTradeInLead={updateTradeInLead} />
    </Contents>
  )
}
