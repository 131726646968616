import { LeadGroupableDefinitionType } from 'models'
import { LeadGroupableDefinitionAttributes } from 'schema'
import { getLeadGroupableDefinitionUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export const putLeadGroupableDefinition = async (
  groupableDefinitionType: LeadGroupableDefinitionType,
  groupableDefinitionId: string,
  payload: Partial<LeadGroupableDefinitionAttributes>
) => {
  return await putRequest(
    getLeadGroupableDefinitionUrl({
      action: `${groupableDefinitionType}/${groupableDefinitionId}`
    }),
    snakeCaseKeys(payload)
  )
}
