import { CCBBYSLead } from 'models'
import { stringifyValue } from 'utils'
import { CCBBYSLeadDetailsProps } from '../../CCBBYSLeadDetails'

export const getLeadInputProps = (
  lead: CCBBYSLead,
  updateLead: CCBBYSLeadDetailsProps['updateLead']
) => {
  const handleUpdateLead = (key: string, val: string) => {
    updateLead({
      [key]: val
    })
  }

  const getProps = (key: string) => {
    const value = lead ? stringifyValue(lead[key]) : ''

    return {
      value,
      onBlur: val => {
        handleUpdateLead(key, val)
      }
    }
  }

  return {
    getProps
  }
}
