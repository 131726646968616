// @ts-strict
import { EmptyTableCell, TableRow as Tr } from 'components'
import { useListContext } from 'contexts'
import { Agent } from 'models'
import { cx } from 'utils'
import {
  AgentClosedReferralsCell,
  AgentConnectedReferralsCell,
  AgentEnrollmentDateCell,
  AgentEscrowClosesCell,
  AgentEscrowOpensCell,
  AgentFailedReferralsCell,
  AgentHomesActivationDateCell,
  AgentHomesApprovalsCell,
  AgentHomesClosesCell,
  AgentHomesEnrollmentDateCell,
  AgentHomesLeadsCell,
  AgentIntroducedReferralsCell,
  AgentLastActivityAtCell,
  AgentLastHomesLeadDateCell,
  AgentLastReferralDateCell,
  AgentLastSignedInAtCell,
  AgentLastTouchedAtCell,
  AgentLeftVmReferralsCell,
  AgentListingReferralsCell,
  AgentMeetingScheduledReferralsCell,
  AgentMetInPersonReferralsCell,
  AgentPitchStatusCell,
  AgentPreferredEscrowOfficerCell,
  AgentProgramsCell,
  AgentsListAgentCell,
  AgentTeamNameCell,
  AgentToHlCashOfferApprovalsCompletedCell,
  AgentToHlCashOfferClosesCell,
  AgentToHlCashOfferLeadsCell,
  AgentToHlTradeInApprovalsCompletedCell,
  AgentToHlTradeInClosesCell,
  AgentToHlTradeInLeadsCell,
  AgentToHomelightSimpleSaleApprovalsCompletedCell,
  AgentToHomelightSimpleSaleClosesCell,
  AgentToHomelightSimpleSaleLeadsCell
} from './AgentsTableCells'

export const columnMapping = {
  name: AgentsListAgentCell,
  programs: AgentProgramsCell,
  pitchStatus: AgentPitchStatusCell,
  lastUpdate: AgentLastActivityAtCell,
  lastTouch: AgentLastTouchedAtCell,
  lastLogin: AgentLastSignedInAtCell,
  teamName: AgentTeamNameCell,
  preferredEscrowOfficer: AgentPreferredEscrowOfficerCell,
  introducedReferrals: AgentIntroducedReferralsCell,
  closedReferrals: AgentClosedReferralsCell,
  agentLeftVmReferrals: AgentLeftVmReferralsCell,
  connectedReferrals: AgentConnectedReferralsCell,
  meetingScheduledReferrals: AgentMeetingScheduledReferralsCell,
  metInPersonReferrals: AgentMetInPersonReferralsCell,
  listingReferrals: AgentListingReferralsCell,
  failedReferrals: AgentFailedReferralsCell,
  escrowOpens: AgentEscrowOpensCell,
  escrowCloses: AgentEscrowClosesCell,
  homesLeads: AgentHomesLeadsCell,
  homesApprovals: AgentHomesApprovalsCell,
  homesCloses: AgentHomesClosesCell,
  agentToHlTradeInLeads: AgentToHlTradeInLeadsCell,
  agentToHlCashOfferLeads: AgentToHlCashOfferLeadsCell,
  agentToHomelightSimpleSaleLeads: AgentToHomelightSimpleSaleLeadsCell,
  agentToHlTradeInApprovalsCompleted: AgentToHlTradeInApprovalsCompletedCell,
  agentToHlCashOfferApprovalsCompleted: AgentToHlCashOfferApprovalsCompletedCell,
  agentToHomelightSimpleSaleApprovalsCompleted: AgentToHomelightSimpleSaleApprovalsCompletedCell,
  agentToHlCashOfferCloses: AgentToHlCashOfferClosesCell,
  agentToHomelightSimpleSaleCloses: AgentToHomelightSimpleSaleClosesCell,
  agentToHlTradeInCloses: AgentToHlTradeInClosesCell,
  homesEnrollmentDate: AgentHomesEnrollmentDateCell,
  referralsEnrollmentDate: AgentEnrollmentDateCell,
  homesActivationDate: AgentHomesActivationDateCell,
  lastHomesLeadDate: AgentLastHomesLeadDateCell,
  lastReferralLeadDate: AgentLastReferralDateCell
}

type TProps = {
  agent: Agent
}

export const AgentsTableRow = ({ agent }: TProps) => {
  const { highlightRows, visibleColumns, getColumnAlignment } = useListContext()
  const rowClassName = highlightRows?.includes(agent.id) && 'highlight'

  return (
    <Tr className={cx(rowClassName)} data-testid={`agent-id-${agent.id}`}>
      {visibleColumns.map(visibleColumn => {
        const Cell = columnMapping[visibleColumn]
        const testId = `${visibleColumn}Cell`
        const align = getColumnAlignment(visibleColumn)

        return Cell ? (
          <Cell
            value={agent}
            column={visibleColumn}
            key={visibleColumn}
            testId={testId}
            align={align}
          />
        ) : (
          <EmptyTableCell key={visibleColumn} align={align} />
        )
      })}
    </Tr>
  )
}
