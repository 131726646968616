import { useEffect } from 'react'
import { Box, Spinner } from '@foundation/components'
import { Contents } from 'components/Contents'
import { AgentBilling, AgentReferrals, PaymentInformation } from 'content/Agent/AgentBilling'
import {
  AgentPayPerLeadReferralsProvider,
  AgentPayPerLeadSettingsProvider,
  useAgentContext
} from 'contexts'
import {
  useAgentPayPerLeadReferralsController,
  useAgentPayPerLeadSettingsController
} from 'controllers'
import { useDocumentTitle } from 'hooks'
import { Agent, AgentPayPerLeadReferral } from 'models'
import styles from './AgentBillingTab.module.scss'

export const AgentBillingTab = () => {
  const { agent } = useAgentContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${agent.fullName} - Agent`), [agent, setTitle])

  const { currentPayPerLeadReferralsQuery, pastPayPerLeadReferralsQuery } =
    useAgentPayPerLeadReferralsController(agent.id)
  const { agentPayPerLeadSettingsQuery } = useAgentPayPerLeadSettingsController(agent.id)
  const { data: agentPplData }: { data: Agent } = agentPayPerLeadSettingsQuery
  const { data: currentReferrals }: { data: AgentPayPerLeadReferral[] } =
    currentPayPerLeadReferralsQuery
  const { data: pastReferrals }: { data: AgentPayPerLeadReferral[] } = pastPayPerLeadReferralsQuery

  if (agentPayPerLeadSettingsQuery.isLoading) {
    return (
      <Box margin="$3">
        <Spinner size="xs" label="Loading Pay-Per-Lead Information..." />
      </Box>
    )
  }
  return (
    <Contents className={styles.ppl_container}>
      <AgentPayPerLeadSettingsProvider agentId={agent?.id}>
        <Contents className={styles.billing_details}>
          <AgentBilling agent={agentPplData} />
          <PaymentInformation agent={agentPplData} />
        </Contents>
      </AgentPayPerLeadSettingsProvider>
      <AgentPayPerLeadReferralsProvider agentId={agent?.id}>
        <Contents>
          <AgentReferrals
            agent={agent}
            currentReferrals={currentReferrals}
            pastReferrals={pastReferrals}
          />
        </Contents>
      </AgentPayPerLeadReferralsProvider>
    </Contents>
  )
}
