// @ts-strict

const formatTimeText = (minutes: string = '00', seconds: string = '00') => {
  return `${minutes}:${seconds}`
}

export const calculateTimeBySeconds = (secs: number) => {
  const validSecs = +secs

  if (isNaN(validSecs)) {
    return formatTimeText()
  }

  const minutes = Math.floor(validSecs / 60)
  const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
  const seconds = Math.floor(validSecs % 60)
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
  return formatTimeText(returnedMinutes, returnedSeconds)
}
