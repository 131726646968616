// @ts-strict
import { Block, LoadingText } from 'components'
import { AgentsAvatars } from 'content/Agents'
import { LeadBadge } from 'content/Lead'
import { useLeadController } from 'hooks'
import {
  Agent,
  AgentProviderLead,
  isAgentMatchingLead,
  isCCTradeInLead,
  isEscrowLead,
  Lead
} from 'models'
import { formatDate, formatOrDash, formatPrice, formatUserType, titleize } from 'utils'
import styles from './OrderLeadNavigation.module.scss'
import { OrderLeadNavigationLink } from './'

type Props = {
  lead: Lead
}

export const OrderLeadNavigationItem = ({ lead }: Props) => {
  let activeAgents: Agent[] = []
  const { agentProviderLeadsQuery } = useLeadController(lead?.id)

  if (isAgentMatchingLead(lead)) {
    const activeAgentProviderLeads = AgentProviderLead.getActive(agentProviderLeadsQuery.data)
    activeAgents = activeAgentProviderLeads?.map(item => item.agentLead?.agent) as Agent[]
  }

  const leadTypeName =
    isCCTradeInLead(lead) && lead?.tradeInProviderLead?.tradeInLead?.simpleSale
      ? 'HLSS'
      : formatUserType(lead.userType!)
  const escrowSuffix = isEscrowLead(lead)
    ? `(${formatOrDash(lead?.secondaryUserType, titleize)})`
    : ''

  const originalCreatedAt = lead.originalCreatedAt || lead.createdAt

  const actions = (
    <div className={styles.actions}>
      <div className={styles.actionsContent}>{formatDate(originalCreatedAt, 'short')}</div>
      {agentProviderLeadsQuery.isLoading ? (
        <LoadingText text="Loading Agents" />
      ) : (
        <AgentsAvatars agents={activeAgents} disableTierBadge={true} />
      )}
    </div>
  )

  return (
    <OrderLeadNavigationLink to={lead.getPathname()}>
      <Block
        data-testid={`orderLeadNavigationItem${lead.id}`}
        title={`${leadTypeName} ${escrowSuffix} ${formatPrice(lead.price)}`}
        subtitle={lead.stageAndFurthestStage}
        image={<LeadBadge lead={lead} />}
        actions={actions}
      />
    </OrderLeadNavigationLink>
  )
}
