// @ts-strict

import { ButtonForm, EmployeesAutoComplete, FormField, Select } from 'components'
import { TransactionTeam } from 'models'
import { useSubTeamAddEmployeeButtonForm } from './useSubTeamAddEmployeeButtonForm'

type SubTeamAddEmployeeButtonFormProps = {
  team: TransactionTeam
}

export const SubTeamAddEmployeeButtonForm = ({ team }: SubTeamAddEmployeeButtonFormProps) => {
  const { formProps, payload, onChangeAttribute, teamRolesOptions } =
    useSubTeamAddEmployeeButtonForm(team)

  const escrowOfficersTeamMembers = team.teamMemberships
    ? team.teamMemberships
        ?.filter(member => member.role === 'escrow_officer')
        .map(member => {
          return { name: member.user.name, id: member.id }
        })
    : []

  const subTeamMemberRoles = teamRolesOptions.filter(role => role.slug === 'escrow_assistant')

  return (
    <ButtonForm title="Add Sub Team Member" buttonText="Add Sub Team Member" {...formProps}>
      <FormField title="Team">{team.name}</FormField>
      <FormField title="Team Member" htmlFor="team-member" required>
        <Select
          id="team-member"
          onChange={teamMembershipId => onChangeAttribute('teamMembershipId', teamMembershipId)}
          name="teamMembershipId"
          options={escrowOfficersTeamMembers.map(({ name, id }) => ({
            name,
            value: id
          }))}
          value={payload.teamMembershipId}
          data-testid="team-membership-id-field"
          addBlank
        />
      </FormField>
      <FormField title="HomeLight Employee" htmlFor="employee" required>
        <EmployeesAutoComplete
          id="employee"
          value={payload.userName}
          onSelect={user => {
            onChangeAttribute('userId', user.id)
            onChangeAttribute('userName', user.title)
          }}
          onClear={() => {
            onChangeAttribute('userId', undefined)
            onChangeAttribute('userName', undefined)
          }}
          autoFocus
        />
      </FormField>
      <FormField title="Role" htmlFor="role" required>
        <Select
          id="role"
          onChange={role => onChangeAttribute('role', role)}
          name="role"
          options={subTeamMemberRoles}
          value={payload.role}
          data-testid="role-field"
          addBlank
        />
      </FormField>
    </ButtonForm>
  )
}
