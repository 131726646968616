import { Spinner } from 'components'
import { LenderCard } from 'content/Lender/LenderCard'
import { useLendersController } from 'controllers'
import { Lead, Lender } from 'models'
import styles from './LenderStack.module.scss'

export const LenderStack = ({ lead }: { lead: Lead }) => {
  const { data: lenders, isLoading } = useLendersController(lead.id, {
    states: lead.stateCode,
    status: 'active'
  })

  const stageMapper = {
    connected: 1,
    introduced: 2,
    undefined: 100
  }

  const lenderMapper = {
    "Agent's Lender": 1,
    'Answer Home Lending': 2,
    'American Pacific Mortgage': 3,
    'Lower.com': 4,
    Sage: 5
  }

  if (lenders && lenders.length) {
    return (
      <div className={styles.lendersWrapper}>
        {lenders
          .sort((a: Lender, b: Lender) => {
            return (lenderMapper[a.name] || 100) - (lenderMapper[b.name] || 100)
          })
          .sort((a: Lender, b: Lender) => {
            return stageMapper[a.providerLead?.stage] - stageMapper[b.providerLead?.stage]
          })
          .map((lender: Lender) => {
            return <LenderCard lender={lender} key={lender.id} />
          })}
      </div>
    )
  } else if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner />
        <span className={styles.spinnerLabel}>Lenders</span>
      </div>
    )
  }
  return <div>None Found</div>
}
