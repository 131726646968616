// @ts-strict
import { UserIntegrationResponse } from 'schema'

export class UserIntegration {
  id?: string
  name?: string
  value?: string

  constructor(attributes: UserIntegration) {
    Object.assign(this, attributes)
  }

  static create(response: Potential<UserIntegrationResponse>): Potential<UserIntegration> {
    if (!response) {
      return
    }

    return new UserIntegration({ ...response })
  }
}
