import { Button } from '@foundation/components'
import { useSendLeadToDialerController } from 'controllers'
import { Lead } from 'models'
import { DialerButtonWrapper } from './styles'

type SendLeadToDialerProps = {
  lead: Lead
}

export const SendLeadToDialer = ({ lead }: SendLeadToDialerProps) => {
  const { sendLeadToDialer } = useSendLeadToDialerController(lead.id)

  return (
    <DialerButtonWrapper>
      <Button
        size="xs"
        onClick={() => sendLeadToDialer.mutate()}
        isLoading={sendLeadToDialer.isLoading}
        isDisabled={sendLeadToDialer.isSuccess}
        color="warning"
        variant="solid"
      >
        Send to Dialer
      </Button>
    </DialerButtonWrapper>
  )
}
