import { Lead } from 'models'
import { getHapiRequestParams, LeadSchema } from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams, TLeadsSearchParams } from './getSearchParams'

const leadResponseShape: LeadSchema = {
  lead: [
    'partnerName',
    'callReview',
    // 'closeDate', // not in lead schema
    'createdAt',
    'email',
    'emailAlt',
    // 'escrowAttachment', // not in lead schema
    'fullAddress',
    'furthestStage',
    'humanTimeline',
    'lastCallDate',
    'lastStageUpdate',
    'latitude',
    'longitude',
    'name',
    'notesToAgents',
    'partnerName',
    { phoneNumbers: ['number', 'countryCode'] },
    'phoneAlt',
    'price',
    'propertyType',
    'salesNotes',
    'source',
    'sourcePageType',
    'stage',
    'userType',
    {
      'referringOwner:agent': ['firstName', 'lastName']
    }
  ]
}

export const fetchLeads = async (settings: TLeadsSearchParams) => {
  const url = getLeadsUrl({
    searchParams: {
      ...getSearchParams(settings),
      ...getHapiRequestParams(leadResponseShape)
    }
  })
  const response = await getRequest(url)
  const leads = await deserializeAsync(response.data, {
    transform: Lead.create
  })
  return {
    leads,
    count: response.data.meta.total_leads as number
  }
}
