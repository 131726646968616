// @ts-strict

import { SearchInputParams, ViewsMenu } from 'components'
import { Page } from 'components/Page'
import { AgentCreateButtonForm } from 'content/Agent/AgentForm'
import { AgentsDatatableList } from 'content/Agents'
import { AgentsListFilters } from 'content/Agents'
import {
  AgentsProvider,
  ListProvider,
  QueryStringParamsProvider,
  useCurrentUserContext
} from 'contexts'
import { useDocumentTitle, useFeatureFlags } from 'hooks'
import { agentsListColumns, useAgentsSearchFilter } from 'lookups'
import style from './AgentsPage.module.scss'

export const AgentsPage = () => {
  useDocumentTitle('Agents')
  const { currentUser, aeId } = useCurrentUserContext()
  const agentsSearchFilter = useAgentsSearchFilter()
  const { salesAppAgentMetrics } = useFeatureFlags(['sales-app-agent-metrics'])

  if (!currentUser) {
    return null
  }

  const filters = (
    <>
      <AgentsListFilters />
      {salesAppAgentMetrics.enabled && <ViewsMenu columnsClassname={style.fixedHeight} />}
    </>
  )

  return (
    <AgentsProvider aeId={aeId}>
      <QueryStringParamsProvider initialParams={currentUser?.agentsDefaultFilters}>
        <ListProvider filters={agentsSearchFilter} columns={agentsListColumns}>
          <Page>
            <AgentsDatatableList
              filters={filters}
              actions={<AgentCreateButtonForm />}
              search={
                <SearchInputParams placeholder="Search by name, office, license, phone or email" />
              }
            />
          </Page>
        </ListProvider>
      </QueryStringParamsProvider>
    </AgentsProvider>
  )
}
