import { EscrowLead } from 'models'
import { getSubmitInstantOrderUrl } from 'services/getUrl'
import { postRequest } from 'utils'
import { uploadFiles } from './uploadFiles'

export type TEscrowOrderInstantOpenPostPayload = {
  data: {
    attributes: {
      buyer: boolean
      cooperating_agent_email: string
      files: File[]
      lead_attributes: {
        address: string
        email?: string
        first_name: string
        last_name: string
        name: string
        phone?: string
        referring_agent_id?: string
        source: string
        source_form: string
        source_page_type: string
      }
      order_type: string
      source_of_funds: string
      started_as_pre_escrow: boolean
    }
    type: 'lead'
  }
  orderType: 'instant_open'
  parent_lead_id?: string
}

export const postEscrowOrderInstantOpen = async (
  payload: TEscrowOrderInstantOpenPostPayload
): Promise<Potential<EscrowLead>> => {
  const documents = await uploadFiles(payload.data.attributes.files)

  const params = {
    ...payload,
    data: {
      ...payload.data,
      attributes: {
        ...payload.data.attributes,
        document_uuids: documents.map((doc: { id: string }) => doc.id),
        files: null
      }
    }
  }

  const {
    data: { data: lead }
  } = await postRequest(getSubmitInstantOrderUrl(), params)

  return EscrowLead.create(lead)
}
