import { useEffect, useMemo } from 'react'
import type { UseMutationResult } from 'react-query'
import { useButtonForm } from 'components'
import type { LeadGroupableDefinition } from 'models'

type PutLeadGroupableDefinitionPayload = any

// This hook is used to manage the form for the LeadGroupableDefinition
// Each definition contains multiple statements, each statement has a column, action and values
// The form is used to add, remove and update the statements
// When a statement is updated, there is a callback to the parent component to update the definition
export const useLeadGroupableDefinitionForm = (
  mutation: UseMutationResult<any, any, any, any>,
  leadGroupableDefinition: LeadGroupableDefinition,
  registerUpdateDefinition: (definition: () => void) => void,
  unregisterUpdateDefinition: () => void
) => {
  const initialPayload = useMemo(
    () => ({
      definition: leadGroupableDefinition.definition?.map(definition => ({
        column: definition?.column,
        action: definition?.action,
        values: Array.isArray(definition?.values)
          ? definition?.values.join('\n')
          : definition?.values || ''
      })) ?? [{ column: '', action: '', values: '' }]
    }),
    [leadGroupableDefinition]
  )

  const transformPayload = (
    payload: PutLeadGroupableDefinitionPayload
  ): PutLeadGroupableDefinitionPayload => {
    return {
      definition: payload.definition.map((definition: any) => ({
        column: definition?.column,
        action: definition?.action,
        values: definition?.values?.split('\n')
      }))
    }
  }

  const {
    fieldsProps: { payload, onChangeAttribute },
    formProps: { onSubmit }
  } = useButtonForm<PutLeadGroupableDefinitionPayload>({
    initialPayload,
    mutation,
    transformPayload
  })

  const setAttribute = (index: number) => (attribute: string, value: string) => {
    const newPayload = JSON.parse(JSON.stringify(payload.definition))
    if (newPayload[index][attribute] !== value) {
      newPayload[index][attribute] = value
      onChangeAttribute('definition', newPayload)
    }
  }

  const addStatement = () => {
    const newPayload = payload.definition ? JSON.parse(JSON.stringify(payload.definition)) : []
    newPayload.push({
      column: '',
      action: '',
      values: ''
    })
    onChangeAttribute('definition', newPayload)
  }

  const removeStatement = (index: number) => () => {
    const newPayload = payload.definition ? JSON.parse(JSON.stringify(payload.definition)) : []
    newPayload.splice(index, 1)
    onChangeAttribute('definition', newPayload)
  }

  useEffect(() => {
    const hasChanges = JSON.stringify(initialPayload) !== JSON.stringify(payload)

    if (hasChanges) {
      registerUpdateDefinition(() => onSubmit())
    } else {
      unregisterUpdateDefinition()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, initialPayload])

  return { setAttribute, addStatement, removeStatement, payload }
}
