import { AddLeadTeamMemberVariables } from 'contexts/types'
import { getLeadTeamMembershipsUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export const postLeadTeamMember = (
  leadId: Potential<string>,
  { userId, role }: AddLeadTeamMemberVariables
): Promise<any> => {
  return postRequest(getLeadTeamMembershipsUrl(), {
    lead_id: leadId,
    user_id: userId,
    role
  })
}
