import { SearchParams } from '@types'
import { TLeadUserTypeServerSlug } from 'lookups'
import { removeAttributesWithNullValues } from 'utils'
import { getSort } from '../../utils'

const sortParam = {
  Created: 'created_at',
  Name: 'name.keyword',
  Score: '_score'
}

export type TLeadsSearchParams = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'searchTerm' | 'sortDir' | 'sortBy'
> & {
  agentAndTeam?: string
  agentId?: string
  hlcsEligible?: boolean
  scope?: string
  similarEmails?: Potential<string>[]
  similarNames?: Potential<string>[]
  similarOrder?: string
  similarPhoneNumbers?: Potential<string>[]
  userType?: TLeadUserTypeServerSlug
}

const getFilters = ({
  agentAndTeam,
  agentId,
  scope,
  searchTerm,
  similarEmails,
  similarNames,
  similarOrder,
  similarPhoneNumbers,
  userType,
  hlcsEligible
}: TLeadsSearchParams) => {
  const filters = {}

  if (searchTerm) {
    filters['filter[_search]'] = searchTerm
  }

  if (similarNames) {
    filters['filter[_similar[names]]'] = similarNames
  }

  if (similarPhoneNumbers) {
    filters['filter[_similar[phone_numbers]]'] = similarPhoneNumbers
  }

  if (similarEmails) {
    filters['filter[_similar[emails]]'] = similarEmails
  }

  if (agentId) {
    filters['filter[agent_lead_agents.id]'] = agentId
  }

  if (agentAndTeam) {
    filters['filter[_agent_and_team]'] = agentAndTeam
  }

  if (similarOrder) {
    filters['filter[_similar_order]'] = similarOrder
  }
  if (userType) {
    filters['filter[user_type]'] = userType
  }

  if (hlcsEligible) {
    filters['filter[_eligible_for_escrow_order_opening]'] = true
  }

  switch (scope) {
    case 'Mortgage':
      filters['filter[user_type]'] = 'mortgage'
      break
    case 'Escrow':
      filters['filter[user_type]'] = 'escrow'
      break
    case 'Agent Matching':
      filters['filter[user_type]'] = ['seller', 'buyer']
      break
    case 'Cash Close':
      filters['filter[user_type]'] = ['cc_trade_in', 'cc_cash_offer']
      break
    default:
    // no filter applied
  }

  return filters
}

export const getSearchParams = ({
  page,
  perPage,
  sortBy,
  sortDir,
  ...filters
}: TLeadsSearchParams) => {
  return removeAttributesWithNullValues({
    'page[number]': page ? page : undefined,
    'page[size]': perPage ? perPage : undefined,
    sort: getSort(sortParam, sortDir, sortBy),
    ...getFilters(filters)
  })
}
