// @ts-strict
import { ReactNode } from 'react'

type OptionAttributes = {
  defaultValue?: string
  description?: string
  disabled?: boolean
  hidden?: boolean
  icon?: ReactNode
  name?: string
  value?: string
}
export class Option<TValue extends Potential<string> = string> {
  defaultValue?: string
  description?: string
  disabled?: boolean
  hidden?: boolean
  icon?: ReactNode
  name?: string
  value?: TValue
  constructor(optionAttributes: OptionAttributes) {
    Object.assign(this, optionAttributes)
  }
}
