import { Option } from 'classes'
import { TLeadRequirementsSlug, TLeadStageSlug } from './leadStageAttributes'

export class LeadStage<TSlug extends TLeadStageSlug = TLeadStageSlug> {
  active?: boolean
  name!: string
  nextStageOptions?: Option[]
  otherStageOptions?: Option[]
  previousStageOptions?: Option[]
  nextStageSlugs?: readonly TSlug[]
  other?: boolean
  leadRequirements?: Partial<Record<TLeadRequirementsSlug, string>>
  slug!: TSlug

  constructor(leadStageAttributes: Omit<LeadStage, 'toOption'>) {
    Object.assign(this, leadStageAttributes)
  }
  toOption() {
    return new Option({ name: this.name, value: this.slug })
  }
}
