import { CamelCase } from 'type-fest'
import { titleize } from '../titleize'

export const toCamelCase = <T extends string = string>(str?: Potential<T>) => {
  if (!str) {
    return ''
  }

  return str
    .toLowerCase()
    .split(/[^a-zA-Z0-9]+/g)
    .map((word, index) => (!index ? word.toLowerCase() : titleize(word)))
    .join('') as CamelCase<T>
}
