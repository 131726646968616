// @ts-strict
import { TableHeaderRow, TableHeaderColumn as TH } from 'components'
import { useListContext } from 'contexts'

export const FilesTableHeader = () => {
  const { showColumn } = useListContext()
  return (
    <TableHeaderRow>
      {showColumn('name') && (
        <TH
          sort={[{ name: 'Name', slug: 'name' }]}
          colSpan={showColumn('incomingResidence') ? 2 : 4}
        />
      )}
      {showColumn('incomingResidence') && <TH colSpan={2} name="IR Address" />}
      {showColumn('createdAt') && (
        <TH sort={[{ name: 'Created At', slug: 'createdAt' }]} colSpan={1} />
      )}
    </TableHeaderRow>
  )
}
