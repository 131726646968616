import { Contents } from 'components'
import { UseMutationResult } from 'hooks'
import { CCCashOfferLead } from 'models'
import { TPostCashOfferLeadVariables } from 'services'
import { CashOfferLeadDetailsSection, IR } from './CCCashOffer'

export type CCCashOfferLeadDetailsProps = {
  createCashOffer: UseMutationResult<unknown, unknown, TPostCashOfferLeadVariables>
  lead?: CCCashOfferLead
  runMortgageAffordability: UseMutationResult<unknown, unknown, { id: string }>
  updateLead: (payload: Record<string, string>) => void
  updateOrder: (payload: Record<string, string>) => void
}

export const CCCashOfferLeadDetails = ({
  lead,
  updateLead,
  updateOrder,
  createCashOffer,
  runMortgageAffordability
}: CCCashOfferLeadDetailsProps) => {
  return (
    <Contents>
      <CashOfferLeadDetailsSection lead={lead} updateLead={updateLead} updateOrder={updateOrder} />

      <IR
        lead={lead}
        updateLead={updateLead}
        runMortgageAffordability={runMortgageAffordability}
        createCashOffer={createCashOffer}
      />
    </Contents>
  )
}
