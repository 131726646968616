import { AgentProviderLead } from 'models'
import { sortByAttrDefinedOrder } from 'utils'
import { AgentLeadStage } from './AgentLeadStage'
import { agentLeadStagesSettings } from './agentLeadStagesSettings'

type TStaged = AgentProviderLead

const allAgentLeadStages = agentLeadStagesSettings.map(item => new AgentLeadStage(item))
export const allAgentLeadStageValues = allAgentLeadStages.map(item => item.value)
export const allRankValues = Array.from(Array(20), (x, i) => i)
export const sortByAgentLeadStageAttr = sortByAttrDefinedOrder('stage', allAgentLeadStageValues)
export const sortByAgentLeadRank = sortByAttrDefinedOrder('rank', allRankValues)

const activeAgentLeadStages = allAgentLeadStages.filter(item => item.active)
export const activeAgentLeadStageValues = activeAgentLeadStages.map(item => item.value)
export const sortByActiveAgentLeadStageAttr = sortByAttrDefinedOrder(
  'stage',
  activeAgentLeadStageValues
)
export const isActiveAgentLead = (stagedItem: TStaged) =>
  activeAgentLeadStageValues.some(stage => stage === stagedItem.stage)
