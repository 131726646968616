import { CCTradeInLead } from 'models'
import { AgentAttributesAndAssociations, getHapiRequestParams, LeadSchema } from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getPageCount, getRequest } from 'utils'
import { TFetchItems } from '../../types'
import {
  getCCTradeInLeadsSearchParams,
  TCCTradeInLeadsSearchParams
} from './getCCTradeInLeadsSearchParams'

const agentFields: AgentAttributesAndAssociations = [
  'firstName',
  'lastName',
  { agentTeam: ['name'] },
  { agentTeamMemberships: [{ agentTeam: ['name'] }] }
]

const leadResponseShape: LeadSchema = {
  lead: [
    'partnerName',
    'createdAt',
    'email',
    'fullAddress',
    'lastStageUpdate',
    'name',
    'stage',
    'userType',
    'propertyType',
    'secondaryUserType',
    {
      'childLeads:lead': [
        {
          'escrowOfficeProviderLeads:providerLead': [
            {
              'providable:escrowOfficeLead': [
                'orderStatus',
                'orderOpenAt',
                'orderClosingOn',
                'netEquity',
                {
                  escrowOrder: [
                    {
                      escrowOrderSettlementTeamMembers: [
                        'role',
                        { settlementTeamMember: ['firstName', 'lastName', 'email', 'phone'] }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    { 'referringOwner:agent': agentFields },
    {
      tasks: [
        'title',
        'completedAt',
        'readyAt',
        'templateSlug',
        'dismissedAt',
        'dueAt',
        'completionNote',
        'type',
        {
          'assignee:user': ['name']
        },
        {
          leadStageTaskDependency: ['stage']
        }
      ]
    },
    {
      order: ['source', { leads: ['userType', 'price'] }]
    },
    { leadUsers: ['role', { user: ['name'] }] },
    {
      'tradeInProviderLead:providerLead': [
        {
          'providable:tradeInLead': [
            'tradeInPlus',
            'departingMasterProperty',
            'departingPropertyHomeownerAssociation',
            'departingPropertySolarPanels',
            'dpFundingAmount',
            'dpTargetPurchaseCoeDate',
            'incomingPropertyCloseOfEscrowAt',
            'departingPropertyCloseOfSaleEscrowAt',
            'departingPropertyCloseOfPurchaseEscrowAt',
            'departingPropertyOpenedPurchasedEscrowAt',
            'departingPropertyOpenedSaleEscrowAt',
            'dpActualPurchaseFundedDate',
            'departingPropertyListedAt',
            {
              'departingPropertySellerAgent:agent': [
                ...agentFields,
                {
                  'transactionTeam:team': ['name']
                }
              ]
            },
            { 'incomingPropertyBuyerAgent:agent': agentFields }
          ]
        },
        { providerLeadCapitalPartner: [{ capitalPartner: ['name', 'fundingFacility'] }] }
      ]
    }
  ]
}

export const fetchCCTradeInLeads = async (
  settings: TCCTradeInLeadsSearchParams
): Promise<TFetchItems<CCTradeInLead>> => {
  const url = getLeadsUrl({
    searchParams: {
      ...getCCTradeInLeadsSearchParams(settings),
      ...getHapiRequestParams(leadResponseShape)
    }
  })
  const response = await getRequest(url)
  const leads = await deserializeAsync<CCTradeInLead[]>(response.data, {
    transform: CCTradeInLead.create
  })

  return {
    items: leads,
    pageCount: getPageCount(response.data.meta.total_leads, settings.perPage),
    totalCount: response.data.meta.total_leads || 0
  }
}
