import { TEnrollmentStatus } from '../types'

export const getCalendlyFilter = (calendly: Potential<TEnrollmentStatus>) => {
  const filters = {}
  if (calendly === 'enrolled') {
    filters['filter[capabilities.enrolled_calendly]'] = true
  } else if (calendly === 'declined') {
    filters['filter[capabilities.declined_calendly]'] = true
  } else if (calendly === 'unenrolled') {
    filters['filter[capabilities.enrolled_calendly]'] = false
  }

  return filters
}
