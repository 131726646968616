export const pageArray = (totalPageCount, currentPage = 1) => {
  let startPage
  let endPage

  const totalPages = Math.ceil(totalPageCount)

  if (totalPages <= 10) {
    startPage = 1
    endPage = totalPages
  } else if (currentPage <= 6) {
    startPage = 1
    endPage = 10
  } else if (currentPage + 4 >= totalPages) {
    startPage = totalPages - 9
    endPage = totalPages
  } else {
    startPage = currentPage - 5
    endPage = currentPage + 4
  }

  return new Array(endPage + 1 - startPage).fill().map((_null, i) => startPage + i)
}
