// @ts-strict
import { CashOfferLead } from 'models'
import { formatDateTime, formatMoney } from 'utils'
import { IncomingResidenceProps } from '../IR'

type TProps = { cashOfferLead: CashOfferLead } & Pick<IncomingResidenceProps, 'lead'>

export const useRunMortgageAffordability = ({ lead, cashOfferLead }: TProps) => {
  const loanApplicationId = lead?.order?.loanApplicationId

  const salePrice = formatMoney(cashOfferLead?.incomingPropertySalePrice)
  const affordabilityUpdatedAt = cashOfferLead.ipAffordabilityUpdatedAt

  const buttonTitle = affordabilityUpdatedAt
    ? `Last run on ${formatDateTime(affordabilityUpdatedAt)}`
    : !loanApplicationId
    ? 'Mortgage Lead loan application required'
    : ''

  const popupMessage = `Click 'Submit' to calculate the mortgage affordability \
    for a max Cash Offer purchase price of ${salePrice}`

  const purchacePriceErrorMessage =
    cashOfferLead.ipMaxOfferPriceWithoutBuffer &&
    cashOfferLead.incomingPropertySalePrice !== undefined &&
    cashOfferLead.incomingPropertySalePrice > cashOfferLead.ipMaxOfferPriceWithoutBuffer
      ? 'Purchase Price is above Max Price'
      : ''

  return {
    affordabilityUpdatedAt,
    buttonTitle,
    loanApplicationId,
    popupMessage,
    purchacePriceErrorMessage
  }
}
