// @ts-strict

import { useQuery } from 'hooks'
import { fetchEligibilityCheck } from 'services'

type TOptions = {
  onError?: (err: unknown) => void
}

export const useEligibilityController = (
  {
    agentValuation,
    clientMortgageBalance,
    enabled,
    leadId,
    state
  }: {
    agentValuation?: string | number
    clientMortgageBalance?: string | number
    enabled?: boolean
    leadId: string
    state?: string
  },
  opt = {} as TOptions
) => {
  const { data, error, isLoading, isError, refetch, isSuccess } = useQuery(
    ['eligibilityCheck', leadId, agentValuation, clientMortgageBalance],
    () => fetchEligibilityCheck({ leadId, state, agentValuation, clientMortgageBalance }),
    {
      refetchOnWindowFocus: false,
      enabled: enabled,
      onError: opt.onError || undefined
    }
  )

  return {
    data,
    error,
    isLoading,
    isError,
    refetch,
    isSuccess
  }
}
