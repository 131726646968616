import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Table.module.scss'

type Props = {
  children: ReactNode
  className?: string
  onClick?: () => void
}

export const TableRow = ({ className, children, onClick, ...props }: Props) => {
  const clickableClass = onClick ? styles.clickable : null
  return (
    <tr className={cx(styles.row, className, clickableClass)} onClick={onClick} {...props}>
      {children}
    </tr>
  )
}
