import { PhoneNumberResponse } from 'schema'
import { formatPhone } from 'utils'

export class PhoneNumber {
  id!: string
  countryCode?: string
  number?: string
  formattedNumber?: string
  phoneType?: 'cell' | 'office' | 'fax' | 'twilio' | 'home'

  constructor(phoneNumberAttributes: PhoneNumber) {
    Object.assign(this, phoneNumberAttributes)
  }

  static create(phoneNumberResponse: Potential<PhoneNumberResponse>): Potential<PhoneNumber> {
    if (!phoneNumberResponse) {
      return
    }
    const formattedNumber = formatPhone(phoneNumberResponse.number)
    return new PhoneNumber({ ...phoneNumberResponse, formattedNumber })
  }
}
