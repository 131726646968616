// @ts-strict
import { useState } from 'react'
import {
  ButtonForm,
  CheckBoxField,
  FormField,
  InlineChangeButton,
  TextInput,
  useButtonForm
} from 'components'
import { useLeadContext } from 'contexts'
import { d, DEFAULT_STRING, validatePresence } from 'utils'
import * as S from '../styles'

type Props = {
  brokerages?: string[] | []
}

type BrokeragesToExcludePayload = {
  brokeragesToExclude: string[]
}

const toggleOptionsStringArray = (option: string, stringArray: string[]) => {
  if (stringArray.includes(option)) {
    const optionsWithoutSelected = stringArray.filter(value => value !== option)

    return optionsWithoutSelected
  }

  return [...stringArray, option]
}

export const BrokeragesToExcludeButtonForm = ({ brokerages = [] }: Props) => {
  const { updateLead } = useLeadContext()
  const {
    fieldsProps: { onChangeAttribute, payload },
    formProps
  } = useButtonForm<BrokeragesToExcludePayload>({
    initialPayload: {
      brokeragesToExclude: brokerages
    },
    mutation: updateLead,
    formValidations: {
      brokeragesToExclude: [validatePresence]
    },
    transformPayload: data => {
      return { callReview: data }
    }
  })
  const [newBrokerage, setNewBrokerage] = useState<string>('')
  const [newBrokerages, setNewBrokerages] = useState<string[]>(brokerages)
  const formTitleWithBrokerages = brokerages.length > 1 ? brokerages.join(', ') : brokerages[0]
  const formTitle = brokerages.length ? formTitleWithBrokerages : DEFAULT_STRING

  const handleBrokerages = (brokerage: string) => {
    const brokeragesToExclude = payload.brokeragesToExclude
    const newListOfBrokerages = toggleOptionsStringArray(brokerage, brokeragesToExclude)
    onChangeAttribute('brokeragesToExclude', newListOfBrokerages)
  }

  const setNewBrokeragesToExclude = (brokerage: string) => {
    setNewBrokerage(brokerage)
  }

  const saveNewBrokeragesToExclude = () => {
    if (newBrokerage) {
      handleBrokerages(newBrokerage)
      setNewBrokerages(prevBrokerages => toggleOptionsStringArray(newBrokerage, prevBrokerages))
      setNewBrokerage('')
    }
  }

  return (
    <ButtonForm
      buttonComponent={InlineChangeButton}
      title="Brokerages to Exclude"
      buttonText={formTitle}
      {...formProps}
    >
      {formProps.showForm && (
        <>
          <FormField title="New Brokerages to Exclude">
            <TextInput
              aria-label="New Brokerages to Exclude"
              id="new-brokerages"
              onBlur={saveNewBrokeragesToExclude}
              onChange={setNewBrokeragesToExclude}
              data-testid="new-brokerages-field"
              value={newBrokerage}
            />
          </FormField>
          {!!newBrokerages.length && (
            <S.FormField title="Excluded brokerages" required>
              {newBrokerages.map(brokerage => {
                return (
                  <CheckBoxField
                    key={brokerage}
                    title={d(brokerage)}
                    checked={payload.brokeragesToExclude.includes(brokerage)}
                    onChange={() => handleBrokerages(brokerage)}
                  />
                )
              })}
            </S.FormField>
          )}
        </>
      )}
    </ButtonForm>
  )
}
