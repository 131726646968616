import { css, styled } from '@foundation/themes/styles'

export const Ellipsis = styled.div`
  ${({ theme }) => css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 14rem;
  `}
`

export const WrapperSpinner = styled.div`
  width: 19rem;
`

export const WrapperAvatar = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.space.$1};
  `}
`
