import { useEffect, useState } from 'react'
import { AgentsAutoComplete } from 'components'
import { TMarketplaceProgramSlug } from 'lookups'
import { cx, dataOrDash } from 'utils'
import { AgentAutoCompleteResponse } from '../../AutoComplete'
import { DisplayValue, InlineProps } from '../DisplayValue'
import style from '../InlineEditDateInput/InlineEditDateInput.module.scss'

type Props = {
  className?: string
  onBlur?: (val: string) => void
  placeholder?: string
  searchParams?: Record<string, string>
  value?: string
} & InlineProps

const programMap = {
  trade_in: 'tradeIn',
  cash_offer: 'cashOffer',
  hlss: 'hlss'
}

const getInlineEditAgentComponentByProgram = (program: TMarketplaceProgramSlug) => {
  const filterProgram = programMap[program]
  return (props: Props) => (
    <InlineEditAgent {...props} searchParams={{ [filterProgram]: 'enrolled' }} />
  )
}

export const InlineEditCCEnrolledAgent = getInlineEditAgentComponentByProgram('cash_close')
export const InlineEditHLEnrolledAgent = getInlineEditAgentComponentByProgram('home_loans')
export const InlineEditTradeInEnrolledAgent = getInlineEditAgentComponentByProgram('trade_in')
export const InlineEditCashOfferEnrolledAgent = getInlineEditAgentComponentByProgram('cash_offer')
export const InlineEditHLSSEnrolledAgent = getInlineEditAgentComponentByProgram('hlss')

export const InlineEditAgent = ({
  onBlur = (_: string) => {},
  value,
  isLoading,
  isError,
  searchParams,
  format,
  className
}: Props) => {
  const [currentValue, setCurrentValue] = useState(value || '')
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleOnSelect = (selectedValue: AgentAutoCompleteResponse) => {
    if (selectedValue?.id !== value) {
      onBlur(selectedValue?.id ?? '')
    }
    setEditMode(false)
    setCurrentValue(selectedValue?.title)
  }

  const handleClear = () => {
    setCurrentValue('')
  }

  const setEditModeTrue = () => {
    setEditMode(true)
  }
  const setEditModeFalse = () => {
    setEditMode(false)
  }

  const formatter = (changedValue: string) => {
    return format ? format(changedValue) : changedValue
  }

  if (editMode) {
    return (
      <AgentsAutoComplete
        value={currentValue}
        onSelect={handleOnSelect}
        onClear={handleClear}
        onBlur={setEditModeFalse}
        searchParams={searchParams}
        className={cx(style.date, className)}
        autoFocus
      />
    )
  }

  return (
    <DisplayValue
      value={dataOrDash(currentValue)}
      setEditMode={setEditModeTrue}
      format={formatter}
      isLoading={isLoading}
      isError={isError}
    />
  )
}
