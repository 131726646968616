// @ts-strict

import { useQuery } from 'hooks'
import { fetchInvestor } from 'services'

export const useInvestorController = (investorId: string) => {
  const {
    data: investor,
    isLoading,
    isError
  } = useQuery(['investors', investorId], () => fetchInvestor(investorId))

  return {
    investor,
    isLoading,
    isError
  }
}
