// @ts-strict

import { Agent, MarketplaceProgram } from 'models'
import { MarketplaceProgramAgentEnrollStatus } from 'models/MarketplaceProgramAgent/utils'

export type EligibilityValidationPayload = {
  isEligible: boolean
  selectedProgramStatus: MarketplaceProgramAgentEnrollStatus
  validationMsg: string
}

const TRANSACTION_TEAM_VALIDATION_MESSAGE = `Agent must be assigned to a Transaction Team`

export const getAgentProgramEligibility = (
  agent: Agent,
  program: MarketplaceProgram
): EligibilityValidationPayload => {
  const selectedProgramStatus = agent.getMarketplaceProgramEnrollStatus(program?.slug)
  let isEligible: boolean = true
  let validationMsg: string = 'Eligible'

  const isTransactionTeamValid: boolean =
    agent.hasATransactionTeam || !program.transactionTeamRequired
  const isStateValid: boolean = program.isStateEligible(agent.state?.code)

  if (!agent.hasUserAccount) {
    isEligible = false
    validationMsg = 'Agent must have an associated User Account'
  } else if (!isTransactionTeamValid) {
    isEligible = false
    validationMsg = TRANSACTION_TEAM_VALIDATION_MESSAGE
  } else if (!isStateValid) {
    isEligible = false
    validationMsg = `Not Eligible in ${agent.state?.code}`
  }

  return { selectedProgramStatus, isEligible, validationMsg }
}
