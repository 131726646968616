import { EliteStatus } from '@constants'
import { getAgentsUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export type PutAgentPhoneNumber = {
  id: string
  number: string
  phoneType: 'cell' | 'office'
}

export type PutAgentPayload = {
  assignedAreaId?: string
  assignedAreaType?: 'Metroplex' | 'State'
  brokerage?: string
  eliteStatus?: EliteStatus
  email1?: string
  email2?: string
  firstName?: string
  lastName?: string
  licenseNumber?: string
  mlsIdentifier?: string
  phoneNumbers?: PutAgentPhoneNumber[]
  settlementAgencyTeamId?: string
  stateId?: string
  transactionTeamId?: string
  website?: string
}

export const putAgent = (id: string, payload: PutAgentPayload) =>
  putRequest(getAgentsUrl({ id }), snakeCaseKeys(payload))
