// @ts-strict
import {
  ChangeReasonButtonFormDate,
  Contents,
  FormField,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  InlineEditDollarsInput,
  InlineEditIntegerInput,
  InlineEditPercentageInput,
  InlineEditSelect,
  Section,
  Sections
} from 'components'
import { useLeadContext } from 'contexts'
import { useCashOfferLeadController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import {
  useCapitalPartners,
  useCashOfferLeadFields,
  useCcCashOfferLeadFields,
  useChangeReasons
} from 'lookups'
import { CashOfferLead } from 'models'
import { formatMoney, formatNumber, idMatch, stringifyValue } from 'utils'
import { IncomingResidenceProps } from './IR'
import { useRunMortgageAffordability } from './hooks'
import { getCashOfferLeadInputProps, getLeadInputProps } from './utils'

type TProps = { cashOfferLead: CashOfferLead } & Pick<IncomingResidenceProps, 'lead' | 'updateLead'>

export const IRPurchaseTransaction = ({ lead, cashOfferLead, updateLead }: TProps) => {
  const { salesAppRevenueRecognition } = useFeatureFlags(['sales-app-revenue-recognition'])
  const { capitalPartnersOptions } = useCapitalPartners()
  const { updateCapitalPartner } = useLeadContext()
  const { purchacePriceErrorMessage } = useRunMortgageAffordability({
    lead,
    cashOfferLead
  })
  const { updateCashOfferLead } = useCashOfferLeadController(cashOfferLead.id)
  const { getProps: getLeadProps } = getLeadInputProps(lead, updateLead)
  const { getCashOfferLeadField, getCashOfferLeadFieldName } = useCashOfferLeadFields()
  const { getProps, getPercentProps } = getCashOfferLeadInputProps(
    cashOfferLead,
    updateCashOfferLead,
    getCashOfferLeadField
  )

  const { coeDateChangeReasonOptions } = useChangeReasons()
  const { getCcCashOfferLeadFieldName } = useCcCashOfferLeadFields()

  const providerLead = lead?.cashOfferProviderLeads?.find(copl =>
    idMatch(copl.cashOfferLead?.id, cashOfferLead.id)
  )!

  const { capitalPartner } = providerLead.providerLeadCapitalPartner || {}

  const handleUpdateCashOfferLead = (val: string) => {
    updateCashOfferLead.mutateAsync({
      incomingPropertySalePrice: val
    })
  }

  const readOnlyCashOfferRate = cashOfferLead.cashOfferFeeMethod !== 'rate'
  const readOnlyCashOfferAmount = cashOfferLead.cashOfferFeeMethod !== 'amount'

  const lenderIsBesi = lead?.lenderSlug === 'besi'

  return (
    <Contents>
      <Sections title={`Purchase Transaction (${cashOfferLead.contingentStatus})`}>
        <Section>
          <FormField title={getCashOfferLeadFieldName('incomingPropertyListPrice')} inline>
            <InlineEditDollarsInput {...getProps('incomingPropertyListPrice')} />
          </FormField>
          <FormField
            title={getCashOfferLeadFieldName('incomingPropertySalePrice')}
            error={purchacePriceErrorMessage}
            inline
          >
            <InlineEditDollarsInput
              value={stringifyValue(cashOfferLead.incomingPropertySalePrice)}
              isLoading={updateCashOfferLead.isLoading}
              onBlur={handleUpdateCashOfferLead}
              isError={!!purchacePriceErrorMessage}
            />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipMaxOfferPriceWithoutBuffer')} inline>
            {lenderIsBesi ? (
              <InlineEditDollarsInput {...getProps('ipMaxOfferPriceWithoutBuffer')} />
            ) : (
              formatMoney(cashOfferLead.ipMaxOfferPriceWithoutBuffer)
            )}
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipBuyersAgentCommissionRate')} inline>
            <InlineEditPercentageInput {...getPercentProps('ipBuyersAgentCommissionRate')} />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipBuyersAgentCommission')} inline>
            {formatMoney(cashOfferLead.ipBuyersAgentCommission)}
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipEarnestMoney')} inline>
            <InlineEditDollarsAndCentsInput {...getProps('ipEarnestMoney')} />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('depositRate')} inline>
            <InlineEditPercentageInput {...getPercentProps('depositRate')} />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipCashOfferDeposit')} inline>
            {formatMoney(cashOfferLead.ipCashOfferDeposit)}
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipAdditionalDeposit')} inline>
            <InlineEditDollarsAndCentsInput {...getProps('ipAdditionalDeposit')} />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipNetSettlement')} inline>
            {formatMoney(cashOfferLead?.ipNetSettlement)}
          </FormField>
          <FormField title={getCashOfferLeadFieldName('cashOfferFeeMethod')} inline>
            <InlineEditSelect {...getProps('cashOfferFeeMethod')} />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('cashOfferFeeRate')} inline>
            <InlineEditPercentageInput
              readOnly={readOnlyCashOfferRate}
              {...getPercentProps('cashOfferFeeRate')}
            />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('cashOfferFeeAmount')} inline>
            <InlineEditDollarsAndCentsInput
              readOnly={readOnlyCashOfferAmount}
              {...getProps('cashOfferFeeAmount')}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title={getCashOfferLeadFieldName('ipInsuranceCoverage')} inline>
            <InlineEditDollarsAndCentsInput {...getProps('ipInsuranceCoverage')} />
          </FormField>
          <FormField
            title={getCashOfferLeadFieldName('incomingPropertyOpenPurchaseEscrowAt')}
            inline
          >
            <InlineEditDateInput {...getProps('incomingPropertyOpenPurchaseEscrowAt')} />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipTargetCloseOfPurchaseEscrowAt')} inline>
            <InlineEditDateInput {...getProps('ipTargetCloseOfPurchaseEscrowAt')} />
          </FormField>
          <FormField
            title={getCashOfferLeadFieldName('incomingPropertyCloseOfPurchaseEscrowAt')}
            inline
          >
            {!!cashOfferLead.incomingPropertyCloseOfPurchaseEscrowAt ? (
              <ChangeReasonButtonFormDate
                fieldName="incomingPropertyCloseOfPurchaseEscrowAt"
                mutation={updateCashOfferLead}
                reasons={coeDateChangeReasonOptions}
                title={getCcCashOfferLeadFieldName('incomingPropertyCloseOfPurchaseEscrowAt')}
                value={cashOfferLead.incomingPropertyCloseOfPurchaseEscrowAt || ''}
              />
            ) : (
              <InlineEditDateInput {...getProps('incomingPropertyCloseOfPurchaseEscrowAt')} />
            )}
          </FormField>
          <FormField
            title={getCashOfferLeadFieldName('incomingPropertyFundsWiredToSellerAt')}
            inline
          >
            <InlineEditDateInput {...getProps('incomingPropertyFundsWiredToSellerAt')} />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipFundingAmount')} inline>
            <InlineEditDollarsAndCentsInput {...getProps('ipFundingAmount')} />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipBorrowingBase')} inline>
            <InlineEditDollarsAndCentsInput {...getProps('ipBorrowingBase')} />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipHlEquity')} inline>
            {formatMoney(cashOfferLead.ipHlEquity)}
          </FormField>
          <FormField title="Capital Markets Facility" inline>
            <InlineEditSelect
              options={capitalPartnersOptions}
              value={capitalPartner?.id}
              onChange={value => updateCapitalPartner({ providerLead, capitalPartnerId: value })}
            />
          </FormField>
          <FormField title="Funding Facility" inline>
            {capitalPartner?.fundingFacility}
          </FormField>
          {/* this field must be removed after removing the feature flag */}
          {!salesAppRevenueRecognition.enabled && (
            <FormField title={getCashOfferLeadFieldName('revenueRecognitionDate')} inline>
              <InlineEditDateInput {...getLeadProps('revenueRecognitionDate')} />
            </FormField>
          )}
          <FormField title={getCashOfferLeadFieldName('ipCapitalDaysOutstanding')} inline>
            {salesAppRevenueRecognition.enabled ? (
              // keep this when removing the feature flag
              <InlineEditIntegerInput {...getProps('ipCapitalDaysOutstanding')} />
            ) : (
              formatNumber(cashOfferLead?.ipCapitalDaysOutstanding)
            )}
          </FormField>
        </Section>
      </Sections>
    </Contents>
  )
}
