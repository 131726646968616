import { CCHLSimpleSaleLead } from 'models'
import { HLSSLeadDispositionProvider } from './HLSSLeadDispositionContext'
import { HLSSLeadDispositionForm } from './HLSSLeadDispositionForm'

type Props = {
  lead: CCHLSimpleSaleLead
  onCancel: () => void
}

export const HLSSLeadDisposition = ({ lead, onCancel }: Props) => {
  return (
    <HLSSLeadDispositionProvider lead={lead}>
      <HLSSLeadDispositionForm lead={lead} onCancel={onCancel} />
    </HLSSLeadDispositionProvider>
  )
}
