import { useMutation, useQueryClient } from 'react-query'
import type { UseQueryOptions } from 'react-query'
import { TLeadUploadPhotosPayload } from 'content/Lead'
import { PHOTOS_CACHE_KEY, useQuery } from 'hooks'
import {
  createPhotoUploads,
  deletePhotoUploads,
  fetchPhotoUploads,
  TUploadedPhotoSearchParams
} from 'services'

const defaultSearchSettings: TUploadedPhotoSearchParams = {
  perPage: 12,
  category: 'property_photo'
}

export const usePhotoUploadsController = (
  searchParams?: TUploadedPhotoSearchParams,
  options: UseQueryOptions = {}
) => {
  const queryCache = useQueryClient()

  const defaultCallback = {
    onSuccess: () => queryCache.invalidateQueries(PHOTOS_CACHE_KEY)
  }

  const photoUploadsQuery = useQuery(
    [PHOTOS_CACHE_KEY, searchParams],
    () =>
      fetchPhotoUploads({
        ...defaultSearchSettings,
        ...searchParams!
      }),
    { enabled: options.enabled || !!searchParams, refetchOnWindowFocus: false }
  )
  const destroyPhotoUploads = useMutation(deletePhotoUploads, defaultCallback)

  const uploadPhotos = useMutation((payload: TLeadUploadPhotosPayload) => {
    return createPhotoUploads(searchParams?.leadId!, payload)
  }, defaultCallback)

  return {
    photoUploadsQuery,
    uploadPhotos,
    destroyPhotoUploads
  }
}
