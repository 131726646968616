import {
  FormField,
  InlineEditAddressUnit,
  InlineEditDateInput,
  InlineEditPhoneInput,
  InlineEditSelect,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { yesOrNoOptions } from 'lookups'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import { getBBYSLeadInputProps } from './utils'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>

export const IRPropertyDetailsSection = ({ lead, updateBBYSLead, updateLead }: Props) => {
  const bbysLead = lead.bbysProviderLead.bbysLead
  const { getProps } = getBBYSLeadInputProps(bbysLead, updateBBYSLead)

  return (
    <Sections title="Incoming Residence">
      <Section>
        <FormField title="Property Address" inline>
          <InlineEditAddressUnit
            onBlur={val =>
              updateBBYSLead.mutate({
                id: bbysLead.id,
                payload: {
                  incoming_property_full_address: val
                }
              })
            }
            value={bbysLead.incomingPropertyFullAddress}
          />
        </FormField>
        <FormField title="Under contract at intake" inline>
          <InlineEditSelect
            {...getProps('incomingResidenceUnderContractAtIntake')}
            options={yesOrNoOptions}
            addBlank
          />
        </FormField>
      </Section>
      <Section>
        <FormField title="Title company" inline>
          <InlineEditTextInput {...getProps('incomingResidenceTitleCompany')} />
        </FormField>
        <FormField title="Title company email address" inline>
          <InlineEditTextInput {...getProps('incomingResidenceTitleCompanyEmail')} />
        </FormField>
        <FormField title="Title company phone number" inline>
          <InlineEditPhoneInput {...getProps('incomingResidenceTitleCompanyPhone')} />
        </FormField>
        <FormField title="Client in contract date" inline>
          <InlineEditDateInput {...getProps('departingResidenceClientInContractDate')} />
        </FormField>
      </Section>
    </Sections>
  )
}
