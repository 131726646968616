import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { environment } from 'environment'

const trySentry = (call: () => void) => {
  try {
    if (environment.sentryDSN) {
      call()
    }
  } catch {
    if (environment.env !== 'production' && environment.env !== 'development') {
      console.log('Sentry is not defined')
    }
  }
}

export const initSentry = () => {
  trySentry(() => {
    Sentry.init({
      dsn: environment.sentryDSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.0,
      environment: environment.sentryEnvironment
    })
  })
}
