// @ts-strict
import { Option } from 'classes'
import { FormField, Select, TextInput } from 'components'
import { presence } from 'utils'
import { TLeadExternalLinkPayload } from '../utils'

type Props = {
  onChangeAttribute: <K extends keyof TLeadExternalLinkPayload>(
    key: K,
    value: TLeadExternalLinkPayload[K]
  ) => void
  payload: TLeadExternalLinkPayload
  subAttachableOptions?: Option[]
}

export const LeadExternalLinkFormFields = ({
  payload,
  onChangeAttribute,
  subAttachableOptions
}: Props) => {
  const setName = (val: string) => onChangeAttribute('name', val)
  const setUrl = (val: string) => onChangeAttribute('url', val)

  const setIncomingResidence = (value: string) => {
    onChangeAttribute('subAttachableId', value)
    onChangeAttribute('subAttachableType', value ? 'CashOfferLead' : undefined)
  }

  return (
    <>
      <FormField title="Name" htmlFor="name" required>
        <TextInput id="name" name="name" value={payload.name} onChange={setName} />
      </FormField>
      <FormField title="URL" htmlFor="url" required>
        <TextInput id="url" name="url" value={payload.url} onChange={setUrl} />
      </FormField>
      {presence(subAttachableOptions) && (
        <FormField title="Incoming Residence" htmlFor="subAttachableOptions">
          <Select
            addBlank
            id="subAttachableOptions"
            options={subAttachableOptions}
            value={payload.subAttachableId}
            onChange={setIncomingResidence}
          />
        </FormField>
      )}
    </>
  )
}
