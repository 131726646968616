// @ts-strict
import { useMutation, useQuery, useQueryClient } from 'hooks'
import { fetchAgentAgreements, issueAgentAgreement, voidAgentAgreement } from 'services'

type VoidAgreementPayload = {
  agreementId: string
}

type IssueAgentAgreementPayload = {
  agreementId: string
}

export const useAgentAgreementsController = (agentId: string) => {
  const agentAgreementsQuery = useQuery(
    ['agent-agreements', agentId],
    () => fetchAgentAgreements(agentId),
    { refetchOnWindowFocus: false }
  )

  const queryCache = useQueryClient()

  const agentAgreementVoid = useMutation(
    (payload: VoidAgreementPayload) => voidAgentAgreement(agentId, payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries()
      }
    }
  )

  const agentAgreementIssue = useMutation(
    (payload: IssueAgentAgreementPayload) => issueAgentAgreement(agentId, payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries()
      }
    }
  )

  return { agentAgreementsQuery, agentAgreementVoid, agentAgreementIssue }
}
