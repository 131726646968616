import { getPureCloudTokenActiveUrl } from 'services/getUrl'
import { getRequest } from 'utils'

interface ResponseData {
  data: {
    data: {
      result: boolean
    }
  }
}

export const fetchPureCloudTokenActive = async () => {
  const response = (await getRequest(getPureCloudTokenActiveUrl())) as unknown as ResponseData

  return response.data.data
}
