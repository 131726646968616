import { Block } from 'components'
import { isCCCashOfferLead, isCCTradeInLead, Lead } from 'models'

type Props = {
  lead: Lead
}

export const OrdersTableRowLeadTransactionTeam = ({ lead }: Props) => {
  if (isCCCashOfferLead(lead) || isCCTradeInLead(lead)) {
    return <Block title={lead.agent?.transactionTeam?.name} />
  }

  return null
}
