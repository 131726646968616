import { AgentLeadsView } from 'content/AgentLeads'
import { useParamsContext } from 'contexts/Params'
import { useAgentLeadsController } from 'controllers'
import { Agent } from 'models'

type AgentLeadsProps = {
  agent: Agent
}

export const AgentAgentLeads = ({ agent }: AgentLeadsProps) => {
  const { params } = useParamsContext()
  const { agentLeads, isLoading, pageCount, totalCount } = useAgentLeadsController({
    searchParams: params,
    agentId: agent.id
  })
  return (
    <AgentLeadsView
      agentLeads={agentLeads}
      isLoading={isLoading}
      pageCount={pageCount}
      totalCount={totalCount}
    />
  )
}
