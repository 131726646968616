import { useChangeReasons, useFailedReasons, useLeadPropertyTypes } from 'lookups'
import { byAttr } from 'utils'
import { CCHLSimpleSaleLeadField } from './CCHLSimpleSaleLeadField'
import {
  ccHLSimpleSaleLeadFieldsSettings,
  TCCHLSimpleSaleLeadFieldAttribute
} from './ccHLSimpleSaleLeadFieldsSettings'

const buildLeadFields = (optionLoaders: object) => {
  const lookupCCHLSimpleSaleLeadFields = ccHLSimpleSaleLeadFieldsSettings.map(setting => {
    let options = setting.options
    if (setting.getOptions) {
      options = optionLoaders[setting.getOptions]
    }
    return CCHLSimpleSaleLeadField.createFromSetting({ ...setting, options })
  })

  return lookupCCHLSimpleSaleLeadFields
}

export const useCCHLSimpleSaleLeadFields = () => {
  const { hlSimpleSaleFailedReasons } = useFailedReasons()
  const { coeDateChangeReasons } = useChangeReasons()
  const { leadPropertyTypes } = useLeadPropertyTypes()

  const optionLoaders = {
    hlSimpleSaleFailedReasons,
    coeDateChangeReasons,
    leadPropertyTypes
  }

  const lookupCCHLSimpleSaleLeadFields = buildLeadFields(optionLoaders)

  const getCCHLSimpleSaleLeadField = (attr: TCCHLSimpleSaleLeadFieldAttribute) => {
    return lookupCCHLSimpleSaleLeadFields.find(byAttr('attr', attr))
  }

  const getCCHLSimpleSaleLeadFieldName = (attr: TCCHLSimpleSaleLeadFieldAttribute) => {
    return getCCHLSimpleSaleLeadField(attr)?.name
  }

  return {
    lookupCCHLSimpleSaleLeadFields,
    getCCHLSimpleSaleLeadField,
    getCCHLSimpleSaleLeadFieldName
  }
}
