import { Option } from 'classes'
import { Button } from 'components/Button'
import { SELECTED } from 'utils'
import styles from './ListBox.module.scss'

type Props = {
  onChange?: (val: string) => void
  options: Option[]
  title?: string
  value: string | number
}

const getOptionValue = (option: Option): string => {
  const optionValue = option.value
  return optionValue !== undefined ? String(optionValue) : ''
}
export const ListBox = ({ options = [], onChange, value, title }: Props) => {
  const handleOnChange = (val: string) => {
    onChange(val)
  }

  return (
    <div className={styles.listBoxWrapper}>
      {title ? <div className={styles.listBoxTitle}>{title}</div> : null}
      <div className={styles.listBox}>
        {options.map(option => {
          const [optionValue, name] =
            typeof option === 'object'
              ? [getOptionValue(option), option.name]
              : [String(option), String(option)]

          return (
            <Button
              as="toggle"
              key={optionValue}
              onClick={() => handleOnChange(optionValue)}
              className={value === optionValue ? SELECTED : null}
            >
              {name}
            </Button>
          )
        })}
      </div>
    </div>
  )
}
