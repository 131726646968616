import { ModelField } from '../ModelField'
import { TServiceOfficeFieldAttribute } from './serviceOfficeFieldsSettings'

export class ServiceOfficeField extends ModelField {
  attr!: TServiceOfficeFieldAttribute

  constructor(attributes: ServiceOfficeField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
