import { ModelField } from '../ModelField'
import { THLSimpleSaleProviderLeadFieldAttribute } from './hlSimpleSaleProviderLeadFieldsSettings'

export class HLSimpleSaleProviderLeadField extends ModelField {
  attr!: THLSimpleSaleProviderLeadFieldAttribute

  constructor(attributes: HLSimpleSaleProviderLeadField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
