// @ts-strict
import React, { useState } from 'react'
import { ELITE_STATUS_OPTIONS, EliteStatus } from '@constants'
import { useToastContext } from '@foundation/components'
import { Select } from 'components' // todo jnunez: inlineeditselect?
import { useCurrentUserContext } from 'contexts'
import { useAgentController } from 'controllers'
import { formatOrDash } from 'utils'

type Props = {
  agentId: string
  eliteStatus: EliteStatus
}

export const EliteStatusSelect = ({ agentId, eliteStatus }: Props) => {
  const [selectedEliteStatus, setSelectedEliteStatus] = useState(eliteStatus)
  const { updateAgent } = useAgentController(agentId)
  const { isEliteStatusAdmin } = useCurrentUserContext()
  const toast = useToastContext()
  const eliteStatusOptions = ELITE_STATUS_OPTIONS.map(option => ({ label: option, value: option }))

  if (!isEliteStatusAdmin) {
    const formatEliteStatus = (value: any) => (value ? value : 'none')
    return formatOrDash(eliteStatus, formatEliteStatus)
  }

  const handleOnStatusChange = (status: string) => {
    const payload = {
      eliteStatus: status
    }

    updateAgent.mutate(payload, {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Elite status updated successfully'
        })
        setSelectedEliteStatus(status)
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Unable to update elite status. Please contact support'
        })
        setSelectedEliteStatus(eliteStatus)
      }
    })
  }

  return (
    <Select
      placeholder="none"
      value={selectedEliteStatus}
      options={eliteStatusOptions}
      onChange={option => handleOnStatusChange(option)}
    />
  )
}
