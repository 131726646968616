// @ts-strict
import { Agent } from 'models'
import { TextMessageResponse } from 'schema'

export class Message {
  id!: string
  agent?: Agent
  body?: string
  createdAt!: string
  outbound?: boolean
  twilioSid?: string
  provider?: {
    fullName: string
  }

  constructor(messageAttributes: Message) {
    Object.assign(this, messageAttributes)
  }

  static create(messageResponse: Potential<TextMessageResponse>): Potential<Message> {
    if (!messageResponse) {
      return
    }
    const agent = new Agent(messageResponse.provider as any)
    return new Message({ ...messageResponse, agent })
  }
}
