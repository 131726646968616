// @ts-strict
import { IntroTrackingResponse } from 'schema'

export class IntroTracking {
  claimMethod?: string
  introDetail?: string
  introType?: string
  qualificationMethod?: string
  qualificationType?: string

  constructor(introTrackingAttributes: IntroTracking) {
    Object.assign(this, introTrackingAttributes)
  }
  static create(introTrackingResponse: Potential<IntroTrackingResponse>): Potential<IntroTracking> {
    if (!introTrackingResponse) {
      return
    }
    return new IntroTracking(introTrackingResponse)
  }
}
