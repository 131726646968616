import { CreateCheckoutSessionVariables } from 'contexts/types'
import { getCheckoutSessionsUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export const postCheckoutSession = (payload: CreateCheckoutSessionVariables) => {
  const { userId, priceId, redirectUrl } = payload
  return postRequest(getCheckoutSessionsUrl(), {
    user_id: userId,
    price_id: priceId,
    redirect_url: redirectUrl
  })
}
