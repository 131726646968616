import { presence } from '../presence'
import { TValidation } from './validate'

export const validatePresence: TValidation = ({ label, value }) => {
  const errors = []

  if (!presence(value)) {
    errors.push(`${label} is missing`)
  }

  return errors
}
