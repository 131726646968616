import { PhoneNumber } from 'models'
import {
  getHapiRequestParams,
  LoanOfficerAutoCompleteResponse,
  LoanOfficersAutoCompleteSchema,
  PartnerResponse,
  UserResponse
} from 'schema'
import { getLoanOfficersUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams, TLoanOfficersAutoCompleteParams } from './getSearchParams'

type TLoanOfficerSearchResponse = {
  companyName?: string
  email: string
  id: string
  name: string
  partner?: PartnerResponse
  phoneNumber?: PhoneNumber[]
  user?: UserResponse
}

export const transformLoanOfficersAutoComplete = (loanOfficer: TLoanOfficerSearchResponse) => {
  const email = loanOfficer.email ? ` <${loanOfficer.email}>` : ''

  return {
    id: loanOfficer.id,
    title: `${loanOfficer.name}${email}`,
    userId: loanOfficer.user?.id,
    companyName: loanOfficer.companyName,
    name: loanOfficer.name,
    email: loanOfficer.email,
    phoneNumber: loanOfficer.phoneNumber
  }
}

export const fetchLoanOfficersAutoComplete = async (params: TLoanOfficersAutoCompleteParams) => {
  const loanOfficersFields: Array<keyof LoanOfficerAutoCompleteResponse> = [
    'companyName',
    'email',
    'name',
    'userId'
  ]

  const loanOfficersShape: LoanOfficersAutoCompleteSchema = {
    loanOfficers: [
      ...loanOfficersFields,
      { user: ['name'] },
      { phoneNumber: ['number', 'phoneType'] },
      { partner: ['slug'] }
    ]
  }

  const response = await getRequest(
    getLoanOfficersUrl({
      searchParams: {
        ...getHapiRequestParams(loanOfficersShape),
        ...getSearchParams(params)
      }
    })
  )

  return await deserializeAsync(response.data, {
    transform: transformLoanOfficersAutoComplete
  })
}
