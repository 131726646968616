import { titleize } from '../titleize'

export const camelize = (
  sourceString: Potential<string>,
  separator: string | RegExp = /[\s_-]+/g
) => {
  if (!sourceString) {
    return ''
  }
  return sourceString
    .split(separator)
    .map((word, index) => (!index ? word.toLowerCase() : titleize(word, separator)))
    .join('')
}
