// @ts-strict

import { ComponentType, MouseEvent, ReactNode, useContext } from 'react'
import { Icon, IconProps } from 'components/Icon'
import { cx } from 'utils'
import styles from './Menu.module.scss'
import { MenuContext } from './MenuContext'

export type TMenuTitleProps = {
  children: ReactNode
  icon?: ComponentType<IconProps> | false
}

export const MenuTitle = ({ icon: IconComponent, children }: TMenuTitleProps) => {
  const [menuOpen, setMenuOpen] = useContext(MenuContext)

  const toggleMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(!menuOpen)
  }

  const renderIcon = (() => {
    if (IconComponent === false) {
      return null
    }

    if (!IconComponent) {
      const arrowIcons = menuOpen ? <Icon.UpChevron /> : <Icon.DownChevron />
      return arrowIcons
    }

    return <IconComponent className={cx(menuOpen && styles.menuOpen)} width={20} height={20} />
  })()

  return (
    <button
      className={cx(styles.title, 'menu-title')}
      type="button"
      onMouseDown={toggleMenu}
      data-testid="menuTitle"
    >
      {children}
      {renderIcon}
    </button>
  )
}
