// @ts-strict
import { MouseEvent } from 'react'
import { UseQueryResult } from 'react-query'
import { Link } from 'react-router-dom'
import { Block, Icon, Popup } from 'components'
import { PhotoUploadDeleteButtonForm } from 'content/PhotoUpload'
import { PhotoUpload } from 'models'
import { TFetchItems } from 'services/types'
import { cx, formatDate, titleize } from 'utils'
import styles from './PhotoUploadsCarousel.module.scss'
import { usePhotoUploadsCarousel } from '.'

export type TCarouselSettings = {
  index?: number
  open: boolean
}

type TProps = {
  photoUploadId: string
  photoUploadsQuery: UseQueryResult<TFetchItems<PhotoUpload>>
}

export const PhotoUploadsCarousel = ({ photoUploadId, photoUploadsQuery }: TProps) => {
  const { currentPhoto, handleCancel, previousPhotoId, nextPhotoId, isZoomedIn, toggleZoomedIn } =
    usePhotoUploadsCarousel({
      photoUploadsQuery,
      photoUploadId
    })

  if (!currentPhoto) {
    return null
  }

  const { url, name: photoTitle } = currentPhoto
  const zoomedInStyle = isZoomedIn ? styles.zoomedIn : ''

  return (
    <Popup
      title={titleize(photoTitle)}
      onCancel={handleCancel}
      className={styles.popup}
      modalClassName={styles.modal}
    >
      <Link
        to={`../${previousPhotoId}`}
        className={cx(styles.carouselArrow, styles.left)}
        data-testid="previous-photo-link"
      >
        <Icon.LeftChevron />
      </Link>

      <div className={cx(styles.popupContent, zoomedInStyle)}>
        <img
          src={url}
          className={styles.carouselImg}
          alt={currentPhoto.name}
          onClick={(e: MouseEvent<HTMLImageElement>) => {
            toggleZoomedIn(e)
          }}
        />
      </div>

      <Link
        to={`../${nextPhotoId}`}
        className={cx(styles.carouselArrow, styles.right)}
        data-testid="next-photo-link"
      >
        <Icon.RightChevron />
      </Link>

      <Block
        title={
          <a href={url} target="_blank" className={styles.footerTitle} rel="noreferrer">
            View Full Size Image
            <Icon.ExternalLink className={styles.externalLinkIcon} />
          </a>
        }
        subtitle={`Uploaded by ${currentPhoto.user?.name} on ${formatDate(
          currentPhoto.createdAt,
          'short'
        )}`}
        data-testid="photo-upload-info"
        actions={<PhotoUploadDeleteButtonForm ids={[currentPhoto.id]} />}
        className={styles.popupFooter}
      />
    </Popup>
  )
}
