// @ts-strict
import { byValue } from 'utils'
import { TaskStatus, taskStatusSettings, TTaskStatusValue } from '.'

export const lookupTaskStatus = () => {
  const taskStatuses = taskStatusSettings.map(setting => {
    return new TaskStatus(setting)
  })

  const getTaskStatus = (value: TTaskStatusValue) => {
    return taskStatuses.find(byValue(value))
  }

  const getTaskStatusName = (value: TTaskStatusValue) => {
    return getTaskStatus(value)?.name
  }

  return {
    getTaskStatusName,
    getTaskStatus,
    taskStatuses
  }
}
