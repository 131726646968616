// @ts-strict
import { useButtonForm } from 'components'
import { useDispositionAgentLeadController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import { AgentProviderLead, Lead } from 'models'
import { TFormValidation, validatePresence } from 'utils'

export type TAgentLeadDispositionButtonFormPayload = {
  delayIntroEmail?: boolean
  disposition: string
  includeAgentProfile?: boolean
  includeAgentWebsite?: boolean
  note?: string
  sendIntroFirst?: boolean
  skipAgentEmail?: boolean
}

export const useAgentLeadDispositionButtonForm = (
  agentProviderLead: AgentProviderLead,
  lead: Potential<Lead>
) => {
  const isIntroduced = agentProviderLead.introduced
  const agentLead = agentProviderLead?.agentLead
  const { updateDispositionAgent, updateDispositionAgentLead, updateDispositionProviderLead } =
    useDispositionAgentLeadController({
      agentId: agentLead?.agent?.id,
      agentLeadId: agentLead?.id,
      providerLeadId: agentProviderLead?.id,
      leadId: lead?.id,
      leadType: lead?.userType!
    })

  const { salesAppProviderLeadDispositionUrl } = useFeatureFlags([
    'sales-app-provider-lead-disposition-url'
  ])

  const mutation = (() => {
    if (salesAppProviderLeadDispositionUrl.enabled) {
      return updateDispositionProviderLead
    }
    return isIntroduced ? updateDispositionAgentLead : updateDispositionAgent
  })()

  const formValidations: TFormValidation = {
    disposition: { label: 'Disposition', validations: [validatePresence] }
  }

  const initialPayload: TAgentLeadDispositionButtonFormPayload = {
    includeAgentProfile: false,
    includeAgentWebsite: false,
    sendIntroFirst: false,
    delayIntroEmail: false,
    skipAgentEmail: false,
    disposition: agentProviderLead?.furthestStage || ''
  }

  const transformPayload = ({
    disposition,
    note,
    delayIntroEmail,
    includeAgentProfile,
    includeAgentWebsite,
    sendIntroFirst,
    skipAgentEmail
  }: TAgentLeadDispositionButtonFormPayload) => {
    if (!isIntroduced) {
      if (disposition === 'awaiting_response' || disposition === 'failed') {
        return { disposition }
      }

      if (disposition === 'introduce') {
        return {
          disposition,
          delayIntroEmail,
          includeAgentProfile,
          includeAgentWebsite,
          sendIntroFirst,
          skipAgentEmail
        }
      }
    }

    return {
      disposition,
      note
    }
  }

  const { formProps, fieldsProps } = useButtonForm<TAgentLeadDispositionButtonFormPayload>({
    transformPayload,
    initialPayload,
    formValidations,
    mutation
  })

  return {
    formProps,
    fieldsProps
  }
}
