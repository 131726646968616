import { ActivityFeedSchema, getHapiRequestParams } from 'schema'
import { getActivityFeedUrl } from 'services/getUrl'
import { TFetchItems } from 'services/types'
import { deserializeAsync, getRequest } from 'utils'
import { ActivitiesFeedParams, getSearchParams } from './getSearchParams'

export const fetchActivitiesFeed = async <Response>(
  shape: ActivityFeedSchema,
  settings: ActivitiesFeedParams
): Promise<TFetchItems<Response>> => {
  const searchParams = getHapiRequestParams(shape)
  const url = getActivityFeedUrl({
    searchParams: { ...searchParams, ...getSearchParams(settings) }
  })

  const response = await getRequest(url)
  const deserializedResponse = await deserializeAsync<Response[]>(response.data)

  return {
    items: deserializedResponse,
    pageCount: response.data.meta.total_pages,
    totalCount: response.data.meta.total_count
  }
}
