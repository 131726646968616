import { getLeadDispositionUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

type TPutLeadDispositionParams = { leadId: string; payload: {} }

export const putLeadDisposition = ({ leadId, payload }: TPutLeadDispositionParams) =>
  putRequest(
    getLeadDispositionUrl({
      id: leadId
    }),
    snakeCaseKeys(payload)
  )
