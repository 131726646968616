// @ts-strict

import { Table, TableResults } from 'components'
import { useListContext } from 'contexts'
import { FileUpload } from 'models'
import { FilesTableHeader, FilesTableRow } from '.'

export const FilesTable = ({ fileUploads = [] }: { fileUploads?: FileUpload[] }) => {
  const { showColumn } = useListContext()
  return (
    <Table data-testid="files-table">
      <FilesTableHeader />
      <TableResults type="Files" colSpan={showColumn('incomingResidence') ? 5 : 3}>
        {fileUploads?.map(file => (
          <FilesTableRow key={file.id} file={file} />
        ))}
      </TableResults>
    </Table>
  )
}
