import { useEmployeeUsers } from 'controllers'
import { useFeatureFlags } from 'hooks'
import {
  ccTradeInLeadStagesOptions,
  ccTradeInLeadStagesOptionsWithoutPitched,
  sourcesOptions,
  stateNameOptions,
  trueFalseOptions,
  useCapitalPartners
} from 'lookups'
import { titleize } from 'utils'
import { buildSearchFilters, findFilterBySlug } from '../utils'
import { ccTradeInLeadSearchFilterSettings } from './ccTradeInLeadSearchFilterSettings'

export const useCCTradeInLeadSearchFilter = () => {
  const { capitalPartnersOptions } = useCapitalPartners()
  const { getUserName } = useEmployeeUsers()
  const { leftMessageStage } = useFeatureFlags(['left-message-stage'])

  const stageOptions = leftMessageStage.enabled
    ? ccTradeInLeadStagesOptionsWithoutPitched
    : ccTradeInLeadStagesOptions

  const optionLoaders = {
    stage: stageOptions,
    capitalPartnerId: capitalPartnersOptions,
    source: sourcesOptions,
    stateName: stateNameOptions,
    trueFalse: trueFalseOptions
  }
  const displayValueFnLoaders = {
    titleize,
    getUserName
  }

  const ccTradeInFilters = buildSearchFilters(ccTradeInLeadSearchFilterSettings, {
    optionLoaders,
    displayValueFnLoaders
  })

  const findCCTradeInLeadFilterBySlug = findFilterBySlug(ccTradeInFilters)

  return { ccTradeInFilters, findFilterBySlug: findCCTradeInLeadFilterBySlug }
}
