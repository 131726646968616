import { forwardRef } from 'react'
import { Button, ButtonProps } from '@foundation/components'

type Props = Pick<ButtonProps, 'children' | 'onClick' | 'iconRight'>

export const ListFiltersTriggerButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, onClick, iconRight }, ref) => {
    return (
      <Button ref={ref} onClick={onClick} iconRight={iconRight} color="neutralLight" size="lg">
        {children}
      </Button>
    )
  }
)
