import { Investor } from 'models'
import { getInvestorsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchInvestor = async (id: string): Promise<Investor> => {
  const url = getInvestorsUrl({ id })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, {
    transform: Investor.create
  })
}
