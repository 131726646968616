import { useEffectOnce } from 'react-use'
import { ParamsProvider } from 'contexts'
import { InfiniteActivityList } from 'features/activities/commons'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import { useInfiniteFetchThisAgentActivities } from '../hooks'

const Content = ({ agentId }: { agentId: string }) => {
  const { tracking } = useCustomEvents()
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteFetchThisAgentActivities({
      searchParams: { agentId }
    })
  const isLoading = isFetching || isFetchingNextPage

  useEffectOnce(() => {
    tracking({
      name: 'drawer_view',
      params: { trigger: 'click', location: 'agent', target: 'activity' }
    })
  })

  return (
    <InfiniteActivityList
      isLoading={isLoading}
      data={data}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
    />
  )
}

export const ListAgentActivities = ({ agentId }: { agentId: string }) => {
  return (
    <ParamsProvider key="thisAgent">
      <Content agentId={agentId} />
    </ParamsProvider>
  )
}
