import { TSearchFilterSetting } from 'lookups'

export type TAllowedGetDisplayValueFnName =
  | 'getUserName'
  | 'titleize'
  | 'formatPrice'
  | 'getOpportunityNameFromId'
  | 'getLeadStageNameFromSlug'
  | 'getCompletionStatus'
  | 'getAssignedTo'

export type TLeadSearchFilterSettings = Record<
  TSearchFilterSetting['slug'],
  TSearchFilterSetting<string, TAllowedGetDisplayValueFnName, string, string>
>

export const leadSearchFilterAttributes: TLeadSearchFilterSettings = {
  assigneeId: {
    slug: 'assigneeId',
    name: 'Assigned User',
    getDisplayValueFnName: 'getUserName'
  },
  capitalPartnerId: {
    slug: 'capitalPartnerId',
    name: 'Capital Markets Facility',
    optionsKey: 'capitalPartnerId'
  },
  lastStageUpdate: {
    slug: 'lastStageUpdate',
    name: 'Stage Update Within',
    optionsKey: 'lastStageUpdate',
    getDisplayValueFnName: 'titleize'
  },
  escrowAttached: {
    slug: 'escrowAttached',
    name: 'Escrow Attachment',
    optionsKey: 'trueFalse'
  },
  expressClose: {
    slug: 'expressClose',
    name: 'Express Close',
    optionsKey: 'trueFalse'
  },
  listingStatus: {
    slug: 'listingStatus',
    name: 'Listing Statuses',
    optionsKey: 'listingStatus',
    getDisplayValueFnName: 'titleize'
  },
  maxPrice: {
    slug: 'maxPrice',
    name: 'Max Price Range',
    getDisplayValueFnName: 'formatPrice',
    placeholder: 'With max price'
  },
  minPrice: {
    slug: 'minPrice',
    name: 'Min Price Range',
    getDisplayValueFnName: 'formatPrice',
    placeholder: 'With min price'
  },
  needsListingReview: {
    slug: 'needsListingReview',
    name: 'Needs Listing Review',
    optionsKey: 'trueFalse',
    getDisplayValueFnName: 'titleize'
  },
  oppStatus: {
    slug: 'oppStatus',
    name: 'Opp Status',
    optionsKey: 'oppStatus',
    getDisplayValueFnName: 'getOpportunityNameFromId'
  },
  protectedOfferStatus: {
    slug: 'protectedOfferStatus',
    name: 'Protected Offer',
    optionsKey: 'protectedOfferStatus'
  },
  source: {
    slug: 'source',
    name: 'Sources',
    optionsKey: 'source'
  },
  stage: {
    slug: 'stage',
    name: 'Stages',
    getDisplayValueFnName: 'getLeadStageNameFromSlug',
    optionsKey: 'stage',
    serverFilter: 'filter[stage]'
  },
  stageFurthest: {
    slug: 'stageFurthest',
    name: 'Furthest Stages',
    getDisplayValueFnName: 'getLeadStageNameFromSlug',
    optionsKey: 'furthestStage',
    serverFilter: 'filter[furthest_stage]'
  },
  twoChecks: {
    slug: 'twoChecks',
    name: '2 Checks',
    optionsKey: 'trueFalse'
  },
  userType: {
    slug: 'userType',
    name: 'Lead Type',
    optionsKey: 'userType'
  },
  tradeInPlus: {
    slug: 'tradeInPlus',
    name: 'Trade-In+',
    optionsKey: 'trueFalse'
  },
  pendingActions: {
    slug: 'pendingActions',
    name: 'Pending Actions',
    optionsKey: 'pendingActions'
  },
  completionStatus: {
    slug: 'completionStatus',
    name: 'Completion Status',
    optionsKey: 'completionStatus'
  },
  escrowOfficer: {
    slug: 'escrowOfficer',
    name: 'Escrow Officer',
    getDisplayValueFnName: 'getUserName',
    optionsKey: 'escrowOfficer'
  },
  productEligibility: {
    name: 'Product Eligibility',
    slug: 'productEligibility',
    optionsKey: 'productEligibility'
  },
  stateName: {
    name: 'State',
    slug: 'stateName',
    optionsKey: 'stateName'
  }
}
