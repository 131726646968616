import { BlendUserInterface } from 'models/v2/BlendUser'
import { getBlendUsersUrl } from 'services/getUrl'
import { TFetchItems } from 'services/types'
import { deserializeAsync, getRequest, removeAttributesWithNullValues } from 'utils'

export const fetchBlendUsers = async <Response extends BlendUserInterface>(
  roles: string
): Promise<TFetchItems<Response>> => {
  const filterParams = removeAttributesWithNullValues({
    roles: roles
  })

  const response = await getRequest(
    getBlendUsersUrl({
      searchParams: filterParams
    })
  )

  const deserializedResponse = await deserializeAsync<Response[]>({
    data: {
      ...response.data,
      attributes: response.data
    }
  })

  return {
    items: deserializedResponse,
    pageCount: 0,
    totalCount: deserializedResponse.length
  }
}
