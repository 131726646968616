import { ReactNode, useContext, useRef } from 'react'
import { Coaster } from 'components/Coaster'
import { useClickAway } from 'hooks'
import { cx } from 'utils'
import styles from './Menu.module.scss'
import { MenuContext } from './MenuContext'

type MenuItemsProps = {
  children: ReactNode
  className?: string
  justify?: 'left' | 'right'
  title?: string
}

export const MenuItems = ({
  title,
  children,
  className,
  justify = 'right',
  ...props
}: MenuItemsProps) => {
  const [menuOpen, setMenuOpen] = useContext(MenuContext)
  const menuRef = useRef(null)

  const closeMenu = () => {
    setMenuOpen(false)
  }

  useClickAway(menuRef, closeMenu, !menuOpen)

  if (!menuOpen) {
    return null
  }
  return (
    <Coaster {...props} className={cx(className, justify)} ref={menuRef} data-testid="menuItems">
      {title ? <div className={styles.title}>{title}</div> : null}
      {children}
    </Coaster>
  )
}
