import { getCheckoutSessionsUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export const postSetupCheckoutSession = async (payload: any) => {
  const url = getCheckoutSessionsUrl({ action: 'setup' })
  return await postRequest(url, snakeCaseKeys({ ...payload }))
}

export const postSendSetupCheckoutSessionMagicLink = async (payload: any) => {
  const url = getCheckoutSessionsUrl({ action: 'send-setup-session-magic-link' })
  await postRequest(url, snakeCaseKeys({ ...payload }))
}
