import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react'
import { Spinner } from 'components/Graphic'
import { cx } from 'utils'
import styles from './Button.module.scss'

export type ButtonTypes =
  | 'action'
  | 'cancel'
  | 'create'
  | 'download'
  | 'external'
  | 'icon'
  | 'iconInverted'
  | 'link'
  | 'underscore'
  | 'menuButton'
  | 'primary'
  | 'secondary'
  | 'submit'
  | 'tab'
  | 'tertiary'
  | 'toggle'
  | 'wrapper'

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  as?: ButtonTypes
  children?: ReactNode
  className?: string
  disabled?: boolean
  icon?: ReactNode
  iconPosition?: 'start' | 'end'
  large?: boolean
  size?: 'large' | 'medium' | 'small' | 'xsmall'
  submitting?: boolean
  text?: string
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      children,
      text,
      className,
      as,
      large,
      size,
      submitting,
      disabled,
      icon,
      iconPosition = 'end',
      ...props
    },
    ref
  ) => {
    let asClass = null
    let dataTestId = ''
    let buttonType: 'button' | 'submit' | 'reset' = 'button'

    switch (as) {
      case 'create':
        asClass = styles.create
        dataTestId = 'create-button'
        break
      case 'primary':
        asClass = styles.primary
        dataTestId = 'primary-button'
        break
      case 'submit':
        asClass = styles.primary
        dataTestId = 'submit-button'
        buttonType = 'submit'
        break
      case 'cancel':
        asClass = styles.transparent
        dataTestId = 'cancel-button'
        break
      case 'download':
        asClass = styles.download
        dataTestId = 'download-button'
        break
      case 'action':
        asClass = styles.action
        dataTestId = 'action-button'
        break
      case 'link':
        asClass = styles.link
        dataTestId = 'link-button'
        break
      case 'underscore':
        asClass = styles.underscore
        dataTestId = 'underscore-button'
        break
      case 'tab':
        dataTestId = 'tab-button'
        break
      case 'secondary':
        asClass = styles.secondary
        dataTestId = 'secondary-button'
        break
      case 'tertiary':
        asClass = styles.tertiary
        dataTestId = 'tertiary-button'
        break
      case 'menuButton':
        asClass = styles.menuButton
        dataTestId = 'menu-button'
        break
      case 'icon':
        asClass = styles.icon
        dataTestId = 'icon-button'
        break
      case 'iconInverted':
        asClass = styles.iconInverted
        dataTestId = 'icon-inverted-button'
        break
      case 'wrapper':
        asClass = styles.wrapper
        dataTestId = 'wrapper-button'
        break
      case 'toggle':
        asClass = styles.toggle
        dataTestId = 'toggle-button'
        break
      default:
        asClass = null
        dataTestId = 'button'
    }

    const sizeClass = (() => {
      if (large || size === 'large') {
        return styles.large
      }
      if (size === 'medium') {
        return styles.medium
      }
      if (size === 'small') {
        return styles.small
      }
      if (size === 'xsmall') {
        return styles.xsmall
      }
      return null
    })()

    const spinnerHasNoSibling = !text && !children && styles.spinnerNoSibling
    const spinnerHasSibling = (text || children) && styles.spinner

    return (
      <button
        type={buttonType}
        data-testid={dataTestId}
        disabled={disabled || submitting}
        className={cx(styles.button, sizeClass, asClass, className)}
        ref={ref}
        {...props}
      >
        {!submitting && iconPosition === 'start' ? icon : null}
        {submitting ? (
          <Spinner height={16} width={16} className={cx(spinnerHasSibling, spinnerHasNoSibling)} />
        ) : null}
        {text}
        {children}
        {!submitting && iconPosition === 'end' ? icon : null}
      </button>
    )
  }
)
