import { Employee } from 'models'
import { EmployeesSchema, getHapiRequestParams } from 'schema'
import { getUsers2Url } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const employeeShape: EmployeesSchema = {
  user: [
    'archived',
    'confirmationSentAt',
    'confirmedAt',
    'createdAt',
    'currentSignInAt',
    'currentSignInIp',
    'email',
    'firstName',
    'lastName',
    'lastSignInAt',
    'lastSignInIp',
    'name',
    'pictureUrl',
    'pureCloudUserId',
    'region',
    'signInCount',
    'updatedAt',
    {
      phoneNumbers: ['number', 'phoneType']
    },
    {
      roles: ['name']
    },
    {
      teamMemberships: ['role', { team: ['name', 'type'] }]
    },
    {
      userIntegrations: ['name', 'value']
    }
  ]
}

export const fetchEmployee = async (id: string) => {
  const searchParams = getHapiRequestParams(employeeShape)
  const url = getUsers2Url({ id, searchParams })

  const response = await getRequest(url)
  const employee = await deserializeAsync(response.data, {
    transform: Employee.create
  })

  return employee
}
