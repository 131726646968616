// @ts-strict
import { Option } from 'classes'
import { IOptionable } from 'lookups'
import { TaskCategoryResponse } from 'schema'

export class TaskCategory implements IOptionable {
  id?: string
  name?: string
  slug?: string

  constructor(attributes?: Omit<TaskCategory, 'toOption'>) {
    Object.assign(this, attributes)
  }

  static create(taskCategoryResponse: Potential<TaskCategoryResponse>): Potential<TaskCategory> {
    if (!taskCategoryResponse) {
      return
    }

    return new TaskCategory(taskCategoryResponse)
  }

  toOption() {
    return new Option({ name: this.name, value: this.id })
  }
}
