import { TColumnAttributes } from '../../Column'

export const orderColumns: Record<string, TColumnAttributes> = {
  businessUnit: {
    name: 'Business Unit',
    value: 'businessUnit',
    isReorderable: false
  },
  clientName: {
    name: 'Client / Partner',
    value: 'clientName',
    isReorderable: false
  },
  orderStageSource: {
    name: 'Order',
    value: 'orderStageSource',
    isReorderable: true
  },
  property: {
    name: 'Property',
    value: 'property',
    isReorderable: true
  },
  lead: {
    name: 'Lead',
    value: 'lead',
    isReorderable: true
  },
  price: {
    name: 'Price',
    value: 'price',
    isReorderable: true
  },
  stage: {
    name: 'Stage / Furthest',
    value: 'stage',
    isReorderable: true
  },
  listingStatus: {
    name: 'Listing Status',
    value: 'listingStatus',
    isReorderable: true
  },
  agentsOpps: {
    name: 'Agents / Opps',
    value: 'agentsOpps',
    isReorderable: true
  },
  transactionTeam: {
    name: 'Tx Team',
    value: 'transactionTeam',
    isReorderable: true
  },
  gppVal: {
    name: 'GPP / Val',
    value: 'gppVal',
    isReorderable: true
  },
  coe: {
    name: 'COE Pur / Sale',
    value: 'coe',
    isReorderable: true
  },
  daysOwned: {
    name: 'Days Owned',
    value: 'daysOwned',
    isReorderable: true
  },
  fundingDate: {
    name: 'Funding Date',
    value: 'fundingDate',
    isReorderable: true
  },
  createdAt: {
    name: 'Created',
    value: 'createdAt',
    isReorderable: true
  },
  updatedAt: {
    name: 'Updated',
    value: 'updatedAt',
    isReorderable: true
  },
  introSentAt: {
    name: 'Intro Sent At',
    value: 'introSentAt',
    isReorderable: true
  }
}

export const ordersColumnsSettings = [
  orderColumns.businessUnit,
  orderColumns.clientName,
  orderColumns.property,
  orderColumns.lead,
  orderColumns.stage,
  orderColumns.createdAt,
  orderColumns.updatedAt
] as const

export type TOrdersColumnValue = (typeof ordersColumnsSettings)[number]['value']
