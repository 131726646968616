// @ts-strict
import * as SliderPrimitive from '@radix-ui/react-slider'
import { css, styled } from '@foundation/themes/styles'

type DisabledProps = {
  disabled: boolean
}

const disabledStyle = `
  cursor: not-allowed;
`

export const Wrapper = styled.div<DisabledProps>`
  ${({ disabled, theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.space.$4};

    ${disabled && disabledStyle}
  `}
`

export const Slider = styled(SliderPrimitive.Root)`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  user-select: none;
  touch-action: none;

  &[data-orientation='horizontal'] {
    height: inherit;
  }
`

export const SliderTrack = styled(SliderPrimitive.Track)<DisabledProps>`
  ${({ disabled, theme }) => css`
    background-color: ${theme.colors.neutral5};
    position: relative;
    flex-grow: 1;
    border-radius: ${theme.radii.rounded};
    cursor: pointer;

    &[data-orientation='horizontal'] {
      height: ${theme.sizes.$2};
    }

    ${disabled && disabledStyle}
  `}
`

export const SliderRange = styled(SliderPrimitive.Range)`
  ${({ theme }) => css`
    position: absolute;
    background-color: ${theme.colors.primary5};
    border-radius: ${theme.radii.rounded};
    height: 100%;
  `}
`

export const SliderThumb = styled(SliderPrimitive.Thumb)`
  ${({ theme }) => css`
    all: unset;
    display: block;
    width: ${theme.sizes.$6};
    height: ${theme.sizes.$6};
    background-color: ${theme.colors.white};
    border-radius: ${theme.radii.rounded};
    border: 1px solid ${theme.colors.neutral5};
    cursor: pointer;

    &:hover {
      scale: calc(1.1);
    }
  `}
`
