import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SearchParams, TViewParams } from '@types'
import { ParamsContextType } from 'contexts/types'
import { useDeepCompareEffect, useLocation } from 'hooks'
import { getSearchParam, presence, queryStringToObject, sortDirection } from 'utils'
import { getViewParams, getViewParamsQueryString, omitViewParams } from '../useParams/utils'

type Params = Partial<{
  [key: string]: string | string[] | number
}> &
  SearchParams &
  TViewParams

const emptyArray = []

export const useQueryStringParams = (
  defaultParams: Record<string, string> = {},
  initialParams: Record<string, string> = {}
): ParamsContextType => {
  const { search, pathname } = useLocation()
  const navigate = useNavigate()
  const [params, setParamsState] = useState<Params>({})
  const searchParams = useMemo(() => omitViewParams(queryStringToObject(search)), [search])
  const viewParams = useMemo(() => getViewParams(queryStringToObject(search)), [search])

  useDeepCompareEffect(() => {
    setParamsState({ ...defaultParams, ...queryStringToObject(search) })
  }, [defaultParams, search])

  const setParams = useCallback(
    (newParams = {}) => {
      const newSearch = getSearchParam(newParams)
      navigate(`${pathname}${newSearch}`)
    },
    [navigate, pathname]
  )

  const updateParams = useCallback(
    (newParams: Params = {}) => {
      setParams({ ...params, ...newParams })
    },
    [params, setParams]
  )

  const updateParam = useCallback(
    (name, value) => {
      updateParams({ [name]: value })
    },
    [updateParams]
  )

  const resetParam = useCallback(name => updateParams({ [name]: undefined }), [updateParams])

  const resetParams = useCallback(() => {
    const viewParamQueryString = getViewParamsQueryString(params)
    navigate(`${pathname}${viewParamQueryString}`)
  }, [navigate, pathname, params])

  const initializeParams = useCallback(() => {
    const initialSearch = getSearchParam(initialParams)
    navigate(`${pathname}${initialSearch}`)
  }, [navigate, pathname, initialParams])

  const updateSearchTerm = searchTerm =>
    updateParams({
      page: undefined,
      searchTerm
    })

  const updatePage = (pageNumber: number) => {
    if (pageNumber === 1) {
      return resetParam('page')
    }

    return updateParam('page', String(pageNumber))
  }

  const updateSort = (sortBy: string, defaultDir: SortDirection = 'asc') => {
    const sortDir = sortDirection(params.sortBy, params.sortDir, sortBy, defaultDir)
    updateParams({ ...params, sortBy, sortDir })
  }

  const updateFilters = useCallback(
    (newParams: {} | Record<string, string | string[]> = {}) => {
      updateParams({
        ...newParams,
        page: undefined
      })
    },
    [updateParams]
  )

  const updateViews = useCallback(
    (newParams: {} | Record<string, string | string[]> = {}) => {
      updateParams(newParams)
    },
    [updateParams]
  )

  const removeValueFromParam = (param: string, value: string) => {
    const paramValue = params[param]
    if (typeof paramValue === 'string' && value === paramValue) {
      resetParam(param)
    }
    if (Array.isArray(paramValue)) {
      const filteredValues = paramValue?.filter(paramValue => paramValue !== value)
      presence(filteredValues) ? updateParam(param, filteredValues) : resetParam(param)
    }
  }

  const viewColumns: string[] = presence(params?.viewColumns) || emptyArray

  const setViewColumns = (val: string[] | undefined) => {
    val ? updateParam('viewColumns', val) : resetParam('viewColumns')
  }

  const isSelectedColumn = (val: string) => {
    return viewColumns.includes(val)
  }

  return {
    defaultParams,
    searchParams,
    params,
    initializeParams,
    initialParams,
    isSelectedColumn,
    updateParam,
    updateParams,
    removeValueFromParam,
    resetParams,
    resetParam,
    setViewColumns,
    updateSearchTerm,
    updatePage,
    updateSort,
    updateFilters,
    updateViews,
    viewColumns,
    viewParams
  }
}
