// @ts-strict
import { Icon } from '@foundation/components'
import { Ellipsis } from 'components/Ellipsis'
import { cx, presence, toCamelCase } from 'utils'
import legacyStyles from '../Form.module.scss'
import { BlurrableValue, UnblurButton, ValueWrapper } from './styles'
import { FormFieldProps } from './types'

export const FormField = ({
  children,
  title,
  htmlFor,
  className,
  inline,
  required,
  note,
  hint,
  error,
  description,
  valueSubtitle,
  onClickViewSensitiveData,
  noWrap,
  ...props
}: FormFieldProps) => {
  const requiredStar = required ? <span className={legacyStyles.required}>*</span> : null
  const value = (
    <span
      data-testid={`${toCamelCase(title)}-value`}
      className={cx(legacyStyles.displayValue, 'formFieldDisplayValue')}
    >
      {children}
    </span>
  )
  const shouldBlur = !!onClickViewSensitiveData

  if (htmlFor && inline) {
    return (
      <div
        className={cx(
          legacyStyles.field,
          legacyStyles.inlineMode,
          noWrap && legacyStyles.noWrap,
          className
        )}
        data-testid={`${toCamelCase(title)}-form-field`}
        {...props}
      >
        {value}
        <label
          htmlFor={htmlFor}
          data-testid={`${toCamelCase(title)}-label`}
          className={legacyStyles.label}
          title={description || title}
        >
          <Ellipsis>{title}</Ellipsis>
          {requiredStar}
        </label>
      </div>
    )
  }

  const hintOrError = (
    <>
      {presence(error) && (
        <span
          className={cx(legacyStyles.hint, legacyStyles.errorMsg, legacyStyles.show)}
          data-testid="errorMessage"
        >
          {error}
        </span>
      )}
      {presence(hint) && <span className={cx(legacyStyles.hint, legacyStyles.show)}>{hint}</span>}
    </>
  )

  return (
    <div
      className={cx(
        legacyStyles.field,
        className,
        inline && legacyStyles.inlineMode,
        noWrap && legacyStyles.noWrap,
        error ? legacyStyles.error : null
      )}
      data-testid={`${toCamelCase(title)}-form-field`}
      {...props}
    >
      <label
        htmlFor={htmlFor}
        data-testid={`${toCamelCase(title)}-label`}
        className={legacyStyles.label}
      >
        {title}
        {requiredStar}
        {note && <span className={cx(legacyStyles.note, legacyStyles.inline)}>{note}</span>}
      </label>

      <div className={valueSubtitle ? legacyStyles.value : undefined}>
        <ValueWrapper isBlurred={shouldBlur}>
          <BlurrableValue
            shouldBlur={shouldBlur}
            data-testid={`${toCamelCase(title)}-blurrable-value`}
          >
            {value}
          </BlurrableValue>
          {valueSubtitle}
        </ValueWrapper>

        {shouldBlur && (
          <UnblurButton onClick={() => onClickViewSensitiveData()}>
            <Icon path="VisibilityOutlined" />
          </UnblurButton>
        )}
      </div>
      {hintOrError}
    </div>
  )
}
