import { Link } from 'react-router-dom'
import { Graphic, Icon, LoadingText } from 'components'
import { PhotoUpload } from 'models'
import { cx } from 'utils'
import styles from './PhotoUploadGridItem.module.scss'

type TProps = {
  isSelected?: boolean
  onSelect: () => void
  photo: PhotoUpload
}

export const PhotoUploadGridItemPlaceholder = () => (
  <div className={cx(styles.item, styles.placeholder)}>
    <Graphic.Logo />
    <LoadingText text="Processing photo" />
  </div>
)

export const PhotoUploadGridItem = ({ photo, isSelected, onSelect }: TProps) => {
  return photo.isProcessing ? (
    <PhotoUploadGridItemPlaceholder />
  ) : (
    <div className={styles.item}>
      <Link to={photo.id} className={styles.link}>
        <img
          src={photo.thumbUrl}
          alt={photo.name}
          className={cx(styles.image, isSelected && styles.selected)}
        />
      </Link>
      <Icon.CheckWithCircle
        className={cx(styles.selectIcon, isSelected && styles.selected)}
        title="Select Image"
        onClick={onSelect}
      />
    </div>
  )
}
