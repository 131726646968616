// @ts-strict
import { EmptyTableCell, TableCellAlignments, TableRow } from 'components'
import { useListContext } from 'contexts'
import { cx } from 'utils'

export function SmartTableRow<T extends BaseTableItem>({ value, columnMapping }: RowProps<T>) {
  const { highlightRows, visibleColumns, getColumnAlignment } = useListContext()
  const rowClassName = highlightRows?.includes(value.id) && 'highlight'

  return (
    <TableRow className={cx(rowClassName)} data-testid={`item-id-${value.id}`}>
      {visibleColumns.map(visibleColumn => {
        const Cell = columnMapping[visibleColumn]
        const testId = `${visibleColumn}Cell`
        const align = getColumnAlignment(visibleColumn)

        return Cell ? (
          <Cell
            value={value}
            column={visibleColumn}
            key={visibleColumn}
            testId={testId}
            align={align}
          />
        ) : (
          <EmptyTableCell key={visibleColumn} align={align} />
        )
      })}
    </TableRow>
  )
}

type ColumnMapping<T extends BaseTableItem> = Record<
  string,
  ({
    value,
    testId,
    column,
    key,
    align
  }: {
    align: TableCellAlignments | undefined
    column: string
    key: string
    testId: string
    value: T
  }) => JSX.Element
>

type RowProps<T extends BaseTableItem> = {
  columnMapping: ColumnMapping<T>
  value: T
}

type BaseTableItem = {
  id: string
}
