import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Spinner, useToastContext } from '@foundation/components'
import { Table, TColumn, useTable } from '@foundation/components/Table'
import { CheckBoxField } from 'components'
import { deserializeAsync, makeRequest } from 'utils'
import { Container } from './styles'

type CA = {
  caRoundRobinAvailability: false
  created_at: string
  email: string
  id: string
  name: string
  picture_url: string
}

type UpdateCAPayload = {
  id: string
  isAvailable: boolean
}

const GET_CA_LIST = 'GET_CA_LIST'
const BASE_URL = process.env.REACT_APP_API_ROOT

export const All = () => {
  const [mutationId, setMutationId] = useState<string | null>(null)

  const client = useQueryClient()
  const table = useTable()
  const toast = useToastContext()

  const { data, isLoading: isQueryLoading } = useQuery<CA[]>(
    [GET_CA_LIST],
    async () => {
      const URL = BASE_URL + '/user-data-service/users/cas?fields[user]=ca_round_robin_availability'

      const { data } = await makeRequest(URL)
      const deserialized = await deserializeAsync(data)

      return deserialized
    },
    {
      onError: error => {
        console.error(error)
        toast({
          title: 'Ops! Something went wrong.',
          status: 'error'
        })
      }
    }
  )

  const { mutate, isLoading: isMutationLoading } = useMutation(
    ({ id, isAvailable }: UpdateCAPayload) => {
      const URL = BASE_URL + '/user-data-service/users/update_ca_availability'

      return makeRequest(URL, {
        method: 'POST',
        data: {
          user_id: id,
          availability: isAvailable ? 'true' : 'false'
        }
      })
    },
    {
      onMutate: ({ id, isAvailable }) => {
        const previousCas = client.getQueryData(GET_CA_LIST)
        client.setQueryData(GET_CA_LIST, (old: CA[]) => {
          return old.map(ca =>
            ca.id === id ? { ...ca, caRoundRobinAvailability: isAvailable } : ca
          )
        })
        return { previousCas }
      },
      onSuccess: () => {
        client.invalidateQueries(GET_CA_LIST)
        setMutationId(null)
      },
      onError: (error, _payload, context) => {
        client.setQueryData(GET_CA_LIST, context.previousCas)
        console.error(error)

        toast({
          title: 'Ops! Something went wrong.',
          status: 'error'
        })
      }
    }
  )

  const columns: TColumn<CA>[] = [
    {
      label: 'Name',
      render: row => row.name
    },
    {
      label: 'Email',
      render: row => row.email
    },
    {
      label: 'Available?',
      render: row =>
        isMutationLoading && mutationId === row.id ? (
          <Spinner size="xs" />
        ) : (
          <CheckBoxField
            checked={row.caRoundRobinAvailability}
            onChange={e => mutate({ id: row.id, isAvailable: e.target.checked })}
          />
        )
    }
  ]

  return (
    <Container>
      <Table.Root {...table}>
        <Table.View>
          <Table.Heading title="CA" />
          <Table.Data rows={data || []} columns={columns} isLoading={isQueryLoading} />
        </Table.View>
      </Table.Root>
    </Container>
  )
}
