// @ts-strict
import { Order } from 'models'
import { stringifyValue } from 'utils'
import { HLSSLeadDetailsProps } from '../../HLSSLeadDetails'

export const getOrderInputProps = (
  order: Order,
  updateOrder: HLSSLeadDetailsProps['updateOrder']
) => {
  const handleUpdateOrder = (key: string, val: string) => {
    updateOrder({
      [key]: val
    })
  }

  const getProps = (key: string) => {
    const value = order ? stringifyValue(order[key]) : ''

    return {
      value,
      onBlur: (val: any) => {
        handleUpdateOrder(key, val)
      }
    }
  }

  return {
    getProps
  }
}
