export const attachLeadColumnsSettings = [
  {
    name: 'Type',
    value: 'type'
  },
  {
    name: 'Name',
    value: 'name'
  },
  {
    name: 'Contact Info',
    value: 'contactInfo'
  },
  {
    name: 'Address',
    value: 'address'
  },
  {
    name: 'Stage',
    value: 'stage'
  },
  {
    name: 'Created At',
    value: 'createdAt'
  },
  {
    name: 'Action',
    value: 'action'
  }
] as const

export type TAttachLeadColumnValue = (typeof attachLeadColumnsSettings)[number]['value']
