// @ts-strict
import {
  FormField,
  InlineEditAddressUnit,
  InlineEditDateInput,
  MapBox,
  Section,
  Sections
} from 'components'
import { useCcTradeInLeadFields } from 'lookups'
import { CCTradeInLeadDetailsProps } from '../CCTradeInLeadDetails'
import { getTradeInLeadInputProps } from './utils'

type Props = Pick<CCTradeInLeadDetailsProps, 'lead' | 'updateTradeInLead'>

export const IRPropertyDetailsSection = ({ updateTradeInLead, lead }: Props) => {
  const tradeInLead = lead.tradeInProviderLead?.tradeInLead
  const incomingMasterProperty = tradeInLead?.incomingMasterProperty
  const id = tradeInLead?.id || ''
  const { getProps } = getTradeInLeadInputProps(tradeInLead, updateTradeInLead)
  const { getCcTradeInLeadFieldName } = useCcTradeInLeadFields()

  return (
    <Sections title="IR Property Information">
      <Section>
        <FormField title={getCcTradeInLeadFieldName('displayAddress')} inline>
          <InlineEditAddressUnit
            onBlur={val =>
              updateTradeInLead.mutate({
                id,
                payload: {
                  incoming_property_full_address: val
                }
              })
            }
            value={incomingMasterProperty?.displayAddress}
          />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('incomingPropertyCloseOfEscrowAt')} inline>
          <InlineEditDateInput {...getProps('incomingPropertyCloseOfEscrowAt')} />
        </FormField>
      </Section>
      <Section>
        {incomingMasterProperty?.latitude && incomingMasterProperty?.longitude ? (
          <MapBox
            center={incomingMasterProperty?.point}
            markers={[incomingMasterProperty?.marker]}
          />
        ) : (
          <FormField title="Map" inline>
            Latitude and Longitude are missing
          </FormField>
        )}
      </Section>
    </Sections>
  )
}
