// @ts-sctrict
import { useEffect, useRef, useState } from 'react'

// This hook is used to register and unregister the update functions for the lead groupable definitions.
// Since each lead groupable definition is a record, we need a way to ensure that all the updates are saved together.
// Definitions register their update function and record id
// When the save button is clicked, all the update functions are called
export const useLeadGroupableDefinitionUpdateRegister = () => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  const updateDefinitionMap = useRef<Record<number, () => void>>({})
  const [updateTrigger, setUpdateTrigger] = useState(0)

  const registerUpdateDefinition = (id: number) => (updateDefinition: () => void) => {
    updateDefinitionMap.current[id] = updateDefinition
    setUpdateTrigger(updateTrigger + 1)
  }

  const unregisterUpdateDefinition = (id: number) => () => {
    delete updateDefinitionMap.current[id]
    setUpdateTrigger(updateTrigger + 1)
  }

  const handleSaveDefinitions = async () => {
    const updatePromises = Object.values(updateDefinitionMap.current).map(updateDefinition =>
      updateDefinition()
    )

    try {
      await Promise.all(updatePromises)
    } catch (error) {
      alert(error)
    } finally {
      updateDefinitionMap.current = {}
    }
  }

  useEffect(() => {
    const hasChanges = Object.values(updateDefinitionMap.current).length > 0

    setHasUnsavedChanges(hasChanges)
  }, [updateTrigger])

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      const hasChanges = Object.values(updateDefinitionMap.current).length > 0

      if (hasChanges) {
        event.preventDefault()
      }
    }

    window.addEventListener('beforeunload', onBeforeUnload)
  }, [])

  return {
    hasUnsavedChanges,
    registerUpdateDefinition,
    unregisterUpdateDefinition,
    handleSaveDefinitions
  }
}
