import * as S from './styles'
import type { CommonProps } from './types'

export type HeadingProps = CommonProps

export const Heading = ({
  children,
  as = 'h1',
  color = 'primary10',
  hasEllipsis,
  size = 'xl2'
}: HeadingProps) => {
  return (
    <S.Heading as={as} color={color} hasEllipsis={hasEllipsis} size={size}>
      {children}
    </S.Heading>
  )
}
