// @ts-strict
import { useQuery } from 'hooks'
import { fetchNylasEnrollmentUrl } from 'services'
import { NYLAS_CACHE_KEY } from '../index'

export const useNylasController = () => {
  const nylasEnrollmentUrlQuery = useQuery(NYLAS_CACHE_KEY, () => fetchNylasEnrollmentUrl())

  return {
    nylasEnrollmentUrl: nylasEnrollmentUrlQuery?.data?.data.redirect_url
  }
}
