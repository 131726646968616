// @ts-strict
import { useToastContext } from '@foundation/components'
import { TaskCompleteButtonForm, TaskEditButtonForm, TaskReopenAction } from 'content/Task'
import { TaskDismissButtonForm } from 'content/Task/TaskDismissButtonForm'
import { useCurrentUserContext } from 'contexts'
import { Task } from 'models'
import { idMatch } from 'utils'

type TProps = { onSuccessCallback?: Function; task: Task }

export const TaskActions = ({ task, onSuccessCallback }: TProps) => {
  const { currentUser } = useCurrentUserContext()
  const showReopenTaskButton = !!task.completedAt || !!task.dismissedAt
  const authenticatedUser = task.assignee && idMatch(task.assignee.id, currentUser?.id)
  const thirdPartyTask = task.type === 'AgentTask' || task.type === 'ClientTask'

  const canCompleteTask = authenticatedUser || thirdPartyTask

  const toast = useToastContext()
  const onTaskComplete = () => {
    toast({
      title: 'Task successfully updated',
      status: 'success'
    })
  }

  if (showReopenTaskButton) {
    return <TaskReopenAction task={task} onSuccessCallback={onSuccessCallback} />
  }

  return (
    <>
      <TaskEditButtonForm task={task} onSuccessCallback={onSuccessCallback} />
      <TaskDismissButtonForm task={task} disabled={!canCompleteTask} />
      <TaskCompleteButtonForm
        task={task}
        disabled={!canCompleteTask}
        onSuccessCallback={onSuccessCallback || onTaskComplete}
      />
    </>
  )
}
