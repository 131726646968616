import { UserResource, UserResourceType } from '..'

const userResourceTypeMap: Record<UserResourceType, string> = {
  Agent: 'Agent',
  Order: 'Client',
  User: 'HL'
}

export const getUserTypeString = (userResource?: UserResource) => {
  if (!userResource?.linkedResourceType) {
    return undefined
  }

  return userResourceTypeMap[userResource?.linkedResourceType] as string
}

export const getFormattedUserTypeString = (userResource?: UserResource) => {
  if (!userResource) {
    return ''
  }

  const userTypeString = getUserTypeString(userResource)

  if (!userTypeString) {
    return ''
  }

  return `(${userTypeString}) `
}
