// @ts-strict
import { Button } from '@foundation/components'
import {
  ButtonForm,
  CheckBoxField,
  FormField,
  FormFieldSet,
  Section,
  Sections,
  Select,
  TextArea,
  TextInput,
  useButtonForm
} from 'components'
import { UseMutationResult } from 'hooks'
import { useTransactionTeams } from 'lookups'
import { AgentPartnershipPostPayload } from 'services'
import { validatePresence, validateUrl } from 'utils'

type TProps = {
  mutation: UseMutationResult<unknown, unknown, AgentPartnershipPostPayload, unknown>
}

export const CreateAgentPartnershipButtonForm = ({ mutation }: TProps) => {
  const { transactionTeamsOptions } = useTransactionTeams()
  const {
    formProps,
    fieldsProps: { payload, onChangeAttribute }
  } = useButtonForm<AgentPartnershipPostPayload, string>({
    initialPayload: {
      active: false,
      description: '',
      destinationUrl: 'https://academy.homelight.com',
      name: '',
      transactionTeamId: undefined
    },
    mutation,
    formValidations: {
      name: validatePresence,
      destinationUrl: [validatePresence, validateUrl],
      transactionTeamId: validatePresence
    }
  })

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={formProps.toggleShowForm}>
        Create Partnership
      </Button>
      <ButtonForm
        {...formProps}
        hideTriggerButton
        isFormValid={formProps.isFormValid && !!payload.transactionTeamId}
        title="Create Partnership"
      >
        <FormFieldSet>
          <Sections>
            <Section>
              <FormField title="Name" required htmlFor="name">
                <TextInput
                  required
                  id="name"
                  onChange={value => {
                    onChangeAttribute('name', value)
                  }}
                  value={payload.name}
                />
              </FormField>
              <FormField title="Description" htmlFor="description">
                <TextArea
                  id="description"
                  onChange={value => {
                    onChangeAttribute('description', value)
                  }}
                  value={payload.description}
                />
              </FormField>
              <FormField title="Destination URL" required htmlFor="destinationUrl">
                <TextInput
                  required
                  id="destinationUrl"
                  onChange={value => {
                    onChangeAttribute('destinationUrl', value)
                  }}
                  value={payload.destinationUrl}
                />
              </FormField>
              <FormField title="Transaction Team" required htmlFor="transactionTeam">
                <Select
                  required
                  id="transactionTeam"
                  options={transactionTeamsOptions}
                  value={payload.transactionTeamId || 0}
                  placeholder="Select a Transaction Team"
                  onChange={value => {
                    onChangeAttribute('transactionTeamId', value)
                  }}
                />
              </FormField>
              <CheckBoxField
                name="Enabled"
                title="Enabled"
                checked={payload.active}
                onChange={e => {
                  onChangeAttribute('active', e.target.checked)
                }}
              />
            </Section>
          </Sections>
        </FormFieldSet>
      </ButtonForm>
    </>
  )
}
