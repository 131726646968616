export const convertAllNullToUndefined = (obj: any): any => {
  if (obj === null) {
    return undefined
  }
  if (Array.isArray(obj)) {
    return obj.filter(Boolean).map(convertAllNullToUndefined)
  }
  if (typeof obj === 'object') {
    Object.keys(obj).forEach(key => {
      obj[key] = convertAllNullToUndefined(obj[key])
    })
  }

  return obj
}
