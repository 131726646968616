import { Section, Sections } from 'components'
import { AgentSection } from 'content/Agent/AgentSection'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead'>

export const DRAgentInformationSection = ({ lead, updateBBYSLead }: Props) => {
  const bbysLead = lead.bbysProviderLead.bbysLead
  const sellerAgent = bbysLead.departingPropertySellerAgent
  const buyerAgent = bbysLead.incomingPropertyBuyerAgent

  const leadType = lead.isHLSS ? 'hlss' : 'trade_in'

  return (
    <Sections title="Agent Information">
      <Section>
        <AgentSection
          title="Departing residence agent"
          agent={sellerAgent}
          program={leadType}
          onBlur={val =>
            updateBBYSLead.mutate({
              id: bbysLead.id,
              payload: {
                departing_property_seller_agent_id: val
              }
            })
          }
        />
      </Section>
      <Section>
        <AgentSection
          title="Incoming residence agent"
          agent={buyerAgent}
          program={leadType}
          onBlur={val =>
            updateBBYSLead.mutate({
              id: bbysLead.id,
              payload: {
                incoming_property_buyer_agent_id: val
              }
            })
          }
        />
      </Section>
    </Sections>
  )
}
