import { css, styled } from '@foundation/themes/styles'

export const BlurrableValue = styled.span<{ shouldBlur: boolean }>`
  ${({ theme, shouldBlur }) => css`
    flex: 0;

    ${shouldBlur && `filter: blur(4px);`}
  `}
`

const blurredStyle = () => css`
  cursor: not-allowed;

  > * {
    pointer-events: none;
  }
`

export const ValueWrapper = styled.div<{ isBlurred?: boolean }>`
  ${({ isBlurred }) =>
    css`
      ${isBlurred && blurredStyle}
    `}
`

export const UnblurButton = styled.button`
  ${({ theme }) => css`
    position: absolute;
    display: inline-block;
    right: ${theme.sizes.$7};
    top: 50%;
    transform: translateY(-50%);
    border: none;
    padding: 0;

    width: ${theme.sizes.$7};
    height: ${theme.sizes.$7};

    cursor: pointer;

    background-color: ${theme.colors.white};

    path {
      fill: ${theme.colors.neutral5};
    }
  `}
`
