// @ts-strict
import { RepeatIconButton } from 'components/Button/IconButton'
import { useTaskController } from 'controllers'
import { Task } from 'models'

type TProps = {
  onSuccessCallback?: Function
  task: Task
}

export const TaskReopenAction = ({ task, onSuccessCallback }: TProps) => {
  const { reopenTask } = useTaskController(task.id)

  const handleReopenTask = async () => {
    await reopenTask.mutateAsync()

    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }

  return (
    <RepeatIconButton
      title="Reopen Task"
      aria-label="Reopen Task"
      isLoading={reopenTask.isLoading}
      onClick={handleReopenTask}
    />
  )
}
