// @ts-strict

import { Table, TableBody } from 'components/Table'
import { LeadUser } from 'models'
import { LeadUsersListHeader } from './LeadUsersListHeader'
import { LeadUsersListRow } from './LeadUsersListRow'

type TLeadUsersListProps = {
  leadUsers?: LeadUser[]
}
export const LeadUsersList = ({ leadUsers }: TLeadUsersListProps) => {
  if (!leadUsers || !leadUsers.length) {
    return <span>There are no team members.</span>
  }

  return (
    <Table>
      <LeadUsersListHeader />
      <TableBody>
        {leadUsers.map(leadUser => (
          <LeadUsersListRow leadUser={leadUser} key={leadUser.id} />
        ))}
      </TableBody>
    </Table>
  )
}
