// @ts-strict

import { ReactNode } from 'react'
import { createCtx } from '../utils'
import { useDialer } from './useDialer'

export const [useDialerContext, DialerContextProvider] = createCtx<ReturnType<typeof useDialer>>()

type Props = {
  children: ReactNode
}

export const DialerProvider = ({ children }: Props) => {
  return <DialerContextProvider value={useDialer()}>{children}</DialerContextProvider>
}
