import { Option } from 'classes'
import { ModelField } from '../ModelField'
import {
  TCcCashOfferLeadFieldAttribute,
  TCcCashOfferLeadFieldSetting
} from './ccCashOfferLeadFieldsSettings'

export class CcCashOfferLeadField extends ModelField {
  attr!: TCcCashOfferLeadFieldAttribute

  constructor(attributes: CcCashOfferLeadField) {
    super(attributes)
    Object.assign(this, attributes)
  }

  static createFromSetting(setting: TCcCashOfferLeadFieldSetting) {
    const options = setting.options?.map(option => new Option(option))
    return new CcCashOfferLeadField({ ...setting, options })
  }
}
