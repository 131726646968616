import { Order } from 'models'
import { getHapiRequestParams, OrderSchema } from 'schema'
import { getOrdersUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchOrder = async (id: string, orderResponseShape: OrderSchema): Promise<Order> => {
  const searchParams = getHapiRequestParams(orderResponseShape)
  const url = getOrdersUrl({ id, searchParams })
  const response = await getRequest(url)

  return await deserializeAsync(response.data, { transform: Order.create })
}
