// @ts-strict
import { Marker, Point } from 'classes'
import { Agent } from 'models'
import { TransactionResponse } from 'schema'
import { formatPrice } from 'utils'
import { getIcon } from '../../components/MapBox/utils/getIcon'

type TTransactionConstructor = Omit<Transaction, 'toMarker' | 'toPoint'>

export class Transaction {
  id!: string
  sellingDate?: string
  sellingPrice?: number
  fullAddress?: string
  latitude?: number
  longitude?: number
  humanizedPropertyType?: string
  sellingAgent?: Agent
  listingAgent?: Agent

  constructor(transactionAttributes: TTransactionConstructor) {
    Object.assign(this, transactionAttributes)
  }

  static create(transactionResponse: Potential<TransactionResponse>): Potential<Transaction> {
    if (!transactionResponse) {
      return
    }
    const latitude = transactionResponse.latitude
    const longitude = transactionResponse.longitude
    const sellingAgent = Agent.create(transactionResponse.sellingAgent)
    const listingAgent = Agent.create(transactionResponse.listingAgent)
    return new Transaction({
      ...transactionResponse,
      latitude,
      longitude,
      sellingAgent,
      listingAgent
    })
  }

  toMarker(icon?: HTMLDivElement) {
    const defaultIcon = getIcon()
    return this.latitude && this.longitude
      ? new Marker({
          name: `${formatPrice(this.sellingPrice, 'short')} ${this.fullAddress}`,
          description: `Listed by ${this.listingAgent?.fullName}.  Sold by ${this.sellingAgent?.fullName}`,
          type: 'transaction',
          point: this.toPoint(),
          icon: icon || defaultIcon
        })
      : undefined
  }

  toPoint() {
    return this.latitude && this.longitude
      ? new Point({ latitude: this.latitude, longitude: this.longitude })
      : undefined
  }
}
