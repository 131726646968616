import { getUsers2Url } from 'services/getUrl'
import { postRequest } from 'utils'

type Payload = {
  name: string
}

export const postEmployeeRole = async (userId: string, payload: Payload) => {
  return postRequest(getUsers2Url({ id: userId, action: 'roles' }), payload)
}
