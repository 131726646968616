import React from 'react'
import styles from './Activity.module.scss'

type Props = {
  transitions: string[]
}

export const ActivityTransition = ({ transitions }: Props) => {
  if (!transitions) {
    return null
  }
  return <div className={styles.transitions}>{transitions.join(' > ')}</div>
}
