import { Option } from 'classes'
import { byValue } from 'utils'

type TIntegrationSetting = {
  name: string
  value: string
}

export const integrationSettings: Array<TIntegrationSetting> = [
  { value: 'blend_user_id', name: 'Blend User Id' },
  { value: 'calendly_url', name: 'Calendly Url' },
  { value: 'nylas_account_id', name: 'Nylas Account Id' },
  { value: 'qualia_connect_user_id', name: 'Qualia Connect User Id' },
  { value: 'slack_user_id', name: 'Slack User Id' },
  { value: 'twilio_flex_identity', name: 'Twilio Flex Identity' },
  { value: 'zoom_bbys_approved', name: 'Calendar Link: BBYS Approved' },
  { value: 'zoom_bbys_intro', name: 'Calendar Link: BBYS Intro' }
]

export const integrationOptions = integrationSettings.map(option => {
  return new Option({ name: option.name, value: option.value })
})

export const getName = (integrationValue: string) =>
  integrationSettings.find(byValue(integrationValue))?.name
