import { getTasksUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export type TPutDismissTaskVariables = {
  id: string
  payload: {
    completionNote: string
  }
}

export const putDismissTask = async ({ id, payload }: TPutDismissTaskVariables) => {
  const url = getTasksUrl({ id, action: 'dismiss' })
  return putRequest(url, snakeCaseKeys(payload))
}
