import { Button } from '@foundation/components'
import { If } from 'components'
import { useQualifyInvestorLeadsController } from 'controllers'
import { Lead } from 'models'
import { QualifyInvestorLeadsWrapper } from './styles'

type QualifyInvestorLeadsProps = {
  lead: Lead
}

export const QualifyInvestorLeads = ({ lead }: QualifyInvestorLeadsProps) => {
  const { qualifyInvestorLeads } = useQualifyInvestorLeadsController(lead.id)

  return (
    <If test={lead.userType === 'seller'}>
      <QualifyInvestorLeadsWrapper>
        <Button
          size="xs"
          onClick={() => qualifyInvestorLeads.mutate()}
          isLoading={qualifyInvestorLeads.isLoading}
          color="success"
          variant="solid"
        >
          Qualify Investors
        </Button>
      </QualifyInvestorLeadsWrapper>
    </If>
  )
}
