import { Option } from 'classes'
import { IOptionable } from 'lookups'
import { TransactionTeam } from 'models/TransactionTeam'
import { AgentPartnershipResponse } from 'schema'

export class AgentPartnership implements IOptionable {
  id: string
  name?: string
  description?: string
  transactionTeam?: TransactionTeam
  destinationUrl?: string
  incomingUrl?: string
  active?: boolean
  createdAt?: string
  updatedAt?: string
  agentsCount?: number

  constructor(agentPartnershipAttributes: AgentPartnershipResponse) {
    Object.assign(this, agentPartnershipAttributes)
  }

  static create(
    agentPartnershipResponse: Potential<AgentPartnershipResponse>
  ): Potential<AgentPartnership> {
    if (!agentPartnershipResponse) {
      return
    }

    return new AgentPartnership({
      ...agentPartnershipResponse
    })
  }

  toOption() {
    return new Option({ name: this.name, value: this.id })
  }
}
