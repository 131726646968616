import { ReactNode } from 'react'
import { useLeadsController } from 'controllers'
import { useLocation } from 'hooks'
import { queryStringToObject } from 'utils'
import { createCtx } from '../utils'

export const [useLeadsContext, LeadsContextProvider] =
  createCtx<ReturnType<typeof useLeadsController>>()

type TProps = {
  agentId?: string
  children: ReactNode
  rootPath?: string
  scope?: string
}

export const LeadsProvider = ({ scope, agentId, children }: TProps) => {
  const { search } = useLocation()
  const searchParams = { ...queryStringToObject(search), scope, agentId }
  return (
    <LeadsContextProvider value={useLeadsController(searchParams)}>{children}</LeadsContextProvider>
  )
}
