import { useEffect } from 'react'
import { Contents } from 'components/Contents/index'
import { ListingsList } from 'content/Listings/ListingsList'
import { useAgentContext } from 'contexts/Agent'
import { ListingsProvider, useListingsContext } from 'contexts/Listings'
import { useDocumentTitle } from 'hooks'

const AgentListingsTabContents = () => {
  const { listings, pageCount, isLoading } = useListingsContext()
  return (
    <Contents title="MLS Listings">
      <ListingsList {...{ listings, pageCount, isLoading }} />
    </Contents>
  )
}

export const AgentListingsTab = () => {
  const { agent } = useAgentContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${agent.fullName} - Agent MLS Listings`), [agent, setTitle])

  return (
    <ListingsProvider rootPath={`/agents/${agent.id}/listings`} agentId={agent.id}>
      <AgentListingsTabContents />
    </ListingsProvider>
  )
}
