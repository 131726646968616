// @ts-strict
import { useNavigate } from 'react-router-dom'
import { ORDERS_CACHE_KEY, useMutation, UseMutationResult, useQueryClient } from 'hooks'
import { OrderSchema } from 'schema'
import { fetchOrder, postSalesOrder, TOrderPostPayload } from 'services'

const orderLeadIDShape: OrderSchema = {
  order: [{ leads: ['userType'] }]
}

type UseCreateOrderControllerProps = {
  clearCacheKey?: string
  onSuccess?: () => void
}

export const useCreateOrderController = ({
  onSuccess,
  clearCacheKey
}: UseCreateOrderControllerProps) => {
  const queryCache = useQueryClient()
  const clearCaches = () => {
    if (clearCacheKey) {
      queryCache.invalidateQueries([clearCacheKey])
    }
    queryCache.invalidateQueries([ORDERS_CACHE_KEY])
  }
  const navigate = useNavigate()

  const createOrder: UseMutationResult<string, unknown, TOrderPostPayload, unknown> = useMutation(
    data => postSalesOrder(data),
    {
      onSuccess: () => {
        clearCaches()
        if (onSuccess) {
          onSuccess()
        }
      }
    }
  )

  const handleOrderCreated = async (id: string) => {
    const order = await fetchOrder(id, orderLeadIDShape)

    const pathname = order?.leads?.[0]?.getPathname() || ''

    navigate(pathname)
  }

  return { createOrder, handleOrderCreated }
}
