import { Box, Text } from '@foundation/components'
import { styled } from '@foundation/themes/styles'
import { Button, Form, FormActions, PrimaryButton, Section, Sections } from 'components'
import { InvestorPayPerLeadSetting } from 'models'
import { MAXIMUM_PPL_SETTING_PRICE, MINIMUM_PPL_SETTING_PRICE } from '../constants'
import { LocationSettingsRemoveList } from './LocationSettingsRemoveList'
import { LocationSettingsTable } from './LocationSettingsTable'
import { SearchLocation } from './SearchLocation'
import { useLocationSettingsData } from './useLocationSettingsData'

const StyledForm = styled(Form)`
  padding: 2.4rem;
  width: 100%;
  min-width: 60rem;
  max-width: 80rem;

  &.confirmation {
    min-width: 40rem;
    max-width: 60rem;
  }

  @media (max-width: 414px) {
    min-width: 30rem;
  }
`

type LocationSettingsFormProps = {
  onCancel: () => void
  onSubmit: (settings: InvestorPayPerLeadSetting[]) => void
  settings?: InvestorPayPerLeadSetting[] | null
  submitText?: string
}

const validatePplSettings = (settings: InvestorPayPerLeadSetting[] | null) => {
  if (!settings || settings.length === 0) {
    return false
  }

  const allPricesAtZero = settings.every(setting => {
    const priceInDollars = (setting.price || 0) / 100
    return priceInDollars === 0
  })

  const allPricesValid = settings.every(setting => {
    const priceInDollars = (setting.price || 0) / 100

    return (
      priceInDollars === 0 ||
      (priceInDollars >= MINIMUM_PPL_SETTING_PRICE && priceInDollars <= MAXIMUM_PPL_SETTING_PRICE)
    )
  })

  return !allPricesAtZero && allPricesValid
}

export const LocationSettingsForm = ({
  onCancel,
  onSubmit,
  settings,
  submitText = 'Save'
}: LocationSettingsFormProps) => {
  const { locationSettings, addLocationSettings, removeLocationSetting, toggleActive, setPrice } =
    useLocationSettingsData(settings)
  const validPplSettings = validatePplSettings(locationSettings)

  return (
    <StyledForm onSubmit={() => onSubmit(locationSettings)}>
      <Section>
        <Box marginBottom="$3">
          <Text weight="bold">Select lead locations</Text>
        </Box>
        <Text size="sm">Add zip code for sellers.</Text>

        <Sections>
          <SearchLocation value="" onSelect={addLocationSettings} />
        </Sections>
        <Sections>
          <LocationSettingsRemoveList
            locationSettings={locationSettings}
            removeLocationSetting={removeLocationSetting}
          />
        </Sections>
        <Sections>
          <LocationSettingsTable
            locationSettings={locationSettings}
            toggleActive={toggleActive}
            setPrice={setPrice}
          />
        </Sections>
      </Section>
      <FormActions>
        <Button type="button" onClick={onCancel}>
          Cancel
        </Button>
        <PrimaryButton disabled={!validPplSettings} type="submit">
          {submitText}
        </PrimaryButton>
      </FormActions>
    </StyledForm>
  )
}
