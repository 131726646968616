import { LEADS_CACHE_KEY } from 'controllers'
import { useMutation, useQueryClient } from 'hooks'
import { PhoneNumber } from 'models'
import { postPhoneNumber, putPhoneNumber } from 'services'
import type { PostPhoneNumberPayload, PutPhoneNumberPayload } from 'services'

export type UseLeadPhoneNumberPayload = {
  id?: string
  number: string
  phoneType?: PhoneNumber['phoneType']
}

export const useLeadPhoneNumberController = (leadId: string, leadType: string) => {
  const queryCache = useQueryClient()

  const clearCaches = () => {
    queryCache.invalidateQueries([LEADS_CACHE_KEY, leadType, leadId])
  }

  const defaultCallback = {
    onSuccess: clearCaches
  }

  const updatePhoneNumber = useMutation(
    (payload: PutPhoneNumberPayload) => putPhoneNumber(leadId, payload),
    defaultCallback
  )

  const createPhoneNumber = useMutation(
    (payload: PostPhoneNumberPayload) => postPhoneNumber(leadId, payload),
    defaultCallback
  )

  return { createPhoneNumber, updatePhoneNumber }
}
