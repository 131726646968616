import { ComponentType, useEffect, useRef, useState } from 'react'
import { CreateNumberMask } from 'text-mask-addons/dist/createNumberMask'
import { TextInput, TextInputProps } from 'components/Form'
import { useUpdateEffect } from 'hooks'
import { cx } from 'utils'
import { DisplayValue, InlineProps } from '../DisplayValue'
import style from './InlineEditTextInput.module.scss'

export interface InlineEditTextInputProps extends InlineProps, Omit<TextInputProps, 'onBlur'> {
  maskParams?: CreateNumberMask
  onBlur?: (val: string) => void
  render?: ComponentType<TextInputProps>
}

export const InlineEditTextInput = ({
  render: Component,
  value,
  onChange,
  isLoading,
  isError,
  format,
  onBlur,
  readOnly,
  ...props
}: InlineEditTextInputProps) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [isEditMode, setIsEditMode] = useState(false)
  const inputRef = useRef(null)

  useUpdateEffect(() => {
    setCurrentValue(value)
  }, [value])

  useEffect(() => {
    const handleInputFocus = () => {
      const inputElement = inputRef.current

      if (!isEditMode || !inputElement) {
        return
      }

      const maskedInputElement = inputElement.inputElement
      if (maskedInputElement) {
        maskedInputElement.focus()
      } else {
        inputElement.focus()
      }
    }

    handleInputFocus()
  }, [isEditMode])

  const handleOnChange = (changedValue: string) => {
    setCurrentValue(changedValue)

    if (onChange) {
      onChange(changedValue)
    }
  }

  const stopEditMode = () => {
    setIsEditMode(false)

    if (onBlur) {
      onBlur(currentValue)
    }
  }

  const formatter = (changedValue: string) => {
    return format ? format(changedValue) : changedValue
  }

  if (isEditMode) {
    const RenderComponent = Component || TextInput

    return (
      <RenderComponent
        ref={inputRef}
        {...props}
        className={cx(style.input, props.className)}
        value={currentValue}
        onChange={handleOnChange}
        onBlur={stopEditMode}
      />
    )
  }

  return (
    <DisplayValue
      className={cx(style.input, props.className)}
      isReadOnly={readOnly}
      format={formatter}
      setEditMode={setIsEditMode}
      value={currentValue}
      isLoading={isLoading}
      isError={isError}
    />
  )
}
