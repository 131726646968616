// @ts-strict

import {
  LoadingText,
  Table,
  TableBody,
  TableHeaderCell as TH,
  TableHeaderRow as THR
} from 'components'
import { useTeamsContext } from 'contexts'
import styles from './TeamsList.module.scss'
import { TeamsListRow } from './TeamsListRow'

export const TeamsList = () => {
  const { teamsQuery } = useTeamsContext()

  if (teamsQuery.isLoading) {
    return <LoadingText />
  }

  return (
    <Table className={styles.table}>
      <THR>
        <TH>Name</TH>
        <TH>Members</TH>
        <TH>Enabled</TH>
      </THR>
      <TableBody>
        {teamsQuery.data?.items.map(team => {
          return <TeamsListRow key={team.id} team={team} />
        })}
      </TableBody>
    </Table>
  )
}
