import { Button } from '@foundation/components'
import {
  FileInput,
  Form,
  FormActions,
  FormField,
  PropertyAutoComplete,
  RadioTabs,
  Section,
  SectionTitle,
  TextInput
} from 'components'
import { ESCROW_LEADS_CACHE_KEY, useCreateEscrowOrderController } from 'controllers'
import styles from '../CreateEscrowOrder.module.scss'
import { ReferringAgentAutocomplete } from '../ReferringAgentAutocomplete'
import { isCA } from '../helpers'
import { useCreateInstantOrderForm } from './useCreateInstantOrderForm'

export const CreateInstantOrderForm = ({ handleClose }: { handleClose: () => void }) => {
  const { createInstantOrder, handleOrderCreated } = useCreateEscrowOrderController({
    clearCacheKey: ESCROW_LEADS_CACHE_KEY
  })

  const { formProps, payload, onChangeAttribute } = useCreateInstantOrderForm({
    mutation: createInstantOrder,
    onSubmit: handleOrderCreated
  })

  const onPropertySelect = ({ lead, title }) => {
    if (lead) {
      onChangeAttribute('address', lead.fullAddress)
      onChangeAttribute('firstName', lead.firstName)
      onChangeAttribute('lastName', lead.lastName)
      onChangeAttribute('name', lead.name)
      onChangeAttribute('phone', lead.phone)
      onChangeAttribute('email', lead.email)
      onChangeAttribute('parentLeadId', lead.id)
      if (!payload.referringAgentId) {
        if (lead.referringAgent) {
          onChangeAttribute('referringAgentId', lead.referringAgent.id)
          onChangeAttribute('referringAgentName', lead.referringAgent.name)
        } else if (lead.referringOwner) {
          onChangeAttribute('referringAgentId', lead.referringOwner.id)
          onChangeAttribute(
            'referringAgentName',
            [lead.referringOwner.firstName, lead.referringOwner.lastName].join(' ')
          )
        }
      }
    } else {
      onChangeAttribute('address', title)
    }
  }

  const isPreEscrow = payload.orderType === 'pre_escrow'
  const isCalifornia = isCA(payload.address || '')

  return (
    <Form onSubmit={formProps.onSubmit} className={styles.form}>
      <Section>
        <FormField>
          <RadioTabs
            data-testid="order-type-field"
            value={payload.orderType}
            onChange={(val: 'escrow' | 'pre_escrow') => onChangeAttribute('orderType', val)}
            options={[
              {
                label: 'Escrow',
                value: 'escrow'
              },
              {
                label: 'Pre-Escrow',
                value: 'pre_escrow'
              }
            ]}
          />
        </FormField>
      </Section>
      <Section>
        <SectionTitle className={styles.sectionTitle}>Property</SectionTitle>
        <FormField title="Property address">
          <PropertyAutoComplete
            data-testid="property-autocomplete"
            className={styles.input}
            value={payload.address}
            onSelect={onPropertySelect}
            onClear={() => onChangeAttribute('address', '')}
            placeholder="Enter property address or client name"
            leadSearchParams={{
              agentAndTeam: payload.referringAgentId,
              hlcsEligible: true
            }}
          />
        </FormField>
        <FormField title="Unit / Apt / Suite" note="Optional">
          <TextInput
            data-testid="unit-field"
            value={payload.secondaryAddress}
            onChange={val => onChangeAttribute('secondaryAddress', val)}
            className={styles.input}
            placeholder="Enter unit e.g: Unit 14"
          />
        </FormField>
      </Section>
      <Section>
        <SectionTitle className={styles.sectionTitle}>Agent Info</SectionTitle>
        <FormField title="Referring agent">
          <ReferringAgentAutocomplete
            data-testid="referring-agent-autocomplete"
            value={payload.referringAgentName}
            className={styles.input}
            onSelect={val => {
              onChangeAttribute('referringAgentName', [val?.title, val?.subTitle].join(' - '))
              onChangeAttribute('referringAgentId', val?.id)
            }}
            onClear={() => {
              onChangeAttribute('referringAgentName', '')
              onChangeAttribute('referringAgentId', '')
            }}
            searchParams={{
              include:
                'state,marketplace_program_agents,marketplace_program_agents.marketplace_program'
            }}
          />
        </FormField>
        <FormField title="Referring agent role">
          <RadioTabs
            data-testid="agent-role-field"
            value={payload.buyer ? 'buyer' : 'seller'}
            onChange={val => onChangeAttribute('buyer', val === 'buyer')}
            options={[
              {
                label: 'Listing agent',
                value: 'seller'
              },
              {
                label: 'Selling agent',
                value: 'buyer'
              }
            ]}
          />
        </FormField>
        {!isPreEscrow && (
          <FormField title="Cooperating agent email address">
            <TextInput
              data-testid="cooperating-agent-field"
              className={styles.input}
              value={payload.cooperatingAgentEmail}
              onChange={val => onChangeAttribute('cooperatingAgentEmail', val)}
              placeholder="Enter email address"
            />
          </FormField>
        )}
      </Section>
      <Section>
        {(!isPreEscrow || isCalifornia) && (
          <SectionTitle className={styles.sectionTitle}>Transaction Info</SectionTitle>
        )}
        {!isPreEscrow && (
          <FormField title="Buyer Financing">
            <RadioTabs
              data-testid="financing-field"
              value={payload.sourceOfFunds}
              onChange={val => onChangeAttribute('sourceOfFunds', val)}
              options={[
                {
                  label: 'All cash',
                  value: 'cash'
                },
                {
                  label: 'Lender',
                  value: 'lender'
                }
              ]}
            />
          </FormField>
        )}
        {isCalifornia && (
          <FormField title="Transaction type">
            <RadioTabs
              data-testid="transaction-type-field"
              value={payload.transactionType}
              onChange={val => onChangeAttribute('transactionType', val)}
              options={[
                {
                  value: 'title_escrow',
                  label: 'HomeLight Title & Escrow'
                },
                {
                  value: 'escrow',
                  label: 'HomeLight Escrow Only'
                },
                isPreEscrow
                  ? null
                  : {
                      value: 'title',
                      label: 'HomeLight Title Only'
                    }
              ].filter(Boolean)}
            />
          </FormField>
        )}
        {!isPreEscrow && (
          <FormField title="Purchase contract">
            <FileInput
              id="file-name"
              data-testid="file-upload-input"
              accept=".doc,.docx,.pdf"
              value={payload.files}
              multiple
              onChange={value => {
                onChangeAttribute('files', value)
              }}
            />
          </FormField>
        )}
      </Section>
      <FormActions
        error={(formProps.showError && formProps.error) || formProps.errorMessage}
        onMouseEnter={formProps.handleHover}
      >
        <Button
          onClick={handleClose}
          isDisabled={formProps.isLoading}
          variant="ghost"
          color="neutralLight"
          type="button"
        >
          Cancel
        </Button>
        <Button isDisabled={!formProps.isFormValid} isLoading={formProps.isLoading}>
          Submit
        </Button>
      </FormActions>
    </Form>
  )
}
