// @ts-strict
import { SearchFilter } from 'lookups'
import { SearchStatusFilter } from './SearchStatusFilter'

export const SearchStatusPage = ({ value }: { value?: string }) => {
  const filter = new SearchFilter({
    name: 'Page',
    slug: 'page',
    getDisplayValue: (val: string) => `pg ${val}`
  })

  return value ? <SearchStatusFilter filter={filter} value={value} /> : null
}
