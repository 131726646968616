import { matchRoutes, useLocation } from 'react-router-dom'
import { Virtuoso } from 'react-virtuoso'
import { Box } from '@foundation/components'
import { useEffectOnce, useFeatureFlags } from 'hooks'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import { EmptyPlaceholderInfiniteScrolling, LoadingInfiniteScrollingFooter } from 'templates'
import { CommsListRow, useCommsFiltersContext } from '../../commons/ui'
import { thisAgentTabPathsToMatch } from '../../commons/ui/utils'
import { useFetchInfiniteThisAgentComms } from '../hooks'
import { ListAgentCommsFilters } from './ListAgentCommsFilters'

export const ListAgentComms = () => {
  const location = useLocation()
  const { tracking } = useCustomEvents()
  const { salesAppCommsUxImprovements } = useFeatureFlags(['sales-app-comms-ux-improvements'])
  const { filters } = useCommsFiltersContext()
  const [matchedRoute] = matchRoutes(thisAgentTabPathsToMatch, location.pathname) || []
  const { agentId: agentIdParam } = matchedRoute.params

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useFetchInfiniteThisAgentComms(agentIdParam!, {
      searchParams: {
        name: filters.agent.channels,
        lessEqualOccurredAt: filters.agent.dateRange?.endingDate,
        greaterEqualOccurredAt: filters.agent.dateRange?.startingDate,
        userIdContactInfo: filters.agent.user?.value,
        orderIdContactInfo: filters.agent.client?.value
      },
      options: { enabled: !!agentIdParam }
    })

  useEffectOnce(() => {
    tracking({
      name: 'drawer_view',
      params: {
        trigger: 'click',
        location: 'agent',
        target: 'communication'
      }
    })
  })

  const isLoading = isFetching || isFetchingNextPage

  return (
    <>
      {salesAppCommsUxImprovements.enabled && (
        <Box marginTop="$4" marginBottom="$4">
          <ListAgentCommsFilters />
        </Box>
      )}
      <Virtuoso
        context={{ isLoading }}
        style={{ height: '100%' }}
        data={data?.pages.flatMap(d => d.items) || []}
        endReached={() => {
          if (!isLoading && hasNextPage) {
            fetchNextPage()
          }
        }}
        itemContent={(index, item) => {
          return <CommsListRow data-testid={`eventComponent${index}`} activityFeed={item} />
        }}
        components={{
          Footer: ({ context }) => (
            <LoadingInfiniteScrollingFooter isLoading={context?.isLoading} />
          ),
          EmptyPlaceholder: ({ context }) => (
            <EmptyPlaceholderInfiniteScrolling isLoading={context?.isLoading} />
          )
        }}
      />
    </>
  )
}
