// @ts-strict
import { Outlet, useParams } from 'react-router-dom'
import { Console, ConsoleAside, ConsoleDetails, TabNav, Tabs } from 'components'
import { LeadGroupableAside } from 'content/LeadGroupable'
import { LeadGroupableProvider } from 'contexts'
import { LeadGroupableType } from 'models'
import { singularize } from 'utils'
import styles from './LeadGroupablePage.module.scss'

interface LeadGroupablePageContentProps {
  leadGroupableType: LeadGroupableType
}

export const LeadGroupablePage = {
  Persona: () => <LeadGroupablePageContent leadGroupableType="personas" />
}

export const LeadGroupablePageContent = ({ leadGroupableType }: LeadGroupablePageContentProps) => {
  const { [`${singularize(leadGroupableType)}Id`]: leadGroupableId } = useParams()

  return (
    <LeadGroupableProvider leadGroupableId={leadGroupableId!} leadGroupableType={leadGroupableType}>
      <Console>
        <ConsoleAside className={styles.aside}>
          <LeadGroupableAside />
        </ConsoleAside>
        <ConsoleDetails className={styles.details}>
          <Tabs className={styles.tabs}>
            <TabNav to={`/lead-creation-admin/${leadGroupableType}/${leadGroupableId}`}>
              Definitions
            </TabNav>
          </Tabs>
          <Outlet />
        </ConsoleDetails>
      </Console>
    </LeadGroupableProvider>
  )
}
