// @ts-strict
import {
  AddressUnitAutoComplete,
  AgentsByProgramAutoComplete,
  FormField,
  FormFieldSet,
  Section,
  Sections
} from 'components'
import { TFormItemsProps } from '../types'

export const TradeInHLSSItems = ({
  onChangeAttribute,
  marketplaceProgramsServerSlugsParams
}: TFormItemsProps) => {
  return (
    <FormFieldSet title="Property Details">
      <Sections>
        <Section>
          <FormField title="DR Full Address" required>
            <AddressUnitAutoComplete
              onSelect={val => onChangeAttribute('departingPropertyFullAddress', val)}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="DR Agent">
            <AgentsByProgramAutoComplete
              name="departing_property_seller_agent_id"
              marketplaceProgramServerSlugs={marketplaceProgramsServerSlugsParams || {}}
              onSelect={agent => onChangeAttribute('departingPropertySellerAgentId', agent?.id)}
              onClear={() => onChangeAttribute('departingPropertySellerAgentId', undefined)}
            />
          </FormField>
        </Section>
      </Sections>
    </FormFieldSet>
  )
}
