import { getLeadDispositionUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export type TPutDispositionAgent = {
  delayIntroEmail?: boolean
  disposition: string
  includeAgentProfile?: boolean
  includeAgentWebsite?: boolean
  sendIntroFirst?: boolean
  skipAgentEmail?: boolean
}

export const putDispositionAgent = (
  leadId: string,
  agentId: string,
  payload: TPutDispositionAgent
) => {
  return putRequest(
    getLeadDispositionUrl({
      id: leadId,
      action: `agents/${agentId}`
    }),
    snakeCaseKeys(payload)
  )
}
