// @ts-strict

import { ReactNode } from 'react'
import { useTokenController } from 'controllers'
import { createCtx } from '../utils'

export const [useTokenContext, TokenContextProvider] =
  createCtx<ReturnType<typeof useTokenController>>()

type Props = {
  children: ReactNode
}

export const TokenProvider = ({ children }: Props) => {
  return <TokenContextProvider value={useTokenController()}>{children}</TokenContextProvider>
}
