import { FormField, InlineEditDateInput, InlineEditSelect, Section, Sections } from 'components'
import { AgentSection } from 'content/Agent/AgentSection'
import { CCCashOfferLeadCallout } from 'content/CCCashOfferLead'
import { CCCashOfferLeadDetailsProps } from 'content/Lead'
import { getLeadFieldName, sourcesOptions, useCashOfferLeadFields, useFailedReasons } from 'lookups'
import { dataOrDash, titleize } from 'utils'
import { getOrderInputProps } from './utils'

type Props = Pick<CCCashOfferLeadDetailsProps, 'lead' | 'updateLead' | 'updateOrder'>

export const CashOfferLeadDetailsSection = ({ lead, updateLead, updateOrder }: Props) => {
  const referringAgent = lead.referringAgent

  const { getProps } = getOrderInputProps(lead.order, updateOrder)
  const { ccCashOfferFailedReasonOptions: failedReasons } = useFailedReasons()
  const { getCashOfferLeadFieldName } = useCashOfferLeadFields()

  const failedReason = failedReasons.find(failedReason => {
    return failedReason.value === lead?.reasonForFail
  })

  const showReferringAgent = lead.order?.source === 'accept' || lead.order?.source === 'agent'

  return (
    <>
      <Sections title={`Cash Offer (${titleize(lead.stage)})`}>
        <Section>
          <Section>
            <FormField title={getCashOfferLeadFieldName('createdAt')} inline>
              <InlineEditDateInput {...getProps('createdAt')} />
            </FormField>
            <FormField title={getLeadFieldName('leadUsersNamesString')} inline>
              {lead.leadUsersNamesString}
            </FormField>
            <FormField title={getCashOfferLeadFieldName('source')} inline>
              <InlineEditSelect {...getProps('source')} options={sourcesOptions} />
            </FormField>
            {lead.isFailed && (
              <FormField title={getCashOfferLeadFieldName('reasonForFail')} inline>
                {dataOrDash(failedReason?.name)}
              </FormField>
            )}
          </Section>

          {showReferringAgent && (
            <AgentSection
              agent={referringAgent}
              program="cash_offer"
              onBlur={val =>
                updateLead({
                  referringOwnerId: val,
                  referringOwnerType: 'Agent'
                })
              }
            />
          )}
        </Section>
        <Section>
          <CCCashOfferLeadCallout lead={lead} />
        </Section>
      </Sections>
    </>
  )
}
