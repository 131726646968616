// @ts-strict

import { useQuery } from 'hooks'
import { fetchLenders } from 'services'

export const useLendersController = (
  leadId?: string,
  filters?: { states?: string; status?: string }
) => {
  const { data, isLoading } = useQuery(
    ['lenders', leadId, filters],
    () => fetchLenders(leadId, filters),
    {
      refetchOnWindowFocus: false
    }
  )

  return {
    isLoading,
    data
  }
}
