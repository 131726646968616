// @ts-strict
import {
  ButtonForm,
  CreateButton,
  TButtonFormContentsProps,
  TButtonFormFormProps
} from 'components'
import { LoanOfficerFormFields } from './LoanOfficerFormFields'
import { useCreateLoanOfficer } from './hooks/useCreateLoanOfficer'
import { TLoanOfficerResponse } from './utils'

type Props = {
  onSuccess?: (response: TLoanOfficerResponse, params?: any) => void
} & Partial<TButtonFormFormProps & TButtonFormContentsProps>

export const CreateLoanOfficerButtonForm = ({ onSuccess, ...props }: Props) => {
  const { formProps, fieldsProps } = useCreateLoanOfficer({ onSuccess })

  return (
    <ButtonForm
      {...formProps}
      isFormValid={formProps.isFormValid}
      title="Create Loan Officer"
      buttonComponent={CreateButton}
      {...props}
    >
      <LoanOfficerFormFields {...fieldsProps} />
    </ButtonForm>
  )
}
