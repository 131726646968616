import { Link } from 'react-router-dom'
import { Block, Ellipsis, List, TableCell as Td, TableRow as Tr } from 'components'
import { UserImpersonateButton } from 'content/User'
import { useCurrentUserContext } from 'contexts'
import { useUserController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import { UserInterface, UserModel } from 'models/v2/User'
import { ImpersonateIconButton } from 'templates'
import { csv, d, initializeString } from 'utils'

type Props = {
  user: UserInterface
}

export const ListUsersTableRow = ({ user }: Props) => {
  const { employeeImpersonate } = useUserController(user.id)
  const { currentUser } = useCurrentUserContext()
  const { salesAppImpersonatingAgentTeamCoord } = useFeatureFlags([
    'sales-app-impersonating-agent-team-coord'
  ])

  const handleImpersonate = () => {
    employeeImpersonate.mutateAsync()
  }

  const impersonateEmployeeButton = (
    <ImpersonateIconButton
      title="Impersonate employee"
      onClick={handleImpersonate}
      isLoading={employeeImpersonate.isLoading}
    />
  )

  return (
    <Tr data-testid={`user-id-${user.id}`}>
      <Td>
        <Block
          title={<Link to={UserModel.getPathname(user.id)}>{UserModel.getFullName(user)}</Link>}
          subtitle={d(user.region)}
          data-testid="user-name"
        />
      </Td>
      <Td data-testid="user-email">
        <Ellipsis>{user.email}</Ellipsis>
      </Td>
      <Td data-testid="user-roles">{csv(user.roles, 'name')}</Td>
      <Td data-testid="user-teams">
        <List>
          {user.teamMemberships.map(
            membership => `${membership.team.name} (${initializeString(d(membership.role))})`
          )}
        </List>
      </Td>
      <Td>
        <Block title={user.id} data-testid="user-id" />
      </Td>
      {!currentUser?.impersonated &&
        (salesAppImpersonatingAgentTeamCoord.enabled ? (
          // keep this when removing the FF
          <Td>
            {UserModel.isEmployee(user.roles) ? (
              impersonateEmployeeButton
            ) : (
              <UserImpersonateButton userId={user.id} as={ImpersonateIconButton} />
            )}
          </Td>
        ) : (
          <Td>{impersonateEmployeeButton}</Td>
        ))}
    </Tr>
  )
}
