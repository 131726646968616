import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Contents } from 'components'
import { ActivityList } from 'content/Activities'
import { AddAgentActivityNoteButtonForm } from 'content/Agent'
import { useAgentContext } from 'contexts'
import { useAgentActivitiesController } from 'controllers'
import { useDocumentTitle } from 'hooks'

export const AgentActivityTab = () => {
  const { agentId } = useParams()
  const { activitiesQuery } = useAgentActivitiesController(agentId)
  const { agent } = useAgentContext()
  const { setTitle } = useDocumentTitle()

  const actions = <AddAgentActivityNoteButtonForm agent={agent} />

  useEffect(() => {
    setTitle(`${agent.fullName} - Agent Activity`)
  }, [agent, setTitle])
  return (
    <>
      <Contents title="Activity" actions={actions}>
        <ActivityList
          activities={activitiesQuery.data?.activities}
          isLoading={activitiesQuery.isLoading}
        />
      </Contents>
    </>
  )
}
