import { environment } from 'environment'
import { getUserAuthUrl, postUserGoogleAuthUrl } from 'services/getUrl'
import { postRequest } from 'utils'

const errorMessages = {
  401: 'The credentials are not valid'
}

type TPostSigninPayload = {
  email: string
  password: string
}

export const postGoogleSignin = async (accessToken: string): Promise<String> => {
  try {
    const result = await postRequest(
      postUserGoogleAuthUrl(),
      { client_id: environment.doorkeeperAuthClientId },
      { headers: { Authorization: accessToken } }
    )
    return result.data.access_token
  } catch (error: any) {
    const status = error?.response.status
    if (errorMessages[status]) {
      throw new Error(errorMessages[status])
    }
    throw error
  }
}

export const postSignin = async (payload: TPostSigninPayload): Promise<any> => {
  try {
    const result = await postRequest(getUserAuthUrl({ action: 'create_token' }), payload)
    return result.data.token
  } catch (error: any) {
    const status = error?.response.status
    if (errorMessages[status]) {
      throw new Error(errorMessages[status])
    }
    throw error
  }
}
