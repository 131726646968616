import { Model } from 'models/Model'
import { User } from 'models/User'
import { ClientResponse } from 'schema'

export class Client extends Model {
  id?: string
  firstName?: string
  lastName?: string
  email?: string
  softCreditCheckReportLink?: string
  softCreditCheckIntelligence?: string
  user?: User

  constructor(attributes?: Omit<Client, keyof Model>) {
    super()
    Object.assign(this, attributes)
  }

  static create(clientResponse: Potential<ClientResponse>): Potential<Client> {
    if (!clientResponse) {
      return
    }
    const user = User.create(clientResponse.user)

    return new Client({ ...clientResponse, user })
  }
}
