// @ts-strict
import { ButtonForm, FormField, PhoneInput, Section, Sections, TextInput } from 'components'
import { Employee } from 'models'
import { useEmployeeEditButtonForm } from '.'

type TProps = {
  employee: Employee
}

export const EmployeeEditButtonForm = ({ employee }: TProps) => {
  const { formProps, payload, onChangeAttribute } = useEmployeeEditButtonForm(employee)

  return (
    <ButtonForm title="Edit Employee" buttonText="Edit" {...formProps}>
      <Sections>
        <Section>
          <FormField title="First Name" required>
            <TextInput
              value={payload.firstName}
              onChange={val => onChangeAttribute('firstName', val)}
              data-testid="firstName"
            />
          </FormField>

          <FormField title="Cell Number">
            <PhoneInput
              value={payload.phoneMobile}
              onChange={val => onChangeAttribute('phoneMobile', val)}
              data-testid="phoneMobile"
            />
          </FormField>

          <FormField title="Email" required>
            <TextInput
              value={payload.email}
              onChange={val => onChangeAttribute('email', val)}
              data-testid="email"
            />
          </FormField>
        </Section>

        <Section>
          <FormField title="Last Name" required>
            <TextInput
              value={payload.lastName}
              onChange={val => onChangeAttribute('lastName', val)}
              data-testid="lastName"
            />
          </FormField>

          <FormField title="Office Number">
            <PhoneInput
              value={payload.phoneOffice}
              onChange={val => onChangeAttribute('phoneOffice', val)}
              data-testid="phoneOffice"
            />
          </FormField>

          <FormField title="Region">
            <TextInput
              value={payload.region}
              onChange={val => onChangeAttribute('region', val)}
              data-testid="region"
            />
          </FormField>
        </Section>
      </Sections>
    </ButtonForm>
  )
}
