export const getTheme = (type, subType) => {
  switch (subType) {
    case 'outbound':
      return 'highlight'
    case 'agent_tier_one_reached':
      return 'bronze'
    case 'agent_tier_two_reached':
      return 'silver'
    case 'agent_tier_three_reached':
      return 'gold'
    case 'agent_unaccepts_warm_transfers':
      return 'alert'
    case 'agent_signed_agreement':
      return 'success'
    case 'agent_hidden_from_search':
      return 'alert'
    case 'agent_unhidden_from_search':
      return 'success'
    default:
      return undefined
  }
}
