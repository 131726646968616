import { useEffect } from 'react'
import { Button } from '@foundation/components'
import {
  DateInput,
  Form,
  FormActions,
  FormField,
  ListStringValues,
  Popup,
  Section
} from 'components'
import { LeadUncompletedTasks, UpdateStage } from 'content/Lead'
import formStyles from 'content/forms/Form.module.scss'
import { CashOfferProviderLead, CCCashOfferLead } from 'models'
import { cx } from 'utils'
import {
  CashOfferAgreementSigned,
  CashOfferClosedPaid,
  CashOfferClosedPurchase,
  CashOfferClosedSell,
  CashOfferInEscrowPurchase,
  CashOfferInEscrowSell,
  CashOfferOfferAccepted,
  CashOfferOfferCancelled,
  CashOfferOfferMade,
  CashOfferOfferRejected,
  CashOfferPropertyApproved,
  CashOfferPropertyCancelled,
  CashOfferPropertyRejected,
  CashOfferPropertySubmitted,
  useCashOfferDispositionContext
} from '../index'
import styles from './CashOfferLeadDispositionForm.module.scss'

type Props = {
  lead: CCCashOfferLead
  onCancel: () => void
  providerLead: CashOfferProviderLead
}

export const CashOfferLeadDispositionForm = ({ providerLead, lead, onCancel }: Props) => {
  const {
    commonPayload,
    currentStage,
    hasLeadRequirementErrors,
    hasUncompletedTasks,
    isLoading,
    isValid,
    leadRequirementErrors,
    onHandleSubmit,
    selectedStage,
    setCommonPayload,
    setCommonPayloadItem,
    setCommonRequiredFields,
    showSubmitForm,
    uncompletedTasks,
    cashOfferLead
  } = useCashOfferDispositionContext()

  useEffect(() => {
    setCommonRequiredFields(['occurredAt'])
  }, [setCommonRequiredFields])

  useEffect(() => {
    if (setCommonPayload && providerLead) {
      setCommonPayload({
        disposition: currentStage?.nextStageOptions?.[0]?.value || providerLead.stage,
        occurredAt: new Date().toISOString()
      })
    }
  }, [currentStage, setCommonPayload, providerLead])

  const stageFormFields = {
    agreement_signed: CashOfferAgreementSigned,
    closed_paid: CashOfferClosedPaid,
    closed_purchase: CashOfferClosedPurchase,
    closed_sell: CashOfferClosedSell,
    in_escrow_purchase: CashOfferInEscrowPurchase,
    in_escrow_sell: CashOfferInEscrowSell,
    offer_accepted: CashOfferOfferAccepted,
    offer_cancelled: CashOfferOfferCancelled,
    offer_made: CashOfferOfferMade,
    offer_rejected: CashOfferOfferRejected,
    property_approved: CashOfferPropertyApproved,
    property_submitted: CashOfferPropertySubmitted,
    property_rejected: CashOfferPropertyRejected,
    property_cancelled: CashOfferPropertyCancelled
  }

  const CashOfferLeadDispostionFields = stageFormFields[commonPayload.disposition]
  const isClosedPurchase = commonPayload.disposition === 'closed_purchase'
  const validationForClosedPurchase = isValid && cashOfferLead.propertyConditionApproved
  const propertyConditionApprovedLabel = `${!!cashOfferLead.propertyConditionApproved}`

  return (
    <Popup title="Cash Offer Disposition" onCancel={onCancel} className={formStyles.popup}>
      <Form onSubmit={() => onHandleSubmit(onCancel)} className={formStyles.form}>
        <UpdateStage
          payload={commonPayload}
          setPayloadItem={setCommonPayloadItem}
          currentStage={currentStage}
          hideOther
        />

        {isClosedPurchase && (
          <>
            <p className={styles.fieldDescription}>
              Property Condition Approved:{' '}
              <strong className={cx(!cashOfferLead.propertyConditionApproved && styles.errorValue)}>
                {propertyConditionApprovedLabel}
              </strong>
            </p>
            <p className={styles.fieldInformation}>
              Property condition must be approved for Closed Purchase
            </p>
          </>
        )}

        {showSubmitForm ? (
          <>
            {CashOfferLeadDispostionFields ? <CashOfferLeadDispostionFields /> : null}
            <Section>
              <FormField title="Occurred On" required>
                <DateInput
                  value={commonPayload.occurredAt}
                  onChange={val => setCommonPayloadItem('occurredAt', val)}
                />
              </FormField>
            </Section>
            <FormActions>
              <Button variant="ghost" color="neutralLight" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                isDisabled={isClosedPurchase ? !validationForClosedPurchase : !isValid}
                isLoading={isLoading}
              >
                Submit
              </Button>
            </FormActions>
          </>
        ) : (
          <>
            {hasLeadRequirementErrors && (
              <ListStringValues title="Stage Cannot be Updated" items={leadRequirementErrors} />
            )}
            {hasUncompletedTasks && (
              <Section>
                <LeadUncompletedTasks
                  selectedStage={selectedStage?.slug!}
                  items={uncompletedTasks}
                />
              </Section>
            )}
          </>
        )}
      </Form>
    </Popup>
  )
}
