import { getEnvelopesUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'
import { requestTryCatchWrapper } from 'utils'

export const postEnvelope = (leadId: string, payload: {}) =>
  requestTryCatchWrapper(() =>
    postRequest(
      getEnvelopesUrl({
        id: leadId,
        action: 'envelopes'
      }),
      snakeCaseKeys(payload)
    )
  )
