// @ts-strict
import { EscrowOfficeLead, Lead } from 'models'
import { EscrowOfficeLeadResponse, ProviderLeadResponse } from 'schema'

export class EscrowOfficeProviderLead {
  id!: string
  stage?: string
  escrowOfficeLead?: EscrowOfficeLead
  lead?: Lead

  constructor(escrowOfficeProviderLeadAttributes: EscrowOfficeProviderLead) {
    Object.assign(this, escrowOfficeProviderLeadAttributes)
  }

  static create(
    escrowOfficeProviderLeadResponse: Potential<ProviderLeadResponse>
  ): Potential<EscrowOfficeProviderLead> {
    if (!escrowOfficeProviderLeadResponse) {
      return
    }
    const escrowOfficeLead = EscrowOfficeLead.create(
      escrowOfficeProviderLeadResponse?.providable as EscrowOfficeLeadResponse
    )
    const lead = Lead.create(escrowOfficeProviderLeadResponse?.lead)

    return new EscrowOfficeProviderLead({
      ...escrowOfficeProviderLeadResponse,
      escrowOfficeLead,
      lead
    })
  }
}
