import { Table, TableResults } from 'components'
import { ListingTableRow } from 'content/Listing'
import { Listing } from 'models'
import styles from './ListingsTable.module.scss'
import { ListingsTableHeader } from './ListingsTableHeader'

export const ListingsTable = ({
  listings,
  isLoading
}: {
  isLoading: boolean
  listings: Listing[]
}) => {
  return (
    <Table className={styles.table}>
      <ListingsTableHeader />
      <TableResults type="Listings" isLoading={isLoading} colSpan={6}>
        {listings?.map(listing => (
          <ListingTableRow key={listing.id} listing={listing} />
        ))}
      </TableResults>
    </Table>
  )
}
