import { HtmlHTMLAttributes, ReactNode } from 'react'
import { cx } from 'utils'
import styles from './IconTitle.module.scss'

type Props = {
  flip?: boolean
  icon: ReactNode
} & HtmlHTMLAttributes<HTMLDivElement>

export const IconTitle = ({ className, children, icon, flip = false, ...rest }: Props) => {
  return (
    <div
      data-testid="IconTitle"
      className={cx(className, styles.iconTitle, !!flip && styles.iconFlip)}
      {...rest}
    >
      {!flip && icon}
      <div className={cx(styles.title)}>{children}</div>

      {flip && icon}
    </div>
  )
}
