import { getLead, Lead } from 'models'
import {
  getHapiRequestParams,
  LeadAttributesWithoutId,
  MortgageLeadSchema,
  OrderAttributes
} from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchMortgageLead = async (id: string): Promise<Lead> => {
  const leadFields: Array<LeadAttributesWithoutId> = [
    'areaId',
    'areaType',
    'bbysEligibleArea',
    'callReview',
    'createdAt',
    'eligibilityDetails',
    'email',
    'emailAlt',
    'fullAddress',
    'furthestStage',
    'hasMinimumDownPayment',
    'humanTimeline',
    'lastCallDate',
    'lastStageUpdate',
    'latitude',
    'longitude',
    'lowerApplicationLink',
    'name',
    'notesToAgents',
    'partnerName',
    'phoneAlt',
    'price',
    'propertyType',
    'propertyUuid',
    'reasonForFail',
    'salesNotes',
    'sellerBbysEligible',
    'source',
    'sourcePageType',
    'stage',
    'stateCode',
    'userType',
    'isDeal',
    'qualificationTalkedToClient'
  ]
  const orderFields: Array<keyof OrderAttributes> = [
    'createdAt',
    'ownedBusinessUnit',
    'source',
    'stage',
    'updatedAt'
  ]

  const leadResponseShape: MortgageLeadSchema = {
    lead: [
      ...leadFields,
      { client: [{ user: ['firstName', 'lastName', 'email'] }] },
      { order: orderFields },
      { phoneNumbers: ['number', 'phoneType'] },
      { leadUsers: ['role', { user: ['name'] }] },
      {
        'referringOwner:agent': [
          'email1',
          'email2',
          'firstName',
          'lastName',
          'licenseNumber',
          { agentTeam: ['name'] },
          { agentTeamMemberships: [{ agentTeam: ['name'] }] },
          { phoneNumbers: ['number', 'phoneType'] }
        ]
      },
      {
        'lenderProviderLeads:providerLead': [
          {
            'providable:lenderLead': [
              'actualLoanAmount',
              'actualPurchasePrice',
              'appraisalExpectedDate',
              'appraisalReceivedDate',
              'archived',
              'closeOfEscrowOn',
              {
                lender: ['slug']
              },
              'coeDateChangeReason',
              'comformingLoanLimit',
              'conditionalApprovalDate',
              'downpaymentAmount',
              'dtiAtMax',
              'dtiRatios',
              'eaveLoanApplicationId',
              'interestRate',
              'lmTaskStage',
              'loanAmount',
              'loanDecision',
              'ltv',
              'product',
              'program',
              'propertyCounty',
              'targetCoeDate',
              'term',
              'loanApplicationLink',
              'loanManagerName',
              'loanMilestone',
              'loanStatus',
              'ltvAtMaxPurchasePriceAndLoanAmount',
              'maxDownPayment',
              'maxLoanAmount',
              'maxPurchasePrice',
              'pinnedNote',
              'updatedAt'
            ]
          }
        ]
      }
    ]
  }

  const searchParams = getHapiRequestParams(leadResponseShape)
  const response = await getRequest(getLeadsUrl({ id, searchParams }))

  return await deserializeAsync(response.data, { transform: getLead })
}
