// @ts-strict
import { PropertyValuation } from '@types'
import { Marker, Point } from 'classes'
import { Agent, MasterProperty, TradeInLeadStageUpdate, TradeInProviderLead } from 'models'
import { TradeInLeadResponse } from 'schema'
import { formatPrice, normalizeDateString } from 'utils'

type TTradeInLeadConstructor = Omit<TradeInLead, 'toMarker' | 'toPoint'>

export class TradeInLead {
  id!: string
  actualCashCloseFee?: string
  actualLoanAmount?: string
  agentValuation?: string
  approvalType?: string
  avmDate?: string
  countryCode?: string
  createdAt?: string
  daysSinceFunding?: string
  departingMasterProperty?: MasterProperty
  departingPropertyActualDom?: number
  departingPropertyActualSalePrice?: number
  departingPropertyAgentValuationReceivedAt?: string
  departingPropertyAmountOverGuaranteedPrice?: string
  departingPropertyBuyersAgentCommission?: string
  departingPropertyCashCloseFee?: string
  departingPropertyClientValuationReceivedAt?: string
  departingPropertyCloseOfPurchaseEscrowAt?: string
  departingPropertyCloseOfSaleEscrowAt?: string
  departingPropertyCondition?: string
  departingPropertyDaysOfOwnership?: number
  departingPropertyExpectedListingPrice?: number
  departingPropertyExpectedRevenue?: string
  departingPropertyExternalListingDate?: string
  departingPropertyExternalListingStatus?: string
  departingPropertyExternalListingStatusDate?: string
  departingPropertyExternalLoanPayoff?: number
  departingPropertyFlags?: string[]
  departingPropertyFundsWiredAmount?: string
  departingPropertyFundsWiredToClientAt?: string
  departingPropertyFundsWiredToHlAt?: string
  departingPropertyGuaranteedPrice?: number
  departingPropertyHlOwnedAt?: string
  departingPropertyHomeownerAssociation?: boolean
  departingPropertyInspectedAt?: string
  departingPropertyInspectionScheduledAt?: string
  departingPropertyListedAt?: string
  departingPropertyListingPrepFee?: string
  departingPropertyListingPrice?: number
  departingPropertyLoanPayoff?: number
  departingPropertyLocalTransferTax?: string
  departingPropertyMarketDom?: number
  departingPropertyMarketIndex?: string
  departingPropertyMarketInventoryCount?: string
  departingPropertyMeetsPropertyGuidelines?: string
  departingPropertyOfferAcceptedAt?: string
  departingPropertyOfferCount?: number
  departingPropertyOpenedPurchasedEscrowAt?: string
  departingPropertyOpenedSaleEscrowAt?: string
  departingPropertyOwnershipExpenses?: string
  departingPropertyPercentEquity?: string
  departingPropertyPreliminaryTitleReviewed?: boolean
  departingPropertyPreliminaryValuation?: number
  departingPropertyPriceDelta?: number
  departingPropertyQualificationReason?: string
  departingPropertyQualified?: boolean
  departingPropertyQualifiedAt?: string
  departingPropertyRepairCosts?: string
  departingPropertySellerAgent?: Agent
  departingPropertySellerAgentId?: string
  departingPropertySellersAgentCommission?: string
  departingPropertySolarLoanBalance?: number
  departingPropertySolarPanels?: string
  departingPropertyTransactionCosts?: string
  departingPropertyUuid?: string
  departingPropertyValuation?: number
  dpActualAgentFees?: number
  dpActualLoanPayoff?: number
  dpActualSolarLoanBalance?: number
  dpActualOtherExpenses?: number
  dpActualInspection?: number
  dpActualHoaDocumentFees?: number
  dpActualNewHomePurchaseEquityHl?: string
  dpActualPurchaseFundedDate?: string
  dpActualTotalPropertyOwnership?: number
  dpActualTotalTransactionCosts?: number
  dpActualTotalRepairCostsAndValuationAdjustments?: number
  dpActualTotalListingPrepWork?: number
  dpActualFeeFirstThirtyDays?: string
  dpActualFeeProratedAfterThirtyDays?: string
  dpActualPropertyTaxes?: number
  dpActualPropertyInsurance?: number
  dpActualHoaTransferFees?: number
  dpActualHoaDues?: number
  dpActualSipCosts?: number
  dpActualUtilities?: number
  dpActualCityTransferTaxes?: number
  dpActualAdditionalRepairs?: number
  dpActualCreditsGivenToBuyer?: number
  dpActualCreditsGivenToSeller?: number
  dpActualServicesBilledFromEscrow?: number
  dpActualStagingCosts?: number
  dpActualCleaningCosts?: number
  dpActualPaintingCosts?: number
  dpActualFlooringCosts?: number
  dpActualRepairsCosts?: number
  dpActualOtherListingCosts?: number
  dpActualHomeWarrantyPolicy?: number
  dpActualClosingCosts?: number
  dpBorrowingBase?: string
  dpBuyersAgentCommissionRate?: number
  dpCapitalDaysOutstanding?: number
  dpDaysSellerInPossession?: number
  dpEstimatedDaysOfWork?: number
  dpEstimatedListToPending?: number
  dpEstimatedSaleEscrow?: number
  dpFundingAmount?: string
  dpGpPercentageOfValuation?: string
  dpHlEquity?: string
  dpLocalTransferTaxRate?: number
  dpPropertyType?: string
  dpSellersAgentCommissionRate?: number
  dpSellerCredits?: string
  dpSellerVacateDate?: string
  dpTargetAnnualPropertyTaxRate?: number
  dpTargetClientClosingCosts?: string
  dpTargetNewHomePurchaseEquity?: string
  dpTargetPurchaseCoeDate?: string
  dpTargetSellCoeDate?: string
  dpTargetTotalCosts?: string
  dpTotalActualCosts?: number
  dpTotalEquity?: string
  dpTotalEstimatedDaysOfOwnership?: number
  dpTotalProceedsFromFinalSale?: number
  dpTargetTradeInFee?: string
  dpTargetTradeInFeeRate?: number
  dpTotalAgentCommission?: string
  dpUpfrontProceedsToClient?: string
  dpAgentAfterRepairValue?: string
  dpAgentAfterRepairValueDate?: string
  dpAnalystValuation?: string
  dpAnalystValuationDate?: string
  dpAnalystAfterRepairValue?: string
  dpAnalystAfterRepairValueDate?: string
  dpSolarLeasePayoff?: string
  dp_first_position_payoffs?: string
  dp_loan_to_value_ratio?: string
  dp_non_first_position_balances?: string
  dp_second_mortgage_balance?: string
  dp_heloc_balance?: string
  dp_other_liens_balance?: string
  dp_max_downpayment_equity?: string
  dp_actual_loan_payoff?: string
  hlcsAttached?: boolean
  incomingMasterProperty?: MasterProperty
  incomingPropertyBuyerAgent?: Agent
  incomingPropertyCloseOfEscrowAt?: string
  incomingPropertyEstimatedMoveInDate?: string
  incomingPropertyFoundAt?: string
  incomingPropertyFullAddress?: string // this is incomingMasterProperty.displayAddress
  incomingPropertyInspectedAt?: string
  incomingPropertyLoanApprovedAt?: string
  incomingPropertyLoanClosedAt?: string
  incomingPropertyOfferAcceptedAt?: string
  incomingPropertyOfferMadeAt?: string
  incomingPropertyRpaSignedAt?: string
  incomingPropertySalePrice?: number
  incomingPropertyUuid?: string
  incomingPropertyValuation?: number
  inspectionCompletedAt?: string
  introSentAt?: string
  introduced?: boolean
  netProceedsRequired?: string
  number?: string
  phoneType?: string
  propertyValuation?: PropertyValuation
  providerLead?: TradeInProviderLead
  purchasingPower?: string
  tradeInLeadStageUpdates?: TradeInLeadStageUpdate[]
  updatedAt?: string
  simpleSale?: boolean
  tradeInPlus?: boolean
  twoCheckHlSimpleSale?: boolean

  constructor(tradeInLeadAttributes: TTradeInLeadConstructor) {
    Object.assign(this, tradeInLeadAttributes)
  }

  toMarker(color?: string) {
    return this.providerLead?.lead?.latitude && this.providerLead.lead.longitude
      ? new Marker({
          name: `${formatPrice(this.providerLead.lead.price, 'short')} ${
            this.providerLead.lead.fullAddress
          }`,
          description: this.providerLead.lead.name,
          type: 'lead',
          point: this.toPoint(),
          color
        })
      : undefined
  }

  toPoint() {
    return this.providerLead?.lead?.latitude && this.providerLead.lead.longitude
      ? new Point({
          latitude: this.providerLead.lead.latitude,
          longitude: this.providerLead.lead.longitude
        })
      : undefined
  }

  static create(tradeInLeadResponse: Potential<TradeInLeadResponse>): Potential<TradeInLead> {
    if (!tradeInLeadResponse) {
      return
    }

    const departingMasterProperty = MasterProperty.create(
      tradeInLeadResponse.departingMasterProperty
    )
    const departingPropertySellerAgent = Agent.create(
      tradeInLeadResponse.departingPropertySellerAgent
    )
    const incomingMasterProperty = MasterProperty.create(tradeInLeadResponse.incomingMasterProperty)
    const incomingPropertyBuyerAgent = Agent.create(tradeInLeadResponse.incomingPropertyBuyerAgent)
    const incomingPropertyFullAddress = tradeInLeadResponse.incomingMasterProperty?.displayAddress
    const providerLead = TradeInProviderLead.create(tradeInLeadResponse?.tradeInProviderLead)
    const tradeInLeadStageUpdates = (tradeInLeadResponse.tradeInLeadStageUpdates?.map(
      TradeInLeadStageUpdate.create
    ) || []) as TradeInLeadStageUpdate[]
    const departingPropertyCloseOfPurchaseEscrowAt = normalizeDateString(
      tradeInLeadResponse.departingPropertyCloseOfPurchaseEscrowAt
    )
    const departingPropertyCloseOfSaleEscrowAt = normalizeDateString(
      tradeInLeadResponse.departingPropertyCloseOfSaleEscrowAt
    )
    const inspectionCompletedAt = normalizeDateString(tradeInLeadResponse.inspectionCompletedAt)
    const dpLocalTransferTaxRate = tradeInLeadResponse.dpLocalTransferTaxRate
      ? Number(tradeInLeadResponse.dpLocalTransferTaxRate)
      : undefined
    const dpBuyersAgentCommissionRate = Number(tradeInLeadResponse.dpBuyersAgentCommissionRate)
    const dpSellersAgentCommissionRate = Number(tradeInLeadResponse.dpSellersAgentCommissionRate)
    const dpTargetTradeInFeeRate = Number(tradeInLeadResponse.dpTargetTradeInFeeRate)
    const dpTargetAnnualPropertyTaxRate = Number(tradeInLeadResponse.dpTargetAnnualPropertyTaxRate)

    const hlcsAttached = tradeInLeadResponse.hlcsAttached

    return new TradeInLead({
      ...tradeInLeadResponse,
      departingMasterProperty,
      departingPropertySellerAgent,
      hlcsAttached,
      incomingMasterProperty,
      incomingPropertyBuyerAgent,
      incomingPropertyFullAddress,
      providerLead,
      tradeInLeadStageUpdates,
      departingPropertyCloseOfPurchaseEscrowAt,
      departingPropertyCloseOfSaleEscrowAt,
      inspectionCompletedAt,
      dpLocalTransferTaxRate,
      dpBuyersAgentCommissionRate,
      dpSellersAgentCommissionRate,
      dpTargetTradeInFeeRate,
      dpTargetAnnualPropertyTaxRate
    })
  }
}
