// @ts-strict
import { useState } from 'react'
import {
  ActionButton,
  AgentsByProgramAutoComplete,
  FormField,
  Section,
  Sections,
  Select
} from 'components'
import { LoanOfficersAutoComplete } from 'components/AutoComplete/LoanOfficersAutoComplete'
import { CreateLoanOfficerButtonForm } from 'content/LoanOfficer'
import { sourcesOptions } from 'lookups'
import { formatPhone } from 'utils'
import { TFormItemsProps } from '../types'

export const SourceItems = ({
  payload,
  onChangeAttribute,
  showReferringAgent,
  showReferringLoanOfficer,
  handleSelectSource,
  marketplaceProgramsServerSlugsParams
}: TFormItemsProps) => {
  const [loanOfficerDisplay, setLoanOfficerDisplay] = useState('')

  return (
    <Sections>
      <Section>
        <FormField title="Source" required>
          <Select
            value={payload?.source}
            options={sourcesOptions}
            onChange={val => (handleSelectSource ? handleSelectSource(val) : null)}
          />
        </FormField>
      </Section>
      <Section>
        {showReferringAgent && (
          <FormField title="Referring Agent" required>
            <AgentsByProgramAutoComplete
              name="referring_owner_id"
              marketplaceProgramServerSlugs={marketplaceProgramsServerSlugsParams || {}}
              onSelect={agent => {
                if (!agent) {
                  return
                }

                onChangeAttribute('referringOwnerId', agent?.id)

                if (payload?.leadType === 'bbys') {
                  onChangeAttribute('agentName', agent?.name)
                  onChangeAttribute('agentEmail', agent?.email)
                  if (agent.phoneNumbers && agent.phoneNumbers?.length) {
                    onChangeAttribute('agentPhone', formatPhone(agent?.phoneNumbers[0]?.number))
                  }
                }
              }}
              onClear={() => {
                onChangeAttribute('referringOwnerId', undefined)

                if (payload?.leadType === 'bbys') {
                  onChangeAttribute('agentName', undefined)
                  onChangeAttribute('agentEmail', undefined)
                  onChangeAttribute('agentPhone', undefined)
                }
              }}
            />
          </FormField>
        )}
        {showReferringLoanOfficer && (
          <FormField
            title="Referring Loan Officer"
            hint={
              <span>
                Can't find the loan officer?{' '}
                <CreateLoanOfficerButtonForm
                  onSuccess={(loanOfficer, params) => {
                    setLoanOfficerDisplay(loanOfficer.name)
                    onChangeAttribute('referringOwnerId', loanOfficer.userId)
                    onChangeAttribute('lenderCompanyName', loanOfficer.companyName)
                    onChangeAttribute('loanOfficerName', loanOfficer.name)
                    onChangeAttribute('loanOfficerEmail', loanOfficer.email)
                    onChangeAttribute('loanOfficerPhone', formatPhone(params?.loanOfficerPhone))
                  }}
                  title="Create a new loan officer"
                  buttonComponent={ActionButton}
                />
              </span>
            }
            required
          >
            <LoanOfficersAutoComplete
              value={loanOfficerDisplay}
              onSelect={loanOfficer => {
                if (!loanOfficer) {
                  return
                }
                setLoanOfficerDisplay(loanOfficer.name)
                onChangeAttribute('referringOwnerId', loanOfficer.userId)
                onChangeAttribute('lenderCompanyName', loanOfficer.companyName)
                onChangeAttribute('loanOfficerName', loanOfficer.name)
                onChangeAttribute('loanOfficerEmail', loanOfficer.email)
                onChangeAttribute('loanOfficerPhone', formatPhone(loanOfficer.phoneNumber?.number))
              }}
              onClear={() => {
                setLoanOfficerDisplay('')
                onChangeAttribute('referringOwnerId', undefined)
                onChangeAttribute('lenderCompanyName', undefined)
                onChangeAttribute('loanOfficerName', undefined)
                onChangeAttribute('loanOfficerEmail', undefined)
                onChangeAttribute('loanOfficerPhone', undefined)
              }}
            />
          </FormField>
        )}
      </Section>
    </Sections>
  )
}
