import { SearchParams } from '@types'
import { removeAttributesWithNullValues } from 'utils'
import { getSort } from '../../utils'

const sortParam = {
  Created: 'created_at',
  Name: 'name.keyword',
  Score: '_score',
  Updated: 'order.last_lead_stage_update',
  Stage: 'stage',
  'DR Purchase Actual COE Date': 'trade_in.departing_property.close_of_purchase_escrow_at',
  'IR Purchase Actual COE Date': 'trade_in.incoming_property.close_of_escrow_at',
  'DR Sale Actual COE Date': 'trade_in.departing_property.close_of_sale_escrow_at',
  'DR Sale Open Escrow Date': 'trade_in.departing_property.opened_sale_escrow_at',
  'DR Purchase Open Escrow Date': 'trade_in.departing_property.opened_purchased_escrow_at',
  'Trade-In+': 'trade_in.trade_in_plus',
  'Funding Date': 'providable.purchase_funded_at',
  'Listed Date': 'trade_in.departing_property.listed_at'
}

export type EscrowLeadsSearchParams = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'searchTerm' | 'sortDir' | 'sortBy'
> & {
  completionStatus?: 'complete' | 'incomplete'
  escrowOfficer?: string[]
  source?: string[]
  stageFurthest?: string[]
  stateName?: string
}

const getFilters = ({
  searchTerm,
  stageFurthest,
  source,
  escrowOfficer,
  completionStatus,
  stateName
}: EscrowLeadsSearchParams) => {
  const filters = {}

  if (searchTerm) {
    filters['filter[_search]'] = searchTerm
  }

  if (source) {
    filters['filter[order.source]'] = source
  }

  if (stageFurthest) {
    filters['filter[stage]'] = stageFurthest
  }

  if (escrowOfficer) {
    filters['filter[_escrow_officer]'] = escrowOfficer
  }

  switch (completionStatus) {
    case 'complete':
      filters['filter[escrow_office_lead.order_status]'] = ['CLOSED', 'CANCELLED']
      break
    case 'incomplete':
      filters['filter[escrow_office_lead.order_status]'] = 'OPEN'
      break
    default:
      break
  }

  if (stateName) {
    filters['filter[state_name]'] = stateName
  }

  return filters
}

export const getEscrowLeadsSearchParams = ({
  page,
  perPage,
  sortBy,
  sortDir,
  ...filters
}: EscrowLeadsSearchParams) => {
  return removeAttributesWithNullValues({
    'page[number]': page ? page : undefined,
    'page[size]': perPage ? perPage : undefined,
    'filter[user_type]': 'escrow',
    sort: getSort(sortParam, sortDir, sortBy),
    ...getFilters(filters)
  })
}
