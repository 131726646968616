import { TModelFieldSetting } from '../ModelField'

export type TServiceOfficeFieldAttribute = 'licenseNumber' | 'name'

export type TServiceOfficeFieldSetting = {
  attr: TServiceOfficeFieldAttribute
} & TModelFieldSetting

export const serviceOfficeFieldsSettings: TServiceOfficeFieldSetting[] = [
  {
    attr: 'licenseNumber',
    name: 'License Number'
  },
  { attr: 'name', name: 'Settlement Agency' }
]
