import { SearchParams } from '@types'
import { TLeadUserTypeServerSlug } from 'lookups'
import { asArray, presence, removeAttributesWithNullValues } from 'utils'

type SearchParamsSettings = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'searchTerm' | 'sortDir' | 'sortBy'
>

export type TOrdersSearchArgs = Partial<{
  activeIRStages: string
  aeId: string
  cashOfferLeadStages: string
  daysAgo: string
  escrowAttached: string
  expressClose: string
  ids: string[]
  inspected: string
  leadStages: string
  listingStatuses: string
  maxPrice: string
  minPrice: string
  mortgageLeadStages: string
  needsListingReview: string
  oppStatus: string
  orderStage: string
  protectedOfferStatus: string
  scope: string
  searchTerm: string
  source: string
  tradeInLeadStages: string
  tradeInSimpleSale: string
  transactionTeamMember: string
  userType: TLeadUserTypeServerSlug | TLeadUserTypeServerSlug[]
  valuation: string
}> &
  SearchParamsSettings

const sortParam = {
  Client: 'name.keyword',
  'COE Purchase': 'providable.close_of_purchase_escrow_at',
  'COE Sale': 'providable.close_of_sale_escrow_at',
  Created: 'order.created_at',
  FundingDate: 'providable.purchase_funded_at',
  Updated: 'order.last_lead_stage_update',
  Price: 'price',
  IntroSentAt: 'agent_lead_agents.intro_sent_at'
}

const getSort = (sortBy: Potential<string>, sortDir: Potential<string>) => {
  const dir = sortDir === 'desc' ? '-' : ''

  if (sortBy) {
    return `${dir}${sortParam[sortBy]}`
  }
  return sortParam['Due']
}

const getFilters = ({
  activeIRStages,
  aeId,
  cashOfferLeadStages,
  daysAgo,
  escrowAttached,
  expressClose,
  inspected,
  leadStages,
  listingStatuses,
  maxPrice,
  minPrice,
  mortgageLeadStages,
  needsListingReview,
  oppStatus,
  orderStage,
  protectedOfferStatus,
  ids,
  scope,
  searchTerm,
  source,
  tradeInLeadStages,
  tradeInSimpleSale,
  transactionTeamMember,
  userType,
  valuation
}: TOrdersSearchArgs) => {
  const filters = {}
  const stages = [
    ...[leadStages, cashOfferLeadStages, tradeInLeadStages, mortgageLeadStages].flat()
  ].filter(Boolean)

  if (activeIRStages) {
    filters['filter[active_cash_offer.stage]'] = activeIRStages
  }
  if (aeId) {
    filters['filter[_ae_id]'] = aeId
  }
  if (cashOfferLeadStages) {
    filters['filter[user_type]'] = [
      ...asArray(filters['filter[user_type]']),
      'cc_cash_offer'
    ].filter(Boolean)
  }
  if (daysAgo) {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    date.setDate(date.getDate() - Number(daysAgo))
    filters['filter[>last_stage_update]'] = date.toISOString()
  }
  if (escrowAttached) {
    filters['filter[escrow_attachment]'] = escrowAttached
  }
  if (inspected) {
    filters['filter[trade_in_departing_property_inspected_at]'] = inspected
  }
  if (maxPrice) {
    filters['filter[<price]'] = maxPrice
  }
  if (minPrice) {
    filters['filter[>price]'] = minPrice
  }
  if (mortgageLeadStages) {
    filters['filter[user_type]'] = [...asArray(filters['filter[user_type]']), 'mortgage'].filter(
      Boolean
    )
  }
  if (oppStatus) {
    filters['filter[services_opportunities.status_id]'] = oppStatus
  }
  if (orderStage) {
    filters['filter[order.stage]'] = orderStage
  }
  if (protectedOfferStatus) {
    filters['filter[active_cash_offer.protected_offer_status]'] = protectedOfferStatus
  }
  if (ids) {
    filters['filter[id]'] = presence(ids) || 'none'
  }
  if (scope) {
    filters['filter[order.owned_business_unit]'] = scope
  }
  if (searchTerm && searchTerm.length) {
    filters['filter[_search]'] = searchTerm
  }
  if (source) {
    filters['filter[order.source]'] = source
  }
  if (stages.length) {
    filters['filter[stage]'] = stages
  }
  if (tradeInLeadStages) {
    filters['filter[user_type]'] = [...asArray(filters['filter[user_type]']), 'cc_trade_in'].filter(
      Boolean
    )
  }
  if (tradeInSimpleSale) {
    filters['filter[trade_in.simple_sale]'] = tradeInSimpleSale
  }
  if (transactionTeamMember) {
    filters['filter[lead_users.user_id]'] = transactionTeamMember
  }
  if (userType) {
    filters['filter[order.lead_user_types]'] = userType
  }
  if (valuation) {
    filters['filter[trade_in_departing_property_valuation]'] = valuation
  }
  if (expressClose) {
    filters['filter[active_cash_offer.express_close]'] = expressClose
  }
  if (needsListingReview) {
    filters['filter[needs_listing_review]'] = needsListingReview
  }

  if (listingStatuses) {
    filters['filter[external_listing.status]'] = listingStatuses
  }
  return removeAttributesWithNullValues(filters)
}

export const getSearchParams = ({
  page,
  perPage,
  sortBy,
  sortDir,
  ...filters
}: TOrdersSearchArgs) => {
  return {
    'page[number]': page ? page : undefined,
    'page[size]': perPage ? perPage : undefined,
    sort: getSort(sortBy, sortDir),
    ...getFilters(filters)
  }
}
