import { Dialog as DialogComponent } from './Dialog'
import { DialogBody, DialogContent, DialogFooter, DialogHeader } from './DialogContent'
import { DialogTrigger } from './DialogTrigger'

export type { DialogProps } from './Dialog'

export const Dialog = {
  Root: DialogComponent,
  Trigger: DialogTrigger,
  Content: DialogContent,
  Header: DialogHeader,
  Body: DialogBody,
  Footer: DialogFooter
}
