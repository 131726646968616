// @ts-strict

import { AgentTeamUsersAutoComplete, ButtonForm, FormField, useButtonForm } from 'components'
import { ButtonV2 } from 'components'
import { useAgentTeamContext } from 'contexts'
import type { AgentTeam } from 'models'
import { validatePresence } from 'utils'
import styles from './AddAgentTeamMemberButtonForm.module.scss'

type Props = {
  team: AgentTeam
}

export const AddAgentTeamMemberButtonForm = ({ team }: Props) => {
  const { addTeamMember } = useAgentTeamContext()
  const {
    formProps,
    fieldsProps: { onChangeAttribute }
  } = useButtonForm({
    mutation: addTeamMember,
    initialPayload: {
      userId: ''
    },
    formValidations: {
      userId: [validatePresence]
    }
  })

  return (
    <ButtonForm
      title="Add Team Member"
      buttonComponent={buttonProps => <ButtonV2 className={styles.button} {...buttonProps} />}
      {...formProps}
    >
      <FormField title="Team">{team.name}</FormField>
      <FormField title="Member" required>
        <AgentTeamUsersAutoComplete
          onSelect={user => {
            onChangeAttribute('userId', user.id)
          }}
          onClear={() => {
            onChangeAttribute('userId', '')
          }}
        />
      </FormField>
    </ButtonForm>
  )
}
