// @ts-strict

import { Option } from 'classes'
import { useButtonForm } from 'components'
import { useLeadTeamContext } from 'contexts'
import { useFeatureFlags } from 'hooks'
import { useTransactionTeams } from 'lookups'
import { sorts, validatePresence } from 'utils'
import { TNewLeadUserPayload } from './LeadUsersNewLeadUserButtonForm'

export const useLeadUsersNewLeadUserButtonForm = () => {
  const { salesOpsCentralizedLeadDistribution } = useFeatureFlags([
    'sales-ops-centralized-lead-distribution'
  ])
  const { addTeamMember, roleExists } = useLeadTeamContext()
  const { transactionTeamRoles, transactionTeamsMembersOptions, leadTeamRoles } =
    useTransactionTeams()

  const formValidations = {
    role: validatePresence,
    userId: validatePresence
  }

  const roles = salesOpsCentralizedLeadDistribution.enabled ? leadTeamRoles : transactionTeamRoles
  const transactionTeamRoleOptions = roles
    .map(
      role =>
        new Option({ name: role.description, value: role.value, disabled: roleExists(role.value) })
    )
    .sort(sorts.name)

  const initialPayload: TNewLeadUserPayload = {
    userId: '',
    role: ''
  }

  const { formProps, fieldsProps } = useButtonForm({
    initialPayload,
    mutation: addTeamMember,
    formValidations
  })

  return {
    formProps,
    fieldsProps,
    transactionTeamRoleOptions,
    transactionTeamsMembersOptions
  }
}
