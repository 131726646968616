import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Console.module.scss'

type ConsoleDetailsProps = {
  children: ReactNode
  className?: string
}

export const ConsoleDetails = ({ className, children }: ConsoleDetailsProps) => (
  <div className={cx(styles.details, className)}>{children}</div>
)
