import { ExternalLink, FormField, Section, Sections } from 'components'
import { useFeatureFlags } from 'hooks'
import { CCTradeInLeadDetailsProps } from '../CCTradeInLeadDetails'
import styles from './DRExpressApprovalSection.module.scss'

const CreditScoreIcon = ({ fill }: { fill: string }) => (
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.53334 0.657688C4.40446 0.522359 4.21113 0.470804 4.03069 0.518062C3.85025 0.56532 3.71063 0.709242 3.66337 0.889682L3.32397 2.23224L1.99216 1.85632C1.81172 1.80477 1.61839 1.85632 1.48735 1.98735C1.35632 2.11839 1.30477 2.31172 1.35632 2.49216L1.73224 3.82397L0.389682 4.16552C0.209242 4.21063 0.0674684 4.3524 0.0180623 4.53284C-0.0313437 4.71328 0.0223585 4.90446 0.157688 5.03334L1.1501 5.99998L0.157688 6.96662C0.0223585 7.09551 -0.0291956 7.28884 0.0180623 7.46928C0.0653203 7.64972 0.209242 7.78934 0.389682 7.8366L1.73224 8.176L1.35632 9.50781C1.30477 9.68825 1.35632 9.88158 1.48735 10.0126C1.61839 10.1436 1.81172 10.1952 1.99216 10.1436L3.32397 9.76773L3.66337 11.1103C3.70848 11.2907 3.85025 11.4325 4.03069 11.4819C4.21113 11.5313 4.40231 11.4776 4.53334 11.3444L5.49998 10.352L6.46662 11.3444C6.59766 11.4776 6.78884 11.5313 6.96928 11.4819C7.14972 11.4325 7.28934 11.2907 7.3366 11.1103L7.676 9.76773L9.00781 10.1436C9.18825 10.1952 9.38158 10.1436 9.51261 10.0126C9.64365 9.88158 9.6952 9.68825 9.64365 9.50781L9.26773 8.176L10.6103 7.8366C10.7907 7.79149 10.9325 7.64972 10.9819 7.46928C11.0313 7.28884 10.9776 7.09766 10.8444 6.96662L9.85201 5.99998L10.8444 5.03334C10.9776 4.90231 11.0313 4.71113 10.9819 4.53069C10.9325 4.35025 10.7907 4.21063 10.6103 4.16337L9.26773 3.82397L9.64365 2.49216C9.6952 2.31172 9.64365 2.11839 9.51261 1.98735C9.38158 1.85632 9.18825 1.80477 9.00781 1.85632L7.676 2.23224L7.33445 0.889682C7.28934 0.709242 7.14757 0.567468 6.96713 0.518062C6.78669 0.468656 6.59551 0.522359 6.46662 0.657688L5.49998 1.6501L4.53334 0.657688Z"
      fill={fill}
    />
  </svg>
)

type CreditScoreProps = {
  score?: string
}

const CreditScore = ({ score }: CreditScoreProps) => {
  if (!score) {
    return <p>Credit score unavailable</p>
  }

  const getIconColor = () => {
    const scoreColorMap = {
      green: '#38BF8F',
      yellow: '#FFD453',
      orange: '#FF9000',
      red: '#CA0D02',
      black: '#000000',
      frozen: '#F93A2F',
      'no-hit': '#F93A2F'
    }

    let color: string

    for (const key in scoreColorMap) {
      if (score.toLowerCase().includes(key)) {
        color = scoreColorMap[key]
        break
      }
    }

    return color
  }

  return (
    <div className={styles.creditScore}>
      <CreditScoreIcon fill={getIconColor()} />
      <p>{score}</p>
    </div>
  )
}

type PropertyConditionScoreLabel =
  | 'Disrepair'
  | 'Poor'
  | 'Average'
  | 'Good'
  | 'Excellent'
  | 'Luxury'

type PropertyConditionScoreProps = {
  propertyCondition?: string
}

const PropertyConditionScore = ({ propertyCondition }: PropertyConditionScoreProps) => {
  if (!propertyCondition) {
    return <p>Property score unavailable</p>
  }

  const scoreMap: Record<number, PropertyConditionScoreLabel> = {
    6: 'Disrepair',
    5: 'Poor',
    4: 'Average',
    3: 'Good',
    2: 'Excellent',
    1: 'Luxury'
  }

  const scoreKey = Math.floor(Number(propertyCondition))
  const score = scoreMap[scoreKey]

  return (
    <p>
      {propertyCondition} ({score})
    </p>
  )
}

type Props = Pick<CCTradeInLeadDetailsProps, 'lead'>

export const DRExpressApprovalSection = ({ lead }: Props) => {
  const tradeInLead = lead.tradeInProviderLead.tradeInLead

  const { softCreditCheck, restbAiIntegration } = useFeatureFlags([
    'soft-credit-check',
    'restb-ai-integration'
  ])

  if (!softCreditCheck.enabled && !restbAiIntegration.enabled) {
    return <></>
  }

  return (
    <Sections title="Express Approval">
      <Section>
        {softCreditCheck.enabled && !!lead.client && (
          <FormField title="Credit Score Insight" inline>
            <div className={styles.row}>
              <CreditScore score={lead.client.softCreditCheckIntelligence} />
              {lead.client.softCreditCheckReportLink && (
                <ExternalLink href={lead.client.softCreditCheckReportLink}>
                  See Credit Report
                </ExternalLink>
              )}
            </div>
          </FormField>
        )}

        {restbAiIntegration.enabled && (
          <FormField title="Property Condition Score" inline>
            <div className={styles.row}>
              <PropertyConditionScore propertyCondition={tradeInLead?.departingPropertyCondition} />
              <ExternalLink href={`${window.location.href}/documents/photos`}>
                See Photos
              </ExternalLink>
            </div>
          </FormField>
        )}
      </Section>
    </Sections>
  )
}
