import React from 'react'
import { Icon, Tier } from 'components'

export const getIcon = (type, subType) => {
  switch (type) {
    case 'user_event':
      switch (subType) {
        case 'agent_tier_one_reached':
          return <Tier tier={1} />
        case 'agent_tier_two_reached':
          return <Tier tier={2} />
        case 'agent_tier_three_reached':
          return <Tier tier={3} />
        case 'agent_hidden_from_search':
          const hiddenProps = {
            color: '#bc3030',
            opaque: true
          }
          return <Icon.Hidden {...hiddenProps} />
        default:
          return undefined
      }
    case 'agent_note':
      return <Icon.Note opaque />
    case 'outgoing_email_log':
      return <Icon.Envelope opaque />
    default:
      return undefined
  }
}
