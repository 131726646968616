import React from 'react'
import { PaginationParams } from 'components'
import { ListProvider } from 'contexts'
import { Listing } from 'models'
import { ListingsView } from '../ListingsView'
import { ListingsListHeader } from './ListingsListHeader'

type ListingsListProps = {
  isLoading?: boolean
  listings: Listing[]
  pageCount?: number
}

export const ListingsList = ({ listings, pageCount, isLoading }: ListingsListProps) => {
  return (
    <ListProvider>
      <ListingsListHeader />
      <ListingsView listings={listings} isLoading={isLoading} />
      <PaginationParams totalPages={pageCount} />
    </ListProvider>
  )
}
