export const updateTitle = (newTitle: string) => {
  const titleElement = document.getElementsByTagName('title')[0]
  if (titleElement) {
    titleElement.innerHTML = newTitle
  } else {
    document.title = newTitle
  }
}

export const getTitle = () => {
  const titleElement = document.getElementsByTagName('title')[0]
  if (titleElement) {
    return titleElement.innerHTML
  }
  return ''
}
