import styled from 'styled-components'
import { css } from 'styled-components/macro'

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1200px;
    margin: ${theme.space.$8} auto auto;
    gap: ${theme.space.$6};
    display: flex;
    flex-direction: column;
  `}
`
