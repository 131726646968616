import { SyntheticEvent } from 'react'
import { If, RoundImage, Tooltip } from 'components'
import { Agent } from 'models'
import { cx, randomImage } from 'utils'
import { AgentTierBadge } from '..'
import styles from './AgentAvatar.module.scss'

type Props = {
  agent: Agent
  className?: string
  disableTierBadge?: boolean
}

export const AgentAvatar = ({ agent, className, disableTierBadge }: Props) => {
  const tooltipText = [agent.fullName, agent.officeDisplayName, agent.state?.code]
    .filter(Boolean)
    .join(', ')

  const handleDefaultImage = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = randomImage(+agent.id, 50)
  }

  return (
    <Tooltip text={tooltipText} className={styles.tooltip}>
      <If test={!disableTierBadge}>
        <AgentTierBadge agent={agent} className={styles.agentTierBadge} />
      </If>
      <RoundImage
        className={cx(styles.agentAvatar, className)}
        src={agent.pictureThumbUrl}
        onError={handleDefaultImage}
        alt={agent.fullName}
      />
    </Tooltip>
  )
}
