// @ts-strict

import { Option } from 'classes'
import { IOptionable } from '../utils'
import { leadUserTypeSettings } from './leadUserTypeSettings'

export type TLeadUserTypeServerSlug =
  | 'buyer'
  | 'seller'
  | 'bbys'
  | 'cc_trade_in'
  | 'cc_cash_offer'
  | 'cc_hl_simple_sale'
  | 'renter'
  | 'agent'
  | 'unknown'
  | 'mortgage'
  | 'escrow'
  | 'investor'
  | 'bbys'

type TLeadUserTypeAttributes = {
  name: string
  value: TLeadUserTypeServerSlug
}

export class LeadUserType implements IOptionable {
  abbreviation!: string
  name!: string
  value!: TLeadUserTypeServerSlug
  primary?: boolean
  duplicatableLeadUserTypeSlugs?: TLeadUserTypeServerSlug[]
  onePerOrder?: boolean

  constructor(leadUserTypeAttributes: TLeadUserTypeAttributes) {
    Object.assign(this, leadUserTypeAttributes)

    const settings = leadUserTypeSettings[this.value]

    if (settings) {
      Object.assign(this, settings)
    }
  }

  toOption() {
    return new Option({ name: this.name, value: this.value })
  }
}
