import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Block, Graphic, IconTitle, List, TableCell as Td, TableRow as Tr } from 'components'
import { LeadBadge } from 'content/Lead'
import { useListContext } from 'contexts'
import { isAgentMatchingLead, isCCTradeInLead, Lead, Order, Property } from 'models'
import {
  d,
  dataOrDash,
  formatDate,
  formatPrice,
  formatTime,
  formatUserType,
  structureAddress
} from 'utils'
import styles from './OrdersTable.module.scss'
import {
  OrdersTableRowLeadAgent,
  OrdersTableRowLeadAgentsOpps,
  OrdersTableRowLeadCoe,
  OrdersTableRowLeadDaysOwned,
  OrdersTableRowLeadFundingDate,
  OrdersTableRowLeadGppVal,
  OrdersTableRowLeadTransactionTeam
} from '.'

const OrdersTableRowLead = ({ lead }: { lead: Lead }) => {
  const { showColumn } = useListContext()

  const leadTypeName =
    isCCTradeInLead(lead) && lead?.tradeInProviderLead?.tradeInLead?.simpleSale
      ? 'HLSS'
      : formatUserType(lead.userType)

  return (
    <Fragment>
      {showColumn('lead') && (
        <Td data-testid="lead-name">
          <IconTitle icon={<LeadBadge lead={lead} />}>
            <Link to={lead.getPathname()}>{leadTypeName}</Link>
            {isAgentMatchingLead(lead) && lead.needsListingReview && (
              <Graphic.Canary className={styles.canary} />
            )}
          </IconTitle>
        </Td>
      )}
      {showColumn('price') && <Td data-testid="lead-price">{formatPrice(lead.price, 'short')}</Td>}
      {showColumn('stage') && (
        <Td>
          <Block
            title={d(lead.stage)}
            subtitle={
              ['failed', 'nurture'].includes(lead.stage) ? d(lead.furthestStage) : undefined
            }
          />
        </Td>
      )}

      {showColumn('listingStatus') && isAgentMatchingLead(lead) && (
        <Td>
          <Block title={dataOrDash(d(lead.externalListing?.status))} />
        </Td>
      )}
      {isAgentMatchingLead(lead) && showColumn('agentsOpps') && (
        <Td data-testid="lead-agents">
          <OrdersTableRowLeadAgentsOpps lead={lead} />
        </Td>
      )}
      {showColumn('agent') && (
        <Td data-testid="lead-agents">
          <OrdersTableRowLeadAgent lead={lead} />
        </Td>
      )}
      {showColumn('transactionTeam') && (
        <Td data-testid="lead-transaction-team" className={styles.transactionTeam}>
          <OrdersTableRowLeadTransactionTeam lead={lead} />
        </Td>
      )}
      {showColumn('gppVal') && (
        <Td data-testid="lead-gpp-val" className={styles.gppVal}>
          <OrdersTableRowLeadGppVal lead={lead} />
        </Td>
      )}
      {showColumn('coe') && (
        <Td data-testid="lead-coe" className={styles.date}>
          <OrdersTableRowLeadCoe lead={lead} />
        </Td>
      )}
      {showColumn('daysOwned') && (
        <Td data-testid="lead-days-owned" className={styles.daysOwned}>
          <OrdersTableRowLeadDaysOwned lead={lead} />
        </Td>
      )}
      {showColumn('fundingDate') && (
        <Td data-testid="lead-funding-date" className={styles.date}>
          <OrdersTableRowLeadFundingDate lead={lead} />
        </Td>
      )}
      {showColumn('createdAt') && (
        <Td data-testid="lead-created-at" className={styles.date}>
          <Block
            title={formatDate(lead.createdAt, 'short')}
            subtitle={formatTime(lead.createdAt)}
          />
        </Td>
      )}
      {showColumn('updatedAt') && (
        <Td data-testid="lead-updated-at" className={styles.date}>
          <Block
            title={formatDate(lead.lastStageUpdate, 'short')}
            subtitle={formatTime(lead.lastStageUpdate)}
          />
        </Td>
      )}
      {showColumn('introSentAt') && (
        <Td data-testid="lead-intro-sent-at" className={styles.date}>
          <Block
            title={formatDate(lead.introSentAt, 'short')}
            subtitle={formatTime(lead.introSentAt)}
          />
        </Td>
      )}
    </Fragment>
  )
}

const OrdersTableRowProperty = ({ property }: { property: Property }) => {
  const { showColumn } = useListContext()
  const { street, cityState } = structureAddress(property.name)
  return (
    showColumn('property') && (
      <Td data-testid="property-name" rowSpan={property.leads.length} className={styles.property}>
        <List>
          {street}
          {cityState}
        </List>
      </Td>
    )
  )
}

export const OrdersTableRow = ({ order }: { order: Order }) => {
  const { showColumn, colSpan } = useListContext()

  if (!order.properties?.[0]) {
    return (
      <Tr data-testid="order-missing-leads">
        <td className={styles.missingLeads} colSpan={colSpan}>
          This Order is missing Leads. order #id: {order.id}
        </td>
      </Tr>
    )
  }

  return (
    <Fragment>
      <Tr data-testid={`order-id-${order.id}`}>
        {showColumn('businessUnit') && (
          <Td
            data-testid="order-business-unit"
            rowSpan={order.leads.length}
            className={styles.order}
          >
            {d(order.ownedBusinessUnit)}
          </Td>
        )}
        {showColumn('clientName') && (
          <Td data-testid="order-client-name" rowSpan={order.leads.length} className={styles.order}>
            <Block title={d(order.clientName)} subtitle={d(order.partnerName)} />
          </Td>
        )}
        {showColumn('orderStageSource') && (
          <Td
            data-testid="order-stage-source"
            rowSpan={order.leads.length}
            className={styles.order}
          >
            <Block title={d(order.stage)} subtitle={d(order.source)} />
          </Td>
        )}
        <OrdersTableRowProperty property={order.properties[0]} />
        <OrdersTableRowLead lead={order.properties[0].leads[0]} />
      </Tr>
      {order.properties[0].leads.slice(1).map(lead => (
        <Tr key={lead.id}>
          <OrdersTableRowLead lead={lead} />
        </Tr>
      ))}
      {order.properties.slice(1).map(property => (
        <Fragment key={property.name}>
          <Tr>
            <OrdersTableRowProperty property={property} />
            <OrdersTableRowLead lead={property.leads[0]} />
          </Tr>
          {property.leads.slice(1).map(lead => (
            <Tr key={lead.id}>
              <OrdersTableRowLead lead={lead} />
            </Tr>
          ))}
        </Fragment>
      ))}
    </Fragment>
  )
}
