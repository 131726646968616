import { ServiceOffice } from 'models'
import { getHapiRequestParams, ServiceOfficeSchema } from 'schema'
import { getServiceOfficesUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchServiceOffices = async (
  serviceOfficeResponseShape: ServiceOfficeSchema
): Promise<ServiceOffice[]> => {
  const searchParams = getHapiRequestParams(serviceOfficeResponseShape)
  const url = getServiceOfficesUrl({ searchParams })
  const response = await getRequest(url)

  return await deserializeAsync(response.data, { transform: ServiceOffice.create })
}
