import { ChangeEvent, InputHTMLAttributes } from 'react'
import { cx } from 'utils'
import styles from './Form.module.scss'

type Props = {
  className?: string
  onChange?: (val: string) => void
}

export const TextArea = ({
  className,
  onChange,
  ...props
}: Props & Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'onChange'>) => {
  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value.length ? e.currentTarget.value : ''
    onChange(value)
  }
  return (
    <textarea className={cx(styles.textArea, className)} onChange={handleOnChange} {...props} />
  )
}
