import { cx } from 'utils'
import { ButtonV2, ButtonV2Props } from '../ButtonV2'
import styles from './PrimaryButton.module.scss'

type Props = {
  isFormValid?: boolean
} & ButtonV2Props

export const PrimaryButton = ({ children, className, ...props }: Props) => {
  return (
    <ButtonV2
      data-testid="primary-button"
      className={cx(className, styles.primary)}
      type="button"
      {...props}
    >
      {children}
    </ButtonV2>
  )
}
