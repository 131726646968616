import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMap } from 'react-use'
import { useLeadContext } from 'contexts'
import { getCCHLSimpleSaleLeadStage, TCCHLSimpleSaleLeadStageSlug } from 'lookups'
import { CCHLSimpleSaleLead } from 'models'
import { asArray, presence, stringifyValue } from 'utils'

export type HLSSLeadDispositionPayload = {
  disposition?: TCCHLSimpleSaleLeadStageSlug
  note?: string
  occurredAt?: string
  reasonForFail?: string
}

export const useHLSSLeadDisposition = (lead: CCHLSimpleSaleLead) => {
  const { updateProviderLeadDisposition, uncompletedLeadTasks } = useLeadContext()
  const [commonPayload, { set: setCommonPayloadItem, setAll: setCommonPayload }] = useMap<any>()
  const [commonRequiredFields, setCommonRequiredFields] = useState<string[]>([])
  const [dispositionPayload, { set: setDispositionPayloadItem, setAll: setDispositionPayload }] =
    useMap<any>()
  const [requiredFields, setRequiredFields] = useState([])
  const [payloadFields, setPayloadFields] = useState([])

  const reasonForFailOtherIsValid =
    dispositionPayload?.reasonForFail === 'other' ? presence(dispositionPayload?.note) : true

  // determines if form is valid based on required fields.
  const isValid = useMemo(() => {
    return (
      requiredFields.every(field => presence(dispositionPayload[field])) &&
      commonRequiredFields.every(field => presence(commonPayload[field])) &&
      reasonForFailOtherIsValid
    )
  }, [
    dispositionPayload,
    commonPayload,
    commonRequiredFields,
    requiredFields,
    reasonForFailOtherIsValid
  ])

  useEffect(() => {
    if (lead && payloadFields && setDispositionPayload) {
      const newPayload = payloadFields.reduce((acc, field) => {
        return {
          ...acc,
          [field]:
            stringifyValue(lead.hlSimpleSaleLead[field]) ||
            stringifyValue(lead[field]) ||
            stringifyValue(lead.hlSimpleSaleProviderLead[field])
        }
      }, {})
      setDispositionPayload(newPayload)
    }
  }, [lead, payloadFields, setDispositionPayload])

  const registerFields = useCallback(
    ({ fields, required }: { fields?: string | string[]; required?: string | string[] }) => {
      setPayloadFields([...asArray(fields).filter(Boolean), ...asArray(required).filter(Boolean)])
      setRequiredFields([...asArray(required).filter(Boolean)])
    },
    []
  )

  const unregisterFields = useCallback(() => {
    setPayloadFields([])
    setRequiredFields([])
  }, [])

  const onHandleSubmit = (onSuccessCallback: () => void) => {
    updateProviderLeadDisposition.mutate(
      {
        leadId: lead.id,
        id: lead.hlSimpleSaleProviderLead.id,
        payload: { ...dispositionPayload, ...commonPayload }
      },
      { onSuccess: onSuccessCallback }
    )
  }

  const currentStage = getCCHLSimpleSaleLeadStage(lead.stage as TCCHLSimpleSaleLeadStageSlug)
  const furthestStage = getCCHLSimpleSaleLeadStage(
    lead.furthestStage as TCCHLSimpleSaleLeadStageSlug
  )
  const newStage = getCCHLSimpleSaleLeadStage('new')

  const selectedStage = getCCHLSimpleSaleLeadStage(commonPayload.disposition)

  const uncompletedTasks: string[] = uncompletedLeadTasks
    .filter(task => task.requiredForLeadStage === selectedStage?.slug)
    .map(item => item.title)

  const showSubmitForm = !presence(uncompletedTasks)

  return {
    commonPayload,
    dispositionPayload,
    isLoading: updateProviderLeadDisposition.isLoading,
    isValid,
    onHandleSubmit,
    currentStage,
    furthestStage,
    newStage,
    selectedStage,
    registerFields,
    requiredFields,
    setCommonPayload,
    setCommonPayloadItem,
    setCommonRequiredFields,
    setDispositionPayloadItem,
    showSubmitForm,
    unregisterFields,
    uncompletedTasks
  }
}
