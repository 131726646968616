import { CC_CASH_OFFER_LEADS_CACHE_KEY, useQuery } from 'hooks'
import { fetchCCCashOfferLeads } from 'services'
import { TCashOfferLeadsSearchParams } from 'services'

const defaultSearchSettings: TCashOfferLeadsSearchParams = {
  sortBy: 'Created',
  sortDir: 'desc',
  perPage: 25
}

type TArgs = {
  searchParams?: TCashOfferLeadsSearchParams
}

export const useCCCashOfferLeadsController = ({ searchParams = {} }: TArgs = {}) => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  const ccCashOfferLeadsQuery = useQuery([CC_CASH_OFFER_LEADS_CACHE_KEY, searchSettings], () =>
    fetchCCCashOfferLeads(searchSettings)
  )

  return { ccCashOfferLeadsQuery }
}
