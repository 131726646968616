import { isCCTradeInLead, Lead } from 'models'

interface PropertyResponse {
  leads: Lead[]
  name: string
}

export class Property {
  name?: string
  leads?: Lead[]
  hasTradeIn?: Boolean

  constructor(propertyAttributes: Property) {
    Object.assign(this, propertyAttributes)
  }

  static create(propertyResponse: Potential<PropertyResponse>): Potential<Property> {
    if (!propertyResponse) {
      return
    }
    const hasTradeIn = propertyResponse.leads?.some(isCCTradeInLead)
    return new Property({
      ...propertyResponse,
      hasTradeIn
    })
  }
}
