// @ts-strict
import { useState } from 'react'
import { Button, Popover } from '@foundation/components'
import { MenuSection, TableHeaderCell as Th } from 'components'
import type { TableCellProps } from 'components'
import styles from 'components/Table/Table.module.scss'
import { useParamsContext } from 'contexts'
import { cx } from 'utils'
import { SortText } from './SortText'

export type TableHeaderColumnProps = {
  name?: string
  sort?: { flip?: boolean; name: string; slug: string }[]
} & Omit<TableCellProps, 'children'>

export const TableHeaderColumn = ({ sort = [], name, ...props }: TableHeaderColumnProps) => {
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const { params, updateSort } = useParamsContext()
  const getDirection = (flip: boolean = false) => (flip ? 'desc' : 'asc')
  const isSelected = (slug: string) => slug === params.sortBy
  const isMenuItemSelected = sort.some(({ slug }) => isSelected(slug))
  const firstSort = sort[0]

  return (
    <Th {...props}>
      {sort.length === 0 && name}
      {sort.length === 1 && (
        <button
          className={cx(styles.headerButton, isSelected(firstSort.slug) && styles.selected)}
          onClick={() => {
            updateSort(firstSort.slug, getDirection(firstSort.flip))
          }}
        >
          <SortText dir={params.sortDir} showIcon={isSelected(firstSort.slug)}>
            {name ?? firstSort.name}
          </SortText>
        </button>
      )}
      {sort.length > 1 && (
        <Popover.Root
          isOpen={isOpenPopover}
          onOpenChange={setIsOpenPopover}
          placement="bottom-start"
        >
          <Popover.Trigger>
            <button className={styles.headerButton}>
              <SortText dir={params.sortDir} showIcon={isMenuItemSelected}>
                {sort?.map(({ slug, name }, index) => (
                  <div className={cx(isSelected(slug) && styles.selected)} key={slug}>
                    {name}
                    {index === 0 && <span className={styles.divider}>/</span>}
                  </div>
                ))}
              </SortText>
            </button>
          </Popover.Trigger>
          <Popover.Content>
            <MenuSection title="Sort by">
              <div className={styles.menuSectionOptions}>
                {sort.map(({ slug, name, flip }) => (
                  <Button
                    variant="link"
                    color="neutralLight"
                    size="xs"
                    onClick={() => {
                      updateSort(slug, getDirection(flip))
                      setIsOpenPopover(false)
                    }}
                    key={slug}
                  >
                    {name}
                  </Button>
                ))}
              </div>
            </MenuSection>
          </Popover.Content>
        </Popover.Root>
      )}
    </Th>
  )
}
