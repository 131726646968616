import { getLead, Lead } from 'models'
import {
  CashOfferLeadAttributes,
  CCCashOfferLeadSchema,
  getHapiRequestParams,
  LeadAttributesWithoutId,
  OrderAttributes
} from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchCCCashOfferLead = async (id: string): Promise<Lead> => {
  const leadFields: Array<LeadAttributesWithoutId> = [
    'calculatedValue',
    'callReview',
    'createdAt',
    'email',
    'emailAlt',
    'fullAddress',
    'furthestStage',
    'humanTimeline',
    'isDeal',
    'lastCallDate',
    'lastStageUpdate',
    'latitude',
    'longitude',
    'name',
    'notesToAgents',
    'partnerName',
    'phoneAlt',
    'price',
    'propertyType',
    'propertyUuid',
    'qualificationTalkedToClient',
    'reasonForFail',
    'revenueRecognitionDate',
    'salesNotes',
    'source',
    'sourcePageType',
    'stage',
    'userType',
    'dialerQueueStatus'
  ]
  const orderFields: Array<keyof OrderAttributes> = [
    'createdAt',
    'ownedBusinessUnit',
    'source',
    'stage',
    'updatedAt'
  ]

  const cashOfferLeadFields: Array<keyof CashOfferLeadAttributes> = [
    'agentNotes',
    'calculatedValue',
    'cashOfferFeeAmount',
    'cashOfferFeeMethod',
    'cashOfferFeeRate',
    'contingent',
    'depositRate',
    'expressClose',
    'incomingMasterProperty',
    'incomingPropertyCloseOfPurchaseEscrowAt',
    'incomingPropertyCloseOfSellEscrowAt',
    'incomingPropertyFundsWiredToHlAt',
    'incomingPropertyFundsWiredToSellerAt',
    'incomingPropertyInspectionContingencyWaived',
    'incomingPropertyListPrice',
    'incomingPropertyMaxOfferPrice',
    'incomingPropertyOfferAcceptedAt',
    'incomingPropertyOfferCancelledAt',
    'incomingPropertyOfferCancelledReason',
    'incomingPropertyOfferRejectedAt',
    'incomingPropertyOpenPurchaseEscrowAt',
    'incomingPropertyOpenSellEscrowAt',
    'incomingPropertySalePrice',
    'incomingPropertyValuation',
    'ipAdditionalDeposit',
    'ipAffordabilityUpdatedAt',
    'ipAvmValuation',
    'ipAvmValuationAt',
    'ipBorrowingBase',
    'ipBuyersAgentCommission',
    'ipBuyersAgentCommissionRate',
    'ipCapitalDaysOutstanding',
    'ipCashOfferDeposit',
    'ipCashOfferFee',
    'ipContingencyRemovalAt',
    'ipEarnestMoney',
    'ipFundingAmount',
    'ipHlEquity',
    'ipHomeownerAssociation',
    'ipInsuranceCoverage',
    'ipMaxOfferPriceAtHlValuation',
    'ipMaxOfferPriceWithoutBuffer',
    'ipMinAppraisal',
    'ipNetSettlement',
    'ipOfferTermsDaysTillCloseOfEscrow',
    'ipOfferTermsDaysTillInspectionContingencyEnds',
    'ipOfferTermsDeadlineDate',
    'ipOfferTermsInspectionObjectionDeadlineDate',
    'ipOfferTermsInspectionResolutionDeadlineDate',
    'ipOfferTermsInspectionTerminationDeadlineDate',
    'ipOfferTermsOptionTerminationFee',
    'ipPropertyType',
    'ipSellerCredits',
    'ipSolarPanels',
    'ipTargetCloseOfPurchaseEscrowAt',
    'ipTargetCloseOfSellEscrowAt',
    'protectedOfferStatus',
    'updatedAt',
    'propertyConditionApproved'
  ]

  const leadResponseShape: CCCashOfferLeadSchema = {
    lead: [
      ...leadFields,
      { client: [{ user: ['firstName', 'lastName', 'email'] }] },
      { leadUsers: ['role', { user: ['name'] }] },
      {
        order: [
          ...orderFields,
          {
            leads: [
              {
                'lenderProviderLeads:providerLead': [
                  {
                    'providable:lenderLead': [
                      'eaveLoanApplicationId',
                      'closeOfEscrowOn',
                      {
                        lender: ['slug']
                      }
                    ]
                  },
                  'stage'
                ]
              },
              {
                'escrowOfficeProviderLeads:providerLead': [
                  { 'providable:escrowOfficeLead': ['netEquity', 'orderClosingOn'] }
                ]
              }
            ]
          }
        ]
      },
      { phoneNumbers: ['number', 'phoneType'] },
      {
        'referringOwner:agent': [
          'email1',
          'email2',
          'firstName',
          'lastName',
          'licenseNumber',
          'officeDisplayName',
          { agentTeam: ['name'] },
          { agentTeamMemberships: [{ agentTeam: ['name'] }] },
          { phoneNumbers: ['number', 'phoneType'] }
        ]
      },
      {
        'cashOfferProviderLeads:providerLead': [
          'stage',
          'createdAt',
          'reasonForFail',
          { providerLeadCapitalPartner: [{ capitalPartner: ['name', 'fundingFacility'] }] },
          {
            'providable:cashOfferLead': [
              ...cashOfferLeadFields,
              {
                'incomingPropertyBuyerAgent:agent': [
                  'firstName',
                  'lastName',
                  { agentTeam: ['name'] },
                  { agentTeamMemberships: [{ agentTeam: ['name'] }] },
                  { phoneNumbers: ['number', 'phoneType'] }
                ]
              }
            ]
          }
        ]
      }
    ]
  }

  const searchParams = getHapiRequestParams(leadResponseShape)
  const url = getLeadsUrl({ id, searchParams })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: getLead })
}
