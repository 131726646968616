import { Option } from 'classes'
import { asArray } from 'utils'

export type TGetOptionNameValue = string | string[]

export interface IOptionable<T extends Option = Option> {
  toOption: () => T
}

export const toOptions = <T extends Option>(arr: IOptionable<T>[] = []): Option[] => {
  return arr.map?.(item => item.toOption()) || []
}

export const getOptions = <T extends Option>(arr: IOptionable<T>[] = []) => {
  const options = toOptions(asArray(arr))

  const getOptionName = (value: TGetOptionNameValue): Potential<T['name']> => {
    if (!value) {
      return
    }
    return options.find(opt => String(opt.value) === String(value))?.name
  }

  return {
    getOptionName,
    options
  }
}
