import { isEmptyValue } from 'utils'
import { UserResource } from '..'

/* This function is used to get the name of the user from the user resource
 * It will return the first non-empty value from the following list:
 * 1. userName
 * 2. email.name
 * 3. email.address
 * If none of the above are present, it will return 'Someone'
 */
export const getNameFromUserResource = (userResource?: UserResource) => {
  return (
    [
      userResource?.userName,
      userResource?.email?.name,
      userResource?.email?.address,
      userResource?.call?.phone,
      userResource?.sms?.phone
    ].filter(val => !isEmptyValue(val))[0] || 'Someone'
  )
}
