import { Icon } from 'components'
import { useDialerContext } from 'contexts/Dialer'
import { titleize } from 'utils'
import styles from '../Dialer.module.scss'
import { DialerCallButton } from '../DialerCallButton'

export const DialerMinified = () => {
  const { isOnCall, callDuration, activeDialer, toggleExpanded } = useDialerContext()

  return (
    <div className={styles.minifiedContainer}>
      <div className={styles.minifiedLeftSection}>
        <div className={styles.minifiedDisplay}>
          <div className={styles.dialerSource}>{activeDialer.name}</div>
          <div className={styles.dialerSource}>{activeDialer.phoneNumber}</div>
          <div className={styles.phoneType}>{`(${titleize(activeDialer.phoneType)})`}</div>
          {isOnCall ? <div className={styles.dialerDisplay}>{callDuration}</div> : null}
        </div>
        <div className={styles.minifedSelection}>
          <button type="button" onClick={toggleExpanded} className={styles.minifiedSelectionButton}>
            <Icon.Keypad />
          </button>
        </div>
      </div>
      <div className={styles.minifiedRightAction}>
        <DialerCallButton />
      </div>
    </div>
  )
}
