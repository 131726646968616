import { getTextCountsUrl } from 'services/getUrl'
import { getRequest } from 'utils'
import {
  getMessagesCountSearchParams,
  TGetMessagesCountSettings
} from './getMessagesCountSearchParams'

export const fetchMessagesCount = async (settings: TGetMessagesCountSettings) => {
  const response = await getRequest(
    getTextCountsUrl({ searchParams: getMessagesCountSearchParams(settings) })
  )
  return response.data.data
}
