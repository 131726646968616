type SearchParams = Record<string, any>

export const activityFeedKeys = {
  // all caches using this key
  all: ['activityFeed'] as const,
  // all activity-feed lists
  allLists: () => [...activityFeedKeys.all, 'list'] as const,
  globalList: (searchParams: SearchParams) =>
    [...activityFeedKeys.allLists(), 'global', searchParams] as const,
  thisLeadList: (searchParams: SearchParams) =>
    [...activityFeedKeys.allLists(), 'thisLead', searchParams] as const,
  thisAgentList: (searchParams: SearchParams) =>
    [...activityFeedKeys.allLists(), 'thisAgent', searchParams] as const
}
