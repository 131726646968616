import { SearchParams } from '@types'
import { Employee } from 'models'
import { EmployeesSchema, getHapiRequestParams } from 'schema'
import { getUsers2Url } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getEmployeeSearchParams } from './getEmployeeSearchParams'

type SearchParamsSettings = Pick<SearchParams, 'page' | 'perPage' | 'searchTerm'>

export const fetchEmployees = async (searchParams: SearchParamsSettings) => {
  const employeeShape: EmployeesSchema = {
    user: [
      'email',
      'name',
      'region',
      'pictureUrl',
      {
        roles: ['name', 'isEmployee']
      },
      {
        teamMemberships: ['role', { team: ['name'] }]
      }
    ]
  }

  const url = getUsers2Url({
    searchParams: {
      ...getHapiRequestParams(employeeShape),
      ...getEmployeeSearchParams(searchParams)
    }
  })
  const response = await getRequest(url)
  const employees = await deserializeAsync(response.data, {
    transform: Employee.create
  })

  return {
    employees,
    employeesCount: response.data.meta.total
  }
}
