import { getPhotosUrl } from 'services/getUrl'
import { postRequest } from 'utils'

type Props = {
  ids: string[]
}

export const deletePhotoUploads = ({ ids }: Props) => {
  return postRequest(getPhotosUrl({ action: 'batch-delete' }), {
    file_ids: ids
  })
}
