// @ts-strict
import { Dispatch, SetStateAction } from 'react'
import { Sections, Select } from 'components'
import styleGeneric from 'content/Lead/LeadDetails/LeadDetails.module.scss'
import { LenderProviderLead } from 'models'
import { presence } from 'utils'
import style from './LenderProviderLead.module.scss'
import { LoanApplicationOptions } from './LoanApplicationOptions'
import { LenderProviderLeadLoanProducts, TLoanProductsProps } from '.'

type TProps = Omit<TLoanProductsProps, 'lenderProviderLead'> & {
  currentLoanApplication: LenderProviderLead
  lenderProviderLeads: LenderProviderLead[]
  selectedLoanApplication: string
  setSelectedLoanApplication: Dispatch<SetStateAction<string>>
}

export const LenderProviderLeadLoanApplicationsSection = ({
  currentLoanApplication,
  lenderProviderLeads,
  selectedLoanApplication,
  setSelectedLoanApplication,
  price
}: TProps) => {
  const hasEaveId = (lead: LenderProviderLead) => !!lead?.lenderLead?.eaveLoanApplicationId
  const lenderLeadsWithEaveId = lenderProviderLeads.filter(lpl => hasEaveId(lpl))
  const showDropdown = lenderLeadsWithEaveId.length > 1

  const actions = showDropdown && (
    <div className={styleGeneric.actions}>
      <Select
        className={styleGeneric.select}
        onChange={setSelectedLoanApplication}
        value={selectedLoanApplication}
      >
        <LoanApplicationOptions lenderProviderLeads={lenderLeadsWithEaveId} />
      </Select>
    </div>
  )

  return presence(lenderLeadsWithEaveId) ? (
    <Sections title="Loan Applications" actions={actions} className={style.sections}>
      {hasEaveId(currentLoanApplication) && (
        <LenderProviderLeadLoanProducts lenderProviderLead={currentLoanApplication} price={price} />
      )}
    </Sections>
  ) : null
}
