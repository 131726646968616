export const dictionary = {
  CCCashOfferLead: 'Cash Offer Lead',
  CashOfferLead: 'Cash Offer IR',
  CCTradeInLead: 'Trade In Lead',
  CCHLSimpleSaleLead: 'HLSS Lead',
  BuyerLead: 'Buyer Lead',
  SellerLead: 'Seller Lead',
  EscrowLead: 'Escrow Lead',
  MortgageLead: 'Mortgage Lead',
  cc_cash_offer: 'Cash Offer',
  cc_trade_in: 'Trade In',
  cc_hl_simple_sale: 'HLSS',
  cc_cash_offer_lead: 'Cash Offer Lead',
  cc_trade_in_lead: 'Trade In Lead',
  cc_hl_simple_sale_lead: 'HLSS Lead',
  new_no_app: 'New - No Application',
  pre_approval_started: 'Pre-approval Started',
  pre_approved: 'Pre-approved',
  agent_left_vm: 'Agent Left VM',
  client_left_vm: 'Client Left VM',
  application_created: 'Qualification Form Sent'
}
