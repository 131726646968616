import { getAgentUrl } from 'services/getUrl'
import { postRequest } from 'utils'

type Args = {
  agentId: string
  notes: string
}

export const postAgentActivityNote = ({ agentId, notes }: Args): Promise<any> => {
  return postRequest(
    getAgentUrl({
      id: agentId,
      action: 'save-notes'
    }),
    { notes }
  )
}
