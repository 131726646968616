// @ts-strict
import { Section } from 'components'
import { Agent } from 'models'
import { AgentProgramEligibilityTable } from '../AgentProgramsFormButton/AgentProgramEligibilityTable'
import styles from './AgentProgramEligibility.module.scss'

type Props = {
  agent: Agent
}

export const AgentProgramEligibility = ({ agent }: Props) => {
  return (
    <Section title="Program Eligibility" className={styles.section}>
      <AgentProgramEligibilityTable agent={agent} />
    </Section>
  )
}
