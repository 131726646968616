// @ts-strict
import { Outlet } from 'react-router-dom'
import { TabNav, Tabs } from 'components'
import { useLeadContext, useSavedSearchesContext } from 'contexts'
import { useFeatureFlags } from 'hooks'

export const LeadInspectionsTab = () => {
  const { lead } = useLeadContext()
  const { getLastSearchUrl } = useSavedSearchesContext()
  const { salesAppDeficiencies } = useFeatureFlags(['sales-app-deficiencies'])

  return (
    <>
      <Tabs>
        <TabNav to={getLastSearchUrl(lead?.getPathname() + '/inspections')}>Reports</TabNav>
        {salesAppDeficiencies.enabled && (
          <TabNav to={getLastSearchUrl(lead?.getPathname() + '/inspections/deficiencies')}>
            Deficiencies
          </TabNav>
        )}
      </Tabs>
      <Outlet />
    </>
  )
}
