import { Link } from 'react-router-dom'
import {
  LoadingText,
  Table,
  TableBody,
  TableCell as TD,
  TableHeaderCell as TH,
  TableHeaderRow as THR,
  TableRow as TR
} from 'components'
import { Block } from 'components/Block'
import { useTeamsContext } from 'contexts'
import { SETTLEMENT_AGENCY_TEAM_ROLE_CACHE_KEY, useLookupsController } from 'hooks'
import { format } from 'utils'

export const SettlementAgencyTeamsList = () => {
  const { teamsQuery } = useTeamsContext()
  const [, findTeamRole] = useLookupsController(SETTLEMENT_AGENCY_TEAM_ROLE_CACHE_KEY)

  if (teamsQuery.isLoading) {
    return <LoadingText />
  }

  return (
    <Table>
      <THR>
        <TH>Name</TH>
        <TH>Members</TH>
        <TH>Enabled</TH>
      </THR>
      <TableBody>
        {teamsQuery.data?.items.map(team => {
          return (
            <TR key={team.id}>
              <TD>
                <Block
                  title={<Link to={`/teams/${team.id}`}>{team.name}</Link>}
                  subtitle={team.description}
                />
              </TD>
              <TD>
                {team.teamMemberships
                  ?.sort((a, b) => a.user.name.localeCompare(b.user.name))
                  .map(member => {
                    const role = findTeamRole(member.role)
                    return (
                      <div key={member.id}>{`${member.user.name} (${
                        role?.name ?? 'deprecated role'
                      })`}</div>
                    )
                  })}
              </TD>
              <TD>{format.boolean(team.enabled)}</TD>
            </TR>
          )
        })}
      </TableBody>
    </Table>
  )
}
