import { Option } from 'classes'
import { TSearchFilterSetting } from './types'

type TSearchFilterConstructor<TSetting extends TSearchFilterSetting = TSearchFilterSetting> = Omit<
  SearchFilter<TSetting>,
  'getDisplayValue'
> &
  Partial<Pick<SearchFilter<TSetting>, 'getDisplayValue'>>

export class SearchFilter<TSetting extends TSearchFilterSetting = TSearchFilterSetting> {
  getDisplayValue: (value: string) => string | undefined = v => v
  getDisplayValueFnName?: TSetting['getDisplayValueFnName']
  name?: string
  options?: Option[]
  optionsKey?: string
  placeholder?: string
  serverFilter?: string
  slug!: TSetting['slug']

  constructor(searchFilterAttributes: TSearchFilterConstructor<TSetting>) {
    Object.assign(this, searchFilterAttributes)
  }
}
