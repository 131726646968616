import { blue, gray, green, red, yellow } from './primitives'

/**
 * brand colors
 * the brand colors are aliases using its primitive values.
 */
const brand = {
  danger1: red.$1,
  danger2: red.$2,
  danger3: red.$3,
  danger4: red.$4,
  danger5: red.$5,
  danger6: red.$6,
  danger7: red.$7,
  danger8: red.$8,
  danger9: red.$9,
  danger10: red.$10,

  neutral1: gray.$1,
  neutral2: gray.$2,
  neutral3: gray.$3,
  neutral4: gray.$4,
  neutral5: gray.$5,
  neutral6: gray.$6,
  neutral7: gray.$7,
  neutral8: gray.$8,
  neutral9: gray.$9,
  neutral10: gray.$10,

  primary1: blue.$1,
  primary2: blue.$2,
  primary3: blue.$3,
  primary4: blue.$4,
  primary5: blue.$5,
  primary6: blue.$6,
  primary7: blue.$7,
  primary8: blue.$8,
  primary9: blue.$9,
  primary10: blue.$10,

  success1: green.$1,
  success2: green.$2,
  success3: green.$3,
  success4: green.$4,
  success5: green.$5,
  success6: green.$6,
  success7: green.$7,
  success8: green.$8,
  success9: green.$9,
  success10: green.$10,

  warning1: yellow.$1,
  warning2: yellow.$2,
  warning3: yellow.$3,
  warning4: yellow.$4,
  warning5: yellow.$5,
  warning6: yellow.$6,
  warning7: yellow.$7,
  warning8: yellow.$8,
  warning9: yellow.$9,
  warning10: yellow.$10
}

export default brand
