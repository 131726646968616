// @ts-strict
import { SearchFilter } from 'lookups'
import { SearchStatusFilter } from './SearchStatusFilter'

export const SearchStatusSearchTerm = ({ value }: { value?: string }) => {
  const filter = new SearchFilter({
    name: 'Searching by',
    slug: 'searchTerm'
  })

  return value ? <SearchStatusFilter filter={filter} value={value} /> : null
}
