import { Option } from 'classes'
import { useLookupsController } from 'controllers/Lookups'

export const useInspectionType = () => {
  const [data] = useLookupsController('inspection_types')

  const inspectionTypeOptions = data.map(
    (lookup: TLookupType) => new Option({ name: lookup.name, value: lookup.slug })
  )

  return { inspectionTypeOptions }
}
