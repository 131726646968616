// @ts-strict

import { useMutation, useQuery, useQueryClient } from 'hooks'
import { fetchAgentActivities, postAgentActivityNote } from 'services'
import { randomString } from 'utils'

export const useAgentActivitiesController = (agentId: string) => {
  const queryCache = useQueryClient()

  const activitiesQuery = useQuery(['activities', agentId], () => fetchAgentActivities(agentId))

  const addAgentActivityNote = useMutation(
    ({ notes }: { notes: string }) => postAgentActivityNote({ agentId, notes }),
    {
      onSuccess: (_, notes) => {
        // Optimistic Update due to Activities being ES Indexed (currently taking 3 secs+ to index)
        const data = queryCache.getQueryData(['activities', agentId]) as any
        queryCache.setQueryData(['activities', agentId], {
          activities: [
            {
              type: 'agent_note',
              id: randomString(),
              occurredAt: new Date().toISOString(),
              title: 'New sticky note',
              body: notes.notes
            },
            ...data.activities
          ]
        })
      }
    }
  )

  return { addAgentActivityNote, activitiesQuery }
}
