// @ts-strict
import {
  EmailInput,
  FormField,
  FormFieldSet,
  PhoneInput,
  Section,
  Sections,
  TextInput
} from 'components'
import { TFormItemsProps } from '../types'

export const ClientDetailsFormItems = ({ payload, onChangeAttribute }: TFormItemsProps) => {
  return (
    <Sections>
      <Section>
        <FormFieldSet title="Client Details">
          <Sections>
            <Section>
              <FormField title="Client Name" required htmlFor="client_name">
                <TextInput
                  id="client_name"
                  required
                  onChange={value => {
                    onChangeAttribute('clientName', value)
                  }}
                  value={payload?.clientName}
                />
              </FormField>
            </Section>
            <Section>
              <FormField title="Spouse / Partner Name" htmlFor="partner_name">
                <TextInput
                  id="partner_name"
                  onChange={value => {
                    onChangeAttribute('partnerName', value)
                  }}
                  value={payload?.partnerName}
                />
              </FormField>
            </Section>
          </Sections>

          <Sections>
            <Section>
              <FormField title="Client Phone Number" htmlFor="phone_number">
                <PhoneInput
                  id="phone_number"
                  onChange={value => {
                    onChangeAttribute('phoneNumber', value)
                  }}
                  value={payload?.phoneNumber}
                />
              </FormField>
            </Section>
            <Section>
              <FormField title="Spouse / Partner Phone Number" htmlFor="phone_alt">
                <PhoneInput
                  id="phone_alt"
                  onChange={value => {
                    onChangeAttribute('phoneAlt', value)
                  }}
                  value={payload?.phoneAlt}
                />
              </FormField>
            </Section>
          </Sections>
          <Sections>
            <Section>
              <FormField title="Client Email" htmlFor="clientEmail" required>
                <EmailInput
                  id="clientEmail"
                  onChange={value => {
                    onChangeAttribute('email', value)
                  }}
                  value={payload?.email}
                />
              </FormField>
            </Section>
            <Section>
              <FormField title="Spouse / Partner Email" htmlFor="email_alt">
                <EmailInput
                  onChange={value => {
                    onChangeAttribute('emailAlt', value)
                  }}
                  value={payload?.emailAlt}
                />
              </FormField>
            </Section>
          </Sections>
        </FormFieldSet>
      </Section>
    </Sections>
  )
}
