import { ALL_LEADS_CACHE_KEY, useQuery } from 'hooks'
import { AllLeadsSearchParams, fetchAllLeads } from 'services'

const defaultSearchSettings: AllLeadsSearchParams = {
  sortBy: 'Created',
  sortDir: 'desc',
  perPage: 25
}

export const useAllLeadsController = (searchParams: AllLeadsSearchParams) => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  const allLeadsQuery = useQuery([ALL_LEADS_CACHE_KEY, searchSettings], () =>
    fetchAllLeads(searchSettings)
  )

  return { allLeadsQuery }
}
