import { BuyerLead, SellerLead } from 'models'
import { DISPOSITION_KEYS, DispositionInfo } from './types'
import { DISPOSITIONS_BY_STAGE, EXCLUDE_STAGES_FOR_BUYERS } from './utils'

type Lead = BuyerLead | SellerLead

export const getCurrentDisposition = (lead: Lead): DispositionInfo => {
  return DISPOSITIONS_BY_STAGE.find(disposition => {
    if (lead.stage === 'not_transacting') {
      return disposition.key === DISPOSITION_KEYS.other
    }

    if (lead.stage === 'called_no_msg' && lead.isDeal) {
      return disposition.key === DISPOSITION_KEYS.followUp
    }
    return disposition.options.some(option => option.value === lead.stage)
  })
}

export const getSelectableDispositions = (
  currentDisposition: DispositionInfo
): DispositionInfo[] => {
  return DISPOSITIONS_BY_STAGE.filter(disposition =>
    currentDisposition.nextStages.some(stage => disposition.key === stage)
  )
}

export const getInitialDispositionStage = (
  listOfDispositionsToSelect: DispositionInfo[],
  isBuyer: boolean
): string => {
  if (listOfDispositionsToSelect.length === 0) {
    return ''
  }

  const dispositionList = listOfDispositionsToSelect.map(disposition => {
    const options = isBuyer
      ? disposition.options.filter(option => !EXCLUDE_STAGES_FOR_BUYERS.includes(option?.value!))
      : disposition.options

    return options
  })

  const [initialDispositionOption] = dispositionList.flat()
  return initialDispositionOption?.value || ''
}
