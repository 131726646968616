import { toOptions } from 'lookups/utils'
import { asArray, bySlug } from 'utils'
import { LeadStage } from './LeadStage'
import { TLeadStageSetting } from './leadStageSettings'

export const buildLeadStages = <TSetting extends TLeadStageSetting, TModel extends LeadStage>(
  settings: TSetting[],
  model: new (attr: TSetting) => TModel
): TModel[] => {
  const getLeadStagesBySlug = (
    slugs: TSetting['slug'] | readonly TSetting['slug'][] | undefined
  ): Array<TModel> => {
    return asArray(slugs)
      .map(slug => settings.find(bySlug(slug)))
      .filter(Boolean)
      .map(setting => new model(setting!))
  }

  return settings.map((item, idx) => {
    const nextStageOptions = toOptions(getLeadStagesBySlug(item.nextStageSlugs))
    const previousStageOptions = toOptions(
      getLeadStagesBySlug(
        settings
          .slice(0, idx)
          .filter(stage => stage.validPreviousStage)
          .map(subitem => subitem.slug)
      )
    )
    const otherStageOptions = toOptions(
      getLeadStagesBySlug(
        settings
          .filter(subitem => subitem.other && subitem.slug !== item.slug)
          .map(item => item.slug)
      )
    )
    return new model({
      ...item,
      nextStageOptions,
      previousStageOptions,
      otherStageOptions
    })
  })
}
