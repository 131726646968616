// @ts-strict

import { useLocation } from 'react-use'
import { useMutation, useQuery, useQueryClient } from 'hooks'
import { fetchLeadActivities, postLeadNote } from 'services'
import { d, randomString } from 'utils'

type Props = {
  leadId: string
  rootPath: string
}

export const useLeadActivitiesController = ({ leadId, rootPath }: Props) => {
  const { pathname } = useLocation()
  const queryCache = useQueryClient()

  const activitiesQuery = useQuery(['activities', leadId], () => fetchLeadActivities(leadId), {
    enabled: pathname === rootPath
  })

  const addLeadActivityNote = useMutation(
    (payload: Record<string, string>) => postLeadNote(leadId, payload),
    {
      onSuccess: (_, payload) => {
        // Optimistic Update due to Activities being ES Indexed (currently taking 3 secs+ to index)
        const data = queryCache.getQueryData(['activities', leadId]) as any
        queryCache.setQueryData(['activities', leadId], {
          activities: [
            {
              type: 'lead_note',
              id: randomString(),
              occurredAt: new Date().toISOString(),
              title: d(payload.salesEventNoteAction),
              body: payload.note
            },
            ...data.activities
          ]
        })
      }
    }
  )

  return { addLeadActivityNote, activitiesQuery }
}
