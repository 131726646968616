// @ts-strict
import type { UseMutationResult } from 'react-query'
import { useToastContext } from '@foundation/components'
import { useButtonForm } from 'components'
import type { Agent } from 'models'

type PutAgentPayload = any

type RequestedLocationsSettingButtonFormPayload = PutAgentPayload

export const useRequestedLocationsButtonForm = (
  mutation: UseMutationResult<any, unknown, PutAgentPayload, unknown>,
  agent: Agent
) => {
  const transformPayload = (
    payload: RequestedLocationsSettingButtonFormPayload
  ): RequestedLocationsSettingButtonFormPayload => {
    return {
      requestedZipCodes: payload.requestedZipCodes
    }
  }

  const toast = useToastContext()
  const onSubmitSuccess = () => {
    toast({
      title: 'Requested Locations Updated',
      status: 'success'
    })
  }

  const { fieldsProps, formProps, setters } =
    useButtonForm<RequestedLocationsSettingButtonFormPayload>({
      initialPayload: {
        requestedZipCodes: agent.referralPreferences?.requestedZipCodes?.map(c => c.code) ?? []
      },
      callback: onSubmitSuccess,
      mutation,
      transformPayload
    })

  return { fieldsProps, formProps, setters }
}
