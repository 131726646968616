// @ts-strict
import { Option } from 'classes'
import { TCashCloseProduct } from 'content/Order'
import { FhaVaType } from 'models'
import { CERTIFIED, DECLINED, ENROLLED, NOT_CERTIFIED, PENDING, UNENROLLED } from 'utils/constants'
import { titleize } from 'utils/titleize'

export const blankOptions = [{ name: '', value: '' }].map(option => new Option(option))

export const stateNameItems = [
  { name: 'Alabama', value: 'al' },
  { name: 'Alaska', value: 'ak' },
  { name: 'Arizona', value: 'az' },
  { name: 'Arkansas', value: 'ar' },
  { name: 'California', value: 'ca' },
  { name: 'Colorado', value: 'co' },
  { name: 'Connecticut', value: 'ct' },
  { name: 'Delaware', value: 'de' },
  { name: 'Florida', value: 'fl' },
  { name: 'Georgia', value: 'ga' },
  { name: 'Hawaii', value: 'hi' },
  { name: 'Idaho', value: 'id' },
  { name: 'Illinois', value: 'il' },
  { name: 'Indiana', value: 'in' },
  { name: 'Iowa', value: 'ia' },
  { name: 'Kansas', value: 'ks' },
  { name: 'Kentucky', value: 'ky' },
  { name: 'Louisiana', value: 'la' },
  { name: 'Maine', value: 'me' },
  { name: 'Maryland', value: 'md' },
  { name: 'Massachusetts', value: 'ma' },
  { name: 'Michigan', value: 'mi' },
  { name: 'Minnesota', value: 'mn' },
  { name: 'Mississippi', value: 'ms' },
  { name: 'Missouri', value: 'mo' },
  { name: 'Montana', value: 'mt' },
  { name: 'Nebraska', value: 'ne' },
  { name: 'Nevada', value: 'nv' },
  { name: 'New Hampshire', value: 'nh' },
  { name: 'New Jersey', value: 'nj' },
  { name: 'New Mexico', value: 'nm' },
  { name: 'New York', value: 'ny' },
  { name: 'North Carolina', value: 'nc' },
  { name: 'North Dakota', value: 'nd' },
  { name: 'Ohio', value: 'oh' },
  { name: 'Oklahoma', value: 'ok' },
  { name: 'Oregon', value: 'or' },
  { name: 'Pennsylvania', value: 'pa' },
  { name: 'Rhode Island', value: 'ri' },
  { name: 'South Carolina', value: 'sc' },
  { name: 'South Dakota', value: 'sd' },
  { name: 'Tennessee', value: 'tn' },
  { name: 'Texas', value: 'tx' },
  { name: 'Utah', value: 'ut' },
  { name: 'Vermont', value: 'vt' },
  { name: 'Virginia', value: 'va' },
  { name: 'Washington', value: 'wa' },
  { name: 'West Virginia', value: 'wv' },
  { name: 'Wisconsin', value: 'wi' },
  { name: 'Wyoming', value: 'wy' },
  { name: 'District of Columbia', value: 'dc' }
]

export const stateNameOptions = stateNameItems.map(option => new Option(option))

export const userTypeOptions = [
  { name: 'Buyer', value: 'buyer' },
  { name: 'Seller', value: 'seller' }
].map(option => new Option(option))

export const stateOptions = (urgentCount?: number) => {
  return [{ name: `Urgent (${urgentCount ?? 0})`, value: 'urgent' }].map(
    option => new Option(option)
  )
}

export const productEligibilityOptions = [
  { name: 'Buy Before You Sell', value: 'eligible_for_hlti' }
].map(option => new Option(option))

// keep this when the new HLSS is ready for production
export const cashCloseOrderProductsOptions = [
  { name: 'Trade In', value: 'trade_in' },
  { name: 'HLSS', value: 'hl_simple_sale' },
  { name: 'Cash Offer', value: 'cash_offer' },
  { name: 'Mortgage', value: 'mortgage' }
].map(option => new Option<TCashCloseProduct>(option))

// keep this when the BBYS is ready for production
export const bbysOrderProductsOptions = [
  { name: 'Buy Before You Sell', value: 'bbys' },
  { name: 'HLSS', value: 'hl_simple_sale' },
  { name: 'Cash Offer', value: 'cash_offer' },
  { name: 'Mortgage', value: 'mortgage' }
].map(option => new Option<TCashCloseProduct>(option))

export const cashCloseUserTypeOptions = [
  { name: 'Trade In', value: 'cc_trade_in' },
  { name: 'Cash Offer', value: 'cc_cash_offer' },
  { name: 'HLSS', value: 'cc_hl_simple_sale' },
  { name: 'Mortgage', value: 'mortgage' }
].map(option => new Option(option))

export const taskUserTypeOptions = [
  { name: 'Buyer', value: 'buyer' },
  { name: 'Seller', value: 'seller' },
  { name: 'Cash Offer', value: 'cc_cash_offer' },
  { name: 'Trade In', value: 'cc_trade_in' },
  { name: 'Buy Before You Sell', value: 'bbys' },
  { name: 'Mortgage', value: 'mortgage' },
  { name: 'Escrow', value: 'escrow' },
  { name: 'HLSS', value: 'cc_hl_simple_sale' }
].map(option => new Option(option))

export const sourcesOptions = [
  { name: 'Accept', value: 'accept' },
  { name: 'Agent', value: 'agent' },
  { name: 'Disclosures', value: 'disclosures' },
  { name: 'Home Consultant', value: 'home_consultant' },
  { name: 'Loan Officer', value: 'loan_officer' },
  { name: 'Simple Sale', value: 'simple_sale' },
  { name: 'Website', value: 'website' },
  { name: 'Other', value: 'other' }
].map(option => new Option(option))

export const cashCloseOrderStagesOptions = [
  { name: 'New', value: 'new' },
  { name: 'Active', value: 'active' },
  { name: 'Complete', value: 'complete' },
  { name: 'Nurture', value: 'nurture' },
  { name: 'Failed', value: 'failed' }
].map(option => new Option(option))

export const propertyDnqReasonsOptions = [
  { name: 'Property: Home Value too high', value: 'property_home_value_too_high' },
  { name: 'Home Value too low', value: 'property_home_value_too_low' },
  { name: 'Property: Property Type', value: 'property_property_type' },
  { name: 'Property: Listed', value: 'property_listed' },
  { name: 'Property: Market', value: 'property_market' },
  { name: 'Property: Condition', value: 'property_condition' },
  { name: 'Equity', value: 'equity' }
].map(option => new Option(option))

export const propertyQualifiedOptions = [
  { name: 'False', value: 'false' },
  { name: 'True', value: 'true' },
  { name: 'Flagged', value: 'flagged' }
].map(option => new Option(option))

export const solarPanelsOptions = [
  { name: 'No Solar', value: 'no_solar' },
  { name: 'Leased', value: 'leased' },
  { name: 'Owned - Loan', value: 'owned_loan' },
  { name: 'Fully Owned', value: 'fully_owned' }
].map(option => new Option(option))

export const mortgageCreditCheckPassedOption = {
  name: 'Credit Check Pulled',
  value: 'credit_check_passed'
}

export const mortgageStagesOptions = [
  { name: 'New - No Application', value: 'new_no_app' },
  { name: 'Pre-approval Started', value: 'pre_approval_started' },
  { name: 'Account Created', value: 'account_created' },
  { name: 'Pre-approved', value: 'pre_approved' },
  { name: 'Application Created', value: 'application_created' },
  mortgageCreditCheckPassedOption,
  { name: 'Signed Borrower Authorization', value: 'signed_borrower_auth' },
  { name: 'In UW Review', value: 'in_review' },
  { name: 'Conditionally Approved', value: 'conditionally_approved' },
  { name: 'In Contract', value: 'in_contract' },
  { name: 'Clear to Close', value: 'clear_to_close' },
  { name: 'Funded', value: 'funded' },
  { name: 'Did Not Qualify', value: 'did_not_qualify' },
  { name: 'Lost', value: 'lost' }
].map(option => new Option(option))

const trueFalseLookup = [
  { name: 'False', value: 'false' },
  { name: 'True', value: 'true' }
] as const

export type TrueFalseType = (typeof trueFalseLookup)[number]['value']

export const trueFalseOptions: Option<TrueFalseType>[] = trueFalseLookup.map(
  option => new Option(option)
)

const completionStatusLookup = [
  { name: 'Complete', value: 'complete' },
  { name: 'Incomplete', value: 'incomplete' }
] as const

export type CompletionStatusType = (typeof completionStatusLookup)[number]['value']

export const completetionStatusOptions: Option<CompletionStatusType>[] = completionStatusLookup.map(
  option => new Option(option)
)

export const protectedOfferStatusOptions = [
  { name: 'Protected without Cash Offer', value: 'protected_without_cash_offer' },
  { name: 'Protected with Cash Offer', value: 'protected_with_cash_offer' }
].map(option => new Option(option))

export const activityWindowOptions = [
  { value: undefined, name: 'All' },
  { value: '0', name: 'Today' },
  { value: '1', name: '1 day' },
  { value: '2', name: '2 days' },
  { value: '3', name: '3 days' },
  { value: '5', name: '5 days' },
  { value: '7', name: '7 days' },
  { value: '14', name: '14 days' },
  { value: '30', name: '30 days' },
  { value: '60', name: '60 days' },
  { value: '90', name: '90 days' },
  { value: '180', name: '6 months' },
  { value: '365', name: '12 months' }
].map(option => new Option(option))

export const enrollmentOptions = [
  { value: '', name: 'All' },
  { value: PENDING, name: titleize(PENDING) },
  { value: ENROLLED, name: titleize(ENROLLED) },
  { value: UNENROLLED, name: titleize(UNENROLLED) },
  { value: DECLINED, name: titleize(DECLINED) }
].map(option => new Option(option))

export const leadStagesOptions = [
  { value: 'intro_pending', name: 'Intro Pending' },
  { value: 'introduced', name: 'Introduced' },
  { value: 'agent_left_vm', name: 'Agent Left VM' },
  { value: 'client_left_vm', name: 'Client Left VM' },
  { value: 'connected', name: 'Connected' },
  { value: 'meeting_scheduled', name: 'Meeting Scheduled' },
  { value: 'met_in_person', name: 'Met In Person' },
  { value: 'coming_soon', name: 'Coming Soon' },
  { value: 'listing', name: 'Listing' },
  { value: 'making_offer', name: 'Making Offer' },
  { value: 'in_escrow', name: 'In Escrow' },
  { value: 'closed', name: 'Closed' },
  { value: 'closed_booked', name: 'Closed Booked' },
  { value: 'closed_paid', name: 'Closed Paid' },
  { value: 'failed', name: 'Failed' }
].map(option => new Option(option))

export const certificationOptions = [
  { value: '', name: 'All' },
  { value: CERTIFIED, name: titleize(CERTIFIED) },
  { value: NOT_CERTIFIED, name: titleize(NOT_CERTIFIED) }
].map(option => new Option(option))

export const eliteStatusOptions = [
  { value: 'active', name: 'Elite' },
  { value: 'ready', name: 'Elite Ready' },
  { value: 'eligible', name: 'Prospect' }
].map(option => new Option(option))

export const pitchStatusProgramsOptions = [
  { value: 'referrals', name: 'Referrals' },
  { value: 'title_escrow', name: 'Closing Services' }
].map(option => new Option(option))

export const agentLeadDispositionOptions = [
  { value: 'awaiting_response', name: 'Awaiting Response' },
  { value: 'introduced', name: 'Introduced' },
  { value: 'agent_left_vm', name: 'Agent Left VM/Email' },
  { value: 'client_left_vm', name: 'Client Left VM/Email' },
  { value: 'connected', name: 'Connected' },
  { value: 'meeting_scheduled', name: 'Meeting Scheduled' },
  { value: 'met_in_person', name: 'Met In Person' },
  { value: 'coming_soon', name: 'Coming Soon' },
  { value: 'making_offer', name: 'Making Offers' },
  { value: 'listing', name: 'Listing' },
  { value: 'making_offer', name: 'Making Offer' },
  { value: 'in_escrow', name: 'In Escrow' },
  { value: 'closed', name: 'Closed' },
  { value: 'closed_booked', name: 'Closed - Booked' },
  { value: 'closed_paid', name: 'Closed - Paid' },
  { value: 'failed', name: 'Failed' }
].map(option => new Option(option))

export const agentLeadDispositionNotIntroducedOptions = [
  { value: 'awaiting_response', name: 'Awaiting Response' },
  { value: 'introduce', name: 'Introduce' },
  { value: 'failed', name: 'Failed' }
].map(option => new Option(option))

export const investorLeadDispositionOptions = [
  { value: 'introduced', name: 'Introduced' },
  { value: 'agent_left_vm', name: 'Agent Left VM/Email' },
  { value: 'client_left_vm', name: 'Client Left VM/Email' },
  { value: 'connected', name: 'Connected' },
  { value: 'meeting_scheduled', name: 'Meeting Scheduled' },
  { value: 'met_in_person', name: 'Met In Person' },
  { value: 'coming_soon', name: 'Coming Soon' },
  { value: 'listing', name: 'Listing' },
  { value: 'in_escrow', name: 'In Escrow' },
  { value: 'closed', name: 'Closed' },
  { value: 'closed_booked', name: 'Closed - Booked' },
  { value: 'closed_paid', name: 'Closed - Paid' },
  { value: 'failed', name: 'Failed' }
].map(option => new Option(option))

export const investorLeadDispositionNotIntroducedOptions = [
  { value: 'introduced', name: 'Introduced' },
  { value: 'failed', name: 'Failed' }
].map(option => new Option(option))

export const lenderLeadDispositionOptions = [{ value: 'introduced', name: 'Introduced' }].map(
  option => new Option(option)
)

export const bbysDatesAndMilestonesOptions = [
  { value: 'departing_residence_listed_date', name: 'DR Listed Date' },
  { value: 'departing_residence_in_escrow_date', name: 'DR in Escrow Date' },
  { value: 'departing_residence_expected_close_escrow_date', name: 'DR Expected Close of Escrow' },
  { value: 'hl_purchased_departing_residence_date', name: 'HL Purchased Departing Residence Date' },
  { value: 'hl_purchase_listed_date', name: 'HL Purchase Listed Date' },
  { value: 'expected_loan_funding_date', name: 'Expected Equity Unlock loan funded' }
].map(option => new Option(option))

export const bbysStageOptions = [
  { value: 'new', name: 'New' },
  { value: 'in_review', name: 'In Review' },
  { value: 'approved', name: 'Approved' },
  { value: 'agreement_signed', name: 'Agreement Signed' },
  { value: 'ir_contract', name: 'IR Contract' },
  { value: 'clear_to_fund', name: 'Clear to Fund' },
  { value: 'ir_closed', name: 'IR Closed' },
  { value: 'dr_closed', name: 'DR Closed' },
  { value: 'failed', name: 'Failed' },
  { value: 'nurture', name: 'Nurture' }
].map(option => new Option(option))

export const bbysStageFailureOptions = [
  { name: 'Fee too high', value: 'fee_too_high' },
  { name: 'Equity unlock too low', value: 'equity_unlock_too_low' },
  { name: 'Used competitor/bridge loan', value: 'used_competitor_bridge_loan' },
  { name: 'Decided not to sell/move', value: 'decided_not_to_sell_move' },
  { name: 'Decided program not needed for move', value: 'decided_program_not_needed_for_move' },
  { name: 'Unresponsive ', value: 'unresponsive' },
  {
    name: 'Offer on DR (able to close prior to IR COE)',
    value: 'offer_on_dr'
  },
  {
    name: 'Unable to quaify for IR financing',
    value: 'not_qualified_for_ir_financing'
  },
  { name: 'Approval denial: Lack of comps', value: 'approval_denial_lack_of_comps' },
  {
    name: 'Approval denial: Negative equity unlock',
    value: 'approval_denial_negative_equity_unlock'
  },
  { name: 'Approval denial: Zoning', value: 'approval_denial_zoning' },
  { name: 'Approval denial: Unpermitted addition', value: 'approval_denial_unpermitted_addition' },
  { name: 'Approval denial: High Price Point', value: 'approval_denial_high_price_point' },
  { name: 'Approval denial: Active DOM too high', value: 'approval_denial_active_dom_too_high' },
  {
    name: 'Approval denial: Projected DOM too high',
    value: 'approval_denial_projected_dom_too_high'
  },
  { name: 'Approval denial: Property type', value: 'approval_denial_property_type' },
  { name: 'Approval denial: Acreage', value: 'approval_denial_acreage' },
  { name: 'Approval denial: Condition', value: 'approval_denial_condition' },
  { name: 'Approval denial: Other   ', value: 'approval_denial_other' },
  { name: 'Test lead', value: 'test_lead' },
  { name: 'Duplicate lead', value: 'duplicate' },
  { name: 'Other', value: 'other' }
].map(option => new Option(option))

export const bbysPartnerOptions = [
  { value: 'tls', name: 'The Loan Store' },
  { value: 'lower', name: 'Lower.com' },
  { value: 'lennar', name: 'Lennar' },
  { value: 'other', name: 'Etc.' }
].map(option => new Option(option))

export const lenderLeadTypeOptions = [
  { value: 'bbys', name: 'BBYS' },
  { value: 'regular_mortgage', name: 'Regular Mortgage' },
  { value: 'ultra', name: 'Ultra' }
].map(option => new Option(option))

export const lenderLeadDispositionNotIntroducedOptions = [
  { value: 'introduced', name: 'Introduced' },
  { value: 'application_created', name: 'Qualification Form Sent' }
].map(option => new Option(option))

export const leadPendingActionsResolveActionTaken = [
  { value: 'additional_agents_introduced', name: 'Additional Agents Introduced' },
  { value: 'non_transacted_lead', name: 'Non-transacted Lead' },
  { value: 'agent_nudged', name: 'Agent Nudged' },
  { value: 'awaiting_response_from_agent', name: 'Awaiting Response from Agent' },
  { value: 'needs_additional_followup', name: 'Needs Additional Followup' },
  { value: 'no_action_required', name: 'No Action Required' },
  { value: 'failed_out_agents', name: 'Failed Out Agents' }
].map(option => new Option(option))

export const leadPendingActionsOptions = [
  { value: 'additional_followup', name: 'Additional Followup' },
  { value: 'hc_followup', name: 'HC Followup' },
  { value: 'needs_additional_agents', name: 'Needs Additional Agents' },
  { value: 'review_auto_intro_rejected_reasons', name: 'Review Auto Intro Rejected Reasons' }
].map(option => new Option(option))

export const agentMatchingCallReviewCreditScoreOptions = [
  { value: 'over_740', name: '740 +' },
  { value: '680_739', name: '680 - 739' },
  { value: '620_679', name: '620 - 679' },
  { value: '580_620', name: '580 - 619' },
  { value: 'under_580', name: '579 or below' },
  { value: 'dont_know', name: "Don't know" }
].map(option => new Option(option))

export const agentMatchingCallReviewAgentCommunicationPreference = [
  { value: 'Call', name: 'Call' },
  { value: 'Message', name: 'Message' },
  { value: 'default', name: 'Default' }
].map(option => new Option(option))

export const pplBillingFreq = [
  { value: 'month', name: 'Monthly' },
  { value: 'week', name: 'Weekly' }
].map(option => new Option(option))

export const agentMatchingLeadTimelineOptions = [
  { value: 'asap', name: 'ASAP' },
  { value: '2_months', name: '0-3 months' },
  { value: '6_months', name: '3-6 months' },
  { value: '10_months', name: '6-12 months' },
  { value: '1_year', name: '12+ months' },
  { value: 'unsure', name: 'Unsure' }
].map(option => new Option(option))

export const yesOrNoOptions = [
  { value: 'true', name: 'Yes' },
  { value: 'false', name: 'No' }
].map(option => new Option(option))

export const yesOrNoForJsonOptions = [
  { value: 'Yes', name: 'Yes' },
  { value: 'No', name: 'No' }
].map(option => new Option(option))

export const estimatedMortgageBalanceSourceOptions = [
  { value: 'external_lead_submitter', name: 'NHC' },
  { value: 'loan_officer', name: 'Loan Officer' },
  { value: 'agent', name: 'Agent' },
  { value: 'house_canary', name: 'House Canary' }
].map(option => new Option(option))

export const agentMatchingCallReviewWhy = [
  { value: 'Downsizing', name: 'Downsizing' },
  { value: 'Divorce/Separation', name: 'Divorce/Separation' },
  { value: 'Relocating Within Current Market', name: 'Relocating Within Current Market' },
  { value: 'Relocating To New Market', name: 'Relocating To New Market' },
  { value: 'Buying/Selling Investment Property', name: 'Buying/Selling Investment Property' },
  { value: 'Buying/Selling Vacation Property', name: 'Buying/Selling Vacation Property' },
  { value: "Selling a Family Member's Property", name: "Selling a Family Member's Property" },
  { value: 'Upsizing', name: 'Upsizing' },
  { value: 'First Time Home Buyer', name: 'First Time Home Buyer' },
  { value: 'Probate Property', name: 'Probate Property' },
  { value: 'Expired Listing', name: 'Expired Listing' },
  { value: 'Was FSBO', name: 'Was FSBO' },
  { value: 'Other', name: 'Other' },
  { value: 'No answer', name: 'No answer' }
].map(option => new Option(option))

export const agentMatchingCallReviewAssistedIntroEligible = [
  { value: 'true', name: 'True' },
  { value: 'false', name: 'False' },
  { value: 'eligible', name: 'Eligible' },
  { value: 'not_eligible', name: 'Not Eligible' }
]

export const agentMatchingCallReviewBuyingProcess = [
  { value: 'Researching options', name: 'Researching options' },
  { value: 'Viewing Properties', name: 'Touring Properties' },
  { value: 'Ready to Offer', name: 'Ready to Offer' },
  { value: 'In Contract', name: 'In Contract' }
].map(option => new Option(option))

export const agentMatchingCallReviewPreapproved = [
  { value: 'No', name: 'Researching options' },
  { value: 'Lender Contacted', name: 'Talked to lender' },
  { value: 'Yes', name: 'Preapproved' },
  { value: 'Cash Buyer', name: 'Cash buyer' }
].map(option => new Option(option))

export const agentMatchingCallReviewIntroType = [
  { value: 'Agent Intro', name: 'Agent Intro' },
  { value: 'Investor Intro', name: 'Investor Intro' },
  { value: 'Both', name: 'Both' }
].map(option => new Option(option))

export const agentMatchingCallReviewRelationship = [
  { value: 'Self', name: 'Self' },
  { value: 'Family Member', name: 'Family Member' },
  { value: 'Executor', name: 'Executor' },
  { value: 'Other', name: 'Other' }
].map(option => new Option(option))

export const agentMatchingCallReviewGenderPreference = [
  { value: 'No Preference', name: 'No Preference' },
  { value: 'Male', name: 'Male' },
  { value: 'Female', name: 'Female' }
].map(option => new Option(option))

export const agentMatchingCallReviewYearBuilt = [
  { value: 'Before 1960', name: 'Before 1960' },
  { value: '1960s', name: '1960s' },
  { value: '1970s', name: '1970s' },
  { value: '1980s', name: '1980s' },
  { value: '1990s', name: '1990s' },
  { value: '2000 or later', name: '2000 or later' }
].map(option => new Option(option))

export const agentMatchingCallReviewGarageSize = [
  { value: 'None', name: 'None' },
  { value: '1 Car', name: '1 Car' },
  { value: '2 Car', name: '2 Car' },
  { value: '3+ Cars', name: '3+ Cars' }
].map(option => new Option(option))

export const agentMatchingCallReviewCondition = [
  { value: 'Tear Down', name: 'Tear Down' },
  { value: 'Needs Significant Work', name: 'Needs Significant Work' },
  { value: 'Needs A Little Work', name: 'Needs A Little Work' },
  { value: 'Needs Nothing', name: 'Needs Nothing' }
].map(option => new Option(option))

export const agentMatchingLeadPropertyTypes = [
  { value: 'residential_lease', name: 'Residential Lease' },
  { value: 'single_family_home', name: 'Single Family Home' },
  { value: 'condominium', name: 'Condominium' },
  { value: 'townhomes', name: 'Townhouse' },
  { value: 'tenancy_in_common', name: 'Tenancy in Common' },
  { value: 'coop', name: 'Coop' },
  { value: 'mobile_home', name: 'Mobile Home' },
  { value: 'mobile', name: 'Mobile Home' },
  { value: 'commercial_lease', name: 'Commercial Lease' },
  { value: 'retail', name: 'Retail' },
  { value: 'industrial', name: 'Industrial' },
  { value: 'office', name: 'Office' },
  { value: 'hotel_motel', name: 'Hotel/Motel' },
  { value: 'residential', name: 'Residential' },
  { value: 'acreage', name: 'Acreage' },
  { value: 'agricultural', name: 'Agricultural' },
  { value: 'recreation', name: 'Recreation' },
  { value: 'two_unit', name: '2 Units' },
  { value: 'three_unit', name: '3 Units' },
  { value: 'four_unit', name: '4 Units' },
  { value: 'five_plus_unit', name: '5+ Units' },
  { value: 'land', name: 'Land' },
  { value: 'other', name: 'Other' },
  { value: 'multi_family', name: 'Multi-Family' },
  { value: 'not_sure', name: 'Not Sure' }
].map(option => new Option(option))

export const agentMatchingCallReviewLanguageRequirement = [
  { value: 'None', name: 'None' },
  { value: 'Spanish', name: 'Spanish' },
  { value: 'Other - Specify in Notes', name: 'Other - Specify in Notes' }
].map(option => new Option(option))

export const agentMatchingCallReviewPreviousAgent = [
  { value: 'Yes', name: 'Yes' },
  { value: 'No', name: 'No' },
  { value: 'No Answer', name: 'No Answer' }
].map(option => new Option(option))

export const agentMatchingCallReviewAgentIntroCount = [
  { value: '2', name: '2' },
  { value: '3', name: '3' },
  { value: 'Default Amount', name: 'Default Amount' }
].map(option => new Option(option))

export const agentMatchingCallReviewHowLongOwned = [
  { value: 'Less than 2 years', name: 'Less than 2 years' },
  { value: 'More than 2 years', name: 'More than 2 years' }
].map(option => new Option(option))

export const agentMatchingCallReviewOccupiedBy = [
  { value: 'Owner Occupied', name: 'Owner Occupied' },
  { value: 'Tenant Occupied', name: 'Tenant Occupied' },
  { value: 'Vacant', name: 'Vacant' }
].map(option => new Option(option))

export const agentMatchingCallReviewLotSize = [
  { value: '0.5 Acres or Less', name: '0.5 Acres or Less' },
  { value: '0.5 - 1.0 Acres', name: '0.5 - 1.0 Acres' },
  { value: '1 Acre or More', name: '1 Acre or More' }
].map(option => new Option(option))

export const agentMatchingCallReviewOwnershipStatus = [
  { value: 'Double escrow', name: 'Double escrow' },
  { value: 'Real estate owned', name: 'Real estate owned' },
  { value: 'Short sale', name: 'Short sale' },
  { value: 'Distressed property', name: 'Distressed property' },
  { value: 'Bank owned', name: 'Bank owned' },
  { value: 'None', name: 'None' }
].map(option => new Option(option))

export const agentMatchingCallReviewBedroomsCount = [
  { value: '0', name: '0' },
  { value: '1', name: '1' },
  { value: '2', name: '2' },
  { value: '3', name: '3' },
  { value: '4', name: '4' },
  { value: '5', name: '5' },
  { value: '6+', name: '6+' }
].map(option => new Option(option))

export const agentMatchingCallReviewBathroomsCount = [
  { value: '1', name: '1' },
  { value: '1.5', name: '1.5' },
  { value: '2', name: '2' },
  { value: '2.5', name: '2.5' },
  { value: '3', name: '3' },
  { value: '3.5', name: '3.5' },
  { value: '4', name: '4' },
  { value: '4.5', name: '4.5' },
  { value: '5', name: '5' },
  { value: '5.5', name: '5.5' },
  { value: '6+', name: '6+' }
].map(option => new Option(option))

export const agentMatchingCallReviewQualificationType = [
  { value: 'Assisted', name: 'Assisted' }
].map(option => new Option(option))

export const agentMatchingCallReviewQualificationMethod = [
  { value: 'Message', name: 'Message' },
  { value: 'Call', name: 'Call' }
].map(option => new Option(option))

export const agentStatuses = [
  { value: 'eligible', name: 'Prospect' },
  { value: 'ready', name: 'Elite Ready' },
  { value: 'active', name: 'Elite', disabled: true }
].map(option => new Option(option))

export const taskCreationTypes = [
  { value: undefined, name: 'All' },
  { value: 'automatic', name: 'Automatic' },
  { value: 'manual', name: 'Manual' }
].map(option => new Option(option))

export const userCategoryOptions = [
  { value: undefined, name: 'All' },
  { value: 'employees', name: 'Employees' }
].map(option => new Option(option))

export const userStatusOptions = [
  { value: undefined, name: 'All' },
  { value: 'active', name: 'Active' },
  { value: 'archived', name: 'Archived' }
].map(option => new Option(option))

export const taskAttachableStatus = [
  { value: undefined, name: 'All' },
  { value: 'active', name: 'Active' },
  { value: 'nurture', name: 'Nurture' },
  { value: 'failed', name: 'Failed' }
].map(option => new Option(option))

export const aiBotExitFlags = [
  { value: 'unset', name: '(Clear Out)' },
  { value: 'all_information_is_gathered', name: 'All Information Gathered', disabled: true },
  { value: 'not_interested', name: 'Not Interested', disabled: true },
  { value: 'not_interested_bot_dispo', name: 'Not Interested (Auto Dispo)', disabled: true },
  { value: 'requires_call_now', name: 'Requires Call Now', disabled: true },
  { value: 'requires_human_to_call_specific', name: 'Requires Call Later', disabled: true },
  { value: 'upcoming_inbound', name: 'Client Will Call Us', disabled: true },
  { value: 'requires_delay', name: 'Requires Delay', disabled: true },
  { value: 'requires_human_to_call', name: 'Requires Human to Call', disabled: true },
  { value: 'requires_human_to_text', name: 'Requires Human to Text', disabled: true },
  {
    value: 'requires_human_to_call_auto_queue',
    name: 'Requires Human to Call Auto Queue',
    disabled: true
  },
  {
    value: 'requires_human_to_call_specific_auto_queue',
    name: 'Requires Human to Later Auto Queue',
    disabled: true
  },
  { value: 'unqualified', name: 'Unqualified', disabled: true }
].map(option => new Option(option))

export const dialerQueueOptions = [
  { value: 'home_consultant', name: 'Home Consultant' },
  { value: 'product_eligible_team', name: 'Product Eligible Team' },
  { value: 'client_advocate_st_lucia', name: 'Client Advocate St.Lucia' }
].map(option => new Option(option))

export const bbysLeadChannelOptions = [
  { value: 'enterprise', name: 'Enterprise' },
  { value: 'd2c', name: 'D2C' },
  { value: 'agent', name: 'Agent' },
  { value: 'lender', name: 'Lender' },
  { value: 'other', name: 'Other' }
]

export const bbysLeadWarehouseOptions = [
  {
    value: 'HLHL Flagstar',
    name: 'HLHL Flagstar'
  },
  {
    value: 'HLHL Florida Capital Bank',
    name: 'HLHL Florida Capital Bank'
  },
  {
    value: 'HLHL Equity',
    name: 'HLHL Equity'
  },
  {
    value: 'TLS Flagstar',
    name: 'TLS Flagstar'
  }
]

export const incomingResidenceFinanceTypeOptions = [
  { value: 'unsure', name: 'Mortgage (unsure)' },
  { value: 'mortgage', name: 'Mortgage' },
  { value: 'cash', name: 'All cash' }
]

// TODO: This is currently maintained in two spots, we should consolidate
//      - https://github.com/homelight/agent-dashboard/blob/main/apps/equity-app/components/Bbys/LeadSubmission/constants.ts#276
export const lennarDivisionOptions = [
  'AL - Gulf Coast',
  'AL - North Alabama',
  'AZ - Arizona Sales Center',
  'AZ - Phoenix',
  'AZ - Tucson',
  'CA - Bay Area-Concord',
  'CA - Central Valley',
  'CA - Coastal',
  'CA - Inland Empire',
  'CA - Sacramento',
  'CA - San Diego',
  'CO - Englewood',
  'FL - Jacksonville',
  'FL - Mid-Florida',
  'FL - Orlando',
  'FL - Palm Atlantic',
  'FL - SE FL South',
  'FL - SW Florida',
  'FL - Tampa',
  'GA - Atlanta',
  'ID - Boise',
  'IL - Chicago',
  'IN - Indianapolis',
  'Maryland',
  'MN - Minneapolis',
  'NC - Charlotte',
  'NC - Charlotte Sales Center',
  'NC - Raleigh',
  'NV - Las Vegas',
  'NV - Reno',
  'NY - New Jersey',
  'OR - Portland',
  'PA - Philadelphia Metro',
  'SC - Charleston',
  'TN - Nashville',
  'TX - Austin',
  'TX - Dallas',
  'TX - Houston',
  'TX - San Antonio',
  'Utah',
  'Virginia',
  'WA - Seattle',
  'Other'
].map(division => new Option({ value: division, name: division }))

export const eligibilityOptions = [
  { value: 'true', name: 'Eligble' },
  { value: 'false', name: 'Not Eligible' }
].map(option => new Option(option))

export const fhaVaOptions = (
  [
    { value: 'fha', name: 'Yes, FHA' },
    { value: 'va', name: 'Yes, VA' },
    { value: 'other_government', name: 'Yes, other government' },
    { value: 'no', name: 'No' },
    { value: 'true', name: 'Yes', disabled: true },
    { value: 'false', name: 'No', disabled: true }
  ] as Array<{ name: string; value: FhaVaType | 'Yes' | 'No' }>
).map(option => new Option(option))
