// @ts-strict
import { ButtonForm, useButtonForm } from 'components'
import { useAgentController } from 'hooks'
import { Agent } from 'models'
import { AgentFormFields } from './AgentFormFields'
import { agentFormValidations, TAgentFormPayload, transformPayload } from './utils'

export const AgentEditButtonForm = ({ agent }: { agent: Agent }) => {
  const initialPayload: TAgentFormPayload = {
    firstName: agent.firstName!,
    lastName: agent.lastName!,
    phoneMobileId: agent.getPhoneMobile()?.id!,
    phoneMobile: agent.getPhoneMobile()?.number!,
    phoneOfficeId: agent.getPhoneOffice()?.id!,
    phoneOffice: agent.getPhoneOffice()?.number!,
    email1: agent.email1!,
    email2: agent.email2!,
    licenseNumber: agent.licenseNumber!,
    stateId: agent.state?.id!,
    brokerage: agent.officeDisplayName!,
    website: agent.website!,
    assignedAreaId: agent.assignedArea?.id!,
    eliteStatus: agent?.eliteStatus,
    mlsIdentifier: agent.mlsIdentifier
  }

  const { updateAgent } = useAgentController(agent.id)
  const { fieldsProps, formProps } = useButtonForm<TAgentFormPayload>({
    initialPayload,
    mutation: updateAgent,
    transformPayload: transformPayload,
    formValidations: agentFormValidations
  })

  return (
    <ButtonForm buttonText="Edit" title="Edit Agent" {...formProps}>
      <AgentFormFields {...fieldsProps} />
    </ButtonForm>
  )
}
