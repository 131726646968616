import { useRef, useState } from 'react'
import {
  autoUpdate,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole
} from '@floating-ui/react'
import debounce from 'lodash.debounce'
import { Item } from '../Select/types'
import { AutocompleteProps } from './types'

type UseAutocompleteProps = Pick<
  AutocompleteProps,
  'defaultOpen' | 'isDisabled' | 'onChange' | 'onInputChange' | 'value' | 'onClose' | 'onOpen'
>

export const useAutocomplete = ({
  defaultOpen,
  isDisabled,
  onClose,
  onInputChange,
  onOpen,
  onChange,
  value
}: UseAutocompleteProps) => {
  const [inputValue, setInputValue] = useState<string>(value?.label ?? '')
  const [selectedItem, setSelectedItem] = useState<Item | undefined>(value)
  const [isOpen, setIsOpen] = useState<boolean>(!!defaultOpen)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  // if some value is coming from outside (async cases), it must update the selectedItem
  if (value?.value !== selectedItem?.value) {
    setSelectedItem(value)
    setInputValue(value?.label ?? '')
  }

  const debouncedOnChangeInput = useRef(
    debounce((nextValue: string) => {
      onInputChange(nextValue)
    }, 300)
  ).current

  const listRef = useRef<Array<HTMLElement | null>>([])

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (isDisabled) {
      return
    }

    const value = event.target.value

    setInputValue(value)
    debouncedOnChangeInput(value)

    if (value) {
      if (!isOpen) {
        onOpen?.()
      }
      setIsOpen(true)
      setActiveIndex(0)
    }
  }

  const { x, y, strategy, refs, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: open => {
      if (isDisabled) {
        return
      }

      if (open && onOpen) {
        onOpen()
      }

      const returnToPreviousInputValueIfNoneWasSelected = () => {
        // since "value" object can be undefined, both needs to be string to be compared
        const valueLabel = value?.label ?? ''
        if (selectedItem?.label !== inputValue) {
          onInputChange(valueLabel)
          setInputValue(valueLabel)
        }
      }

      if (!open) {
        onClose?.()
        returnToPreviousInputValueIfNoneWasSelected()
      }

      setIsOpen(open)
    },
    middleware: [
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`
          })
        },
        padding: 10
      })
    ]
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useRole(context, { role: 'listbox' }),
    useDismiss(context),
    useListNavigation(context, {
      listRef,
      activeIndex,
      onNavigate: setActiveIndex,
      virtual: true,
      loop: true
    })
  ])

  const onClear = () => {
    setSelectedItem(undefined)
    setInputValue('')
    onChange(undefined)
    onInputChange('')
  }

  return {
    activeIndex,
    listRef,
    positionX: x,
    positionY: y,
    strategy,
    refs,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    open: isOpen,
    setOpen: setIsOpen,
    setActiveIndex,
    inputValue,
    setInputValue,
    context,
    handleInputChange,
    selectedItem,
    setSelectedItem,
    onClear
  }
}
