import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Callout.module.scss'

type Props = {
  children?: ReactNode
  className?: string
}

export const Callout = ({ className, children }: Props) => {
  return <div className={cx(styles.callout, className)}>{children}</div>
}
