import { useCallback, useEffect, useRef, useState } from 'react'

export const useDocumentFocus = () => {
  const focusedHandler = useRef(0)
  const sleepingHandler = useRef(0)
  const [focused, setFocused] = useState(document.hasFocus())
  const [sleeping, setSleeping] = useState(false)

  const updateFocused = () => {
    setFocused(document.hasFocus())
  }

  const updateSleeping = (val = true) => {
    setSleeping(val)
  }

  const monitorFocus = useCallback(() => {
    clearTimeout(focusedHandler.current)
    focusedHandler.current = setTimeout(monitorFocus, 100)
    updateFocused()
  }, [])

  const stopMonitoringFocus = () => {
    clearTimeout(focusedHandler.current)
  }

  useEffect(() => {
    if (focused) {
      clearTimeout(sleepingHandler.current)
      updateSleeping(false)
    } else {
      sleepingHandler.current = setTimeout(updateSleeping, 5 * 60 * 1000)
    }
  }, [focused])

  useEffect(() => {
    monitorFocus()

    return () => {
      stopMonitoringFocus()
    }
  }, [monitorFocus])

  return { focused, sleeping }
}
