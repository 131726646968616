// @ts-strict
import { ButtonForm, FormField, PasswordInput, Section, Sections, TextInput } from 'components'
import { useSignInFormButton } from './useSignInFormButton'

export const SignInFormButton = () => {
  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useSignInFormButton()

  const setEmail = (val: string) => onChangeAttribute('email', val)
  const setPassword = (val: string) => onChangeAttribute('password', val)

  return (
    <ButtonForm title="Sign In" {...formProps}>
      <Sections>
        <Section>
          <FormField htmlFor="email" title="Email" required>
            <TextInput
              id="email"
              value={payload.email}
              onChange={setEmail}
              data-testid="signInEmail"
              autoFocus
            />
          </FormField>
        </Section>
        <Section>
          <FormField htmlFor="password" title="Password" required>
            <PasswordInput
              id="password"
              value={payload.password}
              onChange={setPassword}
              data-testid="signInPassword"
            />
          </FormField>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <FormField title="Need Help?">
            For any other issues, such as lost password, please visit homelight.com.
          </FormField>
        </Section>
      </Sections>
    </ButtonForm>
  )
}
