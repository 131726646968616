import { Section, Sections } from 'components'
import { FieldFormField } from '../../FieldFormField'
import { useFields } from '../../useHLSSLeadDisposition'

export const HLSSClosedSell = () => {
  useFields({
    required: ['dpSellerCredit', 'departingPropertyCloseOfSaleEscrowDate']
  })
  return (
    <Sections title="DR Sale Transaction">
      <Section>
        <FieldFormField field="dpSellerCredit" />
      </Section>
      <Section>
        <FieldFormField field="departingPropertyCloseOfSaleEscrowDate" />
      </Section>
    </Sections>
  )
}
