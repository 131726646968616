// @ts-strict

import { useState } from 'react'
import { TLeadUserTypeServerSlug } from 'lookups'
import { Lead, Order } from 'models'
import { byId } from 'utils'

type Args = {
  lead: Lead
  order: Order
  selectedLeadId: string
}

const CC_TRADE_IN = 'cc_trade_in'
const CC_CASH_OFFER = 'cc_cash_offer'
const attachButtonRuleUserTypes = [CC_TRADE_IN, CC_CASH_OFFER]

export const useAttachLead = ({ lead, order, selectedLeadId }: Args) => {
  const isLeadAlreadyAttached: boolean = !!order.leads?.some(byId(lead.id))
  const [isDetachMode, setIsDetachMode] = useState(isLeadAlreadyAttached)

  const isLeadFromCurrentPage = lead.id === selectedLeadId
  const isLeadAttachedAsTradeInOrCashOffer: boolean = !!order.leads?.some(
    item =>
      attachButtonRuleUserTypes.includes(item.userType || '') &&
      item.id === lead.id &&
      item.userType === lead.userType
  )
  const hasLeadAttachedWithUserType = (userType: TLeadUserTypeServerSlug): boolean => {
    return !!order.leads?.some(item => item.userType === userType && lead.userType === userType)
  }

  const hasLeadAttachedAsTradeIn = hasLeadAttachedWithUserType(CC_TRADE_IN)
  const disabledButtonTitleTradeIn =
    !isLeadAttachedAsTradeInOrCashOffer && hasLeadAttachedAsTradeIn
      ? 'Only 1 Trade In per order'
      : ''

  const hasLeadAttachedAsCashOffer = hasLeadAttachedWithUserType(CC_CASH_OFFER)
  const disabledButtonTitleCashOffer =
    !isLeadAttachedAsTradeInOrCashOffer && hasLeadAttachedAsCashOffer
      ? 'Only 1 Cash Offer per order'
      : ''

  const shouldShowAttachButton = !isLeadFromCurrentPage
  const shouldDisableAttachButton =
    !isLeadAttachedAsTradeInOrCashOffer && (hasLeadAttachedAsTradeIn || hasLeadAttachedAsCashOffer)

  const defaultAttachButtonTitle = isDetachMode ? 'Detach Lead' : 'Attach Lead'
  const disabledAttachButtonTitle = disabledButtonTitleTradeIn || disabledButtonTitleCashOffer

  return {
    isDetachMode,
    setIsDetachMode,
    shouldShowAttachButton,
    shouldDisableAttachButton,
    defaultAttachButtonTitle,
    disabledAttachButtonTitle
  }
}
