import { TUserIntegrationPayload } from 'services'
import { getUserIntegrationsUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export const putUserIntegration = async (
  integrationId: string,
  payload: TUserIntegrationPayload
) => {
  return putRequest(getUserIntegrationsUrl({ id: integrationId }), snakeCaseKeys(payload))
}
