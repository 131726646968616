// @ts-strict

import { AGENT_PARTNERSHIPS_CACHE_KEY, useMutation, UseMutationResult, useQueryClient } from 'hooks'
import { AgentPartnership } from 'models'
import { AgentPartnershipPutPayload, putAgentPartnership } from 'services'

export const useAgentPartnershipController = (agentPartnershipId: string) => {
  const queryCache = useQueryClient()

  const clearCaches = () => {
    queryCache.invalidateQueries(AGENT_PARTNERSHIPS_CACHE_KEY)
  }

  const updateAgentPartnership: UseMutationResult<
    unknown,
    unknown,
    AgentPartnershipPutPayload,
    unknown
  > = useMutation(data => putAgentPartnership(agentPartnershipId, data), {
    onSuccess: (data: { agentPartnership: AgentPartnership }) => {
      clearCaches()
    }
  })

  return {
    updateAgentPartnership,
    isError: updateAgentPartnership.isError
  }
}
