// @ts-strict

import { css, styled } from '@foundation/themes/styles'

export const Overlay = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: scroll;
    z-index: ${theme.zIndices.overlay};
    /* prettier-ignore */
    @media(max-width: 414px) {
      width: 100vw;
    }
  `}
`
