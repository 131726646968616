import { useMemo, useState } from 'react'
import { useFeatureFlags } from 'hooks'
import {
  agentChannelsDefaultValues,
  AgentFilters,
  clientChannelsDefaultValues,
  ClientFilters,
  emailChannelValue,
  globalChannelsDefaultValues,
  GlobalFilters
} from './filterSettings'

type CommsTabs = {
  agent: AgentFilters
  client: ClientFilters
  global: GlobalFilters
}

export const useCommsFilters = () => {
  const { salesOpsCommsEmails } = useFeatureFlags([
    'sales-app-comms-zoom-calls',
    'sales-ops-comms-emails'
  ])

  const globalDefaultChannels = useMemo(
    () => [
      ...globalChannelsDefaultValues,
      ...(salesOpsCommsEmails.enabled ? [emailChannelValue] : [])
    ],
    [salesOpsCommsEmails.enabled]
  )
  const clientDefaultChannels = useMemo(
    () => [
      ...clientChannelsDefaultValues,
      ...(salesOpsCommsEmails.enabled ? [emailChannelValue] : [])
    ],
    [salesOpsCommsEmails.enabled]
  )
  const agentDefaultChannels = useMemo(
    () => [
      ...agentChannelsDefaultValues,
      ...(salesOpsCommsEmails.enabled ? [emailChannelValue] : [])
    ],
    [salesOpsCommsEmails.enabled]
  )

  const [filters, setFilters] = useState<CommsTabs>({
    global: {
      channels: globalDefaultChannels
    },
    client: {
      channels: clientDefaultChannels
    },
    agent: {
      channels: agentDefaultChannels
    }
  })

  const setFilter = <TabKey extends keyof CommsTabs>(key: TabKey, value: CommsTabs[TabKey]) => {
    setFilters(state => ({ ...state, [key]: value }))
  }

  return {
    filters,
    setFilter,
    defaults: {
      globalDefaultChannels,
      clientDefaultChannels,
      agentDefaultChannels
    }
  }
}
