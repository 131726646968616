import { Option } from 'classes'
import { IOptionable } from 'lookups'

export class ChangeReason implements IOptionable {
  name!: string
  value!: string

  constructor(changeReasonAttributes: Omit<ChangeReason, 'toOption'>) {
    Object.assign(this, changeReasonAttributes)
  }

  toOption() {
    return new Option({ name: this.name, value: this.value })
  }
}
