import { Table, TableResults } from 'components'
import { AgentLead } from 'models'
import styles from './AgentLeadsTable.module.scss'
import { AgentLeadsTableHeader } from './AgentLeadsTableHeader'
import { AgentLeadsTableRow } from './AgentLeadsTableRow'

export const AgentLeadsTable = ({
  agentLeads,
  isLoading
}: {
  agentLeads: AgentLead[]
  isLoading: boolean
}) => {
  return (
    <Table className={styles.table}>
      <AgentLeadsTableHeader />
      <TableResults type="Referrals" colSpan={6} isLoading={isLoading}>
        {agentLeads?.map(agentLead => (
          <AgentLeadsTableRow key={agentLead.id} agentLead={agentLead} />
        ))}
      </TableResults>
    </Table>
  )
}
