export const breakpoint = (width: number) => {
  if (width > 1200) {
    return 'xl'
  }
  if (width > 992) {
    return 'lg'
  }
  if (width > 768) {
    return 'md'
  }
  if (width >= 576) {
    return 'sm'
  }
  if (width < 576) {
    return 'xs'
  }
  return ''
}
