// @ts-strict
import { Button } from '@foundation/components'
import { ButtonForm, useButtonForm } from 'components'
import { useLeadContext } from 'contexts'
import { AgentMatchingLead } from 'models'
import { validatePresence } from 'utils'
import { AgentMatchingLeadDispositionFormFields } from '../AgentMatchingLeadDispositionFormFields'

export type AgentMatchingLeadDispositionPayload = { key: string; notifyClient: boolean }

type TProps = {
  callback?: () => void
  lead: AgentMatchingLead
}

const formValidations = { key: { label: 'Stage', validations: [validatePresence] } }
const initialPayload: AgentMatchingLeadDispositionPayload = {
  notifyClient: false,
  key: ''
}

export const AgentMatchingLeadDispositionButtonForm = ({ lead, callback }: TProps) => {
  // todo: This should come from the controller, not the context
  const { updateAgentMatchingLeadDisposition } = useLeadContext()

  const { fieldsProps, formProps } = useButtonForm<AgentMatchingLeadDispositionPayload>({
    initialPayload,
    formValidations,
    mutation: updateAgentMatchingLeadDisposition,
    callback: callback
  })

  const renderDispositionButton = () => {
    return (
      <Button onClick={formProps.toggleShowForm} size="xs">
        Disposition
      </Button>
    )
  }

  return (
    <>
      {renderDispositionButton()}
      <ButtonForm hideTriggerButton title="Disposition" {...formProps}>
        <AgentMatchingLeadDispositionFormFields lead={lead} fieldsProps={fieldsProps} />
      </ButtonForm>
    </>
  )
}
