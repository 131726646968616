// @ts-strict
import { ServiceOfficeResponse } from 'schema'

export class ServiceOffice {
  branchName?: string
  id!: string
  licenseNumber?: string
  name?: string
  constructor(serviceOfficeAttributes: ServiceOffice) {
    Object.assign(this, serviceOfficeAttributes)
  }

  static create(serviceOfficeResponse: Potential<ServiceOfficeResponse>): Potential<ServiceOffice> {
    if (!serviceOfficeResponse) {
      return serviceOfficeResponse
    }
    return new ServiceOffice(serviceOfficeResponse)
  }
}
