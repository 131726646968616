import { FormField, InlineEditMoneyInput, InlineEditSelect, Section, Sections } from 'components'
import { useLeadContext } from 'contexts'
import { agentMatchingCallReviewCreditScoreOptions, fhaVaOptions, yesOrNoOptions } from 'lookups'
import { dataOrDash, stringifyValue } from 'utils'

type TProps = {
  onChangeAttribute: (key: any, value: any) => void
  optionalFields?: boolean
}

export default function LeadDispositionMortgageScreenSection({
  optionalFields = false,
  onChangeAttribute
}: TProps) {
  const { lead, updateLeadNoCacheClear } = useLeadContext()

  const updateLeadField = (value: Record<string, string | number | Record<string, string>>) => {
    updateLeadNoCacheClear.mutateAsync(value)
  }

  const updateCallReview = (key: string, value: string) => {
    onChangeAttribute(key, value)
    updateLeadField({ callReview: { [key]: value } })
  }

  return (
    <Sections title="Mortgage Screen">
      <Section className="mortgage-screen">
        <FormField title="Need Lender?" inline required={false}>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('needLender', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.needLender)}
          />
        </FormField>
        <FormField title="Credit Score" inline required={!optionalFields}>
          <InlineEditSelect
            onChange={(val: string) => {
              updateCallReview('creditScore', val)
            }}
            options={agentMatchingCallReviewCreditScoreOptions}
            value={dataOrDash(lead?.callReview?.creditScore)}
            isError={!lead?.callReview?.creditScore}
          />
        </FormField>
        <FormField title="Downpayment > 3%" inline required={!optionalFields}>
          <InlineEditSelect
            onChange={(value: string) => {
              onChangeAttribute('hasMinimumDownPayment', value)
              updateLeadField({ hasMinimumDownPayment: value })
            }}
            options={yesOrNoOptions}
            value={stringifyValue(lead.hasMinimumDownPayment)}
            isError={
              lead.hasMinimumDownPayment === null || lead.hasMinimumDownPayment === undefined
            }
          />
        </FormField>
        <FormField title="Consistent Income?" inline required={!optionalFields}>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('consistentIncome', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.consistentIncome)}
            isError={
              lead.callReview?.consistentIncome === null ||
              lead.callReview?.consistentIncome === undefined
            }
          />
        </FormField>
        <FormField title="IR FHA/VA Loan" inline required={!optionalFields}>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('fhaVaLoan', value || null)}
            options={fhaVaOptions}
            value={stringifyValue(lead.callReview?.fhaVaLoan)}
            isError={
              lead.callReview?.fhaVaLoan === null || lead.callReview?.fhaVaLoan === undefined
            }
          />
        </FormField>
        <FormField title="Reverse Mortgage" required={false} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('reverseMortgage', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.reverseMortgage)}
          />
        </FormField>
        <FormField title="Minimum Loan Amount" required={false} inline>
          <InlineEditMoneyInput
            value={stringifyValue(lead.callReview?.minimumLoanAmount)}
            readOnly
          />
        </FormField>
      </Section>
    </Sections>
  )
}
