import React from 'react'
import { SearchStatus } from 'components/SearchStatus/index'
import { SearchSummary } from 'components/SearchSummary/index'
import { useListingsContext } from 'contexts/Listings'
import { useParamsContext } from 'contexts/Params'

export const ListingsListStatus = () => {
  const { totalCount, isLoading } = useListingsContext()

  const {
    params: { page, teamMember, ...filtersAndSorts },
    resetParams
  } = useParamsContext()

  const onClear = () => resetParams()

  const canClear = !isLoading && !!Object.keys(filtersAndSorts).length

  return (
    <SearchSummary
      status={<SearchStatus params={filtersAndSorts} count={totalCount} type="Listing" />}
      onClear={canClear ? onClear : null}
    />
  )
}
