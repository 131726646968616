// @ts-strict
import { Contents, PaginationParams, SearchStatus } from 'components'
import { InspectionTable } from 'content/Inspection'
import { ListProvider, useLeadContext, useParamsContext } from 'contexts'
import { useInspectionsController } from 'controllers'
import { useDocumentTitle } from 'hooks'
import { inspectionListColumns, useInspectionSearchFilter } from 'lookups'

export const LeadInspectionsReportsTab = () => {
  const { lead } = useLeadContext()
  const { params } = useParamsContext()
  const providerLead = lead?.[lead.providerName!]
  const { inspectionsQuery } = useInspectionsController({
    ...params,
    providerLeadId: providerLead.id
  })
  useDocumentTitle(`${lead?.name} - Inspections - Reports`)
  const inspectionSearchFilter = useInspectionSearchFilter()

  return (
    <ListProvider columns={inspectionListColumns} filters={inspectionSearchFilter}>
      <Contents title="Reports">
        <SearchStatus params={params} count={inspectionsQuery.data?.totalCount} type="Report" />
        <InspectionTable
          items={inspectionsQuery.data?.items}
          isLoading={inspectionsQuery.isLoading}
        />
        <PaginationParams totalPages={inspectionsQuery.data?.pageCount} />
      </Contents>
    </ListProvider>
  )
}
