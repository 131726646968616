import {
  activityWindowOptions,
  leadStagesOptions,
  trueFalseOptions,
  userTypeOptions,
  useServicesOpportunities
} from 'lookups'
import { bySlug, titleize } from '../../utils'
import { SearchFilter } from './SearchFilter'
import { useSearchFilters } from './useSearchFilters'

export const useOrdersListSearchFilters = () => {
  const { findFilterBySlug } = useSearchFilters()
  const { activeServicesOpportunitiesStatusOptions } = useServicesOpportunities()

  const OrdersListSearchFilters = [
    new SearchFilter({
      slug: 'userType',
      name: 'Lead Type',
      options: userTypeOptions,
      getDisplayValue: titleize
    }),
    new SearchFilter({
      slug: 'oppStatus',
      name: 'Opp Status',
      options: activeServicesOpportunitiesStatusOptions,
      getDisplayValue: titleize
    }),
    new SearchFilter({
      slug: 'leadStages',
      name: 'Stages',
      options: leadStagesOptions,
      getDisplayValue: titleize
    }),
    new SearchFilter({
      slug: 'daysAgo',
      name: 'Stage Update Within',
      options: activityWindowOptions,
      getDisplayValue: titleize
    }),
    new SearchFilter({
      slug: 'escrowAttached',
      name: 'Escrow Attachment',
      options: trueFalseOptions
    })
  ]

  const findOrdersListSearchFilterBySlug = (slug: string) => {
    return OrdersListSearchFilters.find(bySlug(slug)) || findFilterBySlug(slug)
  }

  return {
    findFilterBySlug: findOrdersListSearchFilterBySlug
  }
}
