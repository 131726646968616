// @ts-strict

import React, { ChangeEventHandler, useRef } from 'react'
import { cx, randomString } from 'utils'
import { Style } from '../Style'
import styles from './RangeInput.module.scss'

type Props = {
  className?: string
  disabled: boolean
  max: number
  min: number
  onChange?: ChangeEventHandler<HTMLInputElement>
  value: string
}

export const RangeInput = ({
  className,
  disabled = false,
  max = 100,
  min = 0,
  onChange,
  value,
  ...rest
}: Props) => {
  const { current: id } = useRef(randomString())
  const progress = (Number(value) / max - min) * 100
  const rules = {}

  rules[`#${id}::before`] = `width: ${progress}%`

  return (
    <>
      <Style rules={rules} />
      <input
        id={id}
        className={cx(styles.rangeInput, className)}
        disabled={disabled}
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </>
  )
}
