// @ts-strict

import { TASKS_CACHE_KEY } from 'controllers/cache'
import { useMutation, useQueryClient } from 'hooks'
import {
  alertError,
  postResendTaskEmail,
  putCompleteTask,
  putDismissTask,
  putReopenTask,
  putTask,
  TPutCompleteTaskVariables,
  TPutDismissTaskVariables
} from 'services'

export const useTaskController = (id: string) => {
  const queryCache = useQueryClient()

  const defaultCallback = {
    onSuccess: () => queryCache.invalidateQueries(TASKS_CACHE_KEY),
    onError: () => alertError()
  }

  const updateTask = useMutation((data: object) => putTask(id, data), defaultCallback)

  const completeTask = useMutation(
    (payload: TPutCompleteTaskVariables['payload']) => putCompleteTask({ id, payload }),
    defaultCallback
  )

  const dismissTask = useMutation(
    (payload: TPutDismissTaskVariables['payload']) => putDismissTask({ id, payload }),
    defaultCallback
  )

  const reopenTask = useMutation(() => putReopenTask({ id }), defaultCallback)

  const resendTaskEmail = useMutation(postResendTaskEmail, defaultCallback)

  return {
    completeTask,
    resendTaskEmail,
    updateTask,
    dismissTask,
    reopenTask
  }
}
