// @ts-strict
import { Card, ConsoleLoading, HiddenAgentWrapper } from 'components'
import { IslandOutline } from 'components/Graphic'
import { AgentAside } from 'content/Agent'
import {
  AgentProviderLeadDispositionHeader,
  AgentProviderLeadTabs
} from 'content/AgentProviderLead'
import { useAgentController } from 'controllers'
import { AgentProviderLead } from 'models'
import { cx, toSnakeCase } from 'utils'
import styles from './AgentProviderLeadCard.module.scss'

type AgentProviderLeadCardProps = {
  agentProviderLead: AgentProviderLead
}

export const AgentProviderLeadCard = ({ agentProviderLead }: AgentProviderLeadCardProps) => {
  const agentByProvider = agentProviderLead && agentProviderLead.agentLead
  const agentByLead = agentByProvider && agentByProvider.agent
  const id = agentByLead && agentByLead.id
  const { agent, isLoading } = useAgentController(id!, true)

  const isHidden = agent?.hideProfile || agent?.hideFromSearch
  const onVacation = agent?.referralPreferences?.onVacationUntil
  const stage = toSnakeCase(agentProviderLead.stage)

  if (isLoading) {
    return <ConsoleLoading type="Agent" />
  } else if (!agent) {
    return null
  }

  const cardTopBanner = onVacation ? (
    <div className={styles.topBanner}>
      <IslandOutline />
      {`Vacationing until ${agent?.referralPreferences?.onVacationUntil}`}
    </div>
  ) : null

  return isHidden ? (
    <HiddenAgentWrapper agent={agent} agentLead={agentProviderLead.agentLead}>
      <AgentAside agent={agent} agentLead={agentProviderLead.agentLead} className={styles.aside} />
    </HiddenAgentWrapper>
  ) : (
    <Card
      data-testid="agentProviderLeadCard"
      summary={
        <>
          <AgentProviderLeadDispositionHeader agentProviderLead={agentProviderLead} />
          <AgentAside
            agent={agent}
            agentLead={agentProviderLead.agentLead}
            className={styles.aside}
          />
        </>
      }
      details={<AgentProviderLeadTabs agentProviderLead={agentProviderLead} />}
      topBanner={cardTopBanner}
      className={cx(styles.card, styles[stage], onVacation ? styles.withBanner : '')}
    />
  )
}
