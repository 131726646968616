// @ts-strict
import { ButtonForm } from 'components'
import { UserButtonFormFields, useUserButtonForm } from 'content/User'
import { useAgentController } from 'hooks'
import { Agent } from 'models'

type AgentCreateUserButtonProps = {
  agent: Agent
}

export const AgentCreateUserButtonForm = ({ agent }: AgentCreateUserButtonProps) => {
  const { createAgentUser } = useAgentController(agent.id)
  const { formProps, fieldsProps } = useUserButtonForm(createAgentUser, agent.user)

  return (
    <ButtonForm title="Create User" {...formProps}>
      <UserButtonFormFields {...fieldsProps} />
    </ButtonForm>
  )
}
