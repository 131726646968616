// @ts-strict

import { useStack } from 'hooks'

export const useSettings = () => {
  const { stack: agentHistory, add: addAgentToHistory } = useStack(7, 'agentHistory')
  const { stack: leadHistory, add: addLeadToHistory } = useStack(15, 'leadHistory')
  const { stack: orderHistory, add: addOrderToHistory } = useStack(5, 'orderHistory')

  return {
    agentHistory,
    addAgentToHistory,
    leadHistory,
    addLeadToHistory,
    orderHistory,
    addOrderToHistory
  }
}
