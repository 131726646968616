// @ts-strict
import { useLocation } from 'react-use'
import { Contents, ListSearchHeader, Page, PaginationParams, ViewsMenu } from 'components'
import { EscrowLeadListFilters } from 'content/EscrowLead'
import { CreateEscrowOrderButtonForm } from 'content/EscrowLead'
import { EscrowLeadsTable } from 'content/EscrowLeads/EscrowLeadsTable'
import { ListProvider } from 'contexts'
import { useEscrowLeadsController } from 'controllers/Leads/useEscrowLeadsController'
import { useDocumentTitle } from 'hooks'
import { useFeatureFlags } from 'hooks'
import { escrowLeadsListColumns } from 'lookups'
import { useEscrowLeadSearchFilters } from 'lookups/lookupSearchFilters/escrowLeadSearchFilter/useEscrowLeadSearchFilter'
import { queryStringToObject } from 'utils'

const PAGE_TITLE = 'Escrow Leads'

const actions = (
  <>
    <EscrowLeadListFilters />
    <ViewsMenu />
  </>
)

const useEscrowLeadsPage = () => {
  useDocumentTitle(PAGE_TITLE)
  const { search } = useLocation()
  const searchParams = queryStringToObject(search)

  const { escrowLeadsQuery } = useEscrowLeadsController(searchParams)

  return {
    escrowLeadsQuery
  }
}

export const EscrowLeadsPage = () => {
  const { escrowLeadsQuery } = useEscrowLeadsPage()
  const { data, isLoading } = escrowLeadsQuery
  const { items } = data || {}
  const escrowLeadFilters = useEscrowLeadSearchFilters()
  const { salesAppCreateEscrow } = useFeatureFlags(['sales-app-create-escrow'])

  const action = salesAppCreateEscrow.enabled ? <CreateEscrowOrderButtonForm /> : null

  return (
    <ListProvider columns={escrowLeadsListColumns} filters={escrowLeadFilters}>
      <Page>
        <Contents title={PAGE_TITLE} actions={action}>
          <ListSearchHeader
            counterText="Escrow lead"
            totalCount={escrowLeadsQuery.data?.totalCount}
            isLoading={escrowLeadsQuery.isLoading}
            filter={actions}
          />
          <EscrowLeadsTable items={items} isLoading={isLoading} />
          <PaginationParams totalPages={escrowLeadsQuery.data?.pageCount} />
        </Contents>
      </Page>
    </ListProvider>
  )
}
