import { ReactNode, useContext } from 'react'
import { Link } from 'react-router-dom'
import { cx } from 'utils'
import styles from './Menu.module.scss'
import { MenuContext } from './MenuContext'

type Props = {
  children: ReactNode
  className?: string
  onClick?: () => void
  to: string
}

export const MenuLink = ({ className, to, onClick, children, ...props }: Props) => {
  const [, setMenuOpen] = useContext(MenuContext)

  const closeMenu = () => {
    if (onClick) {
      onClick()
    }
    setMenuOpen(false)
  }

  const internalUrl = (
    <Link to={to} onClick={closeMenu} {...props} data-testid="menuLink">
      {children}
    </Link>
  )

  const externalUrl = (
    <a href={to} onClick={closeMenu} {...props} data-testid="menuLink">
      {children}
    </a>
  )

  return (
    <div className={cx(styles.item, className)}>
      {to.startsWith('http') ? externalUrl : internalUrl}
    </div>
  )
}
