export const parseStringToObject = <ReturnedObject>(
  stringToParse: string
): ReturnedObject | undefined => {
  const hasUnnecessaryQuotes =
    stringToParse.includes('"{') || stringToParse.includes('}"') || stringToParse.includes('\\')
  let formattedString = stringToParse
  if (hasUnnecessaryQuotes) {
    const removeBackslash = formattedString.replaceAll(/\\/g, '')
    const removeStartingDoubleQuote = removeBackslash.replaceAll('"{', '{')
    formattedString = removeStartingDoubleQuote.replaceAll('}"', '}')
  }

  try {
    const newObject = JSON.parse(formattedString)
    if (newObject && typeof newObject === 'object' && !Array.isArray(newObject)) {
      return newObject as ReturnedObject
    }
  } catch (e) {
    return undefined
  }
}
