// @ts-strict
import { Block, ExternalLink, FormField } from 'components'
import { getMortgageLeadFieldName } from 'lookups'
import { formatDateTime, formatOrDash } from 'utils'

export type TLoanLinkProps = {
  lenderIsBesi: boolean
  loanApplicationLink?: string
  updatedAt?: string
}

export const LenderProviderLeadLoanLink = ({
  lenderIsBesi,
  loanApplicationLink,
  updatedAt
}: TLoanLinkProps) => {
  const lenderLinkLabel = `${lenderIsBesi ? 'Besi' : 'TRUS'} Loan Application`

  return (
    <>
      <FormField title={getMortgageLeadFieldName('loanApplicationLink')} inline>
        <Block
          title={<ExternalLink href={loanApplicationLink}>{lenderLinkLabel}</ExternalLink>}
          subtitle={`${getMortgageLeadFieldName('updatedAt')} ${formatOrDash(
            updatedAt,
            formatDateTime
          )}`}
        />
      </FormField>
    </>
  )
}
