// @ts-strict
import { Button, ButtonProps } from '@foundation/components'
import { AsideActionBar } from 'components'
import { LeadCreateActivityNoteButtonForm } from 'content/Lead'
import { useCurrentUserContext, useDialerContext } from 'contexts'
import { Lead } from 'models'
import { toSearchParam } from 'utils'

type Props = { lead: Lead }

type TriggerButtonFormProps = Pick<ButtonProps, 'onClick'>

const TriggerButtonForm = ({ onClick }: TriggerButtonFormProps) => (
  <Button onClick={onClick} size="xs" iconLeft="StickyNote2Outlined">
    Note
  </Button>
)

export const LeadSummaryActionBar = ({ lead }: Props) => {
  const { call, isOnCall } = useDialerContext()
  const { currentUser } = useCurrentUserContext()

  const phoneNumber = lead.phoneNumbers?.[0]

  const handleCall = () => call(lead.getDialer(currentUser!))
  const handleMail = () => window.open(`mailto:${lead.email}`)
  const handleGoogle = () =>
    window.open(
      `https://www.google.com/search?q=${toSearchParam(
        `${lead.name} ${phoneNumber?.formattedNumber || ''} ${lead.email}`
      )}`
    )
  return (
    <AsideActionBar>
      <Button
        iconLeft="PhoneEnabledOutlined"
        size="xs"
        onClick={handleCall}
        isDisabled={!phoneNumber || isOnCall}
      >
        Call
      </Button>
      <Button size="xs" iconLeft="EmailOutlined" onClick={handleMail} isDisabled={!lead.email}>
        Email
      </Button>
      <Button size="xs" iconLeft="OpenInNewOutlined" onClick={handleGoogle}>
        Google
      </Button>
      <LeadCreateActivityNoteButtonForm lead={lead} buttonComponent={TriggerButtonForm} />
    </AsideActionBar>
  )
}
