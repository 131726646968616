import { TModelFieldSetting } from '../ModelField'

export type TEscrowOfficeLeadFieldAttribute =
  | 'escrowOrderSettlementTeamMembers'
  | 'netEquity'
  | 'orderIdentifier'
  | 'orderClosingOn'
  | 'orderNumber'
  | 'orderLoanAmount'
  | 'orderOpenAt'
  | 'orderStatus'
  | 'secondaryUserType'
  | 'startedAsPreEscrow'
  | 'transactionType'

export type TEscrowOfficeLeadFieldSetting = {
  attr: TEscrowOfficeLeadFieldAttribute
} & TModelFieldSetting

export const escrowOfficeLeadFieldsSettings: TEscrowOfficeLeadFieldSetting[] = [
  {
    attr: 'escrowOrderSettlementTeamMembers',
    name: 'Order Opener'
  },
  {
    attr: 'netEquity',
    name: 'Net Equity'
  },
  {
    attr: 'orderIdentifier',
    name: 'Order Identifier'
  },
  {
    attr: 'orderClosingOn',
    name: 'Expected Close Date',
    inputType: 'date'
  },
  {
    attr: 'orderNumber',
    name: 'Order #'
  },
  {
    attr: 'orderLoanAmount',
    name: 'Loan Amount',
    inputType: 'money'
  },
  {
    attr: 'orderOpenAt',
    name: 'Order Opened Date',
    inputType: 'date'
  },
  {
    attr: 'orderStatus',
    name: 'Escrow Status'
  },
  {
    attr: 'startedAsPreEscrow',
    name: 'Pre Escrow'
  },
  {
    attr: 'transactionType',
    name: 'Transaction Type'
  }
]
