import {
  Block,
  FormField,
  InlineEditEmailInput,
  InlineEditPhoneInput,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { useUserController } from 'hooks'
import { dataOrDash, formatPhone } from 'utils'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import { AddLoanOfficerAssistantContact } from './AddLoanOfficerAssistantContact'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>

export const LoanOfficerAssistantSection = ({ lead, updateBBYSLead, updateLead }: Props) => {
  const { loanOfficerAssistant } = lead
  const { userUpdate } = useUserController(loanOfficerAssistant?.user.id)
  const loanOfficerAssistantPhone = loanOfficerAssistant?.user?.phoneNumbers[0]?.number
    ? formatPhone(loanOfficerAssistant?.user?.phoneNumbers[0]?.number)
    : ''

  const updateUser = (payload: any) => {
    userUpdate.mutateAsync(payload)
  }

  const getSplitName = (value: string) => {
    const splitName = value.split(' ', 2)
    return { first_name: splitName[0], last_name: splitName[1] }
  }

  if (!loanOfficerAssistant) {
    return (
      <Sections title="Loan Officer Assistant/Processor">
        <Section>
          <FormField title="Name" inline>
            <Block
              actions={
                <AddLoanOfficerAssistantContact bbysLead={lead?.bbysProviderLead?.bbysLead} />
              }
            ></Block>
          </FormField>
        </Section>
        <Section>
          <></>
        </Section>
      </Sections>
    )
  }

  return (
    <Sections title="Loan Officer Assistant/Processor">
      <Section>
        <FormField title="Name" inline>
          <InlineEditTextInput
            onBlur={(value: string) => updateUser(getSplitName(value))}
            value={dataOrDash(loanOfficerAssistant?.user?.name)}
          />
        </FormField>
      </Section>
      <Section>
        <FormField title="Email Address" inline>
          <InlineEditEmailInput
            onBlur={(value: string) => updateUser({ email: value })}
            value={dataOrDash(loanOfficerAssistant?.user?.email)}
          />
        </FormField>

        <FormField title="Phone Number" inline>
          <InlineEditPhoneInput
            onBlur={(value: string) => updateUser({ phone_mobile: value })}
            value={dataOrDash(loanOfficerAssistantPhone)}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
