import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from 'react-query'
import { Virtuoso } from 'react-virtuoso'
import { ActivityListRow } from 'features/activities/commons/ui/ActivityListRow'
import { ActivityFeedInterface } from 'models/v2/ActivityFeed'
import { TFetchItems } from 'services'
import { EmptyPlaceholderInfiniteScrolling, LoadingInfiniteScrollingFooter } from 'templates'

type Props = {
  data?: InfiniteData<TFetchItems<ActivityFeedInterface>>
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<TFetchItems<unknown>>>
  hasNextPage?: boolean
  isLoading: boolean
}

export const InfiniteActivityList = ({ isLoading, data, hasNextPage, fetchNextPage }: Props) => (
  <Virtuoso
    context={{ isLoading }}
    style={{ height: '100%' }}
    data={data?.pages.flatMap(d => d.items) || []}
    endReached={() => {
      if (!isLoading && hasNextPage) {
        fetchNextPage()
      }
    }}
    itemContent={(index, item) => {
      return <ActivityListRow data-testid={`eventComponent${index}`} activityFeed={item} />
    }}
    components={{
      Footer: ({ context }) => <LoadingInfiniteScrollingFooter isLoading={context?.isLoading} />,
      EmptyPlaceholder: ({ context }) => (
        <EmptyPlaceholderInfiniteScrolling isLoading={context?.isLoading} />
      )
    }}
  />
)
