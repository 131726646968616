// @ts-strict
import { ButtonForm } from 'components'
import { useUserController } from 'controllers'
import type { User } from 'models'
import { UserButtonFormFields } from './UserButtonFormFields'
import { useUserButtonForm } from './useUserButtonForm'

type Props = {
  user: User
}

export const UserEditButtonForm = ({ user }: Props) => {
  const { userUpdate } = useUserController(user.id)
  const { fieldsProps, formProps } = useUserButtonForm(userUpdate, user)

  return (
    <ButtonForm buttonText="Edit" title="Edit User" {...formProps}>
      <UserButtonFormFields {...fieldsProps} />
    </ButtonForm>
  )
}
