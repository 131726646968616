// @ts-strict

import { ReactNode } from 'react'
import { SearchConsole, SearchStatus, SearchSummary } from 'components'
import { useOrdersContext, useParamsContext } from 'contexts'
import { omitViewParams } from 'hooks/useParams/utils'
import { OrdersListFilters } from '../OrdersListFilters'
import { OrdersListSearch } from '../OrdersListSearch'

type TProps = {
  filter?: ReactNode
}

export const OrdersListHeader = ({ filter = <OrdersListFilters /> }: TProps) => {
  const { totalCount, isLoading } = useOrdersContext()
  const { params, resetParams } = useParamsContext()

  const onClear = () => resetParams()
  const canClear = !isLoading && !!Object.keys(omitViewParams(params)).length

  return (
    <>
      <SearchSummary
        status={<SearchStatus params={params} count={totalCount} type="Order" />}
        onClear={canClear ? onClear : undefined}
      />
      <SearchConsole search={<OrdersListSearch />} actions={filter} />
    </>
  )
}
