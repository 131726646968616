import { css, styled } from '@foundation/themes/styles'
import { TextProps as Props } from './Text'

type TextProps = Omit<Props, 'children'>

const colorStyle = css<TextProps>`
  color: ${({ color, theme }) => theme.colors[color!]};
`

const fontSizeStyle = css<TextProps>`
  font-size: ${({ size, theme }) => theme.fontSizes[size!]};
`

const fontWeightStyle = css<TextProps>`
  font-weight: ${({ weight, theme }) => theme.fontWeights[weight!]};
`

const textTransformStyle = css<TextProps>`
  text-transform: ${({ transform }) => transform};
`

const fontItalicStyle = css`
  font-style: italic;
`

const textDecorationUnderlineStyle = css`
  text-decoration: underline;
`

const textDecorationLineThroughStyle = css`
  text-decoration: line-through;
`

const ellipsisStyle = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Text = styled.p<TextProps>`
  ${({ hasEllipsis, hasLineThrough, hasUnderline, isItalic, transform, weight }) => css`
    overflow-wrap: break-word;
    ${colorStyle};
    ${fontSizeStyle};
    ${!!weight && fontWeightStyle};
    ${!!transform && textTransformStyle};
    ${isItalic && fontItalicStyle};
    ${hasUnderline && textDecorationUnderlineStyle};
    ${hasLineThrough && textDecorationLineThroughStyle};
    ${hasEllipsis && ellipsisStyle};
  `}
`
