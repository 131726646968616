import { byAttr } from 'utils'
import { orderFieldsSettings, TOrderFieldAttribute } from './orderFieldsSettings'
import { OrderField } from './'

export const orderFields = orderFieldsSettings.map(setting => {
  return new OrderField(setting)
})

export const getOrderField = (attr: TOrderFieldAttribute) => {
  return orderFields.find(byAttr('attr', attr))
}

export const getOrderFieldName = (attr: TOrderFieldAttribute) => {
  return getOrderField(attr)?.name
}
