// @ts-strict

import { useState } from 'react'
import { Button } from '@foundation/components'
import { HLSSLeadDisposition } from 'content/Lead'
import { CCHLSimpleSaleLead } from 'models'

type HLSimpleSaleLeadTabActionsProps = {
  lead: CCHLSimpleSaleLead
}

export const HLSimpleSaleLeadTabActions = ({ lead }: HLSimpleSaleLeadTabActionsProps) => {
  const [showDispositionForm, setShowDispositionForm] = useState(false)

  return (
    <>
      <Button size="xs" onClick={() => setShowDispositionForm(true)}>
        Disposition
      </Button>
      {showDispositionForm && (
        <HLSSLeadDisposition lead={lead} onCancel={() => setShowDispositionForm(false)} />
      )}
    </>
  )
}
