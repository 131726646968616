import { TLeadStageSlug } from 'lookups'
import {
  EscrowLead,
  isCCCashOfferLead,
  isCCHLSimpleSaleLead,
  isCCTradeInLead,
  Lead,
  LeadSecondaryUserType
} from 'models'

const invalidEscrowLeadStagesAttachedToOrder: TLeadStageSlug[] = ['failed', 'nurture']

export const getEscrowAttachedToOrder = (
  lead: Lead,
  secondaryUserType: NonNullable<LeadSecondaryUserType>
) => {
  const isValidLead = isCCTradeInLead(lead) || isCCCashOfferLead(lead) || isCCHLSimpleSaleLead(lead)

  if (!isValidLead) {
    return
  }

  const validEscrowLeads = lead.siblingLeads!.filter(
    item =>
      item.userType === 'escrow' &&
      !invalidEscrowLeadStagesAttachedToOrder.includes(item.stage as TLeadStageSlug)
  ) as EscrowLead[]

  return validEscrowLeads.find(item => item.secondaryUserType === secondaryUserType)
}
