import { getLeadsSearchFilterSettings } from '../utils'

export const ccHLSimpleSaleLeadSearchFilterSlugs = [
  'assigneeId',
  'capitalPartnerId',
  'source',
  'twoChecks',
  'stage',
  'stateName'
] as const

export const ccHLSimpleSaleLeadSearchFilterSettings = getLeadsSearchFilterSettings(
  ccHLSimpleSaleLeadSearchFilterSlugs
)
