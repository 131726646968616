// @ts-strict
import { useState } from 'react'
import {
  ExternalLink,
  FormField,
  FormFieldSet,
  Section,
  Sections,
  Select,
  SubSection
} from 'components'
import { EscrowOrderSettlementTeamMemberTable } from 'content/EscrowOrderSettlementTeamMembers'
import { getEscrowOfficeLeadFieldName, getServiceOfficeFieldName } from 'lookups'
import { EscrowLead, EscrowOfficeLead, getQualiaUrl } from 'models'
import { dataOrDash, formatDate, formatOrDash, formatPrice, parseAddress, titleize } from 'utils'

type Props = {
  lead: Potential<EscrowLead>
}

export const EscrowOfficeLeadDetailsSection = ({ lead }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState('0')
  const providerLeads = lead?.escrowOfficeProviderLeads
  const escrowOfficeLead: Potential<EscrowOfficeLead> =
    providerLeads?.[selectedIndex]?.escrowOfficeLead
  const showDropdown = !!providerLeads && providerLeads.length > 1

  const getLabel = (lead: Potential<EscrowOfficeLead>) =>
    `${dataOrDash(lead?.orderNumber)} (${formatOrDash(lead?.orderStatus, titleize)})`

  const actions = showDropdown && (
    <Select onChange={setSelectedIndex} value={selectedIndex}>
      {providerLeads.map((providerLead, index) => (
        <option key={providerLead.id} value={index}>
          {getLabel(providerLead.escrowOfficeLead)}
        </option>
      ))}
    </Select>
  )
  const { state } = parseAddress(lead?.fullAddress || '')

  return (
    <Section title="Escrow Orders" actions={actions}>
      <SubSection title={`Escrow Order ${getLabel(escrowOfficeLead)}`}>
        <Sections>
          <Section>
            <FormFieldSet>
              <FormField title={getServiceOfficeFieldName('name')} inline>
                {dataOrDash(
                  [
                    escrowOfficeLead?.serviceOffice?.name,
                    escrowOfficeLead?.serviceOffice?.branchName
                  ]
                    .filter(Boolean)
                    .join(' - ')
                )}
              </FormField>
              <FormField title={getServiceOfficeFieldName('licenseNumber')} inline>
                {dataOrDash(escrowOfficeLead?.serviceOffice?.licenseNumber)}
              </FormField>
              <FormField title={getEscrowOfficeLeadFieldName('orderIdentifier')} inline>
                {dataOrDash(escrowOfficeLead?.orderIdentifier)}
              </FormField>
              <FormField title={getEscrowOfficeLeadFieldName('orderNumber')} inline>
                {escrowOfficeLead?.orderIdentifier ? (
                  <ExternalLink href={getQualiaUrl(escrowOfficeLead?.orderIdentifier, state)}>
                    {dataOrDash(escrowOfficeLead?.orderNumber)}
                  </ExternalLink>
                ) : (
                  'N/A'
                )}
              </FormField>
              <FormField title={getEscrowOfficeLeadFieldName('startedAsPreEscrow')} inline>
                {escrowOfficeLead?.startedAsPreEscrow ? 'Yes' : 'No'}
              </FormField>
              <FormField title={getEscrowOfficeLeadFieldName('transactionType')} inline>
                {dataOrDash(escrowOfficeLead?.escrowOrder?.transactionType)}
              </FormField>
            </FormFieldSet>
          </Section>
          <Section>
            <FormField title={getEscrowOfficeLeadFieldName('orderStatus')} inline>
              {formatOrDash(escrowOfficeLead?.orderStatus, titleize)}
            </FormField>
            <FormField title={getEscrowOfficeLeadFieldName('orderOpenAt')} inline>
              {formatOrDash(escrowOfficeLead?.orderOpenAt, formatDate)}
            </FormField>
            <FormField title={getEscrowOfficeLeadFieldName('orderClosingOn')} inline>
              {formatOrDash(escrowOfficeLead?.orderClosingOn, formatDate)}
            </FormField>
            <FormField title={getEscrowOfficeLeadFieldName('netEquity')} inline>
              {formatOrDash(escrowOfficeLead?.netEquity, formatPrice)}
            </FormField>
            <FormField title={getEscrowOfficeLeadFieldName('orderLoanAmount')} inline>
              {formatOrDash(escrowOfficeLead?.loanAmount, formatPrice)}
            </FormField>
          </Section>
        </Sections>
      </SubSection>

      <SubSection title="Settlement Team Members">
        <Sections>
          <Section>
            <EscrowOrderSettlementTeamMemberTable
              escrowOrderSettlementTeamMembers={escrowOfficeLead?.escrowOrderSettlementTeamMembers}
            />
          </Section>
        </Sections>
      </SubSection>
    </Section>
  )
}
