// @ts-strict
import { useEffect } from 'react'
import { Contents } from 'components/Contents/index'
import { LeadGroupableDefinitionsSection } from 'content/LeadGroupableDefinition'
import { useLeadGroupableContext } from 'contexts'
import { useDocumentTitle } from 'hooks'
import { capitalize } from 'utils'

export const LeadGroupableDefinitionsTab = () => {
  const { setTitle } = useDocumentTitle()

  const { leadGroupable, leadGroupableType } = useLeadGroupableContext()

  useEffect(
    () => setTitle(`${leadGroupable?.name} - ${capitalize(leadGroupableType)}`),
    [leadGroupable, leadGroupableType, setTitle]
  )

  return (
    <Contents>
      <LeadGroupableDefinitionsSection />
    </Contents>
  )
}
