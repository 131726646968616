// @ts-strict

import { useFlags, useFlagsmith } from 'flagsmith/react'
import { camelCaseKeys } from 'utils'

export const useFeatureFlags = <F extends string = string, T extends string = string>(
  flags: F[],
  traits?: T[]
) => {
  const result = useFlags(flags, traits)
  return camelCaseKeys(result)
}

type TraitKey = 'transaction_team'
export const useTrait = () => {
  const flagsmith = useFlagsmith()

  const setTrait = (str: TraitKey, value: string | number | boolean) =>
    flagsmith.setTrait(str, value)

  const getTrait = (str: TraitKey) => flagsmith.getTrait(str)

  return {
    setTrait,
    getTrait
  }
}

export const useFlagsmithStatus = () => {
  // somehow the flagsmith SDK doesn't return the initialised flag when in the e2e environment
  // so we need to check if the environment is e2e and return true always
  // @ts-ignore
  const isCypress = !!window?.Cypress
  const { initialised, ...rest } = useFlagsmith()

  const flagsmithProperties: any = rest

  return {
    ...rest,
    initialised: initialised || isCypress,
    isLoading: flagsmithProperties?.isLoading
  }
}
