import { Option } from 'classes'
import {
  ApplyFiltersButton,
  CheckBoxArray,
  Column,
  Columns,
  Filters,
  MenuSection,
  Section,
  SectionFooter
} from 'components'
import { useNewFilters } from 'hooks'
import styles from './TeamMemberFilterButton.module.scss'

const booleanOptions = [
  { name: 'Yes', value: 'true' },
  { name: 'No', value: 'false' }
].map(option => new Option(option))

const leadTypeOptions = [
  { name: 'Buyers', value: 'worksWithBuyers' },
  { name: 'Sellers', value: 'worksWithSellers' }
].map(option => new Option(option))

type Params = {
  fallbackAgent: string
  leadRoutingMember: string
  leadType: string[]
  warmTransferMember: string
}

export const TeamMemberFilterButton = () => {
  const { newParams, remove, set } = useNewFilters<Params>()

  const toggleBoolean = (key: keyof Params, value: string[]) => {
    const [firstValue, secondValue] = value
    const currentValue = secondValue ?? firstValue

    if (newParams[key] !== currentValue) {
      remove(key)
    }

    set(key, currentValue)
  }

  return (
    <Filters>
      <Section className={styles.section}>
        <Columns>
          <Column>
            <MenuSection title="Lead Routing Member">
              <CheckBoxArray
                options={booleanOptions}
                value={newParams.leadRoutingMember}
                onChange={value => toggleBoolean('leadRoutingMember', value)}
              />
            </MenuSection>
            <MenuSection title="Lead Type">
              <CheckBoxArray
                options={leadTypeOptions}
                value={newParams.leadType}
                onChange={val => set('leadType', val)}
              />
            </MenuSection>
          </Column>
          <Column>
            <MenuSection title="Fallback Agent">
              <CheckBoxArray
                options={booleanOptions}
                value={newParams.fallbackAgent}
                onChange={val => toggleBoolean('fallbackAgent', val)}
              />
            </MenuSection>
            <MenuSection title="Warm Transfer Member">
              <CheckBoxArray
                options={booleanOptions}
                value={newParams.warmTransferMember}
                onChange={val => toggleBoolean('warmTransferMember', val)}
              />
            </MenuSection>
          </Column>
        </Columns>
        <SectionFooter>
          <ApplyFiltersButton newParams={newParams} />
        </SectionFooter>
      </Section>
    </Filters>
  )
}
