import { ModelField } from '../ModelField'
import { TAgentMatchingLeadFieldAttribute } from './agentMatchingLeadFieldsSettings'

export class AgentMatchingLeadField extends ModelField {
  attr!: TAgentMatchingLeadFieldAttribute

  constructor(attributes: AgentMatchingLeadField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
