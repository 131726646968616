import GoogleButton from 'react-google-button'
import { useSearchParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { Icon, Page } from 'components'
import { useTokenContext } from 'contexts'

export const SignInOAuthPage = () => {
  const [searchParams] = useSearchParams()
  const { signInWithGoogle } = useTokenContext()

  useEffectOnce(() => {
    if (searchParams.get('statusCode') === '401') {
      window.location.replace('/')
    }
  })

  return (
    <Page title="Sales App">
      <p>
        <Icon.Account style={{ verticalAlign: 'bottom' }} /> Welcome to the Sales App. Please{' '}
        <div style={{ marginTop: 15 }}>
          <GoogleButton onClick={() => signInWithGoogle()} />
        </div>
      </p>
    </Page>
  )
}
