import { getUserManagementUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export const postImpersonateEmployee = async (userId: string) => {
  const response = await postRequest<{ redirect_url?: string }>(
    getUserManagementUrl({ action: 'impersonate-user' }),
    { impersonated_user_id: userId }
  )

  return {
    data: response.data
  }
}
