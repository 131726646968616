// @ts-strict

import { ButtonForm, EmployeesAutoComplete, FormField, Select } from 'components'
import { TransactionTeam } from 'models'
import { useTeamAddEmployeeButtonForm } from './useTeamAddEmployeeButtonForm'

type TeamAddEmployeeButtonFormProps = {
  team: TransactionTeam
}

export const TeamAddEmployeeButtonForm = ({ team }: TeamAddEmployeeButtonFormProps) => {
  const { formProps, payload, onChangeAttribute, teamRolesOptions } =
    useTeamAddEmployeeButtonForm(team)

  return (
    <ButtonForm title="Add Team Member" buttonText="Add Member" {...formProps}>
      <FormField title="Team">{team.name}</FormField>
      <FormField title="HomeLight Employee" htmlFor="employee" required>
        <EmployeesAutoComplete
          id="employee"
          value={payload.userName}
          onSelect={user => {
            onChangeAttribute('userId', user.id)
            onChangeAttribute('userName', user.title)
          }}
          onClear={() => {
            onChangeAttribute('userId', undefined)
            onChangeAttribute('userName', undefined)
          }}
          autoFocus
        />
      </FormField>
      <FormField title="Role" htmlFor="role" required>
        <Select
          id="role"
          onChange={role => onChangeAttribute('role', role)}
          name="role"
          options={teamRolesOptions}
          value={payload.role}
          data-testid="role-field"
          addBlank
        />
      </FormField>
    </ButtonForm>
  )
}
