import { ReactNode, useState } from 'react'

let toastId = 0

export type Toast = {
  description?: string | ReactNode
  duration?: typeof Infinity | 'default'
  id: number
  onOpenChange?: (arg: boolean) => void
  status: 'success' | 'error' | 'warning' | 'info'
  title: string
}

export const useToast = () => {
  const [toasts, setToasts] = useState<Toast[]>([])

  const toast = (newToast: Omit<Toast, 'id'>) => {
    setToasts(prev => [...prev, { id: toastId++, ...newToast }])
  }

  return { toast, toasts }
}
