// @ts-strict
import { Block, TableCell as Td, TableRow as Tr } from 'components'
import { Agent, MarketplaceProgram } from 'models'
import { cx, titleize } from 'utils'
import { getAgentProgramEligibility } from '../utils'
import styles from './AgentProgramEligibilityTable.module.scss'

type Props = {
  agent: Agent
  program: MarketplaceProgram
}

export const AgentProgramEligibilityRow = ({ agent, program }: Props) => {
  const validation = getAgentProgramEligibility(agent, program)

  const notEligibleStyle = !validation.isEligible && styles.notEligible

  return (
    <Tr className={styles.tr} data-testid={`${program.slug}-row`}>
      <Td>
        <Block title={program.name} />
      </Td>
      <Td className={cx(styles.eligibilityRow, notEligibleStyle)}>
        <Block
          title={validation.validationMsg}
          subtitle={titleize(validation.selectedProgramStatus)}
        />
      </Td>
    </Tr>
  )
}
