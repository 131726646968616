import { LEADS_CACHE_KEY, ORDER_CACHE_KEY, ORDERS_CACHE_KEY } from 'controllers'
import { MORTGAGE_LEAD_CACHE_KEY } from 'hooks'
import { useMutation, useQueryClient } from 'hooks'
import { TMortgageLeadFailedReason, TMortgageLeadStagesSlug } from 'lookups'
import { putLeadDisposition } from 'services/Lead'

export type TMortgageLeadDispositionPayload = {
  disposition: TMortgageLeadStagesSlug | string
  occurredAt: string
  reasonForFail?: TMortgageLeadFailedReason
}

export const useMortgageLeadController = (leadId: string) => {
  const queryCache = useQueryClient()

  const clearCaches = () => {
    queryCache.invalidateQueries([LEADS_CACHE_KEY, MORTGAGE_LEAD_CACHE_KEY, leadId])
    queryCache.invalidateQueries(ORDER_CACHE_KEY)
    queryCache.invalidateQueries(ORDERS_CACHE_KEY)
  }

  const defaultCallback = {
    onSuccess: clearCaches
  }

  const updateMortgageLeadDisposition = useMutation(
    (payload: TMortgageLeadDispositionPayload) => putLeadDisposition({ leadId, payload }),
    defaultCallback
  )

  return {
    updateMortgageLeadDisposition
  }
}
