import { DEFAULT_STRING } from '../constants'

const DEFAULT_MAPPING = { true: 'Yes', false: 'No' }

export const formatBoolean = (
  value?: boolean,
  mapping: Record<string, any> = DEFAULT_MAPPING,
  defaultValue: any = DEFAULT_STRING
) => {
  if (value === undefined) {
    return
  }
  return mapping[String(value)] || defaultValue
}
