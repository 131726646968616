import { Option } from 'classes'
import { titleize } from 'utils'
import { SearchFilter } from '..'
import { TSearchFilterSetting } from '../types'

type TLoaderDisplayValueFn = (val: string) => Potential<string>

type TBuildSearchFilterOptions<TSetting extends TSearchFilterSetting> = {
  displayValueFnLoaders?: PartialRecord<
    NonNullable<TSetting['getDisplayValueFnName']>,
    TLoaderDisplayValueFn
  >
  optionLoaders?: PartialRecord<NonNullable<TSetting['optionsKey']>, Option[]>
}

export const buildSearchFilters = <TSetting extends TSearchFilterSetting>(
  settings: TSetting[],
  { optionLoaders, displayValueFnLoaders }: TBuildSearchFilterOptions<TSetting> = {}
) => {
  const buildOptions = (setting: TSetting): Option[] => {
    if (optionLoaders && setting.optionsKey) {
      const customOptions: Potential<Option[]> = optionLoaders[setting.optionsKey]

      if (customOptions) {
        return customOptions
      }
    }

    // default options
    return setting.options || []
  }

  const buildDefaultDisplayValue = (setting: TSetting) => (value: string) =>
    buildOptions(setting).find(el => el.value === value)?.name || titleize(value)

  const buildDisplayValue = (setting: TSetting) => {
    if (displayValueFnLoaders && setting.getDisplayValueFnName) {
      const displayValueFnName: Potential<TLoaderDisplayValueFn> =
        displayValueFnLoaders[setting.getDisplayValueFnName]
      if (displayValueFnName) {
        return displayValueFnName
      }
    }

    return buildDefaultDisplayValue(setting)
  }

  const buildFilter = (setting: TSetting): SearchFilter<TSetting> =>
    new SearchFilter<TSetting>({
      ...setting,
      options: buildOptions(setting),
      getDisplayValue: buildDisplayValue(setting)
    })

  return settings.map(buildFilter)
}
