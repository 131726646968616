import { createContext, useContext } from 'react'
import { useParamsContext } from 'contexts/Params'
import { AgentLeadsContextType } from 'contexts/types'
import { useAgentLeadsController } from 'controllers'
import { useLocation } from 'hooks'

export const AgentLeadsContext = createContext<AgentLeadsContextType | null>(null)
export const useAgentLeadsContext = () => useContext(AgentLeadsContext)

export const AgentLeadsProvider = ({ children, rootPath, agentId }) => {
  const { pathname } = useLocation()
  const { params } = useParamsContext()

  return (
    <AgentLeadsContext.Provider
      value={useAgentLeadsController({
        agentId: agentId,
        searchParams: params,
        enabled: pathname === rootPath
      })}
    >
      {children}
    </AgentLeadsContext.Provider>
  )
}
