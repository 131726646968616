import { sorts } from 'utils'
import { getOptions } from '../utils'
import {
  cashOfferFailedReasonsSettings,
  ccCashOfferFailedReasonsSettings,
  hlSimpleSaleFailedReasonsSettings,
  mortgageFailedReasonsSettings,
  tradeInFailedReasonsSettings
} from './failedReasonSettings'
import { FailedReason } from '.'

export const useFailedReasons = () => {
  const tradeInFailedReasons = tradeInFailedReasonsSettings
    .sort(sorts.name)
    .map(FailedReason.create)
  const cashOfferFailedReasons = cashOfferFailedReasonsSettings
    .sort(sorts.name)
    .map(FailedReason.create)
  const ccCashOfferFailedReasons = ccCashOfferFailedReasonsSettings
    .sort(sorts.name)
    .map(FailedReason.create)
  const mortgageFailedReasons = mortgageFailedReasonsSettings
    .sort(sorts.name)
    .map(FailedReason.create)
  const hlSimpleSaleFailedReasons = hlSimpleSaleFailedReasonsSettings
    .sort(sorts.name)
    .map(FailedReason.create)

  const { options: tradeInFailedReasonOptions } = getOptions(tradeInFailedReasons as FailedReason[])
  const { options: cashOfferFailedReasonOptions } = getOptions(
    cashOfferFailedReasons as FailedReason[]
  )
  const { options: ccCashOfferFailedReasonOptions } = getOptions(
    ccCashOfferFailedReasons as FailedReason[]
  )
  const { options: mortgageFailedReasonOptions } = getOptions(
    mortgageFailedReasons as FailedReason[]
  )

  const { options: hlSimpleSaleFailedReasonOptions } = getOptions(
    hlSimpleSaleFailedReasons as FailedReason[]
  )

  return {
    tradeInFailedReasons,
    tradeInFailedReasonOptions,
    cashOfferFailedReasons,
    cashOfferFailedReasonOptions,
    ccCashOfferFailedReasons,
    ccCashOfferFailedReasonOptions,
    mortgageFailedReasonOptions,
    hlSimpleSaleFailedReasons,
    hlSimpleSaleFailedReasonOptions
  }
}
