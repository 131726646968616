import { toSnakeCase } from 'utils'
import { TPrograms } from '../types'

type TProgramsKeys = keyof TPrograms

const getCustomProgramKey = (program: TProgramsKeys): string => toSnakeCase(program)

export const getAgentProgramsFilter = (programs: TPrograms) => {
  const filters = {}

  let key: keyof TPrograms
  for (key in programs) {
    const prop = getCustomProgramKey(key)

    if (programs[key] === 'pending') {
      filters[`filter[marketplace_programs.pending_${prop}]`] = true
    } else if (programs[key] === 'enrolled') {
      filters[`filter[marketplace_programs.enrolled_${prop}]`] = true
    } else if (programs[key] === 'declined') {
      filters[`filter[marketplace_programs.declined_${prop}]`] = true
    } else if (programs[key] === 'unenrolled') {
      filters[`filter[marketplace_programs.enrolled_${prop}]`] = 'false'
      filters[`filter[marketplace_programs.declined_${prop}]`] = 'false'
    }
  }

  return filters
}
