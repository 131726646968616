import { useLocation } from 'react-router-dom'
import { PaginationParams, SearchConsole, SearchInputParams } from 'components'
import { ListProvider } from 'contexts'
import { useUsersSearchFilter } from 'lookups'
import { queryStringToObject } from 'utils'
import { useFetchListUsers } from '../hooks'
import { ListUsersFilters } from './ListUsersFilters'
import { ListUsersListStatus } from './ListUsersListStatus'
import { ListUsersTable } from './ListUsersTable'

export const ListUsers = () => {
  const { search } = useLocation()
  const searchParams = queryStringToObject(search)
  const { data, isLoading } = useFetchListUsers({ searchParams })
  const filters = useUsersSearchFilter()

  return (
    <ListProvider filters={filters}>
      <ListUsersListStatus />
      <SearchConsole
        search={<SearchInputParams placeholder="Search by name or email" />}
        actions={<ListUsersFilters />}
      />
      <ListUsersTable users={data?.items} isLoading={isLoading} />
      <PaginationParams totalPages={data?.pageCount} />
    </ListProvider>
  )
}
