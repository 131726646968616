import { AgentProviderLead } from 'models'
import { getHapiRequestParams, ProviderLeadSchema } from 'schema'
import { agentResponseForAgentMatchingLeads } from 'services'
import { getLeadProviderLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const providerLeadsShape: ProviderLeadSchema = {
  providerLead: [
    {
      'providable:agentLead': [
        'rank',
        {
          agentLeadStageUpdates: [
            'createdAt',
            'newStage',
            'notes',
            'oldStage',
            'updatedBy',
            { ae: ['name'] }
          ]
        },
        {
          providerLead: [
            'providableType',
            'furthestStage',
            'introSentAt',
            'stage',
            'rank',
            { lead: [] },
            {
              servicesOpportunities: [
                'productType',
                { 'status:servicesOpportunityStatus': ['name', 'slug'] }
              ]
            }
          ]
        },
        {
          'workedByAgent:agent': ['firstName', 'lastName']
        },
        agentResponseForAgentMatchingLeads
      ]
    }
  ]
}

export const fetchLeadProviderLeads = async (leadId: string, providable_filter: string) => {
  const url = getLeadProviderLeadsUrl({
    id: leadId,
    action: 'provider-leads',
    searchParams: {
      ...getHapiRequestParams(providerLeadsShape),
      'filter[providable_type]': providable_filter
    }
  })

  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: AgentProviderLead.create })
}
