import { Badge } from 'components/Badge'
import { cx } from 'utils'
import styles from './Tier.module.scss'

type TierProps = {
  tier: 0 | 1 | 2 | 3
}

export const Tier = ({ tier }: TierProps) => {
  if (tier === 0) {
    return null
  }

  return (
    <Badge title={`Tier ${tier}`} className={cx(styles[`t${tier}`], styles.tier)}>
      T{tier}
    </Badge>
  )
}
