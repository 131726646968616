// @ts-strict

import { Option } from 'classes'
import { TUsStateCode } from 'lookups'
import { IOptionable, TMarketplaceProgramSlug } from 'lookups'
import { marketplaceProgramSettings } from 'lookups'
import { MarketplaceProgramResponse } from 'schema'
import { UsState } from '../UsState'

type TMarketplaceProgramConstructor = Omit<MarketplaceProgram, 'toOption' | 'isStateEligible'>

export class MarketplaceProgram implements IOptionable {
  abbr?: string
  agentTeamRequired?: boolean = false
  eligibleForPitchStatus?: boolean
  id?: string
  name?: string
  slug?: TMarketplaceProgramSlug
  states?: UsState[]
  transactionTeamRequired?: boolean = false

  constructor(marketplaceProgramAttributes: TMarketplaceProgramConstructor) {
    Object.assign(this, marketplaceProgramAttributes)
  }
  static create(
    marketplaceProgramResponse: Potential<MarketplaceProgramResponse>
  ): Potential<MarketplaceProgram> {
    if (!marketplaceProgramResponse) {
      return
    }
    const mpp = marketplaceProgramSettings.find(m => m.slug === marketplaceProgramResponse.slug)

    const states = (marketplaceProgramResponse.states?.map(UsState.create).filter(Boolean) ||
      []) as UsState[]

    return new MarketplaceProgram({ ...mpp, states, id: marketplaceProgramResponse.id })
  }

  isStateEligible(stateCode: Nullable<TUsStateCode>): boolean {
    if (!stateCode || !this.states) {
      return false
    }

    return this.states.map(state => state.code).includes(stateCode)
  }

  toOption() {
    return new Option({ name: this.name, value: this.slug })
  }
}
