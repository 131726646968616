export const USER_TYPES = Object.freeze({
  BUYER: 'buyer',
  SELLER: 'seller'
})

export const PLACE_TYPES = Object.freeze({
  ZIP_CODE: 'postcode',
  PLACE: 'place'
})

export const IS_ZIP_CODE = (location: string) => /^\d+$/.test(location)

export const MINIMUM_PPL_MONTHLY_CAP = 200
export const MINIMUM_PPL_SETTING_PRICE = 15
export const MAXIMUM_PPL_SETTING_PRICE = 300
