// @ts-strict
import { useMutation, useQueryClient } from 'react-query'
import { EditExternalLinkVariables } from 'contexts/types'
import { deleteExternalLink, putExternalLink } from 'services'
import { EXTERNAL_LINKS_CACHE_KEY } from '../cache'

export const useExternalLinkController = (externalLinkId: string) => {
  const queryCache = useQueryClient()

  const defaultCallback = {
    onSuccess: () => {
      queryCache.invalidateQueries(EXTERNAL_LINKS_CACHE_KEY)
    }
  }

  const editExternalLink = useMutation(
    (payload: EditExternalLinkVariables) => putExternalLink(externalLinkId, payload),
    defaultCallback
  )

  const removeExternalLink = useMutation(() => deleteExternalLink(externalLinkId), defaultCallback)

  return {
    editExternalLink,
    removeExternalLink
  }
}
