// @ts-strict

import { Column, useSearchFilters } from 'lookups'
import { TGeneratedColumnsLookup } from 'lookups'
import { presence } from 'utils'
import { useParamsContext } from '../Params'
import { ListProviderProps } from './ListProvider'

type TArgs = {
  columns: TGeneratedColumnsLookup
}

export const useList = <T extends Record<string, any> = Record<string, any>>({
  columns = {
    columns: [],
    isFixedColumn: () => true,
    isDefaultColumn: () => true,
    getColumnName: () => undefined,
    getColumnTitle: () => undefined,
    getColumnAlignment: () => undefined,
    getColumnSize: () => undefined,
    getSortableAttributes: () => undefined,
    findColumn: () => ({} as Column)
  },
  filters = {},
  actions,
  highlightRows
}: Omit<ListProviderProps<T>, 'children' | 'columns'> & TArgs) => {
  const defaultFilters = useSearchFilters()

  const { isSelectedColumn, viewColumns } = useParamsContext()
  const { isFixedColumn, isDefaultColumn, getColumnName } = columns

  const showColumn = (columnName: string) => {
    if (presence(viewColumns)) {
      return isSelectedColumn(columnName) || isFixedColumn(columnName)
    }
    return isDefaultColumn(columnName) || isFixedColumn(columnName)
  }
  const filteredViewColumns = viewColumns.filter(column =>
    columns.columns.find(c => column === c.value)
  )
  const visibleColumns = presence(filteredViewColumns)
    ? filteredViewColumns
    : (columns.columns.map(column => column.isDefault && column.value).filter(Boolean) as string[])

  const colSpan = visibleColumns.length

  return {
    ...columns,
    showColumn,
    getColumnName,
    colSpan,
    ...defaultFilters,
    ...filters,
    actions,
    highlightRows,
    visibleColumns
  }
}
