// @ts-strict
import { Button } from '@foundation/components'
import { ButtonForm, useButtonForm } from 'components'
import { useFileUploadsController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import { Lead } from 'models'
import { TFormValidation, validatePresence } from 'utils/validations'
import { LeadFileUploadsButtonFields } from './LeadFileUploadsButtonFields'

type TLeadFileUploadsButtonFormProps = {
  lead?: Lead
}

export type TLeadFileUploadsPayload = {
  category?: string
  files: File[]
  name: Potential<string>
  subAttachableId: Potential<string>
  subAttachableType: Potential<string>
}

export const LeadFileUploadsButtonForm = ({ lead }: TLeadFileUploadsButtonFormProps) => {
  const { uploadFile } = useFileUploadsController({ leadId: lead?.id || '' })
  const { filesPermissioning } = useFeatureFlags(['files-permissioning'])

  const initialPayload: TLeadFileUploadsPayload = {
    files: [],
    name: '',
    subAttachableId: undefined,
    subAttachableType: undefined,
    category: filesPermissioning.enabled ? 'other' : undefined
  }

  const formValidations: TFormValidation = {
    name: { label: 'Name', validations: [validatePresence] },
    files: { label: 'Document', validations: [validatePresence] }
  }

  const { formProps, fieldsProps } = useButtonForm<TLeadFileUploadsPayload>({
    initialPayload,
    formValidations,
    mutation: uploadFile
  })

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={formProps.toggleShowForm}>
        Upload File
      </Button>
      <ButtonForm buttonText="Upload File" title="Upload File" hideTriggerButton {...formProps}>
        <LeadFileUploadsButtonFields lead={lead} {...fieldsProps} />
      </ButtonForm>
    </>
  )
}
