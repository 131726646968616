import { CONSTANTS_CACHE_KEY, TASK_CATEGORIES_CACHE_KEY, useQuery } from 'hooks'
import { fetchTaskCategories } from 'services'
import { sorts } from 'utils'
import { getOptions } from '../utils'

export const useTaskCategories = () => {
  const { data: taskCategories = [] } = useQuery(
    [CONSTANTS_CACHE_KEY, TASK_CATEGORIES_CACHE_KEY],
    fetchTaskCategories,
    { staleTime: Infinity }
  )

  const { options: taskCategoryOptions, getOptionName: getTaskCategoryName } =
    getOptions(taskCategories)

  return {
    getTaskCategoryName,
    taskCategories,
    taskCategoryOptions: taskCategoryOptions.sort(sorts.name)
  }
}
