import { useState } from 'react'
import { Popover } from '@foundation/components'
import theme from '@foundation/themes'
import { Ellipsis } from 'components'
import { useFeatureFlags } from 'hooks'
import { Agent } from 'models'
import { AgentLink } from '../AgentLink'
import { AgentDetailsMenuCard } from './AgentDetailsMenuCard'
import styles from './AgentLinkWithMenuCard.module.scss'

export const AgentLinkWithMenuCard = ({ agent }: { agent: Agent }) => {
  const [isHoverEnabled, setIsHoverEnabled] = useState(true)
  const { salesAppAgentDetailsComponent } = useFeatureFlags(['sales-app-agent-details-component'])

  if (!salesAppAgentDetailsComponent.enabled) {
    return (
      <span className={styles.wrapper}>
        <Ellipsis>
          <AgentLink agent={agent} />
        </Ellipsis>
      </span>
    )
  }

  return (
    <Popover.Root placement="bottom-start" enterDelay="longest" isHoverEnabled={isHoverEnabled}>
      <Popover.Trigger>
        <span>
          <Ellipsis>
            <AgentLink agent={agent} />
          </Ellipsis>
        </span>
      </Popover.Trigger>
      <Popover.Content zIndex={theme.zIndices.overlay - 1}>
        <AgentDetailsMenuCard
          agentId={agent.id}
          onOpenChangeModal={value => setIsHoverEnabled(!value)}
        />
      </Popover.Content>
    </Popover.Root>
  )
}
