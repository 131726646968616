import React, { ReactNode } from 'react'
import { Tab } from 'components'
import styles from './Tabs.module.scss'

type TabNavProps = {
  children: ReactNode
  parent?: boolean
  to?: string
}

export const TabNav = ({ to = '', parent = false, children }: TabNavProps) => {
  const { pathname } = window.location
  const toPathName = to.split('?')[0]
  const selected = parent ? pathname.startsWith(to) : pathname === toPathName
  return (
    <Tab className={selected ? styles.selected : null} to={to}>
      {children}
    </Tab>
  )
}
