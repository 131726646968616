import { ReactNode, useEffect, useState } from 'react'
import { Button, ButtonTypes } from 'components/Button/index'
import { TextInput } from 'components/Form'
import { Icon } from 'components/Icon/index'
import { Activity } from 'models'
import { cx } from 'utils'
import { LoadingText } from '../../../components/LoadingText/LoadingText'
import styles from './ActivitiesList.module.scss'
import { ActivityListItem } from './ActivityListItem'

type ActivityListProps = {
  activities: Array<Activity>
  children?: ReactNode
  className?: string
  isLoading?: boolean
  title?: string
}

export const ActivityList = ({
  children,
  title,
  activities = [],
  className,
  isLoading,
  ...props
}: ActivityListProps) => {
  const minCount = 6
  const [minimized, setMinimized] = useState(activities.length > minCount)
  const [searchTerm, setSearchTerm] = useState('')

  const toggleMinimized = () => {
    setMinimized(!minimized)
  }

  const handleSearchTermChange = val => {
    setMinimized(false)
    setSearchTerm(val.toLowerCase())
  }

  const showAllProps = {
    as: 'link' as ButtonTypes,
    onClick: toggleMinimized,
    className: styles.viewAll,
    'data-testid': 'show-all'
  }

  const viewAllButton =
    activities.length > minCount && !searchTerm.length ? (
      <Button {...showAllProps}>
        {minimized ? (
          <>
            Show All Activities ({activities.length - minCount} more) <Icon.DownArrow />
          </>
        ) : (
          <>
            Show Less <Icon.UpArrow />
          </>
        )}
      </Button>
    ) : null

  const visibleActivities = minimized
    ? activities.slice(0, minCount)
    : activities.filter(
        activity =>
          (activity.body && activity.body.toLowerCase().includes(searchTerm)) ||
          (activity.title && activity.title.toLowerCase().includes(searchTerm))
      )

  useEffect(() => {
    if (activities && activities.length) {
      setMinimized(activities.length > minCount)
    }
  }, [activities])

  if (isLoading) {
    return <LoadingText />
  }

  const textInputProps = {
    placeholder: 'Search Activities',
    onChange: handleSearchTermChange,
    className: styles.search,
    value: searchTerm
  }

  return (
    <>
      {activities.length > minCount ? <TextInput {...textInputProps} /> : null}
      <ul className={cx(styles.list, className)} {...props}>
        {title ? <h4 className={styles.title}>{title}</h4> : null}
        {visibleActivities.map(activity => (
          <ActivityListItem activity={activity} key={activity.id} />
        ))}
        {children}
      </ul>
      {viewAllButton}
    </>
  )
}
