// @ts-strict
import { useEffect } from 'react'
import { Contents } from 'components'
import { TeamAddEmployeeButtonForm } from 'content/TransactionTeam'
import { TransactionTeamMembersList } from 'content/TransactionTeamMembers'
import { useTeamContext } from 'contexts'
import { SubTeamAddEmployeeButtonForm } from 'features/settlementAgency/SubTeamAddEmployeeButtonForm'
import { SettlementAgencyTeamMembersList } from 'features/settlementAgency/listSettlementAgencyTeamMembers'
import { useDocumentTitle } from 'hooks'
import styles from './MembersTab.module.scss'

export const MembersTab = () => {
  const { team } = useTeamContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${team?.name} - Team Members`), [team, setTitle])

  if (!team) {
    return null
  }
  return (
    <>
      <Contents
        actions={
          <div className={styles.membersActions}>
            <TeamAddEmployeeButtonForm team={team} />
            {team.type === 'SettlementAgencyTeam' && <SubTeamAddEmployeeButtonForm team={team} />}
          </div>
        }
        title="Members"
      >
        {team.type === 'SettlementAgencyTeam' ? (
          <SettlementAgencyTeamMembersList members={team.teamMemberships} />
        ) : (
          <TransactionTeamMembersList members={team.teamMemberships} />
        )}
      </Contents>
    </>
  )
}
