// @ts-strict
import { Table, TableHeaderRow, TableResults, TableRow as Tr } from 'components'
import { TableHeaderColumn, useTable } from 'components/Table'
import { useListContext } from 'contexts'
import type { InspectionDeficiency } from 'models'
import { InspectionDeficiencyTableCell } from './InspectionDeficiencyTableCell'

type Props = {
  isLoading?: boolean
  items: Potential<InspectionDeficiency[]>
}

export const InspectionDeficiencyTable = ({ items, isLoading }: Props) => {
  const { visibleColumns, colSpan } = useListContext()
  const { getBodyCellProps, getHeaderCellProps } = useTable()

  return (
    <Table alternatingColumns data-testid="inspection-deficiency-table">
      <TableHeaderRow>
        {visibleColumns.map(columnKey => (
          <TableHeaderColumn key={columnKey} {...getHeaderCellProps(columnKey)} />
        ))}
      </TableHeaderRow>
      <TableResults type="Deficiencies" colSpan={colSpan} isLoading={isLoading}>
        {items?.map(item => (
          <Tr key={item.id}>
            {visibleColumns.map(columnKey => (
              <InspectionDeficiencyTableCell
                key={columnKey}
                {...getBodyCellProps(item, columnKey)}
              />
            ))}
          </Tr>
        ))}
      </TableResults>
    </Table>
  )
}
