import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './SearchConsole.module.scss'

type SearchConsoleProps = {
  actions?: ReactNode
  className?: string
  moreFilters?: ReactNode
  search?: ReactNode
}

export const SearchConsole = ({ search, actions, className, ...props }: SearchConsoleProps) => {
  return (
    <div data-testid="search-console" className={cx(styles.root, className)} {...props}>
      {search && <div className={styles.search}>{search}</div>}
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  )
}
