// @ts-strict
import { TLeadUserTypeServerSlug } from 'lookups'
import {
  CCTradeInLead,
  EscrowLead,
  isCCCashOfferLead,
  isCCTradeInLead,
  isEscrowLead,
  isMortgageLead,
  Lead,
  MortgageLead,
  Property
} from 'models'
import { OrderResponse } from 'schema'
import { formatOrDash, titleize } from 'utils'
import { last } from 'utils'
import { Model } from '../Model'
import { getClientName } from './utils/getClientName'
import { getLeads } from './utils/getLeads'
import { getPartnerName } from './utils/getPartnerName'
import { getProduct } from './utils/getProduct'
import { getProperties } from './utils/getProperties'

type TOrderConstructor = Omit<
  Order,
  | keyof Model
  | 'hasCCTradeInLead'
  | 'hasCCCashOfferLead'
  | 'getIneligibleLeadUserTypeSlugs'
  | 'pathname'
  | 'title'
>

export class Order extends Model {
  clientName?: string
  closeOfEscrowOnHLHL?: string
  createdAt?: string
  escrowLead?: EscrowLead
  id!: string
  leads?: Lead[]
  loanApplicationId?: string
  lenderSlug?: string
  mortgageLead?: MortgageLead
  ownedBusinessUnit?: string
  partnerName?: string
  product?: TLeadUserTypeServerSlug
  properties?: Property[]
  source?: string
  stage?: string
  ccTradeInLead?: CCTradeInLead
  updatedAt?: string
  pathname: string
  title: string

  constructor(attributes: TOrderConstructor) {
    super()
    Object.assign(this, attributes)
    this.pathname = `/orders/${attributes.id}`

    const orderType =
      attributes?.ownedBusinessUnit === 'cash_close' ? 'homes' : attributes?.ownedBusinessUnit

    this.title = `${formatOrDash(orderType, titleize)} (${formatOrDash(
      attributes?.source,
      titleize
    )})`
  }

  hasCCTradeInLead(): boolean {
    return !!this.leads?.some(isCCTradeInLead)
  }

  hasCCCashOfferLead(): boolean {
    return !!this.leads?.some(isCCCashOfferLead)
  }

  getIneligibleLeadUserTypeSlugs(): TLeadUserTypeServerSlug[] {
    return [
      this.hasCCCashOfferLead() && 'cc_cash_offer',
      this.hasCCTradeInLead() && 'cc_trade_in'
    ].filter(Boolean) as TLeadUserTypeServerSlug[]
  }

  static create(orderResponse: Potential<OrderResponse>): Potential<Order> {
    if (!orderResponse) {
      return
    }
    const leads = getLeads(orderResponse)
    const clientName = getClientName(orderResponse)
    const partnerName = getPartnerName(orderResponse)
    const properties = getProperties(leads)
    const product = getProduct(orderResponse)

    const escrowLead = leads.find(isEscrowLead) as Potential<EscrowLead>
    const mortgageLead: Potential<MortgageLead> = leads.find(isMortgageLead)
    const ccTradeInLead: Potential<CCTradeInLead> = leads.find(isCCTradeInLead)

    const lastLenderProviderLead = last(mortgageLead?.lenderProviderLeads)
    const closeOfEscrowOnHLHL = lastLenderProviderLead?.lenderLead?.closeOfEscrowOn
    const loanApplicationId = lastLenderProviderLead?.lenderLead?.eaveLoanApplicationId
    const lenderSlug = lastLenderProviderLead?.lenderLead?.lender?.slug

    return new Order({
      ...orderResponse,
      clientName,
      closeOfEscrowOnHLHL,
      escrowLead,
      leads,
      loanApplicationId,
      lenderSlug,
      mortgageLead,
      partnerName,
      properties,
      product,
      ccTradeInLead
    })
  }
}
