// @ts-strict

import { Flex } from '@foundation/components/Flex/styles'
import { Block, Ellipsis, ExternalLink, FormField, List, Section, StageBadge } from 'components'
import { ContactsLeadNavigationMobile, LeadEditContactInfoButtonForm } from 'content/Lead'
import { environment } from 'environment'
import { useFeatureFlags, useFlexUi, useScreenSize } from 'hooks'
import { isAgentMatchingLead, Lead } from 'models'
import { isCCBBYSLead } from 'models'
import { getBBYSStage } from 'modules/bbys/helpers'
import { capitalizeWords, dataOrDash, titleize, toSearchParam } from 'utils'
import styles from './LeadSummary.module.scss'
import { LeadSummaryActionBar } from './LeadSummaryActionBar'

export const LeadSummary = ({ lead }: { lead: Lead }) => {
  const { salesOpsReferralLeadUpdates } = useFeatureFlags(['sales-ops-referral-lead-updates'])
  const isNewReferralLead = isAgentMatchingLead(lead) && salesOpsReferralLeadUpdates.enabled
  const { isFlexUi } = useFlexUi()
  const { isMobile } = useScreenSize()

  const actions = !isFlexUi && <LeadEditContactInfoButtonForm lead={lead} />

  return (
    <>
      {isCCBBYSLead(lead) && (
        <Section data-testid="lead-summary">
          <Block
            title={
              isMobile ? (
                <Flex justifyContent="space-between" alignItems="flex-start">
                  <Flex flexDirection="column" alignItems="flex-start" gap="$3">
                    <p className={styles.wrapped}>{dataOrDash(lead.name)}</p>
                    <StageBadge stage={getBBYSStage(lead.stage)} />
                  </Flex>
                  <ContactsLeadNavigationMobile lead={lead} />
                </Flex>
              ) : (
                <Flex justifyContent="space-between" flexWrap="wrap">
                  <p className={styles.wrapped}>{dataOrDash(lead.name)}</p>
                  <StageBadge stage={getBBYSStage(lead.stage)} />
                </Flex>
              )
            }
            className={styles.header}
            subtitle={!isFlexUi && lead.fullAddress ? capitalizeWords(lead.fullAddress) : ''}
          />
        </Section>
      )}

      {isCCBBYSLead(lead) && (
        <Section data-testid="doc-center-link">
          <Flex justifyContent="space-between" alignItems="center">
            <p>Links</p>
            {lead.bbysLead?.documentCenterLink ? (
              <p style={{ fontWeight: '700' }}>
                <ExternalLink href={lead.bbysLead?.documentCenterLink}>
                  Transaction Page
                </ExternalLink>
              </p>
            ) : (
              <p>Unavailable</p>
            )}
          </Flex>
        </Section>
      )}

      {!isCCBBYSLead(lead) && (
        <Section data-testid="lead-summary">
          <Block
            title={dataOrDash(lead.name)}
            subtitle={!isFlexUi && lead.partnerName}
            className={styles.header}
            actions={actions}
          />

          <LeadSummaryActionBar lead={lead} />

          {!isNewReferralLead && !isFlexUi && (
            <FormField title="Email" inline>
              <Ellipsis>{lead.email}</Ellipsis>
            </FormField>
          )}
          {!isNewReferralLead && !isFlexUi && (
            <FormField title="Email Alt" inline>
              <Ellipsis>{dataOrDash(lead.emailAlt)}</Ellipsis>
            </FormField>
          )}
          {!isNewReferralLead && !isFlexUi && (
            <FormField title="Phone" inline>
              {dataOrDash(lead.phoneNumbers?.[0]?.formattedNumber)}
            </FormField>
          )}
          {isAgentMatchingLead(lead) && !isFlexUi && (
            <>
              <FormField title="Links" inline>
                <ExternalLink href={`${environment.oldSalesLeadURL}${lead.id}`}>
                  Old Sales App
                </ExternalLink>
              </FormField>
              <FormField title="Google" inline>
                <List>
                  {lead.phoneNumbers?.map(pn => (
                    <ExternalLink
                      key={pn.phoneType}
                      href={`https://www.google.com/search?q=${toSearchParam(
                        `${lead.name} ${pn.formattedNumber}`
                      )}`}
                    >
                      Name & {titleize(pn.phoneType)}
                    </ExternalLink>
                  ))}

                  <ExternalLink
                    href={`https://www.google.com/search?q=${toSearchParam(
                      `${lead.name} ${lead.email}`
                    )}`}
                  >
                    Name & Email
                  </ExternalLink>
                  {lead.emailAlt && (
                    <ExternalLink
                      href={`https://www.google.com/search?q=${toSearchParam(
                        `${lead.name} ${lead.emailAlt}`
                      )}`}
                    >
                      Name & Alt Email
                    </ExternalLink>
                  )}
                </List>
              </FormField>
            </>
          )}
        </Section>
      )}
    </>
  )
}
