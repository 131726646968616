import { css, styled } from '@foundation/themes/styles'

export const ContentLeft = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.space.$6};
  `}
`

export const Collapse = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
  `}
`

export const NameWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: inherit;
  width: 14rem;
`

export const DescriptionWrapper = styled.div`
  max-width: 27rem;
`
