// @ts-strict
import { useContext, useMemo } from 'react'
import useLocalStorage from '@homelight/react-hook-uselocalstorage'
import { Link, useLocation } from 'react-router-dom'
import { Menu, MenuLink } from 'components'
import { MenuContext } from 'components/Menu/MenuContext'
import { useCurrentUserContext, useSavedSearchesContext } from 'contexts'
import { useFeatureFlags } from 'hooks'
import { cx } from 'utils'
import styles from './SiteHeader.module.scss'

const LatestLeadLink = ({ latestLead }: { latestLead: string | null }) => {
  const [, setMenuOpen] = useContext(MenuContext)
  const { getLastSearchUrl } = useSavedSearchesContext()

  const [latestLeadType, leadPageUrl] = (latestLead || 'Trade In||/trade-ins')?.split('||')
  const link = getLastSearchUrl(leadPageUrl) || leadPageUrl

  return (
    <Link
      to={link}
      onClick={() => {
        setMenuOpen(false)
      }}
    >
      {latestLeadType} Leads
    </Link>
  )
}

export const LeadsMenu = () => {
  const { pathname } = useLocation()
  const { currentUser } = useCurrentUserContext()
  const { getLastSearchUrl } = useSavedSearchesContext()
  const [latestLead, setLatestLead] = useLocalStorage('leadsMenuLink')
  const { salesAppEscrowPage, salesAppBbysPipelineView } = useFeatureFlags([
    'sales-app-escrow-page',
    'sales-app-bbys-pipeline-view'
  ])

  const leadsNavigationSettings = useMemo(() => {
    const items = salesAppBbysPipelineView?.enabled
      ? [
          {
            to: '/all-leads',
            content: 'All'
          },
          {
            to: '/buy-before-you-sell/leads',
            content: 'Buy Before You Sell'
          },
          {
            to: '/cash-offers',
            content: 'Cash Offer'
          },
          {
            to: '/hlss',
            content: 'HLSS'
          },
          {
            to: '/mortgages',
            content: 'Mortgage'
          },
          {
            to: '/escrows',
            content: salesAppEscrowPage.enabled ? 'Escrow' : ''
          },
          {
            to: getLastSearchUrl('/buyers-sellers', `?assigneeId=${currentUser?.id}`),
            content: 'Buyers/Sellers'
          },
          {
            to: '/trade-ins',
            content: 'Trade-In Legacy'
          }
        ]
      : [
          {
            to: '/all-leads',
            content: 'All'
          },
          {
            to: '/trade-ins',
            content: 'Buy Before You Sell'
          },
          {
            to: '/cash-offers',
            content: 'Cash Offer'
          },
          {
            to: '/hlss',
            content: 'HLSS'
          },
          {
            to: '/mortgages',
            content: 'Mortgage'
          },
          {
            to: '/escrows',
            content: salesAppEscrowPage.enabled ? 'Escrow' : ''
          },
          {
            to: getLastSearchUrl('/buyers-sellers', `?assigneeId=${currentUser?.id}`),
            content: 'Buyers/Sellers'
          }
        ]

    return items.filter(item => !!item.content)
  }, [currentUser, getLastSearchUrl, salesAppEscrowPage, salesAppBbysPipelineView])

  const isSelected = leadsNavigationSettings.some(navItem => navItem.to === pathname)

  return (
    <div className={cx(styles.dropDownMenu, isSelected && styles.selected)}>
      <Menu shouldUseHover title={<LatestLeadLink latestLead={latestLead} />} popupAlignment="left">
        {leadsNavigationSettings.map(({ to, content }) => {
          const pageUrl = getLastSearchUrl(to)
          const onClick = () => setLatestLead(`${content}||${to}`)

          return (
            <MenuLink onClick={onClick} to={pageUrl} className={styles.dropDownMenuItem} key={to}>
              {content}
            </MenuLink>
          )
        })}
      </Menu>
    </div>
  )
}
