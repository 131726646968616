import { Contact, Message } from 'models'
import { idMatch } from 'utils'

type Props = {
  lastRead: Record<string, string>
  messages?: Message[]
}

export const getContacts = ({ messages, lastRead }: Props): Contact[] => {
  const messagesWith = cid => (messages ? messages.filter(m => idMatch(m.agent.id, cid)) : [])
  const inboundMessagesWith = cid => messagesWith(cid).filter(m => !m.outbound)

  const unreadMessagesFrom = cid => {
    const cutoffDate = lastRead[cid] || lastRead.min
    return inboundMessagesWith(cid).filter(m => new Date(m.createdAt) > new Date(cutoffDate))
  }

  return messages
    ? messages
        .reduce((contactsAcc, message) => {
          let contact = contactsAcc.find(c => c.agent.id === message.agent.id)
          if (!contact) {
            contact = Contact.create(message.agent)
            contactsAcc.push(contact)
          }
          contact.messages.push(message)
          return contactsAcc
        }, [] as Contact[])
        .map(c => ({
          ...c,
          totalCount: inboundMessagesWith(c.agent.id).length,
          unreadCount: unreadMessagesFrom(c.agent.id).length,
          lastReadAt: lastRead[c.agent.id] || lastRead.min
        }))
    : []
}
