import { css, styled } from '@foundation/themes/styles'
import type { Theming } from '@foundation/themes/styles'
import {
  ghostColorDisabledModifier,
  ghostColorModifier,
  linkColorDisabledModifier,
  linkColorModifier,
  outlineColorDisabledModifier,
  outlineColorModifier,
  solidColorDisabledModifier,
  solidColorModifier
} from './modifiers'
import { ButtonProps } from '.'

export type ButtonStyle = Omit<ButtonProps, 'children'>
type StylesProps = { theme: Theming } & ButtonStyle

const variantStyle = {
  ghost: ({ color, isDisabled, theme }: StylesProps) => {
    const colorModifier = isDisabled
      ? ghostColorDisabledModifier(theme)
      : ghostColorModifier[color!](theme)

    return css`
      border: 1px solid transparent;
      background-color: transparent;
      ${colorModifier};
    `
  },
  outline: ({ color, isDisabled, theme }: StylesProps) => {
    const colorModifier = isDisabled
      ? outlineColorDisabledModifier(theme)
      : outlineColorModifier[color!](theme)

    return css`
      background-color: transparent;
      ${colorModifier};
    `
  },
  solid: ({ color, isDisabled, theme }: StylesProps) => {
    const colorModifier = isDisabled
      ? solidColorDisabledModifier(theme)
      : solidColorModifier[color!](theme)

    return css`
      border: 1px solid transparent;
      ${colorModifier}
    `
  },
  link: ({ color, isDisabled, theme }: StylesProps) => {
    const colorModifier = isDisabled
      ? linkColorDisabledModifier(theme)
      : linkColorModifier[color!](theme)

    return css`
      background-color: transparent;
      border: 1px solid transparent;
      ${colorModifier};

      && {
        padding: 0;
      }

      &:hover {
        text-decoration: underline;
      }
    `
  }
}

const sizeStyle = {
  xs: ({ theme }: StylesProps) => css`
    font-size: ${theme.fontSizes.xs};
    padding: ${theme.space.$1} ${theme.space.$4};
  `,
  sm: ({ theme }: StylesProps) => css`
    font-size: ${theme.fontSizes.sm};
    padding: ${theme.space.$2} ${theme.space.$4};
  `,
  md: ({ theme }: StylesProps) => css`
    font-size: ${theme.fontSizes.base};
    padding: ${theme.space.$3} ${theme.space.$6};
  `,
  lg: ({ theme }: StylesProps) => css`
    font-size: ${theme.fontSizes.base};
    padding: ${theme.space.$4} ${theme.space.$7};
  `
}

const hasFullWidthStyle = css`
  width: 100%;
`

const isDisabledStyle = css`
  &:disabled {
    cursor: not-allowed;
  }
`

export const Button = styled.button<ButtonStyle>`
  ${({ variant, size, hasFullWidth, isDisabled, theme, isRounded }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: ${theme.fontWeights.bold};
    border-radius: ${theme.radii.md};
    cursor: pointer;
    gap: ${theme.space.$1};
    line-height: ${theme.lineHeights.sm};
    outline: none;

    svg {
      path {
        fill: currentColor;
      }
      circle {
        stroke: currentColor;
      }
    }

    ${!!hasFullWidth && hasFullWidthStyle};
    ${!!variant && variantStyle[variant]};
    ${!!size && sizeStyle[size]};
    ${!!isDisabled && isDisabledStyle};
    ${!!isRounded && `border-radius: ${theme.radii.rounded}`};
  `}
`
