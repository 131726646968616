import { useMemo, useState } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { AGENTS_CACHE_KEY } from 'controllers'
import { AgentInterface, AgentModel } from 'models/v2/Agent'
import { AgentsSchema, getHapiRequestParams } from 'schema'
import { TFetchItems } from 'services'
import { getAgentsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest, removeAttributesWithNullValues } from 'utils'

const agentsShape: AgentsSchema = {
  agents: ['firstName', 'lastName', 'officeDisplayName', { state: ['code'] }]
}

type Settings = {
  searchTerm?: string
}

const fetchAgentsAutocomplete = async <Response extends AgentInterface>({
  searchTerm
}: Settings): Promise<TFetchItems<Response>> => {
  const shapeParams = getHapiRequestParams(agentsShape)
  const filterParams = removeAttributesWithNullValues({
    'page[size]': 8,
    'filter[_search]': searchTerm
  })

  const url = getAgentsUrl({
    searchParams: { ...shapeParams, ...filterParams }
  })

  const response = await getRequest(url)
  const deserializedResponse = await deserializeAsync<Response[]>(response.data)

  return {
    items: deserializedResponse,
    pageCount: 0,
    totalCount: response.data.meta.total_agents
  }
}

type QueryOptions = UseQueryOptions<
  TFetchItems<AgentInterface>,
  unknown,
  TFetchItems<AgentInterface>,
  any
>

export const useAgentsAutocomplete = (options: QueryOptions = {}) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  const [isQueryEnabled, setIsQueryEnabled] = useState(options?.enabled || false)
  const enabled = options.enabled ? isQueryEnabled : isQueryEnabled && searchTerm !== undefined

  const searchSettings: Settings = useMemo(
    () => ({
      searchTerm
    }),
    [searchTerm]
  )

  const { data, status } = useQuery(
    [AGENTS_CACHE_KEY, 'autocomplete', searchSettings],
    () => fetchAgentsAutocomplete(searchSettings),
    {
      ...options,
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 300000 // 5min
    }
  )

  const items = useMemo(() => {
    return (
      data?.items.map(item => {
        const officeLabel = [item.officeDisplayName, item.state.code].filter(Boolean).join(' - ')
        return {
          value: item.id,
          label: `${AgentModel.getFullName(item)} (${officeLabel})`
        }
      }) ?? []
    )
  }, [data])

  const autocompleteStatus = useMemo(() => {
    if (!data?.items.length && status === 'success') {
      return 'error'
    }

    return status
  }, [data, status])

  return {
    items,
    status: autocompleteStatus,
    setIsQueryEnabled,
    setSearchTerm
  }
}
