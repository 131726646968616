// @ts-strict
import { ReactElement } from 'react'
import { Card, TabbedContent } from 'components'
import {
  InvestorDetails,
  InvestorLeadDispositionHeader,
  InvestorSummary
} from 'content/InvestorLead'
import { InvestorLead } from 'models'
import { cx, toSnakeCase } from 'utils'
import styles from './InvestorLeadCard.module.scss'

type InvestorLeadCardProps = {
  investorLead: InvestorLead
}

export const InvestorLeadCard = ({ investorLead }: InvestorLeadCardProps) => {
  const stage = toSnakeCase(investorLead.providerLead.stage)

  const cardContent: Record<string, ReactElement> = {
    Details: <InvestorDetails investorLead={investorLead} />
  }

  return (
    <Card
      data-testid="investorLeadCard"
      summary={
        <>
          <InvestorLeadDispositionHeader investorLead={investorLead} />
          <InvestorSummary investorLead={investorLead} />
        </>
      }
      details={<TabbedContent content={cardContent} />}
      className={cx(styles.card, styles[stage])}
    />
  )
}
