// @ts-strict

import { useLocation } from 'react-use'
import { Contents, ListSearchHeader, Page, PaginationParams, ViewsMenu } from 'components'
import { CCHLSimpleSaleLeadListFilters } from 'content/CCHLSimpleSaleLead'
import { CCHLSimpleSaleLeadsTable } from 'content/CCHLSimpleSaleLeads'
import { CreateOrderButtonForm } from 'content/Order'
import { ListProvider } from 'contexts'
import {
  HLSS_LEADS_CACHE_KEY,
  useCCHLSimpleSaleLeadsController,
  useCreateOrderController
} from 'controllers'
import { useDocumentTitle } from 'hooks'
import { ccHLSimpleSaleLeadsListColumns, useCCHLSimpleSaleLeadSearchFilter } from 'lookups'
import { queryStringToObject } from 'utils'

const PAGE_TITLE = 'HLSS Leads'

const actions = (
  <>
    <CCHLSimpleSaleLeadListFilters />
    <ViewsMenu />
  </>
)

const useHLSSLeadsPage = () => {
  useDocumentTitle(PAGE_TITLE)
  const { search } = useLocation()
  const searchParams = queryStringToObject(search)

  const { hlssLeadsQuery } = useCCHLSimpleSaleLeadsController({ searchParams })

  return {
    hlssLeadsQuery
  }
}

export const HLSSLeadsPage = () => {
  const { hlssLeadsQuery } = useHLSSLeadsPage()
  const { data, isLoading } = hlssLeadsQuery
  const { items } = data || {}
  const ccHLSimpleSaleFilters = useCCHLSimpleSaleLeadSearchFilter()
  const { createOrder, handleOrderCreated } = useCreateOrderController({
    clearCacheKey: HLSS_LEADS_CACHE_KEY
  })

  const action = <CreateOrderButtonForm mutation={createOrder} onSubmit={handleOrderCreated} />

  return (
    <ListProvider columns={ccHLSimpleSaleLeadsListColumns} filters={ccHLSimpleSaleFilters}>
      <Page>
        <Contents title={PAGE_TITLE} actions={action}>
          <ListSearchHeader
            counterText="Lead"
            totalCount={hlssLeadsQuery.data?.totalCount}
            isLoading={hlssLeadsQuery.isLoading}
            filter={actions}
          />
          <CCHLSimpleSaleLeadsTable items={items} isLoading={isLoading} />
          <PaginationParams totalPages={hlssLeadsQuery.data?.pageCount} />
        </Contents>
      </Page>
    </ListProvider>
  )
}
