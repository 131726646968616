// @ts-strict
import { ButtonForm } from 'components'
import { useAgentAgreementsController } from 'controllers'
import type { Agent } from 'models'
import { IssueButtonFormFields } from './IssueButtonFormFields'
import { useAgentAgreementButtonForm } from './useAgentAgreementButtonForm'

type Props = {
  agent: Agent
}

export const IssueButtonForm = ({ agent }: Props) => {
  const lastSignedAgreementId = agent.lastSignedAgreement?.agreement?.id!

  const { agentAgreementIssue } = useAgentAgreementsController(agent.id)
  const { fieldsProps, formProps } = useAgentAgreementButtonForm(
    agentAgreementIssue,
    lastSignedAgreementId
  )

  return (
    <ButtonForm buttonText="Issue" title="Issue Agent Agreement" {...formProps}>
      <IssueButtonFormFields agent={agent} {...fieldsProps} />
    </ButtonForm>
  )
}
