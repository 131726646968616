import { Button } from '@foundation/components'
import {
  DateInput,
  FileInput,
  Form,
  FormActions,
  FormField,
  PropertyAutoComplete,
  RadioTabs,
  Section,
  SectionTitle,
  Select,
  TextArea,
  TextInput
} from 'components'
import {
  ESCROW_LEADS_CACHE_KEY,
  useCreateEscrowOrderController,
  useServiceOfficesController
} from 'controllers'
import styles from '../CreateEscrowOrder.module.scss'
import { ReferringAgentFields } from './ReferringAgentFields'
import { useCreateEscrowOrderForm } from './useCreateEscrowOrderForm'

export const CreateEscrowOrderForm = ({ handleClose }: { handleClose: () => void }) => {
  const { createEscrowOrder, handleOrderCreated } = useCreateEscrowOrderController({
    clearCacheKey: ESCROW_LEADS_CACHE_KEY
  })

  const { formProps, payload, onChangeAttribute } = useCreateEscrowOrderForm({
    mutation: createEscrowOrder,
    onSubmit: handleOrderCreated
  })

  const { serviceOfficesQuery } = useServiceOfficesController()
  const serviceOfficeOptions = (serviceOfficesQuery?.data || []).map(office => ({
    name: [office.name, office.branchName].join(' - '),
    value: office.id
  }))

  const onPropertySelect = val => {
    if (!val) {
      onChangeAttribute('address', '')
      return
    }

    const { title, lead } = val
    if (lead) {
      const nameParts = lead.name.split(' ')
      const { '0': firstName, [nameParts.length - 1]: lastName } = nameParts
      onChangeAttribute('address', lead.fullAddress)
      onChangeAttribute('name', lead.name)
      onChangeAttribute('firstName', firstName)
      onChangeAttribute('lastName', lastName)
      onChangeAttribute('name', lead.name)
      onChangeAttribute('phone', lead.phone)
      onChangeAttribute('email', lead.email)
      onChangeAttribute('parentLeadId', lead.id)
      if (!payload.referringAgentId) {
        if (lead.referringAgent) {
          onChangeAttribute('referringAgentId', lead.referringAgent.id)
          onChangeAttribute('referringAgentName', lead.referringAgent.name)
        } else if (lead.referringOwner) {
          onChangeAttribute('referringAgentId', lead.referringOwner.id)
          onChangeAttribute(
            'referringAgentName',
            [lead.referringOwner.firstName, lead.referringOwner.lastName].join(' ')
          )
        }
      }
    } else {
      onChangeAttribute('address', title)
    }
  }

  const isPreEscrow = payload.orderType === 'pre_escrow'

  return (
    <Form onSubmit={formProps.onSubmit} className={styles.form}>
      <Section>
        <FormField>
          <RadioTabs
            data-testid="order-type-field"
            value={payload.orderType}
            onChange={(val: 'escrow' | 'pre_escrow' | 'refinance') =>
              onChangeAttribute('orderType', val)
            }
            options={[
              {
                label: 'Escrow',
                value: 'escrow'
              },
              {
                label: 'Pre-Escrow',
                value: 'pre_escrow'
              },
              {
                label: 'Refinance',
                value: 'refinance'
              }
            ]}
          />
        </FormField>
      </Section>
      <Section>
        <SectionTitle className={styles.sectionTitle}>Property</SectionTitle>
        <FormField title="Property address">
          <PropertyAutoComplete
            data-testid="property-autocomplete"
            className={styles.input}
            value={payload.address}
            onSelect={onPropertySelect}
            onClear={() => onChangeAttribute('address', '')}
            placeholder="Enter property address or client name"
            leadSearchParams={{
              agentAndTeam: payload.referringAgentId,
              hlcsEligible: true
            }}
          />
        </FormField>
        <FormField title="Unit / Apt / Suite" note="Optional">
          <TextInput
            data-testid="unit-field"
            value={payload.secondaryAddress}
            onChange={val => onChangeAttribute('secondaryAddress', val)}
            className={styles.input}
            placeholder="Enter unit e.g: Unit 14"
          />
        </FormField>
      </Section>
      <Section>
        <SectionTitle className={styles.sectionTitle}>Agent Info</SectionTitle>
        <ReferringAgentFields payload={payload} onChangeAttribute={onChangeAttribute} />
        <FormField title="Referring agent role">
          <RadioTabs
            data-testid="agent-role-field"
            value={payload.buyer ? 'buyer' : 'seller'}
            onChange={val => onChangeAttribute('buyer', val === 'buyer')}
            options={[
              {
                label: 'Listing agent',
                value: 'seller'
              },
              {
                label: 'Selling agent',
                value: 'buyer'
              }
            ]}
          />
        </FormField>
      </Section>
      <Section>
        <SectionTitle className={styles.sectionTitle}>Transaction Info</SectionTitle>
        <FormField title="Client name">
          <TextInput
            data-testid="client-name-field"
            value={payload.name}
            onChange={val => {
              onChangeAttribute('name', val)
              onChangeAttribute('firstName', val.split(' ')[0])
              onChangeAttribute('lastName', val.split(' ')[1])
            }}
            className={styles.input}
            placeholder="Enter client name"
          />
        </FormField>
        <FormField title="Leg of transaction" note="Optional">
          <RadioTabs
            data-testid="transaction-leg-field"
            value={payload.secondaryUserType}
            onChange={(val: 'buyer' | 'seller') => onChangeAttribute('secondaryUserType', val)}
            options={[
              {
                label: '1st Leg',
                value: 'buyer'
              },
              {
                label: '2nd Leg',
                value: 'seller'
              }
            ]}
          />
        </FormField>
        <FormField title="Service office">
          <Select
            data-testid="service-office-select"
            options={serviceOfficeOptions}
            onChange={val => onChangeAttribute('serviceOffice', val)}
            value={payload.serviceOffice}
            className={styles.input}
            addBlank
          />
        </FormField>
        <FormField title="Contract received date" note={isPreEscrow ? 'Optional' : null}>
          <DateInput
            data-testid="contract-received-date-field"
            value={payload.contractReceivedDate}
            onChange={val => onChangeAttribute('contractReceivedDate', val)}
            className={styles.input}
          />
        </FormField>
        <FormField title="Transaction coordinator name">
          <TextInput
            data-testid="transaction-coordinator-name-field"
            value={payload.transactionCoordinatorName}
            onChange={val => onChangeAttribute('transactionCoordinatorName', val)}
            className={styles.input}
          />
        </FormField>
        <FormField title="Transaction coordinator email">
          <TextInput
            data-testid="transaction-coordinator-email-field"
            value={payload.transactionCoordinatorEmail}
            onChange={val => onChangeAttribute('transactionCoordinatorEmail', val)}
            className={styles.input}
          />
        </FormField>
        {!isPreEscrow && (
          <FormField title="Purchase contract">
            <FileInput
              id="file-name"
              data-testid="file-upload-input"
              accept=".doc,.docx,.pdf"
              value={payload.files}
              multiple
              onChange={value => {
                onChangeAttribute('files', value)
              }}
            />
          </FormField>
        )}
        <FormField title="Notes" note="Optional">
          <TextArea
            value={payload.notes}
            onChange={val => onChangeAttribute('notes', val)}
            data-testid="sales-notes-field"
            className={styles.textarea}
          />
        </FormField>
      </Section>
      <FormActions
        error={(formProps.showError && formProps.error) || formProps.errorMessage}
        onMouseEnter={formProps.handleHover}
      >
        <Button
          onClick={handleClose}
          isDisabled={formProps.isLoading}
          variant="ghost"
          color="neutralLight"
          type="button"
        >
          Cancel
        </Button>
        <Button isDisabled={!formProps.isFormValid} isLoading={formProps.isLoading}>
          Submit
        </Button>
      </FormActions>
    </Form>
  )
}
