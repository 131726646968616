// @ts-strict
import { ReactNode } from 'react'
import { useAgentController } from 'controllers'
import { createCtx } from '../utils'

export const [useAgentContext, AgentContextProvider] =
  createCtx<ReturnType<typeof useAgentController>>()

export const AgentProvider = ({ children, agentId }: { agentId: string; children: ReactNode }) => {
  return (
    <AgentContextProvider value={useAgentController(agentId, true)}>
      {children}
    </AgentContextProvider>
  )
}
