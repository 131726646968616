import { getLead, Lead } from 'models'
import {
  AgentAttributesAndAssociations,
  EscrowOfficeLeadAttributesAndAssociations,
  EscrowOrderSchema,
  getHapiRequestParams,
  LeadAttributesAndAssociations,
  LeadSchema,
  OrderAttributesAndAssociations
} from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchEscrowLead = async (id: string): Promise<Lead> => {
  const leadFields: LeadAttributesAndAssociations = [
    'callReview',
    'createdAt',
    'email',
    'emailAlt',
    'fullAddress',
    'furthestStage',
    'humanTimeline',
    'lastCallDate',
    'lastStageUpdate',
    'latitude',
    'longitude',
    'name',
    'notesToAgents',
    'partnerName',
    'phoneAlt',
    'price',
    'propertyType',
    'propertyUuid',
    'salesNotes',
    'secondaryUserType',
    'source',
    'sourcePageType',
    'stage',
    { leadUsers: [] },
    'userType',
    'isDeal',
    'qualificationTalkedToClient'
  ]
  const orderFields: OrderAttributesAndAssociations = [
    'createdAt',
    'ownedBusinessUnit',
    'source',
    'stage',
    'updatedAt'
  ]
  const referringAgentFields: AgentAttributesAndAssociations = [
    'email1',
    'firstName',
    'lastName',
    'licenseNumber',
    { agentTeam: ['name'] },
    { phoneNumbers: ['number'] },
    {
      'settlementAgencyTeam:team': [
        'name',
        'description',
        { teamMemberships: ['role', { user: ['name'] }] }
      ]
    }
  ]

  const escrowOrderShape: EscrowOrderSchema['escrowOrder'] = [
    'transactionType',
    {
      escrowOrderSettlementTeamMembers: [
        'role',
        {
          settlementTeamMember: ['firstName', 'lastName', 'email', 'phone']
        }
      ]
    }
  ]

  const escrowOfficeProviderLeadFields: EscrowOfficeLeadAttributesAndAssociations = [
    'loanAmount',
    'netEquity',
    'orderClosingOn',
    'orderIdentifier',
    'orderNumber',
    'orderOpenAt',
    'orderStatus',
    'transactionCoordinatorEmail',
    'transactionCoordinatorName',
    'startedAsPreEscrow',
    { serviceOffice: ['licenseNumber', 'name', 'branchName'] },
    {
      escrowOrder: escrowOrderShape
    }
  ]

  const leadResponseShape: LeadSchema = {
    lead: [
      ...leadFields,
      { client: [{ user: ['firstName', 'lastName', 'email'] }] },
      { order: orderFields },
      { phoneNumbers: ['number', 'phoneType'] },
      {
        'referringOwner:agent': referringAgentFields
      },
      {
        'escrowOfficeProviderLeads:providerLead': [
          {
            'providable:escrowOfficeLead': escrowOfficeProviderLeadFields
          }
        ]
      }
    ]
  }

  const searchParams = getHapiRequestParams(leadResponseShape)
  const url = getLeadsUrl({ id, searchParams })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: getLead })
}
