import { useEffect, useState } from 'react'
import { useQuery } from 'hooks'
import { alertError } from 'services'
import { fetchDownloadUrl } from './services'

export const useDownloadButton = (url: string, downloadAttribute: string = 'download_url') => {
  const [isDownloadStarted, setIsDownloadStarted] = useState(false)

  const handleClick = () => {
    if (data) {
      window.open(data[downloadAttribute])
    } else {
      setIsDownloadStarted(true)
    }
  }

  const { data, isLoading, isError, error } = useQuery(
    ['download_button', url],
    () => fetchDownloadUrl(url),
    {
      enabled: isDownloadStarted
    }
  )

  useEffect(() => {
    if (data && isDownloadStarted) {
      window.open(data[downloadAttribute])
      setIsDownloadStarted(false)
    } else if (isError) {
      alertError(error['message'])
    }
  }, [data, isError, error, downloadAttribute, isDownloadStarted])

  return {
    data,
    isLoading,
    handleClick
  }
}
