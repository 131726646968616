import { format, parseISO } from 'date-fns'
import { DEFAULT_STRING } from '../constants'

export const formatTime = (dateTime: Nullable<string>, defaultValue: string = DEFAULT_STRING) => {
  if (dateTime === undefined || dateTime === null) {
    return defaultValue
  }
  try {
    const dateTimeDate = typeof dateTime === 'string' ? parseISO(dateTime) : dateTime
    return format(dateTimeDate, 'h:mma').replace('AM', 'am').replace('PM', 'pm')
  } catch {
    return 'Invalid date'
  }
}
