import { ModelField } from '../ModelField'
import { TAgentFieldAttribute } from './agentFieldsSettings'

export class AgentField extends ModelField {
  attr!: TAgentFieldAttribute

  constructor(attributes: AgentField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
