// @ts-strict
import { User } from 'models'
import { FileResponse } from 'schema'

export abstract class Upload {
  user?: User
  id!: string
  name!: string
  createdAt?: string
  updatedAt?: string

  static build(
    response: Potential<FileResponse>
  ): Potential<Omit<FileResponse, 'user'> & { user: User }> {
    if (!response) {
      return
    }

    const user = User.create(response.user)!
    return { ...response, user }
  }
}
