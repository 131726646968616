// @ts-strict
import { ConfirmationPopup } from 'components'
import type { ConfirmationPopupProps } from 'components'
import { usePhotoUploadsController } from 'controllers'
import pluralize from 'pluralize'

type TProps = Pick<ConfirmationPopupProps, 'callback'> & {
  ids: string[]
  multiple?: boolean
}

export const PhotoUploadDeleteButtonForm = ({ ids, callback, multiple }: TProps) => {
  const { destroyPhotoUploads } = usePhotoUploadsController()
  const photoCount = ids.length
  const CTA = multiple ? 'Photos' : 'Photo'

  const handleCallback = (data: '') => {
    callback && callback(data)
  }

  return (
    <ConfirmationPopup
      initialPayload={{ ids }}
      mutation={destroyPhotoUploads}
      callback={handleCallback}
      title={`Delete Property ${CTA}`}
      buttonText={`Delete ${CTA}`}
      message={
        <span>
          Once the photo is removed it is not possible to recover.
          <br /> Are you sure you want to delete
          {multiple ? <strong> {photoCount} </strong> : ' this '}
          {pluralize('photo', photoCount)}?
        </span>
      }
    />
  )
}
