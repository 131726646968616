import { AgentResponse } from 'schema'
import { bySlug } from 'utils'

const badgeList = [
  { slug: 'elite', name: 'HomeLight Elite', abbr: 'E' },
  { slug: 'tier1', name: 'Tier 1', abbr: 'T1' },
  { slug: 'tier2', name: 'Tier 2', abbr: 'T2' },
  { slug: 'tier3', name: 'Tier 3', abbr: 'T3' }
] as const

export interface AgentBadge {
  abbr: (typeof badgeList)[number]['abbr']
  name: (typeof badgeList)[number]['name']
  slug: (typeof badgeList)[number]['slug']
}

type TProps = {
  eliteStatus?: AgentResponse['eliteStatus']
  tier?: AgentResponse['tier']
}

export const getAgentBadge = ({ tier, eliteStatus }: TProps = {}): AgentBadge => {
  const tierStr = tier > 0 ? `tier${tier}` : null
  const badge = eliteStatus === 'active' ? 'elite' : tierStr

  return badge ? badgeList.find(bySlug(badge)) : null
}
