// @ts-strict
import { useCallback } from 'react'
import { useLocalStorage } from 'hooks'
import { valueMatch } from 'utils'

type stackItem = {
  name: string
  pathname: string
}

export const useStack = (max: number = 10, storageKey = 'stack') => {
  const [stack, setStack] = useLocalStorage<stackItem[]>(storageKey, [])

  const add = useCallback(
    (item: stackItem) => {
      if (!stack.length || !valueMatch(stack[0], item)) {
        const newstack = [item]
          .concat(stack.filter(l => l.pathname !== item.pathname))
          .slice(0, max)
        setStack(newstack)
      }
    },
    [stack, max, setStack]
  )

  return { stack, add }
}
