import { Inspection } from 'models'
import { getHapiRequestParams } from 'schema'
import type { InspectionsSchema } from 'schema'
import { deserializeAsync, getRequest } from 'utils'
import { getInspectionUrl } from '../getUrl'
import type { TFetchItems } from '../types'
import { getInspectionsSearchParams } from './getInspectionsSearchParams'
import type { InspectionsSearchParams } from './getInspectionsSearchParams'

const inspectionShape: InspectionsSchema = {
  inspections: [
    'businessName',
    'createdAt',
    'externalId',
    'inspectionType',
    'price',
    'scheduledAt',
    'status',
    'updatedAt',
    { inspectionOrder: ['id'] },
    { activeInspectionReports: [{ file: ['id'] }, 'webUrl'] }
  ]
}
export type FetchInspectionsProps = InspectionsSearchParams
export const fetchInspections = async (
  settings: FetchInspectionsProps
): Promise<TFetchItems<Inspection>> => {
  const url = getInspectionUrl({
    searchParams: {
      ...getInspectionsSearchParams(settings),
      ...getHapiRequestParams(inspectionShape)
    },
    action: 'inspection-details'
  })
  const response = await getRequest(url)
  const inspections = await deserializeAsync<Inspection[]>(response.data, {
    transform: Inspection.create
  })
  return {
    items: inspections,
    pageCount: response.data.meta.total_pages,
    totalCount: response.data.meta.total_count
  }
}
