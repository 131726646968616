// @ts-strict

import { useLocation } from 'react-use'
import { Contents, ListSearchHeader, Page, PaginationParams, ViewsMenu } from 'components'
import { CCCashOfferLeadListFilters } from 'content/CCCashOfferLead'
import { CCCashOfferLeadsTable } from 'content/CCCashOfferLeads'
import { CreateOrderButtonForm } from 'content/Order'
import { ListProvider } from 'contexts'
import {
  CC_CASH_OFFER_LEADS_CACHE_KEY,
  useCCCashOfferLeadsController,
  useCreateOrderController
} from 'controllers'
import { useDocumentTitle } from 'hooks'
import { ccCashOfferLeadsListColumns, useCCCashOfferLeadSearchFilter } from 'lookups'
import { queryStringToObject } from 'utils'

const PAGE_TITLE = 'Cash Offer Leads'

const actions = (
  <>
    <CCCashOfferLeadListFilters />
    <ViewsMenu />
  </>
)

const useCCCashOfferLeadsPage = () => {
  useDocumentTitle(PAGE_TITLE)
  const { search } = useLocation()
  const searchParams = queryStringToObject(search)

  const { ccCashOfferLeadsQuery } = useCCCashOfferLeadsController({ searchParams })

  return {
    ccCashOfferLeadsQuery
  }
}

export const CCCashOfferLeadsPage = () => {
  const { ccCashOfferLeadsQuery } = useCCCashOfferLeadsPage()
  const { data, isLoading } = ccCashOfferLeadsQuery
  const { items } = data || {}
  const ccCashOfferLeadFilters = useCCCashOfferLeadSearchFilter()
  const { createOrder, handleOrderCreated } = useCreateOrderController({
    clearCacheKey: CC_CASH_OFFER_LEADS_CACHE_KEY
  })

  const action = <CreateOrderButtonForm mutation={createOrder} onSubmit={handleOrderCreated} />

  return (
    <ListProvider columns={ccCashOfferLeadsListColumns} filters={ccCashOfferLeadFilters}>
      <Page>
        <Contents title={PAGE_TITLE} actions={action}>
          <ListSearchHeader
            counterText="Lead"
            totalCount={ccCashOfferLeadsQuery.data?.totalCount}
            isLoading={ccCashOfferLeadsQuery.isLoading}
            filter={actions}
          />
          <CCCashOfferLeadsTable items={items} isLoading={isLoading} />
          <PaginationParams totalPages={ccCashOfferLeadsQuery.data?.pageCount} />
        </Contents>
      </Page>
    </ListProvider>
  )
}
