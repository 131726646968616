import { FunctionComponent } from 'react'
import { Button, ButtonProps } from '@foundation/components'
import {
  ButtonForm,
  CheckBoxField,
  FormField,
  Section,
  Sections,
  Select,
  TButtonFormContentsProps,
  TextArea
} from 'components'
import { useActivityFeedDisposition } from 'lookups'
import { Lead } from 'models'
import { useLeadCreateActivityNoteButtonForm } from '.'
import { TLeadActivityNotePayload } from '.'

type TProps = {
  buttonComponent?: FunctionComponent<Partial<ButtonProps>>
  buttonText?: TButtonFormContentsProps['buttonText']
  lead: Lead
}

export const LeadCreateActivityNoteButtonForm = ({
  buttonComponent: ButtonComponent,
  buttonText,
  lead
}: TProps) => {
  const { formProps, payload, onChangeAttribute } = useLeadCreateActivityNoteButtonForm(lead)
  const { activityFeedDispositionOptions } = useActivityFeedDisposition()
  const toggleCheckbox = (key: keyof TLeadActivityNotePayload, checked: boolean) => {
    onChangeAttribute(key, checked)
  }

  return (
    <>
      {ButtonComponent ? (
        <ButtonComponent onClick={formProps.toggleShowForm} />
      ) : (
        <Button variant="outline" color="neutralLight" onClick={formProps.toggleShowForm}>
          New Note
        </Button>
      )}
      <ButtonForm hideTriggerButton title="New Note" buttonText={buttonText} {...formProps}>
        <Sections>
          <Section>
            <FormField title="Choose an optional disposition">
              <Select
                data-testid="dispositionDropdown"
                value={payload.disposition}
                onChange={val => onChangeAttribute('disposition', val)}
                addBlank
                options={activityFeedDispositionOptions}
              />
            </FormField>
            <FormField htmlFor="note" title="Note" required>
              <TextArea
                id="note"
                value={payload.note}
                onChange={val => onChangeAttribute('note', val)}
              />
            </FormField>
            <CheckBoxField
              title="Post to slack"
              checked={payload.slack}
              disabled={false}
              onChange={e => {
                toggleCheckbox('slack', e.target.checked)
              }}
            />
          </Section>
        </Sections>
      </ButtonForm>
    </>
  )
}
