import { useEffect } from 'react'
import { TasksList } from 'content/Tasks/TasksList'
import { ListProvider, TasksProvider, useLeadContext, useParamsContext } from 'contexts'
import { useDocumentTitle } from 'hooks'
import {
  agentTaskListColumns,
  bbysTasksListColumns,
  cashOfferTaskListColumns,
  tradeInTasksListColumns,
  useCCCashOfferLeadTaskSearchFilters,
  useCCTradeInLeadTaskSearchFilters
} from 'lookups'
import { isCCBBYSLead, isCCCashOfferLead, isCCTradeInLead } from 'models'

export const LeadTasksTab = () => {
  const { lead } = useLeadContext()
  const { setTitle } = useDocumentTitle()
  const { params } = useParamsContext()

  const ccCashOfferLead = isCCCashOfferLead(lead) ? lead : undefined
  const ccCashOfferLeadTaskSearchFilters = useCCCashOfferLeadTaskSearchFilters(ccCashOfferLead)
  const ccTradeInLeadTaskSearchFilters = useCCTradeInLeadTaskSearchFilters()

  useEffect(() => setTitle(`${lead.name} - Lead Tasks`), [lead, setTitle])

  const filters = isCCCashOfferLead(lead)
    ? ccCashOfferLeadTaskSearchFilters
    : isCCTradeInLead(lead)
    ? ccTradeInLeadTaskSearchFilters
    : undefined

  const columns = isCCCashOfferLead(lead)
    ? cashOfferTaskListColumns
    : isCCTradeInLead(lead)
    ? tradeInTasksListColumns
    : isCCBBYSLead(lead)
    ? bbysTasksListColumns
    : agentTaskListColumns

  return (
    <TasksProvider params={params} attachable={lead}>
      <ListProvider filters={filters} columns={columns}>
        <TasksList />
      </ListProvider>
    </TasksProvider>
  )
}
