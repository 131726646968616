import { ReactNode } from 'react'
import { Popover, PopoverProps } from '@foundation/components'

type Props = {
  children: ReactNode
} & PopoverProps

export const ListFilters = ({ children, ...rest }: Props) => {
  return (
    <Popover.Root placement="bottom-end" {...rest}>
      {children}
    </Popover.Root>
  )
}
