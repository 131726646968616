import { Contents } from 'components/Contents/index'
import { Page } from 'components/Page/index'
import { ListUsers } from 'features/users/listUsers'
import { useDocumentTitle, useFeatureFlags } from 'hooks'

export const UsersPage = () => {
  const { salesAppImpersonatingAgentTeamCoord } = useFeatureFlags([
    'sales-app-impersonating-agent-team-coord'
  ])
  const pageTitle = salesAppImpersonatingAgentTeamCoord.enabled ? 'Users' : 'Employees'

  useDocumentTitle(pageTitle)

  return (
    <Page>
      <Contents title={pageTitle}>
        <ListUsers />
      </Contents>
    </Page>
  )
}
