import {
  leadSearchFilterAttributes,
  TLeadSearchFilterSettings
} from './leadsSearchFilterAttributes'

export const getLeadsSearchFilterSettings = <TSlug extends keyof TLeadSearchFilterSettings>(
  slugs: readonly TSlug[]
) => {
  return slugs.map(slug => leadSearchFilterAttributes[slug])
}
