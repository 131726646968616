// @ts-strict
import {
  ChangeReasonButtonFormDate,
  Contents,
  FormField,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  Section,
  Sections
} from 'components'
import { useCashOfferLeadController } from 'controllers'
import { useCashOfferLeadFields, useCcCashOfferLeadFields, useChangeReasons } from 'lookups'
import { CashOfferLead } from 'models'
import { getCashOfferLeadInputProps } from './utils'

type Props = { cashOfferLead: CashOfferLead }

export const IRSaleTransactionSection = ({ cashOfferLead }: Props) => {
  const { getCashOfferLeadField, getCashOfferLeadFieldName } = useCashOfferLeadFields()
  const { updateCashOfferLead } = useCashOfferLeadController(cashOfferLead.id)
  const { getCcCashOfferLeadFieldName } = useCcCashOfferLeadFields()
  const { getProps } = getCashOfferLeadInputProps(
    cashOfferLead,
    updateCashOfferLead,
    getCashOfferLeadField
  )

  const { coeDateChangeReasonOptions } = useChangeReasons()

  return (
    <Contents>
      <Sections title="Sale Transaction">
        <Section>
          <FormField title="Open Escrow Date" inline>
            <InlineEditDateInput {...getProps('incomingPropertyOpenSellEscrowAt')} />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipSellerCredits')} inline>
            <InlineEditDollarsAndCentsInput {...getProps('ipSellerCredits')} />
          </FormField>
        </Section>
        <Section>
          <FormField title="Target COE Date" inline>
            <InlineEditDateInput {...getProps('ipTargetCloseOfSellEscrowAt')} />
          </FormField>
          <FormField
            title={getCashOfferLeadFieldName('incomingPropertyCloseOfSellEscrowAt')}
            inline
          >
            {!!cashOfferLead.incomingPropertyCloseOfSellEscrowAt ? (
              <ChangeReasonButtonFormDate
                fieldName="incomingPropertyCloseOfSellEscrowAt"
                mutation={updateCashOfferLead}
                reasons={coeDateChangeReasonOptions}
                title={getCcCashOfferLeadFieldName('incomingPropertyCloseOfSellEscrowAt')}
                value={cashOfferLead.incomingPropertyCloseOfSellEscrowAt}
              />
            ) : (
              <InlineEditDateInput {...getProps('incomingPropertyCloseOfSellEscrowAt')} />
            )}
          </FormField>
        </Section>
      </Sections>
    </Contents>
  )
}
