import { Fragment } from 'react'
import { Divider } from 'components/Divider'
import { MessageListItem } from 'content/Message/MessageListItem'
import { Message } from 'models'
import { cx, formatDate } from 'utils'
import styles from './MessagesList.module.scss'

type Props = {
  messages: Message[]
  newMessagesAt: string
}

export const MessagesList = ({ messages, newMessagesAt }: Props) => {
  let shouldPlaceDivider = false
  let hasPlacedDivider = false

  if (!messages) {
    return null
  }

  const dateDivider = (msg, i) => {
    if (i && formatDate(msg.createdAt) === formatDate(messages[i - 1].createdAt)) {
      return null
    }
    return (
      <Divider key={`div-${msg.id}`} className={styles.divider}>
        {formatDate(msg.createdAt)}
      </Divider>
    )
  }

  const newMessageDivider = msg => {
    if (new Date(msg.createdAt) > new Date(newMessagesAt)) {
      if (!msg.outbound) {
        shouldPlaceDivider = true
      }
      return null
    }
    if (shouldPlaceDivider && !hasPlacedDivider) {
      hasPlacedDivider = true
      return (
        <Divider key="new-messages" className={cx(styles.divider, styles.newMessages)}>
          Older Messages
        </Divider>
      )
    }
    return null
  }

  return (
    <ul className={styles.list}>
      {messages.map((message, i) => (
        <Fragment key={message.id}>
          {newMessageDivider(message)}
          {dateDivider(message, i)}
          <MessageListItem message={message} />
        </Fragment>
      ))}
    </ul>
  )
}
