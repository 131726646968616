import { getDialerUrl } from 'services/getUrl'
import { getRequest } from 'utils'

interface ResponseData {
  data: {
    identity: string
    token: string
  }
}

export const fetchToken = async () => {
  const response = (await getRequest(getDialerUrl())) as unknown as ResponseData

  return response.data.token
}
