import { useEffect, useRef, useState } from 'react'
import { Device } from 'twilio-client'
import { Dialer } from 'classes'
import { useQuery } from 'hooks'
import { fetchToken } from 'services'
import { formatTalkTime } from 'utils'

export const useDialer = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [isOnCall, setIsOnCall] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)
  const [callDuration, setCallDuration] = useState('00:00')
  const [activeDialer, setActiveDialer] = useState<Dialer>(null)
  const device = useRef(null)

  const { data: token, isLoading } = useQuery(['dialer_token'], fetchToken, {
    enabled: isOnCall,
    refetchOnWindowFocus: !isOnCall,
    refetchInterval: isOnCall ? false : 60_000,
    refetchOnReconnect: !isOnCall
  })

  useEffect(() => {
    if (isOnCall && token) {
      if (device.current) {
        device.current.destroy()
      }
      device.current = new Device()
      device.current.setup(token, {
        debug: activeDialer.showDebug,
        enableRingingState: true
      })

      const callStart = Date.now()

      device.current.on('ready', () => {
        const connection = device.current.connect(activeDialer.connectionData)

        connection.on('sample', (rtc: any) =>
          setCallDuration(formatTalkTime(rtc.timestamp - callStart))
        )

        connection.on('disconnect', () => {
          device.current.destroy()
          setIsOnCall(false)
        })
      })
    }
  }, [token, isOnCall, activeDialer?.connectionData, activeDialer?.showDebug])

  // Sends Digits/Characters to the active Device Connection for IVR
  const sendKeyToCall = (key: string) => {
    const connection = device.current.activeConnection()
    if (connection && connection.sendDigits) {
      connection.sendDigits(key)
    }
  }

  const toggleExpanded = () => setIsExpanded(isExpanded => !isExpanded)
  const toggleVisible = () => setIsVisible(isVisible => !isVisible)

  const call = (dialer?: Dialer) => {
    if (!isOnCall) {
      if (dialer) {
        setActiveDialer(dialer)
      }
      setCallDuration('00:00')
      setIsVisible(true)
      setIsOnCall(true)
    }
  }

  const endCall = () => device.current.disconnectAll()

  return {
    isLoading,
    isExpanded,
    isVisible,
    isOnCall,
    toggleExpanded,
    toggleVisible,
    sendKeyToCall,
    callDuration,
    call,
    endCall,
    activeDialer
  }
}
