import { BuyerLead, SellerLead } from '../../models'
import { TValidation } from './validate'

export const validateBlindIntroEligible =
  (lead: SellerLead | BuyerLead): TValidation =>
  () => {
    const errors = []

    if (!lead.blindIntro) {
      errors.push('Lead is not eligible for blind intro')
    }
    return errors
  }
