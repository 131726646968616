import { getLeads2Url } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export type PostStopLeadNurturePayload = {
  itemType: string
}

export const postStopLeadNurture = (leadId: string): Promise<any> => {
  return postRequest(
    getLeads2Url({
      id: leadId,
      action: 'unsubscribed-items'
    }),
    snakeCaseKeys({ itemType: 'iterable_nurture' })
  )
}
