// @ts-strict
import { ComponentType, ReactNode } from 'react'
import { Popup } from 'components'
import styles from './PopupButton.module.scss'
import { usePopup } from '.'

type Props = {
  buttonComponent: ComponentType<any>
  buttonText: string
  children: ReactNode
  title: string
}

export const PopupButton = ({
  buttonText,
  children,
  title,
  buttonComponent: ButtonComponent
}: Props) => {
  const { handleClose, showPopup, toggleShowPopup } = usePopup()

  return (
    <>
      <ButtonComponent onClick={toggleShowPopup} title={buttonText}>
        {buttonText}
      </ButtonComponent>
      {showPopup ? (
        <Popup title={title} onCancel={handleClose} className={styles.popup}>
          <div className={styles.popupContent}>{children}</div>
        </Popup>
      ) : null}
    </>
  )
}
