// @ts-strict
import { Flex, Text } from '@foundation/components'
import { Badge } from 'components'
import { Agent } from 'models'
import { dataOrDash, formatDate } from 'utils'
import styles from './AgentBilling.module.scss'
import { SetupPayPerLeadSettingsButtonForm } from './PayPerLeadSettings'

type AgentBillingStatusProps = {
  agent: Agent
}

const AgentBillingStatus = ({ agent }: AgentBillingStatusProps) => {
  const hasProviderSettings = agent.pplProviderSettings && agent.pplProviderSettings.length
  const hasBillingInfos = agent.pplBillingInfos && agent.pplBillingInfos.length

  if (hasProviderSettings && hasBillingInfos) {
    return <PplActive agent={agent} />
  }

  if (hasProviderSettings && !hasBillingInfos) {
    return <PplPending agent={agent} />
  }

  if (!hasProviderSettings && !hasBillingInfos) {
    return <RevShareActive agent={agent} />
  }

  return null
}

const ActiveBadge = () => (
  <Badge title="Active" className={styles.activeBadge}>
    Active
  </Badge>
)

const InactiveBadge = () => (
  <Badge title="Inactive" className={styles.inactiveBadge}>
    Inactive
  </Badge>
)

const WaitingBadge = () => (
  <Badge title="Waiting" className={styles.waitingBadge}>
    Waiting on Agent
  </Badge>
)

const RevShareActive = ({ agent }: { agent: Agent }) => {
  return (
    <Flex gap="$6" flexDirection="column">
      <RevShareSection agent={agent} isActive={true} />

      <Flex gap="$3" flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center" gap="$3">
          <Text size="sm" weight="bold">
            Pay-Per-Lead
          </Text>
          <SetupPayPerLeadSettingsButtonForm agent={agent} />
        </Flex>
      </Flex>
    </Flex>
  )
}

const PplPending = ({ agent }: { agent: Agent }) => {
  return (
    <Flex gap="$6" flexDirection="column">
      <RevShareSection agent={agent} isActive={true} />
      <Flex gap="$3" flexDirection="column">
        <Flex justifyContent="normal" gap="$3">
          <Text size="sm" weight="bold">
            Pay-Per-Lead
          </Text>
          <WaitingBadge />
        </Flex>
      </Flex>
    </Flex>
  )
}

const PplActive = ({ agent }: { agent: Agent }) => {
  return (
    <Flex gap="$6" flexDirection="column">
      <Flex gap="$3">
        <Text size="sm" weight="bold">
          Pay-Per-Lead
        </Text>
        <ActiveBadge />
      </Flex>
      <RevShareSection agent={agent} isActive={false} />
    </Flex>
  )
}

const RevShareSection = ({ agent, isActive }: { agent: Agent; isActive: boolean }) => (
  <Flex flexDirection="column" justifyContent="normal" gap="$1">
    <Flex gap="$3">
      <Text size="sm" weight="bold">
        Rev Share
      </Text>
      {isActive ? <ActiveBadge /> : <InactiveBadge />}
    </Flex>
    <Text size="xs">
      {`${dataOrDash(agent.lastSignedAgreement?.agreement?.version)},
          ${formatDate(agent.lastSignedAgreement?.signedAt)},
          ${dataOrDash(agent.lastSignedAgreement?.agreement?.commissionSplit)}%`}
    </Text>
  </Flex>
)

export default AgentBillingStatus
