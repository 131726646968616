import { environment } from 'environment'

export const getQualiaUrl = (orderNumber: string = '', state?: string): string => {
  switch (environment.env) {
    case 'production': {
      if (state && ['CO', 'MI', 'COLORADO', 'MICHIGAN'].includes(state.toUpperCase())) {
        return `https://homelightcolorado.qualia.io/orders/${orderNumber}/dashboard`
      }

      return `https://homelighttitle.qualia.io/orders/${orderNumber}/dashboard`
    }
    case 'staging':
    case 'development': {
      return `https://sandbox-homelight.qualia.io/orders/${orderNumber}/dashboard`
    }
    default: {
      return ''
    }
  }
}
