import { EligibilityCheckResponse } from 'schema'
import { titleize } from 'utils'
import { Model } from '../Model'

export class EligibilityCheck extends Model {
  agentFee?: string
  bbysFee?: string
  closingCost?: string
  denialChecks?: {}
  eligibilityChecks?: {}
  eligibilityStatus?:
    | 'eligible'
    | 'not_eligible'
    | 'potentially_eligible'
    | 'property_not_found'
    | 'requires_info'
  errors?: String[]
  fallback?: boolean
  highEndRange?: string
  id?: string
  inspectionCost?: string
  lowEndRange?: string
  modelName? = 'EligibilityCheck'
  propertyAddress?: string
  validEquity?: boolean
  warningChecks?: {}

  constructor(eligibilityCheckAttributes: EligibilityCheckResponse) {
    super()
    Object.assign(this, eligibilityCheckAttributes)
  }

  static create(
    eligibilityCheckResponse: Potential<EligibilityCheckResponse>
  ): Potential<EligibilityCheck> {
    if (!eligibilityCheckResponse) {
      return
    }

    return new EligibilityCheck(eligibilityCheckResponse)
  }

  hasWarnings(): boolean {
    return Object.values(this.warningChecks).some(field => field)
  }

  hasErrors(): boolean {
    return Object.values(this.denialChecks).some(field => field)
  }

  humanReadableErrors(): string[] {
    const errors = { ...this.denialChecks, ...this.warningChecks }

    return Object.keys(errors)
      .filter(key => errors?.[key])
      .map(key => titleize(key, /([A-Z][a-z]+)/))
  }
}
