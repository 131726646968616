// @ts-strict
import { FormField, Section, Sections, TButtonFormFieldsProps, TextInput } from 'components'
import type { UserButtonFormPayload } from './useUserButtonForm'

type Props = TButtonFormFieldsProps<UserButtonFormPayload>
export const UserButtonFormFields = ({ payload, onChangeAttribute }: Props) => (
  <>
    <Sections>
      <Section>
        <FormField title="First Name" htmlFor="userFirstName" required>
          <TextInput
            id="userFirstName"
            data-testid="firstName-field"
            onChange={val => onChangeAttribute('firstName', val)}
            value={payload.firstName}
          />
        </FormField>
      </Section>
      <Section>
        <FormField title="Last Name" htmlFor="userLastName" required>
          <TextInput
            id="userLastName"
            data-testid="lastName-field"
            onChange={val => onChangeAttribute('lastName', val)}
            value={payload.lastName}
          />
        </FormField>
      </Section>
    </Sections>
    <Sections>
      <Section>
        <FormField title="Email" htmlFor="userEmail" required>
          <TextInput
            id="userEmail"
            data-testid="email-field"
            onChange={val => onChangeAttribute('email', val)}
            value={payload.email}
          />
        </FormField>
      </Section>
      <Section />
    </Sections>
  </>
)
