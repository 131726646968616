import { forwardRef } from 'react'
import { TTypeaheadSelectProps, TypeaheadSelect } from './TypeaheadSelect'

// prepopulate based current year - 100
export const yearOptions = Array.from(
  [...Array(100)],
  (_val, idx) => new Date().getFullYear() - idx
).map(year => ({
  label: year.toString(),
  value: year.toString()
}))

type TProps = Omit<TTypeaheadSelectProps, 'options'> & {
  options?: { label: string; value: string }[]
}

export const YearSelect = forwardRef<HTMLDivElement, TProps>(({ options, ...props }, ref) => (
  <TypeaheadSelect {...props} options={yearOptions} ref={ref} />
))
