import React from 'react'
import { AutoComplete, AutoCompleteProps } from '../../../../components'
import { getUsersUrl } from '../../../../services/getUrl'
import { getRequest } from '../../../../utils'

type TProps = {
  onSelect?: AutoCompleteProps['onSelect']
  value?: string
}

export const UsersAutocomplete = ({ onSelect = () => {}, value, ...props }: TProps) => {
  const formatUser = ({ id, attributes: { name, email } }) => ({ id, title: name, subTitle: email })
  const formatUsers = users => users.map(formatUser)

  const getUsers = async (searchTerm: string) => {
    const searchParams = {
      'filter[_search]': searchTerm,
      autocomplete: true,
      'page[size]': 10
    }
    const response = await getRequest(getUsersUrl({ searchParams }))
    return formatUsers(response.data.data).slice(0, 10)
  }

  return (
    <AutoComplete
      getData={getUsers}
      onSelect={onSelect}
      value={value}
      placeholder="Enter user name or email..."
      {...props}
    />
  )
}
