import { Section, Sections } from 'components'
import { useFields } from '..'
import { FieldFormField } from '../FieldFormField'

const econModel = {
  readOnly: true,
  title: 'Editable only on Economic Model'
}

export const CCTradeInApproved = () => {
  useFields({
    required: [
      'departingPropertyPreliminaryTitleReviewed',
      'departingPropertyGuaranteedPrice',
      'dpTargetTradeInFeeRate',
      'dpSellersAgentCommissionRate',
      'dpBuyersAgentCommissionRate',
      'departingPropertyValuation',
      'dpEstimatedListToPending',
      'dpEstimatedDaysOfWork'
    ],
    fields: [
      'departingPropertyExternalLoanPayoff',
      'departingPropertyLoanPayoff',
      'dpLocalTransferTaxRate'
    ]
  })
  return (
    <>
      <Sections title="DR Property Qualification">
        <Section>
          <FieldFormField field="departingPropertyPreliminaryTitleReviewed" />
          <FieldFormField field="departingPropertyExternalLoanPayoff" {...econModel} />
        </Section>
        <Section>
          <FieldFormField field="departingPropertyLoanPayoff" {...econModel} />
        </Section>
      </Sections>
      <Sections title="DR Property Valuation">
        <Section>
          <FieldFormField field="departingPropertyGuaranteedPrice" {...econModel} />
        </Section>
        <Section>
          <FieldFormField field="departingPropertyValuation" {...econModel} />
        </Section>
      </Sections>
      <Sections title="Expense Inputs">
        <Section>
          <FieldFormField field="dpBuyersAgentCommissionRate" {...econModel} />
          <FieldFormField field="dpSellersAgentCommissionRate" {...econModel} />
        </Section>
        <Section>
          <FieldFormField field="dpLocalTransferTaxRate" {...econModel} />
          <FieldFormField field="dpTargetTradeInFeeRate" {...econModel} />
        </Section>
      </Sections>
      <Sections title="DR Property Assessment">
        <Section>
          <FieldFormField field="dpEstimatedListToPending" />
        </Section>
        <Section>
          <FieldFormField field="dpEstimatedDaysOfWork" />
        </Section>
      </Sections>
    </>
  )
}
