import { getSearchLoanOfficersUrl } from 'services/getUrl'
import { getRequest } from 'utils'

export const searchLoanOfficers = async (search: string) => {
  const url = getSearchLoanOfficersUrl({ searchParams: { search } })

  const { data } = await getRequest(url)

  return {
    loanOfficers: data.loan_officers
  }
}
