import { TEnrollmentStatus } from '../types'

export const getWarmTransferFilter = (warmTransfer: Potential<TEnrollmentStatus>) => {
  const filters = {}
  if (warmTransfer === 'enrolled') {
    filters['filter[capabilities.enrolled_warm_transfer]'] = true
  } else if (warmTransfer === 'declined') {
    filters['filter[capabilities.declined_warm_transfer]'] = true
  } else if (warmTransfer === 'unenrolled') {
    filters['filter[capabilities.enrolled_warm_transfer]'] = false
  }

  return filters
}
