// @ts-strict

import { ReactNode } from 'react'
import { Menu, TMenuTitleProps } from 'components'
import styles from './Filters.module.scss'

type FiltersProps = {
  children: ReactNode
  title?: ReactNode
} & Pick<TMenuTitleProps, 'icon'>

export const Filters = ({ children, title = 'Filters', icon, ...props }: FiltersProps) => {
  return (
    <Menu title={title} {...props} className={styles.filters} icon={icon}>
      {children}
    </Menu>
  )
}
