import { Option } from 'classes'
import { FormField, Select } from 'components'
import { useLeadContext } from 'contexts'
import { isCCBBYSLead } from 'models'
import { TNewLeadUserPayload } from './LeadUsersNewLeadUserButtonForm'

export const LeadUsersNewLeadUserFormFields = ({
  payload,
  onChangeAttribute,
  transactionTeamsMembersOptions,
  transactionTeamRoleOptions
}: {
  onChangeAttribute: <K extends keyof TNewLeadUserPayload>(
    key: K,
    value: TNewLeadUserPayload[K]
  ) => void
  payload: TNewLeadUserPayload
  transactionTeamRoleOptions: Option[]
  transactionTeamsMembersOptions: Option[]
}) => {
  const { lead } = useLeadContext()
  const isBbysLead = lead ? isCCBBYSLead(lead) : false
  const validBbysLeadUserRoles = [
    'agent_sales_owner',
    'contract_advisor',
    'lender_operations_specialist',
    'lender_relationship_manager',
    'lender_sales_manager',
    'listing_operations_specialist',
    'lo_sales_owner',
    'processor',
    'strategic_relationship_manager',
    'transaction_specialist'
  ]
  const bbysValidRoleOptions = transactionTeamRoleOptions.filter(x =>
    validBbysLeadUserRoles.includes(x.value)
  )
  return (
    <>
      <FormField title="User" htmlFor="userSelect" required>
        <Select
          id="userSelect"
          onChange={val => onChangeAttribute('userId', val)}
          name="user"
          options={transactionTeamsMembersOptions}
          value={payload.userId}
          data-testid="user-field"
          addBlank
        />
      </FormField>
      <FormField title="Role" htmlFor="roleSelect" required>
        <Select
          id="roleSelect"
          onChange={val => onChangeAttribute('role', val)}
          name="role"
          options={isBbysLead ? bbysValidRoleOptions : transactionTeamRoleOptions}
          value={payload.role}
          data-testid="role-field"
          addBlank
        />
      </FormField>
    </>
  )
}
