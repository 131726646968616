import { presence } from 'utils/presence'
import { TValidation } from './validate'

export const validatePhoneFormat: TValidation = ({ label, value }) => {
  if (!presence(value)) {
    return []
  }

  const errors = []
  const cleanValue = typeof value === 'string' ? value : JSON.stringify(value)

  if (!/((\(\d{3}\)?)|(\d{3}))([\s-./]?)(\d{3})([\s-./]?)(\d{4})/.test(cleanValue)) {
    errors.push(`${label} is invalid`)
  }

  return errors
}
