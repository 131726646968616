import { Metroplex } from 'models'
import { getConstantsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchMetroplexes = async (): Promise<Metroplex[]> => {
  const response = await getRequest(
    getConstantsUrl({
      searchParams: {
        types: 'metroplexes'
      }
    })
  )
  const metroPlexes = await deserializeAsync(response.data, {
    transform: Metroplex.create
  })
  return metroPlexes
}
