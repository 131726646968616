import { toOptions } from 'lookups/utils'
import { bySlug } from 'utils'
import { buildLeadStages } from '../lookupLeadStages'
import { EscrowLeadStage } from './EscrowLeadStage'
import { escrowLeadStagesSettings, TEscrowLeadStagesSlug } from './escrowLeadStagesSettings'

export const lookupEscrowLeadStages = buildLeadStages(escrowLeadStagesSettings, EscrowLeadStage)

export const getEscrowLeadStage = (slug: TEscrowLeadStagesSlug) => {
  return lookupEscrowLeadStages.find(bySlug(slug))
}

export const getEscrowLeadStageName = (slug: TEscrowLeadStagesSlug) => {
  return getEscrowLeadStage(slug)?.name
}

export const escrowLeadStagesOptions = toOptions(lookupEscrowLeadStages)
