// @ts-strict
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Contents, List, LoadingText } from 'components'
import { AgentTeamActionsSection, filterAgentTeamMemberships } from 'content/Agent/AgentTeam'
import { AgentTeamMembershipsTable } from 'content/AgentTeamMemberships/AgentTeamMembershipsTable'
import { useAgentContext } from 'contexts/Agent'
import { AgentTeamProvider, useAgentTeamContext } from 'contexts/AgentTeam'
import { useParamsContext } from 'contexts/Params'
import { useDocumentTitle } from 'hooks'
import { presence } from 'utils'

const AgentTeamTabContents = () => {
  const { agent } = useAgentContext()
  const { agentTeamQuery } = useAgentTeamContext()
  const { params } = useParamsContext()

  const agentTeam = agentTeamQuery.data

  if (!presence(agent)) {
    return null
  }

  if (agentTeamQuery.isLoading) {
    return <LoadingText />
  }

  const agentIsTeamMember = !agentTeam && !!agent!.agentTeamMemberships?.length

  if (agentIsTeamMember) {
    return (
      <Contents title="Agent Team">
        <List>
          {agent!.agentTeamMemberships!.map(mem => (
            <Link to={`/agents/${mem.agentTeam!.agent!.id}/team`}>
              A member of {mem.agentTeam!.name}
            </Link>
          ))}
        </List>
      </Contents>
    )
  }

  return (
    <Contents title="Agent Team">
      <>
        <AgentTeamActionsSection agent={agent} agentTeam={agentTeam} />
        <AgentTeamMembershipsTable
          agentTeamMemberships={filterAgentTeamMemberships(agentTeam?.agentTeamMemberships, params)}
        />
      </>
    </Contents>
  )
}

export const AgentTeamTab = () => {
  const { agent } = useAgentContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${agent?.fullName} - Agent Team`), [agent, setTitle])

  return (
    <AgentTeamProvider agentId={agent?.id}>
      <AgentTeamTabContents />
    </AgentTeamProvider>
  )
}
