import { Section, Sections } from 'components'
import { useFeatureFlags } from 'hooks'
import { useFields } from '..'
import { FieldFormField } from '../FieldFormField'

export const CCTradeInClosedPaid = () => {
  const { salesAppRevenueRecognition } = useFeatureFlags(['sales-app-revenue-recognition'])
  useFields({
    required: [
      'departingPropertyFundsWiredToClientAt',
      // this field must be removed after removing the feature flag
      !salesAppRevenueRecognition.enabled ? 'revenueRecognitionDate' : undefined
    ]
  })

  return (
    <Sections>
      <Section title="DR Sale Transaction">
        <FieldFormField field="departingPropertyFundsWiredToClientAt" />
      </Section>
      {/* this field must be removed after removing the feature flag */}
      {!salesAppRevenueRecognition.enabled && (
        <Section title="Financing">
          <FieldFormField field="revenueRecognitionDate" />
        </Section>
      )}
    </Sections>
  )
}
