import { amber, black, brand, cyan, ironGray, lime, purple, white } from './colors'

const theme = {
  borderWidths: {
    thin: '1px',
    thick: '2px',
    thicker: '4px',
    thickest: '8px'
  },
  colors: {
    black,
    white,

    /**
     * brand colors
     */
    ...brand,

    /**
     * decorative colors
     * these colors are exceptions and do not relate to the brand colors
     */
    decorative1: amber.$5,
    decorative2: cyan.$5,
    decorative3: lime.$5,
    decorative4: purple.$5,
    decorative5: ironGray.$9
  },
  fonts: {
    base: '"Open Sans", "notoSans", "San Francisco", "-apple-system",  "HelveticaNeue", helvetica, roboto, droid serif, arial, sans-serif'
  },
  fontSizes: {
    xxs: '1.0rem', // 10px
    xs: '1.2rem', // 12px
    sm: '1.4rem', // 14px
    base: '1.6rem', // 16px
    lg: '1.8rem', // 18px
    xl: '2rem', // 20px
    xl2: '2.4rem' // 24px
  },
  fontWeights: {
    normal: 400,
    semibold: 600,
    bold: 700
  },
  lineHeights: {
    xs: '1.6rem', // 16px
    sm: '2rem', // 20px
    base: '2.4rem', // 24px
    lg: '2.8rem', // 28px
    xl: '2.8rem', // 28px
    xl2: '3.2rem' // 32px
  },
  radii: {
    base: '0.4rem', // 4px
    md: '0.6rem', // 6px
    lg: '0.8rem', // 8px
    xl: '1.2rem', // 12px
    rounded: '9999px'
  },
  shadows: {
    sm: '0 1px 2px 0 rgb(0 0 0 / 0.1)',
    base: '0 1px 3px 0 rgb(0 0 0 / 0.14), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    md: '0 4px 6px -1px rgb(0 0 0 / 0.14), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    lg: '0 10px 15px -3px rgb(0 0 0 / 0.14), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    xl: '0 20px 25px -5px rgb(0 0 0 / 0.14), 0 8px 10px -6px rgb(0 0 0 / 0.1)'
  },
  space: {
    $1: '0.4rem', // 4px
    $2: '0.6rem', // 6px
    $3: '0.8rem', // 8px
    $4: '1.2rem', // 12px
    $5: '1.6rem', // 16px
    $6: '2rem', // 20px
    $7: '2.4rem', // 24px
    $8: '3.2rem' // 32px
  },
  sizes: {
    $1: '0.2rem' /* 2px */,
    $2: '0.4rem' /* 4px */,
    $3: '0.6rem' /* 6px */,
    $4: '0.8rem' /* 8px */,
    $5: '0.1rem' /* 10px */,
    $6: '1.2rem' /* 12px */,
    $7: '1.4rem' /* 14px */,
    $8: '1.6rem' /* 16px */,
    $9: '1.8rem' /* 18px */,
    $10: '2rem' /* 20px */,
    $11: '2.2rem' /* 22px */,
    $12: '2.4rem' /* 24px */,
    $13: '2.6rem' /* 26px */,
    $14: '2.8rem' /* 28px */,
    $15: '3rem' /* 30px */,
    $16: '3.2rem' /* 32px */,
    $17: '3.4rem' /* 34px */,
    $18: '3.6rem' /* 36px */,
    $19: '3.8rem' /* 38px */,
    $20: '4rem' /* 40px */,
    $21: '4.2rem' /* 42px */,
    $22: '4.4rem' /* 44px */,
    $23: '4.6rem' /* 46px */,
    $24: '4.8rem' /* 48px */,
    $28: '5.6rem' /* 56px */,
    $32: '6.4rem' /* 64px */,
    $40: '8rem' /* 80px */,
    $48: '9.6rem' /* 96px */
  },
  // Warning: Avoid customizing these values. They are designed to all work together.
  // The pattern here starts with an arbitrary number and is incremented by +100 (just to keep it consistent).
  zIndices: {
    base: 0,
    sticky: 1000,
    overlay: 1100,
    drawer: 1200,
    modal: 1300,
    popover: 1400,
    dropdown: 1500,
    toast: 1600,
    tooltip: 1700
  },
  motions: {
    duration: {
      none: 0,
      short: 300,
      longest: 700
    }
  }
} as const

export type Theme = typeof theme

export default theme
