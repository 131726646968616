import { useState } from 'react'
import { InvestorPayPerLeadSetting } from 'models'

export const useLocationSettingsData = (initialLocationSettings?: InvestorPayPerLeadSetting[]) => {
  const [locationSettings, setLocationSettings] = useState<InvestorPayPerLeadSetting[]>(
    initialLocationSettings || []
  )

  const addLocationSettings = (slugs: string | string[], userTypes: Array<string>) => {
    setLocationSettings(prevLocationSettings => {
      const uniqueSettings = new Set(
        prevLocationSettings.map(setting => `${setting.location}-${setting.userType}`)
      )

      const newSettings = []

      const slugArray = Array.isArray(slugs) ? slugs : [slugs]

      slugArray.forEach(slug => {
        userTypes.forEach(userType => {
          const settingKey = `${slug}-${userType}`
          if (!uniqueSettings.has(settingKey)) {
            uniqueSettings.add(settingKey)
            newSettings.push({ active: false, location: slug, userType: userType })
          }
        })
      })

      return [...prevLocationSettings, ...newSettings]
    })
  }

  const removeLocationSetting = (locationSlug: string) => {
    const newLocationSettings = locationSettings.filter(ls => ls.location !== locationSlug)
    setLocationSettings(newLocationSettings)
  }

  const toggleActive = (index: number) => {
    const newLocationSettings = [...locationSettings]
    if ((newLocationSettings[index].price ?? 0) === 0) {
      return
    }

    newLocationSettings[index].active = !newLocationSettings[index].active
    setLocationSettings(newLocationSettings)
  }

  const setPrice = (index: number, price: number) => {
    const newLocationSettings = [...locationSettings]
    const setting = newLocationSettings[index]

    setting.price = price > 0 ? price : null
    setting.active = price > 0

    setLocationSettings(newLocationSettings)
  }

  return {
    locationSettings,
    addLocationSettings,
    removeLocationSetting,
    toggleActive,
    setPrice
  }
}
