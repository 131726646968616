import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMap } from 'react-use'
import { useLeadContext } from 'contexts'
import { useFeatureFlags } from 'hooks'
import {
  getCcTradeInLeadExpressStage,
  getCcTradeInLeadStage,
  getCcTradeInLeadStageWithLeftMessage,
  getCcTradeInLeadStageWithoutPitched,
  TCcTradeInLeadFieldAttribute,
  TCcTradeInLeadStagesSlug
} from 'lookups'
import { CCTradeInLead } from 'models'
import { asArray, presence, stringifyValue } from 'utils'

export type CCTradeInLeadDispositionPayload = {
  disposition?: TCcTradeInLeadStagesSlug
  note?: string
  occurredAt?: string
  reasonForFail?: string
}

export const useCCTradeInLeadDisposition = (lead: CCTradeInLead) => {
  const { updateProviderLeadDisposition, uncompletedLeadTasks } = useLeadContext()
  const [commonPayload, { set: setCommonPayloadItem, setAll: setCommonPayload }] =
    useMap<CCTradeInLeadDispositionPayload>()
  const [commonRequiredFields, setCommonRequiredFields] = useState<string[]>([])
  const [dispositionPayload, { set: setDispositionPayloadItem, setAll: setDispositionPayload }] =
    useMap<any>()
  const [requiredFields, setRequiredFields] = useState([])
  const [payloadFields, setPayloadFields] = useState([])
  const { leftMessageStage, salesOpsPitchStageRemoval, expressApproval } = useFeatureFlags([
    'left-message-stage',
    'sales-ops-pitch-stage-removal',
    'express-approval'
  ])
  const tradeInLead = lead.tradeInLead

  const pitchStageRemovalValue =
    salesOpsPitchStageRemoval.enabled && getCcTradeInLeadStageWithoutPitched
  const leftMessageStageValue = leftMessageStage.enabled && getCcTradeInLeadStageWithLeftMessage
  const expressApprovalStageValue =
    expressApproval &&
    (tradeInLead?.approvalType ?? 'express') === 'express' &&
    getCcTradeInLeadExpressStage

  const getStage =
    expressApprovalStageValue ||
    pitchStageRemovalValue ||
    leftMessageStageValue ||
    getCcTradeInLeadStage

  const reasonForFailOtherIsValid =
    dispositionPayload?.reasonForFail === 'other' ? presence(dispositionPayload?.note) : true

  // determines if form is valid based on required fields.
  const isValid = useMemo(() => {
    return (
      requiredFields.every(field => presence(dispositionPayload[field])) &&
      commonRequiredFields.every(field => presence(commonPayload[field])) &&
      reasonForFailOtherIsValid
    )
  }, [
    dispositionPayload,
    commonPayload,
    commonRequiredFields,
    requiredFields,
    reasonForFailOtherIsValid
  ])

  useEffect(() => {
    if (lead && payloadFields && setDispositionPayload) {
      setDispositionPayload(currentDispositionPayload =>
        payloadFields.reduce((acc, field) => {
          return {
            ...acc,
            [field]:
              currentDispositionPayload[field] ||
              stringifyValue(lead.tradeInLead[field]) ||
              stringifyValue(lead[field]) ||
              stringifyValue(lead.tradeInProviderLead[field])
          }
        }, {})
      )
    }
  }, [lead, payloadFields, setDispositionPayload])

  const registerFields = useCallback(
    ({ fields, required }: { fields?: string | string[]; required?: string | string[] }) => {
      setPayloadFields([...asArray(fields).filter(Boolean), ...asArray(required).filter(Boolean)])
      setRequiredFields([...asArray(required).filter(Boolean)])
    },
    []
  )

  const unregisterFields = useCallback(() => {
    setPayloadFields([])
    setRequiredFields([])
  }, [])

  const onHandleSubmit = (onSuccessCallback: () => void) => {
    updateProviderLeadDisposition.mutate(
      {
        leadId: lead.id,
        id: lead.tradeInProviderLead.id,
        payload: { ...dispositionPayload, ...commonPayload }
      },
      { onSuccess: onSuccessCallback }
    )
  }

  const currentStage = getStage(lead.stage as TCcTradeInLeadStagesSlug)
  const furthestStage = getStage(lead.furthestStage as TCcTradeInLeadStagesSlug)
  const newStage = getStage('new')

  const selectedStage = getStage(commonPayload.disposition!)

  const uncompletedTasks: string[] = uncompletedLeadTasks
    .filter(task => task.requiredForLeadStage === selectedStage?.slug)
    .map(item => item.title)

  const showSubmitForm = lead.isHLSS || !presence(uncompletedTasks)

  const changes = useMemo(
    () =>
      Object.keys(dispositionPayload).reduce<
        Partial<Record<TCcTradeInLeadFieldAttribute, boolean>>
      >((agr, key) => {
        if (dispositionPayload[key] !== tradeInLead[key]) {
          return { ...agr, [key]: true }
        }
        return agr
      }, {}),
    [tradeInLead, dispositionPayload]
  )

  return {
    changes,
    commonPayload,
    dispositionPayload,
    isLoading: updateProviderLeadDisposition.isLoading,
    isValid,
    onHandleSubmit,
    currentStage,
    furthestStage,
    newStage,
    selectedStage,
    registerFields,
    requiredFields,
    setCommonPayload,
    setCommonPayloadItem,
    setCommonRequiredFields,
    setDispositionPayloadItem,
    showSubmitForm,
    tradeInLead,
    uncompletedTasks,
    unregisterFields
  }
}
