// @ts-strict
import { useMemo } from 'react'
import { useListContext } from 'contexts'
import { SearchFilter } from 'lookups'
import { isEmptyValue } from 'utils'
import styles from './SearchStatus.module.scss'
import { SearchStatusFilter } from './SearchStatusFilter'
import { SearchStatusPage } from './SearchStatusPage'
import { SearchStatusPrompt } from './SearchStatusPrompt'
import { SearchStatusSearchTerm } from './SearchStatusSearchTerm'
import { SearchStatusSort } from './SearchStatusSort'

type SearchStatusProps = {
  count?: number
  params?: Record<string, any>
  type?: string
  unfilteredText?: string
}

export const SearchStatus = ({
  params = {},
  count,
  type = '',
  unfilteredText
}: SearchStatusProps) => {
  const { page, searchTerm, sortBy, sortDir } = params

  const { findFilterBySlug } = useListContext()

  const validSearchFilters = useMemo(
    () =>
      Object.keys(params)
        .map(key => findFilterBySlug(key))
        .filter(Boolean),
    [params, findFilterBySlug]
  ) as SearchFilter[]

  const isEmpty =
    isEmptyValue(validSearchFilters) &&
    isEmptyValue(searchTerm) &&
    isEmptyValue(page) &&
    isEmptyValue(sortBy)

  const sortFilter = findFilterBySlug(sortBy)

  return (
    <div className={styles.searchStatus}>
      <SearchStatusPrompt count={count} type={type} />

      {isEmpty ? (
        unfilteredText
      ) : (
        <>
          <SearchStatusSearchTerm value={searchTerm} />
          {validSearchFilters.map(filter => (
            <SearchStatusFilter key={filter.slug} value={params[filter.slug]} filter={filter} />
          ))}
          <SearchStatusSort sortBy={sortBy} name={sortFilter?.name} sortDir={sortDir} />
          <SearchStatusPage value={page} />
        </>
      )}
    </div>
  )
}
