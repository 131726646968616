import { css, styled } from '@foundation/themes/styles'

export const ListFiltersFooter = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.space.$2};
    border-top: solid 1px ${theme.colors.neutral5};
    margin-top: ${theme.space.$4};
    padding-top: ${theme.space.$4};
  `}
`
