import { useButtonForm } from 'components'
import { useTokenContext } from 'contexts'
import { validateEmailFormat, validatePresence } from 'utils'

export type TSignInFormFieldsPayload = Record<'email' | 'password', string>

const initialPayload: TSignInFormFieldsPayload = { email: '', password: '' }

export const useSignInFormButton = () => {
  const { signIn } = useTokenContext()

  const transformPayload = (payload: TSignInFormFieldsPayload): TSignInFormFieldsPayload => ({
    email: payload.email.toLowerCase(),
    password: payload.password
  })

  const formValidations = {
    email: [validatePresence, validateEmailFormat],
    password: validatePresence
  }

  const { fieldsProps, formProps } = useButtonForm<TSignInFormFieldsPayload>({
    initialPayload,
    mutation: signIn,
    transformPayload,
    formValidations
  })

  return { fieldsProps, formProps }
}
