import { useState } from 'react'
import { Tab } from './Tab'
import { Tabs } from './Tabs'

export const TabbedContent = ({ content }) => {
  const keys = Object.keys(content)
  const firstTab = keys[0]
  const [selectedTab, setSelectedTab] = useState(firstTab)
  const isSelectedTabAvailable = keys.includes(selectedTab)
  const selectedTabReference = isSelectedTabAvailable ? selectedTab : firstTab

  if (selectedTabReference !== selectedTab) {
    setSelectedTab(selectedTabReference)
  }

  return (
    <>
      <Tabs>
        {Object.keys(content).map(tabName => (
          <Tab
            as="button"
            onClick={() => setSelectedTab(tabName)}
            isSelected={selectedTab === tabName}
            key={tabName}
          >
            {tabName}
          </Tab>
        ))}
      </Tabs>
      {content[selectedTab]}
    </>
  )
}
