import { asArray } from '../index'
import { TValidation } from './validate'

export const validateMatch =
  (match: string | string[]): TValidation =>
  ({ label, value }) => {
    const errors = []

    if (!asArray(match).includes(value)) {
      errors.push(`${label} does not match '${asArray(match).join(', ')}'`)
    }
    return errors
  }
