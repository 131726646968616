import { LEADS_CACHE_KEY, useQuery } from 'hooks'
import { fetchLeads, TLeadsSearchParams } from 'services'
import { getPageCount } from 'utils'

const defaultSearchSettings: Pick<TLeadsSearchParams, 'sortDir' | 'perPage' | 'sortBy'> = {
  sortBy: 'Name',
  sortDir: 'desc',
  perPage: 25
}

export const useLeadsController = (searchParams: TLeadsSearchParams) => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  const { data, isLoading } = useQuery([LEADS_CACHE_KEY, searchSettings], () =>
    fetchLeads(searchSettings)
  )

  const leads = data?.leads
  const totalCount = data?.count
  const pageCount = getPageCount(totalCount, searchSettings.perPage)

  return { leads, totalCount, pageCount, isLoading }
}
