// @ts-strict
import { InputHTMLAttributes, useRef } from 'react'
import { cx } from 'utils'
import formStyles from '../Form.module.scss'
import styles from './FileInput.module.scss'

type TProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  onChange?: (files: File[]) => void
  value?: File[]
}

export const FileInput = ({ value = [], onChange = () => {}, className, ...rest }: TProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleClick = () => {
    inputRef?.current?.click()
  }

  const chosenFiles = !!value.length ? value.map(f => f.name).join(', ') : 'No file chosen'

  return (
    <div className={cx(styles.wrapper, formStyles.input, className)}>
      <button onClick={handleClick} className={styles.button} type="button">
        Choose Files
      </button>
      {chosenFiles}
      <input
        className={styles.input}
        type="file"
        onChange={e => {
          onChange([...e.target.files!])
        }}
        ref={inputRef}
        {...rest}
      />
    </div>
  )
}
