import { FormField, InlineEditDateInput, InlineEditSelect, Section, Sections } from 'components'
import { AgentSection } from 'content/Agent/AgentSection'
import { useHLSimpleSaleLeadController } from 'controllers'
import { sourcesOptions, trueFalseOptions, useFailedReasons } from 'lookups'
import { d, dataOrDash } from 'utils'
import { HLSSLeadDetailsProps } from '../HLSSLeadDetails'
import { getFieldValues } from '../utils'
import { getOrderInputProps } from './utils'

type Props = Pick<HLSSLeadDetailsProps, 'lead' | 'updateLead' | 'updateOrder'>

export const HLSSLeadDetailsSection = ({ lead, updateLead, updateOrder }: Props) => {
  const referringAgent = lead.referringAgent
  const hlSimpleSaleLead = lead.hlSimpleSaleLead!
  const { hlSimpleSaleFailedReasonOptions: failedReasons } = useFailedReasons()
  const { updateHLSimpleSaleLead } = useHLSimpleSaleLeadController(hlSimpleSaleLead.id)
  const { getFieldValue } = getFieldValues(hlSimpleSaleLead)
  const teamMembers = lead.leadUsers?.map(leadUser => leadUser.prettyName).join(', ')

  const { getProps: getOrderProps } = getOrderInputProps(lead.order, updateOrder)

  const showReferringAgent =
    lead.order?.source === 'accept' ||
    lead.order?.source === 'agent' ||
    lead.order?.source === 'disclosures'

  const failedReason = failedReasons.find(failedReason => {
    return failedReason.value === lead?.hlSimpleSaleProviderLead?.reasonForFail
  })

  return (
    <>
      <Sections title={`HLSS (${d(lead.stage)})`}>
        <Section>
          <FormField title="Date Received" inline>
            <InlineEditDateInput {...getOrderProps('createdAt')} />
          </FormField>
          <FormField title="Team Members" inline>
            {teamMembers}
          </FormField>
          <FormField title="Source" inline>
            <InlineEditSelect {...getOrderProps('source')} options={sourcesOptions} />
          </FormField>
          {lead.isFailed && (
            <FormField title="Failed Reason" inline>
              {dataOrDash(failedReason?.name)}
            </FormField>
          )}
          <FormField title="2 Check HLSS" inline>
            <InlineEditSelect
              onBlur={val => updateHLSimpleSaleLead.mutate({ twoCheck: val })}
              value={getFieldValue('twoCheck')}
              options={trueFalseOptions}
              addBlank={false}
            />
          </FormField>
        </Section>

        {showReferringAgent ? (
          <Section>
            <AgentSection
              agent={referringAgent}
              program="hlss"
              onBlur={val =>
                updateLead({
                  referringOwnerId: val,
                  referringOwnerType: 'Agent'
                })
              }
            />
          </Section>
        ) : null}
      </Sections>
    </>
  )
}
