// @ts-strict
import { useMutation, useQueryClient } from 'react-query'
import { useParamsContext } from 'contexts'
import { CreateExternalLinkVariables } from 'contexts/types'
import { useQuery } from 'hooks'
import { Lead } from 'models'
import { fetchExternaLinks, postExternalLink } from 'services'
import { ExternalLinksParams } from 'services/ExternalLinks/getSearchParams'
import { getPageCount } from 'utils'
import { EXTERNAL_LINKS_CACHE_KEY } from '../cache'

const defaultSearchSettings: Pick<ExternalLinksParams, 'perPage' | 'sortBy' | 'sortDir'> = {
  perPage: 10,
  sortBy: 'createdAt',
  sortDir: 'desc'
}

export const useExternalLinksController = (lead: Lead) => {
  const queryCache = useQueryClient()
  const { searchParams } = useParamsContext()
  const defaultCallback = {
    onSuccess: () => {
      queryCache.invalidateQueries(EXTERNAL_LINKS_CACHE_KEY)
    }
  }
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams,
    attachableId: lead.id,
    attachableType: 'Lead' as 'Lead'
  }

  const externalLinksQuery = useQuery(
    [EXTERNAL_LINKS_CACHE_KEY, searchSettings],
    () => {
      return fetchExternaLinks(searchSettings)
    },
    { refetchOnWindowFocus: false }
  )

  const externalLinksPageCount = getPageCount(
    externalLinksQuery.data?.externalLinksCount,
    defaultSearchSettings.perPage
  )

  const createExternalLink = useMutation(
    (payload: CreateExternalLinkVariables) => postExternalLink(lead.id, payload),
    defaultCallback
  )

  return {
    externalLinksQuery,
    externalLinksPageCount,
    createExternalLink
  }
}
