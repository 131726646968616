// @ts-strict

import { ORDER_CACHE_KEY, ORDERS_CACHE_KEY } from 'controllers/cache'
import { useMutation, useQueryClient } from 'hooks'
import { Lead } from 'models'
import { postDuplicatableLead, PostDuplicatableLeadPayload } from 'services'

export const useDuplicatableLeadController = (lead: Lead) => {
  const queryCache = useQueryClient()

  const defaultCallback = {
    onSuccess: () => {
      queryCache.invalidateQueries(['leads', lead.userType, lead.id])
      queryCache.invalidateQueries(ORDER_CACHE_KEY)
      queryCache.invalidateQueries(ORDERS_CACHE_KEY)
    }
  }

  const addDuplicatableLead = useMutation(
    (data: PostDuplicatableLeadPayload) => postDuplicatableLead(data),
    defaultCallback
  )

  return {
    addDuplicatableLead
  }
}
