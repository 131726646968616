// @ts-strict
import { LeadGroupablesList } from 'content/LeadGroupables'
import { LeadGroupablesProvider } from 'contexts'
import { useDocumentTitle } from 'hooks'
import { LeadGroupableType } from 'models'

interface LeadGroupablesPageContentProps {
  leadGroupableType: LeadGroupableType
}

export const LeadGroupablesPage = {
  Personas: () => <LeadGroupablesPageContent leadGroupableType="personas" />
}

const LeadGroupablesPageContent = ({ leadGroupableType }: LeadGroupablesPageContentProps) => {
  useDocumentTitle(leadGroupableType)

  return (
    <LeadGroupablesProvider leadGroupableType={leadGroupableType}>
      <LeadGroupablesList />
    </LeadGroupablesProvider>
  )
}
