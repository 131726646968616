// @ts-strict
import { Link } from 'react-router-dom'
import {
  Block,
  Flipup,
  Icon,
  IconTitle,
  TableActions,
  TableCell as TD,
  TableRow as TR,
  ViewMore
} from 'components'
import { TaskActions } from 'content/Task'
import { useListContext, useTasksContext } from 'contexts'
import {
  getCcCashOfferLeadStageName,
  getCcTradeInLeadStageName,
  TCcCashOfferLeadStagesSlug,
  TCcTradeInLeadStagesSlug
} from 'lookups'
import { isCCCashOfferLead, isCCTradeInLead, Task } from 'models'
import { dataOrDash, formatDateTime } from 'utils'
import { AttachedToSubtitle } from './AttachedToSubtitle'
import { ResendTaskEmailButton } from './ResendTaskEmailButton'
import styles from './Tasks.module.scss'
import { TaskStatus } from '.'

type TaskTableRowProps = {
  task: Task
}

export const TaskTableRow = ({ task }: TaskTableRowProps) => {
  const { attachable } = useTasksContext()
  const { showColumn } = useListContext()

  const hasRequiredForLeadStage = !!task?.requiredForLeadStage
  const subAttachableAddress = task.subAttachable?.incomingMasterProperty?.displayAddress

  const getLeadTaskStage = () => {
    if (isCCCashOfferLead(task?.attachable)) {
      return getCcCashOfferLeadStageName(task.requiredForLeadStage as TCcCashOfferLeadStagesSlug)
    } else if (isCCTradeInLead(task?.attachable)) {
      return getCcTradeInLeadStageName(task.requiredForLeadStage as TCcTradeInLeadStagesSlug)
    }

    return
  }

  const requiredForLeadStage = hasRequiredForLeadStage ? getLeadTaskStage() : undefined

  const resendTaskEmail = <ResendTaskEmailButton task={task} />
  let note = task.note
  if (task.templateSlug === 'cc_trade_in_property_questionnaire') {
    note = `${note} ${task.metadata?.action_url}`
  }

  return (
    <TR data-testid={`task${task.id}`}>
      {showColumn('status') && (
        <TD className={styles.status}>
          <TaskStatus task={task} />
        </TD>
      )}
      {showColumn('categoryStage') && (
        <TD className={styles.categoryStage}>
          <Block
            title={dataOrDash(task.taskCategory?.name)}
            subtitle={dataOrDash(requiredForLeadStage)}
          />
        </TD>
      )}
      {showColumn('title') && (
        <TD>
          <Block
            title={
              <IconTitle icon={!!task.templateSlug && <Icon.Gear />} title={task.title}>
                {task.title}
              </IconTitle>
            }
            subtitle={<ViewMore height={36}>{note}</ViewMore>}
          />
        </TD>
      )}
      {showColumn('attachedTo') && !attachable && (
        <TD>
          {task.attachable && (
            <Block
              title={<Link to={task.attachable.getPathname()}>{task.attachable.name}</Link>}
              subtitle={
                <AttachedToSubtitle
                  attachableLead={task.attachable}
                  subAttachableLead={task.subAttachable}
                />
              }
            />
          )}
        </TD>
      )}
      {showColumn('ir') && (
        <TD className={styles.ir}>
          <Flipup title={dataOrDash(!!task.subAttachable ? <Icon.Sign /> : null)}>
            {subAttachableAddress}
          </Flipup>
        </TD>
      )}
      {showColumn('assigneeAssigner') && (
        <TD className={styles.assignee}>
          {task.assignee?.agent ? (
            <Block
              title={
                <Link to={task.assignee.agent.getPathname()}>{task.assignee.agent.fullName}</Link>
              }
              subtitle={task.assignee.agent.agentTeamName}
              actions={resendTaskEmail}
            />
          ) : (
            <Block
              title={dataOrDash(task.assignee && task.assignee.name)}
              subtitle={task.assigner && task.assigner.name}
            />
          )}
        </TD>
      )}
      {showColumn('dueReady') && (
        <TD>
          <Block title={formatDateTime(task.dueAt)} subtitle={formatDateTime(task.readyAt)} />
        </TD>
      )}
      {showColumn('irCoeDate') && (
        <TD>
          <Block
            title={formatDateTime(
              task.subAttachable && task.subAttachable['incomingResidencedExpectedCloseEscrowDate']
            )}
          />
        </TD>
      )}
      {showColumn('assignedCreated') && (
        <TD>
          <Block
            title={formatDateTime(task.assignedAt)}
            subtitle={formatDateTime(task.createdAt)}
          />
        </TD>
      )}
      <TableActions>
        <TaskActions task={task} />
      </TableActions>
    </TR>
  )
}
