// @ts-strict
import { FormField } from 'components'
import { UserInfo } from 'content/User'
import { Agent } from 'models'
import { formatDateTime } from 'utils'
import { AgentCreateUserButtonForm } from '../AgentCreateUserButtonForm'

type Props = {
  agent: Agent
}

export const AgentUserInfo = ({ agent }: Props) => {
  const hasUserAccount = agent?.user?.id

  return (
    <UserInfo user={agent?.user}>
      {hasUserAccount ? (
        <FormField title="Last Login" inline>
          {formatDateTime(agent.lastSignedInAt)}
        </FormField>
      ) : (
        <AgentCreateUserButtonForm agent={agent} />
      )}
    </UserInfo>
  )
}
