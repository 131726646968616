// @ts-strict
import { AgreementResponse } from 'schema'
import { Model } from '../Model'

export class Agreement extends Model {
  commissionSplit?: number
  effectiveAt?: string
  id?: string
  name?: string
  proType?: string
  replacedAt?: string
  templateId?: string
  version?: string

  constructor(agreementAttributes: Agreement) {
    super()
    Object.assign(this, agreementAttributes)
  }

  static create(agreementResponse: Potential<AgreementResponse>): Potential<AgreementResponse> {
    if (!agreementResponse) {
      return
    }

    return new Agreement(agreementResponse)
  }
}
