import { MORTGAGE_LEADS_CACHE_KEY, useQuery } from 'hooks'
import { fetchMortgageLeads, TMortgageLeadsSearchParams } from 'services'

const defaultSearchSettings: TMortgageLeadsSearchParams = {
  sortBy: 'Created',
  sortDir: 'desc',
  perPage: 25
}

type TArgs = {
  searchParams?: TMortgageLeadsSearchParams
}

export const useMortgageLeadsController = ({ searchParams = {} }: TArgs = {}) => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  const mortgageLeadsQuery = useQuery([MORTGAGE_LEADS_CACHE_KEY, searchSettings], () =>
    fetchMortgageLeads(searchSettings)
  )

  return { mortgageLeadsQuery }
}
