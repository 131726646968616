// @ts-strict
import { CONSTANTS_CACHE_KEY, METROPLEXES_CACHE_KEY, useQuery } from 'hooks'
import { fetchMetroplexes } from 'services'

export const useMetroplexes = () => {
  const { data: metroplexes = [] } = useQuery(
    [CONSTANTS_CACHE_KEY, METROPLEXES_CACHE_KEY],
    fetchMetroplexes,
    {
      staleTime: Infinity
    }
  )

  return {
    metroplexes
  }
}
