// @ts-strict

import { Link } from 'react-router-dom'
import { ErrorPage } from 'components/ErrorPage'
import { useDocumentTitle } from 'hooks'

export const PAGE_NOT_FOUND_TITLE = 'Page Not Found'

export const NotFoundPage = () => {
  useDocumentTitle(PAGE_NOT_FOUND_TITLE)

  return (
    <ErrorPage
      title="Sorry, this page isn't available. 👀"
      subtitle="The link you followed may be broken. Check to see if the link you're trying to open is correct."
    >
      <Link to="/">Go back to home page</Link>
    </ErrorPage>
  )
}
