export const transformUserQueues = queues => {
  if (!queues) {
    return undefined
  }

  return {
    houseCanary: queues.house_canary,
    handmatch: queues.handmatch
  }
}
