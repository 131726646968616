import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { Flex, IconButton, Text, useToastContext } from '../../../../@foundation/components'
import { User } from '../../../../models'
import { putRequest } from '../../../../utils'
import { BASE_URL, GET_TRANSACTION_OPERATORS_LIST } from '../../pages'
import { Team } from '../../pages/types'
import { Container } from './styles'

type TransactionGroupOperatorProps = {
  groupId: string
  operator: User | Team
  type: 'user' | 'team'
}

type TransactionGroupOperatorPropsUser = TransactionGroupOperatorProps & {
  operator: User
  type: 'user'
}

type TransactionGroupOperatorPropsTeam = TransactionGroupOperatorProps & {
  operator: Team
  type: 'team'
}

export const Operator = ({
  operator,
  type,
  groupId
}: TransactionGroupOperatorPropsUser | TransactionGroupOperatorPropsTeam) => {
  const queryCache = useQueryClient()
  const toast = useToastContext()

  const deleteOperator = async (id: string, type: string) => {
    return putRequest(
      `${BASE_URL}/lead-data-service/transaction-operator-groups/${groupId}/remove-operator`,
      {
        operator_id: id,
        operator_type: type
      }
    )
  }

  const { mutate, isLoading } = useMutation(
    ({ id, type }: { id: string; type: string }) => deleteOperator(id, type),
    {
      onSuccess: () => queryCache.invalidateQueries([GET_TRANSACTION_OPERATORS_LIST]),
      onError: (error: AxiosError) => {
        console.error(error.response)
        toast({
          title: `Oops! Something went wrong. ${error.response.data.errors}`,
          status: 'error'
        })
      }
    }
  )

  let title = ''
  let subtitle = ''

  if (type === 'user') {
    title = `${operator.firstName} ${operator.lastName}`
    subtitle = operator.email
  } else if (type === 'team') {
    title = operator.name
    subtitle = operator.description
  }

  return (
    <Container>
      <Flex flexDirection="column">
        <Flex gap="$2" alignItems="center" justifyContent="space-between">
          {title}
          <IconButton
            variant="ghost"
            icon="Close"
            title="Delete Operator"
            size="xs"
            onClick={() => mutate({ id: operator.id, type })}
            isLoading={isLoading}
          />
        </Flex>

        <Text size="xs" color="neutral8">
          {subtitle}
        </Text>
      </Flex>
    </Container>
  )
}
