import { useEffect, useRef, useState } from 'react'
import { breakpoint } from 'utils'

export const usePageResize = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const resHandle = useRef(0)

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(resHandle.current)
      resHandle.current = setTimeout(() => {
        setWidth(window.innerWidth)
      }, 300)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { width, size: breakpoint(width) }
}
