// @ts-strict
import { useMutation, useQueryClient } from 'react-query'
import { GENERATED_DOCUMENTS_CACHE_KEY, useQuery } from 'hooks'
import {
  fetchGeneratedDocuments,
  generateDocument,
  TGeneratedDocumentsSearchParams,
  TPostGeneratedDocumentPayload
} from 'services'

export const useGeneratedDocumentsController = (
  searchParams: TGeneratedDocumentsSearchParams = { bbysLeadId: '' }
) => {
  const queryCache = useQueryClient()
  const defaultCallback = {
    onSuccess: () => {
      queryCache.invalidateQueries(GENERATED_DOCUMENTS_CACHE_KEY)
    }
  }

  const { data, isLoading } = useQuery([GENERATED_DOCUMENTS_CACHE_KEY, searchParams], () =>
    fetchGeneratedDocuments(searchParams)
  )

  const document = useMutation(
    (payload: TPostGeneratedDocumentPayload) => generateDocument(searchParams.bbysLeadId, payload),
    defaultCallback
  )

  return {
    generateDocument: document,
    generatedDocuments: data?.generatedDocuments,
    generatedDocumentsLoading: isLoading
  }
}
