import { getLead, Lead } from 'models'
import { AgentAttributesAndAssociations, getHapiRequestParams, LeadSchema } from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getPageCount, getRequest } from 'utils'
import { TFetchItems } from '../../types'
import { AllLeadsSearchParams, getAllLeadsSearchParams } from './getAllLeadsSearchParams'

const agentFields: AgentAttributesAndAssociations = [
  'firstName',
  'lastName',
  { agentTeam: ['name'] },
  { agentTeamMemberships: [{ agentTeam: ['name'] }] }
]

const leadResponseShape: LeadSchema = {
  lead: [
    'partnerName',
    'createdAt',
    'fullAddress',
    'lastStageUpdate',
    'email',
    'name',
    'stage',
    'userType',
    'source',
    'sourcePageType',
    { 'referringOwner:agent': agentFields },
    {
      order: ['source', { leads: ['userType', 'price'] }]
    }
  ]
}

export const fetchAllLeads = async (settings: AllLeadsSearchParams): Promise<TFetchItems<Lead>> => {
  const url = getLeadsUrl({
    searchParams: {
      ...getAllLeadsSearchParams(settings),
      ...getHapiRequestParams(leadResponseShape)
    }
  })
  const response = await getRequest(url)
  const leads = await deserializeAsync<Lead[]>(response.data, {
    transform: getLead
  })

  return {
    items: leads,
    pageCount: getPageCount(response.data.meta.total_leads, settings.perPage),
    totalCount: response.data.meta.total_leads || 0
  }
}
