import { getPresignedS3Url } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export type FetchPresignedLinkProps = {
  fileType?: 'image' | 'document'
  mime: string
}

export const fetchPresignedLink = async ({ mime, fileType }: FetchPresignedLinkProps) => {
  const response = await postRequest(
    getPresignedS3Url(),
    snakeCaseKeys({
      mime,
      fileCount: 1,
      ...(fileType ? { fileType } : {})
    })
  )

  return {
    ...response,
    data: response?.data?.[0]
  }
}
