// @ts-strict
import type { UseMutationResult } from 'react-query'
import { useToastContext } from '@foundation/components'
import { useButtonForm } from 'components'
import type { Agent } from 'models'
import { validatePresence } from 'utils'
import {
  ChangeReasonsData,
  selectableVisibilityOptionFromAgent,
  visibilityOptionFromSlug
} from './utils'

type PutAgentPayload = any

export type AgentButtonFormPayload = PutAgentPayload

export const useAgentReferralVisibilityButtonForm = (
  mutation: UseMutationResult<any, unknown, PutAgentPayload, unknown>,
  agent: Agent
) => {
  const transformPayload = (payload: AgentButtonFormPayload): AgentButtonFormPayload => {
    const visibilityOption = visibilityOptionFromSlug(payload.agentVisibility)

    const notifyAgent =
      payload.reason === ChangeReasonsData.declinedPpl.slug && payload.notifyAgent === 'yes'

    return {
      ...visibilityOption!.agentOptions,
      notifyAgent,
      reason: payload.reason,
      notes: payload.notes
    }
  }

  const formValidations = {
    reason: validatePresence,
    agentVisibility: validatePresence,
    notes: validatePresence,
    notifyAgent: validatePresence
  }

  const currentVisibilityOption = selectableVisibilityOptionFromAgent(agent)

  const toast = useToastContext()
  const onSubmitSuccess = () => {
    toast({
      title: 'Referral visibility updated',
      status: 'success'
    })
  }

  const { fieldsProps, formProps } = useButtonForm<AgentButtonFormPayload>({
    initialPayload: {
      reason: ChangeReasonsData.declinedPpl.slug,
      agentVisibility: currentVisibilityOption?.slug,
      notes: '',
      notifyAgent: 'yes'
    },
    callback: onSubmitSuccess,
    mutation,
    transformPayload,
    formValidations
  })

  return { fieldsProps, formProps }
}
