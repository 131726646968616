import { ChangeEvent, useState } from 'react'
import {
  Block,
  Console,
  ConsoleAside,
  ConsoleDetails,
  FormField,
  Icon,
  IconTitle,
  Page,
  RangeInput,
  Section,
  Sticky
} from 'components'
import { useDocumentTitle } from 'hooks'
import styles from './DevIconsPage.module.scss'

export const DevIconsPage = () => {
  useDocumentTitle('Icons')

  const [width, setWidth] = useState<string | number>(30)
  const iconNames = Object.keys(Icon)

  return (
    <Page>
      <Console>
        <ConsoleAside className={styles.iconsAside}>
          <Sticky>
            <Section>
              <Block title="Icons" className={styles.iconsAsideTitle} />
            </Section>
            <Section title="Size">
              <FormField title="Width" inline>
                {/* @ts-ignore */}
                <RangeInput
                  value={width}
                  min={10}
                  max={120}
                  step={5}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setWidth(e.target.value)}
                />
              </FormField>
            </Section>
          </Sticky>
        </ConsoleAside>

        <ConsoleDetails className={styles.details}>
          <Section className={styles.iconsSection}>
            {iconNames.map(iconName => {
              const IconComponent = Icon[iconName]
              return (
                <IconTitle
                  icon={<IconComponent width={width} height={width} />}
                  className={styles.iconTitle}
                >
                  {iconName}
                </IconTitle>
              )
            })}
          </Section>
        </ConsoleDetails>
      </Console>
    </Page>
  )
}
