import { ServicesOpportunitySelector } from 'content/ServicesOpportunity'
import { useAgentOpportunityDeclinedReason, useLeadOpportunityDeclinedReason } from 'lookups'
import { Agent } from 'models'

type Props = {
  agent: Agent
  declineReasonType?: 'agent' | 'lead'
  onChange: () => void
}

export const AgentServicesOpportunitiesSelector = ({
  agent,
  declineReasonType = 'agent',
  onChange
}: Props) => {
  const { agentOpportunityDeclinedReason } = useAgentOpportunityDeclinedReason()
  const { leadOpportunityDeclinedReason } = useLeadOpportunityDeclinedReason()

  const declinedReasons =
    declineReasonType === 'agent' ? agentOpportunityDeclinedReason : leadOpportunityDeclinedReason

  return (
    <>
      {agent.servicesOpportunities.map(servicesOpportunity => (
        <ServicesOpportunitySelector
          key={servicesOpportunity.id}
          servicesOpportunity={servicesOpportunity}
          onChange={onChange}
          declinedReasons={declinedReasons}
        />
      ))}
    </>
  )
}
