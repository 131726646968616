import { Accept } from 'react-dropzone'
import { asArray } from 'utils'

const mimeTypes = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf: 'application/pdf',
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  heic: 'image/heic',
  heif: 'image/heif',
  gif: 'image/gif'
}

export type AcceptedFiletypes = keyof typeof mimeTypes

// builds an Accept object from a filetype extension or filetype extension  array
export const getAcceptFiletypesObject = (
  filetypes: AcceptedFiletypes | AcceptedFiletypes[]
): Accept =>
  asArray(filetypes).reduce((acc, val) => {
    return { ...acc, [mimeTypes[val]]: [] }
  }, {})
