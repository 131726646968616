import { ReactNode } from 'react'
import { createCtx } from 'contexts'
import { usePopover, UsePopoverProps } from './usePopover'

export const [usePopoverContext, PopoverContextProvider] = createCtx<ReturnType<typeof usePopover>>(
  {
    errorMessage:
      'usePopoverContext: `context` is undefined. Seems you forgot to wrap all popover components within `<Popover.Root />`'
  }
)

export type PopoverProps = {
  /**
   * The content to display inside the Popover Root
   */
  children: ReactNode
} & UsePopoverProps

export const Popover = ({
  children,
  enterDelay = 'short',
  isHoverEnabled,
  placement,
  onClose,
  isOpen,
  onOpenChange
}: PopoverProps) => {
  return (
    <PopoverContextProvider
      value={usePopover({ enterDelay, isHoverEnabled, placement, onClose, isOpen, onOpenChange })}
    >
      {children}
    </PopoverContextProvider>
  )
}
