// @ts-strict

import { ReactNode } from 'react'
import { styled } from '@foundation/themes/styles'
import { Popup } from 'components'

export type SettingsModalProps = {
  children: ReactNode
  onCancel: () => void
  title: string
}

const StyledPopup = styled(Popup)`
  width: fit-content;
  border-radius: 12px;
`

export const SettingsModal = ({ children, onCancel, title }: SettingsModalProps) => {
  return (
    <>
      <StyledPopup title={title} onCancel={onCancel}>
        {children}
      </StyledPopup>
    </>
  )
}
