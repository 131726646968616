import { FunctionComponent, ReactNode } from 'react'
import { createCtx } from 'contexts'
import { TGeneratedColumnsLookup, TLookupSearchFilters } from 'lookups'
import { useList } from './useList'

export type TListFilters = {
  findFilterBySlug?: TLookupSearchFilters['findFilterBySlug']
} & Omit<TLookupSearchFilters, 'findFilterBySlug'>

export type ListProviderProps<TActionPayload extends Record<string, any>> = {
  actions?: FunctionComponent<TActionPayload>
  children: ReactNode
  columns?: TGeneratedColumnsLookup
  filters?: TListFilters
  highlightRows?: string | string[]
}

type TUseListReturn = ReturnType<typeof useList>

export const [useListContext, ListContextProvider] = createCtx<
  TLookupSearchFilters & TUseListReturn
>()

export const ListProvider = <TActionPayload,>({
  children,
  columns,
  filters,
  actions,
  highlightRows
}: ListProviderProps<TActionPayload>) => {
  const useListHookValue = useList<TActionPayload>({
    columns,
    filters,
    actions,
    highlightRows
  })

  return <ListContextProvider value={{ ...useListHookValue }}>{children}</ListContextProvider>
}
