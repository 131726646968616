import { Section, Sections } from 'components'
import { useFields } from '..'
import { FieldFormField } from '../FieldFormField'

export const CCTradeInInEscrowSell = () => {
  useFields({
    required: [
      'departingPropertyOfferCount',
      'departingPropertyActualSalePrice',
      'dpTargetSellCoeDate',
      'departingPropertyOpenedSaleEscrowAt'
    ],
    fields: ['departingPropertyOfferAcceptedAt']
  })
  return (
    <Sections title="DR Sale Transaction">
      <Section>
        <FieldFormField field="departingPropertyOfferAcceptedAt" />
        <FieldFormField field="departingPropertyOfferCount" />
        <FieldFormField field="departingPropertyActualSalePrice" />
      </Section>
      <Section>
        <FieldFormField field="dpTargetSellCoeDate" />
        <FieldFormField field="departingPropertyOpenedSaleEscrowAt" />
      </Section>
    </Sections>
  )
}
