import { ReactNode } from 'react'
import { Heading } from '@foundation/components'
import { Icon } from 'components/Icon'
import styles from './Popup.module.scss'
import * as S from './styles'

type TProps = {
  actions?: ReactNode
  onCancel?: () => void
  title?: string
}

export const PopupHeader = ({ title, onCancel, actions }: TProps) => {
  if (!title) {
    return null
  }
  return (
    <div className={styles.header} role="dialog">
      {title ? (
        <S.Title>
          <Heading as="h2" size="lg" hasEllipsis>
            {title}
          </Heading>
        </S.Title>
      ) : null}
      {actions}
      {onCancel ? (
        <button
          type="button"
          className={styles.close}
          onClick={onCancel}
          data-testid="popup-cancel-button"
          title="Close Popup"
        >
          <Icon.Close />
        </button>
      ) : null}
    </div>
  )
}
