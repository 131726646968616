import { FormEventHandler, useState } from 'react'
import { Box, Dialog, Flex, useToastContext } from '@foundation/components'
import { Button, CheckBoxField, FormField, Select } from 'components'
import { useMutation } from 'hooks'
import { CCBBYSLead } from 'models'
import { getBBYSRequestValuationUrl } from 'services/getUrl'
import { putRequest } from 'utils'
import styles from './RequestValuation.module.scss'

type RequestValuationProps = {
  lead: CCBBYSLead
}

export const RequestValuation = ({ lead }: RequestValuationProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [approvalType, setApprovalType] = useState(lead.bbysLead.approvalTypeIntake)
  const [rushApproval, setRushApproval] = useState(false)
  const approvalTypeOptions = [
    { value: 'instant', name: 'Instant' },
    { value: 'express', name: 'Express' },
    { value: 'light', name: 'Light' },
    { value: 'listed', name: 'Listed' },
    { value: 'refresh', name: 'Refresh' }
  ]
  const toast = useToastContext()

  const { mutate } = useMutation(
    () =>
      putRequest(getBBYSRequestValuationUrl(), {
        id: lead.id,
        valuation_type: approvalType,
        rush: rushApproval
      }),
    {
      onSuccess: () => {
        setIsOpen(false)
      },
      onError: () => {
        toast({
          title: 'Ops! Something went wrong!',
          status: 'error'
        })
      }
    }
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    mutate()
  }

  const renderTrigger = () => {
    return (
      <Button as="primary" iconPosition="start" size="small" className={styles.requestValuationBtn}>
        Request Valuation
      </Button>
    )
  }

  return (
    <Dialog.Root isOpen={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>{renderTrigger()}</Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Header>Request Valuation</Dialog.Header>
        <Dialog.Body>
          <form onSubmit={onSubmit}>
            <FormField title="Valuation Type" required>
              <Select
                options={approvalTypeOptions}
                value={approvalType}
                onChange={setApprovalType}
                placeholder=""
                className={styles.select}
              />
            </FormField>
            <FormField>
              <CheckBoxField
                title="Rush?"
                checked={rushApproval}
                onChange={e => setRushApproval(e.target.checked)}
              />
            </FormField>
            <Box marginTop="$6">
              <Flex gap="$4" justifyContent="flex-end">
                <Button type="button" as="cancel" onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <Button type="submit" as="primary">
                  Submit
                </Button>
              </Flex>
            </Box>
          </form>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  )
}
