import { FileUpload } from 'models'
import { getFilesUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getFileUploadsSearchParams, TFileUploadsSearchParams } from '.'

type TResponse = { count: number; fileUploads: FileUpload[]; pages: number }

export const fetchFileUploads = async (settings: TFileUploadsSearchParams): Promise<TResponse> => {
  const response = await getRequest(
    getFilesUrl({
      searchParams: getFileUploadsSearchParams(settings)
    })
  )

  const fileUploads = await deserializeAsync(response.data, { transform: FileUpload.create })
  return {
    fileUploads,
    count: response.data.meta.total_count as number,
    pages: response.data.meta.total_pages as number
  }
}
