import {
  AddressUnitAutoComplete,
  DateInput,
  DateTimeInput,
  DollarsAndCentsInput,
  FloatInput,
  FormField,
  IntegerInput,
  MoneyInput,
  PercentInputV2,
  SelectBoolean,
  SelectWithBlank,
  TextInput
} from 'components'
import { TCcTradeInLeadFieldAttribute, useCcTradeInLeadFields } from 'lookups'
import { stringifyValue } from 'utils'
import { useCCTradeInLeadDispositionContext } from '../index'

type Props = {
  field: TCcTradeInLeadFieldAttribute
  readOnly?: boolean
  required?: boolean
  title?: string
}

export const FieldFormField = ({ field, ...rest }: Props) => {
  const { dispositionPayload, setDispositionPayloadItem, requiredFields } =
    useCCTradeInLeadDispositionContext()

  const { getCcTradeInLeadField } = useCcTradeInLeadFields()
  const formField = getCcTradeInLeadField(field)

  const inputComponents = {
    address: AddressUnitAutoComplete,
    boolean: SelectBoolean,
    date: DateInput,
    datetime: DateTimeInput,
    dollarsAndCents: DollarsAndCentsInput,
    float: FloatInput,
    integer: IntegerInput,
    money: MoneyInput,
    percent: PercentInputV2,
    select: SelectWithBlank,
    text: TextInput
  }

  const InputComponent = inputComponents[formField.inputType]

  return (
    <FormField title={formField.name} required={requiredFields.includes(field)}>
      {InputComponent ? (
        <InputComponent
          id={field}
          name={field}
          onChange={val => setDispositionPayloadItem(field, val)}
          value={stringifyValue(dispositionPayload[field])}
          options={formField.options}
          {...rest}
        />
      ) : null}
    </FormField>
  )
}
