// @ts-strict
import { ReactNode, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { LeadPhotoUploadsGallery } from 'content/Lead'
import { ParamsProvider, useLeadContext } from 'contexts'
import { useDocumentTitle } from 'hooks'
import { Lead } from 'models'
import { LeadDocumentsTabs } from '../LeadDocumentsTabs'

type TProps = {
  children?: ReactNode
  lead: Lead
}

const LeadPropertyPhotosContent = ({ lead, children }: TProps) => {
  return (
    <>
      <LeadDocumentsTabs />
      <LeadPhotoUploadsGallery lead={lead} />
      {children}
    </>
  )
}

export const LeadPropertyPhotosTab = () => {
  const { setTitle } = useDocumentTitle()
  const { lead } = useLeadContext()

  useEffect(() => setTitle(`${lead?.name} - Documents - Property Photos`), [lead, setTitle])

  return (
    <ParamsProvider>
      <LeadPropertyPhotosContent lead={lead!}>
        <Outlet />
      </LeadPropertyPhotosContent>
    </ParamsProvider>
  )
}
