/* eslint-disable typescript-sort-keys/interface */
/* eslint-disable import/order */

import { useQuery } from 'react-query'
import { useToastContext } from '@foundation/components'
import { FiltersObj } from '@foundation/components/Table'

import { deserializeAsync, makeRequest } from 'utils'

import { BBYSFormattedResponse, BBYSLead } from '../../types'
import { ENDPOINTS, PAGE_SIZE } from '../../constants'
import { buildURL } from '../../helpers'

export const BBYS_DATA_QUERY = 'BBYS_DATA'

type UseBBYSDataArgs = {
  search: string
  sortBy: string
  page: string
  filters: FiltersObj
}

export const useBBYSData = ({ search, sortBy, page, filters }: UseBBYSDataArgs) => {
  const toast = useToastContext()

  const URL = buildURL(ENDPOINTS.leadDataService.v2.bbys, {
    search,
    sortBy,
    page,
    pageSize: `${PAGE_SIZE}`,
    filters,
    fields: [
      'departing_property_uuid',
      'lead.name',
      'lead.loan_officer.phone_numbers.number',
      'departing_property_seller_agent.license_number',
      'departing_property_seller_agent.agent_team_name',
      'departing_property_seller_agent.notes',
      'source_partner',
      'source_partner.slug'
    ],
    includes: [
      'lead',
      'lead.loan_officer',
      'lead.loan_officer.phone_numbers',
      'lead.phone_numbers',
      'source_partner',
      'departing_property_seller_agent'
    ]
  })

  return useQuery<BBYSFormattedResponse>({
    queryKey: [BBYS_DATA_QUERY, URL],
    queryFn: async () => {
      const { data } = await makeRequest(URL)
      const deserialized = (await deserializeAsync(data)) as BBYSLead[]
      return { ...data, data: deserialized }
    },
    onError: error => {
      console.error(error)
      toast({
        title: 'Ops! Something went wrong. Try to login again!',
        status: 'error'
      })
    },
    staleTime: 0
  })
}
