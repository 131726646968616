// @ts-strict

import { useQuery } from 'hooks'
import { lookupPropertyUuid } from 'services'

type TOptions = {
  onError?: (err: unknown) => void
}

export const usePropertiesController = (fullAddress: string, opt = {} as TOptions) => {
  const { data, isLoading } = useQuery(
    ['property_lookup', fullAddress],
    () => lookupPropertyUuid(fullAddress),
    {
      refetchOnWindowFocus: false,
      enabled: !!fullAddress,
      onError: opt.onError || undefined
    }
  )

  return {
    isLoading,
    data
  }
}
