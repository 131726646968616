import React, { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './FieldHeader.module.scss'

type Props = {
  children: ReactNode
  className?: string
}

export const FieldHeader = ({ className, children }: Props) => {
  return (
    <header data-testid="fieldHeader" className={cx('fieldHeader', styles.header, className)}>
      {children}
    </header>
  )
}
