import { CCHLSimpleSaleLead } from 'models'
import {
  CCHLSimpleSaleLeadSchema,
  getHapiRequestParams,
  HLSimpleSaleLeadAttributesAndAssociations,
  LeadAttributesWithoutId,
  OrderAttributesAndAssociations
} from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchCCHLSimpleSaleLead = async (id: string): Promise<CCHLSimpleSaleLead> => {
  const leadFields: Array<LeadAttributesWithoutId> = [
    'calculatedValue',
    'callReview',
    'createdAt',
    'email',
    'emailAlt',
    'fullAddress',
    'furthestStage',
    'humanTimeline',
    'isDeal',
    'lastCallDate',
    'lastStageUpdate',
    'latitude',
    'longitude',
    'name',
    'notesToAgents',
    'partnerName',
    'phoneAlt',
    'price',
    'propertyType',
    'qualificationTalkedToClient',
    'revenueRecognitionDate',
    'salesNotes',
    'source',
    'sourcePageType',
    'stage',
    'userType',
    'dialerQueueStatus'
  ]

  const orderShape: OrderAttributesAndAssociations = [
    'createdAt',
    'ownedBusinessUnit',
    'source',
    'stage',
    'updatedAt',
    {
      leads: [
        {
          'lenderProviderLeads:providerLead': [
            { 'providable:lenderLead': ['eaveLoanApplicationId', 'closeOfEscrowOn'] }
          ]
        },
        {
          'escrowOfficeProviderLeads:providerLead': [
            { 'providable:escrowOfficeLead': ['netEquity', 'orderClosingOn'] }
          ]
        }
      ]
    }
  ]
  const simpleSaleLeadProvidableShape: HLSimpleSaleLeadAttributesAndAssociations = [
    'agentValuation',
    'avmDate',
    'createdAt',
    'departingMasterProperty',
    'departingPropertyActualDom',
    'departingPropertyActualSalePrice',
    'departingPropertyAgentValuationReceivedDate',
    'departingPropertyCloseOfPurchaseEscrowDate',
    'departingPropertyCloseOfSaleEscrowDate',
    'departingPropertyDaysOfOwnership',
    'departingPropertyExpectedListingPrice',
    'departingPropertyExternalListingDate',
    'departingPropertyExternalListingStatus',
    'departingPropertyExternalListingStatusDate',
    'departingPropertyFlags',
    'departingPropertyFundsWiredToClientAt',
    'departingPropertyFundsWiredToHlAt',
    'departingPropertyGuaranteedPrice',
    'departingPropertyHomeownerAssociation',
    'departingPropertyInspectedAt',
    'departingPropertyInspectionScheduledAt',
    'departingPropertyListedDate',
    'departingPropertyListingPrice',
    'departingPropertyLoanPayoff',
    'departingPropertyMarketDom',
    'departingPropertyMarketIndex',
    'departingPropertyMarketInventoryCount',
    'departingPropertyOfferAcceptedDate',
    'departingPropertyOfferCount',
    'departingPropertyOpenedPurchasedEscrowDate',
    'departingPropertyOpenedSaleEscrowDate',
    'departingPropertyPreliminaryTitleReviewed',
    'departingPropertyPreliminaryValuation',
    'departingPropertyPriceDelta',
    'departingPropertyQualificationReason',
    'departingPropertyQualified',
    'departingPropertyQualifiedDate',
    'departingPropertySellerAgentId',
    'departingPropertySolarLoanBalance',
    'departingPropertySolarPanels',
    'departingPropertyUuid',
    'departingPropertyValuation',
    'dpActualDaysToListing',
    'dpActualGainOnSale',
    'dpActualPurchaseFundedDate',
    'dpActualRehabExpensesPercentage',
    'dpActualRehabExpensesPrice',
    'dpBuyerAgentFee',
    'dpCapitalDaysOutstanding',
    'dpDaysSellerInPossession',
    'dpDealValuationPercentage',
    'dpDealValuationPrice',
    'dpDealValuationPriceRounding',
    'dpEstimatedDaysOfOwnership',
    'dpEstimatedDaysOfWork',
    'dpEstimatedDaysOfWorkTransaction',
    'dpEstimatedListToPending',
    'dpEstimatedListToPendingTransaction',
    'dpEstimatedSaleEscrow',
    'dpFundingAmount',
    'dpFundingAmountBorrowingBase',
    'dpFundingAmountHlEquity',
    'dpHlAfterRepairValue',
    'dpHlCommitteeAdjustment',
    'dpInspectionAdjustment',
    'dpPropertyType',
    'dpSellerAgentFee',
    'dpSellerCredit',
    'dpSellerVacateDate',
    'dpTargetPurchaseCoeDate',
    'dpTargetRehabBudgetMethod',
    'dpTargetRehabBudgetPercentage',
    'dpTargetRehabBudgetPrice',
    'dpTargetSalePrice',
    'dpTargetSellCoeDate',
    'dpTermSheetOffer',
    'dpTotalEstimatedCostsOfBuying',
    'dpTotalEstimatedCostsOfFinancing',
    'dpTotalEstimatedCostsOfSelling',
    'dpTotalEstimatedDaysOfOwnership',
    'twoCheck',
    'updatedAt',
    'dpAgentAfterRepairValue',
    'dpAgentAfterRepairValueDate',
    'dpAnalystValuation',
    'dpAnalystValuationDate',
    'dpAnalystAfterRepairValue',
    'dpAnalystAfterRepairValueDate',
    { 'propertyValuation:corbetsPropertyValuation': ['spreadsheetUrl'] },
    {
      'departingPropertySellerAgent:agent': [
        'email1',
        'email2',
        'firstName',
        'lastName',
        'licenseNumber',
        { agentTeam: ['name'] },
        { agentTeamMemberships: [{ agentTeam: ['name'] }] },
        { phoneNumbers: ['number', 'phoneType'] }
      ]
    },
    {
      'incomingPropertyBuyerAgent:agent': [
        'email1',
        'email2',
        'firstName',
        'lastName',
        'licenseNumber',
        { agentTeam: ['name'] },
        { agentTeamMemberships: [{ agentTeam: ['name'] }] },
        { phoneNumbers: ['number', 'phoneType'] }
      ]
    }
  ]

  const leadResponseShape: CCHLSimpleSaleLeadSchema = {
    lead: [
      ...leadFields,
      { client: [{ user: ['firstName', 'lastName', 'email'] }] },
      { leadUsers: ['role', { user: ['name'] }] },
      {
        order: orderShape
      },
      { phoneNumbers: ['number', 'phoneType'] },
      {
        'referringOwner:agent': [
          'email1',
          'email2',
          'firstName',
          'lastName',
          'licenseNumber',
          { agentTeam: ['name'] },
          { agentTeamMemberships: [{ agentTeam: ['name'] }] },
          { phoneNumbers: ['number', 'phoneType'] }
        ]
      },
      {
        'hlssProviderLead:providerLead': [
          'stage',
          'reasonForFail',
          { providerLeadCapitalPartner: [{ capitalPartner: ['name', 'fundingFacility'] }] },
          { providable: simpleSaleLeadProvidableShape },
          { providerLeadExpenses: ['slug', 'category', 'description', 'value'] }
        ]
      }
    ]
  }

  const searchParams = getHapiRequestParams(leadResponseShape)
  const url = getLeadsUrl({ id, searchParams })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: CCHLSimpleSaleLead.create })
}
