// @ts-strict
import { RoleResponse } from 'schema'

export class Role {
  id!: string
  name!: string
  isEmployee?: boolean

  constructor(roleAttributes: Role) {
    Object.assign(this, roleAttributes)
  }
  static create(roleResponse: Potential<RoleResponse>): Potential<Role> {
    if (!roleResponse) {
      return
    }
    return new Role(roleResponse)
  }
}
