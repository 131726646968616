// @ts-strict
import { ReactNode } from 'react'
import { createCtx } from 'contexts/utils'
import { useTeamController } from 'controllers'

export const [useTeamContext, TeamContextProvider] =
  createCtx<ReturnType<typeof useTeamController>>()

type Props = {
  children: ReactNode
  teamId: string
}
export const TeamProvider = ({ children, teamId }: Props) => {
  return <TeamContextProvider value={useTeamController(teamId)}>{children}</TeamContextProvider>
}
