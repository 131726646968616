import { ModelField } from '../ModelField'
import { TMasterPropertyFieldAttribute } from './masterPropertyFieldsSettings'

export class MasterPropertyField extends ModelField {
  attr!: TMasterPropertyFieldAttribute

  constructor(attributes: MasterPropertyField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
