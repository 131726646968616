import { InvestorLead } from 'models'
import { sortByAttrDefinedOrder } from 'utils'
import { InvestorLeadStage } from './InvestorLeadStage'
import { investorLeadStagesSettings } from './investorLeadStagesSettings'

type TStaged = InvestorLead

const allInvestorLeadStages = investorLeadStagesSettings.map(item => new InvestorLeadStage(item))
export const allInvestorLeadStageValues = allInvestorLeadStages.map(item => item.value)
export const sortByInvestorLeadStageAttr = sortByAttrDefinedOrder(
  'stage',
  allInvestorLeadStageValues
)

const activeInvestorLeadStages = allInvestorLeadStages.filter(item => item.active)
export const activeInvestorLeadStageValues = activeInvestorLeadStages.map(item => item.value)
export const sortByActiveInvestorLeadStageAttr = sortByAttrDefinedOrder(
  'stage',
  activeInvestorLeadStageValues
)
export const sortByInvestorLeadRank = (a: InvestorLead, b: InvestorLead) => {
  const rankA = a.providerLead?.rank || 100
  const rankB = b.providerLead?.rank || 100

  return rankA - rankB
}

export const isActiveInvestorLead = (stagedItem: TStaged) =>
  activeInvestorLeadStageValues.some(stage => stage === stagedItem.providerLead.stage)
