import { byAttr } from 'utils'
import { MortgageLeadField } from './MortgageLeadField'
import {
  mortgageLeadFieldsSettings,
  TMortgageLeadFieldAttribute
} from './mortgageLeadFieldsSettings'

export const lookupMortgageLeadFields = mortgageLeadFieldsSettings.map(setting => {
  return new MortgageLeadField(setting)
})

export const getMortgageLeadField = (attr: TMortgageLeadFieldAttribute) => {
  return lookupMortgageLeadFields.find(byAttr('attr', attr))
}

export const getMortgageLeadFieldName = (attr: TMortgageLeadFieldAttribute) => {
  return getMortgageLeadField(attr)?.name
}
