import { getCashOfferLeadsUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export type TPutCashOfferLeadVariables = {
  id: string
  payload: Record<string, string>
}

export const putCashOfferLead = (
  id: TPutCashOfferLeadVariables['id'],
  payload: TPutCashOfferLeadVariables['payload']
): Promise<any> => {
  return putRequest(getCashOfferLeadsUrl({ id }), snakeCaseKeys(payload))
}
