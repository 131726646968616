// @ts-strict
import { FormField, FormFieldSet, Section, Sections, TextArea } from 'components'
import styles from 'content/forms/Form.module.scss'
import { TFormItemsProps } from '../types'

export const NotesSection = ({ payload, onChangeAttribute }: TFormItemsProps) => {
  return (
    <Sections>
      <Section>
        <FormFieldSet>
          <FormField title="Notes">
            <TextArea
              onChange={value => {
                onChangeAttribute('note', value)
              }}
              className={styles.note}
              value={payload?.note}
            />
          </FormField>
        </FormFieldSet>
      </Section>
    </Sections>
  )
}
