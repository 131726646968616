// @ts-strict
import { PARTNERS_CACHE_KEY, useQuery } from 'hooks'
import { fetchPartnersForBbysLeads } from 'services/Partners'

export const usePartners = () => {
  const { data: partners = [] } = useQuery([PARTNERS_CACHE_KEY], fetchPartnersForBbysLeads)

  return {
    partners
  }
}
