import { DEFAULT_STRING } from '../constants'

// type "pct" param correctly
export const formatPercent = (pct: any, defaultValue: any = DEFAULT_STRING) => {
  if (String(Number(pct)) !== String(parseFloat(pct))) {
    return defaultValue
  }
  return `${Number(pct).toLocaleString('en-US', {
    style: 'decimal',
    maximumFractionDigits: 3,
    minimumFractionDigits: 1
  })}%`
}
export const formatPercentFromDecimal = (value?: number | string, maximumFractionDigits = 2) => {
  if (!value || isNaN(Number(value))) {
    return
  }
  const formatted = Number(value) * 100
  return `${formatted.toLocaleString('en-US', {
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits: 1
  })}%`
}
