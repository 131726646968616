import { formatBoolean as boolean } from '../formatBoolean'
import { formatDate as date } from '../formatDate'
import { formatDateTime as dateTime } from '../formatDateTime'
import { formatPercent as percent } from '../formatPercent'
import { formatPercentage as percentage } from '../formatPercentage'
import { formatPhone as phone } from '../formatPhone'
import { formatMoney as money, formatPrice as price } from '../formatPrice'
import { formatStage as stage } from '../formatStage'
import { formatTalkTime as talkTime } from '../formatTalkTime'
import { formatTime as time } from '../formatTime'
import { formatUserType as userType } from '../formatUserType'

export const format = {
  boolean,
  date,
  dateTime,
  money,
  percent,
  percentage,
  phone,
  price,
  stage,
  talkTime,
  time,
  userType
}
