import { byAttr } from 'utils'
import { HLSimpleSaleLeadField } from './HLSimpleSaleLeadField'
import { hlSimpleSaleLeadFieldsSettings, THLSimpleSaleLeadFieldAttribute } from '.'

export const hlSimpleSaleLeadFields = hlSimpleSaleLeadFieldsSettings.map(setting => {
  return new HLSimpleSaleLeadField(setting)
})

export const getHLSimpleSaleLeadField = (attr: THLSimpleSaleLeadFieldAttribute) => {
  return hlSimpleSaleLeadFields.find(byAttr('attr', attr))
}

export const getHLSimpleSaleLeadFieldName = (attr: THLSimpleSaleLeadFieldAttribute) => {
  return getHLSimpleSaleLeadField(attr)?.name
}
