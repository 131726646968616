import {
  AddressUnitAutoComplete,
  DateInput,
  DateTimeInput,
  DollarsAndCentsInput,
  FloatInput,
  FormField,
  IntegerInput,
  MoneyInput,
  PercentInputV2,
  SelectBoolean,
  SelectWithBlank,
  TextInput
} from 'components'
import { TCashOfferLeadFieldAttribute, useCashOfferLeadFields } from 'lookups'
import { useCashOfferDispositionContext } from '../CashOfferDispositionContext'

type Props = {
  field: TCashOfferLeadFieldAttribute
  required?: boolean
}

export const FieldFormField = ({ field }: Props) => {
  const { getCashOfferLeadField } = useCashOfferLeadFields()
  const { dispositionPayload, setDispositionPayloadItem, requiredFields } =
    useCashOfferDispositionContext()

  const formField = getCashOfferLeadField(field)

  const inputComponents = {
    address: AddressUnitAutoComplete,
    boolean: SelectBoolean,
    date: DateInput,
    datetime: DateTimeInput,
    float: FloatInput,
    integer: IntegerInput,
    money: MoneyInput,
    dollarsAndCents: DollarsAndCentsInput,
    percent: PercentInputV2,
    select: SelectWithBlank,
    text: TextInput
  }

  const InputComponent = inputComponents[formField.inputType]

  return (
    <FormField title={formField.name} required={requiredFields.includes(field)}>
      {InputComponent ? (
        <InputComponent
          id={field}
          name={field}
          onChange={val => setDispositionPayloadItem(field, val)}
          value={dispositionPayload[field]}
          options={formField.options}
        />
      ) : null}
    </FormField>
  )
}
