import React from 'react'
import { SearchStatus } from 'components/SearchStatus'
import { SearchSummary } from 'components/SearchSummary'
import { useLeadsContext } from 'contexts/Leads'
import { useParamsContext } from 'contexts/Params'
import { byAttr } from 'utils'

export const LeadsListStatus = () => {
  const { totalCount, isLoading, teamMembersOptions } = useLeadsContext()

  const {
    params: { page, teamMember, ...filtersAndSorts },
    resetParams
  } = useParamsContext()

  const onClear = () => resetParams()

  const canClear = !isLoading && !!Object.keys(filtersAndSorts).length

  const tm = teamMembersOptions ? teamMembersOptions.find(byAttr('value', teamMember)) : undefined

  return (
    <SearchSummary
      status={
        <SearchStatus params={filtersAndSorts} count={totalCount} type="SellerLead" custom={tm} />
      }
      onClear={canClear ? onClear : null}
    />
  )
}
