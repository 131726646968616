// @ts-strict
import { cx } from 'utils'
import { RadioTab } from './RadioTab'
import styles from './RadioTabs.module.scss'

type TProps = {
  className?: string
  onChange: (value: Potential<string>) => void
  options: {
    label: string
    value: string
  }[]
  tabClassName?: string
  value: Potential<string>
}

export const RadioTabs = ({
  options,
  value,
  onChange,
  className,
  tabClassName,
  ...props
}: TProps) => {
  return (
    <div className={cx(styles.radioTabs, className)} {...props}>
      {options.map(option => {
        const { value: optionValue, label: optionLabel } = option

        return (
          <RadioTab
            key={optionValue}
            label={optionLabel}
            value={optionValue}
            checked={value === optionValue}
            onChange={() => onChange(optionValue)}
            className={tabClassName}
          />
        )
      })}
    </div>
  )
}
