// @ts-strict
import { Table, TableResults } from 'components'
import { TableHeaderCell as TH, TableHeaderRow as TR } from 'components'
import { TableCell as Td, TableRow as Tr } from 'components'
import { Agent, AgentPayPerLeadReferral } from 'models'
import styles from './ReferralsActionMenu.module.scss'
import { ReferralsActionMenu } from './'

type ReferralsTableProps = {
  agent: Agent
  referrals: AgentPayPerLeadReferral[]
}

type ReferralsRowProps = {
  agent: Agent
  referral: AgentPayPerLeadReferral
}

export const ReferralsTableReadOnly = ({ agent, referrals }: ReferralsTableProps) => {
  return (
    <Table data-testid="referrals-table" wrapperClassName={styles.table}>
      <TR>
        <TH>Lead</TH>
        <TH>Location Setting</TH>
        <TH>Status</TH>
        <TH>Notes</TH>
        <TH>Actions</TH>
      </TR>
      <TableResults type="Pay-Per-Lead Referrals" colSpan={2}>
        {referrals?.map((referral: AgentPayPerLeadReferral, index: number) => (
          <ReferralsRow key={index} agent={agent} referral={referral} />
        ))}
      </TableResults>
    </Table>
  )
}

const ReferralsRow = ({ agent, referral }: ReferralsRowProps) => {
  return (
    <Tr data-testid="referrals-replace-me">
      <Td>
        <a href={`/leads/${referral?.providerLead?.lead?.id}`} target="_blank" rel="noreferrer">
          {referral?.providerLead?.lead?.name}
        </a>
      </Td>
      <Td>{referral?.location}</Td>
      <Td>{referral?.status || 'unpaid'}</Td>
      <Td>{referral?.notes}</Td>
      <Td>
        <ReferralsActionMenu agent={agent} referral={referral} />
      </Td>
    </Tr>
  )
}
