import { forwardRef } from 'react'
import { useMergeRefs } from '@floating-ui/react'
import { IconPathKeys } from '@foundation/icons'
import { Icon } from '../Icon'
import { useDrawerContext } from './Drawer'
import * as S from './styles'

type DrawerMenuButtonProps = {
  /**
   * A unique key to map with the drawer content
   */
  drawerKey: string
  /**
   * The icon button
   */
  icon: IconPathKeys
  /**
   * A title to display in the header
   */
  title: string
}

export const DrawerMenuButton = forwardRef<HTMLButtonElement, DrawerMenuButtonProps>(
  ({ icon, drawerKey, title }, ref) => {
    const { context, getReferenceProps, openDrawer, activeKey } = useDrawerContext()
    const buttonRef = useMergeRefs([context.refs.setReference, ref])
    const isActive = activeKey === drawerKey
    return (
      <S.MenuButton
        {...getReferenceProps({ ref: buttonRef, onClick: () => openDrawer(drawerKey) })}
        title={title}
        isActive={isActive}
      >
        <Icon path={icon} size="lg" />
      </S.MenuButton>
    )
  }
)

DrawerMenuButton.displayName = 'DrawerMenuButton'
