import { MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { useDrawerContext } from '@foundation/components'
import { Block } from 'components'
import { LeadBadge } from 'content/Lead'
import { ActivityFeedInterface } from 'models/v2/ActivityFeed'
import { LeadModel } from 'models/v2/Lead'
import { dataOrDash } from 'utils'
import { ActivityEventRow } from '../ActivityEventRow'

type Props = {
  activityFeed: ActivityFeedInterface
  'data-testid'?: string
}

export const ActivityLeadEventRow = ({ activityFeed, ...rest }: Props) => {
  const { closeDrawer } = useDrawerContext()
  const leadName = dataOrDash(activityFeed.lead?.name)

  const handleClickLink = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation()
    closeDrawer()
  }

  return (
    <ActivityEventRow
      {...rest}
      title={
        <Block
          title={
            <Link to={LeadModel.getPathname(activityFeed.lead!)} onClick={handleClickLink}>
              {leadName}
            </Link>
          }
          image={activityFeed.lead ? <LeadBadge lead={activityFeed.lead} /> : null}
        />
      }
      date={activityFeed.occurredAt}
      description={activityFeed.title}
      meta={activityFeed.meta}
    />
  )
}
