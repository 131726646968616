// @ts-strict
import { TableBody } from 'components'
import { SimpleTable } from 'components/SimpleTable/SimpleTable'
import { EscrowOrderSettlementTeamMember } from 'models'
import { EscrowOrderSettlementTeamMemberRow } from './EscrowOrderSettlementTeamMemberRow'

type TProps = { escrowOrderSettlementTeamMembers?: Potential<EscrowOrderSettlementTeamMember>[] }

export const EscrowOrderSettlementTeamMemberTable = ({
  escrowOrderSettlementTeamMembers
}: TProps) => (
  <SimpleTable>
    <TableBody>
      {escrowOrderSettlementTeamMembers?.map(
        teamMember =>
          teamMember && (
            <EscrowOrderSettlementTeamMemberRow key={teamMember.id} teamMember={teamMember} />
          )
      )}
    </TableBody>
  </SimpleTable>
)
