import { css, styled } from '@foundation/themes/styles'

const darkBackground = `hsl(0deg 0% 13%)`

export const Arrow = styled.span<{ arrowSide: string; x?: number; y?: number }>`
  ${({ theme, arrowSide, x, y }) => css`
    position: absolute;
    transform: rotate(45deg);
    background-color: ${darkBackground};
    width: ${theme.sizes.$4};
    height: ${theme.sizes.$4};
    ${x ? `left: ${x}px;` : ''};
    ${y ? `top: ${y}px;` : ''};
    ${arrowSide !== undefined && `${arrowSide}: -4px;`};
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    z-index: 10;
    font-size: ${theme.fontSizes.xs};
    width: max-content;
    max-width: 20rem;
    color: ${theme.colors.white};
    background-color: ${darkBackground};
    padding: ${theme.space.$3};
    box-shadow: ${theme.shadows.md};
    border-radius: 0.3rem;
    }
  `}
`
