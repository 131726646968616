import { getUserManagementUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export const postImpersonateUser = async (userId: string) => {
  const response = await postRequest<{ redirect_url?: string }>(
    getUserManagementUrl({ action: 'impersonate-user' }),
    {
      impersonated_user_id: userId,
      return_url: window.location.href,
      set_hapi_cookie: true
    },
    {
      withCredentials: true
    }
  )

  return {
    data: response.data
  }
}
