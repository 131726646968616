import { InputHTMLAttributes } from 'react'
import { TextInput } from 'components'

type TProps = {
  onChange?: (v: string) => void
  value?: string
}

export const EmailInput = ({
  value,
  onChange = () => {},
  ...props
}: TProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const handleOnChange = (val: string) => {
    onChange(val.toLowerCase().replace(/\s/g, ''))
  }

  return (
    <TextInput
      {...props}
      onChange={handleOnChange}
      value={value}
      autoCorrect="off"
      autoCapitalize="off"
      type="email"
      data-testid="email-input"
    />
  )
}
