import { Section } from 'components'
import { FieldFormField } from '../FieldFormField'
import { useFields } from '../useFields'

export const CashOfferPropertyRejected = () => {
  useFields({
    required: ['reasonForFail']
  })
  return (
    <Section>
      <FieldFormField field="reasonForFail" />
    </Section>
  )
}
