import { ModelField } from '../ModelField'
import { TTaskFieldAttribute } from './taskFieldsSettings'

export class TaskField extends ModelField {
  attr!: TTaskFieldAttribute

  constructor(attributes: TaskField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
