// @ts-strict
import { ESCROW_LEADS_CACHE_KEY, useMutation, UseMutationResult, useQueryClient } from 'hooks'
import { postSyncEscrowOrder } from 'services'

type UseSyncEscrowOrderControllerProps = {
  clearCacheKey?: string
  onSuccess?: () => void
}

export const useSyncEscrowOrderController = ({
  onSuccess,
  clearCacheKey
}: UseSyncEscrowOrderControllerProps) => {
  const queryCache = useQueryClient()
  const clearCaches = () => {
    if (clearCacheKey) {
      queryCache.invalidateQueries([clearCacheKey])
    }
    queryCache.invalidateQueries([ESCROW_LEADS_CACHE_KEY])
  }

  const syncEscrowOrder: UseMutationResult<void, unknown, string, unknown> = useMutation(
    escrowOfficeLeadId => postSyncEscrowOrder(escrowOfficeLeadId),
    {
      onSuccess: () => {
        clearCaches()
        if (onSuccess) {
          onSuccess()
        }
      }
    }
  )

  return { syncEscrowOrder }
}
