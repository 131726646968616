import { AgentProviderLeadCard } from 'content/AgentProviderLead/AgentProviderLeadCard'
import { AgentProviderLead } from 'models'

export const AgentProviderLeadsStack = ({
  agentProviderLeads
}: {
  agentProviderLeads: AgentProviderLead[]
}) => {
  if (agentProviderLeads && agentProviderLeads.length) {
    return (
      <>
        {agentProviderLeads.map(agentProviderLead => {
          return (
            <AgentProviderLeadCard
              agentProviderLead={agentProviderLead}
              key={agentProviderLead.id}
            />
          )
        })}
      </>
    )
  }
  return <div>None Found</div>
}
