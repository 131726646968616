// @ts-strict

import { MouseEventHandler, useEffect } from 'react'
import { SearchInputParams, TableCell } from 'components'
import { DeleteIconButton } from 'components/Button/IconButton'
import { AgentsDatatableList } from 'content/Agents'
import { AddAgentToTransactionTeamButtonForm } from 'content/TransactionTeam'
import { ListProvider, useCurrentUserContext } from 'contexts'
import { AgentsProvider } from 'contexts/Agents'
import { useTeamContext } from 'contexts/Team'
import { ReassignPreferredEscrowOfficerOfAgents } from 'features/settlementAgency/reassignPreferredEscrowOfficerOfAgents'
import { useDocumentTitle, useLocation } from 'hooks'
import { agentsListColumns } from 'lookups'
import styles from './AgentsTab.module.scss'

export const AgentsTab = () => {
  const { team, teamId } = useTeamContext()
  const { pathname } = useLocation()
  const { setTitle } = useDocumentTitle()
  const { removeAgent, removeAgentFromSettlementAgencyTeam } = useTeamContext()

  useEffect(() => setTitle(`${team?.name} - Team Agents`), [team, setTitle])

  const handleRemoveAgent: MouseEventHandler<HTMLButtonElement> = e => {
    const mutation =
      team?.type === 'SettlementAgencyTeam' ? removeAgentFromSettlementAgencyTeam : removeAgent

    const agentId = (e?.currentTarget as HTMLButtonElement)?.closest<HTMLElement>('.agent-row')
      ?.dataset?.agentId

    if (!agentId) {
      return
    }

    mutation.mutate(agentId)
  }

  const RowActions = (
    <TableCell align="right">
      <DeleteIconButton onClick={handleRemoveAgent} />
    </TableCell>
  )

  const { isEscrowManager } = useCurrentUserContext()

  const actions = (
    <div className={styles.agentsActions}>
      <AddAgentToTransactionTeamButtonForm team={team} />
      {team.type === 'SettlementAgencyTeam' && isEscrowManager && (
        <ReassignPreferredEscrowOfficerOfAgents team={team} />
      )}
    </div>
  )

  if (!team) {
    return null
  }
  return (
    <AgentsProvider rootPath={pathname} teamId={teamId} teamType={team.type} sortBy="Name">
      <ListProvider columns={agentsListColumns}>
        <AgentsDatatableList
          actions={actions}
          search={
            <SearchInputParams placeholder="Search by name, office, license, phone or email" />
          }
          rowActions={RowActions}
        />
      </ListProvider>
    </AgentsProvider>
  )
}
