// @ts-strict

import { marketplaceProgramSlugs } from 'lookups'
import { Agent } from 'models'

export const getAgentEligibilityCounts = (agents: Potential<Agent[]>) => {
  if (!agents) {
    return {}
  }
  // Max count for each program is the number of agents
  const eligibilityCounts = marketplaceProgramSlugs.reduce((agg, slug) => {
    return { ...agg, [slug]: agents.length }
  }, {})

  // Reduce count for agents already enrolled
  agents?.forEach(agent => {
    agent.marketplaceProgramAgents?.forEach(mpa => {
      if (mpa?.marketplaceProgram?.slug) {
        eligibilityCounts[mpa?.marketplaceProgram?.slug] = mpa.enrolled
          ? eligibilityCounts[mpa.marketplaceProgram.slug] - 1
          : eligibilityCounts[mpa.marketplaceProgram.slug]
      }
    })
  })

  return eligibilityCounts
}
