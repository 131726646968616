import React, { ReactNode } from 'react'
import { cx, showIf } from 'utils'
import styles from './Field.module.scss'
import { FieldHeader } from './FieldHeader'
import { FieldTitle } from './FieldTitle'

type Props = {
  actions?: ReactNode
  children?: ReactNode
  className?: string
  headerClassName?: string
  inline?: boolean
  title?: string
  titleClassName?: string
}

export const Field = ({
  className,
  title,
  actions,
  inline,
  children,
  headerClassName,
  titleClassName
}: Props) => {
  let fieldHeader
  if (title || actions) {
    fieldHeader = (
      <FieldHeader className={headerClassName}>
        {showIf(title, <FieldTitle className={titleClassName}>{title}</FieldTitle>)}
        {actions}
      </FieldHeader>
    )
  }

  return (
    <div className={cx(styles.field, inline ? styles.inline : null, className)} data-testid="field">
      {fieldHeader}
      <div className={styles.contents}>{children}</div>
    </div>
  )
}
