import { EMPLOYEES_CACHE_KEY } from 'controllers/cache'
import { useMutation, useQueryClient } from 'hooks'
import {
  alertError,
  deleteUserIntegration,
  putUserIntegration,
  TUserIntegrationPayload
} from 'services'

export const useUserIntegrationController = (integrationId: string) => {
  const queryCache = useQueryClient()

  const defaultCallback = {
    onSuccess: () => queryCache.invalidateQueries(EMPLOYEES_CACHE_KEY),
    onError: () => alertError()
  }

  const updateUserIntegration = useMutation(
    (payload: TUserIntegrationPayload) => putUserIntegration(integrationId, payload),
    defaultCallback
  )

  const removeUserIntegration = useMutation(
    () => deleteUserIntegration(integrationId),
    defaultCallback
  )

  return {
    updateUserIntegration,
    removeUserIntegration
  }
}
