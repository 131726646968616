// @ts-strict
import { useCallback } from 'react'
import { useLocalStorage } from 'hooks'
import { pick } from 'utils'

export type userSettingsKey = 'devMode' | 'flipDefaultOrders' | 'houseCanary'

export const userSettings: userSettingsKey[] = ['devMode', 'flipDefaultOrders', 'houseCanary']

export const useUserSettings = () => {
  const [settings, setSettings] = useLocalStorage<Partial<Record<userSettingsKey, boolean>>>(
    'userSettings',
    {
      devMode: false,
      flipDefaultOrders: false,
      houseCanary: false
    }
  )

  const toggleKey = (key: userSettingsKey) => {
    setSettings(
      pick(
        {
          ...settings,
          [key]: !settings[key]
        },
        userSettings
      )
    )
  }

  const isEnabled = useCallback((key: userSettingsKey) => settings[key], [settings])

  return { settings, toggleKey, isEnabled }
}
