import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { cx } from 'utils'
import styles from './Tabs.module.scss'

type Props = {
  as?: 'button' | 'link'
  children: ReactNode
  className?: string
  isSelected?: boolean
  onClick?: () => void
  to?: string
}

export const Tab = ({
  to = '',
  onClick,
  isSelected = false,
  className,
  children,
  as: tabAs = 'link'
}: Props) => {
  const selectedClass = isSelected ? styles.selected : null

  const handleOnClick = e => {
    if (onClick) {
      e.stopPropagation()
      onClick()
    }
  }

  if (tabAs === 'button') {
    return (
      <button
        role="tab"
        className={cx(selectedClass, className, styles.tabButton)}
        onClick={handleOnClick}
        aria-selected={isSelected}
      >
        {children}
      </button>
    )
  }

  return (
    <Link
      className={cx(selectedClass, className, styles.tab)}
      to={to}
      onClick={handleOnClick}
      aria-selected={isSelected}
    >
      {children}
    </Link>
  )
}
