import { Lead, Property } from 'models'

export const getProperties: (leads: Lead[]) => Property[] = leads => {
  return leads
    ?.reduce((properties, lead) => {
      const displayName = lead.fullAddress || 'Unknown Location'
      const existingProperty = properties.find(p => p.name === displayName)
      if (existingProperty) {
        existingProperty.leads.push(lead)
      } else {
        properties.push(
          Property.create({
            name: displayName,
            leads: new Array(lead)
          })
        )
      }
      return properties
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => (a.hasTradeIn ? -1 : b.hasTradeIn ? 1 : 0))
}
