// @ts-strict
import { ExternalLink, Field, FormField, If, Section, Sections } from 'components'
import { InvestorLead } from 'models'
import { formatPrice } from 'utils'
import styles from './InvestorDetails.module.scss'

type InvestorDetailsProps = {
  investorLead: InvestorLead
}

export const InvestorDetails = ({ investorLead }: InvestorDetailsProps) => {
  const offerUrl = investorLead.investor.offerUrl

  return (
    <Sections className={styles.section}>
      <Section title="About Investor">
        <FormField title="Program" inline>
          {investorLead.investor?.name}
        </FormField>
        <FormField title="Estimated Range" inline>
          {`${formatPrice(investorLead.estimatedRange?.max)} - ${formatPrice(
            investorLead.estimatedRange?.min
          )}`}
        </FormField>
        <FormField title="Rank" inline>
          {investorLead.providerLead.rank}
        </FormField>
        <If test={offerUrl}>
          <FormField title="Offer URL" inline>
            <ExternalLink href={offerUrl}> View </ExternalLink>
          </FormField>
        </If>
      </Section>
      <Section title="Best Practices">
        <Field>
          {investorLead.investor?.bestPractices &&
            Object.values(investorLead.investor?.bestPractices).map((value: string, i: number) => {
              return <div key={i}> {value} </div>
            })}
        </Field>
      </Section>
    </Sections>
  )
}
