export type TCcTradeInLeadStagesSlug =
  | 'new'
  | 'connected'
  | 'pitched'
  | 'application_started'
  | 'application_complete'
  | 'conditionally_approved'
  | 'approved'
  | 'agreement_signed'
  | 'in_escrow_purchase'
  | 'closed_purchase'
  | 'listed'
  | 'in_escrow_sell'
  | 'closed_sell'
  | 'closed_paid'
  | 'nurture'
  | 'failed'
  | 'ip_in_escrow'
  | 'ip_closed'
  | 'client_listed'
  | 'client_in_escrow'
  | 'client_closed'
  | 'called_left_msg'

export type TCcTradeInLeadStageSetting = {
  name: string
  nextStageSlugs?: TCcTradeInLeadStagesSlug[]
  other?: boolean
  slug: TCcTradeInLeadStagesSlug
  validPreviousStage?: boolean
}

export const ccTradeInLeadStagesSettings: Array<TCcTradeInLeadStageSetting> = [
  {
    name: 'New',
    slug: 'new',
    nextStageSlugs: ['connected', 'called_left_msg'],
    validPreviousStage: true
  },
  {
    name: 'Connected',
    slug: 'connected',
    nextStageSlugs: ['pitched', 'application_started'],
    validPreviousStage: true
  },
  {
    name: 'Pitched',
    slug: 'pitched',
    nextStageSlugs: ['application_started'],
    validPreviousStage: true
  },
  {
    name: 'Application Started',
    slug: 'application_started',
    nextStageSlugs: ['application_complete'],
    validPreviousStage: true
  },
  {
    name: 'Application Complete',
    slug: 'application_complete',
    nextStageSlugs: ['conditionally_approved'],
    validPreviousStage: true
  },
  {
    name: 'Conditionally Approved',
    slug: 'conditionally_approved',
    nextStageSlugs: ['approved'],
    validPreviousStage: true
  },
  {
    name: 'Approved',
    slug: 'approved',
    nextStageSlugs: ['agreement_signed'],
    validPreviousStage: true
  },
  {
    name: 'Agreement Signed',
    slug: 'agreement_signed',
    nextStageSlugs: ['in_escrow_purchase', 'ip_in_escrow'],
    validPreviousStage: true
  },
  {
    name: 'IR In Escrow',
    slug: 'ip_in_escrow',
    validPreviousStage: true,
    nextStageSlugs: ['ip_closed', 'in_escrow_purchase']
  },
  {
    name: 'IR Closed',
    slug: 'ip_closed',
    validPreviousStage: true,
    nextStageSlugs: ['client_listed', 'in_escrow_purchase']
  },
  {
    name: 'Client Listed',
    slug: 'client_listed',
    validPreviousStage: true,
    nextStageSlugs: ['client_in_escrow', 'in_escrow_purchase']
  },
  {
    name: 'Client In Escrow',
    slug: 'client_in_escrow',
    validPreviousStage: true,
    nextStageSlugs: ['client_closed', 'in_escrow_purchase']
  },
  {
    name: 'Client Closed',
    slug: 'client_closed',
    validPreviousStage: true,
    nextStageSlugs: ['closed_paid', 'in_escrow_purchase']
  },
  {
    name: 'In Escrow Purchase',
    slug: 'in_escrow_purchase',
    nextStageSlugs: ['closed_purchase'],
    validPreviousStage: true
  },
  {
    name: 'Closed Purchase',
    slug: 'closed_purchase',
    nextStageSlugs: ['listed'],
    validPreviousStage: true
  },
  { name: 'Listed', slug: 'listed', nextStageSlugs: ['in_escrow_sell'], validPreviousStage: true },
  {
    name: 'In Escrow Sell',
    slug: 'in_escrow_sell',
    nextStageSlugs: ['closed_sell'],
    validPreviousStage: true
  },
  {
    name: 'Closed Sell',
    slug: 'closed_sell',
    nextStageSlugs: ['closed_paid'],
    validPreviousStage: true
  },
  { name: 'Closed Paid', slug: 'closed_paid', validPreviousStage: true },
  { name: 'Nurture', slug: 'nurture', other: true },
  { name: 'Failed', slug: 'failed', other: true }
]

/*
For future reference when removing the left-message-stage feature flag:
Left Message is after New and before Connected
*/
const generateTradeInLeadStagesSettingsWithLeftMessage = () => {
  const settings = [...ccTradeInLeadStagesSettings]
  settings.splice(1, 0, {
    name: 'Left Message',
    slug: 'called_left_msg',
    nextStageSlugs: ['connected'],
    validPreviousStage: true
  })

  return settings
}

export const ccTradeInLeadStagesLeftMessageSettings: Array<TCcTradeInLeadStageSetting> =
  generateTradeInLeadStagesSettingsWithLeftMessage()

/*
Left Message should replace Pitched
New -> Left Message -> Connected
Pitched should be REMOVED after feature flag removed (this COULD break the front-end
if  existing pitched leads are not backfilled)
*/
const generateTradeInLeadStagesSettingsWithoutPitched = () => {
  const settings = [...ccTradeInLeadStagesSettings]
  settings.splice(
    0,
    3,
    {
      name: 'New',
      slug: 'new',
      nextStageSlugs: ['connected', 'called_left_msg'],
      validPreviousStage: true
    },
    {
      name: 'Left Message',
      slug: 'called_left_msg',
      nextStageSlugs: ['connected'],
      validPreviousStage: true
    },
    {
      name: 'Connected',
      slug: 'connected',
      nextStageSlugs: ['pitched', 'application_started'],
      validPreviousStage: true
    },

    {
      name: 'Pitched',
      slug: 'pitched',
      nextStageSlugs: ['application_started'],
      validPreviousStage: false
    }
  )

  return settings
}

export const ccTradeInLeadStagesWithoutPitchedSettings: Array<TCcTradeInLeadStageSetting> =
  generateTradeInLeadStagesSettingsWithoutPitched()

const generateExpressTrandeInLeadStagesSettings = () => {
  const settings = [...ccTradeInLeadStagesWithoutPitchedSettings]
  settings.splice(
    5,
    5,
    {
      name: 'Application Complete',
      slug: 'application_complete',
      nextStageSlugs: ['approved'],
      validPreviousStage: true
    },
    {
      name: 'Approved',
      slug: 'approved',
      nextStageSlugs: ['agreement_signed'],
      validPreviousStage: true
    },
    {
      name: 'Agreement Signed',
      slug: 'agreement_signed',
      nextStageSlugs: ['in_escrow_purchase', 'ip_in_escrow'],
      validPreviousStage: true
    },
    {
      name: 'IR In Escrow',
      slug: 'ip_in_escrow',
      validPreviousStage: true,
      nextStageSlugs: ['ip_closed', 'in_escrow_purchase']
    }
  )
  return settings
}

export const ccTradeInLeadExpressStages: Array<TCcTradeInLeadStageSetting> =
  generateExpressTrandeInLeadStagesSettings()
