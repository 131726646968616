// @ts-strict
import { Option } from 'classes'
import { IOptionable } from 'lookups'
import { TeamMembership } from 'models'
import { TransactionTeamResponse } from 'schema'
import { titleize } from 'utils'
import { Model } from '../Model'

export type TeamType = 'TransactionTeam' | 'ClientAdvisoryTeam' | 'SettlementAgencyTeam'
export class TransactionTeam extends Model implements IOptionable {
  id!: string
  name!: string
  description?: string
  enabled?: boolean
  teamMemberships?: Potential<TeamMembership[]>
  type!: TeamType

  constructor(
    attributes: Omit<TransactionTeam, 'toOption' | 'userExists' | 'roleExists' | keyof Model>
  ) {
    super()
    Object.assign(this, attributes)
  }

  userExists(id: string): boolean {
    return !!this.teamMemberships?.some(member => member.user?.id === id)
  }
  roleExists(role: string): boolean {
    return !!this.teamMemberships?.some(member => member.role === role)
  }

  static create(teamAttributes: Potential<TransactionTeamResponse>): Potential<TransactionTeam> {
    if (!teamAttributes) {
      return
    }
    const teamMemberships = teamAttributes.teamMemberships
      ? (teamAttributes.teamMemberships
          .map(tm => TeamMembership.create(tm))
          .filter(Boolean) as TeamMembership[])
      : undefined
    return new TransactionTeam({ ...teamAttributes, teamMemberships })
  }

  toOption = () => {
    return new Option({ name: titleize(this.name), value: this.id })
  }
}
