// @ts-strict
import { Contents, Page } from 'components'
import { CreateTeamButtonForm } from 'content/Team/TeamButtonForm/CreateTeamButtonForm'
import { TeamsList } from 'content/Teams'
import { TeamsProvider } from 'contexts'
import { useDocumentTitle } from 'hooks'

const TEAM_TYPE = 'ClientAdvisoryTeam'

export const ClientAdvisorTeamsPage = () => {
  useDocumentTitle('Teams')

  return (
    <TeamsProvider type={TEAM_TYPE}>
      <Page>
        <Contents title="Client Advisory Teams" actions={<CreateTeamButtonForm />}>
          <TeamsList />
        </Contents>
      </Page>
    </TeamsProvider>
  )
}
