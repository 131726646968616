import { Table, TableResults } from 'components'
import { Order } from 'models'
import styles from './OrdersTable.module.scss'
import { OrdersTableHeader } from './OrdersTableHeader'
import { OrdersTableRow } from './OrdersTableRow'

type OrdersTableProps = {
  isLoading: boolean
  orders: Order[]
}

export const OrdersTable = ({ orders, isLoading }: OrdersTableProps) => {
  return (
    <Table className={styles.table}>
      <OrdersTableHeader />
      <TableResults type="Orders" colSpan={8} isLoading={isLoading}>
        {orders?.map(order => (
          <OrdersTableRow key={order.id} order={order} />
        ))}
      </TableResults>
    </Table>
  )
}
