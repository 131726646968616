import { Task } from 'models'
import { TaskResponse } from 'schema'

export const getTasks = (tasks?: TaskResponse[]): Task[] => {
  if (!tasks) {
    return []
  }

  return tasks.map(Task.create).filter((el): el is Task => el !== undefined)
}
