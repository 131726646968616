import { Block, TableCell as TD, Tooltip, TableRow as TR } from 'components'
import { useListContext } from 'contexts'
import { Envelope } from 'models'
import { formatDate } from 'utils'
import styles from './EnvelopesTable.module.scss'

type Props = {
  envelope: Envelope
}

export const EnvelopeTableRow = ({ envelope }: Props) => {
  const { showColumn } = useListContext()

  return (
    <TR>
      {showColumn('template') && (
        <TD className={styles.expanding}>
          <Block
            title={
              <a href={envelope.envelopeDetailsUrl} target="_blank" rel="noreferrer">
                <Tooltip>{envelope.templateSlug}</Tooltip>
              </a>
            }
            subtitle={envelope.subAttachable?.incomingPropertyFullAddress}
          />
        </TD>
      )}

      {showColumn('createdBy') && (
        <TD className={styles.expanding}>
          <Block
            title={<Tooltip>{envelope.createdBy.name}</Tooltip>}
            subtitle={formatDate(envelope.createdAt)}
          />
        </TD>
      )}
      {showColumn('recipients') && (
        <TD className={styles.expanding}>
          {envelope.recipients.map((recipient, idx) => (
            <Block
              key={idx}
              title={<Tooltip>{recipient.name}</Tooltip>}
              subtitle={<Tooltip>{recipient.email}</Tooltip>}
            />
          ))}
        </TD>
      )}
      {showColumn('status') && (
        <TD className={styles.status}>
          <Block
            title={<Tooltip>{envelope.displayStatus}</Tooltip>}
            subtitle={
              <Tooltip>
                {envelope.status === 'completed' ? formatDate(envelope.completedAt) : ''}
              </Tooltip>
            }
          />
        </TD>
      )}
      {showColumn('sent') && <TD className={styles.sent}>{formatDate(envelope.sentAt)}</TD>}
    </TR>
  )
}
