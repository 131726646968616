import { Block, List, TableCell as Td, TableRow as Tr } from 'components'
import { Listing } from 'models'
import { formatDate, formatPrice, formatTime, presence } from 'utils'

export const ListingTableRow = ({ listing }: { listing: Listing }) => {
  const addressLine =
    listing.location.hlFullAddress ||
    `${presence(String(listing.location.streetNumber))} ${presence(listing.location.streetName)}`

  return (
    <Tr data-testid={`listing-id-${listing.id}`}>
      <Td>
        <Block
          title={addressLine}
          subtitle={`${listing.location.city}, ${listing.location.state} ${listing.location.zipCode}`}
        />
        <List>
          {addressLine}
          {`${[listing.location.city, listing.location.state].filter(Boolean).join(', ')} ${
            listing.location.zipCode
          }`}
        </List>
      </Td>
      <Td>{formatPrice(listing.listingDetails.listPrice, 'short')}</Td>
      <Td>{listing.listingDetails.daysOnMarket}</Td>
      <Td>{listing.listingDetails.mlsStatus}</Td>
      <Td>
        <Block
          title={formatDate(listing.listingDetails.listingContractDate)}
          subtitle={formatTime(listing.listingDetails.listingContractDate)}
        />
      </Td>
      <Td>
        <Block
          title={formatDate(listing.listingIdentity.modificationTs)}
          subtitle={formatTime(listing.listingIdentity.modificationTs)}
        />
      </Td>
    </Tr>
  )
}
