import { Agent } from 'models'
import { getAgentTeamUrl } from 'services/getUrl'
import { postRequest } from 'utils'

type Args = {
  agentId: Agent['id']
  name: Agent['name']
}

export const postAgentTeam = ({ agentId, name }: Args): Promise<any> => {
  return postRequest(getAgentTeamUrl(), {
    agent_id: agentId,
    name: name
  })
}
