// @ts-strict
import { Button } from '@foundation/components'
import { ButtonForm, FileDropzoneInput, FormField, useButtonForm } from 'components'
import { usePhotoUploadsController } from 'controllers'
import { Lead } from 'models'
import { TFormValidation, validateFileSize, validatePresence } from 'utils/validations'

type TProps = {
  lead: Lead
}

export type TLeadUploadPhotosPayload = {
  photos: File[]
}

export const LeadUploadPhotosButtonForm = ({ lead }: TProps) => {
  const { uploadPhotos } = usePhotoUploadsController({ leadId: lead.id })

  const initialPayload: TLeadUploadPhotosPayload = {
    photos: []
  }

  const formValidations: TFormValidation = {
    photos: {
      label: 'Photos',
      validations: [validatePresence, validateFileSize({ max: 20971520 })]
    }
  }

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm<TLeadUploadPhotosPayload>({
    initialPayload,
    formValidations,
    mutation: uploadPhotos
  })

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={formProps.toggleShowForm}>
        Upload Photos
      </Button>
      <ButtonForm title="Upload Photos" hideTriggerButton {...formProps}>
        <FormField htmlFor="photos">
          <FileDropzoneInput
            id="photos"
            accept={['gif', 'jpg', 'jpeg', 'heic', 'heif', 'png']}
            value={payload.photos}
            onChange={value => onChangeAttribute('photos', value)}
            data-testid="photo-upload-input"
            multiple
          />
        </FormField>
      </ButtonForm>
    </>
  )
}
