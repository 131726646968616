// @ts-strict

import {
  DisplayReadOnlyValue,
  ExternalLink,
  FormField,
  InlineEditAddressUnit,
  InlineEditCityState,
  InlineEditSelect,
  List,
  MapBox,
  Section,
  Sections
} from 'components'
import { useCurrentUserContext } from 'contexts'
import { useHomeValueController } from 'controllers'
import { agentMatchingLeadPropertyTypes, yesOrNoForJsonOptions } from 'lookups'
import { BuyerLead, isSellerLead, MortgageLead, SellerLead } from 'models'
import { dataOrDash, formatPrice, structureAddress, toSearchParam } from 'utils'
import styles from '../AgentMatchingLead.module.scss'

type Props = {
  lead: BuyerLead | SellerLead | MortgageLead
  updateCallReview: (key: string, value: string) => void
  updateField: (value: Record<string, string | number | Record<string, string>>) => void
}

export const AgentMatchingLeadPropertyDetailsSection = ({
  lead,
  updateCallReview,
  updateField
}: Props) => {
  const { city } = structureAddress(lead.fullAddress)
  const paramsCity = city ? city.split(', ').join('_') : ''
  const { currentUser } = useCurrentUserContext()
  const { homeValueEstimate } = useHomeValueController(lead)

  return (
    <Sections title="Property Details">
      <Section>
        <FormField title="Full Address" inline>
          {isSellerLead(lead) ? (
            <InlineEditAddressUnit
              className={lead.propertyUuid ? '' : styles.invalidValue}
              value={lead?.fullAddress}
              onBlur={(val: string) => {
                updateField({ fullAddress: val })
              }}
            />
          ) : (
            <InlineEditCityState
              className={lead.areaId ? '' : styles.invalidValue}
              value={city}
              onBlur={(val: string) => {
                const [city, state] = val.split(',').map(field => field.trim())
                updateField({ ...(city && { cityName: city }), ...(state && { stateName: state }) })
              }}
            />
          )}
        </FormField>
        <FormField title="Property Type" inline>
          <InlineEditSelect
            onChange={(val: string) => {
              updateField({ propertyType: val })
            }}
            options={agentMatchingLeadPropertyTypes}
            value={dataOrDash(lead?.propertyType)}
          />
        </FormField>
        <FormField title="Foreclosure" inline>
          <InlineEditSelect
            options={yesOrNoForJsonOptions}
            onChange={(val: string) => updateCallReview('foreclosure', val)}
            value={lead?.callReview?.foreclosure}
          />
        </FormField>
        {isSellerLead(lead) && (
          <FormField title="Home Value Estimate" inline>
            <DisplayReadOnlyValue
              value={dataOrDash(Number(homeValueEstimate?.dollarValue))}
              format={formatPrice}
            />
          </FormField>
        )}
        <FormField title="External Links" inline>
          <List>
            <ExternalLink
              href={`https://www.google.com/search?q=${toSearchParam(lead.fullAddress)}`}
            >
              Google Address
            </ExternalLink>
            <ExternalLink
              href={`http://www.zillow.com/homes/${toSearchParam(lead.fullAddress)}_rb`}
            >
              Zillow Address
            </ExternalLink>
            <ExternalLink
              href={`http://www.realtor.com/realestateagents/${paramsCity}/realtorType-Agent`}
            >
              Realtor.com
            </ExternalLink>
          </List>
        </FormField>
        {currentUser?.roles?.lenderAe && (
          <FormField title="Equity Unlock Calculator" inline>
            <ExternalLink href="https://staging.agent.homelight.com/lender/equity-unlock-calculator">
              Equity Unlock Calculator
            </ExternalLink>
          </FormField>
        )}
      </Section>
      <Section>
        {lead.latitude && lead.longitude ? (
          <MapBox center={lead.toPoint()} markers={[lead.toMarker()]} />
        ) : (
          <FormField title="Map" inline>
            Latitude and Longitude are missing
          </FormField>
        )}
      </Section>
    </Sections>
  )
}
