import { LeadViewersResponse } from 'schema'

export class LeadViewers {
  firstName: string
  lastName: string
  id: string
  initials?: string

  constructor(leadViewersAttributes: LeadViewersResponse) {
    Object.assign(this, leadViewersAttributes)
  }

  static create(leadViewersResponse: Potential<LeadViewersResponse>): Potential<LeadViewers> {
    if (!leadViewersResponse) {
      return
    }

    const firstLetter = leadViewersResponse.firstName.charAt(0)
    const lastLetter = leadViewersResponse.lastName.charAt(0)
    const initials = `${firstLetter}${lastLetter}`

    return new LeadViewers({ ...leadViewersResponse, initials })
  }
}
