// @ts-strict
import { Table, TableResults } from 'components'
import { useAgentAeAreasContext } from 'contexts/AgentAeAreas'
import { AgentAeArea } from 'models'
import styles from './AgentAeAreasTable.module.scss'
import { AgentAeAreasTableHeader, AgentAeAreasTableRow } from '.'

export const AgentAeAreasTable = () => {
  const { agentAeAreasQuery } = useAgentAeAreasContext()
  const { agentAeAreas, isLoading } = agentAeAreasQuery

  return (
    <Table className={styles.table}>
      <AgentAeAreasTableHeader />
      <TableResults type="Agent Ae Areas" colSpan={8} isLoading={isLoading}>
        {agentAeAreas?.map((agentAeArea: AgentAeArea) => (
          <AgentAeAreasTableRow key={agentAeArea.id} agentAeArea={agentAeArea} />
        ))}
      </TableResults>
    </Table>
  )
}
