import { TLeadUserTypeServerSlug } from 'lookups'
import {
  getBBYSPathName,
  getBuyerPathName,
  getCashOfferPathName,
  getEscrowPathName,
  getLeadPathName,
  getMortgagePathName,
  getSellerPathName,
  getSimpleSalePathName,
  getTradeInPathName
} from '.'

/**
 *  if using models/V2, please use this utility from models/v2/Lead
 */
export const getAnyLeadPathName = (id?: string, userType?: TLeadUserTypeServerSlug) => {
  if (!id || !userType) {
    return ''
  }
  switch (userType) {
    case 'buyer':
      return getBuyerPathName(id)
    case 'seller':
      return getSellerPathName(id)
    case 'cc_trade_in':
      return getTradeInPathName(id)
    case 'cc_cash_offer':
      return getCashOfferPathName(id)
    case 'escrow':
      return getEscrowPathName(id)
    case 'mortgage':
      return getMortgagePathName(id)
    case 'cc_hl_simple_sale':
      return getSimpleSalePathName(id)
    case 'bbys':
      return getBBYSPathName(id)
    default:
      return getLeadPathName(id)
  }
}
