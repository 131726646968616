// @ts-strict
import { EmptyTableCell, TableRow as Tr } from 'components'
import {
  CCCashOfferLeadCapitalMarketsFacilityCell,
  CCCashOfferLeadDaysUntilFundingCell,
  CCCashOfferLeadFundingAmountCell,
  CCCashOfferLeadFundingDateCell,
  CCCashOfferLeadFundingFacilityCell,
  CCCashOfferLeadHoaCell,
  CCCashOfferLeadIRPurchaseCOEDateCell,
  CCCashOfferLeadIRPurchaseOpenEscrowDate,
  CCCashOfferLeadIRSaleActualCOEDateCell,
  CCCashOfferLeadIRSellOpenEscrowDate,
  CCCashOfferLeadSolarPanelsCell
} from 'content/CCCashOfferLeads/CCCashOfferLeadsTable/CCCashOfferLeadsTableCells'
import {
  CashCloseLeadConsumerClientManagerCell,
  CashCloseLeadContractAdministratorCell,
  CashCloseLeadListingSpecialistCell,
  CashCloseLeadSalesSpecialistCell,
  CashCloseLeadStrategicRelationshipManagerCell,
  CashCloseLeadTransactionSpecialistCell,
  CashCloseLeadTxTeamCell,
  CashCloseLoanOfficerTeamLeadCell
} from 'content/CashCloseLeads/CashCloseLeadsTableCells'
import {
  LeadAddressCell,
  LeadAgentCell,
  LeadClientAdvisorAssistantCell,
  LeadClientAdvisorCell,
  LeadCreatedUpdatedCell,
  LeadDaysSinceLastStageUpdateCell,
  LeadLastStageUpdateCell,
  LeadNamePartnerCell,
  LeadOtherLeadsCell,
  LeadPropertyTypeCell,
  LeadProvidableAgentCell,
  LeadPurchaseEscrowAssistantCell,
  LeadPurchaseEscrowExpectedCloseDateCell,
  LeadPurchaseEscrowOfficerCell,
  LeadPurchaseEscrowOrderOpenedDateCell,
  LeadPurchaseEscrowStatusCell,
  LeadPurchaseEscrowTitleOfficerCell,
  LeadSaleEscrowAssistantCell,
  LeadSaleEscrowExpectedCloseDateCell,
  LeadSaleEscrowOfficerCell,
  LeadSaleEscrowOrderOpenedDateCell,
  LeadSaleEscrowStatusCell,
  LeadSaleEscrowTitleOfficerCell,
  LeadSourceCell,
  LeadStageFurthestCell,
  LeadTaskCell
} from 'content/Leads/LeadsTable/LeadsTableCells'
import { useListContext } from 'contexts'
import { CCCashOfferLead } from 'models'
import { capitalize } from 'utils'

const columnMapping = {
  namePartner: LeadNamePartnerCell,
  address: LeadAddressCell,
  createdUpdated: LeadCreatedUpdatedCell,
  lastStageUpdate: LeadLastStageUpdateCell,
  otherLeads: LeadOtherLeadsCell,
  propertyType: LeadPropertyTypeCell,
  source: LeadSourceCell,
  stageFurthest: LeadStageFurthestCell,
  txTeam: CashCloseLeadTxTeamCell,
  incomingPropertyBuyerAgent: LeadProvidableAgentCell,
  referringAgent: LeadAgentCell,
  daysSinceLastStageUpdate: LeadDaysSinceLastStageUpdateCell,
  hoa: CCCashOfferLeadHoaCell,
  solarPanels: CCCashOfferLeadSolarPanelsCell,
  fundingAmount: CCCashOfferLeadFundingAmountCell,
  irPurchaseCoeDate: CCCashOfferLeadIRPurchaseCOEDateCell,
  irSaleActualCoeDate: CCCashOfferLeadIRSaleActualCOEDateCell,
  irPurchaseOpenEscrowDate: CCCashOfferLeadIRPurchaseOpenEscrowDate,
  irSaleOpenEscrowDate: CCCashOfferLeadIRSellOpenEscrowDate,
  fundingFacility: CCCashOfferLeadFundingFacilityCell,
  fundingDate: CCCashOfferLeadFundingDateCell,
  strategicRelationshipManager: CashCloseLeadStrategicRelationshipManagerCell,
  consumerClientManager: CashCloseLeadConsumerClientManagerCell,
  salesSpecialist: CashCloseLeadSalesSpecialistCell,
  contractAdministrator: CashCloseLeadContractAdministratorCell,
  transactionSpecialist: CashCloseLeadTransactionSpecialistCell,
  listingSpecialist: CashCloseLeadListingSpecialistCell,
  clientAdvisor: LeadClientAdvisorCell,
  clientAdvisorAssistant: LeadClientAdvisorAssistantCell,
  capitalMarketsFacility: CCCashOfferLeadCapitalMarketsFacilityCell,
  daysUntilFunding: CCCashOfferLeadDaysUntilFundingCell,
  cashOfferCheckHoaStatusAndDues: LeadTaskCell,
  cashOfferValueIrProperty: LeadTaskCell,
  cashOfferReceiveInspectionReports: LeadTaskCell,
  cashOfferReviewInspectionReports: LeadTaskCell,
  cashOfferSendCashOfferAgreement: LeadTaskCell,
  cashOfferReceiveCashOfferAgreement: LeadTaskCell,
  cashOfferSendOfferSubmissionDocuments: LeadTaskCell,
  cashOfferSendIntentToProceed: LeadTaskCell,
  cashOfferReceiveIntentToProceed: LeadTaskCell,
  cashOfferReceiveIrContract: LeadTaskCell,
  cashOfferReviewIrContract: LeadTaskCell,
  cashOfferMonitorCoeFirstReminder: LeadTaskCell,
  cashOfferMonitorCoeSecondReminder: LeadTaskCell,
  cashOfferMonitorCoeThirdReminder: LeadTaskCell,
  cashOfferSendAssignmentAddendumAndPoa: LeadTaskCell,
  cashOfferReceiveAssignmentAddendumAndPoa: LeadTaskCell,
  cashOfferReceiveIrBuyDisclosures: LeadTaskCell,
  cashOfferAssignFundingFacility: LeadTaskCell,
  cashOfferRequestPropertyInsurance: LeadTaskCell,
  cashOfferFundProperty: LeadTaskCell,
  cashOfferExecuteBuyerClosingPackage: LeadTaskCell,
  cashOfferSendIrSellContract: LeadTaskCell,
  cashOfferReceiveIrSellContract: LeadTaskCell,
  cashOfferSendIrSellDisclosures: LeadTaskCell,
  cashOfferReceiveIrSellDisclosures: LeadTaskCell,
  cashOfferExecuteSellerClosingPackage: LeadTaskCell,
  cashOfferCloseOfEscrowUpdate: LeadTaskCell,
  cashOfferCommunicateIrCoeDateChange: LeadTaskCell,
  loanOfficerTeamLead: CashCloseLoanOfficerTeamLeadCell,
  purchaseEscrowStatus: LeadPurchaseEscrowStatusCell,
  purchaseEscrowOrderOpenedDate: LeadPurchaseEscrowOrderOpenedDateCell,
  purchaseEscrowExpectedCloseDate: LeadPurchaseEscrowExpectedCloseDateCell,
  purchaseEscrowOfficer: LeadPurchaseEscrowOfficerCell,
  purchaseEscrowAssistant: LeadPurchaseEscrowAssistantCell,
  purchaseEscrowTitleOfficer: LeadPurchaseEscrowTitleOfficerCell,
  saleEscrowStatus: LeadSaleEscrowStatusCell,
  saleEscrowOrderOpenedDate: LeadSaleEscrowOrderOpenedDateCell,
  saleEscrowExpectedCloseDate: LeadSaleEscrowExpectedCloseDateCell,
  saleEscrowOfficer: LeadSaleEscrowOfficerCell,
  saleEscrowAssistant: LeadSaleEscrowAssistantCell,
  saleEscrowTitleOfficer: LeadSaleEscrowTitleOfficerCell
}

type TProps = {
  lead: CCCashOfferLead
}

export const CCCashOfferLeadTableRow = ({ lead }: TProps) => {
  const { visibleColumns, getColumnAlignment } = useListContext()

  return (
    <Tr data-testid={`lead-id-${lead.id}`}>
      {visibleColumns.map(visibleColumn => {
        const Cell = columnMapping[visibleColumn]
        const testId = `lead${capitalize(visibleColumn)}Cell`
        const align = getColumnAlignment(visibleColumn)

        return Cell ? (
          <Cell
            lead={lead}
            key={visibleColumn}
            column={visibleColumn}
            testId={testId}
            align={align}
          />
        ) : (
          <EmptyTableCell key={visibleColumn} align={align} />
        )
      })}
    </Tr>
  )
}
