import { TColumnAttributes } from '../../Column'

const columns: Record<string, TColumnAttributes> = {
  template: {
    name: 'Template',
    value: 'template'
  },
  createdBy: {
    name: 'Created By',
    value: 'createdBy'
  },
  recipients: {
    name: 'Recipients',
    value: 'recipients'
  },
  status: {
    name: 'Status',
    value: 'status'
  },
  sent: {
    name: 'Sent',
    value: 'sent'
  }
}

export const docusignColumnsSettings = [
  columns.template,
  columns.createdBy,
  columns.recipients,
  columns.status,
  columns.sent
] as const

export type TDocusignColumnValue = (typeof docusignColumnsSettings)[number]['value']
