// @ts-strict
import { CCTradeInLead, ProviderLeadCapitalPartner, ServicesOpportunity, TradeInLead } from 'models'
import { ProviderLeadResponse, TradeInLeadResponse } from 'schema'

type ProviderLeadExpenses = {
  category: string
  description: string
  id: string
  slug: string
  value: string
}

export class TradeInProviderLead {
  id!: string
  createdAt?: string
  followUpDate?: string
  lead?: CCTradeInLead
  providerLeadCapitalPartner?: ProviderLeadCapitalPartner
  reasonForFail?: string
  representedProduct?: string
  servicesOpportunities?: ServicesOpportunity[]
  stage?: string
  tradeInLead?: TradeInLead
  providerLeadExpenses?: ProviderLeadExpenses[]
  constructor(tradeInProviderLeadAttributes: TradeInProviderLead) {
    Object.assign(this, tradeInProviderLeadAttributes)
  }

  static create(
    tradeInProviderLeadResponse: Potential<ProviderLeadResponse>
  ): Potential<TradeInProviderLead> {
    if (!tradeInProviderLeadResponse) {
      return
    }
    const lead = CCTradeInLead.create(tradeInProviderLeadResponse?.lead)
    const providerLeadCapitalPartner = ProviderLeadCapitalPartner.create(
      tradeInProviderLeadResponse.providerLeadCapitalPartner
    )
    const tradeInLead = TradeInLead.create(
      tradeInProviderLeadResponse?.providable as TradeInLeadResponse
    )
    return new TradeInProviderLead({
      ...tradeInProviderLeadResponse,
      lead,
      providerLeadCapitalPartner,
      tradeInLead
    })
  }
}
