// @ts-strict
import type {
  INSPECTION_DEFICIENCY_COLUMN_SLUGS_TYPE,
  INSPECTION_DEFICIENCY_FILTER_SLUGS_TYPE
} from 'lookups'
import { getFilter, getSort } from '../utils'

type FilterSlugs = PartialRecord<
  INSPECTION_DEFICIENCY_FILTER_SLUGS_TYPE | 'page' | 'perPage',
  string
>
const filterSlugs: FilterSlugs = {
  page: 'page[number]',
  perPage: 'page[size]',
  filterByProviderLeadId: 'filter[provider_lead_id]',
  filterByCategory: 'filter[category]',
  filterBySeverity: 'filter[severity]',
  filterByInspectionType: 'filter[inspection_types]',
  filterByEscalated: 'filter[escalated]'
}

type SortSlugs = PartialRecord<INSPECTION_DEFICIENCY_COLUMN_SLUGS_TYPE, string>
const sortSlugs: SortSlugs = {
  reportInspectionTypes: 'inspection_report.inspection_types',
  category: 'category',
  severity: 'severity'
}

export type InspectionDeficienciesSearchParams = FilterSlugs & {
  sortBy?: keyof typeof sortSlugs
  sortDir?: SortDirection
}

export const getInspectionDeficienciesSearchParams = ({
  sortDir,
  sortBy,
  filterByProviderLeadId,
  page,
  perPage,
  filterByCategory,
  filterBySeverity,
  filterByInspectionType,
  filterByEscalated
}: InspectionDeficienciesSearchParams) => {
  const result = getFilter(filterSlugs, {
    filterByProviderLeadId,
    page,
    perPage,
    filterByCategory,
    filterBySeverity,
    filterByInspectionType,
    filterByEscalated
  })
  result['sort'] = getSort(sortSlugs, sortDir, sortBy, false)
  return result
}
