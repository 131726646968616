import { Pagination } from 'components'
import { useParamsContext } from 'contexts/Params'

interface Props {
  totalPages?: number
}

export const PaginationParams = ({ totalPages }: Props) => {
  const { params, updatePage } = useParamsContext()

  if (!totalPages) {
    return null
  }

  return <Pagination totalPages={totalPages} currentPage={params.page} onChange={updatePage} />
}
