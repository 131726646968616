import { Section, Sections } from 'components'
import { FieldFormField } from '../../FieldFormField'
import { useFields } from '../../useHLSSLeadDisposition'

const econModel = {
  readOnly: true,
  title: 'Editable only on Economic Model'
}

export const HLSSApproved = () => {
  useFields({
    fields: ['dpHlAfterRepairValue'],
    required: [
      'departingPropertyPreliminaryTitleReviewed',
      'departingPropertyValuation',
      'dpDealValuationPercentage',
      'dpDealValuationPrice',
      'dpInspectionAdjustment',
      'dpTermSheetOffer',
      'dpHlCommitteeAdjustment',
      'departingPropertyGuaranteedPrice',
      'dpBuyerAgentFee',
      'departingPropertyExpectedListingPrice',
      'dpSellerAgentFee'
    ]
  })
  return (
    <>
      <Sections title="DR Property Qualification">
        <Section>
          <FieldFormField field="departingPropertyPreliminaryTitleReviewed" />
        </Section>
      </Sections>
      <Sections title="DR Property Valuation">
        <Section>
          <FieldFormField field="dpHlAfterRepairValue" />
        </Section>
        <Section>
          <FieldFormField field="departingPropertyValuation" />
        </Section>
      </Sections>
      <Sections title="DR Purchase Transaction">
        <Section>
          <FieldFormField field="dpDealValuationPrice" />
          <FieldFormField field="dpTermSheetOffer" {...econModel} />
          <FieldFormField field="dpHlCommitteeAdjustment" />
          <FieldFormField field="dpBuyerAgentFee" />
        </Section>
        <Section>
          <FieldFormField field="dpDealValuationPercentage" />
          <FieldFormField field="dpInspectionAdjustment" />
          <FieldFormField field="departingPropertyGuaranteedPrice" {...econModel} />
        </Section>
      </Sections>
      <Sections title="DR Sale Transaction">
        <Section>
          <FieldFormField field="departingPropertyExpectedListingPrice" />
        </Section>
        <Section>
          <FieldFormField field="dpSellerAgentFee" />
        </Section>
      </Sections>
    </>
  )
}
