import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  ActionButton,
  Block,
  Console,
  ConsoleAside,
  ConsoleDetails,
  LoadingText,
  Page
} from 'components'
import { AgentMessageButtonForm } from 'content/Agent'
import { ContactsList } from 'content/Contacts'
import { MessagesList } from 'content/Messages'
import { useInboxContext } from 'contexts'
import { useBodyClass, useDocumentTitle } from 'hooks'
import styles from './MessagesPage.module.scss'

export const MessagesPage = () => {
  useBodyClass('noscroll')
  const [contactId, setContactId] = useState(undefined)
  const [contact, setContact] = useState(undefined)
  const { setTitle } = useDocumentTitle()

  const { contacts, getContact, isLoading, markAllRead, markRead, unreadMessagesCount } =
    useInboxContext()

  useEffect(() => {
    setContact(getContact(contactId))
  }, [getContact, contactId])

  useEffect(() => {
    unreadMessagesCount ? setTitle(`Messages (${unreadMessagesCount})`) : setTitle('Messages')
  }, [setTitle, unreadMessagesCount])

  const ContactActions = contact ? (
    <Link to={`/agents/${contact.agent.id}`}>{contact.agent.fullName}</Link>
  ) : null

  const MarkAllButton = <ActionButton onClick={markAllRead}>Mark all as read</ActionButton>

  const MarkRead = (
    <ActionButton onClick={() => markRead(contact.agent.id)}>Mark as Read</ActionButton>
  )

  return (
    <Console className={styles.wrapper}>
      <ConsoleAside className={styles.nav}>
        <Block title="Contacts" actions={MarkAllButton} className={styles.title} />
        <ContactsList
          contacts={contacts}
          onClick={setContactId}
          currentContactId={contact && contact.agent.id}
        />
      </ConsoleAside>
      <ConsoleDetails className={styles.details}>
        {contact ? (
          <Page title={contact.agent.fullName} actions={ContactActions} subtitle={MarkRead} nopad>
            <AgentMessageButtonForm agent={contact.agent} />
            {isLoading ? (
              <LoadingText />
            ) : (
              <MessagesList messages={contact.messages} newMessagesAt={contact.lastReadAt} />
            )}
          </Page>
        ) : null}
      </ConsoleDetails>
    </Console>
  )
}
