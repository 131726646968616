import { forwardRef } from 'react'
import { CreateNumberMask } from 'text-mask-addons/dist/createNumberMask'
import { formatEmail, getPercentageValue, getPercentageValueFormat } from 'utils'
import { TextInput, TextInputProps } from './TextInput'
import {
  dollarsAndCentsMask,
  dollarsMask,
  floatMask,
  floatUnder100Mask,
  inventoryMask,
  moneyMask,
  percentMask,
  phoneMask
} from './masks'

export const onlyDigits = (val: string): string => {
  return val ? parseInt(val.replace(/\D/g, ''), 10).toString() : ''
}

export const removeSpecialCharacters = (val: string): string => {
  return val ? formatEmail(val) : ''
}

export const onlyIntegers = (val: string): string => {
  const exceptNumbersAndDash = /[^0-9-]/g
  return val ? parseInt(val.replace(exceptNumbersAndDash, ''), 10).toString() : ''
}

export const onlyFloatCharacters = (val: string): string => {
  // if it ends with a "." don't replace it
  const dot = val.match(/\.$/) ? '.' : ''
  return val ? parseFloat(val.replace(/[%$x,]/g, '')).toString() + dot : dot
}

const handleOnChange = (onChange, convertFunction) => val => {
  if (onChange) {
    onChange(convertFunction(val))
  }
}

export type DollarsInputProps = Pick<CreateNumberMask, 'allowNegative'>
export const DollarsInput = forwardRef<HTMLInputElement, TextInputProps & DollarsInputProps>(
  ({ onChange, allowNegative = false, value, ...props }, ref) => {
    return (
      <TextInput
        inputMask={dollarsMask({ allowNegative })}
        onChange={handleOnChange(onChange, onlyIntegers)}
        value={parseInt(value).toString()}
        {...props}
        ref={ref}
      />
    )
  }
)

export const InventoryInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => (
  <TextInput inputMask={inventoryMask} {...props} ref={ref} />
))

export type MoneyInputProps = Pick<CreateNumberMask, 'allowNegative'>
export const MoneyInput = forwardRef<HTMLInputElement, TextInputProps & MoneyInputProps>(
  ({ value, onChange, placeholder, allowNegative = false, ...props }, ref) => {
    return (
      <TextInput
        ref={ref}
        {...props}
        onChange={handleOnChange(onChange, onlyFloatCharacters)}
        value={value}
        placeholder={placeholder}
        inputMask={moneyMask({ allowNegative })}
      />
    )
  }
)

export type DollarsAndCentsInputProps = Pick<CreateNumberMask, 'allowNegative'>
export const DollarsAndCentsInput = forwardRef<
  HTMLInputElement,
  TextInputProps & DollarsAndCentsInputProps
>(({ value, onChange, placeholder, allowNegative = false, ...props }, ref) => {
  return (
    <TextInput
      ref={ref}
      {...props}
      onChange={handleOnChange(onChange, onlyFloatCharacters)}
      value={String(value || ' ')}
      placeholder={placeholder}
      inputMask={dollarsAndCentsMask({ allowNegative })}
    />
  )
})

export const PhoneInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ onChange, ...props }, ref) => (
    <TextInput
      ref={ref}
      inputMask={phoneMask}
      {...props}
      onChange={handleOnChange(onChange, onlyDigits)}
      type="tel"
    />
  )
)

export const EmailTextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ onChange, ...props }, ref) => (
    <TextInput
      ref={ref}
      {...props}
      onChange={handleOnChange(onChange, removeSpecialCharacters)}
      type="email"
    />
  )
)

export const PercentInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ onChange, ...props }, ref) => {
    return (
      <TextInput
        inputMask={percentMask}
        {...props}
        onChange={handleOnChange(onChange, onlyFloatCharacters)}
        ref={ref}
      />
    )
  }
)

export const PercentInputV2 = forwardRef<HTMLInputElement, TextInputProps>(
  ({ onChange, value, ...props }, ref) => {
    const inputValue = getPercentageValueFormat(value).toFixed(2)
    return (
      <TextInput
        inputMask={percentMask}
        value={inputValue}
        {...props}
        onChange={handleOnChange(onChange, getPercentageValue)}
        ref={ref}
      />
    )
  }
)

export const FloatInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ onChange, ...props }, ref) => (
    <TextInput
      inputMask={floatMask}
      {...props}
      onChange={handleOnChange(onChange, onlyFloatCharacters)}
      ref={ref}
    />
  )
)

export const FloatUnder100Input = forwardRef<HTMLInputElement, TextInputProps>(
  ({ onChange, ...props }, ref) => (
    <TextInput
      inputMask={floatUnder100Mask}
      {...props}
      onChange={handleOnChange(onChange, onlyFloatCharacters)}
      ref={ref}
    />
  )
)

export * from './IntegerInput'
