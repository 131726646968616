import { useState } from 'react'
import { AsideActionBar, AsideActionButton, AsideActionIcon } from 'components'
import { AddAgentActivityNoteButtonForm, AgentMessageButtonForm } from 'content/Agent'
import { useCurrentUserContext } from 'contexts/CurrentUser'
import { useDialerContext } from 'contexts/Dialer'
import { useCheckoutSessionsController } from 'controllers'
import { environment } from 'environment'
import { Agent } from 'models'

type Props = { agent: Agent; onOpenChangeModal?: (isOpen: boolean) => void }

export const AgentActionBar = ({ agent, onOpenChangeModal }: Props) => {
  const { call, isOnCall } = useDialerContext()
  const { currentUser } = useCurrentUserContext()
  const { sendCheckoutSessionMagicLink } = useCheckoutSessionsController()
  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false)

  const phoneOffice = agent.getPhoneOffice()
  const phoneMobile = agent.getPhoneMobile()
  const isOfficeButtonDisabled = !phoneOffice || isOnCall
  const isCellButtonDisabled = !phoneMobile || isOnCall

  const handleClickCallAgentOffice = () => {
    if (!currentUser || !phoneOffice?.number) {
      return
    }

    call(agent.getDialer(currentUser, phoneOffice?.number, 'office'))
  }

  const handleClickCallAgentMobile = () => {
    if (!currentUser || !phoneMobile?.number) {
      return
    }

    call(agent.getDialer(currentUser, phoneMobile?.number, 'mobile'))
  }

  const handleMailTo = () => {
    window.open(`mailto:${[agent.email1, agent.email2].filter(Boolean).join('?cc=')}`)
  }

  const handleCheckoutSession = () => {
    if (!isMagicLinkSent && sendCheckoutSessionMagicLink.isSuccess) {
      setIsMagicLinkSent(true)
    }

    if (!isMagicLinkSent) {
      const subscriberId = agent.id
      const subscriberType = 'Agent'
      const priceId = environment.stripeProsPriceId
      const redirectUrl = `${environment.apiMonolith}/ultra-low-rates/create-checkout-session?subscriber_type=${subscriberType}&subscriber_id=${subscriberId}&price_id=${priceId}`

      sendCheckoutSessionMagicLink.mutate({ subscriberId, subscriberType, priceId, redirectUrl })
    }
  }

  return (
    <AsideActionBar>
      {currentUser?.phoneTwilio && (
        <>
          <AsideActionButton
            title="Make a call"
            onClick={handleClickCallAgentOffice}
            disabled={isOfficeButtonDisabled}
          >
            <AsideActionIcon icon="Call" />
            Office
          </AsideActionButton>
          <AsideActionButton
            title="Make a call"
            onClick={handleClickCallAgentMobile}
            disabled={isCellButtonDisabled}
          >
            <AsideActionIcon icon="Cellphone" />
            Cell
          </AsideActionButton>
          <AgentMessageButtonForm
            agent={agent}
            onOpenChangeModal={onOpenChangeModal}
            buttonText={
              <span>
                <AsideActionIcon icon="Message" />
                Text
              </span>
            }
            buttonComponent={AsideActionButton}
          />
        </>
      )}
      <AsideActionButton title="Send email" onClick={handleMailTo}>
        <AsideActionIcon icon="Envelope" />
        Email
      </AsideActionButton>
      <AddAgentActivityNoteButtonForm
        agent={agent}
        buttonComponent={AsideActionButton}
        onOpenChangeModal={onOpenChangeModal}
        buttonText={
          <span>
            <AsideActionIcon icon="Note" />
            Note
          </span>
        }
      />
      {currentUser?.roles?.subscriptionsManager && (
        <AsideActionButton
          title="Send payment link"
          onClick={handleCheckoutSession}
          isLoading={sendCheckoutSessionMagicLink.isLoading}
          disabled={isMagicLinkSent}
        >
          <AsideActionIcon icon="Envelope" />
          Send payment link
        </AsideActionButton>
      )}
    </AsideActionBar>
  )
}
