import { Section, Sections } from 'components'
import { useFeatureFlags } from 'hooks'
import { FieldFormField } from '../FieldFormField'
import { useFields } from '../useFields'

export const CashOfferClosedPaid = () => {
  const { salesAppRevenueRecognition } = useFeatureFlags(['sales-app-revenue-recognition'])
  useFields({
    required: [
      'cashOfferFeeRate',
      // this field must be removed after removing the feature flag
      !salesAppRevenueRecognition.enabled ? 'revenueRecognitionDate' : undefined
    ]
  })
  return (
    <>
      <Sections title="IR Purchase Transaction">
        <Section>
          <FieldFormField field="cashOfferFeeRate" />
        </Section>
      </Sections>
      {!salesAppRevenueRecognition.enabled && (
        // this field must be removed after removing the feature flag
        <Section title="Financing">
          <Section>
            <FieldFormField field="revenueRecognitionDate" />
          </Section>
        </Section>
      )}
    </>
  )
}
