// @ts-strict

import { ReactNode } from 'react'
import { useCurrentUserController } from 'controllers'
import { createCtx } from '../utils'

export const [useCurrentUserContext, CurrentUserContextProvider] =
  createCtx<ReturnType<typeof useCurrentUserController>>()

type Props = {
  children: ReactNode
}

export const CurrentUserProvider = ({ children }: Props) => {
  return (
    <CurrentUserContextProvider value={useCurrentUserController()}>
      {children}
    </CurrentUserContextProvider>
  )
}
