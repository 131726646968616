import { Point } from 'classes'

export class Marker {
  name?: string
  description?: string
  type?: 'lead' | 'transaction' | 'referral' | 'listing'
  point: Point
  link?: string
  color?: string

  constructor(property) {
    Object.assign(this, property)
  }
}
