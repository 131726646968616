import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Divider.module.scss'

type TProps = {
  as?: 'horizontal' | 'vertical'
  children?: ReactNode
  className?: string
}

export const Divider = ({ as = 'horizontal', className, children }: TProps) => {
  const dividerStyle = as === 'vertical' ? styles.verticalDivider : styles.divider

  return (
    <div className={cx(dividerStyle, className)}>
      {children ? <div className={styles.content}>{children}</div> : null}
    </div>
  )
}
