import { MapBox } from 'components'
import { AgentLead } from 'models'
import styles from './AgentLeadsMap.module.scss'

export const AgentLeadsMap = ({
  agentLeads,
  isLoading
}: {
  agentLeads: AgentLead[]
  isLoading: boolean
}) => {
  if (isLoading) {
    return null
  }
  const markers = agentLeads.map(l => l.toMarker()).filter(Boolean)

  return <MapBox markers={markers} className={styles.leadsMap} />
}
