type RequestType = () => any

export const requestTryCatchWrapper = async (
  request: RequestType,
  fallbackError: string = 'Something went wrong'
) => {
  try {
    return await request()
  } catch (error: any) {
    if (error?.response?.data?.errors.length) {
      throw Error(`Something went wrong: ${error.response.data.errors.join(', ')}`)
    }
    throw Error(fallbackError)
  }
}
