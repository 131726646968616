import { SERVICE_OFFICES_CACHE_KEY, useQuery } from 'hooks'
import { fetchServiceOffices } from 'services'

export const useServiceOfficesController = () => {
  const serviceOfficesQuery = useQuery([SERVICE_OFFICES_CACHE_KEY, {}], () =>
    fetchServiceOffices({ serviceOffice: ['id', 'name', 'branchName'] })
  )

  return { serviceOfficesQuery }
}
