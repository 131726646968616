import { BUYERS_AND_SELLERS_CACHE_KEY, useQuery } from 'hooks'
import { fetchBuyerAndSellerLeads } from 'services'

const defaultSearchSettings: any = {
  sortBy: 'Created',
  sortDir: 'desc',
  perPage: 25
}

type TArgs = {
  searchParams?: any
}

export const useBuyersAndSellersLeadsController = ({ searchParams = {} }: TArgs = {}) => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  const buyerAndSellerLeadsQuery = useQuery([BUYERS_AND_SELLERS_CACHE_KEY, searchSettings], () =>
    fetchBuyerAndSellerLeads(searchSettings)
  )

  return { buyerAndSellerLeadsQuery }
}
