import { pluralize } from 'utils/index'
import { TValidation } from './validate'

type TValidateLengthProps = {
  max?: number
  min?: number
}

export const validateLength =
  ({ min, max }: TValidateLengthProps): TValidation =>
  ({ label, value }) => {
    const errors = []

    if (max && value && value.length > max) {
      errors.push(`${label} is too long. ${pluralize(max, 'characters')} maximum`)
    } else if (min && value && value.length < min) {
      errors.push(`${label} is too short. ${pluralize(min, 'characters')} minimum`)
    }

    return errors
  }
