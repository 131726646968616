import styled from 'styled-components'
import { css } from 'styled-components/macro'

export const Container = styled.div`
  ${({ theme }) => css`
    border: ${theme.borderWidths.thin} solid ${theme.colors.neutral6};
    padding: ${theme.space.$3};
    width: fit-content;
    border-radius: ${theme.radii.base};
  `}
`
