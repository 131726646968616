import {
  ButtonForm,
  CreateButton,
  FormField,
  TButtonFormContentsProps,
  TextArea,
  useButtonForm
} from 'components'
import { useInboxContext } from 'contexts'
import { Agent } from 'models'
import { validateLength, validatePresence } from 'utils'

type TProps = {
  agent: Agent
  buttonComponent?: TButtonFormContentsProps['buttonComponent']
  buttonText?: TButtonFormContentsProps['buttonText']
  onOpenChangeModal?: (isOpen: boolean) => void
}

export const AgentMessageButtonForm = ({
  agent,
  buttonComponent = CreateButton,
  buttonText,
  onOpenChangeModal
}: TProps) => {
  const { sendText } = useInboxContext()

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm({
    mutation: sendText,
    initialPayload: {
      body: '',
      type: 'Agent',
      id: agent.id
    },
    formValidations: {
      body: [validateLength({ max: 1600 }), validatePresence]
    },
    onOpenChange: onOpenChangeModal
  })
  return (
    <ButtonForm
      title="Send Text Message"
      buttonComponent={buttonComponent}
      buttonText={buttonText}
      {...formProps}
    >
      <FormField title={`To: ${agent.fullName}`} required>
        <TextArea
          value={payload.body}
          onChange={val => onChangeAttribute('body', val)}
          data-testid="new-message-body"
        />
      </FormField>
    </ButtonForm>
  )
}
