export const agentPartnershipsColumnsSettings = [
  { name: 'Name', value: 'name', isDefault: true, sort: ['Name'] },
  { name: 'Transaction Team', value: 'transactionTeam', isDefault: true },
  { name: 'Incoming URL', value: 'incomingUrl', isDefault: true },
  { name: 'Destination URL', value: 'destinationUrl', isDefault: true },
  { name: 'Enabled', value: 'enabled', isDefault: true, columnSize: 'xsm' },
  { name: '# Agents', value: 'numberOfAgents', isDefault: true, columnSize: 'sm' },
  { name: 'Created / Updated', value: 'createdUpdatedAt', isDefault: true, columnSize: 'md' },
  { name: 'Actions', value: 'actions', isDefault: true, columnSize: 'xsm' }
] as const

export type TAgentPartnershipsColumnValue =
  (typeof agentPartnershipsColumnsSettings)[number]['value']

export type TAgentPartnershipsColumn = typeof agentPartnershipsColumnsSettings
