import { useEffect, useState } from 'react'
import { ButtonForm, FormField, Icon, Select, TextArea } from 'components'
import { useCurrentUserContext } from 'contexts'
import { useAgentController } from 'controllers'
import type { Agent } from 'models'
import styles from './AgentReferralVisibilityButtonForm.module.scss'
import { useAgentReferralVisibilityButtonForm } from './useAgentReferralVisibilityButtonForm'
import {
  changeReasonOptions,
  ChangeReasonsData,
  notifyAgentOptions,
  visibilityOptionFromSlug,
  visibilityOptions
} from './utils'

type Props = {
  agent: Agent
}

export const AgentReferralVisibilityButtonForm = ({ agent }: Props) => {
  const { updateAgentVisibility } = useAgentController(agent.id, false)
  const { isAgentAdmin } = useCurrentUserContext()

  const {
    fieldsProps: { onChangeAttribute, payload },
    formProps
  } = useAgentReferralVisibilityButtonForm(updateAgentVisibility, agent)

  const [displayEmailConfirmationField, setDisplayEmailConfirmationField] = useState(false)
  const [currentVisibilityOption, setCurrentVisibilityOption] = useState(
    visibilityOptionFromSlug(payload.agentVisibility)
  )

  useEffect(() => {
    if (payload.reason === ChangeReasonsData.declinedPpl.slug) {
      setDisplayEmailConfirmationField(true)
    } else {
      setDisplayEmailConfirmationField(false)
    }
  }, [payload.reason])

  useEffect(() => {
    setCurrentVisibilityOption(visibilityOptionFromSlug(payload.agentVisibility))
  }, [payload.agentVisibility])

  if (!isAgentAdmin) {
    return null
  }

  return (
    <ButtonForm buttonText="Edit" title="Edit Referral Visibility" {...formProps}>
      <FormField title="Select the reason" required>
        <Select
          data-testid="reason-input"
          value={payload.reason}
          onChange={val => onChangeAttribute('reason', val)}
          options={changeReasonOptions}
        />
      </FormField>
      <FormField title="Referral allocation and profile visibility" required>
        <Select
          data-testid="agent-visibility-input"
          value={payload.agentVisibility}
          onChange={val => onChangeAttribute('agentVisibility', val)}
          options={visibilityOptions}
        />
      </FormField>
      {currentVisibilityOption?.disclaimer && (
        <div className={styles.disclaimer}>
          <Icon.InfoIcon />
          {currentVisibilityOption.disclaimer}
        </div>
      )}
      <FormField title="Notes" required>
        <TextArea
          onChange={val => onChangeAttribute('notes', val)}
          name="notes"
          autoComplete="off"
          value={payload.notes}
          data-testid="notes-field"
        />
      </FormField>
      {displayEmailConfirmationField && (
        <FormField title="Send email confirmation to agent" required>
          <Select
            data-testid="email-confirmation"
            value={payload.notifyAgent}
            onChange={val => onChangeAttribute('notifyAgent', val)}
            options={notifyAgentOptions}
          />
        </FormField>
      )}
    </ButtonForm>
  )
}
