// @ts-strict
import { Text } from '@foundation/components'

type Props = {
  isLoading?: boolean
}

export const EmptyPlaceholderInfiniteScrolling = ({ isLoading }: Props) => {
  if (isLoading) {
    return null
  }

  return (
    <Text size="sm" as="span">
      There are no data.
    </Text>
  )
}
