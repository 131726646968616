// @ts-strict
import React, { InputHTMLAttributes } from 'react'
import { cx } from 'utils'
import { Icon } from '../..'
import styles from './CheckBox.module.scss'

export const CheckBox = ({
  checked = false,
  className = '',
  disabled = false,
  id,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) => (
  <div
    className={cx(
      styles.checkbox,
      checked && styles.checked,
      disabled && styles.disabled,
      className
    )}
    data-testid="checkbox"
  >
    <input
      type="checkbox"
      className={styles.input}
      data-testid="checkbox_input"
      checked={checked}
      id={id}
      disabled={disabled}
      {...props}
    />
    <label className={styles.box} htmlFor={id}>
      {checked && <Icon.Check />}
    </label>
  </div>
)
