// @ts-strict
import { ReactNode } from 'react'
import { useParamsContext } from 'contexts'
import { useOrdersController } from 'controllers'
import { useLocation } from 'hooks'
import { TLeadUserTypeServerSlug } from 'lookups'
import { TOrdersSearchArgs } from '../../services'
import { createCtx } from '../utils'

export const [useOrdersContext, OrdersContextProvider] =
  createCtx<ReturnType<typeof useOrdersController>>()

export type OrderType =
  | 'agent_matching'
  | 'cash_close'
  | 'closing_services'
  | 'home_loans'
  | 'simple_sale'
  | 'homes'

type Props = {
  aeId?: string
  children: ReactNode
  ids?: Array<string>
  rootPath?: string
  scope?: OrderType | OrderType[]
  userType?: TLeadUserTypeServerSlug | TLeadUserTypeServerSlug[]
}

export const OrdersProvider = ({
  children,
  rootPath = '/orders',
  scope,
  aeId,
  userType,
  ids
}: Props) => {
  const { pathname } = useLocation()
  const { searchParams } = useParamsContext()

  const urlParams: TOrdersSearchArgs = {
    userType,
    aeId,
    ids,
    ...searchParams
  }

  return (
    <OrdersContextProvider
      value={useOrdersController(urlParams, scope, !!pathname && rootPath.includes(pathname))}
    >
      {children}
    </OrdersContextProvider>
  )
}
