export const toggleArrayItem = <T extends unknown[]>(array: T, item: T[number]): T[number][] => {
  const index = array.findIndex(arrayItem => item === arrayItem)
  const newArray = [...array]
  if (index !== -1) {
    newArray.splice(index, 1)
  } else {
    newArray.push(item)
  }
  return newArray
}
