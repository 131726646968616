// @ts-strict
import { TableHeaderActions, TableHeaderRow, TableHeaderColumn as TH } from 'components'
import { useListContext } from 'contexts'

export const ExternalLinksTableHeader = () => {
  const { showColumn } = useListContext()

  return (
    <TableHeaderRow>
      {showColumn('name') && (
        <TH
          sort={[{ name: 'Name', slug: 'name' }]}
          colSpan={showColumn('incomingResidence') ? 2 : 4}
        />
      )}
      {showColumn('incomingResidence') && <TH colSpan={2} name="IR Address" />}
      {showColumn('createdBy') && <TH colSpan={1} name="Created By" />}
      {showColumn('actions') && <TableHeaderActions />}
    </TableHeaderRow>
  )
}
