import { Divider, RadioButton, Section, Sections } from 'components'
import { CardIcon, Link } from '../Icons'
import styles from './MonthlyCap.module.scss'
import { PaymentOptionsProps } from './types'

export const PaymentOptions = ({
  hide = false,
  paymentMethod,
  onSelectPaymentType
}: PaymentOptionsProps) => {
  if (hide) {
    return null
  }

  return (
    <>
      <Sections>
        <RadioButton
          className={styles.radio}
          checked={paymentMethod === 'card'}
          onChange={() => onSelectPaymentType('card')}
        />

        <CardIcon variant="card" />

        <span style={{ marginLeft: '5px' }}>Credit Card</span>

        <Section className={styles.cards}>
          <CardIcon variant="visa" />
          <CardIcon variant="mastercard" />
          <CardIcon variant="american_express" />
          <CardIcon variant="discover" />
        </Section>
      </Sections>

      <Divider className={styles.dividerPayment} />

      <Sections>
        <RadioButton
          className={styles.radio}
          checked={paymentMethod === 'link'}
          onChange={() => onSelectPaymentType('link')}
        />

        <Link />

        <span style={{ marginLeft: '5px' }}>Payment Link</span>
      </Sections>

      <Divider className={styles.dividerPayment} />
    </>
  )
}
