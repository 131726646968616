// @ts-strict
import { EmptyTableCell, TableRow as Tr } from 'components'
import {
  LeadAddressCell,
  LeadAgentCell,
  LeadClientAdvisorAssistantCell,
  LeadClientAdvisorCell,
  LeadCreatedUpdatedCell,
  LeadDaysSinceLastStageUpdateCell,
  LeadLastStageUpdateCell,
  LeadNamePartnerCell,
  LeadOtherLeadsCell,
  LeadPropertyTypeCell,
  LeadSourceCell,
  LeadStageFurthestCell
} from 'content/Leads'
import {
  MortgageLeadLoanOfficerTeamLeadCell,
  MortgageLeadTxTeamCell
} from 'content/MortgageLeads/MortgageLeadsTable/MortgageLeadsTableCells'
import { useListContext } from 'contexts'
import { MortgageLead } from 'models'
import { capitalize, cx } from 'utils'

const columnMapping = {
  referringAgent: LeadAgentCell,
  namePartner: LeadNamePartnerCell,
  address: LeadAddressCell,
  createdUpdated: LeadCreatedUpdatedCell,
  lastStageUpdate: LeadLastStageUpdateCell,
  otherLeads: LeadOtherLeadsCell,
  propertyType: LeadPropertyTypeCell,
  source: LeadSourceCell,
  stageFurthest: LeadStageFurthestCell,
  txTeam: MortgageLeadTxTeamCell,
  daysSinceLastStageUpdate: LeadDaysSinceLastStageUpdateCell,
  loanOfficerTeamLead: MortgageLeadLoanOfficerTeamLeadCell,
  clientAdvisor: LeadClientAdvisorCell,
  clientAdvisorAssistant: LeadClientAdvisorAssistantCell
}

type TProps = {
  lead: MortgageLead
}

export const MortgageLeadTableRow = ({ lead }: TProps) => {
  const { highlightRows, visibleColumns } = useListContext()
  const rowClassName = highlightRows?.includes(lead.id) && 'highlight'

  return (
    <Tr className={cx(rowClassName)} data-testid={`lead-id-${lead.id}`}>
      {visibleColumns.map(visibleColumn => {
        const Cell = columnMapping[visibleColumn]
        const testId = `lead${capitalize(visibleColumn)}Cell`

        return Cell ? (
          <Cell lead={lead} column={visibleColumn} key={visibleColumn} testId={testId} />
        ) : (
          <EmptyTableCell key={visibleColumn} />
        )
      })}
    </Tr>
  )
}
