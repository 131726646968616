/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from 'react'
import { Tooltip } from '@foundation/components'
import {
  DisplayReadOnlyValue,
  FormField,
  Icon,
  If,
  InlineEditMoneyInput,
  InlineEditSelect,
  Section,
  Sections
} from 'components'
import EligibilityHeader from 'content/AgentMatchingLead/AgentMatchingLeadBBYSScreenSection/EligibilityHeader'
import { useLeadContext } from 'contexts'
import { useEligibilityController } from 'hooks'
import useIsBbysEligible from 'hooks/useIsBbysEligible'
import { agentMatchingCallReviewCreditScoreOptions, fhaVaOptions, yesOrNoOptions } from 'lookups'
import { isSellerLead } from 'models'
import { dataOrDash, formatPrice, stringifyValue, titleize } from 'utils'

type TProps = {
  onChangeAttribute: (key: any, value: any) => void
  onFieldsUpdate?: ({ isBBYSEligible }: TOnFieldsUpdate) => void
}

type TOnFieldsUpdate = {
  isBBYSEligible: boolean
}

export default function LenderDispositionBBYSScreenSection({
  onChangeAttribute,
  onFieldsUpdate
}: TProps) {
  const { lead, updateLeadNoCacheClear, refetch: refreshLead } = useLeadContext()
  const [tempLead, setTempLead] = useState(lead)

  const { isBBYSEligible, isDrStateEligible } = useIsBbysEligible({
    lead: tempLead
  })

  const homeValue = Number(isSellerLead(lead) ? lead.price : lead.callReview?.homeValue)
  const remainingMortgageBalance = lead.callReview?.remainingMortgageBalance
  const fullAddress = isSellerLead(lead)
    ? lead.fullAddress
    : lead.callReview?.othersideInfo?.fullAddress
  const eligibilityCheck = lead.eligibilityDetails

  const [apiCalled, setApiCalled] = useState(false)
  const [tooltipText, setTooltipText] = useState<ReactElement>()

  const {
    data,
    refetch: fetchEligibility,
    isError
  } = useEligibilityController({
    leadId: lead.id,
    agentValuation: homeValue,
    clientMortgageBalance: remainingMortgageBalance,
    enabled: false
  })

  useEffect(() => {
    setTempLead(lead)
  }, [lead])

  useEffect(() => {
    onFieldsUpdate({ isBBYSEligible })
  }, [isBBYSEligible])

  useEffect(() => {
    if (eligibilityCheck?.humanReadableErrors()?.length !== 0) {
      setTooltipText(
        <ul>
          {eligibilityCheck?.humanReadableErrors()?.map((error: string, index: number) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )
    }
  }, [eligibilityCheck])

  useEffect(() => {
    if (data || isError) {
      refreshLead()
    }
  }, [data, isError, refreshLead])

  useEffect(() => {
    fetchEligibilityCheck()
  }, [])

  useEffect(() => {
    if (eligibilityCheck !== undefined) {
      setApiCalled(true)
    }
  }, [eligibilityCheck])

  const fetchEligibilityCheck = () => {
    fetchEligibility()
    setApiCalled(true)
  }

  const homeValueUpdate = (value: string) => {
    if (isSellerLead(lead)) {
      //@ts-ignore
      setTempLead({ ...lead, price: value })
      updateLeadField({ price: value })
    } else {
      updateCallReview('homeValue', value)
    }
  }

  const updateCallReview = (key: string, value: string) => {
    onChangeAttribute(key, value)
    //@ts-ignore
    setTempLead({ ...lead, callReview: { ...lead.callReview, [key]: value } })
    updateLeadField({ callReview: { [key]: value } })
  }

  const updateLeadField = (
    value: Record<string, string | number | Record<string, string | Record<string, string>>>
  ) => {
    updateLeadNoCacheClear.mutateAsync(value)
  }

  return (
    <Sections
      title="Buy Before You Sell Screen"
      actions={<EligibilityHeader lead={tempLead} hideButton={true} />}
      titleStyle={{ flex: 'none', marginRight: '10px' }}
    >
      <Section>
        <FormField title="DR Address" inline>
          <DisplayReadOnlyValue value={dataOrDash(fullAddress)} />
        </FormField>
        <FormField title="DR State Eligibility" inline>
          <DisplayReadOnlyValue
            value={dataOrDash(isDrStateEligible ? 'Eligible' : 'Not Eligible')}
          />
        </FormField>
        <FormField title="DR Home Value" inline>
          <InlineEditMoneyInput
            onBlur={homeValueUpdate}
            value={dataOrDash(homeValue)}
            isError={!homeValue}
            readOnly
          />
        </FormField>
        <FormField title="Remaining Mortgage Balance?" inline>
          <InlineEditMoneyInput
            onBlur={(value: string) => {
              updateCallReview('remainingMortgageBalance', value)
            }}
            value={dataOrDash(lead.callReview?.remainingMortgageBalance)}
            isError={isDrStateEligible && lead.callReview?.remainingMortgageBalance === undefined}
            readOnly
          />
        </FormField>
        <FormField title="Equity Unlock - Min" inline>
          <DisplayReadOnlyValue
            value={dataOrDash(lead.callReview?.minEquityUnlock)}
            format={formatPrice}
          />
        </FormField>
        <FormField title="Equity Unlock - Max" inline>
          <DisplayReadOnlyValue
            value={dataOrDash(lead.callReview?.maxEquityUnlock)}
            format={formatPrice}
          />
        </FormField>
        <FormField title="DR Property Eligibility" inline>
          <DisplayReadOnlyValue value={titleize(eligibilityCheck?.eligibilityStatus)} />
          <If test={eligibilityCheck?.eligibilityStatus && tooltipText}>
            <Tooltip content={tooltipText} placement="right">
              <span>
                <Icon.QuestionCircle width={18} height={18} />
              </span>
            </Tooltip>
          </If>
        </FormField>
        <FormField title="Primary Residence" inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('primaryResidence', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.primaryResidence)}
            isError={apiCalled && lead.callReview?.primaryResidence === undefined}
            readOnly
          />
        </FormField>
        <FormField title="Credit Score > 620" inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('creditScore', value)}
            options={agentMatchingCallReviewCreditScoreOptions}
            value={stringifyValue(lead.callReview?.creditScore)}
            isError={apiCalled && lead.callReview?.creditScore === undefined}
            readOnly
          />
        </FormField>
        <FormField title="DR Age-Restricted Community" inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('ageRestrictedCommunity', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.ageRestrictedCommunity)}
            isError={apiCalled && lead.callReview?.ageRestrictedCommunity === undefined}
            readOnly
          />
        </FormField>
        <FormField title="IR FHA/VA Loan" inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('fhaVaLoan', value || null)}
            options={fhaVaOptions}
            value={stringifyValue(lead.callReview?.fhaVaLoan)}
            isError={apiCalled && lead.callReview?.fhaVaLoan === undefined}
            readOnly
          />
        </FormField>
      </Section>
    </Sections>
  )
}
