import { getUsers2Url } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export type PutUserPayload = {
  email: string
  firstName: string
  lastName: string
}

export const putUser = (id: string, payload: PutUserPayload) =>
  putRequest(getUsers2Url({ id }), snakeCaseKeys(payload))
