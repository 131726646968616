// @ts-strict
import { useAssigneeTaskCountsController } from 'controllers'
import { useMutation, useQuery, useQueryClient } from 'hooks'
import { fetchEmployee, postEmployeeRole, putEmployee } from 'services'
import { EMPLOYEES_CACHE_KEY } from '../index'

export type TEmployeePayload = {
  email?: string
  firstName: string
  lastName: string
  phoneMobile?: string
  phoneOffice?: string
  region?: string
}

export const useEmployeeController = (employeeId: string) => {
  const queryCache = useQueryClient()

  const defaultCallback = {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryCache.invalidateQueries([EMPLOYEES_CACHE_KEY])
    }
  }

  const employeeQuery = useQuery(
    [EMPLOYEES_CACHE_KEY, employeeId],
    () => fetchEmployee(employeeId),
    {
      refetchOnWindowFocus: false
    }
  )

  const updateEmployee = useMutation(
    (payload: TEmployeePayload) => putEmployee(employeeId, payload),
    defaultCallback
  )

  const createEmployeeRole = useMutation(
    (roleName: string) => postEmployeeRole(employeeId, { name: roleName }),
    defaultCallback
  )

  return {
    createEmployeeRole,
    employeeQuery,
    updateEmployee,
    taskCount: useAssigneeTaskCountsController({ userId: employeeId })
  }
}
