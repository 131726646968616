// @ts-strict
import { AgentProviderLead, ExternalListing } from 'models'
import { LeadResponse } from 'schema'
import { Lead, TLeadChildConstructor } from '../Lead'
import { getActiveAgentProviderLeads, getAgentProviderLeads } from '../utils'

type TAgentMatchingLeadConstructor = TLeadChildConstructor<
  Omit<AgentMatchingLead, 'getActiveAgentProviderLeads' | 'mapAgentMatchingLeadResponse'>
>

export class AgentMatchingLead extends Lead {
  agentProviderLeads?: AgentProviderLead[]
  modelName? = 'AgentMatchingLead'
  externalListing?: ExternalListing
  needsListingReview?: boolean

  constructor(agentMatchingLeadAttributes: TAgentMatchingLeadConstructor) {
    super(agentMatchingLeadAttributes)
    Object.assign(this, agentMatchingLeadAttributes)
  }

  static create(agentMatchingLeadResponse: Potential<LeadResponse>): Potential<AgentMatchingLead> {
    if (!agentMatchingLeadResponse) {
      return
    }
    return new AgentMatchingLead(this.mapResponse(agentMatchingLeadResponse))
  }

  static mapAgentMatchingLeadResponse(agentMatchingLeadResponse: LeadResponse): any {
    const agentProviderLeads = getAgentProviderLeads(agentMatchingLeadResponse?.agentProviderLeads)
    const externalListing = ExternalListing.create(agentMatchingLeadResponse.houseCanaryStatusCache)
    const introSentAt =
      agentMatchingLeadResponse.agentProviderLeads &&
      agentMatchingLeadResponse.agentProviderLeads.length
        ? agentMatchingLeadResponse.agentProviderLeads
            .filter(Boolean)
            .map(apl => apl.introSentAt)
            .filter(Boolean)[0]
        : undefined
    const leadAttributes = Lead.mapResponse(agentMatchingLeadResponse)

    return {
      ...leadAttributes,
      agentProviderLeads,
      externalListing,
      introSentAt
    }
  }

  getActiveAgentProviderLeads() {
    return getActiveAgentProviderLeads(this.agentProviderLeads)
  }
}
