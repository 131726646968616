// @ts-strict
import { useState } from 'react'
import { useKey } from 'react-use'
import { PhotoUpload } from 'models'

export type UseCarouselProps = {
  current: number
  images: Array<PhotoUpload>
  onNextSlide: (index: number) => void
  onPrevSlide: (index: number) => void
}

export const useCarousel = ({ images, onNextSlide, onPrevSlide, current }: UseCarouselProps) => {
  const [isZoomed, setIsZoomed] = useState(false)
  const currentPhoto = images[current]
  const length = images.length

  const nextSlide = () => {
    const index = current === length - 1 ? 0 : current + 1
    onNextSlide(index)
    setIsZoomed(false)
  }

  const prevSlide = () => {
    const index = current === 0 ? length - 1 : current - 1
    onPrevSlide(index)
    setIsZoomed(false)
  }

  const toggleZoom = () => {
    setIsZoomed(!isZoomed)
  }

  useKey(event => event.key === 'ArrowLeft', prevSlide, { event: 'keydown' })
  useKey(event => event.key === 'ArrowRight', nextSlide, { event: 'keydown' })

  return { toggleZoom, nextSlide, prevSlide, currentPhoto, isZoomed }
}
