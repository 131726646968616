// @ts-strict

import { Agent, HLSimpleSaleLead, HLSimpleSaleProviderLead } from 'models'
import { LeadResponse } from 'schema'
import { Lead, TLeadChildConstructor } from '../Lead'
import { getLead, getLeadUsers } from '../utils'

type TCCHLSimpleSaleLeadConstructor = TLeadChildConstructor<CCHLSimpleSaleLead>
export class CCHLSimpleSaleLead extends Lead {
  agent?: Agent
  agentTeamsName?: string
  calculatedValue?: string
  siblingLeads?: Lead[]
  modelName? = 'CCHLSimpleSaleLead'
  providableName? = 'hlSimpleSaleLead'
  providerName? = 'hlSimpleSaleProviderLead'
  referringAgent?: Agent
  revenueRecognitionDate?: string
  hlSimpleSaleLead?: HLSimpleSaleLead
  hlSimpleSaleProviderLead?: HLSimpleSaleProviderLead
  constructor(ccHLSimpleSaleLeadAttributes: TCCHLSimpleSaleLeadConstructor) {
    super(ccHLSimpleSaleLeadAttributes)
    Object.assign(this, ccHLSimpleSaleLeadAttributes)
  }

  static create(
    ccHLSimpleSaleLeadResponse: Potential<LeadResponse>
  ): Potential<CCHLSimpleSaleLead> {
    if (!ccHLSimpleSaleLeadResponse) {
      return
    }

    const hlSimpleSaleProviderLead = HLSimpleSaleProviderLead.create(
      ccHLSimpleSaleLeadResponse.hlssProviderLead
    )
    const hlSimpleSaleLead = hlSimpleSaleProviderLead?.providable
    const referringAgent = Agent.create(ccHLSimpleSaleLeadResponse.referringOwner)
    const agent = hlSimpleSaleLead?.departingPropertySellerAgent
    const agentTeamsName = agent?.agentTeamName
    const calculatedValue = ccHLSimpleSaleLeadResponse.calculatedValue
    const leadUsers = getLeadUsers(ccHLSimpleSaleLeadResponse)
    const revenueRecognitionDate = ccHLSimpleSaleLeadResponse.revenueRecognitionDate
    const leadAttributes = Lead.mapResponse(ccHLSimpleSaleLeadResponse)
    const siblingLeads = (ccHLSimpleSaleLeadResponse.childLeads?.map(getLead) as Lead[]) || []

    return new CCHLSimpleSaleLead({
      ...leadAttributes,
      agent,
      agentTeamsName,
      calculatedValue,
      siblingLeads,
      leadUsers,
      referringAgent,
      revenueRecognitionDate,
      hlSimpleSaleLead,
      hlSimpleSaleProviderLead
    })
  }
}
