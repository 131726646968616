import { ButtonHTMLAttributes, FunctionComponent } from 'react'
import { UseMutationResult } from 'react-query'
import { ActionButton, Flipup } from 'components'
import { ButtonV2Props } from 'components/Button/ButtonV2'
import buttonStyles from 'components/Button/ButtonV2.module.scss'
import { cx } from 'utils'
import styles from './MutationButton.module.scss'
export interface IMutationButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonV2Props {
  button?: FunctionComponent<ButtonV2Props>
  isLoading?: boolean
  mutation?: UseMutationResult<any, any, any, unknown>
}

export const MutationButton = ({
  button: ButtonComponent = ActionButton,
  children,
  className,
  disabled,
  mutation,
  ...props
}: IMutationButtonProps) => {
  const { error, isError, isLoading } = mutation
  const errorClass = isError ? buttonStyles.error : ''

  const onClick = payload => {
    mutation.mutate(payload)
  }

  const Button = (
    <ButtonComponent
      className={cx(className, errorClass)}
      disabled={disabled || isLoading}
      isLoading={isLoading}
      onClick={onClick}
      {...props}
    >
      {children}
    </ButtonComponent>
  )

  return (
    <Flipup title={Button}>
      {isError && <div className={styles.errorMsg}>{error.message}</div>}
    </Flipup>
  )
}
