// @ts-strict
import { useEffect } from 'react'
import { Contents } from 'components'
import { EmployeeDetails } from 'content/Employee'
import { useEmployeeContext } from 'contexts'
import { useDocumentTitle } from 'hooks'

export const EmployeeDetailsTab = () => {
  const { employeeQuery, taskCount } = useEmployeeContext()
  const { data: employee } = employeeQuery
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${employee?.name} - Employee`), [employee, setTitle])

  return (
    <Contents>
      <EmployeeDetails employee={employee!} taskCount={taskCount} />
    </Contents>
  )
}
