export const toSnakeCase = (str: Potential<string>): string => {
  if (!str) {
    return ''
  }

  return str
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_')
}
