import { getLeads3Url } from 'services/getUrl'
import { postRequest } from 'utils'

export const postQualifyInvestorLeads = (leadId: string): Promise<any> => {
  return postRequest(
    getLeads3Url({
      id: leadId,
      action: 'qualify-investor-leads'
    })
  )
}
