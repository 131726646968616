// @ts-strict

import { ReactNode } from 'react'
import { useCurrentUserContext } from 'contexts'
import { createCtx } from '../utils'
import { useInbox } from './useInbox'

export const [useInboxContext, InboxContextProvider] = createCtx<ReturnType<typeof useInbox>>()

type Props = {
  children: ReactNode
}

export const InboxProvider = ({ children }: Props) => {
  const { currentUser } = useCurrentUserContext()
  return <InboxContextProvider value={useInbox(currentUser)}>{children}</InboxContextProvider>
}
