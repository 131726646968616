// @ts-strict
import { ReactNode } from 'react'
import { createCtx } from 'contexts/utils'
import { Lead } from 'models'
import { useEnvelopes } from './useEnvelopes'

type TProps = {
  children: ReactNode
  lead: Lead
}

export const [useEnvelopesContext, EnvelopesContextProvider] =
  createCtx<ReturnType<typeof useEnvelopes>>()

export const EnvelopesProvider = ({ children, lead }: TProps) => {
  return <EnvelopesContextProvider value={useEnvelopes(lead)}>{children}</EnvelopesContextProvider>
}
