import { TableHeaderCell as TH, TableHeaderRow as TR } from 'components'

export const ListingsTableHeader = () => {
  return (
    <TR>
      <TH>Address</TH>
      <TH>Price</TH>
      <TH>DOM</TH>
      <TH>Status</TH>
      <TH>List Date</TH>
      <TH>Last Update</TH>
    </TR>
  )
}
