import { removeAttributesWithNullValues } from 'utils'

export type TLoanOfficersAutoCompleteParams = Partial<{
  include?: string
  pageSize?: number
  searchTerm: string
}>

export const getSearchParams = ({
  searchTerm,
  include,
  pageSize
}: TLoanOfficersAutoCompleteParams) => {
  return removeAttributesWithNullValues({
    search: searchTerm,
    'page[size]': pageSize || 15,
    'page[number]': 1,
    include
  })
}
