// @ts-strict
import { TUsStateCode } from 'lookups'
import { UsState } from 'models'
import { MetroplexResponse } from 'schema'
import { Model } from '../Model'

export class Metroplex extends Model {
  id!: string
  modelName = 'Metroplex'
  name!: string
  slug!: string
  stateCode?: TUsStateCode
  state?: UsState

  constructor(metroplexAttributes: Omit<Metroplex, keyof Model>) {
    super()
    Object.assign(this, metroplexAttributes)
  }

  static create(metroplexResponse: Potential<MetroplexResponse>): Potential<Metroplex> {
    if (!metroplexResponse) {
      return
    }

    const stateCode = metroplexResponse.slug.slice(-2).toUpperCase() as TUsStateCode
    const state = UsState.create(metroplexResponse.state)

    return new Metroplex({
      ...metroplexResponse,
      state,
      stateCode
    })
  }
}
