import {
  ExternalLink,
  FormField,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  InlineEditDollarsInput,
  InlineEditPercentageInput,
  InlineEditPhoneInput,
  InlineEditSelect,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { useFeatureFlags } from 'hooks'
import { yesOrNoOptions } from 'lookups'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import styles from './GlobalStyles.module.scss'
import { getBBYSLeadInputProps } from './utils'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>

export const DRPropertyListingOrSaleInformationSection = ({
  lead,
  updateBBYSLead,
  updateLead
}: Props) => {
  const bbysLead = lead.bbysProviderLead.bbysLead
  const { getProps } = getBBYSLeadInputProps(bbysLead, updateBBYSLead)
  const { value: link } = getProps('realEstatePortalLink')
  const { fieldDataAutomation } = useFeatureFlags(['field-data-automation'])

  return (
    <Sections title="Listing/sale information (Departing Residence)">
      <Section>
        <FormField title="Title company" inline>
          <InlineEditTextInput {...getProps('departingResidenceTitleCompany')} />
        </FormField>
        <FormField title="Title company email address" inline>
          <InlineEditTextInput {...getProps('departingResidenceTitleCompanyEmail')} />
        </FormField>
        <FormField title="Title company phone number" inline>
          <InlineEditPhoneInput {...getProps('departingResidenceTitleCompanyPhone')} />
        </FormField>
        <FormField title="Listing plan call invite date" inline>
          <InlineEditDateInput {...getProps('listingPlanCallInviteDate')} />
        </FormField>
        <FormField title="Listing call follow up date" inline>
          <InlineEditDateInput {...getProps('listingCallFollowUpDate')} />
        </FormField>
        <FormField title="Original list price" inline>
          <InlineEditDollarsInput {...getProps('departingResidenceOriginalListPrice')} />
        </FormField>
        {fieldDataAutomation.enabled && (
          <>
            <FormField title="HLCS Attached" inline>
              <InlineEditSelect options={yesOrNoOptions} {...getProps('hlcsAttached')} />
            </FormField>
            <FormField title="Expected program fee" inline>
              <InlineEditPercentageInput {...getProps('expectedProgramFeeRate')} />
            </FormField>
            <FormField title="Actual program fee" inline>
              <InlineEditDollarsAndCentsInput {...getProps('programFee')} readOnly />
            </FormField>
            <FormField title="Program fee surcharge" inline>
              <InlineEditDollarsAndCentsInput {...getProps('programFeeSurcharge')} />
            </FormField>
            <FormField title="Program fee discount" inline>
              <InlineEditDollarsAndCentsInput {...getProps('programFeeDiscount')} />
            </FormField>
            <FormField title="Actual loan payoff" inline>
              <InlineEditDollarsAndCentsInput {...getProps('actualLoanAmount')} readOnly />
            </FormField>
          </>
        )}
      </Section>
      <Section>
        <FormField title="Current list price" inline>
          <InlineEditDollarsInput {...getProps('departingResidenceCurrentListPrice')} />
        </FormField>
        <FormField title="Final sale price" inline>
          <InlineEditDollarsInput {...getProps('departingResidenceFinalSalePrice')} />
        </FormField>
        <FormField title="Real estate portal link" inline>
          {link ? (
            <div className={styles.row}>
              <InlineEditTextInput {...getProps('realEstatePortalLink')} />
              <ExternalLink href={link}>Link</ExternalLink>
            </div>
          ) : (
            <InlineEditTextInput {...getProps('realEstatePortalLink')} />
          )}
        </FormField>
        {fieldDataAutomation.enabled && (
          <FormField title="Target purchase COE date" inline>
            <InlineEditDateInput {...getProps('departingPropertyTargetPurchaseCoeDate')} />
          </FormField>
        )}
        <FormField title="DR purchased by homelight date" inline>
          <InlineEditDateInput {...getProps('homelightDepartingResidencePurchaseDate')} />
        </FormField>
        <FormField title="Actual loan payoff - HL" inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpActualLoanPayoff')} />
        </FormField>
        <FormField title="Payoff requested date" inline>
          <InlineEditDateInput {...getProps('payoffRequestedDate')} />
        </FormField>
        <FormField title="BBYS extension date" inline>
          <InlineEditDateInput {...getProps('programExtensionDate')} />
        </FormField>
      </Section>
    </Sections>
  )
}
