import { TModelFieldSetting } from '../ModelField'

export type TAgentFieldAttribute =
  | 'email1'
  | 'fullName'
  | 'licenseNumber'
  | 'name'
  | 'phone'
  | 'team'
  | 'agentBrokerage'

export type TAgentFieldSetting = {
  attr: TAgentFieldAttribute
} & TModelFieldSetting

export const agentFieldsSettings: TAgentFieldSetting[] = [
  { attr: 'fullName', name: 'Agent Name' },
  { attr: 'email1', name: 'Email' },
  { attr: 'licenseNumber', name: 'License' },
  { attr: 'phone', name: 'Phone' },
  { attr: 'agentBrokerage', name: 'Brokerage' },
  { attr: 'team', name: 'Team' }
]
