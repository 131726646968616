import { getLeads3Url } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export const putAgentMatchingLeadDisposition = ({
  leadId,
  payload
}: {
  leadId: string
  payload: {}
}): Promise<any> => {
  return putRequest(
    getLeads3Url({ id: leadId, action: 'agent-ae-disposition' }),
    snakeCaseKeys(payload)
  )
}
