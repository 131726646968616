import { useLocation } from 'react-router-dom'
import { SearchStatus } from 'components/SearchStatus/index'
import { SearchSummary } from 'components/SearchSummary/index'
import { useParamsContext } from 'contexts/Params'
import { useFeatureFlags } from 'hooks'
import { EmployeeSearchParamsSettings } from 'services'
import { queryStringToObject } from 'utils'
import { useFetchListUsers } from '../hooks'

export const ListUsersListStatus = () => {
  const {
    params: filtersAndSorts,
    resetParams
  }: { params: EmployeeSearchParamsSettings; resetParams: () => void } = useParamsContext()
  const { salesAppImpersonatingAgentTeamCoord } = useFeatureFlags([
    'sales-app-impersonating-agent-team-coord'
  ])
  const { search } = useLocation()
  const searchParams = queryStringToObject(search)
  const { data } = useFetchListUsers({ searchParams })

  const canClear = Object.keys(filtersAndSorts).length

  const searchSummaryType = salesAppImpersonatingAgentTeamCoord.enabled ? 'User' : 'Employee'

  return (
    <SearchSummary
      status={
        <SearchStatus params={filtersAndSorts} count={data?.totalCount} type={searchSummaryType} />
      }
      onClear={canClear ? resetParams : undefined}
    />
  )
}
