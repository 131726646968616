import { Option } from 'classes'
import { Agent } from 'models'

type Field = { name: string; value: string }

const WORKS_WITH_FIELDS: Field[] = [
  { name: 'Sellers', value: 'worksWithSellers' },
  { name: 'Buyers', value: 'worksWithBuyers' }
]

const PROPERTY_TYPE_FIELDS: Field[] = [
  { name: 'Single Family Homes', value: 'acceptsSingleFamilyHomes' },
  { name: 'Condos', value: 'acceptsCondominiums' },
  { name: 'Townhomes', value: 'acceptsTownhomes' },
  { name: 'Tenancy In Common', value: 'acceptsTennancyInCommon' },
  { name: 'Commercial Properties', value: 'acceptsCommercialProperties' },
  { name: 'Land Deals', value: 'acceptsLandDeals' },
  { name: 'Manufactured Homes', value: 'acceptsManufacturedHomes' }
]

export const worksWithOptions = WORKS_WITH_FIELDS.map(field => new Option(field))

export const propertyTypeOptions = PROPERTY_TYPE_FIELDS.map(field => new Option(field))

export const worksWithValues = (agent: Agent): string[] => {
  return (
    WORKS_WITH_FIELDS.map(({ value }: Field) =>
      agent?.referralPreferences?.[value] ? value : null
    ).filter(Boolean) ?? []
  )
}

export const propertyTypeValues = (agent: Agent): string[] => {
  return (
    PROPERTY_TYPE_FIELDS.map(({ value }: Field) =>
      agent?.referralPreferences?.[value] ? value : null
    ).filter(Boolean) ?? []
  )
}

export const worksWithValuesToPayload = (values: string[]): Record<string, boolean> => {
  return WORKS_WITH_FIELDS.reduce((acc, { value }: Field) => {
    acc[value] = values.includes(value)
    return acc
  }, {})
}

export const propertyTypeValuesToPayload = (values: string[]): Record<string, boolean> => {
  return PROPERTY_TYPE_FIELDS.reduce((acc, { value }: Field) => {
    acc[value] = values.includes(value)
    return acc
  }, {})
}
