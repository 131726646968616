import { SearchFilter } from 'lookups'
import { INSPECTION_SORT_SETTINGS } from 'lookups/lookupColumns/listColumns/settings'
import { bySlug } from 'utils'

export const useInspectionSearchFilter = () => {
  const inspectionFilters = INSPECTION_SORT_SETTINGS.map(
    setting =>
      new SearchFilter({
        slug: setting.value.split('.')[0],
        name: setting.name
      })
  )

  const findInspectionFilterBySlug = (slug: string) => inspectionFilters.find(bySlug(slug))

  return { findFilterBySlug: findInspectionFilterBySlug }
}
