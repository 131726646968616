// @ts-strict
import { Table, TableResults } from 'components'
import styles from 'content/Leads/LeadsTable/LeadsTableCells/LeadsTableCells.module.scss'
import { AgentPartnership } from 'models'
import { cx } from 'utils'
import { AgentPartnershipTableHeaderCells } from './AgentPartnershipsTableHeaderCells'
import { AgentPartnershipsTableRow } from '.'

type AgentPartnershipsTableProps = {
  agentPartnerships?: AgentPartnership[]
  className?: string
  isLoading?: boolean
}

export const AgentPartnershipsTable = ({
  className,
  agentPartnerships,
  isLoading
}: AgentPartnershipsTableProps) => {
  return (
    <Table className={cx(styles.table, className)} alternatingColumns>
      <AgentPartnershipTableHeaderCells />
      <TableResults type="Partnerships" isLoading={isLoading}>
        {agentPartnerships?.map(partnership => (
          <AgentPartnershipsTableRow key={partnership.id} agentPartnership={partnership} />
        ))}
      </TableResults>
    </Table>
  )
}
