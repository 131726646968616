import { getAgentTeamMembershipsUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export const postAgentTeamMember = ({
  agentId,
  userId
}: {
  agentId: string
  userId: string
}): Promise<any> => {
  return postRequest(getAgentTeamMembershipsUrl(), {
    agent_id: agentId,
    user_id: userId
  })
}
