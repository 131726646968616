import { DEFAULT_STRING } from '../constants'

type email = string | null | undefined

export const formatEmail = (emailString: email, defaultValue: any = DEFAULT_STRING) => {
  if (!emailString) {
    return defaultValue
  }

  // Replace the matched characters with an empty string and trim
  emailString = emailString.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, '').trim()

  // Escaping any potentially harmful characters
  emailString = emailString.replace(/[&<>"'/]/g, function (char) {
    switch (char) {
      case '&':
        return '&amp;'
      case '<':
        return '&lt;'
      case '>':
        return '&gt;'
      case '"':
        return '&quot;'
      case "'":
        return '&#x27;'
      case '/':
        return '&#x2F;'
      default:
        return char
    }
  })

  return emailString
}
