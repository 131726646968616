import numeral from 'numeral'
import { DEFAULT_STRING } from '../constants'

export const formatPrice = (
  price?: string | number,
  style: string = 'long',
  defaultValue: string = DEFAULT_STRING
) => {
  if (String(Number(price)) !== String(price) || price === undefined) {
    return defaultValue
  }

  if (style === 'long') {
    return Number(price).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
  }

  if (style === 'short') {
    const fmt = price < 1000000 ? '$0a' : '$0.0a'
    return numeral(price).format(fmt).replace('.0', '') // '10.0m' => '10m'
  }

  console.warn('Style is not recognized')
  return defaultValue
}

export const formatMoney = (
  price: Potential<string | number>,
  defaultValue: string = DEFAULT_STRING
) => {
  if (!price || Number.isNaN(price)) {
    return defaultValue
  }

  return Number(price).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export const formatDollars = (
  amount: Potential<string | number>,
  defaultValue: string = DEFAULT_STRING
) => {
  if (!amount || Number.isNaN(amount)) {
    return defaultValue
  }

  return Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}

export const formatDollarsAndCents = (
  price: string | number,
  defaultValue: string = DEFAULT_STRING
) => {
  if (!price || Number.isNaN(price)) {
    return defaultValue
  }

  return Number(price).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}
