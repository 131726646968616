import { Badge } from 'components/Badge'
import { cx } from 'utils'
import styles from './Bubble.module.scss'

type TProps = {
  className?: string
  count?: number | string
  danger?: boolean
}

export const Bubble = ({ count = 0, className, danger = false }: TProps) => {
  return count ? (
    <Badge className={cx(className, danger ? styles.danger : styles.normal)} stroke={false}>
      {count}
    </Badge>
  ) : null
}
