import { useQuery } from 'hooks'
import { fetchLookups } from 'services'
import { byType, byValue } from 'utils'

export const useLookupsController = (
  type: string | string[] = []
): [TLookupType[], (v: string | number) => Potential<TLookupType>] => {
  const { data: allLookups = [] } = useQuery<TLookupType[]>(['lookups'], fetchLookups, {
    staleTime: Infinity
  })

  const lookups = allLookups.filter(byType(type))

  const finder = (value: string | number) => lookups.find(byValue(value))

  return [lookups, finder]
}
