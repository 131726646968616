import { Section, Sections } from 'components'
import { useFeatureFlags } from 'hooks'
import { FieldFormField } from '../../FieldFormField'
import { useFields } from '../../useHLSSLeadDisposition'

export const HLSSClosedPaid = () => {
  const { salesAppRevenueRecognition } = useFeatureFlags(['sales-app-revenue-recognition'])
  useFields({
    required: [
      // this field must be removed after removing the feature flag
      !salesAppRevenueRecognition.enabled ? 'revenueRecognitionDate' : undefined
    ]
  })
  return !salesAppRevenueRecognition.enabled ? (
    // this field must be removed after removing the feature flag
    <Sections>
      <Section title="DR Purchase Transaction">
        <FieldFormField field="revenueRecognitionDate" />
      </Section>
    </Sections>
  ) : null
}
