// @ts-strict

import { useState } from 'react'
import {
  CheckBoxArray,
  Column,
  Columns,
  EmployeesAutoComplete,
  ListBox,
  MenuSection,
  MoneyInput,
  Section,
  Select
} from 'components'
import { useListContext } from 'contexts'
import { useFeatureFlags, useNewFilters } from 'hooks'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import { ListFilters } from 'templates'
import styles from './BuyerAndSellerLeadsListFilter.module.scss'

export const BuyerAndSellerLeadsListFilter = () => {
  const { tracking } = useCustomEvents()
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const { newParams, set } = useNewFilters()
  const { findFilterBySlug } = useListContext()
  const userTypeFilter = findFilterBySlug('userType')
  const oppStatusFilter = findFilterBySlug('oppStatus')
  const productEligibilityFilter = findFilterBySlug('productEligibility')
  const stageFilter = findFilterBySlug('stage')
  const lastStageUpdateFilter = findFilterBySlug('lastStageUpdate')
  const listingStatusFilter = findFilterBySlug('listingStatus')
  const assigneeIdFilter = findFilterBySlug('assigneeId')
  const needsListingReviewFilter = findFilterBySlug('needsListingReview')
  const escrowAttachedFilter = findFilterBySlug('escrowAttached')
  const minPriceFilter = findFilterBySlug('minPrice')
  const maxPriceFilter = findFilterBySlug('maxPrice')
  const pendingActions = findFilterBySlug('pendingActions')
  const stateFilter = findFilterBySlug('stateName')
  const { salesAppBuyersSellersPendingActions } = useFeatureFlags([
    'sales-app-buyers-sellers-pending-actions'
  ])

  return (
    <ListFilters.Root isOpen={isOpenPopover} onOpenChange={setIsOpenPopover}>
      <ListFilters.Trigger>
        <ListFilters.TriggerButton iconRight="FilterAltOutlined">Filters</ListFilters.TriggerButton>
      </ListFilters.Trigger>
      <ListFilters.Content>
        <Section className={styles.section}>
          <Columns>
            <Column>
              <MenuSection title={userTypeFilter?.name}>
                <CheckBoxArray
                  options={userTypeFilter!.options!}
                  value={newParams.userType}
                  onChange={val => set('userType', val)}
                />
              </MenuSection>
              <MenuSection title={oppStatusFilter?.name}>
                <CheckBoxArray
                  options={oppStatusFilter!.options!}
                  value={newParams.oppStatus}
                  onChange={val => set(oppStatusFilter!.slug, val)}
                />
              </MenuSection>
              <MenuSection title={productEligibilityFilter?.name}>
                <CheckBoxArray
                  options={productEligibilityFilter!.options!}
                  value={newParams.productEligibility}
                  onChange={val => set(productEligibilityFilter!.slug, val)}
                />
              </MenuSection>
            </Column>
            <Column title={stageFilter?.name}>
              <CheckBoxArray
                options={stageFilter?.options!}
                value={newParams.stage}
                onChange={val => set(stageFilter?.slug!, val)}
              />
            </Column>
            <Column>
              <MenuSection title={lastStageUpdateFilter?.name}>
                <ListBox
                  options={lastStageUpdateFilter?.options!}
                  value={newParams.lastStageUpdate || ''}
                  onChange={val => set(lastStageUpdateFilter!.slug, val)}
                />
              </MenuSection>
            </Column>
            <Column>
              <MenuSection title={listingStatusFilter?.name}>
                <CheckBoxArray
                  options={listingStatusFilter?.options!}
                  value={newParams.listingStatus || ''}
                  onChange={val => set(listingStatusFilter!.slug, val)}
                />
              </MenuSection>
            </Column>
            {salesAppBuyersSellersPendingActions.enabled && (
              <Column>
                <MenuSection title={pendingActions?.name}>
                  <CheckBoxArray
                    options={pendingActions!.options!}
                    value={newParams.pendingActions}
                    onChange={val => set('pendingActions', val)}
                  />
                </MenuSection>
              </Column>
            )}
            <Column className="resetOverflow">
              <MenuSection title={assigneeIdFilter?.name}>
                <EmployeesAutoComplete
                  value={assigneeIdFilter?.getDisplayValue(newParams.assigneeId)}
                  onSelect={val => set(assigneeIdFilter!.slug, val?.id)}
                  onClear={() => set(assigneeIdFilter!.slug, '')}
                />
              </MenuSection>
              <MenuSection title={needsListingReviewFilter?.name}>
                <Select
                  addBlank
                  value={newParams.needsListingReview}
                  options={needsListingReviewFilter?.options}
                  onChange={val => set(needsListingReviewFilter!.slug, val)}
                />
              </MenuSection>
              <MenuSection title={escrowAttachedFilter?.name}>
                <Select
                  addBlank
                  value={newParams.escrowAttached}
                  options={escrowAttachedFilter?.options}
                  onChange={val => set(escrowAttachedFilter!.slug, val)}
                />
              </MenuSection>
              <MenuSection className={styles.priceRangeFields} title={minPriceFilter!.name}>
                <MoneyInput
                  value={newParams.minPrice}
                  onChange={val => set(minPriceFilter!.slug, val)}
                  placeholder={minPriceFilter?.placeholder}
                />
                <MoneyInput
                  value={newParams.maxPrice}
                  onChange={val => set(maxPriceFilter!.slug, val)}
                  placeholder={maxPriceFilter?.placeholder}
                />
              </MenuSection>
              <MenuSection title={stateFilter?.name}>
                <Select
                  addBlank
                  value={newParams.stateName}
                  options={stateFilter?.options}
                  onChange={val => set(stateFilter!.slug, val)}
                />
              </MenuSection>
            </Column>
          </Columns>
          <ListFilters.Footer>
            <ListFilters.ApplyButton
              newParams={newParams}
              onApplyToggle={setIsOpenPopover}
              onClick={() =>
                tracking({ name: 'filters', params: { action: 'apply', target: 'lead' } })
              }
            />
          </ListFilters.Footer>
        </Section>
      </ListFilters.Content>
    </ListFilters.Root>
  )
}
