import { SearchParams } from '@types'
import { presence, removeAttributesWithNullValues } from 'utils'

type DeafultSearchParams = Pick<
  SearchParams,
  'page' | 'perPage' | 'searchTerm' | 'sortBy' | 'sortDir'
>

type FilterParams = Partial<{
  role: string
  teamId: string
  userCategory: string
  userStatus: string
}> &
  Pick<SearchParams, 'searchTerm'>

export type FetchListUsersSearchParams = Partial<{
  role: string
  teamId: string
  userCategory: string
  userStatus: string
}> &
  DeafultSearchParams

type SortParams = Pick<SearchParams, 'sortBy' | 'sortDir'>

const sortParams = {
  Email: 'email',
  Id: 'id',
  Name: 'first_name'
}

const getFilters = ({ role, searchTerm, teamId, userCategory, userStatus }: FilterParams) => {
  const filters = {}

  if (searchTerm?.length) {
    filters['filter[_search]'] = searchTerm
  }

  if (role) {
    filters['filter[_role]'] = role
  }

  if (teamId) {
    filters['filter[_transaction_team_id]'] = teamId
  }

  if (userCategory === 'employees') {
    filters['filter[_employee]'] = true
  }

  if (userStatus) {
    filters['filter[_archived]'] = userStatus === 'archived' ? 'true' : 'false'
  }

  return removeAttributesWithNullValues(filters)
}

const getSort = ({ sortBy, sortDir }: SortParams) => {
  if (sortBy) {
    const dir = sortDir === 'desc' ? '-' : ''
    return `${dir}${sortParams[sortBy]}`
  }

  return sortParams['Name']
}

export const getSearchParams = ({
  page,
  perPage,
  sortBy,
  sortDir,
  ...filters
}: FetchListUsersSearchParams = {}) => {
  return {
    'page[number]': presence(page),
    'page[size]': presence(perPage),
    sort: getSort({ sortBy, sortDir }),
    ...getFilters(filters)
  }
}
