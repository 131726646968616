import { INVESTOR_LEADS_CACHE_KEY, LEADS_CACHE_KEY, useMutation, useQueryClient } from 'hooks'
import { postQualifyInvestorLeads } from 'services'

export const useQualifyInvestorLeadsController = (leadId: string) => {
  const queryCache = useQueryClient()

  const clearCaches = () => {
    queryCache.invalidateQueries(LEADS_CACHE_KEY)
    queryCache.invalidateQueries([INVESTOR_LEADS_CACHE_KEY, leadId])
  }

  const qualifyInvestorLeads = useMutation(() => postQualifyInvestorLeads(leadId), {
    onSuccess: clearCaches
  })

  return { qualifyInvestorLeads }
}
