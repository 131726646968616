// @ts-strict
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Block, Ellipsis, FormField, Section } from 'components'
import { EmployeeEditButtonForm } from 'content/Employee'
import { useLocation } from 'hooks'
import { Employee } from 'models'
import { d, formatPhone } from 'utils'
import styles from './EmployeeSummary.module.scss'

type Props = {
  children?: ReactNode
  employee: Employee
}

export const EmployeeSummary = ({ employee }: Props) => {
  const { pathname } = useLocation()
  const { name, pathname: employeePath } = employee.link

  return (
    <Section>
      <Block
        title={
          pathname !== employeePath ? (
            <Link to={employeePath} title={name}>
              {name}
            </Link>
          ) : (
            employee.name
          )
        }
        subtitle={employee.region}
        className={styles.title}
        actions={<EmployeeEditButtonForm employee={employee} />}
      />

      <Section>
        <FormField title="Email" inline>
          <Ellipsis>{employee.email}</Ellipsis>
        </FormField>

        {employee?.phoneNumbers?.map(({ id, number, phoneType }) => (
          <FormField title={`${d(phoneType)} Phone`} key={id} inline>
            {formatPhone(number)}
          </FormField>
        ))}
      </Section>
    </Section>
  )
}
