import { ModelField } from '../ModelField'
import { TAgentTeamFieldAttribute } from './agentTeamFieldsSettings'

export class AgentTeamField extends ModelField {
  attr!: TAgentTeamFieldAttribute

  constructor(attributes: AgentTeamField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
