import { DEFAULT_STRING } from '../constants'
import { titleize } from '../titleize'

const leadStage = {
  agent_left_vm: 'Agent Left VM/Email',
  agent_unresponsive: 'Agent Unresponsive',
  bad_number: 'Emailed - Bad Number',
  call_client_back: 'Call Client Back',
  called_busy: 'Called - Busy/No Answer',
  called_left_msg: 'Called - Left MessageListItem',
  called_no_msg: 'Called - No MessageListItem',
  claimed: 'New',
  client_connected: 'Client Connected',
  client_contact: 'Client Contact',
  client_left_vm: 'Client Left VM',
  client_unresponsive: 'Client Unresponsive',
  closed: 'Closed',
  closed_booked: 'Closed - Booked',
  closed_paid: 'Closed - Paid',
  coming_soon: 'Coming Soon',
  connected: 'Connected',
  curious_valuation: 'Valuation - Curious',
  duplicate: 'Duplicate',
  failed: 'Failed',
  fake: 'Fake Lead',
  in_escrow: 'In Escrow',
  incomplete: 'Incomplete',
  interested: 'Interested - Follow Up',
  intro_pending: 'Intro Pending',
  introduced: 'Introduced to Agent',
  listing: 'Listing',
  looking_for_specific_agent: 'Looking For Specific Agent',
  meeting_scheduled: 'Meeting Scheduled',
  met_in_person: 'Met in Person',
  new: 'New',
  not_interested: 'Not Interested',
  not_transacting: 'Not Transacting',
  not_transacting_agent: 'Not Transacting - Agent',
  not_transacting_agents_group: 'Not Transacting - Agents Group',
  not_transacting_closings_groups: 'Not Transacting - Closings Group',
  not_transacting_rental: 'Not Transacting - Rental',
  probate_already_sold: 'Probate - Already Sold',
  processing: 'Processing',
  quiz_match: 'Quiz Match',
  requested: 'Requested',
  refinance_valuation: 'Valuation - Refinance',
  sent_client_profiles: 'Sent Client Profiles - Follow Up',
  spam: 'Spam',
  tax_valuation: 'Valuation - Taxes',
  unqualified: 'Unqualified',
  vm_not_set_up: 'Called - VM Not Set Up'
}

const investorLeadStage = {
  quiz_match: 'Estimate Given',
  introduced: 'Introduced to Investor',
  agent_left_vm: 'Investor Left Voicemail',
  coming_soon: 'Offer Returned',
  closed_booked: 'Closed - Paid',
  investor_qualified: 'Qualified'
}

const escrowLeadStage = {
  new: 'Processing',
  reports_requested: 'Reports Requested',
  processing: 'Processing',
  in_pre_escrow: 'Order Open',
  failed: 'Order Failed',
  delivered: 'Reports Delivered'
}

// Used for displaying AgentLead stages to an agent
const agentsDisplay = {
  client_interviewing: 'Client is still interviewing agents',
  client_wants_to_wait: 'Client is waiting',
  follow_up_call: 'I have a followup call',
  follow_up_scheduled: 'I have a followup',
  followup_scheduled: 'We have a followup scheduled',
  introduced: 'New',
  left_voicemail: 'I left a voicemail',
  list_in_future: "We'll be listing in the future",
  listing_expired: 'The listing has expired',
  make_offer: 'The client wants to make an offer',
  needs_approval: 'Client needs to get pre approved',
  needs_preapproval: 'Client needs to get pre approved',
  not_contacted: 'I have not contacted the client',
  not_on_market: 'Not on the market',
  not_on_market_yet: "We're not on the market yet",
  not_working_with: "We won't be working together",
  on_hold: 'The client is waiting',
  on_hold_buy: 'The client is waiting to buy',
  other: 'Other',
  scheduled_meeting: 'We have a meeting scheduled',
  scheduled_presentation: "We've scheduled a presentation",
  sending_listings: "I'm sending listings",
  signed_agreement: 'We have a listing agreement',
  spoke_on_phone: 'We spoke on the phone',
  viewing_properties: "I've shown properties to the client"
}

const lenderLeadStage = {
  application_complete: 'Application Complete',
  application_started: 'Application Started',
  conditionally_approved: 'Conditionally Approved',
  did_not_qualify: 'Did Not Qualify',
  incomplete_application: 'Incomplete Application',
  offer_declined: 'Offer Declined',
  pre_approved: 'Pre-Approved',
  qualified: 'Qualified',
  underwritten: 'Underwritten'
}

export const formatStage = (value: string) => leadStage[value] || titleize(value) || DEFAULT_STRING

formatStage.investorLead = (value: string) =>
  investorLeadStage[value] || formatStage(value) || DEFAULT_STRING
formatStage.escrowLead = (value: string) =>
  escrowLeadStage[value] || formatStage(value) || DEFAULT_STRING
formatStage.agentDisplay = (value: string) =>
  agentsDisplay[value] || formatStage(value) || DEFAULT_STRING
formatStage.lenderLead = (value: string) =>
  lenderLeadStage[value] || formatStage(value) || DEFAULT_STRING
