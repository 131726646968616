import { createContext, ReactNode, useContext } from 'react'
import { useAgentPayPerLeadSettingsController } from 'controllers'

const AgentPayPerLeadSettingsContext =
  createContext<ReturnType<typeof useAgentPayPerLeadSettingsController>>(null)

export const useAgentPayPerLeadSettingsContext = () => {
  const context = useContext(AgentPayPerLeadSettingsContext)
  if (!context) {
    throw new Error(
      'useAgentPayPerLeadSettingsContext must be called within an AgentPayPerLeadSettingsProvider'
    )
  }
  return context
}

type Props = {
  agentId?: string
  children: ReactNode
}

export const AgentPayPerLeadSettingsProvider = ({ children, agentId }: Props) => {
  return (
    <AgentPayPerLeadSettingsContext.Provider value={useAgentPayPerLeadSettingsController(agentId)}>
      {children}
    </AgentPayPerLeadSettingsContext.Provider>
  )
}
