import { LeadGroupable } from 'models'
import { LeadGroupableType } from 'models'
import { getHapiRequestParams, LeadGroupableBaseSchema, LeadGroupableSchema } from 'schema'
import { getLeadGroupableUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const baseShape: LeadGroupableBaseSchema = [
  'active',
  'name',
  'priority',
  { definitions: ['definition'] }
]

const shapes = {
  personas: { persona: baseShape } as LeadGroupableSchema
}

export const fetchLeadGroupables = async (
  groupableType: LeadGroupableType
): Promise<Array<LeadGroupable>> => {
  const url = getLeadGroupableUrl({
    action: groupableType,
    searchParams: getHapiRequestParams(shapes[groupableType])
  })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, {
    transform: LeadGroupable.create
  })
}

export const fetchLeadGroupable = async (
  groupableType: LeadGroupableType,
  id: string
): Promise<LeadGroupable> => {
  const url = getLeadGroupableUrl({
    action: `${groupableType}/${id}`,
    searchParams: getHapiRequestParams(shapes[groupableType])
  })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, {
    transform: LeadGroupable.create
  })
}
