import { ModelField } from '../ModelField'
import { TMortgageLeadFieldAttribute } from './mortgageLeadFieldsSettings'

export class MortgageLeadField extends ModelField {
  attr!: TMortgageLeadFieldAttribute

  constructor(attributes: MortgageLeadField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
