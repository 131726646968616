import { Section, Sections } from 'components'
import { AgentSection } from 'content/Agent/AgentSection'
import { CCTradeInLeadDetailsProps } from '../CCTradeInLeadDetails'

type Props = Pick<CCTradeInLeadDetailsProps, 'lead' | 'updateTradeInLead'>

export const DRAgentInformationSection = ({ lead, updateTradeInLead }: Props) => {
  const tradeInLead = lead.tradeInProviderLead.tradeInLead
  const sellerAgent = tradeInLead.departingPropertySellerAgent
  const buyerAgent = tradeInLead.incomingPropertyBuyerAgent

  const leadType = lead.isHLSS ? 'hlss' : 'trade_in'

  return (
    <Sections title="Agent Information">
      <Section>
        <AgentSection
          title="DR Agent"
          agent={sellerAgent}
          program={leadType}
          onBlur={val =>
            updateTradeInLead.mutate({
              id: tradeInLead.id,
              payload: {
                departing_property_seller_agent_id: val
              }
            })
          }
        />
      </Section>
      <Section>
        <AgentSection
          title="IR Agent"
          agent={buyerAgent}
          program={leadType}
          onBlur={val =>
            updateTradeInLead.mutate({
              id: tradeInLead.id,
              payload: {
                incoming_property_buyer_agent_id: val
              }
            })
          }
        />
      </Section>
    </Sections>
  )
}
