// @ts-strict

import { INVESTOR_LEADS_CACHE_KEY, useMutation, useQueryClient } from 'hooks'
import { TLeadUserTypeServerSlug } from 'lookups'
import { putProviderLeadDisposition, TPutProviderLeadDisposition } from 'services'
import { LEADS_CACHE_KEY, ORDER_CACHE_KEY, ORDERS_CACHE_KEY } from '../index'

type TProps = {
  id?: string
  leadId?: string
  leadType: TLeadUserTypeServerSlug
}

export const useDispositionProviderLeadController = ({
  id = '',
  leadId = '',
  leadType
}: TProps) => {
  const queryCache = useQueryClient()

  const defaultCallback = {
    onSuccess: () => {
      queryCache.invalidateQueries([LEADS_CACHE_KEY, leadType, leadId])
      queryCache.invalidateQueries([INVESTOR_LEADS_CACHE_KEY, leadId])
      queryCache.invalidateQueries(ORDER_CACHE_KEY)
      queryCache.invalidateQueries(ORDERS_CACHE_KEY)
    }
  }

  const updateDispositionProviderLead = useMutation(
    (payload: TPutProviderLeadDisposition) => putProviderLeadDisposition({ leadId, id, payload }),
    defaultCallback
  )

  return {
    updateDispositionProviderLead
  }
}
