// @ts-strict

import { useState } from 'react'
import { useListContext, useParamsContext } from 'contexts'
import { useUpdateEffect } from 'hooks'
import { Column } from 'lookups'
import { presence, sorts } from 'utils'

type PotentialArrayOfString = Potential<string[]>

const defaultColumnsGroup = 'Optional Display Columns'

const isArrayWithSameContent = (array1: PotentialArrayOfString, array2: PotentialArrayOfString) => {
  return (
    array1 &&
    array2 &&
    array1.length === array2.length &&
    array1.every((i: string) => array2.includes(i)) &&
    array2.every((i: string) => array1.includes(i))
  )
}

const isArrayWithSameContentAndOrder = (
  array1: PotentialArrayOfString,
  array2: PotentialArrayOfString
) => {
  if (!array1 || !array2) {
    return false
  }

  return (
    isArrayWithSameContent(array1, array2) &&
    array1.every((element, index) => element === array2[index]) &&
    array2.every((element, index) => element === array1[index])
  )
}

export const useViewsMenu = () => {
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const { columns } = useListContext()
  const { setViewColumns, viewColumns } = useParamsContext()

  // list that feeds the view of the checkbox array
  const optionalColumns = columns.filter(column => column.isOptional)

  const optionalColumnsFromLookup = optionalColumns
    .map(column => column.toOption())
    .sort(sorts.name)

  const toGroupParam = (group?: string) =>
    optionalColumns
      .filter(el => (!group ? !el.group : el.group === group))
      .sort((a, b) => (a.order || 0) - (b.order || 0))
      .map(el => el.toOption())

  const groupParams = optionalColumns
    .filter(column => !!column.group)
    .reduce((acc, val) => {
      if (!val?.group) {
        return acc
      }

      if (acc[val.group]) {
        return acc
      }

      return { ...acc, [val.group]: toGroupParam(val.group) }
    }, {})

  const defaultColumns = toGroupParam()

  const optionalColumnsFromLookupByGroup = {
    ...groupParams,
    [defaultColumnsGroup]: defaultColumns
  }

  // first state of the values list manipulated by the checkbox array
  const defaultColumnsFromLookup = columns
    .filter(column => column.isDefault)
    .map(column => column.value)

  // list of values manipulated by the checkbox array
  const [displayedColumns, setDisplayedColumns] = useState<string[]>(defaultColumnsFromLookup)

  // first state of the list of selectedColumns
  const defaultSelectedColumns = columns.filter(column => {
    return displayedColumns.includes(column.value)
  })

  // list that feeds the drag and drop component
  const [selectedColumns, setSelectedColumns] = useState<Column[]>(defaultSelectedColumns)

  const selectColumnsAsValues = selectedColumns
    .filter(column => column?.value)
    .map(column => column.value)

  const somethingHaveChanged = !isArrayWithSameContentAndOrder(selectColumnsAsValues, viewColumns)
  const viewColumnsInitialState =
    !presence(viewColumns) &&
    isArrayWithSameContentAndOrder(selectColumnsAsValues, defaultColumnsFromLookup)
  const canApply = somethingHaveChanged && !viewColumnsInitialState

  const hasViewColumnsParams = !viewColumns.length
  const canReset =
    !isArrayWithSameContent(displayedColumns, defaultColumnsFromLookup) && !hasViewColumnsParams

  useUpdateEffect(() => {
    if (!presence(viewColumns)) {
      setDisplayedColumns(defaultColumnsFromLookup)
      return
    }

    const filteredViewColumns = viewColumns.filter(column => columns.find(c => column === c.value))

    setDisplayedColumns(filteredViewColumns)
  }, [viewColumns])

  useUpdateEffect(() => {
    if (!presence(viewColumns)) {
      const defaultSelection = columns.filter(column => {
        return defaultColumnsFromLookup.includes(column.value)
      })
      setSelectedColumns(defaultSelection)
      return
    }

    const formatDisplayToSelectedColumns = viewColumns
      .map(value => columns.find(column => column.value === value) as Column)
      .filter(column => column?.value)

    setSelectedColumns(formatDisplayToSelectedColumns)
  }, [viewColumns])

  // check and uncheck columns to the drag and drop list and the checkbox array
  const selectColumns = (newSetOfColumns: string[]) => {
    setDisplayedColumns(newSetOfColumns)
    const showReorderableColumns =
      selectedColumns.length && selectedColumns.some(columns => columns.isReorderable)

    if (!showReorderableColumns) {
      const newSelectedColumns = columns.filter(column => newSetOfColumns.includes(column.value))
      setSelectedColumns(newSelectedColumns)
      return
    }

    //getting the value that isn't in the selected columns yet
    const newColumn = newSetOfColumns.find(selectedColumn =>
      selectedColumns.every(column => column.value !== selectedColumn)
    )

    //removing columns that are already selected
    const columnToRemove = selectedColumns
      .map(selected => selected.value)
      .find(column => !newSetOfColumns.includes(column))

    if (newColumn) {
      // transform the newColumn (that its a string) to a Column type
      const newSelectedColumn = columns.filter(column => newColumn === column.value)
      setSelectedColumns(prevSelectedColumns => [...prevSelectedColumns, ...newSelectedColumn])
    } else if (columnToRemove) {
      // get a new list of selected columns removing the column that was removed in the checkbox
      const newSelectedColumnsSelection = selectedColumns.filter(
        column => column.value !== columnToRemove
      )
      setSelectedColumns(newSelectedColumnsSelection)
    }
  }

  const applyColumns = () => {
    const isDefaultSelected = isArrayWithSameContentAndOrder(selectColumnsAsValues, viewColumns)
    setViewColumns(isDefaultSelected ? undefined : selectColumnsAsValues)
    setIsOpenPopover(false)
  }

  const resetColumns = () => {
    setViewColumns(undefined)
    setIsOpenPopover(false)
  }

  return {
    applyColumns,
    canApply,
    canReset,
    optionalColumnsFromLookup,
    optionalColumnsFromLookupByGroup,
    displayedColumns,
    resetColumns,
    selectColumns,
    selectedColumns,
    setDisplayedColumns,
    setSelectedColumns,
    isOpenPopover,
    setIsOpenPopover
  }
}
