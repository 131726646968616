import { Agent } from './Agent'
import {
  AgentMatchingLead,
  BuyerLead,
  CCBBYSLead,
  CCCashOfferLead,
  CCHLSimpleSaleLead,
  CCTradeInLead,
  EscrowLead,
  Lead,
  MortgageLead,
  SellerLead
} from './Lead'
import { Metroplex } from './Metroplex'
import { Order } from './Order'
import { UsState } from './UsState'

export const isOrder = (instance): instance is Order => {
  return instance instanceof Order
}
export const isAgent = (instance): instance is Agent => {
  return instance instanceof Agent
}
export const isCCBBYSLead = (instance): instance is CCBBYSLead => {
  return instance instanceof CCBBYSLead
}
export const isCCTradeInLead = (instance): instance is CCTradeInLead => {
  return instance instanceof CCTradeInLead
}
export const isCCCashOfferLead = (instance): instance is CCCashOfferLead => {
  return instance instanceof CCCashOfferLead
}
export const isSellerLead = (instance): instance is SellerLead => {
  return instance instanceof SellerLead
}
export const isBuyerLead = (instance): instance is BuyerLead => {
  return instance instanceof BuyerLead
}
export const isAgentMatchingLead = (instance): instance is AgentMatchingLead => {
  return instance instanceof AgentMatchingLead
}
export const isMortgageLead = (instance): instance is MortgageLead => {
  return instance instanceof MortgageLead
}
export const isEscrowLead = (instance): instance is EscrowLead => {
  return instance instanceof EscrowLead
}
export const isCCHLSimpleSaleLead = (instance): instance is CCHLSimpleSaleLead => {
  return instance instanceof CCHLSimpleSaleLead
}
export const isLead = (instance): instance is Lead => {
  return instance instanceof Lead
}
export const isMetroplex = (instance): instance is Metroplex => {
  return instance instanceof Metroplex
}
export const isUsState = (instance): instance is UsState => {
  return instance instanceof UsState
}
