import { ReactNode, useState } from 'react'
import { Text } from '@foundation/components'
import { useInterval, useUpdateEffect } from 'hooks'
import { formatTalkTime, pluralize } from 'utils'
import * as S from '../styles'

type Props = {
  amountOfAgents: number
  canShowAmountOfAgents: boolean
  children: ReactNode
  totalElapsedTime: number
}

export const AgentsListPopUp = ({
  children,
  canShowAmountOfAgents,
  amountOfAgents,
  totalElapsedTime
}: Props) => {
  const [warmTransferTimer, setWarmTransferTimer] = useState<number>(totalElapsedTime)

  useInterval(() => {
    setWarmTransferTimer(prevTimer => prevTimer + 10)
  }, 10)

  useUpdateEffect(() => {
    setWarmTransferTimer(totalElapsedTime)
  }, [totalElapsedTime])

  return (
    <>
      {canShowAmountOfAgents && (
        <S.Coaster>
          <S.MenuTitle>
            <Text weight="semibold" color="decorative5" size="xs">
              {`${pluralize(amountOfAgents, 'Agent')} (Total elapsed time: ${formatTalkTime(
                warmTransferTimer
              )})`}
            </Text>
          </S.MenuTitle>
          {children}
        </S.Coaster>
      )}
    </>
  )
}
