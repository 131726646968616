import { ReactNode } from 'react'
import { useQueryStringParams } from 'hooks/useQueryStringParams'
import { ParamsContext } from './ParamsContext'

type Props = {
  children: ReactNode
  defaultParams?: any
  initialParams?: any
}

export const QueryStringParamsProvider = ({
  children,
  initialParams = {},
  defaultParams = {}
}: Props) => {
  return (
    <ParamsContext.Provider value={useQueryStringParams(defaultParams, initialParams)}>
      {children}
    </ParamsContext.Provider>
  )
}
