// @ts-strict
import { InvestorPayPerLeadPaymentInformationResponse } from 'schema'
import { Model } from '../Model'

export class InvestorPayPerLeadPaymentInformation extends Model {
  brand?: string
  expMonth?: number
  expYear?: number
  externalId?: string
  id?: string | number
  last4?: string
  monthlyAmountLeft?: number
  monthlyAmountUsed?: number
  monthlyCap?: number
  select?: boolean

  constructor(
    investorPayPerLeadPaymentInformationAttributes: InvestorPayPerLeadPaymentInformationResponse
  ) {
    super()
    Object.assign(this, investorPayPerLeadPaymentInformationAttributes)
  }

  static create(
    investorPayPerLeadPaymentInformationAttributes: Potential<InvestorPayPerLeadPaymentInformationResponse>
  ): Potential<InvestorPayPerLeadPaymentInformationResponse> {
    if (!investorPayPerLeadPaymentInformationAttributes) {
      return
    }

    return new InvestorPayPerLeadPaymentInformation(investorPayPerLeadPaymentInformationAttributes)
  }
}
