// @ts-strict

import { FunctionComponent, ReactNode } from 'react'
import { Button } from '@foundation/components'
import {
  ActionButton,
  Form,
  FormActions,
  IButtonProps,
  Popup,
  Section,
  Sections,
  TButtonFormFormProps
} from 'components'
import { ButtonFormContents } from './ButtonFormContents'
import styles from './ButtonFormContents.module.scss'

export type TButtonFormContentsProps = {
  aside?: ReactNode
  buttonComponent?: FunctionComponent<IButtonProps>
  buttonText?: ReactNode
  children: ReactNode
  // remove this prop after migrating all the disposition buttons
  hideTriggerButton?: boolean
  title: string
} & IButtonProps

export const ButtonForm = ({
  aside = null,
  buttonComponent: ButtonComponent = ActionButton,
  submitButtonText = 'Submit',
  buttonText,
  children,
  error,
  errorMessage,
  handleClose,
  handleHover,
  isFormValid,
  isLoading,
  onSubmit,
  showForm,
  showError,
  title,
  toggleShowForm,
  disabled,
  hideTriggerButton
}: TButtonFormFormProps & TButtonFormContentsProps) => {
  const form = (
    <Form
      onSubmit={isFormValid ? onSubmit : () => {}}
      className={styles.form}
      data-testid="formButtonForm"
    >
      {children}
      <FormActions error={(showError && error) || errorMessage} onMouseEnter={handleHover}>
        <Button
          onClick={handleClose}
          isDisabled={isLoading}
          variant="ghost"
          color="neutralLight"
          type="button"
        >
          Cancel
        </Button>
        <Button isDisabled={!isFormValid} isLoading={isLoading}>
          {submitButtonText || 'Submit'}
        </Button>
      </FormActions>
    </Form>
  )

  return (
    <>
      {!hideTriggerButton && (
        <ButtonComponent onClick={toggleShowForm} title={title} disabled={disabled}>
          {buttonText || title}
        </ButtonComponent>
      )}
      {showForm ? (
        <Popup title={title} onCancel={handleClose} className={styles.popup}>
          <ButtonFormContents>
            {aside ? (
              <Sections>
                <Section>{form}</Section>
                <Section>{aside}</Section>
              </Sections>
            ) : (
              form
            )}
          </ButtonFormContents>
        </Popup>
      ) : null}
    </>
  )
}
