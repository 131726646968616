import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Block, Contents } from 'components'

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-size: 2rem;
`

export const LeadCreationAdminPage = () => {
  return (
    <Contents title="Lead Creation Admin">
      <ListContainer>
        <Block title={<Link to="/lead-creation-admin/personas">Personas</Link>} />
        <Block
          title={<Link to="/lead-creation-admin/lead-induction-groups">Lead Induction Groups</Link>}
        />
      </ListContainer>
    </Contents>
  )
}
