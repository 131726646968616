// @ts-strict

import { ReactNode } from 'react'
import { Block } from 'components'
import { Property } from 'models'
import { cx, structureAddress } from 'utils'
import styles from './OrderLeadNavigation.module.scss'

export const OrderLeadNavigationProperty = ({
  property,
  children,
  className
}: {
  children: ReactNode
  className?: string
  property: Property
}) => {
  const { cityState, street } = structureAddress(property.name)
  const title = [street, cityState].filter(Boolean).join(', ')
  return (
    <Block
      key={property.name}
      data-testid="propertyNavigation"
      title={title}
      className={cx(styles.property, className)}
    >
      {children}
    </Block>
  )
}
