import {
  LEADS_CACHE_KEY,
  ORDER_CACHE_KEY,
  ORDERS_CACHE_KEY,
  useMutation,
  useQueryClient
} from 'hooks'
import { postHLSSPropertyQualification, putHLSimpleSaleLead } from 'services/HLSimpleSaleLead'

export const useHLSimpleSaleLeadController = (hlSimpleSaleLeadId: string) => {
  const queryCache = useQueryClient()

  const clearCaches = () => {
    queryCache.invalidateQueries(LEADS_CACHE_KEY)
    queryCache.invalidateQueries(ORDER_CACHE_KEY)
    queryCache.invalidateQueries(ORDERS_CACHE_KEY)
  }

  const defaultCallback = {
    onSuccess: clearCaches
  }

  const updateHLSimpleSaleLead = useMutation(
    (payload: Record<string, any>) => putHLSimpleSaleLead(hlSimpleSaleLeadId, payload),
    defaultCallback
  )

  const runPropertyQualification = useMutation(
    ({ id }: { id: string }) => postHLSSPropertyQualification(id),
    defaultCallback
  )

  return { updateHLSimpleSaleLead, runPropertyQualification }
}
