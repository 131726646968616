import { ReactNode } from 'react'
import { Console, ConsoleAside, ConsoleDetails } from 'components'
import { cx } from 'utils'
import styles from './Card.module.scss'

type Props = {
  className?: string
  'data-testid'?: string
  details: ReactNode
  summary: ReactNode
  topBanner?: ReactNode
}

export const Card = ({ summary, details, className, topBanner, ...rest }: Props) => {
  return (
    <Console className={cx(styles.card, className)} {...rest}>
      {topBanner}
      <ConsoleAside className={styles.summary}>{summary}</ConsoleAside>
      <ConsoleDetails className={styles.content}>{details}</ConsoleDetails>
    </Console>
  )
}
