import { css, styled } from '@foundation/themes/styles'

export const Wrapper = styled.div<{ alternateBackground?: boolean }>`
  ${({ theme, alternateBackground }) => css`
    display: flex;
    padding: ${theme.space.$5} ${theme.space.$4};
    border-radius: ${theme.radii.base};
    gap: ${theme.space.$5};
    cursor: pointer;

    ${alternateBackground && `background-color: ${theme.colors.neutral1}`};

    &:hover {
      background-color: ${theme.colors.neutral3};
    }
  `}
`

export const DetailsWrapper = styled.div`
  display: flex;
  flex: 1;
`

export const Details = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: ${theme.space.$3};
    max-width: 34rem;
  `}
`

export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.space.$3};
    min-width: 7rem;
  `}
`

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Separator = styled.div`
  ${({ theme }) => css`
    width: 1px;
    height: inherit;
    background-color: ${theme.colors.neutral5};
  `}
`
