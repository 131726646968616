import { environment } from '../../environment'

export const getApiRoot = () => {
  if (environment.apiRoot) {
    return environment.apiRoot
  }

  // this is a temporary backup in case there is no ENV variable supplied.
  // You should pass in an ENV variable, this will be deprecated.

  if (!environment.env || environment.env === 'test') {
    console.warn('Please set the REACT_APP_API_ROOT env variable')
  }

  switch (environment.env) {
    case 'production': {
      return 'https://hapi.homelight.com/api'
    }
    case 'staging': {
      return 'https://staging.hapi.homelight.com/api'
    }
    case 'development': {
      return 'http://localhost:3001/api'
    }
    case 'test': {
      return 'http://example.com/api'
    }
    default: {
      return 'http://undefined.com/api'
    }
  }
}
