// @ts-strict

import { useState } from 'react'
import { Dialog } from '@foundation/components'
import {
  RowButton,
  Table,
  TableHeaderRow,
  TableResults,
  TableCell as TD,
  TableHeaderColumn as TH,
  TableRow as TR
} from 'components'
import { CCBBYSLead, GeneratedDocument } from 'models'
import { signGeneratedDocument } from 'services'
import { formatDate } from 'utils'

const TableHeader = () => {
  return (
    <TableHeaderRow>
      <TH name="Created At" />
      <TH name="Slug" />
      <TH name="Agreement Variables" />
      <TH name="Name" />
      <TH />
    </TableHeaderRow>
  )
}

type TProps = { generatedDocument: GeneratedDocument; lead: CCBBYSLead }

const TableRow = ({ lead, generatedDocument }: TProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isPreparing, setIsPreparing] = useState(false)
  const [presignedUrl, setPresignedUrl] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  async function onSignDocument() {
    if (!lead.bbysLead?.id || !generatedDocument.id) {
      return
    }
    setIsPreparing(true)
    try {
      const rsp = await signGeneratedDocument(lead.bbysLead?.id, generatedDocument.id)
      window.open(rsp.data.sender_view_url, '_blank')
      setPresignedUrl(rsp.data.sender_view_url)
    } catch (error: any) {
      setError(error.message)
    }
    setIsPreparing(false)
  }

  function onOpenChange(open: boolean) {
    setIsOpen(open)
    if (!open) {
      setIsPreparing(false)
      setPresignedUrl(null)
      setError(null)
    } else {
      onSignDocument()
    }
  }

  const convertCamelCaseToNormalCase = (str: string) => {
    let formattedStr = str.replace(/Flag$/, '')
    formattedStr = formattedStr.replace(/([A-Z])/g, ' $1').trim()
    return formattedStr.replace(/\b\w/g, char => char.toUpperCase())
  }

  const agreementVariables =
    generatedDocument.params && typeof generatedDocument.params === 'object'
      ? Object.entries(generatedDocument.params)
          .filter(([key, value]) => key.endsWith('Flag') && value === 'true')
          .map(([key]) => convertCamelCaseToNormalCase(key))
          .join(', ')
      : ''

  return (
    <TR>
      <TD>{formatDate(generatedDocument.createdAt)}</TD>
      <TD>{generatedDocument.slug}</TD>
      <TD>{agreementVariables || ''}</TD>
      <TD>
        <a href={generatedDocument.url} target="_blank" rel="noreferrer noopener">
          {generatedDocument.name}
        </a>
      </TD>
      <TD align="right">
        <Dialog.Root isOpen={isOpen} onOpenChange={onOpenChange}>
          <Dialog.Trigger>
            <RowButton>Sign Document</RowButton>
          </Dialog.Trigger>
          <Dialog.Content>
            <Dialog.Body>
              {isPreparing && <p>Preparing Docusign Envelope...</p>}
              {presignedUrl && (
                <>
                  <p>Envelope is ready.</p>
                  <br />
                  <a
                    href={presignedUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={() => onOpenChange(false)}
                  >
                    Open in Docusign
                  </a>
                </>
              )}
              {error && <p style={{ color: 'red' }}>{error}</p>}
            </Dialog.Body>
          </Dialog.Content>
        </Dialog.Root>
      </TD>
    </TR>
  )
}

export const GeneratedDocumentsTable = ({
  lead,
  generatedDocuments = []
}: {
  generatedDocuments?: GeneratedDocument[]
  lead: CCBBYSLead
}) => {
  return (
    <Table data-testid="generated-documents-table">
      <TableHeader />
      <TableResults type="GeneratedDocuments">
        {generatedDocuments?.map(generatedDocument => (
          <TableRow key={generatedDocument.id} lead={lead} generatedDocument={generatedDocument} />
        ))}
      </TableResults>
    </Table>
  )
}
