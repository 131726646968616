// @ts-strict
import { useEffect } from 'react'
import { FormField, Section, Select } from 'components'
import { TMortgageLeadDispositionPayload } from 'controllers'
import { TMortgageLeadFailedReason, useFailedReasons } from 'lookups'
import { TFormValidation, validatePresence } from 'utils'
import { TMortgageLeadDispositionFC } from '../useMortgageLeadDispositionButtonForm'

export const MortgageLeadDispositionFailedFields = ({
  lead,
  onChangeAttribute,
  payload,
  removeAttribute,
  setValidations
}: TMortgageLeadDispositionFC<TMortgageLeadDispositionPayload>) => {
  const { mortgageFailedReasonOptions } = useFailedReasons()

  useEffect(() => {
    setValidations({ reasonForFail: validatePresence } as TFormValidation)
    return () => setValidations({})
  }, [setValidations])

  useEffect(() => {
    onChangeAttribute('reasonForFail', lead.reasonForFail as TMortgageLeadFailedReason)
    return () => removeAttribute('reasonForFail')
  }, [lead, onChangeAttribute, removeAttribute])

  return (
    <Section>
      <FormField title="Failed Reason" required>
        <Select
          value={payload.reasonForFail}
          onChange={val => onChangeAttribute('reasonForFail', val as TMortgageLeadFailedReason)}
          options={mortgageFailedReasonOptions}
          data-testid="reasonForFail"
          addBlank
        />
      </FormField>
    </Section>
  )
}
