import { TModelFieldSetting } from '../ModelField'

export type TCcCashOfferLeadFieldAttribute =
  | 'agentNotes'
  | 'buyerAgentTeam'
  | 'calculatedValue'
  | 'capitalMarketsFacilityName'
  | 'cashOfferFeeRate'
  | 'createdAt'
  | 'depositRate'
  | 'email1'
  | 'email'
  | 'emailAlt'
  | 'expressClose'
  | 'fullName'
  | 'incomingPropertyBuyerAgentId'
  | 'incomingPropertyCloseOfPurchaseEscrowAt'
  | 'incomingPropertyCloseOfSellEscrowAt'
  | 'incomingPropertyFullAddress'
  | 'incomingPropertyFundsWiredToSellerAt'
  | 'incomingPropertyInspectionContingencyWaived'
  | 'incomingPropertyListPrice'
  | 'incomingPropertyMaxOfferPrice'
  | 'ipMaxOfferPriceAtHlValuation'
  | 'incomingPropertyOfferAcceptedAt'
  | 'incomingPropertyOfferCancelledReason'
  | 'incomingPropertyOfferRejectedAt'
  | 'incomingPropertyOfferRejectedReason'
  | 'incomingPropertyOpenPurchaseEscrowAt'
  | 'incomingPropertyOpenSellEscrowAt'
  | 'incomingPropertySalePrice'
  | 'incomingPropertyValuation'
  | 'ipAdditionalDeposit'
  | 'ipAvmValuation'
  | 'ipAvmValuationAt'
  | 'ipBuyersAgentCommission'
  | 'ipBuyersAgentCommissionRate'
  | 'ipCapitalDaysOutstanding'
  | 'ipCashOfferDeposit'
  | 'ipContingencyRemovalAt'
  | 'ipEarnestMoney'
  | 'ipInsuranceCoverage'
  | 'ipNetSettlement'
  | 'ipOfferTermsDaysTillCloseOfEscrow'
  | 'ipOfferTermsDaysTillInspectionContingencyEnds'
  | 'ipOfferTermsOptionTerminationFee'
  | 'ipOfferTermsInspectionTerminationDeadlineDate'
  | 'ipOfferTermsInspectionResolutionDeadlineDate'
  | 'ipOfferTermsInspectionObjectionDeadlineDate'
  | 'ipOfferTermsDeadlineDate'
  | 'ipTargetCloseOfPurchaseEscrowAt'
  | 'ipTargetCloseOfSellEscrowAt'
  | 'licenseNumber'
  | 'name'
  | 'number'
  | 'partnerName'
  | 'phoneAlt'
  | 'phoneMobile'
  | 'protectedOfferStatus'
  | 'propertyConditionApproved'
  | 'reasonForFail'
  | 'refAgentName'
  | 'refAgentPhoneMobile'
  | 'refAgentTeam'
  | 'referringOwnerId'
  | 'revenueRecognitionDate'
  | 'source'

export type TCcCashOfferLeadFieldSetting = {
  attr: TCcCashOfferLeadFieldAttribute
} & TModelFieldSetting

export const ccCashOfferLeadFieldsSettings: TCcCashOfferLeadFieldSetting[] = [
  { attr: 'agentNotes', name: 'Agent Notes' },
  { attr: 'buyerAgentTeam', name: 'Buyer Agent Team' },
  { attr: 'calculatedValue', name: 'Cash Offer Fee' },
  { attr: 'capitalMarketsFacilityName', name: 'Capital Markets Facility' },
  { attr: 'cashOfferFeeRate', name: 'Cash Offer Fee %', inputType: 'percent' },
  { attr: 'createdAt', name: 'Date Received' },
  { attr: 'depositRate', name: 'Deposit %', inputType: 'percent' },
  { attr: 'email1', name: 'Buyer Agent Email' },
  { attr: 'email', name: 'Client Email' },
  { attr: 'emailAlt', name: 'Spouse / Partner Email' },
  { attr: 'expressClose', name: 'Express Close', inputType: 'boolean' },
  { attr: 'fullName', name: 'Buyer Agent Name' },
  { attr: 'incomingPropertyBuyerAgentId', name: 'Buyer Agent' },
  {
    attr: 'incomingPropertyCloseOfPurchaseEscrowAt',
    name: 'Actual COE Purchase Date',
    inputType: 'date'
  },
  {
    attr: 'incomingPropertyCloseOfSellEscrowAt',
    name: 'Actual COE Sell Date',
    inputType: 'date'
  },
  {
    attr: 'incomingPropertyFullAddress',
    name: 'Incoming Property Full Address',
    inputType: 'address'
  },
  {
    attr: 'incomingPropertyFundsWiredToSellerAt',
    name: 'Purchase Escrow - Actual Funding Date',
    inputType: 'date'
  },
  {
    attr: 'incomingPropertyInspectionContingencyWaived',
    name: 'Insp. Cont. Waived',
    inputType: 'boolean'
  },
  { attr: 'incomingPropertyListPrice', name: 'List Price', inputType: 'money' },
  {
    attr: 'incomingPropertyMaxOfferPrice',
    name: 'Max Cash Offer Purchase Price',
    inputType: 'money'
  },
  {
    attr: 'ipMaxOfferPriceAtHlValuation',
    name: 'Max Offer Price At HL Valuation',
    inputType: 'dollarsAndCents'
  },
  { attr: 'incomingPropertyOfferAcceptedAt', name: 'Offer Accepted', inputType: 'date' },
  {
    attr: 'incomingPropertyOfferCancelledReason',
    name: 'Offer Cancelled Reason',
    inputType: 'text'
  },
  { attr: 'incomingPropertyOfferRejectedAt', name: 'Offer Rejected', inputType: 'date' },
  {
    attr: 'incomingPropertyOfferRejectedReason',
    name: 'Offer Rejected Reason',
    inputType: 'text'
  },
  {
    attr: 'incomingPropertyOpenPurchaseEscrowAt',
    name: 'Purchase Escrow Open Date',
    inputType: 'date'
  },
  {
    attr: 'incomingPropertyOpenSellEscrowAt',
    name: 'Sell Escrow Open Date',
    inputType: 'date'
  },
  {
    attr: 'incomingPropertySalePrice',
    name: 'Cash Offer Purchase Price',
    inputType: 'money'
  },
  { attr: 'incomingPropertyValuation', name: 'HL Expected Appraisal', inputType: 'money' },
  { attr: 'ipAdditionalDeposit', name: 'Additional Deposit', inputType: 'money' },
  { attr: 'ipAvmValuation', name: 'AVM Valuation' },
  { attr: 'ipAvmValuationAt', name: 'Date of AVM Valuation', inputType: 'date' },
  { attr: 'ipBuyersAgentCommission', name: 'Agent Buy Commission $' },
  { attr: 'ipBuyersAgentCommissionRate', name: 'Agent Buy Commission %' },
  { attr: 'ipCapitalDaysOutstanding', name: 'Days of Investor Capital Outstanding' },
  { attr: 'ipCashOfferDeposit', name: 'Cash Offer Deposit' },
  { attr: 'ipContingencyRemovalAt', name: 'Contingency Removal Date', inputType: 'date' },
  { attr: 'ipEarnestMoney', name: 'Earnest Money Deposit', inputType: 'money' },
  { attr: 'ipInsuranceCoverage', name: 'Insurance Coverage Amount' },
  { attr: 'ipNetSettlement', name: 'Net Settlement' },
  {
    attr: 'ipOfferTermsDaysTillCloseOfEscrow',
    name: 'Days of Close of Escrow',
    inputType: 'integer'
  },
  {
    attr: 'ipOfferTermsDaysTillInspectionContingencyEnds',
    name: 'Days on Inspection Contingency',
    inputType: 'integer'
  },
  {
    attr: 'ipOfferTermsOptionTerminationFee',
    name: 'Option Termination Fee',
    inputType: 'money'
  },
  {
    attr: 'ipOfferTermsInspectionTerminationDeadlineDate',
    name: 'Inspection Termination Deadline',
    inputType: 'date'
  },
  {
    attr: 'ipOfferTermsInspectionResolutionDeadlineDate',
    name: 'Inspection Resolution Deadline',
    inputType: 'date'
  },
  {
    attr: 'ipOfferTermsInspectionObjectionDeadlineDate',
    name: 'Inspection Objection Deadline',
    inputType: 'date'
  },
  {
    attr: 'ipOfferTermsDeadlineDate',
    name: 'Offer Deadline',
    inputType: 'date'
  },
  {
    attr: 'ipTargetCloseOfPurchaseEscrowAt',
    name: 'Purchase Escrow - Target COE Date',
    inputType: 'date'
  },
  {
    attr: 'ipTargetCloseOfSellEscrowAt',
    name: 'Sell Escrow - Target COE Date',
    inputType: 'date'
  },
  { attr: 'licenseNumber', name: 'Buyer Agent License' },
  { attr: 'name', name: 'Client Name' },
  { attr: 'number', name: 'Client Phone' },
  { attr: 'partnerName', name: 'Spouse / Partner Name' },
  { attr: 'phoneAlt', name: 'Spouse / Partner Phone' },
  { attr: 'phoneMobile', name: 'Buyer Agent Phone' },
  { attr: 'protectedOfferStatus', name: 'Protected Offer' },
  { attr: 'propertyConditionApproved', name: 'Property Condition Approved', inputType: 'boolean' },
  {
    attr: 'reasonForFail',
    name: 'Failed Reason',
    inputType: 'select',
    getOptions: 'ccCashOfferFailedReasons'
  },
  { attr: 'refAgentName', name: 'Ref Agent Name' },
  { attr: 'refAgentPhoneMobile', name: 'Ref Agent Phone' },
  { attr: 'refAgentTeam', name: 'Ref Agent Team' },
  { attr: 'referringOwnerId', name: 'Ref Agent' },
  {
    attr: 'revenueRecognitionDate',
    name: 'Waterfall Funds Received Date',
    inputType: 'date'
  },
  { attr: 'source', name: 'Source' }
]
