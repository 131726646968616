// @ts-strict
import { Link } from 'react-router-dom'
import { Block, ExternalLink, TableActions, TableCellProps, TableCell as Td } from 'components'
import { AgentPartnership } from 'models'
import { dataOrDash, formatBoolean, formatDate } from 'utils'
import { EditAgentPartnershipButtonForm } from '../EditAgentPartnershipButtonForm'
import styles from './AgentPartnershipCells.module.scss'

type Props = TableCellProps & {
  agentPartnership: AgentPartnership
}

export const AgentPartnershipNameCell = ({ agentPartnership, ...rest }: Props) => {
  const { name } = agentPartnership

  return <Td {...rest}>{dataOrDash(name)}</Td>
}

export const AgentPartnershipTransactionTeamCell = ({ agentPartnership, ...rest }: Props) => {
  const { transactionTeam } = agentPartnership

  return <Td {...rest}>{dataOrDash(transactionTeam?.name)}</Td>
}

export const AgentPartnershipEnabledCell = ({ agentPartnership, ...rest }: Props) => {
  const { active } = agentPartnership

  return <Td {...rest}>{formatBoolean(active)}</Td>
}

export const AgentPartnershipNumberOfAgentsCell = ({ agentPartnership, ...rest }: Props) => {
  const { agentsCount } = agentPartnership

  return (
    <Td {...rest}>
      <Link to={`/agents?agentPartnership=${agentPartnership.id}`}>{dataOrDash(agentsCount)}</Link>
    </Td>
  )
}

export const AgentPartnershipIncomingUrlCell = ({ agentPartnership, ...rest }: Props) => {
  const { incomingUrl } = agentPartnership

  return (
    <Td className={styles.urlCell} {...rest}>
      <ExternalLink href={incomingUrl}>{dataOrDash(incomingUrl)}</ExternalLink>
    </Td>
  )
}

export const AgentPartnershipDestinationUrlCell = ({ agentPartnership, ...rest }: Props) => {
  const { destinationUrl } = agentPartnership

  return (
    <Td className={styles.urlCell} {...rest}>
      <ExternalLink href={destinationUrl}>{dataOrDash(destinationUrl)}</ExternalLink>
    </Td>
  )
}

export const AgentPartnershipCreatedUpdatedAtCell = ({ agentPartnership, ...rest }: Props) => {
  const { createdAt, updatedAt } = agentPartnership

  return (
    <Td {...rest}>
      <Block title={formatDate(createdAt)} subtitle={updatedAt && formatDate(updatedAt)}></Block>
    </Td>
  )
}

export const AgentPartnershipActionsCell = ({ agentPartnership, ...rest }: Props) => {
  return (
    <Td {...rest}>
      <TableActions>
        <EditAgentPartnershipButtonForm agentPartnership={agentPartnership} />
      </TableActions>
    </Td>
  )
}
