import { AgentTeamMembership } from 'models'

const fieldToBoolString = (field: boolean): string => {
  return (!!field).toString()
}

export const filterAgentTeamMemberships = (
  agentTeamMemberships?: AgentTeamMembership[],
  params?: any
) => {
  if (!agentTeamMemberships) {
    return []
  }

  const leadRoutingFilter = (agentTeamMembership: AgentTeamMembership, value: string) =>
    value === undefined
      ? agentTeamMembership
      : fieldToBoolString(agentTeamMembership?.user?.agent?.referralPreferences?.active) === value

  const warmTransferFilter = (agentTeamMemberships: AgentTeamMembership, value: string) =>
    value === undefined
      ? agentTeamMemberships
      : fieldToBoolString(
          agentTeamMemberships?.user?.agent?.referralPreferences?.leadRoutingWarmTransferOptIn
        ) === value

  const fallbackAgentFilter = (agentTeamMemberships: AgentTeamMembership, value: string) =>
    value === undefined
      ? agentTeamMemberships
      : fieldToBoolString(
          agentTeamMemberships?.user?.agent?.referralPreferences?.isFallbackAgent
        ) === value

  const propertyTypeFilter = (agentTeamMembership: AgentTeamMembership, values: string[]) => {
    if (!values || !values.length) {
      return true
    }

    const types: string[] = [
      (agentTeamMembership?.user?.agent?.referralPreferences?.worksWithBuyers &&
        'worksWithBuyers') ||
        '',
      (agentTeamMembership?.user?.agent?.referralPreferences?.worksWithSellers &&
        'worksWithSellers') ||
        ''
    ]

    return values.every(value => types.includes(value))
  }

  return agentTeamMemberships
    .filter(mem => leadRoutingFilter(mem, params?.leadRoutingMember))
    .filter(mem => warmTransferFilter(mem, params?.warmTransferMember))
    .filter(mem => fallbackAgentFilter(mem, params?.fallbackAgent))
    .filter(mem => propertyTypeFilter(mem, params?.propertyTypes))
}
