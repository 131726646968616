import { ReactNode } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import {
  Console,
  ConsoleAside,
  ConsoleDetails,
  ConsoleLoading,
  Dev,
  Sticky,
  TabNav,
  Tabs
} from 'components'
import { EmployeeAside } from 'content/Employee'
import { EmployeeProvider, useEmployeeContext } from 'contexts'
import { Employee } from 'models'
import styles from './EmployeePage.module.scss'

const EmployeePageContent = ({
  employee,
  children
}: {
  children: ReactNode
  employee: Employee
}) => {
  const { pathname } = employee.link

  return (
    <Console>
      <ConsoleAside>
        <Sticky>
          <EmployeeAside employee={employee} className={styles.aside} />
        </Sticky>
      </ConsoleAside>
      <ConsoleDetails className={styles.details}>
        <Tabs className={styles.tabs} sticky>
          <TabNav to={pathname}>Details</TabNav>
          <Dev>
            <TabNav to={`${pathname}/inspect`}>Inspect</TabNav>
          </Dev>
        </Tabs>
        {children}
      </ConsoleDetails>
    </Console>
  )
}

const EmployeePageContext = ({ children }) => {
  const {
    employeeQuery: { data: employee, isLoading, isError }
  } = useEmployeeContext()

  if (isLoading) {
    return <ConsoleLoading type="Employee" />
  }
  if (isError) {
    console.log('Error fetching employee')
  }
  if (!employee) {
    return null
  }
  return <EmployeePageContent employee={employee}>{children}</EmployeePageContent>
}

export const EmployeePage = () => {
  const { employeeId } = useParams()
  return (
    <EmployeeProvider employeeId={employeeId}>
      <EmployeePageContext>
        <Outlet />
      </EmployeePageContext>
    </EmployeeProvider>
  )
}
