import { Children, cloneElement, ReactNode } from 'react'
import { FloatingPortal, Placement } from '@floating-ui/react'
import * as S from './styles'
import { useTooltip } from './useTooltip'

export type TooltipProps = {
  /**
   * The trigger element to show the tooltip. Its not possible to have multiple childrens
   */
  children: ReactNode
  /**
   * Content to be shown when the tooltip is being shown
   */
  content: string | ReactNode
  /**
   * Allows the user to hover the tooltip
   */
  keepOpenOnHover?: boolean
  /**
   * To align the tooltip in different positions
   */
  placement?: Placement
  /**
   * To show or hide the tooltip's arrow
   */
  showArrow?: boolean
}

export const Tooltip = ({
  placement,
  children,
  content,
  showArrow = true,
  keepOpenOnHover = false
}: TooltipProps) => {
  const {
    triggerProps,
    tooltipProps,
    isOpen,
    tooltipArrowProps: { ref, x, y, side }
  } = useTooltip({ preferredPlacement: placement, keepOpenOnHover })

  // enforce a single child
  const child: any = Children.only(children)

  return (
    <>
      {cloneElement(child, triggerProps)}
      <FloatingPortal id="tooltip-portal">
        {isOpen && (
          <S.Content {...tooltipProps}>
            {content}
            {showArrow && <S.Arrow ref={ref} x={x} y={y} arrowSide={side} />}
          </S.Content>
        )}
      </FloatingPortal>
    </>
  )
}
