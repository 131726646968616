// @ts-strict
import { Block, TableCell as Td } from 'components'
import { CCCashOfferLead, CCHLSimpleSaleLead, CCTradeInLead } from 'models'
import { byAttr, dataOrDash } from 'utils'

type TProps = {
  lead: CCCashOfferLead | CCTradeInLead | CCHLSimpleSaleLead
  testId?: string
}

export const CashCloseLeadTxTeamCell = ({ lead, testId }: TProps) => (
  <Td data-testid={testId}>
    <Block title={dataOrDash(lead.agent?.transactionTeam?.name)} />
  </Td>
)

export const CashCloseLeadStrategicRelationshipManagerCell = ({ lead, testId }: TProps) => {
  const strategicRelationshipManager = lead.leadUsers?.find(
    byAttr('role', 'strategic_relationship_manager')
  )
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(strategicRelationshipManager?.user?.name)} />
    </Td>
  )
}

export const CashCloseLeadConsumerClientManagerCell = ({ lead, testId }: TProps) => {
  const clientManager = lead.leadUsers?.find(byAttr('role', 'consumer_client_manager'))
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(clientManager?.user?.name)} />
    </Td>
  )
}

export const CashCloseLeadSalesSpecialistCell = ({ lead, testId }: TProps) => {
  const salesSpecialist = lead.leadUsers?.find(byAttr('role', 'sales_specialist'))
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(salesSpecialist?.user?.name)} />
    </Td>
  )
}

export const CashCloseLeadContractAdministratorCell = ({ lead, testId }: TProps) => {
  const contractAdministrator = lead.leadUsers?.find(byAttr('role', 'contract_administrator'))
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(contractAdministrator?.user?.name)} />
    </Td>
  )
}

export const CashCloseLeadTransactionSpecialistCell = ({ lead, testId }: TProps) => {
  const transactionSpecialist = lead.leadUsers?.find(byAttr('role', 'transaction_specialist'))
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(transactionSpecialist?.user?.name)} />
    </Td>
  )
}

export const CashCloseLeadListingSpecialistCell = ({ lead, testId }: TProps) => {
  const listingSpecialist = lead.leadUsers?.find(byAttr('role', 'listing_specialist'))
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(listingSpecialist?.user?.name)} />
    </Td>
  )
}

export const CashCloseLoanOfficerTeamLeadCell = ({ lead, testId }: TProps) => {
  const listingSpecialist = lead.leadUsers?.find(byAttr('role', 'loan_officer_team_lead'))
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(listingSpecialist?.user?.name)} />
    </Td>
  )
}
