import { css, keyframes, styled } from '@foundation/themes/styles'
import { SpinnerProps as Props } from './Spinner'

type SpinnerProps = Omit<Props, 'label'>

const spinAnimation = keyframes`
	0% {
		transform: rotate(0deg);
	}
  100% {
    transform: rotate(360deg);
  }
`

const spinnerSizeStyle = {
  xs: css`
    ${({ theme }) => `
      height: ${theme.sizes.$7};
      width: ${theme.sizes.$7};
    `}
  `,
  sm: css`
    ${({ theme }) => `
      height: ${theme.sizes.$8};
      width: ${theme.sizes.$8};
    `}
  `,
  md: css`
    ${({ theme }) => `
      height: ${theme.sizes.$9};
      width: ${theme.sizes.$9};
    `}
  `,
  lg: css`
    ${({ theme }) => `
      height: ${theme.sizes.$10};
      width: ${theme.sizes.$10};
    `}
  `
}

const fontSizeStyle = {
  xs: css`
    ${({ theme }) => `
      font-size: ${theme.fontSizes.xs};
    `}
  `,
  sm: css`
    ${({ theme }) => `
      font-size: ${theme.fontSizes.sm};
    `}
  `,
  md: css`
    ${({ theme }) => `
      font-size: ${theme.fontSizes.base};
    `}
  `,
  lg: css`
    ${({ theme }) => `
      font-size: ${theme.fontSizes.base};
    `}
  `
}

const colorStyle = css<SpinnerProps>`
  stroke: ${({ color, theme }) => theme.colors[color!]};
`

const labelPlacementStyle = css<SpinnerProps>`
  flex-direction: ${({ labelPlacement }) => (labelPlacement === 'bottom' ? 'column' : 'row')};
`

export const SpinnerWrapper = styled.span<SpinnerProps>`
  ${({ size, theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.space.$3};

    ${labelPlacementStyle};
    ${!!size && fontSizeStyle[size]};
  `}
`

export const SpinnerAnimation = styled.span<SpinnerProps>`
  ${({ size }) => css`
    display: inline-flex;
    animation: ${spinAnimation} 1.4s linear infinite;
    animation-duration: 450ms;

    ${!!size && spinnerSizeStyle[size]};

    svg {
      circle {
        ${colorStyle};
        stroke-dasharray: 64px, 200px;
        stroke-dashoffset: 0;
        stroke-linecap: round;
      }
    }
  `}
`

export const Label = styled.span<SpinnerProps>`
  ${({ theme }) => css`
    color: ${theme.colors.primary10};
  `}
`
