import { FormField, InlineEditPhoneInput, InlineEditTextInput, Section, Sections } from 'components'
import { AgentSection } from 'content/Agent/AgentSection'
import { dataOrDash } from 'utils'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead'>

export const DRCoListingAgentInformationSection = ({ lead, updateBBYSLead }: Props) => {
  const bbysLead = lead.bbysProviderLead.bbysLead
  const coListingAgent = bbysLead.departingPropertySellerColistAgent

  const leadType = lead.isHLSS ? 'hlss' : 'trade_in'

  return (
    <Sections title="Co-Listing Agent Information">
      <Section>
        <AgentSection
          title="Co-listing agent"
          agent={coListingAgent}
          program={leadType}
          onBlur={val =>
            updateBBYSLead.mutate({
              id: bbysLead.id,
              payload: {
                departing_property_seller_colist_agent_id: val
              }
            })
          }
        />
      </Section>
      <Section>
        <FormField title="Email Address" inline>
          <InlineEditTextInput value={dataOrDash(coListingAgent?.email1)} readOnly={true} />
        </FormField>
        <FormField title="Phone Number" inline>
          <InlineEditPhoneInput value={dataOrDash(coListingAgent?.phoneNumbers)} readOnly={true} />
        </FormField>
      </Section>
    </Sections>
  )
}
