import * as utils from './utils'

export type BlendUserInterface = {
  email?: string
  firstName?: string
  id?: string
  lastName?: string
  name?: string
}

export const BlendUserModel = {
  ...utils
}
