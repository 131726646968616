import { TFormValidation, validatePresence, validateUrl } from 'utils'

export type TLeadExternalLinkPayload = {
  name: string | undefined
  subAttachableId?: string | undefined
  subAttachableType?: string | undefined
  url: string | undefined
}

export const formValidations: TFormValidation = {
  name: validatePresence,
  url: { label: 'URL', validations: [validatePresence, validateUrl] }
}
