import { useQuery } from 'react-query'
import { Flex, Heading, Spinner, Text, useToastContext } from '../../../@foundation/components'
import { deserializeAsync, makeRequest } from '../../../utils'
import CreateOperatorGroupPopover from '../components/create-operator-group-popover/create-operator-group-popover'
import { OperatorGroup } from '../components/operator-group/operator-group'
import { Container } from './styles'
import { TransactionOperatorGroup as TransactionOperatorGroupType } from './types'

export const GET_TRANSACTION_OPERATORS_LIST = 'GET_TRANSACTION_OPERATORS_LIST'
export const BASE_URL = process.env.REACT_APP_API_ROOT

export const TransactionOperatorsPage = () => {
  const toast = useToastContext()

  const { data, isLoading } = useQuery<TransactionOperatorGroupType[]>(
    [GET_TRANSACTION_OPERATORS_LIST],
    async () => {
      const { data } = await makeRequest(
        BASE_URL + '/lead-data-service/transaction-operator-groups?include=users,teams'
      )

      return await deserializeAsync(data)
    },
    {
      onError: error => {
        console.error(error)
        toast({
          title: 'Oops! Something went wrong.',
          status: 'error'
        })
      }
    }
  )

  return (
    <Container>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="xl2">Transaction Operator Groups</Heading>
        <CreateOperatorGroupPopover />
      </Flex>
      {isLoading && <Spinner size="lg" />}
      {data?.map(group => (
        <OperatorGroup group={group} key={group.id} />
      ))}
      {data?.length === 0 && <Text color="neutral9">No Transaction Operator Groups yet</Text>}
    </Container>
  )
}
