import { getResolvePendingActionsUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export type PostResolvePendingActionsParams = {
  actionTaken: string
  ids: string[]
  note?: string
}

export const postResolvePendingActions = ({
  ids,
  actionTaken,
  note
}: PostResolvePendingActionsParams): Promise<any> => {
  return postRequest(getResolvePendingActionsUrl(), {
    pending_action_ids: ids,
    action_taken: actionTaken,
    ...(note ? { note } : null)
  })
}
