// @ts-strict
import { Table } from 'components'
import { TableResults } from 'components/Table/TableResults'
import styles from 'content/Leads/LeadsTable/LeadsTableCells/LeadsTableCells.module.scss'
import { LeadsTableHeaderCells } from 'content/Leads/LeadsTable/LeadsTableHeaderCells'
import { useListContext } from 'contexts'
import { Lead } from 'models'
import { cx } from 'utils'
import { AllLeadsTableRow } from '.'

type AllLeadsTableProps = {
  className?: string
  isLoading: boolean
  items: Potential<Lead[]>
}

export const AllLeadsTable = ({ items, isLoading, className }: AllLeadsTableProps) => {
  const { visibleColumns } = useListContext()
  const colSpan = visibleColumns.length

  return (
    <Table className={cx(styles.table, className)} alternatingColumns>
      <LeadsTableHeaderCells />
      <TableResults type="Leads" colSpan={colSpan} isLoading={isLoading}>
        {items?.map(lead => (
          <AllLeadsTableRow key={lead.id} lead={lead} />
        ))}
      </TableResults>
    </Table>
  )
}
