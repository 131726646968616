// @ts-strict
import { ButtonForm, useButtonForm } from 'components'
import { EditIconButton } from 'components/Button/IconButton'
import { useExternalLinkController } from 'controllers'
import { ExternalLink, isCCCashOfferLead, Lead } from 'models'
import { LeadExternalLinkFormFields } from '../LeadExternalLinkFormFields'
import { formValidations, TLeadExternalLinkPayload } from '../utils'

type TLeadExternalLinksButtonFormProps = {
  externalLink: ExternalLink
  lead: Lead
}

export const LeadEditExternalLinkButtonForm = ({
  lead,
  externalLink
}: TLeadExternalLinksButtonFormProps) => {
  const { editExternalLink } = useExternalLinkController(externalLink.id)

  const subAttachableOptions = isCCCashOfferLead(lead)
    ? lead.getCashOfferProviderLeadsOptions()
    : []

  const initialPayload: TLeadExternalLinkPayload = {
    name: externalLink.name,
    url: externalLink.url,
    subAttachableId: externalLink.subAttachable?.id,
    subAttachableType: externalLink.subAttachable ? 'CashOfferLead' : undefined
  }

  const { formProps, fieldsProps } = useButtonForm<TLeadExternalLinkPayload>({
    mutation: editExternalLink,
    formValidations,
    initialPayload
  })

  return (
    <ButtonForm buttonComponent={EditIconButton} title="Edit External Resource" {...formProps}>
      <LeadExternalLinkFormFields subAttachableOptions={subAttachableOptions} {...fieldsProps} />
    </ButtonForm>
  )
}
