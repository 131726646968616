import { HLSS_LEADS_CACHE_KEY, useQuery } from 'hooks'
import { fetchCCHLSimpleSaleLeads, TCCHLSimpleSaleLeadsSearchParams } from 'services'

const defaultSearchSettings: TCCHLSimpleSaleLeadsSearchParams = {
  sortBy: 'Created',
  sortDir: 'desc',
  perPage: 25
}

type TArgs = {
  searchParams?: TCCHLSimpleSaleLeadsSearchParams
}

export const useCCHLSimpleSaleLeadsController = ({ searchParams = {} }: TArgs = {}) => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  const hlssLeadsQuery = useQuery([HLSS_LEADS_CACHE_KEY, searchSettings], () =>
    fetchCCHLSimpleSaleLeads(searchSettings)
  )

  return { hlssLeadsQuery }
}
