import { ComponentType } from 'react'
import { UseMutationResult } from 'react-query'
import { Option } from 'classes'
import { validateChanged, validatePresence } from 'utils'
import { InlineChangeButton } from '../Button'
import { ButtonForm, useButtonForm } from '../ButtonForm'
import { FormField, Select, TextArea } from '../Form'

export type TChangeReasonButtonFormProps = {
  fieldName: string
  formatter?: (val: string) => string
  inputComponent: ComponentType<{ onChange: (val: string) => void; value: string }>
  mutation: UseMutationResult<unknown, unknown, Record<string, any>>
  reasons: Option[]
  title?: string
  value: Potential<string | boolean>
}

type TChangeReasonFieldsPayload = Record<'note' | 'reason' | 'value', string>

export const ChangeReasonButtonForm = ({
  title,
  reasons,
  mutation,
  fieldName,
  inputComponent: InputComponent,
  formatter = val => val,
  value
}: TChangeReasonButtonFormProps) => {
  const valueString = String(value)
  const initialPayload: TChangeReasonFieldsPayload = {
    value: valueString,
    note: '',
    reason: ''
  }

  const transformPayload = (payload: TChangeReasonFieldsPayload) => {
    return {
      [fieldName]: payload.value,
      changeReasons: {
        [fieldName]: {
          note: payload.note,
          reason: payload.reason
        }
      }
    }
  }

  const formValidations = {
    reason: validatePresence,
    value: { label: 'Field value', validations: [validateChanged(valueString)] }
  }

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm<TChangeReasonFieldsPayload>({
    initialPayload,
    mutation,
    transformPayload,
    formValidations
  })

  const setValue = (val: string) => {
    onChangeAttribute('value', val)
  }
  const setNote = (val: string) => {
    onChangeAttribute('note', val)
  }
  const setReason = (val: string) => {
    onChangeAttribute('reason', val)
  }

  return (
    <ButtonForm
      buttonComponent={InlineChangeButton}
      title="Update Field with Reason"
      buttonText={formatter(valueString)}
      {...formProps}
    >
      <FormField title={title}>
        <InputComponent value={payload.value} onChange={setValue} data-testid="valueField" />
      </FormField>
      <FormField title="Reason" required>
        <Select
          data-testid="reasonSelect"
          options={reasons}
          onChange={setReason}
          value={payload.reason}
          addBlank
        />
      </FormField>
      <FormField title="Note">
        <TextArea onChange={setNote} value={payload.note} data-testid="noteField" />
      </FormField>
    </ButtonForm>
  )
}
