// @ts-strict
import { Icon } from 'components'
import { Task } from 'models'
import { cx } from 'utils'
import styles from './TaskStatus.module.scss'

type TProps = {
  task: Task
}

export const TaskStatusIcon = ({ task }: TProps) => {
  const TaskIcon = Icon[task.status.icon || 'Calendar']
  return <TaskIcon className={cx(styles.statusIcon, styles[task.status.value])} />
}
