// @ts-strict
import { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { Button, Icon } from 'components'
import { cx } from 'utils'
import styles from './ViewMore.module.scss'
import { getScrollHeight } from './getScrollHeight'

type Props = {
  children: ReactNode
  height?: number
  label?: string
  onChange?: (v: boolean) => any
  withIcon?: boolean
}

export const ViewMore = ({ children, height = 72, label, onChange, withIcon = false }: Props) => {
  const contentRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [scrollHeight, setScrollHeight] = useState(getScrollHeight(contentRef, height))
  const canViewMore = scrollHeight > height

  const toggleOpen = () => {
    const value = !open
    setOpen(value)
    onChange?.(value)
  }

  useLayoutEffect(() => {
    setScrollHeight(getScrollHeight(contentRef, height))
  }, [children, height])

  return children ? (
    <div className={cx(!open && canViewMore ? styles.closed : null)}>
      <div
        className={styles.body}
        style={{
          maxHeight: open && scrollHeight ? `${scrollHeight / 10}rem` : `${height / 10}rem`
        }}
        ref={contentRef}
      >
        {children}
      </div>
      {canViewMore ? (
        <Button as="link" className={styles.viewMore} onClick={toggleOpen}>
          {label ? label : open ? 'View less' : 'View more'}
          {open && withIcon && <Icon.UpChevron />}
          {!open && withIcon && <Icon.DownChevron />}
        </Button>
      ) : null}
    </div>
  ) : null
}
