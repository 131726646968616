import { TModelFieldSetting } from '../ModelField'

export type TOrderFieldAttribute = 'createdAt' | 'source'

export type TOrderFieldSetting = {
  attr: TOrderFieldAttribute
} & TModelFieldSetting

export const orderFieldsSettings: TOrderFieldSetting[] = [
  { attr: 'createdAt', name: 'Date Received' },
  { attr: 'source', name: 'Source' }
]
