// @ts-strict
import {
  ButtonForm,
  CreateButton,
  FormField,
  TButtonFormContentsProps,
  TextArea,
  useButtonForm
} from 'components'
import { useAgentActivitiesController } from 'controllers'
import { Agent } from 'models'
import { validatePresence } from 'utils'

type TProps = {
  agent: Agent
  buttonComponent?: TButtonFormContentsProps['buttonComponent']
  buttonText?: TButtonFormContentsProps['buttonText']
  onOpenChangeModal?: (isOpen: boolean) => void
}

export const AddAgentActivityNoteButtonForm = ({
  agent,
  buttonComponent = CreateButton,
  buttonText,
  onOpenChangeModal
}: TProps) => {
  const { addAgentActivityNote } = useAgentActivitiesController(agent.id)

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm({
    mutation: addAgentActivityNote,
    initialPayload: {
      notes: ''
    },
    formValidations: {
      notes: [validatePresence]
    },
    onOpenChange: onOpenChangeModal
  })
  return (
    <ButtonForm
      title="Add Agent Note"
      buttonComponent={buttonComponent}
      buttonText={buttonText}
      {...formProps}
    >
      <FormField title="Note" required>
        <TextArea
          onChange={val => onChangeAttribute('notes', val)}
          name="note"
          autoComplete="off"
          value={payload.notes}
          data-testid="note-field"
        />
      </FormField>
    </ButtonForm>
  )
}
