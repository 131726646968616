// @ts-strict
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { List } from 'components'
import { AgentProviderLeadServicesOpportunitiesSelector } from 'content/AgentProviderLead'
import { useQueryClient } from 'hooks'
import { AgentMatchingLead, isAgentMatchingLead } from 'models'
import { cx } from 'utils'
import styles from './OrdersTable.module.scss'

type Props = {
  lead: AgentMatchingLead
}

export const OrdersTableRowLeadAgentsOpps = ({ lead }: Props) => {
  const queryCache = useQueryClient()
  const clearCache = () => queryCache.invalidateQueries(['orders'])

  if (isAgentMatchingLead(lead)) {
    return (
      <List className={styles.leadAgents}>
        {lead.agentProviderLeads
          ?.filter(apl => apl.agentLead?.introduced && apl.agentLead.agent?.firstName)
          .sort(
            (a, b) =>
              Number(b.stage === lead.stage) -
              Number(a.stage === lead.stage) -
              (Number(b.stage === 'failed') - Number(a.stage === 'failed'))
          )
          .map(apl => {
            const agent = apl.agentLead!.agent!

            return (
              <Fragment key={`${agent.id}_${apl.id}`}>
                <Link
                  className={cx(styles.leadAgentName, apl.stage === 'failed' ? styles.failed : '')}
                  to={`/agents/${agent.id}`}
                >
                  {agent.fullName}
                </Link>
                <AgentProviderLeadServicesOpportunitiesSelector
                  agentProviderLead={apl}
                  onChange={clearCache}
                />
              </Fragment>
            )
          })}
      </List>
    )
  }
  return null
}
