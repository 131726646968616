import { CashOfferProviderLead, CCCashOfferLead } from 'models'
import { CashOfferDispositionProvider } from './CashOfferDispositionContext'
import { CashOfferLeadDispositionForm } from './CashOfferLeadDispositionForm'

type Props = {
  lead: CCCashOfferLead
  onCancel: () => void
  providerLead: CashOfferProviderLead
}

export const CashOfferDisposition = ({ lead, providerLead, onCancel }: Props) => {
  return (
    <CashOfferDispositionProvider lead={lead} providerLead={providerLead}>
      <CashOfferLeadDispositionForm lead={lead} providerLead={providerLead} onCancel={onCancel} />
    </CashOfferDispositionProvider>
  )
}
