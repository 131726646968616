// @ts-strict
import { useButtonForm } from 'components'
import { UseMutationResult } from 'hooks'
import { EscrowLead } from 'models'
import { TPostEditEscrowOrderPayload } from 'services'
import { TEditEscrowOrderPayload } from './types'

const initialPayload: TEditEscrowOrderPayload = {
  address: '',
  asmId: '',
  city: '',
  newStage: '',
  orderIdentifier: '',
  parentLeadId: '',
  referringOwnerId: '',
  secondaryAddress: '',
  secondaryUserType: '',
  serviceOfficeId: '',
  srmId: '',
  stateCode: '',
  transactionCoordinatorEmail: '',
  transactionCoordinatorName: '',
  zipCode: ''
}

const getLeadUserId = (lead: EscrowLead, role: string) =>
  lead?.leadUsers?.find(leadUser => leadUser.role === role)?.user?.id

const initializePayload = (lead: EscrowLead) => {
  return {
    ...initialPayload,
    fullAddress: lead.fullAddress,
    address: lead.masterProperty?.primary_line,
    asmId: getLeadUserId(lead, 'agent_success_manager'),
    city: lead.masterProperty?.city_name,
    orderIdentifier: lead.escrowOfficeProviderLead?.escrowOfficeLead?.orderIdentifier,
    parentLeadId: lead.parentLead?.id,
    referringOwnerId: lead.referringOwner?.id || lead.referringAgent?.id,
    secondaryUserType: lead.secondaryUserType,
    serviceOfficeId: lead.escrowOfficeProviderLead?.escrowOfficeLead?.serviceOffice?.id,
    srmId: getLeadUserId(lead, 'strategic_relationship_manager'),
    stateCode: lead.masterProperty?.state_name,
    transactionCoordinatorEmail:
      lead.escrowOfficeProviderLead?.escrowOfficeLead?.transactionCoordinatorEmail,
    transactionCoordinatorName:
      lead.escrowOfficeProviderLead?.escrowOfficeLead?.transactionCoordinatorName,
    zipCode: lead.masterProperty?.zip_code
  }
}

type TEditEscrowOrderFormParams = {
  lead: EscrowLead
  mutation: UseMutationResult<string, unknown, TEditEscrowOrderPayload, unknown>
  onSubmit: () => void
}

export const useEditEscrowOrderForm = ({
  mutation,
  onSubmit,
  lead
}: TEditEscrowOrderFormParams) => {
  const transformPayload = (payload: TEditEscrowOrderPayload): TPostEditEscrowOrderPayload => {
    return { ...payload }
  }

  const {
    formProps,
    fieldsProps: { payload, onChangeAttribute }
  } = useButtonForm<TEditEscrowOrderPayload, string>({
    initialPayload: initializePayload(lead),
    mutation,
    callback: onSubmit,
    formValidations: {},
    transformPayload
  })

  return {
    onChangeAttribute,
    formProps,
    payload
  }
}
