import React, { ChangeEventHandler } from 'react'
import { cx, randomString } from 'utils'
import { CheckBox } from './CheckBox'
import styles from './Form.module.scss'
import { FormField } from './FormField'
import { FormFieldProps } from './FormField/types'

type Props = {
  checked: Potential<boolean>
  className?: string
  disabled?: boolean
  name?: string
  onChange: ChangeEventHandler<HTMLInputElement>
}
export const CheckBoxField = ({
  onChange,
  checked = false,
  name,
  className,
  disabled,
  description,
  title,
  ...props
}: Props & FormFieldProps) => {
  const id = randomString()
  return (
    <FormField
      className={cx(className, styles.checkBoxField, !title && styles.checkBoxFieldNoLabel)}
      htmlFor={id}
      inline
      description={description}
      title={title}
      {...props}
    >
      <CheckBox checked={checked} name={name} id={id} onChange={onChange} disabled={disabled} />
    </FormField>
  )
}
