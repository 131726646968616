// @ts-strict
import { Button } from '@foundation/components'
import { CheckBoxArray, Column, Columns, MenuSection, Reorderable, Section } from 'components'
import { ListFilters } from 'templates'
import { titleize, toSnakeCase } from 'utils'
import styles from './ViewsMenu.module.scss'
import { useViewsMenu } from './useViewsMenu'

export const ViewsMenu = ({ columnsClassname }: { columnsClassname?: string }) => {
  const {
    isOpenPopover,
    setIsOpenPopover,
    applyColumns,
    canApply,
    canReset,
    optionalColumnsFromLookupByGroup,
    displayedColumns,
    resetColumns,
    selectColumns,
    setSelectedColumns,
    selectedColumns
  } = useViewsMenu()

  const columnKeys = Object.keys(optionalColumnsFromLookupByGroup)

  return (
    <ListFilters.Root isOpen={isOpenPopover} onOpenChange={setIsOpenPopover}>
      <ListFilters.Trigger>
        <ListFilters.TriggerButton iconRight="TableChartOutlined">
          Columns
        </ListFilters.TriggerButton>
      </ListFilters.Trigger>
      <ListFilters.Content>
        <Section className={styles.section}>
          <Columns className={columnsClassname} hasFixedHeight>
            {columnKeys.map(
              key =>
                optionalColumnsFromLookupByGroup[key] &&
                optionalColumnsFromLookupByGroup[key].length > 0 && (
                  <Column className={styles.column} key={key}>
                    <MenuSection title={titleize(toSnakeCase(key))}>
                      <CheckBoxArray
                        options={optionalColumnsFromLookupByGroup[key]}
                        value={displayedColumns}
                        onChange={selectColumns}
                      />
                    </MenuSection>
                  </Column>
                )
            )}
            <Column className={styles.column}>
              <MenuSection title="Selected Columns">
                <Reorderable.Provider>
                  <Reorderable.List onChange={setSelectedColumns} options={selectedColumns} />
                </Reorderable.Provider>
              </MenuSection>
            </Column>
          </Columns>
          <ListFilters.Footer>
            <Button onClick={applyColumns} isDisabled={!canApply}>
              Apply
            </Button>
            {canReset ? (
              <Button variant="ghost" color="neutralLight" onClick={resetColumns}>
                Reset to defaults
              </Button>
            ) : null}
          </ListFilters.Footer>
        </Section>
      </ListFilters.Content>
    </ListFilters.Root>
  )
}
