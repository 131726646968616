import { Badge } from 'components/Badge'
import { Message } from 'models'
import { format, initializeString } from 'utils'
import styles from './MessageListItem.module.scss'

type TProps = {
  message: Message
}

export const MessageListItem = ({ message }: TProps) => {
  return (
    <li className={styles.message}>
      <div className={styles.aside}>
        <Badge className={message.outbound ? styles.homeLight : null} stroke>
          {message.outbound ? 'HL' : initializeString(message.provider.fullName)}
        </Badge>
      </div>
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.name}>
            {message.outbound ? 'HomeLight' : message.provider.fullName}
          </div>
          <div className={styles.dateTime}>
            <span className={styles.time}>{format.date(message.createdAt, 'h:mm b')}</span>
          </div>
        </div>
        <div className={styles.body}>{message.body}</div>
      </div>
    </li>
  )
}
