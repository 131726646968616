// @ts-strict

import { useMutation, useQuery, useQueryClient } from 'hooks'
import {
  deleteSubTeamMembership,
  deleteTeamMembership,
  fetchTeam,
  postSubTeamMembership,
  PostSubTeamMembershipArgs,
  postTeamMembership,
  PostTeamMembershipArgs,
  putAgent,
  putTeam
} from 'services'

export const useTeamController = (teamId: string) => {
  const queryCache = useQueryClient()

  const { data: team } = useQuery(['teams', teamId], () => fetchTeam(teamId))

  const defaultCallback = {
    onSuccess: () => queryCache.invalidateQueries('teams')
  }
  const editTeam = useMutation(
    (payload: { title: string }) => putTeam(teamId, payload),
    defaultCallback
  )
  const addTeamMember = useMutation(
    (payload: PostTeamMembershipArgs) => postTeamMembership(teamId, payload),
    defaultCallback
  )
  const addSubTeamMember = useMutation(
    (payload: PostSubTeamMembershipArgs) => postSubTeamMembership(payload),
    defaultCallback
  )
  const removeSubTeamMember = useMutation(
    (id: string) => deleteSubTeamMembership(id),
    defaultCallback
  )

  const removeTeamMember = useMutation((id: string) => deleteTeamMembership(id), defaultCallback)
  const addAgent = useMutation(
    ({ agentId }: { agentId: string }) => putAgent(agentId, { transactionTeamId: teamId }),
    defaultCallback
  )
  const removeAgent = useMutation(
    (id: string) => putAgent(id, { transactionTeamId: '' }),
    defaultCallback
  )

  const addAgentToSettlementAgencyTeam = useMutation(
    ({ agentId }: { agentId: string }) => putAgent(agentId, { settlementAgencyTeamId: teamId }),
    defaultCallback
  )
  const removeAgentFromSettlementAgencyTeam = useMutation(
    (id: string) => putAgent(id, { settlementAgencyTeamId: '' }),
    defaultCallback
  )

  return {
    teamId,
    team,
    editTeam,
    addTeamMember,
    addAgent,
    removeTeamMember,
    removeAgent,
    addAgentToSettlementAgencyTeam,
    removeAgentFromSettlementAgencyTeam,
    addSubTeamMember,
    removeSubTeamMember
  }
}
