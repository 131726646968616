// @ts-strict
import { LenderProviderLead } from 'models'

type TLoanApplicationsByStatus = {
  active: LenderProviderLead[]
  archived: LenderProviderLead[]
}

export const getLoanApplicationsByStatus = (lenderProviderLeads: LenderProviderLead[]) => {
  return lenderProviderLeads.reduce(
    (acc: TLoanApplicationsByStatus, lenderProviderLead: LenderProviderLead) => {
      const lenderLead = lenderProviderLead.lenderLead
      if (!lenderLead) {
        return acc
      }
      const status = lenderLead?.archived ? 'archived' : 'active'
      acc[status].push(lenderProviderLead)
      return acc
    },
    { active: [], archived: [] }
  )
}
