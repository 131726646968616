export const thisLeadTabPaths = [
  'trade-ins',
  'cash-offers',
  'buyers',
  'sellers',
  'mortgages',
  'escrows',
  'hlss'
]

export const thisLeadTabPathsToMatch = thisLeadTabPaths.map(path => ({
  path: `/${path}/:leadId/*`
}))

export const thisAgentTabPathsToMatch = ['agents'].map(path => ({
  path: `/${path}/:agentId/*`
}))
