import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { formatPageUrlToTitle } from './formatPageUrlToTitle'
import { Tracking } from './types'

export const useCustomEvents = () => {
  const location = useLocation()

  const tracking = useCallback(
    ({ name, ...rest }: Tracking) => {
      let params

      if ('params' in rest) {
        params = rest.params
      }

      window.gtag('event', name, {
        page_title: formatPageUrlToTitle(location.pathname),
        ...params
      })
    },
    [location]
  )

  return { tracking }
}
