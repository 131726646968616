// @ts-strict
import { SearchStatus } from 'components/SearchStatus/index'
import { SearchSummary } from 'components/SearchSummary/index'
import { useAgentsContext } from 'contexts/Agents'
import { useParamsContext } from 'contexts/Params'
import { objectMatch, presence } from 'utils'

export const AgentsListStatus = () => {
  const { initialParams, initializeParams, params, resetParams } = useParamsContext()
  const { totalCount, isLoading } = useAgentsContext()

  if (presence(initialParams)) {
    const canReset = !isLoading && !objectMatch(initialParams, params)

    return (
      <SearchSummary
        status={
          <SearchStatus
            params={params}
            count={totalCount}
            type="Agents"
            unfilteredText="Unfiltered"
          />
        }
        onReset={canReset ? initializeParams : undefined}
      />
    )
  }

  const canClear = Object.keys(params).length

  return (
    <SearchSummary
      status={<SearchStatus params={params} count={totalCount} type="Agent" />}
      onClear={canClear ? resetParams : undefined}
    />
  )
}
