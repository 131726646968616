import { HTMLAttributes, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Flex, Text } from '@foundation/components'
import { ActivityFeedInterface, ActivityFeedModel, UserResource } from 'models/v2/ActivityFeed'
import { ViewDetailsButton } from '../../ViewDetailsButton/ViewDetailsButton'
import { CommsEventRow } from '../CommsEventRow'
import { EmailBody } from './styles'

type Props = {
  activityFeed: ActivityFeedInterface
  rowIndex?: number
} & Pick<HTMLAttributes<HTMLDivElement>, 'onClick'>

const UserResourceLink = ({ userResource }: { userResource: UserResource }) => {
  const resourceUrl = ActivityFeedModel.getResourceUrl(userResource)
  const name = ActivityFeedModel.getNameFromUserResource(userResource)
  const userTypeString = ActivityFeedModel.getFormattedUserTypeString(userResource)

  if (!resourceUrl) {
    return <Text size="xs">{`${name} ${userTypeString}(${userResource?.email?.address})`}</Text>
  }

  return (
    <Flex>
      <Link to={resourceUrl || '#'} role="heading" aria-level={3}>
        <Text size="xs" color="primary9" weight="semibold" data-testid={`userResourceName-${name}`}>
          {name}
        </Text>
      </Link>
      &nbsp;
      <Text
        size="xs"
        data-testid={`userResourceInfo-${name}`}
      >{` ${userTypeString}(${userResource?.email?.address})`}</Text>
    </Flex>
  )
}

export const CommsEmailEventRow = ({ rowIndex, activityFeed }: Props) => {
  const [isViewDetailsEnabled, setIsViewDetailsEnabled] = useState(false)

  const originalSenderUrl = ActivityFeedModel.getResourceUrl(activityFeed.meta.senderDetails)
  const senderName = ActivityFeedModel.getNameFromUserResource(activityFeed?.meta?.senderDetails)

  return (
    <CommsEventRow
      date={activityFeed.occurredAt}
      iconPath="EmailOutlined"
      data-testid="commsEmailEventRow"
      rowIndex={rowIndex}
    >
      <div>
        <Text size="xs" as="span" weight="bold">
          {senderName}
        </Text>
        <Text size="xs" as="span">
          {` emailed `}
        </Text>
        <Text size="xs" as="span" weight="bold">
          {ActivityFeedModel.getRecipientsString(
            (activityFeed.meta.recipientsDetails || [])?.map(
              ActivityFeedModel.getNameFromUserResource
            )
          )}
        </Text>
      </div>

      {isViewDetailsEnabled && (
        <>
          <Flex flexDirection="column" gap="xs">
            <Text size="xs" color="neutral8">
              Sender
            </Text>

            {originalSenderUrl && activityFeed.meta.senderDetails ? (
              <UserResourceLink userResource={activityFeed.meta.senderDetails} />
            ) : (
              <Text size="xs" color="primary10">
                {`${senderName || 'Someone'} (${activityFeed.meta.senderDetails?.email?.address})`}
              </Text>
            )}
          </Flex>

          <Flex flexDirection="column" gap="xs">
            <Text size="xs" color="neutral8">
              Recipients
            </Text>

            {activityFeed.meta.recipientsDetails?.map((recipient, idx) => (
              <UserResourceLink userResource={recipient} key={idx} />
            ))}
          </Flex>
        </>
      )}

      {activityFeed.title && (
        <Flex flexDirection="column" gap="xs">
          <Text size="xs" color="neutral8">
            Subject
          </Text>
          <Box marginBottom="$2">
            <Text size="xs" weight="bold">
              {activityFeed.title}
            </Text>
          </Box>
        </Flex>
      )}

      {activityFeed.description && (
        <>
          {isViewDetailsEnabled && (
            <>
              <Text size="xs" color="neutral8">
                Content
              </Text>

              <EmailBody dangerouslySetInnerHTML={{ __html: activityFeed.description }}></EmailBody>
            </>
          )}
        </>
      )}

      <div>
        <ViewDetailsButton
          onClick={() => setIsViewDetailsEnabled(state => !state)}
          isToggledOn={isViewDetailsEnabled}
        />
      </div>
    </CommsEventRow>
  )
}
