// @ts-strict

import { useState } from 'react'
import {
  Popup,
  PopupPropTypes,
  SectionHeader,
  SectionTitle,
  TabbedContent,
  ViewMore
} from 'components'
import { LeadsTable } from 'content/Leads'
import { ListProvider } from 'contexts'
import { attachLeadsListColumns } from 'lookups'
import { Lead, Order } from 'models'
import { AttachLeadCreateLeadTab } from '../AttachLeadCreateLeadTab'
import { AttachLeadList } from '../AttachLeadList'
import { AttachLeadAction } from './AttachLeadAction'
import styles from './AttachLeadPopup.module.scss'

type TProps = {
  order: Order
  selectedLead: Lead
} & Pick<PopupPropTypes, 'onCancel'>

export const AttachLeadPopup = ({ onCancel, order, selectedLead }: TProps) => {
  const [showAll, setShowAll] = useState(false)
  const orderLeads: Lead[] = [selectedLead].concat(
    order.leads?.filter(leadItem => leadItem.id !== selectedLead.id) || []
  )

  const ActionComponent = ({ lead: rowLead }: { lead: Lead }) => (
    <AttachLeadAction lead={rowLead} order={order} selectedLeadId={selectedLead.id} />
  )

  const content = {
    'Find Lead': <AttachLeadList order={order} />,
    'Create Lead': <AttachLeadCreateLeadTab selectedLead={selectedLead} order={order} />
  }

  return (
    <Popup onCancel={onCancel} title="Attach Leads to Order">
      <div className={styles.content}>
        <ListProvider
          columns={attachLeadsListColumns}
          actions={ActionComponent}
          highlightRows={[selectedLead.id]}
        >
          <SectionHeader>
            <SectionTitle>Current Leads</SectionTitle>
          </SectionHeader>
          {orderLeads.length > 1 ? (
            <ViewMore
              height={85}
              label={
                (showAll ? 'Hide' : 'Show') +
                ` Additional Leads (${order.leads && order.leads.length - 1})`
              }
              onChange={setShowAll}
              withIcon
            >
              <LeadsTable
                data-testid="currentLeadsTable"
                className={styles.currentLeadsList}
                isLoading={false}
                leads={orderLeads}
              />
            </ViewMore>
          ) : (
            <LeadsTable
              className={styles.currentLeadsList}
              isLoading={false}
              leads={orderLeads}
              data-testid="currentLeadsTable"
            />
          )}
          <TabbedContent content={content} />
        </ListProvider>
      </div>
    </Popup>
  )
}
