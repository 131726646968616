import { ReactNode } from 'react'
import { Button } from 'components/Button'
import { cx } from 'utils'
import styles from './SearchSummary.module.scss'

type Props = {
  className?: string
  onClear?: () => void
  onReset?: () => void
  status: ReactNode
}

export const SearchSummary = ({ status, className, onClear, onReset }: Props) => {
  return (
    <div className={cx(className, styles.searchSummary)} data-testid="searchSummary">
      {status}
      {onClear ? (
        <Button as="link" onClick={onClear} className={styles.clearButton}>
          Clear
        </Button>
      ) : null}
      {onReset ? (
        <Button as="link" onClick={onReset} className={styles.resetButton}>
          Reset
        </Button>
      ) : null}
    </div>
  )
}
