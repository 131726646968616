import { useChangeReasons, useFailedReasons } from 'lookups'
import { byAttr } from 'utils'
import { CcTradeInLeadField } from './CcTradeInLeadField'
import {
  ccTradeInLeadFieldsSettings,
  TCcTradeInLeadFieldAttribute
} from './ccTradeInLeadFieldsSettings'

export const useCcTradeInLeadFields = () => {
  const { tradeInFailedReasons } = useFailedReasons()
  const { coeDateChangeReasons } = useChangeReasons()

  const optionLoaders = {
    tradeInFailedReasons,
    coeDateChangeReasons
  }

  const lookupCcTradeInLeadFields = ccTradeInLeadFieldsSettings.map(setting => {
    let options = setting.options
    if (setting.getOptions) {
      options = optionLoaders[setting.getOptions]
    }
    return CcTradeInLeadField.createFromSetting({ ...setting, options })
  })

  const getCcTradeInLeadField = (attr: TCcTradeInLeadFieldAttribute) => {
    return lookupCcTradeInLeadFields.find(byAttr('attr', attr))
  }

  const getCcTradeInLeadFieldName = (attr: TCcTradeInLeadFieldAttribute) => {
    return getCcTradeInLeadField(attr)?.name
  }

  return { lookupCcTradeInLeadFields, getCcTradeInLeadField, getCcTradeInLeadFieldName }
}
