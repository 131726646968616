import { Investor } from 'models'
import { getHapiRequestParams, InvestorSchema } from 'schema'
import { getInvestorsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const investorPayPerLeadSettingsShape: InvestorSchema = {
  investor: [
    'id',
    {
      pplProviderSettings: ['active', 'areaType', 'location', 'price', 'userType']
    },
    {
      pplBillingInfos: ['monthlyCap', 'buyerMonthlyCap', 'billingCycleStartDate']
    }
  ]
}

export const fetchInvestorPayPerLeadSettings = async (investorId: string) => {
  const url = getInvestorsUrl({
    id: investorId,
    searchParams: getHapiRequestParams(investorPayPerLeadSettingsShape)
  })

  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: Investor.create })
}
