import { useMemo, useState } from 'react'
import { LEADS_CACHE_KEY, useQuery, UseQueryOptions } from 'hooks'
import { LeadInterface } from 'models/v2/Lead'
import { getHapiRequestParams, LeadsSchema } from 'schema'
import { TFetchItems } from 'services'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest, removeAttributesWithNullValues } from 'utils'

type QueryOptions = UseQueryOptions<
  TFetchItems<LeadInterface>,
  unknown,
  TFetchItems<LeadInterface>,
  any
>

type Settings = {
  searchTerm?: string
}

const clientsShape: LeadsSchema = {
  leads: ['name', 'email', { order: ['id'] }]
}

const fetchClientsAutoComplete = async <Response extends LeadInterface>({
  searchTerm
}: Settings): Promise<TFetchItems<Response>> => {
  const shapeParams = getHapiRequestParams(clientsShape)
  const filterParams = removeAttributesWithNullValues({
    'page[size]': 8,
    'filter[_search]': searchTerm
  })

  const url = getLeadsUrl({
    searchParams: { ...shapeParams, ...filterParams }
  })

  const response = await getRequest(url)
  const deserializedResponse = await deserializeAsync<Response[]>(response.data)

  return {
    items: deserializedResponse,
    pageCount: 0,
    totalCount: response.data.meta.total_leads
  }
}

export const useClientsAutocomplete = (options: QueryOptions = {}) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>()
  const [isQueryEnabled, setIsQueryEnabled] = useState(options.enabled || false)
  const enabled = options.enabled ? isQueryEnabled : isQueryEnabled && searchTerm !== undefined

  const searchSettings: Settings = useMemo(
    () => ({
      searchTerm
    }),
    [searchTerm]
  )

  const { data, status } = useQuery(
    [LEADS_CACHE_KEY, 'autocomplete', searchSettings],
    () => fetchClientsAutoComplete(searchSettings),
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 300000, //5 min
      retry: 3
    }
  )

  const items = useMemo(() => {
    return (
      data?.items.map(item => {
        const value = item?.order?.id || item.id
        return {
          value,
          label: item.name
        }
      }) ?? []
    )
  }, [data])

  const autocompleteStatus = useMemo(() => {
    if (!data?.items.length && status === 'success') {
      return 'error'
    }

    return status
  }, [data, status])

  return {
    items,
    setSearchTerm,
    setIsQueryEnabled,
    status: autocompleteStatus
  }
}
