// @ts-strict
const BASE_URI = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
const ACCESS_TOKEN =
  'pk.eyJ1IjoiaG9tZWxpZ2h0IiwiYSI6ImNqNmZka24yNDJtd3QzMm8xYm1hbWFhcnIifQ.BT1EY0MP0qndOTsWsvv8FA'
export const createURI = (search: string, types: string, country: string, bias: Array<number>) =>
  [
    BASE_URI,
    encodeURIComponent(search),
    `.json?autocomplete=true&access_token=${ACCESS_TOKEN}`,
    `&country=${encodeURIComponent(country)}`,
    `&types=${encodeURIComponent(types)}`,
    (bias.length && `&proximity=${encodeURIComponent(bias.toString())}`) || ''
  ].join('')
