/* eslint-disable @typescript-eslint/no-use-before-define */
// @ts-strict

import { useEffect } from 'react'
import {
  DisplayReadOnlyValue,
  FormField,
  InlineEditMoneyInput,
  Section,
  Sections,
  Tag
} from 'components'
import { useLoanCalculator } from 'hooks/useLoanCalculator'
import { MortgageLead } from 'models'
import { dataOrDash, formatPrice } from 'utils'

type Props = {
  lead: MortgageLead
  updateCallReview: (key: string, value: string) => void
  updateField: (value: Record<string, string | number | Record<string, string>>) => void
}

const notEligibleBadge = () => {
  const errorList = (
    <ul>
      <li>Minimum Loan Amount should be more than 100K</li>
    </ul>
  )

  const tag = (
    <Tag as="danger" size="xsmall" tooltipText={errorList}>
      Not Eligible
    </Tag>
  )

  return tag
}

export const LoanAmountCalculator = ({ lead, updateCallReview, updateField }: Props) => {
  const { isEligible: isEligibleForLoan, loanAmount: minimumLoanAmount } = useLoanCalculator(lead)

  const handleDownpaymentChange = (value: string) => {
    updateCallReview('downpaymentAmount', value)
  }

  const handlePurchasePriceChange = (value: string) => {
    updateField({ price: value })
  }

  useEffect(() => {
    if (minimumLoanAmount !== null) {
      updateCallReview('minimumLoanAmount', String(minimumLoanAmount || 0))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumLoanAmount])

  return (
    <Sections
      title="Loan Amount Calculator"
      titleStyle={{ flex: 'none', marginRight: '10px' }}
      actions={isEligibleForLoan ? null : notEligibleBadge()}
    >
      <Section>
        <FormField title="IR Purchase Price" inline>
          <InlineEditMoneyInput onBlur={handlePurchasePriceChange} value={dataOrDash(lead.price)} />
        </FormField>
        <FormField title="Equity Unlock - Max" inline>
          <InlineEditMoneyInput readOnly value={dataOrDash(lead.callReview?.maxEquityUnlock)} />
        </FormField>
        <FormField title="Downpayment Amount" inline>
          <InlineEditMoneyInput
            onBlur={(value: string) => {
              handleDownpaymentChange(value)
            }}
            value={dataOrDash(lead.callReview?.downpaymentAmount)}
          />
        </FormField>
      </Section>
      <Section>
        <FormField title="Minimum Loan Amount" inline>
          <DisplayReadOnlyValue value={dataOrDash(minimumLoanAmount)} format={formatPrice} />
        </FormField>
      </Section>
    </Sections>
  )
}
