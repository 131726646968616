import { getTasksUrl } from 'services/getUrl'
import { putRequest } from 'utils'

export type TPutReopenTaskVariables = {
  id: string
}

export const putReopenTask = async ({ id }: TPutReopenTaskVariables) => {
  const url = getTasksUrl({ id, action: 'reopen' })
  await putRequest(url)
}
