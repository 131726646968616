import { toSnakeCase } from 'utils'
import { TEnrollmentStatus, TProgramKey } from '../types'

type TEnrollmentProgram = Potential<TProgramKey> | ''

const getCustomProgramKey = (program: TEnrollmentProgram): string => toSnakeCase(program)

export const getAgentProgramFilter = (
  enrollmentProgram: TEnrollmentProgram,
  enrollmentStatus: Potential<TEnrollmentStatus>
) => {
  const filters = {}
  const prop = getCustomProgramKey(enrollmentProgram)

  if (enrollmentStatus === 'pending') {
    filters[`filter[marketplace_programs.pending_${prop}]`] = true
  } else if (enrollmentStatus === 'enrolled') {
    filters[`filter[marketplace_programs.enrolled_${prop}]`] = true
  } else if (enrollmentStatus === 'declined') {
    filters[`filter[marketplace_programs.declined_${prop}]`] = true
  } else if (enrollmentStatus === 'unenrolled') {
    filters[`filter[marketplace_programs.enrolled_${prop}]`] = 'false'
    filters[`filter[marketplace_programs.declined_${prop}]`] = 'false'
  }

  return filters
}
