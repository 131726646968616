// @ts-strict
import { Block, DownloadButton, TableCell as TD, Tooltip, TableRow as TR } from 'components'
import { useListContext } from 'contexts'
import { FileUpload } from 'models'
import { formatDate } from 'utils'

type TProps = { file: FileUpload }

export const FilesTableRow = ({ file }: TProps) => {
  const { showColumn } = useListContext()
  const address = file.fileAttachment?.subAttachable?.incomingMasterProperty?.displayAddress
  return (
    <TR>
      {showColumn('name') && (
        <TD colSpan={showColumn('incomingResidence') ? 2 : 4}>
          <Block
            title={
              <DownloadButton url={file?.fileAttachment?.downloadUrl}>
                <Tooltip>{file.name}</Tooltip>
              </DownloadButton>
            }
          />
        </TD>
      )}
      {showColumn('incomingResidence') && (
        <TD colSpan={2}>
          <Block title={<Tooltip>{address}</Tooltip>} />
        </TD>
      )}
      {showColumn('createdAt') && (
        <TD colSpan={1}>
          <Block title={<Tooltip>{formatDate(file.createdAt)}</Tooltip>} />
        </TD>
      )}
    </TR>
  )
}
