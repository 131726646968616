// @ts-strict

import { TableHeaderRow, TableHeaderCell as TH } from 'components'
import styles from './AgentProgramEligibilityTable.module.scss'

export const AgentProgramEligibilityTableHeader = () => {
  return (
    <TableHeaderRow className={styles.header}>
      <TH className={styles.program}>Program</TH>
      <TH className={styles.eligibility}>Eligibility</TH>
    </TableHeaderRow>
  )
}
