import { Lead } from 'models'
import { uploadToS3 } from '../utils'
import { postFileToDocuments } from './postFileToDocuments'

export type TPostFileUploadPayload = {
  category?: 'other'
  files: File[]
  lead: Lead
  name: string
  subAttachableId?: string
  subAttachableType?: string
}

const getExtension = (f: File) => f?.name.split('.').pop()

export const uploadFile = async (
  leadId: string,
  { subAttachableId, subAttachableType, files, name, category }: TPostFileUploadPayload
) => {
  const file = files[0]
  const [presignResponse] = await uploadToS3(files)

  return postFileToDocuments({
    attachableId: leadId,
    attachableType: 'Lead',
    category,
    files: [
      {
        name: `${name}.${getExtension(file)}`,
        storageKey: presignResponse.storageKey,
        fileType: 'document'
      }
    ],
    subAttachableId: subAttachableId,
    subAttachableType: subAttachableType
  })
}
