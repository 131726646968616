import { ChangeEvent } from 'react'
import { RoundImage } from 'components/RoundImage'
import { PhoneNumberResponse, StateResponse } from 'schema'
import { randomImage } from 'utils'

type TAgentSearchResponse = {
  email1: string
  email2: string
  firstName: string
  id: string
  lastName: string
  officeDisplayName: string
  phoneNumbers: PhoneNumberResponse[]
  pictureThumbUrl: string
  state: StateResponse
}
export const transformAgentsAutoComplete = (agent: TAgentSearchResponse) => {
  const email = agent.email1 ? ` <${agent.email1}>` : ''

  const useDefaultImage = (e: ChangeEvent<HTMLImageElement>) => {
    e.currentTarget.src = randomImage(+agent.id, 50)
  }
  return {
    id: agent.id,
    image: RoundImage({
      src: agent.pictureThumbUrl,
      onError: useDefaultImage,
      alt: `${agent.firstName} ${agent.lastName} thumbnail`
    }),
    title: `${agent.firstName} ${agent.lastName}${email}`,
    subTitle: `${agent.officeDisplayName}, ${agent.state?.code}`,
    name: `${agent.firstName} ${agent.lastName}`,
    email: agent.email1,
    phoneNumbers: agent.phoneNumbers
  }
}
