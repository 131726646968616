import { css, styled } from '@foundation/themes/styles'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.$3};
    &:hover {
      background-color: ${theme.colors.neutral1};
    }
  `}
`

export const Row = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    font-size: ${theme.fontSizes.sm};
    cursor: pointer;
  `}
`
