import { MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { useDrawerContext } from '@foundation/components'
import { Block } from 'components'
import { ActivityFeedInterface } from 'models/v2/ActivityFeed'
import { AgentModel } from 'models/v2/Agent'
import { dataOrDash } from 'utils'
import { ActivityEventRow } from '../ActivityEventRow'

type Props = {
  activityFeed: ActivityFeedInterface
  'data-testid'?: string
}

export const ActivityAgentEventRow = ({ activityFeed, ...rest }: Props) => {
  const { closeDrawer } = useDrawerContext()
  const agentName = dataOrDash(AgentModel.getFullName(activityFeed?.agent!))

  const handleClickLink = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation()
    closeDrawer()
  }

  return (
    <ActivityEventRow
      {...rest}
      title={
        <Block
          title={
            <Link to={AgentModel.getPathname(activityFeed.agent!.id)} onClick={handleClickLink}>
              {agentName}
            </Link>
          }
        />
      }
      date={activityFeed.occurredAt}
      description={activityFeed.title}
      meta={activityFeed.meta}
    />
  )
}
