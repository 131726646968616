// @ts-strict
import { useQuery } from 'hooks'
import { fetchAgentAeAreas } from 'services'
import { getPageCount } from 'utils'
import { AGENT_AE_AREA_CACHE_KEY } from '../index'

export const useAgentAeAreasController = (searchParams = {}) => {
  const params = {
    perPage: 25,
    ...searchParams
  }

  const defaultCallback = {
    refetchOnWindowFocus: false
  }

  const { data, isLoading } = useQuery(
    [AGENT_AE_AREA_CACHE_KEY, params],
    () => fetchAgentAeAreas(params),
    defaultCallback
  )
  const totalCount = data?.agentAeAreasCount
  const pageCount = getPageCount(totalCount, params.perPage)

  const agentAeAreasQuery = {
    ...data,
    isLoading,
    pageCount
  }

  return {
    agentAeAreasQuery
  }
}
