// @ts-strict
import { ButtonForm, useButtonForm } from 'components'
import { EditIconButton } from 'components/Button/IconButton'
import { useMutation, useQueryClient } from 'hooks'
import { LoanOfficer } from 'models'
import { putLoanOfficer } from 'services/LoanOfficers/putLoanOfficer'
import { validateEmailFormat, validatePhoneFormat, validatePresence } from 'utils'
import { LoanOfficerFormFields } from './LoanOfficerFormFields'
import { TLoanOfficerFormPayload } from './utils'

type TProps = {
  loanOfficer: LoanOfficer
}

export const EditLoanOfficerButtonForm = ({ loanOfficer }: TProps) => {
  const queryCache = useQueryClient()
  const updateLoanOfficer = useMutation((data: {}) => putLoanOfficer(loanOfficer.id, data), {
    onSuccess: () => queryCache.invalidateQueries('loan-officers')
  })

  const { formProps, fieldsProps } = useButtonForm<TLoanOfficerFormPayload, string>({
    initialPayload: {
      loanOfficerName: loanOfficer.name,
      loanOfficerEmail: loanOfficer.email,
      loanOfficerPhone: loanOfficer?.phoneNumber?.number,
      companyName: loanOfficer.companyName,
      loanOfficerNmlsId: loanOfficer.nmlsId,
      loanOfficerTransactionTeamId: loanOfficer.transactionTeam?.id || ''
    },
    mutation: updateLoanOfficer,
    formValidations: {
      loanOfficerName: validatePresence,
      loanOfficerEmail: validateEmailFormat,
      loanOfficerPhone: validatePhoneFormat
    }
  })

  return (
    <ButtonForm
      {...formProps}
      isFormValid={formProps.isFormValid}
      title="Edit Loan Officer"
      buttonComponent={props => <EditIconButton aria-label="edit" onClick={props.onClick} />}
    >
      <LoanOfficerFormFields {...fieldsProps} isEditing />
    </ButtonForm>
  )
}
