// @ts-strict
import { useParamsContext } from 'contexts/Params'
import { useMap, useUpdateEffect } from 'hooks'

export const useNewFilters = <S extends object = any>() => {
  const { params } = useParamsContext()

  const [newParams, { set, setAll, remove }] = useMap<S>(params)

  useUpdateEffect(() => {
    setAll(params)
  }, [params])

  return {
    newParams,
    remove,
    set
  }
}
