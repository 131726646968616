// @ts-strict
import { useRef } from 'react'

type THoverHandlerParams = {
  enterDelay?: number
  leaveDelay?: number
  onEnter: Function
  onLeave: Function
}

/**
 * A hook to timeout-enabled hover events (so you don't depend on CSS for hover to not disappear when hovering over different components)
 * @constructor
 * @param {Function} onEnter - A callback that will be triggered when the mouse enters the referenced space
 * @param {Function} onLeave - A callback that will be triggered when the mouse leaves the referenced space
 * @param {number} leaveDelay - The time until onLeave is called after you are no longer hovering
 * @param {number} enterDelay - The time until onEnter is called after you start hovering
 * @return The callbacks that will be used inside the HTML tags with the onMouseEnter and onMouseLeave events
 */
// before it calls onMouseLeave. great for when there's gaps
// between a parent element and its children!
export const useHoverHandler = ({
  onEnter,
  onLeave,
  leaveDelay,
  enterDelay = 0
}: THoverHandlerParams) => {
  const enterTimeout = useRef<Potential<NodeJS.Timeout>>()
  const leaveTimeout = useRef<Potential<NodeJS.Timeout>>()

  const onMouseEnter = () => {
    enterTimeout.current = setTimeout(() => onEnter(), enterDelay)

    if (leaveTimeout.current) {
      clearTimeout(leaveTimeout.current)
    }
  }

  const onMouseLeave = () => {
    if (enterTimeout.current) {
      clearTimeout(enterTimeout.current)
    }

    leaveTimeout.current = setTimeout(() => {
      onLeave()
    }, leaveDelay || 200)
  }

  return { onMouseEnter, onMouseLeave }
}
