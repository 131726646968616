// @ts-strict
import { Button } from '@foundation/components'
import { ButtonForm, FormField, TextArea } from 'components'
import { useLeadNewSalesNoteButtonForm } from '.'

export const LeadNewSalesNoteButtonForm = () => {
  const { formProps, onChangeAttribute, payload } = useLeadNewSalesNoteButtonForm()

  return (
    <>
      <Button variant="outline" size="xs" color="neutralLight" onClick={formProps.toggleShowForm}>
        New Note
      </Button>
      <ButtonForm hideTriggerButton title="New Sales Note" {...formProps}>
        <FormField title="Note" htmlFor="salesNotes">
          <TextArea
            id="salesNotes"
            value={payload.salesNotes}
            onChange={val => onChangeAttribute('salesNotes', val)}
          />
        </FormField>
      </ButtonForm>
    </>
  )
}
