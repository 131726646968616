import { byAttr } from 'utils'
import {
  serviceOfficeFieldsSettings,
  TServiceOfficeFieldAttribute
} from './serviceOfficeFieldsSettings'
import { ServiceOfficeField } from './'

export const serviceOfficeFields = serviceOfficeFieldsSettings.map(setting => {
  return new ServiceOfficeField(setting)
})

export const getServiceOfficeField = (attr: TServiceOfficeFieldAttribute) => {
  return serviceOfficeFields.find(byAttr('attr', attr))
}

export const getServiceOfficeFieldName = (attr: TServiceOfficeFieldAttribute) => {
  return getServiceOfficeField(attr)?.name
}
