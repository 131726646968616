import { useState } from 'react'
import { Tooltip } from '@foundation/components'
import {
  FormField,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  InlineEditDollarsInput,
  Section,
  Sections
} from 'components'
import { LeadSensitiveInfoModal } from 'content/Lead/LeadSensitiveInfoModal'
import { useFeatureFlags } from 'hooks'
import { useCcTradeInLeadFields } from 'lookups'
import { formatPrice } from 'utils'
import { CCTradeInLeadDetailsProps } from '../CCTradeInLeadDetails'
import { getTradeInLeadInputProps } from './utils'

type Props = Pick<CCTradeInLeadDetailsProps, 'lead' | 'updateTradeInLead'>

export const DRPropertyValuationSection = ({ lead, updateTradeInLead }: Props) => {
  const tradeInLead = lead.tradeInProviderLead.tradeInLead

  const { getProps } = getTradeInLeadInputProps(tradeInLead, updateTradeInLead)
  const { getCcTradeInLeadFieldName } = useCcTradeInLeadFields()

  const { salesAppImprovement } = useFeatureFlags(['sales-app-improvement'])

  const [openSensitiveFields, setSensitiveFields] = useState({
    dpAnalystValuation: false,
    departingPropertyValuation: false
  })
  const toggleIsSensitiveInfoOpen = (field: keyof typeof openSensitiveFields, open?: boolean) =>
    setSensitiveFields({
      ...openSensitiveFields,
      [field]: open !== undefined ? open : !openSensitiveFields[field]
    })

  const departingPropertyValuationValue = formatPrice(tradeInLead.departingPropertyValuation)

  return (
    <Sections title="DR Property Valuation">
      <Section>
        <LeadSensitiveInfoModal
          title={getCcTradeInLeadFieldName('departingPropertyValuation')}
          value={departingPropertyValuationValue}
          isOpen={openSensitiveFields.departingPropertyValuation}
          setOpen={(open: boolean) => toggleIsSensitiveInfoOpen('departingPropertyValuation', open)}
        />
        <Tooltip content="This field contains sensitive data. Click the View icon at the right to view or edit.">
          <span>
            <FormField
              title={getCcTradeInLeadFieldName('departingPropertyValuation')}
              inline
              onClickViewSensitiveData={() =>
                toggleIsSensitiveInfoOpen('departingPropertyValuation')
              }
            >
              {departingPropertyValuationValue}
            </FormField>
          </span>
        </Tooltip>

        <FormField
          title={
            salesAppImprovement
              ? getCcTradeInLeadFieldName('departingPropertyGuaranteedPrice')
              : 'Loan Payoff Value'
          }
          inline
        >
          {formatPrice(tradeInLead.departingPropertyGuaranteedPrice)}
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('agentValuation')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('agentValuation')} />
        </FormField>

        <LeadSensitiveInfoModal
          title={getCcTradeInLeadFieldName('dpAnalystValuation')}
          value={<InlineEditDollarsAndCentsInput {...getProps('dpAnalystValuation')} />}
          isOpen={openSensitiveFields.dpAnalystValuation}
          setOpen={(open: boolean) => toggleIsSensitiveInfoOpen('dpAnalystValuation', open)}
        />
        <Tooltip content="This field contains sensitive data. Click the View icon at the right to view or edit.">
          <span>
            <FormField
              title={getCcTradeInLeadFieldName('dpAnalystValuation')}
              inline
              onClickViewSensitiveData={() => toggleIsSensitiveInfoOpen('dpAnalystValuation')}
            >
              <InlineEditDollarsAndCentsInput {...getProps('dpAnalystValuation')} />
            </FormField>
          </span>
        </Tooltip>

        <FormField title={getCcTradeInLeadFieldName('dpAnalystValuationDate')} inline>
          <InlineEditDateInput {...getProps('dpAnalystValuationDate')} />
        </FormField>
      </Section>
      <Section>
        <FormField
          title={getCcTradeInLeadFieldName('departingPropertyPreliminaryValuation')}
          inline
        >
          <InlineEditDollarsInput {...getProps('departingPropertyPreliminaryValuation')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('avmDate')} inline>
          <InlineEditDateInput {...getProps('avmDate')} />
        </FormField>

        <FormField title={getCcTradeInLeadFieldName('dpAgentAfterRepairValue')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpAgentAfterRepairValue')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpAgentAfterRepairValueDate')} inline>
          <InlineEditDateInput {...getProps('dpAgentAfterRepairValueDate')} />
        </FormField>

        <FormField title={getCcTradeInLeadFieldName('dpAnalystAfterRepairValue')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpAnalystAfterRepairValue')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpAnalystAfterRepairValueDate')} inline>
          <InlineEditDateInput {...getProps('dpAnalystAfterRepairValueDate')} />
        </FormField>
      </Section>
    </Sections>
  )
}
