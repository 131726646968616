// @ts-strict

import { useLocation } from 'react-use'
import { Contents, ListSearchHeader, Page, PaginationParams, ViewsMenu } from 'components'
import { BuyerAndSellerLeadsListFilter } from 'content/BuyerAndSellerLeads'
import { BuyerAndSellerLeadsTable } from 'content/BuyerAndSellerLeads'
import { ListProvider } from 'contexts'
import { useBuyersAndSellersLeadsController } from 'controllers'
import { useDocumentTitle, useFeatureFlags } from 'hooks'
import {
  buyerAndSellerLeadsListColumns,
  buyerAndSellerLeadsListColumnsWithPendingActions,
  useBuyerAndSellerLeadSearchFilter
} from 'lookups'
import { queryStringToObject } from 'utils'

const PAGE_TITLE = 'Buyer and Seller Leads'

const actions = (
  <>
    <BuyerAndSellerLeadsListFilter />
    <ViewsMenu />
  </>
)

const useBuyersAndSellersLeadsPage = () => {
  useDocumentTitle(PAGE_TITLE)
  const { search } = useLocation()
  const searchParams = queryStringToObject(search)

  const { buyerAndSellerLeadsQuery } = useBuyersAndSellersLeadsController({ searchParams })

  return {
    buyerAndSellerLeadsQuery
  }
}

export const BuyersAndSellersLeadsPage = () => {
  const { buyerAndSellerLeadsQuery } = useBuyersAndSellersLeadsPage()
  const { data, isLoading } = buyerAndSellerLeadsQuery
  const { items } = data || {}
  const buyerAndSellerLeadSearchFilter = useBuyerAndSellerLeadSearchFilter()
  const { salesAppBuyersSellersPendingActions } = useFeatureFlags([
    'sales-app-buyers-sellers-pending-actions'
  ])

  return (
    <ListProvider
      columns={
        salesAppBuyersSellersPendingActions.enabled
          ? buyerAndSellerLeadsListColumnsWithPendingActions
          : buyerAndSellerLeadsListColumns
      }
      filters={buyerAndSellerLeadSearchFilter}
    >
      <Page>
        <Contents title={PAGE_TITLE}>
          <ListSearchHeader
            counterText="Lead"
            totalCount={buyerAndSellerLeadsQuery.data?.totalCount}
            isLoading={buyerAndSellerLeadsQuery.isLoading}
            filter={actions}
          />
          <BuyerAndSellerLeadsTable items={items} isLoading={isLoading} />
          <PaginationParams totalPages={buyerAndSellerLeadsQuery.data?.pageCount} />
        </Contents>
      </Page>
    </ListProvider>
  )
}
