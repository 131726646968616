import { LenderResponse } from 'schema'
import { Model } from '../Model'

export class Lender extends Model {
  createdAt?: string
  email?: string
  id?: string
  name?: string
  slug?: string
  states?: Array<string>
  updatedAt?: string
  urls?: {}
  providerLead: {
    furthestStage?: string
    id?: string
    introSentAt?: string
    rank?: number
    stage?: string
  }

  constructor(lenderAttributes: LenderResponse) {
    super()
    Object.assign(this, lenderAttributes)
  }

  static create(lenderResponse: Potential<LenderResponse>): Potential<Lender> {
    if (!lenderResponse) {
      return
    }

    return new Lender(lenderResponse)
  }
}
