// @ts-strict
import { Option } from 'classes'
import { IOptionable } from 'lookups'
import { Agent, PhoneNumber } from 'models'
import { UserResponse } from 'schema'
import { Model } from '../Model'

export class User extends Model implements IOptionable {
  id!: string
  name!: string
  email?: string
  firstName?: string
  lastName?: string
  agent?: Agent
  modelName = 'User'
  phoneNumbers?: PhoneNumber[] | []

  constructor(attributes: Omit<User, 'toOption' | keyof Model>) {
    super()
    Object.assign(this, attributes)
  }

  static create(userResponse: Potential<UserResponse>): Potential<User> {
    if (!userResponse) {
      return
    }
    const name =
      userResponse.name || [userResponse.firstName, userResponse.lastName].filter(Boolean).join(' ')
    const agent = userResponse.agent ? Agent.create(userResponse.agent) : undefined
    return new User({ ...userResponse, name, agent })
  }

  toOption() {
    return new Option({ name: this.name, value: String(this.id) })
  }
}
