import { Box, Text } from '@foundation/components'
import { InlineEditTextInput } from 'components'
import {
  AddAgentTeamButtonForm,
  AddAgentTeamMemberButtonForm,
  ChangeAgentTeamPreferredEscrowOfficer,
  TeamMemberFilterButton,
  ToggleLeadRoutingButtonForm
} from 'content/Agent/AgentTeam'
import { useAgentTeamContext } from 'contexts'
import { Agent, AgentTeam } from 'models'
import styles from './AgentTeamActionsSection.module.scss'

type AgentTeamActionsSectionProps = {
  agent?: Agent
  agentTeam?: AgentTeam
}

const AgentTeamDetails = ({ agentTeam }: AgentTeamActionsSectionProps) => {
  if (!agentTeam) {
    return null
  }

  return (
    <Box className={styles.status}>
      <Text size="sm" weight="semibold">
        {`${agentTeam?.getLeadRoutingActiveAgents()?.length} of ${
          agentTeam?.leadRoutingSeats
        } team members assigned`}
      </Text>
      <Text size="sm" weight="semibold">
        {`${agentTeam?.getWarmTransferOptInAgents()?.length} of ${
          agentTeam?.warmTransferSeats
        } Warm Transfer members assigned`}
      </Text>
    </Box>
  )
}

export const AgentTeamActionsSection = ({ agent, agentTeam }: AgentTeamActionsSectionProps) => {
  const { editAgentTeam } = useAgentTeamContext()

  const onUpdateAgentTeam = (teamName: string) => {
    editAgentTeam.mutateAsync({ agentTeamId: agentTeam.id, name: teamName })
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.teamInfoSection}>
        {agentTeam && <InlineEditTextInput value={agentTeam?.name} onBlur={onUpdateAgentTeam} />}
        {agent && <ChangeAgentTeamPreferredEscrowOfficer agent={agent} />}
      </Box>
      <Box className={styles.teamActionsSection}>
        <Box className={styles.actions}>
          {agentTeam ? (
            <>
              <AddAgentTeamMemberButtonForm team={agentTeam} />
              <ToggleLeadRoutingButtonForm agent={agent} team={agentTeam} />
              <TeamMemberFilterButton />
            </>
          ) : (
            <AddAgentTeamButtonForm />
          )}
        </Box>

        <AgentTeamDetails agentTeam={agentTeam} />
      </Box>
    </Box>
  )
}
