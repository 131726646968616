// @ts-strict
import { Popover } from '@foundation/components'
import theme from '@foundation/themes'
import { Block, HoverBackground } from 'components'
import { TaskActions } from 'content/Task'
import { TaskStatusIcon } from 'content/Tasks'
import { Task } from 'models'
import { dataOrDash, formatDateTime } from 'utils'
import styles from './TaskMenuCard.module.scss'

type TProps = {
  onSuccessCallback?: Function
  task?: Task
}

const getTaskDateString = (task: Task) => {
  if (task.completedAt) {
    return `Completed at: ${formatDateTime(task.completedAt)}`
  }

  if (task.dismissedAt) {
    return `Dismissed at: ${formatDateTime(task.dismissedAt)}`
  }

  return `Due at: ${formatDateTime(task.dueAt)}`
}

const getNoteString = (task: Task) => {
  if (task.completedAt) {
    return 'Completion Note:'
  }

  if (task.dismissedAt) {
    return `Dismissal Note:`
  }

  return `Note:`
}

export const TaskMenuCard = ({ task, onSuccessCallback }: TProps) => {
  if (!task) {
    return <span>---</span>
  }

  const { firstName, lastName, name } = task.assignee || {}
  const fullName = firstName && lastName ? `${firstName} ${lastName}` : undefined
  const assigneeString = `Assigned to: ${dataOrDash(fullName || name)}`
  const note = task.completionNote

  return (
    <Popover.Root placement="bottom-end">
      <Popover.Trigger>
        <HoverBackground>
          <TaskStatusIcon task={task} />
        </HoverBackground>
      </Popover.Trigger>
      <Popover.Content zIndex={theme.zIndices.overlay - 1}>
        <Block
          className={styles.titleNoEllipsis}
          title={<span>{task.title}</span>}
          subtitle={assigneeString}
        />

        <Block subtitle={getTaskDateString(task)} />

        {note && <Block subtitle={`${getNoteString(task)} ${note}`} />}

        <div className={styles.actions}>
          <TaskActions task={task} onSuccessCallback={onSuccessCallback} />
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}
