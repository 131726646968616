// @ts-strict

import { css, styled } from '@foundation/themes/styles'

type AgentProps = {
  'data-testid': string
  isAgentCanceled: boolean
  spaceBetweenAgents: boolean
}

const canceledStyle = `
  cursor: not-allowed;
  opacity: .7;
  user-select: none;

  img {
    filter: grayscale(100);
  }
`

export const Flex = styled.div`
  align-items: center;
  display: flex;
`

export const Agent = styled(Flex)<AgentProps>`
  justify-content: space-between;
  width: 100%;

  ${({ theme, spaceBetweenAgents, isAgentCanceled }) => css`
    margin-bottom: ${spaceBetweenAgents ? theme.space.$6 : 0};

    ${isAgentCanceled && canceledStyle}
  `}
`

export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.sizes.$24};
    max-width: ${theme.sizes.$24};
    height: auto;
    margin-right: ${theme.space.$5};
  `}
`

export const AgentInformation = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.space.$6};
    max-width: 22rem;

    & > * {
      margin: 0;
    }
  `}
`

export const Actions = styled.div`
  p {
    margin: 0;
  }
`
