import { useMemo, useState } from 'react'
import { useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react'

export type UseDialog = {
  initialOpen?: boolean
  isOpen?: boolean
  onOpenChange?: (open: boolean) => void
  shouldCloseOnOverlayClick?: boolean
}

export const useDialog = ({
  initialOpen = false,
  isOpen: controlledOpen,
  onOpenChange: setControlledOpen,
  shouldCloseOnOverlayClick = true
}: UseDialog) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)
  const isOpen = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const { context, reference, floating } = useFloating({
    open: isOpen,
    onOpenChange: setOpen
  })

  const click = useClick(context)

  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    enabled: shouldCloseOnOverlayClick
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss])

  return useMemo(
    () => ({
      triggerRef: reference,
      floatingRef: floating,
      getReferenceProps,
      getFloatingProps,
      setOpen,
      context,
      isOpen
    }),
    [isOpen, setOpen, reference, getReferenceProps, context, floating, getFloatingProps]
  )
}
