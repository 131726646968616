import { useEmployeeUsers } from 'controllers'
import {
  ccHLSimpleSaleLeadStagesOptions,
  sourcesOptions,
  stateNameOptions,
  trueFalseOptions
} from 'lookups'
import { useCapitalPartners } from 'lookups/lookupCapitalPartners'
import { titleize } from 'utils'
import { buildSearchFilters, findFilterBySlug } from '../utils'
import { ccHLSimpleSaleLeadSearchFilterSettings } from './ccHLSimpleSaleLeadSearchFilterSettings'

export const useCCHLSimpleSaleLeadSearchFilter = () => {
  const { capitalPartnersOptions } = useCapitalPartners()
  const { getUserName } = useEmployeeUsers()

  const optionLoaders = {
    stage: ccHLSimpleSaleLeadStagesOptions,
    capitalPartnerId: capitalPartnersOptions,
    source: sourcesOptions,
    trueFalse: trueFalseOptions,
    stateName: stateNameOptions
  }
  const displayValueFnLoaders = {
    titleize,
    getUserName
  }

  const ccHLSimpleSaleLeadFilters = buildSearchFilters(ccHLSimpleSaleLeadSearchFilterSettings, {
    optionLoaders,
    displayValueFnLoaders
  })

  const findCCHLSimpleSaleLeadFilterBySlug = findFilterBySlug(ccHLSimpleSaleLeadFilters)

  return { ccHLSimpleSaleLeadFilters, findFilterBySlug: findCCHLSimpleSaleLeadFilterBySlug }
}
