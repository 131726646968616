import { TModelFieldSetting } from '../ModelField'

export type TEscrowLeadFieldAttribute =
  | 'createdAt'
  | 'fullAddress'
  | 'leadUsersNamesString'
  | 'price'
  | 'secondaryUserType'

export type TEscrowLeadFieldSetting = {
  attr: TEscrowLeadFieldAttribute
} & TModelFieldSetting

export const escrowLeadFieldsSettings: TEscrowLeadFieldSetting[] = [
  { attr: 'createdAt', name: 'Date Received' },
  { attr: 'fullAddress', name: 'Full Address' },
  { attr: 'leadUsersNamesString', name: 'Team Members' },
  { attr: 'price', name: 'Price' },
  { attr: 'secondaryUserType', name: 'Leg' }
]
