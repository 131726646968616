// @ts-strict
import {
  ExternalLink,
  FormField,
  InlineEditAddressUnit,
  InlineEditBoolean,
  InlineEditSelect,
  List,
  MapBox,
  Section,
  Sections
} from 'components'
import { AgentSection } from 'content/Agent/AgentSection'
import { useCashOfferLeadController } from 'controllers'
import {
  getMasterPropertyFieldName,
  solarPanelsOptions,
  useCashOfferLeadFields,
  useLeadPropertyTypes
} from 'lookups'
import { CashOfferLead, CashOfferProviderLead } from 'models'
import { dataOrDash, toSearchParam } from 'utils'
import { getCashOfferLeadInputProps } from './utils'

type Props = { cashOfferLead: CashOfferLead; cashOfferProviderLead?: CashOfferProviderLead }

export const IRPropertyDetailsSection = ({ cashOfferLead, cashOfferProviderLead }: Props) => {
  const incomingMasterProperty = cashOfferLead.incomingMasterProperty
  const buyerAgent = cashOfferLead.incomingPropertyBuyerAgent
  const { getCashOfferLeadField, getCashOfferLeadFieldName } = useCashOfferLeadFields()
  const { updateCashOfferLead } = useCashOfferLeadController(cashOfferLead.id)
  const { getProps } = getCashOfferLeadInputProps(
    cashOfferLead,
    updateCashOfferLead,
    getCashOfferLeadField
  )
  const { leadPropertyTypes } = useLeadPropertyTypes()

  const showReasonForFailField =
    cashOfferProviderLead?.stage &&
    ['property_cancelled', 'property_rejected', 'offer_cancelled', 'offer_rejected'].includes(
      cashOfferProviderLead.stage
    )

  return (
    <Sections>
      <Section>
        <Section>
          <FormField title={getMasterPropertyFieldName('displayAddress')} inline>
            <InlineEditAddressUnit
              onBlur={val =>
                updateCashOfferLead.mutate({
                  incomingPropertyFullAddress: val
                })
              }
              value={incomingMasterProperty?.displayAddress}
            />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipPropertyType')} inline>
            <InlineEditSelect {...getProps('ipPropertyType')} options={leadPropertyTypes} />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipHomeownerAssociation')} inline>
            <InlineEditBoolean {...getProps('ipHomeownerAssociation')} />
          </FormField>
          <FormField title={getCashOfferLeadFieldName('ipSolarPanels')} inline>
            <InlineEditSelect {...getProps('ipSolarPanels')} options={solarPanelsOptions} />
          </FormField>
          <FormField title="External Links" inline>
            <List>
              <ExternalLink
                href={`https://www.google.com/search?q=${toSearchParam(
                  incomingMasterProperty?.displayAddress
                )}`}
              >
                Google Address
              </ExternalLink>
              <ExternalLink
                href={`http://www.zillow.com/homes/${toSearchParam(
                  incomingMasterProperty?.displayAddress
                )}_rb`}
              >
                Zillow Address
              </ExternalLink>
            </List>
          </FormField>
        </Section>

        <AgentSection
          agent={buyerAgent!}
          title="Buyer Agent"
          onBlur={(val: string) => {
            updateCashOfferLead.mutate({
              incomingPropertyBuyerAgentId: val
            })
          }}
          program="cash_offer"
        />
        {showReasonForFailField && (
          <Section>
            <FormField title={getCashOfferLeadFieldName('reasonForFail')} inline>
              {dataOrDash(cashOfferProviderLead?.reasonForFail)}
            </FormField>
          </Section>
        )}
      </Section>
      <Section>
        {incomingMasterProperty?.latitude && incomingMasterProperty?.longitude ? (
          <MapBox
            center={incomingMasterProperty?.point}
            markers={[incomingMasterProperty?.marker]}
          />
        ) : (
          <FormField title="Map" inline>
            Latitude and Longitude are missing
          </FormField>
        )}
      </Section>
    </Sections>
  )
}
