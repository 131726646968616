import { css, styled } from '@foundation/themes/styles'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.space.$4};

    & > div {
      margin-left: ${theme.space.$2};
    }
  `}
`
