// @ts-strict
import { useState } from 'react'
import { useToastContext } from '@foundation/components'
import { PrimaryButton } from 'components'
import { useAgentPayPerLeadSettingsController } from 'controllers'
import { environment } from 'environment'
import { AgentPayPerLeadSetting } from 'models'
import type { Agent } from 'models'
import { LocationSettingsForm } from '../LocationSettings'
import { LinkAgentDetailsForm, MonthlyCapForm } from '../MonthlyCap'
import { SettingsModal } from './SettingsModal'

type Props = {
  agent: Agent
}

export const SetupPayPerLeadSettingsButtonForm = ({ agent }: Props) => {
  const {
    createAgentPayPerLeadSettings,
    createSetupCheckoutSession,
    createSetupCheckoutSessionMagicLink,
    clearCaches
  } = useAgentPayPerLeadSettingsController(agent.id)
  const toast = useToastContext()

  const [visible, setVisible] = useState(false)
  const [currentScreen, setCurrentScreen] = useState('location-settings')
  const [locationSettings, setLocationSettings] = useState<AgentPayPerLeadSetting[]>([])
  const [checkoutUrl, setCheckoutUrl] = useState<string>('')

  const handleOnSubmitSettings = (
    monthlyCap: number,
    buyerMonthlyCap: number,
    paymentMethod: string
  ) => {
    const monthlyCapInCents = monthlyCap * 100
    const buyerMonthlyCapInCents = buyerMonthlyCap * 100
    const pricedSettings = locationSettings.filter(
      locationSettings => locationSettings.price && locationSettings.price > 0
    )

    createAgentPayPerLeadSettings.mutate(
      {
        settings: pricedSettings!,
        monthlyCap: monthlyCapInCents,
        buyerMonthlyCap: buyerMonthlyCapInCents
      },
      {
        onSuccess: () => {
          // FIXME: this is a temporary solution, please do not replicate in other components
          setTimeout(() => {
            createCheckoutSession(paymentMethod)
          }, 1000)
        }
      }
    )
  }

  const createCheckoutSession = (paymentMethod: string) => {
    createSetupCheckoutSession.mutate(
      {
        subscriberId: agent.id,
        subscriberType: 'Agent',
        redirectUrl: window.location.href
      },
      {
        onSuccess: response => {
          handleCheckoutSessionCreated(response, paymentMethod)
        },
        onError: () => {
          toast({
            title: 'Unable to complete your request. Please add your payment information manually',
            status: 'error'
          })

          clearCaches()
          setVisible(false)
        }
      }
    )
  }

  const handleCheckoutSessionCreated = (response: any, paymentMethod: string) => {
    if (paymentMethod === 'card') {
      const newWindow = window.open(response.data.url, '_self', 'noopener,noreferrer')
      if (newWindow) {
        newWindow.opener = null
      }
      setVisible(false)
      setCurrentScreen('location-settings')
      clearCaches()
    }

    if (paymentMethod === 'link') {
      setCheckoutUrl(response.data.url)
      setCurrentScreen('link-agent-details')
    }
  }

  const handleOnSubmitLinkAgentDetails = () => {
    createSetupCheckoutSessionMagicLink.mutate({
      subscriberId: agent.id,
      subscriberType: 'Agent',
      priceId: environment.stripeProsPriceId,
      redirectUrl: window.location.href
    })

    setVisible(false)
    setCurrentScreen('location-settings')
    clearCaches()
  }

  if (!visible) {
    return <PrimaryButton onClick={() => setVisible(true)}>Add Pay-Per-Lead</PrimaryButton>
  }

  return (
    <SettingsModal
      title="Add Pay-Per-Lead ZIP"
      onCancel={() => {
        setVisible(false)
        setCurrentScreen('location-settings')
      }}
    >
      {currentScreen === 'location-settings' && (
        <LocationSettingsForm
          settings={locationSettings}
          onSubmit={(settings: AgentPayPerLeadSetting[]) => {
            setLocationSettings(settings)
            setCurrentScreen('monthly-cap')
          }}
          onCancel={() => setVisible(false)}
          submitText="Next"
        />
      )}
      {currentScreen === 'monthly-cap' && (
        <MonthlyCapForm
          cancelText="Back"
          submitText="Next"
          onSubmit={(cap: number, buyerCap: number, method: string) => {
            handleOnSubmitSettings(cap, buyerCap, method)
          }}
          onCancel={() => {
            setCurrentScreen('location-settings')
          }}
        />
      )}
      {currentScreen === 'link-agent-details' && (
        <LinkAgentDetailsForm
          agent={agent}
          url={checkoutUrl}
          onSubmit={() => {
            handleOnSubmitLinkAgentDetails()
          }}
        />
      )}
    </SettingsModal>
  )
}
