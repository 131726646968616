import { ListingsContextType } from 'contexts/types'
import { useQuery } from 'hooks'
import { fetchListings } from 'services'
import { getPageCount } from 'utils'

const defaultSearchSettings = { perPage: 25 }

type UseListingsProps = {
  searchParams: Record<string, string | number>
}

export const useListings = (
  { searchParams }: UseListingsProps,
  enabled: boolean = true
): ListingsContextType => {
  const searchSettings = { ...defaultSearchSettings, ...searchParams }

  const { data, isLoading } = useQuery(
    ['listings', searchSettings],
    () => fetchListings({ ...searchSettings }),
    { enabled, refetchOnWindowFocus: false }
  )

  const listings = data?.listings
  const totalCount = data?.totalCount
  const pageCount = getPageCount(totalCount, searchSettings.perPage)

  return {
    listings,
    totalCount,
    pageCount,
    isLoading
  }
}
