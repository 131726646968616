import { AgentAgreement } from 'models'
import { AgentAgreementSchema, getHapiRequestParams } from 'schema'
import { getAgentsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const agentAgreementShape: AgentAgreementSchema = {
  agentAgreement: [
    'signedAt',
    'status',
    's3Path',
    'voidedAt',
    'excluded',
    'note',
    {
      agreement: ['name', 'commissionSplit', 'version']
    }
  ]
}

export const fetchAgentAgreements = async (agentId: string) => {
  const url = getAgentsUrl({
    id: agentId,
    action: 'agent-agreements',
    searchParams: getHapiRequestParams(agentAgreementShape)
  })

  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: AgentAgreement.create })
}
