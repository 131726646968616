export function formatTalkTime(ms = 0) {
  const formattedTalkTime = []
  const seconds = Math.floor(ms / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const currentMinutes = minutes - hours * 60
  const currentSeconds = seconds - minutes * 60

  if (!ms || ms < 1) {
    return '00:00'
  }

  formattedTalkTime.push(hours)
  formattedTalkTime.push(currentMinutes >= 10 ? currentMinutes : `0${currentMinutes}`)
  formattedTalkTime.push(currentSeconds >= 10 ? currentSeconds : `0${currentSeconds}`)

  return formattedTalkTime.filter(presence => presence).join(':')
}
