// @ts-strict
import { cx } from 'utils'
import styles from './RadioTabs.module.scss'

export type TRadioTab = {
  checked: boolean
  className?: string
  label: string
  onChange: () => void
  value: string
}

export const RadioTab = ({ label, value, className, onChange, checked, ...props }: TRadioTab) => {
  return (
    <div
      className={cx(styles.radioTab, className, checked && styles.checked)}
      data-testid={`radio-tab-${value}`}
      onClick={onChange}
      {...props}
    >
      {label}
    </div>
  )
}
