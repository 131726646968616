// @ts-strict
import { EmptyTableCell, TableRow as Tr } from 'components'
import {
  CCHLSimpleSaleDRSaleOpenEscrowDateCell,
  CCHLSimpleSaleLeadCapitalMarketsFacilityCell,
  CCHLSimpleSaleLeadDaysOnMarketCell,
  CCHLSimpleSaleLeadDaysOwnedCell,
  CCHLSimpleSaleLeadDaystoListingCell,
  CCHLSimpleSaleLeadDaysUntilFundingCell,
  CCHLSimpleSaleLeadDRPurchaseCoeDateCell,
  CCHLSimpleSaleLeadDRSaleActualCOEDateCell,
  CCHLSimpleSaleLeadFundingAmountCell,
  CCHLSimpleSaleLeadFundingDateCell,
  CCHLSimpleSaleLeadFundingFacilityCell,
  CCHLSimpleSaleLeadHoaCell,
  CCHLSimpleSaleLeadListedDateCell,
  CCHLSimpleSaleLeadOpenPurchaseEscrowDateCell,
  CCHLSimpleSaleLeadOpenSaleEscrowDateCell,
  CCHLSimpleSaleLeadPropertyAddress,
  CCHLSimpleSaleLeadSolarPanelsCell,
  CCSimpleSaleLeadDRPurchaseOpenEscrowDateCell
} from 'content/CCHLSimpleSaleLeads/CCHLSimpleSaleLeadsTable/CCHLSimpleSaleLeadsTableCells'
import {
  CashCloseLeadConsumerClientManagerCell,
  CashCloseLeadContractAdministratorCell,
  CashCloseLeadListingSpecialistCell,
  CashCloseLeadSalesSpecialistCell,
  CashCloseLeadStrategicRelationshipManagerCell,
  CashCloseLeadTransactionSpecialistCell,
  CashCloseLeadTxTeamCell
} from 'content/CashCloseLeads/CashCloseLeadsTableCells'
import { LeadDaysSinceLastStageUpdateCell } from 'content/Leads'
import {
  LeadAddressCell,
  LeadAgentCell,
  LeadClientAdvisorAssistantCell,
  LeadClientAdvisorCell,
  LeadCreatedUpdatedCell,
  LeadLastStageUpdateCell,
  LeadNamePartnerCell,
  LeadOtherLeadsCell,
  LeadPropertyTypeCell,
  LeadProvidableAgentCell,
  LeadPurchaseEscrowAssistantCell,
  LeadPurchaseEscrowExpectedCloseDateCell,
  LeadPurchaseEscrowOfficerCell,
  LeadPurchaseEscrowOrderOpenedDateCell,
  LeadPurchaseEscrowStatusCell,
  LeadPurchaseEscrowTitleOfficerCell,
  LeadSaleEscrowAssistantCell,
  LeadSaleEscrowExpectedCloseDateCell,
  LeadSaleEscrowOfficerCell,
  LeadSaleEscrowOrderOpenedDateCell,
  LeadSaleEscrowStatusCell,
  LeadSaleEscrowTitleOfficerCell,
  LeadSourceCell,
  LeadStageFurthestCell
} from 'content/Leads/LeadsTable/LeadsTableCells'
import { useListContext } from 'contexts'
import { CCHLSimpleSaleLead } from 'models'
import { capitalize, cx } from 'utils'

const columnMapping = {
  namePartner: LeadNamePartnerCell,
  address: LeadAddressCell,
  createdUpdated: LeadCreatedUpdatedCell,
  lastStageUpdate: LeadLastStageUpdateCell,
  otherLeads: LeadOtherLeadsCell,
  propertyType: LeadPropertyTypeCell,
  source: LeadSourceCell,
  stageFurthest: LeadStageFurthestCell,
  txTeam: CashCloseLeadTxTeamCell,
  departingPropertySellerAgent: LeadProvidableAgentCell,
  incomingPropertyBuyerAgent: LeadProvidableAgentCell,
  referringAgent: LeadAgentCell,
  propertyAddress: CCHLSimpleSaleLeadPropertyAddress,
  hoa: CCHLSimpleSaleLeadHoaCell,
  solarPanels: CCHLSimpleSaleLeadSolarPanelsCell,
  fundingAmount: CCHLSimpleSaleLeadFundingAmountCell,
  fundingDate: CCHLSimpleSaleLeadFundingDateCell,
  drPurchaseCoeDate: CCHLSimpleSaleLeadDRPurchaseCoeDateCell,
  drSaleActualCoeDate: CCHLSimpleSaleLeadDRSaleActualCOEDateCell,
  openPurchaseEscrowDate: CCHLSimpleSaleLeadOpenPurchaseEscrowDateCell,
  openSaleEscrowDate: CCHLSimpleSaleLeadOpenSaleEscrowDateCell,
  drSaleOpenEscrowDate: CCHLSimpleSaleDRSaleOpenEscrowDateCell,
  drPurchaseOpenEscrowDate: CCSimpleSaleLeadDRPurchaseOpenEscrowDateCell,
  listedDate: CCHLSimpleSaleLeadListedDateCell,
  fundingFacility: CCHLSimpleSaleLeadFundingFacilityCell,
  daysOwned: CCHLSimpleSaleLeadDaysOwnedCell,
  daysToListing: CCHLSimpleSaleLeadDaystoListingCell,
  daysOnMarket: CCHLSimpleSaleLeadDaysOnMarketCell,
  daysUntilFunding: CCHLSimpleSaleLeadDaysUntilFundingCell,
  daysSinceLastStageUpdate: LeadDaysSinceLastStageUpdateCell,
  capitalMarketsFacility: CCHLSimpleSaleLeadCapitalMarketsFacilityCell,
  strategicRelationshipManager: CashCloseLeadStrategicRelationshipManagerCell,
  consumerClientManager: CashCloseLeadConsumerClientManagerCell,
  salesSpecialist: CashCloseLeadSalesSpecialistCell,
  clientAdvisor: LeadClientAdvisorCell,
  clientAdvisorAssistant: LeadClientAdvisorAssistantCell,
  contractAdministrator: CashCloseLeadContractAdministratorCell,
  transactionSpecialist: CashCloseLeadTransactionSpecialistCell,
  listingSpecialist: CashCloseLeadListingSpecialistCell,
  purchaseEscrowStatus: LeadPurchaseEscrowStatusCell,
  purchaseEscrowOrderOpenedDate: LeadPurchaseEscrowOrderOpenedDateCell,
  purchaseEscrowExpectedCloseDate: LeadPurchaseEscrowExpectedCloseDateCell,
  purchaseEscrowOfficer: LeadPurchaseEscrowOfficerCell,
  purchaseEscrowAssistant: LeadPurchaseEscrowAssistantCell,
  purchaseEscrowTitleOfficer: LeadPurchaseEscrowTitleOfficerCell,
  saleEscrowStatus: LeadSaleEscrowStatusCell,
  saleEscrowOrderOpenedDate: LeadSaleEscrowOrderOpenedDateCell,
  saleEscrowExpectedCloseDate: LeadSaleEscrowExpectedCloseDateCell,
  saleEscrowOfficer: LeadSaleEscrowOfficerCell,
  saleEscrowAssistant: LeadSaleEscrowAssistantCell,
  saleEscrowTitleOfficer: LeadSaleEscrowTitleOfficerCell
}

type TProps = {
  lead: CCHLSimpleSaleLead
}

export const CCHLSimpleSaleLeadTableRow = ({ lead }: TProps) => {
  const { highlightRows, visibleColumns } = useListContext()
  const rowClassName = highlightRows?.includes(lead.id) && 'highlight'

  return (
    <Tr className={cx(rowClassName)} data-testid={`lead-id-${lead.id}`}>
      {visibleColumns.map(visibleColumn => {
        const Cell = columnMapping[visibleColumn]
        const testId = `lead${capitalize(visibleColumn)}Cell`

        return Cell ? (
          <Cell lead={lead} key={visibleColumn} testId={testId} column={visibleColumn} />
        ) : (
          <EmptyTableCell key={visibleColumn} />
        )
      })}
    </Tr>
  )
}
