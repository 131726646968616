// @ts-strict
import { useEffect } from 'react'
import { Button } from '@foundation/components'
import { DateInput, Form, FormActions, FormField, Popup, Section } from 'components'
import { LeadUncompletedTasks, UpdateStage } from 'content/Lead'
import formStyles from 'content/forms/Form.module.scss'
import { CCHLSimpleSaleLead } from 'models'
import {
  HLSSAgreementSigned,
  HLSSApplicationComplete,
  HLSSApproved,
  HLSSClosedPaid,
  HLSSClosedPurchase,
  HLSSClosedSell,
  HLSSFailed,
  HLSSInEscrowPurchase,
  HLSSInEscrowSell,
  HLSSListed
} from '../FormSections'
import { useHLSSLeadDispositionContext } from '../HLSSLeadDispositionContext'

type Props = {
  lead: CCHLSimpleSaleLead
  onCancel: () => void
}

export const HLSSLeadDispositionForm = ({ lead, onCancel }: Props) => {
  const {
    commonPayload,
    currentStage,
    furthestStage,
    isLoading,
    isValid,
    newStage,
    onHandleSubmit,
    setCommonPayload,
    setCommonPayloadItem,
    setCommonRequiredFields,
    uncompletedTasks,
    showSubmitForm,
    dispositionPayload,
    setDispositionPayloadItem
  } = useHLSSLeadDispositionContext()

  useEffect(() => {
    setCommonRequiredFields(['occurredAt'])
  }, [setCommonRequiredFields])

  useEffect(() => {
    if (setCommonPayload && lead) {
      setCommonPayload({
        disposition: currentStage?.nextStageOptions?.[0]?.value || lead.stage,
        occurredAt: new Date().toISOString()
      })
    }
  }, [currentStage, setCommonPayload, lead])

  const stageFormFields = {
    agreement_signed: HLSSAgreementSigned,
    application_complete: HLSSApplicationComplete,
    approved: HLSSApproved,
    closed_paid: HLSSClosedPaid,
    closed_purchase: HLSSClosedPurchase,
    closed_sell: HLSSClosedSell,
    failed: HLSSFailed,
    in_escrow_purchase: HLSSInEscrowPurchase,
    in_escrow_sell: HLSSInEscrowSell,
    listed: HLSSListed
  }

  const HLSSLeadDispositionFields = stageFormFields[commonPayload.disposition]

  return (
    <Popup title="HLSS Disposition" onCancel={onCancel} className={formStyles.popup}>
      <Form onSubmit={() => onHandleSubmit(onCancel)} className={formStyles.form}>
        <UpdateStage
          payload={commonPayload}
          setPayloadItem={setCommonPayloadItem}
          currentStage={currentStage}
          furthestStage={furthestStage}
          newStage={newStage}
        />
        {showSubmitForm ? (
          <>
            {HLSSLeadDispositionFields && (
              <HLSSLeadDispositionFields
                payload={dispositionPayload}
                setPayload={setDispositionPayloadItem}
              />
            )}
            <Section>
              <FormField title="Occurred On" required>
                <DateInput
                  value={commonPayload.occurredAt}
                  onChange={val => setCommonPayloadItem('occurredAt', val)}
                />
              </FormField>
            </Section>
            <FormActions>
              <Button variant="ghost" color="neutralLight" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" isDisabled={!isValid} isLoading={isLoading}>
                Submit
              </Button>
            </FormActions>
          </>
        ) : (
          <Section>
            <LeadUncompletedTasks
              selectedStage={commonPayload.disposition}
              items={uncompletedTasks}
            />
          </Section>
        )}
      </Form>
    </Popup>
  )
}
