import { DrawerBody, DrawerSidebar } from './Drawer'
import { DrawerContent } from './DrawerContent'
import { DrawerHeader } from './DrawerHeader'
import { DrawerMenuButton } from './DrawerMenuButton'
import { RootDrawer } from './RootDrawer'

export { useDrawerContext } from './Drawer'

export const Drawer = {
  Root: RootDrawer,
  Sidebar: DrawerSidebar,
  MenuButton: DrawerMenuButton,
  Content: DrawerContent,
  Header: DrawerHeader,
  Body: DrawerBody
}
