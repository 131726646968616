// @ts-strict
import { FormField, Section, Sections } from 'components'
import { RadioButtons } from 'components/Form/RadioButtons'
import { useFeatureFlags } from 'hooks'
import { bbysOrderProductsOptions, cashCloseOrderProductsOptions } from 'lookups'
import createOrderStyles from '../CreateOrderForm.module.scss'
import { TFormItemsProps } from '../types'

export const LeadTypeSelect = ({ payload, onChangeAttribute }: TFormItemsProps) => {
  const {
    bbysLeadSubmissionSalesApp: { enabled: isBbysEnabled }
  } = useFeatureFlags(['bbys_lead_submission_sales_app'])

  const leadTypeOptions = isBbysEnabled ? bbysOrderProductsOptions : cashCloseOrderProductsOptions

  return (
    <Sections>
      <Section>
        <FormField
          title="Lead Type"
          hint="You can attach other leads to this order later."
          required
        >
          <RadioButtons
            className={createOrderStyles.radioButtons}
            options={leadTypeOptions}
            value={payload?.leadType}
            onChange={value => {
              onChangeAttribute('leadType', value!)
            }}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
