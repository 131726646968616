import { PaginationParams } from 'components/PaginationParams'
import { ListProvider } from 'contexts'
import { AgentLead } from 'models'
import { AgentLeadsTable } from '../AgentLeadsTable'
import { AgentLeadsListHeader } from './AgentLeadsListHeader'

type AgentLeadsListProps = {
  agentLeads: AgentLead[]
  isLoading: boolean
  pageCount?: number
  totalCount?: number
  totalUrgentCount?: number
}

export const AgentLeadsList = ({
  agentLeads,
  pageCount,
  isLoading,
  totalCount,
  totalUrgentCount
}: AgentLeadsListProps) => {
  return (
    <ListProvider>
      <AgentLeadsListHeader totalCount={totalCount} totalUrgentCount={totalUrgentCount} />
      <AgentLeadsTable agentLeads={agentLeads} isLoading={isLoading} />
      {pageCount ? <PaginationParams totalPages={pageCount} /> : null}
    </ListProvider>
  )
}
