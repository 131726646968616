import { InspectionDeficiency } from 'models'
import { getHapiRequestParams } from 'schema'
import type { InspectionDeficienciesSchema } from 'schema'
import { deserializeAsync, getRequest } from 'utils'
import { getInspectionUrl } from '../getUrl'
import { getInspectionDeficienciesSearchParams } from './getInspectionDeficienciesSearchParams'
import type { InspectionDeficienciesSearchParams } from './getInspectionDeficienciesSearchParams'

const deficienciesShape: InspectionDeficienciesSchema = {
  deficiencies: [
    'detail',
    'title',
    'severity',
    'category',
    'escalated',
    'createdAt',
    'updatedAt',
    { inspectionReport: ['inspectionTypes'] },
    { inspectionDeficiencyFiles: [{ file: ['id'] }] }
  ]
}
export type FetchInspectionDeficienciesProps = InspectionDeficienciesSearchParams
export const fetchInspectionDeficiencies = async (settings: FetchInspectionDeficienciesProps) => {
  const url = getInspectionUrl({
    searchParams: {
      ...getInspectionDeficienciesSearchParams(settings),
      ...getHapiRequestParams(deficienciesShape)
    },
    action: 'inspection-deficiencies'
  })
  const response = await getRequest(url)
  const deficiencies = await deserializeAsync<InspectionDeficiency[]>(response.data, {
    transform: InspectionDeficiency.create
  })

  return {
    items: deficiencies,
    pageCount: response.data.meta.total_pages,
    totalCount: response.data.meta.total_count
  }
}
