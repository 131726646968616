export const SERVICE_STATES = [
  'AL', // Alabama
  'AR', // Arkansas
  'AZ', // Arizona
  'CA', // California
  'CO', // Colorado
  'FL', // Florida
  'GA', // Georgia
  'IN', // Indiana
  'IL', // Illinois (testing)
  'IA', // Iowa
  'KS', // Kansas
  'KY', // Kentucky
  'LA', // Louisiana
  'MD', // Maryland
  'MI', // Michigan
  'MN', // Minnesota
  'MO', // Missouri
  'MS', // Mississippi
  'NE', // Nebraska
  'NV', // Nevada
  'NJ', // New Jersey
  'NC', // North Carolina
  'OH', // Ohio
  'OR', // Oregon
  'PA', // Pennsylvania
  'SC', // South Carolina
  'TN', // Tennessee
  'TX', // Texas
  'UT', // Utah
  'VA', // Virginia
  'WA', // Washington
  'DC', // Washington D.C.
  'WI' // Wisconsin
]
