import { Model } from 'models/Model'
import {
  EstimatedRangeAttributes,
  InvestorLeadResponse,
  InvestorResponse,
  ProviderLeadResponse
} from 'schema'

export class InvestorLead extends Model {
  estimatedRange?: EstimatedRangeAttributes
  id?: string
  investor: InvestorResponse
  providerLead: ProviderLeadResponse

  constructor(investorLeadAttributes?: InvestorLeadResponse) {
    super()
    Object.assign(this, investorLeadAttributes)
  }

  static create(investorLeadResponse: Potential<InvestorLeadResponse>): Potential<InvestorLead> {
    if (!investorLeadResponse) {
      return
    }

    return new InvestorLead(investorLeadResponse)
  }
}
