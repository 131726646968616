// @ts-strict
import { SearchParams } from '@types'
import { getFilter, getSort } from '../utils'

const filterSlugs = {
  page: 'page[number]',
  perPage: 'page[size]',
  providerLeadId: 'filter[provider_lead_id]',
  withActiveInspectionOrder: 'with_active_inspection_order'
}

const sortSlugs = {
  createdAt: 'created_at',
  type: 'inspection_type',
  businessName: 'business_name',
  scheduledAt: 'scheduled_at',
  price: 'price',
  status: 'status',
  externalId: 'external_id',
  updatedAt: 'updated_at',
  orderId: 'inspection_order_id'
}
export type InspectionsSearchParams = Omit<SearchParams, 'searchTerm'> & {
  providerLeadId?: string
  sortBy?: keyof typeof sortSlugs
  withActiveInspectionOrder?: boolean
}

export const getInspectionsSearchParams = ({
  sortDir,
  sortBy,
  providerLeadId,
  page,
  perPage,
  withActiveInspectionOrder
}: InspectionsSearchParams) => {
  const result = getFilter(filterSlugs, {
    providerLeadId,
    page,
    perPage,
    withActiveInspectionOrder
  })
  result['sort'] = getSort(sortSlugs, sortDir, sortBy)
  return result
}
