export const agentsColumnsSettings = [
  {
    name: 'Name',
    value: 'name',
    isDefault: true,
    sort: ['name'],
    columnSize: 'xlg',
    isReorderable: false,
    order: 1,
    fixed: true
  },
  {
    name: 'Programs',
    value: 'programs',
    isDefault: true,
    columnSize: 'mlg',
    isReorderable: true,
    isOptional: true,
    group: 'agentAttribute',
    order: 3
  },
  {
    name: 'Pitch Status',
    value: 'pitchStatus',
    isDefault: true,
    columnSize: 'md',
    isReorderable: true,
    isOptional: true,
    group: 'agentAttribute',
    order: 4
  },
  {
    name: 'Team Name',
    value: 'teamName',
    isDefault: true,
    columnSize: 'md',
    isReorderable: true,
    isOptional: true,
    group: 'agentAttribute',
    order: 1
  },
  {
    name: 'Preferred Escrow Officer',
    value: 'preferredEscrowOfficer',
    isDefault: true,
    columnSize: 'md',
    isReorderable: true,
    isOptional: true,
    group: 'agentAttribute',
    order: 1
  },
  {
    name: 'Referrals',
    value: 'introducedReferrals',
    sort: ['introducedReferrals'],
    isDefault: true,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsReferrals'
  },
  {
    name: 'Closed Referrals',
    value: 'closedReferrals',
    sort: ['closedReferrals'],
    isDefault: true,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsReferrals'
  },
  {
    name: 'Homes Leads',
    value: 'homesLeads',
    sort: ['homesLeads'],
    isDefault: true,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsHomes'
  },
  {
    name: 'Homes Approvals',
    value: 'homesApprovals',
    sort: ['homesApprovals'],
    isDefault: true,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsHomes'
  },
  {
    name: 'Homes Closes',
    value: 'homesCloses',
    sort: ['homesCloses'],
    isDefault: true,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsHomes'
  },
  {
    name: 'Last Update',
    value: 'lastUpdate',
    sort: ['lastUpdate'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'agentAttribute',
    order: 12
  },
  {
    name: 'Last Touch',
    value: 'lastTouch',
    sort: ['lastTouch'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'agentAttribute',
    order: 13
  },
  {
    name: 'Last Login',
    value: 'lastLogin',
    sort: ['lastLogin'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'agentAttribute',
    order: 14
  },
  {
    name: 'Homes Enrollment Date',
    value: 'homesEnrollmentDate',
    sort: ['homesEnrollmentDate'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'agentAttribute',
    order: 5
  },
  {
    name: 'Referrals Enrollment Date',
    value: 'referralsEnrollmentDate',
    sort: ['referralsEnrollmentDate'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'agentAttribute',
    order: 6
  },
  {
    name: 'Homes Activation Date',
    value: 'homesActivationDate',
    sort: ['homesActivationDate'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'agentAttribute',
    order: 9
  },
  {
    name: 'Last Homes Lead Date',
    value: 'lastHomesLeadDate',
    sort: ['lastHomesLeadDate'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'agentAttribute',
    order: 10
  },
  {
    name: 'Last Referral Lead Date',
    value: 'lastReferralLeadDate',
    sort: ['lastReferralLeadDate'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'agentAttribute',
    order: 11
  },
  {
    name: 'ALVM',
    value: 'agentLeftVmReferrals',
    sort: ['agentLeftVmReferrals'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsReferrals'
  },
  {
    name: 'Connected Referrals',
    value: 'connectedReferrals',
    sort: ['connectedReferrals'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsReferrals'
  },
  {
    name: 'MS',
    value: 'meetingScheduledReferrals',
    sort: ['meetingScheduledReferrals'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsReferrals'
  },
  {
    name: 'MIP',
    value: 'metInPersonReferrals',
    sort: ['metInPersonReferrals'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsReferrals'
  },
  {
    name: 'Listed Referrals',
    value: 'listingReferrals',
    sort: ['listingReferrals'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsReferrals'
  },
  {
    name: 'Failed Referrals',
    value: 'failedReferrals',
    sort: ['failedReferrals'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsReferrals'
  },
  {
    name: 'Escrow Opens',
    value: 'escrowOpens',
    sort: ['escrowOpens'],
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsEscrow',
    isDefault: true
  },
  {
    name: 'Escrow Closes',
    value: 'escrowCloses',
    sort: ['escrowCloses'],
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsEscrow',
    isDefault: true
  },
  {
    name: 'HLTI Leads',
    value: 'agentToHlTradeInLeads',
    sort: ['agentToHlTradeInLeads'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsHomes'
  },
  {
    name: 'HLCO Leads',
    value: 'agentToHlCashOfferLeads',
    sort: ['agentToHlCashOfferLeads'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsHomes'
  },
  {
    name: 'HLSS Leads',
    value: 'agentToHomelightSimpleSaleLeads',
    sort: ['agentToHomelightSimpleSaleLeads'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsHomes'
  },
  {
    name: 'HLTI Approvals',
    value: 'agentToHlTradeInApprovalsCompleted',
    sort: ['agentToHlTradeInApprovalsCompleted'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsHomes'
  },
  {
    name: 'HLCO Approvals',
    value: 'agentToHlCashOfferApprovalsCompleted',
    sort: ['agentToHlCashOfferApprovalsCompleted'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsHomes'
  },
  {
    name: 'HLSS Approvals',
    value: 'agentToHomelightSimpleSaleApprovalsCompleted',
    sort: ['agentToHomelightSimpleSaleApprovalsCompleted'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsHomes'
  },
  {
    name: 'HLTI Closes',
    value: 'agentToHlTradeInCloses',
    sort: ['agentToHlTradeInCloses'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsHomes'
  },
  {
    name: 'HLCO Closes',
    value: 'agentToHlCashOfferCloses',
    sort: ['agentToHlCashOfferCloses'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsHomes'
  },
  {
    name: 'HLSS Closes',
    value: 'agentToHomelightSimpleSaleCloses',
    sort: ['agentToHomelightSimpleSaleCloses'],
    isDefault: false,
    columnSize: 'sm',
    isReorderable: true,
    isOptional: true,
    group: 'metricsHomes'
  }
] as const

export type TAgentsColumnValue = (typeof agentsColumnsSettings)[number]['value']

export type AgentsColumn = typeof agentsColumnsSettings
