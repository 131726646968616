/* eslint-disable */
/* eslint-disable prettier/prettier */
// DO NOT DELETE THE COMMENT BELOW. IT'S A PLOP CONFIG!
/* PLOP_INJECT_IMPORT */
import { TableChartOutlined } from './TableChartOutlined'
import { ErrorOutlineOutlined } from './ErrorOutlineOutlined'
import { InfoOutlined } from './InfoOutlined'
import { Warning } from './Warning'
import { Pause } from './Pause'
import { PlayArrow } from './PlayArrow'
import { ChevronLeft } from './ChevronLeft'
import { ChatBubbleOutline } from './ChatBubbleOutline'
import { FilterAltOutlined } from './FilterAltOutlined'
import { ShowChart } from './ShowChart'
import { StickyNote2Outlined } from './StickyNote2Outlined'
import { OpenInNewOutlined } from './OpenInNewOutlined'
import { SearchOutlined } from './SearchOutlined'
import { EmailOutlined } from './EmailOutlined'
import { ExpandMoreOutlined } from './ExpandMoreOutlined'
import { ExpandLessOutlined } from './ExpandLessOutlined'
import { DialpadOutlined } from './DialpadOutlined'
import { CallEndOutlined } from './CallEndOutlined'
import { PhoneEnabledOutlined } from './PhoneEnabledOutlined'
import { RepeatOutlined } from './RepeatOutlined'
import { VisibilityOutlined } from './VisibilityOutlined'
import { EditOutlined } from './EditOutlined'
import { Check } from './Check'
import { Add } from './Add'
import { Canary } from './Canary'
import { Help } from './Help'
import { UpArrow } from './UpArrow'
import { Close } from './Close'
import { DownArrow } from './DownArrow'

export const paths = {
  /* PLOP_INJECT_EXPORT */
  TableChartOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  Warning,
  Pause,
  PlayArrow,
  ChevronLeft,
  ChatBubbleOutline,
  FilterAltOutlined,
  ShowChart,
  StickyNote2Outlined,
  OpenInNewOutlined,
  SearchOutlined,
  EmailOutlined,
  ExpandMoreOutlined,
  ExpandLessOutlined,
  DialpadOutlined,
  CallEndOutlined,
  PhoneEnabledOutlined,
  RepeatOutlined,
  VisibilityOutlined,
  EditOutlined,
  Check,
  Add,
  Canary,
  UpArrow,
  DownArrow,
  Close,
  Help
} as const

export type Paths = {
  [key in keyof typeof paths]: string
}

export type IconPathKeys = keyof typeof paths
