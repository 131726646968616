// @ts-strict

import { Contents, Page, PaginationParams, ViewsMenu } from 'components'
import { CreateOrderButtonForm } from 'content/Order'
import { OrdersTable } from 'content/Orders'
import { CashCloseOrdersListFilters } from 'content/Orders/OrdersList/OrdersListFilters'
import { OrdersListHeader } from 'content/Orders/OrdersList/OrdersListHeader'
import { ListProvider, OrdersProvider, QueryStringParamsProvider, useOrdersContext } from 'contexts'
import { useDocumentTitle } from 'hooks'
import { cashCloseOrdersListColumns } from 'lookups'
const actions = (
  <>
    <CashCloseOrdersListFilters />
    <ViewsMenu />
  </>
)

const PageContents = () => {
  const { handleOrderCreated, createSalesOrder, orders, pageCount, isLoading } = useOrdersContext()

  const action = <CreateOrderButtonForm mutation={createSalesOrder} onSubmit={handleOrderCreated} />

  return (
    <>
      <Page>
        <Contents title="Homes Orders" actions={action}>
          <OrdersListHeader filter={actions} />
          <OrdersTable orders={orders} isLoading={isLoading} />
          {pageCount ? <PaginationParams totalPages={pageCount} /> : null}
        </Contents>
      </Page>
    </>
  )
}

export const CashCloseOrdersPage = () => {
  useDocumentTitle('Homes Orders')

  return (
    <QueryStringParamsProvider>
      <ListProvider columns={cashCloseOrdersListColumns}>
        <OrdersProvider rootPath="/homes" scope={['cash_close', 'home_loans', 'homes']}>
          <PageContents />
        </OrdersProvider>
      </ListProvider>
    </QueryStringParamsProvider>
  )
}
