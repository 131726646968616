import { useState } from 'react'
import { FilterBar, ListSelect, PaginationParams } from 'components'
import { LocationPreferences, TransactionsMap, TransactionsTable } from 'content/Transactions'
import { Transaction } from 'models'
import styles from './TransactionsView.module.scss'

type TransactionsViewProps = {
  isLoading: boolean
  pageCount?: number
  transactions: Transaction[]
}

export const TransactionsView = ({ transactions, isLoading, pageCount }: TransactionsViewProps) => {
  const [format, setFormat] = useState<'Table' | 'Map' | 'Location Preferences'>('Table')

  const formats = {
    Table: (
      <>
        <TransactionsTable transactions={transactions} isLoading={isLoading} />
        <PaginationParams totalPages={pageCount} />
      </>
    ),
    Map: <TransactionsMap transactions={transactions} />,
    'Location Preferences': <LocationPreferences />
  }

  return (
    <>
      <FilterBar className={styles.filterBar}>
        <ListSelect
          options={[{ name: 'Table' }, { name: 'Map' }, { name: 'Location Preferences' }]}
          value={format}
          onChange={setFormat}
        />
      </FilterBar>
      {formats[format]}
    </>
  )
}
