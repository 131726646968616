import { isValidImageString } from '@foundation/utilities'
import AvatarFallback from './avatar-fallback.svg'
import * as S from './styles'
import type { AvatarAsImageProps, AvatarAsTextProps, AvatarProps } from './types'

export const Text = ({
  color = 'primary',
  title,
  children,
  size = 'sm',
  variant = 'solid',
  shadow
}: AvatarAsTextProps & AvatarProps) => {
  return (
    <S.Avatar color={color} size={size} title={title} variant={variant} shadow={shadow}>
      {children}
    </S.Avatar>
  )
}

export const Image = ({
  alt,
  src,
  title,
  size = 'sm',
  shadow
}: AvatarAsImageProps & AvatarProps) => {
  const hasValidImage = src && isValidImageString(src)

  const fallbackProps = {
    title: 'Fallback',
    src: AvatarFallback,
    alt: 'Fallback Image'
  }

  return (
    <S.Avatar title={hasValidImage ? title : fallbackProps.title} size={size} shadow={shadow}>
      <S.Image
        alt={hasValidImage ? alt : fallbackProps.alt}
        src={hasValidImage ? src : fallbackProps.src}
      />
    </S.Avatar>
  )
}

export const Avatar = {
  Text: Text,
  Image: Image
}
