type SearchParams = Record<string, any>

export const communicationKeys = {
  // all caches using this key
  all: ['communication'] as const,
  // all communications lists
  allLists: () => [...communicationKeys.all, 'list'] as const,
  globalList: (searchParams: SearchParams) =>
    [...communicationKeys.allLists(), 'global', searchParams] as const,
  thisClientList: (orderId: string, searchParams: SearchParams) =>
    [...communicationKeys.allLists(), 'thisClient', orderId, searchParams] as const,
  thisAgentList: (agentId: string, searchParams: SearchParams) =>
    [...communicationKeys.allLists(), 'thisAgent', agentId, searchParams] as const,
  thisClientLead: (id: string) => [...communicationKeys.all, 'thisClientLead', id] as const,
  thisAgent: (id: string) => [...communicationKeys.all, 'thisAgent', id] as const
}
