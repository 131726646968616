// @ts-strict

import {
  ApplyFiltersButton,
  Column,
  Columns,
  Filters,
  MenuSection,
  Section,
  SectionFooter,
  Select
} from 'components'
import { useListContext } from 'contexts'
import { useFeatureFlags, useNewFilters } from 'hooks'
import styles from './InspectionDeficiencyFilter.module.scss'

export const InspectionDeficiencyFilter = () => {
  const { newParams, set } = useNewFilters()
  const { findFilterBySlug } = useListContext()
  const category = findFilterBySlug('filterByCategory')
  const severity = findFilterBySlug('filterBySeverity')
  const escalated = findFilterBySlug('filterByEscalated')
  const inspectionType = findFilterBySlug('filterByInspectionType')
  const { salesOpsEscalatedDeficiencies } = useFeatureFlags(['sales-ops-escalated-deficiencies'])

  return (
    <Filters>
      <Section className={styles.section}>
        <Columns>
          <Column>
            <MenuSection title={category?.name}>
              <Select
                value={newParams[category?.slug!]}
                options={category?.options!}
                onChange={val => set(category?.slug!, val)}
                addBlank
              />
            </MenuSection>
            <MenuSection title={severity?.name}>
              <Select
                value={newParams[severity?.slug!]}
                options={severity?.options!}
                onChange={val => set(severity?.slug!, val)}
                addBlank
              />
            </MenuSection>
          </Column>
          <Column>
            <MenuSection title={inspectionType?.name}>
              <Select
                value={newParams[inspectionType?.slug!]}
                options={inspectionType?.options!}
                onChange={val => set(inspectionType?.slug!, val)}
                addBlank
              />
            </MenuSection>
            {salesOpsEscalatedDeficiencies.enabled && (
              <MenuSection title={escalated?.name}>
                <Select
                  value={newParams[escalated?.slug!]}
                  options={escalated?.options!}
                  onChange={val => set(escalated?.slug!, val)}
                  addBlank
                />
              </MenuSection>
            )}
          </Column>
        </Columns>
        <SectionFooter>
          <ApplyFiltersButton newParams={newParams} />
        </SectionFooter>
      </Section>
    </Filters>
  )
}
