import { useMutation, useQueryClient } from 'react-query'
import {
  CreateCheckoutSessionVariables,
  SendCheckoutSessionMagicLinkVariables
} from 'contexts/types'
import { postCheckoutSession, postCheckoutSessionMagicLink } from 'services'
import { CHECKOUT_SESSIONS_CACHE_KEY } from '../cache'

export const useCheckoutSessionsController = () => {
  const queryCache = useQueryClient()
  const defaultCallback = {
    onSuccess: () => {
      queryCache.invalidateQueries(CHECKOUT_SESSIONS_CACHE_KEY)
    }
  }

  const createCheckoutSession = useMutation(
    (payload: CreateCheckoutSessionVariables) => postCheckoutSession(payload),
    defaultCallback
  )

  const sendCheckoutSessionMagicLink = useMutation(
    (payload: SendCheckoutSessionMagicLinkVariables) => postCheckoutSessionMagicLink(payload),
    defaultCallback
  )

  return {
    createCheckoutSession,
    sendCheckoutSessionMagicLink
  }
}
