import { Block, List, TableCell as Td, TableRow as Tr } from 'components'
import { AgentLink } from 'content/Agent'
import { Transaction } from 'models'
import { formatDate, formatPrice, formatTime, structureAddress } from 'utils'

export const TransactionsTableRow = ({ transaction }: { transaction: Transaction }) => {
  const { street, cityState } = structureAddress(transaction.fullAddress)
  return (
    <Tr data-testid={`transaction-id-${transaction.id}`}>
      <Td>
        <List>
          {street}
          {cityState}
        </List>
      </Td>
      <Td>{transaction.humanizedPropertyType}</Td>
      <Td>{formatPrice(transaction.sellingPrice, 'short')}</Td>
      <Td>{transaction.sellingAgent ? <AgentLink agent={transaction.sellingAgent} /> : null}</Td>
      <Td>{transaction.listingAgent ? <AgentLink agent={transaction.listingAgent} /> : null}</Td>
      <Td>
        <Block
          title={formatDate(transaction.sellingDate)}
          subtitle={formatTime(transaction.sellingDate)}
        />
      </Td>
    </Tr>
  )
}
