import { useEffect } from 'react'
import { ActionBar, Contents, PaginationParams } from 'components'
import { ExternalLinksTable } from 'content/ExternalLinks'
import { LeadCreateExternalLinkButtonForm } from 'content/Lead'
import { ListProvider, ParamsProvider, useLeadContext } from 'contexts'
import { useExternalLinksController } from 'controllers'
import { useDocumentTitle } from 'hooks'
import { externalResourcesCashOfferListColumns, externalResourcesListColumns } from 'lookups'
import { isCCCashOfferLead, Lead } from 'models'
import { LeadDocumentsTabs } from '../LeadDocumentsTabs'

const LeadExternalLinksContent = ({ lead }: { lead: Lead }) => {
  const { externalLinksQuery, externalLinksPageCount } = useExternalLinksController(lead)

  const actions = (
    <ActionBar>
      <LeadCreateExternalLinkButtonForm lead={lead} />
    </ActionBar>
  )

  return (
    <>
      <LeadDocumentsTabs />
      <Contents title="External Resources" actions={actions}>
        <ExternalLinksTable
          lead={lead}
          isLoading={externalLinksQuery.isLoading}
          externalLinks={externalLinksQuery.data?.externalLinks}
        />
        <PaginationParams totalPages={externalLinksPageCount} />
      </Contents>
    </>
  )
}

export const LeadExternalLinksTab = () => {
  const { lead } = useLeadContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${lead.name} - Documents - External Resources`), [lead, setTitle])

  return (
    <ParamsProvider>
      <ListProvider
        columns={
          isCCCashOfferLead(lead)
            ? externalResourcesCashOfferListColumns
            : externalResourcesListColumns
        }
      >
        <LeadExternalLinksContent lead={lead} />
      </ListProvider>
    </ParamsProvider>
  )
}
