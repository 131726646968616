import { ButtonForm, FormField, TextInput, useButtonForm } from 'components'
import { useAgentContext, useAgentTeamContext } from 'contexts'
import { validatePresence } from 'utils'

export const AddAgentTeamButtonForm = () => {
  const { createAgentTeam } = useAgentTeamContext()
  const { agent } = useAgentContext()

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm({
    mutation: createAgentTeam,
    initialPayload: {
      name: `${agent.fullName}'s Team`
    },
    formValidations: {
      name: [validatePresence]
    }
  })

  return (
    <ButtonForm title="Create Agent Team" {...formProps}>
      <FormField title="Name" required>
        <TextInput
          onChange={val => onChangeAttribute('name', val)}
          name="name"
          autoComplete="off"
          value={payload.name}
          data-testid="name-field"
        />
      </FormField>
    </ButtonForm>
  )
}
