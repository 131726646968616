import { TransactionTeam } from 'models'
import { getTeamsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchTransactionTeams = async (): Promise<TransactionTeam[]> => {
  const searchParams = {
    include: 'team_memberships,team_memberships.user',
    'page[size]': 1000
  }
  const response = await getRequest(getTeamsUrl({ searchParams }))
  const teams = await deserializeAsync(response.data, { transform: TransactionTeam.create })
  return teams
}
