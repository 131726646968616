// @ts-strict
import { Marker, Point } from 'classes'
import { MasterPropertyResponse } from 'schema'
import { Model } from '../Model'

type TMasterPropertyConstructor = Omit<MasterProperty, 'toPoint' | 'toMarker' | keyof Model>

export class MasterProperty extends Model {
  cityName!: string
  county?: string
  displayAddress!: string
  latitude?: string
  lobStatus?: string
  longitude?: string
  marker?: Marker
  modelName = 'MasterProperty'
  point?: Point
  primaryLine?: string
  primaryNumber?: string
  searchId?: string
  secondaryDesignator?: string
  secondaryNumber?: string
  stateName!: string
  streetName?: string
  streetPredirection?: string
  streetSuffix?: string
  uuid!: string
  zipCode?: string
  zipCodePlus4?: string

  constructor(masterPropertyAttributes: TMasterPropertyConstructor) {
    super()
    Object.assign(this, masterPropertyAttributes)
  }

  static create(
    masterPropertyResponse: Potential<MasterPropertyResponse>
  ): Potential<MasterProperty> {
    if (!masterPropertyResponse) {
      return
    }

    const instance = new MasterProperty(masterPropertyResponse)
    instance.marker = instance.toMarker()
    instance.point = instance.toPoint()

    return instance
  }

  toMarker = () =>
    this.latitude && this.longitude
      ? new Marker({
          name: this.displayAddress,
          type: 'lead',
          point: this.toPoint(),
          color: 'yellow'
        })
      : undefined

  toPoint = () =>
    this.latitude && this.longitude
      ? new Point({
          latitude: this.latitude,
          longitude: this.longitude
        })
      : undefined
}
