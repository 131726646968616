import { byAttr } from 'utils'
import { TaskField } from './TaskField'
import { taskFieldsSettings, TTaskFieldAttribute } from './taskFieldsSettings'

export const taskFields = taskFieldsSettings.map(setting => {
  return new TaskField(setting)
})

export const getTaskField = (attr: TTaskFieldAttribute) => {
  return taskFields.find(byAttr('attr', attr))
}

export const getTaskFieldName = (attr: TTaskFieldAttribute) => {
  return getTaskField(attr)?.name
}
