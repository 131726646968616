// @ts-strict
import { DollarsAndCentsInput, DollarsAndCentsInputProps } from 'components/Form'
import { formatDollarsAndCents } from 'utils'
import { InlineEditTextInput, InlineEditTextInputProps } from '../InlineEditTextInput'

export const InlineEditDollarsAndCentsInput = (
  props: InlineEditTextInputProps & DollarsAndCentsInputProps
) => {
  return (
    <InlineEditTextInput render={DollarsAndCentsInput} format={formatDollarsAndCents} {...props} />
  )
}
