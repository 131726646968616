// @ts-strict

import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Block,
  Bubble,
  Graphic,
  Icon,
  Logo,
  LogoWithText,
  Menu,
  MenuButton,
  MenuLink,
  NotificationBanner,
  RowButton
} from 'components'
import { useCurrentUserContext, useInboxContext, useSavedSearchesContext } from 'contexts'
import { usePureCloudController, useQueuedLeadsController, useUserController } from 'controllers'
import { useLogoutUser } from 'features/auth'
import { useDocumentTitle, useFlexUi } from 'hooks'
import { useDocumentFocus, useNylasController, useUserSettings } from 'hooks'
import styles from './SiteHeader.module.scss'
import { LeadsMenu, Recent } from '.'

export const SiteHeader = () => {
  const { setBlink } = useDocumentTitle()
  const { focused } = useDocumentFocus()
  const { queue } = useQueuedLeadsController()
  const { isEnabled } = useUserSettings()
  const { pathname } = useLocation()
  const { isFlexUi } = useFlexUi()

  const {
    currentUser,
    currentUserQueues,
    leadsCount,
    isDev,
    toggleAvailability,
    readyTasksCount,
    pastDueTasksCount
  } = useCurrentUserContext()

  const { signOut } = useLogoutUser()
  const { employeeUnimpersonate } = useUserController('')

  const { unreadMessagesCount } = useInboxContext()
  const { getLastSearchUrl } = useSavedSearchesContext()
  const { nylasEnrollmentUrl } = useNylasController()

  const { pureCloudTokenActive, invalidatePureCloudTokenActive } = usePureCloudController()

  const selectedStyle = (tabName: string) => {
    const root = pathname.split('/')[1]

    const selectedRoutes = {
      Orders: ['agent-matching', 'cash-close', 'homes'],
      Agents: ['agents'],
      LoanOfficers: ['loan-officers'],
      Investors: ['investors'],
      Tasks: ['tasks'],
      Inbox: ['messages'],
      Queue: ['queue']
    }

    return selectedRoutes[tabName].includes(root) ? styles.selected : undefined
  }

  useEffect(() => {
    setBlink(currentUserQueues?.handmatch && currentUser?.available && !focused)
  }, [focused, setBlink, currentUserQueues?.handmatch, currentUser])

  const getHandmatchClassName = () => {
    if (currentUser?.available) {
      if (currentUserQueues?.handmatch) {
        return styles.pulse
      }
      return styles.normal
    }
    return styles.disabled
  }

  const handleSignOut = () => {
    signOut.mutate()
  }

  const handleUnimpersonate = () => {
    employeeUnimpersonate.mutateAsync()
  }

  const ccAppUser =
    (!currentUser?.roles.ccAppUser && isEnabled('flipDefaultOrders')) ||
    (currentUser?.roles.ccAppUser && !isEnabled('flipDefaultOrders'))
  const isAdmin = currentUser?.roles.salesAppAdmin
  const showNylas = currentUser?.isNylasEnrollmentAllowed && pathname === '/'

  const pureCloudStatus = (active: boolean) => {
    if (active) {
      return (
        <div className={styles.pureCloudStatus}>
          <Graphic.GreenDot /> PureCloud
        </div>
      )
    }
    return (
      <div className={styles.pureCloudStatus}>
        <Graphic.RedDot /> PureCloud
      </div>
    )
  }

  if (isFlexUi) {
    return null
  }

  return (
    <header
      data-testid="site-header"
      data-user-first-name={currentUser?.firstName}
      data-user-last-name={currentUser?.lastName}
      data-user-full-name={currentUser?.name}
      data-user-email={currentUser?.email}
      data-user-admin={isAdmin}
      className={styles.header}
    >
      {currentUser?.impersonated && (
        <div className={styles.headerBanner}>
          Impersonating {currentUser?.name}
          <RowButton inverted onClick={handleUnimpersonate}>
            Stop Impersonating
          </RowButton>
        </div>
      )}

      {showNylas && (
        <NotificationBanner>
          Click{' '}
          <a href={nylasEnrollmentUrl} target="_blank" rel="noreferrer">
            here
          </a>{' '}
          to enroll to Nylas
        </NotificationBanner>
      )}

      <div className={styles.headerContents}>
        <Link to="/" className={styles.logoDesktop}>
          <LogoWithText width={165} height={35} />
        </Link>
        <Link to="/" className={styles.logoMobile}>
          <Logo width={35} height={35} />
        </Link>

        <input type="checkbox" id="navCheckbox" className={styles.navCheckbox} />
        <nav className={styles.responsiveMenu}>
          <label htmlFor="navCheckbox" className={styles.navLabel}>
            <Icon.Menu className={styles.navOpenIcon} />
          </label>
        </nav>

        <nav className={styles.nav}>
          <nav className={styles.navCloseIcon}>
            <label htmlFor="navCheckbox">
              <Icon.Close className={styles.navOpenIcon} />
            </label>
          </nav>

          <LeadsMenu />

          <Link
            to={getLastSearchUrl('/agents', currentUser?.agentsDefaultQueryString)}
            className={selectedStyle('Agents')}
          >
            Agents
          </Link>
          <Link to="/admin/loan-officers" className={selectedStyle('LoanOfficers')}>
            Loan Officers
          </Link>
          <Link to="/admin/investors" className={selectedStyle('Investors')}>
            Investors
          </Link>
          <Link
            to={getLastSearchUrl(
              '/tasks',
              `?assigneeId=${currentUser?.id}&completionStatus=[ready]&attachableStatus=active`
            )}
            className={selectedStyle('Tasks')}
            data-testid="header-tasks"
          >
            Tasks
            <Bubble count={readyTasksCount} className={styles.bubble} />
            <Bubble count={pastDueTasksCount} className={styles.bubble} danger />
          </Link>
          {currentUser?.phoneTwilio && (
            <Link to="/messages" className={selectedStyle('Inbox')}>
              Inbox
              <Bubble count={unreadMessagesCount} className={styles.bubble} danger />
            </Link>
          )}
          {!ccAppUser && (
            <Link to={getLastSearchUrl('/queue')} className={selectedStyle('Queue')}>
              Queue
              <Bubble count={queue.length} className={styles.bubble} />
            </Link>
          )}
          <Recent />
        </nav>
        <div className={styles.links}>
          {currentUser && pureCloudStatus(pureCloudTokenActive)}
          {!ccAppUser && (
            <>
              <Link to="/leads/handmatch" className={getHandmatchClassName()}>
                <Block title={<Icon.Hand className={styles.hand} />} className={styles.handmatch} />
                <Bubble count={currentUserQueues?.handmatch} className={styles.bubble} />
              </Link>
              <a href="https://www.homelight.com/sales/app#/inbox" target="_blank" rel="noreferrer">
                <Graphic.Canary className={styles.canary} />
                <Bubble count={leadsCount?.total} className={styles.bubble} />
              </a>
            </>
          )}

          <Menu title={<Icon.Account />} className={styles.settingsMenu}>
            {currentUser ? (
              <MenuButton onClick={handleSignOut}>Sign Out</MenuButton>
            ) : (
              <MenuLink to="/sign-in">Sign In</MenuLink>
            )}
            {!ccAppUser && (
              <MenuButton onClick={() => toggleAvailability.mutate()}>
                {currentUser?.available ? 'Go Offline' : 'Go Online'}
              </MenuButton>
            )}

            <MenuLink to="/release-notes">Release Notes</MenuLink>
            {(isDev || isAdmin) && <MenuLink to="/settings">Settings</MenuLink>}

            {currentUser?.impersonated && (
              <MenuButton onClick={handleUnimpersonate}>Stop Impersonating</MenuButton>
            )}

            {currentUser && (
              <MenuLink to="/pure-cloud" onClick={() => invalidatePureCloudTokenActive()}>
                Login To Purecloud
              </MenuLink>
            )}
          </Menu>
        </div>
      </div>
    </header>
  )
}
