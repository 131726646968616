import { Badge } from 'components'
import { MarketplaceProgram } from 'models'
import { MarketplaceProgramAgentEnrollStatus } from 'models/MarketplaceProgramAgent/utils'
import { camelize, cx, titleize } from 'utils'
import styles from './MarketplaceProgramBadge.module.scss'

type Props = {
  program?: MarketplaceProgram
  status?: MarketplaceProgramAgentEnrollStatus
}

export const MarketplaceProgramBadge = ({ program, status }: Props) => {
  const slugStyle = styles[program?.slug] || null
  const enrollStatusStyle = status ? styles[camelize(status)] : null

  return (
    <Badge
      title={status ? titleize(`${status} in ${program?.name}`) : program?.name}
      strikeThru={status === 'declined'}
      stroke={null}
      className={cx(styles.marketplaceProgramBadge, slugStyle, enrollStatusStyle)}
    >
      {program?.abbr}
    </Badge>
  )
}
