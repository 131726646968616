import { byAttr } from 'utils'
import {
  capitalPartnerFieldsSettings,
  TCapitalPartnerFieldAttribute
} from './capitalPartnerFieldsSettings'
import { CapitalPartnerField } from './'

export const capitalPartnerFields = capitalPartnerFieldsSettings.map(setting => {
  return new CapitalPartnerField(setting)
})

export const getCapitalPartnerField = (attr: TCapitalPartnerFieldAttribute) => {
  return capitalPartnerFields.find(byAttr('attr', attr))
}

export const getCapitalPartnerFieldName = (attr: TCapitalPartnerFieldAttribute) => {
  return getCapitalPartnerField(attr)?.name
}
