import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Flex, Text } from '@foundation/components'
import { ActivityFeedInterface, ActivityFeedModel, UserResource } from 'models/v2/ActivityFeed'
import { dataOrDash } from 'utils'
import { ViewDetailsButton } from '../ViewDetailsButton/ViewDetailsButton'
import { CommsEventRow } from './CommsEventRow'

type Props = {
  activityFeed: ActivityFeedInterface
  'data-testid'?: string
  rowIndex?: number
}

const TEXTED_TEXT = 'texted'

export const CommsTextMessageEventRow = ({
  activityFeed,
  rowIndex,
  'data-testid': dataTestId
}: Props) => {
  const [isViewDetailsEnabled, setIsViewDetailsEnabled] = useState(false)
  const description = dataOrDash(activityFeed.description)
  const originalSenderUrl = ActivityFeedModel.getResourceUrl(activityFeed.meta?.senderDetails)
  const senderName = ActivityFeedModel.getNameFromUserResource(activityFeed?.meta?.senderDetails)
  const getSenderInfo = () => {
    const { senderDetails } = activityFeed.meta
    const senderPhone =
      senderDetails?.userName &&
      ActivityFeedModel.getPhoneFromUserResource(senderDetails.sms?.phone)

    return [ActivityFeedModel.getUserTypeString(activityFeed.meta.senderDetails), senderPhone]
      .filter(Boolean)
      .map(info => `(${info})`)
      .join(' ')
  }

  const recipientsNames = ActivityFeedModel.getRecipientsString(
    (activityFeed?.meta?.recipientsDetails || []).map(ActivityFeedModel.getNameFromUserResource)
  )
  const getRecipientInfo = (recipient: UserResource) => {
    const recipientPhone =
      recipient?.userName && ActivityFeedModel.getPhoneFromUserResource(recipient?.sms?.phone)
    return [ActivityFeedModel.getUserTypeString(recipient), recipientPhone]
      .filter(Boolean)
      .map(info => `(${info})`)
      .join(' ')
  }

  return (
    <CommsEventRow
      rowIndex={rowIndex}
      data-testid={dataTestId}
      aria-expanded={isViewDetailsEnabled}
      date={activityFeed.occurredAt}
      iconPath="ChatBubbleOutline"
    >
      <span title={`${senderName} ${TEXTED_TEXT} ${recipientsNames}`}>
        <Text size="xs" as="span" weight="bold" title={senderName}>
          {senderName}
        </Text>
        <Text size="xs" as="span">
          {` ${TEXTED_TEXT} `}
        </Text>
        <Text size="xs" as="span" weight="bold">
          {recipientsNames}
        </Text>
      </span>

      {isViewDetailsEnabled && (
        <>
          <Flex flexDirection="column" gap="xs" data-testid="senderDetails">
            <Text size="xs" color="neutral8">
              Sender
            </Text>
            {originalSenderUrl ? (
              <Flex gap="$1">
                <Link to={originalSenderUrl || '#'}>
                  {senderName && (
                    <Text size="xs" color="primary10" weight="semibold">
                      {`${senderName}`}
                    </Text>
                  )}
                </Link>
                <Text size="xs">{getSenderInfo()}</Text>
              </Flex>
            ) : (
              <Text size="xs" color="primary10">
                {`${senderName} ${getSenderInfo()}`}
              </Text>
            )}
          </Flex>

          <Flex flexDirection="column" gap="xs" data-testid="recipientsDetails">
            <Text size="xs" color="neutral8">
              Recipients
            </Text>

            {activityFeed.meta.recipientsDetails?.map((recipient, idx) => {
              const recipientUrl = ActivityFeedModel.getResourceUrl(recipient)
              const recipientName = ActivityFeedModel.getNameFromUserResource(recipient)

              if (!recipientUrl) {
                return (
                  <Text key={idx} size="xs">{`${recipientName} ${getRecipientInfo(
                    recipient
                  )}`}</Text>
                )
              }

              return (
                <Flex key={idx}>
                  <Link to={recipientUrl || '#'}>
                    <Text size="xs" color="primary9" weight="semibold">
                      {`${recipientName} `}
                    </Text>
                  </Link>
                  &nbsp;
                  <Text size="xs">{`${getRecipientInfo(recipient)}`}</Text>
                </Flex>
              )
            })}
          </Flex>
        </>
      )}

      {isViewDetailsEnabled && (
        <Text size="xs" color="neutral8">
          Content
        </Text>
      )}
      <Text
        size="xs"
        as="span"
        color="neutral9"
        title={description}
        hasEllipsis={!isViewDetailsEnabled}
      >
        {description}
      </Text>
      <div>
        <ViewDetailsButton
          onClick={() => setIsViewDetailsEnabled(state => !state)}
          isToggledOn={isViewDetailsEnabled}
        />
      </div>
    </CommsEventRow>
  )
}
