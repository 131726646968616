// @ts-strict

import {
  isBuyerLead,
  isCCCashOfferLead,
  isCCTradeInLead,
  isEscrowLead,
  isMortgageLead,
  isSellerLead,
  Lead,
  Order
} from 'models'

const ONE_TI_PER_ORDER_MSG = 'Only 1 Trade In per order'
const ONE_CO_PER_ORDER_MSG = 'Only 1 Cash Offer per order'

export const getLeadUserTypeValidationMessage = (lead: Lead, order: Order) => {
  const oneTIPerOrderMsg = order.hasCCTradeInLead() && ONE_TI_PER_ORDER_MSG
  const oneCOPerOrderMsg = order.hasCCCashOfferLead() && ONE_CO_PER_ORDER_MSG

  if (isBuyerLead(lead) || isCCTradeInLead(lead)) {
    return oneCOPerOrderMsg
  }

  if (isSellerLead(lead) || isCCCashOfferLead(lead)) {
    return oneTIPerOrderMsg
  }

  if (isMortgageLead(lead) || isEscrowLead(lead)) {
    return [oneTIPerOrderMsg, oneCOPerOrderMsg].filter(Boolean).join(' / ')
  }
}
