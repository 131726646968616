import {
  FormField,
  FormFieldSet,
  PhoneInput,
  Section,
  Sections,
  Select,
  TextInput
} from 'components'
import { useTransactionTeams } from 'lookups'
import { TLoanOfficerFormPayload } from './utils'

type TProps = {
  isEditing?: boolean
  onChangeAttribute: <K extends keyof TLoanOfficerFormPayload>(
    key: K,
    value: TLoanOfficerFormPayload[K]
  ) => void
  payload: TLoanOfficerFormPayload
}

export const LoanOfficerFormFields = ({
  payload,
  onChangeAttribute,
  isEditing = false
}: TProps) => {
  const { transactionTeamsOptions } = useTransactionTeams()

  return (
    <FormFieldSet>
      <Sections>
        <Section>
          {isEditing && (
            <FormField title="Email" htmlFor="loanOfficerEmail">
              {payload.loanOfficerEmail}
            </FormField>
          )}
          <FormField title="Name" htmlFor="loanOfficerName" required>
            <TextInput
              id="loanOfficerName"
              onChange={value => onChangeAttribute('loanOfficerName', value)}
              name="loanOfficerName"
              autoComplete="off"
              value={payload.loanOfficerName}
              data-testid="loan-officer-name-field"
            />
          </FormField>
          {!isEditing && (
            <FormField title="Email" htmlFor="loanOfficerEmail" required>
              <TextInput
                id="loanOfficerEmail"
                onChange={value => onChangeAttribute('loanOfficerEmail', value)}
                name="loanOfficerEmail"
                autoComplete="off"
                value={payload.loanOfficerEmail}
                data-testid="email-field"
              />
            </FormField>
          )}
          <FormField title="Phone Number" htmlFor="loanOfficerPhoneNumber" required>
            <PhoneInput
              id="loanOfficerPhone"
              onChange={value => onChangeAttribute('loanOfficerPhone', value)}
              name="loanOfficerPhone"
              autoComplete="off"
              value={payload.loanOfficerPhone}
              data-testid="phone-number-field"
            />
          </FormField>
          <FormField title="Company Name" htmlFor="companyName">
            <TextInput
              id="companyName"
              onChange={value => onChangeAttribute('companyName', value)}
              name="companyName"
              autoComplete="off"
              value={payload.companyName}
              data-testid="company-name-field"
            />
          </FormField>
          <FormField title="NMLS ID" htmlFor="loanOfficerNmlsId">
            <TextInput
              id="loanOfficerNmlsId"
              onChange={value => onChangeAttribute('loanOfficerNmlsId', value ? value : null)}
              name="loanOfficerNmlsId"
              autoComplete="off"
              value={payload.loanOfficerNmlsId}
              data-testid="loan-officer-nmls-id-field"
            />
          </FormField>
          <FormField title="Transaction Team" htmlFor="transactionTeam">
            <Select
              id="transactionTeam"
              options={transactionTeamsOptions}
              value={payload.loanOfficerTransactionTeamId || 0}
              placeholder="Select a Transaction Team"
              onChange={value => {
                onChangeAttribute('loanOfficerTransactionTeamId', value)
              }}
            />
          </FormField>
        </Section>
      </Sections>
    </FormFieldSet>
  )
}
