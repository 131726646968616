import { useEffect, useState } from 'react'
import { Button } from '@foundation/components'
import {
  ActionButton,
  Block,
  ConfirmationPopup,
  Contents,
  DownloadButton,
  ExternalLink,
  FormField,
  InlineEditDollarsAndCentsInput,
  InlineEditPercentageInput,
  Button as LegacyButton,
  Section,
  Sections
} from 'components'
import { CCTradeInLeadPublishReceipt } from 'content/CCTradeInLead'
import { getFieldValues } from 'content/Lead/LeadDetails/utils'
import { useLeadContext } from 'contexts'
import { environment } from 'environment'
import { useDocumentTitle, useFeatureFlags, useFileUploadsController } from 'hooks'
import { isCCTradeInLead } from 'models'
import { dataOrDash, formatDateTime, formatMoney, getPercentageValue } from 'utils'

export const LeadEconomicModelTab = () => {
  const { salesAppTradeInReceipt } = useFeatureFlags(['sales-app-trade-in-receipt'])
  const { lead, createEconomicModel, updateTradeInLead } = useLeadContext()
  const { setTitle } = useDocumentTitle()
  const { fileUploads } = useFileUploadsController({
    leadId: lead.id,
    fileType: 'document',
    perPage: 1,
    // this must be a hard coded name to get the TI receipt file
    filterByName: 'Trade_In_Receipt.pdf'
  })
  const [showPublishReceipt, setShowPublishReceipt] = useState(false)
  const externalResource = fileUploads?.[0]

  useEffect(() => setTitle(`${lead.name} - Lead Economic Model`), [lead, setTitle])

  if (!isCCTradeInLead(lead)) {
    console.error('Lead must be an instance of TradeInLead')
    return null
  }

  const tradeInLead = lead.tradeInProviderLead.tradeInLead
  const expenses = lead.tradeInProviderLead.providerLeadExpenses

  const { getFieldValue, getFieldPercentageValue } = getFieldValues(tradeInLead)

  const action = !!expenses.length ? (
    <LegacyButton>
      <ExternalLink href={`${environment.planningAppURL}/${lead.tradeInProviderLead.id}`}>
        Open Estimate Calculator
      </ExternalLink>
    </LegacyButton>
  ) : (
    <ConfirmationPopup
      title="Create Economic Model"
      buttonComponent={props => (
        <Button variant="outline" color="neutralLight" onClick={props.onClick}>
          Create Economic Model
        </Button>
      )}
      message="Click 'Submit' to generate all expenses and calculated fields required to support the Economic Model for this Trade In Lead."
      mutation={createEconomicModel}
      initialPayload={{ id: lead.tradeInProviderLead.id }}
    />
  )

  const updateTradeIn = (payload: Record<string, any>) => {
    updateTradeInLead.mutate({
      id: tradeInLead.id,
      payload
    })
  }

  const getDollarsAndCentsInputProps = (key: keyof typeof tradeInLead) => ({
    onBlur: (val: string) =>
      updateTradeIn({
        [key]: val
      }),
    value: getFieldValue(key)
  })

  const onHandlePublish = () => {
    setShowPublishReceipt(false)
  }

  return (
    <Contents title="Economic Model" actions={action}>
      <Sections title="Estimated Trade-In Receipt">
        <Section>
          <FormField inline title="HL Valuation">
            {formatMoney(tradeInLead.departingPropertyValuation)}
          </FormField>
          <FormField inline title="Risk Adjusted Value">
            {formatMoney(tradeInLead.departingPropertyGuaranteedPrice)}
          </FormField>
          <FormField inline title="Total Costs">
            {formatMoney(tradeInLead.dpTargetTotalCosts)}
          </FormField>
          <FormField inline title="First Position Lien Balance">
            {formatMoney(tradeInLead.departingPropertyExternalLoanPayoff)}
          </FormField>
          <FormField inline title="Solar Loan Balance">
            {formatMoney(tradeInLead.departingPropertySolarLoanBalance)}
          </FormField>
          <FormField inline title="Solar Lease Payoff">
            {formatMoney(tradeInLead.dpSolarLeasePayoff)}
          </FormField>
          <FormField inline title="Closing Costs">
            {formatMoney(tradeInLead.dpTargetClientClosingCosts)}
          </FormField>
          <FormField inline title="Equity Unlock Amount">
            {formatMoney(tradeInLead.dpTargetNewHomePurchaseEquity)}
          </FormField>
          <FormField inline title="Second mortgage balance">
            {formatMoney(tradeInLead.dp_second_mortgage_balance)}
          </FormField>
          <FormField inline title="HELOC balance">
            {formatMoney(tradeInLead.dp_heloc_balance)}
          </FormField>
        </Section>
        <Section>
          <FormField inline title="Agent Fees">
            {formatMoney(tradeInLead.dpTotalAgentCommission)}
          </FormField>
          <FormField inline title="Property Ownership Costs">
            {formatMoney(tradeInLead.departingPropertyOwnershipExpenses)}
          </FormField>
          <FormField inline title="Transaction Costs">
            {formatMoney(tradeInLead.departingPropertyTransactionCosts)}
          </FormField>
          <FormField inline title="Repair Costs & Valuation Adj">
            {formatMoney(tradeInLead.departingPropertyRepairCosts)}
          </FormField>
          <FormField inline title="Listing Prep Work">
            {formatMoney(tradeInLead.departingPropertyListingPrepFee)}
          </FormField>
          <FormField inline title="BBYS Fee">
            {formatMoney(tradeInLead.dpTargetTradeInFee)}
          </FormField>
          <FormField inline title="Loan Payoff Value">
            {formatMoney(tradeInLead.dp_actual_loan_payoff)}
          </FormField>
          <FormField inline title="Non 1st Position Balances">
            {formatMoney(tradeInLead.dp_non_first_position_balances)}
          </FormField>
          <FormField inline title="Other Liens">
            {formatMoney(tradeInLead.dp_other_liens_balance)}
          </FormField>
          <FormField inline title="Max equity for downpayment">
            {formatMoney(tradeInLead.dp_max_downpayment_equity)}
          </FormField>
        </Section>
      </Sections>
      <Sections
        title="Actual Trade-In Receipt"
        actions={
          salesAppTradeInReceipt.enabled && (
            <ActionButton onClick={() => setShowPublishReceipt(true)}>
              Publish Trade-In Receipt
            </ActionButton>
          )
        }
      >
        <Section>
          <FormField title="Sale Price" inline>
            {formatMoney(tradeInLead.departingPropertyActualSalePrice)}
          </FormField>
          <FormField title="Total Costs" inline>
            {formatMoney(tradeInLead.dpTotalActualCosts)}
          </FormField>
          <FormField title="Total Proceeds From Final Sale" inline>
            {formatMoney(tradeInLead.dpTotalProceedsFromFinalSale)}
          </FormField>
          <FormField title="Estimated Upfront Proceeds" inline>
            {formatMoney(tradeInLead.dpUpfrontProceedsToClient)}
          </FormField>
          <FormField title="Client Upside Proceeds" inline>
            {formatMoney(tradeInLead.departingPropertyFundsWiredAmount)}
          </FormField>
          <FormField title="Actual Equity for New Home Purchase" inline>
            {formatMoney(tradeInLead.dpActualNewHomePurchaseEquityHl)}
          </FormField>
          <FormField title="Total Equity" inline>
            {formatMoney(tradeInLead.dpTotalEquity)}
          </FormField>
          <FormField title="Latest Export" inline>
            {!!externalResource ? (
              <Block
                title={
                  <DownloadButton url={externalResource.fileAttachment.downloadUrl}>
                    {externalResource.name}
                  </DownloadButton>
                }
                subtitle={`Exported ${formatDateTime(externalResource.createdAt)}`}
              />
            ) : (
              dataOrDash(null)
            )}
          </FormField>

          {showPublishReceipt && (
            <CCTradeInLeadPublishReceipt
              lead={lead}
              onCancel={() => setShowPublishReceipt(false)}
              file={externalResource}
              onSuccess={onHandlePublish}
            />
          )}
        </Section>
        <Section>
          <FormField title="Agent Fees" inline>
            {formatMoney(tradeInLead.dpActualAgentFees)}
          </FormField>
          <FormField title="SIP Costs" inline>
            {formatMoney(tradeInLead.dpActualSipCosts)}
          </FormField>
          <FormField title="Property Ownership Costs" inline>
            {formatMoney(tradeInLead.dpActualTotalPropertyOwnership)}
          </FormField>
          <FormField title="Transaction Costs" inline>
            {formatMoney(tradeInLead.dpActualTotalTransactionCosts)}
          </FormField>
          <FormField title="Repair Costs & Valuation Adj" inline>
            {formatMoney(tradeInLead.dpActualTotalRepairCostsAndValuationAdjustments)}
          </FormField>
          <FormField title="Listing Prep Work" inline>
            {formatMoney(tradeInLead.dpActualTotalListingPrepWork)}
          </FormField>
          <FormField title="Trade-In Fee" inline>
            {formatMoney(lead.calculatedValue)}
          </FormField>
        </Section>
      </Sections>
      <Sections title="Actual Costs Breakdown">
        <Section>
          <FormField title="Agent Fees" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualAgentFees')}
            />
          </FormField>
          <FormField title="Trade-In Fee % (<= 30 Days)" inline>
            <InlineEditPercentageInput
              onBlur={val =>
                updateTradeIn({
                  dpActualFeeFirstThirtyDays: getPercentageValue(val)
                })
              }
              value={getFieldPercentageValue('dpActualFeeFirstThirtyDays')}
            />
          </FormField>
          <FormField title="Trade-In Fee % (> 30 Days)" inline>
            <InlineEditPercentageInput
              onBlur={val =>
                updateTradeIn({
                  dpActualFeeProratedAfterThirtyDays: getPercentageValue(val)
                })
              }
              value={getFieldPercentageValue('dpActualFeeProratedAfterThirtyDays')}
            />
          </FormField>
          <FormField title="Property Taxes" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualPropertyTaxes')}
            />
          </FormField>
          <FormField title="Property Insurance" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualPropertyInsurance')}
            />
          </FormField>
          <FormField title="HOA Document Fees" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualHoaDocumentFees')}
            />
          </FormField>
          <FormField title="HOA Transfer Fees" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualHoaTransferFees')}
            />
          </FormField>
          <FormField title="HOA Dues" inline>
            <InlineEditDollarsAndCentsInput {...getDollarsAndCentsInputProps('dpActualHoaDues')} />
          </FormField>
          <FormField title="Utilities" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualUtilities')}
            />
          </FormField>
          <FormField title="City Transfer Taxes" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualCityTransferTaxes')}
            />
          </FormField>
          <FormField title="Home Warranty Policy" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualHomeWarrantyPolicy')}
            />
          </FormField>
          <FormField title="Actual Loan Payoff - HomeLight" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualLoanPayoff')}
            />
          </FormField>
          <FormField title="Actual Solar Loan Balance" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualSolarLoanBalance')}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="Inspection" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualInspection')}
            />
          </FormField>
          <FormField title="Additional Repairs" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualAdditionalRepairs')}
            />
          </FormField>
          <FormField title="Credits Given to Buyer" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualCreditsGivenToBuyer')}
            />
          </FormField>
          <FormField title="Credits Given to Seller" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualCreditsGivenToSeller')}
            />
          </FormField>
          <FormField title="Services Billed From Escrow" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualServicesBilledFromEscrow')}
            />
          </FormField>
          <FormField title="Staging" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualStagingCosts')}
            />
          </FormField>
          <FormField title="Cleaning" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualCleaningCosts')}
            />
          </FormField>
          <FormField title="Painting" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualPaintingCosts')}
            />
          </FormField>
          <FormField title="Flooring" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualFlooringCosts')}
            />
          </FormField>
          <FormField title="Repairs" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualRepairsCosts')}
            />
          </FormField>
          <FormField title="Other Listing Costs" inline>
            <InlineEditDollarsAndCentsInput
              {...getDollarsAndCentsInputProps('dpActualOtherListingCosts')}
            />
          </FormField>
          <FormField title="SIP Costs" inline>
            <InlineEditDollarsAndCentsInput {...getDollarsAndCentsInputProps('dpActualSipCosts')} />
          </FormField>
        </Section>
      </Sections>
    </Contents>
  )
}
