import { MouseEvent, ReactNode, useEffect, useState } from 'react'
import { OVERLAY_ELEMENT_ID } from '@foundation/components'
import { Portal } from 'components/Portal'
import * as S from './styles'

type Props = {
  children: ReactNode
  className?: string
  onCancel: (val) => void
}
// THIS IS DEPRECATED! PLEASE, USE THE DIALOG COMPONENT
export const Modal = ({ onCancel, className, children }: Props) => {
  const [down, setDown] = useState<boolean>(false)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => document.body.classList.remove('modal-open')
  }, [])

  const handleDown = (e: MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget === e.target) {
      setDown(true)
    }
  }

  const handleOnCancel = (e: MouseEvent<HTMLDivElement>) => {
    if (down && e.currentTarget === e.target) {
      setDown(false)
      onCancel(e)
    } else {
      setDown(false)
    }
  }

  return (
    <Portal>
      <S.Overlay
        id={OVERLAY_ELEMENT_ID}
        className={className}
        role="presentation"
        onMouseDown={handleDown}
        onMouseUp={handleOnCancel}
        data-testid="modal"
      >
        {children}
      </S.Overlay>
    </Portal>
  )
}
