import { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { cx } from 'utils'
import styles from './Table.module.scss'

export type TTableProps = {
  alternatingColumns?: boolean
  alternatingRows?: boolean
  children: ReactNode
  className?: string
  'data-testid'?: string
  hasFixedHeader?: boolean
  title?: string
  wrapperClassName?: string
}

const MAX_TABLE_HEIGHT = 540
const MAX_TABLE_CHILDREN = 10

export const Table = ({
  className,
  children,
  wrapperClassName,
  title,
  hasFixedHeader = true,
  alternatingColumns,
  alternatingRows,
  ...rest
}: TTableProps) => {
  const tableRef = useRef(null)
  const [shouldHaveHeight, setShouldHaveHeight] = useState(false)

  useLayoutEffect(() => {
    setShouldHaveHeight(false)
    const tableResults = children ? children[1] : undefined
    const numberOfRows = tableResults?.props?.children?.length

    if (tableRef.current.clientHeight > MAX_TABLE_HEIGHT && numberOfRows > MAX_TABLE_CHILDREN) {
      setShouldHaveHeight(true)
    }
  }, [children])

  const classes = cx(
    styles.wrapper,
    wrapperClassName,
    shouldHaveHeight && styles.tableScrollHeight,
    hasFixedHeader && styles.fixedTable,
    !alternatingRows && alternatingColumns && styles.alternatingColumns,
    alternatingRows && styles.alternatingRows
  )

  return (
    <div className={classes} {...rest}>
      {title && (
        <div className={styles.title} role="heading" aria-level={4}>
          {title}
        </div>
      )}
      <table ref={tableRef} className={cx(styles.table, className)}>
        {children}
      </table>
    </div>
  )
}

type TTableHeaderRowProps = {
  children: ReactNode
  className?: string
}

export const TableHeaderRow = ({ className, children }: TTableHeaderRowProps) => (
  <thead className={cx(styles.thead, className)}>
    <tr>{children}</tr>
  </thead>
)
type TTableBodyProps = {
  children: ReactNode
  className?: string
}

export const TableBody = ({ className, children }: TTableBodyProps) => (
  <tbody className={cx(styles.tbody, className)}>{children}</tbody>
)
