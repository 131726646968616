import { Link } from 'react-router-dom'
import { Block, List, TableCell as Td, TableRow as Tr } from 'components'
import { AgentProviderLeadServicesOpportunitiesSelector } from 'content/AgentProviderLead'
import { useQueryClient } from 'hooks'
import { AgentLead } from 'models'
import { d, formatDate, formatPrice, formatTime, structureAddress } from 'utils'

export const AgentLeadsTableRow = ({ agentLead }: { agentLead: AgentLead }) => {
  const providerLead = agentLead.providerLead
  const { street, cityState } = structureAddress(providerLead.lead.fullAddress)

  const queryCache = useQueryClient()
  const clearCache = () => queryCache.invalidateQueries(['agentLeads', agentLead.agent.id])

  return (
    <Tr data-testid={`lead-id-${providerLead.id}`}>
      <Td data-testid="lead-business-unit">
        <Block
          title={<Link to={providerLead.lead.getPathname()}>{providerLead.lead.name}</Link>}
          subtitle={providerLead.lead.userType}
        />
      </Td>
      <Td>
        <List>
          {street}
          {cityState}
        </List>
      </Td>
      <Td>{formatPrice(providerLead.lead.price, 'short')}</Td>
      <Td>
        <AgentProviderLeadServicesOpportunitiesSelector
          agentProviderLead={providerLead}
          onChange={clearCache}
        />
      </Td>
      <Td data-testid="cellStage">
        <Block
          title={d(providerLead.stageAndFurthestStage)}
          subtitle={
            <List>
              {providerLead.stage !== providerLead.lead.stage ? d(providerLead.lead.stage) : null}
              {agentLead.workedByAgent !== undefined ? (
                <Link
                  to={agentLead.workedByAgent.getPathname()}
                >{`Worked by ${agentLead.workedByAgent.name}`}</Link>
              ) : null}
            </List>
          }
        />
      </Td>
      <Td>
        <Block
          title={formatDate(providerLead.lead.lastStageUpdate)}
          subtitle={formatTime(providerLead.lead.lastStageUpdate)}
        />
      </Td>
      <Td>
        <Block
          title={formatDate(providerLead.lead.introSentAt)}
          subtitle={formatTime(providerLead.lead.introSentAt)}
        />
      </Td>
    </Tr>
  )
}
