import { useLocation } from 'react-router-dom'
import { Button } from '@foundation/components'
import { Contents, Page, PaginationParams } from 'components'
import { OrdersTable } from 'content/Orders'
import { OrdersListHeader } from 'content/Orders/OrdersList/OrdersListHeader'
import { OrdersProvider, useCurrentUserContext, useOrdersContext } from 'contexts'
import { ListProvider } from 'contexts'
import { useQueuedLeadsController } from 'controllers'
import { useDocumentTitle } from 'hooks'
import { queueOrdersListColumns, useOrdersListSearchFilters } from 'lookups'

const PageContents = () => {
  const { orders, pageCount, isLoading } = useOrdersContext()
  const { getNextHandmatchLead } = useQueuedLeadsController()
  const { currentUserQueues } = useCurrentUserContext()

  const getNextHandmatchLeadButton = (
    <Button
      variant="outline"
      color="neutralLight"
      onClick={getNextHandmatchLead}
      isDisabled={!currentUserQueues?.handmatch}
    >
      Next Handmatch
    </Button>
  )

  return (
    <Page>
      <Contents title="Order Queue" actions={getNextHandmatchLeadButton}>
        <OrdersListHeader />
        <OrdersTable orders={orders} isLoading={isLoading} />
        {pageCount ? <PaginationParams totalPages={pageCount} /> : null}
      </Contents>
    </Page>
  )
}

export const QueueOrdersPage = () => {
  const location = useLocation()
  useDocumentTitle('Order Queue')
  const { queueIds } = useQueuedLeadsController()
  const filters = useOrdersListSearchFilters()

  return (
    <OrdersProvider rootPath={location.pathname} ids={queueIds}>
      <ListProvider columns={queueOrdersListColumns} filters={filters}>
        <PageContents />
      </ListProvider>
    </OrdersProvider>
  )
}
