import { TFetchItems } from 'services'

export const getNextPageParam = (
  lastPage: TFetchItems<unknown>,
  allPages: TFetchItems<unknown>[]
) => {
  const totalPages = lastPage.pageCount
  const nextPage = allPages.length + 1
  return nextPage <= totalPages ? nextPage : undefined
}
