// @ts-strict
import { ReactElement } from 'react'
import { Icon, If, Tag, TagTypes } from 'components'
import useIsBbysEligible from 'hooks/useIsBbysEligible'
import { MortgageLead, SellerLead } from 'models'
import { titleize } from 'utils'

const creditScoreValidOptions = ['dont_know', 'over_740', '680_739', '620_679']

const getTagType = ({
  drStateEligible,
  invalidFields,
  lead
}: {
  drStateEligible?: boolean
  invalidFields: boolean
  lead?: SellerLead | MortgageLead
}): TagTypes => {
  if (!lead) {
    return 'active'
  }
  if (
    !drStateEligible ||
    invalidFields ||
    (lead?.callReview?.fhaVaLoan && lead?.callReview?.fhaVaLoan !== 'no') ||
    lead?.sellerBbysEligible === 'not_eligible'
  ) {
    return 'danger'
  } else if (
    lead?.eligibilityDetails?.eligibilityStatus === 'potentially_eligible' ||
    lead?.eligibilityDetails?.eligibilityStatus === 'property_not_found'
  ) {
    return 'warning'
  } else if (lead?.eligibilityDetails?.eligibilityStatus === 'requires_info') {
    return 'info'
  }
  return 'active'
}

const getErrors = ({
  drStateEligible,
  lead
}: {
  drStateEligible?: boolean
  lead?: SellerLead | MortgageLead
}): string[] => {
  if (!lead) {
    return []
  }

  const errors = lead.eligibilityDetails?.humanReadableErrors() || []

  if (!drStateEligible) {
    errors.push('DR State is not eligible')
  }
  if (lead.callReview?.primaryResidence === false) {
    errors.push('Only primary residences are eligible')
  }
  if (
    lead.callReview?.creditScore &&
    !creditScoreValidOptions.includes(lead.callReview?.creditScore)
  ) {
    errors.push('Credit Score should be greater than 620')
  }
  if (lead.callReview?.ageRestrictedCommunity === true) {
    errors.push("Can't be age restricted community")
  }
  if (
    lead.callReview?.fhaVaLoan === true ||
    (typeof lead.callReview?.fhaVaLoan === 'string' && lead.callReview?.fhaVaLoan !== 'no')
  ) {
    errors.push("Can't have IR FHA/VA Loan")
  }

  return errors
}

const EligibilityBadge = ({
  emptyState,
  lead
}: {
  emptyState?: ReactElement
  lead?: SellerLead | MortgageLead
}) => {
  const { isDrStateEligible: drStateEligible, hasInvalidFields: invalidFields } = useIsBbysEligible(
    { lead }
  )
  const errors = getErrors({ lead, drStateEligible })
  const tooltipText = () => {
    if (errors.length !== 0) {
      return (
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )
    }

    return null
  }

  const tagType = getTagType({ drStateEligible, invalidFields, lead })

  if (!lead?.eligibilityDetails && !invalidFields && drStateEligible) {
    return emptyState || null
  }

  return (
    (tagType && (
      <Tag as={tagType} size="xsmall" tooltipText={tooltipText()}>
        {invalidFields || !drStateEligible ? 'Not Eligible' : titleize(lead?.sellerBbysEligible)}
        <If test={tooltipText()}>
          <span>
            <Icon.QuestionCircle width={16} height={16} style={{ verticalAlign: 'middle' }} />
          </span>
        </If>
      </Tag>
    )) ||
    emptyState ||
    null
  )
}

export default EligibilityBadge
