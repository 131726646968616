// @ts-strict
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions
} from '@floating-ui/react'

type Props = {
  isOpen: boolean
  onOpenChange?: (isOpen: boolean) => void
}

export const useDateInput = ({ isOpen, onOpenChange }: Props) => {
  const { x, y, reference, floating, strategy, context } = useFloating({
    open: isOpen,
    onOpenChange,
    middleware: [
      // distance between the trigger element and the popover. Access the docs for more info
      offset(5),
      // changes the placement of the floating element to the opposite one by default in order to keep it in view. Access the docs for more info
      flip(),
      // moves the floating element along the specified axes in order to keep it in view. Access the docs for more info
      shift({ padding: 8 })
    ],
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, {
      // whether to dismiss the floating element upon scrolling an overflow ancestor
      ancestorScroll: true
    })
  ])

  const triggerProps = { ...getReferenceProps({ ref: reference }) }

  const floatingProps = {
    ref: floating,
    context,
    style: {
      position: strategy,
      top: y ?? 0,
      left: x ?? 0
    },
    ...getFloatingProps()
  }

  return { triggerProps, floatingProps, context }
}
