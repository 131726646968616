export class Stat {
  params?: Record<string, string | string[]>
  name: string
  value: number
  position: number
  onClick?: () => void

  constructor(statAttributes: Stat) {
    Object.assign(this, statAttributes)
  }
}
