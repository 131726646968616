import {
  cashOfferProviderLeadStagesSettings,
  TCashOfferProviderLeadStagesSetting
} from '../lookupCashOfferProviderLeadStages'

export type TCcCashOfferLeadStagesSlug = 'new' | 'connected' | 'pitched' | 'nurture' | 'failed'

export type TCcCashOfferLeadStageSetting = {
  name: string
  nextStageSlugs?: TCcCashOfferLeadStagesSlug[]
  other?: boolean
  slug: TCcCashOfferLeadStagesSlug
  validPreviousStage?: boolean
}

export const ccCashOfferLeadStagesSettings: Array<
  TCcCashOfferLeadStageSetting | TCashOfferProviderLeadStagesSetting
> = [
  {
    name: 'New',
    slug: 'new',
    validPreviousStage: true,
    nextStageSlugs: ['connected']
  },
  {
    name: 'Connected',
    slug: 'connected',
    validPreviousStage: true,
    nextStageSlugs: ['pitched']
  },
  {
    name: 'Pitched',
    slug: 'pitched',
    validPreviousStage: true
  },
  ...cashOfferProviderLeadStagesSettings.map(setting => ({
    ...setting,
    validPreviousStage: false
  })),
  { name: 'Nurture', slug: 'nurture', other: true },
  { name: 'Failed', slug: 'failed', other: true }
]
