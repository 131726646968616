//@ts-strict
import { PostAgentPhoneNumber } from 'services/Agents'
import { presence } from 'utils'

export type TAgentFormPayload = {
  assignedAreaId: string
  brokerage: string
  eliteStatus?: string
  email1: string
  email2: Potential<string>
  firstName: string
  lastName: string
  licenseNumber: string
  mlsIdentifier?: string
  phoneMobile: string
  phoneMobileId?: string
  phoneOffice: string
  phoneOfficeId?: string
  stateId: string
  website: Potential<string>
}

export const getAssignedAreaType = (assignedAreaId: string): 'Metroplex' | 'State' =>
  assignedAreaId.length <= 2 ? 'State' : 'Metroplex'

export const transformPayload = (payload: TAgentFormPayload) => {
  const assignedAreaType = getAssignedAreaType(payload.assignedAreaId)

  const phoneNumbers: PostAgentPhoneNumber[] = [
    { number: payload.phoneMobile, phoneType: 'cell', id: payload.phoneMobileId }
  ]
  if (presence(payload.phoneOffice)) {
    phoneNumbers.push({
      number: payload.phoneOffice,
      phoneType: 'office',
      id: payload.phoneOfficeId
    })
  }

  const eliteStatus = payload?.eliteStatus !== 'active' ? payload?.eliteStatus : undefined

  return {
    firstName: payload.firstName,
    lastName: payload.lastName,
    email1: payload.email1,
    email2: presence(payload.email2) || '',
    licenseNumber: payload.licenseNumber,
    brokerage: payload.brokerage,
    website: presence(payload.website) || '',
    stateId: payload.stateId,
    assignedAreaId: payload.assignedAreaId,
    assignedAreaType: assignedAreaType,
    mlsIdentifier: presence(payload.mlsIdentifier) || '',
    eliteStatus,
    phoneNumbers
  }
}
