import { BuyerLead, getLead, SellerLead } from 'models'
import { getHapiRequestParams, LeadSchema } from 'schema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getPageCount, getRequest } from 'utils'
import { TFetchItems } from '../../types'
import {
  BuyerAndSellerLeadsSearchParams,
  getBuyerAndSellerLeadsSearchParams
} from './getBuyerAndSellerLeadsSearchParams'

const leadResponseShape: LeadSchema = {
  lead: [
    'partnerName',
    'createdAt',
    'email',
    'fullAddress',
    'lastStageUpdate',
    'name',
    'price',
    'stage',
    'userType',
    'needsListingReview',
    {
      tasks: [
        'title',
        'completedAt',
        'readyAt',
        'requiredForLeadStage',
        'templateSlug',
        'dismissedAt',
        'type',
        {
          'assignee:user': ['name']
        }
      ]
    },
    { pendingActions: ['name', 'createdAt'] },
    {
      order: [{ leads: ['userType'] }]
    },
    {
      houseCanaryStatusCache: ['status']
    },
    {
      'agentProviderLeads:providerLead': [
        'stage',
        'introSentAt',
        {
          servicesOpportunities: [
            'productType',
            { 'status:servicesOpportunityStatus': ['name', 'slug'] }
          ]
        },
        {
          'providable:agentLead': [
            'createdAt',
            'introduced',
            'introSentAt',
            'stage',
            { agent: ['firstName', 'lastName'] }
          ]
        }
      ]
    }
  ]
}

export const fetchBuyerAndSellerLeads = async (
  settings: BuyerAndSellerLeadsSearchParams
): Promise<TFetchItems<SellerLead | BuyerLead>> => {
  const url = getLeadsUrl({
    searchParams: {
      ...getBuyerAndSellerLeadsSearchParams(settings),
      ...getHapiRequestParams(leadResponseShape)
    }
  })
  const response = await getRequest(url)

  const leads = await deserializeAsync<(SellerLead | BuyerLead)[]>(response.data, {
    transform: getLead
  })

  return {
    items: leads,
    pageCount: getPageCount(response.data.meta.total_leads, settings.perPage),
    totalCount: response.data.meta.total_leads || 0
  }
}
