import { Option } from 'classes'
import { useLookupsController } from 'controllers'

export const useRoles = () => {
  const [roleListing, findRoleBySlug] = useLookupsController('role')

  const roleOptions = roleListing.map((role: TLookupType) => new Option(role))

  return {
    roleOptions,
    findRoleBySlug
  }
}
