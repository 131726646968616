// @ts-strict
import { InvestorPayPerLeadPaymentInformation } from 'models'
import { getPplInvestorPaymentInfosUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchInvestorPayPerLeadPaymentInformation = async (providerId: string) => {
  const url = getPplInvestorPaymentInfosUrl({
    id: providerId
  })

  const response = await getRequest(url)

  return deserializeAsync(response.data, { transform: InvestorPayPerLeadPaymentInformation.create })
}
