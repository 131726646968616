// @ts-strict
import { Icon } from 'components'
import { InlineIconButton } from 'components/Button/IconButton'
import { useTaskController } from 'controllers'
import { Task } from 'models'

type Tprops = {
  task: Task
}

export const ResendTaskEmailButton = ({ task }: Tprops) => {
  const {
    resendTaskEmail: { mutate, isLoading, isSuccess, isError }
  } = useTaskController(task.id)

  const isAllowedTemplate = task.templateSlug === 'cc_trade_in_property_questionnaire'
  const icon = isError ? <Icon.Close /> : isSuccess ? <Icon.Check /> : <Icon.Envelope />
  const message = isError ? 'Error sending reminder' : isSuccess ? 'Reminder sent' : 'Send reminder'

  return !task.completedAt && task.assignee?.agent && isAllowedTemplate ? (
    <InlineIconButton
      data-testid="resend-task-email-button"
      icon={icon}
      disabled={isSuccess || isError}
      title={message + ` to ${task.assignee?.agent?.fullName}`}
      isLoading={isLoading}
      onClick={() => {
        mutate(task.id)
      }}
    />
  ) : null
}
