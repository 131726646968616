// @ts-strict
import { AgentsAutoComplete, ButtonForm, FormField, useButtonForm } from 'components'
import { useTeamContext } from 'contexts'
import type { TransactionTeam } from 'models'
import { validatePresence } from 'utils'

type Props = {
  team?: TransactionTeam
}

export const AddAgentToTransactionTeamButtonForm = ({ team }: Props) => {
  const { addAgent, addAgentToSettlementAgencyTeam } = useTeamContext()
  const {
    formProps,
    fieldsProps: { onChangeAttribute }
  } = useButtonForm({
    initialPayload: {
      agentId: ''
    },
    mutation: team?.type === 'SettlementAgencyTeam' ? addAgentToSettlementAgencyTeam : addAgent,
    formValidations: {
      agentId: [validatePresence]
    }
  })

  const title =
    team?.type === 'SettlementAgencyTeam' ? 'Settlement Agency Team' : 'Transaction Team'

  return (
    <ButtonForm title={`Add Agent to ${title}`} buttonText="Add Agent" {...formProps}>
      <FormField title={title}>{team?.name}</FormField>
      <FormField title="Agent" required>
        <AgentsAutoComplete
          onSelect={agent => {
            onChangeAttribute('agentId', agent.id)
          }}
          onClear={() => {
            onChangeAttribute('agentId', '')
          }}
        />
      </FormField>
    </ButtonForm>
  )
}
