import { TModelFieldSetting } from '../ModelField'

export type TCCHLSimpleSaleLeadFieldAttribute =
  | 'agentValuation'
  | 'avmDate'
  | 'departingPropertyActualSalePrice'
  | 'departingPropertyAgentValuationReceivedDate'
  | 'departingPropertyCloseOfPurchaseEscrowDate'
  | 'departingPropertyCloseOfSaleEscrowDate'
  | 'departingPropertyExpectedListingPrice'
  | 'departingPropertyGuaranteedPrice'
  | 'departingPropertyHomeownerAssociation'
  | 'departingPropertyListedDate'
  | 'departingPropertyListingPrice'
  | 'departingPropertyOfferAcceptedDate'
  | 'departingPropertyOfferCount'
  | 'departingPropertyOpenedPurchasedEscrowDate'
  | 'departingPropertyOpenedSaleEscrowDate'
  | 'departingPropertyPreliminaryTitleReviewed'
  | 'departingPropertyPreliminaryValuation'
  | 'departingPropertySolarPanels'
  | 'departingPropertyValuation'
  | 'dpBuyerAgentFee'
  | 'dpDealValuationPercentage'
  | 'dpDealValuationPrice'
  | 'dpHlCommitteeAdjustment'
  | 'dpHlAfterRepairValue'
  | 'dpInspectionAdjustment'
  | 'dpPropertyType'
  | 'dpSellerAgentFee'
  | 'dpSellerCredit'
  | 'dpTargetPurchaseCoeDate'
  | 'dpTargetSellCoeDate'
  | 'dpTermSheetOffer'
  | 'reasonForFail'
  | 'revenueRecognitionDate'
  | 'dpAgentAfterRepairValue'
  | 'dpAgentAfterRepairValueDate'
  | 'dpAnalystValuation'
  | 'dpAnalystValuationDate'
  | 'dpAnalystAfterRepairValue'
  | 'dpAnalystAfterRepairValueDate'

export type TCCHLSimpleSaleLeadFieldSetting = {
  attr: TCCHLSimpleSaleLeadFieldAttribute
} & TModelFieldSetting

export const ccHLSimpleSaleLeadFieldsSettings: TCCHLSimpleSaleLeadFieldSetting[] = [
  {
    attr: 'dpDealValuationPrice',
    name: 'Deal Valuation',
    inputType: 'money'
  },
  {
    attr: 'avmDate',
    name: 'AVM Valuation Date',
    inputType: 'date'
  },
  {
    attr: 'dpHlAfterRepairValue',
    name: 'HL ARV',
    inputType: 'money'
  },
  {
    attr: 'dpPropertyType',
    name: 'Property Type',
    inputType: 'select',
    getOptions: 'leadPropertyTypes'
  },
  {
    attr: 'agentValuation',
    name: 'Agent As-Is Valuation',
    inputType: 'money'
  },
  {
    attr: 'departingPropertyActualSalePrice',
    name: 'Sale Price',
    inputType: 'money'
  },
  {
    attr: 'departingPropertyAgentValuationReceivedDate',
    name: 'Agent As-Is Val Date',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyExpectedListingPrice',
    name: 'Expected Listing Price',
    inputType: 'money'
  },
  {
    attr: 'departingPropertyHomeownerAssociation',
    name: 'HOA',
    inputType: 'boolean'
  },
  {
    attr: 'departingPropertyListedDate',
    name: 'Listed Date',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyListingPrice',
    name: 'List Price',
    inputType: 'money'
  },
  {
    attr: 'departingPropertyOfferAcceptedDate',
    name: 'Offer Accepted',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyOfferCount',
    name: 'Offers Made',
    inputType: 'integer'
  },
  {
    attr: 'departingPropertyOpenedSaleEscrowDate',
    name: 'Open Escrow Date',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyOpenedPurchasedEscrowDate',
    name: 'Open Escrow Date',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyPreliminaryTitleReviewed',
    name: 'Prelim Title',
    inputType: 'boolean'
  },
  {
    attr: 'departingPropertyPreliminaryValuation',
    name: 'AVM Valuation',
    inputType: 'money'
  },
  {
    attr: 'departingPropertySolarPanels',
    name: 'Solar Panels',
    inputType: 'select',
    options: [
      { name: 'No Solar', value: 'no_solar' },
      { name: 'Leased', value: 'leased' },
      { name: 'Owned - Loan', value: 'owned_loan' },
      { name: 'Fully Owned', value: 'fully_owned' }
    ]
  },
  {
    attr: 'departingPropertyValuation',
    name: 'HL Valuation',
    inputType: 'money'
  },
  {
    attr: 'dpTargetPurchaseCoeDate',
    name: 'Target COE Date',
    inputType: 'date'
  },
  {
    attr: 'dpTargetSellCoeDate',
    name: 'Target COE Date',
    inputType: 'date'
  },
  {
    attr: 'revenueRecognitionDate',
    name: 'Waterfall Funds Received Date',
    inputType: 'date'
  },
  {
    attr: 'dpSellerCredit',
    name: 'Seller Credits',
    inputType: 'money'
  },
  {
    attr: 'dpBuyerAgentFee',
    name: 'Buyer Agent Fees %',
    inputType: 'percent'
  },
  {
    attr: 'departingPropertyCloseOfPurchaseEscrowDate',
    name: 'Actual COE Date',
    inputType: 'date'
  },
  {
    attr: 'departingPropertyCloseOfSaleEscrowDate',
    name: 'Actual COE Date',
    inputType: 'date'
  },
  {
    attr: 'dpDealValuationPercentage',
    name: 'Deal Valuation %',
    inputType: 'percent'
  },
  {
    attr: 'departingPropertyGuaranteedPrice',
    name: 'Purchase Price',
    inputType: 'money'
  },
  {
    allowNegativeNumber: true,
    attr: 'dpHlCommitteeAdjustment',
    name: 'HL Committee Adjustment',
    inputType: 'money'
  },
  {
    allowNegativeNumber: true,
    attr: 'dpInspectionAdjustment',
    name: 'Inspection Adjustment',
    inputType: 'money'
  },
  {
    attr: 'dpTermSheetOffer',
    name: 'Term Sheet Offer',
    inputType: 'money'
  },
  {
    attr: 'dpSellerAgentFee',
    name: 'Seller Agent Fee %',
    inputType: 'percent'
  },
  {
    attr: 'reasonForFail',
    name: 'Failed Reason',
    inputType: 'select',
    getOptions: 'hlSimpleSaleFailedReasons'
  },
  {
    attr: 'dpAgentAfterRepairValue',
    name: 'Agent ARV',
    inputType: 'money'
  },
  {
    attr: 'dpAgentAfterRepairValueDate',
    name: 'Agent ARV Date',
    inputType: 'date'
  },
  {
    attr: 'dpAnalystValuation',
    name: 'Analyst As-Is Valuation',
    inputType: 'money'
  },
  {
    attr: 'dpAnalystValuationDate',
    name: 'Analyst As-Is Val Date',
    inputType: 'date'
  },
  {
    attr: 'dpAnalystAfterRepairValue',
    name: 'Analyst ARV',
    inputType: 'money'
  },
  {
    attr: 'dpAnalystAfterRepairValueDate',
    name: 'Analyst ARV Date',
    inputType: 'date'
  }
]
