import React from 'react'
import { SearchConsole } from 'components/SearchConsole/index'
import { LeadsListSearch } from './LeadsListSearch'
import { LeadsListStatus } from './LeadsListStatus'

export const LeadsListHeader = () => {
  return (
    <>
      <LeadsListStatus />
      <SearchConsole search={<LeadsListSearch />} />
    </>
  )
}
