// @ts-strict
import { Agent } from 'models'
import { LeadWarmTransferResponse, LeadWarmTransferStatus } from 'schema'

export class LeadWarmTransfer {
  acceptedAt?: string
  canceledAt?: string
  completedAt?: string
  declinedAt?: string
  declinedReason?: number
  pin?: number
  smsSentAt?: string
  status?: LeadWarmTransferStatus
  transferredAt?: string
  agent?: Agent
  createdAt?: string
  updatedAt?: string
  leadRoutingEnabled?: string
  workedByAgent?: Agent

  constructor(leadWarmTransferAttributes: LeadWarmTransferResponse) {
    Object.assign(this, leadWarmTransferAttributes)
  }

  static create(
    leadWarmTransferResponse: Potential<LeadWarmTransferResponse>
  ): Potential<LeadWarmTransfer> {
    if (!leadWarmTransferResponse) {
      return
    }

    return new LeadWarmTransfer(leadWarmTransferResponse)
  }
}
