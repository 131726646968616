import { Section, Sections } from 'components'
import { FieldFormField } from '../../FieldFormField'
import { useFields } from '../../useHLSSLeadDisposition'

export const HLSSInEscrowPurchase = () => {
  useFields({
    required: ['departingPropertyOpenedPurchasedEscrowDate', 'dpTargetPurchaseCoeDate']
  })
  return (
    <>
      <Sections title="DR Purchase Transaction">
        <Section>
          <FieldFormField field="departingPropertyOpenedPurchasedEscrowDate" />
        </Section>
        <Section>
          <FieldFormField field="dpTargetPurchaseCoeDate" />
        </Section>
      </Sections>
    </>
  )
}
