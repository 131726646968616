// @ts-strict
import { Contents } from 'components/Contents/index'
import { Page } from 'components/Page/index'
import { AgentAeAreasList } from 'content/AgentAeAreas'
import { AgentAeAreasProvider, ListProvider } from 'contexts'
import { useDocumentTitle } from 'hooks'

export const AgentAeAreasPage = () => {
  useDocumentTitle('Agent Ae Areas')

  return (
    <AgentAeAreasProvider>
      <ListProvider>
        <Page>
          <Contents title="Agent Ae Areas">
            <AgentAeAreasList />
          </Contents>
        </Page>
      </ListProvider>
    </AgentAeAreasProvider>
  )
}
