import { RecipientResponse } from 'schema'

export class Recipient {
  name: string
  email: string
  roleName: string
  createdAt: string
  updatedAt: string

  constructor(recipientAttributes: Recipient) {
    Object.assign(this, recipientAttributes)
  }

  static create(recipientResponse: Potential<RecipientResponse>): Potential<Recipient> {
    if (!recipientResponse) {
      return
    }
    return new Recipient(recipientResponse)
  }
}
