import { Icon, Menu, Section } from 'components'
import { useAgentPayPerLeadReferralsController } from 'controllers'
import { Agent, AgentPayPerLeadReferral } from 'models'
import { ReferralButtonForm } from './ReferralButtonForm'

type ReferralsActionMenuProps = {
  agent: Agent
  referral: AgentPayPerLeadReferral
}

export const ReferralsActionMenu = ({ agent, referral }: ReferralsActionMenuProps) => {
  const referralStatus = referral.status || 'unpaid'
  const cycleStatus = referral.pplBillingInfo.status || 'active'
  const renderActions =
    (referralStatus === 'unpaid' && cycleStatus === 'active') ||
    (referralStatus === 'paid' && cycleStatus === 'paid')

  const { creditAgentPayPerLeadReferral } = useAgentPayPerLeadReferralsController(
    agent.id,
    referral.id
  )

  const { refundAgentPayPerLeadReferral } = useAgentPayPerLeadReferralsController(
    agent.id,
    referral.id
  )

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  })

  const refundSubmitButtonText = `Refund ${formatter.format(
    referral.pplProviderSetting.price / 100
  )}`

  return renderActions ? (
    <Menu icon={Icon.Ellipse} popupAlignment="left">
      <Section data-testid="referralMenuSection">
        {referralStatus === 'unpaid' && (
          <ReferralButtonForm referral={referral} mutation={creditAgentPayPerLeadReferral} />
        )}
        {referralStatus === 'paid' && (
          <ReferralButtonForm
            overrideSubmitButtonText={refundSubmitButtonText}
            referral={referral}
            mutation={refundAgentPayPerLeadReferral}
          />
        )}
      </Section>
    </Menu>
  ) : null
}
