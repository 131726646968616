import { Section } from 'components'
import { useCashOfferDispositionContext } from '../CashOfferDispositionContext'
import { FieldFormField } from '../FieldFormField'
import { useFields } from '../useFields'

export const CashOfferInEscrowPurchase = () => {
  const { cashOfferLead, dispositionPayload } = useCashOfferDispositionContext()
  const requireChangeReason =
    cashOfferLead.expressClose !== undefined &&
    String(cashOfferLead.expressClose) !== dispositionPayload.expressClose

  useFields({
    fields: ['expressClose'],
    required: [
      'ipEarnestMoney',
      'incomingPropertyOpenPurchaseEscrowAt',
      'ipTargetCloseOfPurchaseEscrowAt',
      requireChangeReason ? 'changeReasons.expressClose.reason' : undefined
    ]
  })

  return (
    <Section>
      <FieldFormField field="ipEarnestMoney" />
      <FieldFormField field="incomingPropertyOpenPurchaseEscrowAt" />
      <FieldFormField field="ipTargetCloseOfPurchaseEscrowAt" />
      <FieldFormField field="expressClose" />
      {requireChangeReason && (
        <>
          <FieldFormField field="changeReasons.expressClose.reason" />
          <FieldFormField field="changeReasons.expressClose.note" />
        </>
      )}
    </Section>
  )
}
