import { matchRoutes, useLocation } from 'react-router-dom'
import { TabbedContent } from 'components'
import { ListAgentActivities } from 'features/activities/listAgentActivities'
import { ListGlobalActivities } from 'features/activities/listGlobalActivities'
import { ListLeadActivities } from 'features/activities/listLeadActivities'
import { thisAgentTabPathsToMatch, thisLeadTabPathsToMatch } from './utils'

export const ActivitiesDrawer = () => {
  const location = useLocation()
  const thisLeadTabMatch = matchRoutes(thisLeadTabPathsToMatch, location.pathname)
  const thisAgentTabMatch = matchRoutes(thisAgentTabPathsToMatch, location.pathname)

  const tabContent = {
    ...(thisLeadTabMatch && {
      'This Lead': <ListLeadActivities leadId={thisLeadTabMatch[0].params.leadId!} />
    }),
    ...(thisAgentTabMatch && {
      'This Agent': <ListAgentActivities agentId={thisAgentTabMatch[0].params.agentId!} />
    }),
    Global: <ListGlobalActivities />
  }
  return <TabbedContent content={tabContent} />
}
