// @ts-strict
import { useLocalStorage } from 'hooks'
import { addToArray, removeFromArray } from 'utils'

type TCollections = {
  [key: string]: string[]
}

type TActions = {
  add: (key: string, value: string) => void
  has: (key: string, value: string) => boolean
  init: (key: string) => void
  remove: (key: string, value: string) => void
  reset: (key: string) => void
  toggle: (key: string, value: string) => void
}

export const useCollections = (): [TCollections, TActions] => {
  const [collections, setCollections] = useLocalStorage<TCollections>('Collections', {})

  const init = (key: string) => {
    if (!collections[key]) {
      setCollections({ ...collections, [key]: [] })
    }
  }

  const add = (key: string, value: any) => {
    setCollections({
      ...collections,
      [key]: addToArray(collections[key], value, { sort: true })
    })
  }

  const remove = (key: string, value: any) => {
    setCollections({ ...collections, [key]: removeFromArray(collections[key], value) })
  }

  const toggle = (key: string, value: any) => {
    has(key, value) ? remove(key, value) : add(key, value)
  }

  const has = (key: string, value: any) => {
    return !!collections[key].find((item: string) => item === value)
  }

  const reset = (key: string) => {
    setCollections({ ...collections, [key]: [] })
  }

  return [
    collections,
    {
      add,
      has,
      init,
      remove,
      reset,
      toggle
    }
  ]
}
