// @ts-strict
import { mortgageLeadStagesSettings, TMortgageLeadStagesSlug } from 'lookups'
import { Agent, LenderProviderLead } from 'models/index'
import { LeadResponse } from 'schema'
import { Lead, TLeadChildConstructor } from '../Lead'

type TMortgageLeadConstructor = Omit<
  TLeadChildConstructor<MortgageLead>,
  'isPreviousStageUpdateBlocked'
>

export class MortgageLead extends Lead {
  lenderProviderLeads?: LenderProviderLead[]
  modelName? = 'MortgageLead'
  referringAgent?: Agent
  hasTrusLink?: boolean

  constructor(mortgageLeadAttributes: TMortgageLeadConstructor) {
    super(mortgageLeadAttributes)
    Object.assign(this, mortgageLeadAttributes)
  }
  static create(mortgageLeadLeadResponse: Potential<LeadResponse>): Potential<MortgageLead> {
    if (!mortgageLeadLeadResponse) {
      return
    }
    const lenderProviderLeads = (mortgageLeadLeadResponse?.lenderProviderLeads?.map(
      LenderProviderLead.create
    ) || []) as LenderProviderLead[]
    const hasTrusLink = !!lenderProviderLeads?.some(
      lenderProviderLead => lenderProviderLead?.lenderLead?.eaveLoanApplicationId
    )
    const referringAgent = Agent.create(mortgageLeadLeadResponse.referringOwner)
    const leadAttributes = Lead.mapResponse(mortgageLeadLeadResponse)
    return new MortgageLead({
      ...leadAttributes,
      lenderProviderLeads,
      referringAgent,
      hasTrusLink
    })
  }

  isPreviousStageUpdateBlocked?(newStageSlug: TMortgageLeadStagesSlug) {
    const currentStage = mortgageLeadStagesSettings.find(stage => stage.slug === this.stage)
    const newStage = mortgageLeadStagesSettings.find(stage => stage.slug === newStageSlug)

    if (currentStage?.other || newStage?.other) {
      return false
    }

    const activeStages = mortgageLeadStagesSettings.filter(stage => !stage.other)
    const stageSlugs = mortgageLeadStagesSettings.map(setting => setting.slug)
    const isTrusStage = !activeStages.includes(currentStage!)

    const isPreviousStage =
      isTrusStage ||
      stageSlugs.indexOf(newStageSlug as TMortgageLeadStagesSlug) <
        stageSlugs.indexOf(this.stage as TMortgageLeadStagesSlug)

    return isPreviousStage && this.hasTrusLink
  }
}
