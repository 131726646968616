// @ts-strict
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box } from '@foundation/components'
import {
  Block,
  LoadingText,
  Page,
  PrimaryButton,
  Table,
  TableBody,
  TableHeaderCell as TH,
  TableHeaderRow as THR
} from 'components'
import { useCurrentUserContext, useLeadGroupablesContext } from 'contexts'
import { useDocumentTitle } from 'hooks'
import { LeadGroupable } from 'models'
import { capitalize, singularize } from 'utils'
import styles from './LeadGroupables.module.scss'

const defaultPayload = (type: string) => ({
  name: `${type} - ${new Date().toISOString()}`,
  active: false,
  priority: -1
})

export const LeadGroupablesList = () => {
  const { leadGroupables, isLoading, createLeadGroupable, leadGroupableType } =
    useLeadGroupablesContext()
  const { setTitle } = useDocumentTitle()
  const { isLeadCreationAdmin } = useCurrentUserContext()

  const leadGroupableName = capitalize(leadGroupableType)

  useEffect(() => setTitle(leadGroupableName), [leadGroupableName, setTitle])

  const onCreateLeadGroupable = () => {
    createLeadGroupable.mutate(defaultPayload(singularize(leadGroupableType)))
  }

  return (
    <Page>
      <h1>{leadGroupableName}</h1>
      <Box className={styles.actionsSection}>
        {isLeadCreationAdmin && (
          <PrimaryButton onClick={onCreateLeadGroupable}>{`Create ${singularize(
            leadGroupableName
          )}`}</PrimaryButton>
        )}
      </Box>
      {isLoading && <LoadingText text={`Loading ${leadGroupableName}`} />}
      {!isLoading && (
        <Table>
          <THR>
            <TH>Name</TH>
            <TH>Active</TH>
            <TH>Priority</TH>
          </THR>
          <TableBody>
            <TableRows
              leadGroupables={leadGroupables as LeadGroupable[]}
              leadGroupableType={leadGroupableType}
            />
          </TableBody>
        </Table>
      )}
    </Page>
  )
}

const TableRows = ({
  leadGroupableType,
  leadGroupables
}: {
  leadGroupableType: string
  leadGroupables: LeadGroupable[]
}) => {
  return (
    <>
      {leadGroupables?.map(leadGroupable => (
        <tr key={leadGroupable.id}>
          <td>
            <Block
              title={
                <Link to={`/lead-creation-admin/${leadGroupableType}/${leadGroupable.id}`}>
                  {leadGroupable.name}
                </Link>
              }
              subtitle="‎ ‎"
            />
          </td>
          <td>{leadGroupable.active ? 'Yes' : 'No'}</td>
          <td>{leadGroupable.priority}</td>
        </tr>
      ))}
    </>
  )
}
