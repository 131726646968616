type Obj = Record<string, any>
type OrderBy = 'asc' | 'desc'
type DefinedValuesOrder = Array<number | string>

export const sortByAttrDefinedOrder =
  (attr: string, definedValuesOrder: DefinedValuesOrder, orderBy: OrderBy = 'asc') =>
  (objA: Obj, objB: Obj) => {
    const indexOfA =
      definedValuesOrder.indexOf(objA[attr]) === -1
        ? definedValuesOrder.length
        : definedValuesOrder.indexOf(objA[attr])
    const indexOfB =
      definedValuesOrder.indexOf(objB[attr]) === -1
        ? definedValuesOrder.length
        : definedValuesOrder.indexOf(objB[attr])

    return orderBy === 'desc' ? indexOfB - indexOfA : indexOfA - indexOfB
  }
