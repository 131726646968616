import { useEffect, useState } from 'react'
import { Button, MenuSimple, Spinner } from 'components'
import { MarketplaceProgramBadge } from 'content/MarketplaceProgram'
import { useServicesOpportunities } from 'lookups'
import { ServicesOpportunity, ServicesOpportunityStatus } from 'models'
import { cx } from 'utils'
import styles from './ServicesOpportunitySelector.module.scss'
import { ServicesOpportunitySelectorNoteForm } from './ServicesOpportunitySelectorNoteForm'

type Props = {
  declinedReasons?: TLookupType[]
  onChange: () => void
  servicesOpportunity: ServicesOpportunity
}

export const ServicesOpportunitySelector = ({
  servicesOpportunity,
  declinedReasons,
  onChange
}: Props) => {
  const {
    activeServicesOpportunitiesStatuses,
    updateServicesOpportunity: { mutateAsync, isLoading }
  } = useServicesOpportunities()

  const [statusNotes, setStatusNotes] = useState(undefined)
  const [optimisticStatus, setOptimisticStatus] = useState<ServicesOpportunityStatus>(undefined)
  const [isSelectStatusOpen, setIsSelectStatusOpen] = useState(false)
  const [isNoteFormOpen, setIsNoteFormOpen] = useState(false)

  const toggleSelectStatus = () => {
    setIsSelectStatusOpen(isOpen => !isOpen)
    setIsNoteFormOpen(false)
  }

  useEffect(() => {
    setOptimisticStatus(undefined)
  }, [servicesOpportunity])

  const handleToggleNoteForm = () => setIsNoteFormOpen(isOpen => !isOpen)

  const handleUpdateOpportunity = async (status: ServicesOpportunityStatus, payload = {}) => {
    await mutateAsync({
      id: servicesOpportunity.id,
      payload: {
        asm_action: status.slug,
        ...payload
      }
    })
    setIsSelectStatusOpen(false)
    setOptimisticStatus(status)
    onChange()
    handleToggleNoteForm()
  }

  const handleSelectStatus = (status: ServicesOpportunityStatus) => {
    if (status.slug === 'declined') {
      handleToggleNoteForm()
      setStatusNotes(status)
      return
    }
    return handleUpdateOpportunity(status)
  }

  const handleSubmitStatusNotes = ({ notes, declinedReason }) =>
    handleUpdateOpportunity(statusNotes, {
      notes,
      trigger: {
        trigger_type: declinedReason.type,
        trigger_name: declinedReason.value
      }
    })

  const Title = () => (
    <>
      <MarketplaceProgramBadge program={servicesOpportunity.marketplaceProgram} />
      <span className={styles.text}>
        {optimisticStatus?.name || servicesOpportunity.status?.name}
      </span>
      {isLoading ? <Spinner height={16} width={16} /> : null}
    </>
  )

  return (
    <MenuSimple
      className={cx(styles.opportunity)}
      title={<Title />}
      justify="left"
      onClick={toggleSelectStatus}
      open={isSelectStatusOpen}
      disabled={isLoading}
    >
      {isNoteFormOpen ? (
        <ServicesOpportunitySelectorNoteForm
          onSubmit={handleSubmitStatusNotes}
          onCancel={handleToggleNoteForm}
          declinedReasons={declinedReasons}
        />
      ) : (
        activeServicesOpportunitiesStatuses.map(oppStatus => (
          <Button
            as="menuButton"
            key={oppStatus.slug}
            disabled={isLoading || servicesOpportunity.status?.slug === oppStatus.slug}
            onClick={() => handleSelectStatus(oppStatus)}
          >
            {oppStatus.name}
          </Button>
        ))
      )}
    </MenuSimple>
  )
}
