import { ReactNode } from 'react'
import { Spinner } from 'components/Graphic'
import { Icon } from 'components/Icon'
import { Button } from '..'
import styles from './DownloadButton.module.scss'
import { useDownloadButton } from './useDownloadButton'

type Props = {
  children?: ReactNode
  downloadAttribute?: string
  url?: string
}

export const DownloadButton = ({ children, url = '', downloadAttribute, ...props }: Props) => {
  const { data, handleClick, isLoading } = useDownloadButton(url, downloadAttribute)

  return (
    <Button
      data-testid="download-button"
      as="download"
      onClick={handleClick}
      className={styles.downloadButton}
      {...props}
    >
      <span className={styles.text}>{children || 'Download'}</span>
      {isLoading ? (
        <Spinner height={16} width={16} />
      ) : data ? (
        <Icon.ExternalLink className={styles.externalLinkIcon} />
      ) : (
        <Icon.Download className={styles.downloadIcon} />
      )}
    </Button>
  )
}
