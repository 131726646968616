// @ts-strict
import { ExternalListingResponse } from 'schema'

export class ExternalListing {
  id!: string
  updatedAt?: string
  status?: string

  constructor(externalListingAttributes: ExternalListing) {
    Object.assign(this, externalListingAttributes)
  }

  static create(
    externalListingResponse: Potential<ExternalListingResponse>
  ): Potential<ExternalListing> {
    if (!externalListingResponse) {
      return
    }
    const { id, status, lastRan } = externalListingResponse
    const updatedAt = lastRan

    return new ExternalListing({ id, updatedAt, status })
  }
}
