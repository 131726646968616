import { useState } from 'react'
import { Box, Flex } from '@foundation/components'
import { MenuSection, Section, Select } from 'components'
import { useFeatureFlags, useNewFilters } from 'hooks'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import { userCategoryOptions, useRoles, userStatusOptions, useTransactionTeams } from 'lookups'
import { ListFilters } from 'templates'

type Params = {
  role: string | null
  teamId: string | null
  userCategory: string | null
  userStatus: string | null
}

export const ListUsersFilters = () => {
  const { salesAppImpersonatingAgentTeamCoord } = useFeatureFlags([
    'sales-app-impersonating-agent-team-coord'
  ])
  const { tracking } = useCustomEvents()
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const { transactionTeamsOptions } = useTransactionTeams()
  const { roleOptions } = useRoles()
  const { newParams, set } = useNewFilters<Params>()

  return (
    <ListFilters.Root isOpen={isOpenPopover} onOpenChange={setIsOpenPopover}>
      <ListFilters.Trigger>
        <ListFilters.TriggerButton iconRight="FilterAltOutlined">Filters</ListFilters.TriggerButton>
      </ListFilters.Trigger>
      <ListFilters.Content>
        <Section>
          <Flex gap="$4">
            <Box width="24rem">
              <MenuSection title="Role">
                <Select
                  addBlank
                  value={newParams.role!}
                  options={roleOptions}
                  onChange={val => set('role', val)}
                  placeholder="Select a Role"
                />
              </MenuSection>
            </Box>
            <Box width="24rem">
              <MenuSection title="Transaction Team">
                <Select
                  addBlank
                  value={newParams.teamId!}
                  options={transactionTeamsOptions}
                  onChange={val => set('teamId', val)}
                  placeholder="Select a Transaction Team"
                />
              </MenuSection>
            </Box>
          </Flex>
        </Section>
        {salesAppImpersonatingAgentTeamCoord.enabled && (
          <Section>
            <Flex gap="$4">
              <Box width="24rem">
                <MenuSection title="User Category">
                  <Select
                    value={newParams.userCategory!}
                    options={userCategoryOptions}
                    onChange={val => set('userCategory', val)}
                  />
                </MenuSection>
              </Box>
              <Box width="24rem">
                <MenuSection title="User Status">
                  <Select
                    value={newParams.userStatus!}
                    options={userStatusOptions}
                    onChange={val => set('userStatus', val)}
                  />
                </MenuSection>
              </Box>
            </Flex>
          </Section>
        )}
        <ListFilters.Footer>
          <ListFilters.ApplyButton
            newParams={newParams}
            onApplyToggle={setIsOpenPopover}
            onClick={() =>
              tracking({ name: 'filters', params: { action: 'apply', target: 'user' } })
            }
          />
        </ListFilters.Footer>
      </ListFilters.Content>
    </ListFilters.Root>
  )
}
