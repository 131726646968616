import { SyntheticEvent } from 'react'
import { RoundImage, Tooltip } from 'components'
import { Investor } from 'models'
import { cx, randomImage } from 'utils'
import styles from './InvestorAvatar.module.scss'

type Props = {
  className?: string
  investor: Investor
}

export const InvestorAvatar = ({ investor, className }: Props) => {
  const tooltipText = [investor.name, investor.email].filter(Boolean).join(', ')

  const imageSrc = randomImage(+investor.id, 50)

  const handleDefaultImage = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = randomImage(+investor.id, 50)
  }

  return (
    <Tooltip text={tooltipText} className={styles.tooltip}>
      <RoundImage
        className={cx(styles.agentAvatar, className)}
        src={imageSrc}
        onError={handleDefaultImage}
        alt={investor.name}
      />
    </Tooltip>
  )
}
