// @ts-strict
import { useButtonForm } from 'components'
import { useLeadContext } from 'contexts'

export type TLeadEditAgentNotePayload = {
  notesToAgents: string
}

export const useLeadEditAgentNoteButtonForm = () => {
  const { lead, updateLead } = useLeadContext()
  const initialPayload: TLeadEditAgentNotePayload = { notesToAgents: lead?.notesToAgents || '' }

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm<TLeadEditAgentNotePayload>({
    initialPayload,
    mutation: updateLead
  })

  return {
    formProps,
    onChangeAttribute,
    payload
  }
}
