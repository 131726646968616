// @ts-strict
import { Button } from '@foundation/components'
import { ButtonForm, FormField, TextArea } from 'components'
import { useLeadEditAgentNoteButtonForm } from '.'

export const LeadEditAgentNoteButtonForm = () => {
  const { formProps, onChangeAttribute, payload } = useLeadEditAgentNoteButtonForm()

  return (
    <>
      <Button variant="outline" size="xs" color="neutralLight" onClick={formProps.toggleShowForm}>
        Edit Note
      </Button>
      <ButtonForm hideTriggerButton title="Notes to Agents" {...formProps}>
        <FormField title="Note" htmlFor="note">
          <TextArea
            id="note"
            value={payload.notesToAgents}
            onChange={val => onChangeAttribute('notesToAgents', val)}
          />
        </FormField>
      </ButtonForm>
    </>
  )
}
