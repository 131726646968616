export const leadStageAttributes = {
  introPending: {
    name: 'Intro Pending',
    slug: 'intro_pending',
    active: true
  },
  introduced: {
    name: 'Introduced',
    slug: 'introduced',
    active: true
  },
  agentLeft: {
    name: 'Agent Left VM',
    slug: 'agent_left_vm',
    active: true
  },
  clientLeft: {
    name: 'Client Left VM',
    slug: 'client_left_vm',
    active: true
  },
  connected: {
    name: 'Connected',
    slug: 'connected',
    active: true
  },
  meetingScheduled: {
    name: 'Meeting Scheduled',
    slug: 'meeting_scheduled',
    active: true
  },
  metInPerson: {
    name: 'Met In Person',
    slug: 'met_in_person',
    active: true
  },
  comingSoon: {
    name: 'Coming Soon',
    slug: 'coming_soon',
    active: true
  },
  listing: {
    name: 'Listing',
    slug: 'listing',
    active: true
  },
  inEscrow: {
    name: 'In Escrow',
    slug: 'in_escrow',
    active: true
  },
  closed: {
    name: 'Closed',
    slug: 'closed',
    active: true
  },
  closedBooked: {
    name: 'Closed Booked',
    slug: 'closed_booked',
    active: true
  },
  closedPaid: {
    name: 'Closed Paid',
    slug: 'closed_paid',
    active: true
  },
  failed: {
    name: 'Failed',
    slug: 'failed',
    active: true
  },
  propertyPending: {
    name: 'Property Pending',
    slug: 'property_pending',
    active: true,
    validPreviousStage: true,
    nextStageSlugs: ['property_submitted', 'property_cancelled'],
    leadRequirements: {
      hasIncomingResidence: 'This lead does not have an active Incoming Residence.'
    }
  },
  propertySubmitted: {
    name: 'Property Submitted',
    slug: 'property_submitted',
    active: true,
    validPreviousStage: true,
    nextStageSlugs: ['property_approved', 'property_cancelled', 'property_rejected']
  },
  propertyApproved: {
    name: 'Property Approved',
    slug: 'property_approved',
    active: true,
    validPreviousStage: true,
    nextStageSlugs: ['agreement_signed', 'property_cancelled']
  },
  propertyRejected: {
    name: 'Property Rejected',
    slug: 'property_rejected',
    validPreviousStage: false
  },
  propertyCancelled: {
    name: 'Property Cancelled',
    slug: 'property_cancelled',
    validPreviousStage: false
  },
  agreementSigned: {
    name: 'Agreement Signed',
    slug: 'agreement_signed',
    active: true,
    validPreviousStage: true,
    nextStageSlugs: ['offer_made', 'property_cancelled']
  },
  offerMade: {
    name: 'Offer Made',
    slug: 'offer_made',
    active: true,
    validPreviousStage: true,
    nextStageSlugs: ['offer_accepted', 'offer_rejected', 'offer_cancelled']
  },
  offerAccepted: {
    name: 'Offer Accepted',
    slug: 'offer_accepted',
    active: true,
    validPreviousStage: true,
    nextStageSlugs: ['in_escrow_purchase', 'property_rejected', 'offer_cancelled']
  },
  offerRejected: {
    name: 'Offer Rejected',
    slug: 'offer_rejected',
    validPreviousStage: false
  },
  offerCancelled: {
    name: 'Offer Cancelled',
    slug: 'offer_cancelled',
    validPreviousStage: false
  },
  inEscrowPurchase: {
    name: 'In Escrow Purchase',
    slug: 'in_escrow_purchase',
    active: true,
    validPreviousStage: true,
    nextStageSlugs: ['closed_purchase', 'offer_cancelled']
  },
  closedPurchase: {
    name: 'Closed Purchase',
    slug: 'closed_purchase',
    active: true,
    validPreviousStage: true,
    nextStageSlugs: ['in_escrow_sell']
  },
  inEscrowSell: {
    name: 'In Escrow Sell',
    slug: 'in_escrow_sell',
    active: true,
    validPreviousStage: true,
    nextStageSlugs: ['closed_sell']
  },
  closedSell: {
    name: 'Closed Sell',
    slug: 'closed_sell',
    active: true,
    validPreviousStage: true,
    nextStageSlugs: ['closed_paid']
  },
  newStage: {
    name: 'New',
    slug: 'new',
    active: true
  },
  pitched: {
    name: 'Pitched',
    slug: 'pitched',
    active: true
  },
  nurture: {
    name: 'Nurture',
    slug: 'nurture',
    active: true
  },
  applicationStarted: {
    name: 'Application Started',
    slug: 'application_started',
    active: true
  },
  conditionally_approved: {
    name: 'Conditionally Approved',
    slug: 'conditionally_approved',
    active: true
  },
  applicationComplete: {
    name: 'Application Complete',
    slug: 'application_complete',
    active: true
  },
  inspectionComplete: {
    name: 'Inspection Complete',
    slug: 'inspection_complete',
    active: true
  },
  approved: {
    name: 'Approved',
    slug: 'approved',
    active: true
  },
  listed: {
    name: 'Listed',
    slug: 'listed',
    active: true
  },
  ip_in_escrow: {
    name: 'IR In Escrow',
    slug: 'ip_in_escrow',
    validPreviousStage: true,
    active: true
  },
  ip_closed: {
    name: 'IR Closed',
    slug: 'ip_closed',
    validPreviousStage: true,
    active: true
  },
  client_listed: {
    name: 'Client Listed',
    slug: 'client_listed',
    validPreviousStage: true,
    active: true
  },
  client_in_escrow: {
    name: 'Client In Escrow',
    slug: 'client_in_escrow',
    validPreviousStage: true,
    active: true
  },
  client_closed: {
    name: 'Client Closed',
    slug: 'client_closed',
    validPreviousStage: true,
    active: true
  },
  called_left_msg: {
    name: 'Left Message',
    slug: 'called_left_msg',
    validPreviousStage: true,
    active: true
  },
  requested: {
    name: 'Requested',
    slug: 'requested',
    active: true
  },
  unqualified: {
    name: 'Unqualified',
    slug: 'unqualified',
    active: true
  },
  bad_number: {
    name: 'Bad Number',
    slug: 'bad_number',
    active: true
  },
  not_transacting: {
    name: 'Not Transacting',
    slug: 'not_transacting',
    active: true
  },
  in_pre_escrow: {
    name: 'In Pre-Escrow',
    slug: 'in_pre_escrow',
    active: true
  },
  pending_failed: {
    name: 'Pending Failed',
    slug: 'pending_failed',
    active: true
  },
  pending_order: {
    name: 'Pending Order',
    slug: 'pending_order',
    active: true
  },
  awaiting_agent: {
    name: 'Awaiting Agent',
    slug: 'awaiting_agent',
    active: true
  },
  not_interested: {
    name: 'Not Interested',
    slug: 'not_interested',
    active: true
  },
  duplicate: {
    name: 'Duplicate',
    slug: 'duplicate',
    active: true
  },
  processing: {
    name: 'Processing',
    slug: 'processing',
    active: true
  },
  claimed: {
    name: 'Claimed',
    slug: 'claimed',
    active: true
  }
} as const

type TLeadStageAttributes = typeof leadStageAttributes
type TLeadStageAttribute = TLeadStageAttributes[keyof TLeadStageAttributes]
export type TLeadStageSlug = TLeadStageAttribute['slug']
export type TLeadRequirementsSlug = 'hasIncomingResidence' | 'hasTrusLink'
