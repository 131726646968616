// @ts-strict
import axios from 'axios'
import { Lead } from 'models'
import { fetchLeads, TLeadsSearchParams } from 'services'
import { formatStage, formatUserType } from 'utils'
import { AutoComplete } from '../AutoComplete'
import { AutoCompleteProps } from '../AutoComplete'
import { createURI } from './lib/createMapboxQueryURI'
import { structureResponseForAutoComplete } from './lib/structureResponseForAutoComplete'

export type MapboxAutoCompleteProps = {
  bias?: Array<number>
  country?: string
  leadSearchParams?: TLeadsSearchParams
  types?: string
} & Omit<AutoCompleteProps, 'getData'>

const formatMapboxResponse = (data: any, search: string) => {
  const features = structureResponseForAutoComplete({ features: data.data.features })
  if (
    !features.length ||
    features.some(feature => feature.title?.toLowerCase().includes(search.toLowerCase()))
  ) {
    return features
  }
  const originalSearchString = { id: '0', title: search }
  return [originalSearchString, ...features]
}

const getLeadSubTitle = (lead: Lead) => {
  const agent = lead.referringOwner

  const parts = [
    lead.name,
    formatUserType(lead.userType || ''),
    agent ? `${agent.firstName} ${agent.lastName}` : '',
    formatStage(lead.stage || '')
  ].filter(Boolean)

  return parts.join(' - ')
}

const formatLeadResponse = (leads: Lead[]) =>
  leads.map((lead: Lead) => ({
    id: lead.id,
    title: lead.fullAddress,
    subTitle: getLeadSubTitle(lead),
    lead
  }))

export const PropertyAutoComplete = ({
  bias = [],
  country = 'us',
  types = 'address',
  leadSearchParams = {},
  ...props
}: MapboxAutoCompleteProps) => {
  const getData = async (search?: string) => {
    if (!search?.length) {
      return Promise.resolve([])
    }

    try {
      const mapboxData = await axios.get(createURI(search, types, country, bias))
      const mapboxItems = formatMapboxResponse(mapboxData, search)

      const leadData = await fetchLeads({ searchTerm: search, ...leadSearchParams })
      const leadItems = formatLeadResponse(leadData.leads)

      return [...leadItems, ...mapboxItems]
    } catch (err) {
      return []
    }
  }

  return <AutoComplete getData={getData} {...props} />
}
