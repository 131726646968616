// @ts-strict
import { FileInput, FormField, Select, TextInput } from 'components'
import { isCCCashOfferLead, Lead } from 'models'
import { TLeadFileUploadsPayload } from './LeadFileUploadsButtonForm'

type TLeadFileUploadsButtonFieldsProps = {
  lead?: Lead
  onChangeAttribute: <K extends keyof TLeadFileUploadsPayload>(
    key: K,
    value: TLeadFileUploadsPayload[K]
  ) => void
  payload: TLeadFileUploadsPayload
}

const acceptedFileTypes = `.doc,.docx,.pdf`

export const LeadFileUploadsButtonFields = ({
  lead,
  payload,
  onChangeAttribute
}: TLeadFileUploadsButtonFieldsProps) => {
  const incomingResidenceOptions = isCCCashOfferLead(lead)
    ? lead.getCashOfferProviderLeadsOptions()
    : []

  const setIncomingResidence = (value: string) => {
    onChangeAttribute('subAttachableId', value)
    onChangeAttribute('subAttachableType', value ? 'CashOfferLead' : undefined)
  }

  return (
    <>
      <FormField title="Name" htmlFor="file-name" required>
        <TextInput
          id="file-name"
          value={payload.name}
          onChange={val => onChangeAttribute('name', val)}
          data-testid="filesNameInput"
        />
      </FormField>
      <FormField title="Document (pdf, doc, docx)" htmlFor="file" required>
        <FileInput
          id="file-name"
          data-testid="fileUploadInput"
          accept={acceptedFileTypes}
          value={payload.files}
          onChange={value => {
            onChangeAttribute('files', value)
          }}
        />
      </FormField>
      {isCCCashOfferLead(lead) ? (
        <FormField title="Incoming Residence" htmlFor="incoming-residence">
          <Select
            id="incoming-residence"
            addBlank
            options={incomingResidenceOptions}
            value={payload.subAttachableId}
            onChange={setIncomingResidence}
          />
        </FormField>
      ) : null}
    </>
  )
}
