import { css, styled } from '@foundation/themes/styles'
import { TableHeaderColumn as TH } from 'components'

export const ActionsTH = styled(TH)`
  ${({ theme }) => css`
    width: ${theme.sizes.$20};
  `}
`

export const IdTH = styled(TH)`
  width: 10rem;
`

export const NameTH = styled(TH)`
  width: 22rem;
`
