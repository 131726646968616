import { useParamsContext } from 'contexts/Params'
import { CURRENT_USER_CACHE_KEY } from 'controllers'
import { useMutation, useQuery, useQueryClient } from 'hooks'
import { useEnvelopeTemplates } from 'lookups/lookupEnvelopeTemplates'
import { CCCashOfferLead, CCTradeInLead } from 'models'
import { fetchDocusignOauth, fetchEnvelopes, postEnvelope } from 'services'
import { alertError } from 'services'

const defaultSearchSettings = { perPage: 25 }

export const useEnvelopes = (
  lead: CCTradeInLead | CCCashOfferLead,
  initialParams = {},
  enabled: boolean = true
) => {
  const queryCache = useQueryClient()

  const clearCache = {
    onSuccess: () => {
      queryCache.invalidateQueries('envelopes')
    }
  }

  const { params } = useParamsContext()
  const searchSettings = { ...defaultSearchSettings, ...initialParams, ...params }

  const { envelopeTemplatesCashOffer, envelopeTemplatesTradeIn } = useEnvelopeTemplates()
  const envelopesQuery = useQuery(
    ['envelopes', lead.id, searchSettings],
    () => fetchEnvelopes(lead.id, searchSettings),
    { enabled }
  )
  const addEnvelope = useMutation((data: {}) => postEnvelope(lead.id, data), clearCache)

  const envelopeTemplates =
    lead instanceof CCTradeInLead ? envelopeTemplatesTradeIn : envelopeTemplatesCashOffer

  const oauthSignin = async () => {
    try {
      const response = await fetchDocusignOauth()
      const oAuthWindow = window.open(
        '',
        '_blank',
        'resizable=1,height=600,width=460,top=100,left=200'
      )
      oAuthWindow.opener = null
      oAuthWindow.location = response.redirect_url

      const listenOauthWindowClosed = setInterval(function () {
        if (oAuthWindow.closed) {
          queryCache.invalidateQueries(CURRENT_USER_CACHE_KEY)
          clearInterval(listenOauthWindowClosed)
        }
      }, 1000)
    } catch (error) {
      alertError(error.message)
    }
  }

  return {
    envelopesQuery,
    addEnvelope,
    oauthSignin,
    envelopeTemplates,
    lead
  }
}
