import { Contents, Inspect } from 'components'
import { useCurrentUserContext } from 'contexts'
import { useDocumentTitle, useUserSettings } from 'hooks'

export const SettingsInspectTab = () => {
  useDocumentTitle('Settings')

  const { settings } = useUserSettings()
  const { currentUser, currentUserQueues } = useCurrentUserContext()

  return (
    <Contents title="Inspect">
      <Inspect>{settings}</Inspect>
      <Inspect>{currentUser}</Inspect>
      <Inspect>{currentUserQueues}</Inspect>
    </Contents>
  )
}
