// @ts-strict
import { Button } from '@foundation/components'
import { ButtonForm, useButtonForm } from 'components'
import { useExternalLinksController } from 'controllers'
import { ExternalLink, isCCCashOfferLead, Lead } from 'models'
import { LeadExternalLinkFormFields } from '../LeadExternalLinkFormFields'
import { formValidations, TLeadExternalLinkPayload } from '../utils'

type TLeadCreateExternalLinkButtonFormProps = {
  externalLink?: ExternalLink
  lead: Lead
}

export const LeadCreateExternalLinkButtonForm = ({
  lead
}: TLeadCreateExternalLinkButtonFormProps) => {
  const { createExternalLink } = useExternalLinksController(lead)

  const subAttachableOptions = isCCCashOfferLead(lead)
    ? lead.getCashOfferProviderLeadsOptions()
    : []

  const initialPayload: TLeadExternalLinkPayload = {
    name: '',
    url: '',
    subAttachableId: '',
    subAttachableType: ''
  }

  const { formProps, fieldsProps } = useButtonForm({
    formValidations,
    mutation: createExternalLink,
    initialPayload
  })

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={formProps.toggleShowForm}>
        New Resource
      </Button>
      <ButtonForm {...formProps} hideTriggerButton title="New External Resource">
        <LeadExternalLinkFormFields subAttachableOptions={subAttachableOptions} {...fieldsProps} />
      </ButtonForm>
    </>
  )
}
