import { Contents, Page, PaginationParams } from 'components'
import { OrdersTable } from 'content/Orders'
import { OrdersListHeader } from 'content/Orders/OrdersList/OrdersListHeader'
import { ListProvider, OrdersProvider, useCurrentUserContext, useOrdersContext } from 'contexts'
import { useDocumentTitle } from 'hooks'
import { ordersListColumns, useOrdersListSearchFilters } from 'lookups'

const PageContents = () => {
  const { orders, pageCount, isLoading } = useOrdersContext()

  return (
    <Page>
      <Contents title="Orders">
        <OrdersListHeader />
        <OrdersTable orders={orders} isLoading={isLoading} />
        {pageCount ? <PaginationParams totalPages={pageCount} /> : null}
      </Contents>
    </Page>
  )
}

export const OrdersPage = () => {
  useDocumentTitle('Orders')
  const { aeId } = useCurrentUserContext()
  const filters = useOrdersListSearchFilters()

  return (
    <OrdersProvider aeId={aeId}>
      <ListProvider columns={ordersListColumns} filters={filters}>
        <PageContents />
      </ListProvider>
    </OrdersProvider>
  )
}
