// @ts-strict

import { useNavigate } from 'react-router-dom'
import {
  AgentMatchingLeadDisposition,
  AgentMatchingLeadDispositionButtonForm,
  LeadViewers,
  LeadWarmTransfer
} from 'content/Lead'
import { useCurrentUserContext } from 'contexts'
import { useFeatureFlags, useQueuedLeadsController } from 'hooks'
import { SellerLead } from 'models'
import { StopLeadNurture } from '../StopLeadNurture'
import { ButtonNavigation } from '../styles'

type SellerLeadTabActionsProps = {
  lead: SellerLead
}

export const SellerLeadTabActions = ({ lead }: SellerLeadTabActionsProps) => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUserContext()
  const {
    salesOpsReferralLeadsDisposition,
    salesAppBuyersSellersPendingActions,
    salesAppWarmTransfer
  } = useFeatureFlags([
    'sales-app-buyers-sellers-pending-actions',
    'sales-app-warm-transfer',
    'sales-ops-referral-leads-disposition'
  ])
  const shouldHaveWarmTransfer =
    (currentUser?.roles.clientAe || currentUser?.roles.superUser) &&
    salesAppWarmTransfer.enabled &&
    (lead.warmTransferEligible || lead?.warmTransferStarted)
  const { isActive, removeQueuedLead } = useQueuedLeadsController()

  const afterLeadDisposition = () => {
    if (isActive(lead.id)) {
      removeQueuedLead(lead.id)
      navigate('/queue')
    }
  }

  return (
    <>
      {salesAppBuyersSellersPendingActions.enabled && (
        <LeadViewers leadId={lead.id} leadViewers={lead.leadViewers} />
      )}

      <ButtonNavigation>
        {salesOpsReferralLeadsDisposition.enabled ? (
          <AgentMatchingLeadDisposition lead={lead} />
        ) : (
          <AgentMatchingLeadDispositionButtonForm lead={lead} callback={afterLeadDisposition} />
        )}
        {shouldHaveWarmTransfer && (
          <LeadWarmTransfer
            amountOfEligibleAgents={lead.eligibleWarmTransferAgentQuantity}
            hasStarted={lead?.warmTransferStarted}
            leadId={lead.id}
          />
        )}
        <StopLeadNurture lead={lead} />
      </ButtonNavigation>
    </>
  )
}
