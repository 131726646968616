import { useEmployeeUsers } from 'hooks'
import {
  completetionStatusOptions,
  escrowLeadStagesOptions,
  sourcesOptions,
  stateNameOptions,
  trueFalseOptions
} from 'lookups'
import { titleize } from 'utils'
import { buildSearchFilters, findFilterBySlug } from '../utils'
import { escrowLeadSearchFilterSettings } from './escrowLeadSearchFilterSettings'

export const useEscrowLeadSearchFilters = () => {
  const stageOptions = escrowLeadStagesOptions
  const { getUserName } = useEmployeeUsers()

  const optionLoaders = {
    furthestStage: stageOptions,
    source: sourcesOptions,
    trueFalse: trueFalseOptions,
    completionStatus: completetionStatusOptions,
    stateName: stateNameOptions
  }
  const displayValueFnLoaders = {
    titleize,
    getUserName
  }

  const escrowFilters = buildSearchFilters(escrowLeadSearchFilterSettings, {
    optionLoaders,
    displayValueFnLoaders
  })

  const findEscrowLeadFilterBySlug = findFilterBySlug(escrowFilters)

  return { escrowFilters, findFilterBySlug: findEscrowLeadFilterBySlug }
}
