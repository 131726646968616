// @ts-strict

import { Button, IButtonProps } from '..'
import styles from './RowButton.module.scss'

type Props = {
  inverted?: boolean
} & Pick<IButtonProps, 'onClick' | 'disabled' | 'children'>

export const RowButton = ({ children, inverted, ...props }: Props) => {
  const type = inverted ? 'rowInvertedButton' : 'rowButton'

  return (
    <Button data-testid={type} {...props} as="action" className={styles[type]}>
      {children}
    </Button>
  )
}
