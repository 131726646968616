import { ReactNode } from 'react'
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import { usePopoverContext } from './Popover'
import * as S from './styles'

type PopoverContentProps = {
  /**
   * The content to display inside the Popover Content
   */
  children: ReactNode
  /**
   * To control the stack order of the popup element
   */
  zIndex?: number
}

export const PopoverContent = ({ children, zIndex }: PopoverContentProps) => {
  const {
    popoverProps: { context, isOpen, ref, style }
  } = usePopoverContext()

  return (
    <FloatingPortal id="popover-portal">
      {isOpen && (
        <FloatingFocusManager context={context}>
          <S.Content ref={ref} style={style} $zIndex={zIndex}>
            {children}
          </S.Content>
        </FloatingFocusManager>
      )}
    </FloatingPortal>
  )
}
