// @ts-strict

import { Text } from '@foundation/components'
import { pluralize } from 'utils'
import * as S from '../styles'

type Props = {
  amountOfAgents?: number
}

export const AgentsAvailablePopUp = ({ amountOfAgents }: Props) => {
  return amountOfAgents ? (
    <S.Coaster>
      <Text size="sm">{`Initiate Warm Transfer with ${pluralize(amountOfAgents, 'Agent')}`}</Text>
    </S.Coaster>
  ) : null
}
