import { ModelField } from '../ModelField'
import { TOrderFieldAttribute } from './orderFieldsSettings'

export class OrderField extends ModelField {
  attr!: TOrderFieldAttribute

  constructor(attributes: OrderField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
