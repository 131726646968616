import { FormField, Icon, Section, Select, TextInput } from 'components'
import { useServiceOfficesController } from 'controllers'
import styles from '../EditEscrowOrderButtonForm.module.scss'
import { TFormProps } from '../types'

export const PendingOrder = ({ payload, onChangeAttribute }: TFormProps) => {
  const standardFormFieldProps = key => ({
    value: payload[key],
    onChange: val => onChangeAttribute(key, val)
  })

  const { serviceOfficesQuery } = useServiceOfficesController()
  const serviceOfficeOptions = (serviceOfficesQuery?.data || []).map(office => ({
    name: [office.name, office.branchName].join(' - '),
    value: office.id
  }))

  return (
    <>
      <p className={styles.warning}>
        <Icon.Message />
        This lead failed to create an order. Please update the property address and resubmit
      </p>

      <Section>
        <FormField title="Referring Agent ID">
          <TextInput {...standardFormFieldProps('referringOwnerId')} />
        </FormField>
        <FormField title="Service office">
          <Select
            data-testid="service-office-select"
            options={serviceOfficeOptions}
            onChange={val => onChangeAttribute('serviceOfficeId', val)}
            value={payload.serviceOfficeId}
            className={styles.input}
            addBlank
          />
        </FormField>
        <FormField title="Street address">
          <TextInput {...standardFormFieldProps('address')} />
        </FormField>
        <FormField title="Secondary address">
          <TextInput {...standardFormFieldProps('secondaryAddress')} />
        </FormField>
        <FormField title="City">
          <TextInput {...standardFormFieldProps('city')} />
        </FormField>
        <FormField title="State code">
          <TextInput {...standardFormFieldProps('stateCode')} />
        </FormField>
        <FormField title="Zip">
          <TextInput {...standardFormFieldProps('zipCode')} />
        </FormField>
      </Section>
    </>
  )
}
