// @ts-strict
import { ReactNode } from 'react'
import { createCtx } from 'contexts/utils'
import { useEmployeeController } from 'controllers/Employee'

export const [useEmployeeContext, EmployeeContextProvider] =
  createCtx<ReturnType<typeof useEmployeeController>>()

type Props = {
  children: ReactNode
  employeeId: string
}

export const EmployeeProvider = ({ employeeId, children }: Props) => {
  return (
    <EmployeeContextProvider value={useEmployeeController(employeeId)}>
      {children}
    </EmployeeContextProvider>
  )
}
