import { getPropertyUuid } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const lookupPropertyUuid = async (fullAddress: string) => {
  const url = getPropertyUuid({
    searchParams: {
      full_address: fullAddress
    }
  })
  const response = await getRequest(url)

  return await deserializeAsync(response.data)
}
