import { CamelCase } from 'type-fest'
import { toCamelCase } from './toCamelCase'

type CamelCaseKeysResult<T> = { [P in keyof T as CamelCase<P>]: T[P] }

export const camelCaseKeys = <T extends object>(obj: T) => {
  const result = {}

  Object.keys(obj).forEach(key => {
    result[toCamelCase(key)] = obj[key]
  })

  return result as CamelCaseKeysResult<T>
}
