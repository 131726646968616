import { AgentInterface } from '../..'
import { InvestorInterface } from '../../../Investor'
import { getFullName } from '../getFullName'

type AgentData = Pick<AgentInterface, 'firstName' | 'lastName'>

type Data = {
  investorName?: InvestorInterface['name']
} & AgentData

export const getInvestorName = ({ investorName, firstName, lastName }: Data) => {
  return investorName ?? getFullName({ firstName, lastName })
}
