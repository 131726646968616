// @ts-strict
import { DownloadButton, ExternalLink, TableCellSimple, TableCell as Td } from 'components'
import type { UseTableCellProps } from 'components'
import { getDocumentsUrl } from 'services/getUrl'
import { dataOrDash } from 'utils'

export const ReportUrlCell = ({
  slug,
  value,
  attachedColumnValue,
  ...tdProps
}: UseTableCellProps<string, string>) => {
  return (
    <Td {...tdProps}>
      {dataOrDash(value && <ExternalLink href={value}>WEB URL</ExternalLink>)}
      <br />
      {attachedColumnValue && (
        <DownloadButton url={`${getDocumentsUrl({ action: 'download', id: attachedColumnValue })}`}>
          PDF
        </DownloadButton>
      )}
    </Td>
  )
}

const columnMapping = {
  reportURL: ReportUrlCell
}
export const InspectionTableCell = (props: UseTableCellProps) => {
  const Cell = columnMapping[props.slug!] ?? TableCellSimple
  return <Cell {...props} />
}
