import { toOptions } from 'lookups/utils'
import { bySlug } from 'utils'
import { buildLeadStages } from '../lookupLeadStages'
import { CCHLSimpleSaleLeadStage } from './CCHLSimpleSaleLeadStage'
import {
  ccHLSimpleSaleLeadStagesSettings,
  TCCHLSimpleSaleLeadStageSlug
} from './ccHLSimpleSaleLeadStagesSettings'

export const lookupCCHLSimpleSaleLeadStages = buildLeadStages(
  ccHLSimpleSaleLeadStagesSettings,
  CCHLSimpleSaleLeadStage
)

export const getCCHLSimpleSaleLeadStage = (slug: TCCHLSimpleSaleLeadStageSlug) => {
  return lookupCCHLSimpleSaleLeadStages.find(bySlug(slug))
}

export const getCCHLSimpleSaleLeadStageName = (slug: TCCHLSimpleSaleLeadStageSlug) => {
  return getCCHLSimpleSaleLeadStage(slug)?.name
}

export const ccHLSimpleSaleLeadStagesOptions = toOptions(lookupCCHLSimpleSaleLeadStages)
