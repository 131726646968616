import { AgentPartnership } from 'models'
import { getAgentPartnershipsUrl } from 'services/getUrl'
import { deserializeAsync, postRequest, snakeCaseKeys } from 'utils'

export type AgentPartnershipPostPayload = {
  active: boolean
  description?: string
  destinationUrl?: string
  name?: string
  transactionTeamId?: string
}

export const postAgentPartnership = async (payload: AgentPartnershipPostPayload) => {
  const url = getAgentPartnershipsUrl()

  const response = await postRequest(url, snakeCaseKeys(payload))

  const agentPartnership: AgentPartnership = await deserializeAsync(response.data, {
    transform: AgentPartnership.create
  })
  return {
    agentPartnership
  }
}
