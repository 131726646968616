// @ts-strict
import { MapboxAutoComplete, MapboxAutoCompleteProps, MapboxAutocompleteResponse } from 'components'

export const CityStateAutoComplete = ({ onSelect, ...rest }: MapboxAutoCompleteProps) => {
  const handleSelect = (item: MapboxAutocompleteResponse[number]) => {
    onSelect?.(item ? item.title : '')
  }
  return (
    <MapboxAutoComplete
      data-testid="cityState-auto-complete"
      onSelect={handleSelect}
      types="place"
      {...rest}
    />
  )
}
