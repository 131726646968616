import { createCtx } from 'contexts'
import * as S from './styles'
import { useDrawer } from './useDrawer'

export const [useDrawerContext, DrawerContextProvider] = createCtx<ReturnType<typeof useDrawer>>({
  errorMessage:
    'useDrawerContext: `context` is undefined. Seems you forgot to wrap all your components within `<Drawer.Root />`'
})

/**
 * DRAWER SIDEBAR
 */

export const DrawerSidebar = S.Sidebar

DrawerSidebar.displayName = 'DrawerSidebar'

/**
 * DRAWER BODY
 */

export const DrawerBody = S.Body

DrawerBody.displayName = 'DrawerBody'
