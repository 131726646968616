import {
  CityStateAutoComplete,
  DateInput,
  DollarsInput,
  FormField,
  Section,
  Sections,
  Select
} from 'components'

export const BuyerLeadFormFields = ({
  formTitle,
  fieldSettings,
  fieldValues,
  leadPropertyTypes,
  leadTimelineValues,
  set,
  remove
}) => {
  return (
    <>
      <Sections title={formTitle}>
        <Section>
          <FormField
            data-testid="cityStateSelect"
            title="City, State"
            required={fieldSettings.cityState.isRequired}
          >
            <CityStateAutoComplete
              value={fieldValues.cityState}
              onSelect={val => set('cityState', val)}
              onClear={() => remove('cityState')}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="Property Type" required={fieldSettings.propertyType.isRequired}>
            <Select
              addBlank
              data-testid="propertyTypeSelect"
              value={fieldValues.propertyType}
              options={leadPropertyTypes}
              onChange={val => set('propertyType', val)}
            />
          </FormField>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <FormField title="Price" required={fieldSettings.price.isRequired}>
            <DollarsInput
              data-testid="priceInput"
              value={fieldValues.price}
              onChange={e => set('price', e)}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="Timeline" required={fieldSettings.timeline.isRequired}>
            <Select
              addBlank
              data-testid="timelineSelect"
              value={fieldValues.timeline}
              options={leadTimelineValues}
              onChange={val => set('timeline', val)}
            />
          </FormField>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <FormField title="Original Created At">
            <DateInput
              value={fieldValues.originalCreatedAt}
              onChange={val => set('originalCreatedAt', val)}
            />
          </FormField>
        </Section>
      </Sections>
    </>
  )
}
