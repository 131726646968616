// @ts-strict
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Contents } from 'components'
import { ActivityList } from 'content/Activities'
import { useOrderActivitiesController, useOrderController } from 'controllers'
import { useDocumentTitle } from 'hooks'

export const OrderActivityTab = () => {
  const { orderId = '' } = useParams()
  const { activitiesQuery } = useOrderActivitiesController(orderId)
  const { setTitle } = useDocumentTitle()
  const {
    orderQuery: { data: order }
  } = useOrderController(orderId)

  useEffect(() => {
    if (order) {
      setTitle(`${order.clientName} - Order Activity`)
    }
  }, [order, setTitle])

  return (
    <Contents title="Activity">
      <ActivityList
        activities={activitiesQuery.data?.activities}
        isLoading={activitiesQuery.isLoading}
      />
    </Contents>
  )
}
