// @ts-strict
import { Block, TableActions, TableCell as TD, Tooltip, TableRow as TR } from 'components'
import { LeadEditExternalLinkButtonForm, LeadRemoveExternalLinkButtonForm } from 'content/Lead'
import { useListContext } from 'contexts'
import { ExternalLink, Lead } from 'models'
import { dataOrDash, formatDate } from 'utils'

type TExternalLinksTableRowProps = {
  externalLink: ExternalLink
  lead: Lead
}

export const ExternalLinksTableRow = ({ externalLink, lead }: TExternalLinksTableRowProps) => {
  const { showColumn } = useListContext()
  const address = externalLink.subAttachable?.incomingMasterProperty
  return (
    <TR data-testid={`externalLinkRow-${externalLink.id}`}>
      {showColumn('name') && (
        <TD colSpan={showColumn('incomingResidence') ? 2 : 4}>
          <Block
            title={
              <a href={externalLink.url} target="_blank" rel="noreferrer">
                <Tooltip>{externalLink.name}</Tooltip>
              </a>
            }
          />
        </TD>
      )}
      {showColumn('incomingResidence') && (
        <TD colSpan={2}>
          <Block
            title={<Tooltip>{dataOrDash(address?.displayAddress)}</Tooltip>}
            subtitle={dataOrDash(address?.cityName)}
          />
        </TD>
      )}
      {showColumn('createdBy') && (
        <TD colSpan={1}>
          <Block
            title={<Tooltip>{externalLink?.createdBy?.name}</Tooltip>}
            subtitle={formatDate(externalLink.createdAt)}
          />
        </TD>
      )}
      {showColumn('actions') && (
        <TableActions>
          <LeadEditExternalLinkButtonForm lead={lead} externalLink={externalLink} />
          <LeadRemoveExternalLinkButtonForm externalLink={externalLink} />
        </TableActions>
      )}
    </TR>
  )
}
