// @ts-strict
import type { UseMutationResult } from 'react-query'
import { useButtonForm } from 'components'
import { validatePresence } from 'utils'

type PutAgentPayload = any

export type AgentButtonFormPayload = PutAgentPayload

export const useAgentAgreementButtonForm = (
  mutation: UseMutationResult<any, unknown, PutAgentPayload, unknown>,
  agreementId: string
) => {
  const transformPayload = (payload: AgentButtonFormPayload): AgentButtonFormPayload => ({
    agreementId: payload.agreementId
  })

  const formValidations = {
    agreementId: validatePresence
  }

  const { fieldsProps, formProps } = useButtonForm<AgentButtonFormPayload>({
    initialPayload: {
      agreementId: agreementId
    },
    mutation,
    transformPayload,
    formValidations
  })

  return { fieldsProps, formProps }
}
