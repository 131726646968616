import { SearchInput } from 'components/SearchInput'
import { useParamsContext } from 'contexts/Params'

export const SearchInputParams = ({ placeholder = '' }) => {
  const {
    params: { searchTerm },
    updateSearchTerm
  } = useParamsContext()

  return (
    <SearchInput
      value={searchTerm}
      onChange={updateSearchTerm}
      placeholder={placeholder}
      autoFocus
    />
  )
}
