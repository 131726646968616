// @ts-strict

import { TLeadUserTypeServerSlug } from './LeadUserType'

export type LeadUserTypeSettings = {
  abbreviation: string
  cashCloseOrdersFilter?: boolean
  duplicatableLeadUserTypeSlugs?: TLeadUserTypeServerSlug[]
  label: string
  onePerOrder?: boolean
  primary?: boolean
}

// deprecate this when the new HLSS is ready for production
export const leadUserTypeSettings: Partial<Record<TLeadUserTypeServerSlug, LeadUserTypeSettings>> =
  {
    buyer: {
      primary: true,
      duplicatableLeadUserTypeSlugs: ['buyer', 'seller', 'cc_cash_offer', 'mortgage'],
      label: 'Buyer',
      abbreviation: 'B'
    },
    seller: {
      primary: true,
      duplicatableLeadUserTypeSlugs: ['buyer', 'seller', 'cc_trade_in', 'mortgage'],
      label: 'Seller',
      abbreviation: 'S'
    },
    cc_trade_in: {
      primary: true,
      cashCloseOrdersFilter: true,
      duplicatableLeadUserTypeSlugs: ['cc_cash_offer', 'mortgage'],
      label: 'Buy Before You Sell',
      abbreviation: 'LTI'
    },
    cc_cash_offer: {
      primary: true,
      cashCloseOrdersFilter: true,
      duplicatableLeadUserTypeSlugs: ['cc_trade_in', 'mortgage', 'cc_hl_simple_sale'],
      label: 'Cash Offer',
      abbreviation: 'CO'
    },
    mortgage: {
      cashCloseOrdersFilter: true,
      duplicatableLeadUserTypeSlugs: [
        'cc_trade_in',
        'cc_cash_offer',
        'mortgage',
        'cc_hl_simple_sale'
      ],
      label: 'Mortgage',
      abbreviation: 'HL'
    },
    escrow: {
      duplicatableLeadUserTypeSlugs: [
        'cc_trade_in',
        'cc_cash_offer',
        'mortgage',
        'cc_hl_simple_sale'
      ],
      label: 'Escrow',
      abbreviation: 'E'
    },
    cc_hl_simple_sale: {
      primary: true,
      duplicatableLeadUserTypeSlugs: ['cc_trade_in'],
      label: 'HLSS',
      abbreviation: 'HS'
    }
  }
