// @ts-strict
import { Table, TableHeaderRow, TableResults, TableRow as Tr } from 'components'
import { TableHeaderColumn, useTable } from 'components/Table'
import { useListContext } from 'contexts'
import type { Inspection } from 'models'
import { InspectionTableCell } from './InspectionTableCell'

type Props = {
  isLoading?: boolean
  items: Potential<Inspection[]>
}

export const InspectionTable = ({ items, isLoading }: Props) => {
  const { visibleColumns, colSpan } = useListContext()
  const { getBodyCellProps, getHeaderCellProps } = useTable()

  return (
    <Table alternatingColumns data-testid="inspection-table">
      <TableHeaderRow>
        {visibleColumns.map(columnKey => (
          <TableHeaderColumn key={columnKey} {...getHeaderCellProps(columnKey)} />
        ))}
      </TableHeaderRow>
      <TableResults type="Reports" colSpan={colSpan} isLoading={isLoading}>
        {items?.map(item => (
          <Tr key={item.id}>
            {visibleColumns.map(columnKey => (
              <InspectionTableCell key={columnKey} {...getBodyCellProps(item, columnKey)} />
            ))}
          </Tr>
        ))}
      </TableResults>
    </Table>
  )
}
