// @ts-strict
import { ButtonForm, Section } from 'components'
import { useAgentAgreementsController } from 'controllers'
import type { Agent } from 'models'
import styles from './VoidButtonForm.module.scss'
import { useAgentAgreementButtonForm } from './useAgentAgreementButtonForm'

type Props = {
  agent: Agent
}

export const VoidButtonForm = ({ agent }: Props) => {
  const lastPendingAgreementId = agent.lastPendingAgreement?.agreement?.id!
  const currentAgreement = agent.lastSignedAgreement?.agreement?.version
  const currentCommission = agent.lastSignedAgreement?.agreement?.commissionSplit

  const { agentAgreementVoid } = useAgentAgreementsController(agent.id)
  const { formProps } = useAgentAgreementButtonForm(agentAgreementVoid, lastPendingAgreementId)

  return (
    <ButtonForm buttonText="Void" title="Void Agent Agreement" {...formProps}>
      <Section className={styles.section}>
        <span>
          If you proceed to void this agreement, this agent will be able to accept referrals again
          with {currentAgreement} at {currentCommission}%
        </span>
      </Section>
    </ButtonForm>
  )
}
