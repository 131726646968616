import { format, parseISO } from 'date-fns'
import { DEFAULT_STRING } from '../constants'

export const formatDateTime = (
  dateTime?: string | Date,
  fmt?: string,
  defaultValue = DEFAULT_STRING
) => {
  if (!dateTime) {
    return defaultValue
  }
  const defaultFormat = 'MMM d, yyyy h:mma'

  const formatString = fmt ?? defaultFormat

  try {
    const dateTimeDate = typeof dateTime === 'string' ? parseISO(dateTime) : dateTime
    return format(dateTimeDate, formatString).replace('AM', 'am').replace('PM', 'pm')
  } catch {
    return 'Invalid date'
  }
}
