// @ts-strict
import type { ReactNode } from 'react'
import { Spinner } from '@foundation/components'
import { Block, Icon, Popup } from 'components'
import { cx } from 'utils'
import styles from './Carousel.module.scss'
import type { UseCarouselProps } from './useCarousel'
import { useCarousel } from './useCarousel'

export type CarouselProps = UseCarouselProps & {
  actions?: ReactNode
  isLoading?: boolean
  onClose: () => void
  title?: string
}

export const Carousel = ({
  images,
  onNextSlide,
  onPrevSlide,
  current,
  actions,
  onClose,
  isLoading,
  title
}: CarouselProps) => {
  const loadingText = '...'
  const { currentPhoto, isZoomed, prevSlide, nextSlide, toggleZoom } = useCarousel({
    images,
    onNextSlide,
    onPrevSlide,
    current
  })

  const link = !isLoading && (
    <a
      href={currentPhoto?.url ?? ''}
      target="_blank"
      className={styles.footerTitle}
      rel="noreferrer"
    >
      View Full Size Image
      <Icon.ExternalLink className={styles.externalLinkIcon} />
    </a>
  )

  return (
    <Popup
      title={title ?? currentPhoto?.name ?? loadingText}
      onCancel={onClose}
      className={styles.popup}
      modalClassName={styles.modal}
    >
      <button
        onClick={prevSlide}
        title="previous image"
        className={cx(styles.carouselArrow, styles.left)}
        data-testid="previous-photo-link"
      >
        <Icon.LeftChevron />
      </button>

      <div className={cx(styles.popupContent, isZoomed && styles.zoomedIn)}>
        {!isLoading ? (
          <img
            src={currentPhoto?.url}
            className={styles.carouselImg}
            alt={currentPhoto?.name}
            onClick={toggleZoom}
          />
        ) : (
          <Spinner size="lg" />
        )}
      </div>

      <button
        onClick={nextSlide}
        title="next image"
        className={cx(styles.carouselArrow, styles.right)}
      >
        <Icon.RightChevron />
      </button>

      <Block
        title={link}
        subtitle={currentPhoto?.userLog ?? loadingText}
        data-testid="photo-upload-info"
        actions={actions}
        className={styles.popupFooter}
      />
    </Popup>
  )
}
