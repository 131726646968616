import { useEmployeeUsers } from 'controllers'
import {
  activityWindowOptions,
  leadPendingActionsOptions,
  productEligibilityOptions,
  sellerLeadStageOptions,
  stateNameOptions,
  trueFalseOptions,
  useListingStatus,
  userTypeOptions,
  useServicesOpportunities
} from 'lookups'
import { formatPrice, titleize } from 'utils'
import { buildSearchFilters, findFilterBySlug } from '../utils'
import { buyerAndSellerLeadSearchFilterSettings } from './buyerAndSellerLeadSearchFilterSettings'

export const useBuyerAndSellerLeadSearchFilter = () => {
  const { listingStatusOptions } = useListingStatus()
  const { getUserName } = useEmployeeUsers()
  const { activeServicesOpportunitiesStatusOptions, getOpportunityNameFromId } =
    useServicesOpportunities()

  const optionLoaders = {
    lastStageUpdate: activityWindowOptions,
    listingStatus: listingStatusOptions,
    oppStatus: activeServicesOpportunitiesStatusOptions,
    stage: sellerLeadStageOptions,
    trueFalse: trueFalseOptions,
    userType: userTypeOptions,
    pendingActions: leadPendingActionsOptions,
    productEligibility: productEligibilityOptions,
    stateName: stateNameOptions
  }
  const displayValueFnLoaders = {
    titleize,
    getUserName,
    formatPrice,
    getOpportunityNameFromId
  }

  const buyerAndSellerLeadFilters = buildSearchFilters(buyerAndSellerLeadSearchFilterSettings, {
    optionLoaders,
    displayValueFnLoaders
  })

  const findBuyerAndSellerLeadFilterBySlug = findFilterBySlug(buyerAndSellerLeadFilters)

  return { buyerAndSellerLeadFilters, findFilterBySlug: findBuyerAndSellerLeadFilterBySlug }
}
