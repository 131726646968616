import { ChangeEvent, ComponentPropsWithRef, forwardRef } from 'react'
import MaskedInput from 'react-text-mask'
import { cx } from 'utils'
import styles from './Form.module.scss'

export interface TextInputProps extends Omit<ComponentPropsWithRef<'input'>, 'onChange'> {
  className?: string
  hasError?: boolean
  inputMask?: string | Array<string | RegExp> | (() => string)
  onChange?: (val: string) => void
  type?: string
  value?: string
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ inputMask, value, onChange, className, type = 'text', hasError, ...props }, ref) => {
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!onChange) {
        return
      }

      const value = e.currentTarget.value.length ? e.currentTarget.value : ''
      onChange(value)
    }

    if (inputMask) {
      return (
        <MaskedInput
          className={cx(className, styles.textInput, hasError && styles.hasError)}
          {...props}
          ref={ref}
          mask={inputMask}
          value={value || ''}
          onChange={handleOnChange}
        />
      )
    }

    return (
      <input
        className={cx(className, styles.textInput, hasError && styles.hasError)}
        type={type}
        {...props}
        ref={ref}
        value={value || ''}
        onChange={handleOnChange}
      />
    )
  }
)
