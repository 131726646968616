import styled from 'styled-components'
import { Box, Flex, Icon } from '@foundation/components'
import { InvestorPayPerLeadSetting } from 'models'

type LocationSettingsRemoveListProps = {
  locationSettings: InvestorPayPerLeadSetting[]
  removeLocationSetting: (locationSlug: string) => void
}

export const AppliedFilterItem = styled.div`
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  font-size: 12px;
  font-weight: 700;
  color: #55585e;
  border-radius: 4px;
  background-color: #f5f6f9;
  transition: opacity 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`
export const LocationSettingsRemoveList = ({
  locationSettings,
  removeLocationSetting
}: LocationSettingsRemoveListProps) => {
  const locationSlugs = [...new Set(locationSettings.map(({ location }) => location))]

  return (
    <Box marginTop="$4">
      <Flex gap="$2" alignItems="center" flexWrap="wrap">
        <Flex flexWrap="wrap" alignItems="center" gap="$2">
          {locationSlugs.map(slug => (
            <AppliedFilterItem key={slug} onClick={() => removeLocationSetting(slug)}>
              {slugToCityState(slug)}
              <Icon path="Close" size="xs" />
            </AppliedFilterItem>
          ))}
        </Flex>
      </Flex>
    </Box>
  )
}

const slugToCityState = (slug: string) => {
  const parts = slug.split('-')

  if (parts.length < 2) {
    return slug
  }

  const state = parts.pop()?.toUpperCase()
  const city = parts.map(p => p.charAt(0).toUpperCase() + p.slice(1)).join(' ')

  return `${city}, ${state}`
}
