import {
  FormField,
  InlineEditDollarsAndCentsInput,
  InlineEditSelect,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { bbysLeadWarehouseOptions } from 'lookups'
import { dataOrDash, dateDiffInDays, formatDate } from 'utils'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import { getBBYSLeadInputProps } from './utils'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>

export const EquityUnlockInformationSection = ({ lead, updateBBYSLead, updateLead }: Props) => {
  const bbysLead = lead.bbysProviderLead.bbysLead
  const { getProps } = getBBYSLeadInputProps(bbysLead, updateBBYSLead)
  const today = formatDate(new Date(), 'yyyy-MM-dd')
  const diffInDays = dateDiffInDays(today, bbysLead?.loanFundingDate)
  // Add plus one day to include loan funding date
  const daysSinceEUFundingDate = diffInDays >= 0 ? String(diffInDays + 1).concat(' days ago') : null

  return (
    <Sections title="Equity Unlock Information">
      <Section>
        <FormField title="Days since EU funding" inline>
          {dataOrDash(daysSinceEUFundingDate)}
        </FormField>
        <FormField title="Buy Before You Sell loan amount" inline>
          <InlineEditDollarsAndCentsInput {...getProps('buyBeforeYouSellLoanAmount')} />
        </FormField>
        <FormField title="Equity Unlock loan funding amount" inline>
          <InlineEditDollarsAndCentsInput {...getProps('equityUnlockLoanFundingAmount')} />
        </FormField>
        <FormField title="Equity Unlock loan number" inline>
          <InlineEditTextInput maxLength={10} {...getProps('buyBeforeYouSellLoanNumber')} />
        </FormField>
        <FormField title="Equity Boost Amount (approved)" inline>
          <InlineEditDollarsAndCentsInput {...getProps('equityBoostAmount')} />
        </FormField>
        <FormField title="Equity Boost Funded" inline>
          <InlineEditDollarsAndCentsInput {...getProps('equityBoostFunded')} />
        </FormField>
        <Section borderless title="Equity Boost Assets">
          <FormField title="Savings Accounts" inline>
            <InlineEditDollarsAndCentsInput {...getProps('equityBoostCheckingSavingsAccount')} />
          </FormField>
          <FormField title="IRA & Brokerage Accounts" inline>
            <InlineEditDollarsAndCentsInput {...getProps('equityBoostIraBrokerageAccount')} />
          </FormField>
          <FormField title="401k" inline>
            <InlineEditDollarsAndCentsInput {...getProps('equityBoost401k')} />
          </FormField>
        </Section>
      </Section>
      <Section>
        <FormField title="Equity Unlock IMAD number" inline>
          <InlineEditTextInput maxLength={25} {...getProps('equityUnlockTamNumber')} />
        </FormField>
        <FormField title="Fed Ex tracking number" inline>
          <InlineEditTextInput {...getProps('fedexTrackingNumber')} />
        </FormField>
        <FormField title="Warehouse facility" inline>
          <InlineEditSelect
            {...getProps('warehouseFacility')}
            options={bbysLeadWarehouseOptions}
            addBlank
          />
        </FormField>
        <FormField title="Recording Fee" inline>
          <InlineEditDollarsAndCentsInput {...getProps('recordingFee')} />
        </FormField>
        <FormField title="Notary Fee" inline>
          <InlineEditDollarsAndCentsInput {...getProps('notaryFee')} />
        </FormField>
        <FormField title="Termination Fee" inline>
          <InlineEditDollarsAndCentsInput {...getProps('terminationFee')} />
        </FormField>
        <FormField title="Administration Fee" inline>
          <InlineEditDollarsAndCentsInput {...getProps('administrationFee')} />
        </FormField>
        <FormField title="Documentary and Intangible Taxes" inline>
          <InlineEditDollarsAndCentsInput {...getProps('documentaryAndIntangibleTaxes')} />
        </FormField>
      </Section>
    </Sections>
  )
}
