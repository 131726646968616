import { byAttr } from 'utils'
import { HLSimpleSaleProviderLeadField } from './HLSimpleSaleProviderLeadField'
import { hlSimpleSaleProviderLeadFieldsSettings, THLSimpleSaleProviderLeadFieldAttribute } from '.'

export const hlSimpleSaleProviderLeadFields = hlSimpleSaleProviderLeadFieldsSettings.map(
  setting => {
    return new HLSimpleSaleProviderLeadField(setting)
  }
)

export const getHLSimpleSaleProviderLeadField = (attr: THLSimpleSaleProviderLeadFieldAttribute) => {
  return hlSimpleSaleProviderLeadFields.find(byAttr('attr', attr))
}

export const getHLSimpleSaleProviderLeadFieldName = (
  attr: THLSimpleSaleProviderLeadFieldAttribute
) => {
  return getHLSimpleSaleProviderLeadField(attr)?.name
}
