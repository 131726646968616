import { ActionBar } from 'components'
import { InvestorLeadDispositionButtonForm } from 'content/InvestorLead'
import { InvestorLead } from 'models'
import { cx, d, toSnakeCase } from 'utils'
import styles from './InvestorLeadDispositionHeader.module.scss'

type InvestorLeadDispositionHeaderProps = {
  investorLead: InvestorLead
}

export const InvestorLeadDispositionHeader = ({
  investorLead
}: InvestorLeadDispositionHeaderProps) => {
  const stage = investorLead.providerLead.stage || ''
  const furthestStage = investorLead.providerLead.furthestStage

  const stageLabel = ['failed', 'nurture'].includes(stage)
    ? `${d(stage)} (${d(furthestStage)})`
    : d(stage)

  return (
    <div className={cx(styles.wrapper, styles[toSnakeCase(stage)])}>
      <div>{stageLabel}</div>
      <ActionBar className={styles.actionBar}>
        <InvestorLeadDispositionButtonForm investorLead={investorLead} />
      </ActionBar>
    </div>
  )
}
