// @ts-strict
import { Section } from 'components'
import { QualiaSyncButton } from 'content/EscrowLead'
import { EscrowLead } from 'models'

type Props = {
  lead?: EscrowLead
}

export const QualiaInfo = ({ lead }: Props) => {
  return (
    <Section title="Qualia">
      <QualiaSyncButton lead={lead} />
    </Section>
  )
}
