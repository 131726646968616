// @ts-strict

import { useState } from 'react'
import { Option } from 'classes'
import {
  CheckBoxArray,
  Column,
  Columns,
  EmployeesAutoComplete,
  FormField,
  ListBox,
  MenuSection,
  RadioButtons,
  Section,
  Select
} from 'components'
import { useEmployeeUsers, useFeatureFlags, useLookupsController, useNewFilters } from 'hooks'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import {
  activityWindowOptions,
  certificationOptions,
  eliteStatusOptions,
  enrollmentOptions as enrollmentOptionsLookup,
  leadStagesOptions,
  pitchStatusProgramsOptions,
  trueFalseOptions,
  useAgentPartnerships,
  useAssignedAreas,
  useMarketplacePrograms,
  useServicesOpportunities
} from 'lookups'
import { DEFAULT_PLATFORM_METRICS_TIME_RANGE } from 'schema/AgentPlatformMetricsSchema'
import { ListFilters } from 'templates'
import style from './AgentsListFilters.module.scss'

type Params = {
  agentPartnership: string
  assignedAreaId: string
  assigneeId: string
  calendly: string
  daysAgo: string
  eliteStatus: string[]
  enrollmentProgram: string
  enrollmentStatus: string
  inContract: string
  leadStages: string[]
  metricsTimeRange: string
  moveSafe: string
  pitchOppProduct: string
  pitchOppStatus: string
  preferredEscrowOfficerUserId: string
  tierStatus: string
  warmTransfer: string
}

export const AgentsListFilters = () => {
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const { newParams, set } = useNewFilters<Params>()
  const { marketplaceProgramsOptions } = useMarketplacePrograms()
  const { getUserName } = useEmployeeUsers()
  const { activeServicesOpportunitiesStatuses } = useServicesOpportunities()
  const enrollmentOptions = enrollmentOptionsLookup.filter(({ name }) => name !== 'All')
  const { assignedAreaOptions, isLoading } = useAssignedAreas({ assigneeId: newParams.assigneeId })
  const { partnershipOptions } = useAgentPartnerships()
  const [agentPlatformTiersTimeRanges] = useLookupsController('agent_platform_tiers_time_ranges')
  const [agentPlatformTiers] = useLookupsController('agent_platform_tiers')
  const { salesAppPartnerships, salesAppAgentMetrics } = useFeatureFlags([
    'sales-app-partnerships',
    'sales-app-agent-metrics'
  ])
  const { tracking } = useCustomEvents()

  const setEnrollmentProgram = (val: string) => {
    set('enrollmentProgram', val)
    if (val && !newParams.enrollmentStatus) {
      set('enrollmentStatus', 'enrolled')
    } else if (!val) {
      set('enrollmentStatus', '')
    }
  }

  const assigneeName = newParams.assigneeId ? getUserName(newParams.assigneeId) : ''
  const escrowOfficer = newParams.preferredEscrowOfficerUserId
    ? getUserName(newParams.preferredEscrowOfficerUserId)
    : ''

  const handleFilterTrigger = (open: boolean) => {
    if (open) {
      tracking({ name: 'filters', params: { action: 'open', target: 'agent' } })
    }

    setIsOpenPopover(open)
  }

  return (
    <ListFilters.Root isOpen={isOpenPopover} onOpenChange={handleFilterTrigger}>
      <ListFilters.Trigger>
        <ListFilters.TriggerButton iconRight="FilterAltOutlined">Filters</ListFilters.TriggerButton>
      </ListFilters.Trigger>
      <ListFilters.Content>
        <Section className={style.section}>
          <Columns className={style.hasAutocomplete}>
            <Column className={style.hasAutocomplete}>
              <MenuSection title="Assigned User">
                <EmployeesAutoComplete
                  value={assigneeName}
                  onSelect={val => set('assigneeId', val?.id)}
                  onClear={() => set('assigneeId', '')}
                />
              </MenuSection>
              <MenuSection title="Preferred Escrow Officer">
                <EmployeesAutoComplete
                  value={escrowOfficer}
                  onSelect={val => set('preferredEscrowOfficerUserId', val?.id)}
                  onClear={() => set('preferredEscrowOfficerUserId', '')}
                />
              </MenuSection>
              <MenuSection title="Assigned Area State / Metro">
                <Select
                  options={assignedAreaOptions}
                  value={newParams.assignedAreaId}
                  isLoading={isLoading}
                  onChange={val => set('assignedAreaId', val)}
                  addBlank
                />
              </MenuSection>
              <MenuSection title="Elite Status">
                <CheckBoxArray
                  options={eliteStatusOptions}
                  value={newParams.eliteStatus}
                  onChange={val => set('eliteStatus', val)}
                />
              </MenuSection>
              <MenuSection title="In Contract">
                <Select
                  options={trueFalseOptions}
                  value={newParams.inContract}
                  onChange={val => set('inContract', val)}
                  addBlank
                  data-testid="in-contract-field"
                />
              </MenuSection>
            </Column>
            {!salesAppAgentMetrics.enabled && (
              <>
                <Column title="Lead Stages">
                  <CheckBoxArray
                    options={leadStagesOptions}
                    value={newParams.leadStages}
                    onChange={val => set('leadStages', val)}
                  />
                </Column>
                <Column title="Activity Window">
                  <ListBox
                    options={activityWindowOptions}
                    value={newParams.daysAgo}
                    onChange={val => set('daysAgo', val)}
                  />
                </Column>
              </>
            )}
            {salesAppAgentMetrics.enabled && (
              <Column>
                <MenuSection title="Tier Status">
                  <Select
                    addBlank
                    value={newParams.tierStatus}
                    options={agentPlatformTiers.map(item => new Option(item))}
                    onChange={val => set('tierStatus', val)}
                  />
                </MenuSection>
                <MenuSection title="Tiers & Metrics Time Range">
                  <RadioButtons
                    value={newParams.metricsTimeRange ?? DEFAULT_PLATFORM_METRICS_TIME_RANGE}
                    options={agentPlatformTiersTimeRanges.map(item => new Option(item))}
                    onChange={val => set('metricsTimeRange', val!)}
                  />
                </MenuSection>
              </Column>
            )}
            <Column>
              <MenuSection title="Pitch Status">
                <Select
                  value={newParams.pitchOppProduct}
                  options={pitchStatusProgramsOptions}
                  onChange={val => set('pitchOppProduct', val)}
                  placeholder="Any Program"
                  addBlank
                />
                <Select
                  options={activeServicesOpportunitiesStatuses}
                  value={newParams.pitchOppStatus}
                  onChange={val => set('pitchOppStatus', val)}
                  placeholder="Any Status"
                  addBlank
                />
              </MenuSection>
              <MenuSection title="Enrollments">
                <Select
                  value={newParams.enrollmentProgram}
                  options={marketplaceProgramsOptions}
                  onChange={setEnrollmentProgram}
                  placeholder="Any Program"
                  addBlank
                />
                <Select
                  value={newParams.enrollmentProgram ? newParams.enrollmentStatus : undefined}
                  options={enrollmentOptions}
                  onChange={val => set('enrollmentStatus', val)}
                  placeholder="Any Status"
                  addBlank={!newParams.enrollmentProgram}
                  disabled={!newParams.enrollmentProgram}
                />
              </MenuSection>

              {salesAppPartnerships.enabled && (
                <MenuSection title="Partnership">
                  <Select
                    value={newParams.agentPartnership}
                    options={partnershipOptions}
                    onChange={val => set('agentPartnership', val)}
                    placeholder="All"
                    addBlank
                  />
                </MenuSection>
              )}
            </Column>
            {!salesAppAgentMetrics.enabled && (
              <Column title="Capabilities">
                <FormField title="Warm Transfer">
                  <Select
                    value={newParams.warmTransfer}
                    options={enrollmentOptionsLookup}
                    onChange={val => set('warmTransfer', val)}
                  />
                </FormField>
                <FormField title="Calendly">
                  <Select
                    value={newParams.calendly}
                    options={enrollmentOptionsLookup}
                    onChange={val => set('calendly', val)}
                  />
                </FormField>
                <FormField title="Move Safe">
                  <Select
                    value={newParams.moveSafe}
                    options={certificationOptions}
                    onChange={val => set('moveSafe', val)}
                  />
                </FormField>
              </Column>
            )}
          </Columns>
          <ListFilters.Footer>
            <ListFilters.ApplyButton newParams={newParams} onApplyToggle={setIsOpenPopover} />
          </ListFilters.Footer>
        </Section>
      </ListFilters.Content>
    </ListFilters.Root>
  )
}
