// @ts-strict
import { useEffect, useState } from 'react'
import {
  ButtonForm,
  ButtonV2,
  FormField,
  If,
  InlineEditSelect,
  Section,
  Select,
  TextArea
} from 'components'
import { useLeadContext } from 'contexts'
import useIsBbysEligible from 'hooks/useIsBbysEligible'
import { useLoanCalculator } from 'hooks/useLoanCalculator'
import {
  lenderLeadDispositionNotIntroducedOptions,
  lenderLeadDispositionOptions,
  lenderLeadTypeOptions,
  yesOrNoOptions
} from 'lookups'
import { isMortgageLead, Lender } from 'models'
import { d, stringifyValue } from 'utils'
import LenderDispositionBBYSScreenSection from './LenderDispositionBBYSScreenSection'
import styles from './LenderDispositionButtonForm.module.scss'
import LenderDispositionClientScreenSection from './LenderDispositionClientScreenSection'
import LenderDispositionMortgageScreenSection from './LenderDispositionMortgageScreenSection'
import { calculateBuyerIntent } from './calculateBuyerIntent'
import { useLenderDispositionButtonForm } from './useLenderDispositionButtonForm'

type TProps = {
  lender: Lender
}

export const LenderDispositionButtonForm = ({ lender }: TProps) => {
  const { lead, updateLead } = useLeadContext()

  const [isBBYSLeadType, setIsBBYSLeadType] = useState(false)
  const [selectedStage, setSelectedStage] = useState('')

  const { isEligible: isEligibleForLoan } = useLoanCalculator(lead)

  const validateEUValue = () => {
    const select = document.getElementById('lenderLeadDispositionFormLeadType') as HTMLInputElement
    if (select) {
      return ['bbys', 'bbys_medium_intent'].includes(select.value)
    }

    return false
  }
  const validateNotes = () => {
    const select = document.getElementById(
      'lenderLeadDispositionFormDisposition'
    ) as HTMLInputElement
    if (select) {
      return select.value !== 'application_created'
    }

    return false
  }
  const shouldValidateMortgageFields = () =>
    Boolean(
      ![
        'homelight_bbys_all_cash_buyer',
        'homelight_bbys_existing_lender',
        'homelight_ultra'
      ].includes(lender?.slug || '') &&
        selectedStage &&
        selectedStage === 'introduced'
    )

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useLenderDispositionButtonForm({
    lender,
    lead,
    validateEUValue: validateEUValue(),
    validateNotes: validateNotes(),
    validateMortgageFields: shouldValidateMortgageFields(),
    validateClientFields: selectedStage === 'introduced',
    isBBYSLeadType,
    validateLoan: isMortgageLead(lead) && shouldValidateMortgageFields()
  })

  const { isBBYSEligible } = useIsBbysEligible({
    lead
  })

  const isIntroduced = !!lender.providerLead?.introSentAt
  const showApplicationFields = lender.slug === 'lower_com'

  const stageOptions = isIntroduced
    ? lenderLeadDispositionOptions
    : lenderLeadDispositionNotIntroducedOptions.filter(
        stage => !(stage.value === 'application_created') || showApplicationFields
      )

  const stage = lender.providerLead?.stage || ''
  const skipAutoIntro = 'false'
  const clientSubmittedForm = null
  const leadBuyerIntent = calculateBuyerIntent(lead)

  const handleBBYSFieldsUpdate = ({ isBBYSEligible }: { isBBYSEligible: boolean }) => {
    onChangeAttribute('isBBYSEligible', isBBYSEligible ? 'eligible' : 'not_eligible')
  }

  useEffect(() => {
    onChangeAttribute('isEligibleForLoan', isEligibleForLoan)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEligibleForLoan])

  return (
    <ButtonForm
      title="Lender Lead Disposition"
      buttonText="Disposition"
      buttonComponent={buttonProps => (
        <ButtonV2
          className={styles.dispositionButton}
          {...buttonProps}
          disabled={updateLead.isLoading}
        />
      )}
      {...formProps}
    >
      <Section>
        <FormField title="Lender" inline className={styles.info}>
          {lender.name}
        </FormField>
        <If test={stage}>
          <FormField title="Current Stage" inline className={styles.info}>
            {d(stage)}
          </FormField>
        </If>
        <FormField title="Buyer State" inline className={styles.info} required>
          {payload.buyerState}
        </FormField>
        <FormField title="Buyer Intent" inline className={styles.info}>
          {leadBuyerIntent || ''}
        </FormField>
      </Section>
      <Section>
        <FormField title="Update Stage" required>
          <Select
            addBlank
            id="lenderLeadDispositionFormDisposition"
            options={stageOptions}
            value={String(payload.disposition)}
            onChange={val => {
              setSelectedStage(val)
              onChangeAttribute('disposition', val)
            }}
          />
        </FormField>
        <FormField title="Lead Type" required>
          <Select
            addBlank
            id="lenderLeadDispositionFormLeadType"
            options={lenderLeadTypeOptions}
            value={String(payload.leadType)}
            onChange={val => {
              onChangeAttribute('leadType', val)

              setIsBBYSLeadType(val === 'bbys')
              if (val === 'bbys') {
                handleBBYSFieldsUpdate({ isBBYSEligible })
              }
            }}
          />
        </FormField>
        <FormField title="Skip Agent Intro" required>
          <InlineEditSelect
            options={yesOrNoOptions}
            value={stringifyValue(skipAutoIntro)}
            onChange={val => onChangeAttribute('skipAutoIntro', val)}
          />
        </FormField>
        <If test={showApplicationFields && payload.disposition === 'introduced'}>
          <FormField title="Client submitted qualification form?">
            <InlineEditSelect
              options={yesOrNoOptions}
              value={stringifyValue(clientSubmittedForm)}
              onChange={val => onChangeAttribute('clientSubmittedForm', val)}
            />
          </FormField>
        </If>
        <If test={payload.disposition !== 'application_created'}>
          <FormField title="Note" htmlFor="note" required>
            <TextArea
              id="note"
              value={payload.note}
              onChange={val => onChangeAttribute('note', val)}
            />
          </FormField>
        </If>
      </Section>
      {payload.disposition === 'introduced' && (
        <>
          <LenderDispositionClientScreenSection
            onChangeAttribute={onChangeAttribute}
            isBBYSLeadType={isBBYSLeadType}
          />
          <LenderDispositionMortgageScreenSection
            onChangeAttribute={onChangeAttribute}
            optionalFields={!shouldValidateMortgageFields()}
          />
          {isBBYSLeadType && (
            <LenderDispositionBBYSScreenSection
              onChangeAttribute={onChangeAttribute}
              onFieldsUpdate={handleBBYSFieldsUpdate}
            />
          )}
        </>
      )}
    </ButtonForm>
  )
}
