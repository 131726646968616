import { LeadGroupableType } from 'models'
import { LeadGroupableAttributes } from 'schema'
import { getLeadGroupableUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export const postLeadGroupable = async (
  groupableType: LeadGroupableType,
  payload: Partial<LeadGroupableAttributes>
) => {
  return await postRequest(getLeadGroupableUrl({ action: groupableType }), snakeCaseKeys(payload))
}

export const postLeadGroupableCreateDefinition = async (
  groupableType: LeadGroupableType,
  groupableId: string
) => {
  return await postRequest(
    getLeadGroupableUrl({ action: `${groupableType}/${groupableId}/create-definition` })
  )
}
