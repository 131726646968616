import { PhotoUpload } from 'models'
import { getHapiRequestParams, PhotoUploadsSchema } from 'schema'
import { TFetchItems } from 'services'
import { getPhotosUrl } from 'services/getUrl'
import { deserializeAsync, getPageCount, getRequest } from 'utils'
import { getPhotoUploadsSearchParams, TUploadedPhotoSearchParams } from '.'

const photoResponseShape: PhotoUploadsSchema = {
  files: [
    'name',
    'createdAt',
    {
      fileVersions: ['fastlyUrl', 'metadata', 'mime']
    },
    {
      user: ['firstName', 'lastName']
    }
  ]
}

export const fetchPhotoUploads = async (
  settings: TUploadedPhotoSearchParams
): Promise<TFetchItems<PhotoUpload>> => {
  const url = getPhotosUrl({
    searchParams: {
      ...getPhotoUploadsSearchParams(settings),
      ...getHapiRequestParams(photoResponseShape)
    }
  })

  const response = await getRequest(url)
  const photos = await deserializeAsync(response.data, {
    transform: PhotoUpload.create
  })

  const totalCount = response.data?.meta?.total_count

  return {
    items: photos || [],
    pageCount: getPageCount(totalCount, settings.perPage),
    totalCount
  }
}
