import { useEffect } from 'react'
import { ActionBar, ActionButton, Contents, LoadingText, PaginationParams } from 'components'
import { EnvelopesTable } from 'content/Envelopes'
import {
  EnvelopesProvider,
  ListProvider,
  ParamsProvider,
  useCurrentUserContext,
  useEnvelopesContext,
  useLeadContext
} from 'contexts'
import { useDocumentTitle } from 'hooks'
import { docusignListColumns } from 'lookups'
import { LeadDocumentsTabs } from '../LeadDocumentsTabs'

const LeadDocusignTabContents = () => {
  const { envelopesQuery, oauthSignin } = useEnvelopesContext()
  const { currentUser } = useCurrentUserContext()

  if (envelopesQuery.isLoading) {
    return <LoadingText />
  }

  const actions = (
    <ActionBar>
      {!currentUser.docusignLoggedIn && (
        <ActionButton onClick={oauthSignin}>Sign In to DocuSign</ActionButton>
      )}
    </ActionBar>
  )

  return (
    <>
      <LeadDocumentsTabs />
      <Contents title="Docusign Envelopes" actions={actions}>
        <EnvelopesTable envelopes={envelopesQuery.data.envelopes} />
        <PaginationParams totalPages={envelopesQuery.data.pages} />
      </Contents>
    </>
  )
}

export const LeadDocusignTab = () => {
  const { lead } = useLeadContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${lead.name} - Documents - Docusign Envelopes`), [lead, setTitle])

  return (
    <ParamsProvider>
      <EnvelopesProvider lead={lead}>
        <ListProvider columns={docusignListColumns}>
          <LeadDocusignTabContents />
        </ListProvider>
      </EnvelopesProvider>
    </ParamsProvider>
  )
}
