// @ts-strict
import { useButtonForm } from 'components'
import { useLeadActivitiesController } from 'controllers'
import { useLocation } from 'hooks'
import { Lead } from 'models'
import { TFormValidation, validatePresence } from 'utils'

export type TLeadActivityNotePayload = {
  disposition?: string
  note: string
  slack?: boolean
}

const initialPayload: TLeadActivityNotePayload = { note: '', slack: true }

export const useLeadCreateActivityNoteButtonForm = (lead: Lead) => {
  const { pathname } = useLocation()
  const { addLeadActivityNote } = useLeadActivitiesController({
    leadId: lead.id,
    rootPath: pathname!
  })

  const defaultValidations: TFormValidation = {
    note: validatePresence
  }

  const transformPayload = (payload: TLeadActivityNotePayload) => {
    return {
      note: payload.note,
      slack: payload.slack,
      salesEventNoteAction: payload.disposition || 'activity_feed_note'
    }
  }

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm<TLeadActivityNotePayload>({
    initialPayload,
    mutation: addLeadActivityNote,
    transformPayload,
    formValidations: defaultValidations
  })

  return {
    formProps,
    onChangeAttribute,
    payload
  }
}
