// @ts-strict
import { Agent, EscrowOfficeProviderLead } from 'models/index'
import { LeadResponse } from 'schema'
import { Lead, TLeadChildConstructor } from '../Lead'

type TConstructor = TLeadChildConstructor<EscrowLead>

export class EscrowLead extends Lead {
  escrowOfficeProviderLeads?: EscrowOfficeProviderLead[]
  escrowOfficeProviderLead?: EscrowOfficeProviderLead
  modelName? = 'EscrowLead'
  referringAgent?: Agent
  constructor(escrowLeadAttributes: TConstructor) {
    super(escrowLeadAttributes)
    Object.assign(this, escrowLeadAttributes)
  }

  static create(escrowLeadResponse: Potential<LeadResponse>): Potential<EscrowLead> {
    if (!escrowLeadResponse) {
      return
    }
    const escrowOfficeProviderLeads = (escrowLeadResponse.escrowOfficeProviderLeads?.map(
      EscrowOfficeProviderLead.create
    ) || []) as EscrowOfficeProviderLead[]
    const escrowOfficeProviderLead = escrowOfficeProviderLeads?.[0]
    const referringAgent = Agent.create(escrowLeadResponse.referringOwner)
    delete escrowLeadResponse.referringOwner
    const leadAttributes = Lead.mapResponse(escrowLeadResponse)

    return new EscrowLead({
      ...leadAttributes,
      escrowOfficeProviderLeads,
      escrowOfficeProviderLead,
      referringAgent
    })
  }
}
