import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useWindowScroll } from 'react-use'
import { cx } from 'utils'
import styles from './Tabs.module.scss'

type Props = {
  children: ReactNode
  className?: string
  sticky?: boolean
}

export const Tabs = ({ children, className, sticky, ...props }: Props) => {
  const { y } = useWindowScroll()
  const [style, setStyle] = useState<string>()
  const elementRef = useRef<HTMLDivElement>()
  const placeholderRef = useRef<HTMLSpanElement>()

  useEffect(() => {
    const scrolledAbove =
      placeholderRef.current &&
      placeholderRef.current.getBoundingClientRect().top <= 40 &&
      elementRef.current &&
      elementRef.current.getBoundingClientRect().top <= 40
    if (scrolledAbove) {
      setStyle(styles.sticky)
    } else {
      setStyle(undefined)
    }
  }, [y])

  return (
    <>
      {sticky ? <span className={styles.placeholder} ref={placeholderRef} /> : null}
      <div ref={elementRef} className={cx(style, className, styles.tabs)} {...props}>
        {children}
      </div>
    </>
  )
}
