import { getEditEscrowOrderUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export type TPostEditEscrowOrderPayload = {
  address?: string
  asmId?: string
  city?: string
  newStage?: string
  orderIdentifier?: string
  parentLeadId?: string
  referringOwnerId?: string
  secondaryAddress?: string
  secondaryUserType?: string
  serviceOfficeId?: string
  srmId?: string
  stateCode?: string
  transactionCoordinatorEmail?: string
  transactionCoordinatorName?: string
  zipCode?: string
}

export const postEditEscrowOrder = async (
  payload: TPostEditEscrowOrderPayload,
  escrowOfficeLeadId?: string
): Promise<string> => {
  if (!escrowOfficeLeadId) {
    return ''
  }

  await postRequest(getEditEscrowOrderUrl({ id: escrowOfficeLeadId }), snakeCaseKeys(payload))

  return ''
}
