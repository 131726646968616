import { Client } from 'models/Client'
import { ClientSchema, getHapiRequestParams } from 'schema'
import { getClientsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const clientResponseShape: ClientSchema = {
  client: [
    'email',
    'firstName',
    'lastName',
    'softCreditCheckIntelligence',
    'softCreditCheckReportLink',
    { user: ['firstName', 'lastName', 'email'] }
  ]
}

export const fetchClient = async (id: string): Promise<Client> => {
  const searchParams = getHapiRequestParams(clientResponseShape)
  const url = getClientsUrl({ id, searchParams })

  const response = await getRequest(url)
  return await deserializeAsync(response.data, {
    transform: Client.create
  })
}
