import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMap } from 'react-use'
import { useLeadContext } from 'contexts'
import {
  getCashOfferProviderLeadStage,
  TCashOfferLeadFieldAttribute,
  TCashOfferProviderLeadStagesSlug
} from 'lookups'
import { CashOfferProviderLead, CCCashOfferLead } from 'models'
import { asArray, presence, stringifyValue } from 'utils'

export const useCashOfferProviderLeadDisposition = (
  lead: CCCashOfferLead,
  providerLead: CashOfferProviderLead
) => {
  const { updateProviderLeadDisposition, uncompletedLeadTasks } = useLeadContext()
  const cashOfferLead = providerLead.cashOfferLead
  const [commonPayload, { set: setCommonPayloadItem, setAll: setCommonPayload }] = useMap<any>()
  const [commonRequiredFields, setCommonRequiredFields] = useState([])
  const [dispositionPayload, { set: setDispositionPayloadItem, setAll: setDispositionPayload }] =
    useMap<any>()
  const [requiredFields, setRequiredFields] = useState<TCashOfferLeadFieldAttribute[]>([])
  const [payloadFields, setPayloadFields] = useState<TCashOfferLeadFieldAttribute[]>([])

  // determines if form is valid based on required fields.
  const isValid = useMemo(() => {
    return (
      requiredFields.every(field => presence(dispositionPayload[field])) &&
      commonRequiredFields.every(field => presence(commonPayload[field]))
    )
  }, [dispositionPayload, commonPayload, commonRequiredFields, requiredFields])

  useEffect(() => {
    if (cashOfferLead && payloadFields && setDispositionPayload) {
      setDispositionPayload(currentDispositionPayload => {
        return payloadFields.reduce((acc, field) => {
          const currentFieldValue = currentDispositionPayload[field]
          return {
            ...acc,
            [field]:
              currentFieldValue !== undefined
                ? currentFieldValue
                : stringifyValue(cashOfferLead[field])
          }
        }, {})
      })
    }
  }, [cashOfferLead, payloadFields, setDispositionPayload])

  const registerFields = useCallback(
    ({
      fields,
      required
    }: {
      fields?: TCashOfferLeadFieldAttribute | TCashOfferLeadFieldAttribute[]
      required?: TCashOfferLeadFieldAttribute | TCashOfferLeadFieldAttribute[]
    }) => {
      setPayloadFields([...asArray(fields).filter(Boolean), ...asArray(required).filter(Boolean)])
      setRequiredFields([...asArray(required).filter(Boolean)])
    },
    []
  )

  const onHandleSubmit = (onSuccessCallback: () => void) => {
    updateProviderLeadDisposition.mutate(
      {
        leadId: lead.id,
        id: providerLead.id,
        payload: { ...dispositionPayload, ...commonPayload }
      },
      { onSuccess: onSuccessCallback }
    )
  }

  const currentStage = getCashOfferProviderLeadStage(
    providerLead.stage as TCashOfferProviderLeadStagesSlug
  )

  const selectedStage = getCashOfferProviderLeadStage(commonPayload.disposition)

  const leadRequirementErrors =
    selectedStage?.leadRequirements &&
    Object.keys(selectedStage.leadRequirements)
      .map(requirement => {
        // if lead is not good, return the error message
        return !lead[requirement] && selectedStage.leadRequirements[requirement]
      })
      .filter(Boolean)

  const hasLeadRequirementErrors = presence(leadRequirementErrors)

  const uncompletedTasks = uncompletedLeadTasks
    .filter(task => !task.subAttachable || task.subAttachable.id === cashOfferLead.id)
    .filter(task => task.requiredForLeadStage === selectedStage?.slug)
    .map(item => item.title)

  const hasUncompletedTasks = presence(uncompletedTasks)

  const showSubmitForm = !hasUncompletedTasks && !hasLeadRequirementErrors

  const changes = useMemo(
    () =>
      Object.keys(dispositionPayload).reduce<
        Partial<Record<TCashOfferLeadFieldAttribute, boolean>>
      >((agr, key) => {
        if (dispositionPayload[key] !== cashOfferLead[key]) {
          return { ...agr, [key]: true }
        }
        return agr
      }, {}),
    [cashOfferLead, dispositionPayload]
  )

  return {
    cashOfferLead,
    changes,
    commonPayload,
    currentStage,
    dispositionPayload,
    hasLeadRequirementErrors,
    hasUncompletedTasks,
    isLoading: updateProviderLeadDisposition.isLoading,
    isValid,
    leadRequirementErrors,
    onHandleSubmit,
    registerFields,
    requiredFields,
    selectedStage,
    setCommonPayload,
    setCommonPayloadItem,
    setCommonRequiredFields,
    setDispositionPayloadItem,
    showSubmitForm,
    uncompletedTasks
  }
}
