import { ChangeEvent } from 'react'
import { RoundImage } from 'components/RoundImage'
import { AgentPrograms } from 'content/Agent/AgentPrograms'
import { Agent } from 'models'
import { AgentAttributes, AgentsSchema, getHapiRequestParams, StateResponse } from 'schema'
import {
  getSearchParams,
  TAgentsAutoCompleteParams
} from 'services/Agents/fetchAgentsAutoComplete/getSearchParams'
import { getAgentsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { randomImage } from 'utils'

type TAgentSearchResponse = {
  email1: string
  email2: string
  firstName: string
  id: string
  lastName: string
  officeDisplayName: string
  pictureThumbUrl: string
  state: StateResponse
}

export const transformAgentsAutoComplete = (agent: TAgentSearchResponse) => {
  const email = agent.email1 ? ` <${agent.email1}>` : ''

  const useDefaultImage = (e: ChangeEvent<HTMLImageElement>) => {
    e.currentTarget.src = randomImage(+agent.id, 50)
  }
  const agentObj = Agent.create(agent)

  return {
    id: agentObj.id,
    image: RoundImage({
      src: agentObj.pictureThumbUrl,
      onError: useDefaultImage,
      alt: `${agentObj.name} thumbnail`
    }),
    title: `${agentObj.name}${email}`,
    subTitle: `${agentObj.officeDisplayName}, ${agentObj.state.code}`,
    child: <AgentPrograms agent={agentObj} />
  }
}

export const fetchAgentsAutoComplete = async (params: TAgentsAutoCompleteParams) => {
  const agentsFields: Array<keyof AgentAttributes> = [
    'firstName',
    'lastName',
    'pictureThumbUrl',
    'officeDisplayName'
  ]

  const agentsShape: AgentsSchema = {
    agents: [
      ...agentsFields,
      { state: ['code'] },
      { phoneNumbers: ['number'] },
      {
        marketplaceProgramAgents: [
          'pending',
          'enrolled',
          'declined',
          'declinedAt',
          'createdAt',
          { marketplaceProgram: ['name', 'slug'] }
        ]
      }
    ]
  }

  const searchParams = {
    ...getHapiRequestParams(agentsShape),
    ...getSearchParams(params)
  }

  const response = await getRequest(
    getAgentsUrl({
      searchParams
    })
  )
  const data = response.data.data.filter(
    agent => agent.relationships.marketplace_program_agents.data.length
  )

  return await deserializeAsync(
    {
      ...response.data,
      data
    },
    {
      transform: transformAgentsAutoComplete
    }
  )
}
