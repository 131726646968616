import { useEffect, useState } from 'react'
import { Button } from '@foundation/components'
import { Section, Select } from 'components'
import { LeadNewIncomingResidenceButtonForm } from 'content/Lead'
import { d, idMatch, isEmptyValue } from 'utils'
import { CashOfferDisposition } from '../../CashClose'
import { CCCashOfferLeadDetailsProps } from '../CCCashOfferLeadDetails'
import styles from './IR.module.scss'
import {
  IROfferDetailsSection,
  IRPropertyDetailsSection,
  IRPropertyValuationSection,
  IRPurchaseTransaction,
  IRSaleTransactionSection
} from './'

export type IncomingResidenceProps = Pick<
  CCCashOfferLeadDetailsProps,
  'lead' | 'updateLead' | 'createCashOffer' | 'runMortgageAffordability'
>

export const IR = ({
  lead,
  updateLead,
  createCashOffer,
  runMortgageAffordability
}: IncomingResidenceProps) => {
  const [showLeadDispositionForm, setShowLeadDispositionForm] = useState<boolean>(false)
  const [cashOfferLeadId, setCashOfferLeadId] = useState<string>(
    lead.activeCashOfferLead?.id.toString()
  )

  const toggleLeadDispositionForm = () => {
    setShowLeadDispositionForm(!showLeadDispositionForm)
  }

  useEffect(() => {
    setCashOfferLeadId(lead.activeCashOfferLead?.id.toString())
  }, [lead.activeCashOfferLead?.id])

  const renderNewIncomingResidenceFormButton = (
    <LeadNewIncomingResidenceButtonForm lead={lead} createCashOffer={createCashOffer} />
  )

  if (isEmptyValue(lead.cashOfferProviderLeads)) {
    return (
      <Section
        data-testid="incomingResidencesSection"
        title="Incoming Residence"
        actions={renderNewIncomingResidenceFormButton}
      >
        There are no Incoming Residences
      </Section>
    )
  }

  const cashOfferLeadOptions = lead.getCashOfferProviderLeadsOptions()

  const cashOfferProviderLead =
    lead.cashOfferProviderLeads.find(copl => idMatch(copl.cashOfferLead.id, cashOfferLeadId)) ||
    lead.activeCashOfferProviderLead
  const cashOfferLead = cashOfferProviderLead.cashOfferLead

  const selectCashOfferLeadDropDown =
    cashOfferLeadOptions.length > 1 ? (
      <Select
        options={cashOfferLeadOptions}
        onChange={setCashOfferLeadId}
        className={styles.select}
        placeholder="Select..."
        addBlank
      />
    ) : null

  const showCashOfferLeadDispositionFormButton = (
    <Button variant="outline" size="xs" color="neutralLight" onClick={toggleLeadDispositionForm}>
      Disposition
    </Button>
  )

  return (
    <>
      <Section
        data-testid="incomingResidencesSection"
        title="Incoming Residences"
        actions={<div className={styles.irActions}>{renderNewIncomingResidenceFormButton}</div>}
        className={styles.incomingResidence}
      >
        <Section
          title={`${cashOfferLead.incomingPropertyFullAddress || 'Unknown Location'} (${d(
            cashOfferProviderLead.stage
          )})`}
          actions={
            <div className={styles.irActions}>
              {selectCashOfferLeadDropDown}
              {showCashOfferLeadDispositionFormButton}
            </div>
          }
        />
        <IRPropertyDetailsSection
          cashOfferLead={cashOfferLead}
          cashOfferProviderLead={cashOfferProviderLead}
        />
        <IROfferDetailsSection cashOfferLead={cashOfferLead} />
        <IRPropertyValuationSection
          lead={lead}
          cashOfferLead={cashOfferLead}
          runMortgageAffordability={runMortgageAffordability}
        />
        <IRPurchaseTransaction lead={lead} updateLead={updateLead} cashOfferLead={cashOfferLead} />
        <IRSaleTransactionSection cashOfferLead={cashOfferLead} />
      </Section>
      {showLeadDispositionForm && (
        <CashOfferDisposition
          lead={lead}
          providerLead={cashOfferProviderLead}
          onCancel={toggleLeadDispositionForm}
        />
      )}
    </>
  )
}
