import { cx, marked } from 'utils'
import styles from './Markdown.module.scss'

type Props = {
  children: string
  className?: string
}

export const Markdown = ({ className, children }: Props) => {
  const markup = marked.parse(children)
  return (
    <div className={cx(styles.marked, className)} dangerouslySetInnerHTML={{ __html: markup }} />
  )
}
