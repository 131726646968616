// @ts-strict
import { Block, TableCell as Td } from 'components'
import { MortgageLead } from 'models'
import { byAttr, dataOrDash } from 'utils'

type TProps = {
  column?: string
  lead: MortgageLead
  testId: string
}

export const MortgageLeadTxTeamCell = ({ lead, testId }: TProps) => (
  <Td data-testid={testId}>
    <Block allowTitleOverflow title={dataOrDash(lead.referringAgent?.transactionTeam?.name)} />
  </Td>
)

export const MortgageLeadLoanOfficerCell = ({ lead, testId }: TProps) => {
  const loanOfficer = lead.leadUsers?.find(byAttr('role', 'loan_officer'))
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(loanOfficer?.user?.name)} />
    </Td>
  )
}

export const MortgageLeadLoanOfficerTeamLeadCell = ({ lead, testId }: TProps) => {
  const loanOfficerTeamLead = lead.leadUsers?.find(byAttr('role', 'loan_officer_team_lead'))
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(loanOfficerTeamLead?.user?.name)} />
    </Td>
  )
}

export const MortgageLeadLoanOfficerAssistantCell = ({ lead, testId }: TProps) => {
  const loanOfficerAssistant = lead.leadUsers?.find(byAttr('role', 'loan_officer_assistant'))
  return (
    <Td data-testid={testId}>
      <Block allowTitleOverflow title={dataOrDash(loanOfficerAssistant?.user?.name)} />
    </Td>
  )
}
