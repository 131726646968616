import React from 'react'
import { IconButton } from '../IconButton'
import { useDrawerContext } from './Drawer'
import * as S from './styles'

type DrawerHeaderProps = {
  /**
   * The header title
   */
  children: string
}

export const DrawerHeader = ({ children }: DrawerHeaderProps) => {
  const { closeDrawer } = useDrawerContext()

  return (
    <S.Header>
      <h2>{children}</h2>
      <IconButton title="Close" icon="Close" variant="ghost" size="md" onClick={closeDrawer} />
    </S.Header>
  )
}

DrawerHeader.displayName = 'DrawerHeader'
