import { TValidation } from 'utils'
import { presence } from 'utils/presence'

export const validatePropertyUuid: TValidation = ({ label, value }) => {
  if (!presence(value)) {
    return []
  }
  const errors = []

  if (value === 'not_found') {
    errors.push([`Property not found in property service`])
  }

  return errors
}
