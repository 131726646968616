// @ts-strict

import { AddLeadTeamMemberVariables } from 'contexts/types'
import { useMutation, useQuery, useQueryClient } from 'hooks'
import { deleteLeadTeamMember, fetchLeadTeam, postLeadTeamMember } from 'services'

export const useLeadTeam = (leadId: Potential<string>) => {
  const queryCache = useQueryClient()
  const defaultCallback = {
    onSuccess: () => queryCache.invalidateQueries(['lead_team', leadId])
  }

  const query = useQuery(['lead_team', leadId], () => fetchLeadTeam(leadId!), {
    refetchOnWindowFocus: false,
    enabled: !!leadId
  })

  const leadTeamMembers = query.data?.leadUsers

  const addTeamMember = useMutation(
    (payload: AddLeadTeamMemberVariables) => postLeadTeamMember(leadId, payload),
    defaultCallback
  )

  const removeTeamMember = useMutation(deleteLeadTeamMember, defaultCallback)

  const memberExists = (userId: string) => {
    return leadTeamMembers?.some(leadUser => leadUser.user?.id === userId)
  }

  const roleExists = (role: string) => {
    return leadTeamMembers?.some(leadUser => leadUser.role === role)
  }

  return {
    leadTeamMembers,
    isLoading: query.isLoading,
    addTeamMember,
    removeTeamMember,
    memberExists,
    roleExists
  }
}
