import { useState } from 'react'
import { Text } from '@foundation/components'
import { ButtonForm, ButtonV2, Divider, Section, Sections, TextInput } from 'components'
import { useCurrentUserContext } from 'contexts'
import { useReferralPreferencesController } from 'controllers/ReferralPreferences'
import type { Agent } from 'models'
import styles from './LocationPreferences.module.scss'
import { useSelectedLocationsButtonForm, ZipCodesSection } from '.'

type SelectedLocationsButtonFormProps = {
  agent: Agent
}

export const SelectedLocationsButtonForm = ({ agent }: SelectedLocationsButtonFormProps) => {
  const [filterValue, setFilterValue] = useState<string>('')
  const { isAgentAdmin } = useCurrentUserContext()
  const { updateAgentReferralPreferences } = useReferralPreferencesController(agent.id)
  const {
    fieldsProps: { onChangeAttribute, payload },
    formProps
  } = useSelectedLocationsButtonForm(updateAgentReferralPreferences, agent)

  if (!isAgentAdmin) {
    return null
  }

  const onDisableCode = (code: string) => {
    onChangeAttribute(
      'selectedZipCodes',
      payload.selectedZipCodes.filter(c => c !== code)
    )
    onChangeAttribute('excludedZipCodes', [...payload.excludedZipCodes, code])
  }

  const onEnableCode = (code: string) => {
    onChangeAttribute('selectedZipCodes', [...payload.selectedZipCodes, code])
    onChangeAttribute(
      'excludedZipCodes',
      payload.excludedZipCodes.filter((c: string) => c !== code)
    )
  }

  const onClose = () => {
    setFilterValue('')
    formProps.handleClose()
  }

  const selectedZipCodes = payload.selectedZipCodes.filter((code: string) =>
    code.includes(filterValue)
  )
  const excludedZipCodes = payload.excludedZipCodes.filter((code: string) =>
    code.includes(filterValue)
  )

  return (
    <ButtonForm
      title="Historical transaction and disabled locations"
      buttonText="Edit Locations"
      handleClose={onClose}
      buttonComponent={buttonProps => (
        <ButtonV2 className={styles.editLocationsButton} {...buttonProps} />
      )}
      {...formProps}
    >
      <Section>
        <Text>
          You can click each zip code to disable or enable and use the search bar to filter your
          search.
        </Text>
      </Section>
      <Section>
        <Sections className={styles.inputLabel}>
          <Text size="sm">Filter zip codes</Text>
        </Sections>
        <TextInput placeholder="Filter zip codes" value={filterValue} onChange={setFilterValue} />
      </Section>
      <Section>
        <Sections>
          <Text weight="bold">Historical transaction locations</Text>
        </Sections>
        <Section>
          <ZipCodesSection
            active
            sectionName="historical"
            onZipCodeClick={onDisableCode}
            zipCodes={selectedZipCodes}
          />
        </Section>
      </Section>
      <Divider className={styles.divider} />
      <Section>
        <Sections>
          <Text weight="bold">Disabled locations</Text>
        </Sections>
        <Section>
          <ZipCodesSection
            active
            sectionName="disabled"
            onZipCodeClick={onEnableCode}
            zipCodes={excludedZipCodes}
          />
        </Section>
      </Section>
    </ButtonForm>
  )
}
