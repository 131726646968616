import { AgentProviderLead } from 'models'
import { ActivityList } from '../../Activities/ActivitiesList'

type Props = {
  agentProviderLead: AgentProviderLead
}

export const AgentProviderLeadActivity = ({ agentProviderLead }: Props) => {
  return (
    <ActivityList
      activities={agentProviderLead.agentLead.agentLeadStageUpdates?.map(agentLeadStageUpdate =>
        agentLeadStageUpdate.toActivity()
      )}
    />
  )
}
