import { Button, Contents, List } from 'components'
import { useDocumentTitle, userSettings, useUserSettings } from 'hooks'
import { formatBoolean, titleize, toSnakeCase } from 'utils'
import styles from '../SettingsPage.module.scss'

export const SettingsPreferencesTab = () => {
  useDocumentTitle('Settings')

  const { settings, toggleKey } = useUserSettings()

  return (
    <Contents title="Preferences">
      <List className={styles.settingsList}>
        {userSettings.map(key => {
          return (
            <Button key={key} onClick={() => toggleKey(key)} className={styles.settingsButton}>
              <span>{titleize(toSnakeCase(key))}:</span>
              <span> {formatBoolean(settings[key] || false)}</span>
            </Button>
          )
        })}
      </List>
    </Contents>
  )
}
