import { getTextsUrl } from 'services/getUrl'
import { postRequest } from 'utils'

type TProps = {
  body: string
  id: string
  type: 'Agent' | 'User'
}

export const postMessage = async ({ id, type, body = '' }: TProps) => {
  await postRequest(getTextsUrl(), {
    body: body.trim(),
    resource_id: id,
    resource_type: type
  })
}
