import { SearchParams } from '@types'
import { getFilter, getSort } from '../../utils'

const sortSlugs = {
  Created: 'created_at',
  Name: 'name.keyword',
  Score: '_score',
  Updated: 'order.last_lead_stage_update',
  Stage: 'stage',
  Price: 'price'
}

const filterSlugs = {
  searchTerm: 'filter[_search]',
  assigneeId: 'filter[lead_users.user_id]',
  userType: 'filter[user_type]',
  maxPrice: 'filter[<price]',
  minPrice: 'filter[>price]',
  page: 'page[number]',
  perPage: 'page[size]',
  sort: 'sort',
  stateName: 'filter[state_name]'
}

export type AllLeadsSearchParams = Pick<
  SearchParams<{ sortBy: keyof typeof sortSlugs }>,
  'page' | 'perPage' | 'searchTerm' | 'sortDir' | 'sortBy'
> & {
  assigneeId?: string
  maxPrice?: string
  minPrice?: string
  stateName?: string
  userType?: string
}

export const getAllLeadsSearchParams = ({
  sortBy,
  sortDir,
  ...filterValue
}: AllLeadsSearchParams) => {
  const result = getFilter(filterSlugs, filterValue)
  result['sort'] = getSort(sortSlugs, sortDir, sortBy)

  return result
}
