// @ts-strict
import styled from 'styled-components'
import theme from '@foundation/themes'

export const Separator = styled.span`
  border-left: ${theme.borderWidths.thin} solid ${theme.colors.neutral5};
  height: ${theme.sizes.$8};
  display: inline-block;
  vertical-align: bottom;
  padding: 0;
`
