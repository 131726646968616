import { Option } from 'classes'
import { ModelField } from '../ModelField'
import {
  TCcTradeInLeadFieldAttribute,
  TCcTradeInLeadFieldSetting
} from './ccTradeInLeadFieldsSettings'

export class CcTradeInLeadField extends ModelField {
  attr!: TCcTradeInLeadFieldAttribute

  constructor(attributes: CcTradeInLeadField) {
    super(attributes)
    Object.assign(this, attributes)
  }

  static createFromSetting(setting: TCcTradeInLeadFieldSetting) {
    const options = setting.options?.map(opt => new Option(opt))
    return new CcTradeInLeadField({ ...setting, options })
  }
}
