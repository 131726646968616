import React from 'react'
import { AutoComplete, AutoCompleteProps } from '../../../../components'
import { getTeamsUrl } from '../../../../services/getUrl'
import { getRequest } from '../../../../utils'

type TProps = {
  onSelect?: AutoCompleteProps['onSelect']
  value?: string
}

export const TeamsAutocomplete = ({ onSelect = () => {}, value, ...props }: TProps) => {
  const formatTeam = ({ id, attributes: { name, description } }) => ({
    id,
    title: name,
    subTitle: description
  })
  const formatTeams = teams => teams.map(formatTeam)

  const getTeams = async (searchTerm: string) => {
    const searchParams = {
      'filter[_search]': searchTerm,
      autocomplete: true,
      'page[size]': 10
    }
    const response = await getRequest(getTeamsUrl({ searchParams }))
    return formatTeams(response.data.data).slice(0, 10)
  }

  return (
    <AutoComplete
      getData={getTeams}
      onSelect={onSelect}
      value={value}
      placeholder="Enter team name..."
      {...props}
    />
  )
}
