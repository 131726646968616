import { Agent, AgentTeamMembership } from 'models'
import { AgentResponse, AgentTeamResponse } from 'schema'

type TAgentTeamConstructor = Omit<
  AgentTeam,
  | 'getLeadRoutingActiveAgents'
  | 'getWarmTransferOptInAgents'
  | 'hasLeadRoutingSeatsAvailable'
  | 'hasWarmTransferSeatsAvailable'
>

export class AgentTeam {
  id: string
  name: string
  agent?: Agent
  agentTeamMemberships?: AgentTeamMembership[]
  leadRoutingEnabled?: boolean
  leadRoutingSeats?: number
  warmTransferSeats?: number

  constructor(agentTeamAttributes: TAgentTeamConstructor) {
    Object.assign(this, agentTeamAttributes)
  }

  static create(
    agentTeamResponse: Potential<AgentTeamResponse>,
    agentResponse?: Potential<AgentResponse>
  ): Potential<AgentTeam> {
    if (!agentTeamResponse) {
      return
    }

    const agent = Agent.create(agentTeamResponse.agent)

    if (agentResponse) {
      const selectedAgentIndex = agentTeamResponse?.agentTeamMemberships.findIndex(
        membership => membership.user?.agent?.id === agentResponse.id
      )

      if (selectedAgentIndex !== -1) {
        agentTeamResponse.agentTeamMemberships[selectedAgentIndex].user.agent.referralPreferences =
          agentResponse.referralPreferences
      }
    }

    const agentTeamMemberships =
      agentTeamResponse?.agentTeamMemberships?.map(AgentTeamMembership.create) || []

    return new AgentTeam({
      ...agentTeamResponse,
      agent,
      agentTeamMemberships
    })
  }

  getLeadRoutingActiveAgents() {
    return this.agentTeamMemberships.filter(
      membership => membership?.user?.agent?.referralPreferences?.active
    )
  }

  getWarmTransferOptInAgents() {
    return this.agentTeamMemberships.filter(
      membership => membership?.user?.agent?.referralPreferences?.leadRoutingWarmTransferOptIn
    )
  }

  hasLeadRoutingSeatsAvailable() {
    return this.getLeadRoutingActiveAgents().length < this.leadRoutingSeats
  }

  hasWarmTransferSeatsAvailable() {
    return this.getWarmTransferOptInAgents().length < this.warmTransferSeats
  }
}
