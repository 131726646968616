// @ts-strict
import { AgentPayPerLeadPaymentInformation } from 'models'
import { getPplPaymentInfosUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchAgentPayPerLeadPaymentInformation = async (agentId: string) => {
  const url = getPplPaymentInfosUrl({
    id: agentId
  })

  const response = await getRequest(url)

  return deserializeAsync(response.data, { transform: AgentPayPerLeadPaymentInformation.create })
}
