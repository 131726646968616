import { Section } from 'components'
import { FieldFormField } from '../FieldFormField'
import { useFields } from '../useFields'

export const CashOfferOfferMade = () => {
  useFields({
    required: ['incomingPropertyInspectionContingencyWaived']
  })

  return (
    <Section>
      <FieldFormField field="incomingPropertyInspectionContingencyWaived" />
    </Section>
  )
}
