export const initializeString = (sourceString: string | undefined, maxChars = 2) => {
  if (!sourceString) {
    return ''
  }
  return sourceString
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .slice(0, maxChars)
    .join('')
}
