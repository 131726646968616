import { TColumnAttributes } from '../../Column'

const columns: Record<string, TColumnAttributes> = {
  name: {
    name: 'Name',
    value: 'name'
  },
  incomingResidence: {
    name: 'IR Address',
    value: 'incomingResidence'
  },
  createdBy: {
    name: 'Created By',
    value: 'createdBy'
  },
  actions: {
    name: 'Actions',
    value: 'actions'
  }
}

export const externalResourcesColumnsSettings = [
  columns.name,
  columns.createdBy,
  columns.actions
] as const

export const externalResourcesCashOfferColumnsSettings = [
  columns.name,
  columns.incomingResidence,
  columns.createdBy,
  columns.actions
] as const

export type TExternalResourcesColumnValue =
  (typeof externalResourcesCashOfferColumnsSettings)[number]['value']
