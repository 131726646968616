// @ts-strict
import { TMarketplaceProgramSlug } from 'lookups'
import { MarketplaceProgram } from 'models'
import { ServicesOpportunitiesStatusAttributes, ServicesOpportunityResponse } from 'schema'

export class ServicesOpportunity {
  id!: string
  productType?: TMarketplaceProgramSlug & 'title_escrow'
  status?: ServicesOpportunitiesStatusAttributes
  marketplaceProgram?: MarketplaceProgram

  constructor(servicesOpportunityAttributes: ServicesOpportunity) {
    Object.assign(this, servicesOpportunityAttributes)
  }

  static create(
    servicesOpportunityResponse: Potential<ServicesOpportunityResponse>
  ): Potential<ServicesOpportunity> {
    if (!servicesOpportunityResponse) {
      return
    }
    const status = servicesOpportunityResponse.status
    const marketplaceProgram = MarketplaceProgram.create({
      slug: servicesOpportunityResponse.productType
    })
    return new ServicesOpportunity({
      ...servicesOpportunityResponse,
      marketplaceProgram,
      status
    })
  }
}
