import { fetchAgentsAutoComplete } from 'services/Agents'
import { cx } from 'utils'
import { AutoComplete } from '../AutoComplete'
import styles from './AgentsAutoComplete.module.scss'

type Props = {
  autoFocus?: boolean
  className?: string
  onBlur?: () => void
  onClear: () => void
  onSelect: (agent: AgentAutoCompleteResponse) => void
  searchParams?: object
  value?: string
}

export type AgentAutoCompleteResponse = {
  id: string
  subTitle: string
  title: string
}

export const AgentsAutoComplete = ({
  onSelect,
  onClear,
  onBlur,
  value,
  searchParams,
  autoFocus,
  className,
  ...props
}: Props) => {
  return (
    <AutoComplete
      fetcher={searchTerm => fetchAgentsAutoComplete({ ...searchParams, searchTerm })}
      onSelect={onSelect as any}
      onClear={onClear}
      onBlur={onBlur}
      value={value}
      className={cx(className, styles.autoComplete)}
      placeholder="Enter agent name or email..."
      autoFocus={autoFocus}
      data-testid="agents-autocomplete"
      {...props}
    />
  )
}
