// @ts-strict

import { useState } from 'react'
import {
  CheckBoxArray,
  Column,
  Columns,
  EmployeesAutoComplete,
  MenuSection,
  Section,
  Select
} from 'components'
import { useListContext } from 'contexts'
import { useNewFilters } from 'hooks'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import { ListFilters } from 'templates'
import styles from './CCHLSimpleSaleLeadListFilters.module.scss'

type TParams = {
  assigneeId: string
  capitalPartnerId: string
  source: string[]
  stage: string[]
  stateName: string
  twoChecks: string
}

export const CCHLSimpleSaleLeadListFilters = () => {
  const { tracking } = useCustomEvents()
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const { newParams, set } = useNewFilters<TParams>()
  const { findFilterBySlug } = useListContext()
  const stageFilter = findFilterBySlug('stage')
  const sourceFilter = findFilterBySlug('source')
  const assigneeIdFilter = findFilterBySlug('assigneeId')
  const capitalPartnerIdFilter = findFilterBySlug('capitalPartnerId')
  const twoChecksFilter = findFilterBySlug('twoChecks')
  const stateFilter = findFilterBySlug('stateName')

  return (
    <ListFilters.Root isOpen={isOpenPopover} onOpenChange={setIsOpenPopover}>
      <ListFilters.Trigger>
        <ListFilters.TriggerButton iconRight="FilterAltOutlined">Filters</ListFilters.TriggerButton>
      </ListFilters.Trigger>
      <ListFilters.Content>
        <Section className={styles.section}>
          <Columns className={styles.resetOverflow}>
            <Column title={stageFilter?.name}>
              <CheckBoxArray
                options={stageFilter?.options!}
                value={newParams.stage}
                onChange={val => set('stage', val)}
              />
            </Column>
            <Column>
              <MenuSection title={sourceFilter?.name}>
                <CheckBoxArray
                  options={sourceFilter?.options!}
                  value={newParams.source}
                  onChange={val => set('source', val)}
                />
              </MenuSection>
            </Column>
            <Column className={styles.resetOverflow}>
              <MenuSection title={assigneeIdFilter?.name}>
                <EmployeesAutoComplete
                  value={assigneeIdFilter?.getDisplayValue(newParams.assigneeId)}
                  onSelect={val => set('assigneeId', val?.id)}
                  onClear={() => set('assigneeId', '')}
                />
              </MenuSection>
              <MenuSection title={capitalPartnerIdFilter?.name}>
                <Select
                  value={newParams.capitalPartnerId}
                  options={capitalPartnerIdFilter?.options!}
                  onChange={val => set('capitalPartnerId', val)}
                  addBlank
                />
              </MenuSection>
              <MenuSection title={twoChecksFilter?.name}>
                <Select
                  value={newParams.twoChecks}
                  options={twoChecksFilter?.options!}
                  onChange={val => set('twoChecks', val)}
                  addBlank
                />
              </MenuSection>
              <MenuSection title={stateFilter?.name}>
                <Select
                  addBlank
                  value={newParams.stateName}
                  options={stateFilter?.options!}
                  onChange={val => set('stateName', val)}
                />
              </MenuSection>
            </Column>
          </Columns>
          <ListFilters.Footer>
            <ListFilters.ApplyButton
              newParams={newParams}
              onApplyToggle={setIsOpenPopover}
              onClick={() =>
                tracking({ name: 'filters', params: { action: 'apply', target: 'lead' } })
              }
            />
          </ListFilters.Footer>
        </Section>
      </ListFilters.Content>
    </ListFilters.Root>
  )
}
