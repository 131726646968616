import {
  TSearchFilterSetting,
  userCategoryOptions,
  useRoles,
  userStatusOptions,
  useTransactionTeams
} from 'lookups'
import { byValue } from 'utils'
import { buildSearchFilters, findFilterBySlug } from '../utils'

const settings: TSearchFilterSetting[] = [
  {
    slug: 'role',
    name: 'Role',
    optionsKey: 'role',
    getDisplayValueFnName: 'getRoleName'
  },
  {
    slug: 'teamId',
    name: 'Transaction Team',
    optionsKey: 'teamId',
    getDisplayValueFnName: 'getTransactionTeamName'
  },
  {
    slug: 'userCategory',
    name: 'User Category',
    optionsKey: 'userCategory',
    getDisplayValueFnName: 'getUserCategoryName'
  },
  {
    slug: 'userStatus',
    name: 'User Status',
    optionsKey: 'userStatus',
    getDisplayValueFnName: 'getUserStatusName'
  }
]

export const useUsersSearchFilter = () => {
  const { roleOptions, findRoleBySlug } = useRoles()
  const { transactionTeamsOptions } = useTransactionTeams()

  const optionLoaders = {
    role: roleOptions,
    teamId: transactionTeamsOptions,
    userCategory: userCategoryOptions,
    userStatus: userStatusOptions
  }

  const displayValueFnLoaders = {
    getRoleName: (slug: string) => findRoleBySlug(slug)?.name,
    getTransactionTeamName: (slug: string) => transactionTeamsOptions.find(byValue(slug))?.name,
    getUserCategoryName: (slug: string) => userCategoryOptions.find(byValue(slug))?.name,
    getUserStatusName: (slug: string) => userStatusOptions.find(byValue(slug))?.name
  }

  const usersFilters = buildSearchFilters(settings, {
    optionLoaders,
    displayValueFnLoaders
  })

  const findUsersFilterBySlug = findFilterBySlug(usersFilters)

  return { usersFilters, findFilterBySlug: findUsersFilterBySlug }
}
