import { useState } from 'react'
import { useDeepCompareEffect } from 'hooks'

type UseFiltersDialogProps<TValues extends object = object> = {
  onApply: (values: TValues) => void
  resetValues?: TValues
  values: TValues
}

const handleFiltersCounter = (obj: object) => {
  return Object.entries(obj).reduce((acc, [_, value]) => (value ? (acc = acc + 1) : acc), 0)
}

export const useFilterDialog = <TValues extends object = object>({
  values: valuesProp,
  resetValues = {} as TValues,
  onApply
}: UseFiltersDialogProps<TValues>) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [values, setValues] = useState<TValues>(valuesProp)
  const [filtersCounter, setFiltersCounter] = useState(0)

  useDeepCompareEffect(() => {
    setValues(state => ({ ...state, ...valuesProp }))
  }, [valuesProp])

  const counter = handleFiltersCounter(valuesProp)
  if (filtersCounter !== counter) {
    setFiltersCounter(counter)
  }

  const setValue = (fieldName: keyof TValues, value: TValues[typeof fieldName]) => {
    setValues(state => ({ ...state, [fieldName]: value }))
  }

  const applyFilters = () => {
    onApply(values)
    setIsDialogOpen(false)
    setFiltersCounter(handleFiltersCounter(values))
  }

  const resetFilters = () => {
    setValues(resetValues)
  }

  const rootProps = {
    isOpen: isDialogOpen,
    onOpenChange: (isOpenValue: boolean) => {
      if (!isOpenValue) {
        setValues(valuesProp)
      }
      setIsDialogOpen(isOpenValue)
    }
  }

  const triggerButtonProps = {
    counter: filtersCounter
  }

  const applyButtonProps = {
    onClick: applyFilters
  }

  const resetButtonProps = {
    onClick: resetFilters
  }

  return { values, setValue, rootProps, triggerButtonProps, applyButtonProps, resetButtonProps }
}
