import { BBYSLead } from 'models'
import { BBYSLeadAttributes } from 'schema'
import { stringifyValue } from 'utils'
import { CCBBYSLeadDetailsProps } from '../..'

export const getBBYSLeadInputProps = (
  bbysLead?: BBYSLead,
  updateBbysLead?: CCBBYSLeadDetailsProps['updateBBYSLead']
) => {
  const handleUpdateBBYSLead = (key: keyof BBYSLeadAttributes, val: string) => {
    if (!bbysLead || !updateBbysLead) {
      return
    }
    updateBbysLead.mutate({
      id: bbysLead?.id,
      payload: {
        [key]: val
      }
    })
  }

  const getProps = (key: keyof BBYSLeadAttributes) => {
    const value = bbysLead ? stringifyValue(bbysLead[key]) : ''

    return {
      value,
      onBlur: val => {
        handleUpdateBBYSLead(key, val)
      }
    }
  }

  const genericUpdateBbysLead = (payload: Record<any, any>) => {
    if (!bbysLead || !updateBbysLead) {
      return
    }
    updateBbysLead.mutate({
      id: bbysLead?.id,
      payload
    })
  }

  return {
    getProps,
    handleUpdateBBYSLead,
    genericUpdateBbysLead
  }
}
