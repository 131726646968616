import { ButtonForm, FormField, Select, useButtonForm } from 'components'
import { useAgentTeamContext } from 'contexts'
import { validatePresence } from 'utils'

type Props = {
  agent: any
}

export const ChangeAgentTeamPreferredEscrowOfficer = ({ agent }: Props) => {
  const { changeAgentTeamPreferredEscrowOfficer } = useAgentTeamContext()

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm({
    mutation: changeAgentTeamPreferredEscrowOfficer,
    initialPayload: {
      agentId: agent.id,
      preferredEscrowOfficerUserId: agent.preferredEscrowOfficer?.id
    },
    formValidations: {
      agentId: [validatePresence],
      preferredEscrowOfficerUserId: [validatePresence]
    }
  })

  const escrowOfficersTeamMembers = agent.settlementAgencyTeam?.teamMemberships
    ? agent.settlementAgencyTeam?.teamMemberships
        ?.filter(member => member.role === 'escrow_officer')
        .map(member => {
          return { name: member.user.name, id: member.user.id }
        })
    : []

  return (
    <ButtonForm title="Change Preferred Escrow Officer" {...formProps}>
      <FormField
        title="Preferred Escrow Officer"
        htmlFor="preferred-escrow-officer-user-id"
        required
      >
        <Select
          id="preferred-escrow-officer-user-id"
          onChange={preferredEscrowOfficerUserId =>
            onChangeAttribute('preferredEscrowOfficerUserId', preferredEscrowOfficerUserId)
          }
          name="preferredEscrowOfficerUserId"
          options={escrowOfficersTeamMembers.map(({ name, id }) => ({
            name,
            value: id
          }))}
          value={payload.preferredEscrowOfficerUserId}
          data-testid="preferred-escrow-officer-user-id"
          addBlank
        />
      </FormField>
    </ButtonForm>
  )
}
