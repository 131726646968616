// @ts-strict

import { Button, ButtonTypes } from 'components'
import { MortgageLead, SellerLead } from 'models'
import { cx } from 'utils'
import styles from '../AgentMatchingLead.module.scss'
import EligibilityBadge from './EligibilityBadge'

const EligibilityHeader = ({
  buttonText,
  buttonType,
  disabled,
  isLoading,
  lead,
  onClick = () => null,
  hideButton = false
}: {
  buttonText?: string
  buttonType?: ButtonTypes
  disabled?: boolean
  hideButton?: boolean
  isLoading?: boolean
  lead?: MortgageLead | SellerLead
  onClick?: () => void
}) => {
  return (
    <div className={cx(styles.w100, styles.spaceBetween)}>
      <EligibilityBadge lead={lead} emptyState={<div style={{ flex: '1' }}></div>} />
      {!hideButton && (
        <Button
          as={disabled ? 'cancel' : buttonType}
          submitting={isLoading}
          onClick={onClick}
          size="xsmall"
          disabled={disabled}
        >
          {buttonText}
        </Button>
      )}
    </div>
  )
}

export default EligibilityHeader
