// @ts-strict
import { Contents } from 'components'
import { CCHLSimpleSaleLead } from 'models'
import {
  DRAgentInformationSection,
  DRPropertyAssessmentSection,
  DRPropertyInformationSection,
  DRPropertyQualificationSection,
  DRPropertyValuationSection,
  DRPurchaseTransaction,
  DRSaleTransactionSection,
  HLSSLeadDetailsSection
} from './HLSS'

export type HLSSLeadDetailsProps = {
  lead: CCHLSimpleSaleLead
  updateLead: (payload: Record<string, string>) => void
  updateOrder: (payload: Record<string, string>) => void
}

export const HLSSLeadDetails = ({ lead, updateLead, updateOrder }: HLSSLeadDetailsProps) => {
  return (
    <Contents>
      <HLSSLeadDetailsSection lead={lead} updateLead={updateLead} updateOrder={updateOrder} />
      <DRAgentInformationSection lead={lead} />
      <DRPropertyInformationSection lead={lead} />
      <DRPropertyQualificationSection lead={lead} />
      <DRPropertyValuationSection lead={lead} />
      <DRPropertyAssessmentSection lead={lead} />
      <DRPurchaseTransaction lead={lead} updateLead={updateLead} />
      <DRSaleTransactionSection lead={lead} />
    </Contents>
  )
}
