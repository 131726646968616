// @ts-strict
import { Option } from 'classes'
import { FormField, Select, Table, TableBody } from 'components'
import { useEmployeeContext } from 'contexts'
import { useNewFilters } from 'hooks'
import { useRoles } from 'lookups'
import { Employee } from 'models'
import styles from './EmployeeRolesPopupContent.module.scss'
import { RoleTableRow } from '.'

type Props = {
  employee: Employee
}

type SelectParams = {
  role: string | null
}

export const EmployeeRolesPopupContent = ({ employee }: Props) => {
  const {
    createEmployeeRole: { mutateAsync: createRoleMutation, error: createRoleError }
  } = useEmployeeContext()
  const { roleOptions } = useRoles()
  const { newParams, set } = useNewFilters<SelectParams>()
  const employeeRoleNames = employee.roles?.map(({ name }) => name) || []
  const filteredRoleOptions = roleOptions?.filter(
    (option: Option) => !employeeRoleNames.includes(option.value as string)
  )

  const handleCreateRole = async (role: string) => {
    set('role', role)
    await createRoleMutation(role)
    set('role', null)
  }

  return (
    <>
      <label htmlFor="add-user-role" className={styles.roleSelectLabel}>
        Add Role
      </label>
      <FormField error={(createRoleError as Error)?.message}>
        <Select
          id="add-user-role"
          options={filteredRoleOptions}
          value={newParams.role as string}
          onChange={handleCreateRole}
          placeholder="Select a Role"
          addBlank
        />
      </FormField>

      <Table>
        <TableBody>
          {employee.roles?.map(role => (
            <RoleTableRow employee={employee} role={role} key={role.id} />
          ))}
        </TableBody>
      </Table>
    </>
  )
}
