import { removeAttributesWithNullValues } from 'utils'
import { CommsFiltersDateRange } from '../../ui/CommsFiltersContext/filterSettings'

type UseDateRangeFilter = { dateRangeValues?: CommsFiltersDateRange }

export const useDateRangeFilter = ({ dateRangeValues }: UseDateRangeFilter) => {
  // Date range contains 2 inputs and must be treated as 1 one filter.
  const getDateRangeFilterSetValue = (
    key: keyof CommsFiltersDateRange,
    value: CommsFiltersDateRange[typeof key]
  ) => {
    const newValues = removeAttributesWithNullValues({
      ...dateRangeValues,
      [key]: value
    })

    if (!Object.keys(newValues).length) {
      return
    }

    return newValues
  }

  return { getDateRangeFilterSetValue }
}
