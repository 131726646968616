import { environment } from 'environment'
import { CurrentUser } from 'models/CurrentUser'
import { formatPhone } from 'utils'

interface DialerConnectionData {
  caller_id_number: string
  resource_id: string
  resource_preferred_line: string
  resource_type: string
}

type DialerProps = {
  currentUser: CurrentUser
  id: string
  name: string
  phoneNumber: string
  phoneType: PhoneType
  type: string
}

export class Dialer {
  id: string
  currentUser: CurrentUser
  type: string
  name: string
  phoneNumber: string
  phoneType: PhoneType
  connectionData: DialerConnectionData
  showDebug: boolean
  constructor(dialer: DialerProps) {
    Object.assign(this, dialer)
    this.connectionData = {
      resource_type: this.type,
      resource_id: this.id,
      resource_preferred_line: this.phoneType,
      caller_id_number: this.currentUser.callerId
        ? this.currentUser.callerId
        : this.getDefaultCallerId()
    }
    this.showDebug = this.currentUser.roles.salesAppDev
    this.phoneNumber = formatPhone(this.phoneNumber)
  }

  getDefaultCallerId = () => (environment.env === 'staging' ? '+15128723096' : '+14157992552')
}
