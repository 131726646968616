// @ts-strict

const commonFieldsLookup = {
  leadUserType: {
    isRequired: true
  },
  referringAgent: {
    isRequired: false
  }
}

export const fieldsLookup = {
  seller: {
    ...commonFieldsLookup,
    asmAse: {
      isRequired: false
    },
    price: {
      isRequired: false
    },
    propertyAddress: {
      isRequired: true
    },
    propertyType: {
      isRequired: false
    },
    timeline: {
      isRequired: false
    }
  },
  buyer: {
    ...commonFieldsLookup,
    asmAse: {
      isRequired: false
    },
    cityState: {
      isRequired: false
    },
    price: {
      isRequired: false
    },
    propertyType: {
      isRequired: false
    },
    timeline: {
      isRequired: false
    }
  },
  ccTradeIn: {
    ...commonFieldsLookup,
    propertyAddress: {
      isRequired: true
    },
    sellerAgent: {
      isRequired: false
    },
    hlSimpleSale: {
      isRequired: false
    }
  },
  ccCashOffer: {
    ...commonFieldsLookup,
    propertyAddress: {
      isRequired: false
    },
    buyerAgent: {
      isRequired: false
    }
  },
  mortgage: {
    ...commonFieldsLookup
  },
  ccHlSimpleSale: {
    ...commonFieldsLookup,
    propertyAddress: {
      isRequired: false
    },
    sellerAgent: {
      isRequired: false
    }
  }
}
