import {
  isActiveInvestorLead,
  sortByActiveInvestorLeadStageAttr,
  sortByInvestorLeadRank,
  sortByInvestorLeadStageAttr
} from 'lookups'
import { InvestorLead } from 'models'

export const getActiveInvestorLeads = (investorLeads?: InvestorLead[]) => {
  const activeInvestorLeads =
    investorLeads
      ?.filter(isActiveInvestorLead)
      .sort(sortByActiveInvestorLeadStageAttr)
      .sort(sortByInvestorLeadRank) ?? []

  return activeInvestorLeads
}

export const getSortedInvestorLeads = (investorLeads?: InvestorLead[]) => {
  const activeInvestorLeads =
    investorLeads
      ?.filter(isActiveInvestorLead)
      .sort(sortByActiveInvestorLeadStageAttr)
      .sort(sortByInvestorLeadRank) ?? []

  const inactiveInvestorLeads =
    investorLeads
      ?.filter(investorLead => !isActiveInvestorLead(investorLead))
      .sort(sortByInvestorLeadStageAttr)
      .sort(sortByInvestorLeadRank) ?? []

  return [...activeInvestorLeads, ...inactiveInvestorLeads]
}
