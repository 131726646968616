import { getLeads4Url } from 'services/getUrl'
import { camelcaseKeys, getRequest } from 'utils'
import { getSearchParams, TGetLeadsCountSettings } from './getSearchParams'

export const fetchLeadsCount = async (settings: TGetLeadsCountSettings) => {
  const response = await getRequest(
    getLeads4Url({ searchParams: getSearchParams(settings), action: 'counts' })
  )
  return camelcaseKeys(response.data.data)
}
