// @ts-strict
import { ServicesOpportunityStatusResponse } from 'schema'

export class ServicesOpportunityStatus {
  id!: string
  name?: string
  slug?: string

  constructor(servicesOpportunityStatusAttributes: ServicesOpportunityStatus) {
    Object.assign(this, servicesOpportunityStatusAttributes)
  }

  static create(
    servicesOpportunityStatusResponse: Potential<ServicesOpportunityStatusResponse>
  ): Potential<ServicesOpportunityStatus> {
    if (!servicesOpportunityStatusResponse) {
      return
    }
    return new ServicesOpportunityStatus(servicesOpportunityStatusResponse)
  }
}
