import { ExternalLink } from 'models'
import { ExternalLinksSchema, getHapiRequestParams } from 'schema'
import { getExternalLinksUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { ExternalLinksParams, getSearchParams } from './getSearchParams'

export const fetchExternaLinks = async (searchParams: ExternalLinksParams) => {
  const extenalLinksShape: ExternalLinksSchema = {
    externalLinks: [
      'name',
      'url',
      'createdAt',
      { 'subAttachable:cashOfferLead': ['incomingMasterProperty'] },
      { 'createdBy:user': ['name'] }
    ]
  }

  const url = getExternalLinksUrl({
    searchParams: {
      ...getHapiRequestParams(extenalLinksShape),
      ...getSearchParams(searchParams)
    }
  })

  const response = await getRequest(url)

  return {
    externalLinks: await deserializeAsync<ExternalLink[]>(response.data, {
      transform: externalLink => ExternalLink.create(externalLink)
    }),
    externalLinksCount: response.data.meta.total_external_links as number
  }
}
