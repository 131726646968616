// @ts-strict
import { Option } from 'classes'
import { TUsStateCode } from 'lookups'
import { IOptionable } from 'lookups'
import { MarketplaceProgram } from 'models'
import { StateResponse } from 'schema'
import { Model } from '../Model'

export class UsState extends Model implements IOptionable {
  id!: string
  code!: TUsStateCode
  name?: string
  latitude?: number
  longitude?: number
  timezone?: string
  marketplacePrograms?: Potential<MarketplaceProgram[]>

  constructor(attributes: Omit<UsState, 'toOption' | keyof Model>) {
    super()
    Object.assign(this, attributes)
  }

  static create(stateResponse: Potential<StateResponse>): Potential<UsState> {
    if (!stateResponse) {
      return
    }

    const marketplacePrograms = (stateResponse.marketplacePrograms
      ?.map(MarketplaceProgram.create)
      .filter(Boolean) || []) as MarketplaceProgram[]

    return new UsState({ ...stateResponse, marketplacePrograms })
  }

  toOption() {
    return new Option({ name: this.name, value: this.id })
  }
}
