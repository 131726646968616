/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */

import { Popover } from '@foundation/components'
import { Table, TColumn, useTable } from '@foundation/components/Table'

import { AgentInfo, BasicInfo } from 'content/Lead'
import { Disposition } from 'content/Lead/LeadDetails/CCBBYS/Disposition'

import { useCreateOrderController, useDocumentTitle } from 'hooks'
import { capitalizeWords, formatDate, formatMoney } from 'utils'
import { CreateOrderButtonForm } from 'content/Order'

import { FiltersControl } from '../../components/filters-control'
import { StageTag } from '../../components/stage-tag'
import { PAGE_SIZE } from '../../constants'
import { formatNumber, getBBYSStage } from '../../helpers'
import { BBYS_DATA_QUERY, useBBYSData } from '../../hooks'
import { BBYSLead } from '../../types'
import { getDaysSinceFunding } from '../../helpers/get-days-since-funding'

import { Container, EquityUnlockRange, Hoverable } from './styles'

export const Leads = () => {
  const table = useTable()
  const { search, sortBy, page, filters, onNavigate } = table

  const { data, isLoading } = useBBYSData({ search, sortBy, page, filters })
  useDocumentTitle('Buy Before You Sell Leads')

  const { createOrder, handleOrderCreated } = useCreateOrderController({
    clearCacheKey: BBYS_DATA_QUERY
  })

  const columns: TColumn<BBYSLead>[] = [
    {
      label: 'Client',
      frozen: true,
      minSize: '200px',
      render: row => (
        <Popover.Root isHoverEnabled>
          <Popover.Trigger>
            <a href={`/buy-before-you-sell/leads/${row.lead.id}`}>{row.lead.name}</a>
          </Popover.Trigger>
          <Popover.Content>
            <BasicInfo
              title="Primary Client"
              name={row.lead?.name}
              email={row.lead?.email}
              phone={row.lead?.phoneNumbers?.[0]?.number}
            />
          </Popover.Content>
        </Popover.Root>
      )
    },
    {
      label: 'DR Address',
      minSize: '200px',
      frozen: true,
      render: row => capitalizeWords(row.departingPropertyFullAddress || '')
    },
    { label: 'DR State', sortKey: 'state_name.keyword', render: row => row.lead?.stateName },
    {
      label: 'Current stage',
      sortKey: 'stage.keyword',
      render: row => {
        const stage = row.lead?.stage
        const stageLabel = getBBYSStage(stage)

        return (
          <Disposition
            leadId={row.lead.id}
            leadStage={stage}
            customTrigger={
              <div>
                <StageTag stage={stageLabel} />
              </div>
            }
          />
        )
      }
    },
    {
      label: 'Furthest Stage',
      sortKey: 'furthest_stage',
      render: row => getBBYSStage(row.lead?.furthestStage)
    },
    {
      label: 'Partner Name',
      sortKey: 'bbys.partner_slug.keyword',
      render: row => row.sourcePartner?.slug
    },
    {
      label: 'Loan Officer',
      render: row => (
        <Popover.Root isHoverEnabled>
          <Popover.Trigger>
            <Hoverable>{row.lead?.loanOfficer?.name}</Hoverable>
          </Popover.Trigger>
          <Popover.Content>
            <BasicInfo
              title="Loan Officer"
              name={row.lead?.loanOfficer?.name}
              email={row.lead?.loanOfficer?.email}
              phone={row.lead?.loanOfficer?.phoneNumbers[0]?.number}
            />
          </Popover.Content>
        </Popover.Root>
      )
    },
    {
      label: 'DR Agent',
      render: row => {
        const agentLink = `/agents/${row.departingPropertySellerAgent?.id}`

        return (
          <Popover.Root isHoverEnabled>
            <Popover.Trigger>
              <Hoverable>
                {row.departingPropertySellerAgent?.firstName}{' '}
                {row.departingPropertySellerAgent?.lastName}
              </Hoverable>
            </Popover.Trigger>
            <Popover.Content>
              <AgentInfo
                title="DR Agent"
                name={row.departingPropertySellerAgent?.firstName}
                agentTeam={row.departingPropertySellerAgent?.agentTeam}
                email={row.departingPropertySellerAgent?.email1}
                phone={row.departingPropertySellerAgent?.phoneNumbers?.[0]?.number}
                license={row.departingPropertySellerAgent?.licenseNumber}
                brokerage={row.departingPropertySellerAgent?.brokerageBrandId}
                noteLink={row.departingPropertySellerAgent?.notes}
                agentLink={agentLink}
              />
            </Popover.Content>
          </Popover.Root>
        )
      }
    },
    {
      label: 'Equity Unlock Range',
      render: row => (
        <EquityUnlockRange>
          {formatMoney(row.equityUnlockLowEndRangeIntake)} -{' '}
          {formatMoney(row.equityUnlockHighEndRangeIntake)}
        </EquityUnlockRange>
      )
    },
    {
      label: 'Expected Equity Unlock loan funded',
      render: row => formatDate(row.expectedLoanFundingDate),
      sortKey: 'bbys.expected_loan_funding_date'
    },
    {
      label: 'Days since EU funding',
      render: row => getDaysSinceFunding(row.loanFundingDate) || 'null',
      sortKey: 'bbys.loan_funding_date'
    },
    { label: 'Created Date', sortKey: 'created_at', render: row => formatDate(row.createdAt) },
    {
      label: 'DR Listed Date',
      sortKey: 'bbys.departing_residence_listed_date',
      render: row => formatDate(row.departingResidenceListedDate)
    },
    {
      label: 'DR in Escrow Date',
      sortKey: 'bbys.departing_residence_in_escrow_date',
      render: row => formatDate(row.departingResidenceInEscrowDate)
    },
    {
      label: 'DR Expected Close of Escrow',
      sortKey: 'bbys.departing_residence_expected_close_escrow_date',
      render: row => formatDate(row.departingResidenceExpectedCloseEscrowDate)
    },
    {
      label: 'Approval Type at Intake',
      sortKey: 'bbys.approval_type_intake.keyword',
      render: row => capitalizeWords(row.approvalTypeIntake || '')
    },
    {
      label: 'IR Under Contract at Intake',
      sortKey: 'bbys.incoming_residence_under_contract_at_intake',
      render: row => (row.incomingResidenceUnderContractAtIntake ? 'Yes' : 'No')
    },
    {
      label: 'HL Purchased Departing Residence',
      sortKey: 'bbys.hl_purchased_departing_residence_date',
      render: row => formatDate(row.hlPurchasedDepartingResidenceDate)
    },
    {
      label: 'HL Purchase Listed',
      sortKey: 'bbys.hl_purchase_listed_date',
      render: row => formatDate(row.hlPurchaseListedDate)
    }
  ]

  const totalPages = data?.meta?.total_pages || 1
  const totalLeads = data?.meta?.total_leads || 0

  return (
    <Container>
      <Table.Root {...table}>
        <Table.View>
          <Table.Heading title="Buy Before You Sell" subtitle={`${formatNumber(totalLeads)} leads`}>
            <CreateOrderButtonForm
              mutation={createOrder}
              onSubmit={handleOrderCreated}
              isPrimary
              defaultPayload={{ leadType: 'bbys' }}
            />
          </Table.Heading>

          <Table.Actions
            searchPlaceholder="Search by client, agent, loan officer or property address"
            renderFilters={() => <FiltersControl />}
          />

          <Table.Data rows={data?.data || []} columns={columns} isLoading={isLoading} />
          <Table.Details
            itemsPerPage={PAGE_SIZE}
            currentPage={+page}
            totalPages={totalPages}
            totalLeads={totalLeads}
            isLoading={isLoading}
          />
        </Table.View>

        <Table.Pagination
          currentPage={+page}
          totalPages={totalPages}
          onPageChange={nextPage => onNavigate(`${nextPage}`)}
        />
      </Table.Root>
    </Container>
  )
}
