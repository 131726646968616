// @ts-strict

import { useFlexUi } from 'hooks'
import { TLeadUserTypeServerSlug } from 'lookups'
import {
  BuyerLead,
  CCCashOfferLead,
  CCHLSimpleSaleLead,
  CCTradeInLead,
  Lead,
  MortgageLead,
  SellerLead
} from 'models'
import { BuyerLeadTabActions } from './BuyerLeadTabActions'
import { CashOfferLeadTabActions } from './CashOfferLeadTabActions'
import { HLSimpleSaleLeadTabActions } from './HLSimpleSaleLeadTabActions'
import { MortgageLeadTabActions } from './MortgageLeadTabActions'
import { SellerLeadTabActions } from './SellerLeadTabActions'
import { SendLeadToDialer } from './SendLeadToDialer'
import { TradeInLeadTabActions } from './TradeInLeadTabActions'
import * as S from './styles'

type LeadTabActionsProps = {
  lead: Lead
}

type UserTypeSlug = Extract<
  TLeadUserTypeServerSlug,
  'buyer' | 'seller' | 'cc_trade_in' | 'cc_cash_offer' | 'mortgage' | 'cc_hl_simple_sale'
>

type ActionByLead = {
  [Property in UserTypeSlug]: React.FunctionComponent
}

export const LeadTabActions = ({ lead }: LeadTabActionsProps) => {
  const { isFlexUi } = useFlexUi()

  const hasLeadType =
    lead.userType &&
    ['buyer', 'seller', 'cc_trade_in', 'cc_cash_offer', 'mortgage', 'cc_hl_simple_sale'].includes(
      lead.userType
    )

  const actionByLead: ActionByLead = {
    buyer: () => <BuyerLeadTabActions lead={lead as BuyerLead} />,
    seller: () => <SellerLeadTabActions lead={lead as SellerLead} />,
    cc_trade_in: () => <TradeInLeadTabActions lead={lead as CCTradeInLead} />,
    cc_cash_offer: () => <CashOfferLeadTabActions lead={lead as CCCashOfferLead} />,
    mortgage: () => <MortgageLeadTabActions lead={lead as MortgageLead} />,
    cc_hl_simple_sale: () => <HLSimpleSaleLeadTabActions lead={lead as CCHLSimpleSaleLead} />
  }

  if (!lead.userType) {
    return null
  }

  return hasLeadType ? (
    <S.TabActions>
      {actionByLead[lead?.userType]()}
      {isFlexUi && <SendLeadToDialer lead={lead} />}
    </S.TabActions>
  ) : null
}
