import { endOfDay, formatISO } from 'date-fns'
import { SearchParams } from '@types'
import { getFilter, getSort } from 'services/utils'

const sortParams = {
  occurredAt: 'occurred_at'
}

export type ActivitiesFeedParams = SearchParams<{ sortBy: keyof typeof sortParams }> & {
  agentId?: string
  agentIdContactInfo?: string
  greaterEqualOccurredAt?: string
  leadId?: string
  lessEqualOccurredAt?: string
  name?: string[]
  orderId?: string
  orderIdContactInfo?: string
  userIdContactInfo?: string
}

const filterSlugs = {
  page: 'page[number]',
  perPage: 'page[size]',
  sort: 'sort',
  leadId: 'filter[lead.id]',
  agentId: 'filter[agent.id]',
  lessEqualOccurredAt: 'filter[<=occurred_at]',
  greaterEqualOccurredAt: 'filter[>=occurred_at]',
  name: 'filter[name]',
  orderId: 'filter[order_contact_infos]',
  agentIdContactInfo: 'filter[agent_contact_infos]',
  userIdContactInfo: 'filter[user_contact_infos]',
  orderIdContactInfo: 'filter[order_contact_infos]'
}

export const getSearchParams = ({
  sortBy = 'occurredAt',
  sortDir = 'desc',
  lessEqualOccurredAt,
  greaterEqualOccurredAt,
  ...restParams
}: ActivitiesFeedParams) => {
  const formatLessEqualOccurredAt = () => {
    const shouldApplyNowDate = !lessEqualOccurredAt && !greaterEqualOccurredAt

    if (shouldApplyNowDate) {
      return formatISO(new Date())
    }

    if (lessEqualOccurredAt) {
      return formatISO(endOfDay(new Date(lessEqualOccurredAt)))
    }
  }

  const filtersResult = getFilter(filterSlugs, {
    lessEqualOccurredAt: formatLessEqualOccurredAt(),
    greaterEqualOccurredAt: greaterEqualOccurredAt && formatISO(new Date(greaterEqualOccurredAt)),
    ...restParams
  })

  return {
    ...filtersResult,
    sort: getSort(sortParams, sortDir, sortBy)
  }
}
