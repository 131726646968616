// @ts-strict
import { ChangeEvent, FocusEvent, ReactNode, SelectHTMLAttributes, useMemo, useState } from 'react'
import { Option, OptionGroup } from 'classes'
import { Icon, Spinner } from 'components'
import { cx } from 'utils'
import styles from '../Form.module.scss'

const optionsArray = (options: readonly Option[] | OptionGroup[]) => {
  if (!Array.isArray(options)) {
    console.warn('Converting select options to array', options)
  }
  return Array.isArray(options)
    ? options
    : Object.keys(options).map(key => {
        return new Option({ name: options[key], value: key })
      })
}

export type SelectProps = {
  addBlank?: boolean
  children?: ReactNode
  className?: string
  isLoading?: boolean
  onBlur?: (e: FocusEvent<HTMLSelectElement>) => void
  onChange?: (val: string) => void
  onFocus?: (e: FocusEvent<HTMLSelectElement>) => void
  options?: readonly Option[] | OptionGroup[]
  placeholder?: string
  value?: string | number
}

export const SelectWithBlank = (props: SelectProps) => Select({ ...props, addBlank: true })

export const Select = ({
  children,
  options = [],
  className,
  isLoading,
  addBlank = false,
  onFocus,
  onBlur,
  onChange,
  value,
  ...props
}: SelectProps & Omit<SelectHTMLAttributes<HTMLSelectElement>, 'options' | 'onChange'>) => {
  const currentValue = value || ''
  const [focused, setFocused] = useState(false)

  const handleOnFocus = (e: FocusEvent<HTMLSelectElement>) => {
    setFocused(true)

    if (onFocus) {
      onFocus(e)
    }
  }

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.currentTarget.value.length ? e.currentTarget.value : ''
    onChange?.(selectedValue)
  }

  const handleOnBlur = (e: FocusEvent<HTMLSelectElement>) => {
    setFocused(false)

    if (onBlur) {
      onBlur(e)
    }
  }

  const selectOptions = useMemo(() => optionsArray(options), [options])
  const isOptionGroups = (options: Option[] | OptionGroup[]): options is OptionGroup[] => {
    return options[0] instanceof OptionGroup
  }
  const placeholder = isLoading ? 'Loading...' : props.placeholder
  const icon = isLoading ? <Spinner /> : <Icon.DownChevron />

  return (
    <div className={cx(styles.selectWrapper, focused ? styles.focused : null)}>
      <select
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        className={cx(styles.select, className, !!currentValue && styles.filled)}
        value={currentValue}
        disabled={isLoading}
        {...props}
      >
        {isOptionGroups(selectOptions) ? (
          selectOptions.map(optionGroup => (
            <SelectOptionGroup
              label={optionGroup.label}
              options={optionGroup.options}
              key={optionGroup.label}
            />
          ))
        ) : (
          <SelectOptions options={selectOptions} addBlank={addBlank} placeholder={placeholder} />
        )}
        {children}
      </select>
      {icon}
    </div>
  )
}

type SelectOptionGroupProps = {
  children?: ReactNode
  className?: string
  label?: string
  options: Option[]
}

export const SelectOptionGroup = ({
  children,
  options,
  className,
  label,
  ...props
}: SelectOptionGroupProps) => {
  return (
    <optgroup className={cx(styles.select, className)} label={label} {...props}>
      <SelectOptions options={options} />
      {children}
    </optgroup>
  )
}

const getOptionValue = (option: Option): string => {
  const optionValue = option.value
  return optionValue !== undefined ? String(optionValue) : ''
}

export const SelectOption = ({ name, value, description, ...props }: Option) => {
  const optionValue = getOptionValue({ value })
  return (
    <option {...props} value={optionValue} key={optionValue}>
      {description ? `${description} (${name})` : name}
    </option>
  )
}

type SelectOptionsProp = {
  addBlank?: boolean
  options: Option[]
  placeholder?: string
}

const SelectOptions = ({ options, addBlank, placeholder }: SelectOptionsProp) => {
  return (
    <>
      {placeholder && (
        <SelectOption defaultValue={placeholder} name={placeholder} disabled hidden />
      )}
      {addBlank ? <SelectOption value="" /> : null}
      {options.map((option, index) => (
        <SelectOption key={index} {...option} />
      ))}
    </>
  )
}
