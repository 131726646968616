import { BlendUserInterface } from '../..'

interface Option {
  name: string
  value: string
}

export const mapToOption = (blendUser: BlendUserInterface): Option => {
  return {
    name: `${blendUser.name} (${blendUser.email})`,
    value: `${blendUser.id}`
  }
}
