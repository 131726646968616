// @ts-strict
import { ButtonForm, InlineEditButton } from 'components'
import { useEditEscrowOrderController } from 'controllers'
import { EscrowLead } from 'models'
import { AwaitingAgent, Global, PendingOrder, Standard } from './FormFields'
import { useEditEscrowOrderForm } from './useEditEscrowOrderForm'

type TProps = {
  className?: string
  lead: EscrowLead
}

export const EditEscrowOrderButtonForm = ({ lead, className }: TProps) => {
  const { editEscrowOrder } = useEditEscrowOrderController({
    escrowOfficeLeadId: lead?.escrowOfficeProviderLead?.escrowOfficeLead?.id
  })

  const { formProps, payload, onChangeAttribute } = useEditEscrowOrderForm({
    mutation: editEscrowOrder,
    onSubmit: () => {},
    lead
  })

  const formSectionProps = { payload, onChangeAttribute, lead }

  let content
  switch (lead.stage) {
    case 'pending_order':
      content = <PendingOrder {...formSectionProps} />
      break
    case 'awaiting_agent':
      content = <AwaitingAgent {...formSectionProps} />
      break
    default:
      content = <Standard {...formSectionProps} />
      break
  }

  if (!lead?.escrowOfficeProviderLead?.escrowOfficeLead?.id) {
    return null
  }

  return (
    <div className={className} onClick={e => e.stopPropagation()}>
      <InlineEditButton onClick={formProps.toggleShowForm} name="edit-escrow-order-button" />
      <ButtonForm
        {...formProps}
        isFormValid={formProps.isFormValid}
        title="Create Order"
        hideTriggerButton
      >
        {content}
        <Global {...formSectionProps} />
      </ButtonForm>
    </div>
  )
}
