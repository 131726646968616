import { TableHeaderCell as TH, TableHeaderRow as TR } from 'components'

export const TransactionsTableHeader = () => {
  return (
    <TR>
      <TH>Address</TH>
      <TH>Type</TH>
      <TH>Price</TH>
      <TH>Selling Agent</TH>
      <TH>Listing Agent</TH>
      <TH>Sales Date</TH>
    </TR>
  )
}
