import { useSearchParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { Icon, Page } from 'components'
import { SignInFormButton } from 'content/CurrentUser'

export const SignInPage = () => {
  const [searchParams] = useSearchParams()

  useEffectOnce(() => {
    if (searchParams.get('statusCode') === '401') {
      window.location.replace('/')
    }
  })

  return (
    <Page title="Sales App">
      <p>
        <Icon.Account style={{ verticalAlign: 'bottom' }} /> Welcome to the Sales App. Please{' '}
        <SignInFormButton />
      </p>
    </Page>
  )
}
