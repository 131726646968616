import { HOME_VALUE_CACHE_KEY } from 'controllers'
import { useQuery } from 'hooks'
import { Lead } from 'models'
import { fetchHomeValueEstimate } from 'services'

export const useHomeValueController = (lead: Lead) => {
  const query = useQuery([HOME_VALUE_CACHE_KEY, lead.id], () => fetchHomeValueEstimate(lead), {
    enabled: !!lead.id,
    refetchOnWindowFocus: false
  })
  const homeValueEstimate = query.data

  return {
    isLoading: query.isLoading,
    homeValueEstimate
  }
}
