// @ts-strict

import { useLocation } from 'react-use'
import { Contents, ListSearchHeader, Page, PaginationParams, ViewsMenu } from 'components'
import { AllLeadsSearchFilters } from 'content/AllLeads'
import { AllLeadsTable } from 'content/AllLeads/AllLeadsTable'
import { CreateOrderButtonForm } from 'content/Order'
import { ListProvider } from 'contexts'
import { ALL_LEADS_CACHE_KEY, useCreateOrderController } from 'controllers'
import { useAllLeadsController } from 'controllers/Leads/useAllLeadsController'
import { useDocumentTitle } from 'hooks'
import { allLeadsListColumns } from 'lookups'
import { useAllLeadsSearchFilter } from 'lookups/lookupSearchFilters/allLeadsSearchFilter/useAllLeadsSearchFilter'
import { queryStringToObject } from 'utils'

const PAGE_TITLE = 'All Leads'

const actions = (
  <>
    <AllLeadsSearchFilters />
    <ViewsMenu />
  </>
)

const useAllLeadsPage = () => {
  useDocumentTitle(PAGE_TITLE)
  const { search } = useLocation()
  const searchParams = queryStringToObject(search)

  const { allLeadsQuery } = useAllLeadsController(searchParams)

  return {
    allLeadsQuery
  }
}

export const AllLeadsPage = () => {
  const { allLeadsQuery } = useAllLeadsPage()
  const { data, isLoading } = allLeadsQuery
  const { items } = data || {}
  const allLeadsSearchFilters = useAllLeadsSearchFilter()
  const { createOrder, handleOrderCreated } = useCreateOrderController({
    clearCacheKey: ALL_LEADS_CACHE_KEY
  })

  const action = <CreateOrderButtonForm mutation={createOrder} onSubmit={handleOrderCreated} />

  return (
    <ListProvider columns={allLeadsListColumns} filters={allLeadsSearchFilters}>
      <Page>
        <Contents title={PAGE_TITLE} actions={action}>
          <ListSearchHeader
            counterText="Lead"
            totalCount={allLeadsQuery.data?.totalCount}
            isLoading={allLeadsQuery.isLoading}
            filter={actions}
          />
          <AllLeadsTable items={items} isLoading={isLoading} />
          <PaginationParams totalPages={allLeadsQuery.data?.pageCount} />
        </Contents>
      </Page>
    </ListProvider>
  )
}
