import { Table, TableResults } from 'components'
import { UserInterface } from 'models/v2/User'
import { ListUsersTableHeader, ListUsersTableRow } from '.'

type UsersTableProps = {
  isLoading?: boolean
  users?: UserInterface[]
}

export const ListUsersTable = ({ users, isLoading }: UsersTableProps) => {
  return (
    <Table>
      <ListUsersTableHeader />
      <TableResults type="users" colSpan={6} isLoading={isLoading}>
        {users?.map(user => (
          <ListUsersTableRow key={user.id} user={user} />
        ))}
      </TableResults>
    </Table>
  )
}
