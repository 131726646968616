// @ts-strict
import { getPercentageValueFormat, stringifyValue } from 'utils'

const getFieldValues = <T extends object>(model: T) => {
  const getFieldValue = (key: keyof T) => {
    return stringifyValue(model[key])
  }

  const getFieldPercentageValue = (key: keyof T) => {
    const fieldValue = model[key]
    return fieldValue
      ? String(getPercentageValueFormat(String(fieldValue)))
      : stringifyValue(fieldValue)
  }

  return {
    getFieldValue,
    getFieldPercentageValue
  }
}

export default getFieldValues
