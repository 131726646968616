// @ts-strict
import { useNavigate } from 'react-router-dom'
import { AGENTS_CACHE_KEY } from 'controllers'
import { useConstantsController } from 'controllers/Constants'
import { useFeatureFlags, useMutation, useQuery, useQueryClient } from 'hooks'
import { fetchAgents, fetchAgentsLegacy, postAgent, PostAgentResponse } from 'services'
import { PostAgentReassignPreferredEscrowOfficer } from 'services/Agent/postReassignPreferredEscrowOfficer'
import { getPageCount } from 'utils'

const defaultSearchSettings = { perPage: 20 }

type Props = {
  searchParams: Record<string, any>
}
export const useAgentsController = ({ searchParams }: Props, enabled = true) => {
  const queryCache = useQueryClient()
  const navigate = useNavigate()
  const { metroplexes, usStates } = useConstantsController()
  const { salesAppAgentMetrics } = useFeatureFlags(['sales-app-agent-metrics'])

  const searchSettings: Record<string, any> = {
    ...defaultSearchSettings,
    ...searchParams
  }

  const agentCreationCallback = {
    onSuccess: (response: PostAgentResponse) => {
      const createdAgentId = response?.data?.data?.id
      if (createdAgentId) {
        navigate(`/agents/${createdAgentId}`)
      }

      return queryCache.invalidateQueries(AGENTS_CACHE_KEY)
    }
  }

  const createAgent = useMutation(postAgent, agentCreationCallback)

  const { data, isLoading } = useQuery(
    [AGENTS_CACHE_KEY, { searchSettings }, salesAppAgentMetrics.enabled],
    () => {
      return salesAppAgentMetrics.enabled
        ? fetchAgents({ ...searchSettings }, salesAppAgentMetrics.enabled)
        : fetchAgentsLegacy({ ...searchSettings })
    },
    {
      enabled: enabled && !!metroplexes.length && !!usStates.length,
      refetchOnWindowFocus: false
    }
  )

  const defaultCallback = {
    onSuccess: () => queryCache.invalidateQueries(AGENTS_CACHE_KEY)
  }

  const reassignAgentsPreferredEscrowOfficer = useMutation(
    ({
      oldPreferredEscrowOfficerUserId,
      preferredEscrowOfficerUserId
    }: {
      oldPreferredEscrowOfficerUserId: string
      preferredEscrowOfficerUserId: string
    }) =>
      PostAgentReassignPreferredEscrowOfficer({
        oldPreferredEscrowOfficerUserId: oldPreferredEscrowOfficerUserId,
        preferredEscrowOfficerUserId: preferredEscrowOfficerUserId
      }),
    defaultCallback
  )

  const agents = data?.agents ? data.agents : []
  const totalCount = data?.totalCount
  const pageCount = getPageCount(totalCount, searchSettings.perPage)

  return {
    agents,
    pageCount,
    totalCount,
    isLoading,
    createAgent,
    reassignAgentsPreferredEscrowOfficer
  }
}
