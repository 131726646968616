import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './FieldTitle.module.scss'

type Props = {
  children: ReactNode
  className?: string
}

export const FieldTitle = ({ className, children }: Props) => {
  return (
    <h4 data-testid="fieldTitle" className={cx(styles.title, className, 'fieldTitle')}>
      {children}
    </h4>
  )
}
