// @ts-strict
import createNumberMask, { CreateNumberMask } from 'text-mask-addons/dist/createNumberMask'

export const dollarsMask = (options: CreateNumberMask = {}) => createNumberMask(options)

export const dollarsAndCentsMask = (options: CreateNumberMask = {}) =>
  createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 10,
    allowNegative: false,
    allowLeadingZeroes: false,
    ...options
  })

export const moneyMask = (options: CreateNumberMask = {}) =>
  createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 10,
    allowNegative: false,
    allowLeadingZeroes: false,
    ...options
  })

export const percentMask = createNumberMask({
  prefix: '',
  suffix: '%',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 3,
  allowNegative: false,
  allowLeadingZeroes: true
})

export const floatMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 4,
  allowNegative: false,
  allowLeadingZeroes: true
})

export const floatUnder100Mask = createNumberMask({
  prefix: '',
  suffix: '',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 2,
  allowNegative: false,
  allowLeadingZeroes: false
})

export const inventoryMask = createNumberMask({
  prefix: '',
  suffix: '',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 1,
  allowNegative: false,
  allowLeadingZeroes: false
})

const createIntegerMask = (params = {}) =>
  createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    integerLimit: null,
    allowNegative: false,
    allowLeadingZeroes: false,
    ...params,
    decimalSymbol: '',
    decimalLimit: 0,
    allowDecimal: false
  })

export const integerMask = createIntegerMask()
export const negativeIntegerMask = createIntegerMask({ allowNegative: true })
export const under100IntegerMask = createIntegerMask({ integerLimit: 2 })
export const under1000IntegerMask = createIntegerMask({ integerLimit: 3 })

// prettier-ignore
export const phoneMask = [ '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ]
