import { TModelFieldSetting } from '../ModelField'

export type TTaskFieldAttribute = 'requiredForStage'

export type TTaskFieldSetting = {
  attr: TTaskFieldAttribute
} & TModelFieldSetting

export const taskFieldsSettings: TTaskFieldSetting[] = [
  {
    attr: 'requiredForStage',
    name: 'Required for Stage'
  }
]
