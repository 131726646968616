import { FocusEvent, useEffect, useRef, useState } from 'react'
import { Option } from 'classes'
import { CheckBoxArray, SelectProps } from 'components'
import { DEFAULT_STRING } from 'utils'
import { DisplayValue, InlineProps } from '../DisplayValue'
import styles from './InlineEditMultiSelect.module.scss'

export interface IInlineEditMultiSelectProps
  extends InlineProps,
    Omit<SelectProps, 'onBlur' | 'options' | 'onChange'> {
  onBlur?: (val: string[]) => void
  onChange?: (val: string[]) => void
  options: Option[]
  prompt?: string
  readOnly?: boolean
  values: string[]
}

export const InlineEditMultiSelect = ({
  values,
  options = [],
  onChange,
  onBlur,
  prompt = DEFAULT_STRING,
  isLoading,
  isError,
  readOnly,
  ...props
}: IInlineEditMultiSelectProps) => {
  const [selectedValues, setSelectedValues] = useState<Array<string>>(values)
  const [isEditMode, setIsEditMode] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    setSelectedValues(values)
  }, [values])

  const handleOnChange = (newSelectedValues: string[]) => {
    setSelectedValues(newSelectedValues)

    if (onChange) {
      onChange(newSelectedValues)
    }
  }

  const formatter = (selectedValues: string[]) => {
    const selectedOptions = options.filter(o => selectedValues.includes(o.value))

    return selectedOptions.length ? selectedOptions.map(o => o.name).join(', ') : prompt
  }

  const onBlurred = (e: FocusEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.relatedTarget)) {
      setIsEditMode(false)

      if (onBlur) {
        onBlur(selectedValues)
      }
    }
  }

  return (
    <div className={styles.container} tabIndex={0} onBlur={onBlurred}>
      <DisplayValue
        isReadOnly={readOnly}
        setEditMode={setIsEditMode}
        value={formatter(selectedValues)}
        isLoading={isLoading}
        isError={isError}
        className={styles.input}
      />
      {isEditMode && (
        <div className={styles.menu} ref={dropdownRef} tabIndex={-1}>
          <CheckBoxArray options={options} value={selectedValues} onChange={handleOnChange} />
        </div>
      )}
    </div>
  )
}
