import { ReactNode } from 'react'
import { FloatingFocusManager } from '@floating-ui/react'
import { useDrawerContext } from './Drawer'
import * as S from './styles'

type DrawerContentProps = {
  /**
   * The content to display inside the Drawer
   */
  children: ReactNode
  /**
   * A unique key to map with the menu button
   */
  drawerKey: string
}

export const DrawerContent = ({ children, drawerKey }: DrawerContentProps) => {
  const { context, activeKey, isOpen, getFloatingProps } = useDrawerContext()
  const isActive = isOpen && activeKey === drawerKey

  if (!isActive) {
    return null
  }

  return (
    <FloatingFocusManager context={context}>
      <S.Content ref={context.refs.setFloating} {...getFloatingProps()}>
        {children}
      </S.Content>
    </FloatingFocusManager>
  )
}

DrawerContent.displayName = 'DrawerContent'
