import { Box } from '@foundation/components'
import { css, styled } from '@foundation/themes/styles'
import { FlexProps } from './types'

export const Flex = styled(Box)<FlexProps>`
  ${({ theme, alignItems, flexBasis, flexDirection, flexWrap, justifyContent, gap }) => css`
    display: flex;

    ${alignItems && `align-items: ${alignItems};`}
    ${flexBasis && `flex-basis: ${flexBasis};`}
    ${flexDirection && `flex-direction: ${flexDirection};`}
    ${flexWrap && `flex-wrap: ${flexWrap};`}
    ${gap && `gap: ${theme.space[gap] ? theme.space[gap] : gap};`}
    ${justifyContent && `justify-content: ${justifyContent};`}
  `}
`
