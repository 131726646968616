// @ts-strict
import {
  Ellipsis,
  FormField,
  Section,
  Sections,
  SimpleTable,
  TableCell as Td,
  TableRow as Tr
} from 'components'
import {
  UserIntegrationCreateButtonForm,
  UserIntegrationDeleteButtonForm,
  UserIntegrationEditButtonForm
} from 'content/UserIntegration'
import { getName, integrationSettings } from 'lookups'
import { Employee } from 'models'
import { byValue, formatBoolean, formatDateTime } from 'utils'
import style from './EmployeeDetails.module.scss'
import { taskLabels } from './utils'

type Props = {
  employee: Employee
  taskCount: { [key: string]: number | undefined }
}

export const EmployeeDetails = ({ employee, taskCount }: Props) => {
  const aboutSection = (
    <Section title="About">
      <FormField title="Id" inline>
        <Ellipsis>{employee.id}</Ellipsis>
      </FormField>
      <FormField title="Archived" inline>
        {formatBoolean(!!employee.archived)}
      </FormField>
      <FormField title="Pure Cloud User Id" inline>
        {employee.pureCloudUserId}
      </FormField>
    </Section>
  )

  const authenticationSection = (
    <Section title="Authentication">
      <FormField title="Sign In Count" inline>
        {employee.signInCount}
      </FormField>
      <FormField title="Current Sign In Ip" inline>
        {employee.currentSignInIp}
      </FormField>
      <FormField title="Last Sign In Ip" inline>
        {employee.lastSignInIp}
      </FormField>
      <FormField title="Created At" inline>
        {formatDateTime(employee.createdAt)}
      </FormField>
      <FormField title="Updated At" inline>
        {formatDateTime(employee.updatedAt)}
      </FormField>
      <FormField title="Confirmed At" inline>
        {formatDateTime(employee.confirmedAt)}
      </FormField>
      <FormField title="Confirmation Sent At" inline>
        {formatDateTime(employee.confirmationSentAt)}
      </FormField>
      <FormField title="Current Sign In At" inline>
        {formatDateTime(employee.currentSignInAt)}
      </FormField>
      <FormField title="Last Sign In At" inline>
        {formatDateTime(employee.lastSignInAt)}
      </FormField>
    </Section>
  )

  const userIntegrationsSection = (
    <Section
      title="Integrations"
      actions={<UserIntegrationCreateButtonForm employeeId={employee.id} />}
    >
      <SimpleTable>
        {employee.userIntegrations?.map(userIntegration => {
          const { id, name, value } = userIntegration

          return (
            <Tr key={id} className={style.row}>
              <Td className={style.nameColumn}>{`${
                integrationSettings.find(byValue(name))?.name
              }`}</Td>

              <Td className={style.valueColumn}>
                <Ellipsis>{value as string}</Ellipsis>
              </Td>

              <Td className={style.actionColumn}>
                <UserIntegrationEditButtonForm userIntegration={userIntegration} />
                <UserIntegrationDeleteButtonForm
                  name={getName(name!)!}
                  value={value!}
                  integrationId={id!}
                />
              </Td>
            </Tr>
          )
        })}
      </SimpleTable>
    </Section>
  )

  const tasksSection = (
    <Section title="Tasks">
      {Object.keys(taskCount).map(taskStatus => (
        <FormField title={`${taskLabels(taskStatus)}`} key={`task-${taskStatus}`} inline>
          {taskCount[taskStatus]}
        </FormField>
      ))}
    </Section>
  )

  return (
    <>
      <Sections>
        {aboutSection}
        {authenticationSection}
      </Sections>
      <Sections>
        {userIntegrationsSection}
        {tasksSection}
      </Sections>
    </>
  )
}
