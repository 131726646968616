import { LoadingText, Logo, Page } from 'components'
import styles from './SplashPage.module.scss'

export const SplashPage = () => {
  return (
    <Page className={styles.splashPage}>
      <div className={styles.splashContent}>
        <Logo width={200} height={200} />
        <h1>Sales App</h1>
        <LoadingText text="" />
      </div>
    </Page>
  )
}
