// @ts-strict
import {
  CCHLSimpleSaleLead,
  HLSimpleSaleLead,
  ProviderLeadCapitalPartner,
  ServicesOpportunity
} from 'models'
import { HLSimpleSaleLeadResponse, ProviderLeadResponse } from 'schema'

type ProviderLeadExpenses = {
  category: string
  description: string
  id: string
  slug: string
  value: string
}

export class HLSimpleSaleProviderLead {
  id!: string
  createdAt?: string
  followUpDate?: string
  lead?: CCHLSimpleSaleLead
  providerLeadCapitalPartner?: ProviderLeadCapitalPartner
  reasonForFail?: string
  representedProduct?: string
  servicesOpportunities?: ServicesOpportunity[]
  stage?: string
  providable?: HLSimpleSaleLead
  providerLeadExpenses?: ProviderLeadExpenses[]
  constructor(hlSimpleSaleProviderLeadAttributes: HLSimpleSaleProviderLead) {
    Object.assign(this, hlSimpleSaleProviderLeadAttributes)
  }

  static create(
    hlSimpleSaleProviderLeadResponse: Potential<ProviderLeadResponse>
  ): Potential<HLSimpleSaleProviderLead> {
    if (!hlSimpleSaleProviderLeadResponse) {
      return
    }

    const lead = CCHLSimpleSaleLead.create(hlSimpleSaleProviderLeadResponse?.lead)
    const providerLeadCapitalPartner = ProviderLeadCapitalPartner.create(
      hlSimpleSaleProviderLeadResponse.providerLeadCapitalPartner
    )
    const providable = HLSimpleSaleLead.create(
      hlSimpleSaleProviderLeadResponse?.providable as HLSimpleSaleLeadResponse
    )
    return new HLSimpleSaleProviderLead({
      ...hlSimpleSaleProviderLeadResponse,
      lead,
      providerLeadCapitalPartner,
      providable
    })
  }
}
