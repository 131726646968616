import { cx } from 'utils'
import styles from './Pill.module.scss'

type PillProps = {
  active?: boolean
  children: React.ReactNode
  className?: string
  onClick?: () => void
}

export const Pill = ({ active, children, className, onClick }: PillProps) => {
  const variation = active ? styles.active : styles.inactive

  return (
    <div className={cx(styles.pill, variation, className)} onClick={onClick}>
      {children}
    </div>
  )
}
