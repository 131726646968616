import { SearchParams } from '@types'
import { formatDaysAgo, removeAttributesWithNullValues } from 'utils'
import { getSort } from '../../utils'

const sortParam = {
  Created: 'created_at',
  Name: 'name.keyword',
  Score: '_score',
  Updated: 'order.last_lead_stage_update',
  Stage: 'stage'
}

export type BuyerAndSellerLeadsSearchParams = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'searchTerm' | 'sortDir' | 'sortBy'
> & {
  assigneeId?: string
  escrowAttached?: string
  lastStageUpdate?: string
  listingStatus?: string[]
  maxPrice?: string
  minPrice?: string
  needsListingReview?: string
  oppStatus?: string[]
  pendingActions?: string[]
  productEligibility?: string[]
  stage?: string[]
  stateName?: string
  userType?: string[]
}

const getFilters = ({
  assigneeId,
  searchTerm,
  escrowAttached,
  lastStageUpdate,
  listingStatus,
  minPrice,
  maxPrice,
  oppStatus,
  stage,
  needsListingReview,
  userType,
  pendingActions,
  productEligibility,
  stateName
}: BuyerAndSellerLeadsSearchParams) => {
  const filters = {}

  if (searchTerm) {
    filters['filter[_search]'] = searchTerm
  }

  if (assigneeId) {
    filters['filter[_ae_id]'] = assigneeId
  }

  if (escrowAttached) {
    filters['filter[escrow_attachment]'] = escrowAttached
  }

  if (lastStageUpdate) {
    filters['filter[>last_stage_update]'] = formatDaysAgo(lastStageUpdate)
  }

  if (listingStatus) {
    filters['filter[external_listing.status]'] = listingStatus
  }

  if (maxPrice) {
    filters['filter[<price]'] = maxPrice
  }

  if (minPrice) {
    filters['filter[>price]'] = minPrice
  }

  if (oppStatus) {
    filters['filter[services_opportunities.status_id]'] = oppStatus
  }

  if (stage) {
    filters['filter[stage]'] = stage
  }

  if (needsListingReview) {
    filters['filter[needs_listing_review]'] = needsListingReview
  }

  if (userType) {
    filters['filter[user_type]'] = userType
  }

  if (pendingActions) {
    filters['filter[pending_action_names]'] = pendingActions
  }

  if (productEligibility && productEligibility.includes('eligible_for_hlti')) {
    filters['filter[call_review.eligible_for_hlti]'] = 'true'
  }

  if (stateName) {
    filters['filter[state_name]'] = stateName
  }

  return filters
}

export const getBuyerAndSellerLeadsSearchParams = ({
  page,
  perPage,
  sortBy,
  sortDir,
  ...filters
}: BuyerAndSellerLeadsSearchParams) => {
  return removeAttributesWithNullValues({
    'page[number]': page ? page : undefined,
    'page[size]': perPage ? perPage : undefined,
    'filter[user_type]': 'seller,buyer',
    sort: getSort(sortParam, sortDir, sortBy),
    ...getFilters(filters)
  })
}
