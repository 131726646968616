import { useCallback, useMemo, useState } from 'react'
import { differenceInMilliseconds, min } from 'date-fns'
import { LEAD_WARM_TRANSFER_KEY, useWebSocketController } from 'controllers'
import { useQuery } from 'hooks'
import { fetchLeadWarmTransfer } from 'services'
import { snakeCaseKeys } from 'utils'
import type {
  AgentActions,
  AgentInformation,
  AgentUpdate,
  HandleWarmTransferSteps,
  Message,
  MessageIdentifier,
  Props,
  WarmTransferStepKey
} from './types'
import { WARM_TRANSFER_STEP } from './types'

const validateAgent = (newAgent: AgentInformation) => {
  const { action, agentId, agentName } = newAgent
  if (action && agentId && agentName) {
    return true
  }

  return false
}

type CallbackSendMessage = { callback: (message: Message) => void }

export const useWarmTransferController = ({ leadId, enabled }: Props) => {
  const [step, setStep] = useState<WarmTransferStepKey>(WARM_TRANSFER_STEP.unsubscribed)
  const [agentsAvailable, setAgentsAvailable] = useState<AgentInformation[]>([])
  const [totalElapsedTime, setTotalElapsedTime] = useState<number>(1)

  const { cancelConnection, createWebSocketTicket, sendMessage } = useWebSocketController<Message>({
    onMessage: ({ message, type }) => {
      handleWarmTransferSteps({ callback: sendMessage, message, type })
    }
  })

  const identifier = useMemo(
    () =>
      snakeCaseKeys({
        channel: 'WarmTransferChannel',
        leadId
      }) as MessageIdentifier,
    [leadId]
  )

  const subscribeToChannel = useCallback(
    ({ callback }: CallbackSendMessage) => {
      const message: Message = {
        identifier,
        command: 'subscribe'
      }

      callback(message)
    },
    [identifier]
  )

  const startWarmTransfer = useCallback(
    ({ callback }: CallbackSendMessage) => {
      const message: Message = {
        command: 'message',
        identifier,
        data: {
          action: 'start'
        }
      }

      callback(message)
    },
    [identifier]
  )

  const updateAgentsList = useCallback((newAgent: AgentInformation) => {
    if (!validateAgent(newAgent)) {
      return
    }

    setAgentsAvailable(prevAgents => {
      if (!prevAgents.length) {
        return [newAgent]
      }

      const alreadyHaveTheNewAgent = prevAgents.some(
        agent => String(agent.agentId) === String(newAgent.agentId)
      )
      if (!alreadyHaveTheNewAgent) {
        return [...prevAgents, newAgent]
      }

      return prevAgents
    })
  }, [])

  const updateAgentAction = useCallback(
    ({ action, agentId }: AgentUpdate) => {
      if (!agentsAvailable.length) {
        return
      }

      setAgentsAvailable(prevAgents =>
        prevAgents.map(agent => {
          if (String(agent.agentId) === String(agentId)) {
            return {
              ...agent,
              action
            }
          }

          return agent
        })
      )
    },
    [agentsAvailable]
  )

  const updateAgentState = useCallback(
    ({ action, timestamp, agentId, workedByAgent }: AgentUpdate) => {
      if (!agentsAvailable.length) {
        return
      }

      setAgentsAvailable(prevAgents =>
        prevAgents.map(agent => {
          if (String(agent.agentId) === String(agentId)) {
            return {
              ...agent,
              action,
              timestamp,
              workedByAgent
            }
          }

          return agent
        })
      )
    },
    [agentsAvailable]
  )

  const stopWarmTransferConnection = useCallback(
    (step: WarmTransferStepKey) => {
      cancelConnection(() => {
        setAgentsAvailable([])
        setStep(step)
      })
    },
    [cancelConnection]
  )

  const handleWarmTransferSteps = useCallback(
    ({ callback, message, type }: HandleWarmTransferSteps) => {
      const isNotValidMessage = !callback && !message && !type

      if (isNotValidMessage) {
        return
      }

      const shouldSubscribeToChannel = step !== WARM_TRANSFER_STEP.started && type === 'welcome'

      if (shouldSubscribeToChannel) {
        subscribeToChannel({ callback: callback! })
      }

      const callbackByStep = {
        unsubscribed: () => {
          if (enabled) {
            return
          }

          if (type === 'confirm_subscription') {
            setStep(WARM_TRANSFER_STEP.started)
            startWarmTransfer({ callback: callback! })
            setTotalElapsedTime(0)
          }
        },
        started: () => {
          if (message) {
            updateAgentsList(message as AgentInformation)
          }

          if (message && message.type === 'confirm_cancel') {
            stopWarmTransferConnection(WARM_TRANSFER_STEP.canceled)
          }

          if (message?.action === 'sms_agent') {
            setStep(WARM_TRANSFER_STEP.pending)
          }
        },
        pending: () => {
          if (message) {
            updateAgentsList(message as AgentInformation)
          }

          if (message && message.type === 'confirm_cancel') {
            stopWarmTransferConnection(WARM_TRANSFER_STEP.canceled)
          }

          if (message?.action === 'transferred' || message?.action === 'agent_accepted') {
            setStep(WARM_TRANSFER_STEP.active)
          }

          const canUpdateAgentInPendingState =
            message?.action === 'agent_accepted' ||
            message?.action === 'transferred' ||
            message?.action === 'canceled' ||
            message?.action === 'agent_disconnected'

          if (canUpdateAgentInPendingState) {
            updateAgentState({
              action: message.action,
              agentId: message.agentId,
              timestamp: message.timestamp,
              workedByAgent: message.workedByAgent
            } as AgentUpdate)
          }
        },
        active: () => {
          const canUpdateAgentInActiveState =
            message?.action === 'transferred' || message?.action === 'canceled'

          if (message?.action === 'completed') {
            stopWarmTransferConnection(WARM_TRANSFER_STEP.completed)
            return
          }

          if (canUpdateAgentInActiveState) {
            updateAgentState({
              action: message.action,
              agentId: message.agentId,
              timestamp: message.timestamp
            } as AgentUpdate)
          }
        },
        completed: () => {
          // Ignore any messages in this state
        }
      }

      return callbackByStep[step]()
    },
    [
      enabled,
      startWarmTransfer,
      step,
      stopWarmTransferConnection,
      subscribeToChannel,
      updateAgentState,
      updateAgentsList
    ]
  )

  const startConnection = () => {
    createWebSocketTicket.mutate()
  }

  const connectAgentWithWarmTransfer = (agentId: string) => {
    const message: Message = {
      identifier,
      command: 'message',
      data: {
        action: 'perform',
        agentId
      }
    }

    updateAgentAction({ action: 'perform', agentId })
    sendMessage(message)
  }

  const cancelWarmTransfer = () => {
    const message: Message = {
      identifier,
      command: 'message',
      data: {
        action: 'cancel'
      }
    }

    sendMessage(message)
  }

  useQuery([LEAD_WARM_TRANSFER_KEY, leadId], () => fetchLeadWarmTransfer(leadId), {
    refetchOnWindowFocus: false,
    enabled,
    onSuccess: warmTransferInformation => {
      if (warmTransferInformation?.length) {
        const wasWarmTransferCompleted = warmTransferInformation.some(
          warmTransfer => warmTransfer.status === WARM_TRANSFER_STEP.completed
        )

        if (wasWarmTransferCompleted) {
          setStep(WARM_TRANSFER_STEP.completed)
          return
        }

        const wasWarmTransferCanceled = warmTransferInformation.some(
          warmTransfer => warmTransfer.status === WARM_TRANSFER_STEP.canceled
        )

        if (wasWarmTransferCanceled) {
          setStep(WARM_TRANSFER_STEP.canceled)
          return
        }

        const warmTransferTimelapse = warmTransferInformation.map(warmTransfer => {
          const { createdAt } = warmTransfer
          const timestamp = createdAt ? new Date(createdAt).getTime() : 0
          return timestamp ? timestamp : 0
        })

        const oldestDate = min(warmTransferTimelapse)
        const diffOldestDateAndNow = differenceInMilliseconds(new Date(), oldestDate)

        setTotalElapsedTime(diffOldestDateAndNow)

        warmTransferInformation.forEach(warmTransfer => {
          const {
            agent,
            status,
            acceptedAt,
            transferredAt,
            smsSentAt,
            declinedAt,
            leadRoutingEnabled,
            workedByAgent: claimingAgent
          } = warmTransfer
          const agentBrokerage =
            agent?.officeDisplayName && `${agent?.officeDisplayName}, ${agent?.state?.code}`
          const agentName = agent?.fullName || `${agent?.firstName} ${agent?.lastName}`

          let action: AgentActions
          let timestamp: string | undefined

          if (!smsSentAt) {
            return
          }

          if (status === 'accepted') {
            action = 'agent_accepted'
            timestamp = acceptedAt || smsSentAt
          } else if (status === 'transferred') {
            action = 'transferred'
            timestamp = transferredAt || smsSentAt
          } else if (!!declinedAt) {
            action = 'agent_disconnected'
            timestamp = declinedAt
          } else {
            action = status || 'pending'
            timestamp = smsSentAt
          }

          const workedByAgent: AgentInformation = {
            action,
            agentId: claimingAgent?.id!,
            agentBrokerage:
              claimingAgent?.officeDisplayName &&
              `${claimingAgent?.officeDisplayName}, ${claimingAgent?.state?.code}`,
            agentName:
              claimingAgent?.fullName || `${claimingAgent?.firstName} ${claimingAgent?.lastName}`,
            agentPictureUrl: claimingAgent?.pictureThumbUrl
          }

          const newAgent: AgentInformation = {
            action,
            agentId: agent?.id!,
            agentBrokerage,
            agentName,
            agentPictureUrl: agent?.pictureThumbUrl,
            timestamp,
            leadRoutingEnabled,
            workedByAgent
          }

          updateAgentsList(newAgent)
        })

        const isWarmTransferActive = warmTransferInformation.some(
          warmTransfer =>
            warmTransfer.status === 'accepted' || warmTransfer.status === 'transferred'
        )

        if (isWarmTransferActive) {
          setStep(WARM_TRANSFER_STEP.active)
        } else {
          setStep(WARM_TRANSFER_STEP.pending)
        }

        startConnection()
      }
    }
  })

  return {
    agentsAvailable,
    connectAgentWithWarmTransfer,
    cancelWarmTransfer,
    startConnection,
    step,
    totalElapsedTime
  }
}
