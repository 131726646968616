import { useState } from 'react'
import { FilterBar, ListSelect } from 'components'
import { AgentLeadsMap } from 'content/AgentLeads'
import { AgentLead } from 'models'
import { AgentLeadsList } from '../AgentLeadsList'

type AgentLeadsViewProps = {
  agentLeads: AgentLead[]
  isLoading: boolean
  pageCount?: number
  totalCount?: number
}

export const AgentLeadsView = ({
  agentLeads,
  isLoading,
  pageCount,
  totalCount
}: AgentLeadsViewProps) => {
  const [format, setFormat] = useState<'Table' | 'Map'>('Table')

  const formats = {
    Table: (
      <AgentLeadsList
        agentLeads={agentLeads}
        isLoading={isLoading}
        pageCount={pageCount}
        totalCount={totalCount}
      />
    ),
    Map: <AgentLeadsMap agentLeads={agentLeads} isLoading={isLoading} />
  }

  return (
    <>
      {agentLeads && agentLeads.length ? (
        <FilterBar>
          <ListSelect
            options={[{ name: 'Table' }, { name: 'Map' }]}
            value={format}
            onChange={setFormat}
          />
        </FilterBar>
      ) : null}
      {formats[format]}
    </>
  )
}
