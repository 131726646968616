// @ts-strict
import { Dispatch, SetStateAction, useState } from 'react'
import { If } from 'components'
import { AgentMatchingLeadAgentScreenSection } from 'content/AgentMatchingLead/AgentMatchingLeadAgentScreenSection'
import { AgentMatchingLeadClientScreenSection } from 'content/AgentMatchingLead/AgentMatchingLeadClientScreenSection'
import { AgentMatchingLeadInvestorScreenSection } from 'content/AgentMatchingLead/AgentMatchingLeadInvestorScreenSection'
import { useLeadController } from 'controllers'
import { BuyerLead, SellerLead } from 'models'
import * as S from './styles'
import { AgentScreenFields, ClientScreenFields, InvestorScreenFields } from './types'

type Props = {
  lead: BuyerLead | SellerLead
  setLoadingNewInfoForLead: Dispatch<SetStateAction<boolean>>
}

export const NormalIntroStageRequiredFields = ({ lead, setLoadingNewInfoForLead }: Props) => {
  const { updateLead } = useLeadController(lead.id, lead.userType)

  const baseClientFields = {
    why: true,
    alreadyHasAgent: true,
    qualificationMethod: true,
    interestedInOtherside: true
  }
  const baseAgentFields = { previousAgent: true }

  const clientScreenRequiredFields: ClientScreenFields = {
    ...baseClientFields,
    ...(lead.userType === 'seller'
      ? { clientInterestedIn: true, homeListed: true }
      : { buyerProgress: true, preapproved: true, interestedInLoan: true })
  }

  const investorScreenRequiredFields: InvestorScreenFields = {
    ...(lead.sourcePageType === 'offers' && lead.userType === 'seller'
      ? { askedInvestorQualifyingQuestions: true, pitchedInvestorToClient: true }
      : {})
  }

  const agentFields: AgentScreenFields =
    lead.callReview?.previousAgent === 'Yes'
      ? { ...baseAgentFields, previousAgentName: true, previousAgentBrokerage: true }
      : baseAgentFields

  const [agentScreenRequiredFields, setAgentScreenRequiredFields] =
    useState<AgentScreenFields>(agentFields)

  const updateLeadField = (value: Record<string, string | number | Record<string, string>>) => {
    setLoadingNewInfoForLead(true)
    updateLead.mutate(value)
  }

  const updateCallReview = (key: string, value: string) => {
    if (key === 'previousAgent') {
      setAgentScreenRequiredFields(prevState =>
        value === 'Yes'
          ? { ...prevState, previousAgentName: true, previousAgentBrokerage: true }
          : baseAgentFields
      )
    }

    updateLeadField({ callReview: { [key]: value } })
  }

  return (
    <S.NormalIntroWrapper>
      <AgentMatchingLeadClientScreenSection
        lead={lead}
        updateField={updateLeadField}
        updateCallReview={updateCallReview}
        requiredFields={clientScreenRequiredFields}
      />
      <AgentMatchingLeadAgentScreenSection
        hideBrokeragesToExclude
        lead={lead}
        updateCallReview={updateCallReview}
        requiredFields={agentScreenRequiredFields}
      />
      <If test={lead.sourcePageType === 'offers' && lead.userType === 'seller'}>
        <AgentMatchingLeadInvestorScreenSection
          isDispositionForm={true}
          lead={lead}
          requiredFields={investorScreenRequiredFields}
          updateCallReview={updateCallReview}
        />
      </If>
    </S.NormalIntroWrapper>
  )
}
