//@ts-strict
import { useState } from 'react'
import { Box, Text } from '@foundation/components'
import { ErrorMessage } from '@foundation/components/Select/styles'
import { styled } from '@foundation/themes/styles'
import {
  Button,
  Divider,
  Form,
  FormActions,
  InlineEditMoneyInput,
  InlineEditSelect,
  PrimaryButton,
  Section,
  Sections
} from 'components'
import { pplBillingFreq } from 'lookups'
import { formatMoney } from 'utils'
import { MINIMUM_PPL_MONTHLY_CAP } from '../constants'
import styles from './MonthlyCap.module.scss'
import { monthlyCapInfo } from './MonthlyCapMessages'
import { PaymentOptions } from './PaymentOptions'
import { MonthlyCapFormProps } from './types'

const StyledForm = styled(Form)`
  padding: 2.4rem;
  width: 100%;
  min-width: 60rem;
  max-width: 80rem;

  &.confirmation {
    min-width: 40rem;
    max-width: 60rem;
  }

  @media (max-width: 414px) {
    min-width: 30rem;
  }
`

const monthlyCapErrorMessage = (currentMonthlyCap: number) => {
  if (currentMonthlyCap < MINIMUM_PPL_MONTHLY_CAP) {
    return `Cap is below $${MINIMUM_PPL_MONTHLY_CAP}`
  }

  return ''
}

/*
 * In this component coexists 2 types of monthly caps. `monthlyCap` represents
 * the persisted value, in example the monthly cap the investor previously set.
 *
 * Meanwhile, currentMonthlyCap represents the input monthly cap that can be either
 * added or modified by the sales representative.
 */
export const MonthlyCapForm = ({
  cancelText = 'Cancel',
  monthlyCap = 0,
  billingFreq = '',
  onCancel,
  onSubmit,
  submitText = 'Save'
}: MonthlyCapFormProps) => {
  const [currentMonthlyCap, setCurrentMonthlyCap] = useState(monthlyCap || 0)
  const [currentBillingFreq, setCurrentBillingFreq] = useState(billingFreq || 'monthly')
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<'card' | 'link'>('card')
  const [isLoading, setIsLoading] = useState(false)

  const formattedMonthlyCap = formatMoney(monthlyCap)
  const isUpdatingMonthlyCap = monthlyCap > 0
  const isLessThanMinimumCap = currentMonthlyCap < 200

  const subTitle = isUpdatingMonthlyCap
    ? `HomeLight will place a hold of ${formattedMonthlyCap} at the start of every billing cycle that will expire in 7 days in order to verify payment method. 
       The investor will be charged at the end of each billing cycle based off of the leads and pricing they agreed to below not to exceed ${formattedMonthlyCap}.`
    : `Please input the monthly cap value. Once value has been set, select the payment method the investor would like to use. Billing will be monthly or weekly based on the frequency.`

  return (
    <StyledForm
      onSubmit={() => {
        setIsLoading(true)
        onSubmit(currentMonthlyCap, currentBillingFreq, currentPaymentMethod)
      }}
    >
      <Box marginBottom="$4">
        <Text weight="bold">Set the seller cap and select payment method</Text>
      </Box>

      <Text size="sm" as="span">
        {subTitle}
      </Text>
      <Divider className={styles.divider} />

      <Sections>
        <Section>
          <Box marginBottom="$2" marginTop="$2">
            <Text>Seller Cap</Text>
          </Box>

          <InlineEditMoneyInput
            className={styles.input}
            value={String(currentMonthlyCap)}
            onChange={cap => setCurrentMonthlyCap(Number(cap.replace(/[^\d.]/g, '')))}
          />

          <ErrorMessage hasError={isLessThanMinimumCap}>
            <Box marginTop="$3" marginBottom="$5">
              <Text color="danger5" size="xs">
                {monthlyCapErrorMessage(currentMonthlyCap)}
              </Text>
            </Box>
          </ErrorMessage>

          <Box marginBottom="$2" marginTop="$5">
            <Text>Billing Frequency</Text>
          </Box>

          <InlineEditSelect
            prompt="Select Billing Frequency"
            className={styles.select}
            onChange={freq => setCurrentBillingFreq(String(freq))}
            options={pplBillingFreq}
          />

          <Box marginTop="$8">
            <PaymentOptions
              hide={isUpdatingMonthlyCap}
              paymentMethod={currentPaymentMethod}
              onSelectPaymentType={setCurrentPaymentMethod}
            />
          </Box>
        </Section>

        <Section className={styles.bullets}>
          <ul className={styles.list}>
            {monthlyCapInfo(monthlyCap, currentMonthlyCap).map((info, idx) => (
              <li key={`monthly-cap-message-${idx}`}>{info}</li>
            ))}
          </ul>
        </Section>
      </Sections>

      <FormActions>
        <Button type="button" disabled={isLoading} onClick={onCancel}>
          {cancelText}
        </Button>
        <PrimaryButton type="submit" disabled={isLessThanMinimumCap} isLoading={isLoading}>
          {submitText}
        </PrimaryButton>
      </FormActions>
    </StyledForm>
  )
}
