import { SendCheckoutSessionMagicLinkVariables } from 'contexts/types'
import { getCheckoutSessionsMagicLinkUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export const postCheckoutSessionMagicLink = (payload: SendCheckoutSessionMagicLinkVariables) => {
  const { subscriberId, subscriberType, priceId, redirectUrl } = payload
  return postRequest(getCheckoutSessionsMagicLinkUrl(), {
    subscriber_id: subscriberId,
    subscriber_type: subscriberType,
    price_id: priceId,
    redirect_url: redirectUrl
  })
}
