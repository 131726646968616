import { titleize } from 'utils'
import { leadStageAttributes } from './leadStageAttributes'
import { TLeadStageSetting } from './leadStageSettings'

export const getLeadStageNameFromSlug = (stage: TLeadStageSetting['slug'] | string) => {
  const stageValues = Object.values(leadStageAttributes).find(attributes => {
    return attributes.slug === stage
  })

  if (!stageValues) {
    return titleize(stage)
  }

  return stageValues.name
}
