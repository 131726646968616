import { Page } from 'components'
import { useDocumentTitle } from 'hooks'
import { releaseNotes } from 'releaseNotes'
import { ReleaseNote } from './ReleaseNote'
import styles from './ReleaseNotesPage.module.scss'

export const ReleaseNotesPage = () => {
  useDocumentTitle('Release Notes')

  return (
    <Page title="Release Notes">
      <div className={styles.notes}>
        {releaseNotes.map((note, idx) => (
          <ReleaseNote note={note} key={idx} />
        ))}
      </div>
    </Page>
  )
}
