// @ts-strict
import {
  ButtonForm,
  CheckBoxField,
  FormField,
  InlineChangeButton,
  Select,
  TextArea,
  useButtonForm
} from 'components'
import { useLeadController } from 'controllers'
import { leadPendingActionsResolveActionTaken, TLeadUserTypeServerSlug } from 'lookups'
import { PendingAction } from 'models'
import { d, validatePresence } from 'utils'
import * as S from '../styles'

type ResolvePendingReviewPayload = {
  actionTaken: string
  ids: string[]
  note: string
}

const INITIAL_PAYLOAD = {
  ids: [],
  actionTaken: '',
  note: ''
}

type ResolvePendingReviewButtonFormProps = {
  leadId: string
  leadType: TLeadUserTypeServerSlug
  pendingReviews: PendingAction[]
}

export const ResolvePendingReviewButtonForm = ({
  pendingReviews,
  leadId,
  leadType
}: ResolvePendingReviewButtonFormProps) => {
  const { resolvePendingActions } = useLeadController(leadId, leadType)
  const {
    fieldsProps: { onChangeAttribute, payload },
    formProps
  } = useButtonForm<ResolvePendingReviewPayload>({
    initialPayload: INITIAL_PAYLOAD,
    mutation: resolvePendingActions,
    formValidations: {
      ids: [validatePresence],
      actionTaken: [validatePresence]
    }
  })

  const REVIEWS_NAME = pendingReviews.map(review => d(review.name))
  const FORM_TITLE = REVIEWS_NAME.length > 1 ? REVIEWS_NAME.join(', \r\n') : REVIEWS_NAME[0]

  const handlePendingReviewsChange = (id: string) => {
    const reviews = payload.ids
    if (payload.ids.includes(id)) {
      const optionsWithoutSelected = reviews.filter(review => review !== id)
      onChangeAttribute('ids', optionsWithoutSelected)
      return
    }

    onChangeAttribute('ids', [...payload.ids, id])
  }

  const setNote = (val: string) => {
    onChangeAttribute('note', val)
  }

  const setActionTaken = (val: string) => {
    onChangeAttribute('actionTaken', val)
  }

  return (
    <ButtonForm
      buttonComponent={InlineChangeButton}
      title="Resolve Pending Review"
      buttonText={FORM_TITLE}
      {...formProps}
    >
      {formProps.showForm && (
        <>
          <S.FormField title="Pending Reviews" required>
            {pendingReviews.map(review => {
              return (
                <CheckBoxField
                  key={review.name}
                  title={d(review.name)}
                  checked={payload.ids.includes(review.id)}
                  onChange={() => handlePendingReviewsChange(review.id)}
                />
              )
            })}
          </S.FormField>
          <FormField title="Resolution" required>
            <Select
              aria-label="Resolution"
              options={leadPendingActionsResolveActionTaken}
              value={String(payload.actionTaken)}
              onChange={val => setActionTaken(val)}
              data-testid="resolutionSelect"
              addBlank
            />
          </FormField>
          <FormField title="Note">
            <TextArea aria-label="Note" id="note" onChange={setNote} data-testid="noteField" />
          </FormField>
        </>
      )}
    </ButtonForm>
  )
}
