import { Option } from 'classes'
import { IOptionable } from 'lookups'

export class FailedReason implements IOptionable {
  name!: string
  value!: string

  constructor(failedReasonAttributes: Omit<FailedReason, 'toOption'>) {
    Object.assign(this, failedReasonAttributes)
  }

  static create(failedReasonResponse: Potential<Omit<TLookupType, 'id'>>): Potential<FailedReason> {
    if (!failedReasonResponse) {
      return
    }

    return new FailedReason(failedReasonResponse)
  }

  toOption() {
    return new Option({ name: this.name, value: this.value })
  }
}
