import { useEffect, useState } from 'react'
import { Lead } from 'models'

type TReturn = {
  isEligible: boolean
  isEligibleWoBBYS: boolean
  loanAmount: number | null
  loanAmountWoBBYS: number | null
}

const LOAN_AMOUNT_THRESHOLD = 100000

export function useLoanCalculator(lead: Lead | undefined): TReturn {
  const [isEligible, setIsEligible] = useState(false)
  const [loanAmount, setLoanAmount] = useState(null)
  const [isEligibleWoBBYS, setIsEligibleWoBBYS] = useState(false)
  const [loanAmountWoBBYS, setLoanAmountWoBBYS] = useState(null)

  useEffect(() => {
    if (!lead) {
      return
    }

    calculateLoanAmount(
      lead?.price,
      Number(lead?.callReview?.downpaymentAmount),
      Number(lead?.callReview?.maxEquityUnlock)
    )
    calculateLoanAmountWoBBYS(lead?.price, Number(lead?.callReview?.downpaymentAmount))
  }, [lead])

  function calculateLoanAmount(price: number, downpayment: number, maxEquityUnlock: number) {
    const result = (price || 0) - (downpayment || 0) - (maxEquityUnlock || 0)

    setLoanAmount(result < 0 ? 0 : result)
    setIsEligible(result >= LOAN_AMOUNT_THRESHOLD)

    return
  }

  function calculateLoanAmountWoBBYS(price: number, downpayment: number) {
    const result = (price || 0) - (downpayment || 0)

    setLoanAmountWoBBYS(result < 0 ? 0 : result)
    setIsEligibleWoBBYS(result >= LOAN_AMOUNT_THRESHOLD)

    return
  }

  return {
    isEligible,
    loanAmount,
    isEligibleWoBBYS,
    loanAmountWoBBYS
  }
}
