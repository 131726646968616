import { useLocalStorage } from 'hooks'

export const useLastRead = () => {
  const now = new Date().toISOString()

  const [lastRead, setLastRead] = useLocalStorage('inboxLastRead', { min: now })
  const markRead = id => setLastRead({ ...lastRead, [id]: now })
  const markAllRead = () => setLastRead({ min: now })

  return { lastRead, markRead, markAllRead }
}
