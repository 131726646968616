import { getChangeAgentTeamPreferredEscrowOfficerUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export const postAgentTeamPreferredEscrowOfficer = ({
  agentId,
  preferredEscrowOfficerUserId
}: {
  agentId: string
  preferredEscrowOfficerUserId: string
}): Promise<any> => {
  return postRequest(getChangeAgentTeamPreferredEscrowOfficerUrl(), {
    id: agentId,
    preferred_escrow_officer_user_id: preferredEscrowOfficerUserId
  })
}
