// @ts-strict
import { Marker, Point } from 'classes'
import { getIcon } from 'components/MapBox/utils/getIcon'
import { TUsStateCode } from 'lookups'
import { ListingResponse } from 'schema'

type TListingConstructor = Omit<Listing, 'toMarker' | 'toPoint'>

export class Listing {
  id?: string
  listingDetails?: {
    closePrice: number
    daysOnMarket: number
    listPrice: number
    listingContractDate: string
    mlsStatus: 'Pending' | 'Active' | 'Cancelled' | 'Closed'
  }
  listingIdentity?: {
    modificationTs: string
  }
  location?: {
    city: string
    hlFullAddress: string
    latitude: number
    longitude: number
    state: TUsStateCode
    streetName: string
    streetNumber: number
    zipCode: number
  }
  constructor(listing: TListingConstructor) {
    Object.assign(this, listing)
  }

  static create(listingResponse: Potential<ListingResponse>): Potential<Listing> {
    if (!listingResponse) {
      return
    }
    return new Listing(listingResponse)
  }

  toMarker(icon?: HTMLDivElement) {
    const defaultIcon = getIcon()
    return this.location?.latitude && this.location?.longitude
      ? new Marker({
          name: this.location.hlFullAddress,
          description: `Listed for ${this.listingDetails?.listPrice}. Sold for ${this.listingDetails?.closePrice}`,
          type: 'transaction',
          point: this.toPoint(),
          icon: defaultIcon || icon
        })
      : undefined
  }

  toPoint() {
    return this.location?.latitude && this.location?.longitude
      ? new Point({
          latitude: this.location.latitude,
          longitude: this.location.longitude
        })
      : undefined
  }
}
