type AnyObject = Record<string, any>

export const queryStringToObject = <T extends AnyObject = AnyObject>(
  stringText: string | undefined,
  defaults: Record<string, any> = {}
) => {
  const params = {}
  const stringParams = stringText ? stringText.replace(/^\?/, '') : ''

  if (typeof stringParams === 'string') {
    stringParams.split('&').forEach(queryPair => {
      const [key, value] = queryPair.split('=')

      if (key && value) {
        const decodedValue = decodeURIComponent(value)
        if (decodedValue.match(/^\[.+]$/)) {
          params[key] = decodedValue.replace(/[[\]]/g, '').split(',')
        } else {
          params[key] = decodedValue
        }
      }
    })
  }

  return {
    ...defaults,
    ...params
  } as T
}
