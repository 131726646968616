// @ts-strict
import { ReactNode } from 'react'
import { createCtx } from 'contexts/utils'
import { useAgentAeAreasController } from 'controllers'
import { useLocation } from 'hooks'
import { queryStringToObject } from 'utils'

type Props = {
  children: ReactNode
}

export const [useAgentAeAreasContext, AgentAeAreasContextProvider] =
  createCtx<ReturnType<typeof useAgentAeAreasController>>()

export const AgentAeAreasProvider = ({ children }: Props) => {
  const { search } = useLocation()
  const searchParams = queryStringToObject(search)

  return (
    <AgentAeAreasContextProvider value={useAgentAeAreasController(searchParams)}>
      {children}
    </AgentAeAreasContextProvider>
  )
}
