import { Contents, Page } from 'components'
import { LeadsList } from 'content/Leads'
import { LeadsProvider, ListProvider } from 'contexts'
import { useDocumentTitle } from 'hooks'
import { leadsListColumns } from 'lookups'

export const LeadsPage = () => {
  useDocumentTitle('Leads')

  return (
    <Page>
      <Contents title="Leads">
        <LeadsProvider>
          <ListProvider columns={leadsListColumns}>
            <LeadsList />
          </ListProvider>
        </LeadsProvider>
      </Contents>
    </Page>
  )
}
