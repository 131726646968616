import { MINIMUM_PPL_MONTHLY_CAP } from '../constants'

export const monthlyCapInfo = (monthlyCap: number, newMonthlyCap: number): Array<string> => {
  // Messages for the checkout flow
  if (monthlyCap === 0) {
    return [
      'Re-confirm seller cap with investor',
      'Communicate the monthly billing and hold cycle',
      'Review seller cap example with their lead pricing',
      'Communicate seller cap can be adjusted any time',
      `Set seller cap > $${MINIMUM_PPL_MONTHLY_CAP}`
    ]
  }

  // Messages for edit flow
  const monthlyCapInfo = [
    'Re-confirm seller cap with investor',
    'Communicate current progress towards new cap',
    'Confirm monthly billing and hold cadence with new cap'
  ]

  if (monthlyCap > newMonthlyCap && newMonthlyCap >= MINIMUM_PPL_MONTHLY_CAP) {
    return [
      ...monthlyCapInfo,
      `A monthly cap below the current monthly spend, won't receive new leads`,
      'The updated monthly cap will transfer over to the next month'
    ]
  }

  if (newMonthlyCap < MINIMUM_PPL_MONTHLY_CAP) {
    return [...monthlyCapInfo, `Monthly cap must be > $${MINIMUM_PPL_MONTHLY_CAP}`]
  }

  return monthlyCapInfo
}
