import styled from 'styled-components'
import { Section, Sections } from 'components'
import { Investor, InvestorPayPerLeadSetting } from 'models'
import { LocationSettingsTableReadOnly } from './LocationSettingsTableReadOnly'
import { LocationSettingsUpdateButtonForm } from './LocationSettingsUpdateButtonForm'

type LocationSettingsCurrentListProps = {
  investor: Investor
  locationSettings: InvestorPayPerLeadSetting[]
}

const InactiveSettingsWrapper = styled.div`
  opacity: 0.5;
`

export const LocationSettingsCurrentList = ({
  investor,
  locationSettings
}: LocationSettingsCurrentListProps) => {
  if (!locationSettings || locationSettings.length === 0) {
    return null
  }

  const activeLocations = locationSettings.filter(
    (location: InvestorPayPerLeadSetting) => location.active
  )

  const inactiveLocations = locationSettings.filter(
    (location: InvestorPayPerLeadSetting) => !location.active
  )

  return (
    <>
      <Section>
        <Sections>
          <b style={{ paddingRight: '8px' }}>Location Settings</b>
          <LocationSettingsUpdateButtonForm
            investor={investor}
            locationSettings={locationSettings}
          />
        </Sections>
        <LocationSettingsTableReadOnly locationSettings={activeLocations} />
      </Section>

      <Section>
        <Sections>
          <b
            style={{ paddingRight: '8px' }}
          >{`Inactive Locations (${inactiveLocations.length})`}</b>
        </Sections>

        <InactiveSettingsWrapper>
          <LocationSettingsTableReadOnly locationSettings={inactiveLocations.slice(0, 4)} />
        </InactiveSettingsWrapper>
      </Section>
    </>
  )
}
