import { ServicesOpportunitySelector } from 'content/ServicesOpportunity'
import { useLeadOpportunityDeclinedReason } from 'lookups'
import { AgentProviderLead } from 'models'

type Props = {
  agentProviderLead: AgentProviderLead
  onChange: () => void
}

export const AgentProviderLeadServicesOpportunitiesSelector = ({
  agentProviderLead,
  onChange
}: Props) => {
  const { leadOpportunityDeclinedReason } = useLeadOpportunityDeclinedReason()

  return (
    <>
      {agentProviderLead.servicesOpportunities.map(servicesOpportunity => (
        <ServicesOpportunitySelector
          key={servicesOpportunity.id}
          servicesOpportunity={servicesOpportunity}
          declinedReasons={leadOpportunityDeclinedReason}
          onChange={onChange}
        />
      ))}
    </>
  )
}
