import { TableBody, TableCell, TableRow } from '../index'

type TableNoResultsProps = {
  colSpan?: number
  type: string
}

export const TableNoResults = ({ colSpan, type }: TableNoResultsProps) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={colSpan}>{`No ${type || 'results'} found`}</TableCell>
      </TableRow>
    </TableBody>
  )
}
