import { Activity } from 'models'
import { AgentLeadStageUpdateResponse } from 'schema'
import { d } from 'utils'

export class AgentLeadStageUpdate {
  id?: string
  notes?: string
  oldStage?: string
  newStage?: string
  createdAt?: string
  updatedBy?: string
  ae?: {
    name?: string
  }

  constructor(agentLeadStageUpdateAttributes: Omit<AgentLeadStageUpdate, 'toActivity'>) {
    Object.assign(this, agentLeadStageUpdateAttributes)
  }

  toActivity() {
    return new Activity({
      id: this.createdAt,
      title: `Stage Updated by ${d(this.updatedBy)} ${
        this.updatedBy === 'ae' ? this.ae?.name : ''
      }`,
      body: this.notes,
      transitions: [d(this.oldStage), d(this.newStage)],
      occurredAt: this.createdAt
    })
  }

  static create(
    agentLeadStageUpdateResponse: Potential<AgentLeadStageUpdateResponse>
  ): Potential<AgentLeadStageUpdate> {
    if (!agentLeadStageUpdateResponse) {
      return
    }

    return new AgentLeadStageUpdate(agentLeadStageUpdateResponse)
  }
}
