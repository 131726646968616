import { camelcaseKeys, isObjectEmpty, snakeCaseKeys } from 'utils'

export type StringifyObject<ObjectToStringify> = {
  obj: ObjectToStringify
  transformKeys: 'snakeCase' | 'camelCase'
}

const triggerTransformKeys = {
  snakeCase: (obj: Record<string, any>) => snakeCaseKeys(obj),
  camelCase: (obj: Record<string, any>) => camelcaseKeys(obj)
}

const stringify = <ObjectToStringify>(obj: ObjectToStringify | undefined) => {
  try {
    return JSON.stringify(obj)
  } catch (e) {
    return ''
  }
}

export const stringifyObject = <ObjectToStringify extends object>({
  obj,
  transformKeys = 'snakeCase'
}: StringifyObject<ObjectToStringify>): string => {
  if (typeof obj === 'function' || Array.isArray(obj)) {
    return ''
  }

  const objEntries = Object.entries(obj)
  const prepareObjectToBeString = objEntries.reduce((previous, current) => {
    const [key, value] = current
    const checkIfValueIsObject = typeof value === 'object' && !Array.isArray(value)
    if (checkIfValueIsObject && !isObjectEmpty(value)) {
      const formatValueKeys = triggerTransformKeys[transformKeys](value)

      if (!stringify(formatValueKeys)) {
        return { ...previous }
      }

      return {
        [key]: JSON.stringify(formatValueKeys),
        ...previous
      }
    }

    return {
      ...previous,
      [key]: value
    }
  }, {}) as ObjectToStringify

  const camelizeKeys = triggerTransformKeys[transformKeys](prepareObjectToBeString)
  return stringify<ObjectToStringify>(camelizeKeys)
}
