import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './ActionBar.module.scss'

export type TActionBarProps = {
  children: ReactNode
  className?: string
}

export const ActionBar = ({ className, children }: TActionBarProps) => {
  return <div className={cx(className, styles.actionBar)}>{children}</div>
}
