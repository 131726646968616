// @ts-strict
const buyersAndSellersColumns = {
  pendingActions: {
    isDefault: false,
    isOptional: true,
    group: 'leadAttributes',
    name: 'Pending Actions',
    value: 'pendingActions',
    isReorderable: true,
    columnSize: 'lg'
  }
} as const

export const buyersAndSellersColumnsSettings = [buyersAndSellersColumns.pendingActions] as const
