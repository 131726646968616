import { Option } from 'classes'
import { Button, List } from 'components'
import { cx } from 'utils'
import styles from './ListSelect.module.scss'

type Props = {
  className?: string
  onChange: (newValue) => void
  options: readonly Option[]
  value: string
  vertical?: boolean
}

export const ListSelect = ({ onChange, options, value, className, vertical = false }: Props) => {
  return (
    <List
      className={cx(className, styles.listSelect, vertical ? styles.vertical : styles.horizontal)}
    >
      {options.map(option => {
        const optionValue = option.value || option.name
        return (
          <Button
            key={option.value || option.name}
            as="link"
            className={value === optionValue ? 'selected' : null}
            onClick={() => onChange(option.value || option.name)}
          >
            {option.icon}
            {option.name}
          </Button>
        )
      })}
    </List>
  )
}
