// @ts-strict
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MapboxAutoComplete, MapboxAutocompleteResponseItem } from 'components'
import { IS_ZIP_CODE, PLACE_TYPES, USER_TYPES } from '../constants'

const SearchWrapper = styled.div`
  width: 100%;
  max-width: 489px;
  padding-top: 16px;
`

type Props = {
  className?: string
  onSelect: (slug: string[] | string, userType: Array<string>) => void
  placeholder?: string
  value: string
}

export const SearchLocation = ({ onSelect, value }: Props) => {
  const [currentValue, setCurrentValue] = useState(value || '')
  const [searchType, setSearchType] = useState('place')

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    }
  }, [value])

  useEffect(() => {
    setSearchType(PLACE_TYPES.ZIP_CODE)
  }, [currentValue])

  const handleOnSelect = (selectedValue: MapboxAutocompleteResponseItem) => {
    if (selectedValue) {
      onSelect(selectedValue.place.slug!, [USER_TYPES.SELLER])
    }

    setCurrentValue('')
  }

  const removeValue = () => {
    setCurrentValue('')
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return
    }

    e.preventDefault()
    const inputValues = currentValue.split(',').map(val => val.trim())
    const isAllZipCodes = inputValues.every(val => IS_ZIP_CODE(val as string))

    if (isAllZipCodes) {
      onSelect(inputValues, [USER_TYPES.SELLER])
      setCurrentValue('')
    }
  }

  return (
    <SearchWrapper>
      <MapboxAutoComplete
        data-testid="cityState-auto-complete"
        onSelect={handleOnSelect}
        types={searchType}
        value={currentValue}
        onClear={removeValue}
        onKeyDown={onKeyDown}
        onChange={val => setCurrentValue(val)}
        onBlur={() => console.log('blur called', currentValue)}
        placeholder="select zip code"
      />
    </SearchWrapper>
  )
}
