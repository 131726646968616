import { useEmployeeUsers } from 'controllers'
import { useAgentPartnerships, useAssignedAreas } from 'lookups'
import {
  lookupTaskStatus,
  useServicesOpportunities,
  useTaskCategories,
  useTransactionTeams,
  useUsStates
} from 'lookups'
import { d, formatPrice, titleize } from 'utils'

export const useDisplayValueFunctions = () => {
  const { getUsStateName } = useUsStates()
  const { getUserName } = useEmployeeUsers()
  const { getAssignedAreaName } = useAssignedAreas()
  const { getOpportunityStatusName } = useServicesOpportunities()
  const { getTransactionTeamMemberName } = useTransactionTeams()
  const { getTaskCategoryName } = useTaskCategories()
  const { getTaskStatusName } = lookupTaskStatus()
  const { getPartnershipName } = useAgentPartnerships()

  const buildDaysAgoString = (value: string) => `${value} days`

  return {
    buildDaysAgoString,
    formatPrice,
    getUserName,
    titleize,
    d,
    getUsStateName,
    getAssignedAreaName,
    getOpportunityStatusName,
    getTaskCategoryName,
    getTaskStatusName,
    getTransactionTeamMemberName,
    getPartnershipName
  }
}
