import { useMutation, useQueryClient } from 'hooks'
import { updateReferralPreferences } from 'services'

interface UpdateVacationSettingsPayload {
  onVacationUntil: string
  scheduledUnavailabilityFrom: string
  scheduledUnavailabilityUntil: string
}

interface UpdateAgentReferralPreferencesPayload {
  acceptsCommercialProperties?: boolean
  acceptsCondominiums?: boolean
  acceptsLandDeals?: boolean
  acceptsManufacturedHomes?: boolean
  acceptsSingleFamilyHomes?: boolean
  acceptsTennancyInCommon?: boolean
  acceptsTownhomes?: boolean
  buyerIntroMessage?: string
  excludedZipCodes?: string[]
  minimumPricea?: string
  prefersBuyer?: boolean
  prefersSeller?: boolean
  requestedZipCodes?: string[]
  selectedZipCodes?: string[]
  sellerIntroMessage?: string
  worksWithBuyers?: boolean
  worksWithSellers?: boolean
}

export const useReferralPreferencesController = (agentId: string) => {
  const queryCache = useQueryClient()

  const updateVacationSettings = useMutation(
    (payload: UpdateVacationSettingsPayload) => updateReferralPreferences(agentId, payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(['agents', agentId])
      }
    }
  )

  const updateAgentReferralPreferences = useMutation(
    (payload: UpdateAgentReferralPreferencesPayload) => updateReferralPreferences(agentId, payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(['agents', agentId])
      }
    }
  )

  return { updateVacationSettings, updateAgentReferralPreferences }
}
