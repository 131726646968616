import { ButtonV2Props } from '../ButtonV2'
import { InlineEditButton } from './InlineEditButton'
import styles from './InlineEditButton.module.scss'

export const InlineChangeButton = ({ children, ...props }: ButtonV2Props) => {
  return (
    <InlineEditButton className={styles.inlineChangeButton} {...props}>
      {children}
    </InlineEditButton>
  )
}
