// @ts-strict
import { useQuery } from 'hooks'
import { CapitalPartner } from 'models'
import { fetchCapitalPartners } from 'services'

export const useCapitalPartners = () => {
  const { data: capitalPartnersData = [], isLoading } = useQuery(
    'capitalPartners',
    fetchCapitalPartners,
    {
      staleTime: Infinity
    }
  )

  const capitalPartners = capitalPartnersData.map(CapitalPartner.create)

  const capitalPartnersOptions = capitalPartners?.map((partner: CapitalPartner) =>
    partner.toOption()
  )

  return {
    capitalPartners,
    capitalPartnersOptions,
    isLoading
  }
}
