export const getSort = <TSortParams extends object>(
  sortParams: TSortParams,
  sortDir?: SortDirection,
  sortBy?: keyof TSortParams,
  withInitialValue = true
) => {
  const dir = sortDir === 'desc' ? '-' : ''

  if (sortBy) {
    return `${dir}${sortParams[sortBy]}`
  }
  if (withInitialValue) {
    return `${dir}${Object.values(sortParams)[0]}`
  }
  return ''
}
