import { Section, Sections } from 'components'
import { FieldFormField } from '../FieldFormField'
import { useFields } from '../useFields'

export const CashOfferPropertyApproved = () => {
  useFields({
    fields: ['ipAdditionalDeposit', 'ipMaxOfferPriceAtHlValuation'],
    required: [
      'incomingPropertyValuation',
      'incomingPropertyFullAddress',
      'incomingPropertyListPrice',
      'depositRate'
    ]
  })
  return (
    <>
      <Section>
        <FieldFormField field="incomingPropertyValuation" />
      </Section>
      <Section>
        <FieldFormField field="ipMaxOfferPriceAtHlValuation" />
      </Section>
      <Section>
        <FieldFormField field="incomingPropertyFullAddress" />
      </Section>
      <Sections>
        <Section>
          <FieldFormField field="incomingPropertyListPrice" />
          <FieldFormField field="depositRate" />
        </Section>
        <Section>
          <FieldFormField field="ipAdditionalDeposit" />
        </Section>
      </Sections>
    </>
  )
}
