import { useFeatureFlags } from 'hooks'
import { bySlug } from '../../utils'
import {
  ccTradeInLeadStagesOptions,
  ccTradeInLeadStagesOptionsWithoutPitched
} from '../lookupLeadStages'
import { SearchFilter } from './SearchFilter'
import { useSearchFilters } from './useSearchFilters'

export const useCCTradeInLeadTaskSearchFilters = () => {
  const { findFilterBySlug } = useSearchFilters()
  const { leftMessageStage } = useFeatureFlags(['left-message-stage'])

  const stageOptions = leftMessageStage.enabled
    ? ccTradeInLeadStagesOptionsWithoutPitched
    : ccTradeInLeadStagesOptions

  const ccTradeInLeadTaskSearchFilters = [
    new SearchFilter({
      slug: 'requiredForStage',
      name: 'Required for Stage',
      options: stageOptions
    })
  ]

  const findCCTradeInLeadTaskSearchFilterBySlug = (slug: string) => {
    return ccTradeInLeadTaskSearchFilters.find(bySlug(slug)) || findFilterBySlug(slug)
  }

  return {
    findFilterBySlug: findCCTradeInLeadTaskSearchFilterBySlug
  }
}
