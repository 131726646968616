import { CCTradeInLead } from 'models'
import { CCTradeInLeadDispositionProvider } from './CCTradeInLeadDispositionContext'
import { CCTradeInLeadDispositionForm } from './CCTradeInLeadDispositionForm'

type Props = {
  lead: CCTradeInLead
  onCancel: () => void
}

export const CCTradeInLeadDisposition = ({ lead, onCancel }: Props) => {
  return (
    <CCTradeInLeadDispositionProvider lead={lead}>
      <CCTradeInLeadDispositionForm lead={lead} onCancel={onCancel} />
    </CCTradeInLeadDispositionProvider>
  )
}
