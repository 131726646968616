import { useNavigate } from 'react-router-dom'
import { ActionButton, Section } from 'components'
import { FormField } from 'components/Form'
import { useFeatureFlags } from 'hooks'
import { Agent } from 'models'
import {
  cx,
  dataOrDash,
  formatBoolean,
  formatDate,
  formatNumber,
  formatOrDash,
  formatPercentage,
  formatPrice
} from 'utils'
import { IssueButtonForm, VoidButtonForm } from '../AgentAgreements'
import { EliteStatusSelect } from '../AgentEliteStatus'
import {
  AgentReferralVisibilityButtonForm,
  visibilityOptionFromAgent
} from '../AgentReferralVisibility'
import { ToggleLeadBoostButtonForm } from '../AgentTeam'
import styles from './AgentStats.module.scss'

type Props = {
  agent: Agent
  className?: string
}

export const AgentStats = ({ agent, className }: Props) => {
  const hasPendingAgreement = !!agent.lastPendingAgreement
  const navigate = useNavigate()

  const {
    salesAppAgentAdminAction: { enabled: adminActionEnabled },
    agentPortalLeadBoost: { value: leadBoost }
  } = useFeatureFlags(['sales-app-agent-admin-action', 'agent-portal-lead-boost'])

  const isLeadBoostVisible =
    (JSON.parse(leadBoost?.toString() || '{}')?.['agentIds'] || []).includes(parseInt(agent.id)) ||
    agent.eliteStatus === 'active'

  const isLeadBoostEnabled = !!agent?.referralPreferences?.leadBoostActive

  const formatVisibleHiddenLabel = (value: any) =>
    formatBoolean(value, { true: 'Visible', false: 'Hidden' })

  const formatEnabledDisabledLabel = (value: any) =>
    formatBoolean(value, { true: 'Enabled', false: 'Disabled' })

  const formatBillingTypeLabel = (value: any) => (value === false ? 'Rev Share' : 'Pay-Per-Lead')

  return (
    <Section title="Statistics" className={cx('agent-stats', className)}>
      <FormField title="In Contract?" inline>
        {formatOrDash(agent.inContract, formatBoolean)}
      </FormField>
      <FormField className={styles.formField} title="Current Billing Type" inline>
        {formatOrDash(agent.pplEnabledAgent, formatBillingTypeLabel)}
      </FormField>
      <FormField className={styles.formField} title="Last Signed Agreement" inline>
        {dataOrDash(agent.lastSignedAgreement?.agreement?.version)},{' '}
        {formatDate(agent.lastSignedAgreement?.signedAt)},{' '}
        {dataOrDash(agent.lastSignedAgreement?.agreement?.commissionSplit)}%
        {adminActionEnabled && !hasPendingAgreement && <IssueButtonForm agent={agent} />}
      </FormField>
      <FormField className={styles.formField} title="Pending Agreement" inline>
        {dataOrDash(agent.lastPendingAgreement?.agreement?.version)},{' '}
        {dataOrDash(agent.lastPendingAgreement?.agreement?.commissionSplit)}%
        {adminActionEnabled && hasPendingAgreement && <VoidButtonForm agent={agent} />}
      </FormField>
      <FormField title="Close Chance" inline>
        {formatPercentage(agent.performanceMetrics?.closeChance)}
      </FormField>
      <FormField title="Deal Prog." inline>
        {formatPercentage(agent.performanceMetrics?.dealProgression)}
      </FormField>
      <FormField title="Responsiveness" inline>
        {formatPercentage(agent.performanceMetrics?.responseTime)}
      </FormField>
      <FormField title="Total / Active" inline>
        {dataOrDash(agent.referralStats?.totalCount)} /{' '}
        {dataOrDash(agent.referralStats?.activeCount)}
      </FormField>
      <FormField title="Intros (w/in 48h)" inline>
        {dataOrDash(agent.referralStats?.inIntroducedCount)}
      </FormField>
      <FormField title="Total Solds" inline>
        {formatNumber(agent.agentMetric?.solds)}
      </FormField>
      <FormField title="Sfhs Solds" inline>
        {formatNumber(agent.agentMetric?.sfhs)}
      </FormField>
      <FormField title="Seller Solds" inline>{`${formatNumber(
        agent.agentMetric?.sellerSolds
      )} (${formatNumber(agent.agentMetric?.sellerPercentSold)}%)`}</FormField>
      <FormField title="Price (List)" inline>{`${formatPrice(
        agent.agentMetric?.sellerAvgPrice
      )} (${formatNumber(agent.agentMetric?.sellerSalesList)}%)`}</FormField>
      <FormField title="Avg. DOM" inline>
        {formatNumber(agent.agentMetric?.sellerDom)}
      </FormField>
      <FormField className={styles.formField} title="Lead Routing" inline>
        {formatOrDash(agent.teamLeader, formatEnabledDisabledLabel)}
        <ActionButton onClick={() => navigate(`/agents/${agent.id}/team`)}>Edit</ActionButton>
      </FormField>
      {agent.teamLeader && (
        <FormField className={styles.formField} title="Lead Boost" inline>
          {formatOrDash(isLeadBoostVisible, formatVisibleHiddenLabel)},{' '}
          {formatOrDash(isLeadBoostEnabled, formatEnabledDisabledLabel)}
          <ToggleLeadBoostButtonForm agent={agent} isLeadBoostVisible={isLeadBoostVisible} />
        </FormField>
      )}
      <FormField title="Elite Status" inline>
        <EliteStatusSelect agentId={agent.id} eliteStatus={agent.eliteStatus} />
      </FormField>
      <FormField className={styles.formField} title="Referrals" inline>
        {visibilityOptionFromAgent(agent).displayText}
        <AgentReferralVisibilityButtonForm agent={agent} />
      </FormField>
    </Section>
  )
}
