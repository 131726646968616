import { Section, Sections } from 'components'
import { FieldFormField } from '../FieldFormField'
import { useFields } from '../useFields'

export const CashOfferOfferAccepted = () => {
  useFields({
    required: ['incomingPropertySalePrice', 'ipContingencyRemovalAt']
  })

  return (
    <Sections title="IR Purchase Transaction">
      <Section>
        <FieldFormField field="incomingPropertySalePrice" />
      </Section>
      <Section>
        <FieldFormField field="ipContingencyRemovalAt" />
      </Section>
    </Sections>
  )
}
