// @ts-strict
import { Menu, MenuLink, MenuSection } from 'components'
import { useSettings } from 'hooks'
import styles from './SiteHeader.module.scss'

const sections = [
  {
    title: 'Agents',
    slug: 'agentHistory'
  },
  {
    title: 'Leads',
    slug: 'leadHistory'
  },
  {
    title: 'Orders',
    slug: 'orderHistory'
  }
] as const

export const Recent = () => {
  const history = useSettings()
  const isNotEmpty = sections.some(({ slug }) => history[slug].length > 0)

  if (!isNotEmpty) {
    return null
  }

  return (
    <Menu shouldUseHover title="Recent" popupAlignment="left" className={styles.dropDownMenu}>
      {sections.map(({ title, slug }) => {
        const data = history[slug]

        return (
          !!data.length && (
            <MenuSection title={title} key={slug}>
              {data.map(link => (
                <MenuLink
                  key={link.pathname}
                  to={link.pathname}
                  className={styles.dropDownMenuItem}
                >
                  {link.name}
                </MenuLink>
              ))}
            </MenuSection>
          )
        )
      })}
    </Menu>
  )
}
