// @ts-strict
import { Section, Sections } from 'components'
import { AgentSection } from 'content/Agent/AgentSection'
import { useHLSimpleSaleLeadController } from 'controllers'
import { HLSSLeadDetailsProps } from '../HLSSLeadDetails'
import styles from './HLSSLeadDetails.module.scss'

type Props = Pick<HLSSLeadDetailsProps, 'lead'>

export const DRAgentInformationSection = ({ lead }: Props) => {
  const hlSimpleSaleLead = lead.hlSimpleSaleLead
  const { updateHLSimpleSaleLead } = useHLSimpleSaleLeadController(hlSimpleSaleLead!.id)

  const sellerAgent = hlSimpleSaleLead?.departingPropertySellerAgent

  return (
    <Sections title="Agent Information" className={styles.halfSection}>
      <Section>
        <AgentSection
          title="DR Agent"
          agent={sellerAgent}
          program="hlss"
          onBlur={val =>
            updateHLSimpleSaleLead.mutate({
              departingPropertySellerAgentId: val
            })
          }
        />
      </Section>
    </Sections>
  )
}
