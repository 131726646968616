import { Icon } from 'components/Icon'
import { useDialerContext } from 'contexts/Dialer'
import { titleize } from 'utils'
import styles from '../Dialer.module.scss'
import { DialerCallButton } from '../DialerCallButton'
import { DialerKeyPad } from './DialerKeyPad'

export const DialerExpanded = () => {
  const { isOnCall, callDuration, activeDialer, toggleExpanded } = useDialerContext()

  return (
    <>
      <button type="button" className={styles.collapseButton} onClick={toggleExpanded}>
        <Icon.DownArrow />
      </button>
      <div className={styles.container}>
        <div className={styles.phoneNumber}>{activeDialer.name}</div>
        <div className={styles.phoneName}>{activeDialer.phoneNumber}</div>
        <div className={styles.phoneType}>{`(${titleize(activeDialer.phoneType)})`}</div>
        <div className={styles.timer}>{isOnCall && callDuration ? callDuration : ''}</div>
        <DialerKeyPad />
        <DialerCallButton />
      </div>
    </>
  )
}
