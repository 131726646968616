import { useQuery } from 'hooks'
import { fetchInspections } from 'services'
import type { FetchInspectionsProps } from 'services'
import { INSPECTIONS_CACHE_KEY } from '../cache'

export const useInspectionsController = (settings: FetchInspectionsProps) => {
  const inspectionsQuery = useQuery([INSPECTIONS_CACHE_KEY, settings], () =>
    fetchInspections({ ...settings, withActiveInspectionOrder: true })
  )

  return { inspectionsQuery }
}
