// @ts-strict
import {
  FormField,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  InlineEditIntegerInput,
  InlineEditPercentageInput,
  InlineEditSelect,
  Section,
  Sections
} from 'components'
import { useLeadContext } from 'contexts'
import { useHLSimpleSaleLeadController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import { useCapitalPartners } from 'lookups'
import { formatDate, formatMoney, formatNumber, getPercentageValue } from 'utils'
import { HLSSLeadDetailsProps } from '../HLSSLeadDetails'
import { getFieldValues } from '../utils'
import { getLeadInputProps } from './utils'

type TProps = Pick<HLSSLeadDetailsProps, 'lead' | 'updateLead'>

export const DRPurchaseTransaction = ({ lead, updateLead }: TProps) => {
  const { salesAppRevenueRecognition } = useFeatureFlags(['sales-app-revenue-recognition'])
  const providerLead = lead.hlSimpleSaleProviderLead!
  const hlSimpleSaleLead = lead.hlSimpleSaleLead!
  const { updateHLSimpleSaleLead } = useHLSimpleSaleLeadController(hlSimpleSaleLead!.id)

  const capitalPartner = providerLead.providerLeadCapitalPartner?.capitalPartner

  const { capitalPartnersOptions } = useCapitalPartners()
  const { updateCapitalPartner } = useLeadContext()

  const { getProps: getLeadProps } = getLeadInputProps(lead, updateLead)
  const { getFieldValue, getFieldPercentageValue } = getFieldValues(hlSimpleSaleLead)

  return (
    <Sections title="DR Purchase Transaction">
      <Section>
        <FormField title="Deal Valuation" inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpDealValuationPrice: val })}
            value={getFieldValue('dpDealValuationPrice')}
          />
        </FormField>
        <FormField title="Deal Valuation %" inline>
          <InlineEditPercentageInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ dpDealValuationPercentage: getPercentageValue(val) })
            }
            value={getFieldPercentageValue('dpDealValuationPercentage')}
          />
        </FormField>
        <FormField title="Deal Rounding" inline>
          <InlineEditDollarsAndCentsInput
            allowNegative
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpDealValuationPriceRounding: val })}
            value={getFieldValue('dpDealValuationPriceRounding')}
          />
        </FormField>
        <FormField title="Term Offer Sheet" inline>
          {formatMoney(hlSimpleSaleLead.dpTermSheetOffer)}
        </FormField>
        <FormField title="Inspection Adjustment" inline hint="Negative values allowed">
          <InlineEditDollarsAndCentsInput
            allowNegative
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpInspectionAdjustment: val })}
            value={getFieldValue('dpInspectionAdjustment')}
          />
        </FormField>
        <FormField title="HL Committee Adjustment" inline hint="Negative values allowed">
          <InlineEditDollarsAndCentsInput
            allowNegative
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpHlCommitteeAdjustment: val })}
            value={getFieldValue('dpHlCommitteeAdjustment')}
          />
        </FormField>
        <FormField title="Purchase Price" inline>
          {formatMoney(hlSimpleSaleLead.departingPropertyGuaranteedPrice)}
        </FormField>
        <FormField title="Total Estimated Costs of Buying" inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpTotalEstimatedCostsOfBuying: val })}
            value={getFieldValue('dpTotalEstimatedCostsOfBuying')}
          />
        </FormField>
        <FormField title="Total Estimated Costs of Financing" inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpTotalEstimatedCostsOfFinancing: val })}
            value={getFieldValue('dpTotalEstimatedCostsOfFinancing')}
          />
        </FormField>
        <FormField title="Total Estimated Costs of Selling" inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpTotalEstimatedCostsOfSelling: val })}
            value={getFieldValue('dpTotalEstimatedCostsOfSelling')}
          />
        </FormField>
        <FormField title="Buyer Agent Fees %" inline>
          <InlineEditPercentageInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ dpBuyerAgentFee: getPercentageValue(val) })
            }
            value={getFieldPercentageValue('dpBuyerAgentFee')}
          />
        </FormField>
        <FormField title="Open Escrow Date" inline>
          <InlineEditDateInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyOpenedPurchasedEscrowDate: val })
            }
            value={getFieldValue('departingPropertyOpenedPurchasedEscrowDate')}
          />
        </FormField>
      </Section>
      <Section>
        <FormField title="Target COE Date" inline>
          <InlineEditDateInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpTargetPurchaseCoeDate: val })}
            value={getFieldValue('dpTargetPurchaseCoeDate')}
          />
        </FormField>
        <FormField title="Actual COE Date" inline>
          <InlineEditDateInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyCloseOfPurchaseEscrowDate: val })
            }
            value={getFieldValue('departingPropertyCloseOfPurchaseEscrowDate')}
          />
        </FormField>
        <FormField title="Seller Vacate Date" inline>
          {formatDate(hlSimpleSaleLead.dpSellerVacateDate)}
        </FormField>
        <FormField title="Capital Markets Facility" inline>
          <InlineEditSelect
            options={capitalPartnersOptions}
            value={capitalPartner?.id}
            onChange={value => updateCapitalPartner({ providerLead, capitalPartnerId: value })}
          />
        </FormField>
        <FormField title="Funding Amount" inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpFundingAmount: val })}
            value={getFieldValue('dpFundingAmount')}
          />
        </FormField>
        <FormField title="Funding Amount - Borrowing Base" inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpFundingAmountBorrowingBase: val })}
            value={getFieldValue('dpFundingAmountBorrowingBase')}
          />
        </FormField>
        <FormField title="Funding Amount - HL Equity" inline>
          {formatMoney(hlSimpleSaleLead.dpFundingAmountHlEquity)}
        </FormField>
        <FormField title="Actual Funding Date" inline>
          <InlineEditDateInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpActualPurchaseFundedDate: val })}
            value={getFieldValue('dpActualPurchaseFundedDate')}
          />
        </FormField>
        {/* this field must be removed after removing the feature flag */}
        {!salesAppRevenueRecognition.enabled && (
          <FormField title="Waterfall Funds Received Date" inline>
            <InlineEditDateInput {...getLeadProps('revenueRecognitionDate')} />
          </FormField>
        )}
        <FormField title="Days of Investor Capital Outstanding" inline>
          {salesAppRevenueRecognition.enabled ? (
            // keep this when removing the feature flag
            <InlineEditIntegerInput
              onBlur={val => updateHLSimpleSaleLead.mutate({ dpCapitalDaysOutstanding: val })}
              value={getFieldValue('dpCapitalDaysOutstanding')}
            />
          ) : (
            formatNumber(hlSimpleSaleLead?.dpCapitalDaysOutstanding)
          )}
        </FormField>
      </Section>
    </Sections>
  )
}
