import { asArray } from '../asArray'
import { presence } from '../presence'
import { removeAttributesWithNullValues } from '../removeAttributesWithNullValues'
import { titleize } from '../titleize'
import { getIsValid, getValidationErrorMessages, TValidation, TValue } from './validate'

type Payload = Record<string, TValue>

type TComplexFormValidation = {
  // only validate this key if validations pass for `requirements`
  enabled?: boolean
  label?: string
  requirements?: TFormValidation
  validations: TValidation[]
}
export type TFormValidation = Record<string, TValidation | TValidation[] | TComplexFormValidation>

export type TFormErrorMessages = Record<string, string[]>

const isComplexFormValidation = (
  field: TValidation | TValidation[] | TComplexFormValidation
): field is TComplexFormValidation => {
  return (field as TComplexFormValidation)?.validations !== undefined
}

export const getFormIsValid = (payload: Payload, formValidations?: TFormValidation): boolean => {
  if (formValidations) {
    return Object.keys(formValidations).every(key => {
      const currentField = formValidations[key]
      const payloadValue = payload[key]

      const label = isComplexFormValidation(currentField) ? currentField.label : titleize(key)
      const validations = isComplexFormValidation(currentField)
        ? currentField.validations
        : asArray(currentField)

      // check if it's a TValidation or TValidation[]:
      if (!isComplexFormValidation(currentField)) {
        return getIsValid(payloadValue, validations, label)
      }

      if (currentField.enabled === false) {
        return true
      }

      // it's a TComplexFormValidation:
      // check if this validation's requirements are met
      // if not, don't run this validation
      const { requirements } = currentField

      if (requirements) {
        const requirementsMet = getFormIsValid(payload, requirements)
        if (!requirementsMet) {
          return true
        }
      }
      return getIsValid(payloadValue, validations, label)
    })
  }

  return true
}

export const getFormErrorMessages = (
  payload: Payload,
  formValidations: TFormValidation
): TFormErrorMessages => {
  return Object.keys(payload).reduce((result, key) => {
    if (!formValidations?.[key]) {
      return result
    }

    const currentField = formValidations[key]
    const label = isComplexFormValidation(currentField) ? currentField.label : titleize(key)
    const validations = isComplexFormValidation(currentField)
      ? currentField.validations
      : asArray(currentField)

    const value = payload[key]

    if (presence(value) || typeof value === 'boolean') {
      const itemMessages = getValidationErrorMessages(value, validations as TValidation[], label)

      result[key] = presence(itemMessages)
    }

    return removeAttributesWithNullValues(result)
  }, {})
}
