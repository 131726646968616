import { parseAddress } from 'utils'
import { TEscrowOrderPayload, TInstantOrderPayload } from './types'

export const isCA = (fullAddress: string): boolean => {
  return ['california', 'ca'].includes(parseAddress(fullAddress)?.state?.toLowerCase())
}

export const parseUnit = (unitString: string): string => {
  const unitParts = unitString.split(' ')

  const unitNum = unitParts[unitParts.length - 1]

  return unitNum ? `#${unitNum}` : ''
}

export const parseSubmitAddress = (payload: TInstantOrderPayload | TEscrowOrderPayload): string => {
  const addressInfo = parseAddress(payload.address)

  return `${addressInfo.street_address1}${
    payload.secondaryAddress ? `, ${parseUnit(payload.secondaryAddress)}` : ''
  }, ${addressInfo.city}, ${addressInfo.state}, ${addressInfo.postal_code}`
}
