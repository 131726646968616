// @ts-strict
import { useState } from 'react'
import { Tooltip } from '@foundation/components'
import {
  ChangeReasonButtonFormDate,
  FormField,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  InlineEditIntegerInput,
  InlineEditPercentageInput,
  InlineEditSelect,
  Section,
  Sections
} from 'components'
import { LeadSensitiveInfoModal } from 'content/Lead/LeadSensitiveInfoModal'
import { useLeadContext } from 'contexts'
import { useTradeInLeadController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import {
  getCapitalPartnerFieldName,
  useCapitalPartners,
  useCcTradeInLeadFields,
  useChangeReasons
} from 'lookups'
import { formatDate, formatMoney, formatNumber, formatPercentage } from 'utils'
import { CCTradeInLeadDetailsProps } from '../CCTradeInLeadDetails'
import { getLeadInputProps, getTradeInLeadInputProps } from './utils'

type Props = Pick<CCTradeInLeadDetailsProps, 'lead' | 'updateLead' | 'updateTradeInLead'>

export const DRPurchaseTransaction = ({ lead, updateLead, updateTradeInLead }: Props) => {
  const [showFeePercentageField, setShowFeePercentageField] = useState(false)
  const { salesAppRevenueRecognition, salesAppImprovement } = useFeatureFlags([
    'sales-app-revenue-recognition',
    'sales-app-improvement'
  ])
  const providerLead = lead.tradeInProviderLead
  const tradeInLead = providerLead?.tradeInLead
  const capitalPartner = providerLead?.providerLeadCapitalPartner?.capitalPartner
  const { updateTradeInLead: newUpdateTradeInLead } = useTradeInLeadController(tradeInLead?.id)
  const { coeDateChangeReasonOptions } = useChangeReasons()
  const { getCcTradeInLeadFieldName } = useCcTradeInLeadFields()
  const { capitalPartnersOptions } = useCapitalPartners()
  const { updateCapitalPartner } = useLeadContext()

  const { getProps, handleUpdateTradeInLead } = getTradeInLeadInputProps(
    tradeInLead,
    updateTradeInLead
  )
  const { getProps: getLeadProps } = getLeadInputProps(lead, updateLead)

  const [openSensitiveFields, setSensitiveFields] = useState({
    departingPropertyValuation: false
  })

  const toggleIsSensitiveInfoOpen = (field: keyof typeof openSensitiveFields, open?: boolean) =>
    setSensitiveFields({
      ...openSensitiveFields,
      [field]: open !== undefined ? open : !openSensitiveFields[field]
    })

  const renderFeePercentageField = () => {
    if (showFeePercentageField) {
      return (
        <FormField title="Specify Fee percentage" inline>
          <InlineEditPercentageInput {...getProps('dpTargetTradeInFeeRate')} />
        </FormField>
      )
    }

    const options = [
      { value: '0.024', name: '2.4% - Using HL Services' },
      { value: 'other', name: 'Other' }
    ]

    if (
      tradeInLead?.dpTargetTradeInFeeRate !== undefined &&
      tradeInLead?.dpTargetTradeInFeeRate !== 0.024
    ) {
      options.unshift({
        value: `${tradeInLead?.dpTargetTradeInFeeRate}`,
        name: `${formatPercentage(tradeInLead?.dpTargetTradeInFeeRate)} - Custom Program Fee`
      })
    }

    return (
      <FormField title="Expected Program Fee" inline>
        <InlineEditSelect
          options={options}
          value={tradeInLead?.dpTargetTradeInFeeRate}
          onChange={value => {
            if (value === 'other') {
              setShowFeePercentageField(true)
              return
            }
            handleUpdateTradeInLead('dpTargetTradeInFeeRate', value)
          }}
        />
      </FormField>
    )
  }

  if (!tradeInLead) {
    return null
  }

  const backupOfferPriceValue = formatMoney(
    Number(tradeInLead.departingPropertyValuation) -
      Number(tradeInLead.departingPropertyGuaranteedPrice)
  )

  return (
    <Sections title="DR Purchase Transaction">
      <Section>
        <LeadSensitiveInfoModal
          title="HL Valuation - Loan Payoff Value"
          value={backupOfferPriceValue}
          isOpen={openSensitiveFields.departingPropertyValuation}
          setOpen={(open: boolean) => toggleIsSensitiveInfoOpen('departingPropertyValuation', open)}
        />
        <Tooltip content="This field contains sensitive data. Click the View icon at the right to view or edit.">
          <span>
            <FormField
              title="HL Valuation - Loan Payoff Value"
              inline
              onClickViewSensitiveData={() =>
                toggleIsSensitiveInfoOpen('departingPropertyValuation')
              }
            >
              {backupOfferPriceValue}
            </FormField>
          </span>
        </Tooltip>

        <FormField title={getCcTradeInLeadFieldName('dpTargetTotalCosts')} inline>
          {formatMoney(tradeInLead.dpTargetTotalCosts)}
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpActualNewHomePurchaseEquityHl')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpActualNewHomePurchaseEquityHl')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpActualLoanPayoff')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpActualLoanPayoff')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('actualLoanAmount')} inline>
          {formatMoney(tradeInLead.actualLoanAmount)}
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpActualSolarLoanBalance')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpActualSolarLoanBalance')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpActualClosingCosts')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpActualClosingCosts')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpActualOtherExpenses')} inline>
          {formatMoney(tradeInLead.dpActualOtherExpenses)}
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpActualInspection')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpActualInspection')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpActualHoaDocumentFees')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpActualHoaDocumentFees')} />
        </FormField>
        {salesAppImprovement.enabled && renderFeePercentageField()}
      </Section>
      <Section>
        <FormField title={getCapitalPartnerFieldName('facility')} inline>
          <InlineEditSelect
            options={capitalPartnersOptions}
            value={capitalPartner?.id}
            onChange={value => updateCapitalPartner({ providerLead, capitalPartnerId: value })}
          />
        </FormField>
        <FormField title={getCapitalPartnerFieldName('fundingFacility')} inline>
          {capitalPartner?.fundingFacility}
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpFundingAmount')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpFundingAmount')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpBorrowingBase')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpBorrowingBase')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpHlEquity')} inline>
          {formatMoney(tradeInLead.dpHlEquity)}
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpActualPurchaseFundedDate')} inline>
          <InlineEditDateInput {...getProps('dpActualPurchaseFundedDate')} />
        </FormField>
        {/* this field must be removed after removing the feature flag */}
        {!salesAppRevenueRecognition.enabled && (
          <FormField title={getCcTradeInLeadFieldName('revenueRecognitionDate')} inline>
            <InlineEditDateInput {...getLeadProps('revenueRecognitionDate')} />
          </FormField>
        )}
        <FormField title={getCcTradeInLeadFieldName('dpCapitalDaysOutstanding')} inline>
          {salesAppRevenueRecognition.enabled ? (
            // keep this when removing the feature flag
            <InlineEditIntegerInput {...getProps('dpCapitalDaysOutstanding')} />
          ) : (
            formatNumber(tradeInLead.dpCapitalDaysOutstanding)
          )}
        </FormField>
        <FormField
          title={getCcTradeInLeadFieldName('departingPropertyOpenedPurchasedEscrowAt')}
          inline
        >
          <InlineEditDateInput {...getProps('departingPropertyOpenedPurchasedEscrowAt')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpTargetPurchaseCoeDate')} inline>
          <InlineEditDateInput
            {...getProps('dpTargetPurchaseCoeDate')}
            readOnly={salesAppImprovement.enabled}
          />
        </FormField>
        <FormField
          title={getCcTradeInLeadFieldName('departingPropertyCloseOfPurchaseEscrowAt')}
          inline
        >
          {!!tradeInLead.departingPropertyCloseOfPurchaseEscrowAt ? (
            <ChangeReasonButtonFormDate
              fieldName="departingPropertyCloseOfPurchaseEscrowAt"
              mutation={newUpdateTradeInLead}
              reasons={coeDateChangeReasonOptions}
              title={getCcTradeInLeadFieldName('departingPropertyCloseOfPurchaseEscrowAt')}
              value={tradeInLead.departingPropertyCloseOfPurchaseEscrowAt}
            />
          ) : (
            <InlineEditDateInput {...getProps('departingPropertyCloseOfPurchaseEscrowAt')} />
          )}
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpSellerVacateDate')} inline>
          {formatDate(tradeInLead.dpSellerVacateDate)}
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpDaysSellerInPossession')} inline>
          <InlineEditIntegerInput {...getProps('dpDaysSellerInPossession')} />
        </FormField>
      </Section>
    </Sections>
  )
}
