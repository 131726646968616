import React from 'react'
import { SearchConsole, SearchInputParams } from 'components'
import { ListingsListStatus } from './ListingsListStatus'

export const ListingsListHeader = () => {
  return (
    <>
      <ListingsListStatus />
      <SearchConsole search={<SearchInputParams placeholder="Search by address" />} />
    </>
  )
}
