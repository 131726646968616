import { LeadResponse } from 'schema'
import { getLead } from '.'

export const getParentLead = (lead: LeadResponse) => {
  if (!lead.parentLead) {
    return
  }

  return getLead(lead.parentLead)
}
