import { ServicesOpportunityStatus } from 'models'
import { getConstantsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchServicesOpportunityStatuses = async (): Promise<ServicesOpportunityStatus[]> => {
  const response = await getRequest(
    getConstantsUrl({
      searchParams: {
        types: 'services_opportunity_statuses'
      }
    })
  )
  const servicesOpportunityStatuses = await deserializeAsync(response.data, {
    transform: ServicesOpportunityStatus.create
  })
  return servicesOpportunityStatuses
}
