// @ts-strict

import type { InspectionResponse } from 'schema'
import { formatDate, formatMoney, titleize } from 'utils'
import { Model } from '../Model'
import type { ModelAttrKeys } from '../Model'
import { InspectionOrder } from './InspectionOrder'
import { InspectionReport } from './InspectionReport'

type Attributes = Partial<Omit<Inspection, ModelAttrKeys>>
type Response = Potential<Partial<InspectionResponse>>

export class Inspection extends Model {
  modelName = 'Inspection'
  businessName = ''
  createdAt = ''
  externalId = ''
  id = ''
  order: InspectionOrder = new InspectionOrder()
  orderId = ''
  price = ''
  report: InspectionReport = new InspectionReport()
  reportURL: string = ''
  reportFile: string = ''
  scheduledAt = ''
  status: SlugOption<InspectionResponse['status']> = { name: '', value: '' }
  type: SlugOption<InspectionResponse['inspectionType']> = { name: '', value: '' }
  updatedAt = ''

  constructor(attributes?: Attributes) {
    super()
    Object.assign(this, attributes)
  }

  static create(response: Response): Inspection {
    const order = InspectionOrder.create(response?.inspectionOrder)
    const report = InspectionReport.create(response?.activeInspectionReports?.[0])

    return new Inspection({
      businessName: response?.businessName ?? '',
      createdAt: response?.createdAt ? formatDate(response?.createdAt) : '',
      externalId: response?.externalId ?? '',
      id: response?.id ?? '',
      order,
      orderId: order.id,
      price: response?.price ? formatMoney(response?.price) : '',
      report,
      reportURL: report.webUrl,
      reportFile: report.fileId,
      scheduledAt: response?.scheduledAt ? formatDate(response?.scheduledAt) : '',
      status: response?.status
        ? { name: titleize(response.status), value: response.status }
        : undefined,
      type: response?.inspectionType
        ? { name: titleize(response.inspectionType), value: response.inspectionType }
        : undefined,
      updatedAt: response?.updatedAt ? formatDate(response?.updatedAt) : ''
    })
  }
}
