import { useState } from 'react'
import { Option } from 'classes'
import {
  FormField,
  InlineEditDateInput,
  InlineEditSelect,
  Section,
  Sections,
  Select
} from 'components'
import { AgentSection } from 'content/Agent/AgentSection'
import { CCTradeInLeadCallout } from 'content/CCTradeInLead'
import { useFeatureFlags } from 'hooks'
import {
  getCcTradeInLeadStageName,
  getLeadFieldName,
  getOrderFieldName,
  sourcesOptions,
  TCcTradeInLeadStagesSlug,
  trueFalseOptions,
  useCcTradeInLeadFields,
  useFailedReasons
} from 'lookups'
import { dataOrDash, titleize } from 'utils'
import { CCTradeInLeadDetailsProps } from '../CCTradeInLeadDetails'
import { getOrderInputProps, getTradeInLeadInputProps } from './utils'

type Props = Pick<
  CCTradeInLeadDetailsProps,
  'lead' | 'updateLead' | 'updateOrder' | 'updateTradeInLead'
>

type ApprovalType = 'express' | 'traditional'
const Action = ({ stage, tradeInLead, updateTradeInLead }) => {
  const [approvalType, setApprovalType] = useState<ApprovalType>(
    tradeInLead?.approvalType ?? 'express'
  )
  const disabled = (stage ?? '') === 'conditionally_approved'
  const options: Option<ApprovalType>[] = [
    new Option({ name: 'Express Approval', value: 'express' }),
    new Option({ name: 'Traditional', value: 'traditional' })
  ]
  const onChange = (val: ApprovalType) => {
    setApprovalType(val)
    updateTradeInLead.mutate({ id: tradeInLead.id, payload: { approvalType: val } })
  }

  return (
    <div className="approval-type-wrapper">
      <Select
        id="approval-type"
        disabled={disabled}
        options={options}
        value={approvalType}
        onChange={onChange}
      />
    </div>
  )
}

export const TradeInLeadDetailsSection = ({
  lead,
  updateLead,
  updateOrder,
  updateTradeInLead
}: Props) => {
  const tradeInLead = lead?.tradeInProviderLead?.tradeInLead
  const referringAgent = lead.referringAgent
  const { tradeInFailedReasonOptions: failedReasons } = useFailedReasons()
  const { getCcTradeInLeadFieldName } = useCcTradeInLeadFields()
  const { getProps: getOrderProps } = getOrderInputProps(lead.order!, updateOrder)
  const { getProps: getLeadProps } = getTradeInLeadInputProps(tradeInLead, updateTradeInLead)
  const { expressApproval } = useFeatureFlags(['express-approval'])

  const showReferringAgent =
    lead.order?.source === 'accept' ||
    lead.order?.source === 'agent' ||
    lead.order?.source === 'disclosures'

  const failedReason = failedReasons.find(failedReason => {
    return failedReason.value === lead?.tradeInProviderLead?.reasonForFail
  })

  const stageName = getCcTradeInLeadStageName(lead.stage as TCcTradeInLeadStagesSlug)
  const actions = (
    <Action stage={lead.stage} tradeInLead={tradeInLead} updateTradeInLead={updateTradeInLead} />
  )

  return (
    <>
      <Sections
        title={`${lead.isHLSS ? 'HLSS' : 'Buy Before You Sell (legacy TI)'} (${
          stageName || titleize(lead.stage)
        })`}
        actions={expressApproval && actions}
      >
        <Section>
          <Section>
            <FormField title={getOrderFieldName('createdAt')} inline>
              <InlineEditDateInput {...getOrderProps('createdAt')} />
            </FormField>
            <FormField title={getLeadFieldName('leadUsersNamesString')} inline>
              {dataOrDash(lead.leadUsersNamesString)}
            </FormField>
            <FormField title={getOrderFieldName('source')} inline>
              <InlineEditSelect {...getOrderProps('source')} options={sourcesOptions} />
            </FormField>
          </Section>

          {showReferringAgent && (
            <AgentSection
              agent={referringAgent}
              program={lead.isHLSS ? 'hlss' : 'trade_in'}
              onBlur={val =>
                updateLead({
                  referringOwnerId: val,
                  referringOwnerType: 'Agent'
                })
              }
            />
          )}

          <Section>
            <FormField title={getCcTradeInLeadFieldName('twoCheckHlSimpleSale')} inline>
              <InlineEditSelect
                {...getLeadProps('twoCheckHlSimpleSale')}
                options={trueFalseOptions}
                addBlank={false}
              />
            </FormField>
            <FormField title={getCcTradeInLeadFieldName('tradeInPlus')} inline>
              <InlineEditSelect
                {...getLeadProps('tradeInPlus')}
                options={trueFalseOptions}
                addBlank={false}
              />
            </FormField>
            {lead.isFailed && (
              <FormField title={getCcTradeInLeadFieldName('reasonForFail')} inline>
                {dataOrDash(failedReason?.name)}
              </FormField>
            )}
          </Section>
        </Section>
        <Section>
          <CCTradeInLeadCallout lead={lead} />
        </Section>
      </Sections>
    </>
  )
}
