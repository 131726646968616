// @ts-strict

import { List, Sections } from 'components'
import styles from './ListStringValues.module.scss'

type TProps = {
  className?: string
  items?: string[]
  title?: string
}

export const ListStringValues = ({ title, items = [], className }: TProps) => {
  return (
    <Sections title={title} className={className}>
      <div className={styles.sectionsContent}>
        <List className={styles.list}>
          {items.map(item => (
            <div key={item} className={styles.listItem}>
              {item}
            </div>
          ))}
        </List>
      </div>
    </Sections>
  )
}
