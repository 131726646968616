import { InvestorLeadCard } from 'content/InvestorLead/InvestorLeadCard'
import { InvestorLead } from 'models'

export const InvestorLeadsStack = ({ investorLeads }: { investorLeads: InvestorLead[] }) => {
  if (investorLeads && investorLeads.length) {
    return (
      <>
        {investorLeads.map(investorLead => {
          return <InvestorLeadCard investorLead={investorLead} key={investorLead.id} />
        })}
      </>
    )
  }
  return <div>None Found</div>
}
