import { Section } from 'components'
import { FieldFormField } from '../FieldFormField'
import { useFields } from '../useFields'

export const CashOfferAgreementSigned = () => {
  useFields({
    required: ['incomingPropertyMaxOfferPrice']
  })

  return (
    <Section>
      <FieldFormField field="incomingPropertyMaxOfferPrice" />
    </Section>
  )
}
