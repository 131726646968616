import { SearchParams } from '@types'
import { TMarketplaceProgramSlug } from 'lookups'
import { presence, removeAttributesWithNullValues } from 'utils'

type DeafultSearchParams = Pick<
  SearchParams,
  'page' | 'perPage' | 'searchTerm' | 'sortBy' | 'sortDir'
>

export type SearchParamsSettings = Partial<{
  aseId: string
  asmId: string
  assigneeId: string
  marketplacePrograms: TMarketplaceProgramSlug[]
  usStateId: string
}> &
  DeafultSearchParams

type SortParams = Pick<SearchParams, 'sortBy' | 'sortDir'>

const sortParams = {
  AreaName: 'assigned_area.name'
}

const getFilters = ({
  aseId,
  asmId,
  assigneeId,
  marketplacePrograms,
  searchTerm,
  usStateId
}: SearchParamsSettings) => {
  const filters = {}

  if (aseId) {
    filters['filter[services_user_id]'] = aseId
  }

  if (asmId) {
    filters['filter[user_id]'] = asmId
  }

  if (searchTerm?.length) {
    filters['filter[_search]'] = searchTerm
  }

  if (assigneeId) {
    filters['filter[user_id]'] = assigneeId
  }

  if (marketplacePrograms) {
    filters['filter[marketplace_program.slug]'] = marketplacePrograms
  }

  if (usStateId) {
    filters['filter[_state_id]'] = usStateId
  }

  return removeAttributesWithNullValues(filters)
}

const getSort = ({ sortBy, sortDir }: SortParams) => {
  if (sortBy) {
    const dir = sortDir === 'desc' ? '-' : ''
    return `${dir}${sortParams[sortBy]}`
  }

  return sortParams['AreaName']
}

export const getSearchParams = ({
  page,
  perPage,
  sortBy,
  sortDir,
  ...filters
}: SearchParamsSettings = {}) => {
  return {
    'page[number]': presence(page),
    'page[size]': presence(perPage),
    sort: getSort({ sortBy, sortDir }),
    ...getFilters(filters)
  }
}
