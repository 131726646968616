// @ts-strict
import { SearchFilter } from 'lookups/lookupSearchFilters'
import { asArray } from 'utils'
import { cx } from 'utils'
import styles from './SearchStatusFilter.module.scss'
import { SearchStatusFilterValue } from './SearchStatusFilterValue'

type Tprops = {
  filter: SearchFilter
  value: string | string[]
}

export const SearchStatusFilter = ({ filter, value }: Tprops) => {
  const valueArray = asArray(value)

  return (
    <span className={cx(styles.searchFilter)}>
      {`${filter.name}: `}
      {valueArray.map((val, index) => (
        <SearchStatusFilterValue filter={filter} paramValue={val} key={`${filter.name}-${val}`} />
      ))}
    </span>
  )
}
