// @ts-strict
import { LeadGroupableResponse } from 'schema'
import { LeadGroupableDefinition, LeadGroupableDefinitionType } from '../'
import { Model } from '../Model'

export type LeadGroupableType = 'personas'

export const LeadGroupableDefinitionTypeMap: Record<string, LeadGroupableDefinitionType> = {
  personas: 'persona_definitions'
}

export class LeadGroupable extends Model {
  active?: boolean
  id?: string
  name?: string
  priority?: number
  definitions?: LeadGroupableDefinition[]

  constructor(leadGroupableAttributes: LeadGroupableResponse) {
    super()
    Object.assign(this, leadGroupableAttributes)
  }

  static create(leadGroupableResponse: Potential<LeadGroupableResponse>): Potential<LeadGroupable> {
    if (!leadGroupableResponse) {
      return
    }

    return new LeadGroupable(leadGroupableResponse)
  }
}
