import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { ConsoleAside } from 'components'
import { Agent, AgentLead } from 'models'
import { cx } from 'utils'
import { Icon } from '../Icon'
import cardStyles from './Card.module.scss'
import hiddenStyles from './HiddenAgent.module.scss'

type Props = {
  agent: Agent
  agentLead?: AgentLead
  children?: ReactNode
}

export const HiddenAgentWrapper = ({ agent, agentLead, children }: Props) => {
  const agentName = agent.investor ? agent.investor.name : agent.fullName

  return (
    <div className={cx(cardStyles.card, hiddenStyles.hiddenWrapper)}>
      <div className={hiddenStyles.hiddenAgentHeader}>
        <Icon.EyeSlash />
        Profile is hidden
      </div>
      <ConsoleAside className={cx(cardStyles.summary, hiddenStyles.whiteAside)}>
        {children}
      </ConsoleAside>
      <div className={hiddenStyles.profileButtonWrapper}>
        <Link to={`/agents/${agent.id}`} title={agentName}>
          <div className={hiddenStyles.profileButton}>Go to agent profile</div>
        </Link>
      </div>
    </div>
  )
}
