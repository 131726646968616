// @ts-strict
import { DollarsInput, DollarsInputProps } from 'components/Form'
import { formatDollars } from 'utils'
import { InlineEditTextInput, InlineEditTextInputProps } from '../InlineEditTextInput'

export const InlineEditDollarsInput = (props: InlineEditTextInputProps & DollarsInputProps) => {
  return (
    <InlineEditTextInput
      {...props}
      render={DollarsInput}
      format={formatDollars}
      value={isNaN(Number(props.value)) ? undefined : props.value}
    />
  )
}
