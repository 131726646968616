import { Strategy } from '@floating-ui/react'
import { css, styled, Theming } from '@foundation/themes/styles'
import { TextProps } from '../Text'
import { SelectProps } from './types'

type DropdownProps = {
  left: number | null
  position: Strategy
  top: number | null
}

type OptionProps = {
  disabled: boolean
  isFocused: boolean
}

type OptionCheckProps = {
  isSelected: boolean
}

type TriggerProps = {
  $size?: SelectProps['size']
  as?: 'button' | 'input'
  hasError: boolean
  isDisabled?: boolean
  isOpen: boolean
}

type ErrorMessageProps = {
  hasError: boolean
}

const focusedFieldStyle = (theme: Theming) => `
  box-shadow: inset 0 0 0 ${theme.borderWidths.thick} ${theme.colors.primary5};
  transition: all 0.3s ease-in-out;
`

const fieldWithErrorStyle = (theme: Theming) => `
  box-shadow: inset 0 0 0 ${theme.borderWidths.thin} ${theme.colors.danger5};
  transition: all 0.3s ease-in-out;
`

const fieldWithDisabledStyle = (theme: Theming) => `
  color: ${theme.colors.neutral4};
  cursor: not-allowed;
  box-shadow: inset 0 0 0 ${theme.borderWidths.thin} ${theme.colors.neutral4};

  &:focus,
    &:active,
    &:hover {
      box-shadow: inset 0 0 0 ${theme.borderWidths.thin} ${theme.colors.neutral4};
    }
`

export const selectTextSize: Record<NonNullable<SelectProps['size']>, TextProps['size']> = {
  xs: 'xs',
  lg: 'sm'
}

export const selectOptionTextSize: Record<NonNullable<SelectProps['size']>, TextProps['size']> = {
  xs: 'sm',
  lg: 'base'
}

export const Label = styled.label`
  width: 100%;
`

const triggerSizeStyle = {
  xs: ({ theme }: { theme: Theming }) => css`
    font-size: ${theme.fontSizes.xs};
    padding: 0.5rem 1rem;
  `,
  lg: ({ theme }: { theme: Theming }) => css`
    font-size: ${theme.fontSizes.base};
    padding: ${theme.space.$4} ${theme.space.$5};
  `
}

export const Trigger = styled.button<TriggerProps>`
  ${({ theme, isOpen, hasError, isDisabled, $size }) => css`
    background-color: transparent;
    box-shadow: inset 0 0 0 ${theme.borderWidths.thin} ${theme.colors.neutral5};
    border-radius: ${theme.radii.base};
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    position: relative;
    transition: all 0.3s ease-in-out;
    width: 100%;

    ${isOpen && focusedFieldStyle(theme)}
    ${hasError && fieldWithErrorStyle(theme)}

    &:focus,
    &:active,
    &:hover {
      ${focusedFieldStyle(theme)}
    }

    ${isDisabled && fieldWithDisabledStyle(theme)}
    ${$size && triggerSizeStyle[$size]};
  `}
`

export const Dropdown = styled.div<DropdownProps>`
  ${({ theme, top, left, position }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.radii.xl};
    box-shadow: ${theme.shadows.md};
    cursor: pointer;
    overflow-y: scroll;
    padding-bottom: ${theme.space.$2};
    padding-top: ${theme.space.$4};
    position: ${position};
    z-index: ${theme.zIndices.dropdown};
    max-height: 18rem;
    outline: none;

    & > * {
      outline: none;
    }

    ${top ? `top: ${top}px;` : ''}
    ${left ? `left: ${left}px;` : ''}

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      background-color: transparent;
      width: ${theme.sizes.$3};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${theme.radii.lg};
      background: ${theme.colors.neutral8};
      transition: all 0.2s ease-in-out;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.neutral9};
      transition: all 0.2s ease-in-out;
    }
  `}
`

export const OptionCheck = styled.div<OptionCheckProps>`
  ${({ theme, isSelected }) => css`
    position: absolute;
    visibility: ${isSelected ? 'visible' : 'hidden'};
    left: ${theme.space.$4};
    top: ${theme.space.$3};
  `}
`

const focusedOptionStyle = (theme: Theming) => `
  background-color: ${theme.colors.neutral2};
  transition: all .1s ease-in-out;
`

export const Option = styled.div<OptionProps>`
  ${({ theme, isFocused, disabled }) => css`
    align-items: center;
    border: none;
    display: flex;
    padding: ${theme.space.$2} ${theme.space.$8};
    transition: all 0.1s ease-in-out;
    outline: none;
    position: relative;

    ${!disabled && isFocused && focusedOptionStyle(theme)}
    ${disabled && `cursor: not-allowed;`}

    &:focus &:not:disabled,
    &:active &:not:disabled {
      ${focusedOptionStyle(theme)}
    }
  `}
`

export const LabelText = styled.div`
  margin-bottom: ${({ theme }) => theme.space.$2};
`

export const ErrorMessage = styled.div<ErrorMessageProps>`
  ${({ theme, hasError }) => css`
    margin-top: ${theme.space.$2};
    visibility: ${hasError ? 'visible' : 'hidden'};
    transition: all 0.3s ease-in-out;
  `}
`

export const OptionIcon = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    margin-right: ${theme.space.$2};
  `}
`

export const Section = styled.div`
  & > p {
    margin-left: ${({ theme }) => theme.space.$8};
  }

  & > * {
    outline: none;
  }
`
