import { Link } from 'react-router-dom'
import { Avatar, Popover, Spinner, Text } from '@foundation/components'
import { Ellipsis, FormField, Section } from 'components'
import { useLeadSummaryController } from 'controllers'
import { Lead } from 'models'
import { dataOrDash, titleize } from 'utils'
import * as S from './styles'

type Props = {
  lead: Lead
}

const Content = ({ leadId }: { leadId: string }) => {
  const { lead, isLoading, isError } = useLeadSummaryController(leadId)

  if (isError) {
    return <Text size="xs">An error occur. Close the popup and try to open again!</Text>
  }

  if (!lead && isLoading) {
    return (
      <S.WrapperSpinner>
        <Spinner label="Loading lead data..." />
      </S.WrapperSpinner>
    )
  }

  return (
    <Section>
      <FormField className="leadSummaryField" title="Email" inline>
        <Ellipsis title={lead.email ?? lead.email}>{dataOrDash(lead.email)}</Ellipsis>
      </FormField>
      <FormField className="leadSummaryField" title="Phone" inline>
        {dataOrDash(lead.formattedPhoneNumber)}
      </FormField>
      {!!lead?.leadViewers.length && (
        <FormField className="leadSummaryField" title="Viewers" inline>
          {lead.leadViewers.map((viewer, index) => {
            if (index > 5) {
              return null
            }

            return (
              <S.WrapperAvatar key={viewer.id}>
                <Avatar.Text
                  size="xs"
                  variant="outline"
                  title={`${viewer.firstName} ${viewer.lastName}`}
                >
                  {viewer.initials}
                </Avatar.Text>
              </S.WrapperAvatar>
            )
          })}
        </FormField>
      )}
    </Section>
  )
}

export const LeadSummaryCard = ({ lead }: Props) => {
  return (
    <Popover.Root placement="bottom-start" isHoverEnabled enterDelay="longest">
      <Popover.Trigger>
        <S.Ellipsis>
          <Link to={lead.getPathname()}>{titleize(lead.name)}</Link>
        </S.Ellipsis>
      </Popover.Trigger>
      <Popover.Content>
        <Content leadId={lead.id} />
      </Popover.Content>
    </Popover.Root>
  )
}
