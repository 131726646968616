import { ReactNode } from 'react'
import { useAgentsController } from 'controllers'
import { useLocation } from 'hooks'
import { queryStringToObject } from 'utils'
import { createCtx } from '../utils'

export const [useAgentsContext, AgentsContextProvider] =
  createCtx<ReturnType<typeof useAgentsController>>()

type Props = {
  aeId?: string
  children: ReactNode
  rootPath?: string
  sortBy?: string
  teamId?: string
  teamType?: string
}

export const AgentsProvider = ({
  children,
  rootPath = '/agents',
  teamId,
  teamType,
  aeId,
  sortBy
}: Props) => {
  const { search, pathname } = useLocation()

  return (
    <AgentsContextProvider
      value={useAgentsController(
        {
          searchParams: { teamId, teamType, aeId, sortBy, ...queryStringToObject(search) }
        },
        pathname === rootPath
      )}
    >
      {children}
    </AgentsContextProvider>
  )
}
