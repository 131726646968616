// @ts-strict
import { InputHTMLAttributes, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Icon } from 'components/Icon'
import { cx, formatFileSize } from 'utils'
import formStyles from '../Form.module.scss'
import styles from './FileInput.module.scss'
import { AcceptedFiletypes, getAcceptFiletypesObject } from './acceptedFiletypes'

type TProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value' | 'accept'> & {
  accept: AcceptedFiletypes[]
  onChange?: (files: File[]) => void
  value?: File[]
}

export const FileDropzoneInput = ({
  value = [],
  onChange = () => {},
  className,
  accept,
  ...rest
}: TProps) => {
  const onDrop = useCallback(
    addedFiles => {
      onChange(addedFiles)
    },
    [onChange]
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: getAcceptFiletypesObject(accept)
  })

  const acceptedFiletypesString = accept.map(el => `.${el}`).join(', ')

  const files = value.map((file, idx) => (
    <div key={file.name + file.type} className={styles.filename}>
      <strong>{file.name}</strong> ({formatFileSize(file.size, 'KB').toFixed(1)} kB)
    </div>
  ))

  return (
    <div {...getRootProps()} className={cx(styles.wrapper, formStyles.input, className)}>
      <input {...rest} {...getInputProps()} />

      <Icon.FileUpload />

      {files.length > 0 ? (
        files
      ) : (
        <>
          <div>Drag and drop some files here, or click to select files.</div>
          <div className={styles.small}>Accepted file types: {acceptedFiletypesString}</div>
        </>
      )}
    </div>
  )
}
