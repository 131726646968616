import { toOptions } from 'lookups/utils'
import { bySlug } from 'utils'
import { TCashOfferProviderLeadStagesSlug } from '../lookupCashOfferProviderLeadStages'
import { buildLeadStages } from '../lookupLeadStages'
import { CcCashOfferLeadStage } from './CcCashOfferLeadStage'
import {
  ccCashOfferLeadStagesSettings,
  TCcCashOfferLeadStagesSlug
} from './ccCashOfferLeadStagesSettings'

const lookupCcCashOfferLeadStages = buildLeadStages(
  ccCashOfferLeadStagesSettings,
  CcCashOfferLeadStage
)

export const getCcCashOfferLeadStage = (
  slug: TCcCashOfferLeadStagesSlug | TCashOfferProviderLeadStagesSlug
) => {
  return lookupCcCashOfferLeadStages.find(bySlug(slug))
}

export const getCcCashOfferLeadStageName = (
  slug: TCcCashOfferLeadStagesSlug | TCashOfferProviderLeadStagesSlug
) => {
  return getCcCashOfferLeadStage(slug)?.name
}

export const ccCashOfferLeadStagesOptions = toOptions(lookupCcCashOfferLeadStages)
