export const leadPaths = [
  'trade-ins',
  'cash-offers',
  'buyers',
  'sellers',
  'mortgages',
  'escrows',
  'hlss'
]

const thisClientTabForLeads = leadPaths.map(path => ({
  path: `/${path}/:leadId/*`
}))

export const thisClientTabPathsToMatch = [
  ...thisClientTabForLeads,
  {
    path: `/orders/:orderId/*`
  }
]

export const thisAgentTabPathsToMatch = [
  {
    path: `/agents/:agentId/*`
  }
]
