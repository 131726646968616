import { isValidHttpUrl } from 'utils/index'
import { TValidation } from './validate'

export const validateUrl: TValidation = ({ label, value }) => {
  const errors = []

  if (!isValidHttpUrl(value)) {
    errors.push(`${label} is not a valid URL`)
  }

  return errors
}
