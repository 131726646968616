import { TModelFieldSetting } from '../ModelField'

export type THLSimpleSaleLeadFieldAttribute =
  | 'departingPropertyFlags'
  | 'departingPropertyExternalListingStatus'
  | 'dpCapitalDaysOutstanding'
  | 'departingPropertyPriceDelta'
  | 'departingPropertyActualDom'
  | 'departingPropertyDaysOfOwnership'
  | 'reasonForFail'

export type THLSimpleSaleLeadFieldSetting = {
  attr: THLSimpleSaleLeadFieldAttribute
} & TModelFieldSetting

export const hlSimpleSaleLeadFieldsSettings: THLSimpleSaleLeadFieldSetting[] = [
  {
    attr: 'departingPropertyFlags',
    name: 'Prop Flagged Reason'
  },
  {
    attr: 'departingPropertyExternalListingStatus',
    name: 'Currently Listed'
  },
  {
    attr: 'dpCapitalDaysOutstanding',
    name: 'Days of Investor Capital Outstanding'
  },
  {
    attr: 'departingPropertyPriceDelta',
    name: 'Sale - List Price'
  },
  {
    attr: 'departingPropertyActualDom',
    name: 'Actual Days on Market'
  },
  {
    attr: 'departingPropertyDaysOfOwnership',
    name: 'Actual Days of Ownership'
  },
  {
    attr: 'reasonForFail',
    name: 'Failed Reason'
  }
]
