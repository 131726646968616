export const toSearchParam = (text?: string): string => {
  if (!text) {
    return ''
  }

  return text
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '+')
    .replace(/[^a-z0-9+-]+/g, '')
}
