// @ts-strict
import { TaskStatus } from 'lookups'
import { Agent, getLead, Lead, TaskCategory, User } from 'models'
import { CashOfferLead } from 'models/providables'
import { AgentResponse, LeadResponse, TaskResponse } from 'schema'
import { getTaskStatusByDate } from './utils'

type TTaskConstructor = Omit<Task, 'getCompletedByUser' | 'getAttachable' | 'status'>

export class Task {
  id!: string
  title?: string
  note?: string
  dismissedAt?: string
  dueAt?: string
  completedAt?: string
  completedBy?: User
  completionNote?: string
  createdAt?: string
  assignedAt?: string
  readyAt?: string
  metadata?: any
  type?: string
  assignee?: User
  assigner?: User
  attachable?: Agent | Lead
  taskCategory?: TaskCategory
  status: TaskStatus
  templateSlug?: string
  template?: Record<string, any>
  requiredForLeadStage?: string
  subAttachable?: CashOfferLead

  constructor(taskAttributes: TTaskConstructor) {
    Object.assign(this, taskAttributes)

    this.status = getTaskStatusByDate(taskAttributes)
  }

  static create(taskResponse: Potential<TaskResponse>): Potential<Task> {
    if (!taskResponse) {
      return
    }

    const assignee = User.create(taskResponse?.assignee)
    const assigner = User.create(taskResponse?.assigner)
    const completedBy = User.create(taskResponse?.completedBy)
    const attachable = getAttachable(taskResponse?.attachable)
    const subAttachable = CashOfferLead.create(taskResponse?.subAttachable)
    const taskCategory = TaskCategory.create(taskResponse?.taskCategory)
    const requiredForLeadStage =
      taskResponse.requiredForLeadStage || taskResponse?.leadStageTaskDependency?.stage

    return new Task({
      ...taskResponse,
      assignee,
      assigner,
      attachable,
      completedBy,
      subAttachable,
      taskCategory,
      requiredForLeadStage
    })
  }

  getCompletedByUser() {
    if (!this.completedAt && !this.dismissedAt) {
      return
    }

    if (this.completedBy) {
      return this.completedBy
    }

    if (this.type === 'AgentTask' || this.type === 'ClientTask') {
      return this.assignee
    }
  }
}

const getAttachable = (attachable?: LeadResponse | AgentResponse) => {
  if (!attachable) {
    return
  }
  if ('userType' in attachable) {
    return getLead(attachable)
  }

  return Agent.create(attachable as AgentResponse)
}
