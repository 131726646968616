import { LeadGroupableDefinitionType } from 'models'
import { getLeadGroupableDefinitionUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export const postLeadGroupableDefinition = async (
  groupableDefinitionType: LeadGroupableDefinitionType,
  groupableDefinitionId: string
) => {
  return await postRequest(
    getLeadGroupableDefinitionUrl({
      action: `${groupableDefinitionType}/${groupableDefinitionId}/duplicate`
    })
  )
}
