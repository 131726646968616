// @ts-strict
import type { InspectionReportResponse, InspectionResponse } from 'schema'
import { formatDate } from 'utils'
import { Model } from '../Model'
import type { ModelAttrKeys } from '../Model'

type Attributes = Partial<Omit<InspectionReport, ModelAttrKeys>>
type Response = Potential<Partial<InspectionReportResponse>>

export class InspectionReport extends Model {
  modelName = 'InspectionReport'
  createdAt = ''
  externalId = ''
  id = ''
  inspectionDetail: any[] = []
  inspectionTypes: InspectionResponse['inspectionType'][] = []
  publishedAt = ''
  reportType = ''
  updatedAt = ''
  webUrl = ''
  fileId = ''

  constructor(attributes?: Attributes) {
    super()
    Object.assign(this, attributes)
  }

  static create(response: Response): InspectionReport {
    return new InspectionReport({
      fileId: response?.file?.id ?? '',
      createdAt: response?.createdAt ? formatDate(response?.createdAt) : '',
      externalId: response?.externalId ?? '',
      id: response?.id ?? '',
      inspectionTypes: response?.inspectionTypes ?? [],
      reportType: response?.reportType ?? '',
      updatedAt: response?.updatedAt ? formatDate(response?.updatedAt) : '',
      webUrl: response?.webUrl ?? '',
      inspectionDetail: response?.inspectionDetail ?? []
    })
  }
}
