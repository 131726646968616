import { TModelFieldSetting } from '../ModelField'

export type TAgentTeamFieldAttribute = 'name' | 'DRname' | 'IRname'

export type TAgentTeamFieldSetting = {
  attr: TAgentTeamFieldAttribute
} & TModelFieldSetting

export const agentTeamFieldsSettings: TAgentTeamFieldSetting[] = [
  { attr: 'name', name: 'Ref Agent Team' },
  { attr: 'DRname', name: 'DR Agent Team' },
  { attr: 'IRname', name: 'IR Agent Team' }
]
