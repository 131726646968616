export type TMarketplaceProgramSlug =
  | 'referrals'
  | 'trade_in'
  | 'cash_offer'
  | 'hlss'
  | 'title_escrow'
  | 'fully_funded' // this is legacy, unused
  | 'home_loans'
  | 'simple_sale'
  | 'disclosures_io'
  | 'cash_close'

type TMarketplaceProgramSetting = {
  abbr: string
  agentTeamRequired?: boolean
  eligibleForPitchStatus?: boolean
  name: string
  slug: TMarketplaceProgramSlug
  transactionTeamRequired?: boolean
}

export const marketplaceProgramSettings: Array<TMarketplaceProgramSetting> = [
  {
    abbr: 'R',
    eligibleForPitchStatus: true,
    name: 'Referrals',
    slug: 'referrals'
  },
  {
    abbr: 'BBYS',
    agentTeamRequired: true,
    eligibleForPitchStatus: true,
    name: 'Buy Before You Sell',
    slug: 'trade_in',
    transactionTeamRequired: true
  },
  {
    abbr: 'CO',
    agentTeamRequired: true,
    eligibleForPitchStatus: true,
    name: 'Cash Offer',
    slug: 'cash_offer',
    transactionTeamRequired: true
  },
  {
    abbr: 'HS',
    agentTeamRequired: true,
    eligibleForPitchStatus: true,
    name: 'HL Simple Sale',
    slug: 'hlss',
    transactionTeamRequired: true
  },
  {
    abbr: 'CS',
    agentTeamRequired: true,
    eligibleForPitchStatus: true,
    name: 'Closing Services',
    slug: 'title_escrow',
    transactionTeamRequired: true
  },
  {
    abbr: 'HL',
    eligibleForPitchStatus: true,
    name: 'Home Loans',
    slug: 'home_loans'
  },
  {
    abbr: 'SS',
    eligibleForPitchStatus: true,
    name: 'Simple Sale',
    slug: 'simple_sale'
  },
  {
    abbr: 'D',
    name: 'Disclosures IO',
    slug: 'disclosures_io'
  },
  {
    abbr: 'CC',
    name: 'Cash Close',
    slug: 'cash_close'
  }
]

export const marketplaceProgramSlugs = marketplaceProgramSettings.map(mpp => mpp.slug)
