import { useState } from 'react'
import { Dialog, Flex } from '@foundation/components'
import { Button, Icon } from 'components'
import { CCBBYSLead } from 'models'
import { cx } from 'utils'
import style from './ContactsLeadNavigationMobile.module.scss'

const EnvelopeFilledIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.6969 7.9625C17.8188 7.86562 18 7.95625 18 8.10938V14.5C18 15.3281 17.3281 16 16.5 16H3.5C2.67187 16 2 15.3281 2 14.5V8.1125C2 7.95625 2.17812 7.86875 2.30312 7.96563C3.00312 8.50938 3.93125 9.2 7.11875 11.5156C7.77813 11.9969 8.89062 13.0094 10 13.0031C11.1156 13.0125 12.25 11.9781 12.8844 11.5156C16.0719 9.2 16.9969 8.50625 17.6969 7.9625ZM10 12C10.725 12.0125 11.7688 11.0875 12.2938 10.7063C16.4406 7.69688 16.7562 7.43438 17.7125 6.68438C17.8937 6.54375 18 6.325 18 6.09375V5.5C18 4.67188 17.3281 4 16.5 4H3.5C2.67187 4 2 4.67188 2 5.5V6.09375C2 6.325 2.10625 6.54063 2.2875 6.68438C3.24375 7.43125 3.55937 7.69688 7.70625 10.7063C8.23125 11.0875 9.275 12.0125 10 12Z"
      fill="currentColor"
    />
  </svg>
)

const EnvelopeLineIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 5.5C3.725 5.5 3.5 5.725 3.5 6V6.69063L8.89062 11.1156C9.5375 11.6469 10.4656 11.6469 11.1125 11.1156L16.5 6.69063V6C16.5 5.725 16.275 5.5 16 5.5H4ZM3.5 8.63125V14C3.5 14.275 3.725 14.5 4 14.5H16C16.275 14.5 16.5 14.275 16.5 14V8.63125L12.0625 12.275C10.8625 13.2594 9.13438 13.2594 7.9375 12.275L3.5 8.63125ZM2 6C2 4.89688 2.89688 4 4 4H16C17.1031 4 18 4.89688 18 6V14C18 15.1031 17.1031 16 16 16H4C2.89688 16 2 15.1031 2 14V6Z"
      fill="currentColor"
    />
  </svg>
)

const PhoneFilledIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4188 2.76907L14.1688 2.01909C13.8157 1.93784 13.4532 2.12221 13.3095 2.45345L11.8095 5.95338C11.6783 6.25963 11.7658 6.61899 12.0251 6.82836L13.9188 8.37833C12.7939 10.7752 10.8283 12.7689 8.38145 13.9157L6.83148 12.022C6.61898 11.7626 6.26274 11.6751 5.95649 11.8064L2.45657 13.3064C2.1222 13.4532 1.93783 13.8157 2.01908 14.1688L2.76906 17.4188C2.84718 17.7563 3.14718 18 3.50029 18C11.5033 18 18 11.5158 18 3.50031C18 3.15031 17.7594 2.8472 17.4188 2.76907Z"
      fill="currentColor"
    />
  </svg>
)

const PhoneLineIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8907 2.718L13.9473 2.03994C13.2723 1.88371 12.5849 2.23367 12.3131 2.87111L10.9538 6.04267C10.7038 6.62386 10.8726 7.31129 11.3632 7.71125L12.613 8.73303C11.7194 10.3704 10.3695 11.7202 8.73217 12.6108L7.71039 11.3609C7.31043 10.8703 6.623 10.7016 6.04181 10.9516L2.86713 12.3108C2.22969 12.5826 1.88285 13.2701 2.03596 13.945L2.71402 16.8885C2.864 17.5415 3.43894 17.9977 4.10763 17.9977C11.76 18.0009 18 11.8046 18 4.11161C18 3.44293 17.5438 2.86798 16.8907 2.718ZM4.167 16.501L3.51394 13.6669L6.58238 12.3514L8.32283 14.4793C11.41 13.0295 13.0286 11.4171 14.4785 8.32369L12.3506 6.58324L13.6661 3.5148L16.5001 4.16786C16.4689 10.9672 10.9663 16.4698 4.167 16.501Z"
      fill="currentColor"
    />
  </svg>
)

const CircleInfoIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3235_1633)">
      <path
        d="M10 2C5.58125 2 2 5.58125 2 10C2 14.4187 5.58125 18 10 18C14.4187 18 18 14.4187 18 10C18 5.58125 14.4187 2 10 2ZM10 17C6.14062 17 3 13.8594 3 10C3 6.14062 6.14062 3 10 3C13.8594 3 17 6.14062 17 10C17 13.8594 13.8594 17 10 17ZM10 7.75C10.4141 7.75 10.75 7.41437 10.75 7C10.75 6.58594 10.4141 6.25 10 6.25C9.58594 6.25 9.25 6.58437 9.25 7C9.25 7.41563 9.58437 7.75 10 7.75ZM11.5 13H10.5V9.5C10.5 9.225 10.275 9 10 9H9C8.725 9 8.5 9.225 8.5 9.5C8.5 9.775 8.725 10 9 10H9.5V13H8.5C8.225 13 8 13.225 8 13.5C8 13.775 8.225 14 8.5 14H11.5C11.7761 14 12 13.7761 12 13.5C12 13.225 11.775 13 11.5 13Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3235_1633">
        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
)

const DialogHeader = ({ onClose }) => (
  <div className={style.dialogHeader}>
    <p>Contacts</p>
    <Icon.Close onClick={onClose} />
  </div>
)

type ContactProps = {
  email?: string
  name: string
  phone?: string
  role: string
}

const Contact = ({ name, role, phone, email }: ContactProps) => {
  const hasNoContactInfo = !phone && !email
  const phoneHref = phone ? `tel:${phone}` : ''
  const emailHref = email ? `mailto:${email}` : ''

  return (
    <Flex justifyContent="space-between" alignItems="flex-start">
      <div>
        <p className={cx(style.contactName, hasNoContactInfo && style.noInfo)}>{name || '-'}</p>
        <p className={style.contactRole}>{role}</p>
      </div>

      <Flex gap="$3">
        <a href={phoneHref} className={cx(style.contactBtn, !phone && style.disabled)}>
          {!phone ? <PhoneLineIcon /> : <PhoneFilledIcon />}
        </a>
        <a href={emailHref} className={cx(style.contactBtn, !email && style.disabled)}>
          {!email ? <EnvelopeLineIcon /> : <EnvelopeFilledIcon />}
        </a>
      </Flex>
    </Flex>
  )
}

type ContactsLeadNavigationMobileProps = {
  lead: CCBBYSLead
}

export const ContactsLeadNavigationMobile = ({ lead }: ContactsLeadNavigationMobileProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isDismissed, setIsDismissed] = useState(false)

  const {
    loanOfficer,
    departingPropertySellerAgent,
    incomingPropertyBuyerAgent,
    departingResidenceTitleCompany,
    departingResidenceTitleCompanyEmail,
    departingResidenceTitleCompanyPhone,
    incomingResidenceTitleCompany,
    incomingResidenceTitleCompanyEmail,
    incomingResidenceTitleCompanyPhone
  } = lead.bbysLead

  const { newHomeConsultant, loanOfficerAssistant } = lead

  const contacts: ContactProps[] = [
    {
      name: lead.name,
      role: 'Client',
      email: lead.email,
      phone: lead.phoneOffice?.formattedNumber
    },
    {
      name: loanOfficer?.name,
      role: 'Loan Officer',
      email: loanOfficer?.email,
      phone: loanOfficer?.phoneNumber?.formattedNumber
    },
    {
      name: loanOfficerAssistant?.user?.name,
      role: 'Loan Officer Assistant',
      email: loanOfficerAssistant?.user?.email,
      phone: loanOfficerAssistant?.user?.phoneNumbers[0]?.number
    },
    {
      name: newHomeConsultant?.user?.name,
      role: 'New Home Consultant',
      email: newHomeConsultant?.user?.email,
      phone: newHomeConsultant?.user?.phoneNumbers[0]?.number
    },
    {
      name: departingPropertySellerAgent?.name,
      role: 'DR Agent',
      email: departingPropertySellerAgent?.email1,
      phone: departingPropertySellerAgent?.getPhoneOffice()?.formattedNumber
    },
    {
      name: incomingPropertyBuyerAgent?.name,
      role: 'IR Agent',
      email: incomingPropertyBuyerAgent?.email1,
      phone: incomingPropertyBuyerAgent?.getPhoneOffice()?.formattedNumber
    },
    {
      name: departingResidenceTitleCompany,
      role: 'DR Title Company',
      email: departingResidenceTitleCompanyEmail,
      phone: departingResidenceTitleCompanyPhone
    },
    {
      name: incomingResidenceTitleCompany,
      role: 'IR Title Company',
      email: incomingResidenceTitleCompanyEmail,
      phone: incomingResidenceTitleCompanyPhone
    }
  ]

  return (
    <Dialog.Root isOpen={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>
        <Button
          as="action"
          icon={<Icon.UserCircle />}
          iconPosition="start"
          size="small"
          style={{ fontSize: '16px' }}
        >
          Contacts
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <DialogHeader onClose={() => setIsOpen(false)} />
        <Dialog.Body>
          <div className={style.contactsContainer}>
            {contacts.map(contact => (
              <Contact {...contact} key={contact.role} />
            ))}
          </div>

          {!isDismissed && (
            <div className={style.banner}>
              <CircleInfoIcon />
              <p>
                To edit contact information go to <a href="/">details page</a>
              </p>
              <Icon.Close onClick={() => setIsDismissed(true)} />
            </div>
          )}
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  )
}
