// @ts-strict
import { AgentPayPerLeadPaymentInformationResponse } from 'schema'
import { Model } from '../Model'

export class AgentPayPerLeadPaymentInformation extends Model {
  brand?: string
  expMonth?: number
  expYear?: number
  externalId?: string
  id?: string | number
  last4?: string
  monthlyAmountLeft?: number
  monthlyAmountUsed?: number
  monthlyCap?: number
  select?: boolean

  constructor(
    agentPayPerLeadPaymentInformationAttributes: AgentPayPerLeadPaymentInformationResponse
  ) {
    super()
    Object.assign(this, agentPayPerLeadPaymentInformationAttributes)
  }

  static create(
    agentPayPerLeadPaymentInformationAttributes: Potential<AgentPayPerLeadPaymentInformationResponse>
  ): Potential<AgentPayPerLeadPaymentInformationResponse> {
    if (!agentPayPerLeadPaymentInformationAttributes) {
      return
    }

    return new AgentPayPerLeadPaymentInformation(agentPayPerLeadPaymentInformationAttributes)
  }
}
