// @ts-strict
import { ActivityResponse } from 'schema'
import { d } from 'utils'
import { getTwilioUrl } from './getTwilioUrl'

export class Activity {
  audioUrl?: string
  author?: string
  body?: string
  id!: string
  occurredAt?: string
  subType?: string
  title?: string
  transitions?: string[]
  type?: string
  updatedBy?: string

  constructor(activityAttributes: Activity) {
    Object.assign(this, activityAttributes)
  }
  static create(activityResponse: Potential<ActivityResponse>): Potential<Activity> {
    if (!activityResponse) {
      return
    }
    const metadata = activityResponse?.metadata
    const audioUrl = metadata?.recordingSid ? getTwilioUrl(metadata?.recordingSid) : undefined
    const body =
      metadata?.notes ||
      metadata?.body ||
      metadata?.salesEventResult
        ?.replaceAll(',"', ',\n"')
        ?.replaceAll('":', '": ')
        ?.replaceAll('{', '{\n')
        ?.replaceAll('"}', '"\n}') ||
      undefined
    const transitions = metadata?.oldStage
      ? [d(metadata.oldStage), d(metadata.newStage)]
      : undefined

    return new Activity({
      ...activityResponse,
      audioUrl,
      body,
      title: activityResponse.snippet,
      transitions,
      updatedBy: metadata?.updatedBy
    })
  }
}
