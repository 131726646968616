export const ccTradeInTaskColumns = {
  tradeInContactClient: {
    order: 1,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Contact Client',
    title: 'Contact New Client',
    value: 'tradeInContactClient',
    columnSize: 'xsm',
    align: 'center'
  },
  ccTradeInPropertyQuestionnaire: {
    order: 2,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Questionnaire',
    title: 'Complete Property Questionnaire',
    value: 'ccTradeInPropertyQuestionnaire',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSubmitPropertyPhotos: {
    order: 3,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Submit Photos',
    title: 'Submit Property Photos',
    value: 'tradeInSubmitPropertyPhotos',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInRequestInspection: {
    order: 4,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Req Inspection',
    title: 'Request Inspection',
    value: 'tradeInRequestInspection',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInScheduleInspection: {
    order: 5,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Sched Insp',
    title: 'Schedule Inspection',
    value: 'tradeInScheduleInspection',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInReviewPropertyPhotos: {
    order: 6,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Review Photos',
    title: 'Review Property Photos',
    value: 'tradeInReviewPropertyPhotos',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInReviewQuestionnaireResponses: {
    order: 7,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Rev Questionnaire',
    title: 'Review Questionnaire Responses',
    value: 'tradeInReviewQuestionnaireResponses',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInValuation: {
    order: 8,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Valuation',
    title: 'Complete Valuation',
    value: 'tradeInValuation',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInReceiveInspectionReports: {
    order: 9,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Insp Reports',
    title: 'Receive Inspection Reports',
    value: 'tradeInReceiveInspectionReports',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInPreliminaryTitleReport: {
    order: 10,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Title',
    title: 'Review Title',
    value: 'tradeInPreliminaryTitleReport',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInHoaRequest: {
    order: 11,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Req HOA',
    title: 'Request HOA',
    value: 'tradeInHoaRequest',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInHoaReceive: {
    order: 12,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Rec HOA',
    title: 'Receive HOA',
    value: 'tradeInHoaReceive',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInInspection: {
    order: 13,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Rev Insp Reports',
    title: 'Review Inspection Reports',
    value: 'tradeInInspection',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInPrepDealApproval: {
    order: 14,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Prep Approval',
    title: 'Prep Deal Approval',
    value: 'tradeInPrepDealApproval',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInDealApproval: {
    order: 15,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Approve',
    title: 'Approve Deal',
    value: 'tradeInDealApproval',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInScheduleFinalNumberReview: {
    order: 16,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Sched Final Num',
    title: 'Schedule Final Number Review',
    value: 'tradeInScheduleFinalNumberReview',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSendFinalNumbersEmail: {
    order: 17,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Send Final Num',
    title: 'Send Final Numbers Email',
    value: 'tradeInSendFinalNumbersEmail',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSendTia: {
    order: 18,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Send TIA',
    title: 'Send TIA',
    value: 'tradeInSendTia',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSubmitAgreement: {
    order: 19,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Sig TIA',
    title: 'Receive Signed TIA',
    value: 'tradeInSubmitAgreement',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInConfirmIrInContract: {
    order: 20,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'IR In Contract',
    title: 'Receive IR In Contract',
    value: 'tradeInConfirmIrInContract',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInConfirmFundingAmount: {
    order: 21,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Conf Fund Amount',
    title: 'Confirm Funding Amount',
    value: 'tradeInConfirmFundingAmount',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInAssignFundingFacility: {
    order: 22,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Fund Facility',
    title: 'Assign Funding Facility',
    value: 'tradeInAssignFundingFacility',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSendEmailTi1NextSteps: {
    order: 23,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Send TI1',
    title: 'Send Email TI1 - Next Steps ',
    value: 'tradeInSendEmailTi1NextSteps',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSubmitRpa: {
    order: 24,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Send DR Pur Contract',
    title: 'Send DR Purchase Contract',
    value: 'tradeInSubmitRpa',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSignDrPurchaseContract: {
    order: 25,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Sign DR Pur Contract',
    title: 'Sign DR Purchase Contract',
    value: 'tradeInSignDrPurchaseContract',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInReceiveSignedDrPurchaseContract: {
    order: 26,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Rec DR Pur Contract',
    title: 'Receive Signed DR Purchase Contract',
    value: 'tradeInReceiveSignedDrPurchaseContract',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSendEmailTi2OpenEscrow: {
    order: 27,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Send TI2',
    title: 'Send Email TI2 - Open Escrow',
    value: 'tradeInSendEmailTi2OpenEscrow',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInUpdateLeadStageToInEscrowPurchase: {
    order: 28,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Conf Pur Escrow',
    title: 'Confirm Purchase Escrow is Opened',
    value: 'tradeInUpdateLeadStageToInEscrowPurchase',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSendEmailTi3TimelineEscrowIntro: {
    order: 30,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Send TI3',
    title: 'Send Email TI3 - Timeline / Escrow Introductions',
    value: 'tradeInSendEmailTi3TimelineEscrowIntro',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSendEmailTi4WhatToExpect: {
    order: 31,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Send TI4',
    title: 'Send Email TI4 - What to Expect ',
    value: 'tradeInSendEmailTi4WhatToExpect',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSendDrPurchaseDisclosuresPackage: {
    order: 32,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Send DR Pur Discl',
    title: 'Send DR Purchase Disclosures Package',
    value: 'tradeInSendDrPurchaseDisclosuresPackage',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInReceiveDrPurchaseDisclosuresPackage: {
    order: 33,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Rec DR Pur Discl',
    title: 'Receive Signed DR Purchase Disclosures Package',
    value: 'tradeInReceiveDrPurchaseDisclosuresPackage',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInEmailAgentScheduleListingMeeting: {
    order: 34,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Sched List Meeting',
    title: 'Email Agent to Schedule Listing Meeting',
    value: 'tradeInEmailAgentScheduleListingMeeting',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSendClosingInstructions: {
    order: 35,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Send Clos Intruct',
    title: 'Send Closing Instructions',
    value: 'tradeInSendClosingInstructions',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSignClosingInstructions: {
    order: 36,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Sign Clos Intruct',
    title: 'Sign Closing Instructions',
    value: 'tradeInSignClosingInstructions',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInRequestPropertyInsurance: {
    order: 37,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Req Insurance',
    title: 'Request Property Insurance',
    value: 'tradeInRequestPropertyInsurance',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSendEmailTi5: {
    order: 38,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Send TI5',
    title: 'Send Email TI5 - Request for Investor Documents',
    value: 'tradeInSendEmailTi5',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInConfirmCompleteFundingPackage: {
    order: 39,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Conf Fund Pack',
    title: 'Confirm Complete Funding Package',
    value: 'tradeInConfirmCompleteFundingPackage',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSendEmailsTi7Ti8: {
    order: 40,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Send TI7 & TI8',
    title: 'Send Email TI7 & TI8 - Moving Forward with Escrow',
    value: 'tradeInSendEmailsTi7Ti8',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInAuditFilePurchase: {
    order: 41,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Audit Pur',
    title: 'Audit File - Purchase',
    value: 'tradeInAuditFilePurchase',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInExecuteClosingDocs: {
    order: 42,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Exc Pur Closing Docs',
    title: 'Execute Purchase Closing Docs',
    value: 'tradeInExecuteClosingDocs',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInConfirmMortgageClearToClose: {
    order: 43,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Mort Clear to Close',
    title: 'Confirm Mortgage Clear to Close',
    value: 'tradeInConfirmMortgageClearToClose',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInFundProperty: {
    order: 44,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Fund Property',
    title: 'Fund Property',
    value: 'tradeInFundProperty',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInConfirmPostClosingPackageCompleted: {
    order: 46,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Closing Pkg',
    title: 'Confirm Post Closing Package Completed',
    value: 'tradeInConfirmPostClosingPackageCompleted',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInConfirmListingAgreementReceived: {
    order: 47,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Rec List Agr',
    title: 'Confirm Listing Agreement Received',
    value: 'tradeInConfirmListingAgreementReceived',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSignListingAgreement: {
    order: 48,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Sign List Agr',
    title: 'Sign Listing Agreement',
    value: 'tradeInSignListingAgreement',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInConfirmDrSellDisclosuresPackageSigned: {
    order: 49,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Conf DR Sell Discl',
    title: 'Confirm DR Sell Disclosures Package Signed',
    value: 'tradeInConfirmDrSellDisclosuresPackageSigned',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInReceiveSignedDrSellContract: {
    order: 50,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Rec DR Sell Contract',
    title: 'Receive Signed DR Sell Contract',
    value: 'tradeInReceiveSignedDrSellContract',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInConfirmSellEscrowOpened: {
    order: 51,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Conf Sell Escrow',
    title: 'Confirm Sell Escrow is Opened',
    value: 'tradeInConfirmSellEscrowOpened',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInAuditFileSell: {
    order: 52,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Audit Sell',
    title: 'Audit File - Sell',
    value: 'tradeInAuditFileSell',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInExecuteSellClosingDocs: {
    order: 53,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Exc Sell Closing Docs',
    title: 'Execute Sell Closing Docs',
    value: 'tradeInExecuteSellClosingDocs',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInTerminatePropertyInsurance: {
    order: 54,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Term Insurance',
    title: 'Terminate Property Insurance',
    value: 'tradeInTerminatePropertyInsurance',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInSendFinalCustomerReceipt: {
    order: 55,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Send Receipt',
    title: 'Send Final Customer Receipt',
    value: 'tradeInSendFinalCustomerReceipt',
    columnSize: 'xsm',
    align: 'center'
  },
  tradeInVerifyClientsFinalProceeds: {
    order: 56,
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    name: 'Verify Proceeds Wired',
    title: 'Verify Clients Final Proceeds are Wired',
    value: 'tradeInVerifyClientsFinalProceeds',
    columnSize: 'xsm',
    align: 'center'
  }
} as const

export const ccTradeInTaskColumnsSettings = [
  ccTradeInTaskColumns.tradeInContactClient,
  ccTradeInTaskColumns.ccTradeInPropertyQuestionnaire,
  ccTradeInTaskColumns.tradeInSubmitPropertyPhotos,
  ccTradeInTaskColumns.tradeInRequestInspection,
  ccTradeInTaskColumns.tradeInScheduleInspection,
  ccTradeInTaskColumns.tradeInReviewPropertyPhotos,
  ccTradeInTaskColumns.tradeInReviewQuestionnaireResponses,
  ccTradeInTaskColumns.tradeInValuation,
  ccTradeInTaskColumns.tradeInReceiveInspectionReports,
  ccTradeInTaskColumns.tradeInPreliminaryTitleReport,
  ccTradeInTaskColumns.tradeInHoaRequest,
  ccTradeInTaskColumns.tradeInHoaReceive,
  ccTradeInTaskColumns.tradeInInspection,
  ccTradeInTaskColumns.tradeInPrepDealApproval,
  ccTradeInTaskColumns.tradeInDealApproval,
  ccTradeInTaskColumns.tradeInScheduleFinalNumberReview,
  ccTradeInTaskColumns.tradeInSendFinalNumbersEmail,
  ccTradeInTaskColumns.tradeInSendTia,
  ccTradeInTaskColumns.tradeInSubmitAgreement,
  ccTradeInTaskColumns.tradeInConfirmIrInContract,
  ccTradeInTaskColumns.tradeInConfirmFundingAmount,
  ccTradeInTaskColumns.tradeInAssignFundingFacility,
  ccTradeInTaskColumns.tradeInSendEmailTi1NextSteps,
  ccTradeInTaskColumns.tradeInSubmitRpa,
  ccTradeInTaskColumns.tradeInSignDrPurchaseContract,
  ccTradeInTaskColumns.tradeInReceiveSignedDrPurchaseContract,
  ccTradeInTaskColumns.tradeInSendEmailTi2OpenEscrow,
  ccTradeInTaskColumns.tradeInUpdateLeadStageToInEscrowPurchase,
  ccTradeInTaskColumns.tradeInSendEmailTi3TimelineEscrowIntro,
  ccTradeInTaskColumns.tradeInSendEmailTi4WhatToExpect,
  ccTradeInTaskColumns.tradeInSendDrPurchaseDisclosuresPackage,
  ccTradeInTaskColumns.tradeInReceiveDrPurchaseDisclosuresPackage,
  ccTradeInTaskColumns.tradeInEmailAgentScheduleListingMeeting,
  ccTradeInTaskColumns.tradeInSendClosingInstructions,
  ccTradeInTaskColumns.tradeInSignClosingInstructions,
  ccTradeInTaskColumns.tradeInRequestPropertyInsurance,
  ccTradeInTaskColumns.tradeInSendEmailTi5,
  ccTradeInTaskColumns.tradeInConfirmCompleteFundingPackage,
  ccTradeInTaskColumns.tradeInSendEmailsTi7Ti8,
  ccTradeInTaskColumns.tradeInAuditFilePurchase,
  ccTradeInTaskColumns.tradeInExecuteClosingDocs,
  ccTradeInTaskColumns.tradeInConfirmMortgageClearToClose,
  ccTradeInTaskColumns.tradeInFundProperty,
  ccTradeInTaskColumns.tradeInConfirmPostClosingPackageCompleted,
  ccTradeInTaskColumns.tradeInConfirmListingAgreementReceived,
  ccTradeInTaskColumns.tradeInSignListingAgreement,
  ccTradeInTaskColumns.tradeInConfirmDrSellDisclosuresPackageSigned,
  ccTradeInTaskColumns.tradeInReceiveSignedDrSellContract,
  ccTradeInTaskColumns.tradeInConfirmSellEscrowOpened,
  ccTradeInTaskColumns.tradeInAuditFileSell,
  ccTradeInTaskColumns.tradeInExecuteSellClosingDocs,
  ccTradeInTaskColumns.tradeInTerminatePropertyInsurance,
  ccTradeInTaskColumns.tradeInSendFinalCustomerReceipt,
  ccTradeInTaskColumns.tradeInVerifyClientsFinalProceeds
] as const

export type TCcTradeInTaskColumnsColumn = typeof ccTradeInTaskColumnsSettings
export type TCcTradeInTaskColumnsColumnValue =
  (typeof ccTradeInTaskColumnsSettings)[number]['value']
