import {
  FormField,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  InlineEditDollarsInput,
  InlineEditIntegerInput,
  InlineEditPercentageInput,
  InlineEditSelect,
  Section,
  Sections
} from 'components'
import { useHLSimpleSaleLeadController } from 'controllers'
import { optionsCashOfferFeeMethod } from 'lookups'
import { formatMoney, formatNumber, formatPercentage, getPercentageValue } from 'utils'
import { HLSSLeadDetailsProps } from '../HLSSLeadDetails'
import { getFieldValues } from '../utils'

type TProps = Pick<HLSSLeadDetailsProps, 'lead'>

export const DRSaleTransactionSection = ({ lead }: TProps) => {
  const hlSimpleSaleLead = lead.hlSimpleSaleLead!
  const { updateHLSimpleSaleLead } = useHLSimpleSaleLeadController(lead.hlSimpleSaleLead!.id)
  const { getFieldValue, getFieldPercentageValue } = getFieldValues(hlSimpleSaleLead)

  const targetRehabBudgetIsPercent = hlSimpleSaleLead.dpTargetRehabBudgetMethod === 'rate'

  return (
    <Sections title="DR Sale Transaction">
      <Section>
        <FormField title="Target Rehab Budget Method" inline>
          <InlineEditSelect
            options={optionsCashOfferFeeMethod}
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpTargetRehabBudgetMethod: val })}
            value={getFieldValue('dpTargetRehabBudgetMethod')}
          />
        </FormField>
        <FormField title="Target Rehab Budget %" inline>
          <InlineEditPercentageInput
            readOnly={!targetRehabBudgetIsPercent}
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({
                dpTargetRehabBudgetPercentage: getPercentageValue(val)
              })
            }
            value={getFieldPercentageValue('dpTargetRehabBudgetPercentage')}
          />
        </FormField>
        <FormField title="Target Rehab Budget $" inline>
          <InlineEditDollarsAndCentsInput
            readOnly={targetRehabBudgetIsPercent}
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpTargetRehabBudgetPrice: val })}
            value={getFieldValue('dpTargetRehabBudgetPrice')}
          />
        </FormField>
        <FormField title="Actual Rehab Expenses $" inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpActualRehabExpensesPrice: val })}
            value={getFieldValue('dpActualRehabExpensesPrice')}
          />
        </FormField>
        <FormField title="Actual Rehab Expenses %" inline>
          {formatPercentage(Number(getFieldValue('dpActualRehabExpensesPercentage')))}
        </FormField>
        <FormField title="Target Sale Price" inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpTargetSalePrice: val })}
            value={getFieldValue('dpTargetSalePrice')}
          />
        </FormField>
        <FormField title="Expected List Price" inline>
          <InlineEditDollarsInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyExpectedListingPrice: val })
            }
            value={getFieldValue('departingPropertyExpectedListingPrice')}
          />
        </FormField>
        <FormField title="List Price" inline>
          <InlineEditDollarsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ departingPropertyListingPrice: val })}
            value={getFieldValue('departingPropertyListingPrice')}
          />
        </FormField>
        <FormField title="Listed Date" inline>
          <InlineEditDateInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ departingPropertyListedDate: val })}
            value={getFieldValue('departingPropertyListedDate')}
          />
        </FormField>
        <FormField title="Offers Made" inline>
          <InlineEditIntegerInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ departingPropertyOfferCount: val })}
            value={getFieldValue('departingPropertyOfferCount')}
          />
        </FormField>

        <FormField title="Offer Accepted" inline>
          <InlineEditDateInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyOfferAcceptedDate: val })
            }
            value={getFieldValue('departingPropertyOfferAcceptedDate')}
          />
        </FormField>
        <FormField title="Sale Price" inline>
          <InlineEditDollarsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ departingPropertyActualSalePrice: val })}
            value={getFieldValue('departingPropertyActualSalePrice')}
          />
        </FormField>
        <FormField title="Sale - List Price" inline>
          {formatMoney(hlSimpleSaleLead.departingPropertyPriceDelta)}
        </FormField>
      </Section>
      <Section>
        <FormField title="Actual Gain on Sale" inline>
          {formatMoney(hlSimpleSaleLead.dpActualGainOnSale)}
        </FormField>
        <FormField title="Seller Agent Fee %" inline>
          <InlineEditPercentageInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({
                dpSellerAgentFee: getPercentageValue(val)
              })
            }
            value={getFieldPercentageValue('dpSellerAgentFee')}
          />
        </FormField>
        <FormField title="Estimated Days of Work" inline>
          <InlineEditIntegerInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpEstimatedDaysOfWorkTransaction: val })}
            value={getFieldValue('dpEstimatedDaysOfWorkTransaction')}
          />
        </FormField>
        <FormField title="Estimated List-to-Pending" inline>
          <InlineEditIntegerInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ dpEstimatedListToPendingTransaction: val })
            }
            value={getFieldValue('dpEstimatedListToPendingTransaction')}
          />
        </FormField>
        <FormField title="Estimated Days of Ownership" inline>
          <InlineEditIntegerInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpEstimatedDaysOfOwnership: val })}
            value={getFieldValue('dpEstimatedDaysOfOwnership')}
          />
        </FormField>
        <FormField title="Open Escrow Date" inline>
          <InlineEditDateInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyOpenedSaleEscrowDate: val })
            }
            value={getFieldValue('departingPropertyOpenedSaleEscrowDate')}
          />
        </FormField>
        <FormField title="Target COE Date" inline>
          <InlineEditDateInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpTargetSellCoeDate: val })}
            value={getFieldValue('dpTargetSellCoeDate')}
          />
        </FormField>
        <FormField title="Actual COE Date" inline>
          <InlineEditDateInput
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyCloseOfSaleEscrowDate: val })
            }
            value={getFieldValue('departingPropertyCloseOfSaleEscrowDate')}
          />
        </FormField>
        <FormField title="Actual Days to Listing" inline>
          <InlineEditIntegerInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpActualDaysToListing: val })}
            value={getFieldValue('dpActualDaysToListing')}
          />
        </FormField>
        <FormField title="Actual Days on Market" inline>
          {formatNumber(hlSimpleSaleLead.departingPropertyActualDom)}
        </FormField>
        <FormField title="Actual Days of Ownership" inline>
          {formatNumber(hlSimpleSaleLead.departingPropertyDaysOfOwnership)}
        </FormField>
        <FormField title="Seller credits" inline>
          <InlineEditDollarsAndCentsInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpSellerCredit: val })}
            value={getFieldValue('dpSellerCredit')}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
