import { ReactNode } from 'react'
import { useParams } from 'hooks'
import { ParamsContext } from './ParamsContext'

type Props = {
  children: ReactNode
  defaultParams?: any
  initialParams?: any
}

export const ParamsProvider = ({ children, defaultParams = {}, initialParams = {} }: Props) => {
  return (
    <ParamsContext.Provider value={useParams(defaultParams, initialParams)}>
      {children}
    </ParamsContext.Provider>
  )
}
