// @ts-strict
import { parseISO } from 'date-fns'
import { lookupTaskStatus, TaskStatus, TTaskStatusValue } from 'lookups'
import { TaskResponse } from 'schema'

export const getTaskStatusByDate = (
  task: Pick<TaskResponse, 'completedAt' | 'dueAt' | 'dismissedAt' | 'readyAt' | 'completionNote'>
): TaskStatus => {
  let status: TTaskStatusValue
  const { getTaskStatus } = lookupTaskStatus()
  const dueDate = parseISO(task.dueAt!).setHours(0, 0, 0, 0)
  const todayDate = new Date().setHours(0, 0, 0, 0)

  if (task.completedAt) {
    if (task.completionNote) {
      status = 'completedWithNote'
    } else {
      status = 'completed'
    }
  } else if (task.dismissedAt) {
    status = 'dismissed'
  } else if (task.dueAt) {
    if (dueDate === todayDate) {
      status = 'dueToday'
    } else if (dueDate < todayDate) {
      status = 'pastDue'
    } else {
      status = 'upcoming'
    }
  } else if (task.readyAt) {
    status = 'ready'
  } else {
    status = 'unscheduled'
  }

  return getTaskStatus(status)!
}
