// @ts-strict

import {
  LEADS_CACHE_KEY,
  ORDER_CACHE_KEY,
  ORDERS_CACHE_KEY,
  useMutation,
  useQueryClient
} from 'hooks'
import { putTradeInLead } from 'services/TradeInLead/putTradeInLead'

export const useTradeInLeadController = (tradeInLeadId?: string) => {
  const queryCache = useQueryClient()

  const clearCaches = () => {
    queryCache.invalidateQueries(LEADS_CACHE_KEY)
    queryCache.invalidateQueries(ORDER_CACHE_KEY)
    queryCache.invalidateQueries(ORDERS_CACHE_KEY)
  }

  const updateTradeInLead = useMutation(
    (payload: Record<string, string>) => putTradeInLead(tradeInLeadId, payload),
    { onSuccess: clearCaches }
  )

  return { updateTradeInLead }
}
