// @ts-strict
import React, { ChangeEventHandler } from 'react'
import { cx, randomString } from 'utils'
import styles from './Form.module.scss'
import { FormFieldProps } from './FormField/types'
import { FormField, RadioButton } from '.'

type TProps = {
  checked: boolean
  className?: string
  disabled?: boolean
  label: string
  name?: string
  onChange: ChangeEventHandler<HTMLInputElement>
}
export const RadioButtonField = ({
  onChange,
  checked,
  className,
  disabled,
  label,
  name,
  ...props
}: TProps & FormFieldProps) => {
  const id = randomString()

  return (
    <FormField className={cx(className, styles.checkBoxField)} htmlFor={id} inline {...props}>
      <RadioButton
        checked={checked}
        id={id}
        onChange={onChange}
        disabled={disabled}
        label={label}
        name={name}
        data-testid={`radio-${name}`}
      />
    </FormField>
  )
}
