// @ts-strict
import { Block, Contents, Page, PageFooter, Sections } from 'components'
import { useCurrentUserContext } from 'contexts'
import { environment } from 'environment'
import { useDocumentTitle } from 'hooks'
import style from './HomePage.module.scss'
import { AdministrationSection, DevelopmentSection, MySalesAppSection } from '.'

export const HomePage = () => {
  const { currentUser } = useCurrentUserContext()
  useDocumentTitle('Welcome')

  return (
    <Page title={<p>Welcome, {currentUser?.name}</p>}>
      <Contents>
        <Sections className={style.sections}>
          <MySalesAppSection />

          <AdministrationSection />

          <DevelopmentSection />
        </Sections>
      </Contents>
      <PageFooter>
        <Block
          title="Last Commit SHA"
          subtitle={environment.sourceVersion || 'Not available on Localhost'}
        />
      </PageFooter>
    </Page>
  )
}
