import { css, styled } from '@foundation/themes/styles'
import { BoxProps } from './types'

export const Box = styled.div<BoxProps>`
  ${({
    theme,
    backgroundColor,
    borderWidth,
    borderBottomWidth,
    borderLeftWidth,
    borderRightWidth,
    borderTopWidth,
    borderRadius,
    boxShadow,
    color,
    fontSize,
    fontWeight,
    lineHeight,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    borderColor,
    borderBottomColor,
    borderLeftColor,
    borderRightColor,
    borderTopColor,
    borderStyle,
    flexGrow,
    flexShrink,
    flex,
    width,
    zIndex
  }) => css`
    margin: ${margin && theme.space[margin]};
    margin-bottom: ${marginBottom && theme.space[marginBottom]};
    margin-left: ${marginLeft && theme.space[marginLeft]};
    margin-right: ${marginRight && theme.space[marginRight]};
    margin-top: ${marginTop && theme.space[marginTop]};

    padding: ${padding && theme.space[padding]};
    padding-bottom: ${paddingBottom && theme.space[paddingBottom]};
    padding-left: ${paddingLeft && theme.space[paddingLeft]};
    padding-right: ${paddingRight && theme.space[paddingRight]};
    padding-top: ${paddingTop && theme.space[paddingTop]};

    border-radius: ${borderRadius && theme.radii[borderRadius]};

    border-width: ${borderWidth && theme.borderWidths[borderWidth]};
    border-bottom-width: ${borderBottomWidth && theme.borderWidths[borderBottomWidth]};
    border-left-width: ${borderLeftWidth && theme.borderWidths[borderLeftWidth]};
    border-right-width: ${borderRightWidth && theme.borderWidths[borderRightWidth]};
    border-top-width: ${borderTopWidth && theme.borderWidths[borderTopWidth]};

    border-color: ${borderColor && theme.colors[borderColor]};
    border-bottom-color: ${borderBottomColor && theme.colors[borderBottomColor]};
    border-left-color: ${borderLeftColor && theme.colors[borderLeftColor]};
    border-right-color: ${borderRightColor && theme.colors[borderRightColor]};
    border-top-color: ${borderTopColor && theme.colors[borderTopColor]};

    border-style: ${borderStyle};

    font-size: ${fontSize && theme.fontSizes[fontSize]};
    font-weight: ${fontWeight && theme.fontWeights[fontWeight]};
    color: ${color && theme.colors[color]};

    line-height: ${lineHeight && theme.lineHeights[lineHeight]};

    box-shadow: ${boxShadow && theme.shadows[boxShadow]};

    flex: ${flex};
    flex-grow: ${flexGrow};
    flex-shrink: ${flexShrink};

    background-color: ${backgroundColor && theme.colors[backgroundColor]};

    z-index: ${zIndex && theme.zIndices[zIndex]};

    ${width && `width: ${theme.sizes[width] ? theme.sizes[width] : width};`}
  `}
`
