import { useEffect } from 'react'
import { Contents } from 'components'
import { TransactionsView } from 'content/Transactions'
import { useAgentContext } from 'contexts/Agent'
import { useTransactionsController } from 'controllers'
import { useDocumentTitle, useLocation } from 'hooks'
import { queryStringToObject } from 'utils'

export const AgentTransactionsTab = () => {
  const { agent } = useAgentContext()
  const { setTitle } = useDocumentTitle()
  const { search } = useLocation()
  useEffect(() => setTitle(`${agent.fullName} - Agent Transactions`), [agent, setTitle])

  const searchParams = { ...queryStringToObject(search), agentId: agent.id }
  const { transactions, pageCount, isLoading } = useTransactionsController({ searchParams })

  return (
    <Contents title="Transactions">
      <TransactionsView transactions={transactions} isLoading={isLoading} pageCount={pageCount} />
    </Contents>
  )
}
