import { Option } from 'classes'

export type TModelFieldSetting = {
  allowNegativeNumber?: boolean
  getOptions?: string
  inputType?:
    | 'address'
    | 'boolean'
    | 'date'
    | 'dollarsAndCents'
    | 'integer'
    | 'money'
    | 'percent'
    | 'select'
    | 'text'
  name: string
  options?: Option[]
}

export class ModelField {
  name!: string
  inputType?: string
  options?: Option[]
  allowNegativeNumber?: boolean

  constructor(attributes: ModelField) {
    Object.assign(this, attributes)
  }
}
