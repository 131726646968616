type Setting = {
  isDefault?: boolean
  isOptional?: boolean
  name: string
  value: string
}

export const cashCloseOrdersColumnSettings = [
  {
    name: 'Client / Partner',
    value: 'clientName'
  },
  {
    name: 'Order',
    value: 'orderStageSource',
    isOptional: true,
    isDefault: true
  },
  {
    name: 'Property',
    value: 'property'
  },
  {
    name: 'Lead',
    value: 'lead'
  },
  {
    name: 'Stage / Furthest',
    value: 'stage',
    isOptional: true,
    isDefault: true
  },
  {
    name: 'Agent',
    value: 'agent',
    isOptional: true,
    isDefault: true
  },
  {
    name: 'Tx Team',
    value: 'transactionTeam',
    isOptional: true,
    isDefault: true
  },
  {
    name: 'GPP / Val',
    value: 'gppVal',
    isOptional: true,
    isDefault: true
  },
  {
    name: 'COE Pur / Sale',
    value: 'coe',
    isOptional: true
  },
  {
    name: 'Days Owned',
    value: 'daysOwned',
    isOptional: true
  },
  {
    name: 'Funding Date',
    value: 'fundingDate',
    isOptional: true
  },
  {
    name: 'Created',
    value: 'createdAt',
    isOptional: true,
    isDefault: true
  },
  {
    name: 'Updated',
    value: 'updatedAt',
    isOptional: true,
    isDefault: true
  }
] as const

type TSettings = (typeof cashCloseOrdersColumnSettings)[number] & Setting

type FilterSettings<Condition, S extends TSettings = TSettings> = S extends Condition
  ? S['value']
  : never

export type TCashCloseOrdersColumnValue = FilterSettings<any>
export type TOptionalCashCloseOrdersColumnValue = FilterSettings<{ isOptional: true }>
export type TDefaultCashCloseOrdersColumnValue = FilterSettings<{ isDefault: true }>
