// @ts-strict
import {
  Callout,
  SimpleTable,
  TableHeaderRow,
  TableCell as TD,
  TableHeaderCell as Th,
  TableRow as TR
} from 'components'
import { CCCashOfferLead } from 'models'
import { formatDate } from 'utils'

type TProps = { lead: CCCashOfferLead }
const dateFormat = 'short'

export const CCCashOfferLeadCallout = ({ lead }: TProps) => {
  return (
    <Callout>
      <SimpleTable title="Close Of Escrow Date">
        <TableHeaderRow>
          <Th>Type</Th>
          <Th>Actual</Th>
          <Th>HLHL</Th>
        </TableHeaderRow>
        <tbody>
          <TR>
            <TD>IR Purchase</TD>
            <TD>
              {formatDate(
                lead?.activeCashOfferLead?.incomingPropertyCloseOfPurchaseEscrowAt,
                dateFormat
              )}
            </TD>
            <TD>n/a</TD>
          </TR>
          <TR>
            <TD>IR Sell</TD>
            <TD>
              {formatDate(
                lead?.activeCashOfferLead?.incomingPropertyCloseOfSellEscrowAt,
                dateFormat
              )}
            </TD>
            <TD>{formatDate(lead?.order?.closeOfEscrowOnHLHL, dateFormat)}</TD>
          </TR>
        </tbody>
      </SimpleTable>
    </Callout>
  )
}
