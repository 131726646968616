// @ts-strict
import { SourcePartnerResponse } from 'schema'

export class SourcePartner {
  id!: string
  slug!: string

  constructor(sourcePartnerAttributes: SourcePartner) {
    Object.assign(this, sourcePartnerAttributes)
  }
  static create(sourcePartnerResponse: Potential<SourcePartnerResponse>): Potential<SourcePartner> {
    if (!sourcePartnerResponse) {
      return
    }
    return new SourcePartner(sourcePartnerResponse)
  }
}
