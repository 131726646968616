import { Lead } from 'models'
import { getHomeValueEstimateUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchHomeValueEstimate = async (lead: Lead) => {
  const url = getHomeValueEstimateUrl({
    searchParams: {
      property_uuid: lead.propertyUuid,
      state_code: lead.stateCode
    }
  })

  const response = await getRequest(url)

  return await deserializeAsync(response.data)
}
