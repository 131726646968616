// @ts-strict
import { ReactNode } from 'react'
import { createCtx } from 'contexts/utils'
import { useLeadGroupableController } from 'controllers'
import { LeadGroupableType } from 'models'

type LeadGroupablesContext = ReturnType<typeof useLeadGroupableController> & {
  leadGroupableId: string
  leadGroupableType: LeadGroupableType
}

export const [useLeadGroupableContext, LeadGroupableContextProvider] =
  createCtx<LeadGroupablesContext>()

type Props = {
  children: ReactNode
  leadGroupableId: string
  leadGroupableType: LeadGroupableType
}

export const LeadGroupableProvider = ({ children, leadGroupableType, leadGroupableId }: Props) => {
  const value = {
    ...useLeadGroupableController(leadGroupableType, leadGroupableId),
    leadGroupableId,
    leadGroupableType
  }
  return <LeadGroupableContextProvider value={value}>{children}</LeadGroupableContextProvider>
}
