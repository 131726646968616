import {
  ApplyFiltersButton,
  CheckBoxArray,
  Column,
  Columns,
  Filters,
  Icon,
  MenuSection,
  Section,
  SectionFooter,
  Select,
  SelectBoolean
} from 'components'
import { useNewFilters } from 'hooks'
import {
  blankOptions,
  cashCloseOrderStagesOptions,
  cashCloseUserTypeOptions,
  ccCashOfferLeadStagesOptions,
  ccTradeInLeadStagesOptions,
  mortgageStagesOptions,
  protectedOfferStatusOptions,
  sourcesOptions,
  trueFalseOptions,
  useTransactionTeams
} from 'lookups'
import styles from './OrdersListFilters.module.scss'
import { Params, ProductType } from './types'

export const CashCloseOrdersListFilters = () => {
  const { newParams, set } = useNewFilters<Params>()
  const { transactionTeamsMembersOptions } = useTransactionTeams()

  return (
    <Filters title="Filters" icon={Icon.Filter}>
      <Section className={styles.section}>
        <Columns>
          <Column>
            <MenuSection title="Lead Type">
              <CheckBoxArray
                options={cashCloseUserTypeOptions}
                value={newParams.userType}
                onChange={(val: ProductType[]) => set('userType', val)}
              />
            </MenuSection>
            <MenuSection title="Transaction Team Member">
              <Select
                value={newParams.transactionTeamMember}
                options={blankOptions.concat(transactionTeamsMembersOptions)}
                onChange={val => set('transactionTeamMember', val)}
              />
            </MenuSection>
          </Column>
          <Column>
            <MenuSection title="Sources">
              <CheckBoxArray
                options={sourcesOptions}
                value={newParams.source}
                onChange={val => set('source', val)}
              />
            </MenuSection>
            <MenuSection title="Order Stages">
              <CheckBoxArray
                options={cashCloseOrderStagesOptions}
                value={newParams.orderStage}
                onChange={val => set('orderStage', val)}
              />
            </MenuSection>
          </Column>
          <Column title="Trade In Stages">
            <CheckBoxArray
              options={ccTradeInLeadStagesOptions}
              value={newParams.tradeInLeadStages}
              onChange={val => set('tradeInLeadStages', val)}
            />
          </Column>
          <Column title="Cash Offer Stages">
            <CheckBoxArray
              options={ccCashOfferLeadStagesOptions}
              value={newParams.cashOfferLeadStages}
              onChange={val => set('cashOfferLeadStages', val)}
            />
          </Column>
          <Column title="Mortgage Stages">
            <CheckBoxArray
              options={mortgageStagesOptions}
              value={newParams.mortgageLeadStages}
              onChange={val => set('mortgageLeadStages', val)}
            />
          </Column>
          <Column>
            <MenuSection title="Departing Residence">
              <MenuSection title="Inspected">
                <Select
                  value={newParams.inspected}
                  options={blankOptions.concat(trueFalseOptions)}
                  onChange={val => set('inspected', val)}
                />
              </MenuSection>
              <MenuSection title="Valuation Done">
                <Select
                  value={newParams.valuation}
                  options={blankOptions.concat(trueFalseOptions)}
                  onChange={val => set('valuation', val)}
                />
              </MenuSection>
              <MenuSection title="Protected Offer">
                <Select
                  value={newParams.protectedOfferStatus}
                  options={blankOptions.concat(protectedOfferStatusOptions)}
                  onChange={val => set('protectedOfferStatus', val)}
                />
              </MenuSection>
              <MenuSection title="Express Close">
                <SelectBoolean
                  value={newParams.expressClose}
                  onChange={val => set('expressClose', val)}
                />
              </MenuSection>
            </MenuSection>
          </Column>
        </Columns>
        <SectionFooter>
          <ApplyFiltersButton newParams={newParams} />
        </SectionFooter>
      </Section>
    </Filters>
  )
}
