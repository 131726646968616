// @ts-strict
import { SettlementTeamMemberResponse } from 'schema'

export type SettlementTeamMemberConstructor = Omit<SettlementTeamMember, 'getFullName'>

export class SettlementTeamMember {
  id!: string
  email?: string
  firstName?: string
  lastName?: string
  phone?: string

  constructor(escrowOfficeLeadAttributes: SettlementTeamMemberConstructor) {
    Object.assign(this, escrowOfficeLeadAttributes)
  }

  static create(
    settlementTeamMember: Potential<SettlementTeamMemberResponse>
  ): Potential<SettlementTeamMember> {
    if (!settlementTeamMember) {
      return
    }

    return new SettlementTeamMember({
      ...settlementTeamMember
    })
  }

  getFullName() {
    const fullName = [this.firstName, this.lastName].filter(Boolean).join(' ')
    return fullName
  }
}
