// @ts-strict
import { Text } from '@foundation/components'
import { FormField, Section, Select, SelectOptionGroup } from 'components'
import { IOptionable, LeadStage } from 'lookups'
import { presence } from 'utils'
import * as S from './styles'

interface UpdateStageProps<T, K> {
  currentStage?: T
  furthestStage?: T
  hideOther?: boolean
  newStage?: T
  payload: K
  setPayloadItem?: (key: keyof UpdateStageProps<T, K>['payload'], value: string) => void
}

export const UpdateStage = <T extends IOptionable & LeadStage, K extends Record<string, any>>({
  payload,
  setPayloadItem,
  currentStage,
  furthestStage,
  newStage,
  hideOther
}: UpdateStageProps<T, K>) => {
  return (
    <Section>
      <S.CurrentStage>
        <Text size="sm" as="span">
          Current Stage:
        </Text>{' '}
        {currentStage?.name && (
          <Text size="sm" weight="bold" as="span">
            {currentStage.name}
          </Text>
        )}
      </S.CurrentStage>
      <FormField title="Update Stage" required>
        <Select
          value={payload.disposition}
          onChange={val => setPayloadItem && setPayloadItem('disposition', val)}
          data-testid="stage-drop-down"
        >
          {!currentStage?.other && presence(currentStage?.previousStageOptions) && (
            <SelectOptionGroup
              label="Previous"
              options={currentStage!.previousStageOptions || []}
              data-testid="previous-stage-options"
            />
          )}
          {currentStage?.other && furthestStage && (
            <SelectOptionGroup
              label="Previous"
              options={[...(furthestStage.previousStageOptions || []), furthestStage.toOption()]}
              data-testid="previous-stage-options"
            />
          )}
          {currentStage?.other && !furthestStage && newStage && (
            <SelectOptionGroup
              label="Previous"
              options={[...(newStage.previousStageOptions || []), newStage.toOption()]}
              data-testid="previous-stage-options"
            />
          )}
          <SelectOptionGroup
            label="Current"
            options={currentStage ? [currentStage?.toOption()!] : []}
            data-testid="current-stage-options"
          />
          {presence(currentStage?.nextStageOptions) && (
            <SelectOptionGroup
              label="Next"
              options={currentStage!.nextStageOptions!}
              data-testid="next-stage-options"
            />
          )}
          {!hideOther ? (
            <SelectOptionGroup
              label="Other"
              options={currentStage?.otherStageOptions!}
              data-testid="other-stage-options"
            />
          ) : null}
        </Select>
      </FormField>
    </Section>
  )
}
