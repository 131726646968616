// @ts-strict
import { TMarketplaceProgramSlug } from 'lookups'
import { camelize } from 'utils'

export type TMarketplaceProgramParams = Partial<Record<TMarketplaceProgramSlug, 'enrolled'>>

export const getMarketplaceProgramParams = (programs: TMarketplaceProgramSlug[]) => {
  return programs.reduce<TMarketplaceProgramParams>((acc, program) => {
    return {
      ...acc,
      [camelize(program)]: 'enrolled'
    }
  }, {})
}
