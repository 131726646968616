import { orderColumns } from './ordersColumnsSettings'

export const queueOrdersColumnsSettings = [
  orderColumns.clientName,
  orderColumns.property,
  orderColumns.lead,
  orderColumns.stage,
  orderColumns.agentsOpps,
  orderColumns.price,
  orderColumns.createdAt,
  orderColumns.updatedAt,
  orderColumns.introSentAt
] as const

export type TQueueOrdersColumnValue = (typeof queueOrdersColumnsSettings)[number]['value']
