import { TModelFieldSetting } from '../ModelField'

export type TMasterPropertyFieldAttribute = 'displayAddress'

export type TMasterPropertyFieldSetting = {
  attr: TMasterPropertyFieldAttribute
} & TModelFieldSetting

export const masterPropertyFieldsSettings: TMasterPropertyFieldSetting[] = [
  { attr: 'displayAddress', name: 'Address' }
]
