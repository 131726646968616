// @ts-strict
import { Option } from 'classes'
import { IOptionable } from 'lookups'
import { CapitalPartnerResponse } from 'schema'

export class CapitalPartner implements IOptionable {
  fundingFacility?: string
  id!: string
  name?: string

  constructor(capitalPartnerAttributes: Omit<CapitalPartner, 'toOption'>) {
    Object.assign(this, capitalPartnerAttributes)
  }

  static create(
    capitalPartnerResponse: Potential<CapitalPartnerResponse>
  ): Potential<CapitalPartner> {
    if (!capitalPartnerResponse) {
      return
    }

    return new CapitalPartner(capitalPartnerResponse)
  }

  toOption() {
    return new Option({ name: this.name, value: this.id })
  }
}
