import { ReactNode } from 'react'
import { cx } from 'utils'
import { If } from '../If/If'
import styles from './Console.module.scss'
import { ConsoleAside } from './ConsoleAside'
import { ConsoleDetails } from './ConsoleDetails'

type ConsoleProps = {
  aside?: ReactNode
  children: ReactNode
  className?: string
  'data-testid'?: string
  details?: ReactNode
  flip?: boolean
  onClick?: () => void
}

export const Console = ({
  className,
  aside,
  details,
  flip,
  onClick,
  children,
  ...rest
}: ConsoleProps) => {
  return (
    <div className={cx(styles.root, className)} onClick={onClick} {...rest}>
      <If test={!flip && aside}>
        <ConsoleAside>{aside}</ConsoleAside>
      </If>
      <If test={details}>
        <ConsoleDetails>{details}</ConsoleDetails>
      </If>
      <If test={flip && aside}>
        <ConsoleAside>{aside}</ConsoleAside>
      </If>
      {children}
    </div>
  )
}
