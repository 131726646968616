// @ts-strict
import { TableHeaderRow, TableHeaderColumn as TH } from 'components'
import { useListContext } from 'contexts'

export const AgentPartnershipTableHeaderCells = () => {
  const { findColumn, visibleColumns } = useListContext()
  return (
    <TableHeaderRow>
      {visibleColumns.map((visibleColumn, idx) => {
        const column = findColumn(visibleColumn)
        if (!column) {
          return null
        }

        const { name, sort, columnSize, align, title } = column!

        const sortOptions =
          sort?.map(sortAttr => ({
            name: sortAttr,
            slug: sortAttr,
            flip: true
          })) ?? []

        return (
          <TH
            fixed={idx === 0}
            key={visibleColumn}
            size={columnSize}
            align={align}
            title={title}
            name={name}
            sort={sortOptions}
          />
        )
      })}
    </TableHeaderRow>
  )
}
