import React from 'react'
import { AudioPlayer } from 'components/AudioPlayer/index'
import { Block } from 'components/Block/index'
import { ViewMore } from 'components/ViewMore/index'
import { Activity } from 'models'
import { cx, formatDate, formatTime } from 'utils'
import styles from './Activity.module.scss'
import { ActivityTransition } from './ActivityTransition'
import { getIcon, getTheme } from './utils'

type ActivityListItemProps = {
  activity: Activity
}

export const ActivityListItem = ({ activity }: ActivityListItemProps) => {
  const { title, transitions, body, audioUrl, occurredAt, type, subType } = activity
  return (
    <li
      className={cx(getTheme(type, subType) ? styles[getTheme(type, subType)] : null, styles.item)}
    >
      {getIcon(type, subType) ? <div className={styles.icon}>{getIcon(type, subType)}</div> : null}
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
        </div>
        {transitions ? <ActivityTransition transitions={transitions} /> : null}
        {body ? <ViewMore>{body}</ViewMore> : null}
        {audioUrl ? <AudioPlayer src={audioUrl} className={styles.audioPlayer} /> : null}
      </div>
      <div className={styles.aside}>
        <Block
          className={styles.date}
          title={formatDate(occurredAt, 'short')}
          subtitle={formatTime(occurredAt)}
        />
      </div>
    </li>
  )
}
