import { UseFetchData } from 'controllers/types'
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'hooks'
import { ActivityFeedInterface } from 'models/v2/ActivityFeed'
import { ActivityFeedSchema } from 'schema'
import { fetchActivitiesFeed, TFetchItems } from 'services'
import { ActivitiesFeedParams } from 'services/ActivitityFeed/getSearchParams'
import { getNextPageParam } from 'utils'
import { activityFeedKeys } from '../../commons/hooks'

type QueryOptions = UseInfiniteQueryOptions<
  TFetchItems<ActivityFeedInterface>,
  unknown,
  TFetchItems<ActivityFeedInterface>,
  TFetchItems<ActivityFeedInterface>,
  ReturnType<typeof activityFeedKeys.thisLeadList>
>

const defaultSearchSettings: ActivitiesFeedParams = {
  perPage: 25
}

const FETCH_ACTIVITIES_SHAPE: ActivityFeedSchema = {
  activityFeedItems: [
    { agent: ['firstName', 'lastName'] },
    { lead: ['name', 'userType', 'stage'] },
    'occurredAt',
    'meta',
    'title',
    'name'
  ]
}

export const useInfiniteFetchThisLeadActivities = ({
  searchParams,
  options
}: UseFetchData<ActivitiesFeedParams, QueryOptions>) => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  return useInfiniteQuery(
    activityFeedKeys.thisLeadList(searchSettings),
    ctx =>
      fetchActivitiesFeed<ActivityFeedInterface>(FETCH_ACTIVITIES_SHAPE, {
        ...searchSettings,
        page: ctx.pageParam
      }),
    {
      ...options,
      getNextPageParam: getNextPageParam,
      refetchOnWindowFocus: false,
      staleTime: 300000 // 5min
    }
  )
}
