import { ToggleSelect } from 'components'

type Props = {
  onChange: (val) => void
  value: string
}

export const AgentsClosingServicesFilter = ({ value, onChange }: Props) => {
  const offOption = { name: 'CS', value: undefined }
  const onOption = { name: 'CS', value: 'enrolled' }

  return (
    <ToggleSelect offOption={offOption} onOption={onOption} value={value} onChange={onChange} />
  )
}
