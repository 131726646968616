import { ReactNode } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { Block, Console, ConsoleAside, ConsoleDetails, Section, TabNav, Tabs } from 'components'
import { EditTeamButtonForm } from 'content/Team'
import { TeamProvider, useTeamContext } from 'contexts/Team'
import styles from './TeamPage.module.scss'

const TeamPageContents = ({ children }: { children: ReactNode }) => {
  const { team, teamId } = useTeamContext()

  if (!team) {
    return null
  }

  return (
    <>
      <Console>
        <ConsoleAside className={styles.aside}>
          <Section className={styles.summary}>
            <Block
              title={team.name}
              subtitle={team.description}
              actions={<EditTeamButtonForm team={team} />}
            />
            <Block subtitle={team.enabled ? 'ENABLED' : 'NOT ENABLED'} />
          </Section>
        </ConsoleAside>
        <ConsoleDetails className={styles.details}>
          <Tabs className={styles.tabs} sticky>
            <TabNav to={`/teams/${teamId}`}>Members</TabNav>
            <TabNav to={`/teams/${teamId}/agents`}>Agents</TabNav>
          </Tabs>
          {children}
        </ConsoleDetails>
      </Console>
    </>
  )
}

export const TeamPage = () => {
  const { teamId } = useParams()
  return (
    <TeamProvider teamId={teamId}>
      <TeamPageContents>
        <Outlet />
      </TeamPageContents>
    </TeamProvider>
  )
}
