import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { formatPageUrlToTitle } from './formatPageUrlToTitle'

export const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: formatPageUrlToTitle(location.pathname),
      page_path: `${location.pathname}${location.search ?? ''}${location.hash ?? ''}`,
      ...(location.search && { page_search: location.search }),
      ...(location.hash && { page_hash: location.hash })
    })
  }, [location])
}
