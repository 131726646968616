type TLookupResponse = {
  description: string
  id: string
  kind: string
  name: string
  slug: string
}

export const transformLookup = (lookup: TLookupResponse): TLookupType => {
  const { slug, kind, ...others } = lookup
  return { ...others, value: slug, type: kind, slug }
}
