export const CCTradeInAgreementSigned = () => {
  return null
  // TODO: Add when Capital Markets Facility endpoint is finished on Backend
  // return (
  //   <Section title="Financing">
  //     <FormField title="Capital Markets Facility" required>
  //       <Select options={[]} />
  //     </FormField>
  //   </Section>
  // )
}
