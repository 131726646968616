import { matchRoutes, useLocation } from 'react-router-dom'
import { TabbedContent } from 'components'
import { ListAgentComms } from '../../listAgentComms'
import { ListClientComms } from '../../listClientComms'
import { ListGlobalComms } from '../../listGlobalComms'
import { CommsFiltersProvider } from './CommsFiltersContext'
import { thisClientTabPathsToMatch } from './utils'
import { thisAgentTabPathsToMatch } from './utils'

export const CommsDrawerTabs = () => {
  const location = useLocation()
  const thisClientTabMatch = matchRoutes(thisClientTabPathsToMatch, location.pathname)
  const thisAgentTabMatch = matchRoutes(thisAgentTabPathsToMatch, location.pathname)

  const tabContent = {
    ...(thisClientTabMatch && { 'This Client': <ListClientComms /> }),
    ...(thisAgentTabMatch && { 'This Agent': <ListAgentComms /> }),
    Global: <ListGlobalComms />
  }
  return <TabbedContent content={tabContent} />
}

export const CommsDrawer = () => {
  return (
    <CommsFiltersProvider>
      <CommsDrawerTabs />
    </CommsFiltersProvider>
  )
}
