// @ts-strict
import { useState } from 'react'
import type { FunctionComponent } from 'react'
import { Button, Icon } from '@foundation/components'
import { TableCellSimple, TableCell as Td } from 'components'
import type { UseTableCellProps } from 'components'
import { FileCarousel } from 'content/Files'
import type { INSPECTION_DEFICIENCY_COLUMN_SLUGS_TYPE } from 'lookups'
import type { InspectionDeficiency } from 'models'
import { dataOrDash, DEFAULT_STRING } from 'utils'

export const InspectionTypesCell = ({
  slug,
  value,
  attachedColumnValue,
  ...tdProps
}: UseTableCellProps<InspectionDeficiency['reportInspectionTypes']>) => {
  return <Td {...tdProps}>{dataOrDash(value?.map(el => <div key={el.name}>{el.name}</div>))}</Td>
}
export const FilesCell = ({
  slug,
  value,
  attachedColumnValue,
  ...tdProps
}: UseTableCellProps<InspectionDeficiency['files']>) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1)
  const currentFile = value?.[selectedImageIndex]
  const isOpen = selectedImageIndex !== -1
  const valueLength = value?.length ?? 0

  const nextSlide = () => {
    const index = selectedImageIndex === valueLength - 1 ? 0 : selectedImageIndex + 1
    setSelectedImageIndex(index)
  }

  const prevSlide = () => {
    const index = selectedImageIndex === 0 ? valueLength - 1 : selectedImageIndex - 1
    setSelectedImageIndex(index)
  }

  return (
    <Td {...tdProps}>
      {value?.map((option, i) => (
        <div key={option.value}>
          <Button
            variant="ghost"
            size="xs"
            title="See image"
            onClick={() => setSelectedImageIndex(i)}
          >
            {`Image ${i + 1}`}
          </Button>
        </div>
      ))}
      {value && currentFile && (
        <FileCarousel
          title={currentFile.name}
          fileId={currentFile.value}
          isOpen={isOpen}
          onNextSlide={nextSlide}
          onPrevSlide={prevSlide}
          onClose={() => {
            setSelectedImageIndex(-1)
          }}
        />
      )}

      {!value && DEFAULT_STRING}
    </Td>
  )
}
export const DetailCell = ({
  slug,
  value,
  attachedColumnValue,
  ...tdProps
}: UseTableCellProps<InspectionDeficiency['detail']>) => {
  return <Td {...tdProps}>{dataOrDash(value)}</Td>
}
export const EscalatedCell = ({
  slug,
  value,
  attachedColumnValue,
  ...tdProps
}: UseTableCellProps<InspectionDeficiency['escalated']>) => {
  return <Td {...tdProps}>{value === true && <Icon path="Warning" title="Escalated" />}</Td>
}
const columnMapping: PartialRecord<
  INSPECTION_DEFICIENCY_COLUMN_SLUGS_TYPE,
  FunctionComponent<UseTableCellProps>
> = {
  reportInspectionTypes: InspectionTypesCell,
  files: FilesCell,
  detail: DetailCell,
  escalated: EscalatedCell
}
export const InspectionDeficiencyTableCell = (props: UseTableCellProps) => {
  const Cell = columnMapping[props.slug!] ?? TableCellSimple
  return <Cell {...props} />
}
