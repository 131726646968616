// @ts-strict

import { Option } from 'classes'
import { useLookupsController } from 'controllers/Lookups'
import { Lead, Order } from 'models'
import { byValue } from 'utils'
import { LeadUserType } from './LeadUserType'
import { TLeadUserTypeServerSlug } from './LeadUserType'
import { LeadUserTypeSettings } from './leadUserTypeSettings'

export const useLeadUserTypes = () => {
  const [leadUserTypesLookup] = useLookupsController('lead_user_types')

  const leadUserTypes = leadUserTypesLookup.map(
    item =>
      new LeadUserType({
        name: item.name!,
        value: item.value as TLeadUserTypeServerSlug
      })
  )

  const filteredLeadUserTypes = (filterName: keyof LeadUserTypeSettings) =>
    leadUserTypes.filter(item => item[filterName]).map(item => item.toOption())

  const getLeadUserTypesByValue = (value?: TLeadUserTypeServerSlug[]) => {
    return leadUserTypes.filter(byValue(value))
  }
  const getLeadUserTypesByOrder = (lead: Lead, order: Order): Option[] => {
    const leadUserType = leadUserTypes.find(byValue(lead.userType))
    return getLeadUserTypesByValue(leadUserType?.duplicatableLeadUserTypeSlugs)
      .filter(item => !order.getIneligibleLeadUserTypeSlugs().includes(item.value))
      .map(item => item.toOption())
  }

  const getLeadUserType = (value?: TLeadUserTypeServerSlug): Potential<LeadUserType> =>
    leadUserTypes.find(byValue(value))

  return {
    leadUserTypes,
    filteredLeadUserTypes,
    getLeadUserTypesByOrder,
    getLeadUserType
  }
}
