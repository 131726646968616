import { useEffect, useState } from 'react'
import { AddressUnitAutoComplete } from 'components'
import { cx, dataOrDash } from 'utils'
import { DisplayValue, InlineProps } from '../DisplayValue'
import style from './InlineEditAddressUnit.module.scss'

type Props = {
  className?: string
  onBlur?: (val: string) => void
  placeholderAddress?: string
  placeholderUnit?: string
  value?: string
} & InlineProps

export const InlineEditAddressUnit = ({
  onBlur = (_: string) => {},
  value,
  placeholderAddress,
  placeholderUnit,
  isLoading,
  isError,
  format,
  className
}: Props) => {
  const [currentValue, setCurrentValue] = useState(value || '')
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleOnSelect = (selectedValue: string) => {
    if (selectedValue !== value) {
      onBlur(selectedValue)
    }
    setEditMode(false)
    setCurrentValue(selectedValue)
  }

  const stopEditMode = () => {
    setEditMode(false)
  }

  const setEditModeTrue = () => {
    setEditMode(true)
  }

  const formatter = (changedValue: string) => {
    return format ? format(changedValue) : changedValue
  }

  if (editMode) {
    return (
      <AddressUnitAutoComplete
        value={currentValue}
        onBlur={stopEditMode}
        onSelect={handleOnSelect}
        placeholderAddress={placeholderAddress}
        placeholderUnit={placeholderUnit}
        className={cx(style.address, className)}
        autoFocus
      />
    )
  }

  return (
    <DisplayValue
      value={dataOrDash(currentValue)}
      setEditMode={setEditModeTrue}
      format={formatter}
      isLoading={isLoading}
      isError={isError}
      className={className}
    />
  )
}
