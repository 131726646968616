import { TLeadUserTypeServerSlug } from 'lookups'
import { getLeads5Url } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export type PostDuplicatableLeadPayload =
  | {
      agentAeId?: string
      cashOfferLeadAttributes?: {
        incomingPropertyBuyerAgentId?: string
        incomingPropertyFullAddress?: string
      }
      cityName?: string
      duplicate: boolean
      duplicateFromLeadId: string
      fullAddress?: string
      orderId: string
      originalCreatedAt?: string
      price?: string | number
      propertyType?: string
      referringOwnerId?: string
      source: string
      sourceForm: string
      sourcePageType: string
      stateName?: string
      timeline?: string
      tradeInLeadAttributes?: {
        departingPropertySellerAgentId?: string
        incomingPropertyFullAddress?: string
        simpleSale?: string
      }
      userType: TLeadUserTypeServerSlug
    }
  | {}

export const postDuplicatableLead = (payload: PostDuplicatableLeadPayload) => {
  return postRequest(getLeads5Url(), snakeCaseKeys(payload))
}
