import { Section } from 'components'
import { AgentProgramsFormButton } from 'content/Agent'
import { Agent } from 'models'
import { cx } from 'utils'
import { AgentPrograms } from '..'
import styles from './AgentProgramsCertifications.module.scss'

type Props = {
  agent: Agent
  className?: string
}

export const AgentProgramsCertifications = ({ agent, className }: Props) => {
  return (
    <Section
      title="Program Enrollments"
      className={cx(styles.agentProgramCertifications, className)}
      actions={<AgentProgramsFormButton agent={agent} />}
    >
      <AgentPrograms agent={agent} />
    </Section>
  )
}
