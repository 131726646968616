import { useState } from 'react'
import useWebSocket from 'react-use-websocket'
import { useMutation } from 'hooks'
import { postWebSocketTickets } from 'services'
import { camelcaseKeys } from 'utils'
import { parseStringToObject, stringifyObject } from './helpers'
import { DefaultContract, WebSocketProps } from './types'

const formatMessageToSend = <Message extends object>(message: Message) => {
  return stringifyObject<Message>({ obj: message, transformKeys: 'snakeCase' })
}

const formatReceivedMessage = <Message>(message: string | undefined): Message | undefined => {
  if (!message || message === '') {
    return
  }

  const newMessageObject = parseStringToObject<Message>(message)

  if (newMessageObject) {
    const messageParsedWithCamelizedKeys = camelcaseKeys(newMessageObject)
    return messageParsedWithCamelizedKeys as Message
  }

  return
}

export const useWebSocketController = <WebSocketType extends DefaultContract>({
  onMessage,
  onOpen,
  onClose
}: WebSocketProps<WebSocketType> = {}) => {
  const [URL, setURL] = useState<string>('')
  const [toggleConnection, setToggleConnection] = useState(false)
  const createWebSocketTicket = useMutation(postWebSocketTickets, {
    onSuccess: ({ authURL }) => {
      if (authURL) {
        setURL(authURL)
        setToggleConnection(true)
      }
    }
  })

  const { sendMessage: sendJsonMessage } = useWebSocket(
    URL,
    {
      onOpen,
      onMessage: ({ data: response }) => {
        const data = formatReceivedMessage<WebSocketType>(response)
        if (data?.type === 'ping') {
          return
        }
        onMessage?.({
          message: data?.message,
          type: data?.type
        })
      },
      onClose
    },
    toggleConnection
  )

  const sendMessage = <Message extends object>(message: Message) => {
    if (!message && formatMessageToSend(message) !== '') {
      return
    }

    sendJsonMessage(formatMessageToSend<Message>(message))
  }

  const cancelConnection = (resetCallback: () => void) => {
    resetCallback()
    setToggleConnection(false)
  }

  return {
    cancelConnection,
    createWebSocketTicket,
    sendMessage
  }
}
