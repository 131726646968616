// @ts-strict
import { Option } from 'classes'
import { CashOfferLead } from 'models'
import { TModelFieldSetting } from '../ModelField'

export type TCashOfferLeadFieldAttribute =
  | 'buyerAgentTeam'
  | 'calculatedValue'
  | 'capitalMarketsFacilityName'
  | 'cashOfferFeeAmount'
  | 'cashOfferFeeMethod'
  | 'cashOfferFeeRate'
  | 'changeReasons.incomingPropertyCloseOfPurchaseEscrowAt.note'
  | 'changeReasons.incomingPropertyCloseOfSellEscrowAt.note'
  | 'changeReasons.expressClose.note'
  | 'changeReasons.incomingPropertyCloseOfPurchaseEscrowAt.reason'
  | 'changeReasons.incomingPropertyCloseOfSellEscrowAt.reason'
  | 'changeReasons.expressClose.reason'
  | 'createdAt'
  | 'depositRate'
  | 'email'
  | 'email1'
  | 'emailAlt'
  | 'expressClose'
  | 'fullName'
  | 'incomingPropertyBuyerAgentId'
  | 'incomingPropertyCloseOfPurchaseEscrowAt'
  | 'incomingPropertyCloseOfSellEscrowAt'
  | 'incomingPropertyFullAddress'
  | 'incomingPropertyFundsWiredToSellerAt'
  | 'incomingPropertyInspectionContingencyWaived'
  | 'incomingPropertyListPrice'
  | 'incomingPropertyMaxOfferPrice'
  | 'incomingPropertyOfferAcceptedAt'
  | 'incomingPropertyOfferCancelledReason'
  | 'incomingPropertyOfferRejectedAt'
  | 'incomingPropertyOfferRejectedReason'
  | 'incomingPropertyOpenPurchaseEscrowAt'
  | 'incomingPropertyOpenSellEscrowAt'
  | 'incomingPropertySalePrice'
  | 'incomingPropertyValuation'
  | 'ipAdditionalDeposit'
  | 'ipAvmValuation'
  | 'ipAvmValuationAt'
  | 'ipBuyersAgentCommission'
  | 'ipBuyersAgentCommissionRate'
  | 'ipCapitalDaysOutstanding'
  | 'ipCashOfferDeposit'
  | 'ipContingencyRemovalAt'
  | 'ipEarnestMoney'
  | 'ipFundingAmount'
  | 'ipHomeownerAssociation'
  | 'ipInsuranceCoverage'
  | 'ipMaxOfferPriceAtHlValuation'
  | 'ipMaxOfferPriceWithoutBuffer'
  | 'ipNetSettlement'
  | 'ipOfferTermsDaysTillCloseOfEscrow'
  | 'ipOfferTermsDaysTillInspectionContingencyEnds'
  | 'ipOfferTermsDeadlineDate'
  | 'ipOfferTermsInspectionObjectionDeadlineDate'
  | 'ipOfferTermsInspectionResolutionDeadlineDate'
  | 'ipOfferTermsInspectionTerminationDeadlineDate'
  | 'ipOfferTermsOptionTerminationFee'
  | 'ipPropertyType'
  | 'ipSolarPanels'
  | 'ipTargetCloseOfPurchaseEscrowAt'
  | 'ipTargetCloseOfSellEscrowAt'
  | 'licenseNumber'
  | 'name'
  | 'number'
  | 'partnerName'
  | 'phoneAlt'
  | 'phoneMobile'
  | 'protectedOfferStatus'
  | 'reasonForFail'
  | 'refAgentName'
  | 'refAgentPhoneMobile'
  | 'refAgentTeam'
  | 'referringOwnerId'
  | 'revenueRecognitionDate'
  | 'source'
  | 'ipBorrowingBase'
  | 'ipHlEquity'
  | 'ipSellerCredits'

export type TCashOfferLeadFieldSetting = {
  attr: TCashOfferLeadFieldAttribute
} & TModelFieldSetting

type OptionsCashOfferFeeMethodValue = Extract<
  CashOfferLead['cashOfferFeeMethod'],
  'rate' | 'amount'
>

export const optionsCashOfferFeeMethod: Option<OptionsCashOfferFeeMethodValue>[] = [
  { value: 'rate', name: 'Rate' },
  { value: 'amount', name: 'Amount' }
]

export const cashOfferLeadFieldsSettings: TCashOfferLeadFieldSetting[] = [
  { attr: 'buyerAgentTeam', name: 'Buyer Agent Team' },
  { attr: 'calculatedValue', name: 'Cash Offer Fee' },
  { attr: 'capitalMarketsFacilityName', name: 'Capital Markets Facility' },
  { attr: 'cashOfferFeeRate', name: 'Cash Offer Fee %', inputType: 'percent' },
  {
    attr: 'changeReasons.incomingPropertyCloseOfPurchaseEscrowAt.reason',
    name: 'Reason for change to Actual COE Purchase Date',
    inputType: 'select',
    getOptions: 'coeDateChangeReasons'
  },
  {
    attr: 'changeReasons.incomingPropertyCloseOfPurchaseEscrowAt.note',
    name: 'Note about change to Actual COE Purchase Date',
    inputType: 'text'
  },
  {
    attr: 'changeReasons.expressClose.note',
    name: 'Change Notes for Express Close',
    inputType: 'text'
  },
  {
    attr: 'changeReasons.incomingPropertyCloseOfSellEscrowAt.reason',
    name: 'Reason for change to Actual COE Sale Date',
    inputType: 'select',
    getOptions: 'coeDateChangeReasons'
  },
  {
    attr: 'changeReasons.expressClose.reason',
    name: 'Change Reason for Express Close',
    inputType: 'select',
    getOptions: 'expressCloseChangeReasons'
  },
  {
    attr: 'changeReasons.incomingPropertyCloseOfSellEscrowAt.note',
    name: 'Note about change to Actual COE Sale Date',
    inputType: 'text'
  },
  { attr: 'createdAt', name: 'Date Received' },
  { attr: 'depositRate', name: 'Deposit %', inputType: 'percent' },
  { attr: 'email1', name: 'Buyer Agent Email' },
  { attr: 'email', name: 'Client Email' },
  { attr: 'emailAlt', name: 'Spouse / Partner Email' },
  { attr: 'fullName', name: 'Buyer Agent Name' },
  { attr: 'incomingPropertyBuyerAgentId', name: 'Buyer Agent' },
  {
    attr: 'incomingPropertyCloseOfPurchaseEscrowAt',
    name: 'Actual COE Purchase Date',
    inputType: 'date'
  },
  {
    attr: 'incomingPropertyCloseOfSellEscrowAt',
    name: 'Actual COE Sell Date',
    inputType: 'date'
  },
  {
    attr: 'incomingPropertyFullAddress',
    name: 'Incoming Property Full Address',
    inputType: 'address'
  },
  {
    attr: 'incomingPropertyFundsWiredToSellerAt',
    name: 'Purchase Escrow - Actual Funding Date',
    inputType: 'date'
  },
  {
    attr: 'incomingPropertyInspectionContingencyWaived',
    name: 'Insp. Cont. Waived',
    inputType: 'boolean'
  },
  { attr: 'incomingPropertyListPrice', name: 'List Price', inputType: 'money' },
  {
    attr: 'incomingPropertyMaxOfferPrice',
    name: 'Max Cash Offer Purchase Price',
    inputType: 'money'
  },
  {
    attr: 'ipMaxOfferPriceAtHlValuation',
    name: 'Max Offer Price At HL Valuation',
    inputType: 'dollarsAndCents'
  },
  { attr: 'incomingPropertyOfferAcceptedAt', name: 'Offer Accepted', inputType: 'date' },
  {
    attr: 'incomingPropertyOfferCancelledReason',
    name: 'Offer Cancelled Reason',
    inputType: 'text'
  },
  { attr: 'incomingPropertyOfferRejectedAt', name: 'Offer Rejected', inputType: 'date' },
  { attr: 'incomingPropertyOfferRejectedReason', name: 'Offer Rejected Reason', inputType: 'text' },
  {
    attr: 'incomingPropertyOpenPurchaseEscrowAt',
    name: 'Purchase Escrow Open Date',
    inputType: 'date'
  },
  { attr: 'incomingPropertyOpenSellEscrowAt', name: 'Sell Escrow Open Date', inputType: 'date' },
  { attr: 'incomingPropertySalePrice', name: 'Cash Offer Purchase Price', inputType: 'money' },
  { attr: 'incomingPropertyValuation', name: 'HL Expected Appraisal', inputType: 'money' },
  { attr: 'ipAdditionalDeposit', name: 'Additional Deposit', inputType: 'money' },
  { attr: 'ipAvmValuation', name: 'AVM Valuation' },
  { attr: 'ipAvmValuationAt', name: 'Date of AVM Valuation', inputType: 'date' },
  { attr: 'ipBuyersAgentCommission', name: 'Agent Buy Commission $' },
  { attr: 'ipBuyersAgentCommissionRate', name: 'Agent Buy Commission %' },
  { attr: 'ipCapitalDaysOutstanding', name: 'Days of Investor Capital Outstanding' },
  { attr: 'ipCashOfferDeposit', name: 'Cash Offer Deposit' },
  { attr: 'ipContingencyRemovalAt', name: 'Contingency Removal Date', inputType: 'date' },
  { attr: 'ipEarnestMoney', name: 'Earnest Money Deposit', inputType: 'money' },
  { attr: 'ipInsuranceCoverage', name: 'Insurance Coverage Amount' },
  { attr: 'ipNetSettlement', name: 'Net Settlement' },
  {
    attr: 'ipOfferTermsDaysTillCloseOfEscrow',
    name: 'Days of Close of Escrow',
    inputType: 'integer'
  },
  {
    attr: 'ipOfferTermsDaysTillInspectionContingencyEnds',
    name: 'Days on Inspection Contingency',
    inputType: 'integer'
  },
  { attr: 'ipOfferTermsOptionTerminationFee', name: 'Option Termination Fee', inputType: 'money' },
  {
    attr: 'ipPropertyType',
    name: 'Property Type',
    inputType: 'select'
  },
  {
    attr: 'ipOfferTermsInspectionTerminationDeadlineDate',
    name: 'Inspection Termination Deadline',
    inputType: 'date'
  },
  {
    attr: 'ipOfferTermsInspectionResolutionDeadlineDate',
    name: 'Inspection Resolution Deadline',
    inputType: 'date'
  },
  {
    attr: 'ipOfferTermsInspectionObjectionDeadlineDate',
    name: 'Inspection Objection Deadline',
    inputType: 'date'
  },
  { attr: 'ipOfferTermsDeadlineDate', name: 'Offer Deadline', inputType: 'date' },
  { attr: 'ipSolarPanels', name: 'Solar Panels' },
  {
    attr: 'ipTargetCloseOfPurchaseEscrowAt',
    name: 'Purchase Escrow - Target COE Date',
    inputType: 'date'
  },
  { attr: 'ipTargetCloseOfSellEscrowAt', name: 'Sell Escrow - Target COE Date', inputType: 'date' },
  { attr: 'licenseNumber', name: 'Buyer Agent License' },
  { attr: 'name', name: 'Client Name' },
  { attr: 'number', name: 'Client Phone' },
  { attr: 'partnerName', name: 'Spouse / Partner Name' },
  { attr: 'phoneAlt', name: 'Spouse / Partner Phone' },
  { attr: 'phoneMobile', name: 'Buyer Agent Phone' },
  { attr: 'protectedOfferStatus', name: 'Protected Offer' },
  {
    attr: 'reasonForFail',
    name: 'Failed Reason',
    inputType: 'text'
  },
  { attr: 'refAgentName', name: 'Ref Agent Name' },
  { attr: 'refAgentPhoneMobile', name: 'Ref Agent Phone' },
  { attr: 'refAgentTeam', name: 'Ref Agent Team' },
  { attr: 'referringOwnerId', name: 'Ref Agent' },
  { attr: 'revenueRecognitionDate', name: 'Waterfall Funds Received Date', inputType: 'date' },
  { attr: 'source', name: 'Source' },
  { attr: 'cashOfferFeeAmount', name: 'Cash Offer Fee $' },
  { attr: 'cashOfferFeeMethod', name: 'Cash Offer Fee Method', options: optionsCashOfferFeeMethod },
  { attr: 'ipFundingAmount', name: 'Funding Amount' },
  {
    attr: 'ipHomeownerAssociation',
    name: 'HOA',
    inputType: 'boolean'
  },
  { attr: 'ipMaxOfferPriceWithoutBuffer', name: 'Max Cash Offer Purchase Price' },
  {
    attr: 'expressClose',
    name: 'Express Close',
    inputType: 'boolean'
  },
  {
    attr: 'ipBorrowingBase',
    name: 'Funding Amount - Borrowing Base',
    inputType: 'money'
  },
  {
    attr: 'ipHlEquity',
    name: 'Funding Amount - HL Equity',
    inputType: 'money'
  },
  {
    attr: 'ipSellerCredits',
    name: 'Seller Credits',
    inputType: 'money'
  }
]
