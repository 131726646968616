type LinkAttributes = {
  name: string
  pathname: string
  search?: string
}

export class Link {
  name: string
  pathname: string
  search?: string
  constructor(linkAttributes: LinkAttributes) {
    Object.assign(this, linkAttributes)
  }
}
