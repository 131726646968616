import { css, styled } from '@foundation/themes/styles'

type OptionCheckProps = {
  isSelected: boolean
}

export const OptionCheck = styled.div<OptionCheckProps>`
  ${({ theme, isSelected }) => css`
    position: absolute;
    display: flex;
    left: 1.8rem;
    top: 50%;
    justify-content: center;
    align-items: center;
    background-color: ${isSelected ? theme.colors.primary5 : theme.colors.white};
    transform: translate(-50%, -50%);
    height: ${theme.sizes.$8};
    width: ${theme.sizes.$8};
    border-radius: ${theme.radii.base};
    border: 1px solid ${isSelected ? 'transparent' : theme.colors.neutral5};
  `}
`

export const SectionHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.space.$2};
    justify-content: space-between;
    align-items: center;
    margin-left: ${theme.space.$8};
    margin-right: ${theme.space.$3};
  `}
`
