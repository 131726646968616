// @ts-strict
import { Navigate, Route, Routes } from 'react-router-dom'
import { ToastProvider } from '@foundation/components'
import { useFeatureFlags, useFlagsmithStatus, useFlexUi } from 'hooks'
import { GoogleAnalytics } from 'lib/GoogleAnalytics'
import * as bbys from 'modules/bbys'
import * as ca from 'modules/ca'
import * as lrm from 'modules/lrm'
import * as processor from 'modules/processors'
import { TransactionOperatorsPage } from 'modules/transaction-operators'
import { AllLeadsPage, CCCashOfferLeadsPage, CCTradeInLeadsPage, EscrowLeadsPage } from 'pages'
import { AgentAeAreasPage } from 'pages/AgentAeAreasPage'
import {
  AgentActivityTab,
  AgentAgreementTab,
  AgentBillingTab,
  AgentDetailsTab,
  AgentInspectTab,
  AgentListingsTab,
  AgentPage,
  AgentReferralsTab,
  AgentTasksTab,
  AgentTeamTab,
  AgentTransactionsTab
} from 'pages/AgentPage'
import { AgentsPage } from 'pages/AgentsPage'
import { AuthRedirectPage } from 'pages/AuthRedirectPage'
import { BuyersAndSellersLeadsPage } from 'pages/BuyersAndSellersLeadsPage'
import { ClientAdvisorTeamsPage } from 'pages/ClientAdvisorTeamsPage'
import { DevIconsPage, DevPage } from 'pages/DevPage'
import { EmployeeDetailsTab, EmployeeInspectTab, EmployeePage } from 'pages/EmployeePage'
import { HLSSLeadsPage } from 'pages/HLSSLeadsPage'
import { HandmatchPage } from 'pages/HandmatchPage'
import { HomePage } from 'pages/HomePage'
import { InvestorBillingTab, InvestorPage } from 'pages/InvestorPage'
import { InvestorsPage } from 'pages/InvestorsPage'
import { LeadCreationAdminPage } from 'pages/LeadCreationAdminPage'
import { LeadGroupableDefinitionsTab } from 'pages/LeadGroupableDefinitionsPage'
import { LeadGroupablePage } from 'pages/LeadGroupablePage'
import { LeadGroupablesPage } from 'pages/LeadGroupablesPage'
import {
  LeadActivityTab,
  LeadDetailsTab,
  LeadDocusignTab,
  LeadEconomicModelTab,
  LeadExternalLinksTab,
  LeadFileUploadsTab,
  LeadInspectionsDeficienciesTab,
  LeadInspectionsReportsTab,
  LeadInspectionsTab,
  LeadInspectTab,
  LeadPage,
  LeadPhotoUploadsCarousel,
  LeadPropertyPhotosTab,
  LeadTasksTab,
  LeadTemplatesTab
} from 'pages/LeadPage'
import { CCBBYSLeadEconomicModelTab } from 'pages/LeadPage/CCBBYSLeadEconomicModelTab'
import { LeadTeamTab } from 'pages/LeadPage/LeadTeamTab'
import { LeadsPage } from 'pages/LeadsPage'
import { LoanOfficersPage } from 'pages/LoanOfficersPage'
import { MessagesPage } from 'pages/MessagesPage'
import { MortgageLeadsPage } from 'pages/MortgageLeadsPage'
import { NotFoundPage } from 'pages/NotFoundPage'
import { OrderActivityTab, OrderPage, OrderPageDetailTab } from 'pages/OrderPage'
import {
  ClosingServicesOrdersPage,
  HomeLoansOrdersPage,
  QueueOrdersPage,
  SimpleSaleOrdersPage
} from 'pages/OrdersPage'
import { OrdersPage } from 'pages/OrdersPage'
import { PartnershipsPage } from 'pages/PartnershipsPage'
import { ReleaseNotesPage } from 'pages/ReleaseNotesPage'
import {
  SettingsDetailsTab,
  SettingsInspectTab,
  SettingsPage,
  SettingsPreferencesTab
} from 'pages/SettingsPage'
import { SettlementAgencyTeamsPage } from 'pages/SettlementAgencyTeamsPage'
import { SignInOAuthPage } from 'pages/SignInOAuthPage'
import { SignInOAuthPureCloudPage } from 'pages/SignInOAuthPureCloudPage'
import { SignInPage } from 'pages/SignInPage'
import { SignOutPage } from 'pages/SignOutPage'
import { SplashPage } from 'pages/SplashPage'
import { TasksPage } from 'pages/TasksPage'
import { AgentsTab, MembersTab, TeamPage } from 'pages/TeamPage'
import { TransactionTeamsPage } from 'pages/TransactionTeamsPage'
import { UsersPage } from 'pages/UsersPage'
import { AgentMessagesTab } from '../AgentPage/AgentMessagesTab'
import styles from './AppRoutes.module.scss'

export const SignInRoute = () => {
  const flagsmith = useFlagsmithStatus()
  const { salesOpsGoogleOauth, salesOpsFlexUiGoogleOauth } = useFeatureFlags([
    'sales-ops-google-oauth',
    'sales-ops-flex-ui-google-oauth'
  ])
  const { isFlexUi } = useFlexUi()
  if (!flagsmith.initialised) {
    return <SplashPage />
  }

  const googleOauthSigninPage = () => (
    <>{salesOpsGoogleOauth.enabled ? <SignInOAuthPage /> : <SignInPage />})</>
  )
  const flexUiGoogleOauthSigninPage = () => (
    <>{salesOpsFlexUiGoogleOauth.enabled ? <SignInOAuthPage /> : <SignInPage />})</>
  )
  return isFlexUi ? flexUiGoogleOauthSigninPage() : googleOauthSigninPage()
}

export const AppRoutes = () => {
  GoogleAnalytics.usePageTracking()
  const {
    salesAppInspections,
    salesAppDeficiencies,
    salesOpsCentralizedLeadDistribution,
    salesAppImpersonatingAgentTeamCoord,
    salesOpsGoogleOauth,
    salesAppEscrowPage
  } = useFeatureFlags([
    'sales-app-inspections',
    'sales-app-deficiencies',
    'sales-ops-centralized-lead-distribution',
    'sales-app-impersonating-agent-team-coord',
    'sales-ops-google-oauth',
    'sales-app-escrow-page'
  ])

  const inspectionsRoutes = salesAppInspections.enabled && (
    <Route path="inspections" element={<LeadInspectionsTab />}>
      {salesAppDeficiencies.enabled && (
        <Route path="deficiencies" element={<LeadInspectionsDeficienciesTab />} />
      )}
      <Route index element={<LeadInspectionsReportsTab />} />
    </Route>
  )

  const transactionTeamsPage = salesOpsCentralizedLeadDistribution.enabled ? (
    <Route path="teams/transaction-teams" element={<TransactionTeamsPage />} />
  ) : (
    <Route path="teams" element={<TransactionTeamsPage />} />
  )

  const googleOAuthRedirectPage = salesOpsGoogleOauth.enabled ? (
    <Route
      path="auth/redirect"
      element={
        <ToastProvider>
          <AuthRedirectPage />
        </ToastProvider>
      }
    />
  ) : null

  return (
    <ToastProvider>
      <div className={styles.contents}>
        <Routes>
          <Route path="sign-in" element={<SignInPage />} />
          <Route index element={<HomePage />} />
          <Route path="agents" element={<AgentsPage />} />
          {transactionTeamsPage}
          <Route path="teams/client-advisor-teams" element={<ClientAdvisorTeamsPage />} />
          <Route path="leads" element={<LeadsPage />} />
          <Route path="messages" element={<MessagesPage />} />
          <Route path="tasks" element={<TasksPage />} />
          {salesAppImpersonatingAgentTeamCoord.enabled ? (
            <Route path="admin/users" element={<UsersPage />} />
          ) : (
            <Route path="admin/employees" element={<UsersPage />} />
          )}
          <Route path="admin/agent-ae-areas" element={<AgentAeAreasPage />} />
          <Route path="admin/loan-officers" element={<LoanOfficersPage />} />
          <Route path="admin/investors" element={<InvestorsPage />} />
          <Route path="trade-ins" element={<CCTradeInLeadsPage />} />
          <Route path="all-leads" element={<AllLeadsPage />} />
          <Route path="buyers-sellers" element={<BuyersAndSellersLeadsPage />} />
          <Route path="partnerships" element={<PartnershipsPage />} />

          <Route path="agent-matching" element={<Navigate to="/buyers-sellers" replace />} />
          <Route path="cash-close" element={<Navigate to="/" replace />} />
          <Route path="homes" element={<Navigate to="/" replace />} />

          <Route path="agents/:agentId" element={<AgentPage />}>
            <Route index element={<AgentDetailsTab />} />
            <Route path="tasks" element={<AgentTasksTab />} />
            <Route path="activity" element={<AgentActivityTab />} />
            <Route path="referrals" element={<AgentReferralsTab />} />
            <Route path="listings" element={<AgentListingsTab />} />
            <Route path="messages" element={<AgentMessagesTab />} />
            <Route path="transactions" element={<AgentTransactionsTab />} />
            <Route path="team" element={<AgentTeamTab />} />
            <Route path="agreements" element={<AgentAgreementTab />} />
            <Route path="inspect" element={<AgentInspectTab />} />
            <Route path="billing" element={<AgentBillingTab />} />
          </Route>

          <Route path="investors/:investorId" element={<InvestorPage />}>
            <Route index element={<InvestorBillingTab />} />
          </Route>

          <Route path="leads/handmatch" element={<HandmatchPage />} />

          <Route path="leads/:leadId" element={<LeadPage />}>
            <Route index element={<LeadDetailsTab />} />
            <Route path="tasks" element={<LeadTasksTab />} />
            <Route path="activity" element={<LeadActivityTab />} />
            <Route path="inspect" element={<LeadInspectTab />} />
          </Route>

          <Route path="buyers/:leadId" element={<LeadPage userType="buyer" />}>
            <Route index element={<LeadDetailsTab userType="buyer" />} />
            <Route path="tasks" element={<LeadTasksTab />} />
            <Route path="activity" element={<LeadActivityTab />} />
            <Route path="inspect" element={<LeadInspectTab />} />
          </Route>

          <Route path="sellers/:leadId" element={<LeadPage userType="seller" />}>
            <Route index element={<LeadDetailsTab userType="seller" />} />
            <Route path="tasks" element={<LeadTasksTab />} />
            <Route path="activity" element={<LeadActivityTab />} />
            <Route path="inspect" element={<LeadInspectTab />} />
          </Route>

          <Route path="trade-ins/:leadId" element={<LeadPage userType="cc_trade_in" />}>
            <Route index element={<LeadDetailsTab userType="cc_trade_in" />} />
            <Route path="economic-model" element={<LeadEconomicModelTab />} />
            <Route path="tasks" element={<LeadTasksTab />} />
            <Route path="activity" element={<LeadActivityTab />} />
            <Route path="team" element={<LeadTeamTab />} />
            <Route path="inspect" element={<LeadInspectTab />} />
            <Route path="documents" element={<LeadFileUploadsTab />} />
            <Route path="documents/photos" element={<LeadPropertyPhotosTab />}>
              <Route path=":photoUploadId" element={<LeadPhotoUploadsCarousel />} />
            </Route>

            <Route path="documents/docusign" element={<LeadDocusignTab />} />
            <Route path="documents/external" element={<LeadExternalLinksTab />} />
            {inspectionsRoutes}
          </Route>

          <Route path="/hlss" element={<HLSSLeadsPage />} />

          <Route path="hlss/:leadId" element={<LeadPage userType="cc_hl_simple_sale" />}>
            <Route index element={<LeadDetailsTab userType="cc_hl_simple_sale" />} />
            <Route path="tasks" element={<LeadTasksTab />} />
            <Route path="activity" element={<LeadActivityTab />} />
            <Route path="team" element={<LeadTeamTab />} />
            <Route path="documents" element={<LeadFileUploadsTab />} />
            <Route path="documents/photos" element={<LeadPropertyPhotosTab />}>
              <Route path=":photoUploadId" element={<LeadPhotoUploadsCarousel />} />
            </Route>
            <Route path="documents/external" element={<LeadExternalLinksTab />} />
          </Route>

          <Route path="/cash-offers" element={<CCCashOfferLeadsPage />} />

          <Route path="cash-offers/:leadId" element={<LeadPage userType="cc_cash_offer" />}>
            <Route index element={<LeadDetailsTab userType="cc_cash_offer" />} />
            <Route path="tasks" element={<LeadTasksTab />} />
            <Route path="activity" element={<LeadActivityTab />} />
            <Route path="team" element={<LeadTeamTab />} />
            <Route path="inspect" element={<LeadInspectTab />} />
            <Route path="documents" element={<LeadFileUploadsTab />} />
            <Route path="documents/docusign" element={<LeadDocusignTab />} />
            <Route path="documents/external" element={<LeadExternalLinksTab />} />
          </Route>

          <Route path="orders/:orderId" element={<OrderPage />}>
            <Route index element={<OrderPageDetailTab />} />
            <Route path="activity" element={<OrderActivityTab />} />
          </Route>

          {salesAppEscrowPage.enabled && <Route path="/escrows" element={<EscrowLeadsPage />} />}

          <Route path="escrows/:leadId" element={<LeadPage userType="escrow" />}>
            <Route index element={<LeadDetailsTab userType="escrow" />} />
            <Route path="tasks" element={<LeadTasksTab />} />
            <Route path="activity" element={<LeadActivityTab />} />
            <Route path="team" element={<LeadTeamTab />} />
            <Route path="inspect" element={<LeadInspectTab />} />
            <Route path="documents" element={<LeadFileUploadsTab />} />
            <Route path="documents/docusign" element={<LeadDocusignTab />} />
            <Route path="documents/external" element={<LeadExternalLinksTab />} />
          </Route>

          <Route path="/mortgages" element={<MortgageLeadsPage />} />

          <Route path="mortgages/:leadId" element={<LeadPage userType="mortgage" />}>
            <Route index element={<LeadDetailsTab userType="mortgage" />} />
            <Route path="tasks" element={<LeadTasksTab />} />
            <Route path="activity" element={<LeadActivityTab />} />
            <Route path="team" element={<LeadTeamTab />} />
            <Route path="inspect" element={<LeadInspectTab />} />
          </Route>

          <Route path="settings" element={<SettingsPage />}>
            <Route index element={<SettingsDetailsTab />} />
            <Route path="preferences" element={<SettingsPreferencesTab />} />
            <Route path="inspect" element={<SettingsInspectTab />} />
          </Route>

          <Route path="teams/:teamId" element={<TeamPage />}>
            <Route index element={<MembersTab />} />
            <Route path="agents" element={<AgentsTab />} />
          </Route>

          <Route path="/release-notes" element={<ReleaseNotesPage />} />
          <Route path="/admin/employees/:employeeId" element={<EmployeePage />}>
            <Route index element={<EmployeeDetailsTab />} />
            <Route path="inspect" element={<EmployeeInspectTab />} />
          </Route>

          <Route path="/lrm" element={<lrm.All />} />
          <Route path="/transaction-operators" element={<TransactionOperatorsPage />} />
          <Route path="/ca" element={<ca.All />} />

          <Route path="/processors" element={<processor.All />} />

          <Route path="/lead-creation-admin" element={<LeadCreationAdminPage />} />
          <Route path="lead-creation-admin/personas" element={<LeadGroupablesPage.Personas />} />
          <Route
            path="lead-creation-admin/personas/:personaId"
            element={<LeadGroupablePage.Persona />}
          >
            <Route index element={<LeadGroupableDefinitionsTab />} />
          </Route>

          {/* Modules */}
          <Route path="/buy-before-you-sell">
            <Route path="leads" element={<bbys.Leads />} />
            <Route path="leads/:leadId" element={<LeadPage userType="bbys" />}>
              <Route index element={<LeadDetailsTab userType="bbys" />} />
              <Route path="economic-model" element={<CCBBYSLeadEconomicModelTab />} />
              <Route path="tasks" element={<LeadTasksTab />} />
              <Route path="team" element={<LeadTeamTab />} />
              <Route path="activity" element={<LeadActivityTab />} />
              <Route path="inspect" element={<LeadInspectTab />} />
              <Route path="documents" element={<LeadFileUploadsTab />} />
              <Route path="documents/templates" element={<LeadTemplatesTab />} />
              <Route path="documents/photos" element={<LeadPropertyPhotosTab />}>
                <Route path=":photoUploadId" element={<LeadPhotoUploadsCarousel />} />
              </Route>

              <Route path="documents/docusign" element={<LeadDocusignTab />} />
              <Route path="documents/external" element={<LeadExternalLinksTab />} />
              {inspectionsRoutes}
            </Route>
          </Route>

          {/* Devs only */}
          <Route path="orders" element={<OrdersPage />} />
          <Route path="closing-services" element={<ClosingServicesOrdersPage />} />
          <Route path="queue" element={<QueueOrdersPage />} />
          <Route path="home-loans" element={<HomeLoansOrdersPage />} />
          <Route path="simple-sale" element={<SimpleSaleOrdersPage />} />
          <Route path="/dev" element={<DevPage />} />
          <Route path="/dev/icons" element={<DevIconsPage />} />
          {/* Not found */}
          <Route path="*" element={<NotFoundPage />} />

          {googleOAuthRedirectPage}
          <Route path="sign-out" element={<SignOutPage />} />
          <Route path="teams/settlement-agency-teams" element={<SettlementAgencyTeamsPage />} />
          <Route path="pure-cloud" element={<SignInOAuthPureCloudPage />} />
        </Routes>
      </div>
    </ToastProvider>
  )
}
