export const WARM_TRANSFER_STEP = {
  unsubscribed: 'unsubscribed',
  started: 'started',
  pending: 'pending',
  active: 'active',
  successful: 'successful',
  error: 'error',
  canceled: 'canceled',
  completed: 'completed'
} as const

export type WarmTransferStepKey = keyof typeof WARM_TRANSFER_STEP

export type Props = {
  enabled: boolean
  leadId: string
}

export type MessageIdentifier = {
  channel: 'WarmTransferChannel'
  leadId: string
}

export type AgentActions =
  | 'pending'
  | 'agent_accepted'
  | 'transferred'
  | 'canceled'
  | 'completed'
  | 'sms_agent'
  | 'started'
  | 'perform'
  | 'start'
  | 'accepted'
  | 'agent_disconnected'

export type WarmTransferActions = 'cancel'

type AgentIdentifier = {
  agentId: string
}

export type AgentInformation = AgentIdentifier & {
  action: AgentActions | string
  agentBrokerage?: string
  agentName?: string
  agentPictureUrl?: string
  leadRoutingEnabled?: string
  timestamp?: string
  workedByAgent?: AgentInformation
}

export type AgentUpdate = AgentIdentifier & {
  action: AgentActions
  timestamp?: string
  workedByAgent?: AgentInformation
}

type TypeOfMessage = {
  type?: 'confirm_cancel' | 'confirm_subscription' | 'welcome' | 'ping'
}

type AgentMessage = (AgentInformation | AgentUpdate) & TypeOfMessage

export type CommandMessage = 'subscribe' | 'message' | 'start'

export type Message = {
  command?: CommandMessage
  data?:
    | AgentUpdate
    | {
        action: AgentActions | WarmTransferActions
      }
  identifier?: MessageIdentifier
  message?: AgentMessage
} & TypeOfMessage

export type HandleWarmTransferSteps = {
  callback?: (message: Message) => void
  message?: AgentMessage
} & TypeOfMessage
