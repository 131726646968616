// @ts-strict
import { Block, TableCellProps, TableCell as Td } from 'components'
import { Lead } from 'models'
import { dataOrDash, titleize } from 'utils'

type TProps<T = Lead> = TableCellProps & {
  column?: string
  lead: T
  testId?: string
}

const getSourceString = (lead: Lead) => {
  if (lead.order?.source) {
    return titleize(lead.order?.source)
  }

  if (lead?.source) {
    const source = `${titleize(lead.source)}`
    const sourceType = lead?.sourcePageType ? ` (${titleize(lead.sourcePageType)})` : ''

    return `${source}${sourceType}`
  }

  return null
}

export const AllLeadsSourceCell = ({ lead, testId }: TProps) => {
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(getSourceString(lead))} />
    </Td>
  )
}
