import { isActiveAgentLead, sortByActiveAgentLeadStageAttr } from 'lookups'
import { AgentProviderLead } from 'models'

export const getActiveAgentProviderLeads = (agentProviderLeads?: AgentProviderLead[]) => {
  const activeAgentProviderLeads = agentProviderLeads
    ?.filter(isActiveAgentLead)
    .sort(sortByActiveAgentLeadStageAttr)

  return activeAgentProviderLeads
}
