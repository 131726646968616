// @ts-strict
import { Link } from 'react-router-dom'
import { List, Section } from 'components'
import { useCurrentUserContext, useInboxContext, useSavedSearchesContext } from 'contexts'
import { useFeatureFlags } from 'hooks'

export const MySalesAppSection = () => {
  const { unreadMessagesCount } = useInboxContext()
  const { currentUser, activeTasksCount } = useCurrentUserContext()
  const { getLastSearchUrl } = useSavedSearchesContext()
  const { salesAppEscrowPage, salesAppBbysPipelineView } = useFeatureFlags([
    'sales-app-escrow-page',
    'sales-app-bbys-pipeline-view'
  ])

  return (
    <>
      <Section title="Leads Lists">
        <List>
          <Link
            to={getLastSearchUrl(
              salesAppBbysPipelineView?.enabled ? '/buy-before-you-sell/leads' : '/trade-ins'
            )}
          >
            Buy Before You Sell
          </Link>
          <Link to={getLastSearchUrl('/cash-offers')}>Cash Offer</Link>
          <Link to={getLastSearchUrl('/hlss')}>HLSS</Link>
          <Link to={getLastSearchUrl('/mortgages')}>Mortgage</Link>
          {salesAppEscrowPage.enabled && <Link to={getLastSearchUrl('/escrows')}>Escrow</Link>}
          <Link to={getLastSearchUrl('/buyers-sellers', `?assigneeId=${currentUser?.id}`)}>
            Buyers/Sellers
          </Link>
          {salesAppBbysPipelineView?.enabled && (
            <Link to={getLastSearchUrl('/trade-ins')}>Trade-In Legacy</Link>
          )}
        </List>
      </Section>
      <Section title="My Sales App">
        <List>
          <Link to={getLastSearchUrl('/agents', currentUser?.agentsDefaultQueryString)}>
            Agents
          </Link>
          <Link
            to={getLastSearchUrl('/tasks', '?completionStatus=[ready]&attachableStatus=active')}
          >
            Tasks ({activeTasksCount})
          </Link>
          <Link to="/messages">Inbox ({unreadMessagesCount})</Link>
        </List>
      </Section>
    </>
  )
}
