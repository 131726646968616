import { presence } from 'utils/presence'
import { TValidation } from './validate'

export const validateEmailFormat: TValidation = ({ label, value }) => {
  if (!presence(value)) {
    return []
  }

  const errors = []

  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    errors.push(`${label} is invalid`)
  }
  return errors
}
