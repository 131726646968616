// @ts-strict
import { ReactNode } from 'react'
import {
  ButtonForm,
  TButtonFormContentsProps,
  TUseButtonFormProps,
  useButtonForm
} from 'components'
import { UseMutationResult } from 'hooks'

export type ConfirmationPopupProps = Pick<
  TButtonFormContentsProps,
  'title' | 'buttonText' | 'disabled' | 'buttonComponent'
> &
  Pick<TUseButtonFormProps, 'initialPayload' | 'callback'> & {
    message: ReactNode
    mutation: UseMutationResult<unknown, unknown, any>
  }

export const ConfirmationPopup = ({
  message,
  mutation,
  initialPayload,
  callback,
  ...rest
}: ConfirmationPopupProps) => {
  const { formProps } = useButtonForm({ mutation, initialPayload, callback })

  return (
    <ButtonForm {...rest} {...formProps}>
      {message}
    </ButtonForm>
  )
}
