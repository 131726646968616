import { useEffect } from 'react'
import { useHLSSLeadDispositionContext } from '../HLSSLeadDispositionContext'

export const useFields = (fields: { fields?: string | string[]; required?: string | string[] }) => {
  const fieldString = JSON.stringify(fields)
  const { registerFields, unregisterFields } = useHLSSLeadDispositionContext()

  useEffect(() => {
    registerFields(JSON.parse(fieldString))
    return unregisterFields
  }, [fieldString, registerFields, unregisterFields])
}
