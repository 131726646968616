// @ts-strict
import {
  ExternalLink,
  FormField,
  InlineEditAddressUnit,
  InlineEditSelect,
  List,
  MapBox,
  Section,
  Sections
} from 'components'
import { useHLSimpleSaleLeadController } from 'controllers'
import { solarPanelsOptions, trueFalseOptions, useLeadPropertyTypes } from 'lookups'
import { toSearchParam } from 'utils'
import { HLSSLeadDetailsProps } from '../HLSSLeadDetails'
import { getFieldValues } from '../utils'

type TProps = Pick<HLSSLeadDetailsProps, 'lead'>

export const DRPropertyInformationSection = ({ lead }: TProps) => {
  const hlSimpleSaleLead = lead.hlSimpleSaleLead!
  const { updateHLSimpleSaleLead } = useHLSimpleSaleLeadController(hlSimpleSaleLead!.id)
  const departingMasterProperty = hlSimpleSaleLead?.departingMasterProperty
  const { leadPropertyTypes } = useLeadPropertyTypes()
  const { getFieldValue } = getFieldValues(hlSimpleSaleLead)

  return (
    <Sections title="DR Property Information">
      <Section>
        <FormField title="Address" inline>
          <InlineEditAddressUnit
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({
                departing_property_full_address: val
              })
            }
            value={departingMasterProperty?.displayAddress}
          />
        </FormField>
        <FormField title="Property Type" inline>
          <InlineEditSelect
            options={leadPropertyTypes}
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpPropertyType: val })}
            value={getFieldValue('dpPropertyType')}
          />
        </FormField>
        <FormField title="HOA" inline>
          <InlineEditSelect
            onBlur={val =>
              updateHLSimpleSaleLead.mutate({ departingPropertyHomeownerAssociation: val })
            }
            value={getFieldValue('departingPropertyHomeownerAssociation')}
            options={trueFalseOptions}
            addBlank
          />
        </FormField>
        <FormField title="Solar Panels" inline>
          <InlineEditSelect
            onBlur={val => updateHLSimpleSaleLead.mutate({ departingPropertySolarPanels: val })}
            value={getFieldValue('departingPropertySolarPanels')}
            options={solarPanelsOptions}
            addBlank
          />
        </FormField>
        <FormField title="External Links" inline>
          <List>
            <ExternalLink
              href={`https://www.google.com/search?q=${toSearchParam(
                departingMasterProperty?.displayAddress
              )}`}
            >
              Google Address
            </ExternalLink>
            <ExternalLink
              href={`http://www.zillow.com/homes/${toSearchParam(
                departingMasterProperty?.displayAddress
              )}_rb`}
            >
              Zillow Address
            </ExternalLink>
          </List>
        </FormField>
      </Section>
      <Section>
        {departingMasterProperty?.latitude && departingMasterProperty?.longitude ? (
          <MapBox
            center={departingMasterProperty?.point}
            markers={[departingMasterProperty?.marker]}
          />
        ) : (
          <FormField title="Map" inline>
            Latitude and Longitude are missing
          </FormField>
        )}
      </Section>
    </Sections>
  )
}
