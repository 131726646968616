// @ts-strict
import {
  ButtonForm,
  CheckBoxField,
  FormField,
  FormFieldSet,
  Section,
  Sections,
  Select,
  TextArea,
  TextInput,
  useButtonForm
} from 'components'
import { EditIconButton } from 'components/Button/IconButton'
import { useAgentPartnershipController } from 'controllers'
import { useTransactionTeams } from 'lookups'
import { AgentPartnership } from 'models'
import { AgentPartnershipPutPayload } from 'services'
import { validatePresence, validateUrl } from 'utils'

type TProps = {
  agentPartnership: AgentPartnership
}

export const EditAgentPartnershipButtonForm = ({ agentPartnership }: TProps) => {
  const { transactionTeamsOptions } = useTransactionTeams()
  const { updateAgentPartnership } = useAgentPartnershipController(agentPartnership.id)
  const {
    formProps,
    fieldsProps: { payload, onChangeAttribute }
  } = useButtonForm<AgentPartnershipPutPayload, string>({
    initialPayload: {
      ...agentPartnership,
      active: !!agentPartnership.active,
      transactionTeamId: agentPartnership.transactionTeam?.id
    },
    mutation: updateAgentPartnership,
    formValidations: {
      name: validatePresence,
      destinationUrl: [validatePresence, validateUrl],
      transactionTeamId: validatePresence
    }
  })

  return (
    <ButtonForm
      {...formProps}
      isFormValid={formProps.isFormValid && !!payload.transactionTeamId}
      title="Edit Partnership"
      buttonComponent={props => <EditIconButton aria-label="edit" onClick={props.onClick} />}
    >
      <FormFieldSet>
        <Sections>
          <Section>
            <FormField title="Name" required htmlFor="name">
              <TextInput
                required
                id="name"
                onChange={value => {
                  onChangeAttribute('name', value)
                }}
                value={payload.name}
              />
            </FormField>
            <FormField title="Description" htmlFor="description">
              <TextArea
                id="description"
                onChange={value => {
                  onChangeAttribute('description', value)
                }}
                value={payload.description}
              />
            </FormField>
            <FormField title="Destination URL" required htmlFor="destinationUrl">
              <TextInput
                required
                id="destinationUrl"
                onChange={value => {
                  onChangeAttribute('destinationUrl', value)
                }}
                value={payload.destinationUrl}
              />
            </FormField>
            <FormField title="Transaction Team" required htmlFor="transactionTeam">
              <Select
                required
                id="transactionTeam"
                options={transactionTeamsOptions}
                value={payload.transactionTeamId || 0}
                placeholder="Select a Transaction Team"
                onChange={value => {
                  onChangeAttribute('transactionTeamId', value)
                }}
              />
            </FormField>
            <CheckBoxField
              name="Enabled"
              title="Enabled"
              checked={payload.active}
              onChange={e => {
                onChangeAttribute('active', e.target.checked)
              }}
            />
          </Section>
        </Sections>
      </FormFieldSet>
    </ButtonForm>
  )
}
