// @ts-strict

import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './PageTitle.module.scss'

type TProps = {
  children: ReactNode
  className?: string
}

export const PageTitle = ({ className, children, ...props }: TProps) => {
  return (
    <h1 className={cx(styles.title, className)} {...props}>
      {children}
    </h1>
  )
}
