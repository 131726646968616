// @ts-strict

import { useMutation, useQueryClient } from 'hooks'
import { TLeadUserTypeServerSlug } from 'lookups'
import {
  putDispositionAgent,
  putDispositionAgentLead,
  putDispositionProviderLead,
  TPutDispositionAgent,
  TPutDispositionAgentLead,
  TPutDispositionProviderLead
} from 'services'
import { AGENTS_CACHE_KEY, LEADS_CACHE_KEY, ORDER_CACHE_KEY, ORDERS_CACHE_KEY } from '../index'

type TProps = {
  agentId?: string
  agentLeadId?: string
  leadId?: string
  leadType: TLeadUserTypeServerSlug
  providerLeadId?: string
}

export const useDispositionAgentLeadController = ({
  agentId = '',
  agentLeadId = '',
  providerLeadId = '',
  leadId = '',
  leadType
}: TProps) => {
  const queryCache = useQueryClient()

  const defaultCallback = {
    onSuccess: () => {
      queryCache.invalidateQueries([LEADS_CACHE_KEY, leadType, leadId])
      queryCache.invalidateQueries([AGENTS_CACHE_KEY, leadId])
      queryCache.invalidateQueries(ORDER_CACHE_KEY)
      queryCache.invalidateQueries(ORDERS_CACHE_KEY)
      queryCache.invalidateQueries()
    }
  }

  const updateDispositionAgent = useMutation(
    (payload: TPutDispositionAgent) => putDispositionAgent(leadId, agentId, payload),
    defaultCallback
  )

  const updateDispositionProviderLead = useMutation(
    (payload: TPutDispositionProviderLead) =>
      putDispositionProviderLead(leadId, providerLeadId, payload),
    defaultCallback
  )

  const updateDispositionAgentLead = useMutation(
    (payload: TPutDispositionAgentLead) => putDispositionAgentLead(leadId, agentLeadId, payload),
    defaultCallback
  )

  return {
    updateDispositionAgent,
    updateDispositionAgentLead,
    updateDispositionProviderLead
  }
}
