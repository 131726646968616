import { TableHeaderRow, TableHeaderColumn as TH } from 'components'
import { useListContext } from 'contexts'
import styles from './EnvelopesTable.module.scss'

export const EnvelopesTableHeader = () => {
  const { showColumn } = useListContext()
  return (
    <TableHeaderRow>
      {showColumn('template') && <TH className={styles.expanding} name="Template" />}
      {showColumn('createdBy') && (
        <TH
          sort={[{ name: 'Created By', slug: 'CreatedAt', flip: true }]}
          className={styles.expanding}
        />
      )}
      {showColumn('recipients') && <TH className={styles.expanding} name="Recipients" />}
      {showColumn('status') && <TH className={styles.status} name="Status" />}
      {showColumn('sent') && (
        <TH sort={[{ name: 'Sent', slug: 'SentAt', flip: true }]} className={styles.sent} />
      )}
    </TableHeaderRow>
  )
}
