import { Spinner } from 'components/Graphic'
import { cx } from 'utils'
import styles from './LoadingText.module.scss'

type LoadingTextProps = {
  className?: string
  text?: string
}

export const LoadingText = ({ text = 'Loading', className }: LoadingTextProps) => (
  <span className={cx(styles.loadingText, className)}>
    <Spinner className={styles.spinner} /> {text}
  </span>
)
