/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @ts-strict
import React from 'react'
import { Block } from 'components/Block'
import styles from './AutoComplete.module.scss'

export type AutoCompleteListItemProps = {
  active?: boolean
  item: any
  onClick?: (item: any) => any
}

export const AutoCompleteListItem = ({
  item,
  onClick,
  active = false
}: AutoCompleteListItemProps) => {
  return (
    <li
      data-testid={item.title}
      onMouseDown={e => {
        e.preventDefault()
        onClick?.(item)
      }}
      className={active ? styles.hover : ''}
    >
      <Block image={item.image} title={item.title} subtitle={item.subTitle}>
        {item.child || null}
      </Block>
    </li>
  )
}
