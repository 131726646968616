// @ts-strict

import { ReactElement, SyntheticEvent, useState } from 'react'
import { differenceInMilliseconds, parseISO } from 'date-fns'
import { Link } from 'react-router-dom'
import { Button, Text } from '@foundation/components'
import { RoundImage, Tooltip } from 'components'
import type { AgentActions, AgentInformation } from 'controllers'
import { useInterval } from 'hooks'
import { dataOrDash, formatTalkTime, randomImage } from 'utils'
import * as S from './styles'

type Props = {
  agent: AgentInformation
  amountOfAgents: number
  connectAgent: (agentId: string) => void
}

type Actions = Extract<
  AgentActions,
  | 'sms_agent'
  | 'pending'
  | 'agent_accepted'
  | 'transferred'
  | 'canceled'
  | 'perform'
  | 'agent_disconnected'
>

const ONE_AGENT = 1

export const AgentWarmTransfer = ({ agent, connectAgent, amountOfAgents }: Props) => {
  const parseAgentTimestamp = agent?.timestamp ? parseISO(agent?.timestamp) : new Date()
  const diffInSeconds = differenceInMilliseconds(new Date(), parseAgentTimestamp)
  const [agentTimer, setAgentTimer] = useState<number>(diffInSeconds)
  const tooltipText = [agent.agentName, agent.agentBrokerage].filter(Boolean).join(', ')
  const haveSpaceBetweenAgents = amountOfAgents > ONE_AGENT || agent.action !== 'transferred'

  const handleDefaultImage = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = randomImage(+agent.agentId, 50)
  }

  useInterval(() => {
    setAgentTimer(diffInSeconds + 10)
  }, 10)

  const Actions: Record<Actions, ReactElement> = {
    sms_agent: <Text size="xs">Pending</Text>,
    pending: <Text size="xs">Pending</Text>,
    agent_accepted: (
      <Button onClick={() => connectAgent(agent.agentId)} size="xs">
        Connect
      </Button>
    ),
    perform: (
      <Button isLoading isDisabled size="xs">
        Connecting
      </Button>
    ),
    transferred: <Text size="xs">Connected</Text>,
    canceled: <Text size="xs">Canceled</Text>,
    agent_disconnected: <Text size="xs">Disconnected</Text>
  }

  return (
    <S.Agent
      isAgentCanceled={agent.action === 'canceled'}
      spaceBetweenAgents={haveSpaceBetweenAgents}
      data-testid={`agent-box-${agent.agentId}`}
    >
      <S.Flex>
        <S.ImageWrapper>
          <Tooltip text={tooltipText}>
            <RoundImage
              src={agent.agentPictureUrl}
              onError={handleDefaultImage}
              alt={agent.agentName}
            />
          </Tooltip>
        </S.ImageWrapper>
        <S.AgentInformation>
          {agent.action === 'canceled' && agent.agentName ? (
            <Text>{agent.agentName}</Text>
          ) : (
            <Link to={`/agents/${agent.agentId}`}>{agent.agentName}</Link>
          )}

          <Text color="neutral9" hasEllipsis size="xs">
            {dataOrDash(agent.agentBrokerage)}
          </Text>
          {agent.leadRoutingEnabled?.toString() === 'true' && (
            <Text color="neutral9" hasUnderline size="xs">
              Routing Enabled
            </Text>
          )}
          {['agent_accepted', 'transferred'].includes(agent.action) &&
            agent.leadRoutingEnabled?.toString() === 'true' &&
            agent.workedByAgent?.agentName && (
              <>
                <Text color="neutral9" weight="bold" size="xs">
                  Claimed By
                </Text>
                <S.Flex>
                  <S.ImageWrapper>
                    <Tooltip text={agent.workedByAgent.agentName}>
                      <RoundImage
                        src={agent.workedByAgent.agentPictureUrl}
                        onError={handleDefaultImage}
                        alt={agent.workedByAgent.agentName}
                      />
                    </Tooltip>
                  </S.ImageWrapper>
                  <S.AgentInformation>
                    <Link to={`/agents/${agent.workedByAgent.agentId}`}>
                      {agent.workedByAgent.agentName}
                    </Link>
                  </S.AgentInformation>
                </S.Flex>
              </>
            )}
        </S.AgentInformation>
      </S.Flex>
      <S.Actions>
        {Actions[agent.action] || Actions.pending}
        <Text size="xs">{formatTalkTime(agentTimer)}</Text>
      </S.Actions>
    </S.Agent>
  )
}
