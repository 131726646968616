/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-strict
import { Block, DownloadButton, ExternalLink, TableCellProps, TableCell as Td } from 'components'
import { EditEscrowOrderButtonForm } from 'content/EscrowLead/EditEscrowOrder'
import { EscrowLead, getQualiaUrl, LeadUser } from 'models'
import { dataOrDash, formatDateTime, parseAddress, structureAddress, titleize } from 'utils'
import styles from './EscrowLeadsTable.module.scss'

type TProps<T = EscrowLead> = TableCellProps & {
  column?: string
  lead: T
  testId?: string
}

const getSourceString = (lead: EscrowLead) => {
  if (lead.order?.source) {
    return titleize(lead.order?.source)
  }

  if (lead?.source) {
    const source = `${titleize(lead.source)}`
    const sourceType = lead?.sourcePageType ? ` (${titleize(lead.sourcePageType)})` : ''

    return `${source}${sourceType}`
  }

  return null
}

export const EscrowLeadAddressCell = ({ lead, testId }: TProps) => {
  const { street, cityState } = structureAddress(lead.fullAddress)

  return (
    <Td data-testid={testId} className={styles.addressCell}>
      <Block title={street} subtitle={dataOrDash(cityState)} />
    </Td>
  )
}

export const EscrowLeadsSourceCell = ({ lead, testId }: TProps) => {
  return (
    <Td data-testid={testId}>
      <Block title={dataOrDash(getSourceString(lead))} />
    </Td>
  )
}

export const EscrowOrderNumberCell = ({ lead, testId }: TProps) => {
  const { state } = parseAddress(lead.fullAddress || '')

  if (!lead?.escrowOfficeProviderLead?.escrowOfficeLead?.orderIdentifier) {
    return <Td>N/A</Td>
  }

  return (
    <Td data-testid={testId} onClick={e => e.stopPropagation()}>
      <ExternalLink
        href={getQualiaUrl(
          lead?.escrowOfficeProviderLead?.escrowOfficeLead?.orderIdentifier,
          state
        )}
      >
        {dataOrDash(lead?.escrowOfficeProviderLead?.escrowOfficeLead?.orderNumber)}
      </ExternalLink>
    </Td>
  )
}

export const PurchaseContractCell = ({ lead, testId }: TProps) => {
  const purchaseContracts = lead?.fileAttachments
    ?.filter(fileAttachment => fileAttachment?.file?.category === 'hlcs_purchase_contract')
    .filter((el: any, i: any, arr: any[]) => arr.findIndex(el2 => el2.id === el.id) === i) // unique

  return (
    <Td data-testid={testId}>
      {purchaseContracts?.length ? (
        purchaseContracts.map(contract => (
          <div key={contract.id} onClick={e => e.stopPropagation()}>
            <DownloadButton url={contract.downloadUrl}>{contract.file?.name}</DownloadButton>
          </div>
        ))
      ) : (
        <Block title={dataOrDash('')} />
      )}
    </Td>
  )
}

export const SettlementAgencyCell = ({ lead, testId }: TProps) => {
  return (
    <Td data-testid={testId}>
      <Block
        title={dataOrDash(lead?.escrowOfficeProviderLead?.escrowOfficeLead?.serviceOffice?.name)}
      />
    </Td>
  )
}

export const SubmissionTimeCell = ({ lead, testId }: TProps) => {
  return (
    <Td data-testid={testId}>
      <Block title={formatDateTime(lead?.createdAt, 'M/d/yy h:mma')} />
    </Td>
  )
}

export const EscrowOfficerCell = ({ lead, testId }: TProps) => {
  const eo = (lead?.leadUsers || []).find(
    leadUser => leadUser.role === 'escrow_officer'
  ) as LeadUser

  return (
    <Td data-testid={testId}>
      {eo ? (
        <Block title={dataOrDash(eo?.user?.name)} subtitle={dataOrDash(eo?.user?.email)} />
      ) : (
        '---'
      )}
    </Td>
  )
}

export const EditCell = ({ lead, testId }: TProps) => {
  return (
    <Td data-testid={testId} className={styles.editCell}>
      <EditEscrowOrderButtonForm lead={lead} className={styles.editButton} />
    </Td>
  )
}
