import type { TableCellAlignments, TableCellSizes } from 'components'
import { byValue } from 'utils'
import { Column } from '../Column'
import type { TColumnAttributes } from '../Column'
import {
  agentMatchingOrdersColumnsSettings,
  agentPartnershipsColumnsSettings,
  agentsColumnsSettings,
  agentTasksColumnsSettings,
  ALL_LEAD_TYPES_COLUMNS_SETTINGS,
  attachLeadColumnsSettings,
  attachLeadsColumnsSettings,
  bbysTasksColumnsSettings,
  BUYER_AND_SELLER_LEAD_SETTINGS,
  BUYER_AND_SELLER_LEAD_WITH_PENDING_ACTIONS_SETTINGS,
  cashCloseOrdersColumnSettings,
  cashOfferTasksColumnsSettings,
  ccCashOfferLeadSettings,
  ccHLSimpleSaleLeadSettings,
  ccTradeInLeadsColumnsSettings,
  closingServicesOrdersColumnsSettings,
  docusignColumnsSettings,
  ESCROW_LEAD_TYPES_COLUMNS_SETTINGS,
  externalResourcesCashOfferColumnsSettings,
  externalResourcesColumnsSettings,
  fileUploadsCashOfferColumnsSettings,
  fileUploadsColumnsSettings,
  homeLoansOrdersColumnsSettings,
  INSPECTION_COLUMN_SETTINGS,
  INSPECTION_DEFICIENCY_COLUMN_SETTINGS,
  leadsColumnsSettings,
  mortgageLeadSettings,
  ordersColumnsSettings,
  pageTasksColumnsSettings,
  pageTasksWithIrCoeDateColumnsSettings,
  queueOrdersColumnsSettings,
  similarLeadsSettings,
  simpleSaleOrdersColumnsSettings,
  tradeInTasksColumnsSettings
} from '../listColumns/settings'

export type TGeneratedColumnsLookup = {
  columns: Column[]
  findColumn: (attr: string) => Column | undefined
  getColumnAlignment: (attr: string) => TableCellAlignments | undefined
  getColumnName: (attr: string) => string | undefined
  getColumnSize: (attr: string) => TableCellSizes | undefined
  getColumnTitle: (attr: string) => string | undefined
  getSortableAttributes: (attr: string) => readonly string[] | undefined
  isDefaultColumn: (columnName: string) => boolean
  isFixedColumn: (columnName: string) => boolean
}

export const generateListColumnsLookup = <S extends readonly TColumnAttributes[]>(settings: S) => {
  const columns = settings.map(item => new Column(item))

  const findColumn = (attr: string) => columns.find(byValue(attr))
  const getColumnName = (attr: string) => findColumn(attr)?.name
  const getColumnTitle = (attr: string) => findColumn(attr)?.title
  const getColumnSize = (attr: string) => findColumn(attr)?.columnSize
  const getColumnAlignment = (attr: string) => findColumn(attr)?.align
  const getSortableAttributes = (attr: string) => findColumn(attr)?.sort
  const isFixedColumn = (columnName: string) => {
    const column = findColumn(columnName)
    return !!column && !column.isOptional
  }
  const isDefaultColumn = (columnName: string) => {
    const column = findColumn(columnName)
    return !!column && !!column.isDefault
  }

  return {
    columns,
    findColumn,
    isFixedColumn,
    isDefaultColumn,
    getColumnName,
    getColumnTitle,
    getColumnSize,
    getColumnAlignment,
    getSortableAttributes
  }
}

export const leadsListColumns = generateListColumnsLookup(leadsColumnsSettings)
export const attachLeadsListColumns = generateListColumnsLookup(attachLeadsColumnsSettings)
export const agentMatchingOrdersListColumns = generateListColumnsLookup(
  agentMatchingOrdersColumnsSettings
)
export const agentPartnershipsListColumns = generateListColumnsLookup(
  agentPartnershipsColumnsSettings
)
export const agentsListColumns = generateListColumnsLookup(agentsColumnsSettings)

export const allLeadsListColumns = generateListColumnsLookup(ALL_LEAD_TYPES_COLUMNS_SETTINGS)
export const escrowLeadsListColumns = generateListColumnsLookup(ESCROW_LEAD_TYPES_COLUMNS_SETTINGS)
export const ccCashOfferLeadsListColumns = generateListColumnsLookup(ccCashOfferLeadSettings)
export const ccTradeInLeadsListColumns = generateListColumnsLookup(ccTradeInLeadsColumnsSettings)
export const ccHLSimpleSaleLeadsListColumns = generateListColumnsLookup(ccHLSimpleSaleLeadSettings)
export const mortgageLeadsListColumns = generateListColumnsLookup(mortgageLeadSettings)
export const buyerAndSellerLeadsListColumns = generateListColumnsLookup(
  BUYER_AND_SELLER_LEAD_SETTINGS
)
export const buyerAndSellerLeadsListColumnsWithPendingActions = generateListColumnsLookup(
  BUYER_AND_SELLER_LEAD_WITH_PENDING_ACTIONS_SETTINGS
)
export const inspectionListColumns = generateListColumnsLookup(INSPECTION_COLUMN_SETTINGS)
export const inspectionDeficiencyListColumns = generateListColumnsLookup(
  INSPECTION_DEFICIENCY_COLUMN_SETTINGS
)

export const pageTaskListColumns = generateListColumnsLookup(pageTasksColumnsSettings)
export const pageTaskListWithIrCoeDateColumns = generateListColumnsLookup(
  pageTasksWithIrCoeDateColumnsSettings
)
export const agentTaskListColumns = generateListColumnsLookup(agentTasksColumnsSettings)
export const attachLeadListColumns = generateListColumnsLookup(attachLeadColumnsSettings)
export const cashCloseOrdersListColumns = generateListColumnsLookup(cashCloseOrdersColumnSettings)
export const cashOfferTaskListColumns = generateListColumnsLookup(cashOfferTasksColumnsSettings)
export const closingServiceOrdersListColumns = generateListColumnsLookup(
  closingServicesOrdersColumnsSettings
)
export const externalResourcesCashOfferListColumns = generateListColumnsLookup(
  externalResourcesCashOfferColumnsSettings
)
export const docusignListColumns = generateListColumnsLookup(docusignColumnsSettings)
export const externalResourcesListColumns = generateListColumnsLookup(
  externalResourcesColumnsSettings
)
export const fileUploadsCashOfferListColumns = generateListColumnsLookup(
  fileUploadsCashOfferColumnsSettings
)
export const fileUploadsListColumns = generateListColumnsLookup(fileUploadsColumnsSettings)
export const homeLoansOrdersListColumns = generateListColumnsLookup(homeLoansOrdersColumnsSettings)
export const ordersListColumns = generateListColumnsLookup(ordersColumnsSettings)
export const queueOrdersListColumns = generateListColumnsLookup(queueOrdersColumnsSettings)
export const similarLeadsListColumns = generateListColumnsLookup(similarLeadsSettings)
export const simpleSaleOrdersListColumns = generateListColumnsLookup(
  simpleSaleOrdersColumnsSettings
)
export const tradeInTasksListColumns = generateListColumnsLookup(tradeInTasksColumnsSettings)
export const bbysTasksListColumns = generateListColumnsLookup(bbysTasksColumnsSettings)
