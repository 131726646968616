// @ts-strict
import { useButtonForm } from 'components'
import { useTaskController } from 'controllers'
import { Task } from 'models'

type TTaskCompleteButtonFormPayload = {
  completionNote?: string
}

const initialPayload: TTaskCompleteButtonFormPayload = { completionNote: '' }

export const useTaskCompleteButtonForm = (task: Task, onSuccessCallback?: Function) => {
  const { completeTask } = useTaskController(task.id)

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm<TTaskCompleteButtonFormPayload>({
    initialPayload,
    mutation: completeTask,
    callback: onSuccessCallback ? () => onSuccessCallback() : undefined
  })

  return {
    formProps,
    onChangeAttribute,
    payload
  }
}
