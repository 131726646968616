import { getUserAuthUrl } from 'services/getUrl'
import { deleteRequest } from 'utils'

export const deleteImpersonateUser = async () => {
  const response = await deleteRequest(getUserAuthUrl({ action: 'un-impersonate-user' }))

  return {
    data: response.data
  }
}
