// @ts-strict
import { EmptyTableCell, TableRow as Tr } from 'components'
import {
  BuyerAndSellerLeadListingStatusCell,
  BuyerAndSellerLeadNamePartnerWithLink,
  BuyerAndSellerLeadPendingActionCell
} from 'content/BuyerAndSellerLeads/BuyerAndSellerLeadsTable/BuyerAndSellerLeadsTableCells'
import {
  LeadAddressCell,
  LeadAgentsOppsCell,
  LeadCreatedUpdatedCell,
  LeadIntroSentAtCell,
  LeadNeedsListingReviewCell,
  LeadOtherLeadsCell,
  LeadPriceCell,
  LeadStageFurthestCell
} from 'content/Leads'
import { useListContext } from 'contexts'
import { BuyerLead, SellerLead } from 'models'
import { capitalize, cx } from 'utils'

const columnMapping = {
  namePartner: BuyerAndSellerLeadNamePartnerWithLink,
  address: LeadAddressCell,
  createdUpdated: LeadCreatedUpdatedCell,
  otherLeads: LeadOtherLeadsCell,
  stageFurthest: LeadStageFurthestCell,
  price: LeadPriceCell,
  listingStatus: BuyerAndSellerLeadListingStatusCell,
  introSentAt: LeadIntroSentAtCell,
  agentsOpps: LeadAgentsOppsCell,
  needsListingReview: LeadNeedsListingReviewCell,
  pendingActions: BuyerAndSellerLeadPendingActionCell
}

type TProps = {
  lead: BuyerLead | SellerLead
}

export const BuyerAndSellerLeadTableRow = ({ lead }: TProps) => {
  const { highlightRows, visibleColumns, findColumn } = useListContext()
  const rowClassName = highlightRows?.includes(lead.id) && 'highlight'

  return (
    <Tr className={cx(rowClassName)} data-testid={`lead-id-${lead.id}`}>
      {visibleColumns.map(visibleColumn => {
        const Cell = columnMapping[visibleColumn]
        const testId = `lead${capitalize(visibleColumn)}Cell`
        const columnSetting = findColumn(visibleColumn)
        return Cell ? (
          <Cell
            lead={lead}
            column={visibleColumn}
            key={visibleColumn}
            testId={testId}
            align={columnSetting?.align}
            size={columnSetting?.columnSize}
          />
        ) : (
          <EmptyTableCell
            key={visibleColumn}
            data-testId={testId}
            align={columnSetting?.align}
            size={columnSetting?.columnSize}
          />
        )
      })}
    </Tr>
  )
}
