import { TeamType } from 'models'
import { getFilter } from 'services/utils'

export type TeamsParams = {
  type: TeamType
}

const filterSlugs = {
  type: 'filter[type]'
}

export const getTeamSearchParams = ({ type = 'TransactionTeam', ...restParams }: TeamsParams) => {
  const filtersResult = getFilter(filterSlugs, {
    type,
    ...restParams
  })

  return filtersResult
}
