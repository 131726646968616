export type TGetMessagesCountSettings = {
  fromDate?: string
  senderId?: string
}

export const getMessagesCountSearchParams = ({ fromDate, senderId }: TGetMessagesCountSettings) => {
  const filters = {}

  if (fromDate) {
    filters['filter[>created_at]'] = fromDate
  }
  if (senderId) {
    filters['filter[internal_sender_id]'] = senderId
  }

  return filters
}
