import { HTMLProps, MutableRefObject } from 'react'
import { Icon, Text } from '@foundation/components'
import * as SelectStyle from '../Select/styles'
import { AutocompleteProps } from './types'

type OptionProps = {
  activeIndex: number | null
  getItemProps: (userProps?: HTMLProps<HTMLElement> | undefined) => Record<string, unknown>
  index: number
  isSelected: boolean
  label: string
  listRef: MutableRefObject<(HTMLElement | null)[]>
  onClick: () => void
  size: AutocompleteProps['size']
  value: string
}

export const Option = ({
  getItemProps,
  value,
  label,
  listRef,
  index,
  onClick,
  isSelected = false,
  activeIndex,
  size
}: OptionProps) => {
  return (
    <div
      role="option"
      aria-selected={isSelected}
      key={value}
      tabIndex={index === activeIndex ? 0 : -1}
      {...getItemProps({
        key: value,
        ref(node: HTMLElement | null) {
          listRef.current[index] = node
        },
        onClick() {
          onClick()
        }
      })}
    >
      <SelectStyle.Option
        data-testid={`${value}Option`}
        disabled={false}
        isFocused={index === activeIndex}
      >
        <SelectStyle.OptionCheck data-testid={`${value}OptionCheck`} isSelected={isSelected}>
          {isSelected && <Icon data-testid="checkIcon" path="Check" color="primary5" />}
        </SelectStyle.OptionCheck>
        <Text color="primary10" size={SelectStyle.selectOptionTextSize[size!]}>
          {label}
        </Text>
      </SelectStyle.Option>
    </div>
  )
}
