import {
  ExternalLink,
  FormField,
  InlineEditAddressUnit,
  InlineEditSelect,
  List,
  MapBox,
  Section,
  Sections
} from 'components'
import {
  solarPanelsOptions,
  trueFalseOptions,
  useCcTradeInLeadFields,
  useLeadPropertyTypes
} from 'lookups'
import { toSearchParam } from 'utils'
import { CCTradeInLeadDetailsProps } from '../CCTradeInLeadDetails'
import { getTradeInLeadInputProps } from './utils'

type Props = Pick<CCTradeInLeadDetailsProps, 'lead' | 'updateTradeInLead'>

export const DRPropertyInformationSection = ({ lead, updateTradeInLead }: Props) => {
  const tradeInLead = lead.tradeInProviderLead.tradeInLead
  const departingMasterProperty = tradeInLead?.departingMasterProperty
  const { leadPropertyTypes } = useLeadPropertyTypes()
  const { getCcTradeInLeadFieldName } = useCcTradeInLeadFields()
  const { getProps } = getTradeInLeadInputProps(tradeInLead, updateTradeInLead)

  return (
    <Sections title="DR Property Information">
      <Section>
        <FormField title={getCcTradeInLeadFieldName('displayAddress')} inline>
          <InlineEditAddressUnit
            onBlur={val =>
              updateTradeInLead.mutate({
                id: tradeInLead.id,
                payload: {
                  departing_property_full_address: val
                }
              })
            }
            value={departingMasterProperty?.displayAddress}
          />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpPropertyType')} inline>
          <InlineEditSelect options={leadPropertyTypes} {...getProps('dpPropertyType')} />
        </FormField>
        <FormField
          title={getCcTradeInLeadFieldName('departingPropertyHomeownerAssociation')}
          inline
        >
          <InlineEditSelect
            {...getProps('departingPropertyHomeownerAssociation')}
            options={trueFalseOptions}
            addBlank
          />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('departingPropertySolarPanels')} inline>
          <InlineEditSelect
            {...getProps('departingPropertySolarPanels')}
            options={solarPanelsOptions}
            addBlank
          />
        </FormField>
        <FormField title="External Links" inline>
          <List>
            <ExternalLink
              href={`https://www.google.com/search?q=${toSearchParam(
                departingMasterProperty?.displayAddress
              )}`}
            >
              Google Address
            </ExternalLink>
            <ExternalLink
              href={`http://www.zillow.com/homes/${toSearchParam(
                departingMasterProperty?.displayAddress
              )}_rb`}
            >
              Zillow Address
            </ExternalLink>
          </List>
        </FormField>
      </Section>
      <Section>
        {departingMasterProperty?.latitude && departingMasterProperty?.longitude ? (
          <MapBox
            center={departingMasterProperty?.point}
            markers={[departingMasterProperty?.marker]}
          />
        ) : (
          <FormField title="Map" inline>
            Latitude and Longitude are missing
          </FormField>
        )}
      </Section>
    </Sections>
  )
}
