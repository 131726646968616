import { Section } from 'components'
import { Agent } from 'models'
import { cx } from 'utils'
import { AgentContact } from '../AgentSummary/AgentContact'

type Props = {
  agent: Agent
  className?: string
}

export const AgentContactStatus = ({ agent, className }: Props) => {
  return (
    <Section title="Contact / Status" className={cx('agent-contact', className)}>
      <AgentContact agent={agent} />
    </Section>
  )
}
