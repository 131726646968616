import { useEffect } from 'react'
import { TokenResponse, useGoogleLogin } from '@react-oauth/google'
import { TOKEN_KEY } from '@constants'
import { useLocalStorage, useMutation, useQueryClient } from 'hooks'
import { postGoogleSignin, postSignin, postSignout } from 'services/Auth'

export const useTokenController = () => {
  const queryCache = useQueryClient()
  const [token, setToken, clearToken] = useLocalStorage<String | undefined>(TOKEN_KEY, undefined)

  useEffect(() => {
    if (!token) {
      queryCache.invalidateQueries()
    }
  }, [token, queryCache])

  const signInWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse: TokenResponse) => {
      const token = await postGoogleSignin(tokenResponse.access_token)
      setToken(token)
    },
    onError: error => console.log('Login Failed:', error),
    flow: 'implicit'
  })

  const signIn = useMutation(postSignin, {
    onSuccess: token => {
      setToken(token)
    }
  })

  const signOut = useMutation(postSignout, { onSuccess: clearToken })

  return {
    signIn,
    signInWithGoogle,
    signOut,
    token,
    clearToken,
    setToken
  }
}
