import { getAgentsUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export type PutAgentVisibilityPayload = {
  hideFromSearch: boolean
  hideProfile: boolean
  notes: string
  notifyAgent: string
  reason: string
}

export const putAgentVisibility = (id: string, payload: PutAgentVisibilityPayload) =>
  putRequest(getAgentsUrl({ id, action: 'visibility' }), snakeCaseKeys(payload))
