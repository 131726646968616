import {
  DisplayValue,
  FormField,
  InlineEditPhoneInput,
  InlineEditTextInput,
  Section,
  Sections,
  useButtonForm
} from 'components'
import { useFeatureFlags } from 'hooks'
import { dataOrDash, snakeCaseKeys } from 'utils'
import { CCTradeInLeadDetailsProps } from '../CCTradeInLeadDetails'
import { LenderInformationPopup } from './LenderInformationPopup'

type Props = Pick<CCTradeInLeadDetailsProps, 'lead' | 'updateLenderLead'>
export type TLenderInformationFieldsPayload = Record<
  | 'lenderType'
  | 'lenderName'
  | 'lenderEmail'
  | 'lenderPhone'
  | 'lenderCompanyName'
  | 'loanOfficerId',
  string
>

export const LenderInformationSection = ({ lead, updateLenderLead }: Props) => {
  const { bbysLenderOptions } = useFeatureFlags(['bbys_lender_options'])
  const lenderLead = lead.lenderProviderLeads?.[0]?.providable
  const userInvitation = lead.userInvitations?.[0]

  const isOutsideLender = !!lead.userInvitations?.length
  const getMortgageOption = (): string => {
    if (isOutsideLender) {
      return 'outside'
    } else if (lenderLead) {
      return lenderLead.lender ? 'homelight' : 'unsure'
    }

    return 'none'
  }

  const mortgageOptionDisplayMap = {
    homelight: 'HomeLight Home Loans',
    outside: 'Outside lender',
    none: 'No mortgage needed',
    unsure: 'Unsure'
  }

  const data = {
    lenderName: userInvitation?.name,
    lenderEmail: userInvitation?.email,
    lenderCompanyName:
      getMortgageOption() === 'homelight' ? '' : lenderLead?.loanOfficer?.companyName,
    lenderPhone: lenderLead?.loanOfficer?.phoneNumbers?.[0]?.number,
    lenderType: getMortgageOption(),
    loanOfficerId: lenderLead?.loanOfficer?.id
  }

  const initialPayload: TLenderInformationFieldsPayload = {
    ...data
  }

  const getPostPayload = payload => {
    if (payload.lenderType === 'outside') {
      return snakeCaseKeys(payload)
    }

    return {
      lender_type: payload.lenderType
    }
  }

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm<TLenderInformationFieldsPayload>({
    initialPayload,
    mutation: updateLenderLead,
    transformPayload: payload => ({
      id: lead.id,
      payload: getPostPayload(payload)
    })
  })

  if (!bbysLenderOptions.enabled) {
    return null
  }

  return (
    <Sections title="Lender Information">
      <Section>
        <FormField title="Mortgage Option" onClick={() => formProps.toggleShowForm()} inline>
          <DisplayValue
            setEditMode={() => {}}
            value={dataOrDash(mortgageOptionDisplayMap[getMortgageOption()])}
          />
        </FormField>
        {formProps.showForm && (
          <LenderInformationPopup
            payload={payload}
            onChangeAttribute={onChangeAttribute}
            formProps={formProps}
          />
        )}
        {isOutsideLender && (
          <>
            <FormField title="Loan Officer Name" inline>
              <InlineEditTextInput
                onBlur={(val: string) =>
                  updateLenderLead.mutate({
                    id: lead.id,
                    payload: {
                      lenderType: payload.lenderType,
                      lenderName: val
                    }
                  })
                }
                value={dataOrDash(data.lenderName)}
              />
            </FormField>
            <FormField title="Loan Officer's Email Address" inline>
              <InlineEditTextInput
                onBlur={(val: string) =>
                  updateLenderLead.mutate({
                    id: lead.id,
                    payload: {
                      lenderType: payload.lenderType,
                      lenderEmail: val
                    }
                  })
                }
                value={dataOrDash(data.lenderEmail)}
              />
            </FormField>
          </>
        )}
      </Section>
      <Section>
        {isOutsideLender && (
          <>
            <FormField title="Lender" inline>
              <InlineEditTextInput
                onBlur={(val: string) =>
                  updateLenderLead.mutate({
                    id: lead.id,
                    payload: {
                      lenderType: payload.lenderType,
                      lenderCompanyName: val
                    }
                  })
                }
                value={dataOrDash(data.lenderCompanyName)}
              />
            </FormField>
            <FormField title="Loan Officer's Phone Number" inline>
              <InlineEditPhoneInput
                onBlur={(val: string) =>
                  updateLenderLead.mutate({
                    id: lead.id,
                    payload: {
                      lenderType: payload.lenderType,
                      lenderPhone: val
                    }
                  })
                }
                value={dataOrDash(data.lenderPhone)}
              />
            </FormField>
          </>
        )}
      </Section>
    </Sections>
  )
}
