import { PaginationParams } from 'components'
import { LeadsView } from 'content/Leads'
import { useLeadsContext } from 'contexts/Leads'
import { LeadsListHeader } from './LeadsListHeader'

export const LeadsList = () => {
  const { leads, pageCount, isLoading } = useLeadsContext()

  return (
    <>
      <LeadsListHeader />
      <LeadsView leads={leads} isLoading={isLoading} />
      {pageCount ? <PaginationParams totalPages={pageCount} /> : null}
    </>
  )
}
