// @ts-strict
import { InvestorResponse, PplBillingInfoResponse, PplProviderSettingResponse } from 'schema'
import { Model } from '../Model'

type InvestorAttributes = Omit<Investor, 'id'>

export class Investor extends Model {
  id!: string
  name?: string
  email?: string
  offerUrl?: string
  phoneMobile?: string
  phoneOffice?: string
  pplInvestor?: string

  pplBillingInfos?: PplBillingInfoResponse[]
  pplProviderSettings?: PplProviderSettingResponse[]

  constructor(attributes: InvestorAttributes) {
    super()

    Object.assign(this, attributes)
  }

  static create(investorResponse: Potential<InvestorResponse>): Potential<Investor> {
    if (!investorResponse) {
      return
    }

    return new Investor(investorResponse)
  }
}
