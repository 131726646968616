// @ts-strict
import { ESCROW_LEADS_CACHE_KEY, useMutation, UseMutationResult, useQueryClient } from 'hooks'
import { postEditEscrowOrder, TPostEditEscrowOrderPayload } from 'services'

type UseEditEscrowOrderControllerProps = {
  clearCacheKey?: string
  escrowOfficeLeadId?: string
  onSuccess?: () => void
}

export const useEditEscrowOrderController = ({
  onSuccess,
  clearCacheKey,
  escrowOfficeLeadId
}: UseEditEscrowOrderControllerProps) => {
  const queryCache = useQueryClient()
  const clearCaches = () => {
    if (clearCacheKey) {
      queryCache.invalidateQueries([clearCacheKey])
    }
    queryCache.invalidateQueries([ESCROW_LEADS_CACHE_KEY])
  }

  const editEscrowOrder: UseMutationResult<string, unknown, TPostEditEscrowOrderPayload, unknown> =
    useMutation(data => postEditEscrowOrder(data, escrowOfficeLeadId), {
      onSuccess: () => {
        clearCaches()
        if (onSuccess) {
          onSuccess()
        }
      }
    })

  return { editEscrowOrder }
}
