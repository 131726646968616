import { useEffect, useState } from 'react'
import { FormField, LoadingText, Section } from 'components'
import { LoanOfficer } from 'models'
import { getLoanOfficer } from 'services/LoanOfficers/getLoanOfficer'
import { dataOrDash } from 'utils'
import { ContactActionBar } from '../ContactActionBar'
import styles from './LoanOfficerDetailsMenuCard.module.scss'

type LoanOfficerDetailsMenuCardProps = {
  loanOfficerId: string
  onOpenChangeModal?: (isOpen: boolean) => void
}

export const LoanOfficerDetailsMenuCard = ({
  loanOfficerId,
  onOpenChangeModal
}: LoanOfficerDetailsMenuCardProps) => {
  const [loanOfficer, setLoanOfficer] = useState<LoanOfficer | null>(null)

  useEffect(() => {
    getLoanOfficer(loanOfficerId).then(data => {
      setLoanOfficer(data)
    })
  }, [loanOfficerId])

  if (!loanOfficer) {
    return (
      <Section>
        <LoadingText text="Loading loan officer data..." />
      </Section>
    )
  }

  return (
    <Section className={styles.content}>
      <ContactActionBar loanOfficer={loanOfficer} onOpenChangeModal={onOpenChangeModal} />

      <FormField className={styles.formField} title="Name" inline>
        {dataOrDash(loanOfficer?.name)}
      </FormField>
      <FormField className={styles.formField} title="Email" inline>
        {dataOrDash(loanOfficer?.email)}
      </FormField>
      <FormField className={styles.formField} title="Phone" inline>
        {dataOrDash(loanOfficer?.getPhone())}
      </FormField>
      <FormField className={styles.formField} title="Lender" inline>
        {dataOrDash(loanOfficer?.companyName)}
      </FormField>
      <FormField className={styles.formField} title="Partner" inline>
        {dataOrDash(loanOfficer?.partner?.slug)}
      </FormField>
    </Section>
  )
}
