// @ts-strict
import { useAgentAeAreasContext } from 'contexts/AgentAeAreas'
import { AgentAeArea, isMetroplex, isUsState } from 'models'
import { DEFAULT_STRING } from 'utils'

type Props = {
  agentAeArea: AgentAeArea
}

export const useAgentAeAreasTableRow = ({ agentAeArea }: Props) => {
  const { agentAeAreasQuery } = useAgentAeAreasContext()
  const { assignedAreaAgentsCount } = agentAeAreasQuery
  const { assignedArea, servicesUser, user } = agentAeArea

  const state = isMetroplex(assignedArea) ? assignedArea.state?.code : assignedArea?.['code']
  const areaName = isMetroplex(assignedArea) ? assignedArea.name : 'Non Metroplex'

  const marketplacePrograms = isUsState(assignedArea)
    ? assignedArea.marketplacePrograms
    : assignedArea?.['state']?.marketplacePrograms

  const agentsCountKey = isMetroplex(assignedArea) ? 'metroplex' : 'state'
  const agentsCount =
    assignedAreaAgentsCount[agentsCountKey][assignedArea?.id || ''] || DEFAULT_STRING

  return {
    agentsCount,
    areaName,
    marketplacePrograms,
    servicesUser,
    state,
    user
  }
}
