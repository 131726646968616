// @ts-strict
import { Table, TableBody } from 'components/Table'
import { AgentTeamMembership } from 'models'
import { presence } from 'utils'
import styles from './AgentTeamMembershipsTable.module.scss'
import { AgentTeamMembershipsTableHeader } from './AgentTeamMembershipsTableHeader'
import { AgentTeamMembershipsTableRow } from './AgentTeamMembershipsTableRow'

type Props = {
  agentTeamMemberships: Potential<AgentTeamMembership[]>
}

export const AgentTeamMembershipsTable = ({ agentTeamMemberships }: Props) => {
  if (!presence(agentTeamMemberships)) {
    return <span>There are no team members.</span>
  }

  return (
    <Table wrapperClassName={styles.tableWrapper}>
      <AgentTeamMembershipsTableHeader />
      <TableBody>
        {agentTeamMemberships!.map(teamMember => {
          if (!teamMember.user) {
            return null
          }

          return <AgentTeamMembershipsTableRow teamMember={teamMember} key={teamMember.user!.id} />
        })}
      </TableBody>
    </Table>
  )
}
