type TCanaryProps = {
  className?: string
}

export const Canary = ({ className }: TCanaryProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className={className}>
    <path
      fill="currentColor"
      d="M544 32h-16.36C513.04 12.68 490.09 0 464 0c-44.18 0-80 35.82-80 80v20.98L12.09 393.57A30.216 30.216 0 000 417.74c0 22.46 23.64 37.07 43.73 27.03L165.27 384h96.49l44.41 120.1c2.27 6.23 9.15 9.44 15.38 7.17l22.55-8.21c6.23-2.27 9.44-9.15 7.17-15.38L312.94 384H352c1.91 0 3.76-.23 5.66-.29l44.51 120.38c2.27 6.23 9.15 9.44 15.38 7.17l22.55-8.21c6.23-2.27 9.44-9.15 7.17-15.38l-41.24-111.53C485.74 352.8 544 279.26 544 192v-80l96-16c0-35.35-42.98-64-96-64zm-80 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
    />
  </svg>
)

Canary.displayName = 'Canary'

export const RedDot = () => (
  <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5" r="5" fill="red" />
  </svg>
)

export const GreenDot = () => (
  <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5" r="5" fill="green" />
  </svg>
)

type TLogoWithTextProps = {
  height?: number
  width?: number
}

export const LogoWithText = ({ width = 200, height = 50, ...props }: TLogoWithTextProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="45 80 420 90"
    width={width}
    height={height}
    {...props}
  >
    <circle cx="90" cy="125" r="40" fill="#fff" />
    <path
      fill="#46b6ff"
      fillRule="evenodd"
      d="M126.1 139.3a40.3 40.3 0 0 0 2.6-14.3 39.4 39.4 0 1 0-78.7 0 38.3 38.3 0 0 0 2.7 14.3l36.7-29.9z"
    />
    <path
      fill="#273653"
      fillRule="evenodd"
      d="M89.4 122.5l31.6 25.9a39.3 39.3 0 0 1-63.3 0zM81.7 146h6.6v-6.5h-6.6zm8.8-6.5H97v6.5h-6.5zm-8.8 8.8h6.6v6.5h-6.6zm8.8 0H97v6.5h-6.5zM178.7 145.8v-20.5h-26.1v20.5h-5.5v-43.1h5.5v17.6h26.1v-17.6h5.6v43.1h-5.6zM191.4 129.6c0-9.4 6.2-17 16-17s16 7.6 16 17-6.1 17-16 17-16-7.7-16-17m26.7 0c0-6.6-3.8-12.5-10.7-12.5s-10.7 5.9-10.7 12.5 3.8 12.5 10.7 12.5 10.7-5.9 10.7-12.5M269 145.8v-22c0-4.1-1.7-6.7-5.9-6.7s-6.9 2.4-8.6 5v23.7h-5v-22c0-4.1-1.7-6.7-6-6.7s-6.7 2.4-8.5 5v23.7h-5v-32.4h5v4.7a14.1 14.1 0 0 1 10.5-5.5c4.9 0 7.7 2.7 8.7 6.1a13.4 13.4 0 0 1 10.8-6.1c5.9 0 9 3.3 9 9.8v23.4zM280.6 129.6c0-9.4 6.7-17 15.9-17S312 120.3 312 130v1.2h-26.1c.4 6.1 4.6 11.2 11.6 11.2a14.1 14.1 0 0 0 10-4.1l2.5 3.2a17.7 17.7 0 0 1-12.9 5.1c-9.6 0-16.5-6.9-16.5-17m15.8-12.8c-6.9 0-10.3 5.8-10.5 10.7h21.2c-.1-4.8-3.3-10.7-10.7-10.7M318.7 145.8v-43.1h5.5v38.1H345v5h-26.3zM349.3 105.5a3.4 3.4 0 0 1 3.4-3.4 3.4 3.4 0 1 1 0 6.7 3.4 3.4 0 0 1-3.4-3.3zm.9 40.3h5v-32.4h-5zM363.5 154l2.5-3.6c2.7 3.1 5.9 4.3 10.5 4.3s10.5-2.6 10.5-9.4v-4.9a13.8 13.8 0 0 1-10.9 5.8c-8.5 0-14.4-6.3-14.4-16.8s5.9-16.8 14.4-16.8a13.7 13.7 0 0 1 10.9 5.7v-4.9h5v31.9c0 10.2-7.6 13.6-15.5 13.6-5.4 0-9.1-1.1-13-4.9m23.5-17.5v-14.2a12.2 12.2 0 0 0-9.7-5.2c-6.5 0-10.4 5.2-10.4 12.3s3.9 12.3 10.4 12.3a12.2 12.2 0 0 0 9.7-5.2M422.2 145.8v-21.3c0-5.8-2.9-7.4-7.3-7.4a12.5 12.5 0 0 0-9.5 5v23.7h-5v-43.1h5v15.4a15.6 15.6 0 0 1 11.5-5.5c6.8 0 10.3 3.3 10.3 10.3v22.9zM436.8 139v-21.1h-5.3v-4.5h5.3v-10.7h5.1v10.7h6.6v4.5h-6.6v20c0 2.4 1.1 4.2 3.2 4.2a4.7 4.7 0 0 0 3.4-1.4l1.5 3.8a8.3 8.3 0 0 1-6 2.1c-4.8 0-7.2-2.8-7.2-7.6"
    />
  </svg>
)

LogoWithText.displayName = 'LogoWithText'

export const Logo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path
      fill="#46b6ff"
      d="M467.9 338.1a226.7 226.7 0 0 0 15.4-82.3 227.3 227.3 0 1 0-454.6 0A226.7 226.7 0 0 0 44.1 338L256 165.4z"
    />
    <path
      fill="#444445"
      d="M256 242l183 149.1a227.3 227.3 0 0 1-366 0zm-6.8 97.4h-37.8v37.8h37.8zm50.4 0h-37.8v37.8h37.8zM249.2 390h-37.8v37.8h37.8zm50.4 0h-37.8v37.8h37.8z"
    />
    <path fill="#46b6ff" d="M256 348z" />
  </svg>
)

Logo.displayName = 'Logo'

export const Spinner = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="20 20 60 60"
    preserveAspectRatio="xMidYMid"
    data-testid="spinnerIcon"
    {...props}
  >
    <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="currentColor">
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.9166666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(30 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.8333333333333334s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(60 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.75s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(90 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.6666666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(120 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.5833333333333334s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(150 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.5s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(180 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.4166666666666667s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(210 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.3333333333333333s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(240 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.25s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(270 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.16666666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(300 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.08333333333333333s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(330 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </rect>
  </svg>
)

Spinner.displayName = 'Spinner'

type TPaddleProps = {
  className?: string
}
export const Paddle = ({ className }: TPaddleProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={className}>
    <path
      fill="currentColor"
      d="M496.2 296.5C527.7 218.7 512 126.2 449 63.1 365.1-21 229-21 145.1 63.1l-56 56.1 211.5 211.5c46.1-62.1 131.5-77.4 195.6-34.2zm-217.9 79.7L57.9 155.9c-27.3 45.3-21.7 105 17.3 144.1l34.5 34.6L6.7 424c-8.6 7.5-9.1 20.7-1 28.8l53.4 53.5c8 8.1 21.2 7.6 28.7-1L177.1 402l35.7 35.7c19.7 19.7 44.6 30.5 70.3 33.3-7.1-17-11-35.6-11-55.1-.1-13.8 2.5-27 6.2-39.7zM416 320c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96z"
    />
  </svg>
)

Paddle.displayName = 'Paddle'

export const IslandOutline = () => (
  <svg viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg" className="island-outline-icon">
    <path
      fill="currentColor"
      d="M4.79121 3.75H1.40752C1.78662 2.66797 3.10945 1.25 5.80753 1.25C7.64254 1.25 8.83631 1.90625 9.52999 2.65234C9.70744 2.84375 9.98169 2.91016 10.2317 2.82031C10.7883 2.625 11.4618 2.5 12.2603 2.5C14.9584 2.5 16.2812 3.91797 16.6603 5H9.22751C9.05813 5 8.89277 5.06641 8.77178 5.18359L4.10963 9.69531C3.58534 8.67188 3.48855 6.76562 5.39213 4.91797L5.39616 4.91406C5.85592 4.46875 6.31568 4.14062 6.76335 3.89844C7.07389 3.73047 7.18681 3.35156 7.01743 3.05078C6.84804 2.75 6.45281 2.64062 6.14227 2.80469C5.68654 3.05078 5.23484 3.35938 4.79121 3.75ZM5.80753 0C2.40367 0 0.556555 1.93359 0.108891 3.60156C-0.116957 4.4375 0.633182 5 1.29056 5H3.63374C2.12136 7.0625 2.30688 9.19531 3.08122 10.4805C3.53291 11.2266 4.4726 11.1133 4.94043 10.6602L8.29186 7.41406C8.27572 7.56641 8.25556 7.72266 8.23539 7.88672C8.02164 9.47656 7.54978 11.5625 6.5254 13.75H5.16225C2.31091 13.75 0 15.9883 0 18.75C0 19.4414 0.57672 20 1.29056 20H14.1962C14.91 20 15.4867 19.4414 15.4867 18.75C15.4867 16.2109 13.5348 14.1172 11.0061 13.793C11.7965 11.2891 11.8369 8.96484 11.6554 7.20312C11.6191 6.86328 11.5788 6.54688 11.5304 6.25H16.7773C17.4347 6.25 18.1848 5.6875 17.959 4.85156C17.5113 3.18359 15.6642 1.25 12.2603 1.25C11.4941 1.25 10.8044 1.34766 10.1874 1.51953C9.22751 0.644531 7.7716 0 5.80753 0ZM10.2196 6.25C10.2761 6.57812 10.3285 6.9375 10.3689 7.32812C10.5463 9.04688 10.4979 11.3164 9.66711 13.75H7.93292C8.86051 11.6172 9.30414 9.60547 9.51386 8.05078C9.60662 7.34766 9.65501 6.73828 9.67518 6.25H10.2196ZM10.3245 15C12.462 15 14.1962 16.6797 14.1962 18.75H1.29056C1.29056 16.6797 3.02475 15 5.16225 15H10.3245Z"
    />
  </svg>
)

IslandOutline.displayName = 'IslandOutline'

export const IslandSolid = () => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="island-solid-icon">
    <path
      fill="currentColor"
      d="M4.38859 3.77311C4.34953 3.81217 4.29744 3.83301 4.24015 3.83301H3.44849C3.21932 3.83301 3.02922 3.64551 3.08911 3.42155C3.32869 2.49967 4.34953 1.33301 6.36515 1.33301C7.88599 1.33301 8.83911 1.99707 9.32088 2.71842C9.83911 2.3903 10.5318 2.16634 11.412 2.16634C13.4277 2.16634 14.4485 3.33301 14.6881 4.25488C14.7454 4.47884 14.5579 4.66634 14.3287 4.66634H13.1646C13.1099 4.66634 13.0553 4.64551 13.0162 4.60645L12.3912 3.98145C12.3105 3.90072 12.1777 3.90072 12.0969 3.98145L11.4719 4.60645C11.4329 4.64551 11.3808 4.66634 11.3235 4.66634H9.26099C9.99015 6.74707 9.81307 9.09863 9.5188 10.5544C11.0709 10.8408 12.2454 12.2002 12.2454 13.833C12.2454 14.2939 11.873 14.6663 11.412 14.6663H3.07869C2.61776 14.6663 2.24536 14.2939 2.24536 13.833C2.24536 11.9919 3.73755 10.4997 5.57869 10.4997H7.24536C8.0188 8.43457 8.01099 6.56999 7.83911 5.44759L5.55786 7.72884C5.3964 7.8903 5.12817 7.8903 5.01099 7.69238C4.52922 6.86947 4.42505 5.3252 5.85213 3.89811C5.88859 3.86165 5.92505 3.8278 5.9589 3.79395C5.95109 3.78613 5.94067 3.78092 5.93286 3.77311L5.30786 3.14811C5.22713 3.06738 5.09432 3.06738 5.01359 3.14811L4.38859 3.77311Z"
    />
  </svg>
)

IslandSolid.displayName = 'IslandSolid'

export const Graphic = {
  Canary,
  RedDot,
  GreenDot,
  IslandOutline,
  IslandSolid,
  Logo,
  LogoWithText,
  Paddle,
  Spinner
}
