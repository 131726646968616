// @ts-strict
import { useEffect } from 'react'
import { Button } from '@foundation/components'
import { DateInput, Form, FormActions, FormField, Popup, Section } from 'components'
import { LeadUncompletedTasks, UpdateStage } from 'content/Lead'
import formStyles from 'content/forms/Form.module.scss'
import { TCcTradeInLeadStagesSlug } from 'lookups'
import { CCTradeInLead } from 'models'
import {
  CCTradeInAgreementSigned,
  CCTradeInApplicationComplete,
  CCTradeInApproved,
  CCTradeInClientInEscrow,
  CCTradeInClosedPaid,
  CCTradeInClosedPurchase,
  CCTradeInClosedSell,
  CCTradeInFailed,
  CCTradeInInEscrowPurchase,
  CCTradeInInEscrowSell,
  CCTradeInListed,
  useCCTradeInLeadDispositionContext
} from '..'

type Props = {
  lead: CCTradeInLead
  onCancel: () => void
}

export const CCTradeInLeadDispositionForm = ({ lead, onCancel }: Props) => {
  const {
    commonPayload,
    currentStage,
    furthestStage,
    isLoading,
    dispositionPayload,
    setDispositionPayloadItem,
    isValid,
    newStage,
    onHandleSubmit,
    setCommonPayload,
    setCommonPayloadItem,
    setCommonRequiredFields,
    uncompletedTasks,
    showSubmitForm
  } = useCCTradeInLeadDispositionContext()

  useEffect(() => {
    setCommonRequiredFields(['occurredAt'])
  }, [setCommonRequiredFields])

  useEffect(() => {
    if (setCommonPayload && lead) {
      setCommonPayload({
        disposition: (currentStage?.nextStageOptions?.[0]?.value ||
          lead.stage) as TCcTradeInLeadStagesSlug,
        occurredAt: new Date().toISOString()
      })
    }
  }, [currentStage, setCommonPayload, lead])

  const stageFormFields = {
    agreement_signed: CCTradeInAgreementSigned,
    application_complete: CCTradeInApplicationComplete,
    approved: CCTradeInApproved,
    client_in_escrow: CCTradeInClientInEscrow,
    closed_paid: CCTradeInClosedPaid,
    closed_purchase: CCTradeInClosedPurchase,
    closed_sell: CCTradeInClosedSell,
    failed: CCTradeInFailed,
    in_escrow_purchase: CCTradeInInEscrowPurchase,
    in_escrow_sell: CCTradeInInEscrowSell,
    listed: CCTradeInListed
  }

  const CCTradeInLeadDispositionFields = stageFormFields[commonPayload.disposition!]

  return (
    <Popup title="Buy Before You Sell Disposition" onCancel={onCancel} className={formStyles.popup}>
      <Form onSubmit={() => onHandleSubmit(onCancel)} className={formStyles.form}>
        <UpdateStage
          payload={commonPayload}
          setPayloadItem={setCommonPayloadItem}
          currentStage={currentStage}
          furthestStage={furthestStage}
          newStage={newStage}
        />
        {showSubmitForm ? (
          <>
            {CCTradeInLeadDispositionFields && (
              <CCTradeInLeadDispositionFields
                payload={dispositionPayload}
                setPayload={setDispositionPayloadItem}
              />
            )}
            <Section>
              <FormField title="Occurred On" required>
                <DateInput
                  value={commonPayload.occurredAt}
                  onChange={val => setCommonPayloadItem('occurredAt', val)}
                />
              </FormField>
            </Section>
            <FormActions>
              <Button variant="ghost" color="neutralLight" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" isDisabled={!isValid} isLoading={isLoading}>
                Submit
              </Button>
            </FormActions>
          </>
        ) : (
          <Section>
            <LeadUncompletedTasks
              selectedStage={commonPayload.disposition!}
              items={uncompletedTasks}
            />
          </Section>
        )}
      </Form>
    </Popup>
  )
}
