import { AxiosResponse } from 'axios'
import { UserResponse } from 'schema'
import { getUsersAgentsUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export type PostAgentUserPayload = {
  email: string
  firstName: string
  lastName: string
}

export type PostUserResponse = AxiosResponse<{ data: UserResponse }>

export const postAgentUser = (agentId: string, payload: PostAgentUserPayload) =>
  postRequest(getUsersAgentsUrl({ id: agentId, action: 'users' }), snakeCaseKeys(payload))
