import { useState } from 'react'
import { useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react'
import { DRAWER_ELEMENT_ID } from '../floatingIds'

export const useDrawer = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeKey, setActiveKey] = useState<string | undefined>()
  const { context } = useFloating({
    open: isOpen,
    onOpenChange: open => {
      if (!open) {
        closeDrawer()
      }
    }
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, {
      outsidePress: (event: any) => {
        // do not close the drawer when opening the following elements with those ids
        return !event.target?.closest(`#${DRAWER_ELEMENT_ID}`)
      }
    })
  ])

  const openDrawer = (key: string) => {
    const isSameKeyActive = key === activeKey
    setIsOpen(isSameKeyActive ? false : true)
    setActiveKey(isSameKeyActive ? undefined : key)
  }

  const closeDrawer = () => {
    setIsOpen(state => !state)
    setActiveKey(undefined)
  }

  return {
    isOpen,
    openDrawer,
    closeDrawer,
    context,
    getReferenceProps,
    getFloatingProps,
    activeKey
  }
}
