export const Link = () => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5076 6.27984C15.9126 4.87484 15.9126 2.59734 14.5076 1.19234C13.1701 -0.145163 11.0226 -0.217663 9.59759 1.02984L9.44509 1.16484C9.19509 1.38234 9.17009 1.76234 9.38759 2.01234C9.60509 2.26234 9.98509 2.28734 10.2351 2.06984L10.3876 1.93484C11.3376 1.10484 12.7676 1.15234 13.6601 2.04484C14.5951 2.97984 14.5951 4.49734 13.6601 5.43484L10.8276 8.26484C9.89259 9.19984 8.37259 9.19984 7.43759 8.26484C6.54509 7.37234 6.49759 5.94234 7.32759 4.99234L7.44509 4.85734C7.66259 4.60734 7.63759 4.22984 7.38759 4.00984C7.13759 3.78984 6.76009 3.81734 6.54009 4.06734L6.42259 4.20234C5.17759 5.62734 5.25009 7.77484 6.58759 9.11234C7.99259 10.5173 10.2701 10.5173 11.6751 9.11234L14.5076 6.27984ZM1.49259 5.71984C0.0875928 7.12484 0.0875928 9.40234 1.49259 10.8048C2.83259 12.1448 4.98009 12.2148 6.40509 10.9673L6.55759 10.8323C6.80759 10.6148 6.83259 10.2348 6.61509 9.98484C6.39759 9.73484 6.01759 9.70984 5.76759 9.92734L5.61509 10.0623C4.66509 10.8923 3.23509 10.8448 2.34259 9.95234C1.40759 9.01734 1.40759 7.49984 2.34259 6.56234L5.17509 3.73484C6.11009 2.79984 7.62759 2.79984 8.56509 3.73484C9.45759 4.62734 9.50509 6.05734 8.67509 7.00734L8.54009 7.15984C8.32259 7.40984 8.34759 7.78734 8.59759 8.00734C8.84759 8.22734 9.22509 8.19984 9.44509 7.94984L9.58009 7.79734C10.8276 6.37234 10.7551 4.22484 9.41759 2.88484C8.01259 1.47984 5.73509 1.47984 4.33009 2.88484L1.49259 5.71984Z"
      fill="#3A3C40"
    />
  </svg>
)

export const Copy = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="copy">
        <path
          id="copy-solid"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5712 15.875V16.8906C12.5712 17.2272 12.2834 17.5 11.9283 17.5H4.64261C4.28757 17.5 3.99976 17.2272 3.99976 16.8906V7.54688C3.99976 7.21032 4.28757 6.9375 4.64261 6.9375H6.57118V14.4531C6.57118 15.2372 7.24407 15.875 8.07118 15.875H12.5712ZM12.5712 7.14062V4.5H8.07118C7.71614 4.5 7.42833 4.77282 7.42833 5.10938V14.4531C7.42833 14.7897 7.71614 15.0625 8.07118 15.0625H15.3569C15.7119 15.0625 15.9998 14.7897 15.9998 14.4531V7.75H13.214C12.8605 7.75 12.5712 7.47578 12.5712 7.14062ZM15.8115 6.35278L14.0452 4.67847C13.9246 4.5642 13.7611 4.5 13.5906 4.5H13.4283V6.9375H15.9998V6.78366C15.9998 6.62205 15.932 6.46706 15.8115 6.35278Z"
          fill="#273653"
        />
      </g>
    </svg>
  )
}
