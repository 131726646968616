import { useQuery } from 'hooks'
import { fetchBlendUsers } from 'services'
import { BLEND_USERS_CACHE_KEY } from '../cacheKeys'

type UseListBlendUsersByRole = {
  enabled?: boolean
  roles: string
}

export const useListBlendUsersByRole = ({ enabled = false, roles }: UseListBlendUsersByRole) => {
  const { data, isLoading } = useQuery([BLEND_USERS_CACHE_KEY], () => fetchBlendUsers(roles), {
    staleTime: 300000, // 5min
    refetchOnWindowFocus: false,
    enabled
  })

  return {
    data: data?.items,
    isLoading
  }
}
