// @ts-strict
import { useQuery } from 'hooks'
import { fetchMarketplacePrograms } from 'services/MarketplacePrograms'

export const useMarketplacePrograms = () => {
  const { data: marketplacePrograms, isLoading } = useQuery(
    'marketplacePrograms',
    fetchMarketplacePrograms,
    {
      staleTime: Infinity
    }
  )

  const eligibleMarketplacePrograms = marketplacePrograms
    ? marketplacePrograms.filter(program => program.eligibleForPitchStatus)
    : []

  const marketplaceProgramsOptions = eligibleMarketplacePrograms
    ? eligibleMarketplacePrograms.map(program => program.toOption())
    : []

  return {
    marketplacePrograms: eligibleMarketplacePrograms || [],
    marketplaceProgramsOptions,
    isLoading
  }
}
