// @ts-strict

import { FormField, InlineEditSelect, Section, Sections } from 'components'
import { agentMatchingCallReviewCreditScoreOptions, fhaVaOptions, yesOrNoOptions } from 'lookups'
import { BuyerLead, isBuyerLead, MortgageLead } from 'models'
import { dataOrDash, stringifyValue } from 'utils'

type Props = {
  lead: BuyerLead | MortgageLead
  requiredFields?: Record<string, boolean>
  updateCallReview: (key: string, value: string | null) => void
  updateField: (
    value: Record<
      string,
      string | number | Record<string, string> | Record<string, Record<string, string>>
    >
  ) => void
}

export const AgentMatchingLeadMortgageScreenSection = ({
  lead,
  updateCallReview,
  updateField,
  requiredFields = {}
}: Props) => {
  const isBuyerAndInterestedLoan = isBuyerLead(lead) && lead?.callReview?.interestedInLoan
  return (
    <Sections title="Mortgage Screen">
      <Section className="client-screen">
        <FormField title="Need Lender Intro?" required={requiredFields.needLender} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('needLender', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.needLender)}
          />
        </FormField>
        <FormField title="Credit Score" inline>
          <InlineEditSelect
            onChange={(val: string) => {
              updateCallReview('creditScore', val)
            }}
            options={agentMatchingCallReviewCreditScoreOptions}
            value={dataOrDash(lead?.callReview?.creditScore)}
            isError={isBuyerAndInterestedLoan && !lead?.callReview?.creditScore}
          />
        </FormField>
        <FormField title="Downpayment > 3%" inline>
          <InlineEditSelect
            onChange={(value: string) => updateField({ hasMinimumDownPayment: value })}
            options={yesOrNoOptions}
            value={stringifyValue(lead.hasMinimumDownPayment)}
            isError={
              isBuyerAndInterestedLoan &&
              (lead?.hasMinimumDownPayment === null || lead?.hasMinimumDownPayment === undefined)
            }
          />
        </FormField>
      </Section>
      <Section className="client-screen">
        <FormField title="Consistent Income?" required={requiredFields.consistenIncome} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('consistenIncome', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.consistentIncome)}
          />
        </FormField>
        <FormField title="FHA/VA Loan?" inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('fhaVaLoan', value || null)}
            options={fhaVaOptions}
            value={dataOrDash(lead?.callReview?.fhaVaLoan)}
            isError={
              isBuyerAndInterestedLoan &&
              (typeof lead?.callReview?.fhaVaLoan === 'string'
                ? !lead?.callReview?.fhaVaLoan
                : lead?.callReview?.fhaVaLoan === null || lead?.callReview?.fhaVaLoan === undefined)
            }
          />
        </FormField>
        <FormField title="Reverse Mortgage" required={requiredFields.reverseMortgage} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('reverseMortgage', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.reverseMortgage)}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
