// @ts-strict
import {
  ChangeReasonButtonFormDate,
  FormField,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  InlineEditDollarsInput,
  InlineEditIntegerInput,
  InlineEditSelect,
  Section,
  Sections
} from 'components'
import { useTradeInLeadController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import { useCcTradeInLeadFields, useChangeReasons, yesOrNoOptions } from 'lookups'
import { formatMoney, formatNumber } from 'utils'
import { CCTradeInLeadDetailsProps } from '../CCTradeInLeadDetails'
import { getLeadInputProps, getTradeInLeadInputProps } from './utils'

type Props = Pick<CCTradeInLeadDetailsProps, 'lead' | 'updateTradeInLead' | 'updateLead'>

export const DRSaleTransactionSection = ({ updateTradeInLead, updateLead, lead }: Props) => {
  const tradeInLead = lead.tradeInProviderLead?.tradeInLead
  const { getProps } = getTradeInLeadInputProps(tradeInLead, updateTradeInLead)
  const { getProps: getLeadProps } = getLeadInputProps(lead, updateLead)

  const { salesAppImprovement } = useFeatureFlags(['sales-app-improvement'])

  const { coeDateChangeReasonOptions } = useChangeReasons()
  const { updateTradeInLead: newUpdateTradeInLead } = useTradeInLeadController(tradeInLead?.id)
  const { getCcTradeInLeadFieldName } = useCcTradeInLeadFields()

  const actualTradeInFee = () => {
    const dpTargetTradeInFeeRate = tradeInLead?.dpTargetTradeInFeeRate
    const departingPropertyActualSalePrice = tradeInLead?.departingPropertyActualSalePrice

    if (dpTargetTradeInFeeRate === undefined || departingPropertyActualSalePrice === undefined) {
      return ''
    }

    const result = dpTargetTradeInFeeRate * departingPropertyActualSalePrice

    return String(Math.max(result, 9000))
  }

  if (!tradeInLead) {
    return null
  }

  return (
    <Sections title="DR Sale Transaction">
      <Section>
        <FormField
          title={getCcTradeInLeadFieldName('departingPropertyExpectedListingPrice')}
          inline
        >
          <InlineEditDollarsInput {...getProps('departingPropertyExpectedListingPrice')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('departingPropertyListingPrice')} inline>
          <InlineEditDollarsInput {...getProps('departingPropertyListingPrice')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('departingPropertyListedAt')} inline>
          <InlineEditDateInput {...getProps('departingPropertyListedAt')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('departingPropertyOfferCount')} inline>
          <InlineEditIntegerInput {...getProps('departingPropertyOfferCount')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('departingPropertyOfferAcceptedAt')} inline>
          <InlineEditDateInput {...getProps('departingPropertyOfferAcceptedAt')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('departingPropertyActualSalePrice')} inline>
          <InlineEditDollarsInput {...getProps('departingPropertyActualSalePrice')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('departingPropertyPriceDelta')} inline>
          {formatMoney(tradeInLead?.departingPropertyPriceDelta)}
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpSellerCredits')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpSellerCredits')} />
        </FormField>
      </Section>
      <Section>
        <FormField
          title={
            salesAppImprovement.enabled
              ? getCcTradeInLeadFieldName('dpTargetTradeInFee')
              : getCcTradeInLeadFieldName('calculatedValue')
          }
          inline
        >
          {salesAppImprovement.enabled && (
            <InlineEditDollarsAndCentsInput value={actualTradeInFee()} readOnly />
          )}
          {!salesAppImprovement.enabled && (
            <InlineEditDollarsAndCentsInput {...getLeadProps('calculatedValue')} />
          )}
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('departingPropertyOpenedSaleEscrowAt')} inline>
          <InlineEditDateInput {...getProps('departingPropertyOpenedSaleEscrowAt')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('dpTargetSellCoeDate')} inline>
          <InlineEditDateInput {...getProps('dpTargetSellCoeDate')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('departingPropertyCloseOfSaleEscrowAt')} inline>
          {!!tradeInLead.departingPropertyCloseOfSaleEscrowAt ? (
            <ChangeReasonButtonFormDate
              fieldName="departingPropertyCloseOfSaleEscrowAt"
              mutation={newUpdateTradeInLead}
              reasons={coeDateChangeReasonOptions}
              title={getCcTradeInLeadFieldName('departingPropertyCloseOfSaleEscrowAt')}
              value={tradeInLead.departingPropertyCloseOfSaleEscrowAt}
            />
          ) : (
            <InlineEditDateInput {...getProps('departingPropertyCloseOfSaleEscrowAt')} />
          )}
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('departingPropertyFundsWiredAmount')} inline>
          <InlineEditDollarsAndCentsInput {...getProps('departingPropertyFundsWiredAmount')} />
        </FormField>
        <FormField
          title={getCcTradeInLeadFieldName('departingPropertyFundsWiredToClientAt')}
          inline
        >
          <InlineEditDateInput {...getProps('departingPropertyFundsWiredToClientAt')} />
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('departingPropertyActualDom')} inline>
          {formatNumber(tradeInLead?.departingPropertyActualDom)}
        </FormField>
        <FormField title={getCcTradeInLeadFieldName('departingPropertyDaysOfOwnership')} inline>
          {formatNumber(tradeInLead?.departingPropertyDaysOfOwnership)}
        </FormField>
        {salesAppImprovement.enabled && (
          <FormField title="HLCS Attached" inline>
            <InlineEditSelect options={yesOrNoOptions} {...getProps('hlcsAttached')} />
          </FormField>
        )}
      </Section>
    </Sections>
  )
}
