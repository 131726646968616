import { removeAttributesWithNullValues } from 'utils'

export type TGetLeadsCountSettings = {
  aeId?: string
  agentAeId?: string
  asxScope?: boolean
  createdAt?: string
  group?: string
  lastStageUpdate?: string
  needsListingReview?: boolean
  similarOrder?: string
  stage?: string
  userType?: string
}

const getFilters = ({
  aeId,
  agentAeId,
  asxScope,
  createdAt,
  lastStageUpdate,
  needsListingReview,
  similarOrder,
  stage,
  userType
}: Omit<TGetLeadsCountSettings, 'group'>) => {
  const filters = {}

  if (asxScope) {
    filters['filter[_asx_scope]'] = asxScope
  }
  if (userType) {
    filters['filter[user_type]'] = userType
  }
  if (createdAt) {
    filters['filter[>created_at]'] = createdAt
  }
  if (stage) {
    filters['filter[stage]'] = stage
  }
  if (lastStageUpdate) {
    filters['filter[>last_stage_update]'] = lastStageUpdate
  }
  if (aeId) {
    filters['filter[_ae_id]'] = aeId
  }
  if (agentAeId) {
    filters['filter[agent_ae_id]'] = agentAeId
  }
  if (needsListingReview) {
    filters['filter[needs_listing_review]'] = needsListingReview
  }
  if (similarOrder) {
    filters['filter[_similar_order]'] = similarOrder
  }
  return filters
}

export const getSearchParams = ({ group, ...filters }: TGetLeadsCountSettings) =>
  removeAttributesWithNullValues({
    group,
    ...getFilters(filters)
  })
