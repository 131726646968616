// @ts-strict
import { AutoComplete, AutoCompleteProps } from 'components'
import { getUsers2Url } from 'services/getUrl'
import { getRequest } from 'utils'

type EmployeesAutoCompleteResponse = {
  attributes: { email: string; name: string }
  id: string
}
export type TEmployeesAutoCompleteOption = {
  id: string
  subTitle: string
  title: string
}

export const EmployeesAutoComplete = (props: AutoCompleteProps) => {
  const formatUser = (user: EmployeesAutoCompleteResponse): TEmployeesAutoCompleteOption => ({
    id: user.id,
    title: user.attributes.name,
    subTitle: user.attributes.email
  })

  const getUsers = async (searchTerm: IdType) => {
    if (!searchTerm || !searchTerm.toString().length) {
      return []
    }
    const searchParams = {
      'filter[_search]': searchTerm,
      'filter[_employee]': true,
      autocomplete: true,
      'page[size]': 10
    }
    const response = await getRequest(getUsers2Url({ searchParams }))
    return response.data.data.map(formatUser)
  }

  return <AutoComplete getData={getUsers} placeholder="Enter name..." {...props} />
}
