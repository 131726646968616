import { createContext, ReactNode, useContext } from 'react'
import { useAgentPayPerLeadReferralsController } from 'controllers'

const AgentPayPerLeadReferralsContext =
  createContext<ReturnType<typeof useAgentPayPerLeadReferralsController>>(null)

export const useAgentPayPerLeadReferralsContext = () => {
  const context = useContext(AgentPayPerLeadReferralsContext)
  if (!context) {
    throw new Error(
      'useAgentPayPerLeadReferralsContext must be called within an AgentPayPerLeadReferralsProvider'
    )
  }
  return context
}

type Props = {
  agentId?: string
  children: ReactNode
}

export const AgentPayPerLeadReferralsProvider = ({ children, agentId }: Props) => {
  return (
    <AgentPayPerLeadReferralsContext.Provider
      value={useAgentPayPerLeadReferralsController(agentId)}
    >
      {children}
    </AgentPayPerLeadReferralsContext.Provider>
  )
}
