import {
  BuyerLead,
  CCBBYSLead,
  CCCashOfferLead,
  CCHLSimpleSaleLead,
  CCTradeInLead,
  EscrowLead,
  Lead,
  MortgageLead,
  SellerLead
} from 'models'
import { LeadResponse } from 'schema'

export const getLead = (lead: LeadResponse) => {
  switch (lead.userType) {
    case 'buyer':
      return BuyerLead.create(lead)
    case 'seller':
      return SellerLead.create(lead)
    case 'cc_trade_in':
      return CCTradeInLead.create(lead)
    case 'cc_cash_offer':
      return CCCashOfferLead.create(lead)
    case 'cc_hl_simple_sale':
      return CCHLSimpleSaleLead.create(lead)
    case 'escrow':
      return EscrowLead.create(lead)
    case 'mortgage':
      return MortgageLead.create(lead)
    case 'bbys':
      return CCBBYSLead.create(lead)
    default:
      return Lead.create(lead)
  }
}

export const getCCLead = (lead?: LeadResponse) => {
  switch (lead?.userType) {
    case 'cc_trade_in':
      return CCTradeInLead.create(lead)
    case 'cc_cash_offer':
      return CCCashOfferLead.create(lead)
    case 'cc_hl_simple_sale':
      return CCHLSimpleSaleLead.create(lead)
    case 'bbys':
      return CCBBYSLead.create(lead)
    default:
      return undefined
  }
}
