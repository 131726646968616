// @ts-strict

export type TTaskStatusValue =
  | 'completed'
  | 'completedWithNote'
  | 'unscheduled'
  | 'pastDue'
  | 'dueToday'
  | 'upcoming'
  | 'ready'
  | 'active'
  | 'dismissed'
  | 'past_due'

export type TTaskStatusIcon = 'Calendar' | 'Check' | 'CheckWithNote' | 'Close'

export type TTaskStatusSetting = {
  icon?: TTaskStatusIcon
  name: string
  value: TTaskStatusValue
}

export const taskStatusSettings: TTaskStatusSetting[] = [
  {
    name: 'Complete',
    value: 'completed',
    icon: 'Check'
  },
  {
    name: 'Complete',
    value: 'completedWithNote',
    icon: 'CheckWithNote'
  },
  {
    name: 'Unscheduled',
    value: 'unscheduled',
    icon: 'Calendar'
  },
  {
    name: 'Past Due',
    value: 'pastDue',
    icon: 'Calendar'
  },
  {
    name: 'Scheduled',
    value: 'upcoming',
    icon: 'Calendar'
  },
  {
    name: 'Ready',
    value: 'ready',
    icon: 'Calendar'
  },
  {
    name: 'Open',
    value: 'active',
    icon: 'Calendar'
  },
  // front-end only
  {
    name: 'Due Today',
    value: 'dueToday',
    icon: 'Calendar'
  },
  {
    name: 'Dismissed',
    value: 'dismissed',
    icon: 'Close'
  },
  {
    name: 'Overdue',
    value: 'past_due',
    icon: 'Calendar'
  }
]
