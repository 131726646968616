// @ts-strict
import { PropsWithChildren, useState } from 'react'
import { Icon } from '@foundation/components'
import { IconPathKeys } from '@foundation/icons'
import { Button, Section, SectionProps } from 'components'

export type TConditionalSectionProps = PropsWithChildren<{
  buttonIcon?: IconPathKeys
  defaultShow?: boolean
  hideButtonText: string
  onHide?: () => void
  onShow?: () => void
  showButtonText: string
}> &
  SectionProps

export const ConditionalSection = ({
  buttonIcon = 'Add',
  showButtonText,
  hideButtonText,
  defaultShow = false,
  onShow,
  onHide,
  children,
  ...props
}: TConditionalSectionProps) => {
  const [showContent, setShowContent] = useState(defaultShow)

  const toggle = () => {
    if (showContent) {
      onHide && onHide()
    } else {
      onShow && onShow()
    }

    setShowContent(!showContent)
  }

  return (
    <Section
      {...props}
      actions={
        !showContent && (
          <Button
            as="link"
            onClick={toggle}
            icon={<Icon path={buttonIcon} size="md" />}
            iconPosition="start"
          >
            {showContent ? hideButtonText : showButtonText}
          </Button>
        )
      }
      footer={
        showContent && (
          <Button as="link" onClick={toggle}>
            {showContent ? hideButtonText : showButtonText}
          </Button>
        )
      }
    >
      {showContent && children}
    </Section>
  )
}
