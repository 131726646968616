// @ts-strict
import { User } from 'models'
import { GeneratedDocumentResponse } from 'schema'

export class GeneratedDocument {
  user?: User
  id?: string
  name?: string
  slug?: string
  url?: string
  params?: object
  driveFileId?: string
  createdAt?: string
  updatedAt?: string

  constructor(attributes: GeneratedDocument) {
    Object.assign(this, attributes)
  }

  static create(response: Potential<GeneratedDocumentResponse>): Potential<GeneratedDocument> {
    if (!response) {
      return
    }
    const user = User.create(response.user)
    return new GeneratedDocument({ ...response, user })
  }
}
