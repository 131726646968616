import { EscrowLead } from 'models'
import { getSubmitEscrowOrderUrl } from 'services/getUrl'
import { postRequest } from 'utils'
import { uploadFiles } from './uploadFiles'

type NewAgentParams = {
  agent_brokerage?: string
  agent_email?: string
  agent_full_name?: string
  agent_license_number?: string
  agent_phone?: string
  agent_state?: string
  agent_team_lead_name?: string
  notes_to_ae?: string
}

export type TEscrowOrderPostPayload = {
  data: {
    attributes: {
      buyer: boolean
      files: File[]
      lead_attributes: {
        address: string
        email: string
        first_name: string
        last_name: string
        name: string
        phone?: string
        referring_agent_id?: string
        sales_notes?: string
        source: string
        source_form: string
        source_page_type: string
      }
      refinance: boolean
      seller_coordination: boolean
      service_office_id: string
      started_as_pre_escrow: boolean
      transaction_coordinator_email: string
      transaction_coordinator_name: string
    } & NewAgentParams
    type: 'lead'
  }
  parent_lead_id?: string
}

export const postEscrowOrder = async (
  payload: TEscrowOrderPostPayload
): Promise<Potential<EscrowLead>> => {
  const documents = await uploadFiles(payload.data.attributes.files)

  const params = {
    ...payload,
    data: {
      ...payload.data,
      attributes: {
        ...payload.data.attributes,
        document_uuids: documents.map((doc: { id: string }) => doc.id),
        files: null
      }
    }
  }

  const {
    data: { data: lead }
  } = await postRequest(getSubmitEscrowOrderUrl({ id: params.parent_lead_id }), params)

  return EscrowLead.create(lead)
}
