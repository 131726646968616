// @ts-strict
import { useMutation, useQuery, useQueryClient } from 'hooks'
import { InvestorPayPerLeadSetting } from 'models'
import {
  fetchInvestorPayPerLeadSettings,
  patchInvestorPayPerLeadSettings,
  postInvestorPayPerLeadSettings,
  postSendSetupCheckoutSessionMagicLink,
  postSetupCheckoutSession
} from 'services'

type UpdateInvestorPayPerLeadSettingsPayload = {
  settings: InvestorPayPerLeadSetting[]
}

type CreateInvestorPayPerLeadSettingsPayload = {
  billingFreq: string
  monthlyCap: number
  settings: InvestorPayPerLeadSetting[]
}

type CreateSetupCheckoutSessionPayload = {
  addNewPaymentMethod?: boolean
  redirectUrl?: string
  subscriberId?: string
  subscriberType?: string
}

type CreateSetupCheckoutSessionMagicLinkPayload = {
  priceId?: string
  redirectUrl?: string
  subscriberId?: string
  subscriberType?: string
}

export const useInvestorPayPerLeadSettingsController = (investorId: string) => {
  const investorPayPerLeadSettingsQuery = useQuery(
    ['investor-billing-settings', investorId],
    () => fetchInvestorPayPerLeadSettings(investorId),
    { refetchOnWindowFocus: false }
  )

  const queryCache = useQueryClient()

  const updateInvestorPayPerLeadSettings = useMutation(
    (payload: UpdateInvestorPayPerLeadSettingsPayload) =>
      patchInvestorPayPerLeadSettings(investorId, payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('investor-billing-settings')
      }
    }
  )

  const createInvestorPayPerLeadSettings = useMutation(
    (payload: CreateInvestorPayPerLeadSettingsPayload) =>
      postInvestorPayPerLeadSettings(investorId, payload),
    {
      onSuccess: () => {}
    }
  )

  const createSetupCheckoutSession = useMutation((payload: CreateSetupCheckoutSessionPayload) =>
    postSetupCheckoutSession(payload)
  )

  const createSetupCheckoutSessionMagicLink = useMutation(
    (payload: CreateSetupCheckoutSessionMagicLinkPayload) =>
      postSendSetupCheckoutSessionMagicLink(payload)
  )

  const clearCaches = () => {
    queryCache.invalidateQueries('provider-billing-settings')
  }

  return {
    investorPayPerLeadSettingsQuery,
    updateInvestorPayPerLeadSettings,
    createInvestorPayPerLeadSettings,
    createSetupCheckoutSession,
    createSetupCheckoutSessionMagicLink,
    clearCaches
  }
}
