// @ts-strict
import { useState } from 'react'
import {
  Button,
  FilterBar,
  Icon,
  If,
  ListSelect,
  LoadingText,
  PaginationParams,
  SearchInputParams,
  Section
} from 'components'
import { AgentProviderLeadsStack } from 'content/AgentProviderLeads'
import { AgentsFilters } from 'content/Agents/AgentsFilters'
import { InvestorLeadsStack } from 'content/InvestorLeads'
import { useParamsContext } from 'contexts'
import { useAgentsController } from 'controllers'
import { useLeadController, useMap } from 'hooks'
import {
  AgentMatchingLead,
  AgentProviderLead,
  getActiveInvestorLeads,
  getSortedInvestorLeads
} from 'models'
import { agentDetailsShape } from 'services/Agent/agentShapes'
import styles from './LeadAgents.module.scss'
import { QualifyInvestorLeads } from './QualifyInvestorLeads'

type WhichAgentsValues = 'all' | 'active' | 'search'
type WhichInvestorValues = 'all' | 'active'
type TabValues = 'agents' | 'investors'

export const LeadAgents = ({ lead }: { lead: AgentMatchingLead }) => {
  // Agent Lead Filters
  const [whichAgents, setWhichAgents] = useState<WhichAgentsValues>('active')
  const [selectedTab, setSelectedTab] = useState<TabValues>('agents')
  const [filters, { set: setFilter }] = useMap<Record<string, any>>({
    gender: undefined,
    worksWith: undefined,
    homeLoans: undefined,
    cashClose: undefined,
    closingServices: undefined,
    latitude: undefined,
    longitude: undefined,
    radius: undefined,
    algoLeadId: lead.id.toString()
  })

  //Investor Lead Filters
  const [whichInvestors, setWhichInvestors] = useState<WhichInvestorValues>('active')

  const {
    params: { searchTerm, page }
  } = useParamsContext()

  const searchParams = {
    ...filters,
    perPage: '10',
    searchTerm,
    page,
    agentShape: agentDetailsShape
  }

  const { agents, pageCount, isLoading } = useAgentsController(
    { searchParams },
    whichAgents === 'search'
  )
  const { agentProviderLeadsQuery, investorLeadsQuery } = useLeadController(lead?.id)
  const agentsCount = agentProviderLeadsQuery.data?.length || 0
  const activeAgentsLeads = AgentProviderLead.getActive(agentProviderLeadsQuery.data)
  const sortedAgentsLeads = AgentProviderLead.getSorted(agentProviderLeadsQuery.data)
  const activeAgentsCount = activeAgentsLeads?.length || 0

  const investorsCount = investorLeadsQuery.data?.length || 0
  const sortedInvestorLeads = getSortedInvestorLeads(investorLeadsQuery.data)
  const activeInvestorLeads = getActiveInvestorLeads(investorLeadsQuery.data)
  const activeInvestorsCount = activeInvestorLeads?.length || 0

  const searchAgentProviderLeads = (agents?.map(AgentProviderLead.fromAgent) || []).map(sapl => {
    const existingApl = agentProviderLeadsQuery.data?.find(
      (apl: { agentLead: { agent: any } }) => sapl.agentLead?.agent!.id === apl.agentLead?.agent!.id
    )
    return existingApl || sapl
  })

  const searchButton = (
    <Button
      as="link"
      className={whichAgents === 'search' ? 'selected' : undefined}
      onClick={() => setWhichAgents('search')}
    >
      <Icon.Search />
      Find Agents
    </Button>
  )

  const handleSetFilter = (key: string, val: any) => {
    if (filters[key] === val) {
      setFilter(key, undefined)
    } else {
      setFilter(key, val)
    }
  }

  return (
    <>
      <Section>
        <ListSelect
          className={styles.tabs}
          onChange={setSelectedTab}
          value={selectedTab}
          options={[
            {
              name: `Agents (${activeAgentsCount} Intros, ${agentsCount} Matches)`,
              value: 'agents'
            },
            {
              name: `Investors (${activeInvestorsCount} Intros, ${investorsCount} Matches)`,
              value: 'investors'
            }
          ]}
        />

        <If test={selectedTab === 'investors'}>
          <Section className={styles.agentsSection}>
            <FilterBar actions={<QualifyInvestorLeads lead={lead} />}>
              <ListSelect
                onChange={setWhichInvestors}
                value={whichInvestors}
                options={[
                  {
                    name: `Active (${activeInvestorsCount})`,
                    value: 'active'
                  },
                  {
                    name: `All (${investorsCount})`,
                    value: 'all'
                  }
                ]}
              />
            </FilterBar>
            <If test={whichInvestors === 'all'}>
              <InvestorLeadsStack investorLeads={sortedInvestorLeads} />
            </If>
            <If test={whichInvestors === 'active'}>
              <InvestorLeadsStack investorLeads={activeInvestorLeads} />
            </If>
          </Section>
        </If>

        <If test={selectedTab === 'agents'}>
          <Section className={styles.agentsSection}>
            <FilterBar actions={searchButton}>
              <ListSelect
                onChange={setWhichAgents}
                value={whichAgents}
                options={[
                  {
                    name: `Active (${activeAgentsCount})`,
                    value: 'active'
                  },
                  { name: `All (${agentsCount})`, value: 'all' }
                ]}
              />
            </FilterBar>
            <If test={whichAgents === 'search'}>
              <SearchInputParams placeholder="Search by Agent Name, Phone, License # or Office Name" />
              <AgentsFilters filters={filters} lead={lead} onChange={handleSetFilter} />
              {isLoading ? (
                <LoadingText text="Loading Agents" />
              ) : (
                <>
                  <AgentProviderLeadsStack agentProviderLeads={searchAgentProviderLeads} />
                  <PaginationParams totalPages={pageCount} />
                </>
              )}
            </If>
            <If test={whichAgents === 'all'}>
              {agentProviderLeadsQuery.isLoading ? (
                <LoadingText text="Loading Agents" />
              ) : (
                <AgentProviderLeadsStack agentProviderLeads={sortedAgentsLeads} />
              )}
            </If>
            <If test={whichAgents === 'active'}>
              {agentProviderLeadsQuery.isLoading ? (
                <LoadingText text="Loading Agents" />
              ) : (
                <AgentProviderLeadsStack agentProviderLeads={activeAgentsLeads} />
              )}
            </If>
          </Section>
        </If>
      </Section>
    </>
  )
}
