import { Section, Sections } from 'components'
import { useFields } from '..'
import { useCCTradeInLeadDispositionContext } from '../CCTradeInLeadDispositionContext'
import { FieldFormField } from '../FieldFormField'

export const CCTradeInClosedPurchase = () => {
  const { changes, tradeInLead } = useCCTradeInLeadDispositionContext()
  const requireChangeReason =
    tradeInLead.departingPropertyCloseOfPurchaseEscrowAt &&
    changes.departingPropertyCloseOfPurchaseEscrowAt

  useFields({
    fields: requireChangeReason
      ? ['changeReasons.departingPropertyCloseOfPurchaseEscrowAt.note']
      : [],
    required: [
      'dpActualNewHomePurchaseEquityHl',
      'departingPropertyCloseOfPurchaseEscrowAt',
      'dpActualPurchaseFundedDate',
      requireChangeReason
        ? 'changeReasons.departingPropertyCloseOfPurchaseEscrowAt.reason'
        : undefined
    ]
  })

  return (
    <>
      <Sections title="DR Purchase Transaction">
        <Section>
          <FieldFormField field="dpActualNewHomePurchaseEquityHl" />
          <FieldFormField field="departingPropertyCloseOfPurchaseEscrowAt" />
          <FieldFormField field="dpActualPurchaseFundedDate" />
          {requireChangeReason && (
            <>
              <FieldFormField field="changeReasons.departingPropertyCloseOfPurchaseEscrowAt.reason" />
              <FieldFormField field="changeReasons.departingPropertyCloseOfPurchaseEscrowAt.note" />
            </>
          )}
        </Section>
      </Sections>
    </>
  )
}
