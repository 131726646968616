import { styled } from '@foundation/themes/styles'
import { FormField, RadioButtonField, Section } from 'components'
import radioButtonsStyle from 'components/Form/RadioButton/RadioButton.module.scss'
import { cx } from 'utils'
import { BlindIntroFormPayload } from '../../types'

const StyledSection = styled(Section)`
  margin-top: 2rem;
`
type BlindIntroDispositionFieldsProps<T = BlindIntroFormPayload> = {
  onChangeAttribute: (key: keyof T, value: any) => void
  payload: T
}

export const qualificationOptions = [
  { key: 'qualificationPreviousContract', name: 'Listed or coming soon' },
  { key: 'qualificationIncorrectAddress', name: 'Unverifiable address' },
  { key: 'qualificationNotAProperHouse', name: 'Land, commercial or mobile home' },
  { key: 'qualificationVerifiedPhone', name: 'Name on voicemail matches lead' },
  { key: 'qualificationIncompleteName', name: 'Incomplete or fake client name' },
  { key: 'qualificationIsAnAgent', name: 'Potential real estate agent' },
  {
    key: 'qualificationIncorrectVoicemail',
    name: 'Voicemail is for a different person, a business or a different language'
  }
]

export const BLIND_INTRO_KEYS = qualificationOptions.map(option => option.key)

export const BlindIntroDispositionFields = ({
  onChangeAttribute,
  payload
}: BlindIntroDispositionFieldsProps) => {
  return (
    <StyledSection title="Blind Intro Qualification" data-testid="introducedFields">
      {qualificationOptions.map(option => (
        <div key={option.key}>
          <FormField title={option.name} required={true} inline>
            <div className={radioButtonsStyle.radioButtonRowParent}>
              <RadioButtonField
                title="Yes"
                label="Yes"
                name={option.key}
                className={cx(radioButtonsStyle.radioButtonRow)}
                checked={payload[option.key] === 'true'}
                onChange={() => {
                  onChangeAttribute(option.key as keyof BlindIntroFormPayload, 'true')
                }}
              />
              <RadioButtonField
                title="No"
                label="No"
                name={option.key}
                className={radioButtonsStyle.radioButtonRow}
                checked={payload[option.key] === 'false'}
                onChange={() => {
                  onChangeAttribute(option.key as keyof BlindIntroFormPayload, 'false')
                }}
              />
            </div>
          </FormField>
        </div>
      ))}
    </StyledSection>
  )
}
