import { Fragment } from 'react'
import { Contents, List, Section, Sections } from 'components'
import { useCurrentUserContext } from 'contexts'
import { useDocumentTitle, useUserSettings } from 'hooks'
import { formatBoolean } from 'utils'

export const SettingsDetailsTab = () => {
  useDocumentTitle('Settings')

  const { settings } = useUserSettings()
  const { currentUser, currentUserQueues } = useCurrentUserContext()

  return (
    <Contents>
      <Sections>
        <Section>
          {currentUser?.firstName}
          {currentUser?.lastName}
        </Section>
        <Section>
          <List>
            <>Handmatch: {currentUserQueues?.handmatch}</>
            <>House Canary: {currentUserQueues?.houseCanary}</>
          </List>
        </Section>
      </Sections>
      <Sections>
        <Section title="Preferences">
          <List>
            {Object.entries(settings).map(([key, val]) => (
              <Fragment key={key}>
                {key}: {formatBoolean(val)}
              </Fragment>
            ))}
          </List>
        </Section>
      </Sections>
    </Contents>
  )
}
