// @ts-strict
import { ExternalLink, FormField, FormFieldSet, If, Section, Sections } from 'components'
import { AgentSection } from 'content/Agent/AgentSection'
import { CCCashOfferLeadDetailsProps } from 'content/Lead'
import { useFailedReasons } from 'lookups'
import { MortgageLead } from 'models'
import { d, dataOrDash, formatDate, formatPrice, titleize } from 'utils'

type TProps = { lead: MortgageLead } & Pick<CCCashOfferLeadDetailsProps, 'updateLead'>

export const MortgageLeadDetailsSection = ({ lead, updateLead }: TProps) => {
  const { mortgageFailedReasonOptions: failedReasons } = useFailedReasons()

  if (!lead) {
    return null
  }

  const referringAgent = lead.referringAgent

  const failedReason = failedReasons.find(failedReason => {
    return failedReason.value === lead?.reasonForFail
  })

  return (
    <Sections title={`${d(lead.userType)} (${lead.stageAndFurthestStage})`}>
      <Section>
        <FormFieldSet>
          <FormField title="Created At" inline>
            {formatDate(lead.createdAt)}
          </FormField>
          <FormField title="Team Members" inline>
            {lead.leadUsers?.map(leadUser => leadUser.prettyName).join(', ')}
          </FormField>
          <FormField title="Source" inline>
            {`${titleize(lead.source)} (${titleize(lead.sourcePageType)})`}
          </FormField>
          <FormField title="Price" inline>
            {formatPrice(lead.price)}
          </FormField>
          {lead.isFailed && (
            <FormField title="Failed Reason" inline>
              {dataOrDash(failedReason?.name)}
            </FormField>
          )}
        </FormFieldSet>
      </Section>

      <Section>
        <AgentSection
          agent={referringAgent!}
          program="home_loans"
          onBlur={val =>
            updateLead({
              referringOwnerId: val,
              referringOwnerType: 'Agent'
            })
          }
        />
        <If test={lead.lowerApplicationLink}>
          <FormField title="Qualification Form Link">
            <ExternalLink href={lead.lowerApplicationLink}>Lower.com</ExternalLink>
          </FormField>
        </If>
      </Section>
    </Sections>
  )
}
