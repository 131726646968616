/* eslint-disable typescript-sort-keys/interface */

import { FiltersObj } from '@foundation/components/Table'

export type RequestOptions = {
  sortBy?: string
  filters?: FiltersObj
  search?: string
  page?: string
  pageSize?: string
  fields?: string[]
  includes?: string[]
}

export function buildURL(endpoint: string, options?: RequestOptions): string {
  const baseURL = process.env.REACT_APP_API_ROOT

  let url = endpoint.startsWith('http') ? endpoint : `${baseURL}${endpoint}?`

  if (options) {
    const { sortBy, filters, search, page, pageSize, fields, includes } = options

    if (sortBy) {
      url += `sort=${sortBy}&`
    }

    if (filters) {
      const filtersUrl = Object.entries(filters).reduce((acc, [key, values]) => {
        if (values.length) {
          const first = acc ? acc + '&' : acc
          return `${first}filter[${key}]=${values.map(v => v.value).join(',')}`
        }
        return acc
      }, '')

      url += filtersUrl.length > 0 ? `${filtersUrl}&` : ''
    }

    if (search) {
      url += `filter[_search]=${search}&`
    }

    if (page) {
      url += `page[number]=${page}&`
    }

    if (pageSize) {
      url += `page[size]=${pageSize}&`
    }

    if (fields) {
      url += `fields=${fields.join(',')}&`
    }

    if (includes) {
      url += `include=${includes.join(',')}&`
    }
  }

  return url.replace(/&$/, '')
}
