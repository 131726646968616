import { createGlobalStyle, css } from './'

const GlobalStyle = createGlobalStyle`
${({ theme }) => css`
  * {
    margin: 0;
  }

  html {
    font-size: 62.5%;
    box-sizing: border-box;
  }

  body {
    font-size: ${theme.fontSizes.base};
    color: ${theme.colors.primary10};
    background-color: ${theme.colors.white};
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  body,
  button,
  input {
    font-family: 'Open Sans', 'notoSans', 'San Francisco', '-apple-system', 'HelveticaNeue',
      helvetica, roboto, droid serif, arial, sans-serif;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`}
`

export default GlobalStyle
