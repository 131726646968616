import { useEffect } from 'react'
import { TasksList } from 'content/Tasks/TasksList'
import { ListProvider, TasksProvider, useParamsContext } from 'contexts'
import { useAgentContext } from 'contexts/Agent'
import { useDocumentTitle } from 'hooks'
import { agentTaskListColumns } from 'lookups'

export const AgentTasksTab = () => {
  const { agent } = useAgentContext()
  const { setTitle } = useDocumentTitle()
  const { params } = useParamsContext()
  useEffect(() => setTitle(`${agent.fullName} - Agent Tasks`), [agent, setTitle])

  return (
    <TasksProvider params={params} attachable={agent}>
      <ListProvider columns={agentTaskListColumns}>
        <TasksList />
      </ListProvider>
    </TasksProvider>
  )
}
