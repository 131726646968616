// @ts-strict

import { useMutation, useQuery, useQueryClient } from 'hooks'
import { LeadGroupableType } from 'models'
import { fetchLeadGroupable, postLeadGroupableCreateDefinition, putLeadGroupable } from 'services'

export const useLeadGroupableController = (
  leadGroupableType: LeadGroupableType,
  leadGroupableId: string
) => {
  const queryCache = useQueryClient()
  const cacheKey = `lead-groupable-${leadGroupableType}-${leadGroupableId}`

  const defaultCallback = () => {
    queryCache.invalidateQueries([cacheKey])
  }

  const {
    data: leadGroupable,
    isLoading,
    isError
  } = useQuery([cacheKey], () => fetchLeadGroupable(leadGroupableType, leadGroupableId))

  const createLeadGroupableDefinition = useMutation(
    () => postLeadGroupableCreateDefinition(leadGroupableType, leadGroupableId),
    {
      onSuccess: () => defaultCallback()
    }
  )

  const updateLeadGroupable = useMutation(
    (payload: any) => putLeadGroupable(leadGroupableType, leadGroupableId, payload),
    {
      onSuccess: () => defaultCallback()
    }
  )

  return {
    leadGroupable,
    createLeadGroupableDefinition,
    updateLeadGroupable,
    isLoading,
    isError
  }
}
