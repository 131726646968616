// @ts-strict
import { useState } from 'react'
import { useToastContext } from '@foundation/components'
import { useInvestorPayPerLeadSettingsController } from 'controllers'
import { InvestorPayPerLeadSetting } from 'models'
import type { Investor } from 'models'
import { SettingsModal } from '../PayPerLeadSettings'
import { LocationSettingsForm } from './LocationSettingsForm'

type Props = {
  investor: Investor
  locationSettings: InvestorPayPerLeadSetting[]
}

export const LocationSettingsUpdateButtonForm = ({ investor, locationSettings }: Props) => {
  const { updateInvestorPayPerLeadSettings } = useInvestorPayPerLeadSettingsController(investor.id)
  const toast = useToastContext()
  const [visible, setVisible] = useState(false)

  if (!visible) {
    return <ins onClick={() => setVisible(true)}>Edit</ins>
  }

  return (
    <SettingsModal title="Add Pay-Per-Lead ZIP" onCancel={() => setVisible(false)}>
      <LocationSettingsForm
        settings={locationSettings}
        onSubmit={(locationSettings: InvestorPayPerLeadSetting[]) => {
          // Here we'll store location settings and go to the next step
          updateInvestorPayPerLeadSettings.mutate(
            { settings: locationSettings },
            {
              onSuccess: () => {
                toast({
                  title: 'Location settings updated',
                  status: 'success'
                })
              },
              onError: message => {
                toast({
                  title: 'Failed to update location settings',
                  status: 'error'
                })
              }
            }
          )
          setVisible(false)
        }}
        onCancel={() => setVisible(false)}
      />
    </SettingsModal>
  )
}
