import { ButtonForm, FormField, PhoneInput, TextInput, useButtonForm } from 'components'
import { useBBYSLeadController } from 'hooks'
import { BBYSLead } from 'models'
import { validatePresence } from 'utils'

type Props = {
  bbysLead?: BBYSLead
}

export const AddLoanOfficerContact = ({ bbysLead }: Props) => {
  const { updateBBYSLead } = useBBYSLeadController(bbysLead.id)

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm({
    mutation: updateBBYSLead,
    initialPayload: {
      loanOfficerAdditionalContactName: '',
      loanOfficerAdditionalContactEmail: '',
      loanOfficerAdditionalContactPhoneNumber: ''
    },
    formValidations: {
      loanOfficerAdditionalContactName: [validatePresence],
      loanOfficerAdditionalContactEmail: [validatePresence]
    }
  })

  return (
    <ButtonForm title="Add Contact" {...formProps}>
      <FormField title="Name" required>
        <TextInput
          onChange={val => onChangeAttribute('loanOfficerAdditionalContactName', val)}
          data-testid="name"
          value={payload.loanOfficerAdditionalContactName}
        />
      </FormField>
      <FormField title="Email Address" required>
        <TextInput
          onChange={val => onChangeAttribute('loanOfficerAdditionalContactEmail', val)}
          data-testid="email"
          value={payload.loanOfficerAdditionalContactEmail}
        />
      </FormField>
      <FormField title="Phone Number">
        <PhoneInput
          onChange={val => onChangeAttribute('loanOfficerAdditionalContactPhoneNumber', val)}
          data-testid="phoneMobile"
          value={payload.loanOfficerAdditionalContactPhoneNumber}
        />
      </FormField>
    </ButtonForm>
  )
}
