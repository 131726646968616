import { ComponentType, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { TypeaheadSelect } from 'components/Form/TypeaheadSelect/TypeaheadSelect'
import { TTypeaheadSelectProps } from 'components/Form/TypeaheadSelect/TypeaheadSelect'
import { useUpdateEffect } from 'hooks'
import { DisplayValue } from '../DisplayValue'

export type InlineEditTypeaheadSelectProps = Omit<TTypeaheadSelectProps, 'options' | 'onBlur'> & {
  format?: (val: string) => string
  isError?: boolean
  onBlur: (val: string) => void
  onSelect?: (val: string) => void
  options?: []
  render?: ComponentType<TTypeaheadSelectProps>
}

export const InlineEditTypeaheadSelect = ({
  render: Component,
  value,
  options = [],
  onChange,
  onBlur,
  onSelect,
  isError,
  format = val => val,
  ...props
}: InlineEditTypeaheadSelectProps) => {
  const [currentValue, setCurrentValue] = useState(value ?? '')
  const [isEditMode, setIsEditMode] = useState(false)
  const inputRef = useRef()
  useClickAway(inputRef, () => setIsEditMode(false))

  useUpdateEffect(() => {
    setCurrentValue(value)
  }, [value])

  useUpdateEffect(() => {
    const handleOnBlur = () => {
      if (!isEditMode) {
        if (onBlur) {
          onBlur(currentValue)
        }
      }
    }

    handleOnBlur()
  }, [isEditMode])

  const handleOnChange = (changedValue: string) => {
    setCurrentValue(changedValue)

    if (onChange) {
      onChange(changedValue)
    }
  }

  const formatter = (changedValue: string) => {
    return format(changedValue)
  }

  const handleOnSelect = (val: string) => {
    setCurrentValue(val)
    setIsEditMode(false)

    if (onSelect) {
      onSelect(val)
    }
  }

  if (isEditMode) {
    const RenderComponent = Component || TypeaheadSelect

    return (
      <RenderComponent
        {...props}
        ref={inputRef}
        onSelect={handleOnSelect}
        onChange={handleOnChange}
        value={currentValue}
        options={options}
      />
    )
  }

  return (
    <DisplayValue
      isError={isError}
      format={formatter}
      setEditMode={setIsEditMode}
      value={String(currentValue)}
    />
  )
}
