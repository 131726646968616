// @ts-strict

import { useCallback, useState } from 'react'
import { useWarmTransferController, WARM_TRANSFER_STEP } from 'controllers'
import { useHoverHandler } from 'hooks'
import { UseLeadWarmTransferProps } from './types'

const TIMEOUT_TO_OPEN_AGENTS_AVAILABLE = 250

export const useLeadWarmTransfer = ({ leadId, hasStarted = false }: UseLeadWarmTransferProps) => {
  const {
    startConnection,
    step,
    agentsAvailable,
    connectAgentWithWarmTransfer,
    cancelWarmTransfer,
    totalElapsedTime
  } = useWarmTransferController({ leadId, enabled: hasStarted })

  const canShowCancelButton =
    step === WARM_TRANSFER_STEP.pending || step === WARM_TRANSFER_STEP.started

  const isFinishedState =
    step === WARM_TRANSFER_STEP.completed || step === WARM_TRANSFER_STEP.canceled

  const [canShowAgents, setCanShowAgents] = useState<boolean>(false)
  const [canShowAmountOfAgentsAvailable, setCanShowAmountOfAgentsAvailable] =
    useState<boolean>(false)
  const { onMouseEnter: openAgentsAvailable } = useHoverHandler({
    onEnter: () => {
      if (step === WARM_TRANSFER_STEP.unsubscribed) {
        setCanShowAmountOfAgentsAvailable(true)
      }
    },
    onLeave: () => {},
    enterDelay: TIMEOUT_TO_OPEN_AGENTS_AVAILABLE
  })

  const closeAgentsList = () => {
    setCanShowAgents(false)
  }

  const closeAgentsAvailable = () => {
    setCanShowAmountOfAgentsAvailable(false)
  }

  const handleClick = useCallback(() => {
    if (step === WARM_TRANSFER_STEP.unsubscribed) {
      startConnection()
      setCanShowAmountOfAgentsAvailable(false)
    }

    if (
      agentsAvailable.length &&
      (step === WARM_TRANSFER_STEP.pending || step === WARM_TRANSFER_STEP.active)
    ) {
      setCanShowAgents(true)
    }
  }, [agentsAvailable, startConnection, step])

  const cancel = () => {
    cancelWarmTransfer()
    setCanShowAgents(false)
  }

  return {
    cancel,
    canShowCancelButton,
    canShowAgents,
    canShowAmountOfAgentsAvailable,
    isFinishedState,
    openAgentsAvailable,
    closeAgentsList,
    closeAgentsAvailable,
    handleClick,
    connectAgentWithWarmTransfer,
    step,
    agentsAvailable,
    totalElapsedTime
  }
}
