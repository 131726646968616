// @ts-strict
import { CONSTANTS_CACHE_KEY, US_STATES_CACHE_KEY, useQuery } from 'hooks'
import { fetchUsStates } from 'services'
import { getOptions } from '../utils'

export const useUsStates = () => {
  const { data: usStates = [] } = useQuery(
    [CONSTANTS_CACHE_KEY, US_STATES_CACHE_KEY],
    fetchUsStates,
    {
      staleTime: Infinity
    }
  )
  const { getOptionName: getUsStateName, options: usStateOptions } = getOptions(usStates)

  return {
    usStates,
    usStateOptions,
    getUsStateName
  }
}
