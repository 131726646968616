import { Block, List, Section } from 'components'
import { useCurrentUserContext } from 'contexts'
import styles from './SettingsPage.module.scss'

export const SettingsAside = () => {
  const { currentUser } = useCurrentUserContext()

  return (
    <>
      <Block title={currentUser.name} />

      <Section title="Roles" className={styles.roles}>
        <List>{Object.keys(currentUser.roles)}</List>
      </Section>
    </>
  )
}
