import { createContext, ReactNode, useContext } from 'react'
import { Marker, Point } from 'classes'
import { useMapBox } from 'hooks'

export const MapBoxContext = createContext<ReturnType<typeof useMapBox>>({
  markers: undefined,
  center: undefined
})
export const useMapBoxContext = () => useContext(MapBoxContext)

type Props = {
  center?: Point
  children: ReactNode
  markers?: Marker[]
}

export const MapProvider = ({ markers, center, children }: Props) => {
  return (
    <MapBoxContext.Provider value={useMapBox({ markers: markers, center })}>
      {children}
    </MapBoxContext.Provider>
  )
}
