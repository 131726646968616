// @ts-strict

import { styled } from '@foundation/themes/styles'
import { FormField as FormFieldComponent } from 'components'

type FormFieldStyleProps = {
  inline?: boolean
  required?: boolean
  title: string
}

export const FormField = styled(FormFieldComponent)<FormFieldStyleProps>`
  & button {
    white-space: pre-line;
  }

  & > div > div > span > .formFieldDisplayValue {
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 0;
  }

  .formFieldDisplayValue > p {
    margin: 0;
  }
`
