// @ts-strict
import { InputHTMLAttributes } from 'react'
import { cx } from 'utils'
import styles from './RadioButton.module.scss'

type TProps = { label?: string }

export const RadioButton = ({
  checked = false,
  className = '',
  disabled = false,
  id,
  label,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & TProps) => (
  <div
    className={cx(checked && styles.checked, disabled && styles.disabled, className)}
    data-testid="radio"
  >
    <input
      type="radio"
      className={styles.input}
      data-testid="radio_input"
      checked={checked}
      id={id}
      disabled={disabled}
      {...props}
    />
  </div>
)
