import { TModelFieldSetting } from '../ModelField'

export type TMortgageLeadFieldAttribute =
  | 'actualLoanAmount'
  | 'actualPurchasePrice'
  | 'appraisalExpectedDate'
  | 'appraisalReceivedDate'
  | 'archived'
  | 'calculatedValue'
  | 'coeDateChangeReason'
  | 'closeOfEscrowOn'
  | 'comformingLoanLimit'
  | 'downpaymentAmount'
  | 'dtiAtMax'
  | 'dtiRatios'
  | 'eaveLoanApplicationId'
  | 'incomingPropertyFundsWiredToSellerAt'
  | 'interestRate'
  | 'lmTaskStage'
  | 'loanAmount'
  | 'loanDecision'
  | 'loanManagerName'
  | 'loanMilestone'
  | 'loanStatus'
  | 'ltv'
  | 'ltvAtMaxPurchasePriceAndLoanAmount'
  | 'maxDownPayment'
  | 'maxLoanAmount'
  | 'maxPurchasePrice'
  | 'pinnedNote'
  | 'product'
  | 'program'
  | 'propertyCounty'
  | 'targetCoeDate'
  | 'term'
  | 'loanApplicationLink'
  | 'updatedAt'

export type TMortgageLeadFieldSetting = {
  attr: TMortgageLeadFieldAttribute
} & TModelFieldSetting

export const mortgageLeadFieldsSettings: TMortgageLeadFieldSetting[] = [
  {
    attr: 'actualLoanAmount',
    name: 'Actual Loan Amount'
  },
  {
    attr: 'actualPurchasePrice',
    name: 'Actual Purchase Price'
  },
  {
    attr: 'appraisalExpectedDate',
    name: 'Appraisal Expected Date'
  },
  {
    attr: 'appraisalReceivedDate',
    name: 'Appraisal Received Date'
  },
  { attr: 'archived', name: 'Archived?' },
  { attr: 'calculatedValue', name: 'Input Purchase Price' },
  {
    attr: 'coeDateChangeReason',
    name: 'COE Date Change Reason'
  },
  {
    attr: 'closeOfEscrowOn',
    name: 'COE Date'
  },
  { attr: 'comformingLoanLimit', name: 'Conforming Loan Limit' },
  { attr: 'downpaymentAmount', name: 'Input Downpayment' },
  { attr: 'dtiAtMax', name: 'DTI at Max' },
  { attr: 'dtiRatios', name: 'DTI Ratios' },
  { attr: 'eaveLoanApplicationId', name: 'Loan Application #' },
  {
    attr: 'incomingPropertyFundsWiredToSellerAt',
    name: 'Loan Application #'
  },
  { attr: 'interestRate', name: 'Interest Rate', inputType: 'percent' },
  {
    attr: 'lmTaskStage',
    name: 'LM Task Stage'
  },
  { attr: 'loanAmount', name: 'Input Loan Amount' },
  { attr: 'loanDecision', name: 'Loan Decision' },
  { attr: 'loanManagerName', name: 'Loan Manager' },
  {
    attr: 'loanMilestone',
    name: 'Loan Milestone'
  },
  {
    attr: 'loanStatus',
    name: 'Loan Status'
  },
  { attr: 'ltv', name: 'LTV', inputType: 'percent' },
  { attr: 'ltvAtMaxPurchasePriceAndLoanAmount', name: 'LTV at Max Purchase Price & Loan Amount' },
  { attr: 'maxDownPayment', name: 'Max Downpayment' },
  { attr: 'maxLoanAmount', name: 'Max Loan Amount' },
  { attr: 'maxPurchasePrice', name: 'Max Purchase Price' },
  {
    attr: 'pinnedNote',
    name: 'Pinned Note'
  },
  { attr: 'product', name: 'Product' },
  { attr: 'program', name: 'Loan Program' },
  { attr: 'propertyCounty', name: 'Property County' },
  {
    attr: 'targetCoeDate',
    name: 'Target COE Date'
  },
  { attr: 'term', name: 'Term' },
  {
    attr: 'loanApplicationLink',
    name: 'Loan Link'
  },
  {
    attr: 'updatedAt',
    name: 'Updated'
  }
]
