// @ts-strict

import { FormField, If, InlineEditSelect, Section, Sections } from 'components'
import { agentMatchingLeadTimelineOptions, yesOrNoOptions } from 'lookups'
import { isMortgageLead, MortgageLead, SellerLead } from 'models'
import { stringifyValue } from 'utils'

type Props = {
  lead: MortgageLead | SellerLead
  requiredFields?: Record<string, boolean>
  updateCallReview: (key: string, value: string) => void
  updateField: (value: Record<string, string | number | Record<string, string>>) => void
}

export const AgentMatchingLeadLenderScreenSection = ({
  lead,
  updateCallReview,
  updateField,
  requiredFields = {}
}: Props) => {
  return (
    <Sections title="Lender Screen">
      <Section>
        <If test={isMortgageLead(lead)}>
          <FormField title="Timeline" inline>
            <InlineEditSelect
              onChange={(val: string) => {
                updateField({ timeline: val })
              }}
              options={agentMatchingLeadTimelineOptions}
              value={
                agentMatchingLeadTimelineOptions?.find(opt => opt.name === lead?.humanTimeline)
                  ?.value
              }
            />
          </FormField>
        </If>
      </Section>
      <Section>
        <FormField title="Need Lender?" required={requiredFields.needLender} inline>
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('needLender', value)}
            options={yesOrNoOptions}
            value={stringifyValue(lead.callReview?.needLender)}
          />
        </FormField>
        <If test={isMortgageLead(lead)}>
          <FormField title="Consistent Income?" required={requiredFields.consistenIncome} inline>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('consistentIncome', value)}
              options={yesOrNoOptions}
              value={stringifyValue(lead.callReview?.consistentIncome)}
            />
          </FormField>
          <FormField title="Reverse Mortgage" required={requiredFields.reverseMortgage} inline>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('reverseMortgage', value)}
              options={yesOrNoOptions}
              value={stringifyValue(lead.callReview?.reverseMortgage)}
            />
          </FormField>
        </If>
      </Section>
    </Sections>
  )
}
