// @ts-strict
import { ReactNode, useState } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import { BrowserRouter } from 'react-router-dom'
import { Drawer } from '@foundation/components'
import theme from '@foundation/themes'
import { GlobalStyle, ThemeProvider } from '@foundation/themes/styles'
import { Dialer } from 'components'
import {
  CurrentUserProvider,
  DialerProvider,
  InboxProvider,
  QueryStringParamsProvider,
  SavedSearchesProvider,
  TokenProvider,
  useCurrentUserContext,
  useTokenContext
} from 'contexts'
import { ActivitiesDrawer } from 'features/activities/commons'
import { useLogoutUser } from 'features/auth'
import { CommsDrawer } from 'features/comms/commons'
import {
  QueryClient,
  QueryClientProvider,
  useFeatureFlags,
  useFlagsmithStatus,
  useFlexUi,
  useScreenSize
} from 'hooks'
import { AppRoutes } from 'pages/AppRoutes'
import HttpsRedirect from 'react-https-redirect'
import 'scss/global.module.scss'
import styles from './App.module.scss'
import { environment } from './environment'
import { SignInOAuthPage } from './pages/SignInOAuthPage'
import { SignInPage } from './pages/SignInPage'
import { SiteHeader } from './pages/SiteHeader'
import { SplashPage } from './pages/SplashPage'
import 'x-axios-progress-bar/dist/nprogress.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 3 * 60_000 // 3 min
    }
  }
})

type TAppCurrentUserProps = {
  children: ReactNode
}

export const AppCurrentUser = ({ children }: TAppCurrentUserProps) => {
  const { currentUser, isAgent, isSalesAppAdmin } = useCurrentUserContext()
  const { signOut } = useLogoutUser()
  const [loggingOut, setLoggingOut] = useState(false)

  if (!currentUser) {
    return <SplashPage />
  }

  // users with 'agent' as one of their roles
  // should be instantly logged out of
  // the sales app.
  if (isAgent && !Boolean(isSalesAppAdmin)) {
    if (!loggingOut) {
      setLoggingOut(true)

      signOut.mutate()

      setTimeout(() => {
        window.location.reload()
      }, 500)
    }

    return null
  }

  return (
    <SavedSearchesProvider>
      <QueryStringParamsProvider>
        <DialerProvider>
          <InboxProvider>
            <div className={styles.container}>
              {children}
              <Dialer />
            </div>
          </InboxProvider>
        </DialerProvider>
      </QueryStringParamsProvider>
    </SavedSearchesProvider>
  )
}

const AppSidebar = () => {
  const { salesAppActivities, salesAppComms } = useFeatureFlags([
    'sales-app-activities',
    'sales-app-comms'
  ])

  return (
    <Drawer.Root>
      <Drawer.Sidebar>
        {salesAppActivities.enabled && (
          <Drawer.MenuButton title="Activities" icon="ShowChart" drawerKey="activities" />
        )}
        {salesAppComms.enabled && (
          <Drawer.MenuButton title="Conversations" icon="ChatBubbleOutline" drawerKey="comms" />
        )}
      </Drawer.Sidebar>
      <Drawer.Content drawerKey="activities">
        <Drawer.Header>Activities</Drawer.Header>
        <Drawer.Body>
          <ActivitiesDrawer />
        </Drawer.Body>
      </Drawer.Content>
      <Drawer.Content drawerKey="comms">
        <Drawer.Header>Comms</Drawer.Header>
        <Drawer.Body>
          <CommsDrawer />
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  )
}

const AppToken = () => {
  const { token } = useTokenContext()
  const { salesAppActivities, salesAppComms, salesOpsGoogleOauth, salesOpsFlexUiGoogleOauth } =
    useFeatureFlags([
      'sales-app-activities',
      'sales-app-comms',
      'sales-ops-google-oauth',
      'sales-ops-flex-ui-google-oauth'
    ])
  const { isDesktop } = useScreenSize()
  const flagsmith = useFlagsmithStatus()
  const { isFlexUi } = useFlexUi()
  const isLocalEnv = window.location.href.includes('localhost')
  const isFlagsmithFullyInitialised = flagsmith.initialised && !flagsmith.isLoading
  const googleOauthSigninEnabled =
    (salesOpsGoogleOauth.enabled && !isFlexUi) || (salesOpsFlexUiGoogleOauth.enabled && isFlexUi)

  // where the app inits so we need to make sure that flagsmith (since we use FF all the time) is fully loading
  // if itsn't fully initialised then we show the HL loader
  if (isFlagsmithFullyInitialised) {
    // once the flagsmith is fully initialised
    // we now have google oauth in our app but for now is only enabled with FF

    if (googleOauthSigninEnabled) {
      // if the FF is enabled for the google oauth
      // we need to start the process of authentication with google
      if (!token) {
        return <SignInOAuthPage />
      }
    } else {
      // if the FF for google oauth is disabled and the user doesn't have a token or
      // if we're currently in the local environment and the user doesn't have a token
      // we should render the old sign in page for the user to login with email and password
      if (!token || (isLocalEnv && !token)) {
        return <SignInPage />
      }
    }

    // after the authentication process is done
    // meaning: the user have a valid token stored in the localStorage
    // we need to render the app
    // so here it goes:

    // keep this when activies is ready for prod
    if (salesAppActivities.enabled || salesAppComms.enabled) {
      return (
        <CurrentUserProvider>
          <AppCurrentUser>
            <div className={styles.mainContent}>
              <SiteHeader />
              <AppRoutes />
              {isDesktop && <AppSidebar />}
            </div>
          </AppCurrentUser>
        </CurrentUserProvider>
      )
    }

    // remove this when activies is ready for prod
    return (
      <CurrentUserProvider>
        <AppCurrentUser>
          <SiteHeader />
          <AppRoutes />
        </AppCurrentUser>
      </CurrentUserProvider>
    )
  }

  return <SplashPage />
}

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <HttpsRedirect>
          <FlagsmithProvider
            options={{
              environmentID: environment.flagsmithKey,
              api: environment.flagsmithApi
            }}
            flagsmith={flagsmith}
          >
            <QueryClientProvider client={queryClient} contextSharing>
              <GoogleOAuthProvider clientId={environment.authClientId}>
                <TokenProvider>
                  <AppToken />
                </TokenProvider>
              </GoogleOAuthProvider>
            </QueryClientProvider>
          </FlagsmithProvider>
        </HttpsRedirect>
      </BrowserRouter>
    </ThemeProvider>
  )
}
