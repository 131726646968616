// @ts-strict
import type { AxiosResponse } from 'axios'
import type { UseMutationResult } from 'react-query'
import { useButtonForm } from 'components'
import type { User } from 'models'
import type { PutUserPayload } from 'services'
import { validateEmailFormat, validatePresence } from 'utils'

export type UserButtonFormPayload = PutUserPayload

export const useUserButtonForm = (
  mutation: UseMutationResult<AxiosResponse, unknown, PutUserPayload, unknown>,
  user?: User
) => {
  const transformPayload = (payload: UserButtonFormPayload): UserButtonFormPayload => ({
    email: payload.email.trim().toLowerCase(),
    firstName: payload.firstName.trim(),
    lastName: payload.lastName.trim()
  })

  const formValidations = {
    email: [validatePresence, validateEmailFormat],
    firstName: validatePresence,
    lastName: validatePresence
  }

  const { fieldsProps, formProps } = useButtonForm<UserButtonFormPayload>({
    initialPayload: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? ''
    },
    mutation,
    transformPayload,
    formValidations
  })

  return { fieldsProps, formProps }
}
