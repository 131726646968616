import { useEffectOnce } from 'react-use'
import { ParamsProvider } from 'contexts'
import { InfiniteActivityList } from 'features/activities/commons'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import { useInfiniteFetchThisLeadActivities } from '../hooks'

const Content = ({ leadId }: { leadId: string }) => {
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteFetchThisLeadActivities({
      searchParams: { leadId }
    })
  const { tracking } = useCustomEvents()
  const isLoading = isFetching || isFetchingNextPage

  useEffectOnce(() => {
    tracking({
      name: 'drawer_view',
      params: { trigger: 'click', location: 'lead', target: 'activity' }
    })
  })

  return (
    <InfiniteActivityList
      isLoading={isLoading}
      data={data}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
    />
  )
}

export const ListLeadActivities = ({ leadId }: { leadId: string }) => {
  return (
    <ParamsProvider key="thisLead">
      <Content leadId={leadId} />
    </ParamsProvider>
  )
}
