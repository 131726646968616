import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Menu.module.scss'

type Props = {
  children: ReactNode
  className?: string
  title?: ReactNode
}

const MenuSection = ({ title, children, className, ...props }: Props) => {
  return (
    <div {...props} className={cx(styles.section, className)} data-testid="menu-section">
      {title ? (
        <div data-testid="menu-section-title" className={styles.sectionTitle}>
          {title}
        </div>
      ) : null}
      {children}
    </div>
  )
}

export { MenuSection }
