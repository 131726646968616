// @ts-strict
import { ActionButton, PopupButton } from 'components'
import { Employee } from 'models'
import { EmployeeRolesPopupContent } from '..'

type Props = {
  employee: Employee
}

export const EmployeeRolesPopupButton = ({ employee }: Props) => {
  return (
    <PopupButton title="Edit Employee Roles" buttonText="Edit" buttonComponent={ActionButton}>
      <EmployeeRolesPopupContent employee={employee} />
    </PopupButton>
  )
}
