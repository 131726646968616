import { UseFetchData } from 'controllers/types'
import { useFeatureFlags, useQuery, UseQueryOptions } from 'hooks'
import { UserInterface } from 'models/v2/User'
import { EmployeesSchema } from 'schema'
import { fetchUsers, TFetchItems } from 'services'
import { FetchListUsersSearchParams, getSearchParams } from './getSearchParams'
import { listUsersKeys } from './keys'

type QueryOptions = UseQueryOptions<
  TFetchItems<UserInterface>,
  unknown,
  TFetchItems<UserInterface>,
  ReturnType<typeof listUsersKeys.list>
>

const FETCH_USERS_SHAPE: EmployeesSchema = {
  user: [
    'email',
    'name',
    'region',
    'pictureUrl',
    {
      roles: ['name', 'isEmployee']
    },
    {
      teamMemberships: ['role', { team: ['name'] }]
    }
  ]
}

export const useFetchListUsers = ({
  searchParams,
  options
}: UseFetchData<FetchListUsersSearchParams, QueryOptions> = {}) => {
  const { salesAppImpersonatingAgentTeamCoord } = useFeatureFlags([
    'sales-app-impersonating-agent-team-coord'
  ])

  const searchSettings = getSearchParams({
    perPage: 25,
    ...searchParams,
    userCategory: salesAppImpersonatingAgentTeamCoord.enabled
      ? searchParams?.userCategory
      : 'employees'
  })

  return useQuery(
    listUsersKeys.list(searchSettings),
    () => fetchUsers<UserInterface>(FETCH_USERS_SHAPE, searchSettings),
    {
      ...options,
      refetchOnWindowFocus: false,
      staleTime: 300000 // 5min
    }
  )
}
