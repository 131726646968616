import { Option } from 'classes'
import { Agent } from 'models'

const ChangeReasonsData = {
  declinedPpl: {
    slug: 'declined_ppl',
    text: 'Declined PPL - Poor Converter'
  },
  highReferralToFailed: {
    slug: 'high_referral_to_failed',
    text: 'High Referral to Failed - Poor Converter'
  },
  agentRequested: {
    slug: 'agent_requested',
    text: 'Agent Requested to Remove'
  },
  failureToPay: {
    slug: 'failure_to_pay',
    text: 'Failure to Pay'
  },
  agentUnresponsive: {
    slug: 'agent_unresponsive',
    text: 'Agent Unresponsive'
  },
  agentPartOfTeam: {
    slug: 'agent_part_of_team',
    text: 'Agent Part of Team'
  },
  resumeReferrals: {
    slug: 'resume_agent_referrals',
    text: 'Resume Agent Referrals'
  }
}

const VisibilityData = {
  allocationActiveProfileVisible: {
    displayText: 'Allocation active\nPublic profile visible',
    optionText: 'Allocation active, public profile visible',
    slug: 'allocation_active_profile_visible',
    disclaimer: '',
    selectableOption: true,
    agentOptions: {
      hideFromSearch: false,
      hideProfile: false
    }
  },
  allocationStoppedProfileVisible: {
    displayText: 'Allocation stopped\nPublic profile visible',
    optionText: 'Allocation stopped, public profile visible',
    slug: 'allocation_stopped_profile_visible',
    disclaimer: 'Agent will no longer receive referrals',
    selectableOption: true,
    agentOptions: {
      hideFromSearch: true,
      hideProfile: false
    }
  },
  allocationActiveProfileHidden: {
    displayText: 'Allocation active\nPublic profile hidden',
    optionText: 'Allocation active, public profile hidden',
    slug: 'allocation_active_profile_hidden',
    selectableOption: false,
    disclaimer: '',
    agentOptions: {
      hideFromSearch: false,
      hideProfile: true
    }
  },
  allocationStoppedProfileHidden: {
    displayText: 'Allocation stopped\nPublic profile hidden',
    optionText: 'Allocation stopped, public profile hidden',
    slug: 'allocation_stopped_profile_hidden',
    selectableOption: true,
    disclaimer: 'Agent will no longer receive referrals and their public profile will be hidden',
    agentOptions: {
      hideFromSearch: true,
      hideProfile: true
    }
  }
}

const visibilityOptionFromAgent = (agent: Agent) => {
  const currentOption = Object.values(VisibilityData).find(
    value =>
      agent.hideFromSearch === value.agentOptions.hideFromSearch &&
      agent.hideProfile === value.agentOptions.hideProfile
  )

  return currentOption
}

const selectableVisibilityOptionFromAgent = (agent: Agent) => {
  const currentOption = Object.values(VisibilityData).find(
    value =>
      agent.hideFromSearch === value.agentOptions.hideFromSearch &&
      agent.hideProfile === value.agentOptions.hideProfile
  )

  if (!currentOption.selectableOption) {
    return null
  }

  return currentOption
}

const visibilityOptionFromSlug = (slug: string) => {
  return Object.values(VisibilityData).find(value => value.slug === slug)
}

const createChangeReasonOptions = () =>
  Object.values(ChangeReasonsData).map(reasonValue => {
    return new Option({ name: reasonValue.text, value: reasonValue.slug })
  })

const createVisibilityOptions = () =>
  Object.values(VisibilityData)
    .filter(value => value.selectableOption)
    .map(visibilityValue => {
      return new Option({ name: visibilityValue.optionText, value: visibilityValue.slug })
    })

const createNotifyAgentOptions = () =>
  [
    { name: 'Yes', value: 'yes' },
    { name: 'No', value: 'no' }
  ].map(option => {
    return new Option(option)
  })

const changeReasonOptions = createChangeReasonOptions()
const visibilityOptions = createVisibilityOptions()
const notifyAgentOptions = createNotifyAgentOptions()

export {
  ChangeReasonsData,
  changeReasonOptions,
  notifyAgentOptions,
  VisibilityData,
  visibilityOptions,
  selectableVisibilityOptionFromAgent,
  visibilityOptionFromAgent,
  visibilityOptionFromSlug
}
