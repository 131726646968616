// @ts-strict
import { Block, FormField, Select, TextArea } from 'components'
import styles from './AgentProgramsFormFields.module.scss'
import { TUseAgentProgramsFormFieldsProps, useAgentProgramsFormFields } from './'

type TProps = TUseAgentProgramsFormFieldsProps

export const AgentProgramsFormFields = ({
  agent,
  agentTeam,
  payload,
  onChangeAttribute
}: TProps) => {
  const {
    hintTitle,
    hintSubtitle,
    programsOptions,
    enrollmentStatusOptions,
    isProgramSelected,
    showNoteField,
    hintNote,
    setProgram,
    setEnrollmentStatus,
    setNote
  } = useAgentProgramsFormFields({ onChangeAttribute, payload, agent, agentTeam })

  const hint = hintTitle && <Block title={hintTitle} subtitle={hintSubtitle} />

  return (
    <div className={styles.agentProgramsFormFields}>
      <FormField title="Program" htmlFor="AgentProgram" required>
        <Select
          id="AgentProgram"
          data-testid="programSelect"
          value={payload.program}
          onChange={setProgram}
          addBlank
          options={programsOptions}
        />
      </FormField>
      <FormField title="Enrollment Status" htmlFor="agentProgramEnrollment" required hint={hint}>
        <Select
          id="agentProgramEnrollment"
          data-testid="enrollmentStatusSelect"
          value={payload.enrollmentStatus}
          onChange={setEnrollmentStatus}
          addBlank
          options={enrollmentStatusOptions}
          disabled={!isProgramSelected}
        />
      </FormField>
      {showNoteField && (
        <FormField title="Note" required hint={hintNote} htmlFor="note">
          <TextArea id="note" value={payload.note} onChange={setNote} />
        </FormField>
      )}
    </div>
  )
}
