// @ts-strict
import { TableHeaderColumn as TH, TableHeaderRow as Tr } from 'components'
import styles from 'content/Leads/LeadsTable/LeadsTableCells/LeadsTableCells.module.scss'
import { useListContext } from 'contexts'
import { cx } from 'utils'

type Props = {
  canFixFirstColumn?: boolean
}

export const LeadsTableHeaderCells = ({ canFixFirstColumn = true }: Props) => {
  const { findColumn, visibleColumns } = useListContext()

  return (
    <Tr>
      {visibleColumns.map((visibleColumn, idx) => {
        const column = findColumn(visibleColumn)
        const fixFirstColumn = canFixFirstColumn && idx === 0

        if (!column) {
          return null
        }

        const { name, sort, columnSize, align, title } = column!
        const sortOptions =
          sort?.map(sortAttr => ({
            name: sortAttr,
            slug: sortAttr,
            flip: true
          })) ?? []

        return (
          <TH
            fixed={fixFirstColumn}
            className={cx(styles.default, styles[visibleColumn])}
            key={visibleColumn}
            size={columnSize}
            align={align}
            title={title}
            name={name}
            sort={sortOptions}
          />
        )
      })}
    </Tr>
  )
}
