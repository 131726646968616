import { SearchParams } from '@types'
import { getHapiRequestParams, TransactionsSchema } from 'schema'
import { removeAttributesWithNullValues } from 'utils'
import { agentItemShape } from '../Agents/agentsShapes'

const sortParam = {
  salesDate: 'selling_date',
  created: 'created_at',
  updated: 'updated_at'
}

type SearchParamsSettings = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'sortBy' | 'sortDir' | 'searchTerm'
>

type Args = Partial<{
  agentId: string
}> &
  SearchParamsSettings

export const getSearchParams = ({ sortBy, sortDir, page, perPage, searchTerm, agentId }: Args) => {
  const getSort = () => {
    const dir = sortDir === 'desc' ? '-' : ''

    if (sortBy) {
      return `${dir}${sortParam[sortBy]}`
    }
    return `-${sortParam['salesDate']}`
  }

  const transactionsShape: TransactionsSchema = {
    transactions: [
      'sellingDate',
      'sellingPrice',
      'fullAddress',
      'latitude',
      'longitude',
      'humanizedPropertyType',
      {
        'listingAgent:agent': agentItemShape
      },
      {
        'sellingAgent:agent': agentItemShape
      }
    ]
  }

  return removeAttributesWithNullValues({
    ...getHapiRequestParams(transactionsShape),
    'page[number]': page,
    'page[size]': perPage,
    'filter[_search]': searchTerm,
    'filter[_agent_id]': agentId,
    sort: getSort()
  })
}
