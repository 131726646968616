import { Marker, Point } from 'classes'
import { median } from './median'

export const getCenter = (markers: Marker[]): Point => {
  const realMarkers = markers.filter(Boolean)

  if (!realMarkers || !realMarkers.length) {
    return new Point({ longitude: -122.3965667, latitude: 37.7882221 })
  }

  return new Point({
    longitude: median(realMarkers.map(m => m.point.longitude).sort()),
    latitude: median(realMarkers.map(m => m.point.latitude).sort())
  })
}
