import { byAttr } from 'utils'
import { AgentMatchingLeadField } from './AgentMatchingLeadField'
import {
  agentMatchingLeadFieldsSettings,
  TAgentMatchingLeadFieldAttribute
} from './agentMatchingLeadFieldsSettings'

export const agentMatchingLeadFields = agentMatchingLeadFieldsSettings.map(setting => {
  return new AgentMatchingLeadField(setting)
})

export const getAgentMatchingLeadField = (attr: TAgentMatchingLeadFieldAttribute) => {
  return agentMatchingLeadFields.find(byAttr('attr', attr))
}

export const getAgentMatchingLeadFieldName = (attr: TAgentMatchingLeadFieldAttribute) => {
  return getAgentMatchingLeadField(attr)?.name
}
