import { AgentAttributes, AgentsSchema, getHapiRequestParams } from 'schema'
import { getAgentsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { getSearchParams, TAgentsAutoCompleteParams } from './getSearchParams'
import { transformAgentsAutoComplete } from '.'

export const fetchAgentsAutoComplete = async (params: TAgentsAutoCompleteParams) => {
  const agentsFields: Array<keyof AgentAttributes> = [
    'firstName',
    'lastName',
    'email1',
    'pictureThumbUrl',
    'officeDisplayName'
  ]

  const agentsShape: AgentsSchema = {
    agents: [...agentsFields, { state: ['code'] }, { phoneNumbers: ['number', 'phoneType'] }]
  }

  const response = await getRequest(
    getAgentsUrl({
      searchParams: {
        ...getHapiRequestParams(agentsShape),
        ...getSearchParams(params)
      }
    })
  )

  return await deserializeAsync(response.data, {
    transform: transformAgentsAutoComplete
  })
}
