// @ts-strict

import {
  TableHeaderCell as Th,
  TableHeaderActions as ThA,
  TableHeaderRow as Tr
} from 'components/Table'

export const LeadUsersListHeader = () => (
  <Tr>
    <Th>Name</Th>
    <Th>Role</Th>
    <Th>Added On</Th>
    <ThA />
  </Tr>
)
