const dictionary = {
  buyer: 'Buyer',
  seller: 'Seller',
  escrow: 'Escrow',
  cc_cash_offer: 'Cash Offer',
  cc_trade_in: 'Buy Before You Sell',
  title: 'Title',
  mortgage: 'Mortgage',
  cc_hl_simple_sale: 'HLSS'
}

export const formatUserType = (userType: string): string => dictionary[userType] || userType
