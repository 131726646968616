import { matchRoutes, useLocation } from 'react-router-dom'
import { Virtuoso } from 'react-virtuoso'
import { Box } from '@foundation/components'
import { useEffectOnce, useFeatureFlags } from 'hooks'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import { EmptyPlaceholderInfiniteScrolling, LoadingInfiniteScrollingFooter } from 'templates'
import { CommsListRow, useCommsFiltersContext } from '../../commons/ui'
import { thisClientTabPathsToMatch } from '../../commons/ui/utils'
import { useFetchInfiniteThisClientComms, useFetchThisClientLead } from '../hooks'
import { ListClientCommsFilters } from './ListClientCommsFilters'

export const ListClientComms = () => {
  const { tracking } = useCustomEvents()
  const location = useLocation()
  const { salesAppCommsUxImprovements } = useFeatureFlags(['sales-app-comms-ux-improvements'])
  const { filters } = useCommsFiltersContext()
  const [matchedRoute] = matchRoutes(thisClientTabPathsToMatch, location.pathname) || []
  const { orderId: orderIdParam, leadId: leadIdParam } = matchedRoute.params
  // fetch the lead to get its order.id
  const { data: leadResponseData, isLoading: isLoadingLeadResponse } = useFetchThisClientLead(
    leadIdParam!,
    {
      // enable it only when accessing a lead page
      enabled: !!leadIdParam
    }
  )
  const leadDataOrderId = leadResponseData?.order?.id
  const orderId = orderIdParam || leadDataOrderId
  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useFetchInfiniteThisClientComms(orderId!, {
      searchParams: {
        name: filters.client.channels,
        lessEqualOccurredAt: filters.client.dateRange?.endingDate,
        greaterEqualOccurredAt: filters.client.dateRange?.startingDate,
        agentIdContactInfo: filters.client.agent?.value,
        userIdContactInfo: filters.client.user?.value
      },
      options: { enabled: !!orderId }
    })
  const isLoading = isFetching || isFetchingNextPage || isLoadingLeadResponse

  useEffectOnce(() => {
    tracking({
      name: 'drawer_view',
      params: {
        trigger: 'click',
        location: 'lead',
        target: 'communication'
      }
    })
  })

  return (
    <>
      {salesAppCommsUxImprovements.enabled && (
        <Box marginTop="$4" marginBottom="$4">
          <ListClientCommsFilters />
        </Box>
      )}
      <Virtuoso
        context={{ isLoading }}
        style={{ height: '100%' }}
        data={data?.pages.flatMap(d => d.items) || []}
        endReached={() => {
          if (!isLoading && hasNextPage) {
            fetchNextPage()
          }
        }}
        itemContent={(index, item) => {
          return <CommsListRow data-testid={`eventComponent${index}`} activityFeed={item} />
        }}
        components={{
          Footer: ({ context }) => (
            <LoadingInfiniteScrollingFooter isLoading={context?.isLoading} />
          ),
          EmptyPlaceholder: ({ context }) => (
            <EmptyPlaceholderInfiniteScrolling isLoading={context?.isLoading} />
          )
        }}
      />
    </>
  )
}
