import { SearchParams } from '@types'
import { removeAttributesWithNullValues } from 'utils'

const sortParam = {
  Name: 'name'
}

export type SearchParamsSettings = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'sortBy' | 'searchTerm' | 'sortDir'
>

type Args = {} & SearchParamsSettings

export const getSearchParams = ({ sortBy, sortDir, page, perPage }: Args) => {
  const getSort = () => {
    const dir = sortDir === 'desc' ? '-' : ''
    const [defaultSortValue] = Object.values(sortParam)

    if (sortBy) {
      return `${dir}${sortParam[sortBy]}`
    }

    return `${dir}${defaultSortValue}`
  }

  return removeAttributesWithNullValues({
    'page[number]': page,
    'page[size]': perPage,
    sort: getSort()
  })
}
