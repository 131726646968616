// @ts-strict
import { Link } from 'react-router-dom'
import { Block, TableCell as Td, TableRow as Tr } from 'components'
import { AgentPrograms, AgentServicesOpportunitiesSelector } from 'content/Agent'
import { AgentAvatar } from 'content/Agent/AgentAvatar'
import { AgentLinkWithMenuCard } from 'content/Agent/AgentDetailsMenuCard'
import { useFeatureFlags, useQueryClient } from 'hooks'
import { Agent } from 'models'
import { dash, DEFAULT_STRING, formatDate, formatTime } from 'utils'
import styles from './Agents.module.scss'

type Props = {
  agent: Agent
  rowActions?: React.ReactNode
}

export const AgentsTableRow = ({ agent, rowActions }: Props) => {
  const queryCache = useQueryClient()
  const { salesAppAgentDetailsComponent } = useFeatureFlags(['sales-app-agent-details-component'])

  const clearCache = () => queryCache.invalidateQueries(['agents'])

  const subtitle = [agent.officeDisplayName, agent.state?.code].filter(Boolean).join(', ')

  const agentNameComponent = salesAppAgentDetailsComponent?.enabled ? (
    <Block
      allowTitleOverflow
      title={<AgentLinkWithMenuCard agent={agent} />}
      subtitle={subtitle}
      image={<AgentAvatar agent={agent} />}
    />
  ) : (
    <Block
      className={styles.agentName}
      title={
        <Link to={`/agents/${agent.id}`} data-testid="agentLink">
          {agent.name}
        </Link>
      }
      subtitle={subtitle}
      image={<AgentAvatar agent={agent} />}
    />
  )

  return (
    <Tr className="agent-row" data-agent-id={agent.id}>
      <Td className={styles.agentNameCell} data-testid="cellAgentName" fixed>
        {agentNameComponent}
      </Td>
      <Td>
        <AgentPrograms agent={agent} />
      </Td>
      <Td>
        <AgentServicesOpportunitiesSelector agent={agent} onChange={clearCache} />
      </Td>
      <Td>
        {agent.servicesOpportunitiesCounts ? (
          <Link to={`/agents/${agent.id}/referrals?oppStatus=%5B2%2C39%5D`}>
            {dash(agent.servicesOpportunitiesCounts.totalCount)}
          </Link>
        ) : (
          DEFAULT_STRING
        )}
      </Td>
      <Td>
        {dash(agent.referralStats?.totalCount)}/{dash(agent.referralStats?.activeCount)}
      </Td>
      <Td>
        {agent.referralStats?.inIntroducedCount ? (
          <Link to={`/agents/${agent.id}/referrals?leadStages=%5Bintroduced%5D`}>
            {agent.referralStats.inIntroducedCount}
          </Link>
        ) : (
          DEFAULT_STRING
        )}
      </Td>
      <Td>
        {agent.referralStats?.inAgentLeftVmCount ? (
          <Link to={`/agents/${agent.id}/referrals?leadStages=%5Bagent_left_vm%5D`}>
            {agent.referralStats?.inAgentLeftVmCount}
          </Link>
        ) : (
          DEFAULT_STRING
        )}
      </Td>
      <Td data-testid="cellConnected">
        {agent.referralStats?.inConnectedCount ? (
          <Link to={`/agents/${agent.id}/referrals?leadStages=%5Bconnected%5D`}>
            {agent.referralStats?.inConnectedCount}
          </Link>
        ) : (
          DEFAULT_STRING
        )}
      </Td>
      <Td>
        {agent.referralStats?.inMeetingScheduledCount ? (
          <Link to={`/agents/${agent.id}/referrals?leadStages=%5Bmeeting_scheduled%5D`}>
            {agent.referralStats?.inMeetingScheduledCount}
          </Link>
        ) : (
          DEFAULT_STRING
        )}
      </Td>
      <Td>
        {agent.referralStats?.inListingCount ? (
          <Link to={`/agents/${agent.id}/referrals?leadStages=%5Blisting%5D`}>
            {agent.referralStats?.inListingCount}
          </Link>
        ) : (
          DEFAULT_STRING
        )}
      </Td>
      <Td>
        {agent.referralStats?.closedCount ? (
          <Link to={`/agents/${agent.id}/referrals?leadStages=%5Bclosed%5D`}>
            {agent.referralStats?.closedCount}
          </Link>
        ) : (
          DEFAULT_STRING
        )}
      </Td>
      <Td>{dash(agent.referralStats?.urgentCount)}</Td>
      <Td>
        <Block
          title={formatDate(agent.lastActivityAt, 'short')}
          subtitle={formatTime(agent.lastActivityAt)}
        />
      </Td>
      <Td>
        <Block
          title={formatDate(agent.lastTouchedAt, 'short')}
          subtitle={formatTime(agent.lastTouchedAt)}
        />
      </Td>
      <Td>
        <Block
          title={formatDate(agent.lastSignedInAt, 'short')}
          subtitle={formatTime(agent.lastSignedInAt)}
        />
      </Td>

      {rowActions}
    </Tr>
  )
}
