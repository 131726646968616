const INSPECTION_DEFICIENCY_SETTINGS = {
  reportInspectionTypes: {
    name: 'Inspection Type',
    value: 'reportInspectionTypes',
    isDefault: true,
    sort: true,
    columnSize: 'md'
  },
  category: {
    name: 'Category',
    value: 'category.name',
    isDefault: true,
    sort: true,
    columnSize: 'md'
  },
  severity: {
    name: 'Severity',
    value: 'severity.name',
    isDefault: true,
    sort: true,
    columnSize: 'md'
  },
  detail: {
    name: 'Detail',
    value: 'detail',
    isDefault: true
  },
  files: {
    name: 'Images',
    value: 'files',
    isDefault: true,
    columnSize: 'md'
  },
  escalated: {
    name: '',
    value: 'escalated',
    align: 'center',
    isDefault: true,
    columnSize: 'xxsm'
  }
} as const

export type INSPECTION_DEFICIENCY_COLUMN_SLUGS_TYPE = keyof typeof INSPECTION_DEFICIENCY_SETTINGS
export const INSPECTION_DEFICIENCY_COLUMN_SLUGS = Object.keys(
  INSPECTION_DEFICIENCY_SETTINGS
) as Array<INSPECTION_DEFICIENCY_COLUMN_SLUGS_TYPE>

export const INSPECTION_DEFICIENCY_SORT_SETTINGS = INSPECTION_DEFICIENCY_COLUMN_SLUGS.map(
  key => INSPECTION_DEFICIENCY_SETTINGS[key]
).filter(setting => setting.hasOwnProperty('sort'))

export const INSPECTION_DEFICIENCY_COLUMN_SETTINGS = [
  INSPECTION_DEFICIENCY_SETTINGS.escalated,
  INSPECTION_DEFICIENCY_SETTINGS.category,
  INSPECTION_DEFICIENCY_SETTINGS.detail,
  INSPECTION_DEFICIENCY_SETTINGS.files,
  INSPECTION_DEFICIENCY_SETTINGS.reportInspectionTypes,
  INSPECTION_DEFICIENCY_SETTINGS.severity
]
