const INSPECTION_DEFICIENCY_FILTER_SETTING_ROOT = {
  category: {
    slug: 'filterByCategory',
    name: 'Category',
    optionsKey: 'inspectionCategoryOptions'
  },
  severity: {
    slug: 'filterBySeverity',
    name: 'Severity',
    optionsKey: 'inspectionSeverityOptions'
  },
  escalated: {
    slug: 'filterByEscalated',
    name: 'Escalated',
    optionsKey: 'trueFalseOptions'
  },
  inspectionType: {
    slug: 'filterByInspectionType',
    name: 'Inspection Type',
    optionsKey: 'inspectionTypeOptions'
  },
  providerLeadId: {
    slug: 'filterByProviderLeadId',
    name: 'Provider Lead ID'
  }
} as const

type InspectionDeficiencyFilterSettingRoot = typeof INSPECTION_DEFICIENCY_FILTER_SETTING_ROOT
export type INSPECTION_DEFICIENCY_FILTER_SLUGS_TYPE =
  InspectionDeficiencyFilterSettingRoot[keyof InspectionDeficiencyFilterSettingRoot]['slug']

const INSPECTION_DEFICIENCY_FILTER_SLUGS = Object.keys(
  INSPECTION_DEFICIENCY_FILTER_SETTING_ROOT
) as Array<INSPECTION_DEFICIENCY_FILTER_SLUGS_TYPE>

export const INSPECTION_DEFICIENCY_FILTER_SETTINGS = INSPECTION_DEFICIENCY_FILTER_SLUGS.map(
  key => INSPECTION_DEFICIENCY_FILTER_SETTING_ROOT[key]
)
