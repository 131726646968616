import { bySlug } from 'utils'
import { buildLeadStages } from '../lookupLeadStages'
import { CashOfferProviderLeadStage } from './CashOfferProviderLeadStage'
import {
  cashOfferProviderLeadStagesSettings,
  TCashOfferProviderLeadStagesSlug
} from './cashOfferProviderLeadStagesSettings'

export const lookupCashOfferProviderLeadStages = buildLeadStages(
  cashOfferProviderLeadStagesSettings,
  CashOfferProviderLeadStage
)

export const getCashOfferProviderLeadStage = (slug: TCashOfferProviderLeadStagesSlug) =>
  lookupCashOfferProviderLeadStages.find(bySlug(slug))
