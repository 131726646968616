// @ts-strict
import { PaginationParams, SearchConsole, SearchInputParams } from 'components'
import { useAgentAeAreasContext } from 'contexts/AgentAeAreas'
import { AgentAeAreasTable } from '..'
import { AgentAeAreasListFilters, AgentAeAreasListStatus } from '.'

export const AgentAeAreasList = () => {
  const {
    agentAeAreasQuery: { pageCount }
  } = useAgentAeAreasContext()

  return (
    <>
      <AgentAeAreasListStatus />
      <SearchConsole
        search={<SearchInputParams placeholder="Search by area name" />}
        actions={<AgentAeAreasListFilters />}
      />
      <AgentAeAreasTable />
      {pageCount ? <PaginationParams totalPages={pageCount} /> : null}
    </>
  )
}
