// @ts-strict
import { ButtonForm, CheckBoxField, DateInput, Section } from 'components'
import { FormField } from 'content/AgentMatchingLead/styles'
import { useCurrentUserContext } from 'contexts'
import { useReferralPreferencesController } from 'controllers/ReferralPreferences'
import type { Agent } from 'models'
import styles from './AgentVacationSettingsButtonForm.module.scss'
import { useAgentVacationSettingButtonForm } from './useAgentVacationSettingsButtonForm'

type Props = {
  agent: Agent
}

export const AgentVacationSettingsButtonForm = ({ agent }: Props) => {
  const { isAgentAdmin } = useCurrentUserContext()
  const { updateVacationSettings } = useReferralPreferencesController(agent.id)
  const {
    fieldsProps: { onChangeAttribute, payload },
    formProps
  } = useAgentVacationSettingButtonForm(updateVacationSettings, agent)

  if (!isAgentAdmin) {
    return null
  }

  const onVacationActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onChangeAttribute('onVacationActive', true)
      onChangeAttribute('onVacationUntil', agent.referralPreferences?.onVacationUntil ?? '')
    } else {
      onChangeAttribute('onVacationActive', false)
      onChangeAttribute('onVacationUntil', '')
    }
  }

  return (
    <ButtonForm buttonText="Edit" title="Vacation Status" {...formProps}>
      <Section className={styles.section}>
        Toggle to turn vacation status on, then select the date in which the agent will return.{' '}
      </Section>
      <Section className={styles.section}>
        <CheckBoxField
          title="On Vacation?"
          checked={payload.onVacationActive}
          onChange={onVacationActiveChange}
        />
      </Section>
      {payload.onVacationActive && (
        <>
          <Section className={styles.section}>
            <FormField title="When will the agent be back?" required>
              <DateInput
                value={payload.onVacationUntil}
                onChange={val => onChangeAttribute('onVacationUntil', val)}
              />
            </FormField>
          </Section>
          <Section className={styles.disclaimer}>
            Please choose the first full day the agent will return.
          </Section>
        </>
      )}
    </ButtonForm>
  )
}
