// @ts-strict
import { css, styled } from '@foundation/themes/styles'
import { Block as BlockComponent } from 'components'

export const Block = styled(BlockComponent)<{ title: string }>`
  .block_title {
    white-space: pre-line;
    font-size: 1.2rem;
  }
`

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
`

export const ActionName = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.space.$2};
  `}
`

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.sizes.$11};
    height: ${theme.sizes.$11};
  `}
`
export const NamePartnerBlockCell = styled(BlockComponent)`
  display: flex;

  .left {
    min-width: 28rem;
    z-index: 1;
  }

  .leadSummaryField {
    & > label {
      flex: 0 0 23%;
    }
  }
`
