import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import { Box, DROPDOWN_ELEMENT_ID, Icon, Spinner, Text } from '@foundation/components'
import * as SelectStyle from '../Select/styles'
import { selectTextSize } from '../Select/styles'
import { Option } from './Option'
import * as S from './styles'
import type { AutocompleteProps } from './types'
import { useAutocomplete } from './useAutocomplete'

export const Autocomplete = ({
  'data-testid': dataTestId = 'autocomplete',
  defaultOpen = false,
  onChange,
  onClose,
  onInputChange,
  onOpen,
  errorMessage,
  isDisabled = false,
  isRequired = false,
  items,
  label,
  value,
  placeholder = 'Type to start searching',
  size = 'xs',
  status = 'idle'
}: AutocompleteProps) => {
  const {
    refs,
    getReferenceProps,
    activeIndex,
    setActiveIndex,
    setOpen,
    inputValue,
    setInputValue,
    getFloatingProps,
    getItemProps,
    listRef,
    open,
    strategy,
    positionX,
    positionY,
    context,
    handleInputChange,
    selectedItem,
    setSelectedItem,
    onClear
  } = useAutocomplete({
    defaultOpen,
    isDisabled,
    onChange,
    onClose,
    onInputChange,
    onOpen,
    value
  })

  const disabledColor = isDisabled ? 'neutral4' : 'primary10'
  const showClearButton = status !== 'loading' && (inputValue || selectedItem)
  const showOptions = !!items.length && status !== 'loading' && status !== 'error'
  const showResultNotFound = status === 'error'
  const showLoadingIndicator = status === 'loading'

  return (
    <Box data-testid={dataTestId} width="100%">
      <SelectStyle.Label
        onClick={e => {
          e.preventDefault()
        }}
      >
        {label && (
          <SelectStyle.LabelText>
            <Text color={disabledColor} size={selectTextSize[size]}>
              {isRequired ? label : `${label} (optional)`}
            </Text>
          </SelectStyle.LabelText>
        )}
      </SelectStyle.Label>
      <S.TriggerWrapper
        data-testid="triggerWrapper"
        color={disabledColor}
        isDisabled={isDisabled}
        size={size}
      >
        <SelectStyle.Trigger
          isDisabled={isDisabled}
          hasError={!!errorMessage}
          isOpen={open}
          $size={size}
          aria-label={inputValue}
          aria-autocomplete="none"
          as="input"
          disabled={isDisabled}
          {...getReferenceProps({
            ref: refs.setReference,
            onChange: handleInputChange,
            value: inputValue,
            placeholder,
            'aria-autocomplete': 'list',
            onKeyDown(event) {
              if (event.key === 'Enter' && activeIndex != null && items[activeIndex]) {
                const newItem = items[activeIndex]
                setSelectedItem(newItem)
                setInputValue(newItem.label)
                onChange(newItem)
                setActiveIndex(null)
                setOpen(false)
              }
            },
            onClick() {
              if (!open && items.length && !isDisabled) {
                onOpen?.()
                setOpen(true)
              }
            }
          })}
        />
        {open ? <Icon path="UpArrow" /> : <Icon path="DownArrow" />}
        {showClearButton && (
          <S.ClearButton title="Clear selected option" data-id="clearButton" onClick={onClear}>
            <Icon path="Close" size="xs" color="neutral8" />
          </S.ClearButton>
        )}
        {showLoadingIndicator && !isDisabled && <Spinner />}
        {!!errorMessage && (
          <SelectStyle.ErrorMessage hasError={!!errorMessage}>
            <Text color="danger5" size={selectTextSize[size]}>
              {errorMessage}
            </Text>
          </SelectStyle.ErrorMessage>
        )}
      </S.TriggerWrapper>

      <FloatingPortal id="autocomplete-portal">
        {open && (
          <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
            <SelectStyle.Dropdown
              id={DROPDOWN_ELEMENT_ID}
              position={strategy}
              top={positionY}
              left={positionX}
              {...getFloatingProps({
                ref: refs.setFloating
              })}
            >
              {showLoadingIndicator && (
                <Box margin="$3">
                  <Spinner size="xs" label="Loading options..." />
                </Box>
              )}
              {showResultNotFound && (
                <Box margin="$3">
                  <Text color="primary10" size="xs">
                    No results
                  </Text>
                </Box>
              )}
              {showOptions &&
                items.map((item, index) => {
                  if (!item.label || !item.value) {
                    return null
                  }

                  return (
                    <Option
                      activeIndex={activeIndex}
                      key={`${item.value}-${item.label}-${item.index}`}
                      getItemProps={getItemProps}
                      listRef={listRef}
                      index={index}
                      value={item.value}
                      label={item.label}
                      isSelected={value?.value === item.value}
                      onClick={() => {
                        setSelectedItem(item)
                        setInputValue(item.label)
                        onChange(item)
                        setOpen(false)
                        onClose?.()
                      }}
                      size={size}
                    />
                  )
                })}
            </SelectStyle.Dropdown>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </Box>
  )
}
