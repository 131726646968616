import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react'

type Props = [boolean, Dispatch<SetStateAction<boolean>>]

const MenuContext = createContext<Props>(null)

const MenuProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState(false)
  return <MenuContext.Provider value={[state, setState]}>{children}</MenuContext.Provider>
}

export { MenuContext, MenuProvider }
