export const ccCashOfferTaskColumns = {
  cashOfferCheckHoaStatusAndDues: {
    order: 1,
    name: 'HOA Status',
    title: 'Check HOA Status & Dues',
    value: 'cashOfferCheckHoaStatusAndDues',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferValueIrProperty: {
    order: 2,
    name: 'Value Property',
    title: 'Value IR Property',
    value: 'cashOfferValueIrProperty',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferReceiveInspectionReports: {
    order: 3,
    name: 'Rec Insp Reports',
    title: 'Receive Inspection Reports',
    value: 'cashOfferReceiveInspectionReports',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferReviewInspectionReports: {
    order: 4,
    name: 'Rev Insp Reports',
    title: 'Review Inspection Reports',
    value: 'cashOfferReviewInspectionReports',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferSendCashOfferAgreement: {
    order: 5,
    name: 'Send COA',
    title: 'Send Cash Offer Agreement',
    value: 'cashOfferSendCashOfferAgreement',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferReceiveCashOfferAgreement: {
    order: 6,
    name: 'Rec COA',
    title: 'Receive Cash Offer Agreement',
    value: 'cashOfferReceiveCashOfferAgreement',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferSendOfferSubmissionDocuments: {
    order: 7,
    name: 'Send CO Letter',
    title: 'Send Cash Offer Letter, Proof of Funds & Purchase Contract Instructions',
    value: 'cashOfferSendOfferSubmissionDocuments',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferSendIntentToProceed: {
    order: 8,
    name: 'Send Intent to Proc',
    title: 'Send Intent to Proceed',
    value: 'cashOfferSendIntentToProceed',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferReceiveIntentToProceed: {
    order: 9,
    name: 'Rec Intent to Proc',
    title: 'Receive Intent to Proceed',
    value: 'cashOfferReceiveIntentToProceed',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferReceiveIrContract: {
    order: 10,
    name: 'Rec IR Contract',
    title: 'Receive IR Contract',
    value: 'cashOfferReceiveIrContract',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferReviewIrContract: {
    order: 11,
    name: 'Rev IR Contract',
    title: 'Review IR Contract',
    value: 'cashOfferReviewIrContract',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferSendAssignmentAddendumAndPoa: {
    order: 15,
    name: 'Send Addendum',
    title: 'Send Assignment Addendum & Purchase Option Agreement',
    value: 'cashOfferSendAssignmentAddendumAndPoa',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferReceiveAssignmentAddendumAndPoa: {
    order: 16,
    name: 'Rec Addendum',
    title: 'Receive Assignment Addendum & Purchase Option Agreement',
    value: 'cashOfferReceiveAssignmentAddendumAndPoa',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferReceiveIrBuyDisclosures: {
    order: 17,
    name: 'Rec IR Buy Discl',
    title: 'Receive IR Buy Disclosures',
    value: 'cashOfferReceiveIrBuyDisclosures',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferAssignFundingFacility: {
    order: 18,
    name: 'Fund Facility',
    title: 'Assign Funding Facility',
    value: 'cashOfferAssignFundingFacility',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferRequestPropertyInsurance: {
    order: 19,
    name: 'Req Insurance',
    title: 'Request Property Insurance',
    value: 'cashOfferRequestPropertyInsurance',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferFundProperty: {
    order: 20,
    name: 'Fund Property',
    title: 'Fund Property',
    value: 'cashOfferFundProperty',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferExecuteBuyerClosingPackage: {
    order: 21,
    name: 'Exc Buy Closing Pack',
    title: 'Execute Buyer Closing Package',
    value: 'cashOfferExecuteBuyerClosingPackage',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferSendIrSellContract: {
    order: 22,
    name: 'Send IR Sell Contract',
    title: 'Send IR Sell Contract',
    value: 'cashOfferSendIrSellContract',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferReceiveIrSellContract: {
    order: 23,
    name: 'Rec IR Sell Contract',
    title: 'Receive IR Sell Contract',
    value: 'cashOfferReceiveIrSellContract',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferSendIrSellDisclosures: {
    order: 24,
    name: 'Send IR Sell Discl',
    title: 'Send IR Sell Disclosures',
    value: 'cashOfferSendIrSellDisclosures',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferReceiveIrSellDisclosures: {
    order: 25,
    name: 'Rec IR Sell Discl',
    title: 'Receive IR Sell Disclosures',
    value: 'cashOfferReceiveIrSellDisclosures',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  },
  cashOfferExecuteSellerClosingPackage: {
    order: 26,
    name: 'Exc Sell Closing Pack',
    title: 'Execute Seller Closing Package',
    value: 'cashOfferExecuteSellerClosingPackage',
    group: 'tasks',
    isDefault: false,
    isOptional: true,
    isReorderable: true,
    columnSize: 'xsm',
    align: 'center'
  }
} as const

export const ccCashOfferTaskColumnsSettings = [
  ccCashOfferTaskColumns.cashOfferCheckHoaStatusAndDues,
  ccCashOfferTaskColumns.cashOfferValueIrProperty,
  ccCashOfferTaskColumns.cashOfferReceiveInspectionReports,
  ccCashOfferTaskColumns.cashOfferReviewInspectionReports,
  ccCashOfferTaskColumns.cashOfferSendCashOfferAgreement,
  ccCashOfferTaskColumns.cashOfferReceiveCashOfferAgreement,
  ccCashOfferTaskColumns.cashOfferSendOfferSubmissionDocuments,
  ccCashOfferTaskColumns.cashOfferSendIntentToProceed,
  ccCashOfferTaskColumns.cashOfferReceiveIntentToProceed,
  ccCashOfferTaskColumns.cashOfferReceiveIrContract,
  ccCashOfferTaskColumns.cashOfferReviewIrContract,
  ccCashOfferTaskColumns.cashOfferSendAssignmentAddendumAndPoa,
  ccCashOfferTaskColumns.cashOfferReceiveAssignmentAddendumAndPoa,
  ccCashOfferTaskColumns.cashOfferReceiveIrBuyDisclosures,
  ccCashOfferTaskColumns.cashOfferAssignFundingFacility,
  ccCashOfferTaskColumns.cashOfferRequestPropertyInsurance,
  ccCashOfferTaskColumns.cashOfferFundProperty,
  ccCashOfferTaskColumns.cashOfferExecuteBuyerClosingPackage,
  ccCashOfferTaskColumns.cashOfferSendIrSellContract,
  ccCashOfferTaskColumns.cashOfferReceiveIrSellContract,
  ccCashOfferTaskColumns.cashOfferSendIrSellDisclosures,
  ccCashOfferTaskColumns.cashOfferReceiveIrSellDisclosures,
  ccCashOfferTaskColumns.cashOfferExecuteSellerClosingPackage
] as const

export type TCCCashOfferTaskColumnsColumn = typeof ccCashOfferTaskColumnsSettings
export type TCCCashOfferTaskColumnsColumnValue =
  (typeof ccCashOfferTaskColumnsSettings)[number]['value']
