import { Option } from 'classes'
import { useLookupsController } from 'controllers/Lookups'

export const useInspectionSeverity = () => {
  const [data] = useLookupsController('inspection_severities')

  const inspectionSeverityOptions = data.map(
    (lookup: TLookupType) => new Option({ name: lookup.name, value: lookup.slug })
  )

  return { inspectionSeverityOptions }
}
