import { useCallback, useMemo } from 'react'
import { useQuery } from 'hooks'
import { fetchUsersLegacy } from 'services'
import { USERS_CACHE_KEY } from '../cache'

export const useUsersController = (searchParams: Record<string, string>) => {
  const { data: users = [] } = useQuery(
    [USERS_CACHE_KEY, searchParams],
    () => fetchUsersLegacy(searchParams),
    {
      refetchInterval: Infinity,
      refetchOnWindowFocus: false
    }
  )
  const userOptions = useMemo(() => users.map?.(user => user.toOption()) || [], [users])

  const getUserName = useCallback(
    (value: any = '') => {
      if (!value) {
        return
      }

      return userOptions.find(opt => String(opt.value) === String(value))?.name
    },
    [userOptions]
  )

  return { users, userOptions, getUserName }
}

export const useRoleUsers = (role: string) =>
  useUsersController({
    'filter[_role]': role,
    'page[size]': '1000',
    autocomplete: 'true'
  })

export const useEmployeeUsers = () =>
  useUsersController({
    'filter[_employee]': 'true',
    'page[size]': '2500',
    autocomplete: 'true'
  })
