// @ts-strict

import { FormField, Section, Sections, TextArea } from 'components'
import styles from './TaskForm.module.scss'

export type TDismissTaskPayload = Record<'completionNote', string>

type TaskDismissFieldsProps = {
  onChangeAttribute: <K extends keyof TDismissTaskPayload>(
    key: K,
    value: TDismissTaskPayload[K]
  ) => void
  payload: TDismissTaskPayload
}

export const TaskDismissButtonFields = ({ payload, onChangeAttribute }: TaskDismissFieldsProps) => {
  return (
    <Sections>
      <Section>
        <FormField htmlFor="completionNote" title="Why are you dismissing this Task?" required>
          <TextArea
            id="completionNote"
            value={payload.completionNote}
            className={styles.note}
            onChange={val => onChangeAttribute('completionNote', val)}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
