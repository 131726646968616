import { environment } from 'environment'
import { postRequest } from 'utils'

type PostWebSocketTicketsResponse = {
  authURL: string
}

export const postWebSocketTickets = async (): Promise<PostWebSocketTicketsResponse> => {
  const { data } = await postRequest(environment.webSocketTicket)
  const ticket = data.ticket
  const authURL = `${environment.webSocket}?ticket=${ticket}`
  return {
    authURL
  }
}
