import { ListingsView } from 'content/Listings'
import { useListings } from 'contexts/Listings'
import { Agent } from 'models'

type AgentListingsProps = {
  agent: Agent
}

export const AgentListings = ({ agent }: AgentListingsProps) => {
  const { listings, isLoading } = useListings({ searchParams: { agentId: agent.id } })
  return <ListingsView listings={listings} isLoading={isLoading} />
}
