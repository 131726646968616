// @ts-strict
import { Button } from '@foundation/components'
import { useSyncEscrowOrderController } from 'controllers'
import { LEADS_CACHE_KEY } from 'hooks'
import { EscrowLead } from 'models'

type Props = {
  lead?: EscrowLead
}

export const QualiaSyncButton = ({ lead }: Props) => {
  const {
    syncEscrowOrder: { mutate, isLoading }
  } = useSyncEscrowOrderController({
    clearCacheKey: LEADS_CACHE_KEY
  })

  if (!lead?.escrowOfficeProviderLead?.escrowOfficeLead?.orderIdentifier) {
    return null
  }

  return (
    <Button
      isDisabled={isLoading}
      isLoading={isLoading}
      onClick={() => mutate(lead.escrowOfficeProviderLead!.escrowOfficeLead!.id!)}
    >
      Sync from Qualia
    </Button>
  )
}
