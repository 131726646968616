// @ts-strict
import { Box } from '@foundation/components'
import { LoadingText, Page, PrimaryButton, SecondaryButton } from 'components'
import {
  LeadGroupableDefinitionProvider,
  useCurrentUserContext,
  useLeadGroupableContext
} from 'contexts'
import { LeadGroupableDefinitionTypeMap } from 'models'
import styles from './LeadGroupableDefinition.module.scss'
import { LeadGroupableDefinitionCard } from './LeadGroupableDefinitionCard'
import { useLeadGroupableDefinitionUpdateRegister } from './useLeadGroupableDefinitionUpdateRegister'

export const LeadGroupableDefinitionsSection = () => {
  const { isLeadCreationAdmin } = useCurrentUserContext()

  const {
    leadGroupable,
    isLoading,
    createLeadGroupableDefinition,
    leadGroupableType,
    leadGroupableId
  } = useLeadGroupableContext()

  const {
    hasUnsavedChanges,
    registerUpdateDefinition,
    unregisterUpdateDefinition,
    handleSaveDefinitions
  } = useLeadGroupableDefinitionUpdateRegister()

  return (
    <Page title="Definitions">
      <Box className={styles.actionsSection}>
        {isLeadCreationAdmin && (
          <>
            <SecondaryButton onClick={() => createLeadGroupableDefinition.mutateAsync()}>
              Add Definition
            </SecondaryButton>
            <PrimaryButton disabled={!hasUnsavedChanges} onClick={handleSaveDefinitions}>
              Save Definitions
            </PrimaryButton>
          </>
        )}
      </Box>
      <Box className={styles.cardsContainer}>
        {isLoading && <LoadingText />}
        {!isLoading &&
          leadGroupable?.definitions?.map((leadGroupableDefinition: any, index: number) => (
            <LeadGroupableDefinitionProvider
              leadGroupableId={leadGroupableId}
              leadGroupableType={leadGroupableType}
              leadGroupableDefinitionId={leadGroupableDefinition.id}
              leadGroupableDefinitionType={LeadGroupableDefinitionTypeMap[leadGroupableType]}
            >
              <LeadGroupableDefinitionCard
                key={index}
                leadGroupableDefinition={leadGroupableDefinition}
                registerUpdateDefinition={registerUpdateDefinition(leadGroupableDefinition.id)}
                unregisterUpdateDefinition={unregisterUpdateDefinition(leadGroupableDefinition.id)}
              />
            </LeadGroupableDefinitionProvider>
          ))}
      </Box>
    </Page>
  )
}
