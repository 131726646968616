// @ts-strict
import axios from 'axios'
import { AutoComplete } from '../AutoComplete'
import { AutoCompleteProps } from '../AutoComplete'
import { createURI } from './lib/createMapboxQueryURI'
import { structureResponseForAutoComplete } from './lib/structureResponseForAutoComplete'

export type MapboxAutoCompleteProps = {
  bias?: Array<number>
  country?: string
  types?: string
} & Omit<AutoCompleteProps, 'getData'>

export const MapboxAutoComplete = ({
  bias = [],
  country = 'us',
  types = 'address',
  ...props
}: MapboxAutoCompleteProps) => {
  const getData = (search?: string) => {
    if (!search?.length) {
      return Promise.resolve([])
    }

    return axios
      .get(createURI(search, types, country, bias))
      .then(res => {
        const features = structureResponseForAutoComplete({ features: res.data.features })
        if (
          !features.length ||
          features.some(feature => feature.title?.toLowerCase().includes(search.toLowerCase()))
        ) {
          return features
        }
        const originalSearchString = { id: '0', title: search }
        return [originalSearchString, ...features]
      })
      .catch(() => [])
  }
  return <AutoComplete getData={getData} {...props} />
}
