import { byAttr } from 'utils'
import { agentFieldsSettings, TAgentFieldAttribute } from './agentFieldsSettings'
import { AgentField } from './'

export const agentFields = agentFieldsSettings.map(setting => {
  return new AgentField(setting)
})

export const getAgentField = (attr: TAgentFieldAttribute) => {
  return agentFields.find(byAttr('attr', attr))
}

export const getAgentFieldName = (attr: TAgentFieldAttribute) => {
  return getAgentField(attr)?.name
}
