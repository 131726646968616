import { Contents, Page } from 'components'
import { CreateTeamButtonForm } from 'content/Team/TeamButtonForm/CreateTeamButtonForm'
import { TeamsList } from 'content/Teams'
import { TeamsProvider } from 'contexts'
import { useDocumentTitle } from 'hooks'

const TEAM_TYPE = 'TransactionTeam'

export const TransactionTeamsPage = () => {
  useDocumentTitle('Teams')

  return (
    <TeamsProvider type={TEAM_TYPE}>
      <Page>
        <Contents title="Transaction Teams" actions={<CreateTeamButtonForm />}>
          <TeamsList />
        </Contents>
      </Page>
    </TeamsProvider>
  )
}
