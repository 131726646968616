import { useQuery } from 'hooks'
import { Agent, Lead } from 'models'
import { countTasks } from 'services'
import { SearchParamsSettings } from 'services/Tasks/getSearchParams'
import { TASKS_CACHE_KEY } from '../cache'

export type TaskAttachable = Agent | Lead

type Props = {
  attachable: TaskAttachable
}

export const attachableType = (attachable: TaskAttachable) =>
  attachable instanceof Agent ? 'Agent' : 'Lead'

const defaultParams: SearchParamsSettings = {
  completionStatus: ['active']
}

export const useAttachableTaskCountsController = ({ attachable }: Props) => {
  const activeTasks = useQuery(
    [TASKS_CACHE_KEY, attachable, 'count-active'],
    () => countTasks({ attachable, perPage: 1, ...defaultParams }),
    { refetchInterval: 60_000 }
  )

  const readyTasks = useQuery(
    [TASKS_CACHE_KEY, attachable, 'count-ready'],
    () => countTasks({ attachable, perPage: 1, completionStatus: ['ready'] }),
    { refetchInterval: 60_000 }
  )

  const pastDueTasksData = useQuery([TASKS_CACHE_KEY, attachable, 'count-past'], () =>
    countTasks({
      attachable,
      pastDue: 'true',
      perPage: 1,
      ...defaultParams
    })
  )

  const activeTasksCount = activeTasks.data?.tasksCount
  const pastDueTasksCount = pastDueTasksData.data?.tasksCount
  const readyTasksCount = readyTasks.data?.tasksCount

  return {
    activeTasksCount,
    pastDueTasksCount,
    readyTasksCount
  }
}
