// @ts-strict
import { ReactNode } from 'react'
import { Ellipsis } from 'components/Ellipsis'

type Props = {
  children?: string | ReactNode
  className?: string
  ellipsis?: boolean
  text?: string
}

export const Tooltip = ({ text, children, ellipsis, ...rest }: Props) => {
  const content = (
    <span {...rest} title={text || String(children)}>
      {children}
    </span>
  )

  if (ellipsis) {
    return <Ellipsis>{content}</Ellipsis>
  }

  return content
}
