import { formatFileSize, truncateString } from 'utils'
import { asArray } from 'utils/asArray'
import { TValidation } from './validate'

type TValidateFileSizeProps = {
  max?: number
  min?: number
}

export const validateFileSize =
  ({ min, max }: TValidateFileSizeProps): TValidation =>
  ({ value }) => {
    const files = asArray(value)

    const errors = files
      .map((file: File) => {
        if (max && file.size > max) {
          const maxMB = formatFileSize(max, 'MB')

          return `${truncateString(file.name, 20)} is too big. ${maxMB}MB maximum`
        } else if (min && file.size > min) {
          const minMB = formatFileSize(min, 'MB')

          return `${truncateString(file.name, 20)} is too small. ${minMB}MB minimum`
        }
        return ''
      })
      .filter(Boolean)

    return errors
  }
