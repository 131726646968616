// @ts-strict
import { TMortgageLeadStagesSlug } from 'lookups'
import { MortgageLead } from 'models'
import { TValidation } from 'utils'

type TProps = {
  lead: MortgageLead
}

export const validatePreviousStageUpdate =
  ({ lead }: TProps): TValidation =>
  ({ value }) => {
    const errors: string[] = []

    if (lead.isPreviousStageUpdateBlocked!(value as TMortgageLeadStagesSlug)) {
      errors.push('This lead cannot progress backwards.')
    }

    return errors
  }
