import { useQuery } from 'hooks'
import {
  fetchCapitalPartners,
  fetchMetroplexes,
  fetchServicesOpportunityStatuses,
  fetchUsStates
} from 'services'

export const useConstantsController = () => {
  const servicesOpportunityStatusesQuery = useQuery(
    ['constants', 'servicesOpportunityStatuses'],
    fetchServicesOpportunityStatuses,
    {
      staleTime: Infinity
    }
  )

  const capitalPartnersQuery = useQuery(['constants', 'capitalPartners'], fetchCapitalPartners, {
    staleTime: Infinity
  })

  const metroplexesQuery = useQuery(['constants', 'metroplexes'], fetchMetroplexes, {
    staleTime: Infinity
  })

  const usStatesQuery = useQuery(['constants', 'usStates'], fetchUsStates, {
    staleTime: Infinity
  })

  return {
    servicesOpportunityStatuses: servicesOpportunityStatusesQuery.data || [],
    capitalPartners: capitalPartnersQuery.data || [],
    metroplexes: metroplexesQuery.data || [],
    usStates: usStatesQuery.data || []
  }
}
