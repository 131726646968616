import { useCallback, useEffect, useRef } from 'react'
import { useLocalStorage, useLocation } from 'hooks'
import { daysAgoDate, presence, toPascalCase } from 'utils'

type SavedSearch = {
  search: string
  searchedAt: number
}

type SavedSearches = Record<string, SavedSearch>

export const useSavedSearches = () => {
  const { search, pathname } = useLocation()
  const [searches, setSearches] = useLocalStorage<SavedSearches>(
    'savedSearches',
    {} as SavedSearches
  )
  const searchesRef = useRef<SavedSearches>(searches)
  const recentSearchesRef = useRef<SavedSearches>({})

  const clear = useCallback(() => {
    searchesRef.current = {}
    recentSearchesRef.current = {}
    setSearches({})
  }, [setSearches])

  useEffect(() => {
    const fiveDaysAgo = daysAgoDate(5).getTime()
    searchesRef.current = searches
    recentSearchesRef.current = Object.entries(searchesRef.current)
      .filter(([, savedSearch]) => savedSearch?.searchedAt > fiveDaysAgo)
      .reduce(
        (agg, [key, value]) => ({
          ...agg,
          [key]: value
        }),
        {}
      )
  }, [searches])

  const getLastSearchUrl = useCallback(
    (key: string, defaultSearch: string = '') => {
      return `${key}${searches[toPascalCase(key)]?.search || defaultSearch}`
    },
    [searches]
  )

  useEffect(() => {
    const searchKey = toPascalCase(pathname)
    const lastSearch = searchesRef.current[toPascalCase(pathname)]?.search
    const lastSearchedAt = searchesRef.current[toPascalCase(pathname)]?.searchedAt
    const oneMinuteAgo = new Date().getTime() - 60_000
    const searchIsOld = lastSearchedAt && lastSearchedAt < oneMinuteAgo

    if (presence(search)) {
      if (lastSearch !== search || searchIsOld) {
        setSearches({
          ...recentSearchesRef.current,
          [searchKey]: { search, searchedAt: new Date().getTime() }
        })
      }
    } else {
      if (searchesRef.current[searchKey]) {
        setSearches({
          ...recentSearchesRef.current,
          [searchKey]: undefined
        })
      }
    }
  }, [pathname, search, setSearches])

  return {
    clear,
    getLastSearchUrl
  }
}
