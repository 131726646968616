// @ts-strict
import { AGENT_PARTNERSHIPS_CACHE_KEY, useQuery } from 'hooks'
import { fetchAgentPartnerships } from 'services'
import { byId } from 'utils'
import { getOptions } from '../utils'

export const useAgentPartnerships = () => {
  const { data: { agentPartnerships = [] } = {} } = useQuery([AGENT_PARTNERSHIPS_CACHE_KEY], () =>
    fetchAgentPartnerships({})
  )
  const { options: partnershipOptions } = getOptions(agentPartnerships)

  const getPartnershipName = (id: string) => agentPartnerships?.find(byId(id))?.name || ''

  return {
    partnershipOptions,
    getPartnershipName
  }
}
