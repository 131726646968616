// @ts-strict
import { ReactNode } from 'react'
import { Tooltip } from '@foundation/components'
import { If } from 'components'
import { cx } from 'utils'
import styles from './Tag.module.scss'

export type TagTypes = 'active' | 'danger' | 'info' | 'warning'

type Props = {
  as: TagTypes
  children: ReactNode
  className?: string
  size: 'large' | 'medium' | 'small' | 'xsmall'
  tooltipText?: ReactNode
}

const sizes = {
  large: styles.large,
  medium: styles.medium,
  small: styles.small,
  xsmall: styles.xsmall
}

const types = {
  active: styles.active,
  danger: styles.danger,
  info: styles.info,
  warning: styles.warning
}

export const Tag = ({ as, children, className, size, tooltipText }: Props) => {
  const sizeClass = sizes[size]
  const asClass = types[as]

  return (
    <>
      <If test={tooltipText}>
        <Tooltip content={tooltipText} placement="right">
          <div className={cx(styles.tagWrapper, sizeClass, asClass, className)}>{children}</div>
        </Tooltip>
      </If>
      <If test={!tooltipText}>
        <div className={cx(styles.tagWrapper, sizeClass, asClass, className)}>{children}</div>
      </If>
    </>
  )
}
