import { Children, ReactNode } from 'react'
import { cx } from 'utils'
import styles from './List.module.scss'

export const List = ({
  title,
  children,
  className,
  withMargin
}: {
  children: ReactNode
  className?: string
  title?: string
  withMargin?: boolean
}) => {
  return (
    <>
      {title ? <h3 className={styles.title}>{title}</h3> : null}
      <ul className={cx(className, styles.list, withMargin && styles.withMargin)}>
        {Children.map(children, child => child && <li className={cx('listItem')}>{child}</li>)}
      </ul>
    </>
  )
}
