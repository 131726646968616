import { useEffect } from 'react'
import { Contents } from 'components/Contents/index'
import { AgentDetails } from 'content/Agent/AgentDetails'
import { useAgentContext } from 'contexts/Agent'
import { useDocumentTitle } from 'hooks'

export const AgentDetailsTab = () => {
  const { agent } = useAgentContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${agent.fullName} - Agent`), [agent, setTitle])

  return (
    <Contents>
      <AgentDetails agent={agent} />
    </Contents>
  )
}
