import { ModelField } from '../ModelField'
import { TLeadFieldAttribute } from './leadFieldsSettings'

export class LeadField extends ModelField {
  attr!: TLeadFieldAttribute

  constructor(attributes: LeadField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
