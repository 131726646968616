import { ReactNode, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

export const OnScreen = ({ children, className }: { children: ReactNode; className?: string }) => {
  const [rendered, setRendered] = useState(false)

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      setRendered(true)
    }
  }, [inView])

  if (rendered) {
    return <div ref={ref}>{children}</div>
  }

  return <div ref={ref} />
}
