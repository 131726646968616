// @ts-strict
import { SettlementTeamMember, SettlementTeamMemberConstructor } from 'models'
import { EscrowOrderSettlementTeamMembersResponse } from 'schema'

type EscrowOrderSettlementTeamMemberConstructor = {
  id: string
  role?: string
  settlementTeamMember?: SettlementTeamMemberConstructor
}

export class EscrowOrderSettlementTeamMember {
  id!: string
  role?: string
  settlementTeamMember?: SettlementTeamMember

  constructor(
    escrowOrderSettlementTeamMemberAttributes: EscrowOrderSettlementTeamMemberConstructor
  ) {
    Object.assign(this, escrowOrderSettlementTeamMemberAttributes)

    if (escrowOrderSettlementTeamMemberAttributes?.settlementTeamMember) {
      this.settlementTeamMember = new SettlementTeamMember(
        escrowOrderSettlementTeamMemberAttributes?.settlementTeamMember
      )
    }
  }

  static create(
    escrowOrderSettlementTeamMember: Potential<EscrowOrderSettlementTeamMembersResponse>
  ): Potential<EscrowOrderSettlementTeamMember> {
    if (!escrowOrderSettlementTeamMember) {
      return
    }

    return new EscrowOrderSettlementTeamMember({
      ...escrowOrderSettlementTeamMember
    })
  }
}
