import { Activity } from 'models/Activity'
import { getAgentsTerritoriesUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchAgentActivities = async (agentId: string) => {
  const url = getAgentsTerritoriesUrl({ id: agentId, action: 'activity-feed' })
  const response = await getRequest(url)
  return {
    activities: await deserializeAsync(response.data, {
      transform: Activity.create
    }),
    activitiesCount: response.data.length
  }
}
