// @ts-strict
import { Option } from 'classes'
import { cx } from 'utils'
import { RadioButtonField } from '../RadioButtonField'
import styles from './RadioButtons.module.scss'

type TProps<T extends string> = {
  className?: string
  onChange: (value: Potential<T>) => void
  options: Option<T>[]
  value: Potential<T>
}

export const RadioButtons = <T extends string>({
  options,
  value,
  onChange,
  className
}: TProps<T>) => {
  return (
    <div className={cx(styles.radioButtons, className)}>
      {options.map(option => {
        const { value: optionValue, name: optionName } = option
        return (
          <RadioButtonField
            key={optionValue}
            title={optionName}
            name={optionName}
            label={optionName || 'optionName'}
            disabled={option.disabled}
            className={styles.radioButtonField}
            checked={value === optionValue}
            onChange={() => onChange(optionValue)}
          />
        )
      })}
    </div>
  )
}
