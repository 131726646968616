import { AttachLeadOrderVariables } from 'contexts/types'
import { getOrdersUrl } from 'services/getUrl'
import { postRequest } from 'utils'

export const postAttachLeadOrder = ({
  orderId,
  leadId
}: AttachLeadOrderVariables): Promise<any> => {
  return postRequest(getOrdersUrl({ id: orderId, action: `leads/${leadId}/order-leads` }), {})
}
