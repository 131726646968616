import { Activity } from 'models'
import { CashOfferLeadStageUpdateResponse } from 'schema'
import { d } from 'utils'

export class CashOfferLeadStageUpdate {
  id: string
  notes?: string
  oldStage?: string
  newStage?: string
  createdAt?: string
  updatedBy?: string
  ae?: {
    name: string
  }

  constructor(cashOfferLeadStageUpdateAttributes: Omit<CashOfferLeadStageUpdate, 'toActivity'>) {
    Object.assign(this, cashOfferLeadStageUpdateAttributes)
  }

  static create(
    cashOfferLeadStageUpdateResponse: Potential<CashOfferLeadStageUpdateResponse>
  ): Potential<CashOfferLeadStageUpdate> {
    if (!cashOfferLeadStageUpdateResponse) {
      return
    }

    return new CashOfferLeadStageUpdate(cashOfferLeadStageUpdateResponse)
  }

  toActivity() {
    return new Activity({
      id: this.createdAt,
      title: `Stage Updated by ${d(this.updatedBy)} ${this.updatedBy === 'ae' ? this.ae.name : ''}`,
      body: this.notes,
      transitions: [d(this.oldStage), d(this.newStage)],
      occurredAt: this.createdAt
    })
  }
}
