// @ts-strict

import { ButtonForm, useButtonForm } from 'components'
import { useTeamContext } from 'contexts'
import { TransactionTeam } from 'models'
import { TeamFormFields } from './TeamFormFields'
import { formValidations, TTeamFormPayload } from './utils'

export const EditTeamButtonForm = ({ team }: { team: TransactionTeam }) => {
  const { editTeam } = useTeamContext()
  const initialPayload: TTeamFormPayload = {
    description: team.description,
    enabled: team.enabled,
    name: team.name
  }

  const title =
    team.type === 'SettlementAgencyTeam' ? 'Edit Settlement Agency Team' : 'Edit Transaction Team'

  const { formProps, fieldsProps } = useButtonForm<TTeamFormPayload>({
    initialPayload,
    mutation: editTeam,
    formValidations
  })

  return (
    <ButtonForm title={title} buttonText="Edit Team" {...formProps}>
      <TeamFormFields {...fieldsProps} />
    </ButtonForm>
  )
}
