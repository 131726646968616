import { ModelField } from '../ModelField'
import { TEscrowOfficeLeadFieldAttribute } from './escrowOfficeLeadFieldsSettings'

export class EscrowOfficeLeadField extends ModelField {
  attr!: TEscrowOfficeLeadFieldAttribute

  constructor(attributes: EscrowOfficeLeadField) {
    super(attributes)
    Object.assign(this, attributes)
  }
}
