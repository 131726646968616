import { Link, useMatch } from 'react-router-dom'
import { Agent } from 'models'

export const AgentLink = ({
  agent,
  linkText,
  className
}: {
  agent: Agent
  className?: string
  linkText?: string
}) => {
  const agentMatch = useMatch(`/agents/${agent.id}/*`)
  if (!agentMatch) {
    return (
      <Link className={className} to={`/agents/${agent.id}`}>
        {linkText || agent.fullName}
      </Link>
    )
  }
  return <span className={className}>{agent.fullName}</span>
}
