import styled from 'styled-components'
import { css } from 'styled-components/macro'

export const PopoverContentContainer = styled.div`
  ${({ theme }) => css`
    width: 300px;
    padding: 0.75rem;

    .tab {
      padding: ${theme.space.$2};
    }
  `}
`
