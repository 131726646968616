// @ts-strict

import { Option } from 'classes'
import { TUsStateCode } from 'lookups'
import { Metroplex } from 'models'
import { sortMetroplexes } from 'models/Metroplex/util'

const getStateCodesFromMetroplex = (metroplexes: Metroplex[]) => [
  ...new Set(metroplexes.map(mpx => mpx.stateCode))
]

export const getAssignedMetroplexOptions = (metroplexes: Metroplex[]): Option<string>[] => {
  const stateCodes = getStateCodesFromMetroplex(metroplexes) as TUsStateCode[]

  if (!metroplexes.length || !stateCodes.length) {
    return []
  }

  const mpxOptions: Option[] = []
  const mpxStates: Record<string, Metroplex[]> = {}

  metroplexes.forEach(mpx => {
    const code = mpx.stateCode || ''
    if (mpxStates[code]) {
      mpxStates[code] = [...mpxStates[code], mpx]
    } else {
      mpxStates[code] = [mpx]
    }
  })

  Object.keys(mpxStates)
    .sort((a, b) => a.localeCompare(b))
    .forEach(stateCode => {
      mpxOptions.push(
        ...sortMetroplexes(mpxStates[stateCode]).map(mpx => ({
          name: `${stateCode} - ${mpx.name}`,
          value: mpx.id
        }))
      )
    }, [])

  return mpxOptions
}
