import { SearchParams } from '@types'
import { removeAttributesWithNullValues } from 'utils'

const sortParam = {
  createdAt: 'created_at',
  name: 'name'
}

type SearchParamsSettings = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'sortBy' | 'sortDir'
>

export type ExternalLinksParams = Partial<{
  attachableId: string
  attachableType: 'Lead'
}> &
  SearchParamsSettings

export const getSearchParams = ({
  page,
  perPage,
  sortBy,
  sortDir,
  attachableId,
  attachableType
}: ExternalLinksParams) => {
  const getSort = () => {
    const dir = sortDir === 'desc' ? '-' : ''

    if (sortBy) {
      return `${dir}${sortParam[sortBy]}`
    }

    return sortParam['Created']
  }

  return removeAttributesWithNullValues({
    'page[number]': page,
    'page[size]': perPage,
    sort: getSort(),
    'filter[_attachable][id]': attachableId,
    'filter[_attachable][type]': attachableType
  })
}
