import { BuyerLeadInterface } from '../BuyerLead'
import { CashOfferLeadInterface } from '../CashOfferLead'
import { EscrowLeadInterface } from '../EscrowLead'
import { HLSimpleSaleLeadInterface } from '../HLSimpleSaleLead'
import { MortgageLeadInterface } from '../MortgageLead'
import { SellerLeadInterface } from '../SellerLead'
import { TradeInLeadInterface } from '../TradeInLead'
import * as utils from './utils'

export type LeadInterface =
  | BuyerLeadInterface
  | SellerLeadInterface
  | TradeInLeadInterface
  | CashOfferLeadInterface
  | HLSimpleSaleLeadInterface
  | EscrowLeadInterface
  | MortgageLeadInterface

export type LeadInterfaceUserType =
  | 'buyer'
  | 'seller'
  | 'cc_trade_in'
  | 'cc_cash_offer'
  | 'cc_hl_simple_sale'
  | 'renter'
  | 'agent'
  | 'unknown'
  | 'mortgage'
  | 'escrow'

export const LeadModel = {
  ...utils
}
