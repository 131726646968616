export const investorLeadStagesSettings = [
  { value: 'closed_paid', name: 'Closed - Paid', active: true },
  { value: 'closed_booked', name: 'Closed - Booked', active: true },
  { value: 'closed', name: 'Closed', active: true },
  { value: 'in_escrow', name: 'In Escrow', active: true },
  { value: 'listing', name: 'Listing', active: true },
  { value: 'coming_soon', name: 'Coming Soon', active: true },
  { value: 'met_in_person', name: 'Met In Person', active: true },
  { value: 'meeting_scheduled', name: 'Meeting Scheduled', active: true },
  { value: 'client_connected', name: 'Client Called Agent', active: true },
  { value: 'connected', name: 'Connected', active: true },
  { value: 'agent_left_vm', name: 'Agent Left VM/Email', active: true },
  { value: 'client_left_vm', name: 'Client Left Voicemail', active: true },
  { value: 'agent_unresponsive', name: 'Agent Unresponsive' },
  { value: 'client_unresponsive', name: 'Client Unresponsive' },
  { value: 'failed', name: 'Failed', active: true },
  { value: 'reassigned', name: 'Reassigned' },
  { value: 'referral_declined', name: 'Referral Declined' },
  { value: 'introduced', name: 'Introduced to Agent', active: true },
  { value: 'auto_intro_match', name: 'Auto Intro Match' },
  { value: 'client_contact', name: 'Client Contact' },
  { value: 'pre_salesforce', name: 'Pre-Salesforce' },
  { value: 'no_response', name: 'No Response' },
  { value: 'awaiting_response', name: 'Awaiting Response', active: true },
  { value: 'call_in_progress', name: 'Calling' },
  { value: 'investor_qualified', name: 'Investor Qualified' },
  { value: 'intro_request', name: 'Intro Request' },
  { value: 'client_cancelled', name: 'Client Cancelled' },
  { value: 'client_added_match', name: 'Client Added Match' },
  { value: 'warm_transfer_match', name: 'Warm Transfer Match' },
  { value: 'quiz_match', name: 'Quiz Match' },
  { value: 'removed', name: 'Removed' }
] as const
