import { Icon } from 'components/Icon'
import { useDialerContext } from 'contexts/Dialer'
import { cx } from 'utils'
import styles from './Dialer.module.scss'

export const DialerCallButton = () => {
  const { isOnCall, call, endCall } = useDialerContext()

  const handleCall = () => call()

  if (isOnCall) {
    return (
      <button type="button" className={cx(styles.callButton, styles.endCall)} onClick={endCall}>
        <Icon.EndCall />
      </button>
    )
  }

  return (
    <button type="button" className={cx(styles.callButton, styles.call)} onClick={handleCall}>
      <Icon.Call />
    </button>
  )
}
