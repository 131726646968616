// @ts-strict
import { SearchFilter } from 'lookups'
import { SearchStatusFilter } from './SearchStatusFilter'

export const SearchStatusSort = ({
  sortBy,
  sortDir,
  name
}: {
  name?: string
  sortBy?: string
  sortDir?: string
}) => {
  const filter = new SearchFilter({
    name: 'Sorting by',
    slug: 'sortBy',
    getDisplayValue: () => `${name || sortBy} ${sortDir}`
  })

  return sortBy ? <SearchStatusFilter filter={filter} value={sortBy} /> : null
}
