import { useState } from 'react'
import { FilterBar, ListSelect } from 'components'
import { ListingsMap, ListingsTable } from 'content/Listings'
import { Listing } from 'models'

type ListingsViewProps = {
  isLoading: boolean
  listings: Listing[]
  pageCount?: number
}

export const ListingsView = ({ listings, isLoading }: ListingsViewProps) => {
  const [format, setFormat] = useState<'Table' | 'Map'>('Table')

  const formats = {
    Table: <ListingsTable listings={listings} isLoading={isLoading} />,
    Map: <ListingsMap listings={listings} />
  }

  return (
    <>
      {listings && listings.length ? (
        <FilterBar>
          <ListSelect
            options={[{ name: 'Table' }, { name: 'Map' }]}
            value={format}
            onChange={setFormat}
          />
        </FilterBar>
      ) : null}
      {formats[format]}
    </>
  )
}
