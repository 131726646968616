import { Option } from 'classes'
import { bbysStageOptions } from 'lookups'

export const getBBYSStage = (stage?: string) =>
  bbysStageOptions.find(({ value }) => value === stage)?.name || '---'

export const getNextBBYSStage = (currentStageValue?: string): Option => {
  const currentStageIndex = bbysStageOptions.findIndex(stage => stage.value === currentStageValue)

  if (currentStageIndex < 0) {
    return bbysStageOptions[0]
  }

  if (
    currentStageValue === 'dr_closed' ||
    currentStageValue === 'failed' ||
    currentStageValue === 'nurture'
  ) {
    return bbysStageOptions[currentStageIndex]
  }

  return bbysStageOptions[currentStageIndex + 1]
}
