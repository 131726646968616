// @ts-strict

import { css, styled } from '@foundation/themes/styles'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    padding: ${theme.space.$4} 0;
  `}
`
