import { useCallback, useRef, useState } from 'react'
import {
  arrow,
  flip,
  offset,
  Placement,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions
} from '@floating-ui/react'

type UseTooltipProps = {
  keepOpenOnHover?: boolean
  preferredPlacement?: Placement
  tooltipTestId?: string
  triggerTestId?: string
}
export const useTooltip = ({
  preferredPlacement = 'bottom',
  keepOpenOnHover = false
}: UseTooltipProps) => {
  const [isOpen, setOpen] = useState(false)
  const arrowRef = useRef<HTMLElement | null>(null)

  const {
    update,
    x,
    y,
    reference: triggerRef,
    floating: tooltipRef,
    strategy,
    context,
    placement,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} }
  } = useFloating({
    placement: preferredPlacement,
    open: isOpen,
    onOpenChange: setOpen,
    middleware: [
      offset({ mainAxis: 8 }),
      flip(),
      shift({ padding: 6 }),
      arrow({ element: arrowRef })
    ]
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      handleClose: keepOpenOnHover ? safePolygon() : undefined
    }),
    useDismiss(context)
  ])

  const arrowCallback = useCallback(
    node => {
      arrowRef.current = node
      update()
    },
    [update]
  )

  const triggerProps = {
    ...getReferenceProps(),
    ref: triggerRef
  }

  const tooltipProps = {
    ref: tooltipRef,
    style: {
      position: strategy,
      top: y ?? 0,
      left: x ?? 0,
      width: 'max-content'
    },
    ...getFloatingProps()
  }

  const staticSide: any = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right'
  }[placement.split('-')[0]]

  const tooltipArrowProps = {
    ref: arrowCallback,
    x: arrowX,
    y: arrowY,
    side: staticSide
  }

  return {
    isOpen,
    triggerProps,
    tooltipProps,
    tooltipArrowProps,
    placement
  }
}
