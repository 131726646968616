// @ts-strict
import { useState } from 'react'
import {
  CheckBoxArray,
  Column,
  Columns,
  EmployeesAutoComplete,
  MenuSection,
  Section,
  Select
} from 'components'
import { useEmployeeUsers, useNewFilters } from 'hooks'
import { useUsStates } from 'lookups'
import { TMarketplaceProgramSlug, useMarketplacePrograms } from 'lookups'
import { ListFilters } from 'templates'
import styles from './AgentAeAreasListFilters.module.scss'

type Params = {
  aseId: string
  asmId: string
  marketplacePrograms: TMarketplaceProgramSlug[]
  usStateId: string
}

export const AgentAeAreasListFilters = () => {
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const { newParams, set } = useNewFilters<Params>()
  const { usStateOptions } = useUsStates()
  const { getUserName } = useEmployeeUsers()
  const { marketplaceProgramsOptions } = useMarketplacePrograms()

  const handleMarketplaceProgramsChange = (val: string[]) => {
    set('marketplacePrograms', val as TMarketplaceProgramSlug[])
  }

  const asmName = getUserName(newParams.asmId)
  const aseName = getUserName(newParams.aseId)

  return (
    <ListFilters.Root isOpen={isOpenPopover} onOpenChange={setIsOpenPopover}>
      <ListFilters.Trigger>
        <ListFilters.TriggerButton iconRight="FilterAltOutlined">Filters</ListFilters.TriggerButton>
      </ListFilters.Trigger>
      <ListFilters.Content>
        <Section className={styles.section}>
          <Columns className={styles.hasAutocomplete}>
            <Column className={styles.hasAutocomplete}>
              <MenuSection title="ASM">
                <EmployeesAutoComplete
                  value={asmName}
                  onSelect={val => set('asmId', val?.id)}
                  onClear={() => set('asmId', '')}
                />
              </MenuSection>
              <MenuSection title="ASE">
                <EmployeesAutoComplete
                  value={aseName}
                  onSelect={val => set('aseId', val?.id)}
                  onClear={() => set('aseId', '')}
                />
              </MenuSection>
            </Column>
            <Column className={styles.hasAutocomplete}>
              <MenuSection title="State">
                <Select
                  value={newParams.usStateId}
                  options={usStateOptions}
                  onChange={val => set('usStateId', val)}
                  placeholder="Select a State..."
                  addBlank
                />
              </MenuSection>
              <MenuSection title="Programs">
                <CheckBoxArray
                  options={marketplaceProgramsOptions}
                  value={newParams.marketplacePrograms}
                  onChange={handleMarketplaceProgramsChange}
                />
              </MenuSection>
            </Column>
          </Columns>

          <ListFilters.Footer>
            <ListFilters.ApplyButton newParams={newParams} onApplyToggle={setIsOpenPopover} />
          </ListFilters.Footer>
        </Section>
      </ListFilters.Content>
    </ListFilters.Root>
  )
}
