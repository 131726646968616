// @ts-strict
import React, { ChangeEvent } from 'react'
import { Option } from 'classes'
import { CheckBoxField } from 'components/Form/CheckBoxField'
import { asArray } from 'utils'
import styles from './CheckBoxArray.module.scss'

type Props = {
  options: Option[]
  value: string | string[] | undefined
} & { onChange: (items: string[]) => void }

export const CheckBoxArray = ({ options, value, onChange }: Props) => {
  const valueArray = asArray(value)

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, selectedValue: string) => {
    if (e.currentTarget.checked) {
      onChange([...valueArray, selectedValue])
    } else {
      const newValues = valueArray.filter(val => val !== selectedValue)
      onChange(newValues)
    }
  }

  return (
    <div className={styles.checkBoxArray}>
      {options.map(option => {
        const [optionValue, optionName] =
          typeof option === 'object'
            ? [String(option.value), option.name]
            : [String(option), String(option)]
        return (
          <CheckBoxField
            key={optionValue}
            title={optionName}
            name={optionName}
            description={option.description}
            disabled={option.disabled}
            className={styles.checkBox}
            checked={valueArray.includes(optionValue)}
            onChange={e => handleOnChange(e, optionValue)}
          />
        )
      })}
    </div>
  )
}
