import { Table, TableResults } from 'components'
import { Transaction } from 'models'
import styles from './TransactionsTable.module.scss'
import { TransactionsTableHeader } from './TransactionsTableHeader'
import { TransactionsTableRow } from './TransactionsTableRow'

export const TransactionsTable = ({
  transactions,
  isLoading
}: {
  isLoading: boolean
  transactions: Transaction[]
}) => {
  return (
    <>
      <Table className={styles.table}>
        <TransactionsTableHeader />
        <TableResults type="Transactions" isLoading={isLoading} colSpan={6}>
          {transactions?.map(transaction => (
            <TransactionsTableRow key={transaction.id} transaction={transaction} />
          ))}
        </TableResults>
      </Table>
    </>
  )
}
