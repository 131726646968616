import { toggleDirection } from 'utils'

export const sortDirection = (
  currentSortBy: string,
  currentSortDirection: string | SortDirection,
  sortBy: string,
  defaultDir: SortDirection
) => {
  return currentSortBy === sortBy ? toggleDirection(currentSortDirection) : defaultDir
}
