// @ts-strict
import {
  FormField,
  InlineEditIntegerInput,
  InlineEditUnder100Input,
  Section,
  Sections
} from 'components'
import { useHLSimpleSaleLeadController } from 'controllers'
import { dataOrDash } from 'utils'
import { HLSSLeadDetailsProps } from '../HLSSLeadDetails'
import { getFieldValues } from '../utils'

type TProps = Pick<HLSSLeadDetailsProps, 'lead'>

export const DRPropertyAssessmentSection = ({ lead }: TProps) => {
  const hlSimpleSaleLead = lead.hlSimpleSaleLead!
  const { updateHLSimpleSaleLead } = useHLSimpleSaleLeadController(hlSimpleSaleLead!.id)
  const { getFieldValue } = getFieldValues(hlSimpleSaleLead)

  return (
    <Sections title="DR Property Assessment">
      <Section>
        <FormField title="Estimated Days of Work" inline>
          <InlineEditUnder100Input
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpEstimatedDaysOfWork: val })}
            value={getFieldValue('dpEstimatedDaysOfWork')}
          />
        </FormField>
        <FormField title="Estimated List-to-Pending" inline>
          <InlineEditUnder100Input
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpEstimatedListToPending: val })}
            value={getFieldValue('dpEstimatedListToPending')}
          />
        </FormField>
        <FormField title="Days Seller in Possession" inline>
          <InlineEditIntegerInput
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpDaysSellerInPossession: val })}
            value={getFieldValue('dpDaysSellerInPossession')}
          />
        </FormField>
      </Section>
      <Section>
        <FormField title="Estimated Sale Escrow" inline>
          <InlineEditUnder100Input
            onBlur={val => updateHLSimpleSaleLead.mutate({ dpEstimatedSaleEscrow: val })}
            value={getFieldValue('dpEstimatedSaleEscrow')}
          />
        </FormField>
        <FormField title="Total Estimated Days of Ownership" inline>
          {dataOrDash(hlSimpleSaleLead?.dpTotalEstimatedDaysOfOwnership)}
        </FormField>
      </Section>
    </Sections>
  )
}
