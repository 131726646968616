// @ts-strict
import { Table } from 'components'
import { TableResults } from 'components/Table/TableResults'
import styles from 'content/Leads/LeadsTable/LeadsTableCells/LeadsTableCells.module.scss'
import { LeadsTableHeaderCells } from 'content/Leads/LeadsTable/LeadsTableHeaderCells'
import { useListContext } from 'contexts'
import { MortgageLead } from 'models'
import { cx } from 'utils'
import { MortgageLeadTableRow } from '.'

type TMortgageLeadsTable = {
  className?: string
  isLoading: boolean
  items: Potential<MortgageLead[]>
}

export const MortgageLeadsTable = ({ items, isLoading, className }: TMortgageLeadsTable) => {
  const { colSpan } = useListContext()

  return (
    <Table className={cx(styles.table, className)} alternatingColumns>
      <LeadsTableHeaderCells />
      <TableResults type="Leads" colSpan={colSpan} isLoading={isLoading}>
        {items?.map(lead => (
          <MortgageLeadTableRow key={lead.id} lead={lead} />
        ))}
      </TableResults>
    </Table>
  )
}
