import { ActivityFeedInterface } from 'models/v2/ActivityFeed'
import { AgentModel } from 'models/v2/Agent'
import { dataOrDash } from 'utils'
import { ActivityAgentEventRow } from '../ActivityEventRows/ActivityAgentEventRow'
import { ActivityEventRow } from '../ActivityEventRows/ActivityEventRow'
import { ActivityLeadEventRow } from '../ActivityEventRows/ActivityLeadEventRow'
import formatters from './formatters'

type Props = {
  activityFeed: ActivityFeedInterface
}

export const agentEventSlugs = [
  'signed_agreement',
  'agent_enrollment_update',
  'agent_onboarding_progress_update',
  'agent_updated',
  'agent_left_note'
]

export const leadEventSlugs = [
  'cash_offer_max_affordability_update',
  'lead_stage_update',
  'lead_cloned',
  'lead_updated',
  'economic_model_update',
  'warm_transfer_eligible',
  'closing_docs_sent',
  'meeting_scheduled',
  'meeting_canceled',
  'employee_left_note',
  'lead_claimed',
  'hlcs_milestone_updated',
  'lead_reviewed',
  'lead_created',
  'lead_product_eligibility_check',
  'lead_sales_action',
  'lead_sent_to_partner',
  'phone_call_initiated',
  'phone_call_status_updated'
]

export const dynamicEventSlugs = ['lead_sub_stage_update']

const FallBackComponent = ({
  activityFeed,
  formatter = data => data,
  ...rest
}: {
  activityFeed: ActivityFeedInterface
  formatter?: (data: any) => any
}) => {
  const agentFullName = activityFeed?.agent && AgentModel.getFullName(activityFeed.agent)
  const title = dataOrDash(agentFullName || activityFeed.lead?.name)

  return (
    <ActivityEventRow
      {...rest}
      title={title}
      date={activityFeed.occurredAt}
      description={activityFeed.title}
      meta={activityFeed.meta}
      formatter={formatter}
    />
  )
}

// This function and EventRows will be refactored to be more dynamic
const mapEventComponent = (activityFeed: ActivityFeedInterface) => {
  if (agentEventSlugs.includes(activityFeed.name)) {
    return ActivityAgentEventRow
  }

  if (leadEventSlugs.includes(activityFeed.name)) {
    return ActivityLeadEventRow
  }

  if (dynamicEventSlugs.includes(activityFeed.name)) {
    if (activityFeed.agent) {
      return ActivityAgentEventRow
    }

    if (activityFeed.lead) {
      return ActivityLeadEventRow
    }
  }

  return FallBackComponent
}

export const ActivityListRow = ({ activityFeed, ...rest }: Props) => {
  const Component = mapEventComponent(activityFeed)
  return Component ? (
    <Component activityFeed={activityFeed} {...rest} formatter={formatters[activityFeed.name]} />
  ) : null
}
