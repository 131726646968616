// @ts-strict
import { useCallback, useMemo } from 'react'
import { activeServicesOpportunitiesStatusesId } from '@constants'
import { Option } from 'classes'
import { CONSTANTS_CACHE_KEY, SERV_OPORTUNITY_STATUS_CACHE_KEY, useMutation, useQuery } from 'hooks'
import { putServicesOpportunity } from 'hooks/useServicesOpportunity/services'
import { ServicesOpportunityStatus } from 'models'
import { fetchServicesOpportunityStatuses } from 'services'
import { byId } from 'utils'
import { TGetOptionNameValue } from '../utils'

export const useServicesOpportunities = () => {
  const { data: servicesOpportunityStatuses = [] } = useQuery(
    [CONSTANTS_CACHE_KEY, SERV_OPORTUNITY_STATUS_CACHE_KEY],
    fetchServicesOpportunityStatuses,
    {
      staleTime: Infinity
    }
  )

  const updateServicesOpportunity = useMutation(putServicesOpportunity)

  const activeServicesOpportunitiesStatuses = useMemo(
    () =>
      servicesOpportunityStatuses.filter(st =>
        activeServicesOpportunitiesStatusesId.includes(st.id)
      ),
    [servicesOpportunityStatuses]
  )
  const getOpportunityStatusName = useCallback(
    (value: TGetOptionNameValue) => {
      if (!value) {
        return
      }
      return activeServicesOpportunitiesStatuses.find(st => String(st.id) === String(value))?.name
    },
    [activeServicesOpportunitiesStatuses]
  )

  const getOpportunityStatusFromId = (id: string): Potential<ServicesOpportunityStatus> => {
    return servicesOpportunityStatuses.find(byId(id))
  }

  const getOpportunityNameFromId = (id: string) => getOpportunityStatusFromId(id)?.name

  const activeServicesOpportunitiesStatusOptions = activeServicesOpportunitiesStatuses.map(
    status => new Option({ name: status.name, value: status.id })
  )

  return {
    servicesOpportunityStatuses,
    activeServicesOpportunitiesStatuses,
    activeServicesOpportunitiesStatusOptions,
    getOpportunityStatusName,
    updateServicesOpportunity,
    getOpportunityStatusFromId,
    getOpportunityNameFromId
  }
}
