// @ts-strict

import { useLocation } from 'react-use'
import { Contents, ListSearchHeader, Page, PaginationParams, ViewsMenu } from 'components'
import { MortgageLeadListFilters } from 'content/MortgageLead'
import { MortgageLeadsTable } from 'content/MortgageLeads'
import { CreateOrderButtonForm } from 'content/Order'
import { ListProvider } from 'contexts'
import {
  MORTGAGE_LEADS_CACHE_KEY,
  useCreateOrderController,
  useMortgageLeadsController
} from 'controllers'
import { useDocumentTitle } from 'hooks'
import { mortgageLeadsListColumns, useMortgageLeadSearchFilter } from 'lookups'
import { queryStringToObject } from 'utils'

const PAGE_TITLE = 'Mortgage Leads'

const actions = (
  <>
    <MortgageLeadListFilters />
    <ViewsMenu />
  </>
)

const useMortgageLeadsPage = () => {
  useDocumentTitle(PAGE_TITLE)
  const { search } = useLocation()
  const searchParams = queryStringToObject(search)

  const { mortgageLeadsQuery } = useMortgageLeadsController({ searchParams })

  return {
    mortgageLeadsQuery
  }
}

export const MortgageLeadsPage = () => {
  const { mortgageLeadsQuery } = useMortgageLeadsPage()
  const { data, isLoading } = mortgageLeadsQuery
  const { items } = data || {}
  const mortgageLeadFilters = useMortgageLeadSearchFilter()
  const { createOrder, handleOrderCreated } = useCreateOrderController({
    clearCacheKey: MORTGAGE_LEADS_CACHE_KEY
  })

  const action = <CreateOrderButtonForm mutation={createOrder} onSubmit={handleOrderCreated} />

  return (
    <ListProvider columns={mortgageLeadsListColumns} filters={mortgageLeadFilters}>
      <Page>
        <Contents title={PAGE_TITLE} actions={action}>
          <ListSearchHeader
            counterText="Lead"
            totalCount={mortgageLeadsQuery.data?.totalCount}
            isLoading={mortgageLeadsQuery.isLoading}
            filter={actions}
          />
          <MortgageLeadsTable items={items} isLoading={isLoading} />
          <PaginationParams totalPages={mortgageLeadsQuery.data?.pageCount} />
        </Contents>
      </Page>
    </ListProvider>
  )
}
