import { Icon } from 'components/Icon'
import { Portal } from 'components/Portal'
import { useDialerContext } from 'contexts/Dialer'
import styles from './Dialer.module.scss'
import { DialerExpanded } from './DialerExpanded'
import { DialerMinified } from './DialerMinified'

export const Dialer = () => {
  const { isOnCall, isVisible, toggleVisible, isExpanded } = useDialerContext()

  return isVisible ? (
    <Portal>
      <div className={styles.dialerContainer}>
        <button
          disabled={isOnCall}
          type="button"
          className={styles.closeButton}
          onClick={toggleVisible}
        >
          <Icon.Close />
        </button>
        {isExpanded ? <DialerExpanded /> : <DialerMinified />}
      </div>
    </Portal>
  ) : null
}
