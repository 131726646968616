import { Box } from '../Box'
import * as S from './styles'
import { SwitchProps } from './types'

export const Switch = ({ isOn, onClick, disabled, label, size = 'md', type }: SwitchProps) => {
  const onClickFn = () => {
    if (disabled) {
      return
    }

    onClick(!isOn)
  }

  return (
    <Box as="span">
      {label && (
        <S.Label data-testid="switch-label" $size={size}>
          {label}
        </S.Label>
      )}
      <S.Track
        type={type}
        data-testid="switch"
        isOn={isOn}
        onClick={onClickFn}
        disabled={disabled}
        size={size}
      >
        <S.Thumb data-testid="switch-thumb" $size={size} $isOn={isOn} />
      </S.Track>
    </Box>
  )
}
