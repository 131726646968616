const INSPECTION_SETTINGS = {
  type: {
    name: 'Inspection Type',
    value: 'type.name',
    sort: true,
    isDefault: true,
    columnSize: 'lg'
  },
  businessName: {
    name: 'Business Name',
    value: 'businessName',
    sort: true,
    isDefault: true
  },
  scheduledAt: {
    name: 'Inspection Date',
    value: 'scheduledAt',
    sort: true,
    isDefault: true,
    columnSize: 'lg'
  },
  price: {
    name: 'Price',
    value: 'price',
    sort: true,
    isDefault: true,
    columnSize: 'md'
  },
  status: {
    name: 'Status',
    value: 'status.name',
    isDefault: true,
    sort: true,
    columnSize: 'md'
  },
  updatedAt: {
    name: 'Last Updated',
    value: 'updatedAt',
    sort: true,
    isDefault: true,
    columnSize: 'md'
  },
  externalId: {
    name: 'External ID',
    value: 'externalId',
    sort: true,
    isDefault: true,
    columnSize: 'md'
  },
  reportURL: {
    name: 'Report URL',
    value: 'reportURL',
    isDefault: true,
    columnSize: 'md'
  },
  reportFile: {
    name: 'PDF',
    value: 'reportFile',
    isDefault: true,
    columnSize: 'md'
  },
  category: {
    name: 'Category',
    value: 'category',
    sort: true,
    isDefault: true,
    columnSize: 'md'
  },
  orderId: {
    name: 'Order ID',
    value: 'orderId',
    sort: true,
    isDefault: true,
    columnSize: 'md'
  }
} as const

export const INSPECTION_SORT_SETTINGS = Object.keys(INSPECTION_SETTINGS)
  .map(key => INSPECTION_SETTINGS[key])
  .filter(({ sort }) => sort)

export const INSPECTION_COLUMN_SETTINGS = [
  INSPECTION_SETTINGS.type,
  INSPECTION_SETTINGS.businessName,
  INSPECTION_SETTINGS.scheduledAt,
  INSPECTION_SETTINGS.price,
  {
    ...INSPECTION_SETTINGS.status,
    attachedColumn: INSPECTION_SETTINGS.updatedAt
  },
  {
    ...INSPECTION_SETTINGS.externalId,
    attachedColumn: INSPECTION_SETTINGS.orderId
  },
  { ...INSPECTION_SETTINGS.reportURL, attachedColumn: INSPECTION_SETTINGS.reportFile }
]
