// This function groups a number up to 3 chars separated by ","
// input: 99999999999 output: 999,999,999,99

export function formatNumber(number: number): string {
  const numberString = number.toString()

  const groups = []
  for (let i = numberString.length; i > 0; i -= 3) {
    groups.unshift(numberString.slice(Math.max(0, i - 3), i))
  }

  return groups.join(',')
}
