import { LeadSummary } from 'models'
import { getHapiRequestParams } from 'schema'
import { LeadSummarySchema } from 'schema/LeadSummarySchema'
import { getLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const LEAD_SUMMARY_RESPONSE_SHAPE: LeadSummarySchema = {
  lead: [
    'email',
    'name',
    { phoneNumbers: ['number', 'phoneType'] },
    { leadViewers: ['id', 'firstName', 'lastName'] }
  ]
}

export const fetchLeadSummary = async (id: string): Promise<LeadSummary> => {
  const searchParams = getHapiRequestParams(LEAD_SUMMARY_RESPONSE_SHAPE)
  const url = getLeadsUrl({ id, searchParams })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, { transform: LeadSummary.create })
}
