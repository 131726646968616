import { ReleaseNote } from 'classes'

type ReleaseNoteData = {
  date: string
  description: string
  title: string
}

const releaseNoteData: ReleaseNoteData[] = [
  {
    title: 'Property Photos',
    date: '2022-05-11',
    description: `
The Sales App now supports photo upload and review!

Features:
  - Photos can be manually uploaded to the Photos tab of the Documents section
  - All major photo formats, including HEIC/iPhone, are supported
  - Photos can be viewed in a grid or zoomed in
  - Cycle through the photos with keyboard arrow key
  - Photos can be downloaded or deleted

Demo: https://drive.google.com/file/d/18cSrh9IZxryoTe_oUY6PHgJxjS8m-jLA/view?usp=sharing
`
  },
  {
    title: 'User Admin Updates',
    date: '2022-04-28',
    description: `Admin users can now add/edit/remove users integrations (calendly, qualia, nylas) and can edit user phone numbers and email within the app.`
  },
  {
    title: 'HouseCanary into the USA',
    date: '2022-04-15',
    description: `
We have ported over the ability to find and dismiss HouseCanary leads from the old Sales app into the USA. To find the HouseCanaries, head to the Orders page, and filter for Needs Listing Review to TRUE. You will now also be able to dismiss the HC in the USA lead page, by setting Needs Listing Review to FALSE.

Demo: https://www.loom.com/share/230fad81546b468e80d84b2070fdb614
`
  },
  {
    title: 'COE Date Change Tasking',
    date: '2022-04-04',
    description: `New tasks now are going to be triggered for TSs and MAs whenever the COE date changes in the Sales App and in TRUS. For more details please review the [TS training deck](https://docs.google.com/presentation/d/1X0DtXfe2-eMYWhK2hMqdo3bm1zd3iXrxmJck1Bji-O8/edit#slide=id.g88f8695cd8_0_0) or the [MA training deck](https://docs.google.com/presentation/d/1kbipdcsPAaNT3hDHj81ZnM3S3bzNog5NXIvSSLA5X4A/edit#slide=id.g88f8695cd8_0_0).`
  },
  {
    title: 'Cash Offer Tasking',
    date: '2022-03-30',
    description: `We've implemented a standard list of tasks to be completed for every Cash Offer lead. These tasks are specific to incoming residence and account for contingency / Express Close statuses. See the [Training Deck](https://docs.google.com/presentation/d/1lKem41aT72F66NblE2gFw8Hkep1QTJbGZYzYgdrBLS0/edit#slide=id.g88f8695cd8_0_0) for details.`
  },
  {
    title: 'Trade-In and Cash Offer Callouts',
    date: '2022-03-04',
    description: `A newly updated section on the Trade-In and Cash Offer lead detail page displays the different Net Equity values (only on Trade-In) and Close of Escrow dates.`
  },
  {
    title: 'Agent Academy: Automatic Team Invites',
    date: '2022-03-04',
    description: `Now when an agent team lead is enrolled in either Cash Offer, Trade-in, or Closing Services their team members will automatically have their status in that program updated to Pending and receive an invitation to the program. We have also updated the Agent Team tab to display the team member's current program enrollments.`
  },
  {
    title: 'Cash Offer Tasks: Incoming Residences',
    date: '2022-03-01',
    description: `Cash Offer tasks are now specific to their respective Incoming Residences! At the order view, you can hover over the IR icon to see which property address each task is associated with. You can also select which property address to attach a task to. At the My Tasks view, you can see the associated property address for each task under the "Attached To" column.`
  },
  {
    title: 'Dismiss / Reopen Tasks Feature',
    date: '2022-02-23',
    description: `We've added a new action to Tasks: Dismiss! Now, you can dismiss a task when it is no longer applicable to the specific Lead you're working on. Don't worry, you can also Reopen a task that has previously been Dismissed or Completed.`
  },
  {
    title: 'Close of Escrow Date Change Reasons',
    date: '2022-02-14',
    description:
      'Now every time the Actual Close of Escrow Date (COE) is updated in a Trade-In, Cash Offer, or HLSS lead, the change, as well as the reason why it changed, will be captured, stored and displayed in the Activity section.'
  },
  {
    title: 'Cash Offer Express Close Field',
    date: '2022-02-10',
    description: `
We've created a dropdown menu under Offer Details that enables you to indicate whether a transaction is Express Close or not. Select True for an Express transaction and False for a standard closing.
`
  },
  {
    title: 'My Tasks: Ignore Inactive Lead Tasks',
    date: '2022-02-02',
    description: `
We added a default filter to your My Tasks view. Now, when leads move into the Failed, Nurture, Property Cancelled/Rejected, Offer Cancelled/Rejected stages, the associated tasks won't appear in your individual Tasks queue anymore.
`
  },
  {
    title: 'Cash Offer Failed Reason',
    date: '2022-01-31',
    description: `
Allowing users to capture the reason when a lead is failed will provide invaluable insight into the business. They will be able to use this data to evaluate why leads are failing and take corrective action to increase lead conversion.

Demo: https://www.loom.com/share/46f77c272875427fb8954eb20493448d
`
  },
  {
    title: 'Lead Stage Tasks',
    date: '2021-01-10',
    description: `
We added a new feature to our Tasking System that allow Tasks to be required when dispositioning Leads to specific Stages. This feature will help Sales App Users manage their workflow as well as help the business gain valuable insights into our operations.
`
  },
  {
    title: 'Transaction Teams in Nylas',
    date: '2021-12-21',
    description: `
The initiative will provide the business with the ability to track and measure team responsiveness to client and agent emails. This provide tremendous insight into the operations and give the business another lever to manage the business and provide a delightful experience.

Demo: https://www.loom.com/share/1b9e1101a1bf44bcbcfacee252992a75
`
  },
  {
    title: 'Enhanced Mortgage Leads',
    date: '2021-12-15',
    description: `
Before this enhancement, Mortgage Advisors and Transactions Specialists had to navigate to TRUS for any loan specific information. This resulted in the teams managing a deal in two locations. With this enhancement the team can view critical mortgage information needed to manage a deal in our app.
`
  },
  {
    title: 'Documents Attachments',
    date: '2021-12-03',
    description: `
We have a new Files section in the Documents tab that displays all documents attached to a lead, including documents uploaded by agents in the Agent Portal. In addition, in the case of Cash Offer leads, the External Resources section was updated, including displaying the Incoming Residence Address.
`
  },
  {
    title: 'Task Categories',
    date: '2021-12-03',
    description: `
Now you can categorize your Tasks for quick and easy access. Use the "Filter" menu on the Task List to display Tasks in one or more categories.
`
  },
  {
    title: 'Added Pending to Program Enrollment Statuses',
    date: '2021-12-02',
    description: `
Releasing new pending stages for Cash Offer & Trade-In enrollment which will trigger invitations for agents to access and complete a short automated course which will complete their enrollment in each program.
`
  },
  {
    title: 'Agent List Filtering by Transaction Team Member',
    date: '2021-11-29',
    description: `
We've added the ability to filter Agents by a User's Transaction Team in addition to their Assigned Area. Now, when you enter a User's Name into the "Assigned User" Filter, it will display Agents that are on that User's Transaction Team as well as Agents that are in the User's Assigned Area. This provides all Sales App Users the ability to see "their" Agents in addition to ASMs.

Demo: https://www.loom.com/share/72f4471f223c4d18b0cd4a91d3d1035d
`
  },
  {
    title: 'Configurable Views on USA List Pages',
    date: '2021-11-19',
    description: `
We've improved the display and functionality of our list pages. Now you can easily show and hide columns on the Cash Close Orders List. This feature is coming to other list pages soon...`
  },
  {
    title: 'Improved Search, Filter & Sort Summary on Search Results Pages',
    date: '2021-11-18',
    description: `
We've improved the display and functionality of all search results pages. Now you can easily remove a search, filter or sort criteria by clicking the "x" in the Search Summary section.`
  },
  {
    title: 'Agent Lead Disposition Updates w/ New Awaiting Response!',
    date: '2021-11-12',
    description: `
We have combined "Introduce" and "Disposition" on Agent Leads into a single "Disposition" button for a more consistent experience. We also added the "Awaiting Response" disposition before "Introduction".`
  },
  {
    title: 'MLS Lead Level Tasking',
    date: '2021-11-09',
    description: `
### Lead Level Tasks

Updated tasks have been added to the system to indicate any HomeLight leads that register with an active MLS listing in our database.`
  },
  {
    title: 'Milestone Email Automation',
    date: '2021-11-02',
    description: `
### Milestones

* Trade-In Lead Submission / Approval
* Cash Offer Lead Submission / Approval

Added the ability to send automated emails to agents and set expectations on next steps. The agent will be provided reference materials so they can receive guidance on the transaction process.`
  },
  {
    title: 'Admin Areas',
    date: '2021-11-02',
    description: `
### Agent Ae Areas

Administrators can now view and filter HomeLight's States and Metroplexes including ASM Assignments, Active Marketplace Programs and number of assigned Agents.`
  },
  {
    title: 'Updates for Real Estate Ops',
    date: '2021-11-02',
    description: `
### Trade In and Cash Offer Leads

The Trade In and Cash Offer Details pages have been re-organized to better serve our HomeLight Homes Sales and Operations Teams. We also added some critical fields and sortable columns to make the Sales App much more useful to the HomeLight Homes Real Estate Ops Team.`
  },
  {
    title: 'Add Leads To Orders',
    date: '2021-10-15',
    description: `
### Attach leads -> Create Lead Tab

Added the ability to add a new lead to an existing order. You can now add a new seller / buyer lead to an existing seller / buyer order, or add a Cash Offer lead to a Trade-in Order and vise versa.`
  },
  {
    title: 'Restrictions in the Sales App',
    date: '2021-10-11',
    description: `
### Restrictions

Limited access to the pipeline movement report and lead page in the v2 Sales App.`
  },
  {
    title: 'Add Agents in Sales App',
    date: '2021-10-11',
    description: `
### Agents list

Added in the ability to create a new agent profile on the agent list, create a user account in the agent profile, and edit agent information.`
  },
  {
    title: 'Lead External Resources',
    date: '2021-08-03',
    description: `
### Lead - > Documents Tab

Added an external link section attached to Trade In and Cash Offer leads where the Cash Close team can store and name any url!    `
  },
  {
    title: 'Add Lead Address to Tasks list',
    date: '2021-08-02',
    description: `
### Tasks list

Now the Cash Close team see the Lead Address in the Tasks List.

### Agent details page

Now the Cash Close team see the assigned ASM and ASE of an Agent at it's details page.`
  },
  {
    title: 'Trade In HLSS',
    date: '2021-07-31',
    description: `
### Cash Close Orders

Now the Cash Close team create orders with Trade In / HLSS leads. Users can also filter order by HLSS and toggle HL Simple Sale on/off on the TradeIn Lead Page.`
  },
  {
    title: 'Sentry',
    date: '2021-07-29',
    description: `
### Sentry - Tracking errors

Sentry was added to track errors that occur in the app.
Whenever we get an error, we can access sentry.io and see in details what really happened.
It will help us to fix all the issues faster.`
  },
  {
    title: 'Add Lead Types to Tasks list',
    date: '2021-07-26',
    description: `
### Tasks list

Now the Cash Close team see the Lead types in the Tasks List (Buyer, Seller, Trade In, Cash Offer)`
  },
  {
    title: 'Net Equity and Close of Escrow sections',
    date: '2021-07-23',
    description: `
### Lead -> Details Tab

Now the Cash Close team see the Close of Escrow dates for Trade In Leads with accurate information!`
  },
  {
    title: 'Documents Tab',
    date: '2021-07-20',
    description: `
### Lead -> Documents Tab

Now the Cash Close team can send documents to client and agents for them to review and sign directly from the Lead, check for a "Documents" tab in the TradeIn or Cash Offer Lead page!`
  },
  {
    title: 'Sign In/Sign Out',
    date: '2021-07-13',
    description: `
### Sign in through the Sales App
We have a new type of authentication in the App, and it will allow you to sign-in and sign-out here, in the App, without going over to HomeLight.com.  This should simplify some things, but also is a change - now when you sign in on HomeLight.com, you will not be signed in in the Sales App.  Same with signing out.

This should make things a little less buggy and will allow us to add some other cool features later, stay tuned...`
  },
  {
    title: 'Release Notes',
    date: '2021-07-06',
    description: `
### Daily Deploy Notes - all the Good Parts
Yes, this thing that you are reading now is a new feature.  We'll try to keep it up-to-date with anything new.  Check back for details on new releases.  `
  },
  {
    title: 'Saved Searches',
    date: '2021-07-06',
    description: `
### Search Results - just like you left it
When going back to a search page by linking through the site header or
through the uplinks on the Lead and Agent page, you will return to the search page with the search term, sorting, filtering, and pagination as you left it.`
  },
  {
    title: 'Bugs Fixed',
    date: '2021-07-01',
    description: `Performance issues around downloading the employees list is much faster now.`
  }
]

export const releaseNotes = releaseNoteData.map(data => new ReleaseNote(data))
