// @ts-strict

import { ButtonForm, useButtonForm } from 'components'
import { DeleteIconButton } from 'components/Button/IconButton'
import { useTaskController } from 'controllers'
import { Task } from 'models'
import { validatePresence } from 'utils'
import { TaskDismissButtonFields, TDismissTaskPayload } from './TaskDismissButtonFields'

type TTaskDismissFormButtonV3Props = {
  disabled?: boolean
  task: Task
}

export const TaskDismissButtonForm = ({ task, disabled }: TTaskDismissFormButtonV3Props) => {
  const { dismissTask } = useTaskController(task.id)
  const initialPayload = { completionNote: '' }
  const formValidations = {
    completionNote: { label: 'Completion Note', validations: [validatePresence] }
  }

  const { formProps, fieldsProps } = useButtonForm<TDismissTaskPayload>({
    initialPayload,
    formValidations,
    mutation: dismissTask
  })

  return (
    <ButtonForm
      title="Dismiss Task"
      buttonComponent={buttonProps => (
        <DeleteIconButton {...buttonProps} aria-label="Dismiss Task" disabled={disabled} />
      )}
      {...formProps}
    >
      <TaskDismissButtonFields {...fieldsProps} />
    </ButtonForm>
  )
}
