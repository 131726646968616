// @ts-strict
import { FormField, Section, Select } from 'components'
import { useCCCashOfferLeadTaskSearchFilters, useCCTradeInLeadTaskSearchFilters } from 'lookups'
import { isCCCashOfferLead, isCCTradeInLead } from 'models'
import { TTaskFormFieldsProps } from './TaskFormFields'

export const TaskRequiredForStage = ({
  attachable,
  onChangeAttribute,
  payload
}: TTaskFormFieldsProps) => {
  const { findFilterBySlug: findCCCashOfferLeadTaskSearchFilterBySlug } =
    useCCCashOfferLeadTaskSearchFilters()
  const { findFilterBySlug: findCCTradeInLeadTaskSearchFilterBySlug } =
    useCCTradeInLeadTaskSearchFilters()

  const filter = isCCCashOfferLead(attachable)
    ? findCCCashOfferLeadTaskSearchFilterBySlug('requiredForStage')
    : isCCTradeInLead(attachable)
    ? findCCTradeInLeadTaskSearchFilterBySlug('requiredForStage')
    : undefined

  if (!filter) {
    return null
  }

  return (
    <Section>
      <FormField title={filter.name} htmlFor="requiredForStage">
        <Select
          id="requiredForStage"
          data-testid="required-for-stage-field"
          onChange={val => onChangeAttribute('requiredForLeadStage', val)}
          value={payload.requiredForLeadStage}
          options={filter.options}
          placeholder="Select a Stage"
          addBlank
        />
      </FormField>
    </Section>
  )
}
