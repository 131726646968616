import { useDialerContext } from 'contexts/Dialer'
import styles from '../Dialer.module.scss'

export const DialerKeyPad = () => {
  const { isOnCall, sendKeyToCall } = useDialerContext()

  const buttons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#']

  return (
    <div className={styles.keyPad}>
      {buttons.map(char => (
        <button
          key={char}
          disabled={!isOnCall}
          className={styles.key}
          type="button"
          onClick={() => sendKeyToCall(char)}
        >
          {char}
        </button>
      ))}
    </div>
  )
}
