type TProps = {
  rules: Record<string, string>
}

export const Style = ({ rules = {} }: TProps) => {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: Object.entries(rules)
          .map(([k, v]) => `${k} { ${v}; }`)
          .join('\\n')
      }}
    />
  )
}

export default Style
