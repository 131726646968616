import type { RoleInterface } from '../Role'
import type { TeamMembershipInterface } from '../TeamMembership'
import * as utils from './utils'

export type UserInterface = {
  email: string
  firstName?: string
  id: string
  lastName?: string
  name?: string
  region: string
  roles: RoleInterface[]
  teamMemberships: TeamMembershipInterface[]
}

export const UserModel = {
  ...utils
}
