import React from 'react'
import { getUsersUrl } from 'services/getUrl'
import { getRequest } from 'utils'
import { AutoComplete, AutoCompleteProps } from './AutoComplete'

type TProps = {
  onClear?: () => void
  onSelect?: AutoCompleteProps['onSelect']
  value?: string
}

export type TUserAutoCompleteResponse = { id: string; subTitle: string; title: string }

export const AgentTeamUsersAutoComplete = ({
  onSelect = () => {},
  onClear = () => {},
  value,
  ...props
}: TProps) => {
  const formatUser = ({ id, attributes: { name, email } }) => ({ id, title: name, subTitle: email })
  const formatUsers = users => users.map(formatUser)

  const getUsers = async searchTerm => {
    if (!searchTerm || !searchTerm.length) {
      return []
    }
    const searchParams = {
      'filter[_search]': searchTerm,
      'filter[_role]': 'agent',
      autocomplete: true,
      'page[size]': 10
    }
    const response = await getRequest(getUsersUrl({ searchParams }))
    return formatUsers(response.data.data).slice(0, 10)
  }

  return (
    <AutoComplete
      getData={getUsers}
      onSelect={onSelect}
      onClear={onClear}
      value={value}
      placeholder="Enter user name or email..."
      {...props}
    />
  )
}
