// @ts-strict
import {
  FormField,
  PhoneInput,
  Section,
  Sections,
  Select,
  TButtonFormFieldsProps,
  TextInput
} from 'components'
import { useAssignedAreas, useUsStates } from 'lookups'
import { TAgentFormPayload } from './utils'

export const AgentFormFields = ({
  payload,
  onChangeAttribute
}: TButtonFormFieldsProps<TAgentFormPayload>) => {
  const { usStateOptions } = useUsStates()
  const { metroplexAndNonMetroplexOptions } = useAssignedAreas()

  return (
    <>
      <Sections>
        <Section>
          <FormField title="First Name" required>
            <TextInput
              data-testid="firstName-field"
              onChange={val => onChangeAttribute('firstName', val)}
              value={payload.firstName}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="Last Name" required>
            <TextInput
              data-testid="lastName-field"
              onChange={val => onChangeAttribute('lastName', val)}
              value={payload.lastName}
            />
          </FormField>
        </Section>
      </Sections>

      <Sections>
        <Section>
          <FormField title="Cell Number" required>
            <PhoneInput
              data-testid="phoneMobile-field"
              onChange={val => onChangeAttribute('phoneMobile', val)}
              value={payload.phoneMobile}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="Office Number">
            <PhoneInput
              data-testid="phoneOffice-field"
              onChange={val => onChangeAttribute('phoneOffice', val)}
              value={payload.phoneOffice}
            />
          </FormField>
        </Section>
      </Sections>

      <Sections>
        <Section>
          <FormField title="Email" required>
            <TextInput
              data-testid="email1-field"
              onChange={val => onChangeAttribute('email1', val)}
              value={payload.email1}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="Secondary Email">
            <TextInput
              data-testid="email2-field"
              onChange={val => onChangeAttribute('email2', val)}
              value={payload.email2}
            />
          </FormField>
        </Section>
      </Sections>

      <Sections>
        <Section>
          <FormField title="License Number" required>
            <TextInput
              data-testid="licenseNumber-field"
              onChange={val => onChangeAttribute('licenseNumber', val)}
              value={payload.licenseNumber}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="License State" required>
            <Select
              data-testid="stateId-field"
              onChange={val => onChangeAttribute('stateId', val)}
              value={payload.stateId}
              options={usStateOptions}
              placeholder="Select a State"
              addBlank
            />
          </FormField>
        </Section>
      </Sections>

      <Sections>
        <Section>
          <FormField title="Brokerage" required>
            <TextInput
              data-testid="brokerage-field"
              onChange={val => onChangeAttribute('brokerage', val)}
              value={payload.brokerage}
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="Website">
            <TextInput
              data-testid="website-field"
              onChange={val => onChangeAttribute('website', val)}
              value={payload.website}
            />
          </FormField>
        </Section>
      </Sections>

      <Sections>
        <Section>
          <FormField title="Assigned Area" required>
            <Select
              data-testid="assignedArea-field"
              onChange={val => onChangeAttribute('assignedAreaId', val)}
              value={payload.assignedAreaId}
              options={metroplexAndNonMetroplexOptions}
              placeholder="Select an area"
              addBlank
            />
          </FormField>
        </Section>
        <Section>
          <FormField title="MLS Identifier">
            <TextInput
              data-testid="mlsIdentifier-field"
              onChange={val => onChangeAttribute('mlsIdentifier', val)}
              value={payload.mlsIdentifier}
            />
          </FormField>
        </Section>
      </Sections>
    </>
  )
}
