import { asArray } from '../asArray'

export type TValue = string | number | boolean | undefined | File | File[] | null

export type TValidation = ({ label, value }: { label?: string; value: any }) => string[]

type TValidations = TValidation | Array<TValidation>

type TValidateProps = {
  label?: string
  validations: TValidations
  value?: any
}

type TValidationResponse = {
  messages: Array<string>
  valid: boolean
}

export const validate = ({ label, validations, value }: TValidateProps): TValidationResponse => {
  const messages: string[] = []
  const validationsArray = asArray(validations)

  validationsArray.filter(Boolean).forEach(validation => {
    const msgs = asArray(
      validation({
        label,
        value
      })
    )

    msgs.forEach(msg => messages.push(msg))
  })

  const valid = !messages.length

  return { valid, messages }
}

export const validateAll = (
  validationResponses: Array<TValidationResponse>
): TValidationResponse => {
  return {
    valid: validationResponses.every(response => response.valid),
    messages: validationResponses.map(response => response.messages).flat()
  }
}

export const getIsValid = (value: TValue, validations: TValidations, label?: string) => {
  const { valid } = validate({ value, validations, label })
  return valid
}

export const getValidationErrorMessages = (
  value: TValue,
  validations: TValidations,
  label?: string
) => {
  const { messages } = validate({ value, validations, label })

  return messages
}
