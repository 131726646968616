import { getInvestorSubsUrl } from 'services/getUrl'
import { putRequest, snakeCaseKeys } from 'utils'

export const putInvestorMonthlyCap = async (providerId: string, monthlyCap: number) => {
  const url = getInvestorSubsUrl({
    id: providerId,
    action: 'ppl-billing-info'
  })
  await putRequest(url, snakeCaseKeys({ monthlyCap }))
}
