import { TCashCloseProduct } from 'content/Order'
import { getSalesOrdersUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export type TOrderPostPayload = {
  clientName: string
  departingPropertyFullAddress?: string
  departingPropertySellerAgentId?: string
  email?: string
  emailAlt?: string
  incomingPropertyBuyerAgentId?: string
  incomingPropertyFullAddress?: string
  newPropertyCloseOfEscrowDate?: string
  note?: string
  partnerName?: string
  phoneAlt?: string
  phoneNumber?: string
  referringOwnerId?: string
  referringOwnerType?: string
  secondaryLoans: { balance: number; type: string }[]
  simpleSale?: boolean
  source: string
  sourceForm: string
  sourcePageType: string
  types: TCashCloseProduct[]
}

export const postSalesOrder = async (payload: TOrderPostPayload): Promise<string> => {
  const post = await postRequest(getSalesOrdersUrl(), snakeCaseKeys(payload))
  return post.data.data.id
}
