import { useLayoutEffect, useRef, useState } from 'react'
import {
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead
} from '@floating-ui/react'
import type { Item } from '../Select/types'
import { groupBySection } from '../Select/useSelect'
import type { UseMultiSelectProps } from './types'

export const useMultiSelect = ({
  defaultOpen,
  onClose,
  onOpen,
  items,
  isDisabled,
  values
}: UseMultiSelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!defaultOpen)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [isPointer, setIsPointer] = useState<boolean>(false)

  const options = items.map(item => item.value)

  if (!isOpen && isPointer) {
    setIsPointer(false)
  }

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: open => {
      if (isDisabled) {
        return
      }

      if (open && onOpen) {
        onOpen()
      }

      if (!open && onClose) {
        onClose()
      }

      setIsOpen(open)
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        padding: 10,
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`
          })
        }
      })
    ]
  })

  const listRef = useRef<Array<HTMLElement | null>>([])
  const listContentRef = useRef(options)

  const click = useClick(context, { event: 'mousedown' })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'listbox' })
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    loop: true
  })
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    onMatch: isOpen ? setActiveIndex : undefined
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    click,
    dismiss,
    role,
    listNav,
    typeahead
  ])

  useLayoutEffect(() => {
    if (activeIndex == null || isPointer) {
      return
    }

    requestAnimationFrame(() => {
      listRef.current[activeIndex]?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest'
      })
    })
  }, [isPointer, activeIndex])

  const displayType: 'section' | 'single' = items.every(item => item.section?.value)
    ? 'section'
    : 'single'

  const formattedItems =
    displayType === 'section' ? Object.entries(groupBySection(items)) : (items as Item[])

  const generateButtonText = () => {
    if (values.length === 1) {
      const firstSelectedItem = values[0]
      return items.find(item => item.value === firstSelectedItem)?.label
    }

    if (values.length > 1) {
      return `${values.length} selected`
    }
  }

  const getSection = (sectionValue: string) =>
    items.find(option => option.section?.value === sectionValue)!.section!

  const getEnabledSectionItemsValues = (sectionValue: string) =>
    items
      .filter(item => item.section?.value === sectionValue && !item.isDisabled)
      .map(item => item.value)

  const getSelectAllSectionButtonText = (sectionValue: string) => {
    const isAllSelected = getEnabledSectionItemsValues(sectionValue).every(itemValue => {
      return values.includes(itemValue)
    })

    if (isAllSelected) {
      return 'Deselect all'
    }

    return 'Select all'
  }

  return {
    context,
    positionX: x,
    positionY: y,
    reference,
    floating,
    strategy,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    setIsPointer,
    isOpen,
    options: formattedItems,
    listRef,
    activeIndex,
    displayType,
    generateButtonText,
    getSection,
    getSelectAllSectionButtonText,
    getEnabledSectionItemsValues
  }
}
