// @ts-strict
import { UseQueryResult } from 'react-query'
import { Table } from 'components'
import { useListContext } from 'contexts'
import { Lead } from 'models'
import { TFetchItems } from 'services/types'
import { cx } from 'utils'
import { TableResults } from '../../../components/Table/TableResults'
import styles from './LeadsTable.module.scss'
import { LeadsTableHeader } from './LeadsTableHeader'
import { LeadsTableRow } from './LeadsTableRow'

type TProps = {
  className?: string
  'data-testid'?: string
  isLoading: boolean
  leads: Potential<Lead[]>
}

export const LeadsTable = ({ leads, isLoading, className, 'data-testid': dataTestId }: TProps) => {
  const { colSpan } = useListContext()

  return (
    <Table className={cx(styles.table, className)} data-testid={dataTestId}>
      <LeadsTableHeader />
      <TableResults type="Leads" colSpan={colSpan} isLoading={isLoading}>
        {leads?.map(lead => (
          <LeadsTableRow key={lead.id} lead={lead} />
        ))}
      </TableResults>
    </Table>
  )
}

type TLeadsTableV2 = {
  className?: string
  query: UseQueryResult<TFetchItems<Lead>>
}

export const LeadsTableV2 = ({ query, className }: TLeadsTableV2) => {
  const { isLoading, data } = query

  return <LeadsTable isLoading={isLoading} leads={data?.items} className={className} />
}
