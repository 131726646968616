import * as RadixToast from '@radix-ui/react-toast'
import { css, keyframes, styled, Theming } from '@foundation/themes/styles'
import type { Toast } from './useToast'

type StatusProps = { theme: Theming }

const statusStyle = {
  success: ({ theme }: StatusProps) => {
    return css`
      background-color: ${theme.colors.success5};
      svg {
        fill: ${theme.colors.white};
      }
    `
  },
  warning: ({ theme }: StatusProps) => {
    return css`
      background-color: ${theme.colors.warning5};
      svg {
        fill: ${theme.colors.warning10};
      }
    `
  },
  error: ({ theme }: StatusProps) => {
    return css`
      background-color: ${theme.colors.danger5};
      svg {
        fill: ${theme.colors.white};
      }
    `
  },
  info: ({ theme }: StatusProps) => {
    return css`
      background-color: ${theme.colors.primary5};
      svg {
        fill: ${theme.colors.white};
      }
    `
  }
} as const

const hide = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const slideIn = keyframes`
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
`

const swipeOut = keyframes`
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
`

export const Root = styled(RadixToast.Root)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    background-color: ${theme.colors.neutral1};
    border-radius: ${theme.radii.lg};
    box-shadow: ${theme.shadows.md};
    padding: ${theme.space.$6};

    &[data-state='open'] {
      animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    &[data-state='closed'] {
      animation: ${hide} 100ms ease-in;
    }

    &[data-swipe='move'] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }

    &[data-swipe='cancel'] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }

    &[data-swipe='end'] {
      animation: ${swipeOut} 100ms ease-out;
    }
  `}
`

export const MainContent = styled(RadixToast.Title)`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.space.$4};
  `}
`

export const Content = styled(RadixToast.Title)`
  display: flex;
  flex-direction: column;
`

export const Title = styled(RadixToast.Title)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.primary10};
    font-size: ${theme.fontSizes.sm};
    min-height: ${theme.sizes.$12};
  `}
`

export const Description = styled(RadixToast.Description)`
  ${({ theme }) => css`
    color: ${theme.colors.primary10};
    font-size: ${theme.fontSizes.sm};
  `}
`

export const StatusWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 100%;
`

export const Status = styled.div<{ $status: Toast['status'] }>`
  ${({ theme, $status }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${theme.sizes.$12};
    width: ${theme.sizes.$12};
    padding: ${theme.space.$1};
    border-radius: ${theme.radii.rounded};
    ${statusStyle[$status]};
  `}
`

export const Action = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 100%;
`

export const Viewport = styled(RadixToast.Viewport)`
  ${({ theme }) => css`
    --viewport-padding: ${theme.space.$5};
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: var(--viewport-padding);
    gap: ${theme.space.$3};
    width: 35rem;
    max-width: 100vw;
    margin: 0;
    list-style: none;
    z-index: ${theme.zIndices.toast};
    outline: none;
  `}
`
