// @ts-strict

import { ReactNode } from 'react'
import { useTeamsController } from 'controllers'
import { TeamType } from 'models'
import { createCtx } from '../utils'

type TeamsContext = ReturnType<typeof useTeamsController> & { type: string }

export const [useTeamsContext, TeamsContextProvider] = createCtx<TeamsContext>()

type Props = {
  children: ReactNode
  type: TeamType
}

export const TeamsProvider = ({ children, type = 'TransactionTeam' }: Props) => {
  const value = { ...useTeamsController(type), type }
  return <TeamsContextProvider value={value}>{children}</TeamsContextProvider>
}
