import { TLeadUploadPhotosPayload } from 'content/Lead'
import { postPhotoUploads, validatePhotoUploads } from 'services'
import { uploadToS3 } from '../utils'

type TValidateImageResponse = {
  key: string
  message?: string
  valid: boolean
}

export const createPhotoUploads = async (leadId: string, payload: TLeadUploadPhotosPayload) => {
  const uploadedPhotos = await uploadToS3(payload.photos, 'image')
  const keys = uploadedPhotos.map(photo => photo.storageKey)
  const { data, status } = await validatePhotoUploads({ keys })

  if (status !== 200) {
    return
  }

  const validUploadedPhotos = uploadedPhotos.filter(photo =>
    data.find((image: TValidateImageResponse) => image.key === photo.storageKey && image.valid)
  )

  return postPhotoUploads({
    attachableId: leadId,
    attachableType: 'Lead',
    category: 'property_photo',
    files: validUploadedPhotos
  })
}
