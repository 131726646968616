// @ts-strict
import { useButtonForm } from 'components'
import { useCurrentUserContext, useLeadContext } from 'contexts'
import { formatDateTime, initializeString } from 'utils'
import { TFormValidation, validatePresence } from 'utils/validations'

export type TLeadNewSalesNotePayload = {
  salesNotes: string
}

export const useLeadNewSalesNoteButtonForm = () => {
  const { currentUser } = useCurrentUserContext()
  const { lead, updateLead } = useLeadContext()
  const initialPayload: TLeadNewSalesNotePayload = { salesNotes: '' }

  const formValidations: TFormValidation = {
    salesNotes: validatePresence
  }

  const transformPayload = (payload: TLeadNewSalesNotePayload) => {
    const now = new Date()
    const currentUserName = `${currentUser?.firstName} ${initializeString(currentUser?.lastName)}`
    const newSalesNote = `(${formatDateTime(now)}) ${currentUserName}: ${payload.salesNotes}`
    const salesNotes = lead?.salesNotes ? `${lead?.salesNotes}\n\n${newSalesNote}` : newSalesNote

    return { salesNotes }
  }

  const {
    formProps,
    fieldsProps: { onChangeAttribute, payload }
  } = useButtonForm<TLeadNewSalesNotePayload>({
    initialPayload,
    mutation: updateLead,
    transformPayload,
    formValidations
  })

  return {
    formProps,
    onChangeAttribute,
    payload
  }
}
