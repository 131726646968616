import { toOptions } from 'lookups/utils'
import { bySlug } from 'utils'
import { buildLeadStages } from '../lookupLeadStages'
import { CcTradeInLeadStage } from './CcTradeInLeadStage'
import {
  ccTradeInLeadExpressStages,
  ccTradeInLeadStagesLeftMessageSettings,
  ccTradeInLeadStagesSettings,
  ccTradeInLeadStagesWithoutPitchedSettings,
  TCcTradeInLeadStagesSlug
} from './ccTradeInLeadStagesSettings'

export const lookupCcTradeInLeadStages = buildLeadStages(
  ccTradeInLeadStagesSettings,
  CcTradeInLeadStage
)
export const lookupCcTradeInLeadStagesWithLeftMessage = buildLeadStages(
  ccTradeInLeadStagesLeftMessageSettings,
  CcTradeInLeadStage
)
export const lookupCcTradeInLeadStagesWithoutPitched = buildLeadStages(
  ccTradeInLeadStagesWithoutPitchedSettings,
  CcTradeInLeadStage
)

export const lookupCcTradeInLeadExpressStages = buildLeadStages(
  ccTradeInLeadExpressStages,
  CcTradeInLeadStage
)

export const getCcTradeInLeadStage = (slug: TCcTradeInLeadStagesSlug) => {
  return lookupCcTradeInLeadStages.find(bySlug(slug))
}

/*
  Remove everything with WithLeftMessage once the left-message-stage feature toggle is removed
  Add the ccTradeInLeadStagesLeftMessageSettings setting (called_left_msg) into the normal
  settings and delete all of these extra functions. To eventually replace pitched (see below)
*/
export const getCcTradeInLeadStageWithLeftMessage = (slug: TCcTradeInLeadStagesSlug) => {
  return lookupCcTradeInLeadStagesWithLeftMessage.find(bySlug(slug))
}

/*
  Remove everything with WithoutPitched once the sales-ops-pitch-stage-removal toggle is removed
  Replace pitched with called_left_msg in the normal
  settings and delete all of these extra functions
*/
export const getCcTradeInLeadStageWithoutPitched = (slug: TCcTradeInLeadStagesSlug) => {
  return lookupCcTradeInLeadStagesWithoutPitched.find(bySlug(slug))
}

export const getCcTradeInLeadExpressStage = (slug: TCcTradeInLeadStagesSlug) => {
  return lookupCcTradeInLeadExpressStages.find(bySlug(slug))
}

export const getCcTradeInLeadStageName = (slug: TCcTradeInLeadStagesSlug) => {
  return getCcTradeInLeadStage(slug)?.name
}

export const ccTradeInLeadStagesOptions = toOptions(lookupCcTradeInLeadExpressStages)
export const ccTradeInLeadExpressStagesOptions = toOptions(lookupCcTradeInLeadStages)

export const ccTradeInLeadStagesOptionsWithoutPitched = toOptions(
  lookupCcTradeInLeadStagesWithoutPitched
)
