// @ts-strict

import { useState } from 'react'
import { Button } from '@foundation/components'
import { Block, ConfirmationPopup, Icon, Section } from 'components'
import { AttachLeadPopup } from 'content/Order'
import { useLeadController, useOrderController } from 'controllers'
import { useFlexUi } from 'hooks'
import { Lead, Order } from 'models'
import { formatOrDash, titleize } from 'utils'
import styles from './OrderLeadNavigation.module.scss'
import { OrderLeadNavigationItem, OrderLeadNavigationLink, OrderLeadNavigationProperty } from './'

type TProps = {
  order: Order
  selectedLead: Lead
}

export const OrderLeadNavigation = ({ order, selectedLead }: TProps) => {
  const { detachLeadFromOrder } = useLeadController('', 'seller', false)
  const { similarLeadsQuery } = useOrderController(order.id)
  const [showAttachLead, setShowAttachLead] = useState(false)
  const { isFlexUi } = useFlexUi()

  const totalCount = similarLeadsQuery?.data?.total ?? 0

  const similarLeadsCounter = totalCount > 5 ? '5+' : totalCount
  const leadsLength = order?.leads?.length || 0

  const toggleAttachLeadPopup = () => setShowAttachLead(state => !state)
  const attachLeadsText: string = `Attach Leads ${
    !!similarLeadsCounter ? `(${similarLeadsCounter})` : `(${similarLeadsCounter})`
  }`
  const actions = (
    <Button variant="ghost" color="primary" size="xs" onClick={toggleAttachLeadPopup}>
      {attachLeadsText}
    </Button>
  )

  return (
    <>
      <Section title="Order" actions={actions}>
        {!isFlexUi && (
          <OrderLeadNavigationLink to={order.pathname}>
            <Block
              data-testid={`leadNavigationItem${order.id}`}
              title={order.title}
              subtitle={!isFlexUi && formatOrDash(order.stage, titleize)}
              className={styles.orders}
            />
          </OrderLeadNavigationLink>
        )}
        <div className={styles.order}>
          {order.properties?.map(property => (
            <OrderLeadNavigationProperty key={property.name} property={property}>
              <div className={styles.ordersWrapper}>
                {property?.leads?.map((leadItem: Lead) => (
                  <div key={leadItem.id} className={styles.relative}>
                    <OrderLeadNavigationItem lead={leadItem} />
                    <ConfirmationPopup
                      buttonText={<Icon.Close />}
                      title="Detach lead"
                      message="Are you sure you want to detach the lead from this order?"
                      buttonComponent={props => (
                        <button {...props} className={styles.detachButton} />
                      )}
                      disabled={leadsLength <= 1}
                      mutation={detachLeadFromOrder}
                      initialPayload={{ orderId: order.id, leadId: leadItem.id }}
                    />
                  </div>
                ))}
              </div>
            </OrderLeadNavigationProperty>
          ))}
        </div>
      </Section>
      {showAttachLead && (
        <AttachLeadPopup
          onCancel={toggleAttachLeadPopup}
          order={order}
          selectedLead={selectedLead}
        />
      )}
    </>
  )
}
