import { SearchParams } from '@types'
import { formatDaysAgo, removeAttributesWithNullValues } from 'utils'

const sortParam = {
  Price: 'price',
  Intro: 'created_at'
}

export type SearchParamsSettings = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'sortBy' | 'searchTerm' | 'sortDir'
>

type Args = {
  daysAgo?: string
  escrowAttached?: string
  leadStages?: string[]
  oppStatus?: string
  state?: string[]
  userType?: string
} & SearchParamsSettings

export const getSearchParams = ({
  sortBy,
  sortDir,
  page,
  perPage,
  searchTerm,
  escrowAttached,
  daysAgo,
  leadStages,
  userType,
  oppStatus,
  state
}: Args) => {
  const getSort = () => {
    const dir = sortDir === 'desc' ? '-' : ''

    if (sortBy) {
      return `${dir}${sortParam[sortBy]}`
    }
    return `-${sortParam['Intro']}`
  }

  return removeAttributesWithNullValues({
    'filter[escrow_attachment]': escrowAttached,
    'filter[>last_stage_update]': formatDaysAgo(daysAgo),
    'filter[agent_lead_agents.stage]': leadStages,
    'filter[user_type]': userType,
    'filter[services_opportunities.status_id]': oppStatus,
    'page[number]': page,
    'page[size]': perPage,
    'filter[_search]': searchTerm,
    'filter[_asx_scope]': 'true',
    urgent_only: state?.includes('urgent'),
    sort: getSort()
  })
}
