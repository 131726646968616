import { createContext, ReactNode, useContext } from 'react'
import { useAgentAgreementsController } from 'controllers'

const AgentAgreementsContext = createContext<ReturnType<typeof useAgentAgreementsController>>(null)

export const useAgentAgreementsContext = () => {
  const context = useContext(AgentAgreementsContext)
  if (!context) {
    throw new Error('useAgentAgreementsContext must be called within an AgentAgreementsProvider')
  }
  return context
}

type Props = {
  agentId?: string
  children: ReactNode
}

export const AgentAgreementsProvider = ({ children, agentId }: Props) => {
  return (
    <AgentAgreementsContext.Provider value={useAgentAgreementsController(agentId)}>
      {children}
    </AgentAgreementsContext.Provider>
  )
}
