import { ReactNode, SyntheticEvent } from 'react'
import { Block, RoundImage } from 'components'
import { Section } from 'components/Section'
import { InvestorLead } from 'models'
import { randomImage } from 'utils'
import styles from './InvestorSummary.module.scss'

type Props = {
  children?: ReactNode
  investorLead: InvestorLead
}

export const InvestorSummary = ({ investorLead }: Props) => {
  const title = investorLead.investor.name
  const subtitle = 'Investor'

  const imageSrc = randomImage(+investorLead.id, 50)
  const handleDefaultImage = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = imageSrc
  }

  const image = (
    <RoundImage
      className={styles.agentAvatar}
      src={imageSrc}
      onError={handleDefaultImage}
      alt={title}
    />
  )

  return (
    <div className={styles.summary}>
      <Section>
        <Block title={title} subtitle={subtitle} image={image} className={styles.title} />
      </Section>
    </div>
  )
}
