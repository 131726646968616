import { IntegerInput, Under1000IntegerInput, Under100IntegerInput } from 'components/Form'
import { InlineEditTextInput, InlineEditTextInputProps } from '../InlineEditTextInput'

export const InlineEditIntegerInput = (props: InlineEditTextInputProps) => (
  <InlineEditTextInput {...props} render={IntegerInput} />
)

export const InlineEditUnder100Input = (props: InlineEditTextInputProps) => (
  <InlineEditTextInput {...props} render={Under100IntegerInput} />
)

export const InlineEditUnder1000Input = (props: InlineEditTextInputProps) => (
  <InlineEditTextInput {...props} render={Under1000IntegerInput} />
)
