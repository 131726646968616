import { Partner } from 'models/Partner'
import { getPartnersForBbysLeadsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

export const fetchPartnersForBbysLeads = async (): Promise<Partner[]> => {
  const searchParams = {
    'page[size]': 1000
  }
  const response = await getRequest(getPartnersForBbysLeadsUrl({ searchParams }))
  const partners = await deserializeAsync(response.data, { transform: Partner.create })
  return partners
}
