// @ts-strict
import { ReactNode } from 'react'
import { cx } from 'utils'
import style from './Block.module.scss'

export type BlockProps = {
  actions?: ReactNode
  allowTitleOverflow?: boolean
  children?: ReactNode
  className?: string
  date?: ReactNode
  image?: ReactNode
  subtitle?: ReactNode
  textChildren?: ReactNode
  title?: ReactNode
}

export const Block = ({
  children,
  className,
  title,
  subtitle,
  image,
  date,
  actions,
  allowTitleOverflow,
  ...props
}: BlockProps) => {
  if (!title && !subtitle && !image && !actions) {
    return null
  }

  const imageOutput = image ? <div className={cx(style.image, 'block_image')}>{image}</div> : null

  const overflowStyle = allowTitleOverflow ? style.allowOverflow : ''

  const textOutput =
    title || subtitle ? (
      <div className={cx(style.text, 'block_text')}>
        {title && <div className={cx(style.title, overflowStyle, 'block_title')}>{title}</div>}
        {subtitle && <div className={cx(style.subtitle, 'block_subtitle')}>{subtitle}</div>}
        {children}
      </div>
    ) : null

  const actionsOutput =
    actions || date ? (
      <div className={cx(style.actions, 'block_date', 'block_actions')}>
        {date ? <div className={style.date}>{date}</div> : null}
        {actions}
      </div>
    ) : null

  return (
    <div className={cx(style.block, className)} {...props}>
      {imageOutput}
      {textOutput}
      {actionsOutput}
    </div>
  )
}
