// @ts-strict

import { Contents, PaginationParams, SearchConsole } from 'components'
import { TaskCreateButtonForm } from 'content/Task/TaskForm'
import { useTasksContext } from 'contexts'
import { TaskListFilters } from './TaskListFilters'
import { TaskListSearch } from './TaskListSearch'
import { TaskListStatus } from './TaskListStatus'
import { TaskTable } from './TaskTable'

export const TasksList = () => {
  const { pageCount, tasks, isLoading } = useTasksContext()

  return (
    <Contents title="Tasks" actions={<TaskCreateButtonForm />}>
      <TaskListStatus />
      <SearchConsole search={<TaskListSearch />} actions={<TaskListFilters />} />
      <TaskTable tasks={tasks} isLoading={isLoading} />
      <PaginationParams totalPages={pageCount} />
    </Contents>
  )
}
