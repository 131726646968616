import { useState } from 'react'
import { Box, Text } from '@foundation/components'
import { Divider, Section, Sections, TextInput } from 'components'
import { useAgentContext } from 'contexts/Agent'
import styles from './LocationPreferences.module.scss'
import { RequestedLocationsButtonForm, SelectedLocationsButtonForm, ZipCodesSection } from './'

export const LocationPreferences = () => {
  const { agent } = useAgentContext()
  const [filterValue, setFilterValue] = useState<string>('')

  const requestedZipCodes = agent.referralPreferences?.requestedZipCodes?.map(c => c.code) || []
  const selectedZipCodes = agent.referralPreferences?.selectedZipCodes?.map(c => c.code) || []
  const excludedZipCodes = agent.referralPreferences?.excludedZipCodes?.map(c => c.code) || []

  const filterZipCodes = (zipCodes: string[]) => {
    return zipCodes.filter(zipCode => zipCode.includes(filterValue))
  }

  return (
    <Section>
      <Section>
        <Sections className={styles.inputLabel}>
          <Text size="sm">Search for a zip code</Text>
        </Sections>
        <TextInput placeholder="Search zip codes" value={filterValue} onChange={setFilterValue} />
      </Section>
      <Section>
        <Box className={styles.container}>
          <Text size="base" weight="semibold">
            Requested Locations
          </Text>
          <ZipCodesSection sectionName="requested" zipCodes={filterZipCodes(requestedZipCodes)} />
          <RequestedLocationsButtonForm agent={agent} />
        </Box>
        <Divider className={styles.divider} />
        <Box className={styles.container}>
          <Text size="base" weight="semibold">
            Historical Transaction Locations
          </Text>
          <ZipCodesSection sectionName="historical" zipCodes={filterZipCodes(selectedZipCodes)} />
          <Text size="base" weight="semibold">
            Disabled Locations
          </Text>
          <ZipCodesSection sectionName="disabled" zipCodes={filterZipCodes(excludedZipCodes)} />
          <SelectedLocationsButtonForm agent={agent} />
        </Box>
      </Section>
    </Section>
  )
}
