import { Option } from 'classes'
import type { TableCellAlignments, TableCellSizes } from 'components'
import { IOptionable } from '../utils'

type TColumn = {
  name: string
  value: string
}

export type TColumnAttributes = {
  align?: TableCellAlignments
  attachedColumn?: TColumnAttributes
  columnSize?: TableCellSizes
  group?: string
  isDefault?: boolean
  isOptional?: boolean
  isReorderable?: boolean
  name: string
  order?: number
  sort?: readonly string[] | string[] | boolean
  title?: string
  value: string
}

export class Column<T extends TColumn = TColumn> implements IOptionable {
  name!: T['name']
  value!: T['value']
  isDefault?: boolean
  isOptional?: boolean
  isReorderable?: boolean
  sort?: readonly string[] | string[]
  fixed?: boolean
  title?: string
  group?: string
  order?: number
  columnSize?: TableCellSizes
  attachedColumn?: Column
  align?: TableCellAlignments

  constructor(columnAttributes: TColumnAttributes) {
    Object.assign(this, columnAttributes)
  }

  toOption() {
    return new Option({ name: this.name, value: this.value, description: this.title })
  }
}
