import { Badge } from 'components'
import { AgentAvatar } from 'content/Agent/AgentAvatar'
import { Agent } from 'models'
import { cx } from 'utils'
import styles from './AgentsAvatars.module.scss'

const DEFAULT_LIMIT = 5

type Props = {
  agents: Agent[]
  disableTierBadge?: boolean
  limit?: number
}

export const AgentsAvatars = ({ agents, limit = DEFAULT_LIMIT, disableTierBadge }: Props) => {
  const shouldShowPlusIcon = agents.length > limit
  const plusMoreText = `+${agents.length - limit}`

  const limitedAgents = agents.slice(0, limit)

  return (
    <div className={styles.agentAvatars}>
      {limitedAgents.map(agent => (
        <AgentAvatar
          key={agent.id}
          agent={agent}
          className={styles.avatar}
          disableTierBadge={disableTierBadge}
        />
      ))}
      {shouldShowPlusIcon ? (
        <Badge
          title={`${plusMoreText} active agents`}
          className={cx(styles.plusBadge, styles.avatar)}
        >
          {plusMoreText}
        </Badge>
      ) : null}
    </div>
  )
}
