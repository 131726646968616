import React, { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './SectionSubTitle.module.scss'

type Props = {
  children: ReactNode
  className?: string
}

export const SectionSubTitle = ({ className, children }: Props) => {
  return (
    <div data-testid="sectionSubTitle" className={cx(styles.subTitle, className)}>
      {children}
    </div>
  )
}
