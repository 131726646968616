// @ts-strict

import { Pagination, SearchInput, SearchStatus, SearchSummary } from 'components'
import { LeadsTable } from 'content/Leads'
import { useParamsContext } from 'contexts/Params'
import { useLeadsController } from 'controllers'
import { Order } from 'models'

type TProps = {
  order: Order
}

export const AttachLeadList = ({ order }: TProps) => {
  const {
    params: { searchTerm, page },
    updatePage,
    updateSearchTerm,
    resetParams
  } = useParamsContext()
  const isSearchEmpty = !searchTerm
  const params = isSearchEmpty
    ? {
        similarOrder: order.id,
        sortBy: 'Created' as 'Created',
        sortDir: 'desc' as 'desc'
      }
    : {}

  const { leads, isLoading, pageCount, totalCount } = useLeadsController({
    searchTerm,
    perPage: 7,
    page,
    ...params
  })

  const canClear = !isSearchEmpty ? resetParams : undefined

  return (
    <>
      <SearchSummary
        status={
          <SearchStatus
            params={isSearchEmpty ? {} : { searchTerm }}
            count={totalCount}
            type="Matching Lead"
            unfilteredText="With: 'similar leads'"
          />
        }
        onClear={canClear}
      />
      <SearchInput
        value={searchTerm}
        onChange={updateSearchTerm}
        placeholder="Search by client name, client email or lead id"
      />
      <LeadsTable leads={leads} isLoading={isLoading} data-testid="matchingLeadsTable" />

      {pageCount ? (
        <Pagination totalPages={pageCount} currentPage={page} onChange={val => updatePage(val)} />
      ) : null}
    </>
  )
}
