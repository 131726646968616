// @ts-strict
import { Contents, PaginationParams, SearchStatus } from 'components'
import { InspectionDeficiencyFilter, InspectionDeficiencyTable } from 'content/Inspection'
import { ListProvider, useLeadContext, useParamsContext } from 'contexts'
import { useInspectionDeficienciesController } from 'controllers'
import { useDocumentTitle, useFeatureFlags } from 'hooks'
import { inspectionDeficiencyListColumns, useInspectionDeficienciesSearchFilter } from 'lookups'

const actions = <InspectionDeficiencyFilter />

export const LeadInspectionsDeficienciesTab = () => {
  const { lead } = useLeadContext()
  const { params } = useParamsContext()
  const providerLead = lead?.[lead.providerName!]
  const { inspectionDeficienciesQuery } = useInspectionDeficienciesController({
    ...params,
    filterByProviderLeadId: providerLead.id
  })
  useDocumentTitle(`${lead?.name} - Inspections - Deficiencies`)
  const filters = useInspectionDeficienciesSearchFilter()
  const { salesOpsEscalatedDeficiencies } = useFeatureFlags(['sales-ops-escalated-deficiencies'])

  if (!salesOpsEscalatedDeficiencies.enabled) {
    inspectionDeficiencyListColumns.columns = inspectionDeficiencyListColumns.columns.filter(
      col => col.value !== 'escalated'
    )
  }

  return (
    <ListProvider columns={inspectionDeficiencyListColumns} filters={filters}>
      <Contents title="Deficiencies" actions={actions}>
        <SearchStatus
          params={params}
          count={inspectionDeficienciesQuery.data?.totalCount}
          type="Deficiency"
        />
        <InspectionDeficiencyTable
          items={inspectionDeficienciesQuery.data?.items}
          isLoading={inspectionDeficienciesQuery.isLoading}
        />
        <PaginationParams totalPages={inspectionDeficienciesQuery.data?.pageCount} />
      </Contents>
    </ListProvider>
  )
}
