import { getOpportunitiesUrl } from 'services/getUrl'
import { getRequest, yearsAgoDate } from 'utils'
import { transformOpportunityCounts } from './transformOpportunityCounts'

export const fetchOpportunityCounts = async (userId: string | undefined) => {
  const twoYearsAgoDate = yearsAgoDate(2)
  const searchParams = {
    'filter[attachable_type]': 'ProviderLead',
    'filter[stage]': 'listing,coming_soon,in_escrow',
    'filter[created_at>]': twoYearsAgoDate.toISOString(),
    group: 'status_id',
    'filter[_ae_id]': userId
  }

  const response = await getRequest(getOpportunitiesUrl({ searchParams, action: 'counts' }))
  return transformOpportunityCounts(response.data)
}
