import { SearchParams } from '@types'
import { removeAttributesWithNullValues } from 'utils'

const sortParam = {
  listDate: 'listing_contract_date',
  lastUpdate: 'modification_ts'
}

type SearchParamsSettings = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'sortBy' | 'sortDir' | 'searchTerm'
>

type Args = Partial<{
  agentId: string
  status: string
}> &
  SearchParamsSettings

export const getSearchParams = ({
  sortBy,
  sortDir,
  page,
  perPage,
  searchTerm,
  status,
  agentId
}: Args) => {
  const getSort = () => {
    const dir = sortDir === 'desc' ? '-' : ''

    if (sortBy) {
      return `${dir}${sortParam[sortBy]}`
    }
    return `-${sortParam['listDate']}`
  }

  return removeAttributesWithNullValues({
    'page[number]': page,
    'page[size]': perPage,
    'filter[_search]': searchTerm,
    'filter[_agent_id]': agentId,
    'filter[mls_status.keyword]': status,
    sort: getSort()
  })
}
