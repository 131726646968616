export const tradeInFailedReasonsSettings = [
  {
    name: 'Valuation/Listing Price',
    value: 'valuation_listing_price',
    type: 'trade_in_failed_reason',
    slug: 'valuation_listing_price'
  },
  {
    name: 'Net Proceeds too low',
    value: 'net_proceeds_too_low',
    type: 'trade_in_failed_reason',
    slug: 'net_proceeds_too_low'
  },
  {
    name: 'Lost to Competitor',
    value: 'lost_to_competitor',
    type: 'trade_in_failed_reason',
    slug: 'lost_to_competitor'
  },
  {
    name: 'Traditional Listing',
    value: 'traditional_listing',
    type: 'trade_in_failed_reason',
    slug: 'traditional_listing'
  },
  {
    name: 'Area Not Serviced',
    value: 'area_not_serviced',
    type: 'trade_in_failed_reason',
    slug: 'area_not_serviced'
  },
  {
    name: 'Not Transacting',
    value: 'not_transacting',
    type: 'trade_in_failed_reason',
    slug: 'not_transacting'
  },
  {
    name: 'Already Listed',
    value: 'already_listed',
    type: 'trade_in_failed_reason',
    slug: 'already_listed'
  },
  {
    name: 'HLHL Approval',
    value: 'hlhl_approval',
    type: 'trade_in_failed_reason',
    slug: 'hlhl_approval'
  },
  {
    name: 'Unresponsive',
    value: 'unresponsive',
    type: 'trade_in_failed_reason',
    slug: 'unresponsive'
  },
  {
    name: 'Fee too High',
    value: 'fee_too_high',
    type: 'trade_in_failed_reason',
    slug: 'fee_too_high'
  },
  {
    name: 'Non-CC Agent',
    value: 'non_cc_agent',
    type: 'trade_in_failed_reason',
    slug: 'non_cc_agent'
  },
  {
    name: 'Duplicate',
    value: 'duplicate',
    type: 'trade_in_failed_reason',
    slug: 'duplicate'
  },
  {
    name: 'Lender Platform',
    value: 'lender_platform',
    type: 'trade_in_failed_reason',
    slug: 'lender_platform'
  },
  {
    name: 'No Show',
    value: 'no_show',
    type: 'trade_in_failed_reason',
    slug: 'no_show'
  },
  {
    name: 'Client Cancelled Appointment',
    value: 'client_cancelled_appointment',
    type: 'trade_in_failed_reason',
    slug: 'client_cancelled_appointment'
  },
  {
    name: 'Credit',
    value: 'credit',
    type: 'trade_in_failed_reason',
    slug: 'credit'
  },
  {
    name: 'Used HLSS',
    value: 'used_hlss',
    type: 'trade_in_failed_reason',
    slug: 'used_hlss'
  },
  {
    name: 'Bad Contact Info',
    value: 'bad_contact_info',
    type: 'trade_in_failed_reason',
    slug: 'bad_contact_info'
  },
  {
    name: 'Property Value',
    value: 'property_value',
    type: 'trade_in_failed_reason',
    slug: 'property_value'
  },
  {
    name: 'Ineligible Property Type',
    value: 'ineligible_property_type',
    type: 'trade_in_failed_reason',
    slug: 'ineligible_property_type'
  },
  {
    name: 'Gross Living Area',
    value: 'gross_living_area',
    type: 'trade_in_failed_reason',
    slug: 'gross_living_area'
  },
  {
    name: 'Long Days on Market',
    value: 'long_days_market',
    type: 'trade_in_failed_reason',
    slug: 'long_days_market'
  },
  {
    name: 'Lot Size',
    value: 'lot_size',
    type: 'trade_in_failed_reason',
    slug: 'lot_size'
  },
  {
    name: 'Room Count',
    value: 'room_count',
    type: 'trade_in_failed_reason',
    slug: 'room_count'
  },
  {
    name: 'Repair Budget',
    value: 'repair_budget',
    type: 'trade_in_failed_reason',
    slug: 'repair_budget'
  },
  {
    name: 'Ownership',
    value: 'ownership',
    type: 'trade_in_failed_reason',
    slug: 'ownership'
  },
  {
    name: 'Max Guaranteed Price',
    value: 'max_guaranteed_price',
    type: 'trade_in_failed_reason',
    slug: 'max_guaranteed_price'
  },
  {
    name: 'Amenities',
    value: 'amenities',
    type: 'trade_in_failed_reason',
    slug: 'amenities'
  },
  {
    name: 'Mid-Project Properties',
    value: 'mid_project_properties',
    type: 'trade_in_failed_reason',
    slug: 'mid_project_properties'
  },
  {
    name: 'Property Condition',
    value: 'property',
    type: 'trade_in_failed_reason',
    slug: 'property'
  },
  {
    name: 'Solar Lien',
    value: 'solar_lien',
    type: 'trade_in_failed_reason',
    slug: 'solar_lien'
  },
  {
    name: 'Resale Restrictions',
    value: 'resale_restrictions',
    type: 'trade_in_failed_reason',
    slug: 'resale_restrictions'
  },
  {
    name: 'Occupancy',
    value: 'occupancy',
    type: 'trade_in_failed_reason',
    slug: 'occupancy'
  },
  {
    name: 'Oil/Gas/Mineral Rights/Leases',
    value: 'oil_gas_mineralrights_leases',
    type: 'trade_in_failed_reason',
    slug: 'oil_gas_mineralrights_leases'
  },
  {
    name: 'Listed Properties',
    value: 'listed_properties',
    type: 'trade_in_failed_reason',
    slug: 'listed_properties'
  },
  {
    name: 'Test Lead',
    value: 'test_lead',
    type: 'trade_in_failed_reason',
    slug: 'test_lead'
  },
  { name: 'Other', value: 'other', type: 'trade_in_failed_reason', slug: 'other' }
]
export const cashOfferFailedReasonsSettings = [
  {
    name: 'Using Non-HL Agent',
    value: 'using_non_hl_agent',
    type: 'cash_offer_failed_reason',
    slug: 'using_non_hl_agent'
  },
  {
    name: 'Lost to Competitor',
    value: 'lost_to_competitor',
    type: 'cash_offer_failed_reason',
    slug: 'lost_to_competitor'
  },
  {
    name: 'Area Not Serviced',
    value: 'area_not_serviced',
    type: 'cash_offer_failed_reason',
    slug: 'area_not_serviced'
  },
  {
    name: 'Traditional Offer',
    value: 'traditional_offer',
    type: 'cash_offer_failed_reason',
    slug: 'traditional_offer'
  },
  {
    name: 'Not Transacting',
    value: 'not_transacting',
    type: 'cash_offer_failed_reason',
    slug: 'not_transacting'
  },
  {
    name: 'HLHL Approval',
    value: 'hlhl_approval',
    type: 'cash_offer_failed_reason',
    slug: 'hlhl_approval'
  },
  {
    name: 'Fee too High',
    value: 'fee_too_high',
    type: 'cash_offer_failed_reason',
    slug: 'fee_too_high'
  },
  {
    name: 'Unresponsive',
    value: 'unresponsive',
    type: 'cash_offer_failed_reason',
    slug: 'unresponsive'
  },
  {
    name: 'Duplicate',
    value: 'duplicate',
    type: 'cash_offer_failed_reason',
    slug: 'duplicate'
  }
]
export const ccCashOfferFailedReasonsSettings = [
  {
    name: 'Unresponsive',
    value: 'unresponsive',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'unresponsive'
  },
  {
    name: 'Not Transacting',
    value: 'not_transacting',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'not_transacting'
  },
  {
    name: 'Duplicate',
    value: 'duplicate',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'duplicate'
  },
  {
    name: 'HLHL Approval',
    value: 'hlhl_approval',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'hlhl_approval'
  },
  {
    name: 'Fee too High',
    value: 'fee_too_high',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'fee_too_high'
  },
  {
    name: 'Using Non-CO Agent',
    value: 'non_co_agent',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'non_co_agent'
  },
  {
    name: 'Lost to Competitor',
    value: 'lost_to_competitor',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'lost_to_competitor'
  },
  {
    name: 'Area Not Serviced',
    value: 'area_not_serviced',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'area_not_serviced'
  },
  {
    name: 'Test Lead',
    value: 'test_lead',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'test_lead'
  },
  {
    name: 'Traditional Offer',
    value: 'traditional_offer',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'traditional_offer'
  },
  {
    name: 'No Show',
    value: 'no_show',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'no_show'
  },
  {
    name: 'Client Cancelled Appointment',
    value: 'client_cancelled_appointment',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'client_cancelled_appointment'
  },
  {
    name: 'Rate',
    value: 'rate',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'rate'
  },
  {
    name: 'Other',
    value: 'other',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'other'
  },
  {
    name: 'Bad Contact Info',
    value: 'bad_contact_info',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'bad_contact_info'
  },
  {
    name: 'Trade-In Failed',
    value: 'trade_in_failed',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'trade_in_failed'
  },
  {
    name: 'Property Value',
    value: 'property_value',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'property_value'
  },
  {
    name: 'Ineligible Property Type',
    value: 'ineligible_property_type',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'ineligible_property_type'
  },
  {
    name: 'Gross Living Area',
    value: 'gross_living_area',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'gross_living_area'
  },
  {
    name: 'Lot Size',
    value: 'lot_size',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'lot_size'
  },
  {
    name: 'Room Count',
    value: 'room_count',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'room_count'
  },
  {
    name: 'Ownership',
    value: 'ownership',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'ownership'
  },
  {
    name: 'Amenities',
    value: 'amenities',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'amenities'
  },
  {
    name: 'Mid-Project Properties',
    value: 'mid_project_properties',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'mid_project_properties'
  },
  {
    name: 'Property Condition',
    value: 'property',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'property'
  },
  {
    name: 'Resale Restrictions',
    value: 'resale_restrictions',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'resale_restrictions'
  },
  {
    name: 'Occupancy',
    value: 'occupancy',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'occupancy'
  },
  {
    name: 'Oil/Gas/Mineral Rights/Leases',
    value: 'oil_gas_mineralrights_leases',
    type: 'cc_cash_offer_lead_failed_reason',
    slug: 'oil_gas_mineralrights_leases'
  }
]
export const mortgageFailedReasonsSettings = [
  {
    name: 'Stopped looking',
    value: 'stopped_looking',
    type: 'mortgage_lead_failed_reason',
    slug: 'stopped_looking'
  },
  {
    name: "Lost to agent's lender",
    value: 'lost_to_agents_lender',
    type: 'mortgage_lead_failed_reason',
    slug: 'lost_to_agents_lender'
  },
  {
    name: "Lost to client's bank",
    value: 'lost_to_clients_bank',
    type: 'mortgage_lead_failed_reason',
    slug: 'lost_to_clients_bank'
  },
  {
    name: 'Lost on rate',
    value: 'lost_on_rate',
    type: 'mortgage_lead_failed_reason',
    slug: 'lost_on_rate'
  },
  {
    name: 'Lost on credits',
    value: 'lost_on_credits',
    type: 'mortgage_lead_failed_reason',
    slug: 'lost_on_credits'
  },
  {
    name: 'Wants FHA',
    value: 'wants_fha',
    type: 'mortgage_lead_failed_reason',
    slug: 'wants_fha'
  },
  {
    name: 'Wants VA',
    value: 'wants_va',
    type: 'mortgage_lead_failed_reason',
    slug: 'wants_va'
  },
  {
    name: 'Wants non-QM',
    value: 'wants_non_qm',
    type: 'mortgage_lead_failed_reason',
    slug: 'wants_non_qm'
  },
  {
    name: 'Wants 3+ borrowers',
    value: 'wants_3_borrowers',
    type: 'mortgage_lead_failed_reason',
    slug: 'wants_3_borrowers'
  },
  {
    name: 'Wants other product',
    value: 'wants_other_product',
    type: 'mortgage_lead_failed_reason',
    slug: 'wants_other_product'
  },
  {
    name: 'Called too slow',
    value: 'called_too_slow',
    type: 'mortgage_lead_failed_reason',
    slug: 'called_too_slow'
  },
  {
    name: 'Not interested',
    value: 'not_interested',
    type: 'mortgage_lead_failed_reason',
    slug: 'not_interested'
  },
  {
    name: 'Out of HLHL service area',
    value: 'out_of_hlhl_service_area',
    type: 'mortgage_lead_failed_reason',
    slug: 'out_of_hlhl_service_area'
  },
  {
    name: 'Duplicate',
    value: 'duplicate',
    type: 'mortgage_lead_failed_reason',
    slug: 'duplicate'
  },
  {
    name: 'Test Lead',
    value: 'test_lead',
    type: 'mortgage_lead_failed_reason',
    slug: 'test_lead'
  },
  {
    name: 'No Show',
    value: 'no_show',
    type: 'mortgage_lead_failed_reason',
    slug: 'no_show'
  },
  {
    name: 'Client Cancelled Appointment',
    value: 'client_cancelled_appointment',
    type: 'mortgage_lead_failed_reason',
    slug: 'client_cancelled_appointment'
  },
  { name: 'Rate', value: 'rate', type: 'mortgage_lead_failed_reason', slug: 'rate' },
  { name: 'Other', value: 'other', type: 'mortgage_lead_failed_reason', slug: 'other' }
]
export const hlSimpleSaleFailedReasonsSettings = [
  {
    name: 'Unresponsive',
    value: 'unresponsive',
    type: 'hl_simple_sale_failed_reason',
    slug: 'unresponsive'
  },
  {
    name: 'Not Transacting',
    value: 'not_transacting',
    type: 'hl_simple_sale_failed_reason',
    slug: 'not_transacting'
  },
  {
    name: 'Duplicate',
    value: 'duplicate',
    type: 'hl_simple_sale_failed_reason',
    slug: 'duplicate'
  },
  {
    name: 'Market',
    value: 'market',
    type: 'hl_simple_sale_failed_reason',
    slug: 'market'
  },
  {
    name: 'Offer Too Low',
    value: 'offer_too_low',
    type: 'hl_simple_sale_failed_reason',
    slug: 'offer_too_low'
  },
  {
    name: 'Lost to Competitor',
    value: 'lost_to_competitor',
    type: 'hl_simple_sale_failed_reason',
    slug: 'lost_to_competitor'
  },
  {
    name: 'Area Not Serviced',
    value: 'area_not_serviced',
    type: 'hl_simple_sale_failed_reason',
    slug: 'area_not_serviced'
  },
  {
    name: 'Non-HLSS Agent',
    value: 'non_hlss_agent',
    type: 'hl_simple_sale_failed_reason',
    slug: 'non_hlss_agent'
  },
  {
    name: 'Already Listed',
    value: 'already_listed',
    type: 'hl_simple_sale_failed_reason',
    slug: 'already_listed'
  },
  {
    name: 'Traditional Listing',
    value: 'traditional_listing',
    type: 'hl_simple_sale_failed_reason',
    slug: 'traditional_listing'
  },
  {
    name: 'Listing Price',
    value: 'listing_price',
    type: 'hl_simple_sale_failed_reason',
    slug: 'listing_price'
  },
  {
    name: 'Test Lead',
    value: 'test_lead',
    type: 'hl_simple_sale_failed_reason',
    slug: 'test_lead'
  },
  {
    name: 'No Show',
    value: 'no_show',
    type: 'hl_simple_sale_failed_reason',
    slug: 'no_show'
  },
  {
    name: 'Client Cancelled Appointment',
    value: 'client_cancelled_appointment',
    type: 'hl_simple_sale_failed_reason',
    slug: 'client_cancelled_appointment'
  },
  {
    name: 'Other',
    value: 'other',
    type: 'hl_simple_sale_failed_reason',
    slug: 'other'
  },
  {
    name: 'Timeline',
    value: 'timeline',
    type: 'hl_simple_sale_failed_reason',
    slug: 'timeline'
  },
  {
    name: 'Used Trade-In',
    value: 'used_trade_in',
    type: 'hl_simple_sale_failed_reason',
    slug: 'used_trade_in'
  },
  {
    name: 'Bad Contact Info',
    value: 'bad_contact_info',
    type: 'hl_simple_sale_failed_reason',
    slug: 'bad_contact_info'
  },
  {
    name: 'Used HLSS 2-Check',
    value: 'used_hlss_2Check',
    type: 'hl_simple_sale_failed_reason',
    slug: 'used_hlss_2Check'
  },
  {
    name: 'Property Value',
    value: 'property_value',
    type: 'hl_simple_sale_failed_reason',
    slug: 'property_value'
  },
  {
    name: 'Ineligible Property Type',
    value: 'ineligible_property_type',
    type: 'hl_simple_sale_failed_reason',
    slug: 'ineligible_property_type'
  },
  {
    name: 'Listed Properties',
    value: 'listed_properties',
    type: 'hl_simple_sale_failed_reason',
    slug: 'listed_properties'
  },
  {
    name: 'Property Age',
    value: 'property_age',
    type: 'hl_simple_sale_failed_reason',
    slug: 'property_age'
  },
  {
    name: 'Gross Living Area',
    value: 'gross_living_area',
    type: 'hl_simple_sale_failed_reason',
    slug: 'gross_living_area'
  },
  {
    name: 'Long Days on Market',
    value: 'long_days_on_market',
    type: 'hl_simple_sale_failed_reason',
    slug: 'long_days_on_market'
  },
  {
    name: 'Lot Size',
    value: 'lot_size',
    type: 'hl_simple_sale_failed_reason',
    slug: 'lot_size'
  },
  {
    name: 'Room Count',
    value: 'room_count',
    type: 'hl_simple_sale_failed_reason',
    slug: 'room_count'
  },
  {
    name: 'Repair Budget',
    value: 'repair_budget',
    type: 'hl_simple_sale_failed_reason',
    slug: 'repair_budget'
  },
  {
    name: 'Ownership',
    value: 'ownership',
    type: 'hl_simple_sale_failed_reason',
    slug: 'ownership'
  },
  {
    name: 'Amenities',
    value: 'amenities',
    type: 'hl_simple_sale_failed_reason',
    slug: 'amenities'
  },
  {
    name: 'Mid-Project Properties',
    value: 'mid_project_properties',
    type: 'hl_simple_sale_failed_reason',
    slug: 'mid_project_properties'
  },
  {
    name: 'Property Condition',
    value: 'property',
    type: 'hl_simple_sale_failed_reason',
    slug: 'property'
  },
  {
    name: 'Resale Restrictions',
    value: 'resale_restrictions',
    type: 'hl_simple_sale_failed_reason',
    slug: 'resale_restrictions'
  },
  {
    name: 'Oil/Gas/Mineral Rights/Leases',
    value: 'oil_gas_mineralrights_leases',
    type: 'hl_simple_sale_failed_reason',
    slug: 'oil_gas_mineralrights_leases'
  },
  {
    name: 'Solar Lien',
    value: 'solar_lien',
    type: 'hl_simple_sale_failed_reason',
    slug: 'solar_lien'
  },
  {
    name: 'Short sale/foreclosure',
    value: 'short_sale_foreclosure',
    type: 'hl_simple_sale_failed_reason',
    slug: 'short_sale_foreclosure'
  },
  {
    name: 'Occupancy',
    value: 'occupancy',
    type: 'hl_simple_sale_failed_reason',
    slug: 'occupancy'
  }
]
