// @ts-strict
import { ButtonForm, FormField, Section, Sections, TextArea } from 'components'
import { TaskCompleteAction } from 'content/Tasks'
import { Task } from 'models'
import styles from './TaskCompleteButtonForm.module.scss'
import { useTaskCompleteButtonForm } from './useTaskCompleteButtonForm'

type TProps = {
  disabled?: boolean
  onSuccessCallback?: Function
  task: Task
}

export const TaskCompleteButtonForm = ({ disabled, task, onSuccessCallback }: TProps) => {
  const { formProps, payload, onChangeAttribute } = useTaskCompleteButtonForm(
    task,
    onSuccessCallback
  )

  return (
    <ButtonForm
      title="Complete Task"
      {...formProps}
      buttonComponent={buttonProps => <TaskCompleteAction {...buttonProps} disabled={disabled} />}
    >
      <Sections>
        <Section>
          <FormField title="Notes (optional)" htmlFor="notes">
            <TextArea
              id="notes"
              data-testid="completion-note-field"
              value={payload.completionNote}
              className={styles.note}
              onChange={val => onChangeAttribute('completionNote', val)}
            />
          </FormField>
        </Section>
      </Sections>
    </ButtonForm>
  )
}
