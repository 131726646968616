import { ButtonHTMLAttributes, ReactNode } from 'react'
import { Spinner } from 'components/Graphic'
import { cx } from 'utils'
import styles from './ButtonV2.module.scss'

export interface ButtonV2Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
  className?: string
  disabled?: boolean
  isLoading?: boolean
}

export const ButtonV2 = ({ children, className, disabled, isLoading, ...props }: ButtonV2Props) => {
  return (
    <button disabled={disabled || isLoading} className={cx(styles.button, className)} {...props}>
      {isLoading && <Spinner height={16} width={16} />}
      {children}
    </button>
  )
}
