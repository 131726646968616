import { CurrentUser } from 'models'
import { CurrentUserSchema, getHapiRequestParams } from 'schema'
import { getCurrentUserUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'

const currentUserShape: CurrentUserSchema = {
  user: [
    'available',
    'docusignLoggedIn',
    'email',
    'firstName',
    'impersonated',
    'lastName',
    'phoneTwilio',
    { roles: ['name'] },
    {
      agentAeAreas: [
        { 'assignedArea:metroplex': ['name', 'slug', { state: ['name', 'code'] }] },
        { 'assignedArea:state': ['name', 'code'] }
      ]
    },
    { teamMemberships: ['id', 'role'] },
    { userIntegrations: ['id', 'name'] }
  ]
}

export const fetchCurrentUser = async (): Promise<CurrentUser> => {
  const response = await getRequest(
    getCurrentUserUrl({
      searchParams: getHapiRequestParams(currentUserShape)
    })
  )
  return await deserializeAsync(response.data, { transform: CurrentUser.create })
}
