import { Button } from '@foundation/components'
import { useStopLeadNurtureController } from 'controllers'
import { Lead } from 'models'
import { StopLeadNurtureButtonWrapper } from './styles'

type StopLeadNurtureProps = {
  lead: Lead
}

export const StopLeadNurture = ({ lead }: StopLeadNurtureProps) => {
  const { stopLeadNurture } = useStopLeadNurtureController(lead.id)

  return (
    <StopLeadNurtureButtonWrapper>
      <Button
        size="xs"
        onClick={() => stopLeadNurture.mutate()}
        isLoading={stopLeadNurture.isLoading}
        isDisabled={stopLeadNurture.isSuccess}
        color="danger"
        variant="solid"
      >
        Stop Nurture
      </Button>
    </StopLeadNurtureButtonWrapper>
  )
}
