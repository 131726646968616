import { Contents, Page } from 'components'
import { CreateTeamButtonForm } from 'content/Team/TeamButtonForm/CreateTeamButtonForm'
import { TeamsProvider } from 'contexts'
import { SettlementAgencyTeamsList } from 'features/settlementAgency/listSettlementAgencyTeams'
import { useDocumentTitle } from 'hooks'

const TEAM_TYPE = 'SettlementAgencyTeam'

export const SettlementAgencyTeamsPage = () => {
  useDocumentTitle('Teams')

  return (
    <TeamsProvider type={TEAM_TYPE}>
      <Page>
        <Contents title="Settlement Agency Teams" actions={<CreateTeamButtonForm />}>
          <SettlementAgencyTeamsList />
        </Contents>
      </Page>
    </TeamsProvider>
  )
}
