// @ts-strict
import { Table, TableResults } from 'components'
import { useListContext } from 'contexts'
import { ExternalLink, Lead } from 'models'
import { ExternalLinksTableHeader } from './ExternalLinksTableHeader'
import { ExternalLinksTableRow } from './ExternalLinksTableRow'

type Props = {
  externalLinks: ExternalLink[]
  isLoading: boolean
  lead: Lead
}

export const ExternalLinksTable = ({ externalLinks, isLoading, lead }: Props) => {
  const { showColumn } = useListContext()
  return (
    <Table data-testid="external-resources-table">
      <ExternalLinksTableHeader />
      <TableResults
        type="External Resources"
        colSpan={showColumn('incomingResidence') ? 8 : 6}
        isLoading={isLoading}
      >
        {externalLinks?.map(resource => (
          <ExternalLinksTableRow externalLink={resource} key={resource.id} lead={lead} />
        ))}
      </TableResults>
    </Table>
  )
}
