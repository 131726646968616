import { Section, Sections } from 'components'
import { FieldFormField } from '../../FieldFormField'
import { useFields } from '../../useHLSSLeadDisposition'

export const HLSSListed = () => {
  useFields({
    required: ['departingPropertyListingPrice', 'departingPropertyListedDate']
  })
  return (
    <Sections title="DR Sale Transaction">
      <Section>
        <FieldFormField field="departingPropertyListingPrice" />
      </Section>
      <Section>
        <FieldFormField field="departingPropertyListedDate" />
      </Section>
    </Sections>
  )
}
