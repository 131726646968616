// @ts-strict
import {
  Block,
  Contents,
  ExternalLink,
  FormField,
  FormFieldSet,
  List,
  MapBox,
  Section,
  Sections
} from 'components'
import {
  getAgentFieldName,
  getAgentTeamFieldName,
  getEscrowLeadFieldName,
  getOrderFieldName,
  getSettlementAgencyTeamMemberNamesByRole
} from 'lookups'
import { EscrowLead } from 'models'
import {
  dataOrDash,
  formatDate,
  formatMoney,
  formatOrDash,
  formatPhone,
  presence,
  titleize,
  toSearchParam
} from 'utils'
import { EscrowOfficeLeadDetailsSection } from './Escrow'

type TProps = {
  lead: EscrowLead
}

export const EscrowLeadDetails = ({ lead }: TProps) => {
  return (
    <Contents>
      <Sections title={`Escrow (${formatOrDash(lead.stage, titleize)})`}>
        <Section>
          <FormFieldSet>
            <FormField title={getEscrowLeadFieldName('createdAt')} inline>
              {formatOrDash(lead.createdAt, formatDate)}
            </FormField>
            <FormField title={getEscrowLeadFieldName('leadUsersNamesString')} inline>
              {dataOrDash(lead.leadUsersNamesString)}
            </FormField>
            <FormField title={getOrderFieldName('source')} inline>
              {formatOrDash(lead.order?.source, titleize)}
            </FormField>
            <FormField title={getEscrowLeadFieldName('secondaryUserType')} inline>
              {formatOrDash(lead?.secondaryUserType, titleize)}
            </FormField>
            <FormField title={getEscrowLeadFieldName('price')} inline>
              {formatOrDash(lead.price, formatMoney)}
            </FormField>
          </FormFieldSet>
        </Section>
        <Section>
          <FormField title={getAgentFieldName('fullName')} inline>
            {dataOrDash(lead?.referringAgent?.fullName)}
          </FormField>
          <FormField title={getAgentTeamFieldName('name')} inline>
            {dataOrDash(lead?.referringAgent?.agentTeamName)}
          </FormField>
          <FormField title={getAgentFieldName('email1')} inline>
            {dataOrDash(lead?.referringAgent?.email1)}
          </FormField>
          <FormField title={getAgentFieldName('phone')} inline>
            {formatOrDash(lead?.referringAgent?.getPhoneMobile()?.number, formatPhone)}
          </FormField>
          <FormField title={getAgentFieldName('licenseNumber')} inline>
            {dataOrDash(lead?.referringAgent?.licenseNumber)}
          </FormField>
          <FormField title="Branch Manager" inline>
            <div>
              {getSettlementAgencyTeamMemberNamesByRole(
                'branch_manager',
                lead.referringAgent?.settlementAgencyTeam?.teamMemberships
              ).map(name => (
                <div key={name}>{name}</div>
              ))}
            </div>
          </FormField>
          <FormField title="State Manager" inline>
            <div>
              {getSettlementAgencyTeamMemberNamesByRole(
                'state_manager',
                lead.referringAgent?.settlementAgencyTeam?.teamMemberships
              ).map(name => (
                <div key={name}>{name}</div>
              ))}
            </div>
          </FormField>
          <FormField title="Escrow Officer" inline>
            <div>
              {getSettlementAgencyTeamMemberNamesByRole(
                'escrow_officer',
                lead.referringAgent?.settlementAgencyTeam?.teamMemberships
              ).map(name => (
                <div key={name}>{name}</div>
              ))}
            </div>
          </FormField>
          <FormField title="Preferred Escrow Officer" inline>
            {dataOrDash(lead?.referringAgent?.preferredEscrowOfficer?.name)}
          </FormField>
          <FormField title="Escrow Assistant" inline>
            <div>
              {getSettlementAgencyTeamMemberNamesByRole(
                'escrow_assistant',
                lead.referringAgent?.settlementAgencyTeam?.teamMemberships
              ).map(name => (
                <div key={name}>{name}</div>
              ))}
            </div>
          </FormField>
        </Section>
      </Sections>
      <Sections title="Property Details">
        <Section>
          <FormField title={getEscrowLeadFieldName('fullAddress')} inline>
            <Block title={lead.fullAddress} />
          </FormField>
          <FormField title="External Links" inline>
            <List>
              <ExternalLink
                href={`https://www.google.com/search?q=${toSearchParam(lead.fullAddress)}`}
              >
                Google Address
              </ExternalLink>
              <ExternalLink
                href={`http://www.zillow.com/homes/${toSearchParam(lead.fullAddress)}_rb`}
              >
                Zillow Address
              </ExternalLink>
            </List>
          </FormField>
        </Section>
        <Section>
          {lead.latitude && lead.longitude ? (
            <MapBox center={lead.toPoint()} markers={lead.toMarker()} />
          ) : (
            <FormField title="Map" inline>
              Latitude and Longitude are missing
            </FormField>
          )}
        </Section>
      </Sections>
      {presence(lead.escrowOfficeProviderLeads) && <EscrowOfficeLeadDetailsSection lead={lead} />}
    </Contents>
  )
}
