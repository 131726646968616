import { Table, TableResults } from 'components'
import { Task } from 'models'
import { TaskTableHeader } from './TaskTableHeader'
import { TaskTableRow } from './TaskTableRow'

type Props = {
  isLoading: boolean
  tasks?: Task[]
}

export const TaskTable = ({ tasks, isLoading }: Props) => {
  return (
    <>
      <Table data-testid="tasksTable">
        <TaskTableHeader />
        <TableResults type="Tasks" colSpan={5} isLoading={isLoading}>
          {tasks?.map(task => (
            <TaskTableRow task={task} key={task.id} />
          ))}
        </TableResults>
      </Table>
    </>
  )
}
