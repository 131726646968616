// @ts-strict
import { FormField, Section, Select, TButtonFormFieldsProps, TextInput } from 'components'
import { useListBlendUsersByRole } from 'features/blendUsers'
import { integrationOptions } from 'lookups'
import { BlendUserInterface, BlendUserModel } from 'models/v2/BlendUser'
import { TUserIntegrationPayload } from 'services'

type TProps = {
  fieldsProps: TButtonFormFieldsProps<TUserIntegrationPayload>
  nameDisabled?: boolean
}

export const UserIntegrationFields = ({ fieldsProps, nameDisabled }: TProps) => {
  const { payload, onChangeAttribute } = fieldsProps
  const isPayloadBlendUser = payload.name === 'blend_user_id'
  const blendUsers = useListBlendUsersByRole({ roles: 'Loan Officer', enabled: isPayloadBlendUser })
  const blendUserOptions = blendUsers.data?.map((blendUser: BlendUserInterface) =>
    BlendUserModel.mapToOption(blendUser)
  )

  return (
    <>
      <Section>
        <FormField htmlFor="name-field" title="Name" data-testid="name-form" required>
          <Select
            addBlank
            disabled={nameDisabled}
            options={integrationOptions}
            id="name-field"
            data-testid="name-field"
            onChange={val => onChangeAttribute('name', val as TUserIntegrationPayload['name'])}
            value={payload.name}
          />
        </FormField>
      </Section>

      <Section>
        {isPayloadBlendUser && (
          <FormField
            htmlFor="value-field"
            title="Blend Users"
            data-testid="value-blend-form"
            required
          >
            <Select
              addBlank
              options={blendUserOptions}
              id="value-field"
              isLoading={blendUsers.isLoading}
              data-testid="value-field"
              onChange={val => onChangeAttribute('value', val)}
              value={payload.value}
            />
          </FormField>
        )}
        {payload.name !== 'blend_user_id' && (
          <FormField htmlFor="value-field" title="Value" data-testid="value-default-form" required>
            <TextInput
              id="value-field"
              data-testid="value-field"
              onChange={val => onChangeAttribute('value', val)}
              value={payload.value}
            />
          </FormField>
        )}
      </Section>
    </>
  )
}
