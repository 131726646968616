// @ts-strict

import { ReactNode } from 'react'
import { PageContents, PageHeader, PageSubTitle, PageTitle } from 'components/Page'
import { cx } from 'utils'
import styles from './Page.module.scss'

export type TPageProps = {
  actions?: ReactNode
  children?: ReactNode
  className?: string
  nopad?: boolean
  subtitle?: ReactNode
  title?: ReactNode
}

export const Page = ({ className, title, subtitle, actions, nopad, children }: TPageProps) => {
  let pageHeader
  if (title || actions) {
    pageHeader = (
      <PageHeader>
        <PageTitle>
          {title}
          {subtitle ? <PageSubTitle>{subtitle}</PageSubTitle> : null}
        </PageTitle>
        {actions}
      </PageHeader>
    )
  }

  return (
    <div className={cx(nopad ? null : styles.pad, styles.page, className)} data-testid="page">
      {pageHeader}
      <PageContents>{children}</PageContents>
    </div>
  )
}
