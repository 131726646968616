// @ts-strict

import { useEffect } from 'react'
import { Contents, LoadingText } from 'components'
import { LeadUsersList, LeadUsersNewLeadUserButtonForm } from 'content/LeadUsers'
import { LeadTeamProvider, useLeadContext, useLeadTeamContext } from 'contexts'
import { useDocumentTitle } from 'hooks'

const LeadTeamTabContents = () => {
  const { leadTeamMembers, isLoading } = useLeadTeamContext()

  if (isLoading) {
    return <LoadingText />
  }

  const actions = <LeadUsersNewLeadUserButtonForm />

  return (
    <Contents title="Team Members" actions={actions}>
      <LeadUsersList leadUsers={leadTeamMembers} />
    </Contents>
  )
}

export const LeadTeamTab = () => {
  const { lead } = useLeadContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${lead?.name} - Lead Team`), [lead, setTitle])

  return (
    <LeadTeamProvider leadId={lead?.id}>
      <LeadTeamTabContents />
    </LeadTeamProvider>
  )
}
