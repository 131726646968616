import { useEffect } from 'react'
import { ActionBar, Button, Contents, LoadingText } from 'components'
import { AgentMessageButtonForm } from 'content/Agent'
import { MessagesList } from 'content/Messages'
import { useAgentContext } from 'contexts/Agent'
import { useInboxContext } from 'contexts/Inbox'
import { useDocumentTitle } from 'hooks'

const AgentMessagesTabContents = ({ agent, isLoading }) => {
  const { getContact, markRead } = useInboxContext()

  if (!agent) {
    return null
  }

  const contact = (agent && getContact(agent.id)) || {
    messages: [],
    lastReadAt: new Date().toString(),
    agent
  }

  const actions = (
    <ActionBar>
      {contact.messages.length ? (
        <Button onClick={() => markRead(agent.id)} as="link">
          Mark as Read
        </Button>
      ) : null}
      <AgentMessageButtonForm agent={agent} />
    </ActionBar>
  )

  return (
    <>
      <Contents title="Messages" actions={actions}>
        {isLoading ? (
          <LoadingText />
        ) : (
          <MessagesList messages={contact.messages} newMessagesAt={contact.lastReadAt} />
        )}
      </Contents>
    </>
  )
}

export const AgentMessagesTab = () => {
  const { agent, isLoading } = useAgentContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${agent.fullName} - Agent Messages`), [agent, setTitle])

  return <AgentMessagesTabContents agent={agent} isLoading={isLoading} />
}
