// @ts-strict
import { Icon } from '@foundation/components'
import { Block, TableCell as Td } from 'components'
import { LeadBadge, LeadSummaryCard } from 'content/Lead'
import { LeadNamePartnerCell } from 'content/Leads'
import { useFeatureFlags } from 'hooks'
import { BuyerLead, SellerLead } from 'models'
import { d, dataOrDash, DEFAULT_STRING, titleize } from 'utils'
import * as S from './styles'

type Props = {
  lead: BuyerLead | SellerLead
  testId: string
}

export const BuyerAndSellerLeadListingStatusCell = ({ lead, testId }: Props) => (
  <Td data-testid={testId}>
    <Block allowTitleOverflow title={dataOrDash(titleize(lead.externalListing?.status))} />
  </Td>
)

export const BuyerAndSellerLeadPendingActionCell = ({ lead, testId }: Props) => {
  const pendingActions = lead?.pendingActions ?? []
  const reviewsNames: string[] = pendingActions.map(action => d(action.name))

  return (
    <Td data-testid={testId}>
      {reviewsNames.length ? (
        <S.Flex>
          <S.IconWrapper>
            <Icon size="lg" path="VisibilityOutlined" />
          </S.IconWrapper>
          <S.ActionName>
            {reviewsNames.map(name => (
              <S.Block key={name} title={dataOrDash(name)} />
            ))}
          </S.ActionName>
        </S.Flex>
      ) : (
        <Block allowTitleOverflow title={DEFAULT_STRING} />
      )}
    </Td>
  )
}

export const BuyerAndSellerLeadNamePartnerWithLink = ({ lead, testId }: Props) => {
  const { salesAppBuyersSellersPendingActions } = useFeatureFlags([
    'sales-app-buyers-sellers-pending-actions'
  ])
  return salesAppBuyersSellersPendingActions.enabled ? (
    <Td data-testid={testId}>
      <S.NamePartnerBlockCell
        title={dataOrDash(lead && <LeadSummaryCard lead={lead} />)}
        allowTitleOverflow
        image={<LeadBadge lead={lead} />}
      />
    </Td>
  ) : (
    <LeadNamePartnerCell lead={lead} testId={testId} />
  )
}
