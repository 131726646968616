import { uploadToS3 } from 'services'
import { getFilesUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export const uploadFiles = async (files: File[]) => {
  const documentUploads = await uploadToS3(files, 'document')

  const {
    data: { data: documents }
  } = await postRequest(
    getFilesUrl(),
    snakeCaseKeys({
      category: 'hlcs_purchase_contract',
      files: documentUploads
    })
  )

  return documents
}
