// @ts-strict

import { SETTLEMENT_AGENCY_TEAM_ROLE_CACHE_KEY, useLookupsController } from 'hooks'
import { TeamMembership } from 'models'

type SettlementAgencyTeamMembersCSVProps = {
  members: Potential<TeamMembership[]>
}

export const SettlementAgencyTeamMembersCSV = ({
  members
}: SettlementAgencyTeamMembersCSVProps) => {
  const [, findTeamRole] = useLookupsController(SETTLEMENT_AGENCY_TEAM_ROLE_CACHE_KEY)
  if (!members || !members.length) {
    return null
  }

  return (
    <>
      {members
        .map(member => {
          const role = findTeamRole(member.role)
          return `${member.user.name}${role?.name ? ` (${role.name})` : ''}`
        })
        .join(', ')}
    </>
  )
}
