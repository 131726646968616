import { HTMLAttributes, useEffect, useRef, useState } from 'react'
import { IconButton, Text } from '@foundation/components'
import { IconPathKeys } from '@foundation/icons'
import * as S from './styles'
import { calculateTimeBySeconds } from './utils'

export type AudioPlayerProps = {
  /**
   * For testing purposes
   */
  'data-testid'?: string
  /**
   * The location (URL) of the audio file
   */
  src?: string
} & Pick<HTMLAttributes<HTMLDivElement>, 'onClick'>

/**
 * this component was created following those tutorials:
 * https://css-tricks.com/using-requestanimationframe-with-react-hooks/
 * https://css-tricks.com/lets-create-a-custom-audio-player/
 */
export const AudioPlayer = ({
  src,
  onClick,
  'data-testid': dataTestId = 'audioPlayer'
}: AudioPlayerProps) => {
  const audioElementRef = useRef<HTMLAudioElement>(null)
  const audioElement = audioElementRef?.current
  const animationFrameRef = useRef<any>(null) // requestAnimationFrame
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [maxDuration, setMaxDuration] = useState(0)
  const isInfinityDuration = duration === Infinity // some audios can have its duration as Infinity
  const isDisabled = !src

  const whilePlaying = () => {
    if (audioElement) {
      const currentTimeFloor = Math.floor(audioElement.currentTime)
      setCurrentTime(currentTimeFloor)

      if (isInfinityDuration) {
        // follow the current time if there's no max duration
        setMaxDuration(currentTimeFloor)
      }

      // change the state according to the animation
      animationFrameRef.current = requestAnimationFrame(whilePlaying)
    }
  }

  useEffect(() => {
    return () => cancelAnimationFrame(animationFrameRef.current)
  }, [])

  const togglePlayPause = () => {
    setIsPlaying(prevValue => !prevValue)

    if (!isPlaying) {
      audioElement?.play()
      animationFrameRef.current = requestAnimationFrame(whilePlaying)
    } else {
      audioElement?.pause()
      cancelAnimationFrame(animationFrameRef.current)
    }
  }

  const onEndedAudio = () => {
    if (audioElement) {
      audioElement.currentTime = 0
      setCurrentTime(0)
      setIsPlaying(false)
      cancelAnimationFrame(animationFrameRef.current)
    }
  }

  const handleChangeSlider = ([sliderValue]: number[]) => {
    if (audioElement) {
      setCurrentTime(Number(sliderValue))

      if (!audioElement.paused) {
        // stop the process while the user is sliding
        cancelAnimationFrame(animationFrameRef.current)
      }
    }
  }

  const handleValueCommitSlider = ([sliderValue]: number[]) => {
    if (audioElement) {
      audioElement.currentTime = Number(sliderValue)

      if (!audioElement.paused) {
        // resume the process when the user stops sliding
        animationFrameRef.current = requestAnimationFrame(whilePlaying)
      }
    }
  }

  const onLoadedMetadata = (metadata: any) => {
    // it cannot be readyState = 0. We want metadata which can be readyState = 1 or higher.
    if (!metadata?.target?.readyState) {
      return
    }

    const seconds = Math.floor(metadata.target.duration)
    setDuration(seconds)
    setMaxDuration(seconds)
  }

  const buttonTitle = isPlaying ? 'Pause' : 'Play'
  const buttonIcon: IconPathKeys = isPlaying ? 'Pause' : 'PlayArrow'
  const durationText = !isInfinityDuration ? `/ ${calculateTimeBySeconds(duration)}` : ''

  return (
    <S.Wrapper data-testid={dataTestId} disabled={isDisabled} onClick={onClick}>
      {!isDisabled && (
        <IconButton
          title={buttonTitle}
          icon={buttonIcon}
          onClick={togglePlayPause}
          size="xs"
          isRounded
        />
      )}
      <audio
        data-testid="audio"
        ref={audioElementRef}
        src={src}
        preload="metadata"
        onLoadedMetadata={onLoadedMetadata}
        onEnded={onEndedAudio}
      />
      <S.Slider
        value={[currentTime]}
        max={maxDuration}
        step={1}
        onValueChange={handleChangeSlider}
        onValueCommit={handleValueCommitSlider}
      >
        <S.SliderTrack data-testid="audioPlayerSliderTrack" disabled={isDisabled}>
          <S.SliderRange />
        </S.SliderTrack>
        {!isDisabled && <S.SliderThumb data-testid="audioPlayerSliderThumb" />}
      </S.Slider>
      <Text size="xs" color="neutral9">
        {`${calculateTimeBySeconds(currentTime)} ${durationText}`}
      </Text>
    </S.Wrapper>
  )
}
