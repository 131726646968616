export const searchFilterSettings = [
  { slug: 'aseId', name: 'ASE', getDisplayValueFnName: 'getUserName' },
  { slug: 'asmId', name: 'ASM', getDisplayValueFnName: 'getUserName' },
  { slug: 'assignedAreaId', name: 'Assigned Area', getDisplayValueFnName: 'getAssignedAreaName' },
  { slug: 'assignerId', name: 'Assigner', getDisplayValueFnName: 'getUserName' },
  {
    slug: 'assigneeId',
    name: 'Assigned to',
    getDisplayValueFnName: 'getUserName'
  },
  {
    slug: 'preferredEscrowOfficerUserId',
    name: 'Preferred Escrow Officer',
    getDisplayValueFnName: 'getUserName'
  },
  {
    slug: 'enrollmentProgram',
    name: 'Enrollment Program',
    getDisplayValueFnName: 'titleize'
  },
  {
    slug: 'enrollmentStatus',
    name: 'Enrollment Status',
    getDisplayValueFnName: 'titleize'
  },
  { slug: 'minPrice', name: 'With min price', getDisplayValueFnName: 'formatPrice' },
  { slug: 'maxPrice', name: 'With max price', getDisplayValueFnName: 'formatPrice' },
  {
    slug: 'daysAgo',
    name: 'Updated within the past',
    getDisplayValueFnName: 'buildDaysAgoString'
  },
  {
    slug: 'marketplacePrograms',
    name: 'Programs',
    options: [{ name: 'HL Simple Sale', value: 'hlss' }]
  },
  {
    slug: 'usStateId',
    name: 'State',
    getDisplayValueFnName: 'getUsStateName'
  },
  {
    slug: 'queue',
    name: 'Queue',
    options: [{ name: 'In Your Queue', value: 'true' }]
  },
  {
    slug: 'templateSlug',
    name: 'Template'
  },
  {
    slug: 'valuation',
    name: 'Valuation Done'
  },
  {
    slug: 'inspected',
    name: 'Inspected'
  },
  {
    slug: 'assigned',
    name: 'Assigned'
  },
  {
    slug: 'inContract',
    name: 'In Contract'
  },
  {
    slug: 'agentPartnership',
    name: 'Partnership',
    getDisplayValueFnName: 'getPartnershipName'
  },
  { slug: 'orderStage', name: 'Order Stage' },
  { slug: 'activeIRStages', name: 'Active IR Stage' },
  {
    slug: 'protectedOfferStatus',
    name: 'Protected Offer Status',
    options: [
      { value: 'protected_without_cash_offer', name: 'Without Cash Offer' },
      { value: 'protected_with_cash_offer', name: 'With Cash Offer' }
    ]
  },
  { slug: 'tradeInLeadStages', name: 'Buy Before You Sell Stage', getDisplayValueFnName: 'd' },
  { slug: 'cashOfferLeadStages', name: 'Cash Offer Stage', getDisplayValueFnName: 'd' },
  { slug: 'mortgageLeadStages', name: 'Mortgage Stage', getDisplayValueFnName: 'd' },
  { slug: 'activeCashOfferStage', name: 'Active Cash Offer Stage' },
  { slug: 'product', name: 'Product' },
  { slug: 'source', name: 'Source' },
  { slug: 'warmTransfer', name: 'Warm Transfer' },
  { slug: 'calendly', name: 'Calendly' },
  { slug: 'moveSafe', name: 'Move Safe' },
  { slug: 'eliteStatus', name: 'Elite Status' },
  { slug: 'leadStages', name: 'With Leads in', getDisplayValueFnName: 'd' },
  { slug: 'userType', name: 'With Leads of type' },
  { slug: 'state', name: 'With State' },
  { slug: 'escrowAttached', name: 'With Escrow attached' },
  {
    slug: 'completionStatus',
    name: 'Completion Status',
    getDisplayValueFnName: 'getTaskStatusName'
  },
  {
    slug: 'creationType',
    name: 'Creation Type'
  },
  { slug: 'tradeInSimpleSale', name: 'With HL Simple Sale' },
  { slug: 'tradeInSimpleSale', name: 'With HL Simple Sale' },
  { slug: 'pitchOppProduct', name: 'Pitch Status Program' },
  {
    slug: 'oppStatus',
    name: 'With Opportunities',
    getDisplayValueFnName: 'getOpportunityStatusName'
  },
  {
    slug: 'transactionTeamMember',
    name: 'With Team Member',
    getDisplayValueFnName: 'getTransactionTeamMemberName'
  },
  {
    slug: 'categories',
    name: 'Categories',
    getDisplayValueFnName: 'getTaskCategoryName'
  },
  {
    slug: 'requiredForLeadStage',
    name: 'Required for Stage'
  },
  {
    slug: 'metricsTimeRange',
    name: 'Metrics Time Range'
  },
  {
    slug: 'tierStatus',
    name: 'Tier Status'
  }
] as const
