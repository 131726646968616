import { DEFAULT_STRING } from '../constants'

export function dataOrDash(data: any) {
  return (
    ((data === null ||
      data === undefined ||
      data === '' ||
      data?.toString().trim() === '' ||
      Number.isNaN(data)) &&
      DEFAULT_STRING) ||
    data
  )
}
