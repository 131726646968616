import { Section } from 'components'
import { useCashOfferDispositionContext } from '../CashOfferDispositionContext'
import { FieldFormField } from '../FieldFormField'
import { useFields } from '../useFields'

export const CashOfferClosedPurchase = () => {
  const { changes, cashOfferLead } = useCashOfferDispositionContext()
  const requireChangeReason =
    cashOfferLead.incomingPropertyCloseOfPurchaseEscrowAt &&
    changes.incomingPropertyCloseOfPurchaseEscrowAt

  useFields({
    fields: [
      'incomingPropertyFundsWiredToSellerAt',
      'incomingPropertyCloseOfPurchaseEscrowAt',
      requireChangeReason ? 'changeReasons.incomingPropertyCloseOfPurchaseEscrowAt.note' : undefined
    ],
    required: requireChangeReason
      ? ['changeReasons.incomingPropertyCloseOfPurchaseEscrowAt.reason']
      : []
  })

  return (
    <Section>
      <FieldFormField field="incomingPropertyFundsWiredToSellerAt" />
      <FieldFormField field="incomingPropertyCloseOfPurchaseEscrowAt" />
      {requireChangeReason && (
        <>
          <FieldFormField field="changeReasons.incomingPropertyCloseOfPurchaseEscrowAt.reason" />
          <FieldFormField field="changeReasons.incomingPropertyCloseOfPurchaseEscrowAt.note" />
        </>
      )}
    </Section>
  )
}
