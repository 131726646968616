// @ts-strict
import { useEffect } from 'react'
import { Block, LoadingText, Table, TableResults } from 'components'
import { TableHeaderCell as TH, TableHeaderRow as TR } from 'components'
import { TableCell as Td, TableRow as Tr } from 'components'
import { AgentAgreementsProvider, useAgentAgreementsContext, useAgentContext } from 'contexts'
import { useDocumentTitle } from 'hooks'
import { AgentAgreement } from 'models'
import { capitalize, formatDate, formatTime } from 'utils'

export const AgentAgreementTab = () => {
  const { agent } = useAgentContext()

  const { setTitle } = useDocumentTitle()

  useEffect(() => setTitle(`${agent?.fullName} - Agent Agreements`), [agent, setTitle])

  return (
    <AgentAgreementsProvider agentId={agent?.id}>
      <AgentAgreementsTabContents />
    </AgentAgreementsProvider>
  )
}

const AgentAgreementsTabContents = () => {
  const { agentAgreementsQuery } = useAgentAgreementsContext()

  if (agentAgreementsQuery.isLoading) {
    return <LoadingText />
  }

  const agentAgreements = agentAgreementsQuery.data

  return (
    <Table data-testid="agent-agreements-table">
      <TR>
        <TH>Issued At</TH>
        <TH>Voided At</TH>
        <TH>Signed At</TH>
        <TH>Version</TH>
        <TH>Commission</TH>
        <TH>Status</TH>
        <TH>Note</TH>
      </TR>
      <TableResults type="AgentAgreements" colSpan={6}>
        {agentAgreements.map((agentAgreement: AgentAgreement) => (
          <AgentAgreementRow key={agentAgreement.id} agentAgreement={agentAgreement} />
        ))}
      </TableResults>
    </Table>
  )
}

const AgentAgreementRow = ({ agentAgreement }: { agentAgreement: AgentAgreement }) => {
  return (
    <Tr data-testid={`agent-agreement-id-${agentAgreement.id}`}>
      <Td>
        <Block
          title={formatDate(agentAgreement.createdAt)}
          subtitle={formatTime(agentAgreement.createdAt)}
        />
      </Td>
      <Td>
        <Block
          title={formatDate(agentAgreement.voidedAt)}
          subtitle={formatTime(agentAgreement.voidedAt)}
        />
      </Td>
      <Td>
        <Block
          title={formatDate(agentAgreement.signedAt)}
          subtitle={formatTime(agentAgreement.signedAt)}
        />
      </Td>
      <Td>{agentAgreement.agreement?.version}</Td>
      <Td>{agentAgreement.agreement?.commissionSplit}%</Td>
      <Td>{capitalize(agentAgreement.status!)}</Td>
      <Td>{agentAgreement.note}</Td>
    </Tr>
  )
}
