import { useLookupsController } from 'controllers/Lookups'

export const useEnvelopeTemplates = () => {
  const [envelopeTemplatesTradeIn] = useLookupsController('docusign_template_cc_trade_in')
  const [envelopeTemplatesCashOffer] = useLookupsController('docusign_template_cc_cash_offer')

  return {
    envelopeTemplatesTradeIn,
    envelopeTemplatesCashOffer
  }
}
