// @ts-strict

import { useLocation } from 'react-use'
import { Contents, Page } from 'components'
import { AgentPartnershipsTable, CreateAgentPartnershipButtonForm } from 'content/AgentPartnerships'
import { ListProvider } from 'contexts'
import { useAgentPartnershipsController } from 'controllers'
import { useDocumentTitle } from 'hooks'
import { agentPartnershipsListColumns } from 'lookups'
import { queryStringToObject } from 'utils'

const PAGE_TITLE = 'Partnerships'

const useAgentPartnershipsPage = () => {
  useDocumentTitle(PAGE_TITLE)
  const { search } = useLocation()
  const searchParams = queryStringToObject(search)

  const { agentPartnerships, isLoading, isError, createAgentPartnership } =
    useAgentPartnershipsController({ searchParams })

  return {
    agentPartnerships,
    createAgentPartnership,
    isLoading,
    isError
  }
}

export const PartnershipsPage = () => {
  useDocumentTitle('Partnerships')
  const { agentPartnerships, isLoading, createAgentPartnership } = useAgentPartnershipsPage()

  return (
    <Page>
      <Contents
        title={PAGE_TITLE}
        actions={<CreateAgentPartnershipButtonForm mutation={createAgentPartnership} />}
      >
        <ListProvider columns={agentPartnershipsListColumns}>
          <AgentPartnershipsTable isLoading={isLoading} agentPartnerships={agentPartnerships} />
        </ListProvider>
      </Contents>
    </Page>
  )
}
