import { Autocomplete, Box, Flex, MultiSelect, Text } from '@foundation/components'
import { CheckBoxArray, DateInput, FormField } from 'components'
import { useFeatureFlags } from 'hooks'
import { useCustomEvents } from 'lib/GoogleAnalytics'
import { FilterDialog, useFilterDialog } from 'templates'
import {
  useAgentsAutocomplete,
  useDateRangeFilter,
  useUsersAutocomplete
} from '../../commons/hooks'
import { useCommsFiltersContext } from '../../commons/ui/CommsFiltersContext'
import {
  channelsOptions,
  CommsFiltersDateRange,
  GlobalFilters
} from '../../commons/ui/CommsFiltersContext/filterSettings'

export const ListClientCommsFilters = () => {
  const { tracking } = useCustomEvents()
  const { salesAppCommsZoomCalls, salesOpsCommsEmails } = useFeatureFlags([
    'sales-app-comms-zoom-calls',
    'sales-ops-comms-emails'
  ])

  const { filters, setFilter, defaults } = useCommsFiltersContext()
  const { values, setValue, rootProps, triggerButtonProps, applyButtonProps, resetButtonProps } =
    useFilterDialog<GlobalFilters>({
      resetValues: {
        channels: defaults.clientDefaultChannels
      },
      values: {
        ...filters.client,
        channels: filters.client.channels
      },
      onApply: appliedFilters => {
        setFilter('client', appliedFilters)
        tracking({
          name: 'filters',
          params: { action: 'apply', location: 'lead', target: 'communication' }
        })
      }
    })
  const { getDateRangeFilterSetValue } = useDateRangeFilter({ dateRangeValues: values.dateRange })
  const agentsAutocomplete = useAgentsAutocomplete()
  const usersAutocomplete = useUsersAutocomplete()

  const isChannelsSelected = !!values.channels?.length

  const setDateRange = (
    key: keyof CommsFiltersDateRange,
    value: CommsFiltersDateRange[typeof key]
  ) => {
    setValue('dateRange', getDateRangeFilterSetValue(key, value))
  }

  const legacyChannelsOptions = channelsOptions
    .map(channels => ({
      value: channels.value,
      name: channels.label
    }))
    .filter(
      channels =>
        channels.name === 'Texts' || channels.name === 'Voice Calls' || channels.name === 'Emails'
    )
    .filter(channels => {
      if (channels.name === 'Emails' && !salesOpsCommsEmails.enabled) {
        return false
      }

      return true
    })

  const multiselectChannelsOptions = channelsOptions.filter(channels => {
    if (channels.label === 'Emails' && !salesOpsCommsEmails.enabled) {
      return false
    }

    return true
  })

  return (
    <FilterDialog.Root {...rootProps}>
      <FilterDialog.Trigger>
        <FilterDialog.TriggerButton variant="outline" {...triggerButtonProps} />
      </FilterDialog.Trigger>
      <FilterDialog.Content>
        <FilterDialog.Header>Filter Communications</FilterDialog.Header>
        <FilterDialog.Body>
          {salesAppCommsZoomCalls.enabled ? (
            <Box marginTop="$5">
              <MultiSelect
                label="Channels"
                isRequired
                items={multiselectChannelsOptions}
                values={values.channels || []}
                onChange={val => setValue('channels', val)}
                errorMessage={!isChannelsSelected ? 'Select one or multiple options' : ''}
              />
            </Box>
          ) : (
            <FormField
              title="Channels"
              error={!isChannelsSelected ? 'Select one or multiple options' : ''}
            >
              <CheckBoxArray
                options={legacyChannelsOptions}
                value={values?.channels}
                onChange={val => setValue('channels', val)}
              />
            </FormField>
          )}
          <Box marginTop="$5" marginBottom="$5">
            <Flex flexDirection="column" justifyContent="center" gap="$3">
              <Text size="sm" weight="bold">
                Date range
              </Text>
              <Flex gap="$3">
                <Box width="50%">
                  <FormField title="Starting">
                    <DateInput
                      value={values?.dateRange?.startingDate}
                      onChange={val => setDateRange('startingDate', val)}
                      maxDate={
                        values?.dateRange?.endingDate
                          ? new Date(values?.dateRange?.endingDate)
                          : undefined
                      }
                    />
                  </FormField>
                </Box>
                <Box width="50%">
                  <FormField title="Ending">
                    <DateInput
                      value={values?.dateRange?.endingDate}
                      onChange={val => setDateRange('endingDate', val)}
                      minDate={
                        values?.dateRange?.startingDate
                          ? new Date(values?.dateRange?.startingDate)
                          : undefined
                      }
                    />
                  </FormField>
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Box marginTop="$5">
            <Autocomplete
              data-testid="agentAutocomplete"
              label="Agent"
              items={agentsAutocomplete.items}
              onChange={e => setValue('agent', e)}
              onInputChange={agentsAutocomplete.setSearchTerm}
              status={agentsAutocomplete.status}
              placeholder="Select one Agent by name or email"
              onOpen={() => agentsAutocomplete.setIsQueryEnabled(true)}
              value={values.agent}
            />
          </Box>
          <Box marginTop="$5">
            <Autocomplete
              data-testid="userAutocomplete"
              items={usersAutocomplete.items}
              onChange={val => setValue('user', val)}
              onInputChange={usersAutocomplete.setSearchTerm}
              status={usersAutocomplete.status}
              label="HL User"
              placeholder="Select one HL user by name or email"
              onOpen={() => usersAutocomplete.setIsQueryEnabled(true)}
              value={values.user}
            />
          </Box>
        </FilterDialog.Body>
        <FilterDialog.Footer>
          <FilterDialog.ResetButton {...resetButtonProps} />
          <FilterDialog.ApplyButton isDisabled={!isChannelsSelected} {...applyButtonProps} />
        </FilterDialog.Footer>
      </FilterDialog.Content>
    </FilterDialog.Root>
  )
}
