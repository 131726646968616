import { TransactionTeam } from 'models'
import { getHapiRequestParams, TransactionTeamsSchema } from 'schema'
import { getTeamsUrl } from 'services/getUrl'
import { deserializeAsync, getPageCount, getRequest } from 'utils'
import { TFetchItems } from '../types'
import { getTeamSearchParams, TeamsParams } from './getSearchParams'

const transactionTeamListShape: TransactionTeamsSchema = {
  'transactionTeams:team': [
    'description',
    'enabled',
    'name',
    'type',
    {
      teamMemberships: [
        'role',
        'createdAt',
        {
          user: ['firstName', 'lastName', 'email']
        }
      ]
    }
  ]
}

export const fetchTeams = async (settings: TeamsParams): Promise<TFetchItems<TransactionTeam>> => {
  const searchParams = getHapiRequestParams(transactionTeamListShape)
  const url = getTeamsUrl({
    searchParams: { ...searchParams, ...getTeamSearchParams(settings), 'page[size]': 1000 }
  })
  const response = await getRequest(url)
  const teams = await deserializeAsync(response.data, {
    transform: TransactionTeam.create
  })

  return {
    items: teams,
    totalCount: response.data.meta.total_teams,
    pageCount: getPageCount(response.data.meta.total_teams, 1000)
  }
}
