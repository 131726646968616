/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Lead, MortgageLead, SellerLead } from 'models'

type TProps = {
  lead: Lead | MortgageLead | SellerLead | undefined
}

const creditScoreValidOptions = ['dont_know', 'over_740', '680_739', '620_679']
const fieldsValidations = {
  primaryResidence: [true, 'true'],
  fhaVaLoan: ['no'],
  ageRestrictedCommunity: [false, 'false'],
  creditScore: creditScoreValidOptions
}

export default function useIsBBYSEligible({ lead }: TProps) {
  const [isBBYSEligible, setIsBBYSEligible] = useState<boolean>(null)
  const [isDrStateEligible, setIsDrStateEligible] = useState<boolean>(null)
  const [hasInvalidFields, setHasInvalidFields] = useState<boolean>(null)

  function calculateEligibility() {
    return !getHasInvalidFields() && getIsDrStateEligible()
  }

  function getIsDrStateEligible() {
    return lead?.bbysEligibleArea
  }

  function getHasInvalidFields() {
    return Object.keys(fieldsValidations).some(
      field =>
        !fieldsValidations[field].includes(lead.callReview?.[field]) &&
        lead.callReview?.[field] !== undefined
    )
  }

  useEffect(() => {
    if (lead) {
      setIsBBYSEligible(calculateEligibility())
      setIsDrStateEligible(getIsDrStateEligible())
      setHasInvalidFields(getHasInvalidFields())
    }
  }, [lead])

  return {
    isBBYSEligible,
    isDrStateEligible,
    hasInvalidFields
  }
}
