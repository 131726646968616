import { useState } from 'react'
import { Button, FormField, PhoneInput, Select, TextArea, TextInput } from 'components'
import { useUsStates } from 'lookups'
import styles from '../CreateEscrowOrder.module.scss'
import { ReferringAgentAutocomplete } from '../ReferringAgentAutocomplete'
import { TEscrowOrderFormProps, TEscrowOrderPayload } from '../types'

type AgentFormFields = keyof TEscrowOrderPayload

export const ReferringAgentFields = ({ payload, onChangeAttribute }: TEscrowOrderFormProps) => {
  const [addingAgent, setAddingAgent] = useState(false)
  const { usStateOptions, usStates } = useUsStates()

  const agentFields = [
    'referringAgentId',
    'referringAgentName',
    'agentBrokerage',
    'agentEmail',
    'agentFullName',
    'agentLicenseNumber',
    'agentPhone',
    'agentState',
    'agentStateId',
    'agentTeamLeadName',
    'notesToAe'
  ] as AgentFormFields[]

  const clearAgentInfo = () => {
    agentFields.forEach(field => onChangeAttribute(field, ''))
  }

  const toggleAddingAgent = () => {
    setAddingAgent(prev => !prev)
    clearAgentInfo()
  }

  if (addingAgent) {
    return (
      <>
        <FormField title="Name" required>
          <TextInput
            data-testid="agent-name-field"
            value={payload.agentFullName}
            onChange={val => onChangeAttribute('agentFullName', val)}
            className={styles.input}
            placeholder="Enter full name"
          />
        </FormField>
        <FormField title="Phone" required>
          <PhoneInput
            data-testid="agent-phone-field"
            value={payload.agentPhone}
            onChange={val => onChangeAttribute('agentPhone', val)}
            className={styles.input}
            placeholder="Enter phone"
          />
        </FormField>
        <FormField title="Email" required>
          <TextInput
            data-testid="agent-email-field"
            value={payload.agentEmail}
            onChange={val => onChangeAttribute('agentEmail', val)}
            className={styles.input}
            placeholder="Enter email"
          />
        </FormField>
        <FormField title="State of licensure" required>
          <Select
            data-testid="agent-state-field"
            onChange={val => {
              const selectedState = usStates.find(state => state.id === val)
              if (!selectedState) {
                return
              }
              onChangeAttribute('agentStateId', selectedState.id)
              onChangeAttribute('agentState', selectedState.code)
            }}
            value={payload.agentStateId}
            options={usStateOptions}
            placeholder="Select a State"
            className={styles.input}
            addBlank
          />
        </FormField>
        <FormField title="License number" required>
          <TextInput
            data-testid="agent-license-field"
            value={payload.agentLicenseNumber}
            onChange={val => onChangeAttribute('agentLicenseNumber', val)}
            className={styles.input}
            placeholder="Enter license number"
          />
        </FormField>
        <FormField title="Team lead name">
          <TextInput
            data-testid="agent-team-lead-field"
            value={payload.agentTeamLeadName}
            onChange={val => onChangeAttribute('agentTeamLeadName', val)}
            className={styles.input}
            placeholder="Enter team lead full name"
          />
        </FormField>
        <FormField title="Brokerage" required>
          <TextInput
            data-testid="agent-brokerage-field"
            value={payload.agentBrokerage}
            onChange={val => onChangeAttribute('agentBrokerage', val)}
            className={styles.input}
            placeholder="Enter brokerage"
          />
        </FormField>
        <FormField title="Agent notes">
          <TextArea
            data-testid="agent-notes-field"
            value={payload.notesToAe}
            onChange={val => onChangeAttribute('notesToAe', val)}
            className={styles.input}
            placeholder="Enter notes"
          />
        </FormField>
        <p className={styles.note}>
          <Button as="link" onClick={toggleAddingAgent} data-testid="toggle-search-agent-button">
            Search for agent
          </Button>
        </p>
      </>
    )
  }

  return (
    <>
      <FormField title="Referring agent">
        <ReferringAgentAutocomplete
          data-testid="referring-agent-autocomplete"
          value={payload.referringAgentName}
          className={styles.input}
          onSelect={val => {
            onChangeAttribute('referringAgentName', [val?.title, val?.subTitle].join(' - '))
            onChangeAttribute('referringAgentId', val?.id)
          }}
          onClear={() => {
            onChangeAttribute('referringAgentName', '')
            onChangeAttribute('referringAgentId', '')
          }}
          searchParams={{
            include:
              'state,marketplace_program_agents,marketplace_program_agents.marketplace_program'
          }}
        />
      </FormField>
      <p className={styles.note}>Can't find the agent?</p>
      <Button as="link" onClick={toggleAddingAgent} data-testid="toggle-search-agent-button">
        Add a new agent
      </Button>
    </>
  )
}
